import React from 'react';
import s from './Port.scss';
import RaisedButton from '../Common/RaisedButton';

export default function ButtonForm(props) {
  return (
    <div className={s.wrapper_buttons_form}>
      <RaisedButton
        label="Cancel"
        disableTouchRipple
        disableFocusRipple
        secondary
        onTouchTap={props.onTouchTap}
        buttonStyle={{
          borderRadius: '50px',
        }}
        style={{
          marginRight: '20px',
        }}
      />

      <RaisedButton
        label="SAVE"
        disableTouchRipple
        disableFocusRipple
        type="submit"
        primary
        style={{
          marginRight: '0',
        }}
        buttonStyle={{
          borderRadius: '50px',
        }}
      />
    </div>
  );
}
