
    var content = require("!!../../../node_modules/css-loader/dist/cjs.js?{\"importLoaders\":1,\"sourceMap\":false,\"modules\":true,\"localIdentName\":\"[hash:base64:5]\"}!../../../node_modules/postcss-loader/src/index.js!../../../node_modules/sass-loader/lib/loader.js?sourceMap!./Header.scss");
    var insertCss = require("!../../../node_modules/isomorphic-style-loader/lib/insertCss.js");

    if (typeof content === 'string') {
      content = [[module.id, content, '']];
    }

    module.exports = content.locals || {};
    module.exports._getContent = function() { return content; };
    module.exports._getCss = function() { return content.toString(); };
    module.exports._insertCss = function(options) { return insertCss(content, options) };
    
    // Hot Module Replacement
    // https://webpack.github.io/docs/hot-module-replacement
    // Only activated in browser context
    if (module.hot && typeof window !== 'undefined' && window.document) {
      var removeCss = function() {};
      module.hot.accept("!!../../../node_modules/css-loader/dist/cjs.js?{\"importLoaders\":1,\"sourceMap\":false,\"modules\":true,\"localIdentName\":\"[hash:base64:5]\"}!../../../node_modules/postcss-loader/src/index.js!../../../node_modules/sass-loader/lib/loader.js?sourceMap!./Header.scss", function() {
        content = require("!!../../../node_modules/css-loader/dist/cjs.js?{\"importLoaders\":1,\"sourceMap\":false,\"modules\":true,\"localIdentName\":\"[hash:base64:5]\"}!../../../node_modules/postcss-loader/src/index.js!../../../node_modules/sass-loader/lib/loader.js?sourceMap!./Header.scss");

        if (typeof content === 'string') {
          content = [[module.id, content, '']];
        }

        removeCss = insertCss(content, { replace: true });
      });
      module.hot.dispose(function() { removeCss(); });
    }
  