import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import Form from './Form';
import Dialog from "../../Common/Dialog";
import cx from "classnames";

class DialogForm extends Component {
  render() {
    return (
      this.props.dialogOpened ?
        <Dialog
          open={true}
          handleClose={this.props.handleCloseDialog}
          bodyClassName={s.dialogBody}
          contentClassName={s.dialogContent}
          className={s.dialog_form}
        >
          <Form
            label={this.props.label}
            handleCloseDialog={this.props.handleCloseDialog}
            requestType={this.props.requestType}
          />
        </Dialog>
        : null
    );
  }
}

export default withStyles(s)(DialogForm);
