/** @name PORT_GET_LIST_REDUCER */
export default function PORT_GET_LIST(state, { payload, res }) {
  const newPortList = { ...state.portList, loading: false };

  if (res.page === 1) {
    newPortList.data = res.data;
  } else {
    newPortList.data = [...newPortList.data, ...res.data];
  }
  if (payload && payload.filters) {
    newPortList.filters = payload.filters;
  }
  newPortList.page = res.page;
  newPortList.total = res.total;
  newPortList.next = res.next;
  newPortList.sort = payload.sort;
  const newState = { portList: newPortList };
  return { ...state, ...newState };
}
