import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _Dialog from 'material-ui/Dialog';
import s from './Dialog.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from 'classnames';
import NavigationClose from 'material-ui/svg-icons/navigation/close';

const dialogStyles = {
  style: {},
  bodyStyle: {
    padding: '0 0 32px',
    overflowY: 'hidden!important',
  },
  contentStyle: {},
  overlayStyle: {},
  titleStyle: {
    overflow: 'hidden',
    padding: '0',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottom: 'none',
    fontSize: '16px',
  },
  inputTitleStyle: {
    margin: '0',
    padding: '24px 28px',
    backgroundColor: '#F5F5F5',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    fontSize: '18px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.8)',
  },
};
const closeIconStyle = {
  position: 'absolute',
  cursor: 'pointer',
  top: '20px',
  right: '12px',
};
class DialogGreyTitle extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.title = (
      <div>
        {props.title ?
          <h3 style={dialogStyles.inputTitleStyle}>{props.title}</h3>
          : ''
        }
        <NavigationClose style={closeIconStyle} onClick={props.handleClose} />
      </div>
    );
  }


  render() {
    const { header, open,title, handleClose, ...rest } = this.props;
    return (
      <_Dialog
        titleClassName="dialog_header_title"
        title={header || this.title}
        modal={this.props.modal}
        open={this.props.open || this.state.open}
        onRequestClose={this.props.handleClose}
        autoScrollBodyContent={this.props.autoScrollBodyContent}
        bodyStyle={dialogStyles.bodyStyle}
        {...dialogStyles}
        {...rest}
        contentClassName={cx(s.dialogContent, !this.props.title ? s.empty_title : '', this.props.contentClassName)}
      >
        {this.props.children}
      </_Dialog>
    );
  }
}

DialogGreyTitle.propTypes = {
  handleClose: PropTypes.func.isRequired,
  autoScrollBodyContent: PropTypes.bool,
};
DialogGreyTitle.defaultProps = { autoScrollBodyContent: true };

export default withStyles(s)(DialogGreyTitle);
