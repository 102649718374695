import React from 'react';

import CargoApi from '../../core/api/Cargo';
import Layout from '../../components/Layout';


export default {
  path: '/bid',
  children: [
    {
      path: '/tc/:bidId',
      async action({ params, hash, token, ...rest }) {
        const TcTrade = ((await import(/* webpackChunkName: 'TcTrade' */ '../../components/Monitor/TcOffer/TcTrade')).default);
        let bid;
        let error;
        try {
          bid = (await CargoApi.getTcBidById(params.bidId, token)).data;
        } catch (e) {
          error = e;
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <TcTrade
                type={params.type}
                bid={bid}
                bidId={params.bidId}
                error={error}
              />
            </Layout>
          ),
        };
      },
    },
  ],
};
