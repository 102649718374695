import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StarIcon from "material-ui/svg-icons/toggle/star";
import StarIconBorder from "material-ui/svg-icons/toggle/star-border";

export default function VesselRatingInsideTableView({rating}){
  return <span>
    {
      rating?.average ?
        <StarIcon
          style={{
            position: 'relative',
            width: '12px',
            height: '12px',
            top: '-1px',
          }}
          color='#4380C7'
        />
        :
        <StarIconBorder
          style={{
            position: 'relative',
            width: '12px',
            height: '12px',
            top: '-1px',
          }}
          color='#4380C7'
        />
    }
    <span>{rating?.average ? ' ' + (rating.average * 5).toFixed(1) : null}</span>
  </span>
}
