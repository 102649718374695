/** @name PROFILE_SAVE_PREFERENCES_REDUCER */
export default function PROFILE_SAVE_PREFERENCES(state, action) {
  const newState = {
    user: {
      ...state.user,
      accountPreferences: { ...(state.user.accountPreferences || {}), ...action.payload },
    },
  };
  return { ...state, ...newState };
}
