import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextArea from './../../Monitor/Edit/TextArea';
import ContentAdd from 'material-ui/svg-icons/content/add';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import Delete from 'material-ui/svg-icons/action/delete';
import cx from 'classnames';
import s from './AdditionalDescription.scss';
import RaisedButton from './../../Common/RaisedButton';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Formsy from "formsy-react";
import { dateTime } from './../../../core/format';
import Vessel from './../../../core/api/Vessel';
import { connect } from 'react-redux';

export class AdditionalDescription extends Component {

  static contextTypes = {
    showMessage: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.MAX_SYMBOLS = 50000;
    this.state = {
      addingNew: false,
      isClumped: false,
      isChanged: false,
    }
  }

  isOverflowing = () => this.description && this.description.scrollHeight > 54 ? true : false;

  componentDidMount() {
    if (this.isOverflowing()) {
      this.setState({
        isClumped: true,
      })
    }
  }

  toggleDesc = () => this.setState( state => ({isClumped: !state.isClumped}))

  renderDesc = () => {
    const { additionalDescription } = this.props;
    const { isClumped } = this.state;
    return (
      <div title={additionalDescription?.value}>
        {this.renderLabel()}
        <pre ref={el => this.description = el} style={{ overflow: "hidden", textOverflow: "ellipsis" }} className={cx(s.description, isClumped ? s.truncate : "")}>
          {
            additionalDescription.value
          }
        </pre>
        {
          this.isOverflowing()
          ? (
            <span onClick={this.toggleDesc}>
              {
                isClumped
                ? <span className={s.green}>Show more</span>
                : <span className={s.green}>Show less</span>
              }
            </span>
          )
          : null
        }
      </div>
    )
  }

  reset = (cb = () => undefined) => this.setState(state => ({ ...state, addingNew: false, isChanged: false }), cb)

  checkForOverflow = () => {
    this.setState( state => ({
      ...state,
      isClumped: this.isOverflowing() ? true : state.isClumped
    }))
  }

  handleCancel = () => {
    this.props.resetValue && this.props.resetValue();
    this.reset(this.checkForOverflow)
  }

  handleChange = val => {
    const { additionalDescription } = this.props;
    const isValid = this.runValidations(val);
    if (this.props.handler) {
      this.props.handler(val);
    }
    this.setState({
      isChanged: isValid
    })
  }

  runValidations = val => {
    if (val.length > this.MAX_SYMBOLS) return false;
    else if (String(val).trim().length === 0) return false;
    else return true;
  }

  handleSave = async () => {
    if (!this.state.isChanged) return;
    try {
      const { additionalDescription } = this.props;
      const obj = additionalDescription?.value ? { value: additionalDescription.value } : { value: "", status: "delete" };
      obj.value = obj.value || "";
      obj.status = obj.value?.length > 0 ? "update" : "delete";

      const res = await Vessel.addAdditionalDesc(this.props.vessel._id, obj);
      this.props.handler && this.props.handler(this.newAdditionalDescObj(obj.value));
    } catch (error) {
      console.error(error);
      this.context.showMessage && this.context.showMessage({ message: `Error updating additional description ${error?.message}` })
    } finally {
      this.reset(this.checkForOverflow);
    }

  }
  // as we are displaying GMT, convert local date to UTC every time we save
  localToUTC = (date = new Date()) => date.getTime() + (date.getTimezoneOffset() * 60000)

  newAdditionalDescObj = (value = "") => {
    const obj = {
      value,
      lastUpdate: {
        status: value.length === 0 ? 'delete' : 'update',
        date: this.localToUTC(new Date()),
        user: {
          ...this.props.user
        }
      }
    }
    return obj;
  }

  handleDelete = async () => {
    try {
      this.props.handler(this.newAdditionalDescObj());
      const res = await Vessel.addAdditionalDesc(this.props.vessel._id, {
        value: "",
        status: "delete",
      });
      this.setState({
        isChanged: false,
        addingNew: false,
      })
    } catch (error) {
      console.error(error);
      this.context.showMessage && this.context.showMessage({ message: `Error deleting additional description ${error?.message}` })
    }
  }
  toggleEdit = () => {
    if (this.state.addingNew) {
      this.props.resetValue && this.props.resetValue();
    }
    this.setState(state => ({ addingNew: !state.addingNew }));
  }

  renderLabel = () => {
    const { additionalDescription } = this.props;
    const date = additionalDescription?.lastUpdate?.date ? new Date(additionalDescription.lastUpdate.date) : null;
    let text = "";
    if (additionalDescription?.lastUpdate?.user) {
      const name = additionalDescription.lastUpdate.user.name;
      const surname = additionalDescription.lastUpdate.user.surname;
      text = <span>Last update: <span className={s.name}>{name}</span> <span className={s.name}>{surname}</span> {date ? dateTime(date) : ""}</span>;
    }
    const isDeleted = additionalDescription?.lastUpdate?.status === 'delete';
    return (
      <div className={s.label}>
        <h5>ADDITIONAL DESCRIPTION</h5>
        {
          text
          ? (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <p className={s.last_update}><span className={s.divider}></span>{text} {isDeleted ? <span className={s.del}>(deleted)</span> : null}</p>
              {
                isDeleted
                ? null
                : (
                  <div>
                    <Edit onClick={this.toggleEdit} color="#B2B2B2" className={s.edit}></Edit>
                    <Delete onClick={this.handleDelete} color="#B2B2B2" className={s.delete}></Delete>
                  </div>
                )
              }
            </div>
          )
          : null
        }
      </div>
    )
  }

  renderArea = () => {
    const { handleCancel, handleChange, MAX_SYMBOLS } = this;
    const { additionalDescription } = this.props;
    const { isChanged } = this.state;

    return (
      <Formsy.Form onValidSubmit={this.handleSave} className={s.text_area}>
        {this.renderLabel()}
        <TextArea
            validations={`minLength:1,maxLength:${MAX_SYMBOLS}`}
            validationError={`1 - ${MAX_SYMBOLS} characters`}
            placeholder="Type your text..."
            onChange={handleChange}
            name={`additionalDescription[value]`}
            value={additionalDescription?.value || ""}
            style={{ borderRadius: "8px" }}
        />

        <div className="row" style={{justifyContent: 'flex-end', marginTop: 12}}>
          <RaisedButton
            label="cancel"
            disableTouchRipple={true}
            disableFocusRipple={true}
            style={{
              marginRight: "8px",
              borderRadius: "15px",
              overflow: "hidden"
            }}
            onClick={handleCancel}
            buttonStyle={{
              borderRadius: "15px"
            }}
            secondary={true}
            onTouchTap={handleCancel}
          />
          <RaisedButton
            label={"SAVE"}
            disableTouchRipple={true}
            disableFocusRipple={true}
            primary={true}
            disabled={!isChanged}
            type="submit"
            style={{
              borderRadius: "15px"
            }}
            buttonStyle={{
              borderRadius: "15px"
            }}
          />
        </div>
      </Formsy.Form>
    )
  }

  renderAdd = () => {
    const { addNew } = this;
    const { addingNew } = this.state;

    if (addingNew) {
      return this.renderArea();
    }
    return (
      <div>
        {
          this.props.vessel?.additionalDescription?.lastUpdate
          ? this.renderLabel()
          : null
        }
        <Add onClick={addNew}></Add>
      </div>
    )
  }

  addNew = () => {
    this.setState({
      addingNew: true
    })
  }

  render() {

    const { additionalDescription } = this.props;
    const { addingNew } = this.state;

    const el = additionalDescription && additionalDescription.value && !addingNew ? this.renderDesc() : this.renderAdd();

    return (
      <div className={s.additional_desc}>
        {el}
      </div>
    )
  }
}

export const Add = ({children = 'Add additional description', onClick, styles = {}, ...rest}) => <div onClick={onClick} style={{display: 'inline-flex', cursor: 'pointer', alignItems: 'center', marginTop: 8, ...(styles || {})}} {...rest}> <ContentAdd className={s.plus_sign}></ContentAdd> <p className={s.add_new}>{children}</p> </div>

export default connect(state => ({ user: {...state.login.user} }), null)(withStyles(s)(AdditionalDescription));
