import { date } from '../../core/format';
import { filterTcOffer } from './filter';
//noinspection JSUnusedGlobalSymbols
/** @name VESSEL_TCOFFER_LIST_REDUCER */
export default function VESSEL_TCOFFER_LIST(state, { res }) {
  const newTcOfferList = { ...state.tcOfferList };
  if (res.page === 1) {
    newTcOfferList.data = res.data;
  } else {
    newTcOfferList.data = [...newTcOfferList.data, ...res.data];
  }
  newTcOfferList.page = res.page;
  newTcOfferList.total = res.total;
  newTcOfferList.next = res.next;
  newTcOfferList.filters = res.filters;
  newTcOfferList.loading = false;
  const newState = { tcOfferList: newTcOfferList };
  return { ...state, ...newState };
}
