
const handysize = {
  "_id": "596741612954b5238475dbec",
  maxAge:0,
  "draft": 10.538,
  "flag": {
    "_id": "581c9a0937c38af7940e06f9",
    "name": "Malta"
  },
  "name": "Handysize",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "Handysize Bulkers 10-39,999 dwt",
  "speed": {
    "laden": "12.5"
  },
  "hatches": {
    "quantity": 5,
    "size": [
      {
        "l": 16.8,
        "b": 15
      },
      {
        "l": 19.2,
        "b": 19.2
      },
      {
        "l": 19.2,
        "b": 19.2
      },
      {
        "l": 19.2,
        "b": 19.2
      },
      {
        "l": 19.2,
        "b": 19.2
      }
    ],
    "all": [],
    "hatchType": "Folding"
  },
  "consumptions": {
    "tpc": 49,
    "tpi": 0,
    "laden": [
      {
        "quantity": 21.8,
        "typeOfFuel": "HFO 380"
      }
    ],
    "ballast": [
      {
        "quantity": 19.6,
        "typeOfFuel": "HFO 380"
      }
    ],
    "eco": [
      {
        "quantity": 16.5,
        "typeOfFuel": "HFO 380"
      }
    ],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 2,
        "typeOfFuel": "HFO 380"
      }
    ],
    "portWorking": [
      {
        "quantity": 5,
        "typeOfFuel": "HFO 380"
      }
    ]
  },
  "holds": {
    "quantity": 5,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 23689,
    "nt": 12725,
    "panamaNt": 0,
    "suezGtNt": 0,
    "suezGt": 0,
    "suezNt": 0
  },
  "dw": {
    "dwcc": 0,
    "tropical": 0,
    "summer": 38200
  },
  "dimensions": {
    "loa": 180,
    "beam": 29.8,
    "depth": 15.2
  },
  "capacity": {
    "grain": 47125,
    "bale": 45300,
    "teu": 0,
    "feu": 0,
    "ramp": 0
  },
  "blt": 2017,
  "speeds": {
    "laden": 13.5,
    "ballast": 14,
    "eco": 12,
    "ecoBallast": 0
  },
  "fitted": {
    "co2": true,
    "grab": true
  },
  "gears": {
    "summary": [
      {
        "category": "CR",
        "quantity": 4,
        "tonnage": 35,
        "combinable": false
      }
    ],
    "all": [
      "4 x 30 ton cranes"
    ],
    "maxSWL": 35
  },
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 21,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-07T23:37:28.825Z",
  "class": "RINA",
  "dimensionsV2": {
    "loa": 180,
    "beam": 29.8,
    "depth": 15.2
  },
  "exNames": [],
  "typeByPurpose": "BULK",
  "engineLocation": "",
  "typeByConstruction": "SID",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 26
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Ballast",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 24
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 12,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 18
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 12,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 17
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
  ]
};
const supramax = {
  "_id": "5967416a2954b5238475f285",
  maxAge:15,
  "draft": 12.80,
  "flag": {
    "_id": "581c9a0937c38af7940e0719",
    "name": "Panama"
  },
  "name": "Supramax",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "Handymax Bulkers 40-64,999 dwt",
  "speed": {
    "laden": "14.4"
  },
  "hatches": {
    "quantity": 5,
    "size": [],
    "all": [],
    "hatchType": "Folding"
  },
  "holds": {
    "quantity": 5,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 34164,
    "suezGtNt": 0,
    "nt": 0,
    "suezGt": 0,
    "suezNt": 0,
    "panamaNt": 0
  },
  "dw": {
    "dwcc": 0,
    "tropical": 0,
    "summer": 58328
  },
  "dimensions": {
    "loa": 189.99,
    "beam": 32.26,
    "depth": 18.38
  },
  "capacity": {
    "grain": 72360,
    "bale": 70557,
    "teu": 0,
    "feu": 0
  },
  "blt": 2016,
  "speeds": {
    "laden": 13.6,
    "ecoBallast": 0,
    "eco": 0,
    "ballast": 14.4
  },
  "fitted": {},
  "consumptions": {
    "tpc": 57.5,
    "tpi": 0,
    "laden": [
      {
        "quantity": 28.2,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ],
    "ballast": [
      {
        "quantity": 28.2,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ],
    "eco": [],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 2.8,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ],
    "portWorking": [
      {
        "quantity": 5,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.3,
        "typeOfFuel": "MGO"
      }
    ],
    "": [
      {
        "quantity": 22.2,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ]
  },
  "gears": {
    "summary": [
      {
        "_id": "5a25af8d9598277bc4bfb389",
        "combinable": false,
        "tonnage": 30,
        "category": "CR",
        "quantity": 4
      }
    ],
    "all": [
      "4 x 30t Cr",
      "12 cbm grabs"
    ]
  },
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 24,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-07T21:36:41.676Z",
  "dimensionsV2": {
    "loa": 189.99,
    "beam": 32.26,
    "depth": 18.38
  },
  "exNames": [],
  "typeByPurpose": "BULK",
  "engineLocation": "",
  "typeByConstruction": "SID",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 33
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 32
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 12,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 24
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 12.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 23
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
  ],
  noDieselAtSea:true,
};
const panamax = {
  "_id": "5967416f2954b5238475fd63",
  "draft": 13.95,
  maxAge:12,
  "flag": {
    "_id": "581c9a0937c38af7940e0719",
    "name": "Panama"
  },
  "name": "Panamax",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "Panamax Bulkers 65-99,999 dwt",
  "speed": {
    "laden": "12.5"
  },
  "hatches": {
    "quantity": 7,
    "size": [],
    "all": [],
    "operation": "H"
  },
  "consumptions": {
    "tpc": 66.5,
    "tpi": 0,
    "laden": [
      {
        "quantity": 33.5,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.05,
        "typeOfFuel": "MGO"
      }
    ],
    "ballast": [
      {
        "quantity": 31.5,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.05,
        "typeOfFuel": "MGO"
      }
    ],
    "eco": [],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 3.8,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.05,
        "typeOfFuel": "MGO"
      }
    ],
    "portWorking": []
  },
  "holds": {
    "quantity": 7,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 43501,
    "suezGtNt": 0,
    "nt": 27819,
    "suezGt": 0,
    "suezNt": 0,
    "panamaNt": 0
  },
  "dw": {
    "dwcc": 0,
    "tropical": 0,
    "summer": 74000
  },
  "dimensions": {
    "loa": 225,
    "beam": 32.26,
    "depth": 20.25
  },
  "capacity": {
    "grain": 89500,
    "bale": 86000,
    "teu": 0,
    "feu": 0
  },
  "blt": 2012,
  "speeds": {
    "laden": 12.5,
    "ecoBallast": 0,
    "eco": 0,
    "ballast": 13
  },
  "fitted": {},
  "gears": {
    "summary": [],
    "all": []
  },
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-08T03:29:29.448Z",
  "dimensionsV2": {
    "loa": 225,
    "beam": 32.26,
    "depth": 20.25
  },
  "exNames": [],
  "typeByPurpose": "BULK",
  "class": "ABS",
  "engineLocation": "",
  "typeByConstruction": "SID",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14.2,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 34
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 13.7,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 34
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 12.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 28
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 13,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 28
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
  ],
  noDieselAtSea:true,
};
const kamsarmax = {
  "_id": "5967416f2954b5238475fe4d",
  maxAge:12,
  "flag": {
    "_id": "581c9a0937c38af7940e0730",
    "name": "Singapore"
  },
  "name": "Kamsarmax",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "Panamax Bulkers 65-99,999 dwt",
  "speed": {
    "laden": "14.3"
  },
  "hatches": {
    "quantity": 0,
    "size": [],
    "all": []
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 44327,
    "suezGtNt": 0,
    "nt": 27618,
    "suezGt": 0,
    "suezNt": 0,
    "panamaNt": 0
  },
  "dw": {
    "dwcc": 0,
    "tropical": 0,
    "summer": 82500
  },
  draft:14.43,
  "dimensions": {
    "loa": 229,
    "beam": 32.25
  },
  "capacity": {
    "grain": 97000,
    "bale": 0,
    "teu": 0,
    "feu": 0
  },
  "blt": 2017,
  "speeds": {
    "laden": 12.75,
    "ecoBallast": 0,
    "eco": 0,
    "ballast": 12.75
  },
  "fitted": {},
  "consumptions": {
    "tpc": 70.5,
    "tpi": 0,
    "laden": [
      {
        "quantity": 23,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "ballast": [
      {
        "quantity": 21.75,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "eco": [],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 3,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "portWorking": [
      {
        "quantity": 5,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ]
  },
  "gears": {
    "summary": [],
    "all": []
  },
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-07T22:36:43.332Z",
  "dimensionsV2": {
    "loa": 229,
    "beam": 32.25,
    "depth": 20
  },
  "exNames": [],
  "typeByPurpose": "BULK",
  "engineLocation": "",
  "typeByConstruction": "SID",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 13.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 33
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Ballast",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 31
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 11.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 22
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 12.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 23
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
  ]
};
const capesize = {
  "_id": "596741772954b5238476108c",
  "draft": 18.2,
  maxAge:10,
  "flag": {
    "_id": "581c9a0937c38af7940e06c6",
    "name": "Greece"
  },
  "name": "Capesize",
  "imoNumber": "",
  "type": "capesize",
  "vesselType": "capesize",
  "speed": {
    "laden": "13.9"
  },
  "hatches": {
    "quantity": 0,
    "size": [],
    "all": []
  },
  "consumptions": {
    "canal": {},
    "portLoad": {},
    "portUnLoad": {},
    "working24h": {},
    "ecoLaden": {
      "mgo": 0,
      "lsfo": 0,
      "mdo": 0,
      "ifo": 47,
      "speed": 12.5
    },
    "ecoBallast": {
      "mgo": 0,
      "lsfo": 0,
      "mdo": 0,
      "ifo": 47,
      "speed": 13
    },
    "portWorking": [],
    "portIdle": [
      {
        "quantity": 4.5,
        "typeOfFuel": "HFO 180"
      }
    ],
    "eco": [
      {
        "quantity": 47,
        "typeOfFuel": "HFO 180"
      }
    ],
    "ballast": [
      {
        "quantity": 68,
        "typeOfFuel": "HFO 180"
      }
    ],
    "laden": [
      {
        "quantity": 68,
        "typeOfFuel": "HFO 180"
      }
    ],
    "windScale": 0,
    "tpi": 49.17,
    "tpc": 121
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 93360,
    "nt": 61829,
    "panamaNt": 0,
    "suezGtNt": 0
  },
  "dw": {
    "dwcc": 0,
    "summer": 180000,
    "tropical": 0
  },
  "dimensions": {
    "loa": 290,
    "beam": 45,
    "depth": 0
  },
  "capacity": {
    "grain": 198000,
    "bale": 0,
    "teu": 0,
    "feu": 0
  },
  "class": "american bureau of shipping",
  "subType": null,
  "callSign": "svbd2",
  "gears": {
    "summary": [],
    "all": []
  },
  "fitted": {
    "strengthened": "true"
  },
  "speeds": {
    "laden": 14.7,
    "ballast": 15.5,
    "eco": 13,
    "ecoBallast": 0
  },
  "blt": 2010,
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-08T14:13:20.641Z",
  "cells": [],
  "dimensionsV2": {},
  "exNames": [],
  "typeByPurpose": "BULK",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 62
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 15,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 62
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 12,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 43
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 13,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 43
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
  ],
  noDieselAtSea: true,
};

const tess58 = {
  "_id": "596741692954b5238475f00d",
  "draft": 12.83,
  "flag": {
    "_id": "581c9a0937c38af7940e0719",
    "name": "Panama"
  },
  "name": "TESS58",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "Handymax Bulkers 40-64,999 dwt",
  "speed": {
    "laden": "14.5"
  },
  "hatches": {
    "quantity": 0,
    "size": [],
    "all": []
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 0,
    "suezGtNt": 0,
    "nt": 0,
    "suezGt": 0,
    "suezNt": 0,
    "panamaNt": 0
  },
  "dw": {
    "dwcc": 0,
    "tropical": 0,
    "summer": 58220
  },
  "dimensions": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "capacity": {
    "grain": 72554,
    "bale": 0,
    "teu": 0,
    "feu": 0
  },
  "blt": 2015,
  "speeds": {
    "laden": 13.7,
    "ecoBallast": 0,
    "eco": 0,
    "ballast": 14.3
  },
  "fitted": {},
  "consumptions": {
    "tpc": 57.39,
    "tpi": 0,
    "laden": [
      {
        "quantity": 27.5,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "ballast": [
      {
        "quantity": 27.5,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "eco": [],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 2.8,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "portWorking": [
      {
        "quantity": 4.9,
        "typeOfFuel": "HFO 380"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ]
  },
  "gears": {
    "summary": [],
    "all": []
  },
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-04T22:21:02.177Z",
  "cells": [],
  "dimensionsV2": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "exNames": [
    {
      "_id": "5f3981b385590b762c470e18",
      "name": "King Island"
    }
  ],
  "typeByPurpose": "BULK",
  "class": "NK",
  "engineLocation": "",
  "typeByConstruction": "SID",
  "rating": {
    "average": 0,
    "reviews": 0
  },
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 13.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 31.5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 31
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 12,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 22.5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 12.25,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 21.5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Working",
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
  ],
}
const imabary61 ={
  "_id": "5967416a2954b5238475f21c",
  "draft": 13,
  "flag": {
    "_id": "581c9a0937c38af7940e0719",
    "name": "Panama"
  },
  "name": "Imabari 61",
  "imoNumber": "",
  "type": "Bulk Carrier",
  "vesselType": "Handymax Bulkers 40-64,999 dwt",
  "speed": {
    "laden": "14.5"
  },
  "hatches": {
    "quantity": 0,
    "size": [],
    "all": [],
    "operation": "H"
  },
  "consumptions": {
    "portWorking": [],
    "portIdle": [],
    "eco": [],
    "ballast": [],
    "laden": [],
    "windScale": 0,
    "tpi": 0,
    "tpc": 61.4
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 34773,
    "nt": 0,
    "panamaNt": 0,
    "suezGtNt": 0
  },
  "dw": {
    "dwcc": 0,
    "summer": 61000,
    "tropical": 0
  },
  "dimensions": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "capacity": {
    "grain": 77700,
    "bale": 0,
    "teu": 0,
    "feu": 0
  },
  "blt": 2015,
  "speeds": {
    "laden": 14.5,
    "ballast": 0,
    "eco": 0,
    "ecoBallast": 0
  },
  "fitted": {},
  "gears": {
    "summary": [
    ],
    "all": [
    ]
  },
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-05-16T20:40:10.150Z",
  "cells": [],
  "dimensionsV2": {},
  "exNames": [],
  "typeByPurpose": "BULK",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 33
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 14.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 33
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Working",
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 2.5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
  ],
}
const mstImb37 = {
  "_id": "596741612954b5238475dbef",
  "draft": 10.54,
  "flag": {
    "_id": "581c9a0937c38af7940e067f",
    "name": "Bahamas"
  },
  "name": "MST imb 37",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "Handysize Bulkers 10-39,999 dwt",
  "speed": {
    "laden": "14"
  },
  "hatches": {
    "quantity": 0,
    "size": [
      {
        "l": 15.87,
        "b": 17.16
      },
      {
        "l": 20.04,
        "b": 20.02
      },
      {
        "l": 20.04,
        "b": 20.02
      },
      {
        "l": 20.04,
        "b": 20.02
      },
      {
        "l": 20.04,
        "b": 20.02
      }
    ],
    "all": []
  },
  "consumptions": {
    "tpc": 48.64,
    "tpi": 0,
    "laden": [
      {
        "quantity": 22,
        "typeOfFuel": "HFO 180"
      }
    ],
    "ballast": [
      {
        "quantity": 19.5,
        "typeOfFuel": "HFO 180"
      }
    ],
    "eco": [
      {
        "quantity": 22,
        "typeOfFuel": "HFO 380"
      }
    ],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 2,
        "typeOfFuel": "HFO 380"
      }
    ],
    "portWorking": [
      {
        "quantity": 5.2,
        "typeOfFuel": "HFO 380"
      }
    ]
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 23322,
    "nt": 12208,
    "panamaNt": 19427,
    "suezGtNt": 0,
    "suezGt": 23859,
    "suezNt": 21625
  },
  "dw": {
    "dwcc": 0,
    "summer": 37707,
    "tropical": 38772
  },
  "dimensions": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "capacity": {
    "grain": 46995,
    "bale": 0,
    "teu": 0,
    "feu": 0,
    "ramp": 0
  },
  "blt": 2014,
  "speeds": {
    "laden": 13.5,
    "ballast": 13.5,
    "eco": 13.5,
    "ecoBallast": 0
  },
  "fitted": {
    "co2": true,
    "grab": true
  },
  "gears": {
    "summary": [
    ],
    "all": [
    ],
    "maxSWL": 30.5
  },
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 15.1,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-02T03:37:13.945Z",
  "class": "American Bureau of Shipping",
  "cells": [
    {
      "hatches": [
        {},
        {}
      ],
      "tweenDecks": [
        {},
        {}
      ],
      "holds": {}
    }
  ],
  "dimensionsV2": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "exNames": [],
  "typeByPurpose": "BULK",
  "engineLocation": "",
  "typeByConstruction": "SID",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 24.1
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 21.5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 13.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 22
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 13.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 19.5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Working",
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 5.2
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
  ],
}
const bpi74 = {
  "_id": "5967416d2954b5238475f818",
  "draft": 13.95,
  "flag": {
    "_id": "581c9a0937c38af7940e06d2",
    "name": "Hong Kong"
  },
  "name": "BPI 74",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "Panamax Bulkers 65-99,999 dwt",
  "speed": {
    "laden": "13.5"
  },
  "hatches": {
    "quantity": 0,
    "size": [],
    "all": [],
    "operation": null
  },
  "consumptions": {
    "tpc": 67,
    "tpi": 0,
    "laden": [
      {
        "quantity": 31.8,
        "typeOfFuel": "HFO 180"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "ballast": [
      {
        "quantity": 29.8,
        "typeOfFuel": "HFO 180"
      },
      {
        "quantity": 0.1,
        "typeOfFuel": "MGO"
      }
    ],
    "eco": [
      {
        "quantity": 25,
        "typeOfFuel": "HFO 180"
      }
    ],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 1.8,
        "typeOfFuel": "HFO 180"
      }
    ],
    "portWorking": []
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": [
      11415.3,
      13094.6,
      12931.7,
      13008.9,
      13018,
      13013,
      11853.2
    ]
  },
  "rt": {
    "gt": 38330,
    "nt": 24680,
    "suezGtNt": 0,
    "suezGt": 0,
    "suezNt": 0,
    "panamaNt": 0
  },
  "dw": {
    "dwcc": 0,
    "tropical": 0,
    "summer": 74000
  },
  "dimensions": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "capacity": {
    "grain": 89000,
    "bale": 0,
    "teu": 0,
    "feu": 0
  },
  "class": "CCS",
  "subType": null,
  "callSign": "vrho9",
  "gears": {
    "summary": [],
    "all": []
  },
  "fitted": {
    "strengthened": true,
    "co2": false
  },
  "speeds": {
    "laden": 13.5,
    "ballast": 13.75,
    "eco": 12.5,
    "ecoBallast": 0
  },
  "blt": 1997,
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-04T00:19:33.813Z",
  "dimensionsV2": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "exNames": [],
  "typeByPurpose": "BULK",
  "engineLocation": "",
  "typeByConstruction": "SID",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 32
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 28
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Working",
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 3
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
  ],
};

const bki82 ={
  "_id": "596741702954b52384760049",
  "draft": 14.43,
  "flag": {
    "_id": "581c9a0937c38af7940e06d2",
    "name": "Hong Kong"
  },
  "name": "BKI 82",
  "imoNumber": "",
  "type": "SID",
  "vesselType": "panamax",
  "speed": {
    "laden": "14.5"
  },
  "hatches": {
    "quantity": 0,
    "size": [],
    "all": []
  },
  "consumptions": {
    "tpc": 70.5,
    "tpi": 0,
    "laden": [
      {
        "quantity": 38.5,
        "typeOfFuel": "HFO 180"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ],
    "ballast": [
      {
        "quantity": 38.5,
        "typeOfFuel": "HFO 180"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ],
    "eco": [
      {
        "quantity": 27,
        "typeOfFuel": "HFO 180"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ],
    "windScale": 0,
    "portIdle": [
      {
        "quantity": 2.8,
        "typeOfFuel": "HFO 180"
      },
      {
        "quantity": 0.2,
        "typeOfFuel": "MGO"
      }
    ],
    "portWorking": [
      {
        "quantity": 3.8,
        "typeOfFuel": "HFO 180"
      },
      {
        "quantity": 0.4,
        "typeOfFuel": "MGO"
      }
    ]
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": [
      12185,
      14322.2,
      14229.4,
      14344.8,
      14247.7,
      14774,
      13083
    ]
  },
  "rt": {
    "gt": 43158,
    "nt": 27291,
    "suezGtNt": 0,
    "suezGt": 0,
    "suezNt": 0,
    "panamaNt": 0
  },
  "dw": {
    "dwcc": 0,
    "tropical": 0,
    "summer": 82500
  },
  "dimensions": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "capacity": {
    "grain": 97000,
    "bale": 0,
    "teu": 0,
    "feu": 0
  },
  "class": "nippon kaiji",
  "subType": null,
  "callSign": "vrgz7",
  "gears": {
    "summary": [],
    "all": []
  },
  "fitted": {
    "strengthened": true,
    "co2": false
  },
  "speeds": {
    "laden": 13.5,
    "ballast": 14,
    "eco": 13,
    "ecoBallast": 0
  },
  "blt": 2008,
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-08T14:05:45.395Z",
  "cells": [
    {
      "hatches": [
        {},
        {}
      ],
      "tweenDecks": [
        {},
        {}
      ],
      "holds": {}
    }
  ],
  "dimensionsV2": {
    "loa": 0,
    "beam": 0
  },
  "exNames": [],
  "typeByPurpose": "BULK",
  "engineLocation": "",
  "homePort": {
    "_id": "5821e6852fb0950f14a747da",
    "name": "Hong Kong"
  },
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 13.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 33
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Ballast",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 31
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 11.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 22
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 12.5,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 23
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
    {
      "mode": "Working",
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 3
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0.1
      }
    },
  ],
};

const bci180 ={
  "_id": "596741772954b52384760f21",
  "draft": 18.2,
  "flag": {
    "_id": "581c9a0937c38af7940e06ac",
    "name": "Cyprus"
  },
  "name": "BCI 180",
  "imoNumber": "",
  "type": "capesize",
  "vesselType": "capesize",
  "speed": {
    "laden": "15.4"
  },
  "hatches": {
    "quantity": 0,
    "size": [],
    "all": []
  },
  "consumptions": {
    "canal": {},
    "portLoad": {},
    "portUnLoad": {},
    "working24h": {},
    "ecoLaden": {
      "mgo": 0,
      "lsfo": 0,
      "mdo": 0,
      "ifo": 0,
      "speed": 0
    },
    "ecoBallast": {
      "mgo": 0,
      "lsfo": 0,
      "mdo": 0,
      "ifo": 0,
      "speed": 0
    },
    "portWorking": [],
    "portIdle": [],
    "eco": [],
    "ballast": [
      {
        "quantity": 57,
        "typeOfFuel": "HFO 180"
      }
    ],
    "laden": [
      {
        "quantity": 59,
        "typeOfFuel": "HFO 180"
      }
    ],
    "windScale": 0,
    "tpi": 47.6,
    "tpc": 121
  },
  "holds": {
    "quantity": 0,
    "lowerDims": [],
    "tweenDims": [],
    "all": []
  },
  "rt": {
    "gt": 92960,
    "nt": 58613,
    "panamaNt": 0,
    "suezGtNt": 0
  },
  "dw": {
    "dwcc": 0,
    "summer": 180000,
    "tropical": 0
  },
  "dimensions": {
    "loa": 0,
    "beam": 0,
    "depth": 0
  },
  "capacity": {
    "grain": 198000,
    "bale": 0,
    "teu": 0,
    "feu": 0
  },
  "class": "det norske veritas",
  "subType": null,
  "callSign": "5bzr2",
  "gears": {
    "summary": [],
    "all": []
  },
  "fitted": {
    "strengthened": "true"
  },
  "speeds": {
    "laden": 14,
    "ballast": 14.5,
    "eco": 0,
    "ecoBallast": 0
  },
  "blt": 2010,
  "images": [],
  "reeferPlugs": 0,
  "hullLimit": 0,
  "stackWeight": {
    "20hold": 0,
    "20deck": 0,
    "40hold": 0,
    "40deck": 0
  },
  "areas": {
    "mainDeck": 0,
    "tweenDeck": 0,
    "weatherDeck": 0,
    "total": 0
  },
  "strength": {
    "maxTt": 0,
    "maxTween": 0,
    "maxWeather": 0
  },
  "bunkers": [],
  "hasKnownLocationSince": "2020-06-06T05:00:34.706Z",
  "cells": [],
  "dimensionsV2": {},
  "exNames": [],
  "typeByPurpose": "BULK",
  "attachments": [],
  "speedConsumptionV2":[
    {
      "mode": "Laden",
      "speed": 14,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 62
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Ballast",
      "speed": 15,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 62
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Laden",
      "speed": 12,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 43
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Eco Ballast",
      "speed": 13,
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 43
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
    {
      "mode": "Working",
      "ifo": {
        "typeIfo": "HFO 380",
        "cons": 5
      },
      "mdo": {
        "typeMdo": "MGO",
        "cons": 0
      }
    },
  ],
};

export default [
  handysize,
  supramax,
  panamax,
  kamsarmax,
  capesize,
  tess58,
  imabary61,
  mstImb37,
  bpi74,
  bki82,
  bci180,
];
