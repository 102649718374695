import PropTypes from 'prop-types';
import React, { Component } from 'react'

export class WindowOpenInterceptor extends Component {

  static contextTypes = {
    interceptWindowOpen: PropTypes.func,
  }

  render() {
    const { interceptWindowOpen } = this.context;
    const { children } = this.props;

    if (typeof children === "function") {
      return children(interceptWindowOpen);
    }
    else {
      return React.Children.map(child => React.cloneElement(child, { interceptWindowOpen }));
    }
  }
}

export default WindowOpenInterceptor;
