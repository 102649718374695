import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MenuItem from 'material-ui/MenuItem';
import NarrowSelect from '../NewInputs/NarrowSelect';


class CargoTypeSelect extends Component {
  ref = input => {
    this.input = input;
  };
  getValue() {
    const id = this.input.getValue();
    if (id) {
      return this.context.cargoTypes.find(t => t._id === id);
    }
    return id;
  }
  setValue(value) {
    const obj = this.context.cargoTypes.find(t => t._id === value);
    this.props.onChange && this.onChange(null, obj || value);
    this.input.setValue((value && value._id) || value);
    return obj || value;
  }
  onChange = (e, _id) => {
    const obj = this.context.cargoTypes.find(t => t._id === _id);
    this.props.onChange && this.props.onChange(e, obj);
  };
  focus() {
    this.input.focus();
  }
  render() {
    const { onChange, ...props } = this.props;
    const { cargoTypes = [] } = this.context;
    return (
      <NarrowSelect
        innerRef={this.ref}
        onChange={this.onChange}
        {...props}
        value={this.props.value}
      >
        {cargoTypes.filter(ct => !ct.forbidNewSubtypes || !this.props.hideForbidden).map(cargoType => {
          return (
            <MenuItem
              key={cargoType._id}
              value={cargoType._id}
              primaryText={cargoType.name}
              style={{
                fontSize: '13px'
              }}
            />
          );
        })}
      </NarrowSelect>
    );
  }
}

CargoTypeSelect.propTypes = {};
CargoTypeSelect.defaultProps = {
  hideForbidden: false,
};
CargoTypeSelect.contextTypes = {
  cargoTypes: PropTypes.array,
  formsy: PropTypes.object,
};
export default CargoTypeSelect;
