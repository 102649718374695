import React, { Component } from 'react'
import Select from '../Common/Select';
import styles from './muistyles'
import cx from 'classnames'
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class NarrowSelect extends Component {

    static defaultProps = {
      innerRef: () => { },
      El: Select,
    }

    render() {
        const {inputStyles, className, classes, labelStyle = {}, containerStyle = {}, style = {}, El, underlineStyle = {}, ...props} = this.props;
        return (
            <El
                ref={this.props.innerRef}
                className={cx("select_default", className, classes)}
                style={{...styles.selectContainer, ...containerStyle, ...style}}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={{...styles.text.label, ...labelStyle}}
                hintStyle={{...styles.text.label, ...labelStyle}}
                errorStyle={styles.error}
                inputStyle={{...styles.select.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={{...styles.text.underline, ...underlineStyle}}
                underlineFocusStyle={styles.text.underlineFocus}
                {...props}
            />
        )
    }
}

export default withStyles(s)(NarrowSelect)
