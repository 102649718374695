import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Facebook = props => (
  <span {...props}>
    <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1">

        <title>Facebook</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Web---Start"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Shipnext-1440" transform="translate(-1038.000000, -1754.000000)" fill="#FFFFFF">
            <g id="Group-5" transform="translate(951.000000, 1754.000000)">
                <g id="Group-3" transform="translate(87.000000, 0.000000)">
                    <g id="Icon/Social/FB">
                        <circle id="Oval-4" opacity="0.33350317" cx="22" cy="22" r="22"></circle>
                        <path d="M26.0192908,13.6585167 L23.8830495,13.6551724 C21.4830556,13.6551724 19.9320741,15.2077703 19.9320741,17.6108273 L19.9320741,19.4346491 L17.784179,19.4346491 C17.5985754,19.4346491 17.4482759,19.5814645 17.4482759,19.7625591 L17.4482759,22.405069 C17.4482759,22.5861636 17.5987468,22.7328118 17.784179,22.7328118 L19.9320741,22.7328118 L19.9320741,29.4007055 C19.9320741,29.5818001 20.0823737,29.7284483 20.2679773,29.7284483 L23.0703694,29.7284483 C23.2559731,29.7284483 23.4062726,29.5816329 23.4062726,29.4007055 L23.4062726,22.7328118 L25.9176629,22.7328118 C26.1032666,22.7328118 26.2535661,22.5861636 26.2535661,22.405069 L26.2545944,19.7625591 C26.2545944,19.6756069 26.2191189,19.5923335 26.1562227,19.5307981 C26.0933266,19.4692627 26.007637,19.4346491 25.9185198,19.4346491 L23.4062726,19.4346491 L23.4062726,17.8885726 C23.4062726,17.145466 23.5877631,16.7682273 24.5798771,16.7682273 L26.018948,16.7677257 C26.2043803,16.7677257 26.3546798,16.6209103 26.3546798,16.4399829 L26.3546798,13.9862595 C26.3546798,13.8054993 26.2045517,13.6588512 26.0192908,13.6585167 Z" id="Symbol/FB" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
        </g>
      </svg>
  </span>
);

Facebook = pure(Facebook);
Facebook.displayName = 'Facebook';
Facebook.muiName = 'SvgIcon';

export default Facebook;
