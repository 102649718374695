import React, { Component } from 'react'

import cx from 'classnames';

const styles = {
  position: 'fixed',
  right: '40px',
  bottom: '40px',
  display: "flex",
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  zIndex: '999'
}

export class FloatingPopup extends Component {

  static defaultProps = {
    forceClose: false
  }

  constructor(props) {
    super(props);

    this.state = {
      isAnimating: false,
      isMounted: false,
      dimensions: {
        w: 0,
        h: 0
      }
    }
  }

  expand = () => {
    this.props.open();
  }

  close = () => {
    this.props.close();
  }

  outsider = ev => {
    if (!this._container) {
      window.removeEventListener("click", this.outsider);
      return;
    }

    if (this._container.contains(ev.target) || this._container === ev.target) {
      return;
    }

    this.close();
  }

  setRef = el => {
    this._element = el;
  }

  renderContents = () => {
    const { children, isOpen } = this.props;
    return children(isOpen, this.setRef, this.close);
  }

  componentDidMount() {
    this.computeDimensions();
  }

  componentWillUnmount() {
    if (this._container) {
      this._container.removeEventListener("transitionend", this.animate)
    }
    window.removeEventListener("click", this.outsider)
  }

  computeDimensions = () => {
    if (this._element) {
      const w = this._element.offsetWidth;
      const h = this._element.offsetHeight;
      this.setState({
        dimensions: {
          w,
          h
        }
      })
    }
  }

  animate = () => {
    this.setState({
      isAnimating: false
    })
    if (this.props.isOpen && !this.props.forceClose) {
      window.addEventListener('click', this.outsider)
    }
    this._container.removeEventListener("transitionend", this.animate)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.computeDimensions();
      this.setState({
        isAnimating: true,
        isMounted: true
      }, () => {
        if (!this.props.isOpen) {
          window.removeEventListener("click", this.outsider)
        }
      })
      this._container.addEventListener("transitionend", this.animate)
    }
  }

  prepareAnimatingStyles = isAnimating => {
    let styles = {};
    if (isAnimating) {
      styles.overflow = "hidden";
      //styles.transition = ".25s ease-in-out";
    }
    return styles;
  }

  render() {
    const { isOpen } = this.props;
    const { dimensions: { w, h }, isAnimating, isMounted } = this.state;
    const { style = {}, openedStyles, closedStyles, className = "", openedClass = "", closedClass = "", animatingClass = "" } = this.props;

    const animatingStyles = this.prepareAnimatingStyles(isAnimating);
    return (
        <div
          className={cx(className, isOpen ? openedClass : closedClass, isAnimating ? animatingClass : "")}
          ref={el => this._container = el}
          onClick={isOpen ? undefined : this.expand}
          style={{ ...styles, ...animatingStyles, ...(isOpen ? (openedStyles || {}) : (closedStyles || {})), transition: isMounted ? '0.25s ease-in-out' : "none", width: w || 0, height: h || 0, ...style }}
        >
            <div style={ isAnimating ? {visibility: 'hidden', opacity: 0} : {visibility: 'visible', opacity: 1, transition: '0.25s ease-out'}}>
              {
                this.renderContents()
              }
            </div>
        </div>
    )
  }
}

export default FloatingPopup
