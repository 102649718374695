import React from "react";

const computeMax = (val, max = 0) => Math.min((val || max), max);
const computeMin = (val, min = 0, max = 0) => Math.max((val > max || (val == undefined || val == null) ? min : val), min);

export function MinMaxInput({ val, min = 0, max, type = 'min', children, extraDef, bothRequired= false }) {
  const def = `isNumeric,${extraDef ? Object.keys(extraDef).join(',') + ',' : ''}`;
  let validations;
  let minMaxError;
  const extraProps = {};
  if (type === 'min') {
    const _max = computeMax(val, max);
    validations = `${def}min:${min},max:${_max}`;
    minMaxError = `${min} - ${_max}`;
  } else {
    const _min = computeMin(val, min, max);
    validations = `${def}min:${_min},max:${max}`;
    minMaxError = `${_min} - ${max}`;
  }

  if (bothRequired && val) {
    extraProps.required = true;
    extraProps.requiredError = "required";
  }

  const vErrors = {
    isNumeric: 'only numbers',
    min: minMaxError,
    max: minMaxError,
    ...(extraDef || {})
  }
  return (
    <div>
      {
        React.Children.map(children, child => React.cloneElement(child, {
          ...child.props,
          ...extraProps,
          validations,
          validationErrors: vErrors
        }))
      }
    </div>
  )
}
