import React from 'react';
import styled from 'styled-components';
import CustomTooltip from '../Common/CustomTooltip';
import { stopPropagation } from '../../core/utils';
import cx from 'classnames';
import { SeenBySmall } from './SeenBy';

const EmailActionButtons = styled(({ className, onReplyClick, onForwardClick, sentByClient, onUnreadClick, readBy, canReply, ...props }) =>  {
  function onReplyToAll(e)  {
    onReplyClick(e, true);
  }
  return (
    <div className={className} onClick={stopPropagation}>
      <CustomTooltip tooltip={"Reply"}><div className={cx((!canReply) && 'disabled')}><span className={cx("material-icons-outlined")} onClick={onReplyClick}>reply</span></div></CustomTooltip>
      <CustomTooltip tooltip={"Reply to all"}><div className={cx((!canReply) && 'disabled')}><span className={cx("material-icons-outlined")} onClick={onReplyToAll}>reply_all</span></div></CustomTooltip>
      <CustomTooltip horizontalAlign={'left'} tooltip={"Forward"}><div className={cx(!canReply && 'disabled')}><span className="material-icons" onClick={onForwardClick}>forward</span></div></CustomTooltip>
      {!sentByClient ? <CustomTooltip horizontalAlign={'left'} tooltip={"Mark as unread"}><div><span onClick={onUnreadClick} className="material-icons">mark_email_unread</span></div></CustomTooltip> : null}
      {readBy ? <CustomTooltip horizontalAlign={'left'} tooltip={"Seen by"}><div><SeenBySmall readBy={readBy} /></div></CustomTooltip> : null }
    </div>
  );
})`
  display: flex;
  justify-content: flex-end;
  > div > div {
    cursor: pointer;
    display: flex;
  }
  .disabled {
    opacity: 0.7;
    cursor: not-allowed;
    > * {
      pointer-events: none;
    }
  }
`;

export default EmailActionButtons;
