/** @name USER_STATS_REQUEST_REDUCER */
export default function USER_STATS_REQUEST(state, action) {
    const newState = { statistics: {
          ...state.statistics,
          loading: true
        } 
      };
    
    return { ...state, ...newState };
  }
