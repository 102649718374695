/** @name EMAILS_GET_EMAIL_REDUCER */
export default function EMAILS_GET_EMAIL(state, { res, payload }) {
  const newState = {};
  const details = { ...state.details, loading: false };
  details.store = { ...details.store };
  details.store[res.data.id] = res.data;
  details.selectedEmail = res.data;
  newState.details = details;
  return { ...state, ...newState };
}
