import React from 'react';
import s from "./CustomAutocomplete.scss";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";

class BasicItem extends React.Component {

  static defaultProps = {
    adjustRef: () => { }
  }

  determineKey = ev => {
    const key = ev.keyCode || ev.which;

    if (key === 13) {
      this.props.handleClick(ev, this.props.item);
    }
  }


  render() {
    const { item, handleClick, className = "" } = this.props;
    return (
      <li
        key={item._id}
        onKeyDown={(ev) =>
          ev.keyCode === 13 || ev.which === 13 ? handleClick(ev, item) : null
        }
        onClick={(ev) => {
          handleClick(ev, item);
        }}
        onKeyPress={this.determineKey}
        onFocus={ev => ev.stopPropagation()}
        tabIndex="2"
        ref={this.props.adjustRef}
        className={cx(s.item_wrapper, s.tag_item, className)}
      >
        {
          this.props.children
        }
      </li>
    );
  }
}

export default withStyles(s)(BasicItem);
