import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dialog from './Dialog';
import RaisedButton from '../Common/RaisedButton';
import ActionDone from 'material-ui/svg-icons/action/done';
import ErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import { connect } from 'react-redux';
import { toggleDialogMessage } from '../../actions/runtime';

const buttonsStyle = { textAlign: 'center', margin: '24px 0 16px 0' };

function MessageDialog(props) {
  let Icon;
  const message = props.message;
  switch (message.level) {
    case 'error':
      Icon = <ErrorOutline style={{ height: '44px', width: '44px', fill: '#FD3B53' }} />;
      break;
    default:
      Icon = <ActionDone style={{ height: '42px', width: '42px', border: '1px solid rgba(77, 158, 14, 0.2)', borderRadius: '50%', fill: '#75C422' }} />;
  }
  return (
    <Dialog
      modal={!message.dismissAble}
      contentStyle={{ maxWidth: '440px' }}
      open
      handleClose={() => {
        props.handleClose(false);

      }}
      header=" "
    >
      <div style={{ color: '#333333', lineHeight: '28px' }}>
        <div className="flex justify-content-center" style={{ padding: '16px 0' }}>
          {Icon}
        </div>
        <div className="flex justify-content-center" style={{ textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }} >{message.title}</div>
        <div className="flex justify-content-center prewrap" >
          {message.message}
        </div>
        <div className="row" style={buttonsStyle}>
          <div className="col-12 flex justify-content-center">
            <RaisedButton
              label="OK"
              buttonStyle={{borderRadius: '50px'}}
              disableTouchRipple
              disableFocusRipple
              primary
              onTouchTap={() => {
                props.handleClose(true);
              }}
              style={{ height: '50px', width: '210px', overflow: 'hidden', borderRadius: '50px' }}
              labelStyle={{ lineHeight: '50px', fontSize: '14px' }}
            />
            {message.dismissAble ?
              <RaisedButton
                label="CANCEL"
                style={{overflow: 'hidden', borderRadius: '50px'}}
                buttonStyle={{borderRadius: '50px'}}
                disableTouchRipple
                disableFocusRipple
                secondary
                onTouchTap={() => {
                  props.handleClose(false);
                }}
                style={{ height: '50px', width: '210px', marginLeft: '12px', overflow: 'hidden', borderRadius: '50px' }}
                labelStyle={{ lineHeight: '50px', fontSize: '14px' }}
              />
              : null}
          </div>
        </div>
      </div>

    </Dialog>
  );
}


class DialogWrapper extends PureComponent {
  handleClose = (decision) => {
    if (this.props.dialogMessage && this.props.dialogMessage.action) {
      this.props.dialogMessage.action.callback(decision);
    }
    this.props.toggleDialogMessage(null);
  };

  render() {
    if (!this.props.dialogMessage) {
      return null;
    }
    return (<MessageDialog message={this.props.dialogMessage} handleClose={this.handleClose} />);
  }
}

export default connect(state => (state.runtime), { toggleDialogMessage })(DialogWrapper);
