import produce from 'immer';
import type { IMessageDetails } from '../../components/Emails/types';
import {replaceFromWithAliases} from "../../components/Emails/utils";

/** @name EMAIL_SEND_REPLY_REDUCER */
 export default function EMAIL_SEND_REPLY(state, action) {
  const reply:IMessageDetails = action.res.data;
  replaceFromWithAliases(reply);
  const _reply = action.payload.reply;
  return produce(state, draft => {
    if (!reply.to) {
      reply.to = _reply.to;
    }
    if (!reply.from && _reply.replyGate) {
      reply.from = [_reply.replyGate.address];
    }
    reply.html = reply.html || _reply.content;
    reply.text = _reply.text;
    if (draft.details.selectedEmail?.id === action.payload.id) {
      draft.details.selectedEmail.lastUsedAlias = reply.from[0];
      let replyToRef = draft.details.selectedEmail.refNo;
      const replyTo = draft.details.selectedEmail.nestedMails.find(nm => nm.id === reply.replyTo);
      if (replyTo) {
        replyToRef = replyTo.refNo;
      }
      draft.details.selectedEmail.nestedMails.push({
        ...reply,
        replyToRef,
        read: true,
      });
    }
    const messageInList:IMessageDetails = draft.emailList.data.find( m => m.id === action.payload.id);
    if (messageInList) {
      //messageInList.repliesCounter = messageInList.repliesCounter || 1;
      //messageInList.repliesCounter += 1;
      //messageInList.subject = reply.subject;
      messageInList.text = _reply.text || reply.text;
      messageInList.hasUnread = false;
    }
  });
}
