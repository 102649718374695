import PropTypes from 'prop-types';
import React, { Children } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Layout.css';
import Header from '../Header';
import SubscribeDialog from '../Signup/SubscribeDialog';
import WaitingForApprovalDialog from '../Signup/WaitingForApprovalDialog';
import ConfirmEmailDialog from '../Signup/ConfirmEmailDialog';
import VerifyEmailBar from '../Page/VerifyEmailBar';

const routesConfig = {
  '': {
    leftBar: false,
    header: false,
  },
  '/': {
    leftBar: false,
    header: false,
  },
  register: {
    leftBar: false,
    header: false,
  },
  'forgot-password': {
    leftBar: false,
    header: false,
  },
  'confirm-email': {
    leftBar: false,
    header: true,
  },
  unsubscribe: {
    leftBar: false,
    header: true,
  },
  login: {
    leftBar: false,
    header: false,
  },
  default: {
    leftBar: true,
    header: false,
  },
};
function getRouteConfig(path) {
  path = path ? path.split('/')[1] : 'default';
  return routesConfig[path] || routesConfig.default;
}

function Layout({ children }, { path, isPublicPath, isUnLockedPathForUnverified, isUnLockedPathForUnapproved }) {
  const config = getRouteConfig(path);
  return (
    <div className={s.root}>
      <VerifyEmailBar />
      <div className={s.content}>
        {config.header === false
          ? null
          : <div className={s.content_header}><Header path={path} /></div>}
        <div className={s.content_child}>
          {Children.only(children)}
        </div>
      </div>
      <SubscribeDialog />
      <WaitingForApprovalDialog path={path} isPublicPath={isPublicPath} isUnLockedPathForUnapproved={isUnLockedPathForUnapproved} />
      <ConfirmEmailDialog path={path} isPublicPath={isPublicPath} isUnLockedPathForUnverified={isUnLockedPathForUnverified} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

Layout.contextTypes = {
  path: PropTypes.string, isPublicPath: PropTypes.bool, isUnLockedPathForUnverified: PropTypes.bool,
  isUnLockedPathForUnapproved: PropTypes.bool,
};

export default withStyles(s)(Layout);
