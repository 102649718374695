import produce from "immer";
import {sortContacts} from "./CONTACTS_CREATE_GROUP";

/** @name CONTACTS_TOGGLE_FAVORITES_REDUCER */
 export default function CONTACTS_TOGGLE_FAVORITES(state, { payload }) {
  return produce(state, draft => {
    draft.contactList.data = draft.contactList.data.map(ct => {
      if (payload.ids.indexOf(ct.id) !== -1) {
        return { ...ct, faved: payload.faved, selected: false };
      }
      return ct;
    });
    draft.contactList = sortContacts(draft.contactList);
  });
}
