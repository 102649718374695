import Sort from "../../Common/Sort";

export const sortFields = {
  added_date: {
    field: "added_date",
    value: -1,
    label: "Added Date"
  },
  dwt: {
    field: "dwt",
    value: 1,
    label: "DWT"
  },
  capacity: {
    field: "capacity",
    value: 1,
    label: "Capacity"
  }
};

export const defaultSortField = sortFields['added_date'];

class SortSnP extends Sort {
  static defaultProps = {
    sort: { field: 'added_date', value: -1, label: 'Added Date' },
    values: {
      added_date: { field: 'added_date', value: -1, label: 'Added Date' },
    },
  };
}
export default SortSnP;
