import React from 'react';
import pure from 'recompose/pure';

const _ContactsMessenger = props => (
  <span {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#285596"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.001 3.11108C6.89341 3.11108 2.9343 6.84442 2.9343 11.9058C2.91858 13.1299 3.16417 14.3434 3.65467 15.4651C4.14518 16.5868 4.86931 17.591 5.77875 18.4106C5.85223 18.4755 5.91172 18.5547 5.9536 18.6433C5.99547 18.732 6.01885 18.8282 6.0223 18.9262L6.07208 20.5404C6.07588 20.6592 6.10879 20.7751 6.16792 20.8782C6.22705 20.9812 6.31058 21.0682 6.41118 21.1314C6.51177 21.1946 6.62635 21.2321 6.74485 21.2407C6.86334 21.2492 6.98212 21.2285 7.09075 21.1804L8.88986 20.3875C9.0419 20.3194 9.21304 20.3068 9.37341 20.352C10.2289 20.587 11.112 20.7065 11.9992 20.7075C17.105 20.7075 21.0659 16.9653 21.0659 11.9129C21.0659 6.86042 17.1067 3.11108 12.001 3.11108Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.55518 14.4765L9.22185 10.2525C9.32172 10.0932 9.45355 9.95648 9.609 9.85082C9.76446 9.74516 9.94015 9.67292 10.125 9.63866C10.3098 9.6044 10.4997 9.60888 10.6827 9.65181C10.8657 9.69473 11.0378 9.77518 11.1881 9.88804L13.3072 11.4774C13.4007 11.55 13.5158 11.5895 13.6343 11.5895C13.7528 11.5895 13.8678 11.55 13.9614 11.4774L16.829 9.30493C16.9093 9.24388 17.0084 9.21297 17.1092 9.21762C17.21 9.22227 17.3059 9.26217 17.3802 9.33036C17.4545 9.39855 17.5025 9.49068 17.5158 9.59067C17.5291 9.69065 17.5069 9.79213 17.453 9.87738L14.7863 14.1032C14.6855 14.2615 14.5531 14.3972 14.3973 14.5019C14.2415 14.6065 14.0657 14.6778 13.881 14.7112C13.6963 14.7446 13.5067 14.7394 13.3241 14.6959C13.1415 14.6525 12.9699 14.5717 12.8201 14.4587L10.701 12.8694C10.6067 12.7984 10.4919 12.76 10.3739 12.76C10.2558 12.76 10.141 12.7984 10.0467 12.8694L7.18452 15.04C7.10493 15.1005 7.0068 15.1313 6.90696 15.1273C6.80712 15.1232 6.7118 15.0845 6.63736 15.0179C6.56292 14.9512 6.51401 14.8607 6.49901 14.762C6.48402 14.6632 6.50388 14.5622 6.55518 14.4765Z" fill="#285596"/>
    </svg>
  </span>
);

const ContactsMessenger = pure(_ContactsMessenger);
ContactsMessenger.displayName = 'ContactsMessenger';
ContactsMessenger.muiName = 'SvgIcon';

export default ContactsMessenger;
