import React from 'react'
import Formsy from 'formsy-react';
import { debounce } from 'formsy-material-ui/lib/utils';

export default class ExtendedForm extends Formsy.Form {
  constructor(props) {
    super(props);
  }
  validateForm = debounce(() => {
    super.validateForm();
  },this.props.delay || 500)
}


export class SubForm extends ExtendedForm {

  render(){
    const {
      mapping,
      validationErrors,
      onSubmit,
      onValid,
      onValidSubmit,
      onInvalid,
      onInvalidSubmit,
      onChange,
      reset,
      preventExternalInvalidation,
      onSuccess,
      onError,
    ...nonFormsyProps
    } = this.props;

    return (
      <span {...nonFormsyProps}>
        {this.props.children}
      </span>
    );
  }
}

