import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Telegram = props => (
  <span {...props}>
    <svg width="45px" height="44px" viewBox="0 0 45 44" version="1.1">

        <title>Telegram</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Web---Start"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Shipnext-1440" transform="translate(-1206.000000, -1754.000000)" fill="#FFFFFF">
            <g id="Group-5" transform="translate(951.000000, 1754.000000)">
                <g id="Group-3" transform="translate(87.000000, 0.000000)">
                    <g id="Icon/Social/Youtube" transform="translate(168.413793, 0.000000)">
                        <circle id="Oval-4-Copy-3" opacity="0.33350317" cx="22" cy="22" r="22"></circle>
                        <path d="M12.0240732,22.0706566 L16.2625547,23.6525913 L17.9031069,28.928589 C18.0080787,29.2664976 18.4212825,29.3913968 18.6955458,29.1671668 L21.058147,27.2411306 C21.3057994,27.0393419 21.6585464,27.0292862 21.9173583,27.2171563 L26.178649,30.3109424 C26.4720427,30.524197 26.8876991,30.3634283 26.9612775,30.0089032 L30.0829007,14.993338 C30.1632238,14.6060706 29.782701,14.2830003 29.4139508,14.4256197 L12.0191067,21.1360274 C11.5898383,21.3015787 11.5935785,21.909336 12.0240732,22.0706566 Z M17.6387153,22.810487 L25.9222897,17.7086248 C26.0711633,17.6172037 26.2243289,17.8185018 26.0964865,17.9370856 L19.2601371,24.2918033 C19.0198424,24.5154815 18.8648373,24.8148228 18.8209356,25.1397326 L18.5880601,26.865513 C18.5572185,27.0959359 18.233535,27.1188065 18.169951,26.8958028 L17.2743185,23.748795 C17.171738,23.3898552 17.3212247,23.0064507 17.6387153,22.810487 Z" id="XMLID_497_" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
        </g>
      </svg>
  </span>
);

Telegram = pure(Telegram);
Telegram.displayName = 'Telegram';
Telegram.muiName = 'SvgIcon';

export default Telegram;
