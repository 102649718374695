import React, { PureComponent } from 'react';
import s from '../Monitor.scss';
import Formsy from 'formsy-react';
import cx from 'classnames';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from '../../Common/RaisedButton';
import SpinnerButton from '../../Common/SpinnerButton';
import FormsyInput from '../../Common/FormsyInput';
import { dateRange, getDemmurageTermsOpts, getDemmurageTimeOpts } from '../../../core/format';
import { ReduxLessNewOffer, SingleFreight } from './NewOffer';
import { toUTC } from '../../../core/utils';
import CurrencySelect from '../../Common/CurrencySelect';
import MultiFreightEditNarrow from "./MultiFreight/MultiFreightEditNarrow";
import ChargesEditNarrow from "./Charges/ChargesEditNarrow";
import NarrowFormsyText from "../../NewInputs/NarrowFormsyText";
import NarrowSelect from "../../NewInputs/NarrowSelect";

const demmurageTimeOpts = getDemmurageTimeOpts();
const demmurageTermsOpts = getDemmurageTermsOpts();




class ChangeFreight extends ReduxLessNewOffer {


  handleSend = () => {
    this.refs.form.submit();
    if (!this.refs.form.state.isValid) {
      return;
    }
    const values = JSON.parse(JSON.stringify({ ...this.props.bid.trading.changedRequest, ...this.refs.form.getModel()}));
    if(values.chartererSubject?.value === 0){
      values.chartererSubject = {};
    }
    if(values.ownerSubject?.value === 0){
       values.ownerSubject = {};
    }
    delete values.loadingPort;
    delete values.unloadingPort;
    delete values.readinessDate;
    delete values.cancellingDate;
    if (!values.terms) {
      values.terms = {};
    }
    if (!values.terms.default) {
      values.terms.default = [];
    }
    if (!values.terms.user) {
      values.terms.user = [];
    }
    values.terms.default = values.terms.default
      .filter(t => t && t.checked !== undefined)
      .sort((a, b) => a.index - b.index);
    values.terms.user = values.terms.user.filter(t => !!t);
    const fullOffer = {};
    values.loading.forEach(lg => lg.oneOf.forEach((l) => {
      l.port = l.port?._id || l.port;
      l.readinessDate = toUTC(l.readinessDate);
      l.cancellingDate = toUTC(l.cancellingDate);
      l.rates = undefined;
    }));
    values.unloading.forEach(lg => lg.oneOf.forEach((l) => {
      l.port = l.port?._id || l.port;
      l.rates = undefined;
    }));
    fullOffer.changedRequest = values;
    delete fullOffer.changedRequest._id;
    fullOffer.cargoRequest = this.state.cargo._id;
    fullOffer.timeForReply = 24;
    delete values._freights;
    if (this.isMultiFreight) {
      fullOffer.freights = this.multiFreight.getValue();
      fullOffer.charges = this.charges.getValue();
      fullOffer.changedRequest.freight = fullOffer.freights[0];
      fullOffer.tradingType = 'shipment';
    }
    return this.props.handleSend(fullOffer);

  };

  componentDidUpdate(prevProps, prevState, prevContext) {
      this.reposition();
  }
  reposition = () => {
    window.dispatchEvent(new Event('resize'));
  }


  render() {
    return (<div className={s.wrapper_dialog_offer_freight_static}>
      <div>
        <div>
          <Formsy.Form
            noValidate
            ref="form"
            style={{
              marginBottom: '10px',
            }}
            onInvalidSubmit={this.handleInvalidSubmit}
          >
            <div className={s.wrapper_loading_discharging}>
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}>FREIGHT*</div>
                {this.isMultiFreight ? // remove when all clients will support multifreight
                  <div className={cx(s.right_table_offer_freight)}>
                    {this.state.mounted && <MultiFreightEditNarrow value={this.state.cargo.freights} innerRef={this.refMultiFreight} typeOfTransportation={this.tt} />}
                  </div>
                  :
                  (<SingleFreight previousValues={this.state.previousValues} />)
                }
              </div>
            </div>
            <div className={s.wrapper_loading_discharging}>
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}></div>
                <div className={cx(s.right_table_offer_freight)}>
                  {this.state.mounted && this.isMultiFreight && <ChargesEditNarrow value={this.state.cargo.charges} innerRef={this.refCharges} onRowsChange={this.reposition} />}
                </div>
              </div>
            </div>
            <div className={s.bottom_info}>
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}>DEMURRAGE</div>
                <div className={cx(s.right_table_offer_freight, s.wrapper_demurrage_detention)}>
                  <div className={cx("row", s.demurrage_detention_row)}>
                    <div>
                      <div>
                        <NarrowFormsyText
                          hintText="Demurrage"
                          validations="isNumeric"
                          validationErrors={{
                            isNumeric: 'only numbers',
                          }}
                          name="demurrage"
                          fullWidth
                          errorStyle={{ bottom: '7px' }}
                          previousValue={
                            this.state.previousValues.demurrage
                          }
                        />
                      </div>
                      <div>
                        <CurrencySelect
                          name="demurrageOpts[currency]"
                          className={s.currency_select}
                          previousValue={
                            this.state.previousValues[
                              'demurrageOpts[currency]'
                              ]
                          }
                          as={NarrowSelect}
                        />
                      </div>
                    </div>
                    <div>
                      <NarrowSelect
                        hintText=" "
                        requiredError="required"
                        name="demurrageOpts[timeUnit]"
                        fullWidth
                        autoWidth
                        required
                        defaultValue={'perDay'}
                        previousValue={
                          this.state.previousValues[
                            'demurrageOpts[timeUnit]'
                            ]
                        }
                      >
                        {demmurageTimeOpts.map(to => <MenuItem
                          key={to._id}
                          value={to._id}
                          primaryText={to.label}
                        /> )}
                      </NarrowSelect>
                    </div>
                    {!this.isWetBulk ? (
                      <div>
                        <NarrowSelect
                          hintText=" "
                          requiredError="required"
                          name="demurrageOpts[terms]"
                          fullWidth
                          autoWidth
                          defaultValue={''}
                          previousValue={
                            this.state.previousValues[
                              'demurrageOpts[terms]'
                              ]
                          }
                        >
                          {demmurageTermsOpts.map(to => <MenuItem
                            key={to._id}
                            value={to._id}
                            primaryText={to.label}
                          /> )}
                        </NarrowSelect>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {!this.isWetBulk ? (
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}>DETENTION</div>
                <div className={cx(s.right_table_offer_freight, s.wrapper_demurrage_detention)}>
                  <div className={cx("row", s.demurrage_detention_row)}>
                    <div>
                      <div>
                        <NarrowFormsyText
                          hintText="Detention"
                          validations="isNumeric"
                          validationErrors={{
                            isNumeric: 'only numbers',
                          }}
                          name="detention"
                          fullWidth
                          previousValue={
                            this.state.previousValues.detention
                          }
                          errorStyle={{ bottom: '7px' }}
                        />
                      </div>
                      <div>
                        <CurrencySelect
                          name="detentionOpts[currency]"
                          className={s.currency_select}
                          previousValue={
                            this.state.previousValues[
                              'detentionOpts[currency]'
                              ]
                          }
                          as={NarrowSelect}
                        />
                      </div>
                    </div>
                    <div>
                      <NarrowSelect
                        hintText=" "
                        requiredError="required"
                        name="detentionOpts[timeUnit]"
                        fullWidth
                        autoWidth
                        defaultValue={'perDay'}
                        previousValue={
                          this.state.previousValues[
                            'detentionOpts[timeUnit]'
                            ]
                        }
                      >
                        {demmurageTimeOpts.map(to => <MenuItem
                          key={to._id}
                          value={to._id}
                          primaryText={to.label}
                        /> )}
                      </NarrowSelect>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>)
                : null}
            </div>

            <div className={s.wrapper_buttons_offer}>
              <RaisedButton
                label="Cancel"
                disableTouchRipple
                disableFocusRipple
                secondary
                style={{
                  width: '68px',
                  height: '28px',
                  minWidth: '68px',
                  marginRight: '8px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '15px',
                  lineHeight: '28px',
                }}
                buttonStyle={{
                  height: '28px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '15px',
                  lineHeight: '28px',
                }}
                overlayStyle={{
                  borderRadius: '15px',
                }}
                labelStyle={{
                  padding: '0',
                  fontSize: '11px',
                  lineHeight: '28px',
                  color: '#999999',
                }}
                onClick={this.props.handleClose}
              />

              <SpinnerButton
                label="Send"
                disableTouchRipple
                disableFocusRipple
                onClick={this.handleSend}
                primary
                style={{
                  width: '68px',
                  height: '28px',
                  minWidth: '68px',
                  lineHeight: '28px',
                  borderRadius: '15px',
                }}
                buttonStyle={{
                  height: '28px',
                  borderRadius: '15px',
                  lineHeight: '28px',
                }}
                overlayStyle={{
                  borderRadius: '15px',
                }}
                labelStyle={{
                  padding: '0',
                  fontSize: '11px',
                  lineHeight: '28px',
                }}
              />
            </div>
          </Formsy.Form>
        </div>
      </div>
    </div>);
  }
}




const currencies = [{ value: 'USD', sign: '$' }, { value: 'EUR', sign: '€' }];

class CurrencyRadio extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.selectedCurrency = currencies[0];
  }

  setSelectedCurrency = (e) => {
    const selectedCurrency = currencies[parseInt(e.target.getAttribute('data-index'))];
    if (!selectedCurrency) {
      return;
    }
    this.setState({ selectedCurrency });
  };
  onSetValue = (value) => {
    const selectedCurrency = currencies.find(c => c.value === value);
    if (selectedCurrency && selectedCurrency !== this.state.selectedCurrency) {
      this.setState({ selectedCurrency });
    }
  }


  render() {
    return (<div onClick={this.setSelectedCurrency} className={s.currency_radio}>
      {currencies.map((c, i) => <div
        className={this.state.selectedCurrency.value === c.value && s.selected}
        data-value={c.value} data-index={i}
      >{c.sign}</div>)}
      <span><FormsyInput
        name={this.props.name} onSetValue={this.onSetValue} type="hidden"
        value={this.state.selectedCurrency.value}
      /></span>
    </div>);
  }
}

export default ChangeFreight;
