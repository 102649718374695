/* @flow */
import React, { PureComponent } from 'react';
import type { Freight, TradeAble } from '../../../../interfaces/types';
import { v4 } from 'uuid';
import s from '../../Monitor.scss';
import FormsyText from '../../../Common/FormsyText';
import CurrencySelect from '../../../Common/CurrencySelect';
import FormsySelect from '../../../Common/Select';
import MenuItem from 'material-ui/MenuItem';
import styled from 'styled-components';
import { ContentAddButton, ContentClearButton } from '../../../Common/AddButton';

type Props = {
  value: TradeAble<Freight>[];
  className: string;
  onChange: () => TradeAble<Freight>[];
  refMultiFreight: () => any;
}
type State = {
  value: TradeAble<Freight>[];
}

export class MultiFreightEdit extends PureComponent<Props, State> {

  constructor(props, context) {
    super(props, context);
    if (props.value?.length) {
      this.state.value = props.value.filter(v => v.curr);
    }
    this.methodEnabled = this.props.typeOfTransportation !== 'wetbulk';
    if(!this.state.value?.length){
      this.state.value = [this.getNewRow()];
    }
  }
   static RowComponent = FreightRow;
  state = { };

  getNewRow() {
    return { key: Symbol(v4()), curr: { term: 'per mt', method: this.methodEnabled ? 'fios' : undefined, currency: 'USD' } };
  }

  componentDidMount() {
    if(this.props.innerRef) {
      this.props.innerRef(this);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value && nextProps.value !== this.state.value) {
      let newVal;
      if (nextProps.value?.length) {
        newVal = nextProps.value.filter(v => v.curr);
      }
      if (!newVal?.length) {
        newVal = [this.getNewRow()];
      }
      this.setState({ value: newVal });
    }
  }


  addRow = () => {
    this.setState({ value: [...this.state.value, this.getNewRow()] });
    if (this.props.onRowsChange) {
      this.props.onRowsChange();
    }
  }

  deleteRow = (i) => {
    this.changeRow(i, 'deleted', null, true);
    if (this.props.onRowsChange) {
      this.props.onRowsChange();
    }
  }
  changeRow = (i, name, e, val) => {
    const value = [...this.state.value];
    const freight = { ...value[i] };
    freight.curr[name] = val;
    value[i] = freight;
    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
  }
  getValue = () => {
    return this.state.value.map((f) => {
      f = { ...f, curr: { ...f.curr } };
      if (f.curr.deleted) {
        f.curr.value = undefined;
      }
      const deleted = f.currVersion && !f.curr.value;
      if (deleted) {
        return {
          key: f.key,
          version: f.currVersion,
          deleted: true,
        };
      }
      return ({
        ...f.curr,
        key: f.key,
        version: f.currVersion,
        deleted: undefined,
      });
    }).filter(f => f.value || f.deleted);
  }

  render() {

    const Row = this.constructor.RowComponent;
    return (
      <div className={this.props.className}>
        {this.state.value.map((f, i) => {
          if(!f.curr || f.curr.deleted) {
            return null;
          }
          return <Row item={f} key={f.key.toString()} i={i} onChange={this.changeRow.bind(this, i)}
                             onDelete={this.deleteRow.bind(this, i)} onAdd={this.addRow} methodEnabled={this.methodEnabled} />;
        })}
      </div>
    );
  }
}


function FreightRow({ item: { curr, prev = {} }, i, onChange, onDelete, onAdd }) {
 prev = prev || {};
 curr = curr || {};
  return (<div className={'freight_row'}>
    <div>
      <FormsyText
        hintText="Freight*"
        validations="isNumeric,gt0"
        validationErrors={{
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        }}
        requiredError="required"
        name={`_freights[${i}][value]`}
        required
        fullWidth
        previousValue={prev.value}
        value={curr.value}
        onBlur={onChange.bind(this, 'value')}
      />
    </div>
    <CurrencySelect
      name={`_freights[${i}][currency]`}
      className={s.currency_select}
      previousValue={prev.currency}
      value={curr.currency}
      onChange={onChange.bind(this,'currency')}
    />
    <div>
      <FormsySelect
        hintText=" "
        requiredError="required"
        name={`_freights[${i}][term]`}
        fullWidth
        autoWidth
        required
        defaultValue={'per mt'}
        previousValue={prev.term}
        value={curr.term}
        onChange={onChange.bind(this,'term')}
      >
        <MenuItem
          key="per mt"
          value="per mt"
          primaryText="per mt"
        />
        <MenuItem
          key="per cbm"
          value="per cbm"
          primaryText="per cbm"
        />
        <MenuItem
          key="lump sum"
          value="lump sum"
          primaryText="lump sum"
        />
        <MenuItem
          key="per w/m"
          value="per w/m"
          primaryText="per w/m"
        />
        <MenuItem
          key="per unit"
          value="per unit"
          primaryText="per unit"
        />
        <MenuItem
          key="per box"
          value="per box"
          primaryText="per box"
        />
        <MenuItem
          key="per TEU"
          value="per TEU"
          primaryText="per TEU"
        />
        <MenuItem
          key="per FEU"
          value="per FEU"
          primaryText="per FEU"
        />
      </FormsySelect>
    </div>
    <div>
      <FormsySelect
        hintText=" "
        requiredError="required"
        name={`_freights[${i}][method]`}
        fullWidth
        autoWidth
        required
        defaultValue={'fios'}
        previousValue={prev.method}
        value={curr.method}
        onChange={onChange.bind(this,'method')}
      >
        <MenuItem
          key="fios"
          value="fios"
          primaryText="FIOS"
        />
        <MenuItem
          key="filo"
          value="filo"
          primaryText="FILO"
        />
        <MenuItem
          key="lifo"
          value="lifo"
          primaryText="LIFO"
        />
        <MenuItem
          key="lilo"
          value="lilo"
          primaryText="LILO"
        />
      </FormsySelect>
    </div>
    <div>
      <FormsyText
        hintText="Details"
        name={`_freights[${i}][details]`}
        fullWidth
        previousValue={prev.details}
        value={curr.details}
        onBlur={onChange.bind(this,'details')}
      />
    </div>
    <div>
      {i === 0 ?
        <ContentAddButton onClick={onAdd} />
        :
        <ContentClearButton onClick={onDelete} />
      }
    </div>
  </div>);
}

export default styled(MultiFreightEdit)`
  width: 100%;

  .freight_row {
    display: flex;

    > div + div {
      margin-left: 12px;
    }

    > div:last-child {
      margin-top: 14px;
    }

    > div:nth-child(1) {
      width: 131px;
    }

    > div:nth-child(2) {
      width: 60px;
    }

    > div:nth-child(3) {
      width: 110px;
    }

    > div:nth-child(4) {
      width: 109px;
    }

    > div:nth-child(5) {
      width: 224px;
    }

    .select >div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
      padding-right: 20px !important;
    }
  }
`;

