import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import RaisedButton from '../../Common/RaisedButton';
import { User } from '../../../core/api/api';
import { getFractional, isUpgradePlan, redirectToCheckout } from '../../../core/utils';
import plansDetails from '../../Settings/plans';
import Partners from '../Partners';
import Formsy from 'formsy-react';
import SelectionPlan from '../../Settings/SelectionPlan';
import Media from 'react-media';
import SwipeableViews from 'react-swipeable-views';
import { Tab, Tabs } from 'material-ui/Tabs';
import { connect } from 'react-redux';
import SpinnerButton from '../../Common/SpinnerButton';
import history from '../../../core/history';
import ConfirmDowngradeDialog from '../../Settings/ConfirmDowngradeDialog';
import { toggleDialogMessage } from '../../../actions/runtime';
import ConfirmEmailDialog from '../../Signup/ConfirmEmailDialog';
import { getProfile } from '../../../actions/login';
import waitForEvent from '../../../core/waitForEvent';

const Form = Formsy.Form;
const styleTabs={
  background: 'transparent',
  border: 'none',
  width: '8px',
  height: '8px',
  marginRight: '4px',
  marginLeft: '4px',
}

function ChoosePlanMobile({ selectedCycle, handleChangeCycle, plans, user, currentPlan, handlePlanSelected, scrollToFeatures, slideIndex, handleChangeSlide }) {

  return <div>
    <div className={s.billing_cycle_selector}>
      <div className={cx(selectedCycle === 'month' && s.selected)}
           onClick={handleChangeCycle.bind(this, 'month')}>Monthly
      </div>
      <div className={cx(selectedCycle === 'year' && s.selected)}
           onClick={handleChangeCycle.bind(this, 'year')}>Yearly
      </div>
    </div>
    <div className={cx(s.plan_list)}>
      <SwipeableViews
        index={slideIndex}
        onChangeIndex={handleChangeSlide}
      >
        {plans[selectedCycle].map(plan => (<div key={plan.id} className={s.part_plan}>
          <PricingPlan handleSelected={handlePlanSelected} plan={plan} user={user}
                       stripePlan={currentPlan?.stripePlan} scrollToFeatures={scrollToFeatures} />
        </div>))}
        <div className={s.part_plan}>
          <article>
            <div className={s.wrapper_title_plan}>
              <h2>SHIPNEXT Monitor</h2>
              <p>Customized solution for Cargo tracking and Supply Chain Management</p>
            </div>
            <div className={s.wrapper_body_plan}>
              <div className={s.top_body_plan}>
                <p className={cx(s.save_info, s.personal)}>&nbsp;</p>
                <p className={cx(s.wrapper_price, s.on_demand)}>on demand</p>
                <p className={s.billed}>&nbsp;</p>
                <RaisedButton
                  style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    height: '48px',
                    marginBottom: '40px',
                    borderRadius: '100px',
                  }}
                  buttonStyle={{
                    backgroundColor: '#1684DD',
                    borderRadius: '100px',
                  }}
                  labelStyle={{
                    padding: '0',
                    fontFamily: 'Montserrat',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '48px',
                    textTransform: 'capitalize',
                    color: '#fff',
                  }}
                  primary
                  label={<a href="mailto:support@shipnext.com">Contact Sales</a>}
                />
              </div>
              <div className={s.bottom_body_plan}>
                <ul className={s.wrapper_features_shot}>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>24/7 Shipping Marketplace</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Instant Cargo-to-Ship or Ship-to-Cargo matching</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Import of open Ship positions and Cargo/Freight Requests</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Cargo tracking</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Ship tracking</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Supply Chain management</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Door-to-door supply-chain integration</span>
                  </li>
                </ul>
                <a className={s.link_on_features} onClick={scrollToFeatures}>See all features </a>
              </div>

            </div>
          </article>
        </div>
      </SwipeableViews>
    </div>
    <Tabs
      onChange={handleChangeSlide}
      value={slideIndex}
      className={s.wrapper_bullets}
    >
      <Tab style={styleTabs} value={0} className={slideIndex === 0 && s.active_bullet}/>
      <Tab style={styleTabs} value={1} className={slideIndex === 1 && s.active_bullet}/>
      <Tab style={styleTabs} value={2} className={slideIndex === 2 && s.active_bullet}/>
      <Tab style={styleTabs} value={3} className={slideIndex === 3 && s.active_bullet}/>
    </Tabs>
  </div>
}

function ChoosePlanDesktop({ selectedCycle, handleChangeCycle, plans, user, currentPlan, handlePlanSelected, scrollToFeatures }) {
  return(
    <div>
      <div className={s.billing_cycle_selector}>
        <div className={cx(selectedCycle === 'month' && s.selected)} onClick={handleChangeCycle.bind(this, 'month')} >Monthly</div>
        <div className={cx(selectedCycle === 'year' && s.selected)} onClick={handleChangeCycle.bind(this, 'year')} >Yearly</div>
      </div>
      <ul className={cx(s.plan_list)} >
        {plans[selectedCycle].map(plan => (<li key={plan.id} className={s.part_plan}>
          <PricingPlan handleSelected={handlePlanSelected} plan={plan} scrollToFeatures={scrollToFeatures} user={user} stripePlan={currentPlan?.stripePlan} />
        </li>)) }
        <li className={s.part_plan}>
          <article>
            <div className={s.wrapper_title_plan}>
              <h2>SHIPNEXT Monitor</h2>
              <p>Customized solution for Cargo tracking and Supply Chain Management</p>
            </div>
            <div className={s.wrapper_body_plan}>
              <div className={s.top_body_plan}>
                <p className={cx(s.save_info, s.personal)}>&nbsp;</p>
                <p className={cx(s.wrapper_price, s.on_demand)}>on demand</p>
                <p className={s.billed}>&nbsp;</p>
                <RaisedButton
                  style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    height: '48px',
                    marginBottom: '40px',
                    borderRadius: '100px'
                  }}
                  buttonStyle={{
                    backgroundColor: '#1684DD',
                    borderRadius: '100px',
                  }}
                  labelStyle={{
                    padding: '0',
                    fontFamily: 'Montserrat',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '48px',
                    textTransform: 'capitalize',
                    color: '#fff',
                  }}
                  primary
                  label={<a href="mailto:support@shipnext.com">Contact Sales</a>}
                />
              </div>
              <div className={s.bottom_body_plan}>
                <ul className={s.wrapper_features_shot}>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>24/7 Shipping Marketplace</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Instant Cargo-to-Ship or Ship-to-Cargo matching</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Import of open Ship positions and Cargo/Freight Requests</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Cargo tracking</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Ship tracking</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Supply Chain management</span>
                  </li>
                  <li>
                    <span className={s.icon_check}></span>
                    <span>Door-to-door supply-chain integration</span>
                  </li>
                </ul>
                <a className={s.link_on_features} onClick={scrollToFeatures}>See all features </a>
              </div>

            </div>
          </article>
        </li>
      </ul>
    </div>
  );
}

class ChoosePlan extends PureComponent{
  state = {
    slideIndex: 0,
  };

  static propTypes = {
    selectedCycle:PropTypes.string,
    handleChangeCycle: PropTypes.func,
    plans:PropTypes.array,
    user: PropTypes.object,
    currentPlan:PropTypes.object,
    handlePlanSelected: PropTypes.func,
    scrollToFeatures: PropTypes.func,
  }

  handleChangeSlide = (value) => {
    this.setState({
      slideIndex: value,
    });
  };

  render(){
    const {selectedCycle, handleChangeCycle, plans, user, currentPlan, handlePlanSelected, scrollToFeatures } = this.props;
   return <Media query="(max-width: 767px)" defaultMatches={false}>
      {matches =>
        matches ? (
            <ChoosePlanMobile
            user={user}
            selectedCycle={selectedCycle}
            scrollToFeatures={scrollToFeatures}
            handlePlanSelected={handlePlanSelected}
            handleChangeCycle={handleChangeCycle}
            currentPlan={currentPlan}
            plans={plans}
            handleChangeSlide={this.handleChangeSlide}
            slideIndex={this.state.slideIndex}
            />
          )
          :
          (
            <ChoosePlanDesktop
              user={user}
              selectedCycle={selectedCycle}
              scrollToFeatures={scrollToFeatures}
              handlePlanSelected={handlePlanSelected}
              handleChangeCycle={handleChangeCycle}
              currentPlan={currentPlan}
              plans={plans}
            />
          )
      }
    </Media>
  }
}

class PagePricing extends Component {
  state = {
    selectedCycle: 'year',
   };
  constructor(props) {
    super(props);
    this.state.plans = this.props.plans;
    if(this.props.user) {
      this.state.currentPlan = User.getPlanByProducts(props.user.activeProducts);
    }
  }


  componentDidMount = async () =>  {
    if(this.props.user && this.state.currentPlan?.key !== 'free') {
      this.setState({currentPlan: (await User.getCurrentPlan(this.props.user))});
    }

  }

  handleChangeCycle = (newCycle) => {
    this.setState({ selectedCycle : newCycle })
  };

  handleDowngrade = async (downgrade, newPlan) => {
    if(!downgrade) {
      this.setState({ confirmDowngradeDialogOpened: false, newPlan: null});
      return;
    }
    return await this.handleChangePlan(newPlan.stripePlan.id)
  }

  handleChangePlan = async (planId) => {
    try {
      const res = await User.changeSubscription(planId);
      if (res.status === 200) {
        if (res.data.redirectToPayment) {
          await redirectToCheckout(res.data);
        } else {
          await waitForEvent('user:updateProfile', 3000);
          window.location = '/settings/subscription';
        }
      } else {
        throw res;
      }
    } catch (e) {
      console.error(e);
      this.props.toggleDialogMessage({ level: 'error', message: e.message?.errors?.[0]?.messages?.[0] || 'Sorry error occurred. please try again later.', dismissAble: false});
    }
  };
  handlePlanSelected = (newStripePlan) => {
    const { user } = this.props;
    const currentPlan = this.state.currentPlan;
    if(!user) {
      history.push('/register');
    }
    if(!user.emailVerified) {
      return this.setState({ upgradeButtonClicked: true });
    }
    const newPlan= { ...plansDetails[newStripePlan.key], stripePlan: newStripePlan };
    if(currentPlan.key === newPlan.key || isUpgradePlan(currentPlan.stripePlan, newPlan.stripePlan)) {
      return this.handleChangePlan(newPlan.stripePlan.id);
    }
    this.setState({ confirmDowngradeDialogOpened: true, newPlan})
  }


  scrollToFeatures = () => {
    document.getElementById('features').scrollIntoView({behavior: 'smooth'})
  };
  toggleBillingCycle = (e,isYear) =>{
    this.handleChangeCycle(isYear ? 'year' : 'month');
  };

  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_pricing_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Pricing</h1>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className={s.wrapper_subscription_plan}>
          <div className={s.container}>
            <h2 className={s.title_plan}>Choose your subscription plan</h2>
             <ChoosePlan
               plans={this.props.plans}
               currentPlan={this.state.currentPlan}
               handleChangeCycle={this.handleChangeCycle}
               handlePlanSelected={this.handlePlanSelected}
               scrollToFeatures={this.scrollToFeatures}
               selectedCycle={this.state.selectedCycle}
               user={this.props.user} />
             </div>
        </div>

        <div className={s.wrapper_compare_pricing} id='features'>
          <div className={s.container}>
            <h2 className={s.title_general_pricing}>Compare our pricing plans</h2>
            <SelectionPlan plansSelected={this.state.plans[this.state.selectedCycle]} toggled={this.state.selectedCycle === 'year'} onToggle={this.toggleBillingCycle} handleChangePlan={this.handlePlanSelected} user={this.props.user} currentPlan={this.state.currentPlan}  />
          </div>
        </div>

        {/* <div className={s.wrapper_subscription_faqs}>
          <div className={s.container}>
            <h2 className={s.title_general_pricing}>Subscription FAQs</h2>
            <div className={s.wrapper_questions_list}>
              <div className={s.left_part}>
                <div className={s.question}>
                  <h3>1. How does SHIPNEXT’s pricing work?</h3>
                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                </div>
                <div className={s.question}>
                  <h3>2. How secure is SHIPNEXT?</h3>
                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                </div>
                <div className={s.question}>
                  <h3>3. Should I upgrade my plan?</h3>
                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                </div>
              </div>
              <div className={s.right_part}>
                <div className={s.question}>
                  <h3>4. Will I be charged sales tax?</h3>
                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                </div>
                <div className={s.question}>
                  <h3>5. What accepted forms of payment?</h3>
                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                </div>
                <div className={s.question}>
                  <h3>6. What is your refund policy?</h3>
                  <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className={s.wrapper_partners}>
          <div className={s.container}>
            <h2 className={s.title_general_pricing}>You're in good company</h2>
            <div className={s.wrapper_partners_block}><Partners /></div>
          </div>
        </div>
        {this.state.confirmDowngradeDialogOpened ?
          <ConfirmDowngradeDialog currentPlan={this.state.currentPlan} selectedPlan={this.state.newPlan} handleClose={this.handleDowngrade} />
          : null}
        {this.props.user && this.state.upgradeButtonClicked && <ConfirmEmailDialog path={'/main-deck/general'} /> }
      </div>
    );
  }
}


class PricingPlan extends Component {

  handleSelected = () => {
    this.props.handleSelected(this.props.plan);
  };

  render() {
    const { user,plan, stripePlan, scrollToFeatures } = this.props;
    const planDetail = plansDetails[plan.key];
    const _isUpgrade = isUpgradePlan(stripePlan, plan);
    const isCurrentPlan = stripePlan?.planId === plan.id;
    let changeTitle =  _isUpgrade ? 'Upgrade' : 'Change' ;
    if(!user) {
      changeTitle = "Proceed";
    }
    return (
      <article className={cx(plan.isRecommended && s.recommended_plan)} >
        <div className={s.wrapper_title_plan}>
          <h2>{planDetail.title}</h2>
          <p>{planDetail.shortDescription}</p>
        </div>
        <div className={s.wrapper_body_plan}>
          <div className={s.top_body_plan}>
            <p className={s.save_info}>

              {plan.saving > 0 ? <span> Save ${plan.saving} a year</span> : <span>&nbsp;</span>}
            </p>
            <p className={s.wrapper_price}>
              <span className={s.currency}>$</span>
              <span className={s.figures}>{parseInt(plan.realAmount === 1000 ? 999 : plan.realAmount)}</span>
              <span className={s.fractional}>{getFractional(plan.realAmount === 1000 ? 999 : plan.realAmount)}</span>
              <span className={s.units}>/ {plan.perShort}</span>
            </p>
            {plan.interval === 'year' ?
              <p className={s.billed}>Save $ {plan.moSaving} per month</p>
              : <p className={s.billed}>&nbsp;</p>}

            {isCurrentPlan ?
              <RaisedButton
                style={{
                  minWidth: '100%',
                  maxWidth: '100%',
                  height: '48px',
                  marginBottom: '40px',
                  borderRadius: '100px',
                }}
                buttonStyle={{
                  backgroundColor: '#fff',
                  border: '1px solid #e6e6e6',
                  borderRadius: '100px',
                }}
                labelStyle={{
                  padding: '0',
                  fontFamily: 'Montserrat',
                  fontSize: '15px',
                  fontWeight: '600',
                  lineHeight: '48px',
                  textTransform: 'capitalize',
                  color: '#e6e6e6',
                }}
                primary
                label={'Your current plan'}
              />
              :
              (
                <SpinnerButton /*TODO standardize buttons*/
                  style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    height: '48px',
                    marginBottom: '40px'
                  }}
                  buttonStyle={{
                    backgroundColor: '#1684DD',
                    borderRadius: '100px',
                  }}
                  labelStyle={{
                    padding: '0',
                    fontFamily: 'Montserrat',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '48px',
                    textTransform: 'capitalize',
                    color: '#fff',
                  }}
                  primary
                  label={changeTitle}
                  onClick={this.handleSelected}
                />
              )
            }

          </div>
          <div className={s.bottom_body_plan}>
            <ul className={s.wrapper_features_shot}>
              {planDetail.featuresShort.map((feature, i) => (<li key={i}>
                <span className={s.icon_check}></span>
                <span>{feature}</span>
              </li>))}
            </ul>
            <a className={s.link_on_features} onClick={scrollToFeatures}>See all features </a>
          </div>
        </div>
      </article>
    )
  }
}


export default withStyles(s)(connect(state => ({ user:state.login.user, plans: state.runtime.plans}), {toggleDialogMessage, getProfile})(PagePricing));
