import ContentAdd from 'material-ui/svg-icons/content/add';
import React from 'react'
import styled from 'styled-components';

const Add = styled(ContentAdd)`
    fill: var(--main-color) !important;
    border-radius: 50%;
    border: 1px solid var(--main-color);
    background: transparent;
    width: 24px !important;
    padding: 2px;
    height: 24px !important;
    overflow: hidden;
    margin-right: 10px !important;
    cursor: pointer;
    transition: 0.2s ease-out;
    &:hover {
      background-color: var(--main-color);
      fill: #fff !important;
    }
`;

const Label = styled.span`
  color: var(--main-color);
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
`;

function AddIcon({ label = "", ...props }) {
  return (
    <div className="align-items-center">
      <Add {...props} />
      {
        label && <Label>{label}</Label>
      }
    </div>
  )
}

export default AddIcon
