import {
  SET_RUNTIME_VARIABLE,
  NAVIGATE,
  DICTIONARY,
  MESSAGE,
  ADD_TOP_MESSAGE,
  REMOVE_TOP_MESSAGE,
} from '../constants';
import history from '../core/history';
import React from 'react';
import { number } from '../core/format';

export default function runtime(state = {
  landing: {},
  topMessages: [],
  voyageTerms: [],
  timeTerms: [],
  ldRates: {},
  organizations: [],
  surchargeTypes: [],
  sectors: [],
  contracts: [],
  cargoTypes: [],
  countries: [],
}, action) {
  switch (action.type) {
    case SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case DICTIONARY:
      return {
        ...state,
        ...action.payload,
      };
    case NAVIGATE:
      if (action.payload.replace) {
        history.replace(action.payload.route);
      } else {
        history.push(action.payload.route);
      }
      return state;
    case MESSAGE:
      return { ...state, message: action.payload };
    case 'MONITOR_BID_NEW': {
      let text = '';
      try {
        text = `cargo ref ${action.payload.cargoRequest.refNo} \n vessel ${action.payload.vesselRequest.name} \n freight $${number(action.payload.freight.value)} ${action.payload.freight.term}`;
      } catch (e) {
        console.error(e);
      }
      let title;
      let level = 'success';
      switch (action.payload.status.status || action.payload.status.name) {
        case 'offer':
          title = 'New offer';
          break;
        case 'bid':
          title = 'Counter offer';
          break;
        case 'fixed':
        case 'fix':
          title = 'Offer fixed';
          break;
        case 'rejected':
        case 'reject':
          title = 'Offer rejected';
          level = 'error';
          break;
        default:
          return state;
      }
       let basePath = '/main-deck/general/';

      const message = {
        title,
        level,
        message: text,
        uid: action.payload.messageId,
        children: <NotificationLink href={`${basePath}${action.payload.cargoRequest._id}/${action.payload.vesselRequest.vessel || ''}/`} />,
        /*action: {
          label: 'view',
          callback() {
            history.push(`${basePath}${action.payload.cargoRequest._id}/${action.payload.vesselRequest.vessel || ''}/`);
          },
        },*/
        autoDismiss: !!__DEV__,
      };
      return { ...state, message };
    }

    case 'VESSEL_TC_NEW': {
      let text = '';
      try {
        text = `vessel ${action.payload.name} \n hire ${number(action.payload.hirePerDay.value)} ${action.payload.hirePerDay.currency}`;
      } catch (e) {
        console.error(e);
      }
      let title;
      let level = 'success';
      switch (action.payload.status) {
        case 'offer':
          title = 'New T/C Offer';
          break;
        case 'bid':
          title = 'T/C  Counter offer';
          break;
        case 'fix':
          title = 'T/C  Offer fixed';
          break;
        case 'reject':
          title = 'T/C  Offer rejected';
          level = 'error';
          break;
        default:
          return state;
      }
      const message = {
        title,
        level,
        message: text,
        uid: action.payload.messageId,
        children: <NotificationLink href={'/fleetmonitor/allfleet/tc/' + action.payload._id} />,
        autoDismiss: false,
      };
      return { ...state, message };
    }
    case 'CONTRACT_TC_SUBJECT_UPDATED':
      action.payload.type = 'tc';
// eslint-disable-next-line no-fallthrough
    case 'CONTRACT_VOYAGE_SUBJECT_UPDATED': {
      const text = '';
      let title;
      let level = 'success';
      const contractType = action.payload.type || 'voyage';
      switch (action.payload.action) {
        case 'fix':
          title = 'Counterpart fixed subjects';
          break;
        case 'reject':
          title = 'Counterpart rejected subjects';
          level = 'error';
          break;
        case 'extend':
          title = 'Time for subjects extended';
          break;
        default:
          return state;
      }
      const message = {
        title,
        level,
        message: text,
        children: <NotificationLink href={`/contracts/${contractType}/${action.payload._id}`} />,
        /*action: {
          label: 'view',
          callback() {
            history.push(`/contracts/${contractType}/${action.payload._id}`);
          },
        },*/
        autoDismiss: false,
      };
      return { ...state, message };
    }
    case 'VESSEL_NEW_FREIGHT_REQUEST': {
      const text = `M/V ${action.payload.name}`;
      const title = 'New Freight Request';
      const level = 'success';
      const message = {
        title,
        level,
        message: text,
        children: <NotificationLink href={`/main-deck/general/${action.payload.cargoRequest._id}/${action.payload.vessel}`} />,
        /*action: {
          label: 'view',
          callback() {
            history.push(`/main-deck/general/${action.payload.cargoRequest._id}/${action.payload.vessel}`);
          },
        },*/
        autoDismiss: false,
      };
      return { ...state, message };
    }
    case 'KYC_STATUS_UPDATED': {
      const status = action.payload.status;
      const statusLevel = status === 'approved' ? 'success' : status === 'clarification' ? 'warning' : (status === 'rejected' || status ==='declined') ? 'error' : 'info';
      const text = <div>KYC status for <b>{action.payload.department?.name}</b> updated to <span style={{textTransform:'capitalize'}}>{status}</span>. Please move to the compliance section for more details.</div>
      const level = statusLevel;
      const message={
        level,
        message:text,
        children:<NotificationLink level={level} href={`/settings/profile?accountId=${action.payload.department?._id}&tab=compliance`} />,
        autoDismiss:false,
      }
      return { ...state, message };
    }
    case 'INVOICE_INVITED':{
      const text = `You have been invited as a participant (${action.payload.role}) in Invoice`;
      const level = 'info';
      const message = {
        level,
        message:text,
        children:<NotificationLink level={level} href={`/contracts/invoice/${action.payload.invoiceId}`} />,
        autoDismiss:false,
      }
      return { ...state, message };
    }
    case 'INVOICE_UPDATED':{
      const text = `Transaction status Ref No ${action.payload.refNo} has been updated to ${action.payload.freightStatus}`;
      const level = 'info';
      const message = {
        level,
        message:text,
        children: <NotificationLink level={level} href={`/contracts/invoice/${action.payload.invoiceId}`} />,
        autoDismiss:false,
      }
      return { ...state, message };
    }
    case 'LOADING':
      return { ...state, loading: action.payload };
    case 'STATIC_POPUP':
      return {
        ...state,
        staticPopup: action.payload ||
          (action.res && action.res.data && (action.res.data.content)),
      };
    case 'MONITOR_SOCKET_CONNECTED':
      return {
        ...state,
        socketConnected: !!action.payload,
      };
    case 'DIALOG_MESSAGE':
      return {
        ...state,
        dialogMessage: action.payload,
      };
    case 'BL_GENERIC_UPDATE': {
      const text = action.payload.message;
      const title = 'B/L Updated';
      const level = 'success';
      if (!text || action.currentUser._id === action.payload.initiator) {
        return state;
      }
      const message = {
        title,
        level,
        message: text,
        children: <NotificationLink href={`/contracts/bill-of-lading/${action.payload.id}`} />,
        /*action: {
          label: 'View B/L',
          callback() {
            history.push(`/contracts/bill-of-lading/${action.payload.id}`);
          },
        },*/
        autoDismiss: false,
      };
      return { ...state, message };
    }
    case 'LANDING': {
      const landing = action.res;
      return { ...state, landing };
    }
    case ADD_TOP_MESSAGE: {
      const message = action.payload;
      if(!message.id) {
        message.id = Math.random();
      }
      if(state.topMessages.find(m => m.id === message.id)) {
        return state;
      }
      return {...state, topMessages: [...state.topMessages, message]};
    }
    case REMOVE_TOP_MESSAGE: {
      const id = action.payload.id;
      return {...state, topMessages: state.topMessages.filter(m => m.id !== id)};
    }
    default:
      return state;
  }
}



function NotificationLink ({href,level}) {
  const bgColor = level === 'success' ? 'rgb(94, 164, 0)' : level ==='error' ? '#E34848' : level==='warning' ? '#E7AF1C' : '#4380C7'
  return (<a style={{ color:'white', cursor:'pointer' }} href={ href } target="_blank"><button className="notification-action-button" style={{background: level ? bgColor : 'rgb(94, 164, 0)', borderRadius: '2px', padding:'6px 20px', fontWeight: 'bold', margin: '10px 0px 0px', border: '0px', color: 'rgb(255, 255, 255)', cursor:'pointer'}}>view</button></a>)
}

export {NotificationLink}
