import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let BL = ({ fill = "#7D8794", opacity = 0.4, ...props }) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_29548_223091)" opacity={opacity}>
          <path
            d="M16.8178 3.87097H3.03616C2.53584 3.87097 2.12891 4.27791 2.12891 4.77823V23.0927C2.12891 23.5931 2.53584 24 3.03616 24H16.8178C17.3176 24 17.7246 23.5931 17.7246 23.0927V4.77823C17.7246 4.27791 17.3176 3.87097 16.8178 3.87097ZM13.7418 16.6452H6.38697C5.95923 16.6452 5.61278 16.2987 5.61278 15.871C5.61278 15.4432 5.95923 15.0968 6.38697 15.0968H13.7418C14.1696 15.0968 14.516 15.4432 14.516 15.871C14.516 16.2987 14.1696 16.6452 13.7418 16.6452ZM13.7418 13.5484H6.38697C5.95923 13.5484 5.61278 13.2019 5.61278 12.7742C5.61278 12.3465 5.95923 12 6.38697 12H13.7418C14.1696 12 14.516 12.3465 14.516 12.7742C14.516 13.2019 14.1696 13.5484 13.7418 13.5484ZM13.7418 10.4516H6.38697C5.95923 10.4516 5.61278 10.1052 5.61278 9.67742C5.61278 9.24968 5.95923 8.90323 6.38697 8.90323H13.7418C14.1696 8.90323 14.516 9.24968 14.516 9.67742C14.516 10.1052 14.1696 10.4516 13.7418 10.4516Z"
            fill={fill}
          />
          <path
            d="M21.8706 2.32258V19.3548C21.8706 20.5239 20.9299 21.6774 19.2727 21.6774V4.77823C19.2727 3.42387 18.1714 2.32258 16.8175 2.32258H4.72656C4.72656 1.04177 5.83027 0 7.18656 0H19.4106C20.7669 0 21.8706 1.04177 21.8706 2.32258Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_29548_223091">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </span>
  </span>
);

BL = pure(BL);
BL.displayName = 'BL';
BL.muiName = 'SvgIcon';

export default BL;
