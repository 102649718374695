import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Pages.scss';
import AliceCarousel from 'react-alice-carousel';
import styled from 'styled-components';

const NextIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="-0.5" y="0.5" width="59" height="59" rx="29.5" transform="matrix(-1 0 0 1 59 0)" fill="white"/>
  <path d="M21.475 18.525L25 15L40 30L25 45L21.475 41.475L32.925 30L21.475 18.525Z" fill="#999999"/>
  <rect x="-0.5" y="0.5" width="59" height="59" rx="29.5" transform="matrix(-1 0 0 1 59 0)" stroke="#D2D2D2"/>
  </svg>
);
const PrevIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" fill="white"/>
    <path d="M38.525 18.525L35 15L20 30L35 45L38.525 41.475L27.075 30L38.525 18.525Z" fill="#999999"/>
    <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke="#D2D2D2"/>
  </svg>
);

const responsive = {
  0: { items: 1 },
  868: { items: 2 },
  1280: { items: 3 },
};
const StyleWrapper = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  .alice-carousel__prev-btn, .alice-carousel__next-btn {
    position: absolute;
    z-index: 12;
    top: 50%;
    width: auto;
    transform: translateY(-70%);
    cursor: pointer;
    svg {
      opacity: 0.6;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
  .alice-carousel__prev-btn {
    left: -90px;
    @media(max-width: 1300px) {
      left: 0;
    }
  }
  .alice-carousel__next-btn {
    right: -90px;
    @media(max-width: 1300px) {
      right: 0;
    }
  }
`;
class ShipnextSolutionsCarousel extends Component {
  async componentDidMount() {
    await import("!!style-loader!css-loader!react-alice-carousel/lib/alice-carousel.css");
    this.forceUpdate();
  }
  render() {
    return (
      <StyleWrapper>
        <AliceCarousel
          infinite
          responsive={responsive}
          renderPrevButton={() => <PrevIcon/>}
          renderNextButton={() => <NextIcon/>}
          {...this.props}
        />
      </StyleWrapper>

    );
  }
}

export default (withStyles(s)(ShipnextSolutionsCarousel));
