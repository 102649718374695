import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from './UnderConstraction.scss';
import cx from 'classnames';
import ActionBuild from 'material-ui/svg-icons/action/build';
import Ship from './Icons/Ship';
import Page from './Page/Page';

class UnderCounstruction extends Component {
  static propTypes = {};
  static defaultProps = {};

  render() {
    return (
      <Page headerTitle={this.props.title}>
        <div className={s.root}>
          <Ship className="ship_icon_big" style={{ width: 140, height: 140 }} />
          <h1>Coming soon</h1>
        </div>
      </Page>
    );
  }
}
export default connect(state => state.login, {})(
  withStyles(s)(UnderCounstruction),
);
