import React, { Component } from 'react'
import styles from './muistyles'
import cx from 'classnames'
import FormsyDate from '../Common/FormsyDate';
import { date } from '../../core/format';

export class NarrowFormsyDate extends Component {

    render() {
        const {inputStyles, className, ...props} = this.props;
        return (
            <FormsyDate
                ref="formsyDate"
                className={cx("input_default", className)}
                style={styles.container}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={styles.text.label}
                hintStyle={styles.text.label}
                inputStyle={{...styles.text.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={styles.text.underline}
                underlineFocusStyle={styles.text.underlineFocus}
                errorStyle={styles.error}
                formatDate={date}
                dialogContainerStyle={{ marginTop: "-28px" }}
                {...props}

            >

            </FormsyDate>
        )
    }
}

export default NarrowFormsyDate;
