//import TextField from "material-ui/TextField";
import PropTypes from 'prop-types';

import React, { Component } from "react";
import { Input } from "./StyledComponents";
import BlurIfNoSubscription from "../../Signup/BlurIfNoSubscription";

class OnlyNumbersInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue || "",
      focus: false
    };
  }

  static propTypes = {
    defaultValue: PropTypes.any,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    value: PropTypes.any,
    blur: PropTypes.boolean
  };

  static defaultProps = {
    name: "name",
    label: "T/C index ($)",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    locale: "en-US",
    separator: ',',
    decimalSeparator: '.',
  };

  componentWillMount() {
    this.handleChange(null, this.props.value, true);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value)
      this.handleChange(null, nextProps.value, true);
  }

  formatValue = value =>
    value && value > 0
      ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "";

  handleChange = (event, receivedValue, isReceivedValue) => {
    if (isReceivedValue) {
      this.setState({value: receivedValue})
      return;
    }

    const { onlyInt } = this.props;

    const limit = 1000000000000;
    const value = event?.currentTarget?.value?.replace(/[^0-9.]/g, '') || receivedValue;

    if (value === "") {
      this.setState({ value }, () =>
        !isReceivedValue
          ? this.props.onChange(this.props.name, undefined)
          : null
      );
    }
    else if (isFinite(value)) {
      this.setState({ value: value > limit ? limit : value }, () =>
        !isReceivedValue
          ? this.props.onChange(this.props.name, onlyInt ? +this.state.value : this.state.value)
          : null
      );
    }
  };

  render() {
    let formattedValue = this.state.value;
    const value = this.state.value;
    try {
      if (typeof value === 'number' && isFinite(value) && value.toLocaleString) {
        let minimumFractionDigits = this.minimumFractionDigits || 0;
        if (!this.state.focus) {
          minimumFractionDigits = Math.max(this.minimumFractionDigits || 0, this.props.minimumFractionDigits);
        }
        formattedValue = value.toLocaleString(this.props.locale, { maximumFractionDigits: this.props.maximumFractionDigits, minimumFractionDigits });
      } else {
        formattedValue = this.formatValue(value);
      }
    } catch (e) {
    }
    const { style, ...rest } = this.props;
    const input = <Input
      {...rest}
      size="small"
      margin="dense"
      style={{ width: "100px", ...style }}
      floatingLabelText={this.props.label}
      floatingLabelStyle={{ top: "38px" }}
      onChange={this.handleChange}
      value={formattedValue}
      onFocus={() => this.setState({ focus: true })}
      onBlur={() => this.setState({ focus: false })}
      focus={this.state.focus}
    />;
    if (this.props.blur) {
      return (
        <BlurIfNoSubscription>
          {input}
        </BlurIfNoSubscription>
      );
    }
    return input;
  }
}

export default OnlyNumbersInput;
