/** @name SET_INDEXES_SEARCH_DATA */
export default function SET_INDEXES_SEARCH_DATA(state, action) {
  const newState = {};

  const newSelectedIndexes = state.balticIndex.indexesList.filter(
    index =>
      index.shortCode.toLowerCase().includes(action.payload.toLowerCase()) ||
      index.sourceName.toLowerCase().includes(action.payload.toLowerCase()) ||
      index.shortDescription
        .toLowerCase()
        .includes(action.payload.toLowerCase())
  );
  const selectedIndexes = newSelectedIndexes.map(index => index.id);
  newState.balticIndex = {
    ...state.balticIndex,
    selectedIndexes: selectedIndexes,
    search: action.payload,
  };

  return { ...state, ...newState };
}
