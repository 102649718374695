export default function UPDATE_CONTRACT_PDF(state, action) {
  const newState = {};
  const selectedContract = {
    ...state.selectedContract,
  };

  if (!selectedContract || selectedContract._id !== action.payload.contractId) {
    return { ...state, ...newState };
  }

  selectedContract.fullContractFile._id = action.payload.contractLinkToPDF;

  newState.selectedContract = selectedContract;
  newState.isAwaitingPDFUpdate = false;

  return { ...state, ...newState };
}
