import { indexByAsArray } from '../../core/utils';

export default function mergeTerms(
  current = { default: [], user: [] },
  previous = { default: [], user: [] },
) {
  const currentDt = indexByAsArray(current.default, 'index');
  const previousDt = indexByAsArray(previous.default, 'index');

  const mergedDt = currentDt.map((ct, i) => {
    if (!ct) return ct;
    ct = { ...ct };
    delete ct._id;
    const pt = previousDt[i];
    if(pt) {
      delete pt._id;
    }
    if ((!pt || pt.checked === false) && ct.checked === true) {
      ct.new = true;
      return ct;
    }
    if (pt && pt.checked === true && ct.checked === false) {
      ct.unchecked = true;
    }
    return ct;
  });
  //TODO merge user terms
  return { default: mergedDt, user: current.user.map(ut => { delete ut._id; return ut}) };
}
