import React from 'react';
import styles from './muistyles';
import cx from 'classnames';
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CityAutocomplete from "../Common/CityAutocomplete";

export function NarrowCityAutocomplete(props) {
  const { inputStyles, classes, ...restProps } = props;
  return (
    <CityAutocomplete
      className={cx("input_default", classes)}
      textFieldStyle={styles.container}
      floatingLabelFocusStyle={styles.text.labelFocus}
      floatingLabelStyle={styles.text.label}
      hintStyle={styles.text.label}
      inputStyle={{...styles.text.input, ...inputStyles}}
      floatingLabelShrinkStyle={styles.text.labelShrink}
      underlineStyle={styles.text.underline}
      underlineFocusStyle={styles.text.underlineFocus}
      {...restProps}
    />);
}

export default withStyles(s)(CityAutocomplete);
