import React from 'react';

const RecognizedIcon = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" fill="#285596"/>
<path fillRule="evenodd" clipRule="evenodd" d="M4 15.3311H9.33333V12.6667H7.21895L8.27614 13.7238L7.33333 14.6667L4.66667 12L7.33333 9.33332L8.27614 10.2761L7.21895 11.3333H9.33333V4.66666H4V15.3311ZM10.6667 4.66666H16V15.3311H10.6667V8.6644H12.781L11.7239 9.72159L12.6667 10.6644L15.3333 7.99774L12.6667 5.33107L11.7239 6.27388L12.781 7.33107H10.6667V4.66666Z" fill="white"/>
</svg>

);

export default RecognizedIcon;
