import styled from "styled-components";
import cx from "classnames";
import React from "react";

function getShortName(contact) {
  const fullNameParts = `${contact.name || contact.emails?.[0] || ''}`.trim().split(' ');
  return (fullNameParts?.[0]?.[0] || '') + (fullNameParts?.[1]?.[0] || '').toUpperCase();
}

export const Avatar = styled(({ contact, className, size, ...props}) => {
  const shortName = getShortName(contact);
  const photoId = contact.photoId || contact.logoId;
  return (<div className={cx(className, size)} {...props} >
    {photoId ? <img alt="avatar" src={`/api/v1/file/${photoId}`} /> : <div>{shortName}</div>}
  </div>);
})`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  background-color: var(--stroke-light-gray1);
  border-radius: 50%;
  &.large {
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    font-size: 42px;
  }
  &.mid {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    font-size: 18px;
  }
  &.small {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    font-size: 12px;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
