import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

const _StartChatIcon = ({ fill = false, ...props }) => (
  <span {...props}>
    <span className="icon-wrapper">
      {/*<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">*/}
      {/*<g id="Icon/Start-chat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
      {/*<path*/}
      {/*d="M7.06448706,19.5272176 L2.38931674,21.2173131 L4.25104805,16.5803018 C3.45623706,15.2385365 3,13.6725482 3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C10.1775788,21 8.48167031,20.458335 7.06448706,19.5272176 Z"*/}
      {/*id="Combined-Shape" fill="#75C422"*/}
      {/*/>*/}
      {/*<g id="Group-4" transform="translate(7.000000, 11.000000)" fill="#FFFFFF">*/}
      {/*<circle id="Oval-2" cx="1" cy="1" r="1" />*/}
      {/*<circle id="Oval-2-Copy" cx="5" cy="1" r="1" />*/}
      {/*<circle id="Oval-2-Copy-2" cx="9" cy="1" r="1" />*/}
      {/*</g>*/}
      {/*</g>*/}
      {/*</svg>*/}
      {/* <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4959 3.09768C13.8262 2.24439 12.9164 1.56998 11.7677 1.07556C10.6179 0.581139 9.36198 0.333374 8 0.333374C6.98521 0.333374 6.01329 0.480033 5.08744 0.772241C4.16052 1.06334 3.36327 1.45887 2.69353 1.95663C2.02379 2.45438 1.49121 3.04546 1.09473 3.73098C0.699314 4.4165 0.5 5.13536 0.5 5.88754C0.5 6.75528 0.752893 7.56857 1.25761 8.32742C1.76339 9.08516 2.45349 9.72068 3.33005 10.2362C3.2679 10.4673 3.1961 10.6873 3.11144 10.8962C3.02786 11.1039 2.95285 11.2739 2.88641 11.4073C2.8189 11.5406 2.72889 11.6884 2.61423 11.8506C2.49957 12.0117 2.41277 12.1261 2.35384 12.1928C2.29597 12.2594 2.19953 12.3694 2.06558 12.5228C1.93163 12.6761 1.84483 12.7761 1.80626 12.8216C1.8009 12.825 1.7784 12.8516 1.73982 12.9005C1.70017 12.9494 1.68088 12.9739 1.68088 12.9739L1.63052 13.0516C1.60266 13.0961 1.5898 13.1227 1.59194 13.135C1.59516 13.145 1.5898 13.175 1.57587 13.2205C1.56194 13.2672 1.56408 13.3027 1.58015 13.3261V13.3338C1.60266 13.4383 1.65195 13.5216 1.72696 13.5849C1.80197 13.6494 1.88663 13.676 1.982 13.6638C2.34526 13.6172 2.66352 13.5538 2.9357 13.4727C4.39841 13.085 5.6811 12.385 6.78697 11.3728C7.20489 11.4184 7.60994 11.4417 8 11.4417C9.36198 11.4417 10.6168 11.1951 11.7666 10.6995C12.9164 10.2051 13.8262 9.53069 14.4959 8.6774C15.1657 7.82411 15.5 6.89416 15.5 5.88754C15.5 4.88093 15.1657 3.95097 14.4959 3.09768Z"
          fill="#75C422"
        />
      </svg> */}
      {!fill ?
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0H18C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H4L0 20V2C0 0.9 0.9 0 2 0ZM4 14H18V2H2V16L4 14Z"
            fill="#fff"
          />
        </svg> :
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.6 3H4.4C3.63 3 3 3.63 3 4.4V17L5.8 14.2H15.6C16.37 14.2 17 13.57 17 12.8V4.4C17 3.63 16.37 3 15.6 3Z"
            fill="#fff"
          />
        </svg>
      }
    </span>
  </span>
);

const StartChatIcon = pure(_StartChatIcon);
StartChatIcon.displayName = "StartChatIcon";
StartChatIcon.muiName = "SvgIcon";

export default StartChatIcon;
