import React, { Component } from 'react'
import { HOC } from 'formsy-react';
import StyledToggle from './StyledToggle';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    padding: 3px 0px;
    align-items: center;
    &.disabled {
      position: relative;
      pointer-events: none !important;
      div {
        cursor: initial !important;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        bottom: 0;
        background: #fff;
        opacity: 0.66;
        cursor: initial !important;
      }
    }
`;

const Label = styled.span`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${props => props.active ? `#333` : `var(--stroke-light-gray1)`};
    cursor: pointer;
    &:last-child {
        margin-left: 4px;
    }
`

export function Switch({ leftLabel, rightLabel, name, value, onChange, ...rest }) {
    return (
      <Wrapper>
        <Label onClick={() => onChange(false)} active={!value}>
          {
            leftLabel
          }
        </Label>
        <StyledToggle
          style={{width: 'auto'}}
          {...rest}
          onToggle={(ev, val) => onChange(val)}
          toggled={value}
          name={name}
        />
        <Label onClick={() => onChange(true)} active={value}>
          {
            rightLabel
          }
        </Label>
      </Wrapper>
    )
  }

export default HOC(Switch);
