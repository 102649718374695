import * as handlers from './bl/index';

export default function bl(
  state = {
    blStore: {},
    blList: {
      data: [],
      page: 1,
      next: 2,
      filters: {},
      sort: { field: 'addedAt', value: -1 },
      loading: true,
      isLoading: true,
    },
  },
  action,
) {
  if (!handlers[action.type]) {
    return state;
  }
  try {
    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
