import React from 'react';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import Opts from './Opts';

function Demurrage(props) {
  const {
    namePrefix = "demurrageOpts",
    onChange = () => {},
    demurrage,
    demurrageOpts = {},
  } = props;

  return (
    <div className="row">
      <div className="col-3">
        <NarrowFormsyText
          name="demurrage"
          value={demurrage || ""}
          hintText="Demurrage"
          validations="isNumeric,gt0"
          validationErrors={{
            isNumeric: "only numbers",
            gt0: "must be greater than 0"
          }}
          floatingLabelText="Demurrage"
          fullWidth
          onBlur={(_, val) => val === demurrage ? null : onChange("demurrage")(val)}
        />
      </div>
      <Opts
        onChange={onChange(namePrefix)}
        namePrefix={namePrefix}
        opts={demurrageOpts}
      />
    </div>
  );
}
export default Demurrage;
