import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

const _PortsIcon = props => (
  <span {...props}>
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="29" cy="29" r="29" fill="#F5F5F5"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.9984 11.6C31.7441 11.6 33.9699 13.8591 33.9699 16.646C33.9699 18.843 32.5865 20.7121 30.6556 21.4048V25.52L28.9984 25.1429V18.328C29.9137 18.328 30.6556 17.5749 30.6556 16.646C30.6556 15.717 29.9137 14.964 28.9984 14.964V11.6ZM28.9984 28.42V45.24C29.6456 45.24 30.7831 44.9808 31.4302 44.9808C31.4302 44.9343 30.6556 45.1207 30.6556 45.1207C36.2771 44.3046 40.5984 39.3975 40.5984 33.466H37.2842C37.2842 37.5347 34.4376 40.9285 30.6556 41.7077V27.84L28.9984 28.42Z" fill="#4380C7"/>
<path d="M27.3413 45.1207C27.3413 45.1207 26.9626 45.0287 26.7581 44.997C26.6825 44.9854 26.6306 44.9819 26.6306 44.9945C26.6695 44.9945 26.7122 44.9954 26.7581 44.997C27.4781 45.0231 28.9984 45.24 28.9984 45.24V28.42L27.3413 27.84V41.7077C23.5593 40.9285 20.7127 37.5347 20.7127 33.466H17.3984C17.3984 39.1658 21.3888 43.9197 26.6887 45.0066C26.9041 45.0507 27.1217 45.0889 27.3413 45.1207Z" fill="#88BBF6"/>
<path d="M24.027 16.646C24.027 18.843 25.4104 20.7121 27.3413 21.4048V25.52L28.9984 25.1429V18.328C28.0832 18.328 27.3413 17.5749 27.3413 16.646C27.3413 15.717 28.0832 14.964 28.9984 14.964V11.6C26.2528 11.6 24.027 13.8591 24.027 16.646Z" fill="#88BBF6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.9984 45.24C28.9984 45.24 30.7831 44.9808 31.4302 44.9808C30.3531 46.1408 28.9984 48.72 28.9984 48.72V45.24ZM26.7581 44.997C26.7122 44.9954 26.6695 44.9945 26.6306 44.9945C26.65 44.9986 26.6693 45.0026 26.6887 45.0066C26.9041 45.0507 27.1217 45.0889 27.3413 45.1207C27.3413 45.1207 26.9626 45.0287 26.7581 44.997Z" fill="#4380C7"/>
<path d="M27.3413 45.1207C27.1217 45.0889 26.9041 45.0507 26.6887 45.0066C26.6693 45.0026 26.65 44.9986 26.6306 44.9945C27.7078 46.1545 28.9984 48.72 28.9984 48.72V45.24C28.9984 45.24 27.4781 45.0231 26.7581 44.997C26.9626 45.0287 27.3413 45.1207 27.3413 45.1207Z" fill="#88BBF6"/>
<path d="M35.9584 25.52H30.6556L28.9984 25.1429V28.42L30.6556 27.84H35.9584V25.52Z" fill="#82C3FF"/>
<path d="M22.0384 27.84H27.3413L28.9984 28.42V25.1429L27.3413 25.52H22.0384V27.84Z" fill="#BEE0FF"/>
<path d="M23.9318 34.3474L18.8027 30.7628L14.7249 35.5092L16.6814 36.8765L19.2037 33.9407L22.3763 36.1579L23.9318 34.3474Z" fill="#88BBF6"/>
<path d="M34.2439 34.3255L39.3731 30.741L43.4509 35.4873L41.4944 36.8547L38.9721 33.9188L35.7995 36.136L34.2439 34.3255Z" fill="#4380C7"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.9997 10.4399V16.2399C28.5437 16.2399 28.1446 15.9334 28.0271 15.4927L27.0687 11.8988C26.8723 11.1624 27.4274 10.4399 28.1896 10.4399H28.9997Z" fill="#FF6F32"/>
<path d="M28.9997 10.4399V16.2399C29.4557 16.2399 29.8548 15.9334 29.9723 15.4927L30.9307 11.8988C31.127 11.1624 30.572 10.4399 29.8098 10.4399H28.9997Z" fill="#BE2F2D"/>
</svg>

  </span>
);

const PortsIcon = pure(_PortsIcon);
PortsIcon.displayName = 'Ports';
PortsIcon.muiName = 'SvgIcon';

export default PortsIcon;
