import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Home.scss';
import cx from 'classnames';
import Partners from './Partners';
import NewsHomePage from '../News/NewsHomePage';
import ShipnextToday from './ComponentsIco/ShipnextToday';
import Press from './ComponentsIco/Press';
import Solutions from './Solutions/Solutions';
import FirstScreen from './FirstScreen';
import ManagmentSolutions from './ManagmentSolutions';
import Link from "../Link/Link";

class Home extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <FirstScreen />

        <span className={s.anchor} id="solutions" />
        <Solutions user={this.props.user} />

        <span className={s.anchor} id="today" />
        {
          this.props.statistics ?
            <ShipnextToday
              statistics={this.props.statistics}
            />
            :
            <span />
        }

        <span className={s.anchor} id="supply-chain" />
        <ManagmentSolutions />

        <span className={s.anchor} id="partners" />
        <div className={cx(s.partners_all, this.props.fromPagePartners ? s.partners_all_from_page : null)}>
          <div className={s.container}>
            <div>
              <h2>Clients & Partners</h2>
              <Partners />
              <div
                className={s.wrapper_btn_show}
                style={{
                  display: this.props.fromPagePartners ? 'none' : 'flex',
                }}
              >
                <Link className={s.show_more} to={'/partners'}>Show all</Link>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="news" />
        <NewsHomePage />

        <span className={s.anchor} id="press" />
        <Press />
      </div>
    );
  }
}

export default withStyles(s)(Home);
