import React, { Component } from 'react'
import Autocomplete from './AutoComplete';
import MenuItem from 'material-ui/MenuItem';
import Highlight from '../Monitor/Highlight';
import { escapeRegExp } from '../../core/utils';
import Terms from './../../core/api/Terms';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

function renderer(terms, props = {}) {
  const search = escapeRegExp(this.state.searchText || '');
  const formatDate = date => new Date(date).toLocaleDateString();

  terms = terms.sort((a,b) => b[this.props.params.termsType].date - a[this.props.params.termsType].date);

  return terms.map((term, index) => {
    return {
      text: term.name,
      _value: term,
      value: (
        <MenuItem
          primaryText={<div key={term.id} style={{ maxWidth: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color:'#333333', fontSize: '12px', textTransform: 'initial', fontWeight: 'normal' }}>
            {term.name}</div>}
          secondaryText={<span>{formatDate(term[this.props.params.termsType].date)}
              <span data-type="delete"><CloseIcon style={{width: 24, height: 24, marginLeft: '10px', zIndex: '999', position: 'relative'}} />
              </span>
            </span>}
          className="vessel_autocomplete_item"
          {...props}
        />
      ),
    };
  });
}

export class TermsAutocomplete extends Autocomplete {
    static defaultProps = {
        menuProps: {
          menuItemStyle: {
            minHeight: '32px',
            lineHeight: '32px',
            height: '32px',
            fontSize: '15px',
            fontWeight: '500',
            border: '1px solid #E0E0E0',
            borderTop: 'none',
          },
          className: 'vessel_autocomplete_menu',
        },
      }

      constructor(props) {
        super(props);
        this.Api = Terms;
        this.renderer = renderer;
      }
}

export default TermsAutocomplete
