/** @name EMAILS_GET_EMAIL_REQUEST_REDUCER */
export default function EMAILS_GET_EMAIL_REQUEST(state, { res, payload }) {
  if (payload.silent) {
    return state;
  }
  const newState = {};
  const details = { ...state.details, loading: true };
  newState.details = details;
  return { ...state, ...newState };
}
