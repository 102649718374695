import React, { Component } from 'react';
import { v4 } from 'uuid';
import styled from 'styled-components';
import cx from 'classnames';
import { debounceWithoutFirstCall } from '../../core/utils';
import { connect } from 'react-redux';



const Container = styled.div`
  position: relative;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
`

const Button = styled.div`
  display: inline-block;
  z-index: 105;
  position: fixed;
  bottom: 0;
  right: 108px;
  width: 40px;
  height: 40px;
  background-color: var(--icon-default);
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;
  > span {
    color: white;
    font-size: 30px;
  }
  &.up {
    transform: rotate(180deg);
  }
`

class ScrollTopWrapper extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.id = v4();
  }

  componentDidMount() {
    this.container = document.getElementById(this.id);
    this.calculateState();
    this.intervalId = setInterval(this.calculateState, 1000); // consider to use ResizeObserver
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }


  _onScroll = debounceWithoutFirstCall((e) => {
    this.calculateState();
  })

  onScroll = (e) => {
    this._onScroll(e);
    if (this.props.onScroll) {
      this.props.onScroll(e, this.container);
    }
  }

  calculateState = () => {
    if (!this.container) {
      return;
    }
    const scrollHeight = this.container.scrollHeight;
    const clientHeight = this.container.clientHeight;
    const scrollTop = this.container.scrollTop;

    const newState = {};
    newState.buttonVisible = scrollHeight / clientHeight >= 3;
    newState.scrollTo = scrollHeight - clientHeight;
    if ((scrollHeight * 0.2) >= (scrollHeight - clientHeight - scrollTop)){
      newState.scrollTo = 0;
    }
    if (this.state.buttonVisible !== newState.buttonVisible || this.state.scrollTo !== newState.scrollTo) {
      this.setState(newState);
    }
  }

  scroll = () => {
    this.container.scrollTop = this.state.scrollTo;
  }

  render() {
    const { children, className, selectedElements, ...props } = this.props;
    return (
      <Container id={this.id} className={cx(className, 'scroll-container')} {...props} onScrollCapture={this.onScroll} >
        {children}
        {this.state.buttonVisible ? (
          <Button style={selectedElements ? { right: "230px" } : { right: "108px" }} className={cx(this.state.scrollTo === 0 ? 'up' : 'down')} onClick={this.scroll}>
            <span className="material-icons-outlined">
              expand_more
            </span>
          </Button>
        ) : null}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedElements: Object.values(state.issueReport.selectedElements).flat().length > 0,
}
);


export default connect(mapStateToProps)(ScrollTopWrapper);
