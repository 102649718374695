import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let CargoStat = props => (
  <span {...props}>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g id="cargoes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="evenodd">
          <path d="M9,16 L12.6840432,12.2455161 C12.0260648,11.3989637 11.6303242,10.3292148 11.6303242,9.16859306 C11.6303242,6.91749701 13.1099809,5.01076126 15.128417,4.40653647 L15.128417,1.3981666 C15.128417,0.62654444 15.7434838,0 16.5015893,0 C17.2581055,0 17.8731723,0.62654444 17.8731723,1.3981666 L17.8731723,5.60382623 C17.8731723,6.37544839 17.2581055,7.00039857 16.5015893,7.00039857 C15.3286713,7.00039857 14.3750795,7.97289757 14.3750795,9.16859306 C14.3750795,9.55759267 14.4767959,9.92267836 14.6532104,10.238342 L15.7736809,9.09685134 C15.9659886,8.90075727 16.2282263,8.79075329 16.5015893,8.79075329 C16.7749523,8.79075329 17.0356008,8.90075727 17.2294978,9.09685134 L18.3483789,10.238342 C18.5247934,9.92267836 18.6280992,9.55759267 18.6280992,9.16859306 C18.6280992,8.39537664 19.2399873,7.77042646 19.9980928,7.77042646 C20.7561983,7.77042646 21.3696758,8.39537664 21.3696758,9.16859306 C21.3696758,10.3292148 20.9755245,11.3989637 20.3175461,12.2455161 L24,16 L21.0915448,16 L18.7202797,13.5831008 C18.0543547,13.9322439 17.3010172,14.1315265 16.5015893,14.1315265 C15.7021615,14.1315265 14.9472346,13.9322439 14.2813096,13.5831008 L11.9100445,16 L9,16 Z M3,31 L3,17 L29,17 L29,31 L3,31 Z M5,19 L5,29 L7,29 L7,19 L5,19 Z M9,19 L9,29 L11,29 L11,19 L9,19 Z M13,19 L13,29 L15,29 L15,19 L13,19 Z M17,19 L17,29 L19,29 L19,19 L17,19 Z M21,19 L21,29 L23,29 L23,19 L21,19 Z M25,19 L25,29 L27,29 L27,19 L25,19 Z" id="Combined-Shape" fill="#FFFFFF"></path>
        </g>
      </g>
    </svg>
  </span>
);

CargoStat = pure(CargoStat);
CargoStat.displayName = 'CargoStat';
CargoStat.muiName = 'SvgIcon';

export default CargoStat;
