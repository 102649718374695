import MenuItem from 'material-ui/MenuItem';
import FormsySelect from './Select';
import PropTypes from 'prop-types';
import React from 'react';
import {NarrowSelect} from "../NewInputs/NarrowSelect";
import ContractSelect from "./ContractSelect";
const MENU_ITEM_STYLE = {fontSize: '13px'};
const MENU_PROPS = { maxHeight: 280 };
export default function ContainerTypeSelect(props, context) {

  const { as: El, ...restProps } = props;
  return (<NarrowSelect
    requiredError="required"
    floatingLabelText="Type of Container"
    autoWidth
    fullWidth
    dropDownMenuProps={MENU_PROPS}
    {...restProps}
  >
    {context.containerTypes.map(ct => (
      <MenuItem
        key={ct._id}
        value={ct._id}
        primaryText={ct.name}
        style={MENU_ITEM_STYLE}
      />
    ))}
  </NarrowSelect>);
}

ContainerTypeSelect.defaultProps = { as: FormsySelect };
ContainerTypeSelect.contextTypes = {
  containerTypes: PropTypes.array,
  formsy: PropTypes.object,
};
