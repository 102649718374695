import React from 'react';
import pure from 'recompose/pure';
import s from './../Footer/Footer.scss';
let CircleLinkedIn = props => (
  <svg width="24" className={s.linked_in} style={{fill: "initial"}} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0736 24C18.6604 24 24 18.6274 24 12C24 5.37258 18.6604 0 12.0736 0C5.48684 0 0.147217 5.37258 0.147217 12C0.147217 18.6274 5.48684 24 12.0736 24Z" fill="white" />
    <path d="M9.51451 16.365H7.64863V10.2192H9.51451V16.365ZM8.58056 9.38086C7.98404 9.38086 7.5 8.87539 7.5 8.26513C7.5 7.65487 7.98404 7.15967 8.58056 7.15967C9.17708 7.15967 9.66112 7.65487 9.66112 8.26513C9.66112 8.87539 9.17708 9.38086 8.58056 9.38086ZM16.498 16.365H14.6361V13.3733C14.6361 12.6603 14.6221 11.7459 13.666 11.7459C12.6959 11.7459 12.5473 12.5205 12.5473 13.3219V16.365H10.6834V10.2192H12.473V11.0575H12.4991C12.7482 10.5747 13.3567 10.0651 14.2646 10.0651C16.1525 10.0651 16.5 11.337 16.5 12.989V16.365H16.498Z" fill="#285596" />
    <title>LinkedIn</title>
  </svg>
);
CircleLinkedIn = pure(CircleLinkedIn);
CircleLinkedIn.displayName = 'Wait';
CircleLinkedIn.muiName = 'SvgIcon';

export default CircleLinkedIn;
