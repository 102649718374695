import { returnMockByType } from '../../../core/utils';
import lodashCloneDeep from 'lodash/cloneDeep';

export default class AbstractAdapter {
  constructor(pipeline) {
    this.pipeline = pipeline;
    this._adapteeRequest = null;
  }
  copy(reference) {
    return Object.assign(returnMockByType(reference), reference);
  }
  copyDeep(reference) {
    return lodashCloneDeep(reference);
  }
  removeEmptyStrings(obj) {
    if (!obj || typeof obj !== "object") return obj;

    obj = Object.assign(returnMockByType(obj), obj);

    const keys = Object.keys(obj);

    keys.forEach(key => {
      if (obj[key] && typeof obj[key] === "object" && obj[key] !== null && !(obj[key] instanceof Date)) {
        obj[key] = this.removeEmptyStrings(obj[key]);
      }
      if (!obj[key]) {
        delete obj[key];
      }
    });

    return obj;
  }
  adapt(adaptee, ...rest) {
    this._adapteeRequest = adaptee;
    const copy = this.copy(adaptee);
    const { pipeline } = this;
    return pipeline.reduce((adapted, step) => step(this.copy(adapted), ...rest), copy);
  }
}
