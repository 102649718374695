import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Formsy from 'formsy-react';
import cx from 'classnames';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import s from './Content.css';
import Page from '../../components/Page/Page';
import FormsyText from '../../components/Common/FormsyText';
import RaisedButton from '../../components/Common/RaisedButton';
import { sendFeedback } from '../../actions/login';
import Footer from '../../components/Footer';

const Form = Formsy.Form;

const handleClick = function (e) {
  const target = e.target;
  if (target.className.indexOf('accordion') !== -1) {
    target.classList.toggle('active');
    const answer = target.nextElementSibling;
    if (answer.style.maxHeight) {
      answer.style.maxHeight = null;
    } else {
      answer.style.maxHeight = answer.scrollHeight + 'px';
    }
  }
};

class Help extends Component {

  componentDidMount() {
    if (this.props.user && this.refs.form) {
      this.refs.form.reset({
        name: (this.props.user.name || '') + ' ' + (this.props.user.surname || ''),
        email: this.props.user.email,
      });
    }
  }

  handleSubmit = (feedback) => {
    this.props.sendFeedback(feedback).then((res) => {
      if (res.status === 200) {
        this.refs.form.reset({ ...feedback, message: '' });
      }
    });
  };

  render() {
    const { path, content, titleHeader } = this.props;
    return (
      <Layout>
        <Page headerTitle={(path !== '/' && titleHeader) || ''}>
          <div style={{ width: '100%' }}>
          <div className={s.container}>
            <Form noValidate ref="form" onValidSubmit={this.handleSubmit}>
              <div className={'help-page'}>
                <br /><br />
                <h1 className={'main-title'}>Give Feedback</h1>
                <div className={'container-fluid'}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <FormsyText
                        name={'name'}
                        floatingLabelText={'Name*'}
                        requiredError="required"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="w-100" />
                    <div className="col-12 col-md-6">
                      <FormsyText
                        name={'email'}
                        floatingLabelText={'Email*'}
                        requiredError="required"
                        validations="isEmail"
                        validationError="please enter valid email"
                        fullWidth
                        required
                      />
                    </div>
                    <div className="w-100" />
                    <div className="col-12">
                      <FormsyText
                        name={'message'}
                        floatingLabelText={'Message*'}
                        requiredError="required"
                        fullWidth
                        multiLine
                        rows={1}
                        rowsMax={10}
                        maxLength={10000}
                        required
                      />
                    </div>
                    <div className="w-100" />
                    <div className={'col-0 col-md-4'} />
                    <div className={'col-12 col-md-4'}>
                      <br /><br />
                      <RaisedButton
                        label={'Send Feedback'}
                        fullWidth
                        type={'submit'}
                        primary
                      />
                    </div>
                    <div className={'col-0 col-md-4'} />
                  </div>
                </div>
              </div>
            </Form>
            <br />
            <div // eslint-disable-line jsx-a11y/no-static-element-interactions
              role="button"
              onClick={handleClick}
              dangerouslySetInnerHTML={{ __html: content }} // eslint-disable-line react/no-danger
            />
          </div>
            <Footer />
          </div>
        </Page>
      </Layout>
    );
  }
}


Help.propTypes = {
  path: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
  user: PropTypes.object,
  sendFeedback: PropTypes.func,
};

export default connect(
  state => ({ user: state.login.user }),
  {sendFeedback},
)(withStyles(s)(Help));

