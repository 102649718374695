import RoundedArrowPopover from '../Common/RoundedArrowPopover';
import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import type { IMessageDetails } from './types';
import styled from 'styled-components';
import ArrowPopover from '../Common/ArrowPopover';

function seenByRenderer(list) {
  return list.map(({ userId, user }) => <MenuItem value={userId}
                                                  primaryText={`${user.name || ''} ${user.surname || ''}`.trim() || user.email}
                                                  key={userId}/>);
}

export const SeenBy = styled((props: { message: IMessageDetails, className: string }) => (props.message.readBy?.length ? (
    <RoundedArrowPopover
      className={props.className}
      list={props.message.readBy}
      val={<span className={'seen-by-label'}><span>SEEN BY</span><span className="material-icons">visibility</span> </span>}
      renderer={seenByRenderer}
    />
  ) : null
))`
  .seen-by-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-links);
    > span:first-child {
      padding-top: 2px;
    }
    .material-icons {
      font-size: 18px;
      color: var(--text-links);
      margin-left: 4px;
    }
  }
`

export const SeenBySmall = styled((props: { readBy: [], className: string }) => (props.readBy?.length ? (
    <ArrowPopover
      className={props.className}
      list={props.readBy}
      val={<span className={'seen-by-label'}><span className="material-icons">visibility</span> </span>}
      renderer={seenByRenderer}

    />
  ) : null
))`
  margin-left: 2px;
  > div:first-child {
    margin-right: 0;
    &::before {
      display: none;
    }
    > svg {
      margin-right: 0!important;
      margin-left: -4px;
    }
    .seen-by-label {
      display: flex;
      .material-icons {
        font-size: 18px;
      }
    }
  }
`
