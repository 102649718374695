import React from 'react';
import pure from 'recompose/pure';

const _ContactsTelegram = props => (
  <span {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37258 0 0 5.37259 0 12C0 18.6274 5.37258 24 12 24Z" fill="#285596"/>
      <path d="M7.9338 13.5775L4.32038 12.4179C4.32038 12.4179 3.88853 12.2453 4.02759 11.854C4.05621 11.7733 4.11396 11.7046 4.28669 11.5866C5.08733 11.0369 19.1058 6.07398 19.1058 6.07398C19.1058 6.07398 19.5017 5.94261 19.7351 6.02999C19.7928 6.0476 19.8448 6.08 19.8857 6.12388C19.9265 6.16776 19.9548 6.22155 19.9677 6.27973C19.9929 6.38251 20.0034 6.48825 19.999 6.59389C19.9979 6.68528 19.9867 6.76999 19.9782 6.90282C19.8928 8.25971 17.3377 18.3866 17.3377 18.3866C17.3377 18.3866 17.1849 18.9792 16.6372 18.9995C16.5026 19.0038 16.3685 18.9813 16.2429 18.9335C16.1173 18.8856 16.0028 18.8133 15.9061 18.7209C14.8313 17.8103 11.1165 15.3513 10.2956 14.8104C10.2771 14.798 10.2615 14.7818 10.2499 14.763C10.2383 14.7441 10.2309 14.723 10.2282 14.7011C10.2168 14.6441 10.2797 14.5735 10.2797 14.5735C10.2797 14.5735 16.7482 8.91014 16.9203 8.31561C16.9337 8.26955 16.8833 8.24683 16.8157 8.267C16.3861 8.42268 8.93839 13.0553 8.11641 13.5666C8.05724 13.5842 7.9947 13.588 7.9338 13.5775Z" fill="white"/>
    </svg>
  </span>
);

const ContactsTelegram = pure(_ContactsTelegram);
ContactsTelegram.displayName = 'ContactsTelegram';
ContactsTelegram.muiName = 'SvgIcon';

export default ContactsTelegram;
