import React, {Component} from 'react';
import {uploadImage} from "../../actions/login";
import {connect} from "react-redux";
import styled from "styled-components";

const StyleWrapper = styled.div`
  display: inline-flex;
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: var(--stroke-light-gray1);
  align-items: center;
  justify-content: center;
  input {
    width: 120px;
    height: 120px;
    opacity: 0;
    z-index: 2;
    cursor:pointer;
    position: absolute;
    left:0;
    top:0;
  }
  img {
    object-fit: contain;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .no-avatar-icon {
    font-size: 42px;
    color: white;
  }
  .update-icon {
    position: absolute;
    right: 7px;
    top:4px;
    color: white;
    font-size: 24px;
    background: #B3B3B3;
    border-radius: 50%;
    transform: rotate(90deg);
  }
`

const NoAvatar = () => <span className="material-icons no-avatar-icon">photo_camera</span>;

class AvatarEdit extends Component {

  uploadImg = (e) => {
    const input = e.target;
    if (!input.files || !input.files[0]) return;
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('type', 'image');
    this.props.uploadImage(formData).then((res) => {
      if (res.status === 200) {
        this.props.onChange(null, res.data._id);
      }
    });
  };

  render() {
    return (
      <StyleWrapper>
        <input name="img" id="img" type="file" accept="image/*" onChange={this.uploadImg} />
        {this.props.photoId ? <img alt="avatar" src={`/api/v1/file/${this.props.photoId}`} /> : <span>{this.props.shortName || <NoAvatar /> }</span>}
        <span className="material-icons update-icon">autorenew</span>
      </StyleWrapper>
    );
  }
}

export default connect(state => state.login, {uploadImage})(AvatarEdit);
