import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

const _CargoMonitor = props => (
  <span {...props}>
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="29" fill="#F5F5F5"/>
      <path d="M28.1473 45.8486C27.8765 45.7841 27.6048 45.7213 27.334 45.6544C25.0498 45.0883 23.2696 43.0703 22.9742 40.7234C22.7206 38.707 23.326 36.9757 24.7651 35.5882C25.2887 35.0833 25.9825 34.7552 26.6108 34.3668C26.7695 34.2685 26.8432 34.1809 26.8415 33.9875C26.8317 32.6116 26.8342 31.2356 26.8325 29.8597C27.4903 29.8531 28.1473 29.8473 28.805 29.8407L28.7968 29.8597C28.7985 32.5421 28.8009 35.2246 28.8026 37.907C27.6425 38.0021 26.8342 38.8268 26.8374 39.911C26.8407 40.8845 27.5566 41.6977 28.5162 41.8224C28.6128 41.8348 28.706 41.8654 28.8009 41.8877C28.8009 43.2083 28.8001 44.5289 28.8001 45.8502C28.5833 45.8486 28.3657 45.8486 28.1473 45.8486Z" fill="#B4BCCB"/>
      <path d="M25.1332 12C25.6977 12.2636 25.8736 12.714 25.8629 13.3297C25.8359 14.8684 25.8572 16.4071 25.8597 17.9467C23.8921 17.9467 21.9245 17.9467 19.9568 17.9467C19.9585 16.4079 19.9806 14.8684 19.9536 13.3297C19.9429 12.7148 20.1188 12.2636 20.6833 12C22.1658 12 23.6499 12 25.1332 12Z" fill="#94D352"/>
      <path d="M31.7459 17.9467C31.7484 16.4071 31.7696 14.8676 31.7426 13.3288C31.732 12.714 31.9095 12.2644 32.4724 12C33.9557 12 35.4398 12 36.923 12C37.4859 12.2644 37.6634 12.714 37.6528 13.3288C37.6258 14.8676 37.6471 16.4071 37.6495 17.9467C35.6819 17.9467 33.7135 17.9467 31.7459 17.9467Z" fill="#4D9E0E"/>
      <path d="M31.7436 17.9465C33.7112 17.9465 35.6788 17.9465 37.6464 17.9465C37.9949 17.9474 38.3435 17.9399 38.6912 17.9523C39.1812 17.9697 39.596 18.3647 39.5985 18.8564C39.605 20.5381 39.5936 22.2206 39.5887 23.9023C37.4312 23.9015 35.273 23.9015 33.1156 23.899C31.6773 23.8973 30.2391 23.8924 28.8008 23.8891C28.8024 21.9132 28.8041 19.9365 28.8049 17.9606C29.785 17.9565 30.7643 17.9515 31.7436 17.9465Z" fill="#82C3FF"/>
      <path d="M28.8008 23.8892C30.2391 23.8925 31.6773 23.8974 33.1156 23.8991C35.273 23.9016 37.4312 23.9016 39.5887 23.9024C38.7427 25.0568 37.8976 26.2113 37.05 27.3641C36.5771 28.007 36.0862 28.6376 35.6265 29.2904C35.3409 29.6945 34.9883 29.8647 34.4942 29.8565C33.2547 29.8366 32.0144 29.8548 30.7741 29.8581C30.1163 29.8581 29.4586 29.8581 28.8008 29.859L28.809 29.84C28.809 27.8632 28.8081 25.8857 28.8081 23.909L28.8008 23.8892Z" fill="#42516D"/>
      <path d="M28.8109 45.8487C28.8109 44.5281 28.8117 43.2076 28.8117 41.8862C29.3451 41.8316 29.827 41.6482 30.2246 41.2771C30.8472 40.6962 31.5639 40.5499 32.3362 40.8706C33.0971 41.1862 33.529 41.8102 33.5618 42.6316C33.5929 43.3952 33.1944 43.9852 32.6111 44.4587C31.7267 45.176 30.7474 45.6661 29.6077 45.7942C29.5799 45.7975 29.5554 45.8297 29.53 45.8487C29.2911 45.8487 29.0506 45.8487 28.8109 45.8487Z" fill="#8490A8"/>
      <path d="M28.8008 29.8598C29.4586 29.8598 30.1163 29.8598 30.7741 29.859C30.7725 31.9027 30.7733 33.9463 30.7668 35.9891C30.7635 37.0419 29.8447 37.9336 28.8065 37.9063C28.8049 35.2247 28.8024 32.5423 28.8008 29.8598Z" fill="#8490A8"/>
      <path d="M28.8021 17.9609C28.8005 19.9368 28.7988 21.9135 28.798 23.8894L28.8046 23.9101C25.2113 23.9093 21.6189 23.9084 18.0257 23.9084C18.0175 23.3928 18.0044 22.8779 18.0028 22.3623C17.9995 21.2533 17.9987 20.1434 18.0028 19.0344C18.0052 18.3485 18.3971 17.9576 19.0786 17.951C19.3723 17.9477 19.666 17.9486 19.9597 17.9469C21.9273 17.9469 23.895 17.9469 25.8626 17.9469C26.8419 17.9519 27.822 17.956 28.8021 17.9609Z" fill="#B5D7FF"/>
      <path d="M18.0312 23.9083C21.6245 23.9092 25.2169 23.91 28.8101 23.91C28.8101 25.8867 28.8109 27.8642 28.8109 29.8409C28.1532 29.8476 27.4962 29.8533 26.8384 29.8599C25.5981 29.8566 24.357 29.8426 23.1168 29.8558C22.6381 29.8608 22.2888 29.6905 22.0041 29.3021C20.6861 27.499 19.3566 25.7049 18.0312 23.9083Z" fill="#495A79"/>
      <path d="M28.789 21.3214C29.7328 21.4156 30.5296 21.7685 31.0961 22.5724C31.7911 23.5589 31.7547 24.6051 31.2247 25.5983C30.7283 26.5291 29.872 27.007 28.7852 26.9916C28.7871 25.1022 28.788 23.2118 28.789 21.3214Z" fill="#BE2F2D"/>
      <path d="M28.7571 21.3351C28.7562 23.2256 28.7552 25.1161 28.7542 27.0056C27.3144 26.9979 26.0786 25.9132 25.9423 24.6237C25.7431 22.7457 27.0398 21.5899 28.2872 21.4226C28.4448 21.4024 28.6005 21.3649 28.7571 21.3351Z" fill="#FF6F32"/>
    </svg>
  </span>
);

const CargoMonitor = pure(_CargoMonitor);
CargoMonitor.displayName = 'CargoMonitor';
CargoMonitor.muiName = 'SvgIcon';

export default CargoMonitor;
