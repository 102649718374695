import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let ContactBookIcon = ({fill = "#76818D", opacity = 1}) => (
  <span style={{ opacity: opacity }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.3414 4.55706H19.6953V8.61036H20.3414C21.2574 8.61036 22 7.86551 22 6.94688V6.22024C22 5.30162 21.2574 4.55706 20.3414 4.55706Z" fill={fill}/>
      <path d="M20.3414 9.88533H19.6953V13.9383H20.3414C21.2574 13.9383 22 13.1938 22 12.2752V11.5485C22 10.6299 21.2574 9.88533 20.3414 9.88533Z" fill={fill}/>
      <path d="M20.3414 15.39H19.6953V19.4433H20.3414C21.2574 19.4433 22 18.6988 22 17.7799V17.0532C22 16.1349 21.2574 15.39 20.3414 15.39Z" fill={fill}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9963 3.66288V20.337C17.9963 21.2556 17.2538 22 16.3377 22H3.65847C2.74245 22 2 21.2556 2 20.337V16.8868H3.5294C4.17623 16.8868 4.7004 16.3606 4.7004 15.7121C4.7004 15.0637 4.17623 14.5376 3.5294 14.5376H2V9.46228H3.5294C4.17623 9.46228 4.7004 8.93623 4.7004 8.28805C4.7004 7.63959 4.17623 7.11373 3.5294 7.11373H2V3.66288C2 2.74426 2.74245 2 3.65847 2H16.3377C17.2538 2 17.9963 2.74426 17.9963 3.66288ZM10.5 11.527C11.7431 11.527 12.75 10.5172 12.75 9.27059C12.75 8.02394 11.7431 7.0142 10.5 7.0142C9.25688 7.0142 8.25 8.02394 8.25 9.27059C8.25 10.5172 9.25688 11.527 10.5 11.527ZM6 14.9116C6 13.4111 8.99813 12.6552 10.5 12.6552C12.0019 12.6552 15 13.4111 15 14.9116V16.0398H6V14.9116Z" fill={fill}/>
    </svg>
  </span>
);

ContactBookIcon = pure(ContactBookIcon);
ContactBookIcon.displayName = 'ContactBookIcon';
ContactBookIcon.muiName = 'SvgIcon';

export default ContactBookIcon;
