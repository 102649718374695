import React from 'react'
import CustomCheckbox from './CustomCheckbox';
import { connect } from 'react-redux';
import IconMenu from 'material-ui/svg-icons/navigation/menu';
import s from './../Vessel/Preview/ActionsMenu.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import cx from 'classnames';
import { toggleSubscribeDialog } from './../../actions/login';
import { isUserSubscribedToMarketplace } from '../../core/utils';

class BurgerActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchor: null,
    };
  }

  openMenu = ev => {
    this.setState({
      open: true,
      anchor: ev.currentTarget,
    });
  }

  closeMenu = () => {
    this.setState({
      open: false,
      anchor: null,
    });
  }

  blockIfOnlyForSubscribed = item => {
    if (item.onlyForSubscribed) {
      const isSubscribed = isUserSubscribedToMarketplace(this.props.user);
      if (!isSubscribed) {
        this.props.toggleSubscribeDialog(true);
        this.setState({
          open: false,
          anchor: null,
        });
        return;
      }
    }
  }

  render() {
    const { closeMenu, openMenu } = this;
    const { anchor, open } = this.state;
    const { list, className = '', menuProps = {}, parentState = {}, ...rest } = this.props;

    return (
      <div className={cx(s.menu_btn, className)} onClick={openMenu}>
        <IconButton style={{ width: 18, height: 18 }}>
          <IconMenu style={{ width: 18, height: 18 }} />
        </IconButton>
        <Popover
          anchorEl={anchor}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={closeMenu}
          open={open}
          className={cx(s.actions_menu, className)}
          {...rest}
        >
          <Menu
            {...menuProps}
          >
            {
              list.map(item => {
                const disabled = typeof item.disabled === 'function' ? item.disabled(item) : item.disabled;
                return (
                  <MenuItem style={ disabled ? { cursor: 'not-allowed', pointerEvents: 'none', opacity: 0.4 } : {} }>
                    {
                      item.checkbox
                        ? (
                          <CustomCheckbox
                            checked={parentState[item.checked]}
                            onCheck={(...args) => {
                              this.blockIfOnlyForSubscribed(item);
                              item.handler && item.handler(...args);
                            }}
                            labelPosition="left"
                            label={item.name}
                          />
                        )
                        : (
                          <div
                            onClick={(...args) => {
                              if (disabled) return;
                              this.blockIfOnlyForSubscribed(item);
                              item.handler && item.handler(...args);
                              closeMenu();
                            }}
                            style={{ width: '100%', height: '100%' }}
                          >
                              {
                                item.name
                              }
                          </div>
                        )
                    }
                  </MenuItem>
                )
              })
            }
          </Menu>
        </Popover>
      </div>

    )
  }
};

export default connect(state => ({ user: state.login.user }), { toggleSubscribeDialog })(withStyles(s)(BurgerActions));
