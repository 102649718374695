import React, { Component } from 'react'
import Chip from 'material-ui/Chip';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Tags.scss';
import CogIcon from 'material-ui/svg-icons/action/settings';
import { CircularProgress } from 'material-ui';
import cx from 'classnames';
import { computeColorOrDefault } from './utils'
export class Tag extends Component {

  state = {
    isLoading: false
  }

  handleDelete = async () => {
    this.setState({
      isLoading: true
    })
    try {
      await Promise.resolve(this.props.handleDelete(this.props.tag));
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isLoading: false
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tag?.isLoading !== this.props.tag?.isLoading) {
      this.setState({
        isLoading: !!nextProps.tag?.isLoading
      })
    }
  }

  defaultRenderer = (tag) => (
    <div>
      <span className={cx(
        s.tag_item_name
      )}>#{tag.value || tag.label}</span>
    </div>
  )

  render() {
    const { tag, render = this.defaultRenderer } = this.props;
    const { isLoading } = this.state;
    const editable = tag.canEdit !== false;
    const deleteAble = editable || this.props.allowDeleteAll;

    const bgc = computeColorOrDefault(tag);
    return (
      <div className={cx(s.tag, isLoading ? s.loading : "")}>
        <Chip title={tag.value} className={s.tag_chip} onRequestDelete={deleteAble && this.props.handleDelete ? this.handleDelete : undefined} backgroundColor={bgc}>
          {
            tag.system
            ? (
              <CogIcon style={{ width: 20, minWidth: 20, minHeight: 20, height: 20, marginRight: 6 }}></CogIcon>
            )
            : null
          }
          {
            render(tag, bgc)
          }
          {
            isLoading && <CircularProgress className={s.loading_tag_item}></CircularProgress>
          }
        </Chip>
      </div>
    )
  }
}

export default withStyles(s)(Tag);
