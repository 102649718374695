
import { User } from '../core/api/api';
import { HOME_GENERIC_CONTACT_FORM } from '../constants/home';

export function sendDemoRequest(feedback) {
  try {
    window.dataLayer.push({'event':'send-form-success'});
  }
  catch(e) {
    console.error(e);
  }

  return {
    type: HOME_GENERIC_CONTACT_FORM,
    promise: User.sendFeedback(feedback),
    successMessage: 'Thank you, your message has been sent successfully!',
  };
}
