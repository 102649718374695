import type {Consumption, FuelPrices, LegI, Cargo, PricesI} from '../types';
import {carbonEmissionByFuelTypeMap, isEcaPort, or0} from '../utils';
import dictionaries from "../../dictionaries";

const engines = ['main', 'aux'];

function isEuPort(port) {
  if (!port) {
    return false;
  }
  
  const eu = dictionaries.countries.find(c => c.code === "eu_ets");
  if (eu) {
    return eu.members.some(c => c._id === port.country?._id);
  }

  return false;
}

export default function calculateRotationTotals(legList: LegI[], cons: Consumption, fuelPrices: FuelPrices, cargoList: Cargo[], prices: PricesI ):
  {duration: number, totalDa: number, totalBunker: number, atSea: number, work: number, atPort: number, idle: number,
    tt: number , bunkersByFuelType: {[string]:{quantity:number, cost: number}}}
{
  let duration = 0;
  let totalDa = 0;
  let atSea = 0;
  let work = 0;
  let atPort = 0;
  let idle = 0;
  let additionalIdleDays = 0;
  let tt = 0;
  let totalBunker = 0;
  let totalCanal= 0;
  let bunkersByFuelType = {};
  let totalDistance = 0;
  let totalSecaDistance = 0;
  bunkersByFuelType[cons.main.normal.type] = { quantity:0, cost: 0 } ;
  bunkersByFuelType[cons.aux.normal.type] = { quantity:0, cost: 0 };
  bunkersByFuelType[cons.main.eca.type] = { quantity:0, cost: 0 };
  bunkersByFuelType[cons.aux.eca.type] = { quantity:0, cost: 0 };
  for (let i = 0; i < legList.length; i++) {
    const leg = legList[i];
    atSea = atSea + or0(leg.atSeaDays) + or0(leg.atSeaLsDays);
    work = work + or0(leg.workDays);
    idle = idle + or0(leg.idleDays) + or0(leg.viaIdleDays) + or0(leg.additionalIdleDays);
    additionalIdleDays = additionalIdleDays + or0(leg.additionalIdleDays);
    tt = tt + or0(leg.tt);
    atPort = atPort + or0(leg.atPortDays) + or0(leg.viaIdleDays);

    totalDa = totalDa + or0(leg.da);
    totalDa = totalDa + or0(leg.viaDa);
    totalCanal = totalCanal + or0(leg.viaDa);
    if (leg.port?.isChannel) {
      totalCanal = totalCanal + or0(leg.da);
    }


    const isEcaAtPort = isEcaPort(leg.port);
    let workDays = or0(leg.workDays);
    let idleDays = or0(leg.idleDays) + (or0(leg.tt)/24) + or0(leg.viaIdleDays) + or0(leg.additionalIdleDays);
    const bunkersByModeByType = {
      sea: {
        [cons.main.normal.type]: { quantity: 0, cost: 0 },
        [cons.aux.normal.type]: { quantity: 0, cost: 0 },
        [cons.main.eca.type]: { quantity: 0, cost: 0 },
        [cons.aux.eca.type]: { quantity: 0, cost: 0 },
      },
      port: {
        [cons.main.normal.type]: { quantity: 0, cost: 0 },
        [cons.aux.normal.type]: { quantity: 0, cost: 0 },
        [cons.main.eca.type]: { quantity: 0, cost: 0 },
        [cons.aux.eca.type]: { quantity: 0, cost: 0 },
      }};
    for (let j = 0; j < engines.length; j++) {
      const engine = engines[j];
      let days = or0(leg.atSeaDays);
      let ecaDays = or0(leg.atSeaLsDays);
      const c = cons[engine];
      if(leg.speedType === 'ballastSpeed') {
        bunkersByFuelType[c.normal.type].quantity += days * or0(c.normal.ballast);
        bunkersByFuelType[c.eca.type].quantity += ecaDays * or0(c.eca.ballast);
        bunkersByModeByType.sea[c.normal.type].quantity += days * or0(c.normal.ballast);
        bunkersByModeByType.sea[c.eca.type].quantity += ecaDays * or0(c.eca.ballast);
      }else {
        bunkersByFuelType[c.normal.type].quantity += days * or0(c.normal.laden);
        bunkersByFuelType[c.eca.type].quantity += ecaDays * or0(c.eca.laden);
        bunkersByModeByType.sea[c.normal.type].quantity += days * or0(c.normal.laden);
        bunkersByModeByType.sea[c.eca.type].quantity += ecaDays * or0(c.eca.laden);
      }
      if (isEcaAtPort) {
        bunkersByFuelType[c.eca.type].quantity += workDays * or0(c.eca.working);
        bunkersByFuelType[c.eca.type].quantity += idleDays * or0(c.eca.idle);
        bunkersByModeByType.port[c.eca.type].quantity += workDays * or0(c.eca.working);
        bunkersByModeByType.port[c.eca.type].quantity += (idleDays - or0(leg.viaIdleDays)) * or0(c.eca.idle); //subtract viaIdleDays from port bunkers and add to sea bunkers to rearrange between cargoes
        bunkersByModeByType.sea[c.eca.type].quantity += or0(leg.viaIdleDays) * or0(c.eca.idle);
      } else {
        bunkersByFuelType[c.normal.type].quantity += workDays * or0(c.normal.working);
        bunkersByFuelType[c.normal.type].quantity += idleDays * or0(c.normal.idle);
        bunkersByModeByType.port[c.normal.type].quantity += workDays * or0(c.normal.working);
        bunkersByModeByType.port[c.normal.type].quantity += (idleDays - or0(leg.viaIdleDays)) * or0(c.normal.idle); //subtract viaIdleDays from port bunkers and add to sea bunkers to rearrange between cargoes
        bunkersByModeByType.sea[c.normal.type].quantity += or0(leg.viaIdleDays) * or0(c.normal.idle);
      }

    }
    totalDistance += or0(leg.distance);
    totalSecaDistance += or0(leg.lsDistance);
    let atSeaBunkersConsumed = 0;
    let atSeaCarbonEmission = 0;
    let atPortBunkersConsumed = 0;
    let atPortCarbonEmission = 0;
    for (const [key, value] of Object.entries(bunkersByModeByType.sea)) {
      atSeaBunkersConsumed += value.quantity;
      atSeaCarbonEmission += value.quantity * (carbonEmissionByFuelTypeMap[key] ?? 3);
    }
    for (const [key, value] of Object.entries(bunkersByModeByType.port)) {
      atPortBunkersConsumed += value.quantity;
      atPortCarbonEmission += value.quantity * (carbonEmissionByFuelTypeMap[key] ?? 3);
    }
    leg.atSeaBunkersConsumed = atSeaBunkersConsumed;
    leg.atSeaCarbonEmission = atSeaCarbonEmission;
    leg.atPortBunkersConsumed = atPortBunkersConsumed;
    leg.atSeaTotalCarbonEmission = atSeaCarbonEmission;
    leg.atPortTotalCarbonEmission = atPortCarbonEmission;
    leg.atPortCarbonEmission = atPortCarbonEmission;
    const isCurrentPortInEu = isEuPort(leg.port);
    const isPrevPortInEu = isEuPort(leg.prevPort);
    if (!isCurrentPortInEu) {
      leg.atPortCarbonEmission = 0;
    }
    leg.atSeaCarbonEmission *= ((0.5 * isPrevPortInEu) + (0.5 * isCurrentPortInEu));
  }
  for (const [key, value] of Object.entries(bunkersByFuelType)) {
    value.cost = value.quantity * or0(fuelPrices[key]);
    totalBunker += value.cost;
  }

  duration = atSea + work + idle + tt/24;
  const totalCarbonCost = rearrangeCarbonEmission(legList, cargoList, prices);
  return {duration, atSea, work, atPort, idle, tt , totalBunker, totalDa, bunkersByFuelType, totalCanal, additionalIdleDays, totalDistance, totalSecaDistance, totalCarbonCost};
}

function rearrangeCarbonEmission(legList: LegI[], cargoList: Cargo[], prices: PricesI){

  for (let i = 0; i < legList.length; i++) {
    const leg = legList[i];
    if (leg.type !== 'bunkering') {
      continue;
    }
    const legsToRearrange = [];
    let totalBunkersConsumed = 0;
    for (let j = i + 1; j < legList.length; j++) {
      const nextLeg = legList[j];
      if (nextLeg.type === 'bunkering') {
        break;
      }
      totalBunkersConsumed += nextLeg.atSeaBunkersConsumed + nextLeg.atPortBunkersConsumed;
      legsToRearrange.push(nextLeg);
    }
    for (let j = 0; j < legsToRearrange.length; j++) {
      const toRearrangeLeg = legsToRearrange[j];
      const atSeaCoeff = (toRearrangeLeg.atSeaBunkersConsumed) / totalBunkersConsumed;
      const atPortCoeff = (toRearrangeLeg.atPortBunkersConsumed) / totalBunkersConsumed;
      toRearrangeLeg.atSeaBunkersConsumed += atSeaCoeff * (leg.atSeaBunkersConsumed + leg.atPortBunkersConsumed);
      toRearrangeLeg.atPortBunkersConsumed += atPortCoeff * (leg.atSeaBunkersConsumed + leg.atPortBunkersConsumed);
      toRearrangeLeg.atSeaCarbonEmission += atSeaCoeff * (leg.atSeaCarbonEmission + leg.atPortCarbonEmission);
      toRearrangeLeg.atPortCarbonEmission += atPortCoeff * (leg.atSeaCarbonEmission + leg.atPortCarbonEmission);
    }
    if (legsToRearrange.length) {
      leg.atSeaBunkersConsumed = 0;
      leg.atPortBunkersConsumed = 0;
      leg.atSeaCarbonEmission = 0;
      leg.atPortCarbonEmission = 0;
    }

  }

    const cargoMap = cargoList.reduce((acc, cargo) => {
      acc[cargo._id] = cargo;
      cargo.atPortBunkersConsumed = 0;
      cargo.atSeaBunkersConsumed = 0;
      cargo.atSeaCarbonEmission = 0;
      cargo.atPortCarbonEmission = 0;
      cargo.atSeaTotalCarbonEmission = 0;
      cargo.atPortTotalCarbonEmission = 0;
      return acc;
    }, {});
  for (let i = 0; i < legList.length; i++) {
    const leg = legList[i];
    let atPortBunkersConsumed = leg.atPortBunkersConsumed;
    let atSeaBunkersConsumed = leg.atSeaBunkersConsumed;
    let atPortCarbonEmission = leg.atPortCarbonEmission;
    let atSeaCarbonEmission = leg.atSeaCarbonEmission;
    let atPortTotalCarbonEmission = leg.atPortTotalCarbonEmission;
    let atSeaTotalCarbonEmission = leg.atSeaTotalCarbonEmission; 
    if (leg.cargoId && cargoMap[leg.cargoId]) {
      const cargo = cargoMap[leg.cargoId];
      cargo.atPortBunkersConsumed = or0(cargo.atPortBunkersConsumed) + or0(atPortBunkersConsumed);
      cargo.atPortCarbonEmission = or0(cargo.atPortCarbonEmission) + or0(atPortCarbonEmission);
      cargo.atPortTotalCarbonEmission = or0(cargo.atPortTotalCarbonEmission) + or0(atPortTotalCarbonEmission);
      atPortBunkersConsumed = 0;
      atPortCarbonEmission = 0;
      atPortTotalCarbonEmission = 0;
    }
    if (atPortBunkersConsumed || atSeaBunkersConsumed) {
      const onBoard = leg.onBoard;
      let cargoFrtMap;
      let totalFrtOnBoard;
      if (onBoard.before.frt){
        totalFrtOnBoard = onBoard.before.frt;
        cargoFrtMap = onBoard.before.cargoFrtMap;
      } else { // ballast
        let nextLoading;
        for (let j = i; j < legList.length; j++) {
          const nextLeg = legList[j];
          if (nextLeg.onBoard.after.frt) {
            nextLoading = nextLeg;
            if (nextLeg.nextPort?._id !== nextLeg.port._id) {
              break;
            }
          }
        }
        if (!nextLoading) { //no cargoes on board, and no cargoes at next legs, go back and find prev discharging
          let prevDischarging;
          for (let j = i; j > 0; j--) {
            const prevLeg = legList[j];
            if (prevLeg.onBoard.before.frt) {
              prevDischarging = prevLeg;
              if (prevLeg.prevPort?._id !== prevLeg.port._id) {
                break;
              }
            }
          }
          if (prevDischarging) {
            totalFrtOnBoard = prevDischarging.onBoard.before.frt;
            cargoFrtMap = prevDischarging.onBoard.before.cargoFrtMap;
          }
        } else {
          totalFrtOnBoard = nextLoading.onBoard.after.frt;
          cargoFrtMap = nextLoading.onBoard.after.cargoFrtMap;
        }

      }
      for (const [cargoId, frt] of Object.entries(cargoFrtMap || {})) {
        const cargo = cargoMap[cargoId];
        const coeff = frt / totalFrtOnBoard;
        cargo.atSeaBunkersConsumed = or0(cargo.atSeaBunkersConsumed) + (coeff * atSeaBunkersConsumed);
        cargo.atSeaCarbonEmission = or0(cargo.atSeaCarbonEmission) + (coeff * atSeaCarbonEmission);
        cargo.atSeaTotalCarbonEmission = or0(cargo.atSeaTotalCarbonEmission) + (coeff * atSeaTotalCarbonEmission);
        cargo.atSeaBunkersConsumed = or0(cargo.atSeaBunkersConsumed) + (coeff * atPortBunkersConsumed); // at port cons does not relate directly to the cargo, count as at sea
        cargo.atSeaCarbonEmission = or0(cargo.atSeaCarbonEmission) + (coeff * atPortCarbonEmission);
        cargo.atSeaTotalCarbonEmission = or0(cargo.atSeaTotalCarbonEmission) + (coeff * atPortTotalCarbonEmission);
      }
      atSeaBunkersConsumed = 0;
      atSeaCarbonEmission = 0;
      atSeaTotalCarbonEmission = 0;
    }
  }
  let totalCarbonCost = 0;
  for (let i = 0; i < cargoList.length; i++) {
    const cargo = cargoList[i];
    cargo.ttlConsumption = cargo.atSeaBunkersConsumed + cargo.atPortBunkersConsumed;
    cargo.ttlCarbon = cargo.atSeaCarbonEmission + cargo.atPortCarbonEmission;
    cargo.ttlTotalCarbon = cargo.atSeaTotalCarbonEmission + cargo.atPortTotalCarbonEmission;
    cargo.carbonYear = cargo.carbonYear || 2024;
    cargo.carbonPrice = cargo.carbonPrice || prices?.co2EURPrice || 0;
    cargo.carbonCost = cargo.ttlCarbon * cargo.carbonPrice * carbonYearDiscountMap[cargo.carbonYear];
    totalCarbonCost += cargo.carbonCost;
  }
  return totalCarbonCost;
}

const carbonYearDiscountMap = {
  2024: 0.4,
  2025: 0.7,
  2026: 1,
};
