import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { debounceWithoutFirstCall as debounce } from '../../core/utils';
import { CardTitle } from "material-ui/Card";
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Chip as _Chip } from 'material-ui';
import BasicCard from '../Common/CustomAutocomplete/BasicCard';
import BasicAutocompleteList from '../Common/CustomAutocomplete/BasicAutocompleteList';
import { MenuItem } from 'material-ui/Menu';
import topics from './topics';

const InputContainer = styled.div`
  width: 100%;
  min-height: 41px;
  max-height: 101px;
  background: #fff;
  border-bottom: 1px solid var(--stroke-light-gray1);
  overflow-y: auto;
  flex-wrap: wrap;
  display: flex;

  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    ${
      props => props.invalid && css`border-bottom: 1px solid var(--text-warning) !important;z-index: 2`
    }
  }
  &:focus-within {
    &:after {
      border-bottom: 1px solid var(--text-green-light);
    }
  }
  .tooltip_error {
    right: -8px !important;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  flex: 1;
  min-width: 120px;
  padding: 9px 0px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  &::placeholder {
    color: var(--text-light);
  }
`;

const Card = styled(BasicCard)`
  margin-top: 6px;
`;

const Chip = styled(_Chip)`
  height: 24px;
  overflow: hidden;
  align-items: center;
  margin: 7px 8px 0px 0px !important;
  span {
    padding-right: 8px !important;
    font-size: 12px !important;
    font-weight: 400;
    color: var(--default-dark-text) !important;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
    transform: scale(1.2);
    margin: 0px 8px 0px 0px !important;
  }
`;

const CC = styled.span`
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
  margin: 10px 4px 0 0px;
  height: 20px;
  color: var(--text-dark333);
  line-height: 20px;
`;

class TopicAutocomplete extends Component {

  static contextTypes = {
    showMessage: PropTypes.func,
  }

  state = {
    dataSource: [],
    isLoading: false,
    open: false,
  }

  handleChange = (ev) => {
    const { value } = ev.target;

    this.setState({
      isLoading: true,
      inputValue: value,
      open: true,
    });

    this.loadTopics(value);
  }

  loadTopics = debounce(async value => {
    const res = {
      data: topics.filter(
        topic => topic.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1,
      ),
    };

    if (res?.data) {
      this.setState({
        dataSource: res.data,
        isLoading: false,
      });
    }
  })

  addCustomTopic = (ev) => {
    if (ev.key === "Enter") {
      if (ev.target.value.length > 2) {
        this.addChip(this.createChipObj(ev.target.value));
      }
    }
  }

  createChipObj = val => ({
    name: val,
    id: uuidv4(),
  })


  addChip = (topic) => {
    this.props.handleAddChip(topic);
    this.setState({ dataSource: [], inputValue: "", open: false });
  }

  render() {
    const { setAnchorEl, className = "", chips, handleDeleteChip, label } = this.props;
    let placeholder = chips.length ? "Add more" : "Please enter issue topics";
    if (chips.length >= 20) {
      placeholder = "Allow up to 20 topics";
    }

    const { dataSource, isLoading, open } = this.state;

    return (
      <div ref={setAnchorEl} className={className} style={{ position: "relative" }} >
        <div style={{ position: "relative" }}>
          <InputContainer label={label}>
            {
              label && <CC>{label}</CC>
            }
            {
              chips.map((chip, i) => (
                <Chip
                  id={chip.id}
                  onRequestDelete={() => handleDeleteChip(chip.id)}
                >
                {
                  chip.name
                }
              </Chip>))
            }
            <Input
              placeholder={placeholder}
              value={this.state.inputValue}
              onChange={this.handleChange}
              onKeyDown={this.addCustomTopic}
              innerRef={el => this.input = el}
              disabled={chips.length >= 20}
            />

          </InputContainer>
          <Card
            open={open}
            adjustRef={node => (this.select = node)}
            showLoader={isLoading}
          >
            <div>
              <BasicAutocompleteList
                fallback={isLoading ? null :
                  <CardTitle
                    style={{ padding: "12px" }}
                    subtitle={"No topics found"}
                  />
                }
                list={dataSource}
                renderItem={listItem => (
                  <MenuItem
                    style={{
                      minHeight: '32px',
                      lineHeight: '32px',
                      height: '32px',
                      fontSize: '12px',
                      fontWeight: '400',
                      border: '1px solid #E0E0E0',
                      borderTop: 'none',
                    }}
                    onClick={this.addChip.bind(this, listItem)}
                  >
                    {listItem.name}
                  </MenuItem>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default TopicAutocomplete;
