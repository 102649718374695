/** @name INVOICES_GET_LIST_REDUCER */
export default function INVOICES_GET_LIST(state, { payload }) {
  const newInvoicesList = { ...state.invoicesList, loading: false };
  if (payload) {
    const { res, params } = payload;
    if (!res.data) {
      return state;
    }
    let next = null;
    if (res.data.length === 50) {
      next = params.page + 1;
    }
    if (params.filters) {
      const filters = { ...newInvoicesList.filters, ...params.filters };
      
      newInvoicesList.filters = filters;
    }
    if (params.page === 1) {
      newInvoicesList.data = res.data;
    } else {
      newInvoicesList.data = [...newInvoicesList.data, ...res.data];
    }
    newInvoicesList.page = params.page;
    newInvoicesList.next = next;
  }
  const newState = { invoicesList: newInvoicesList };
  return { ...state, ...newState };
}
