import React from 'react'
import { currencyMap } from '../../Contracts/shared/Freight';
import { Text } from '../../Contracts/shared/styled';
import { terms, units } from '../Request/Opts';
import Label from '../shared/Label';
import { Col } from '../shared/preview';
import Row from '../shared/Row';
import Value from '../shared/Value';
import { formatNumberOrEmpty, number } from '../../../core/format';

export const currencyMapper = currency => currency ? currencyMap[currency] : '$'

function FreightView({ freights, charges = [], demurrage = {}, detention = {} }) {
  return (
    <Col>
      <Freights freights={freights} />
      <Charges charges={charges} />
      <DemurrageDetention value={demurrage.demurrage} opts={demurrage.opts} />
      <DemurrageDetention label="detention" value={detention.detention} opts={detention.opts} />
    </Col>
  )
}

function Freights({ freights, ...rest }) {
  return <Col>
    {freights.map((f, i) =>
      (<Row {...rest}>
        <Label>
          {i === 0 ? 'FREIGHT' : null}
        </Label>
        <Value>
          <Text>
            {
              `${currencyMapper(f.currency)}${number(f.value)} ${f.term || ''}, ${f.method || '---'}, ${f.details || '---'}`
            }
          </Text>
        </Value>
      </Row>))}
  </Col>
}
function Charges({
  charges,
  ...rest
}) {
  if (!charges?.length) return null;

  return (
    <Col>
      {
        charges.map(charge => charge?.type || charge?.value ? (
          <Row>
            <Label>
              {
                charge.type
              }
            </Label>
            <Value>
              <Text>
                {
                  `${currencyMapper(charge.currency)}${number(charge.value) || '---'} ${charge.term || '---'}, ${charge.details || '---'}`
                }
              </Text>
            </Value>
          </Row>
        ) : null)
      }
    </Col>
  )
}

function DemurrageDetention({ label = 'demurrage', value, opts }) {

  let content = '';

  if (typeof value === 'number') {

    const unit = opts.timeUnit && units.find(unit => unit.value === opts.timeUnit);
    const term = opts.terms && terms.find(term => term.value === opts.terms);
    const termStr = term?.label ? `, ${term.label}` : '';
    content = `${currencyMapper(opts.currency)}${formatNumberOrEmpty(value, '0')} ${unit ? unit.label : '---'}${termStr}`;
  }

  return (
    <Row>
      <Label>
        {
          label
        }
      </Label>
      <Value>
        <Text>
          {
            content
          }
        </Text>
      </Value>
    </Row>
  )
}

export default FreightView;
