import { prepareStatusFilter } from '../components/Contracts/Invoice/utils';
import * as constants from '../constants/invoices';
import Mercurion from '../core/api/Mercurion';

export function getInvoicesList(page = 1, filters = {}) {
  return async (dispatch, getState) => {
    dispatch({
      type: constants.INVOICES_GET_LIST_REQUEST,
    });
    try {
      const invoices = await Mercurion.getInvoicesList({ page, filters });
      dispatch({
        type: constants.INVOICES_GET_LIST,
        payload: {
          res: invoices,
          params: { page, filters },
        },
      });
    } catch (error) {
      console.error(error.message);
    }
  };
}

export function selectInvoices(selectedIds, value) {
  return {
    type: constants.INVOICES_SELECT,
    payload: {
      selectedIds,
      value,
    },
  };
}

export function getInvoice(id,token) {
  console.log('Dispatching getInvoice with ID:', id);
  return {
    type: constants.INVOICE_GET,
    payload: id,
    promise: Mercurion.getInvoiceData(id,token),
  };
}

export function getInvoicePreview(id,accessToken){
  return {
    type: constants.INVOICE_GET_PREVIEW,
    payload: id,
    promise: Mercurion.getInvoicePreview(id,accessToken),
  };
}

function refreshInvoice(id) {
  return function (res) {
    return function (dispatch, getState) { dispatch(getInvoice(id)); };
  };
}

export function createInvoice(data) {
  return {
    type: constants.INVOICE_CREATE,
    payload: data,
    promise: Mercurion.createInvoice(data),
  };
}

export function updateInvoice(id,data){
  return {
    type: constants.INVOICE_UPDATE,
    payload: data,
    promise: Mercurion.updateInvoice(id,data),
  }
}

export function deleteInvoice(id){
  return {
    type: constants.INVOICE_DELETE,
    payload: id,
    promise: Mercurion.deleteInvoice(id)
  }
}

export function postInvoiceAction(id,key,args={}){
  return {
    type: constants.INVOICE_POST_ACTION,
    payload: id,
    promise: Mercurion.postAction(id,key,args),
  }
}