import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Arrow = props => (
  <span {...props}>
    <svg width={props.iconWidth || '24px'} height={props.iconHeight || '24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.6077 12.75L11.8038 6L17 12.75H13.8038V19H9.80385V12.75H6.6077Z" fill="black" fillOpacity="0.4"/>
    </svg>
  </span>
);

Arrow = pure(Arrow);
Arrow.displayName = 'Arrow';
Arrow.muiName = 'SvgIcon';

export default Arrow;
