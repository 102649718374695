import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Ship = props => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1">
        <defs />
        <g
          id="WEB"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity="0.4"
        >
          <g
            id="Menu-Opened"
            transform="translate(-16.000000, -210.000000)"
            fillRule="nonzero"
            fill="#FFFFFF"
          >
            <g id="Icons/Ship" transform="translate(13.000000, 208.000000)">
              <path
                d="M19.1935631,3.78868518 C17.9534081,3.78868518 16.6894039,4.21796962 15.5923436,4.98114196 C13.3982232,3.43094814 10.5840251,3.43094814 8.38990466,4.98114196 C7.29284442,4.21796962 6.05268937,3.78868518 4.78868518,3.78868518 L3,3.78868518 L3,2 L4.78868518,2 C6.02884023,2 7.24514615,2.31003876 8.38990466,2.88241802 C10.6555726,1.71381037 13.3266757,1.71381037 15.5923436,2.88241802 C16.713253,2.28618963 17.9534081,2 19.1935631,2 L20.9822483,2 L20.9822483,3.78868518 L19.1935631,3.78868518 Z M4.76483604,5.57737035 L4.81253431,5.57737035 C6.24348245,5.57737035 7.53133578,6.36439183 8.4137538,7.36605553 C9.29617182,6.36439183 10.560176,5.57737035 12.0149733,5.57737035 C13.4459214,5.57737035 14.7337748,6.36439183 15.6161928,7.36605553 C16.4986108,6.36439183 17.762615,5.57737035 19.2174123,5.57737035 L19.2651105,5.57737035 L20.9583992,11.5873525 C21.0299466,11.8258439 21.0060974,12.0643353 20.9107009,12.2789775 C20.7914552,12.4936197 20.6006621,12.6605636 20.3621708,12.7321111 L19.2174123,13.1136972 L19.2174123,17.2634468 C19.2174123,18.2412614 18.4065416,19.052132 17.4287271,19.052132 L14.7099256,19.052132 L14.7099256,21.7470843 L9.32002095,21.7470843 L9.32002095,19.052132 L6.62506862,19.052132 C5.64725406,19.052132 4.83638345,18.2412614 4.83638345,17.2634468 L4.83638345,13.1136972 L3.6677758,12.7321111 C3.42928444,12.6605636 3.23849136,12.4936197 3.11924568,12.2789775 C3,12.0643353 2.97615086,11.8019948 3.07154741,11.5873525 L4.76483604,5.57737035 Z M6.62506862,17.2634468 L17.4048779,17.2634468 L17.4048779,13.6860765 L12.0149733,15.4509125 L6.62506862,13.6860765 L6.62506862,17.2634468 L6.62506862,17.2634468 Z"
                id="Shape"
                transform="translate(12.000000, 11.873542) scale(1, -1) translate(-12.000000, -11.873542) "
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  </span>
);

Ship = pure(Ship);
Ship.displayName = 'Ship';
Ship.muiName = 'SvgIcon';

export default Ship;
