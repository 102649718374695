export const floatingLabelStyle = { width: '130px', fontSize: '12px', color: '#999999' };
export const floatingLabelFocusStyle = { width: '130px', fontSize: '14px' };
export const floatingLabelShrinkStyle = { fontSize: '14px' };
export const inputStyle = { fontSize: '14px', color: 'rgba(#000000, .87)' };
export const CRANE_GROUPS = ['0-24', '25-49', '50-99', '100-149', '150+'];
export const availableFacilities = [
  [
    {
      label: 'Break Bulk',
      name: 'breakBulk',
    },
    {
      label: 'Container',
      name: 'container',
    },
    {
      label: 'Liquid',
      name: 'liquid',
    },
    {
      label: 'Dry Bulk',
      name: 'dryBulk',
    },
    {
      label: 'Passenger',
      name: 'passenger',
    },
  ],
  [
    {
      label: 'Dry Dock',
      name: 'dryDock',
    },
    {
      label: 'LPG',
      name: 'lpg',
    },
    {
      label: 'Bunkering',
      name: 'bunkering',
    },
    {
      label: 'Shipyard',
      name: 'shipyard',
    },
    {
      label: 'Multipurpose',
      name: 'multipurpose',
    },
  ],
  [
    {
      label: 'Ro-Ro',
      name: 'roro',
    },
    {
      label: 'LNG',
      name: 'lng',
    },
    {
      label: 'Steel & non ferrous products',
      name: 'steel',
    },
    {
      label: 'Reefer',
      name: 'reefer',
    },
  ],
];
export const WAREHOUSES = [
  [
    {
      label: 'Timber',
      name: 'timber',
    },
    {
      label: 'Grain',
      name: 'grain',
    },
    {
      label: 'LPG',
      name: 'lpg',
    },
    {
      label: 'Chemicals',
      name: 'chemicals',
    },
  ],
  [
    {
      label: 'Dry bulk (ore, coal, etc)',
      name: 'dryBulk',
    },
    {
      label: 'Liquid',
      name: 'liquid',
    },
    {
      label: 'General cargo',
      name: 'general',
    },
    {
      label: 'Reefer cargo',
      name: 'reefer',
    },
  ],
];
export const ALL_WAREHOUSES = WAREHOUSES[0].concat(WAREHOUSES[1]);
export const timezoneList = [
  'GMT -12',
  'GMT -11',
  'GMT -10',
  'GMT -9:30',
  'GMT -9',
  'GMT -8:30',
  'GMT -8',
  'GMT -7',
  'GMT -6',
  'GMT -5',
  'GMT -4',
  'GMT -3:30',
  'GMT -3',
  'GMT -2',
  'GMT -1',
  'GMT Z',
  'GMT +1',
  'GMT +2',
  'GMT +3',
  'GMT +3:30',
  'GMT +4',
  'GMT +4:30',
  'GMT +5',
  'GMT +5:30',
  'GMT +6',
  'GMT +6:30',
  'GMT +7',
  'GMT +8',
  'GMT +9',
  'GMT +9:30',
  'GMT +10',
  'GMT +10:30',
  'GMT +11',
  'GMT +11:30',
  'GMT +12',
];

export const restrictionsService = [
  {
    name: "berth",
    label: "Berth Name / Terminal",
    width: "128px",
    validations: "maxLength:100",
    validationError: "max 100 characters",
    isString: true
  },
  {
    name: 'maxDraught',
    label: 'Max Draft, m:',
    width: '100px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
  {
    name: 'maxBeam',
    label: 'Max Beam, m:',
    width: '100px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
  {
    name: 'maxLOA',
    label: 'Max LOA, m:',
    width: '100px',
    validations: 'isNumeric,min:1,max:1000',
    validationError: '1 - 1000',
  },
  {
    name: 'maxDWT',
    label: 'Max DWT, mt:',
    width: '100px',
    validations: 'isNumeric,min:1,max:1000000',
    validationError: '1 - 1000000',
  },
  {
    name: 'channelDepth',
    label: 'Channel Depth, m:',
    width: '125px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
  // {
  //   name: 'anchorageDepth',
  //   label: 'Anchorage Depth, m:',
  //   width: '128px',
  //   validations: 'isNumeric,min:0,max:100',
  //   validationError: '0 - 100',
  // },
];
export const restrictions = [
  {
    label: 'Max Draft, m:',
    name: 'maxDraught',
    width: '142px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
  {
    label: 'Max Beam, m:',
    name: 'maxBeam',
    width: '142px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
  {
    label: 'Max LOA, m:',
    name: 'maxLOA',
    width: '142px',
    validations: 'isNumeric,min:1,max:1000',
    validationError: '1 - 1000',
  },
  {
    label: 'Max DWT, mt:',
    name: 'maxDWT',
    width: '142px',
    validations: 'isNumeric,min:1,max:1000000',
    validationError: '1 - 1000000',
  },
  {
    label: 'Channel Depth, m:',
    name: 'channelDepth',
    width: '142px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
  {
    label: 'Anchorage Depth, m:',
    name: 'anchorageDepth',
    width: '142px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
  {
    label: 'Mean Tide, m:',
    name: 'meanTide',
    width: '142px',
    validations: 'isNumeric,min:1,max:100',
    validationError: '1 - 100',
  },
];
export const ACTIVITIES =
["Bunker Trader",
"Classification Society & Flag State representative",
"Marine equipment & spare parts",
"P & I","Pilotage",
"Port Agency",
"Port Authority",
"Port Operator",
"Repairer & Shipbuilder",
"Salvage & Rescue",
"Ship Chandler",
"Stevedoring Company",
"Survey Company",
"Towage"];
