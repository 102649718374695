import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './ComponentsIco.scss';
import Media from "react-media";

const press = [
  {
    "link": "https://www.heavyliftpfi.com/invest-in-shipnext-to-help-shape-the-future-of-the-shipping-industry/18064.article",
    "path": "/images/press/heavy_lift_logo.png",
  },
  {
    link: "https://medium.com/@alexandervarvarenko/shipnext-facilitates-digital-interconnectivity-between-ports-and-ships-f98133c05fd6",
    path: "/images/press/medium@2x.png",
  },
  {
    link: "http://www.ship2shore.it/en/shipping/varvarenko-finds-how-to-accelerate-his-tool_71826.htm",
    path: "/images/press/ship2shore@2x.png",
  },
  {
    "link": "https://lloydslist.maritimeintelligence.informa.com/LL1121079/Will-2018-be-the-year-of-shipping-Initial-Coin-Offerings",
    "path": "/images/press/lloyds_list@2x.png",
  },
  {
    "link": "https://www.hansa-online.de/2017/10/schiffstechnik/85555/digitaler-marktplatz-fuer-die-schifffahrt-eingefuehrt/",
    "path": "/images/press/hansa@2x.png",
  },
  {
    "link": "http://blue-rocket.de/shipnext-digitaler-marktplatz-fuer-schiffsfracht/",
    "path": "/images/press/blue_rocket@2x.png",
  },
  {
    "link": "https://www.dvz.de/rubriken/see/single-view/nachricht/ein-sowjetplan-als-basis-fuer-ein-start-up.html",
    "path": "/images/press/dvz@2x.png",
  },
  {
    "link": "https://fairplay.ihs.com/safety-regulation/article/4292356/digital-chartering-platform-launched-with-thousands-of-cargoes",
    "path": "/images/press/fairplay@2x.png",
  },
  {
    "link": "http://seanews.co.uk/digital-platform-for-global-freight-market-shipnext-launched/",
    "path": "/images/press/sea_news@2x.png",
  },
  {
    "link": "https://ain.ua/2017/10/02/dragon-capital-shipnext",
    "path": "/images/press/ain@2x.png",
  },
  {
    "link": "https://www.hellenicshippingnews.com/ukraine-based-entrepreneur-launches-global-digital-marketplace-for-shipping/",
    "path": "/images/press/hellenic@2x.png",
  },
  {
    "link": "http://www.marinemec.com/news/view,new-online-platform-will-change-global-freight-markets_49348.htm",
    "path": "/images/press/marine_electronics@2x.png",
  },
  {
    "link": "http://www.digitaljournal.com/tech-and-science/technology/new-online-platform-will-change-global-freight-markets/article/504002",
    "path": "/images/press/digital_journal@2x.png",
  },
  {
    "link": "https://postandparcel.info/84205/news/innovation/shipnext-launch/",
    "path": "/images/press/post_and_parcel@2x.png",
  },
  {
    "link": "http://www.seatrade-maritime.com/news/europe/ukrainian-shipowner-launches-shipping-digital-marketplace-with-bold-claims.html",
    "path": "/images/press/seatrade_maritimeNews@2x.png",
  },
  {
    "link": "http://www.tradewindsnews.com/incoming/1356420/varamar-founder-launches-online-fixing-platform",
    "path": "/images/press/trade_winds@2x.png",
  },
  {
    "link": "https://bbeu2018guide.breakbulk.com/listing/alexander-varvarenko-founder-ceo-shipnext/",
    "path": "/images/press/break_bulk@2x.png",
  },
  {
    "link": "http://mtelegraph.com/en/shipnext-offers-to-save-time-and-money-for-shipping-market-players.html",
    "path": "/images/press/maritime_telegraph@2x.png",
  },
  {
    "link": "http://splash247.com/shipnext-levelseas-meets-uber/",
    "path": "/images/press/splash@2x.png",
  },
  {
    "link": "http://gesellberg.com/ru/%D1%83%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D1%81%D1%8C%D0%BA%D0%B0-%D1%81%D1%83%D0%B4%D0%BD%D0%BE%D0%BF%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0-%D0%BA%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D1%96%D1%8F-%D0%BF%D0%BE%D1%87/",
    "path": "/images/press/gesellberg@2x.png",
  },
  {
    "link": "https://thetractionstage.com/2018/07/25/ep-2-shipnext-alexander-varvarenko/",
    "path": "/images/press/traction_stage@2x.png",
  },
  {
    "link": "https://www.manifoldtimes.com/news/d/shipnext-blockchain-to-solve-the-problem-of-unpaid-bunker-bills-",
    "path": "/images/press/manifold_times@2x.png",
  },
  {
    "link": "http://seanews.ru/2019/02/06/ru-jeto-kak-uber-dlja-gruzoperevozok/",
    "path": "/images/press/Sea News@2x.png",
  },
  {
    "link": "https://www.thehindubusinessline.com/economy/logistics/mjunction-forges-alliance-with-ukraine-based-digital-company/article26312680.ece",
    "path": "/images/press/businessLine@2x.png",
  },
];

class Press extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPress: false,
    };
  }
  handleOpenPress = () => {
    this.setState({ isShowPress: !this.state.isShowPress });
  };
  render() {
    return (
      <div className={s.root}>
        <div className={s.press} id='press'>

          <div className={s.wrapper_container_main_page}>
            <div className={s.title}>
              <h2>SHIPNEXT in Press</h2>
            </div>
            <div className={s.wrapper}>
              <Media queries={{
                small: '(max-width: 1200px)',
                large: '(min-width: 1201px)',
              }}
              >
                {matches => (
                  <div>
                    {matches.small &&
                      <div className={s.wrapper_press}>
                        {press.slice(0, this.state.isShowPress ? press.length : 12).map((item, i) => (
                          <a href={item.link} target="_blank" key={i}>
                            <img src={item.path} />
                          </a>
                        ))
                        }
                      </div>
                    }
                    {matches.large &&
                      <div className={s.wrapper_press}>
                        {press.map((item, i) => (
                          <a href={item.link} target="_blank" key={i}>
                            <img src={item.path} />
                          </a>
                        ))
                        }
                      </div>
                    }
                  </div>
                )}
              </Media>
              <div className={s.wrapper_btn_show}>
                <button
                  className={cx(s.show_more, this.state.isShowPress ? s.more_data : '')}
                  onClick={this.handleOpenPress}
                >
                  {this.state.isShowPress ? 'Hide' : 'Show more'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(Press));
