export default function UPDATE_CONTRACT_DETAILS(state, action) {
  const newState = {};
  const { payload: { contractId, mergeValues = {} } = {} } = action;

  let selectedContract = {
    ...state.selectedContract,
  };

  if (!selectedContract || selectedContract._id !== contractId) {
    return { ...state, ...newState };
  }

  selectedContract = {
    ...selectedContract,
    ...(typeof mergeValues === 'function' ? mergeValues(selectedContract) : mergeValues),
  };

  newState.selectedContract = selectedContract;

  return { ...state, ...newState };
}
