import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import Link from '../../Link/Link';

class SolutionsExchange extends Component {
  render() {
    return (
      <div className={s.solution}>
        <div className={s.tab_block}>
          <div className={s.left_part}>
            <div className={s.wrapper_screen}>
              <img src="/images/solutions_screen/exchange1.png" alt="SHIPNEXT Exchange" />
            </div>
          </div>
          <div className={s.right_part}>
            <h2>
              <span className={s.title}>SHIPNEXT Exchange</span>
            </h2>
            <div>
              <p>SHIPNEXT Exchange is a solution that helps manage email communication, extract valuable data and build up a seamless and efficient bridge between emailing, chat communications, and data management.</p>
              <p>Our users can benefit from a unique feature of SHIPNEXT as a standalone email emailing tool, as well as its interoperability with Outlook, Gmail, Sedna, LgMar, and other email services.</p>
              <p>SHIPNEXT Exchange can be white-labelled to meet the needs of larger companies, for better customisation and “look and feel” experience, and upgraded with customized features which may not yet be available.</p>
            </div>
            <div className={s.wrapper_buttons_tab}>
              <Link className={s.learn_more} to={'/solution-shipnext-exchange'}>Learn more</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(s)(SolutionsExchange);
