/** @name GET_NEWS_LIST */
export default function GET_SELECTED_INDEXES(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    selectedIndexes: action.payload.selectedIndexes,
    setId: action.payload.setId,
    loading: false,
  };

  return { ...state, ...newState };
}
