import React from 'react';
import Home from '../../components/Home';
import Layout from '../../components/Layout';

export default {
    path: "/demo",
    children: [
        {
            path: "/",
            async action() {
                return {
                  title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
                  component: (
                    <Layout>
                      <Home />
                    </Layout>
                  ),
                  public: true,
                };
              },
        },
    ],
};
