import React, {Component} from 'react';
import Autocomplete from "../Common/AutoComplete";
import ContactBookApi from "../../core/api/ContactBookApi";
import styles from "../NewInputs/muistyles";
import NarrowAutocomplete from "../NewInputs/NarrowAutocomplete";

class ContactBookCompanyAutocomplete extends NarrowAutocomplete {
  static defaultProps = {
    forceSelection: false,
    style: styles.selectContainer,
    floatingLabelFocusStyle: styles.text.labelFocus,
    floatingLabelStyle: styles.text.label,
    hintStyle: styles.text.label,
    inputStyle: styles.select.input,
    floatingLabelShrinkStyle: styles.text.labelShrink,
    underlineStyle: styles.text.underline,
    underlineFocusStyle: styles.text.underlineFocus,
    errorStyle: styles.error,
    className: 'select_default',
  }
  constructor(props, context) {
    super(props, context);
    this.Api = {
      find: async (query) => {
        return ContactBookApi.searchCompany({ page: 1, filters: { q: query.name }, sort: {}});
      },
    };
  }
}

export default ContactBookCompanyAutocomplete;
