exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".p22b8 {\n  position: relative;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: end;\n      align-items: flex-end; }\n  .p22b8 .fWf9l {\n    display: -ms-flexbox;\n    display: flex;\n    height: 48px;\n    width: 82px;\n    margin-right: 12px; }\n  .p22b8 .fWf9l > span > div > div:nth-child(4) {\n      position: absolute !important;\n      bottom: -8px !important; }\n  .p22b8 ._11Hxf, .p22b8 ._2PRaI {\n    position: relative;\n    width: 63px;\n    height: 24px;\n    top: -10px;\n    margin-top: -20px;\n    margin-left: 6px; }\n  .p22b8 ._11Hxf > div > div > div, .p22b8 ._2PRaI > div > div > div {\n      margin-right: 10px !important; }\n", ""]);

// Exports
exports.locals = {
	"root": "p22b8",
	"commission": "fWf9l",
	"deductible": "_11Hxf",
	"pus": "_2PRaI"
};