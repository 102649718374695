import { removeFromArray, replaceInArray } from '../../core/utils';

// noinspection JSUnusedGlobalSymbols
/** @name PROFILE_UPSERT_ACCOUNT_REDUCER */
export default function PROFILE_UPSERT_ACCOUNT(state, action) {
  const account = action.payload;
  if (!account._id) {
    account._id = action.res.data._id;
  }
  const newState = { ...state };
  if (!newState.departments) {
    newState.departments = [];
  }

  if (account.default) {
    newState.departments =
      newState.departments.map(a => ({ ...a, default: false }));
  }
  const index = newState.departments.findIndex(a => a._id === account._id);

  if (index === -1) {
    newState.departments = [...newState.departments, account];
  } else {
    newState.departments = replaceInArray(newState.departments, index, account);
  }
  return { ...state, ...newState };
}
