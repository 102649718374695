import type { Totals } from '../types';
import { or0, round } from '../utils';


export default function calculateTotals (fieldName: string, value: number, totals: Totals, force = false) {
  if(totals[fieldName] === value && !force) {
    return totals;
  }
  totals[fieldName] = value;
  console.debug(fieldName, value);
  switch (fieldName) {
    case 'hire':
      return this.calculateTotals('hireWithComm', round(or0(totals.hire) * (1 - (or0(totals.hireComm)/ 100)),0), totals);
    case 'hireComm':
      if(totals.manuallyChangedFields.hire !== undefined || this.alwaysCalcHireWithComm) {
        return this.calculateTotals('hireWithComm', round(or0(totals.hire) * (1 - (or0(totals.hireComm)/ 100)),0), totals);
      } else if (totals.hireWithComm) {
        totals.hire = round(or0(totals.hireWithComm) / (1 - (or0(totals.hireComm)/ 100)), 0);
        return this.calculateTotals('duration', totals.duration , totals, true);
      }
      break;
    case 'hireWithComm':
      totals.hire = round(or0(totals.hireWithComm) / (1 - (or0(totals.hireComm)/ 100)),0);
    case 'duration':
      totals = this.calculateTotals('totalHire', round(or0(totals.duration) * or0(totals.hireWithComm) + or0(totals.bbNet),0), totals )
      totals = this.calculateTotals('totalHireGross', round(or0(totals.duration) * or0(totals.hire) + or0(totals.bbGross),0), totals )
      totals = this.calculateTotals('totalCosts', totals.totalCosts, totals, true ) // force tceV2 recalculation
      break;
    case 'ops':
    case 'totalBunker':
    case 'totalLiner':
    case 'totalDa':
    case 'totalAddress':
    case 'includeCarbonCost':
    case 'totalFrt':
    case 'totalBrokerage':
    case 'totalCarbonCost':
      let totalOps = round(or0(totals.ops) + or0(totals.totalBunker) + or0(totals.totalLiner) + or0(totals.totalDa),0);
      let totalCosts = round(or0(totals.ops) + or0(totals.totalBunker) + or0(totals.totalLiner) + or0(totals.totalDa), 0);
      if (totals.includeCarbonCost) {
        totalOps += or0(totals.totalCarbonCost);
        totalCosts += or0(totals.totalCarbonCost);
      }
      totals = this.calculateTotals('totalOps',totalOps, totals);
      totals = calculateVoyageEquiv(totals);
      return this.calculateTotals('totalCosts', totalCosts,totals);
      break;
    case 'totalOps':
    case 'totalHire':
      totals =  this.calculateTotals('totalExpense', round(or0(totals.totalOps) + or0(totals.totalHire),0), totals)
      totals = calculateVoyageEquiv(totals);
      return totals;
    case 'totalRevenue':
    case 'totalAdditionalCosts':
    case 'totalAdditionalIncome':
      totals = calculateTceV2Gross(totals);
      totals = calculateVoyageEquiv(totals);
    case 'totalExpense':
      return  this.calculateTotals('profit', round(or0(totals.totalRevenue) - or0(totals.totalExpense) - or0(totals.totalAdditionalCosts) + or0(totals.totalAdditionalIncome) ,0), totals)
    case 'profit':
      if(!totals.duration) {
        return totals;
      }
      return  this.calculateTotals('tce', round((or0(totals.totalHire) + or0(totals.profit)) / totals.duration,0), totals)
    case 'tce':
      break;
    case 'bbGross':
      return this.calculateTotals('bbNet', round(or0(totals.bbGross) * (1 - (or0(totals.bbComm)/ 100)),0), totals);
      break;
    case 'bbComm':
      if(totals.manuallyChangedFields.bbGross !== undefined) {
        return this.calculateTotals('bbNet', round(or0(totals.bbGross) * (1 - (or0(totals.bbComm)/ 100)),0), totals);
      } else if (totals.bbNet) {
        totals.bbGross = round(or0(totals.bbNet) / (1 - (or0(totals.bbComm)/ 100)),0)
      }
      break;
    case 'bbNet':
      totals.bbGross = round(or0(totals.bbNet) / (1 - (or0(totals.bbComm)/ 100)),0);
      totals = this.calculateTotals('totalHire', round(or0(totals.duration) * or0(totals.hireWithComm) + or0(totals.bbNet),0), totals )
      totals = this.calculateTotals('totalHireGross', round(or0(totals.duration) * or0(totals.hire) + or0(totals.bbGross),0), totals )
      totals = this.calculateTotals('totalCosts', totals.totalCosts, totals, true ) // force tceV2 recalculation
      break;
    case 'totalRevenueGross':
    case 'totalFreightTons':
    case 'totalCosts':
    case 'averageFreightComm':
      totals = calculateTceV2Gross(totals);
      totals = calculateVoyageEquiv(totals);
      break;

  }
  return totals;
}


function calculateVoyageEquiv(totals: Totals) {
  if(!totals.totalFreightTons) {
    totals.voyEquivGross = "";
    return totals;
  }
  totals.voyEquivGross = ((or0(totals.totalHire) + or0(totals.totalCosts) - or0(totals.totalAdditionalIncome) + or0(totals.totalAdditionalCosts)) / (totals.totalFreightTons || 1)) / (1 - totals.averageFreightComm/100);
  return totals;
}

function calculateTceV2Gross(totals: Totals) {
  if(!totals.duration) {
    totals.tceV2Gross = 0;
    return totals;
  }
  const tceV2Gross = round(((or0(totals.totalRevenue) - or0(totals.totalCosts) - or0(totals.totalAdditionalCosts) + or0(totals.totalAdditionalIncome)) / totals.duration) / (1 - (or0(totals.hireComm)/ 100)) );
  totals.tceV2Gross = tceV2Gross;
  return totals;
}
