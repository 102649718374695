/*
 * @Author: salterok
 * @Date: 2017-10-09 18:24:47
 * @Last Modified by: salterok
 * @Last Modified time: 2017-10-16 18:36:02
 */

import PropTypes from 'prop-types';

import React from "react";
import { connect } from "react-redux";
import ChatOpener from "../Icons/StartChatIcon";
import s from './Chat.scss';

import { ChatApi } from "../../core/api/Chat";
import { showPreparedRoom, setChatVisibility, selectContact, setTargetRoom } from "./actions";

export class CreateChat extends React.Component {

  constructor(...args) {
    super(...args);

    this.handleOpenChat = this.handleOpenChat.bind(this);
    this.openChat = this.openChat.bind(this);
  }

  static propTypes = {
    type: PropTypes.oneOf(["28", "32"]),
    toggleOnly: PropTypes.bool,
    hideForSelf: PropTypes.bool,
    me: PropTypes.string,
    addedBy: PropTypes.string,
    refNo: PropTypes.string,
    fill: PropTypes.bool,
  };

  async handleOpenChat() {
    const { addedBy, refNo, showPreparedChat, selectContact, setChatVisibility, setTargetRoom, showPreparedRoom } = this.props;
    console.log("prepare chat for", addedBy, refNo);

    const discussion = await ChatApi.prepareDiscussion({
      withUsers: [addedBy],
      refNo: refNo
    });

    showPreparedRoom(discussion);
    selectContact(addedBy);
    setTargetRoom(addedBy, discussion._id);
    setChatVisibility(true);
  }

  openChat() {
    const { chatVisible, setChatVisibility } = this.props;
    setChatVisibility(!chatVisible);
  }

  render() {
    const { type, toggleOnly, hideForSelf, me, addedBy, refNo, fill } = this.props;
    if (!me) {
      return null;
    }

    if (hideForSelf && me === addedBy) {
      return null;
    }

    const clickHandler = toggleOnly ? this.openChat : this.handleOpenChat;
    const opener = this.props.children || <ChatOpener fill={fill} className={s.new_chat_ico} type={type} active onClick={clickHandler} />;
    return (
      <div>
        {React.cloneElement(opener, { type, active: true, onClick: clickHandler })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const loggedUser = state.login.user;
  return {
    me: loggedUser && loggedUser._id,
    chatVisible: state.chat.isShown,
  };
}

export const CreateChatWrapper = connect(mapStateToProps, {
  showPreparedRoom,
  setChatVisibility,
  selectContact,
  setTargetRoom
})(CreateChat);
