import React from 'react';
import { getProfile, getStatistic, getAccounts } from '../../actions/login';
import UnderConstruction from '../../components/UnderCounstruction';
import Layout from '../../components/Layout';
import history from '../../core/history';
import { User } from '../../core/api/api';


export default {
  path: '/settings',
  async action({ params, hash, store, token, next, previousPath = '/', ...rest }) {
    const Settings = ((await import(/* webpackChunkName: 'Settings' */ '../../components/Settings/Settings')).default);
    try {
      if (typeof window !== 'undefined' && previousPath.indexOf('/settings') !== 0) {
        await store.dispatch(getProfile(token));
        await store.dispatch(getAccounts())
      }
    } catch (e) {
      if (typeof window !== 'undefined') {
        history.push('/');
      }
    }
    const _next = await next();
    if (_next.component) {
      return _next;
    }
    return {
      title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
      component: (
        <Layout>
          <Settings hash={hash} {...params} {..._next} />
        </Layout>
      ),
      isUnLockedPathForUnverified: _next.isUnLockedPathForUnverified ?? true,
      isUnLockedPathForUnapproved: _next.isUnLockedPathForUnapproved ?? true,
    };
  },
  children: [
    {
      path: '/profile',
      async action({ params, hash, store, token, ...rest }) {
        return { selectedTab: 'company', showDelete: false };
      },
    },
    {
      path: '/profile/delete',
      async action(params) {
        return { selectedTab: 'company', showDelete: true };
      },
    },
    {
      path: '/company',
      async action({ params, hash, store, token, ...rest }) {
        return { selectedTab: 'company' };
      },
    },
    {
      path: '/password',
     async action() {
       return { selectedTab: 'security' };
      },
    },
    {
      path: '/security',
      async action() {
        return { selectedTab: 'security' };
      },
    },
    {
      path: '/tags',
      async action() {
        return { selectedTab: 'tags', isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false };
      },
    },
    {
      path: '/subscription',
      async action() {
        return { selectedTab: 'subscription', isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false };
      },
    },
    {
      path: '/change-subscription',
      async action() {
        return { selectedTab: 'subscription', isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false, subscriptionChangeOpen: true };
      },
    },
    {
      path: '/subscription/:transactionStatus?',
      async action({ params }) {
        return { selectedTab: 'subscription', transactionStatus: params.transactionStatus, isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false };
      },
    },
    {
      path: '/connect',
      async action() {
        return { selectedTab: 'connect', isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false };
      },
    },
    {
      path: '/mail-gate',
      async action() {
        return { selectedTab: 'connect', connectUserEmailOpen: true, isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false };
      },
    },
    {
      path: '/subscribe-connect',
      async action() {
        return { selectedTab: 'connect', isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false };
      },
    },
    {
      path: '/connect',
      async action() {
        return { selectedTab: 'connect', isUnLockedPathForUnverified: false, isUnLockedPathForUnapproved: false };
      },
    },
    {
      path: '/payment-status',
      async action({ query, token }) {
        const PaymentStatus = ((await import(/* webpackChunkName: 'Settings' */ '../../components/Settings/PaymentStatus')).default);
        let transaction;
        try {
          transaction = (await User.getPaymentStatus(query.id, token)).data;
        } catch (e) {
          console.error(e);
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: <Layout><PaymentStatus transaction={transaction} /></Layout>,
        };
      },
    },
    {
      path: '/',
      action() {
        if (typeof window !== 'undefined') {
          history.push('/settings/profile');
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <div />
            </Layout>
          ),
        };
      },
    },
  ],
};
