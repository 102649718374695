import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let ContactPersonIcon = ({ fill = "#B2B2B2", ...props }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.3595 11.547C14.1674 11.547 15.633 10.0814 15.633 8.27352C15.633 6.4656 14.1674 5 12.3595 5C10.5515 5 9.08594 6.4656 9.08594 8.27352C9.08594 10.0814 10.5515 11.547 12.3595 11.547Z" fill={fill}/>
    <path d="M6.66615 12.1164C7.84523 12.1164 8.80106 11.1605 8.80106 9.98146C8.80106 8.80239 7.84523 7.84656 6.66615 7.84656C5.48708 7.84656 4.53125 8.80239 4.53125 9.98146C4.53125 11.1605 5.48708 12.1164 6.66615 12.1164Z" fill={fill}/>
    <path d="M18.0529 12.1164C19.2319 12.1164 20.1878 11.1605 20.1878 9.98146C20.1878 8.80239 19.2319 7.84656 18.0529 7.84656C16.8738 7.84656 15.918 8.80239 15.918 9.98146C15.918 11.1605 16.8738 12.1164 18.0529 12.1164Z" fill={fill}/>
    <path d="M7.96512 13.3006C7.15092 14.066 6.55213 15.0319 6.22873 16.1016H4.38987C4.27686 16.1009 4.20222 16.1016 4.08877 16.1016C4.00886 16.0217 3.96364 15.7876 3.96289 15.6746C3.96326 15.2053 4.08567 14.7441 4.31809 14.3364C4.55051 13.9287 4.88496 13.5884 5.28861 13.349C5.69225 13.1096 6.15122 12.9792 6.62046 12.9707C7.0897 12.9622 7.55309 13.0759 7.96512 13.3006Z" fill={fill}/>
    <path d="M16.7559 13.3006C17.5701 14.066 18.1689 15.0319 18.4922 16.1016H20.3311C20.4441 16.1009 20.5796 16.1016 20.6968 16.1016C20.7767 16.0217 20.7573 15.7876 20.7581 15.6746C20.7577 15.2053 20.6353 14.7441 20.4029 14.3364C20.1705 13.9287 19.836 13.5884 19.4324 13.349C19.0287 13.1096 18.5698 12.9792 18.1005 12.9707C17.6313 12.9622 17.1679 13.0759 16.7559 13.3006Z" fill={fill}/>
    <path d="M12.3593 12.4011C10.8877 12.4028 9.47681 12.9881 8.4362 14.0287C7.39559 15.0693 6.81025 16.4802 6.80859 17.9519C6.80859 18.0651 6.85358 18.2988 6.93365 18.3789C7.05078 18.3789 7.12233 18.3789 7.23557 18.3789H17.4831C17.5964 18.3789 17.6739 18.3789 17.785 18.3789C17.8651 18.2988 17.9101 18.0651 17.9101 17.9519C17.9084 16.4802 17.3231 15.0693 16.2825 14.0287C15.2419 12.9881 13.831 12.4028 12.3593 12.4011Z" fill={fill}/>
  </svg>
);

ContactPersonIcon = pure(ContactPersonIcon);
ContactPersonIcon.displayName = 'ContactPersonIcon';
ContactPersonIcon.muiName = 'SvgIcon';

export default ContactPersonIcon;
