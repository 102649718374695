import canals from '../canals';

const indexedCanals = canals.filter(c => !c.hidden).reduce((acc, v) => {
  acc[v._id] = v;
  return acc;
}, {});

export default function transformViaToChannel(calculator) {
  calculator = { ...calculator };
  const newLegs = [];
  for (let i = 0; i < calculator.legList.length; i++) {
    const leg = calculator.legList[i];
    if (leg.via?.length > 0) {
      const viaLeg = { type: 'channel', _id: leg.via.join(',') };
      viaLeg.port = { _id: viaLeg._id, isChannel: true };
      viaLeg.port.name = leg.via.map(_id => indexedCanals[_id]?.name).filter(n => !!n).join(', ') || "Shortest Direct route";
      viaLeg.idleDays = leg.viaIdleDays;
      viaLeg.atPortDays = leg.viaIdleDays;
      viaLeg.da = leg.viaDa;
      newLegs.push(viaLeg);
    }
    newLegs.push(leg);
  }
  calculator.legList = newLegs;
  return calculator;
}
