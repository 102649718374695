import React from 'react';
import Layout from '../../components/Layout';
import Page from '../../components/Page/Page';
import IO from '../../core/api/IO';

export default {
  path: '/news',
  public: true,
  children: [
    {
      path: '/',
      async action({ next }) {
        const { List } = await new Promise((resolve) => {
          require.ensure(
            [],
            require => resolve(require('./News').default),
            'news',
          );
        });
        let newsList;
        try {
          newsList = await IO.getNewsList({ limit: 99, offset: 0 });
        } catch (e) {
          console.error(e);
          return await next();
        }
        return {
          title: 'Stay Updated with Shipnext News & Blog: Industry Insights & Trends',
          description: "Discover the latest news, industry insights, and trends on the Shipnext Blog. Stay informed and ahead in the maritime and shipping industry.",
          chunk: 'news',
          component: <Layout><Page headerTitle="NEWS" withFooter ><List newsList={newsList} /></Page></Layout>,
          public: true,
        };
      },
    },
    {
      path: '/:newsId',
      async action({ params, next }) {
        const { One } = await new Promise((resolve) => {
          require.ensure(
            [],
            require => resolve(require('./News').default),
            'news',
          );
        });
        let news;
        try {
          news = await IO.getNews({ id: params.newsId });
        } catch (e) {
          console.error(e);
          return await next();
        }
        return {
          title: news.metaTitle || news.title,
          description: news.metaDescription || news.shortDescription,
          keywords: news.metaKeywords || '',
          ogImg: (news.imageData && `https://shipnext.com/api/v1/io${news.imageData.path}`) || '',
          chunk: 'news',
          component: <Layout><Page headerTitle={<span dangerouslySetInnerHTML={{__html: `NEWS - ${news.metaTitle || news.title}` }} />} ><One news={news} /></Page></Layout>,
          public: true,
        };
      },
    },
  ],

}
