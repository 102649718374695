import React from 'react';
import IconButton from 'material-ui/IconButton';
import ApproveIcon from '../Icons/ApproveIcon';

const btnStyles = {
  width: '24px',
  height: '24px',
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  outline: 'none',
  border: 'none',
};

const iconStyles = {
  width: '18px',
  height: '18px',
};

function CircleApproveBtn({ icon, style = {}, iconStyle = {}, iconProps = {}, ...rest }) {
  const Icon = icon || ApproveIcon;

  return (
    <IconButton {...rest} iconStyle={{ ...iconStyles, ...iconStyle }} style={{ ...btnStyles, ...style }}>
      <Icon {...iconProps} />
    </IconButton>
  );
}

export default CircleApproveBtn;
