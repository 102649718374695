import React from 'react'
import { ArrowPopover } from './ArrowPopover';
import styled from 'styled-components';

const RoundedArrowPopover = styled((props) => {
  return <ArrowPopover {...props} />;
})`
  background-color: white;
  border-radius: 100px;
  border: 1px solid var(--stroke-light-gray2);
  font-size: 11px;
  font-weight: 400;
  > div:first-child {
    padding-left: 13px;
    &::before {
      display: none;
    }
    >span {
      font-size: 11px;
    }
  }

`;

export default RoundedArrowPopover;
