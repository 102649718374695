import PropTypes from 'prop-types';
import React from 'react';
import s from './Request.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import RaisedButton from '../../../Common/RaisedButton';
import NarrowFormsyText from '../../../NewInputs/NarrowFormsyText';
import MenuItem from 'material-ui/MenuItem';
import NarrowSelect from '../../../NewInputs/NarrowSelect';
import Toggle from '../../../Common/Toggle';
import cx from 'classnames';
import PlaceAndDates from './PlaceAndDates';
import Cargo from './Cargo';
import Bunkers from './Bunkers';
import CustomTooltip from '../../../Common/CustomTooltip';
import ActionInfo from 'material-ui/svg-icons/action/info';

class CharterDetails extends React.PureComponent {
  static contextTypes = {
    onChange: PropTypes.func,
    selector: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this._ports = null;
    this._cargo = null;
  }
  // onChange = this.context.onChange("charterDetails");

  state = {
    charterDetails: {
      ports: {},
      cargo: {},
      hirePerDay: {
        value: "",
        currency: "USD",
      },
      ilhoc: {
        value: "",
        currency: "USD",
      },
      cve: {
        value: "",
        currency: "USD",
      },
      redeliveryNotices: "30/20/15/10/7/5/3/2/1",
      tradingLimits: "",
      tradingExclusions: "",
      countriesAffectedByWar: "",
      gracePeriod: "",
      requisitionPeriod: "",
      intermediateCleaning: "",
      servantsSupercargoMeals: "",
      dryDocking: false,
      deliveryBunkers: [
        {},
      ],
      redeliveryBunkers: [
        {},
      ],
    },
  }

  handleChange = (key, name = null, _, val) => new Promise((res, rej) => {
    if (name !== null) {
      this.setState(state => ({
        charterDetails: {
          ...state.charterDetails,
          [key]: {
            ...state.charterDetails[key],
            [name]: val,
          },
        },
      }), res);
    } else {
      this.setState(state => ({
        charterDetails: {
          ...state.charterDetails,
          [key]: val,
        },
      }), res);
    }
  })
  portsRef = el => {
    this._ports = el;
  }
  cargoRef = el => {
    this._cargo = el;
  }

  handleNext = () => {
    this._ports.openCollapses();
    this.props.handleNext({ charterDetails: {
      ...this.state.charterDetails,
      ports: this._ports.getState(),
      cargo: this._cargo.getState(),
    } });
  }

  render() {
    const { deliveryBunkers, redeliveryBunkers } = this.state.charterDetails;
    return (<div className={s.step} data-id="charterDetails" id="charter_details">
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="Hire (per day)"
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
            onChange={(ev, val) => this.handleChange("hirePerDay", "value", ev, val)}
            value={this.state.charterDetails.hirePerDay.value}
            name="hirePerDay[value]"
            fullWidth
          />
        </div>
        <div className="col-sm-2">
          <NarrowSelect
            requiredError="required"
            name="hirePerDay[currency]"
            onChange={(ev, val) => this.handleChange("hirePerDay", "currency", ev, val)}
            fullWidth
            autoWidth
            required
            value={this.state.charterDetails.hirePerDay.currency}
            defaultValue="USD"
            style={{ display: 'block' }}

          >
            <MenuItem key="USD" value="USD" primaryText="USD" />
            <MenuItem key="EUR" value="EUR" primaryText="EUR" />
          </NarrowSelect>
        </div>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="ILHOC"
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
            onChange={(ev, val) => this.handleChange("ilhoc", "value", ev, val)}
            value={this.state.charterDetails.ilhoc.value}
            name="ilhoc[value]"
            fullWidth
          />
        </div>
        <div className="col-sm-2">
          <NarrowSelect
            requiredError="required"
            name="ilhoc[currency]"
            onChange={(ev, val) => this.handleChange("ilhoc", "currency", ev, val)}
            fullWidth
            autoWidth
            required
            value={this.state.charterDetails.ilhoc.currency}
            defaultValue="USD"
            style={{ display: 'block' }}
          >
            <MenuItem key="USD" value="USD" primaryText="USD" />
            <MenuItem key="EUR" value="EUR" primaryText="EUR" />
          </NarrowSelect>
        </div>
      </div>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="CVE"
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
            onChange={(ev, val) => this.handleChange("cve", "value", ev, val)}
            value={this.state.charterDetails.cve.value}
            name="cve[value]"
            fullWidth
          />
        </div>
        <div className="col-sm-2">
          <NarrowSelect
            requiredError="required"
            name="cve[currency]"
            onChange={(ev, val) => this.handleChange("cve", "currency", ev, val)}
            fullWidth
            autoWidth
            required
            value={this.state.charterDetails.cve.currency}
            defaultValue="USD"
            style={{ display: 'block' }}
          >
            <MenuItem key="USD" value="USD" primaryText="USD" />
            <MenuItem key="EUR" value="EUR" primaryText="EUR" />
          </NarrowSelect>
        </div>
        <div className="col-sm-6">
          <NarrowSelect
            requiredError="required"
            name="redeliveryNotices"
            onChange={(ev, val) => this.handleChange("redeliveryNotices", null, ev, val)}
            fullWidth
            autoWidth
            required
            value={this.state.charterDetails.redeliveryNotices}
            defaultValue="30/20/15/10/7/5/3/2/1"
            floatingLabelText="Redelivery notices (days)"
          >
            <MenuItem key="30/20/15/10/7/5/3/2/1" value="30/20/15/10/7/5/3/2/1" primaryText="30/20/15/10/7/5/3/2/1" />
            <MenuItem key="15/10/7/5/3/2/1" value="15/10/7/5/3/2/1" primaryText="15/10/7/5/3/2/1" />
            <MenuItem key="10/7/5/3/2/1" value="10/7/5/3/2/1" primaryText="10/7/5/3/2/1" />
            <MenuItem key="7/5/4/3/2/1" value="7/5/4/3/2/1" primaryText="7/5/4/3/2/1" />
            <MenuItem key="5/4/3/2/1" value="5/4/3/2/1" primaryText="5/4/3/2/1" />
          </NarrowSelect>
        </div>
      </div>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="Trading Limits"
            name="tradingLimits"
            onChange={(ev, val) => this.handleChange("tradingLimits", null, ev, val)}
            value={this.state.charterDetails.tradingLimits}
            fullWidth
          />
        </div>
        <div className="col-sm-8">
          <NarrowFormsyText
            floatingLabelText="Trading Exclusions"
            name="tradingExclusions"
            onChange={(ev, val) => this.handleChange("tradingExclusions", null, ev, val)}
            value={this.state.charterDetails.tradingExclusions}
            fullWidth
          />
        </div>
      </div>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="Countries affected by war"
            name="countriesAffectedByWar"
            onChange={(ev, val) => this.handleChange("countriesAffectedByWar", null, ev, val)}
            value={this.state.charterDetails.countriesAffectedByWar}
            fullWidth
          />
        </div>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="Grace period, days"
            validations="isNumeric,isInt,gt0,min:1,max:10"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'from 1 to 10',
              isInt: 'integer only',
              min: 'from 1 to 10',
              max: 'from 1 to 10',
            }}
            name="gracePeriod"
            onChange={(ev, val) => this.handleChange("gracePeriod", null, ev, val)}
            value={this.state.charterDetails.gracePeriod}
            fullWidth
          />
        </div>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="Requisition period, num of month"
            validations="isNumeric,isInt,gt0,min:1,max:10"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'from 1 to 10',
              isInt: 'integer only',
              min: 'from 1 to 10',
              max: 'from 1 to 10',
            }}
            name="requisitionPeriod"
            onChange={(ev, val) => this.handleChange("requisitionPeriod", null, ev, val)}
            value={this.state.charterDetails.requisitionPeriod}
            fullWidth
          />
        </div>
      </div>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="Intermediate cleaning"
            name="intermediateCleaning"
            onChange={(ev, val) => this.handleChange("intermediateCleaning", null, ev, val)}
            value={this.state.charterDetails.intermediateCleaning}
            fullWidth
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
          />
        </div>
        <div className={cx("col-sm-2", "flex", "align-items-center")}>
          USD, per hold
        </div>
        <div className="col-sm-4">
          <NarrowFormsyText
            floatingLabelText="Servants/Supercargo meals"
            name="servantsSupercargoMeals"
            onChange={(ev, val) => this.handleChange("servantsSupercargoMeals", null, ev, val)}
            value={this.state.charterDetails.servantsSupercargoMeals}
            fullWidth
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
          />
        </div>
        <div className={cx("col-sm-2", "flex", "align-items-center")}>
          USD, per day
        </div>
      </div>
      <div className={cx("row flex align-items-center", s.step_row)} style={{ padding: "12px 0 0" }}>
        <div className="col-sm-10">
        <p className={s.subtitle}>DELIVERY BUNKERS</p>
          <Bunkers namePrefix={"deliveryBunkers"} deliveryBunkers bunkers={deliveryBunkers} handleChange={this.handleChange}/>
        </div>
        <div className="col-sm-10">
          <p className={s.subtitle}>REDELIVERY BUNKERS</p>
          <Bunkers namePrefix={"redeliveryBunkers"} deliveryBunkers bunkers={redeliveryBunkers} handleChange={this.handleChange}/>
        </div>
      </div>
      <div className={cx("row flex align-items-center", s.step_row)} style={{ padding: "12px 6px 0" }}>
        <p className={s.subtitle}>
          DRY DOCKING
        </p>
        <div className="col-sm-4" style={{ paddingTop: "12px", paddingBottom: "12px", lineHeight: "16px" }}>
          <div className="flex justify-content-start align-items-center">
            not allowed
            <Toggle
              style={{ width: '50px' }}
              name="dryDocking"
              onChange={(ev, val) => this.handleChange("dryDocking", null, ev, val)}
              value={this.state.charterDetails.dryDocking}
            />
            {' '}
            allowed
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "end" }}>
        <p className={s.subtitle}style={{ paddingBottom: 0 }}>
          PLACE & DATES
        </p>
        <div style={{ margin: "0 4px" }}>
          <CustomTooltip
            style={{ alignSelf: "center", cursor: "pointer" }}
            tooltip={
              <div>
                <div>Readiness date to count from 00:00</div>
                <div>Cancelling date to count until 23:59</div>
              </div>
            }
            horizontalAlign="right"
          >
            <ActionInfo style={{ width: "16px", height: "16px" }} />
          </CustomTooltip>
        </div>
      </div>
      <PlaceAndDates
        ports={this.state.charterDetails.ports}
        attachRef={this.portsRef}
      />
      <p className={s.subtitle}style={{ paddingBottom: 0 }}>
        CARGO
      </p>
      <Cargo
        attachRef={this.cargoRef}
      />
      <div style={{ margin: '12px 0' }}>
        <RaisedButton
          label="NEXT"
          disableTouchRipple
          disableFocusRipple
          primary
          type="submit"
          onClick={this.handleNext}
          style={{ margin: '13px 0 6px 0' }}
        />
      </div>
    </div>);
  }
}

export default withStyles(s)(CharterDetails);
