/*
 * @Author: salterok
 * @Date: 2017-10-09 18:07:56
 * @Last Modified by: salterok
 * @Last Modified time: 2017-10-10 14:51:10
 */

import Api from "./api";

export class ChatApi {

  static async post(url, body) {
    return await Api.fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "content-type": "application/json" },
    });
  }

  static async prepareDiscussion(options) {
    const { refNo, withUsers } = options;

    return await this.post("/chat/prepareDiscussion", {
      refNo,
      withUsers
    });
  }
}
