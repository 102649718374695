import { updateVesselChanges } from '../vessel/FLEET_VESSEL_CHANGES_SEEN';
export default function MONITOR_VESSEL_CHANGES_SEEN(state, {payload: {id, changes}}) {
  const {data} = state.vesselList;
  const newChanges = {
    ...changes,
    lastSeenVersion: changes.lastDocVersion
  }
  const newData = updateVesselChanges(data, id, newChanges);
  return { ...state, vesselList: {...state.vesselList, data: newData}};
}
