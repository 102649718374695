import React from 'react';
import pure from 'recompose/pure';

const _ContactsYoutube = props => (
  <span {...props}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37258 0 0 5.37259 0 12C0 18.6274 5.37258 24 12 24Z" fill="#285596"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.0106 6.08735C19.786 6.29473 20.3909 6.90191 20.6006 7.6773C21.149 9.88941 21.1133 14.0717 20.6121 16.3184C20.4047 17.0937 19.7975 17.6986 19.0222 17.9083C16.8331 18.4498 7.02837 18.383 4.96604 17.9083C4.19065 17.7009 3.58578 17.0937 3.37609 16.3184C2.85877 14.2099 2.89449 9.75116 3.36456 7.68882C3.57195 6.91343 4.17913 6.30856 4.95452 6.09887C7.88095 5.48824 17.9691 5.68525 19.0106 6.08735ZM10.2523 9.30177L14.953 11.9978L10.2523 14.6938V9.30177Z" fill="white"/>
  </svg>
  </span>
);

const ContactsYoutube = pure(_ContactsYoutube);
ContactsYoutube.displayName = 'ContactsYoutube';
ContactsYoutube.muiName = 'SvgIcon';

export default ContactsYoutube;
