import React from 'react';
import styled, { css } from 'styled-components';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import RaisedButton from '../../Common/RaisedButton';

const Header = styled.div`
  width: 100%;
  height: 48px;
  background: var(--bg-light-grey);
  border-bottom: 1px solid var(--stroke-light-gray2);
  border-right: 1px solid var(--stroke-light-gray2);
  display: flex;
  flex: 0 0 48px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px 0px 20px;
  position: relative;
  z-index: 1001;
`;

export const Close = styled(NavigationClose)`
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  color: var(--icon-default) !important;
`;

export const Title = styled.h5`
  color: var(--default-dark-text);
  font-weight: 700;
  font-size: 14px;
  margin: 0px;
  line-height: 16px;
  text-transform: uppercase;
  ${
    props => {
      let styles = '';
      if (props.subtitle) {
        styles += `
          color: var(--text-light);
          font-weight: 400;
          line-height: 20px;
          text-transform: initial;
        `;
      }
      if (props.ellipsis) {
        styles += `
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        `;
      }

      return css`
        ${styles}
      `;
    }
  }
`;

function TcHeader({ title, handleClose, handleSubmit, withButtons, ...rest }) {
  return (
    <Header {...rest}>
      <Title>
        {
          title
        }
      </Title>
      {withButtons ?
        <div style={{ display: "flex" }}>
          <div>
            <RaisedButton
              label="BACK TO EDIT"
              disableTouchRipple
              disableFocusRipple
              secondary
              onClick={handleClose}
            />
          </div>
          <div style={{ marginLeft: "8px" }}>
            <RaisedButton
              label="CREATE"
              disableTouchRipple
              disableFocusRipple
              primary
              type="submit"
              onClick={handleSubmit}
            />
          </div>
        </div>
      : <Close onClick={handleClose} />
      }
    </Header>
  )
}

export default TcHeader;
