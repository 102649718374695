import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Diff.scss';
import cx from 'classnames'
import {percent} from "../../core/format";

function Diff({preLine, oldValue, newValue, colorScheme, br=false }) {
  return (
    preLine ?
    <div className={s[colorScheme]}>
      {oldValue != null && oldValue !== newValue
        ? <div className={s.old}>{oldValue}{br && <br/>}</div>
        : null}
      <div className={s.new} >{newValue}</div>
    </div>
    :
    <span className={s[colorScheme]}>
      {oldValue != null && oldValue !== newValue
        ? <span className={s.old}>{oldValue}{br && oldValue && <br/>}</span>
        : null}
      <span className={cx(s.new, ((oldValue === '') || (newValue === '' || newValue == null) || br) && s.nomargin)} >{newValue}</span>
    </span>
  );
}

Diff.propTypes = {
  newValue: PropTypes.string,
  oldValue: PropTypes.string,
  colorScheme: PropTypes.string,
};

export default withStyles(s)(Diff);


export function DeviationDiff(props: { cv: {}, pv: {}}) {
  const ctw = { ...props.cv.totalWeightDeviation };
  const ptw = { ...props.pv.totalWeightDeviation };
  const ctv = { ...props.cv.totalVolumeDeviation };
  const ptv = { ...props.pv.totalVolumeDeviation };
  const parts = [];
  if (!ctw.value && !ptw.value && !ctv.value && !ptv.value) {
    return <span>---</span>;
  }
  if (ctw.value && !ptw.value) {
    parts.push(<span className={s.newforced}>Weight: {percent(ctw.value)}</span>);
  }
  if (!ctw.value && ptw.value) {

    parts.push(<span className={s.old}>Weight: {percent(ptw.value)}</span>);
  }
  if (ctw.value && ptw.value) {
    parts.push(<span >Weight: <Diff oldValue={percent(ptw.value)} newValue={percent(ctw.value)} /></span>);
  }
  if (ctv.value || ptv.value) {
    parts.push(", ");
  }
  if (ctv.value && !ptv.value) {
    parts.push(<span className={s.newforced}>Volume: {percent(ctv.value)}</span>);
  }
  if (!ctv.value && ptv.value) {
    parts.push(<span className={s.old}>Volume: {percent(ptv.value)}</span>);
  }
  if (ctv.value && ptv.value) {
    parts.push(<span >Volume: <Diff oldValue={percent(ptv.value)} newValue={percent(ctv.value)} /></span>);
  }
  if (ctw.value || ctv.value || ptw.value || ptv.value) {
    parts.push(", ");
  }

  if (!ctw.value && ptw.value && !ctv.value && ptv.value) {
    ctw.term = "";
  }
  if (!ptw.value && !ptv.value && (ctw.value || ctv.value)) {
    ptw.term = "";
  }
  parts.push(<span> <Diff oldValue={ptw.term} newValue={ctw.term} /></span>);

  return <span>{parts}</span>;
}
