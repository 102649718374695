/** @name GET_NEWS_LIST_SUCCESS */
export default function GET_NEWS_LIST_SUCCESS(state, action) {
  const newState = {};

  newState.newsList = {
    ...state.newsList,
    loading: false,
    data: action.payload.data,
    total: action.payload.headers.get('X-Total-Count'),
  };

  return { ...state, ...newState };
}
