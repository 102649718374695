import Cargo from '../../core/api/Cargo';
import Autocomplete from './AutoComplete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class CargoAutocomplete extends Autocomplete {
  constructor(props) {
    super(props);
    this.Api = Cargo;
  }
}

CargoAutocomplete.propTypes = {};
CargoAutocomplete.defaultProps = { limit: 100, delayTextValue: true };

export default CargoAutocomplete;
