exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".U9sTd {\n  display: -ms-flexbox;\n  display: flex; }\n\n._2cUm2 {\n  -ms-flex-align: center;\n      align-items: center; }\n\n._3-7xy {\n  margin-right: 18px; }\n\n.rDO7y {\n  width: 100%; }\n\n._3mUQn {\n  gap: 16px; }\n\n._1fIkc {\n  font-size: 12px; }\n", ""]);

// Exports
exports.locals = {
	"flex": "U9sTd",
	"items_center": "_2cUm2",
	"mr_18px": "_3-7xy",
	"w_full": "rDO7y",
	"gap_16px": "_3mUQn",
	"text_xs": "_1fIkc"
};