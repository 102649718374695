import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './MiniPackingList.scss';
import ContentClearIcon from 'material-ui/svg-icons/content/clear';
import validationRules from 'formsy-react/lib/validationRules';
import { HOC } from 'formsy-react';
import Select from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import ContentAdd from 'material-ui/svg-icons/content/add';
import AddIcon from '../Common/AddIcon';
import NarrowSelect from '../NewInputs/NarrowSelect';
import { debounceWithoutFirstCall } from '../../core/utils';

const allowedColumns = [
  {
    key: 'name',
    title: 'Name',
    size: 'x4',
    required: true,
    placeholder: 'Name',
  },
  {
    key: 'length',
    title: 'L',
    unit: 'm',
    placeholder: '0',
    size: 'x1',
    validations: 'isNumeric,min:0,max:300',
    validationError: '0 - 300',
    required: true,
  },
  {
    key: 'width',
    title: 'B',
    unit: 'm',
    placeholder: '0',
    size: 'x1',
    validations: 'isNumeric,min:0,max:100',
    validationError: '0 - 100',
    required: true,
  },
  {
    key: 'height',
    title: 'H',
    unit: 'm',
    placeholder: '0',
    size: 'x1',
    validations: 'isNumeric,min:0,max:200',
    validationError: '0 - 200',
    required: true,
  },
  {
    key: 'quantity',
    title: 'Quantity',
    unit: 'units',
    placeholder: '0',
    size: 'x2',
    validations: 'isNumeric,min:0,isInt',
    validationError: 'invalid',
    required: true,
  },
  {
    key: 'weight',
    title: 'Unit Weight',
    unit: 'mt',
    placeholder: '0',
    size: 'x3',
    validations: 'isNumeric,min:0,max:10000',
    validationError: '0 - 10000',
    required: true,
  },
  {
    key: 'totalWeight',
    title: 'Total Weight',
    unit: 'mt',
    placeholder: '0',
    size: 'x3',
    calculated: '1*row.quantity.value*row.weight.value',
    validations: 'isNumeric,min:0,max:1000000',
    validationError: '0 - 1000000',
    required: true,
  },
  {
    key: 'imdg',
    title: 'IMDG',
    unit: '',
    placeholder: '--',
    validations: 'isNumeric,imdgClass',
    validationError: 'invalid',
    size: 'x1',
  },
  {
    key: 'grossWeight',
    title: 'Gross Weight',
    unit: 'mt',
    placeholder: '0',
    size: 'x3',
    validations: 'isNumeric,min:0,max:1000000',
    validationError: '0 - 1000000',
  },
  {
    key: 'netWeight',
    title: 'Net Weight',
    unit: 'mt',
    placeholder: '0',
    size: 'x3',
    validations: 'isNumeric,min:0,max:1000000',
    validationError: '0 - 1000000',
  },
  {
    key: 'taraWeight',
    title: 'Tare Weight',
    unit: 'mt',
    placeholder: '0',
    size: 'x3',
    validations: 'isNumeric,min:0,max:1000000',
    validationError: '0 - 1000000',
  },
  {
    key: 'area',
    title: 'Area',
    unit: 'm2',
    placeholder: '0',
    size: 'x3',
    validations: 'isNumeric,min:0,max:30000',
    validationError: '0 - 30000',
  },
  {
    key: 'volume',
    title: 'Volume',
    unit: 'm3',
    placeholder: '0',
    size: 'x3',
    validations: 'isNumeric,min:0,max:6000000',
    validationError: '0 - 6000000',
  },
]
  .map((col, i) => {
    col.index = i;
    return col;
  });

const calculate = (function (window) {
  return function (row, formula) {
  // eslint-disable-next-line no-eval
    return Math.round(parseFloat(eval(formula)) * 100) / 100;
  };
}({}));

function validateCell(cell, col) {
  if (!col.validations || cell.value === undefined || cell.value === null || cell.value === '') {
    cell.invalid = false;
   // this.state.invalid = cell.invalid || this.state.invalid;
    return cell;
  }
  const validations = col.validations.split(',').map(v => v.split(':'));
  let valid = true;
  for (let i = 0; valid && i < validations.length; i++) {
    const validation = validations[i];
    valid = validationRules[validation[0]].call(null, {}, cell.value, validation[1]);
  }
  if (valid && validations.find(vals => vals[0] === 'isNumeric')) {
    cell.value = parseFloat(cell.value);
  }
  cell.invalid = !valid;
 // this.state.invalid = cell.invalid || this.state.invalid;
  return cell;
}

class MiniPackingList extends PureComponent {

  constructor(props, context) {
    super(props, context);

    const state = this.parsePropsValue(props);

    state.allColumns = allowedColumns;

    state.selectedRowIndex = null;
    state.selectedColIndex = null;

    this.state = state;

    props.onChange(this.getData(state.rows, state.columns));

    this.onClick = this.onClick.bind(this);
  }

  parsePropsValue = props => {
    let requiredColumns;

    let rows = [];
    if (props.value?.length) {
      const cols = new Set();

      for (const row of props.value) {
        for (const prop of Object.keys(row)) {
          cols.add(prop);
        }
      }
      requiredColumns = allowedColumns
        .filter(col => cols.has(col.key) || col?.required)
        .sort((a, b) => a.index - b.index);

      for (const row of props.value) {
        rows.push(requiredColumns.map(col => ({ value: row[col.key] })));
      }
    } else {
      requiredColumns = allowedColumns.filter(col => col.required);
      rows = [(new Array(requiredColumns.length)).fill({ value: '' })];
    }

    const state = {
      rows,
      columns: requiredColumns,
      additionalColumns: this.computeAdditionalColumns(allowedColumns, requiredColumns),
      nextNewColumn: undefined,
    };
    state.nextNewColumn = (state.additionalColumns && state.additionalColumns[0] && state.additionalColumns[0].key) || undefined;

    return state;
  }

  componentDidMount() {
    this.props.setValue && this.props.setValue(this.props.value || []);

    document.addEventListener('click', this.onClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
  }

  // TODO: make this component fully controllable and get rid of componentWIllReceiveProps

  componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.props.value) {
        const state = this.parsePropsValue(nextProps);
        this.setState({
          ...state,
        });
      }
  }

  onClick(e) {
    const eventPath = e.path || e.deepPath || (e.composedPath && e.composedPath());
    if (!eventPath || eventPath.length === 0) {
      return;
    }
    for (const item of eventPath) {
      // this.refs.root should point to native dom element (not react one)
      if (item === this.refs.root) {
        return;
      }
    }

    // if clicked not inside mini packing list -> drop selection
    this.setState({ selectedRowIndex: null, selectedColIndex: null });
  }

  computeAdditionalColumns(allColumns, usedColumns) {
    const temp = new Set();
    for (const col of usedColumns) {
      temp.add(col.key);
    }
    return allColumns.filter(col => !temp.has(col.key));
  }

  handleAddRow=() => {
    const newRow = (new Array(this.state.columns.length)).fill({ value: '' });
    this.state.columns.forEach((col, j) => {
      if (!col) {
        newRow[j] = null;
      }
    });
    this.setState({ rows: [...this.state.rows, newRow] }, this.validate);
  };
  handleDeleteRow=(i) => {
    const rows = [...this.state.rows];
    rows.splice(i, 1);
    this.setState({ rows }, this.validate);
  };
  handleAddColumn=(newCol = {}) => {
    const column = this.state.allColumns.find(col => col.key === this.state.nextNewColumn);
    if (column) {
      const usedColumns = [...this.state.columns, column];
      const additionalColumns = this.computeAdditionalColumns(this.state.allColumns, usedColumns);
      this.setState({
        rows: this.state.rows.map(row => [...row, {}]),
        additionalColumns,
        columns: usedColumns,
        nextNewColumn: (additionalColumns && additionalColumns[0] && additionalColumns[0].key) || undefined,
      }, this.validate);
    }
  };

  handleDeleteColumn=(columnIndex) => {
    const usedColumns = [...this.state.columns];
    usedColumns.splice(columnIndex, 1);
    const additionalColumns = this.computeAdditionalColumns(this.state.allColumns, usedColumns);
    this.setState({
      rows: this.state.rows.map((row) => { const r = [...row]; r.splice(columnIndex, 1); return r; }),
      additionalColumns,
      columns: usedColumns,
      nextNewColumn: (additionalColumns && additionalColumns[0] && additionalColumns[0].key) || undefined,
    }, this.validate);
  };

  findNextNotNullRowIndex=(i) => {
    for (; i < this.state.rows.length; i++) {
      if (this.state.rows[i]) {
        return i;
      }
    }
  };
  findPrevNotNullRowIndex=(i) => {
    for (; i >= 0; i--) {
      if (this.state.rows[i]) {
        return i;
      }
    }
  };
  findNextNotNullColIndex=(i) => {
    for (; i < this.state.columns.length; i++) {
      if (this.state.columns[i]) {
        return i;
      }
    }
  };
  findPrevNotNullColIndex=(i) => {
    for (; i > 0; i--) {
      if (this.state.columns[i]) {
        return i;
      }
    }
  };

  handleSelectCell=(i, j, direction) => {
    if (!direction || (this.state.rows[i] && this.state.columns[j])) {
      this.setState({ selectedRowIndex: i, selectedColIndex: j });
      return;
    }

    switch (direction) {
      case 'up':
        i = this.findPrevNotNullRowIndex(i);
        break;
      case 'down':
        i = this.findNextNotNullRowIndex(i);
        if (i === undefined) {
          this.handleAddRow();
          i = this.state.rows.length;
        }
        break;
      case 'right':
        j = this.findNextNotNullColIndex(j);
        if (j === undefined) {
          i = this.findNextNotNullRowIndex(i + 1);
          if (i === undefined) {
            this.handleAddRow();
            i = this.state.rows.length;
          }
          j = this.findNextNotNullColIndex(0);
        }
        break;
      case 'left':
        j = this.findPrevNotNullColIndex(j);
        if (j === undefined) {
          i = this.findPrevNotNullRowIndex(i - 1);
          if (i === undefined) {
            return;
          }
          j = this.findPrevNotNullColIndex(this.state.columns.length - 1);
        }
        break;
    }
    if (i === undefined) {
      return;
    }
    this.setState({ selectedRowIndex: i, selectedColIndex: j });
  };
  handleChangeCell=(e, i, j, value) => {
    const rows = [...this.state.rows];
    rows[i] = [...rows[i]];
    rows[i][j] = validateCell({ ...rows[i][j], value }, this.state.columns[j]);
    const indexedRow = {};
    for (let k = 0; k < rows[i].length; k++) {
      if (rows[i][k]) {
        indexedRow[this.state.columns[k].key] = rows[i][k];
        if (k > j && this.state.columns[k].calculated && this.state.columns[k].calculated.indexOf(this.state.columns[j].key) !== -1) {
          const calcValue = calculate(indexedRow, this.state.columns[k].calculated);
          if (calcValue) {
            rows[i][k] = validateCell({ ...rows[i][k], value: calcValue }, this.state.columns[k]);
          }
        }
      }
    }
    this.setState({ rows }, this.validate);
  };

  validate = debounceWithoutFirstCall(() => {
    let invalid = false;
    for (let i = 0; i < this.state.rows.length && !invalid; i++) {
      const cols = this.state.rows[i];
      for (let j = 0; j < cols.length && !invalid; j++) {
        invalid = cols[j].invalid;
      }
    }
    if (invalid) {
      this.props.setValue && this.props.setValue(null);
    } else {
      const data = this.getData();
      this.props.setValue && this.props.setValue(data);
      this.props.onChange && this.props.onChange(null, data);
    }
  });

  getData(rows = this.state.rows, columns = this.state.columns) {
    const result = [];

    for (const row of rows) {
      const item = {};
      for (let i = 0; i < columns.length; i++) {
        if (row[i]) {
          item[columns[i].key] = row[i].value;
        }
      }
      result.push(item);
    }

    return result;
  }

  render() {
    const { readOnly } = this.props;
    return (<div className={cx(s.root, readOnly && s.read_only)} ref="root">
      {!readOnly ?
        <div className={s.title}>Packing list</div>
        : null}
      <div className={s.table_wrapper}>
        <div className={s.table} >
          <div className={cx(s.row, s.header)}>
            {this.state.columns.map((column, i) => {
              if (column) {
                const title = column.title + (column.unit ? ', ' + column.unit : '');
                return (<div title={title} key={column.key} className={cx(s.cell, s[column.size], !column.required && !readOnly && s.removable)}><span>{title}</span>{column.required || readOnly ? null : <ContentClearIcon onClick={this.handleDeleteColumn.bind(this, i)} className={s.remove} />} </div>);
              }
              return null;
            })
            }
          </div>
          {this.state.rows.map(((cells, i) => cells && (
            <Row
              readOnly={readOnly}
              key={i}
              cells={cells}
              rowIndex={i}
              selectedCellIndex={this.state.selectedColIndex}
              selectedRowIndex={this.state.selectedRowIndex}
              handleSelectCell={this.handleSelectCell}
              handleDeleteRow={this.handleDeleteRow.bind(this, i)}
              handleChangeCell={this.handleChangeCell}
              columns={this.state.columns}
            />)))}
        </div>
        <div className={s.close_wrapper}>
          <div />
          {!readOnly && this.state.rows.map((cells, i) => {
            const isFirst = i === 0;
            return cells && (
                <div style={{ cursor: "pointer" }} onClick={isFirst ? this.handleAddRow : this.handleDeleteRow.bind(this, i)}>
                  <div>
                    {
                      isFirst
                        ? (
                          <ContentAdd
                              style={{ width: 18, height: 18 }}
                              color="#285596"
                          />
                        )
                        : <ContentClearIcon className={s.remove} />
                    }
                  </div>
                </div>
              );
          })}
        </div>
      </div>
      <div className={s.footer} style={{ display: readOnly ? 'none' : '' }}>
        {this.state.additionalColumns.length ?
          <div className={s.columnManage}>
            <NarrowSelect
              El={Select}
              value={this.state.nextNewColumn}
              onChange={(e, index, nextNewColumn) => this.setState({ nextNewColumn })}
              autoWidth
              style={{ width: '170px', fontSize: '13px'}}
            >
              {
                this.state.additionalColumns.map(col => <MenuItem style={{fontSize: '13px'}} key={col.key} primaryText={col.title} value={col.key} />)
              }
            </NarrowSelect>
            <AddIcon onClick={this.handleAddColumn} label="Add column" />
          </div> : null
        }
      </div>
    </div>);
  }
}

export default withStyles(s)(HOC(MiniPackingList));

export const PackingList = withStyles(s)(MiniPackingList);

function Row(props) {
  const { cells = [], rowIndex, selectedRowIndex, selectedCellIndex, handleSelectCell, handleChangeCell, readOnly } = props;
  const selected = rowIndex === selectedRowIndex;
  return (
    <div className={cx(s.row, props.className)}>
      {
        cells.map((cell, i) => <Cell
          key={i}
          readOnly={readOnly}
          cell={cell}
          cellIndex={i}
          rowIndex={rowIndex}
          selected={selected && i === selectedCellIndex}
          handleSelect={handleSelectCell}
          handleChangeCell={handleChangeCell}
          column={props.columns[i]}
        />)
      }
    </div>
  );
}

class Cell extends PureComponent {
  constructor(props) {
    super(props);
    this.inputId = s.root + `-${(Math.random() + 1).toString(16).substring(7)}-cell-${props.rowIndex}-${props.cellIndex}`;
    if (props.column.validations && props.column.validations.indexOf('isNumeric') !== -1) {
      this.isNumeric = true;
    }
  }

  componentDidMount() {
    if (this.props.selected) {
      const input = document.getElementById(this.inputId);
      if (input) {
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected && this.props.selected) {
      const input = document.getElementById(this.inputId);
      if (input) {
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    }
  }

  handleClick=() => {
    if (!this.props.selected) {
      this.props.handleSelect(this.props.rowIndex, this.props.cellIndex);
    }
  };
  handleKeyUp=(e) => {
    const keyCode = e.which;
    const value = e.currentTarget.value;
    const selectionStart = e.currentTarget.selectionStart;
    if (keyCode === 13) { //enter or tab
      e.preventDefault();
      this.props.handleSelect(this.props.rowIndex, this.props.cellIndex + 1, 'right');
      return;
    }
    if (keyCode === 39 && selectionStart === value.length) { //right arrow
      this.props.handleSelect(this.props.rowIndex, this.props.cellIndex + 1, 'right');
      return;
    }
    if (keyCode === 37 && selectionStart === 0) { //left arrow
      this.props.handleSelect(this.props.rowIndex, this.props.cellIndex - 1, 'left');
      return;
    }
    if (keyCode === 38) { //up arrow
      this.props.handleSelect(this.props.rowIndex - 1, this.props.cellIndex, 'up');
      return;
    }
    if (keyCode === 40) { //down arrow
      this.props.handleSelect(this.props.rowIndex + 1, this.props.cellIndex, 'down');
      return;
    }
  };
  handleKeyDown=(e) => {
    const keyCode = e.which;
    if (keyCode === 9) { // tab
      if (e.shiftKey) {
        this.props.handleSelect(this.props.rowIndex, this.props.cellIndex - 1, 'left');
      } else {
        this.props.handleSelect(this.props.rowIndex, this.props.cellIndex + 1, 'right');
      }
      e.preventDefault();
      return;
    }
    if (keyCode === 13) {
      return e.preventDefault();
    }
  };
  handleChange=(e) => {
    let value = e.currentTarget.value;
    if (this.isNumeric && value && value.indexOf(',')) {
      value = value.replace(/,/g, '.');
    }
    this.props.handleChangeCell(e, this.props.rowIndex, this.props.cellIndex, value);
  };

  render() {
    const { cell, cellIndex, rowIndex, selected, column, readOnly } = this.props;
    return (<div data-valid={!cell.invalid} onClick={this.handleClick} className={cx(s.cell, s[column.size], (selected && !readOnly) && s.selected, !cell.value && s.placeholder, cell.invalid && s.invalid)}>
      {selected && !readOnly ?
        <input id={this.inputId} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} onChange={this.handleChange} value={cell.value} />
        : <span title={cell.value} >{cell.value || column.placeholder || '' }</span>}
      {cell.invalid && column.validationError ?
        <div className={s.tooltip}>{column.validationError}</div>
        : null}
    </div>);
  }
}
