import { removeFromArray, replaceInArray } from '../../core/utils';
// noinspection JSUnusedGlobalSymbols
/** @name PROFILE_APPROVE_MEMBER_REDUCER */
export default function PROFILE_APPROVE_MEMBER(state, action) {
  const member = { ...action.payload, role: 'manager' };
  const newState = { user: { ...state.user } };
  newState.user.company = { ...newState.user.company };
  const index = newState.user.company.participants.findIndex(m => member._id === m._id);
  newState.user.company.participants =
    replaceInArray(newState.user.company.participants, index, member);
  return { ...state, ...newState };
}
