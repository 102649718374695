import React from "react";
import NarrowFormsyText from "../../NewInputs/NarrowFormsyText";
import TextField from "material-ui/TextField";
import {HiddenButtons} from "../../Common/HiddenButtons";
import s from './Preview.scss';
import {connect} from "react-redux";
import {updateCargoDetails} from "../../../actions/cargo";
import {debounceWithoutFirstCall} from "../../../core/utils";
import cx from 'classnames';

 class AdditionalCargoDetailsEdit extends React.PureComponent<{ value: string, updateCargoDetails: () => Promise<void>, request: any, index: number}> {
  state = {};

  static defaultProps = {
    value: "",
    as: TextField,
    buttonsEnabled: true,
  }

  constructor(props) {
    super(props);
    this.state.value = this.props.value;
    this.state.prevValue = this.props.value;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({value: nextProps.value, prevValue: nextProps.value, shouldSave: false});
    }
  }

  handleChange = (e) => {
    this.setState({value: e.target.value, shouldSave: this.state.prevValue !== e.target.value});
  }

  handleSave = async () => {
    const val = this.state.value;
    const cargoDetails = this.props.request.cargo.map(c => ({ additionalCargoDetails: c.additionalCargoDetails }));
    cargoDetails[this.props.index].additionalCargoDetails = val;
    this.props.request.cargo[this.props.index].additionalCargoDetails = val;
    await this.props.updateCargoDetails(this.props.request, cargoDetails);
    this.setState({ prevValue: val, shouldSave: false });
  }

  handleCancel = () => {
    this.setState({value: this.state.prevValue, shouldSave: false});
  }


  render() {
    const { value, buttonsEnabled, ...restProps } = this.props;
    return <div className={cx(s.additional_cargo_details_edit, !buttonsEnabled && s.full_input_width)}>
      <NarrowFormsyText
        value={this.state.value}
        onChange={this.handleChange}
        as={TextField}
        hintText={"Additional details"}
        multiLine
        adaptHeightByValue={false}
        {...restProps}
      />
      <HiddenButtons
        className={s.buttons}
        shouldShow={this.state.shouldSave && buttonsEnabled}
        handleSubmit={this.handleSave}
        handleCancel={this.handleCancel}
      />
    </div>;
  }
}

export default connect(state => ({ user: state.login.user }), { updateCargoDetails })(AdditionalCargoDetailsEdit);
