/*
 * @Author: salterok
 * @Date: 2017-10-06 17:39:27
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-27 17:41:30
 */

import React from "react";
import s from "../Chat.scss";
import cx from "classnames";

function regularMessage({ content, read, isMine }) {
  return (
    <div>
      <p
        style={{
          padding: !isMine ? '10px 9px 10px 15px' : '10px 15px 10px 9px',
          backgroundColor: (!isMine && read) ? '#EDEDED' :
            (!isMine && !read) ? '#4380C7' : 'transparent',
          border: isMine ? '1px solid #EDEDED' : null,
          color: (!isMine && !read) ? '#FFFFFF' : 'var(--text-dark)'

        }}
        dangerouslySetInnerHTML={content}
      >
      </p>
      <span
        className={cx((isMine) ? s.arrow_owner: s.arrow_contact)}
        style={{
          backgroundColor: (!isMine && read) ? '#EDEDED' :
            (!isMine && !read) ? '#4380C7' : '#FFFFFF',
        }}
      />
    </div>
  );
}

function linkMessage({ link, message, isMine, read }) {
  return (
    <div>
      <a
        style={{
          backgroundColor: (!isMine && read) ? '#EDEDED' :
            (!isMine && !read) ? '#7FC931' : 'transparent',
          border: isMine ? '1px solid #EDEDED' : null
        }}
        href={message.content}
        target="_blank"
        rel="noreferrer noopener"
        alt=""
      >{message.content}</a>
      <span
        className={cx((isMine) ? s.arrow_owner: s.arrow_contact)}
        style={{
          backgroundColor: (!isMine && read) ? '#EDEDED' :
            (!isMine && !read) ? '#7FC931' : '#FFFFFF',
        }}
      />
    </div>
  );
}

/** @typedef {Object} ChatMessage
 * @property {String} _id
 * @property {String} roomId
 * @property {String} senderId
 * @property {Date} sentAt
 * @property {Boolean} seen
 * @property {String} content
 * @property {Object} sender
 */

/**
 * @param {{ message: ChatMessage, me: string }} props
 */
export function genericMessage(props) {
  const { message, me, read, found, messageRef } = props;
  const isMine = message.senderId === me;

  return (
    <div key={message._id} ref={messageRef}>
      <div
        className={s.wrapper_message}
        style={{
          justifyContent: (message.senderId !== me) ? 'flex-start' : 'flex-end',
        }}
      >
        {message.avatar ?
          <img className={s.avatar} src={message.avatar} alt=""/>
          : null
        }
        <div
          className={s.message}
          style={{
            margin: message.senderId !== me ? '0px 0 0 10px' : '0 10px 0 0',
          }}
        >
          {!message.content.indexOf('http') ?
            linkMessage({ message, read, isMine })
            :
            regularMessage({ content: replaceFoundParts(message.content, found), read, isMine })
          }
          <span
            className={s.time_message}
            style={{
              textAlign: message.senderId !== me ? 'left' : 'right',
            }}
          >
          {message.sentAt ? message.sentAt.toLocaleTimeString() : null}
        </span>
        </div>
      </div>
    </div>
  );
}

function replaceFoundParts(text, part) {
  return { __html: escapeHtml(text).replace(part, `<span class="${s.m}">$&</span>`) };
}

function escapeHtml(html)
{
  var text = document.createTextNode(html);
  var div = document.createElement('div');
  div.appendChild(text);
  return div.innerHTML;
}
