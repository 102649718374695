import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Menu_Sandvich = props => (
  <svg {...props} width="18px" height="12px" viewBox="0 0 18 12" version="1.1">
    <defs />
    <g
      id="WEB"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.4"
    >
      <g
        id="Main-Deck-1"
        transform="translate(-15.000000, -54.000000)"
        fill="#FFFFFF"
      >
        <g id="Menu/Sandvich" transform="translate(12.000000, 48.000000)">
          <rect id="Rectangle-2" x="3" y="6" width="18" height="2" />
          <rect id="Rectangle-2-Copy" x="3" y="11" width="18" height="2" />
          <rect id="Rectangle-2-Copy-2" x="3" y="16" width="18" height="2" />
        </g>
      </g>
    </g>
  </svg>
);

Menu_Sandvich = pure(Menu_Sandvich);
Menu_Sandvich.displayName = 'Menu_Sandvich';
Menu_Sandvich.muiName = 'SvgIcon';

export default Menu_Sandvich;
