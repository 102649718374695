import React from 'react';
import pure from 'recompose/pure';

const _ContactsTwitter = props => (
  <span {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37257 0 0 5.37258 0 12C0 18.6274 5.37257 24 12 24Z" fill="#285596"/>
      <path d="M5.03218 6L10.1275 12.8591L5 18.4358H6.15399L10.6431 13.5533L14.2702 18.4358H18.1973L12.8153 11.1909L17.5879 6H16.4339L12.2997 10.4967L8.95926 6H5.03218ZM6.72921 6.85579H8.53332L16.5 17.5799H14.6959L6.72921 6.85579Z" fill="white"/>
    </svg>
  </span>
);

const ContactsTwitter = pure(_ContactsTwitter);
ContactsTwitter.displayName = 'ContactsTwitter';
ContactsTwitter.muiName = 'SvgIcon';

export default ContactsTwitter;
