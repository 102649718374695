import React, { Component } from "react";
import FormsyText from "../Common/FormsyText";
import styles from "./muistyles";
import cx from "classnames";
import s from "./inputs.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
// flow:
// if there is controlled value from props there are two branches determining height:
// check this value for new line character, if there are, then rows = value.split('/n).length
// if there none, check for textarea's scrollHeight, and if it's bigger then minHeight, then rows = textarea.scrollHeight / minHeight
export class NarrowFormsyText extends Component {
  static defaultProps = {
    as: FormsyText,
    adaptHeightByValue: true,
  };

  state = {
    rows: this.props.rows ?? 1,
  };

  componentDidMount() {
    this.props.innerRef?.(this.refs.input);

    if (this.props.multiLine) {
      this.computeRowsAndHeight();
      setTimeout(this.computeRowsAndHeight,10);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.innerRef?.(this.refs.input);
    if (this.props.multiLine) {
      if (prevProps.value !== this.props.value) {
        this.computeRowsAndHeight();
      }
    }
  }

  computeRowsAndHeight = () => {
    const { input } = this.refs;
    const value = this.props.value ?? input?.getValue?.();
    const defaultStyles = this.getDefaultStyles(this.props);

    const lineHeight = parseInt(defaultStyles.textareaStyle.lineHeight, 10);
    if (typeof value === 'undefined' || value === null || value === '') {
      this.setState({
        rows: 1,
      });
      this._inputEl?.handleHeightChange?.(null, lineHeight);
      return;
    }
    if (this.props.adaptHeightByValue) {
      const reg = /\n/g;
      let lineBreaks = (String(value).match(reg) || '').length;

      // if (value[value.length - 1] === '\n') {
      //   lineBreaks += 1;
      // }

      const rows = lineBreaks ? Math.min(lineBreaks + 1, this.props.maxRows ?? Infinity) : 1;
      this.setState({
        rows,
      });
      this._inputEl?.handleHeightChange?.(null, rows * lineHeight);
      return;
    }

    const textarea = this._inputEl?.input?.refs?.input || this.refs?.input?.input?.refs?.input;

    if (textarea) {
      if (!textarea.offsetParent) {
        return;
      };
      const rows = Math.round(textarea.scrollHeight / lineHeight);

      this.setState({
        rows,
      });
      const newHeight = textarea.scrollHeight + parseInt(this.props.paddingTop || styles.textareaContainer.paddingTop, 10) + parseInt(this.props.paddingBottom || styles.textareaContainer.paddingBottom, 10);
        this._lastSavedRows = rows;
        this._lastSavedHeight = newHeight;
        if (this._inputEl) {
          this._inputEl.handleHeightChange(null, newHeight);
        } else if (this.refs.input) {
          this.refs.input.handleHeightChange(null, newHeight);
        }
    }
  };
  // textarea height is now 100% of container. To center it along hr and label i use padding on container.
  // textarea height is calculated by subtracting paddingTop and paddingBottom of container height.
  // each textarea new line is half it's initial height.
  mergeTextareaStyles = mergeStyles => {
    const containerPaddingTop = parseInt(this.props.paddingTop || styles.textareaContainer.paddingTop, 10);
    const containerPaddingBottom = parseInt(this.props.paddingBottom || styles.textareaContainer.paddingBottom, 10);
    const textAreaHeight = this.props.textareaHeight ? (this.props.textareaHeight - (containerPaddingTop + containerPaddingBottom)) : mergeStyles.textareaStyle.lineHeight;

    const copy = {
      ...mergeStyles,
    };

    const areaHeight = Math.max(parseInt(textAreaHeight, 10), parseInt(mergeStyles.textareaStyle.lineHeight, 10));
    const rowsHeight = this.state.rows * parseInt(mergeStyles.textareaStyle.lineHeight, 10);

    copy.style = {
      ...copy.style,
      maxHeight: `${Math.max(rowsHeight, areaHeight) + containerPaddingTop + containerPaddingBottom}px`,
      paddingTop: this.props.paddingTop || containerPaddingTop,
      paddingBottom: this.props.paddingBottom || containerPaddingBottom,
    };

    copy.hintStyle = {
      ...copy.hintStyle,
      top: containerPaddingTop,
    };

    return copy;
  };

  enterAddKey = ev => {
    const key = ev.keyCode || ev.which;

    switch (key) {
      case 13: {
        if (!this.props.adaptHeightByValue) return;
        this.setState(state => ({
          ...state,

          rows: Math.min(state.rows + 1, this.props.maxRows ?? Infinity),
        }));
        break;
      }
      case 8: {
        const val = ev.target.value;
        const deletingChar = val[val.length - 1];
        if (deletingChar === "\n") {
          this.setState(state => ({
            ...state,
            rows: Math.max(state.rows - 1, 1),
          }));
          break;
        }
      }
    }

  };

  handleBlur = ev => {
    setTimeout(() => {
      return this.computeRowsAndHeight();
    }, 1)
  }

  refInput = (el) => {
    this._inputEl = el;
  }

  getDefaultStyles = (props) => {
    return {
      style: {
        ...styles.container,
        ...props.style
      },
      floatingLabelFocusStyle: styles.text.labelFocus,
      floatingLabelStyle: { ...styles.text.label, ...props.floatingLabelStyle },
      inputStyle: { ...styles.text.input, ...props.inputStyles },
      floatingLabelShrinkStyle: { ...styles.text.labelShrink, ...props.floatingLabelShrinkStyle },
      underlineStyle: { ...styles.text.underline, ...props.underlineStyle },
      underlineFocusStyle: styles.text.underlineFocus,
      textareaStyle: styles.text.textarea,
      errorStyle: styles.error,
      hintStyle: { ...styles.text.label, ...props.hintStyle },
    };
  }

  render() {
    const {
      inputStyles,
      classes,
      style = {},
      as: El,
      multiLine,
      ...props
    } = this.props;

    let defaultProps = {
      ...props
    };

    let defaultStyles = this.getDefaultStyles(this.props);

    if (multiLine) {
      defaultStyles = this.mergeTextareaStyles(defaultStyles);
      defaultProps.onKeyDown = (ev) => {
        this.enterAddKey(ev);
        if (!this.props.adaptHeightByValue) {
          setTimeout(() => {
            this.computeRowsAndHeight();
          });
        }
        if (this.props.onKeyDown) {
          this.props.onKeyDown(ev);
        }
      };
      defaultProps.multiLine = true;
      defaultProps.rows = this.state.rows;
      if (this.props.adaptHeightByValue) {
        defaultProps.onBlur = ev => {
          this.handleBlur(ev);
          if (this.props.onBlur) {
            this.props.onBlur(ev);
          }
        };
      }
      else {
        defaultProps.onChange = (ev, ...rest) => {
          this.computeRowsAndHeight();
          if (this.props.onChange) {
            this.props.onChange(ev, ...rest);
          }
        };
      }
    }

    defaultProps = {
      ...defaultProps,
      ...defaultStyles
    };

    return (
      <El
        className={cx(
          "input_default",
          multiLine ? "input_default_multiline" : "",
          classes
        )}
        ref="input"
        innerRef={this.refInput}
        {...defaultProps}
      />
    );
  }
}

export default NarrowFormsyText;
