import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import Form from '../About/Form';
import Link from '../../Link';
import RoundThemableButton from '../../Common/RoundThemableButton';
import TradingDesk from '../../Icons/TradingDesk';
import Exchange from '../../Icons/Exchange';
import FleetMonitor from '../../Icons/FleetMonitor';
import CargoMonitor from '../../Icons/CargoMonitor';
import Documents from '../../Icons/Documents';
import PortsIcon from '../../Icons/PortsIcon';

class PageSolutionsExchange extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_exchange_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Solution for E-mail processing,<br />internal and external<br />communication, tagging and data-<br />management</h1>
                </div>

                <div className={s.wrapper_icon}>
                  <Exchange />
                  <p>SHIPNEXT Exchange</p>
                </div>
                <div className={s.wrapper_links}>
                  <a href="#how-it-works" className={s.wrapper_links_link}>How it works</a>
                  <a href="#use-case" className={s.wrapper_links_link}>Use Case</a>
                  {/* {!this.props.user && <RoundThemableButton className={s.wrapper_links_link_color} component={Link} to={'/register'}>Register</RoundThemableButton>} */}
                </div>
              </div>
              <div className={s.right_part}>
                <Form
                  label={'Request demo'}
                  requestType={'shipnextDemo'}
                />
                <button
                  onClick={this.props.handleChangeForm.bind(this, 'Request demo', 'shipnextDemo')}
                >
                  Request demo
                </button>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="how-it-works" />
        <div className={cx(s.how_it_works, s.how_it_works_ex)}>
          <div className={s.container}>
            <h2 className={s.title_block}>How it works</h2>
          </div>
          <div className={s.first_step}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>1</div>
                  <div className={s.right_part}>
                    <div className={s.wrapper_info}>
                      <div className={s.info_left}>
                        <h3>What’s a SHIPNEXT Exchange</h3>
                        <p className={s.description}>
                        Everyday shipping still today heavily relies on email communication. While SHIPNEXT provides advanced solutions enabling shipping, shipbroking, and trading firms to communicate and work through a platform, giving them access to emails is very important.
                        </p>
                        <p className={s.description}>
                        SHIPNEXT Exchange is an email tool that helps manage and store emails, communicate, build up internal collaboration, clear up the email flow, extract and store data.
                        </p>
                      </div>
                      <div className={s.info_right}>
                        <div className={s.wrapper_image}>
                          <img src="/images/solutions/ex_step_1.png" alt="What’s a SHIPNEXT Exchange" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className={cx(s.second_step, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>2</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Why use SHIPNEXT Exchange?</h3>
                      <p className={s.description}>
                      If your daily work is related to shipping and transportation, using email to negotiate, collect cargo- and fleet-related data, and store information consumes up to 80% of your time.
                      </p>
                      <p className={s.description}>
                      SHIPNEXT uses Artificial intelligence to help users extract valuable cargo-,  fleet- and freight-related  data, and clear up the email flow with the help of filters and tags.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/ex_step_2.png" alt="Why use SHIPNEXT Exchange?" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.third_step, s.divide_figure)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>3</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Machine learning and Artificial<br />Intelligence</h3>
                      <p className={s.description}>
                      SHIPNEXT as a solution to process your emails inside your Email software using SHIPNEXT algorithms. SHIPNEXT uses big data analysis, machine learning and AI to process the logic inside the incoming email flow for instant processing and Cargo-to-Ship matching.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/pages/diagram-solution-ex.png" alt="Machine learning and Artificial Intelligence" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.fourth_step, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>4</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>How does SHIPNEXT compare to Outlook,<br /> Gmail, Sedna, LgMar.</h3>
                      <p className={s.description}>
                      SHIPNEXT is primarily a platform, but it does provide a basic email service – Exchange – as a communication tool. SHIPNEXT, however, also provides an integrated Chat. Both the Exchange and the Chat are interoperable with other Services. Outlook, Gmail, Sedna, LgMar are interconnected with Exchange, while Chat is interoperable with Teams, WhatsApp and Skype.
                      </p>
                      <p className={s.description}>
                      While SHIPNEXT Exchange bares basic features of email service - compose, reply, forward, etc – and has unique features such as tagging, filing, and interoperability with the trading desk, other email services consist of other more advanced features.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/ex_step_4.png" alt="How does SHIPNEXT compare to Outlook, Gmail, Sedna, LgMar." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="use-case" />
        <div className={s.use_case}>
          <div className={s.container}>
            <h2 className={s.title_block}>Use Case</h2>
            <div className={s.wrapper_use_case}>
              <div className={s.wrapper_svg}>
                <Exchange />
              </div>
              <div className={s.description_use_case}>
                <ul>
                  <li><h3>Carriers or Shipping Companies</h3> use Shipnext Exchange to work with their daily email flows. Each manager can defined whether to exclude or not emails related to Cargo/Quotation requests, Orders, Open fleet positions,  S&P Positions or other types of emails. To build a good cooperation users tag emails with preset #tags, that may container manager’s names, trade, type or other category for quick search and better filing.</li>
                  <li><h3>Shipbrokers, Traders and Shippers</h3> use Shipnext Exchange to work with their daily email flows. Each manager can defined whether to exclude or not emails related to Cargo/Quotation requests, Orders, Open fleet positions,  S&P Positions or other types of emails. To build a good cooperation users tag emails with preset #tags, that may container manager’s names, trade, type or other category for quick search and better filing.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="other-solutions" />
        <div className={s.other_solutions}>
          <div className={s.container}>
            <h2 className={s.title_block}>Other solutions</h2>
            <div className={s.links_other_solutions}>
              <Link className={s.part_link} to="/solution-shipnext-marketplace">
                <div>
                  <TradingDesk />
                  <h3>SHIPNEXT<br /> Trading Desk</h3>
                </div>
                <p>Ship Next Your Cargo</p>
              </Link>
              <Link className={s.part_link} to="/solution-shipnext-cargo-monitor">
                <div>
                  <CargoMonitor />
                  <h3>SHIPNEXT<br /> Cargo Monitor</h3>
                </div>
                <p>Digitize and Automate Your Supply Chain</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-fleet-monitor'}>
                <div>
                  <FleetMonitor />
                  <h3>SHIPNEXT<br /> Fleet Monitor</h3>
                </div>
                <p>Track Your Fleet</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-documents'}>
                <div>
                  <Documents />
                  <h3>SHIPNEXT <br /> Documents</h3>
                </div>
                <p>Your Contract Management</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-ports'}>
                <div>
                  <PortsIcon />
                  <h3>SHIPNEXT <br /> Ports</h3>
                </div>
                <p>Port Data-search Base</p>
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(s)(PageSolutionsExchange);
