import orderBy from 'lodash/orderBy';
import {isTagsFitted, safeDate} from '../../core/utils';
import {dateISOLocal} from "../../core/format";
import {replaceFromWithAliases} from "../../components/Emails/utils";

/** @name EMAILS_LIST_CHANGES_REDUCER */
export default function EMAILS_LIST_CHANGES(state, { payload } ) {
  const newState = {};
  switch (payload.kind) {
    case 'changed': {
      if (!payload.mails.length) {
        return state;
      }
      let data = state.emailList.data;
      for (let i = 0; i < payload.mails.length; i++) {
        const mail = payload.mails[i];
        replaceFromWithAliases(mail);
        let folder = 'received';
        if (mail.archived) {
          folder = 'archive';
        } else if (mail.isComposed) {
          folder = 'sent';
        }
        const index = data.findIndex(m => m.id === mail.id);
        const isInList = index > -1;
        let isFilterFitted = filterByTags(state.emailList.filters.tags)(mail, isInList) && filterByScopes(state.emailList.filters.scopes)(mail)
        && filterByFlags(state.emailList.filters, mail);
        if (!isInList) {
          isFilterFitted = isFilterFitted && filterByFields(state.emailList.filters, mail);
        }

        if ((folder !== state.emailList.folder) || !isFilterFitted) {
          if (isInList && (state.emailList.folder === 'archive' || folder === 'archive' || !isFilterFitted)) {
            data = [...data];
            data.splice(index, 1);
          }
          continue;
        }
        let _data = [...data];
        if (isInList) {
          _data[index] = { ...data[index], ...mail };
          data = _data;
        } else {
          _data.push(mail);
          _data = sortMails(_data);
          if (!isLast(_data, mail) || !state.emailList.cursor) {
            data = _data;
          }
        }
      }
      if (data === state.emailList.data) {
        return state;
      }
      newState.emailList = { ...state.emailList, data: sortMails(data) };
    }
      break;
    default:
      return state;
  }
  return { ...state, ...newState };
}


function filterByType(types = { cargo: true, vessel: true, sales: true, tc: true, spam: true }) {
  return (mail) => types[mail.predictedType];
}
function filterByScopes(scopes = []) {
  return (mail) => {
    if (!scopes.length) {
      return true;
    }
    return scopes.some(scope =>
      mail.predictedType === scope.type
      && (scope.processed ?
        (mail.recognized === scope.processed || mail.recognizedByUser === scope.processed)
        : (mail.recognized === scope.processed && mail.recognizedByUser === scope.processed)
      )
    );
  };
}

function sortMails(mails) {
  return orderBy(mails, ['receivedAt', 'refNo'], ['desc', 'desc']);
}

function isLast(array, item) {
  return array.indexOf(item) === (array.length - 1);
}

function filterByTags(tags) {
  return function (message, skipTextMatch) {
    message.fullTextSearch = message.fullTextSearch || `${message.refNo} ${message.subject} ${message.text}`;
    return isTagsFitted(message, tags, skipTextMatch);
  };
}

function filterByFlags(filters, mail){
  let fitted = true;
  if (typeof filters.read === 'boolean') {
    fitted = mail.hasUnread === !filters.read;
  }
  if (typeof filters.private === 'boolean') {
    fitted = fitted && mail.isPrivate === filters.private;
  }
  if (typeof filters.favorite === 'boolean') {
    fitted = fitted && mail.favorite === filters.favorite;
  }
  if (typeof filters.attached === 'boolean') {
    fitted = fitted && mail.hasAttachments === filters.attached;
  }
  return fitted;
}

function filterByFields(filters, mail) {
  if (filters.receivedAt && (filters.receivedAt[0] || filters.receivedAt[1])) {
    const receivedAt = safeDate(dateISOLocal(mail.receivedAt));
    const from = safeDate(filters.receivedAt[0] || new Date(0));
    const to = safeDate(filters.receivedAt[1] || new Date());
    return from <= receivedAt && to >= receivedAt;
  }
  return true;
}
