import React from 'react'
import NarrowSelect from './../../NewInputs/NarrowSelect';
import MenuItem from 'material-ui/MenuItem';
import s from './Maintenance.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export const maintenanceValues = [{
  name: "",
  value: undefined,
  id: 1,
}, {
  name: 'ex dry dock',
  value: 'exdd',
  id: 2,
},
{
  name: 'ex ss',
  value: 'exss',
  id: 3,
},
{
  name: 'ex dd/ss',
  value: 'exddss',
  id: 4,
}]

export const dictionary = {
  exdd: {
    name: 'ex dd',
    value: 'exdd',
    id: 2,
  },
  exss: {
    name: 'ex ss',
    value: 'exss',
    id: 3,
  },
  exddss: {
    name: 'ex dd/ss',
    value: 'exddss',
    id: 4,
  }
}

export function _DisplayMaintenance({ maintenance }) {
  maintenance = dictionary[maintenance];
  if (!maintenance) return null;
  return (
    <span className={s.ex_dd}>({ maintenance.name })</span>
  )
}

export const DisplayMaintenance = withStyles(s)(_DisplayMaintenance)


function Maintenance() {
  return (
    <NarrowSelect
      floatingLabelText="Select"
      name="maintenance"
      maxHeight={271}
    >
      {
        maintenanceValues.map(item => <MenuItem value={item.value} primaryText={item.name} key={item.id} />)
      }
    </NarrowSelect>
  )
}

export default Maintenance
