import Api from './api';
import {convertToSameTimeZ} from './api'
import { toUTC } from '../utils';
export default class TagsApi extends Api {
    static getAllTags(params) {
      const paramsString = Object.entries(params).map(([key, val]) => `${key}=${val}`).join("&");
      return this.fetch(`/tags/tags?${paramsString}`, {
        method: 'GET',
      }, '/api/v2')
    }
    static fetchCategories(entity) {
      return this.fetch(`/tags/categories?entity=${entity}`, {
        method: 'GET',
      }, '/api/v2')
    }
    static getTagsForUser(entity) {
      return this.fetch(`/tags/tags/my?entity=${entity}`, {
        method: 'GET',
      }, '/api/v2')
    }
    static saveCargoTags(cargoId, tags) {
      return this.fetch(`/requests/cargo/${cargoId}/tags`, {
        method: 'POST',
        body: JSON.stringify(tags)
      })
    }
    static saveVesselTags(vesselId, tags) {
      return this.fetch(`/requests/vessel/${vesselId}/tags`, {
        method: 'POST',
        body: JSON.stringify(tags)
      })
    }
    static saveVesselPositionTags(positionId, tags) {
      return this.fetch(`/requests/vesselRequest/${positionId}/tags`, {
        method: 'POST',
        body: JSON.stringify(tags)
      })
    }
    static saveTag(entity, tags) {
      return this.fetch(`/tags/tags?entity=${entity}`, {
        method: 'POST',
        body: JSON.stringify(tags)
      }, '/api/v2')
    }
    static deleteTags(entity, tags) {
      return this.fetch(`/tags/tags`, {
        method: 'DELETE',
        body: JSON.stringify(tags)
      }, '/api/v2')
    }
    static editTags(entity, tags) {
      return this.fetch(`/tags/tags/edit?entity=${entity}`, {
        method: 'POST',
        body: JSON.stringify(tags)
      }, '/api/v2')
    }

    static getAutotags(entity){
      return this.fetch(`/tags/filters?entity=${entity}`, {
        method: 'GET',
      }, '/api/v2');
    }
    static deleteAutotag(entity, autotag) {
      return this.fetch(`/tags/filters/${autotag._id}?entity=${entity}`, {
        method: 'DELETE',
      }, '/api/v2');
    }

    static saveAutotag(entity, autotag) {
      if (autotag._id && autotag._id.length > 24) {
        delete autotag._id;
      }
      if (!autotag._id || autotag.isNew) {
        return this.fetch(`/tags/filters?entity=${entity}`, {
          method: 'POST',
          body: JSON.stringify({ ...autotag, tags: autotag.tags.map(t => t._id || t) }),
        }, '/api/v2');
      }
      return this.fetch(`/tags/filters/${autotag._id}?entity=${entity}`, {
        method: 'PUT',
        body: JSON.stringify({ ...autotag, tags: autotag.tags.map(t => t._id || t) }),
      }, '/api/v2');
    }

  static getMembersTags(){
    return this.fetch(`/user/profile-tags`, {
      method: 'GET',
    }, '/api/v2');
  }
  static saveMembersTag({ userId, tagName, oldTagName }){
    return this.fetch(`/user/profile-tags`, {
      method: 'POST',
      body: JSON.stringify([{ userId, tagName: tagName || oldTagName, op: tagName ? 'set' : 'delete' }]),
    }, '/api/v2');
  }

  static getAllContactTags(params) {
    const paramsString = Object.entries(params).map(([key, val]) => `${key}=${val}`).join("&");
    return this.fetch(`/contact-tags/autocomplete?${paramsString}`, {
      method: 'GET',
    }, '/api/v2')
  }
}
