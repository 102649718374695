import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FormsySelect from './Select';
import MenuItem from 'material-ui/MenuItem';
import {AlertTooltip, StyledAlertTooltip} from "./CompanyChangesTooltip";
import WithTooltip from "./WithTooltip";
import s from "./Tags/Tags.scss";
import InfoIcon from "material-ui/svg-icons/action/info";
import CustomTooltip from "./CustomTooltip";
const floatingLabelStyle = {fontSize: '13px'};
const floatingLabelFocusStyle = {fontSize: '15px'};
const inputStyle = {fontSize: '13px'};
const hintStyle = {fontSize: '13px'};

const codeMap = {
  AFG: 'AF',
  ALA: 'AX',
  ALB: 'AL',
  DZA: 'DZ',
  ASM: 'AS',
  AND: 'AD',
  AGO: 'AO',
  AIA: 'AI',
  ATA: 'AQ',
  ATG: 'AG',
  ARG: 'AR',
  ARM: 'AM',
  ABW: 'AW',
  AUS: 'AU',
  AUT: 'AT',
  AZE: 'AZ',
  BHS: 'BS',
  BHR: 'BH',
  BGD: 'BD',
  BRB: 'BB',
  BLR: 'BY',
  BEL: 'BE',
  BLZ: 'BZ',
  BEN: 'BJ',
  BMU: 'BM',
  BTN: 'BT',
  BOL: 'BO',
  BES: 'BQ',
  BIH: 'BA',
  BWA: 'BW',
  BVT: 'BV',
  BRA: 'BR',
  IOT: 'IO',
  BRN: 'BN',
  BGR: 'BG',
  BFA: 'BF',
  BDI: 'BI',
  KHM: 'KH',
  CMR: 'CM',
  CAN: 'CA',
  CPV: 'CV',
  CYM: 'KY',
  CAF: 'CF',
  TCD: 'TD',
  CHL: 'CL',
  CHN: 'CN',
  CXR: 'CX',
  CCK: 'CC',
  COL: 'CO',
  COM: 'KM',
  COG: 'CG',
  COD: 'CD',
  COK: 'CK',
  CRI: 'CR',
  CIV: 'CI',
  HRV: 'HR',
  CUB: 'CU',
  CUW: 'CW',
  CYP: 'CY',
  CZE: 'CZ',
  DNK: 'DK',
  DJI: 'DJ',
  DMA: 'DM',
  DOM: 'DO',
  ECU: 'EC',
  EGY: 'EG',
  SLV: 'SV',
  GNQ: 'GQ',
  ERI: 'ER',
  EST: 'EE',
  ETH: 'ET',
  FLK: 'FK',
  FRO: 'FO',
  FJI: 'FJ',
  FIN: 'FI',
  FRA: 'FR',
  GUF: 'GF',
  PYF: 'PF',
  ATF: 'TF',
  GAB: 'GA',
  GMB: 'GM',
  GEO: 'GE',
  DEU: 'DE',
  GHA: 'GH',
  GIB: 'GI',
  GRC: 'GR',
  GRL: 'GL',
  GRD: 'GD',
  GLP: 'GP',
  GUM: 'GU',
  GTM: 'GT',
  GGY: 'GG',
  GIN: 'GN',
  GNB: 'GW',
  GUY: 'GY',
  HTI: 'HT',
  HMD: 'HM',
  VAT: 'VA',
  HND: 'HN',
  HKG: 'HK',
  HUN: 'HU',
  ISL: 'IS',
  IND: 'IN',
  IDN: 'ID',
  IRN: 'IR',
  IRQ: 'IQ',
  IRL: 'IE',
  IMN: 'IM',
  ISR: 'IL',
  ITA: 'IT',
  JAM: 'JM',
  JPN: 'JP',
  JEY: 'JE',
  JOR: 'JO',
  KAZ: 'KZ',
  KEN: 'KE',
  KIR: 'KI',
  PRK: 'KP',
  KOR: 'KR',
  KWT: 'KW',
  KGZ: 'KG',
  LAO: 'LA',
  LVA: 'LV',
  LBN: 'LB',
  LSO: 'LS',
  LBR: 'LR',
  LBY: 'LY',
  LIE: 'LI',
  LTU: 'LT',
  LUX: 'LU',
  MAC: 'MO',
  MKD: 'MK',
  MDG: 'MG',
  MWI: 'MW',
  MYS: 'MY',
  MDV: 'MV',
  MLI: 'ML',
  MLT: 'MT',
  MHL: 'MH',
  MTQ: 'MQ',
  MRT: 'MR',
  MUS: 'MU',
  MYT: 'YT',
  MEX: 'MX',
  FSM: 'FM',
  MDA: 'MD',
  MCO: 'MC',
  MNG: 'MN',
  MNE: 'ME',
  MSR: 'MS',
  MAR: 'MA',
  MOZ: 'MZ',
  MMR: 'MM',
  NAM: 'NA',
  NRU: 'NR',
  NPL: 'NP',
  NLD: 'NL',
  NCL: 'NC',
  NZL: 'NZ',
  NIC: 'NI',
  NER: 'NE',
  NGA: 'NG',
  NIU: 'NU',
  NFK: 'NF',
  MNP: 'MP',
  NOR: 'NO',
  OMN: 'OM',
  PAK: 'PK',
  PLW: 'PW',
  PSE: 'PS',
  PAN: 'PA',
  PNG: 'PG',
  PRY: 'PY',
  PER: 'PE',
  PHL: 'PH',
  PCN: 'PN',
  POL: 'PL',
  PRT: 'PT',
  PRI: 'PR',
  QAT: 'QA',
  REU: 'RE',
  ROU: 'RO',
  RUS: 'RU',
  RWA: 'RW',
  BLM: 'BL',
  SHN: 'SH',
  KNA: 'KN',
  LCA: 'LC',
  MAF: 'MF',
  SPM: 'PM',
  VCT: 'VC',
  WSM: 'WS',
  SMR: 'SM',
  STP: 'ST',
  SAU: 'SA',
  SEN: 'SN',
  SRB: 'RS',
  SYC: 'SC',
  SLE: 'SL',
  SGP: 'SG',
  SXM: 'SX',
  SVK: 'SK',
  SVN: 'SI',
  SLB: 'SB',
  SOM: 'SO',
  ZAF: 'ZA',
  SGS: 'GS',
  SSD: 'SS',
  ESP: 'ES',
  LKA: 'LK',
  SDN: 'SD',
  SUR: 'SR',
  SJM: 'SJ',
  SWZ: 'SZ',
  SWE: 'SE',
  CHE: 'CH',
  SYR: 'SY',
  TWN: 'TW',
  TJK: 'TJ',
  TZA: 'TZ',
  THA: 'TH',
  TLS: 'TL',
  TGO: 'TG',
  TKL: 'TK',
  TON: 'TO',
  TTO: 'TT',
  TUN: 'TN',
  TUR: 'TR',
  TKM: 'TM',
  TCA: 'TC',
  TUV: 'TV',
  UGA: 'UG',
  UKR: 'UA',
  ARE: 'AE',
  GBR: 'GB',
  USA: 'US',
  UMI: 'UM',
  URY: 'UY',
  UZB: 'UZ',
  VUT: 'VU',
  VEN: 'VE',
  VNM: 'VN',
  VGB: 'VG',
  VIR: 'VI',
  WLF: 'WF',
  ESH: 'EH',
  YEM: 'YE',
  ZMB: 'ZM',
  ZWE: 'ZW',
  AZR: 'azores',
  'Canary Islands': 'canary',
  CVA: 'holysee',
  XKX: 'kosovo',
  Madeira: 'madeira',
  Saba: 'saba',
  'Saint Eustatius': 'saint_eustatius',
  'EU': "eu",
  'eu': "eu",
};

function Icon({ code = '', ...props }) {
  return (<img {...props} alt=" " role="presentation" src={`/images/flags/${(codeMap[code] || '').toLocaleLowerCase()}.png`} />);
}

export function UnionIconTooltip({ country, horizontalAlign = 'left', verticalAlign }){
  if (!country.members?.length) {
    return null;
  }
  return <CustomTooltip horizontalAlign={horizontalAlign} verticalAlign={verticalAlign} dark tooltip={<div style={{ maxWidth: '225px', whiteSpace: 'normal', lineHeight: '16px' }}>{country.members.map(m => m.name).sort().join(', ')}</div>} ><InfoIcon style={{width: 20, height: 20, fill: '#787878'}}/></CustomTooltip>
}

class CountrySelect extends PureComponent {
  constructor(props, context) {
    super(props, context);
    if (props.withUnions) {
      this.countries = context.countries;
    } else {
      this.countries = context.countries.filter(c => !c.isUnion);
    }
  }
  selectionRenderer = (value) => {
    const country = this.context.countries.find(c => c._id === (value?._id || value));
    return (<MenuItem
      leftIcon={this.props.noFlag ? null : <Icon style={{ marginLeft: '0' }} code={country.code || country.name} />}
      key={country._id}
      value={this.props.countrySelector(country)}
      style={{ backgroundColor: 'transparent', paddingTop: '4px', fontSize:'13px' }}
      innerDivStyle={ this.props.noFlag ? { paddingLeft: '0', width: '155px', overflow: 'hidden' } : {paddingLeft: '36px', width: '100%' }}
      primaryText={country.name}
      className="menu_item"
    />);
  };
  render() {
    return (
      <FormsySelect
        selectionRenderer={this.selectionRenderer}
        floatingLabelStyle={floatingLabelStyle}
        floatingLabelFocusStyle={floatingLabelFocusStyle}
        hintStyle={hintStyle}
        inputStyle={inputStyle}
        fullWidth
        autoWidth
        {...this.props}>
        {this.countries.map((c, i) => (
          <MenuItem
            leftIcon={this.props.noFlag ? null : <Icon code={c.code || c.name} />}
            key={c._id}
            value={this.props.valueSelector ? this.props.valueSelector(c) : c._id}
            primaryText={c.name}
            innerDivStyle={ this.props.noFlag ? { padding: '8px', whiteSpace: 'normal', lineHeight: '16px' } : ''}
            style={{zIndex: this.countries.length - i }}
            secondaryText={<div style={{padding: "6px 0"}}><UnionIconTooltip country={c} /></div>}
          />
        ))}
      </FormsySelect>
    );
  }
}

CountrySelect.propTypes = FormsySelect.propTypes;
CountrySelect.defaultProps = {
  ...FormsySelect.defaultProps,
  countrySelector: country => country._id,
  withUnions: false,
};
CountrySelect.contextTypes = {
  countries: PropTypes.array,
  formsy: PropTypes.object,
};
export default CountrySelect;
