import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SelectionPlan.scss';
import cx from 'classnames';
import RaisedButton from '../Common/RaisedButton';
import plansDetails from '../Settings/plans';
import features from '../Settings/features';
import Formsy from 'formsy-react';
import StyledToggle from '../Common/StyledToggle';
import Link from '../Link';
import Media from 'react-media';
import plans from '../Settings/plans';
import SwipeableViews from 'react-swipeable-views';
import {Tabs, Tab} from 'material-ui/Tabs';
import ConfirmDowngradeDialog from './ConfirmDowngradeDialog';
import { isUpgradePlan, getFractional, isUserOnFreePlan } from '../../core/utils';
import history from '../../core/history';
import SpinnerButton from '../Common/SpinnerButton';

const Form = Formsy.Form;


// hardcoded indexes to blur subsctiprion plans
const disabledHeaders = [1];
const disabledPlans = [1,3,5];

const styleTabs={
    background: 'transparent',
    border: 'none',
    width: '8px',
    height: '8px',
    marginRight: '4px',
    marginLeft: '4px',
}

class SelectionPlan extends Component {
    state = {
        openedAllFeatures: false,
    }

    toggleOpenAllFeatures = () => {
        this.setState({
            openedAllFeatures: !this.state.openedAllFeatures
        })
    }

    handlePlanSelected = (newStripePlan) => {
     const { currentPlan, user } = this.props;
     if(!user) {
       history.push('/register');
     }
     const newPlan= { ...plans[newStripePlan.key], stripePlan: newStripePlan };
     if(currentPlan.key === newPlan.key || isUpgradePlan(currentPlan.stripePlan, newPlan.stripePlan)) {
       return this.props.handleChangePlan(newPlan.stripePlan);
     }
     this.setState({ confirmDowngradeDialogOpened: true, newPlan})
    }

    handleDowngrade = async (downgrade, newPlan) => {
      if(!downgrade) {
        this.setState({ confirmDowngradeDialogOpened: false, newPlan: null});
        return;
      }
      return await this.props.handleChangePlan(newPlan.stripePlan.id)
    }

    render () {
        const { plansSelected, pageSubscriptionChange, handleChangePlan, handleSelected, toggled, onToggle, user, currentPlan } = this.props;
        const stripePlan = currentPlan?.stripePlan;
        return (
            <div>
                <Media query="(max-width: 767px)" defaultMatches={false}>
                    {matches =>
                        matches ? (
                        <MobileTablePlan
                          handleSelected={this.handlePlanSelected}
                          toggled={toggled}
                          onToggle={onToggle}
                          plansSelected={plansSelected}
                          pageSubscriptionChange={pageSubscriptionChange}
                          user={user}
                          stripePlan={stripePlan}
                          openedAllFeatures={this.state.openedAllFeatures}
                          toggleOpenAllFeatures={this.toggleOpenAllFeatures}
                        />
                    ): (
                        <DesktopTablePlan
                          handleSelected={this.handlePlanSelected}
                          toggled={toggled}
                          onToggle={onToggle}
                          plansSelected={plansSelected}
                          pageSubscriptionChange={pageSubscriptionChange}
                          user={user}
                          stripePlan={stripePlan}
                          openedAllFeatures={this.state.openedAllFeatures}
                          toggleOpenAllFeatures={this.toggleOpenAllFeatures}
                        />
                    )}
                </Media>
              {this.state.confirmDowngradeDialogOpened ?
                <ConfirmDowngradeDialog currentPlan={currentPlan} selectedPlan={this.state.newPlan} handleClose={this.handleDowngrade} />
                : null}
            </div>

        )
    }
}

export default withStyles(s)(SelectionPlan);

class TableFeatures extends PureComponent {
    render () {
        const { plansSelected, feature, i, plansDetails } = this.props;

        return (
            <div className={s.body_table_compare}>
                <div className={s.title_features}>{feature}</div>
                <div className={cx(s.title_free)}>
                    { plans.free.featuresAll.includes(i) && <span className={s.icon_check} /> }
                </div>
                {plansSelected.map((plan) => {
                    const planDetail = plansDetails[plan.key];
                    const planDisabled =  false;
                    return (
                    <div className={planDisabled && s.table_cell_disabled}>{planDetail.featuresAll.includes(i) && <span className={s.icon_check} />}</div>
                    )
                })}
                <div className={cx(s.monitor)}>{i !== 1 && <span className={s.icon_check} />}</div>
            </div>
        )
    }
}



class DesktopTablePlan extends PureComponent{

  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { plansSelected, pageSubscriptionChange, stripePlan, toggled, onToggle, handleChangePlan, handleSelected, user, openedAllFeatures,toggleOpenAllFeatures } = this.props;
    return (
      <div className={s.wrapper_table_compare} style={isUserOnFreePlan(user) ? {height: "calc(100vh - 176px)"} : {}}>
        <div className={s.header_table_compare}>
          <div className={s.title_features}>Features</div>
          <div className={cx(s.title_free)}><p><span>FREE<br/> Limited Access</span></p></div>
          {plansSelected.map((plan, i) => {

            const planDisabled = false //disabledHeaders.includes(i);
            const planDetail = plansDetails[plan.key];
            const isCurrentPlan = stripePlan?.planId === plan.id;
            const isCurrentProduct = isCurrentPlan || stripePlan?.planId === plan.oppositePlan.id;
            return (
              <div key={plan.id} className={cx(isCurrentProduct && s.wrapper_title_current,planDisabled && s.table_cell_disabled)}>
                <p className={plan.isRecommended && s.recommended_plan}>
                  <span>{planDetail.title}</span>
                </p>
              </div>
            )
          })}
          <div className={cx(s.monitor)}><p><span>SHIPNEXT Monitor</span></p></div>
        </div>
        {pageSubscriptionChange && !openedAllFeatures ?
            features.slice(0, 7).map((feature, i) => (
              <TableFeatures plansSelected={plansSelected} feature={feature} i={i} plansDetails={plansDetails} />
            ))
          :
          features.map((feature, i) => (
            <TableFeatures plansSelected={plansSelected} feature={feature} i={i} plansDetails={plansDetails}/>
          ))
        }
        <div className={cx(s.body_table_compare, s.wrapper_plan_under_table)}>
          <div className={s.title_features}>
            {pageSubscriptionChange ?
              <div className={s.button_show_features} onClick={toggleOpenAllFeatures}>
                <span>{openedAllFeatures ? 'Hide part features ' : 'See all features' }</span>
                <span className={cx(s.arrow, openedAllFeatures ? s.arrow_up : null)}></span>
              </div>
              : null
            }
            <p className={s.pointer_select}>Please select product<br/> and paid subscription</p>
          </div>
          <div className={cx(s.wrapper_term_plan)}>
            <div className={s.wrapper_middle}>
              <p className={s.wrapper_price}>Free</p>
              <span className={s.save_info}>Limited access</span>
            </div>
            {!user && <PricingButton
              stylesName='gray'
              primary
              label='Try free plan'
              containerElement={<Link to='/register' />}
            />}
          </div>
          {plansSelected.map((plan, i) => {
            const _isUpgrade = isUpgradePlan(stripePlan, plan);
            const isCurrentPlan = stripePlan?.planId === plan.id;
            const isCurrentProduct = isCurrentPlan || stripePlan?.planId === plan.oppositePlan.id;
            let changeTitle =  _isUpgrade ? 'Upgrade' : 'Change' ;
            if(!user) {
              changeTitle = "Proceed";
            }
            const planDisabled = false // disabledHeaders.includes(i);
            return (
              <div className={cx(s.wrapper_term_plan, planDisabled && s.table_cell_disabled)} key={plan.id}>
                <Form>
                  <span className={cx(s.toggle_monthly_desktop, !toggled && s.active)}>Monthly</span>
                  <span className={cx(s.toggle_monthly_mob,!toggled && s.active)}>MO</span>
                  <StyledToggle toggled={toggled} onToggle={onToggle} />
                  <span className={cx(s.toggle_yearly_desktop, toggled && s.active)}>Yearly</span>
                  <span className={cx(s.toggle_yearly_mob, toggled && s.active)}>YR</span>
                </Form>
                <div className={s.wrapper_middle}>
                  <p className={s.wrapper_price}>
                    <span className={s.currency}>$</span>
                    <span className={s.figures}>{parseInt(plan.realAmount)}</span>
                    <span className={s.fractional}>{getFractional(plan.realAmount)}</span>
                    <span className={s.units}>/&nbsp;{plan.perShort}</span>
                  </p>
                  {plan.interval === 'year' ?
                    <span className={s.save_info}>Save $ {plan.moSaving} per month</span>
                    : <span className={s.save_info}>&nbsp;</span>}
                </div>
                {!isCurrentPlan &&  <PricingButton
                  primary
                  stylesName={_isUpgrade ? 'blue' : 'gray'}
                  label={changeTitle}
                  onClick={handleSelected.bind(this, plan)}
                />}

              </div>)
          })
          }
          <div className={cx(s.wrapper_term_plan, s.monitor)}>
            <div className={s.wrapper_middle}>
              <p className={cx(s.wrapper_price, s.on_demand)}>on demand</p>
              <span className={s.save_info}>&nbsp;</span>
            </div>
            <PricingButton
              primary
              label={<a style={{color: '#fff'}} href="mailto:support@shipnext.com">Contact Sales</a>}
              stylesName="blue"
            />
          </div>
        </div>
      </div>
    )
  }
}

class MobileTablePlan extends PureComponent {
    state = {
        slideIndex: 0,
    }
    handleChangeSlide = (value) => {
        this.setState({
          slideIndex: value,
        });
    };

    render () {
        const { plansSelected, pageSubscriptionChange, stripePlan, toggled, onToggle, handleChangePlan, handleSelected, user, openedAllFeatures,toggleOpenAllFeatures } = this.props;
        return (
            <div>
                <SwipeableViews
                    index={this.state.slideIndex}
                    onChangeIndex={this.handleChangeSlide}
                >
                    <div className={s.wrapper_table_compare}>
                        <div className={s.header_table_compare}>
                            <div className={s.title_features}>Features</div>
                            <div className={s.title_free}><p><span>FREE<br/> Limited Access</span></p></div>
                        </div>
                        {pageSubscriptionChange && !openedAllFeatures?
                                features.slice(0, 7).map((feature, i) => (
                                    <div className={s.body_table_compare}>
                                        <div className={s.title_features}>{feature}</div>
                                        <div className={s.title_free}>
                                        { plans.free.featuresAll.includes(i) && <span className={s.icon_check} /> }
                                        </div>
                                    </div>
                                ))
                            :
                                features.map((feature, i) => (
                                    <div className={s.body_table_compare}>
                                        <div className={s.title_features}>{feature}</div>
                                        <div className={s.title_free}>
                                            { plans.free.featuresAll.includes(i) && <span className={s.icon_check} /> }
                                        </div>
                                    </div>
                                ))
                            }
                        <div className={cx(s.body_table_compare, s.wrapper_plan_under_table)}>
                            <div className={s.title_features}>
                                {pageSubscriptionChange ?
                                    <div className={s.button_show_features} onClick={toggleOpenAllFeatures}>
                                        <span>{openedAllFeatures ? 'Hide part features ' : 'See all features' }</span>
                                        <span className={cx(s.arrow, openedAllFeatures ? s.arrow_up : null)}></span>
                                    </div>
                                    : null
                                }
                                <p className={s.pointer_select}>Please select product<br/> and paid subscription</p>
                            </div>
                            <div className={cx(s.wrapper_term_plan, s.wrapper_term_plan_static)}>
                                <div className={s.wrapper_middle}>
                                    <p className={s.wrapper_price}>Free</p>
                                    <span className={s.save_info}>Limited access</span>
                                </div>
                              {!user && <RaisedButton
                                style={{
                                  minWidth: '140px',
                                  maxWidth: '140px',
                                  height: '43px',
                                  borderRadius: '100px'
                                }}
                                buttonStyle={{
                                  backgroundColor: '#D2D2D2',
                                  borderRadius: '100px',
                                }}
                                overlayStyle= {{
                                  borderRadius: '100px',
                                }}
                                labelStyle={{
                                  padding: '0',
                                  fontFamily: 'Montserrat',
                                  fontSize: '15px',
                                  fontWeight: '600',
                                  lineHeight: '43px',
                                  textTransform: 'none',
                                  color: '#787878',
                                }}
                                primary
                                label='Try free plan'
                                containerElement={<Link to='/register' />}
                              /> }
                            </div>
                        </div>
                    </div>
                    {plansSelected.map(plan => {
                        const planDetail = plansDetails[plan.key];
                        const _isUpgrade = isUpgradePlan(stripePlan, plan);
                        const isCurrentPlan = stripePlan?.planId === plan.id;
                        const isCurrentProduct = isCurrentPlan || stripePlan?.planId === plan.oppositePlan.id;
                        let changeTitle =  _isUpgrade ? 'Upgrade' : 'Change' ;
                        if(!user) {
                          changeTitle = "Proceed";
                        }
                        return (
                            <div key={plan.id} className={s.wrapper_table_compare}>
                                <div className={s.header_table_compare}>
                                    <div className={s.title_features}>Features</div>
                                    <div className={isCurrentProduct && s.wrapper_title_current}>
                                        {isCurrentProduct && <span className={s.title_current_plan}>YOUR CURRENT PLAN</span>}
                                        <p className={plan.isRecommended && s.recommended_plan}>
                                            <span>{planDetail.title}</span>
                                        </p>
                                    </div>
                                </div>
                                {pageSubscriptionChange && !openedAllFeatures ?
                                        features.slice(0, 7).map((feature, i) => (
                                            <div className={s.body_table_compare}>
                                                <div className={s.title_features}>{feature}</div>
                                                <div className={s.title_free}>
                                                    {planDetail.featuresAll.includes(i) && <span className={s.icon_check} />}
                                                </div>
                                            </div>
                                        ))
                                    :
                                    features.map((feature, i) => (
                                        <div className={s.body_table_compare}>
                                            <div className={s.title_features}>{feature}</div>
                                            <div className={s.title_free}>
                                                {planDetail.featuresAll.includes(i) && <span className={s.icon_check} />}
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className={cx(s.body_table_compare, s.wrapper_plan_under_table)}>
                                    <div className={s.title_features}>
                                        {pageSubscriptionChange ?
                                            <div className={s.button_show_features} onClick={toggleOpenAllFeatures}>
                                                <span>{openedAllFeatures ? 'Hide part features ' : 'See all features' }</span>
                                                <span className={cx(s.arrow, openedAllFeatures ? s.arrow_up : null)}></span>
                                            </div>
                                            : null
                                        }
                                        <p className={s.pointer_select}>Please select product<br/> and paid subscription</p>
                                    </div>
                                    <div className={s.wrapper_term_plan} key={plan.id}>
                                        <Form>
                                            <span className={cx(s.toggle_monthly_desktop, !toggled && s.active)}>Monthly</span>
                                            <span className={cx(s.toggle_monthly_mob,!toggled && s.active)}>MO</span>
                                            <StyledToggle toggled={toggled} onToggle={onToggle} />
                                            <span className={cx(s.toggle_yearly_desktop, toggled && s.active)}>Yearly</span>
                                            <span className={cx(s.toggle_yearly_mob, toggled && s.active)}>YR</span>
                                        </Form>
                                        <div className={s.wrapper_middle}>
                                            <p className={s.wrapper_price}>
                                                <span className={s.currency}>$</span>
                                              <span className={s.figures}>{parseInt(plan.realAmount)}</span>
                                              <span className={s.fractional}>{getFractional(plan.realAmount)}</span>
                                              <span className={s.units}>/ {plan.perShort}</span>
                                            </p>
                                          {plan.interval === 'year' ?
                                            <span className={s.save_info}>Save $ {plan.moSaving} per month</span>
                                            : <span className={s.save_info}>&nbsp;</span>}
                                        </div>
                                        <RaisedButton
                                            className={s.button_plan}
                                            style={{
                                              minWidth: '140px',
                                              maxWidth: '140px',
                                              height: '43px',
                                              borderRadius: '100px',
                                            }}
                                            buttonStyle={{
                                              backgroundColor: !_isUpgrade ? '#D2D2D2' : '#1684DD',
                                              borderRadius: '100px',
                                            }}
                                            overlayStyle= {{
                                              borderRadius: '100px',
                                            }}
                                            labelStyle={{
                                              padding: '0',
                                              fontFamily: 'Montserrat',
                                              fontSize: '15px',
                                              fontWeight: !_isUpgrade ? '400' : '600',
                                              lineHeight: '43px',
                                              textTransform: 'capitalize',
                                              color: !_isUpgrade ? '#787878' : '#fff',
                                            }}
                                            primary
                                            label={changeTitle}
                                            onClick={handleSelected.bind(this, plan)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )})
                    }
                    <div className={s.wrapper_table_compare}>
                        <div className={s.header_table_compare}>
                            <div className={s.title_features}>Features</div>
                            <div className={s.monitor}><p><span>SHIPNEXT Monitor</span></p></div>
                        </div>
                        {pageSubscriptionChange && !openedAllFeatures ?
                                features.slice(0, 7).map((feature, i) => (
                                    <div className={s.body_table_compare}>
                                        <div className={s.title_features}>{feature}</div>
                                        <div className={s.monitor}>{i !== 1 && <span className={s.icon_check} />}</div>
                                    </div>
                                ))
                            :
                                features.map((feature, i) => (
                                    <div className={s.body_table_compare}>
                                        <div className={s.title_features}>{feature}</div>
                                        <div className={s.monitor}>{i !== 1 && <span className={s.icon_check} />}</div>
                                    </div>
                                ))
                            }
                        <div className={cx(s.body_table_compare, s.wrapper_plan_under_table)}>
                            <div className={s.title_features}>
                                {pageSubscriptionChange ?
                                    <div className={s.button_show_features} onClick={toggleOpenAllFeatures}>
                                        <span>{openedAllFeatures ? 'Hide part features ' : 'See all features' }</span>
                                        <span className={cx(s.arrow, openedAllFeatures ? s.arrow_up : null)}></span>
                                    </div>
                                    : null
                                }
                                <p className={s.pointer_select}>Please select product<br/> and paid subscription</p>
                            </div>
                            <div className={cx(s.wrapper_term_plan, s.monitor, s.wrapper_term_plan_static)}>
                                <div className={s.wrapper_middle}>
                                    <p className={cx(s.wrapper_price, s.on_demand)}>on demand</p>
                                    <span className={s.save_info}>&nbsp;</span>
                                </div>
                                <RaisedButton
                                    style={{
                                      minWidth: '140px',
                                      maxWidth: '140px',
                                      height: '43px',
                                      borderRadius: '100px'
                                    }}
                                    buttonStyle={{
                                      backgroundColor: '#1684DD',
                                      borderRadius: '100px',
                                    }}
                                    overlayStyle= {{
                                      borderRadius: '100px',
                                    }}
                                    labelStyle={{
                                      padding: '0',
                                      fontFamily: 'Montserrat',
                                      fontSize: '15px',
                                      fontWeight: '600',
                                      lineHeight: '43px',
                                      textTransform: 'capitalize',
                                      color: '#fff',
                                    }}
                                    primary
                                    label={<a style={{color: '#fff'}} href="mailto:support@shipnext.com">Contact Sales</a>}
                                />
                            </div>
                        </div>
                    </div>
                </SwipeableViews>
                <Tabs
                    onChange={this.handleChangeSlide}
                    value={this.state.slideIndex}
                    className={s.wrapper_bullets}
                >
                    <Tab style={styleTabs} value={0} className={this.state.slideIndex === 0 && s.active_bullet} />
                    <Tab style={styleTabs} value={1} className={this.state.slideIndex === 1 && s.active_bullet} />
                    <Tab style={styleTabs} value={2} className={this.state.slideIndex === 2 && s.active_bullet}/>
                    <Tab style={styleTabs} value={3} className={this.state.slideIndex === 3 && s.active_bullet}/>
                    <Tab style={styleTabs} value={4} className={this.state.slideIndex === 4 && s.active_bullet}/>
                </Tabs>
            </div>
        )
    }
}



class PricingButton extends PureComponent {

  static STYLES = {
    gray: {
      style: {
        minWidth: '140px',
        maxWidth: '140px',
        height: '43px',
        borderRadius: '100px',
      },
      overlayStyle: {
        borderRadius: '100px',
      },
      buttonStyle: {
        backgroundColor: '#D2D2D2',
        borderRadius: '100px',
      },
      labelStyle: {
        padding: '0',
        fontFamily: 'Montserrat',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '43px',
        textTransform: 'capitalize',
        color: '#787878',
      },

    },
    blue: {
      style: {
        minWidth: '140px',
        maxWidth: '140px',
        height: '43px',
        borderRadius: '100px',
      },
      buttonStyle: {
        backgroundColor: '#1684DD',
        borderRadius: '100px',
      },
      overlayStyle: {
        borderRadius: '100px',
      },
      labelStyle: {
        padding: '0',
        fontFamily: 'Montserrat',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '43px',
        textTransform: 'capitalize',
        color: '#fff',
      },
    },
  }

  getStyles =() => {
    if(this.props.stylesName){
      return PricingButton.STYLES[this.props.stylesName] || PricingButton.STYLES.blue;
    }
  };


  render() {
    const styles = this.getStyles();
    return (
      <SpinnerButton
        {...styles}
        {...this.props}
        />
    );
  }


}
