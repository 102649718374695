import React, { Component } from 'react'
import { _CommonComments } from './CommonComments';
import RatingStars from '../RatingStars';
import { debounce } from './../../../core/utils';
import { toggleSubscribeDialog } from '../../../actions/login';
import { isUserSubscribedToMarketplace } from '../../../core/utils';
import Comments from '../../Vessel/Preview/Comments';
import Collapse from '../Collapse';
import { withRatingAndComment } from './../../../core/HOC';
import { connect } from 'react-redux'
import TextArea from '../../Monitor/Edit/TextArea';
import { CommentFooter } from '../RatingBlock';
import ExtendedForm from '../ExtendedForm';
import s from '../RatingBlock.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
export class RatingComments extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isPrivateComment: true,
      subscribedToMarketplace: isUserSubscribedToMarketplace(props.user)
    }
  }

  handleStars = debounce((type, i) => {
    if (!this.state.subscribedToMarketplace) {
      this.props.toggleSubscribeDialog(true);
      return;
    }
    this.props.handleStars(type, i);
  }, 1000)

  componentWillReceiveProps(nextProps) {
    if(nextProps.user !== this.props.user) {
      this.setState({subscribedToMarketplace: isUserSubscribedToMarketplace(nextProps.user)});
    }
  }

  changePrivate = (isPrivate) => {
    this.setState(state => ({...state, isPrivateComment: typeof isPrivate === 'boolean' ? isPrivate : !state.isPrivateComment}))
  }

  clearComment = () => {
    this.props.setComment("");
    this.changePrivate(false);
  }

  handleSaveComment = () => {
    if (!this.state.subscribedToMarketplace) {
      this.props.toggleSubscribeDialog(true);
      return;
    }
    this.props.handleSaveComment(this.state.isPrivateComment);
  }

  renderHeader() {
    const { entity, operRating, techRating } = this.props;
    return (

      <div>
        <RatingStars dark={this.props.dark} operRating={operRating} techRating={techRating} handleStars={this.handleStars}></RatingStars>
        <div style={{width: '100%'}}>
          <ExtendedForm>
              <TextArea
                validations="minLength:1,maxLength:1000"
                validationError="1 - 1000 characters"
                style={{ resize: 'none', marginBottom: '12px', borderRadius: "8px" }}
                name="comments"
                placeholder="Comment"
                value={this.props.comment}
                classes={s.focus_textarea}
                onChange={this.props.setComment}
              />
            </ExtendedForm>
            <CommentFooter
              horizontalPosition={'right'} whiteArrow={false} dark
              selectedtoggle={this.state.isPrivateComment}
              changeToggle={this.changePrivate.bind(this, null)} positionInsideTransform={false} offset={{ x: 8, y: 0 }} alertClasses={''}
              comment={this.props.comment}
              showButtons
              onClear={this.clearComment}
              onSave={this.handleSaveComment}
            />
        </div>
      </div>
    )
  }

  renderWrapper = (el) => {
    const { isCollapsible, total } = this.props;

    if (isCollapsible) {
      return (
        <Collapse
          defaultOpen
          title={`COMMENTS ${total ? ` (${total}) ` : ""}`}
          {...(this.props.collapseProps || {})}
        >
          {
            el
          }
        </Collapse>
      )
    }
    else {
      return (
        <div style={this.props.style || {}}>
          {
            el
          }
        </div>
      )
    }
  }

  render() {
    const el = (
      <div style={{width: '100%'}}>
          {
            this.renderHeader()
          }
          <Comments commentsLoaded={this.props.setTotalComments} entityType={this.props.entityType} entity={this.props.entity}/>
      </div>
    )

    const Wrapper = this.renderWrapper(el);
    return Wrapper;
  }
}

export default withRatingAndComment(connect(state => ({user: state.login.user}), { toggleSubscribeDialog })(withStyles(s)(RatingComments)));
