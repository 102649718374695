export default function ALLFLEET_UPDATE_SINGLE_VESSEL(state, action) {

  let allVessels = [...state.data];

  const index = allVessels.findIndex(vessel => vessel._id === action.payload._id);

  if (index < 0) {
    return state
  }

  allVessels[index] = {
    ...allVessels[index],
    ...action.payload
  }


  return { ...state, data: allVessels };
}
