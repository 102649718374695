import React from 'react';

const title = 'New User Registration';

export default {
  path: '/register',

  async action() {
    const Register = ((await import(/* webpackChunkName: 'Register' */ './Register')).default);
    return {
      title,
      component: <Register title={title} />,
      public:true,
      robots: "noindex,follow",
    };
  },
};
