//noinspection JSUnusedGlobalSymbols
/** @name PROFILE_GET_REDUCER */
export default function PROFILE_GET(state, { res }) {
  const newState = { user: res.data };
  newState.user.companyRole = 'pending';
  if (res.data.company && res.data.company.participants && res.data.company.participants.length) {
    const participant = res.data.company.participants.find(p => p.user && p.user._id === res.data._id);
    if (participant) {
      newState.user.companyRole = participant.role;
    }
  }
  if (newState.user.companyRole !== 'owner' && newState.user.companyRole !== 'pending') {
    newState.user.companyRole = 'manager';
  }
  return { ...state, ...newState };
}
