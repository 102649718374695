/* @flow */
import React from 'react';
import { v4 } from 'uuid';
import s from '../../Monitor.scss';
import CurrencySelect from '../../../Common/CurrencySelect';
import MenuItem from 'material-ui/MenuItem';
import styled from 'styled-components';
import { ContentAddButton, ContentClearButton } from '../../../Common/AddButton';
import { chargeTypesList } from './chargeTypes';
import { ChargesEdit } from './ChargesEdit';
import { NarrowSelect } from '../../../NewInputs/NarrowSelect';
import NarrowFormsyText from '../../../NewInputs/NarrowFormsyText';


class ChargesEditNarrow extends ChargesEdit{
  static RowComponent = ChargeRow;

}


function ChargeRow({ item: { curr, prev = {} }, i, onChange, onDelete, onAdd }) {
  prev = prev || {};
  curr = curr || {};
  const isTypeRequired = !!curr.value;
  const isValueRequired = !!curr.type;
  return (<div>
    <div className={'charge_row'}>
      <div className={'first_part'}>
        <div>
          <NarrowSelect
            floatingLabelText={`Surcharge Type${isTypeRequired ? '*' : ''}`}
            name={`_charges[${i}][type]`}
            fullWidth
            autoWidth
            previousValue={prev.type}
            value={curr.type}
            onChange={onChange.bind(this, 'type')}
            requiredError="required"
            required={isTypeRequired}
          >
            {chargeTypesList.map(ct => <MenuItem
              key={ct._id}
              value={ct._id}
              primaryText={ct.name}
            />)}
          </NarrowSelect>
        </div>
        <div>
          <NarrowFormsyText
            floatingLabelText={`Surcharge${isValueRequired ? '*' : ''}`}
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
            requiredError="required"
            name={`_charges[${i}][value]`}
            fullWidth
            previousValue={prev.value}
            value={curr.value}
            onBlur={onChange.bind(this, 'value')}
            required={isValueRequired}
          />
        </div>

      </div>
      <div className={'second_part'} >
        <div>
          <CurrencySelect
            as={NarrowSelect}
            name={`_charges[${i}][currency]`}
            className={s.currency_select}
            previousValue={prev.currency}
            value={curr.currency}
            onChange={onChange.bind(this,'currency')}
          />
        </div>
        <div>
          <NarrowSelect
            hintText=" "
            requiredError="required"
            name={`_charges[${i}][term]`}
            fullWidth
            autoWidth
            required
            defaultValue={'per mt'}
            previousValue={prev.term}
            value={curr.term}
            onChange={onChange.bind(this,'term')}
          >
            <MenuItem
              key="per mt"
              value="per mt"
              primaryText="per mt"
            />
            <MenuItem
              key="per cbm"
              value="per cbm"
              primaryText="per cbm"
            />
            <MenuItem
              key="lump sum"
              value="lump sum"
              primaryText="lump sum"
            />
            <MenuItem
              key="per w/m"
              value="per w/m"
              primaryText="per w/m"
            />
            <MenuItem
              key="per unit"
              value="per unit"
              primaryText="per unit"
            />
            <MenuItem
              key="per box"
              value="per box"
              primaryText="per box"
            />
            <MenuItem
              key="per TEU"
              value="per TEU"
              primaryText="per TEU"
            />
            <MenuItem
              key="per FEU"
              value="per FEU"
              primaryText="per FEU"
            />
          </NarrowSelect>
        </div>
        <div>
          <NarrowFormsyText
            floatingLabelText="Details"
            name={`_charges[${i}][details]`}
            fullWidth
            previousValue={prev.details}
            value={curr.details}
            onBlur={onChange.bind(this,'details')}
          />
        </div>
      </div>
  </div>
      <div className={"button_wrapper"}>
        {i === 0 ?
          <ContentAddButton onClick={onAdd} />
          :
          <ContentClearButton onClick={onDelete} />
        }
      </div>
   </div>
  );
}

export default styled(ChargesEditNarrow)`
  width: 100%;
  >div {
    width: 100%;
    display: flex;
  }
  .charge_row {
    display: flex;
    flex: 1;
    margin: 0 -6px;


    .first_part {
      display: flex;
      max-width: 45.520581114%;
      flex: 0 0 45.520581114%;
      > div {
        padding: 0 6px;
      }
      > div:nth-child(1){
        max-width: 60.106382979%;
        flex: 0 0 60.106382979%;
      }
      > div:nth-child(2){
        max-width: 39.893617021%;
        flex: 0 0 39.893617021%;
      }

    }
    .second_part {
      display: flex;
      max-width: 54.479418886%;
      flex: 0 0 54.479418886%;
      > div {
        padding: 0 6px;
      }
      > div:nth-child(1){
        max-width: 33.333333333333%;
        flex: 0 0 33.3333333333333%;
        >div {
          width: 100% !important;
        }
      }
      > div:nth-child(2){
        max-width: 33.3333333333333%;
        flex: 0 0 33.3333333333333%;
      }
      > div:nth-child(3){
        max-width: 33.3333333333333%;
        flex: 0 0 33.3333333333333%;
      }
    }
    @media (max-width: 1299px) {
      flex-wrap: wrap;
      .first_part {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
      .second_part {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  .button_wrapper {
    display: flex;
    align-items: center;
    width: 36px;
    padding-left: 12px;
  }
`;

