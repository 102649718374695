import React from 'react';

import Home from '../../components/Home';
import UnderConstruction from '../../components/UnderCounstruction';
import Layout from '../../components/Layout';
import EditPort from '../../components/Port/EditPort';
import PublicPort from '../../components/Public/PublicPort';
import PublicVessel from '../../components/Public/PublicVessel';
import PortApi from '../../core/api/Port';
import PortList from '../../components/Port/PortList';
//import Calculator from '../../components/Calculator/Calculator';
import SignupConnect from '../../components/Signup/SignupConnect';
import PageSolutionsMarketplace from '../../components/Home/Pages/PageSolutionsMarketplace';
import PageTechnology from '../../components/Home/Pages/PageTechnology';
import PagePartners from '../../components/Home/Pages/PagePartners';
import AboutUs from '../../components/Home/Pages/AboutUs';
import PageGeneral from "../../components/Home/PageGeneral";
import defaultPreviewImg from '../../public/preview.png';
import PagePricing from '../../components/Home/Pages/PagePricing';
import { User } from '../../core/api/api';
import PageSolutionsCargoMonitor from '../../components/Home/Pages/PageSolutionsCargoMonitor';
import PageSolutionsFleetMonitor from '../../components/Home/Pages/PageSolutionsFleetMonitor';
import PageSolutionsExchange from '../../components/Home/Pages/PageSolutionsExchange';
import PageSolutionsDocuments from '../../components/Home/Pages/PageSolutionsDocuments';
import PageSolutionsPorts from '../../components/Home/Pages/PageSolutionsPorts';
import {getAccountFilters} from "../../actions/login";
import { mainPageScript, solutionCargoMonitorScript, solutionDocumentsScript, solutionExchangeScript, solutionMarketplaceScript, solutionPortsScript } from './seoScripts';

function homeAction(addProps = {}) {
  return async function (params) {
    return {
      title: 'Shipping Marketplace and Freight Management Platform: The Ultimate Shipping Platform for Data Management, Trade Automation, and Seamless Workflows',
      description: `SHIPNEXT is an Online Shipping Marketplace and Freight Management Platform for dry-bulk and wet bulk, break-bulk, oversized cargo, steel products, and containers ⭐.`,
      public: true,
      ogImg: `https://shipnext.com${defaultPreviewImg}`,
      ogVideo: 'https://shipnext.com/Logo_final.mp4',
      keywords:`Freight index, dry-bulk index, shipping index, shipping marketplace, ship grain, ship commodity, dry-bulk, shipping market, dry cargo chartering, charter ship, shipping company, digital carrier, digital forwarder, digital ship, digital freight, book cargo, automate supply chain, digitalization of supply chain, freight automation`,
      seoScript: addProps?.withSeoScript && mainPageScript,
      component: (
        <Layout>
          <PageGeneral {...addProps} {...params}>
            <Home {...addProps} {...params} />
          </PageGeneral>
        </Layout>
      ),
    };
  };
}

export default {
  path: '/',
  children: [
    {
      path: '/',
      action: homeAction({ withSeoScript: true }),
    },
    {
      path: '/connect',
      action: homeAction({ selectedAboutTab: 'SHIPNEXT CONNECT' }),
    },
    {
      path: '/invest',
      action: homeAction({ selectedAboutTab: 'Partner with SHIPNEXT' }),
    },
    {
      path: '/partner',
      action: homeAction({ selectedAboutTab: 'Partner with SHIPNEXT' }),
    },
    {
      path: '/solution-shipnext-marketplace',
      action(params) {
        return {
          title: 'SHIPNEXT Trading Desk: Elevate Your Shipping Operations with Advanced Cargo and Fleet Management for Shipbrokers, Carriers, and Traders',
          description: "Discover the power of SHIPNEXT Trading Desk, the ultimate work environment for Shipbrokers, Carriers, and Traders ⭐ Optimize your workflow with a split-screen design, personalized Cargo and Fleet data, and real-time connectivity.",
          keywords: 'Freight index, dry-bulk index, shipping index, shipping marketplace, ship grain, ship commodity, dry-bulk, shipping market, dry cargo chartering, charter ship, shipping company, digital carrier, digital forwarder, digital ship, digital freight, book cargo',
          component: <Layout><PageGeneral {...params}><PageSolutionsMarketplace {...params} /></PageGeneral></Layout>,
          public: true,
          seoScript: solutionMarketplaceScript,
        };
      },
    },
    {
      path: '/solution-shipnext-cargo-monitor',
      action(params) {
        return {
          title: 'SHIPNEXT Cargo Monitor: Optimize Freight Negotiations, Track Shipments, and Enhance CRM Analytics for Efficient Maritime Transportation Management',
          description: "Elevate your cargo management with SHIPNEXT Cargo Monitor ⭐ Streamline freight negotiations, track shipments, and gain valuable insights into CRM analytics.",
          keywords: 'SAP, Blockchain in shipping, blockchain in supply chain, blockchain in transportation, blockchain freight, supply chain automation, digital freight, sigital supply chain',
          component: <Layout><PageGeneral {...params}><PageSolutionsCargoMonitor {...params} /></PageGeneral></Layout>,
          public: true,
          seoScript: solutionCargoMonitorScript,
        };
      },
    },
    {
      path: '/solution-shipnext-fleet-monitor',
      action(params) {
        return {
          title: 'SHIPNEXT Fleet Monitor: Streamline Fleet Management, Monitor Vessel Performance, and Enhance Operational Efficiency',
          description: "Elevate your fleet management with SHIPNEXT Fleet Monitor ⭐ Effortlessly oversee vessel data, monitor commercial and operational performance, and track past/current employment.",
          keywords: 'SAP, Blockchain in shipping, blockchain in supply chain, blockchain in transportation, blockchain freight, supply chain automation, digital freight, sigital supply chain',
          component: <Layout><PageGeneral {...params}><PageSolutionsFleetMonitor {...params} /></PageGeneral></Layout>,
          public: true,
        };
      },
    },
    {
      path: '/solution-shipnext-exchange',
      action(params) {
        return {
          title: 'SHIPNEXT Exchange: Your Ultimate Platform for Streamlined Email Management, Cargo-to-Ship Matching, and Advanced AI Solutions',
          description: "Discover the future of shipping communication with SHIPNEXT Exchange, a groundbreaking platform transforming the industry's reliance on email ⭐ Manage, store, and streamline your shipping-related emails effortlessly.",
          keywords: 'freight index, process shipping email, smart emailing, shipping email solutions, digital freight, freight index, commodity freight, dry-bulk freight, dry-bulk shipping, shipping company',
          component: <Layout><PageGeneral {...params}><PageSolutionsExchange {...params} /></PageGeneral></Layout>,
          public: true,
          seoScript: solutionExchangeScript,
        };
      },
    },
    {
      path: '/solution-shipnext-documents',
      action(params) {
        return {
          title: 'SHIPNEXT Documents: AI-Powered Contract Creation, Real-time Analytics, and Seamless Interconnectivity',
          description: "Discover the future of maritime documentation with SHIPNEXT Documents ⭐ Say goodbye to manual processes and quarterly analytics—leverage AI for instant contract creation, efficient execution tracking, and real-time KPIs.",
          keywords: 'Freight index, dry-bulk index, shipping index, shipping marketplace, ship grain, ship commodity, dry-bulk, shipping market, dry cargo chartering, charter ship, shipping company, digital carrier, digital forwarder, digital ship, digital freight, book cargo',
          component: <Layout><PageGeneral {...params}><PageSolutionsDocuments {...params} /></PageGeneral></Layout>,
          public: true,
          seoScript: solutionDocumentsScript,
        };
      },
    },
    {
      path: '/solution-shipnext-ports',
      action(params) {
        return {
          title: 'SHIPNEXT Ports: Streamlining Port Data, Analytics, and Interconnectivity for Advanced Optimization',
          description: "Discover the power of SHIPNEXT Ports, a comprehensive solution for staying updated on crucial port data ⭐ From detailed port descriptions and restrictions to facilities, berths, congestion, and weather conditions, SHIPNEXT Ports provides a holistic view.",
          keywords: 'Freight index, dry-bulk index, shipping index, shipping marketplace, ship grain, ship commodity, dry-bulk, shipping market, dry cargo chartering, charter ship, shipping company, digital carrier, digital forwarder, digital ship, digital freight, book cargo',
          component: <Layout><PageGeneral {...params}><PageSolutionsPorts {...params} /></PageGeneral></Layout>,
          public: true,
          seoScript: solutionPortsScript,
        };
      },
    },
    {
      path: '/technology',
      action(params) {
        return {
          title: 'SHIPNEXT Ecosystem: Decentralized Blockchain Solution for Future Logistics and Integrated Supply Chain Efficiency',
          description: "Explore the SHIPNEXT Ecosystem, a revolutionary decentralized blockchain solution for the door-to-door Supply Chain ⭐ Enhance logistics, transportation, and settlement with a demand-driven production and supply chain ecosystem.",
          keywords:'blockchain in shipping, blockchain in supply chain, blockchain in transportation, blockchain freight, Artificial intelligence in shipping, smart freight, digital freight, freight index, digital shipping, linear programming in shipping, big data in shipping',
          component: <Layout><PageGeneral {...params}><PageTechnology {...params} /></PageGeneral></Layout>,
          public: true,
        };
      },
    },
    {
      path: '/partners',
      action(params) {
        return {
          title: 'Explore Seamless Collaboration with SHIPNEXT: Connecting Clients & Partners for Innovative Solutions in Intermodal Logistics',
          description: "Discover endless possibilities with SHIPNEXT as we invite new clients and partners to foster creativity and collaboration ⭐.",
          keywords:'supply chain investment, digital supply chain, digitalization of supply chain, supply chain automation',
          component: <Layout><PageGeneral {...params}><PagePartners {...params} /></PageGeneral></Layout>,
          public: true,
        };
      },
    },
    {
      path: '/about-us',
      action(params) {
        return {
          title: 'SHIPNEXT About ',
          description: `SHIPNEXT is the best way to digitalize shipping and chartering to make your self more advanced and efficient in transportation industry in general, and shipping sector in particular. `,
          keywords: '',
          component: <Layout><PageGeneral {...params}><AboutUs {...params} /></PageGeneral></Layout>,
          public: true,
        };
      },
    },
    {
      path: '/trends',
      async action(params) {
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <UnderConstruction />
            </Layout>
          ),
          public: true,
        };
      },
    },
    {
      path: '/main-deck/liquid',
      async action() {
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <UnderConstruction title="Marketplace: Wet Bulk" />
            </Layout>
          ),
          public: true,
        };
      },
    },
    {
      path: '/main-deck/containers',
      async action() {
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout><UnderConstruction title="Marketplace: Containers" /></Layout>
          ),
          public: true,
        };
      },
    },
    {
      path: '/unsubscribe/:unsubscribeToken',
      action: homeAction(),
    },
    {
      path: '/confirm-email/:token',
      action: homeAction(),
    },
    {
      path: '/calculator/:vesselId/:vesselRequestId/:cargoRequestId/:calculationId?',
      async action({ params, hash, store, token, previousPath }) {
        const Calculator = await new Promise((resolve) => {
          require.ensure(
            [],
            require => resolve(require('../../components/Calculator/CalculatorsWrapper').default),
            'calculator',
          );
        });
        try {
          await store.dispatch(Calculator.actions.getSettings());
        } catch (e) {
          console.error(e);
        }
        try {
          await store.dispatch(Calculator.actions.getLdTerms());
        } catch (e) {
          console.error(e);
        }
        if (!previousPath || previousPath.indexOf('/calculator') !== 0) {
          try {
            await store.dispatch(getAccountFilters(token));
          } catch (e) {
            console.error(e);
          }
        }

        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          chunk: 'calculator',
          component: (
            <Layout>
              <Calculator {...params} />
            </Layout>
          ),
        };
      },
    },
    {
      path: '/port',
      action({ params, hash }) {
        return {
          title: 'Shipnext List of Ports: Comprehensive Information About Ports',
          description: "Discover detailed port information with Shipnext's extensive list of global ports, including key features, facilities, and services.",
          component: <Layout><PortList /></Layout>,
          public: true,
        };
      },
    },
    {
      path: '/port/create',
      action({ params, hash }) {
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: <Layout><PortList addPort={true}/></Layout>,
          public: true,
        };
      },
    },
    {
      path: '/port/:portId/edit',
      async action({ params, next, hash }) {
        let res;
        try {
          res = await PortApi.getPublic(params.portId);
          if (!res.data) {
            throw new Error('port not found');
          }
        } catch (e) {
          console.error(e);
          return next();
        }
        return {
          title: `Edit ${res.data.name} Port`,
          description: res.data.additionalDescription || res.data.name,
          component: (
            <Layout>
              <PublicPort port={res.data} portId={params.portId} key={params.portId} editPort={true}/>
            </Layout>
          ),
          public: true,
        };
      },
    },
    {
      path: '/port/:portId/:vesselId?',
      async action({ params, next }) {
        let res;
        try {
          res = await PortApi.getPublic(params.portId);
          if (!res.data) {
            throw new Error('port not found');
          }
        } catch (e) {
          console.error(e);
          return next();
        }
        return {
          title: `${res.data.name} ${res.data.unLoCode} Details: Departures, Expected Arrivals and ${res.data.name} (${res.data.country.name}) Calls | Shipnext`,
          description: `Real-time updates about vessels in the ${res.data.name} (${res.data.country.name}) ${res.data.unLoCode}: Expected arrivals, port calls & wind forecast for ${res.data.name} by Shipnext.`,
          component: (
            <Layout>
              <PublicPort port={res.data} portId={params.portId} key={params.portId} vesselId={params.vesselId}/>
            </Layout>
          ),
          public: true,
        };
      },
    },
    {
      path: '/login-connect',
      action({params}) {
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <SignupConnect/>
            </Layout>
          ),
        };
      },
    },
    {
      path: '/pricing',
      async action({params}) {
        return {
          title: 'Optimize Your Shipping Operations with SHIPNEXT: Choose Your Subscription Plan and Save up to $300/year on 24/7 Shipping Marketplace Solutions',
          description: "Explore SHIPNEXT's subscription plans and revolutionize your shipping processes ⭐ Choose from SHIPNEXT Connect, Professional, Monitor, or enjoy limited access for free.",
          component: <Layout><PageGeneral {...params}><PagePricing {...params} /></PageGeneral></Layout>,
          public: true,
        };
      },
    },
  ],
};
