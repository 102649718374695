import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import Link from '../../Link/Link';

class SolutionsFleetMonitor extends Component {
  render() {
    return (
      <div className={s.solution}>
        <div className={s.tab_block}>
          <div className={s.left_part}>
            <div className={s.wrapper_screen}>
              <img src="/images/solutions_screen/fleet_monitor_1.png" alt="SHIPNEXT Fleet Monitor" />
            </div>
          </div>
          <div className={s.right_part}>
            <h2>
              <span className={s.title}>SHIPNEXT Fleet Monitor</span>
            </h2>
            <div>
              <p>Fleet Monitor is a solution for tracking the global fleet, its sanctions and compliance check, its past trade, and performance. SHIPNEXT also allows a deeper insight into the performance and current employment of the global commercial fleet. It’s both a commercial and an optional tool.</p>
              <p>The Fleet data of the Fleet Monitor can be enhanced with inhouse fleet data. Users of the SHIPNEXT White Label can prioritize the use of their own fleet data-base over that of SHIPNEXT, therefore customizing and personalizing SHIPNEXT for their own corporate use.</p>
              <p>SHIPNEXT products can be white-labelled to meet the needs of larger companies, for better customisation and “look and feel” experience, and upgraded with customized features which may not yet be available.</p>
            </div>
            <div className={s.wrapper_buttons_tab}>
              <Link className={s.learn_more} to={'/solution-shipnext-fleet-monitor'}>Learn more</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(s)(SolutionsFleetMonitor);
