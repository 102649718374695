import React, {Component} from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Footer.scss';
import Link from '../Link';
import cx from "classnames";

class FooterMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      solutionsOpened: false,
      technologyOpened: false,
      freeProductsOpened: false,
      companyOpened: false,
    };
  }
  toggleSolutionsOpen = () => {
    this.setState({ solutionsOpened: !this.state.solutionsOpened });
  };
  toggleTechnologyOpen = () => {
    this.setState({ technologyOpened: !this.state.technologyOpened });
  };
  toggleFreeProductsOpen = () => {
    this.setState({ freeProductsOpened: !this.state.freeProductsOpened });
  };
  toggleCompanyOpen = () => {
    this.setState({ companyOpened: !this.state.companyOpened });
  };
  render() {
    return (
      <div>
        <div
          className={cx(s.title_footer_accordion_mob, this.state.solutionsOpened ? s.active : null)}
          onClick={this.toggleSolutionsOpen}
        >
          <div>
            <p>Solutions</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.solutionsOpened &&
          <ul className={s.list_accordion}>
            <li><Link to={'/solution-shipnext-marketplace'}>SHIPNEXT Trading Desk</Link></li>
            <li><Link to={'/solution-shipnext-cargo-monitor'}>SHIPNEXT Cargo Monitor</Link></li>
            <li><Link to={'/solution-shipnext-fleet-monitor'}>SHIPNEXT Fleet Monitor</Link></li>
            <li><Link to={'/solution-shipnext-exchange'}>SHIPNEXT Exchange</Link></li>
            <li><Link to={'/solution-shipnext-documents'}>SHIPNEXT Documents</Link></li>
            <li><Link to={'/solution-shipnext-ports'}>SHIPNEXT Ports</Link></li>
          </ul>
        }
        <div
          className={cx(s.title_footer_accordion_mob, this.state.technologyOpened ? s.active : null)}
          onClick={this.toggleTechnologyOpen}
        >
          <div>
            <p>TECHNOLOGY</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.technologyOpened &&
          <ul className={s.list_accordion}>
            <li><Link to={'/technology#shipnext-ecosystem'}>SHIPNEXT Ecosystem</Link></li>
            <li><Link to={'/technology#shipnext-supply-chain-ecosystem'}>Supply Chain Ecosystem</Link></li>
            <li><Link to={'/technology#blockchain-technology'}>Blockchain</Link></li>
            <li><Link to={'/technology#data-security'}>Data Security</Link></li>
          </ul>
        }
        <div
          className={cx(s.title_footer_accordion_mob, this.state.freeProductsOpened ? s.active : null)}
          onClick={this.toggleFreeProductsOpen}
        >
          <div>
            <p>Free PRODUCTS</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.freeProductsOpened &&
          <ul className={s.list_accordion}>
            <li><Link to={'/port'}>Port Data</Link></li>
            {/*<li><a href="#">Chat and News Feed</a></li>*/}
            {/*<li><a href="#">Freight Calculator</a></li>*/}
          </ul>
        }
        <div
          className={cx(s.title_footer_accordion_mob, this.state.companyOpened ? s.active : null)}
          onClick={this.toggleCompanyOpen}
        >
          <div>
            <p>Company</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.companyOpened &&
          <ul className={s.list_accordion}>
            <li><Link to={'/about-us#our-story'}>Our Story</Link></li>
            <li><Link to={'/partners'}>Partners</Link></li>
            <li><Link to="/#press">Press</Link></li>
            <li><Link to="/news">News</Link></li>
            <li><Link to="/help">Help & Feedback</Link></li>
          </ul>
        }
        <div className={cx(s.part_info, s.headquarter)}>
          <h2>HEADQUARTERS</h2>
          <div className={s.wrapper_address}>
            SHIPNEXT BV<br />
            Koningin Elisabethlei 16<br />
            Antwerp, 2018, Belgium<br />
          </div>
        </div>
        <div className={s.part_info}>
          <h2>CONTACT</h2>
          <div className={s.wrapper_contact}>
            T <a href="tel:+ 32 (487) 651114">+32 487 65 11 14</a><br />
            E <a href="mailto:support@shipnext.com">support@shipnext.com</a><br />
            <Link to="/register">Free Registration</Link>
          </div>
        </div>
      </div>

    )
  }
}

export default withStyles(s)(FooterMobile);

