import React, { Component } from "react";
import PropTypes from "prop-types";
import VesselPreviewNew from "../../Vessel/Preview/VesselPreviewNew";

export default class Eddie extends Component {
  static childContextTypes = {
    eddie: PropTypes.object
  };

  getChildContext() {
    return { eddie: { open: this.open } };
  }

  componentWillUnmount(): void {
    const { stack } = this.state;
    stack.left.forEach(ch => ch.close && ch.close());
    stack.right.forEach(ch => ch.close && ch.close());
    stack.left = [];
    stack.right = [];
  }

  _open = (position: "left" | "right", componentHolder, closeOther = false) => {
    const stack = { ...this.state.stack };
    stack[position] = [...stack[position], componentHolder];
    this.setState({ stack });
  };

  open = async (
    position: "left" | "right",
    type,
    id,
    componentBuilder: function,
    closeOther = false
  ) => {
    if (!id) {
      id = Date.now().toString() + Math.random().toString();
    }
    const closer = this.handleClose.bind(this, type, id);
    this._open(position, {
      id,
      type,
      close: closer,
      component: await componentBuilder({ handleClose: closer })
    });
    return closer;
  };

  state = {
    stack: {
      right: [],
      left: [],
      open: this.open
    }
  };

  handleClose = (type, id) => {
    const stack = { ...this.state.stack };
    stack.left = stack.left.filter(ch => !(ch.id === id && type === type));
    if (stack.right.find(ch => ch.id === id && type === ch.type)) {
      stack.right.forEach(ch => {
        if (ch.component?._self?._handleCloseDialog) {
          ch.component?._self?._handleCloseDialog();
        }
      });
      stack.right = [];
    }
    this.setState({ stack });
  };

  handleContentWrapperClick = e => {
    const target = e.target;
    let href =
      target.href || target.getAttribute("data-href") || target.parentNode.href;
    if (!href) {
      let parentA = target.closest("a");
      href = parentA?.href;
    }
    if (href) {
      if (!href.includes("main-deck")) return;
      const url = new URL(href);
      const pathname = url.pathname;
      let [m, vesselId] = /\/main-deck\/general\/-\/([a-z0-9]{24})/.exec(
        pathname
      );
      if (vesselId) {
        e.preventDefault();
        this._open("right", {
          id: vesselId,
          type: "vessel",
          component: (
            <VesselPreviewNew
              monitor
              shouldScrollInside
              vesselId={vesselId}
              handleClose={this.handleClose.bind(this, "vessel", vesselId)}
              displayCommonHeader
            />
          )
        });
      }
    }
  };

  render(): React.ReactNode {
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        onClick={this.handleContentWrapperClick}
      >
        {React.cloneElement(this.props.children, { eddie: this.state.stack, closeInfo: this.handleClose })}
      </div>
    );
  }
}
