import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../../Documents/Cargo.scss';
import ContractPDFView from './Request/ContractPDFView';

const TcOfflinePreview = ({ preview, createPdf }) => {
    return (
      <div className={s.preview_container} style={{ position: "absolute", top: "50px", left: "0px", right: "0px", zIndex: 1111, overflow: "auto", background: "#fff", bottom: "0px", padding: "0" }}>
        <ContractPDFView createPdf={createPdf} preview={preview} />
      </div>
    );
  };

export default withStyles(s)(TcOfflinePreview);
