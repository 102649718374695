import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Port.scss';

class PortHeader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={s.port_header} style={this.props.borderRight ? {borderRight: '1px solid #E6E6E6'} : null}>
        {
          this.props.title
          ? <div className={s.port_header_title}><span className={s.title}>{this.props.title}</span>
            {this.props.modification || null}
          </div>
          : null
        }
        {
          this.props.right
          ? this.props.right
          : null
        }
      </div>
    );
  }
}
export default withStyles(s)(PortHeader);

