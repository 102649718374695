import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './VerifyEmailBar.scss';
import { requestConfirmEmail, acceptGDPR } from '../../actions/login';
import Link from '../Link';
import RaisedButton from '../Common/RaisedButton';

let globalSent = false;

class VerifyEmailBar extends Component {
  static propTypes ={
    requestConfirmEmail: PropTypes.func,
    user: PropTypes.object,
  };
  state = { sent: globalSent };
  handleSendVerificationEmail = () => {
    this.props.requestConfirmEmail().then(((res) => {
      if (res.status === 200) {
        this.setState({ sent: true });
        globalSent = true;
      }
    }));
  };
  render() {
    if (!this.props.user || !this.props.user._id) {
      return null;
    }

    if (!this.props.user.gdprComplained) {
      return (<div className={s.root}>
        We have updated our&nbsp;<Link to="/privacy">Privacy Policy</Link>,&nbsp;<Link to="/cookies">Cookie Policy</Link>&nbsp;and&nbsp;<Link to="/legal">Terms & Conditions</Link>&nbsp;&nbsp;
        <RaisedButton
          buttonStyle={{
            borderRadius: '3px',
          }}
          style={{
            backgroundColor: 'transparent',
            height: '30px',
          }}
          labelStyle={{
            fontSize: '11px',
            lineHeight: '30px',
          }}
          primary
          onClick={this.props.acceptGDPR}
          label="Accept" />
      </div>);
    }

    return null;
  }
}
export default connect(state => state.login, { requestConfirmEmail, acceptGDPR })(
  withStyles(s)(VerifyEmailBar),
);
