import PropTypes from 'prop-types';
import React, { Component } from "react";
import cx from "classnames";
import tc from '../../../Monitor/TCOrders/TC.scss';
import s from './Request.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Vessel, { registerClass as registerClassAPI } from "../../../../core/api/Vessel";
import { CheckBox as Cb } from "../../../Monitor/Edit/VesselEdit";
import ContentAdd from "material-ui/svg-icons/content/add";
import Chip from "material-ui/Chip";
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { MenuItem } from 'material-ui';
import NarrowAutocomplete from "../../../NewInputs/NarrowAutocomplete";

export class RegisterClass extends Component {
  static contextTypes = {
    showMessage: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      iacsRegister: false,
      registerClass: ""
    };
  }

  handleRegisterClassChange = val => {
    let stateToUpdate = {
      ...this.state
    };

    stateToUpdate.registerClass = val;

    if (val.hasOwnProperty("iacsRegister")) {
      stateToUpdate.iacsRegister = val.iacsRegister;
    }

    this.setState(stateToUpdate);
  };

  handleChange = (name, ev, val) => {
    this.setState({
      [name]: val
    });
  };

  addCountry = () => {
    const { registerClass, iacsRegister } = this.state;
    const { registerClasses } = this.props;
    const isObj = typeof registerClass === "object";
    const isAnyIacs = !registerClass && iacsRegister;
    const isAny = !registerClass && !iacsRegister;
    if (isObj || isAny || isAnyIacs) {
      const alreadyExists = registerClasses.find(rClass =>
        isAny
          ? !rClass.registerClass && !rClass.iacsRegister
          : isAnyIacs
            ? !rClass.registerClass && rClass.iacsRegister
            : rClass.registerClass?._id === registerClass._id
      );

      if (alreadyExists) {
        return this.props.dispatch({
          type: "MESSAGE",
          payload: {
            title: "Error",
            message: `${
              registerClass.name ? registerClass.name : "Any"
            } ${iacsRegister ? " (IACS)" : ""} is already added.`,
            level: "error",
            _id: new Date().getMilliseconds(),
            autoDismiss: 5
          }
        });
      }

      this.setState(state => ({
        ...state,
        registerClass: "",
        iacsRegister: false
      }));

      const newRegisterClasses = [
        ...(registerClasses || []),
        {
          iacsRegister,
          registerClass: registerClass || null,
          _id: registerClass._id || uuidv4()
        }
      ];
      this.changeParent(newRegisterClasses);
    }
  };

  changeParent = val =>
    this.props.handleChange && this.props.handleChange("registerClasses", null, val);

  delete = id => {
    const { registerClasses } = this.props;
    const newRegisterClasses = registerClasses.filter(
      registerClass => registerClass._id !== id
    );

    this.changeParent(newRegisterClasses);
  };

  renderAutocompleteItem = item => {
    return {
      text: item.name,
      _value: item,
      value: <MenuItem style={{fontSize: '13px'}} primaryText={item.name} />,
    };
  }

  filterIfIACS = data => this.state.iacsRegister ? data.filter(item => item.iacsRegister).map(this.renderAutocompleteItem) : data.map(this.renderAutocompleteItem)

  render() {
    const { iacsRegister, registerClass } = this.state;
    const { prefix = "vesselRequirements", registerClasses = [] } = this.props;

    return (
      <div className={tc.col}>
        <div className={cx("row", s.step_row)} style={{ alignItems: "center", flexWrap: "nowrap" }}>
          <div className="col-sm-9" style={{flexShrink: 1 }}>
            <NarrowAutocomplete
              renderer={this.filterIfIACS}
              floatingLabelText="Register Class"
              name={`${prefix}[registerClasses][${registerClasses.length}][registerClass]`}
              validations="isObj"
              validationError="Please, select register class"
              validationErrors={{
                isObj: "Please, select register class"
              }}
              onNewRequest={this.handleRegisterClassChange}
              onUpdateInput={this.handleRegisterClassChange}
              smallFont
              value={registerClass}
              maxHeight={271}
              api={registerClassAPI}
              fullWidth
            />
          </div>
          <div className="col-sm-3" style={{ display: "flex", minWidth: "132px" }}>
            <Cb
              className={tc.checkbox}
              checked={iacsRegister}
              name={`${prefix}[registerClasses][${registerClasses.length}][iacsRegister]`}
              label="IACS register"
              disabled={typeof registerClass === "object"}
              onCheck={this.handleChange.bind(this, "iacsRegister")}
            />
            <div
              className={cx(tc.add_btn)}
              style={{ alignItems: "flex-end" }}
              onClick={this.addCountry}
            >
             <ContentAdd color="#285596" style={{ padding: "2px" }} />
           </div>
          </div>

        </div>
        <div className={tc.row_start_wrap}>
          {registerClasses?.length
            ? registerClasses.map(registerClass => (
                <Chip
                  key={registerClass.registerClass?._id ?? registerClass._id}
                  onRequestDelete={this.delete.bind(this, registerClass._id)}
                  backgroundColor={"var(--chips-included)"}
                  style={{ margin: "0px 8px 8px 0px" }}
                  className={tc.chip}
                >{`${
                  registerClass.registerClass?.name
                    ? registerClass.registerClass.name
                    : "Any"
                } ${registerClass.iacsRegister ? `(IACS)` : ""}`}</Chip>
              ))
            : null}
        </div>
      </div>
    );
  }
}

export default connect()(withStyles(tc)(RegisterClass));
