// @flow
import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './Page.css';
import Header2 from '../Header/Header2';
import TopNotifications from '../Common/TopNotifications';
import Footer from '../Footer/Footer';

class Page extends Component<{
  headerTitle: typeof PropTypes.node,
  headerLeft: typeof PropTypes.node,
  headerRight: typeof PropTypes.node,
}> {
  componentWillReceiveProps(nextProps) {
    if(this.props.headerTitle !== nextProps.headerTitle) {
      document.querySelector('.page_content')?.scrollTo?.(0,0);
    }
  }

  render() {
    const {
      headerTitle,
      headerLeft,
      headerRight,
      children,
      className,
      withFooter = false,
      ...restProps
    } = this.props;
    return (
      <div id={this.props.id} className={cx(s.root, className)}>
        <Header2 title={headerTitle} left={headerLeft} right={headerRight}/>
        <TopNotifications/>
        <div className={cx('page_content', s.content, withFooter && s.with_footer)}>
          {Children.only(children)}
          { withFooter && <div style={{ alignSelf: "normal" }}>
          <Footer />
        </div> }
          </div>

      </div>
    );
  }
}

Page.propTypes = {
  headerTitle: PropTypes.node,
  headerLeft: PropTypes.node,
  headerRight: PropTypes.node,
};

export default withStyles(s)(Page);
