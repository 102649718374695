/** @name GET_MORE_NEWS_LIST_SUCCESS */
export default function GET_MORE_NEWS_LIST_SUCCESS(state, action) {
    const newState = {};
  
    newState.newsList = {
      ...state.newsList,
      data: [...state.newsList.data, ...action.payload.data],
      loadMoreLoading: false,
    };
  
    return { ...state, ...newState };
  }
