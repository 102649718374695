import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../styles/Dashboard.scss';
import { TextField } from 'material-ui';

import { AutoSizer, List } from 'react-virtualized'

import cx from "classnames";
import {getPortsFromRegion} from "../../../../core/utils";

class PortList extends Component {
  static contextTypes = {

  };

  static propTypes = {

  };

  static defaultProps = {

  };

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      filteredPorts: [],
    };

    this.renderRow = this.renderRow.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
  }

  componentDidMount() {
    if (!this.props.selectedRegion) {
      return;
    }

    this.setState({
      ports: getPortsFromRegion(this.props.selectedRegion, this.props.allPorts),
    }, () => {
      this.handleFiltering();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.selectedRegion) {
      return;
    }

    this.setState({
      ports: getPortsFromRegion(nextProps.selectedRegion, nextProps.allPorts),
    }, () => {
      this.handleFiltering(() => { this.list.forceUpdateGrid(); });
    });
  }

  handleSearch(e, value) {
    this.setState({
      search: value,
    }, () => {
      this.handleFiltering();
    });
  };

  handleFiltering(cb = () => {}) {
    const filteredPorts = this.state.ports.filter((port) => {
      return port.name.toLowerCase().includes(this.state.search.toLowerCase())
    });
    this.setState({
      filteredPorts,
    }, cb);
  }

  renderRow({ index, key, style }) {
    const port = this.state.filteredPorts[index];
    const selectedPort = port._id === (this.props.selectedPort && this.props.selectedPort._id);

    return (
      <div
        style={{ ...style, display: 'flex', cursor: 'pointer', justifyContent: 'space-between'}}
        key={port._id}
        className={cx(selectedPort ? s.active_item : null)}
      >
        {port.name}
      </div>
    );
  }

  render() {
    const { search, filteredPorts } = this.state;
    return <div>
      <TextField
        hintText='Search'
        floatingLabelText='Search'
        name='search port'
        inputStyle={{
          fontSize: '14px',
          color: 'rgba(0,0,0,0.87)',
        }}
        style={{
          width: 'calc(100% - 20px)',
        }}
        value={search}
        onChange={this.handleSearch}
      />

      <div className={s.wrapper_list_port}>
        <AutoSizer>
          {
            ({ width, height }) => {
              return <List
                ref={list => { this.list = list; } }
                rowRenderer={this.renderRow}
                height={height}
                width={width}
                rowHeight={32}
                rowCount={filteredPorts.length}
                className={s.list_regions}
              />
            }
          }
        </AutoSizer>
      </div>
    </div>
  }
}

export default withStyles(s)(PortList);
