import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../Pages.scss';
import TradingDesk from '../../../Icons/TradingDesk';
import CargoMonitor from '../../../Icons/CargoMonitor';
import FleetMonitor from '../../../Icons/FleetMonitor';
import Exchange from '../../../Icons/Exchange';
import Link from '../../../Link/Link';
import ShipnextSolutionsCarousel from './ShipnextSolutionsCarousel';
import PortsIcon from '../../../Icons/PortsIcon';
import Documents from '../../../Icons/Documents';

const TradingDeskSolution = () => (
  <div className={s.solution_card_wrapper}>
    <div className={s.solution_card_header}>
      <TradingDesk />
      <h3>SHIPNEXT <br />Trading Desk</h3>
    </div>
    <div className={s.solution_card_body}>
      <div className={s.wrapper_image}>
        <img src="/images/solutions_screen/trading_desk1.png" alt="Trading Desk" />
      </div>
      <div>
        <div className={s.wrapper_info}>
        <p>Shipnext is digital shipping platform. SHIPNEXT is a  solution which helps structure internal and external freight requests and cargo shipments, as well as open ship positions and shipping solutions into one continuous flow, for instant cargo-to-ship, ship-to-cargo matching and freight trading.</p>
        </div>
      </div>
      <div className={s.solution_card_link}>
        <Link to={'/solution-shipnext-marketplace'} className={s.learn_more}>Learn More</Link>
      </div>
    </div>
  </div>
);
const CargoMonitorSolution = () => (
  <div className={s.solution_card_wrapper}>
    <div className={s.solution_card_header}>
      <CargoMonitor />
      <h3>SHIPNEXT <br />Cargo Monitor</h3>
    </div>
    <div className={s.solution_card_body}>
      <div className={s.wrapper_image}>
        <img src="/images/solutions_screen/cargo_monitor1.png" alt="Shipnext Cargo Monitor" />
      </div>
      <div>
        <div className={s.wrapper_info}>
        <p>SHIPNEXT as a Supply Chain management solution. Integrate your door-to-door delivery and inventory management into one seamless flow.
          Use SHIPNEXT algorithms, secure and reliable data-processing to connect to your supply-chain related data for efficient ERP, documentation flow and supply-chain management. </p>
        </div>
      </div>
      <div className={s.solution_card_link}>
        <Link to={'/solution-shipnext-cargo-monitor'} className={s.learn_more}>Learn More</Link>
      </div>
    </div>
  </div>
);
const FleetMonitorSolution = () => (
  <div className={s.solution_card_wrapper}>
    <div className={s.solution_card_header}>
      <FleetMonitor />
      <h3>SHIPNEXT <br />Fleet Monitor</h3>
    </div>
    <div className={s.solution_card_body}>
      <div className={s.wrapper_image}>
        <img src="/images/solutions_screen/fleet_monitor_1.png" alt="SHIPNEXT Fleet Monitor" />
      </div>
      <div>
        <div className={s.wrapper_info}>
        <p>SHIPNEXT as a solution the helps companies track all international Commercial Fleet, Selected or Own fleet, S&P Fleet. And populate their records with various data related to vessel’s particulars, cargo exclusions, trading restrictions, as well export lists and collaborate with the other members of their team.</p>
        </div>
      </div>
      <div className={s.solution_card_link}>
        <Link to={'/solution-shipnext-fleet-monitor'} className={s.learn_more}>Learn More</Link>
      </div>
    </div>
  </div>
);
const ExchangeSolution = () => (
  <div className={s.solution_card_wrapper}>
    <div className={s.solution_card_header}>
      <Exchange />
      <h3>SHIPNEXT <br />Exchange</h3>
    </div>
    <div className={s.solution_card_body}>
      <div className={s.wrapper_image}>
        <img src="/images/solutions_screen/exchange1.png" alt="SHIPNEXT Exchange" />
      </div>
      <div>
        <div className={s.wrapper_info}>
        <p>SHIPNEXT is a service that allows for connectivity between Email such as: Gmail, Sedna, Telix/LgMar, Outlook, FrontApp etc. Marservices and the SHIPNEXT algorithm, that allows instant cargo-to-ship matching, ship-to-cargo matching, freight negotiation templates and other features from within the email service of choice.</p>
        </div>
      </div>
      <div className={s.solution_card_link}>
        <Link to={'/solution-shipnext-exchange'} className={s.learn_more}>Learn More</Link>
      </div>
    </div>
  </div>
);
const DocumentsSolution = () => (
  <div className={s.solution_card_wrapper}>
    <div className={s.solution_card_header}>
      <Documents />
      <h3>SHIPNEXT <br />Documents</h3>
    </div>
    <div className={s.solution_card_body}>
      <div className={s.wrapper_image}>
        <img src="/images/solutions_screen/documents1.png" alt="SHIPNEXT Documents" />
      </div>
      <div>
        <div className={s.wrapper_info}>
        <p>SHIPNEXT a is a solution for Contract Management, digitalisation of documentation flow and electronic bill of ladings. Our users can hold freight negotiations, user pre-recorded Terms and Conditions, create Contracts, Charter-Parties and automate contract management, extract and transfer data, and execute smart contracts.</p>
        </div>
      </div>
      <div className={s.solution_card_link}>
        <Link to={'/solution-shipnext-documents'} className={s.learn_more}>Learn More</Link>
      </div>
    </div>
  </div>
);
const PortsSolution = () => (
  <div className={s.solution_card_wrapper}>
    <div className={s.solution_card_header}>
      <PortsIcon />
      <h3>SHIPNEXT <br />Ports</h3>
    </div>
    <div className={s.solution_card_body}>
      <div className={s.wrapper_image}>
        <img src="/images/solutions_screen/ports1.png" alt="SHIPNEXT Ports" />
      </div>
      <div>
        <div className={s.wrapper_info}>
        <p>SHIPNEXT is a a service that allows to get important port related information, including the main navigation restrictions, handling facilities, vessel’s line-ups, weather, lineup, congestion, costs and general description. SHIPNEXT promotes connectivity between the shipping marketplace and ports.</p>
        </div>
      </div>
      <div className={s.solution_card_link}>
        <Link to={'/solution-shipnext-ports'} className={s.learn_more}>Learn More</Link>
      </div>
    </div>
  </div>
);

const items = [
  <div className={s.slide} data-value="1"><TradingDeskSolution /></div>,
    <div className={s.slide} data-value="2"><CargoMonitorSolution /></div>,
    <div className={s.slide} data-value="3"><FleetMonitorSolution /></div>,
    <div className={s.slide} data-value="4"><ExchangeSolution /></div>,
    <div className={s.slide} data-value="5"><DocumentsSolution /></div>,
    <div className={s.slide} data-value="6"><PortsSolution /></div>,
];
class ShipnextSolutions extends Component {
  render() {
    return (
      <div className={s.shipnext_solutions_technology_page}>
        <div className={s.container}>
          <div className={s.title}>
            <h2>SHIPNEXT Solutions</h2>
          </div>
            <ShipnextSolutionsCarousel
              items={items}
            />
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(ShipnextSolutions));
