import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Navigation.scss';
import Link from '../Link';
import RoundThemableButton from '../Common/RoundThemableButton';
const enhanceWithClickOutside = require('react-click-outside');

class Navigation extends Component {

  render() {
    const { user, className, logout, openLogin, login, loginConnect, onMouseEnterHandlerSolutions, onMouseLeaveHandlerSolutions } = this.props;
    return (
      <div className={cx(s.root, className)} role="navigation">
        <div
          className={s.dropdown_menu}
          onMouseEnter={onMouseEnterHandlerSolutions}
          onMouseLeave={onMouseLeaveHandlerSolutions}
        >
          <div className={s.name}>
            <span>Solutions</span>
            <span className={s.arrow} />
          </div>
        </div>
        <Link className={s.name} to={'/technology'}>TECHNOLOGY</Link>
        <Link className={s.name} to={'/pricing'}>PRICING</Link>
        <Link className={s.name} to={'/partners'}>PARTNERS</Link>

        {user
          ? <span>
              <span className={s.spacer} />
            </span>
          : <span className={s.wrapper_login}>
              <Link to="/login" className={s.link}>Login</Link>
              <RoundThemableButton className={cx(s.link, s.highlight)} component={Link} to="/register">
                Register <span className={s.for_free}>for free</span>
              </RoundThemableButton>
            </span>
        }
      </div>
    );
  }
}

export default withStyles(s)(Navigation);
