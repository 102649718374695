import React from 'react';
import {connect} from "react-redux";
import {getGroupDetails, updateGroup} from "../../actions/contacts";
import Loader from "../Common/Loader";
import CompanyView from './CompanyView';
import {ContactDetails} from "./ContactDetails";
import {createGroup} from "../Common/Tags/utils";
import {GroupEditConnected} from "./GroupEdit";
import GroupView from "./GroupView";

export class GroupDetails extends ContactDetails {

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    if (!this.state.contact?.id) {
      return this.renderError();
    }
    if (this.props.action === 'edit') {
      return (<div style={{ height: "100%" }}>
        <GroupEditConnected contact={this.state.contact} onClose={this.closeEdit} action={'edit-contact'} />
      </div>);
    }
    return (
      <div style={{ height: "100%" }}>
        <GroupView openEmailsList={this.props.openEmailsList} closeEmailsList={this.props.closeEmailsList} company={this.state.contact} onEdit={this.startEdit} updateCompany={this.props.updateGroup} />
      </div>
    );
  }
}

export const GroupDetailsConnected = connect(state => ({ ...state.contacts, user: state.login.user, tagCategoriesByName: state.login.tagCategoriesByName }), { createContact: createGroup, getContactDetails: getGroupDetails, updateGroup })(GroupDetails);
