import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Port = props => (

  <span {...props}><span className="icon-wrapper"><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <defs />
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon/Menu/Ports">
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <path
          d="M11,6.82929429 C9.83480763,6.41745788 9,5.30621883 9,4 C9,2.34314575 10.3431458,1 12,1 C13.6568542,1 15,2.34314575 15,4 C15,5.30621883 14.1651924,6.41745788 13,6.82929429 L13,9 L16.5,9 L16.5,11 L13,11 L13,18.8999819 C15.2822403,18.4367116 17,16.4189579 17,14 L19,14 C19,17.5264719 16.3922941,20.4438815 13,20.9291111 L13,21 L11,21 L11,20.9291111 C7.60770586,20.4438815 5,17.5264719 5,14 L7,14 C7,16.4189579 8.71775968,18.4367116 11,18.8999819 L11,11 L7.5,11 L7.5,9 L11,9 L11,6.82929429 Z M12,5 C12.5522847,5 13,4.55228475 13,4 C13,3.44771525 12.5522847,3 12,3 C11.4477153,3 11,3.44771525 11,4 C11,4.55228475 11.4477153,5 12,5 Z"
          id="Combined-Shape" fill="#FFFFFF" fillRule="nonzero" opacity="0.4"
        />
      </g>
    </g>
  </svg></span></span>
);

Port = pure(Port);
Port.displayName = 'Port';
Port.muiName = 'SvgIcon';

export default Port;
