/** @name GET_MORE_NEWS_LIST */
export default function GET_MORE_NEWS_LIST(state, action) {
  const newState = {};

  newState.newsList = {
    ...state.newsList,
    loadMoreLoading: true,
    errorMessage: '',
  };

  return { ...state, ...newState };
}
