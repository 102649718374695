import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from '../Common/Dialog';
import s from './ModalDownloadApp.scss';
import Logo_Color from '../Icons/Logo_Color';
import AppDownload from '../Common/AppDownload';
import withStyles from "isomorphic-style-loader/lib/withStyles";

class ModalDownloadApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpened: false,
      handleClose: false,
    };
  }
  _handleOpenDialog = () => {
    this.setState({ dialogOpened: true });
    this.setState({ handleClose: true });
  };
  _handleCloseDialog = () => {
    this.setState({ dialogOpened: false });
  };

  render() {
    return (
      <a className={this.props.className ? this.props.className : null} onClick={this._handleOpenDialog}>
        {this.props.name}
        { this.state.dialogOpened
          ? <Dialog
            className={s.modal}
            contentClassName={s.content_modal}
            bodyClassName={s.wrapper_modal}
            open
            handleClose={this._handleCloseDialog}
          >
            <Logo_Color className={s.logo}/>
            <h2>The continue, download<br/> the free application</h2>
            <div className={s.laptop}></div>
            <div className={s.container_app}>
              <AppDownload />
            </div>
          </Dialog>
          : null
        }
      </a>
    )
  }
}

export default withStyles(s)(ModalDownloadApp);
