import React, { PureComponent } from 'react';
import RaisedButton from './RaisedButton';
import s from './SpinnerButton.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import _SpinnerBtn from './_SpinnerBtn';
class SpinnerButton extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  turnOnSpinner = () => {
    this.setState({ loading: true });
    this.offTimer = setTimeout(this.turnOffSpinner, 60 * 1000);
  };

  turnOffSpinner = (res) => {
    clearTimeout(this.offTimer);
    this.setState({ loading: false });
    return res;
  };
  componentWillUnmount() {
    clearTimeout(this.offTimer);
    this.state.loading = false;
  }

  handleClick = (e) => {
    if (this.props.onClick) {
      if (this.state.loading) {
        return;
      }
      const promise = this.props.onClick(e);
      if (promise && promise.then) {
        this.turnOnSpinner();
        promise.finally(this.turnOffSpinner);
      }
    }
  };

  render() {
    return <_SpinnerBtn {...this.props} onClick={this.handleClick} loading={this.state.loading} />;
  }

}

export default withStyles(s)(SpinnerButton);

export function RoundedButton (props) {
  return <SpinnerButton buttonStyle={{borderRadius: '50px' }} overlayStyle={{ borderRadius: '50px'}} {...props} />
}
