import PropTypes from 'prop-types';
import React, { Component } from 'react'
import tc from './../TC.scss';
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import NarrowFormsyText from './../../../NewInputs/NarrowFormsyText';
import NarrowSelect from './../../../NewInputs/NarrowSelect';
import NarrowCargoAutocomplete from './../../../NewInputs/NarrowCargoAutocomplete';
import { CheckBox as Checkbox } from './../../../Monitor/Edit/VesselEdit';
import WarningIcon from "material-ui/svg-icons/alert/warning";

import ContentAdd from 'material-ui/svg-icons/content/add';
import Chip from 'material-ui/Chip';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from '../../../Common/RaisedButton'

import HiddenRequiredStepInput from './../HiddenRequiredStepInput';
const extractName = ([key, value]) => {
    let res = `${key}:`
    if (value && typeof value === 'object') {
      res += (value.name ? value.name : value);
    }
    else {
      res += value;
    }
    return res;
}

const createIdObj = row => Object.entries(row).map(extractName).join('');

export class Cargo extends Component {

  static defaultProps = {
    cargo: {}
  }

  static cargoTypes = [
    {
      id: 0,
      name: ''
    },
    {
      id: 1,
      name: 'Metals & Steel Products'
    },
    {
      id: 2,
      name: 'Forest Products'
    },
    {
      id: 3,
      name: 'Equipment & Machinery'
    },
    {
      id: 4,
      name: 'Fertilizers'
    },
    {
      id: 5,
      name: 'Oil & Chemicals'
    },
    {
      id: 6,
      name: 'Minerals and Rocks'
    },
    {
      id: 7,
      name: 'Food and Beverages'
    },
    {
      id: 8,
      name: 'Perishables'
    },
    {
      id: 9,
      name: 'Live Stock'
    },
    {
      id: 10,
      name: 'Organic Material'
    },
    {
      id: 11,
      name: 'Consumer Goods'
    },
    {
      id: 12,
      name: 'Project & General cargo'
    },
  ]

  componentDidMount() {
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
  }

  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getState = () => {
    return this.state;
  }

  getDefaultState = () => {
    const state = {
      cargo: {
          included: this.props.cargo.included || [],
          excluded: this.props.cargo.excluded || [],
          canProceed: this.props.cargo.canProceed || false
      },
    }
    return state;
  }

  handleDelete = (field = 'included', row) => {
    const newRows = this.state.cargo[field].filter(item => item.id !== row.id);
    this.handleChange(field, newRows);
  }

  addItem = (field = 'included', row) => {
    const rows = this.state.cargo[field];
    const newRow = {
      ...row,
      id: createIdObj(row)
    };
    const arrToSearch = field === 'included' ? 'excluded' : 'included';
    const alreadyExistsIndex = this.state.cargo[arrToSearch].findIndex(item => item.id === newRow.id)
    if (alreadyExistsIndex >= 0) {
      this.props.dispatch({
        type: 'MESSAGE',
        payload: {
          title: "Error",
          message: `Error adding duplicate cargo to ${field}; this already exists in ${arrToSearch}.`,
          level: "error",
          _id: new Date().getMilliseconds(),
          autoDismiss: 5
        }
      });
      return;
    }
    const newRows = [
      ...rows,
      newRow
    ]
    this.handleChange(field, newRows)
  }

  handleChange = (key, val) => {
    this.setState(state => ({
      cargo: {
        ...state.cargo,
        [key]: val
      }
    }))
  }

  handleNext = () => {
    this.setState(state => ({cargo: { ...state.cargo, canProceed: true } }));
    this.props.handleNext(this.getState());
  }

  render() {

    const {included, excluded, canProceed} = this.state.cargo;
    const {handleDelete, addItem} = this;

    return (
      <div className={cx(tc.col, tc.cargo)}>
        <CargoIncluded rows={included} addRow={addItem.bind(this, 'included')}>
            <CargoChips color="#D6EDBD" rows={included} handleDelete={handleDelete.bind(this, 'included')}></CargoChips>
        </CargoIncluded>
        <CargoExcluded rows={excluded} addRow={addItem.bind(this, 'excluded')}>
            <CargoChips rows={excluded} handleDelete={handleDelete.bind(this, 'excluded')}></CargoChips>
        </CargoExcluded>
        <span>
          <HiddenRequiredStepInput value={canProceed} required={!canProceed} name={`cargo[canProceed]`}/>
          <RaisedButton
            onClick={() => this.setState(state => ({ cargo: { ...state.cargo, canProceed: true } }))}
            label="NEXT"
            disableTouchRipple
            disableFocusRipple
            primary
            type="submit"
            style={{ margin: '24px 0 6px 0' }}
          />
        </span>
      </div>
    )
  }
}

class CargoIncluded extends Component {
  static defaultProps = {
    children: <CargoChips></CargoChips>,
    rows: []
  }

  static bulks = [
    {
      val: 'dry-bulk',
      name: 'Dry-bulk'
    },
    {
      val: 'wet-bulk',
      name: 'Wet-bulk'
    },
    {
      val: 'break-bulk',
      name: 'Break-bulk'
    },
  ]

  static groupName = 'included';

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      dangerous: false,
      bulk: 'dry-bulk',
      cargoName: '',
      cargoType: '',
      cargoTypeInvalid: false
    }
  }

  selectCargoType = val => {
    if (typeof val === 'object' && val?.parent?.red?.name) {
      const cargoType = this.context.cargoTypes.find(c => c._id === val.parent.red._id);
      if (cargoType) {
        console.log(cargoType)
        this.handleChange('cargoType', null, cargoType);
        this.setState({
          cargoTypeInvalid: false
        })
      }
    }
    else {
      this.setState({
        cargoTypeInvalid: true
      })
    }
  }

  handleChange = (key, ev, val) => {
    if (key === 'cargoName') {
      if (val) {
        this.selectCargoType(val);
      }
      else {
        this.setState({
          cargoTypeInvalid: false
        })
      }
    }
    else if (key === 'cargoType' && val) {
      this.setState({
        cargoTypeInvalid: false
      })
    }
    this.setState({
      [key]: val
    })
  }

  resetState = () => {
    this.setState({
      ...(this.getDefaultState())
    })
  }

  addRow = row => {
    const {dangerous = false, bulk = false, cargoName = '', cargoType = ''} = this.state;
    if (typeof cargoName === 'string' && cargoName.length > 0 && !cargoType) {
      this.setState({
        cargoTypeInvalid: true
      });
      return;
    }
    this.props.addRow({
      dangerous,
      bulk,
      cargoName,
      cargoType
    });
    this.resetState();
  }

  render() {
    const {dangerous, bulk, cargoName, cargoType, cargoTypeInvalid} = this.state;
    const {rows} = this.props;
    const {bulks, groupName} = this.constructor;

    const cargoTypes = [undefined, ...this.context.cargoTypes];

    const id = createIdObj({
      dangerous,
      bulk,
      cargoName,
      cargoType
    });
    return (
      <div className={cx(tc.col)}>
        <div className={cx(tc.row, tc.equal_divs)}>
            <div className={cx(tc.expand)} style={{width: '23%'}}>
              <div style={{visibility: 'hidden', width: 0, height: 0, overflow: 'hidden'}}>
                <NarrowFormsyText value={id} name={`cargo[${groupName}][${rows.length}][id]`} />
              </div>
              <NarrowCargoAutocomplete
                  floatingLabelText={groupName === 'excluded' ? `Cargo Name (Excluded)` : `Cargo Name`}
                  name={`cargo[${groupName}][${rows.length}][cargoName]`}
                  fullWidth
                  //validationErrors={rows.length > 0 ? undefined : { isDefaultRequiredValue: 'required' }}
                  //requiredError={rows.length > 0 ? undefined : "required"}
                  //required={rows.length > 0 ? undefined : true}
                  //errorText="Enter cargo name"
                  value={cargoName}
                  //requiredError="required"
                  updateInput={this.handleChange.bind(this, 'cargoName', null)}
                  onNewRequest={this.handleChange.bind(this, 'cargoName', null)}
              />
            </div>
            <div className={cx(tc.expand)} style={{width: '23%'}}>
              <NarrowSelect
                    controlledErrorText="required"
                    valid={!cargoTypeInvalid}
                    floatingLabelText={`Cargo Type`}
                    name={`cargo[${groupName}][${rows.length}][cargoType]`}
                    fullWidth
                    validationErrors={cargoTypeInvalid ? { isDefaultRequiredValue: 'required' } : undefined}
                    errorText="Enter cargo name"
                    required={typeof cargoName === 'string' && cargoName.length > 0}
                    requiredError="required"
                    disabled={(typeof cargoName === 'object' && cargoType)}
                    value={cargoType}
                    onChange={this.handleChange.bind(this, 'cargoType')}
              >
                  {
                    cargoTypes.map(item => <MenuItem value={item} key={item?._id || Math.random()} primaryText={item?.name || ""}/>)
                  }
              </NarrowSelect>
            </div>
            <div className={tc.row} style={{ width: '230px' }}>
                  <NarrowSelect
                        floatingLabelFocusStyle={{}}
                        floatingLabelStyle={{visibility: 'hidden'}}
                        floatingLabelText="1"
                        floatingLabelShrinkStyle={{}}
                        name={`cargo[${groupName}][${rows.length}][bulk]`}
                        fullWidth
                        autoWidth
                        value={bulk}
                        onChange={this.handleChange.bind(this, 'bulk')}
                  >
                      {
                        bulks.map(item => <MenuItem value={item.val} key={item.val} primaryText={item.name}></MenuItem>)
                      }
                  </NarrowSelect>
              <div>
                  <Checkbox style={{maxWidth: '91px'}} className={tc.checkbox} checked={dangerous} label="Dangerous" onChange={this.handleChange.bind(this, 'dangerous')} name={`cargo[${groupName}][${rows.length}][dangerous]`} />
              </div>
              <div className={tc.add_btn}  onClick={this.addRow}>
                <ContentAdd
                    color="#285596"
                    style={{padding: '2px'}}
                />
              </div>
            </div>
        </div>
        <div className={tc.row}>
              {this.props.children}
        </div>
      </div>
    )
  }
}

/* class CargoExcluded extends Component {
  static defaultProps = {
    children: <CargoChips></CargoChips>,
    rows: []
  }
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      dangerous: false,
      bulk: 'dry-bulk',
      cargoName: '',
      cargoType: '',
    }
  }

  selectCargoType = val => {
    if (typeof val === 'object' && val?.parent?.red?.name) {
      const cargoType = this.context.cargoTypes.find(c => c._id === val.parent.red._id);
      if (cargoType) {
        console.log(cargoType)
        this.handleChange('cargoType', null, cargoType);
      }
    }
  }

  handleChange = (key, ev, val) => {
    if (key === 'cargoName' && val) {
      this.selectCargoType(val);
    }
    this.setState({
      [key]: val
    })
  }

  addExcluded = row => {
    const {dangerous = false, bulk = false, cargoName = '', cargoType = ''} = this.state;
    this.props.addExcluded({
      dangerous,
      bulk,
      cargoName,
      cargoType
    });
    this.resetState();
  }

  resetState = () => {
    this.setState({
      ...(this.getDefaultState())
    })
  }

  render() {
    const {cargoName, cargoType, bulk, dangerous} = this.state;
    const {rows} = this.props;
    const {bulks} = CargoIncluded;
    const id = createIdObj(this.state);
    return (
        <div className={tc.col}>
          <div className={cx(tc.row, tc.equal_divs)}>
            <div className={cx(tc.expand)} style={{width: '23%'}}>
                <div style={{visibility: 'hidden', width: 0, height: 0, overflow: 'hidden'}}>
                  <NarrowFormsyText value={id} name={`cargo[excluded][${rows.length}][id]`} />
                </div>
                <NarrowCargoAutocomplete
                    floatingLabelText={`Cargo Name`}
                    name={`cargo[excluded][${rows.length}][cargoName]`}
                    fullWidth

                    value={cargoName}
                    updateInput={this.handleChange.bind(this, 'cargoName', null)}
                    onNewRequest={this.handleChange.bind(this, 'cargoName', null)}
                />
              </div>
              <div className={cx(tc.default_margin, tc.expand)} style={{width: '23%'}}>
                <NarrowSelect
                      floatingLabelText={`Cargo Type`}
                      name={`cargo[excluded][${rows.length}][cargoType]`}
                      fullWidth
                      value={cargoType}
                      onChange={this.handleChange.bind(this, 'cargoType')}
                >
                    {
                      this.context.cargoTypes.map(item => <MenuItem value={item} key={item.id} primaryText={item.name}></MenuItem>)
                    }
                </NarrowSelect>
              </div>
              <div className={tc.row} style={{width: 'auto', flex: '0 1 auto', alignItems: 'flex-end', height: '44px'}}>
                <div className={cx(tc.default_margin, tc.flex_end)}>
                    <NarrowSelect
                          floatingLabelFocusStyle={{}}
                          floatingLabelStyle={{visibility: 'hidden'}}
                          floatingLabelText="1"
                          floatingLabelShrinkStyle={{}}
                          name={`cargo[excluded][${rows.length}][bulk]`}
                          fullWidth
                          value={bulk}
                          onChange={this.handleChange.bind(this, 'bulk')}
                    >
                        {
                          bulks.map(item => <MenuItem value={item.val} key={item.val} primaryText={item.name}></MenuItem>)
                        }
                    </NarrowSelect>
                </div>
                <div className={cx(tc.default_margin, tc.flex_end)} style={{paddingRight: '16px'}}>
                    <Checkbox style={{maxWidth: '91px'}} className={tc.checkbox} checked={dangerous} label="Dangerous" onChange={this.handleChange.bind(this, 'dangerous')} name={`cargo[excluded][${rows.length}][dangerous]`}></Checkbox>
                </div>
                <div className={tc.add_btn} style={{paddingBottom: '0px', marginLeft: '16px'}} onClick={this.addExcluded}>
                  <ContentAdd
                      color="#285596"
                      style={{padding: '4px'}}
                  />
                </div>
              </div>
          </div>
          <div className={tc.row}>
            {this.props.children}
          </div>
        </div>
    )
  }
} */

class CargoExcluded extends CargoIncluded {
  static groupName = "excluded"
}

function CargoChips({ rows, color = '#E6E6E6', handleDelete }) {

  function getInfo(row) {
    return `${(row.cargoType || row.bulk) ? '(' + (row.cargoType ? row.cargoType.name : '') + (row.bulk ? row.cargoType ? `, ${row.bulk}` : `${row.bulk}` : '') + ')' : ''}`
  }

  return (
    <div className={tc.row_start_wrap}>
      {
        rows.map(row =>
            <Chip key={row.id ? row.id : (row?.cargoName?._id ?? row.cargoName)} onRequestDelete={handleDelete.bind(this, row)} backgroundColor={color} style={{margin: '8px 8px 0px 0px'}} className={tc.chip}
            >
              {
                row.dangerous
                ? (
                    <span title="dangerous" style={{marginRight: 4}}>
                      <WarningIcon
                        style={{
                          display: "inline",
                          minWidth: '12px',
                          marginTop: '-2px',
                          width: 18,
                          height: 18,
                          color: "#E34848"
                        }}
                      ></WarningIcon>
                    </span>
                )
                : null
              }
              {
                row.cargoName
                ? `${row.cargoName?.name ? row.cargoName.name : row.cargoName} ${getInfo(row)}`
                : `Any ${getInfo(row)} cargo`
              }
            </Chip>
          )
      }
    </div>
  )
}

CargoIncluded.contextTypes = {
  cargoTypes: PropTypes.array,
  formsy: PropTypes.object,
};

CargoExcluded.contextTypes = {
  cargoTypes: PropTypes.array,
  formsy: PropTypes.object,
};
export default connect(null, dispatch => ({dispatch}))(withStyles(tc)(Cargo))
