import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleSubscribeDialog } from '../../actions/login';
import UpgradePlanDialog from '../Settings/UpgradePlanDialog';

class SubscribeDialog extends Component {
  static propTypes = {
    subscribeDialogOpened: PropTypes.bool,
    toggleSubscribeDialog: PropTypes.func,
  };
  static defaultProps = {
    subscribeDialogOpened: false,
  };

  handleClose =() => {
    this.props.toggleSubscribeDialog(false);
  };

  render() {
    return (
      <div >
        {this.props.subscribeDialogOpened && this.props.user ?
          <UpgradePlanDialog handleClose={this.handleClose} />
          : null}
      </div>
    );
  }
}


export default connect(
  state => ({ ...state.login }),
  { toggleSubscribeDialog },
)(SubscribeDialog);
