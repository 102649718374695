import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AutoComplete from './AutoComplete';
import PortApi from '../../core/api/Port';
import PortAutocomplete from './PortAutocomplete';

class ViaAutocomplete extends PortAutocomplete {
  static contextTypes = {
    formsy: PropTypes.object,
  };
  static defaultProps = {
    forceSelection: true,
    openOnFocus: true,
    popoverProps: { style: { minWidth: '256px' } },
  };
  static vias = [];
  constructor(props, context) {
    super(props, context);
    this.Api = {
      find: async function(query) {
        if(!this.constructor.vias.length) {
          const res = await PortApi.getVias();
          this.constructor.vias.splice();
          this.constructor.vias.push(...res.data);
        }
        if (!query?.name) {
          return { data: [...this.constructor.vias] };
        }
        query = query.name.toLocaleLowerCase();
        return {
          data: this.constructor.vias.filter(
            via => via.name.toLocaleLowerCase().indexOf(query) !== -1,
          ),
        };
      }.bind(this),
    };

  }
}

export default ViaAutocomplete;
