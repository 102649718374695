import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Pane } from "../Common/SplitPane";
import styled from "styled-components";
import AvatarEdit from "./AvatarEdit";
import ContactEmailsEdit from "./ContactEmailsEdit";
import ExtendedForm from "../Common/ExtendedForm";
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";
import NarrowFormsyDate from "../NewInputs/NarrowFormsyDate";
import ContactPhonesEdit from "./ContactPhonesEdit";
import ContactImportantDatesEdit from "./ContactImportantDatesEdit";
import MenuItem from "material-ui/MenuItem";
import {NarrowSelect} from "../NewInputs/NarrowSelect";
import FormsyTextarea from "../Common/FormsyTextarea";
import Collapse from "../Common/Collapse";
import FormsyAttachments from "../Common/FormsyAttachments/FormsyAttachments";
import SpinnerButton from "../Common/SpinnerButton";
import RaisedButton from "../Common/RaisedButton";
import ContactBookCompanyAutocomplete from "./ContactBookCompanyAutocomplete";
import {v4} from "uuid";
import {connect} from "react-redux";
import {checkAll, checkContact, createContact, updateContact, getContactDetails, getContactList, deleteContacts} from "../../actions/contacts";
import {createContactAdapter} from "./contactBookUtils";
import {cloneDeep} from "lodash";
import ContactCompaniesEdit from "./ContactCompaniesEdit";
import ConfirmDialog from "../Common/ConfirmDialog";
import history from "../../core/history";
import {findSectorById} from "../dictionaries";
import RoundBorderedBtn from "../Common/RoundBorderedBtn";
import NarrowCountryAutocomplete from "../NewInputs/NarrowCountryAutocomplete";
import {NarrowCityAutocomplete} from "../NewInputs/NarrowCityAutocomplete";


function getEmptyContact(){
  return {
    photoId: null,
    logoId: null,
    name: "",
    emails: [""],
    company: "",
    address: "",
    altAddress: "",
    postalCode: "",
    city: undefined,
    country: undefined,
    sector: undefined,
    industry: undefined,
    position: "",
    positionNotes: "",
    birthDate: undefined,
    importantDates: [{ date: undefined, note: "" }],
    skype: "",
    maritalStatus: "",
    website: "",
    details: "",
    phones: [{ type: "landline", number: "" }],
    attachments: [],
    linkedin: "",
    twitter: "",
    facebook: "",
    messenger: "",
    instagram: "",
    telegramName: "",
    iceName: "",
    kakaoName: "",
    yahoo: "",
    youtube: "",
    persons: [],
    companies: [{}],
  };
}

export const Body = styled.div`
  padding: 20px 20px 48px 20px;

  .attachments-part {
    border-top: 1px dashed var(--stroke-light-gray1) ;
    padding-top: 12px;
    margin-top: 12px;
  }
  .social-media-part {
    margin-top: 12px;
  }
  .members-part {

  }
  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    > div + div {
      margin-left: 8px;
    }
    .delete-button {
      margin-right: auto;
      button span {
        color: var(--text-light) !important;
      }
    }
  }
`;

const MaritalStatusSelect = (props) => {
  return (<NarrowSelect
    floatingLabelText={'Marital Status'}
    requiredError="required"
    fullWidth
    autoWidth
    {...props}
  >
    <MenuItem
      key="single"
      value="single"
      primaryText="Single"
    />
    <MenuItem
      key="married"
      value="married"
      primaryText="Married"
    />
    <MenuItem
      key="widowed"
      value="widowed"
      primaryText="Widowed"
    />
    <MenuItem
      key="divorced"
      value="divorced"
      primaryText="Divorced"
    />
    <MenuItem
      key="separated"
      value="separated"
      primaryText="Separated"
    />
  </NarrowSelect>);
};


export const BaseContactInfoWrapper = styled.div`
  .avatar-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    width: 100%;
  }
  .main-part {
    display: flex;
    .left-part, .right-part {
      width: 50%;
    }
    .left-part {
      margin-right: 10px;
    }
    .right-part {
      margin-left: 10px;
    }
  }
  .first-contact-part {
    h4 {
      color: var(--text-dark333);
      text-transform: uppercase;
      margin: 12px 0;
      font-size: 12px;
    }
    textarea {
      margin-bottom: 8px;
    }
  }
`;

export const SocialMediaEdit = styled(({ className, changeHandlers, contact, ...props }) => {
  return (<div className={className}>
      <Collapse title={'SOCIAL MEDIA'}>
        <div className={'social-media-edit-content'}>
          <div>
            <NarrowFormsyText
              value={contact.linkedin}
              name={`linkedin`}
              onChange={changeHandlers.linkedin}
              floatingLabelText={'Linkedin'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.facebook}
              name={`facebook`}
              onChange={changeHandlers.facebook}
              floatingLabelText={'Facebook'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.instagram}
              name={`instagram`}
              onChange={changeHandlers.instagram}
              floatingLabelText={'Instagram'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.iceName}
              name={`iceName`}
              onChange={changeHandlers.iceName}
              floatingLabelText={'Ice'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.yahoo}
              name={`yahoo`}
              onChange={changeHandlers.yahoo}
              floatingLabelText={'Yahoo'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
          </div>
          <div>
            <NarrowFormsyText
              value={contact.twitter}
              name={`twitter`}
              onChange={changeHandlers.twitter}
              floatingLabelText={'Twitter'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.messenger}
              name={`messenger`}
              onChange={changeHandlers.messenger}
              floatingLabelText={'Messenger'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.telegramName}
              name={`telegramName`}
              onChange={changeHandlers.telegramName}
              floatingLabelText={'Telegram'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.kakaoName}
              name={`kakaoName`}
              onChange={changeHandlers.kakaoName}
              floatingLabelText={'Kakao'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.youtube}
              name={`youtube`}
              onChange={changeHandlers.youtube}
              floatingLabelText={'Youtube'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
          </div>
        </div>
      </Collapse>
  </div>);
})`
  .social-media-edit-content {
    display: flex;
    padding-top: 8px;
    >div:first-child {
      flex: 1;
      margin-right: 10px;
    }
    >div:nth-child(2) {
      flex: 1;
      margin-left: 10px;
    }

  }
`;

class BaseContactInfoEdit extends PureComponent {
  now = new Date();

  render(){
    const { changeHandlers, contact } = this.props;
    return (<BaseContactInfoWrapper>

        <div className={'avatar-wrapper'}>
          <AvatarEdit onChange={changeHandlers.photoId} photoId={contact.photoId} />
        </div>
        <div className={'main-part'}>
          <div className={'left-part'}>
            <NarrowFormsyText
              value={contact.name}
              name={`name`}
              required
              onChange={changeHandlers.name}
              floatingLabelText={'Full Name*'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
              requiredError="required"
            />
            <ContactCompaniesEdit onChange={changeHandlers.companies} companies={contact.companies} />
            <NarrowFormsyText
              value={contact.altAddress}
              name={`altAddress`}
              onChange={changeHandlers.altAddress}
              floatingLabelText={'Alternative Address'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowCountryAutocomplete
              name={"country"}
              value={contact.country}
              floatingLabelText={'Country'}
              validationErrors={{ isDefaultRequiredValue: 'required', isObj: 'Please select country' }}
              validations="isObj"
              validationError="Please select country"
              fullWidth
              onNewRequest={(value) => { changeHandlers.country(null, value); }}
            />
            <NarrowFormsyText
              value={contact.position}
              name={`position`}
              onChange={changeHandlers.position}
              floatingLabelText={'Position'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyDate
              value={contact.birthDate}
              name={`birthDate`}
              onChange={changeHandlers.birthDate}
              floatingLabelText={'Birthday'}
              fullWidth
              maxDate={this.now}
            />
            <NarrowFormsyText
              value={contact.skype}
              name={`skype`}
              onChange={changeHandlers.skype}
              floatingLabelText={'Skype'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <ContactPhonesEdit
              phones={contact.phones}
              onChange={changeHandlers.phones}
            />
          </div>
          <div className={'right-part'}>
            <ContactEmailsEdit onChange={changeHandlers.emails} emails={contact.emails} entityType={'person'} />
            <NarrowFormsyText
              value={contact.address}
              name={`address`}
              onChange={changeHandlers.address}
              floatingLabelText={'Company Address'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <MaritalStatusSelect
              name={'maritalStatus'}
              value={contact.maritalStatus}
              onChange={changeHandlers.maritalStatus}
            />
            <NarrowCityAutocomplete
              name={"city"}
              value={contact.city}
              floatingLabelText={'City'}
              validationErrors={{ isDefaultRequiredValue: 'required', isObj: 'Please select city' }}
              validations="isObj"
              validationError="Please select city"
              fullWidth
              onNewRequest={(value) => { changeHandlers.city(null, value); }}
              params={{ country: contact.country?._id }}
            />
            <NarrowFormsyText
              value={contact.positionNotes}
              name={`positionNotes`}
              onChange={changeHandlers.positionNotes}
              floatingLabelText={'Notes (for Position)'}
              fullWidth
              validations="maxLength:1024"
              validationError="max 1024 characters"
            />
            <NarrowFormsyText
              value={contact.website}
              name={`website`}
              onChange={changeHandlers.website}
              floatingLabelText={'Website'}
              fullWidth
              validations="isSite"
              validationError="please enter valid website"
            />
            <ContactImportantDatesEdit
              dates={contact.importantDates}
              onChange={changeHandlers.importantDates}
            />
          </div>
        </div>
        <div className={'first-contact-part'}>
          <h4>ADDITIONAL DETAILS</h4>
          <FormsyTextarea
            name={'details'}
            placeholder={'Enter details...'}
            onChange={changeHandlers.details}
            value={contact.details}
          />
        </div>
    </BaseContactInfoWrapper>);
  }
}



export class ContactEdit extends PureComponent {
  changeHandlers = {};
  state = { contact: getEmptyContact() };
  entityType = 'person';
  static labelsMap = {
    create: {
      title: "CREATE CONTACT",
      addButtonLabel: "CREATE",
      cancelButtonLabel: "CANCEL",
      deleteButtonLabel: "DELETE CONTACT",
    },
    edit: {
      title: "EDIT CONTACT",
      addButtonLabel: "UPDATE",
      cancelButtonLabel: "CANCEL",
      deleteButtonLabel: "DELETE CONTACT",
    }
  };

  static contextTypes = {
    blockTransition: PropTypes.func,
  }

  constructor(props, context) {
    super(props, context);
    this.labels = this.constructor.labelsMap.create;
    this.containerId = 'contact-edit-form-' + v4();
    const emptyContact = getEmptyContact();
    Object.keys(emptyContact).forEach(key => {
      this.changeHandlers[key] = this.handleFieldChange.bind(this, key);
    });
    if (this.props.contact) {
      this.state.contact = cloneDeep(this.props.contact);
      this.state.contact.surname = undefined;
      if (!this.state.contact.phones?.length) {
        this.state.contact.phones = emptyContact.phones;
      }
      if (!this.state.contact.importantDates?.length) {
        this.state.contact.importantDates = emptyContact.importantDates;
      }
      if (!this.state.contact.companies?.length) {
        this.state.contact.companies = emptyContact.companies;
      }
      if (!this.state.contact.emails?.length) {
        this.state.contact.emails = emptyContact.emails;
      }
      if (this.props.contact.id && this.props.contact.approved) {
        this.labels = this.constructor.labelsMap.edit;
      }
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    if (this.unblock) {
      this.unblock();
    }
  }

  getHistoryBlockPredicate() {
    if (this.props.getHistoryBlockPredicate) {
      return this.props.getHistoryBlockPredicate(this.props.contact);
    }
    if (this.props.contact) {
      return ((id) => (location, action) => location.pathname.indexOf(`${id}/edit`) === -1)(this.props.contact.id);
    }
      return ((entityType) => (location, action) => location.pathname.indexOf(`add-${entityType}`) === -1)(this.entityType);
  }


  handleFieldChange = (fieldName, e, val) => {
      this.setState((state, props) => ({ contact: { ...state.contact, [fieldName]: val } }));
      switch (fieldName) {
        case 'city':
          if (val && this.state.contact.country?._id !== val.country._id) {
            this.setState((state, props) => ({ contact: { ...state.contact, country: val.country } }));
          }
          break;
        case 'country':
          if (val && this.state.contact.city && this.state.contact.city.country?._id !== val._id) {
            this.setState((state, props) => ({ contact: { ...state.contact, city: undefined } }));
          }
          break;
        case 'industry':

          if (val && this.state.contact.sector) {
            const sectorObj = findSectorById(this.state.contact.sector);
            if (sectorObj.industry._id !== val) {
              this.setState((state, props) => ({ contact: { ...state.contact, sector: undefined } }));
            }
          }
          break;
        case 'sector':
          if (val) {
            const sectorObj = findSectorById(val);
            this.setState((state, props) => ({ contact: { ...state.contact, industry: sectorObj.industry._id } }));
          }
          break;
        case 'companies': {
          const addressParts = [];
          const company = val[0];
          if (this.entityType === "person" && company && company.address) {
             if (company.address) {
               addressParts.push(company.address);
             }
            if (company.city) {
              addressParts.push(company.city.name);
            }
            if (company.postalCode) {
              addressParts.push(company.postalCode);
            }
            if (company.country) {
              addressParts.push(company.country.name);
            }
            const address = addressParts.join(', ').trim();
            this.setState((state, props) => ({ contact: { ...state.contact, address } }));
          }
          break;
        }
      }
    if (!this.unblock) {
      this.unblock = this.context.blockTransition('Are you sure you want to leave this page?', this.getHistoryBlockPredicate());
    }
  }

  handleClose = () => {
    this.props.onClose({ action: 'none' });
  }
  handleCreateContact = async () => {
    this.form.submit();
    if (!this.form.state.isValid){
      document.querySelector(`#${this.containerId} .invalid`).scrollIntoView();
      return;
    }
    let res;
    if (this.state.contact.id) {
      res = await this.props.updateContact(await createContactAdapter(this.state.contact), this.props.contact);
    } else {
      res = await this.props.createContact(await createContactAdapter(this.state.contact));
    }

    if (!res.data) {
      return res;
    }
    if (this.unblock) {
      this.unblock();
    }
    this.props.onClose({ action: this.props.action, id: res.data.id });
    return res;
  }

  refForm = (form) => {
    this.form = form;
  }

  handleDelete = () => {
    this.setState({ confirmDeletionDialog: (<ConfirmDialog
        title=" "
        open
        handleClose={async (confirm) => {
          this.setState({ confirmDeletionDialog: undefined });
          if (confirm) {
           await this.props.deleteContacts([this.props.contact.id]);
           history.push(`/$1/$2/-/-/-/---`);
          }
        }}
      >
        <span>Are you sure you want to delete this contact?</span>
      </ConfirmDialog>) });
  }

  render() {
    return (
      <Pane
        bodyProps={{ style: { height: '100%' } }}
        headerProps={{
          useBorder: true,
          children: this.labels.title,
          onClose: this.handleClose,
        }}
        scrollable
      >
        <Body id={this.containerId}>
          <ExtendedForm noValidate autoComplete="off" ref={this.refForm}>
            <BaseContactInfoEdit contact={this.state.contact} changeHandlers={this.changeHandlers} />
            <div className={'social-media-part'}>
              <SocialMediaEdit contact={this.state.contact} changeHandlers={this.changeHandlers} />
            </div>
            <div className={'attachments-part'}>
              <FormsyAttachments name={'attachments'} files={this.state.contact.attachments} onChange={this.changeHandlers.attachments} privateAttachment />
            </div>
            <footer>
              { this.props.contact?.id ? (<RoundBorderedBtn className={'delete-button'} label={this.labels.deleteButtonLabel} onClick={this.handleDelete} />) : null }
              <RaisedButton secondary onClick={this.handleClose} label={this.labels.cancelButtonLabel} />
              <SpinnerButton primary label={this.labels.addButtonLabel} onClick={this.handleCreateContact} />
            </footer>
          </ExtendedForm>
          {this.state.confirmDeletionDialog}
        </Body>
      </Pane>
    );
  }
}

export const ContactEditConnected = connect(state => ({ ...state.contacts, user: state.login.user, tagCategoriesByName: state.login.tagCategoriesByName }), { createContact, updateContact, deleteContacts })(ContactEdit);
