import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Dbs = props => (
  <span {...props}>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g id="databases" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="evenodd">
          <path d="M16.17156,1 C22.887805,1 28.3334091,3.01520965 28.3334091,5.50438854 L28.3334091,9.20202093 C28.3334091,11.6911998 22.887805,13.7080902 16.17156,13.7080902 C9.45531501,13.7080902 4.00971095,11.6911998 4.00971095,9.20202093 L4.00971095,5.50438854 C4.00971095,3.01520965 9.45531501,1 16.17156,1 Z M16.17156,15.8624653 C22.5835907,15.8624653 27.8611206,14.0237336 28.3233246,11.6891829 C28.346855,11.8017926 28.3334091,17.7969993 28.3334091,17.7969993 C28.3334091,20.2844975 22.887805,22.3030686 16.17156,22.3030686 C9.45531501,22.3030686 4.00971095,20.2861782 4.00971095,17.7969993 C4.00971095,17.7969993 3.98786131,11.8017926 4.00971095,11.6891829 C4.471915,14.0237336 9.75952932,15.8624653 16.17156,15.8624653 Z M28.3209716,20.3893758 L28.3344175,26.4955115 C28.3344175,28.9846903 22.8888135,30.9999 16.1708877,30.9999 C9.45464272,30.9999 4.00903866,28.9846903 4.00903866,26.4955115 L4.00903866,20.3893758 C4.47292345,22.7222457 9.75885702,24.5609774 16.1708877,24.5609774 C22.5845992,24.5609774 27.8604483,22.7222457 28.3209716,20.3893758 Z" id="Combined-Shape-Copy-5" fill="#FFFFFF"></path>
        </g>
      </g>
    </svg>
  </span>
);

Dbs = pure(Dbs);
Dbs.displayName = 'Dbs';
Dbs.muiName = 'SvgIcon';

export default Dbs;
