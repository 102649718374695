import Api from './api';
import { prepareRequest, prepareRequestWithExceptions, toUTC } from '../utils';
import PubSub from "pubsub-js";
/**
 * @class
 */

 const prepareVessel = prepareRequestWithExceptions(['yard', 'design', "disponentOwner", "registerClass"], val => val?._id ? val : ({name: val || ""}))
export default class Vessel extends Api {
  // Apiconstructor(data) {
  //   super(data);
  // }

  static endpoint = '/vessels';

  static async saveLastDryDockInfo(vesselId, data) {
    return Vessel.fetch(`/vessels/${vesselId}/maintenance`, { method: "PUT", body: JSON.stringify(data) });
  }

  static async getPreviousRoutes(vesselImo) {
    return Vessel.fetch(`vessels/${vesselImo}/recent-routes`, {method: 'GET', ignoreError:true}, "/api/v2/");
  }

  static async getComplianceInfo(vesselImo) {
    return Vessel.fetch(`vessels/${vesselImo}/compliance`, {
      method: 'GET',
    }, "/api/v2/");
  }

  static getMapPositionInfo(imoNumber) {
    return this.fetch(`vessels/map/${imoNumber}/basicInfo`, {
      method: "GET",
    }, "/api/v2/");
  }

  static getRelevantTcShareInfo({ _id, vesselIds }) {
    return this.fetch("/vessels/share/get-list-tc-order-relevant-vessels", {
      method: "POST",
      body: JSON.stringify({
        tcOrderId: _id,
        vesselIds,
      }),
    }, "/api/v2");
  }

  static getRelevantCargoShareInfo({ _id, vesselIds }) {
    return this.fetch("/vessels/share/get-list-cargo-relevant-vessels", {
      method: "POST",
      body: JSON.stringify({
        cargoRequestId: _id,
        vesselIds,
      }),
    }, "/api/v2");
  }

  static shareByEmail(data) {
    return this.fetch("/vessels/share/by-email", {
      method: "POST",
      body: JSON.stringify(data),
    }, "/api/v2");
  }

  static getShareInfo(vesselIds) {
    return this.fetch("/vessels/share/get-list", {
      method: "POST",
      body: JSON.stringify({
        vesselIds,
      }),
    }, "/api/v2");
  }

  static async getPreviousPorts(vesselImo) {
    return Vessel.fetch(`vessels/${vesselImo}/recent-ports`, {
      method: 'GET',
    }, "/api/v2/");
  }

  static async findRelevantOrders(_id, page, filters, sort) {
    if (!_id) return;
    return Vessel.fetch(`timecharters/relevant/orders?vesselId=${_id}`, {
      method: 'POST',
      body: JSON.stringify({
        page,
        filters,
        sort,
      }),
    }, "/api/v2/");
  }

  static async hideUnhideVessel(data) {
    return Vessel.fetch(`/deck/vessel/hide/toggle`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  }

  static async changePositionStatus(payload) {
    const data = {
      status: payload.positionStatus.status?.status || payload.positionStatus.status,
      // TODO:  may change in future, but for now hardcoded according to task 3501
      affectSimilar: true
    }
    const id = payload.positionId || payload.vesselRequestId;
    return this.fetch(`vessels/positions/${id}/setStatus`, {
      method: "POST",
      body: JSON.stringify(data)
    }, '/api/v2/')
  }
  static async changeVesselStatus({vesselId, vesselGroups}) {
    const data = {
      groups: vesselGroups.groups
    }
    return this.fetch(`vessels/${vesselId}/setGroups`, {
      method: "POST",
      body: JSON.stringify(data)
    }, '/api/v2/')
  }
  static async saveDisponentOwner(vesselId, data) {
    return Vessel.fetch(`/vessels/${vesselId}/disponentOwner`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  static async addAdditionalDesc(vesselId, data) {
    try {
      return Vessel.fetch(`/vessels/${vesselId}/additionalDescription`, {
        method: 'PUT',
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error(error);
    }
  }

  static async printPdf(data) {
    return Vessel.fetch('/vessels/printPdf', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static async setChangesSeen(id, docVersion) {
    return Vessel.fetch(`/vessel-changes/${id}/set-as-seen/${docVersion}`, {
      method: 'POST',
    });
  }

  static async getVesselFilters() {
    return Vessel.fetch('/preferences/byType/deckVesselFilter', {
      method: 'GET',
    });
  }

  static async saveVesselFilter(name, body) {
    return Vessel.fetch(`/preferences/deckVesselFilter/${name}`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async updateVesselFilter(filterId, formData) {
    return Vessel.fetch(`/preferences/${filterId}`, {
      method: 'PUT',
      body: JSON.stringify(formData),
    });
  }

  static async deleteVesselFilter(filterId) {
    return Vessel.fetch(`/preferences/${filterId}`, {
      method: 'DELETE'
    });
  }
  static async getSuitableVesselFilters() {
    return Vessel.fetch('/preferences/byType/suitableFleetFilter', {
      method: 'GET',
    });
  }

  static async saveSuitableVesselFilter(name, body) {
    return Vessel.fetch(`/preferences/suitableFleetFilter/${name}`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async getVesselRequestList({ filters, page, sort = { field: 'status.weight', value: '-1' } }, token) { // eslint-disable-line no-empty-pattern
    return Vessel.fetch('/requests/my/vessels', { method: 'POST', token, body: JSON.stringify({ sort, filters, page }) });
  }

  static async getVesselTcOfferList({ page, limit, sort, filters }, token) { // eslint-disable-line no-empty-pattern
    return await Vessel.fetch('/timecharts/requests', {
      method: 'POST',
      token,
      body: JSON.stringify({ page, limit, sort, filters }),
    });
  }

  static async getSentTcOfferList({ page, limit, sort, filters }, token) { // eslint-disable-line no-empty-pattern
    return await Vessel.fetch('/timecharts/offers', {
      method: 'POST',
      token,
      body: JSON.stringify({ page, limit, sort, filters }),
    });
  }

  static async getTcList({ page, limit, sort, filters }, token) {
    return await Vessel.fetch('/timecharts/bids', {
      method: 'POST',
      token,
      body: JSON.stringify({ page, limit, sort, filters }),
    });
  }

  static async getVesselList({
    filters = {},
    sort = { field: 'addedAt', value: -1 },
    page = 1,
    limit = 50,
    hidden,
  }) {
    const params = {
      filters: { ...filters, type: undefined, typeCheckbox: undefined },
      sort,
      page,
      limit,
      hidden,
    };
    if (params.filters.openFrom) {
      params.filters.openFrom = toUTC(params.filters.openFrom);
    }
    if (params.filters.openUntil) {
      params.filters.openUntil = toUTC(params.filters.openUntil);
    }
   // debugger
    return Vessel.fetch('/deck/vessel', {
      method: 'POST',
      body: JSON.stringify(params),
    });
  }

  static sendRequest = async function (request = {}) {
    return Vessel.fetch('/requests/vessel', {
      method: 'POST',
      body: JSON.stringify(request),
    });
  }

  static getChannels = async function (vesselId) {
    return Vessel.fetch(`/monitor/vessel/${vesselId}/channels`, {
      method: 'GET',
    }).then((res) => {
      try {
        if (res.data) {
          res.data.sort((a, b) => a.open.from - b.open.from);
        }
      } catch (e) {
        console.error(e);
      }
      return res;
    });
  };

  static async getMonitorVesselById(_id, cargoRequestId, token) {
    if(cargoRequestId && cargoRequestId.length !== 24) {
      cargoRequestId = undefined;
    }
    return Vessel.fetch(`/deck/vessel/${_id}${cargoRequestId ? '?cargoRequest=' + encodeURIComponent(cargoRequestId) : ''}`, { method: 'GET', token })
      .then(res => {
        if(res?.data?.channels) {
          res.data.channels = res.data.channels.filter(c => !!(c.company || c.realUser))
        }
        return res;
      });
  }
  static async getMonitorVesselByIdOld(_id, vesselId, cargoId) {
    const vesselRes = (await this.getMonitorVesselById(vesselId,cargoId)).data;
    const channel = vesselRes.channels.find(c => c._id === _id);
    return {data:channel};
  }

  static async sendFreightRequest(request) {
    const _request = {
      ids: request.ids,
      message: request.message,
      comm: request.comm,
      deductible: request.deductible,
      shareEmails: request.shareEmails,
      attachments: request.attachments,
      additionalMessage: request.additionalMessage,
      pus: request.pus,
    };
    return Vessel.fetch('/requests/freight/' + request.cargo, {
      method: 'POST',
      body: JSON.stringify(_request),
    });
  }

  static find(params) {
    params.query = params.name;
    return Api.find.call(this, params).then((res) => {
      return res;
    });
  }

  static async getDictionaryVesselById(id) {
    const res = await this.getMonitorVesselById(id);
      const vessel = { // i love schemaless backend
        ...{
          fitted: {},
          dimensions: {},
          capacity: {},
          holds: {},
          hatches: {},
          areas: {},
          speeds: {},
          dw: {},
        },
        ...res.data.vessel,
      };
      return { data: vessel };

  }

  static getDictionaryVesselByIdOld(id) {
    return Vessel.fetch('/vessels/' + id, { method: 'GET' }).then((res) => {
      const vessel = { // i love schemaless backend
        ...{
          fitted: {},
          dimensions: {},
          capacity: {},
          holds: {},
          hatches: {},
          areas: {},
          speeds: {},
          dw: {},
          tonnage: {},
        },
        ...res.data,
      };
      if (!vessel.tonnage.gt && vessel.rt?.gt) {
        vessel.tonnage.gt = vessel.rt.gt;
      }
      return { data: vessel };
    });
  }

  static getPublicVessel(idOrImo) {
    return Vessel.fetch('/fleet/public/' + idOrImo, { method: 'GET' }, '/api/v2');
  }

  static async getVesselByIMO(request = {}) {
    return Vessel.fetch('/vessels/find', {
      method: 'POST',
      body: JSON.stringify(request),
    });
  }

  static async uploadImg(request = {}) {
    return Vessel.fetch('/file/upload', {
      headers: {},
      method: 'POST',
      body: request,
    });
  }

  static async addNewVessel(request = {}) {
    let { disponentOwner } = request;
    const prepared = prepareVessel(request);
    prepared.disponentOwner = disponentOwner;
    return Vessel.fetch('/vessels', {
      method: 'POST',
      body: JSON.stringify(prepared),
    });
  }

  static async updateVesselById(vesselId, request) {
    let { disponentOwner } = request;
    const prepared = prepareVessel(request);
    prepared.disponentOwner = disponentOwner;
    return Vessel.fetch(`/vessels/${vesselId}`, {
      method: 'PUT',
      body: JSON.stringify(prepared),
    });
  }

  static async updateExclusionsAndRestrictions(vesselId, {cargoExclusionsV2, tradingRestrictions}) {
    return Vessel.fetch(`/vessels/${vesselId}/exclusions`, {
      method: 'PUT',
      body: JSON.stringify(prepareRequest({cargoExclusionsV2, tradingRestrictions})),
    });
  }
  static async updatePreferences(vesselId, {cargoPreferences, areaPreferences}) {
    return Vessel.fetch(`/vessels/${vesselId}/preferences`, {
      method: 'PUT',
      body: JSON.stringify(prepareRequest({cargoPreferences, areaPreferences})),
    });
  }
  static async updateSpeedConsumption(vesselId, payload) {
    return Vessel.fetch(`/vessels/${vesselId}/speedConsumptions`, {
      method: 'PUT',
      body: JSON.stringify(prepareRequest(payload)),
    });
  }
  static async updateAttachments(vesselId, {attachments,attachmentVisibility=[]}) {
    return Vessel.fetch(`/vessels/${vesselId}/attachments`, {
      method: 'PUT',
      body: JSON.stringify(prepareRequest({attachments,attachmentVisibility})),
    });
  }

  static getMyVessels = async function ({sort, filters}, token) {
    // TODO: CHANGE METHOD TO POST AND ADD SORT TO BODY
    if (filters.openFrom) {
      filters.openFrom = toUTC(filters.openFrom);
    }
    if (filters.openUntil) {
      filters.openUntil = toUTC(filters.openUntil);
    }
    return Vessel.fetch('/vessels/fleet', { method: 'POST', token, body: JSON.stringify({sort, filters}) });
  }

  static async getPositionsByVesselId(id) {
    return Vessel.fetch('/vessels/position/' + id, { method: 'GET' });
  }

  static async deletePositionById(vesselId, positionId) {
    return Vessel.fetch(`/vessels/position/${vesselId}/${positionId}`, {
      method: 'DELETE',
    });
  }

  static async updatePositionById(vesselId, positionId, request) {
    return Vessel.fetch(`/vessels/position/${vesselId}/${positionId}`, {
      method: 'PUT',
      body: JSON.stringify(request),
    });
  }

  static findRelevantCargoes(_id, page = 1, filters={},sort) {
    if(filters.readinessDate){
      const year = filters.readinessDate.getFullYear();
      const month = String(filters.readinessDate.getMonth() + 1).padStart(2, '0');
      const day = String(filters.readinessDate.getDate()).padStart(2, '0');
      filters.readinessDate = `${year}-${month}-${day}`;
    }

    if(filters.cancellingDate){
      const year = filters.cancellingDate.getFullYear();
      const month = String(filters.cancellingDate.getMonth() + 1).padStart(2, '0');
      const day = String(filters.cancellingDate.getDate()).padStart(2, '0');
      filters.cancellingDate = `${year}-${month}-${day}`;
    }
    if (filters.weightMin) {
      filters.weightMin *= 1000;
    }
    if (filters.weightMax) {
      filters.weightMax *= 1000;
    }
    let qs = `?page=${page}&filters=${encodeURIComponent(JSON.stringify(filters))}&sort=${encodeURIComponent(JSON.stringify(sort))}`;
    if(filters.tab === 'archive') {
      qs+='&archive=true'
    }
    return Vessel.fetch('/deck/vessel/' + _id + '/relevant'+qs, { method: 'GET' });
  }

  static claimRights(_id) {
    return Vessel.fetch(`/vessels/${_id}/claim`, { method: 'POST' });
  }

  static getRequestInfo(vesselRequest, cargoRequest) {
    return Vessel.fetch('/requests/info', { method: 'POST', body: JSON.stringify({ vesselRequest, cargoRequest }) });
  }
  static getAllTerms() {
    return Vessel.fetch('/terms/time');
  }

  static async getLocation(imo) {
    const data = (await Vessel.fetch(`/vessels/location/${imo}`, { method: 'GET', ignoreError: true })) || {};
    const { data: previousRoutes } = ((await this.getPreviousRoutes(imo)) || {});
    data.previousRoutes = previousRoutes;
    setTimeout(() => PubSub.publish('vessel:posUpdated', data), 0);
    return data;
  }

  static async getConsumptions(_id) {
    if (typeof window === 'undefined' || !window.localStorage) {
      return;
    }
    return JSON.parse(window.localStorage.getItem('consumptions') || '{}')[_id];
  }
  static async saveConsumptions(_id, consumptions) {
    if (typeof window === 'undefined' || !window.localStorage) {
      return;
    }
    const consumptionsStorage = JSON.parse(window.localStorage.getItem('consumptions') || '{}');
    consumptionsStorage[_id] = consumptions;
    window.localStorage.setItem('consumptions', JSON.stringify(consumptionsStorage));
  }

  static async deleteVessel(vessel) {
    return Vessel.fetch(`/vessels/fleet/${vessel._id}`, {
      method: 'DELETE',
    });
  }
  static getOriginalMail(_id){
    return Vessel.fetch(`/requests/fetchOriginalMail/vessel/${_id}`);
  }

  static async saveVesselRating(body) {
    return Vessel.fetch(`/rating/vessel`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  static async saveVesselComment(body) {
    return Vessel.fetch(`/comments`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  static async getPreviousRating({id}) {
    return Vessel.fetch(`/rating/vessel/${id}`, {
      method: 'GET',
    });
  }
  static async getComments({entityId, entityType = "vessel", page = 1, limit = 5, sort = -1, isPrivate = false}) {
    return Vessel.fetch(`/comments?entityId=${entityId}&entityType=${entityType}&page=${page}&limit=${limit}&sorted=${sort}&isPrivate=${isPrivate}`, {
      method: 'GET',
    })
  }
  static async saveTCIndex(id, body) {
    return Vessel.fetch(`/vessels/${id}/tcindex`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  // body: { hireIdea : Number}
  static async saveHireIdea(id, body) {
    return Vessel.fetch(`/vessels/${id}/hireidea`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }

  static async markAsForSale(id, body) {
    return Vessel.fetch(`/vessels/forSale/${id}`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }

  static async getForSaleMail(id) {
    return Vessel.fetch(`/mails/${id}`, {
      method: "GET"
    })
  }

  static async getForSaleItem({filters, id}) {
    return Vessel.fetch(`/vessels/forSale/${id}/item`, {
      method: 'POST',
      body: JSON.stringify({filters})
    })
  }

  static async getForSaleList({sort, filters, page = 1, limit = 50, hidden}) {
    return Vessel.fetch(`/vessels/forSale?sortField=${sort.field}&sortValue=${sort.value}&page=${page}&limit=${limit}`, {
      method: "POST",
      body: JSON.stringify({filters, hidden})
    })
  }
  static async getOffersByVessel(id) {
    return Vessel.fetch(`/vessels/forSale/${id}/offers`, {
      method: "GET"
    })
  }
  static async deleteOfferByVessel(id) {
    return Vessel.fetch(`/vessels/forSale/${id}`, {
      method: "DELETE"
    })
  }
  static async updateForSaleOffer(id, body) {
    return Vessel.fetch(`/vessels/forSale/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(body)
    });
  }
  static async addToSelected(body) {
    return Vessel.fetch(`/preferences/selectedVessels`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async updateHiddenOffers(body) {
    return Vessel.fetch(`/vessels/forSale/hide/toggle`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    });
  }

  static async findTcIndex({ vesselId, regionFrom, regionTo }) {
    function fixRegion(region) {
      if (region && typeof region === 'string') {
        return [{ _id: region }];
      }
      return region;
    }
    regionFrom = fixRegion(regionFrom);
    regionTo = fixRegion(regionTo);
    return Vessel.fetch(`/vessels/tcIndex/find`, {
      method: 'POST',
      body: JSON.stringify({ vesselId, regionFrom, regionTo }),
    }, '/api/v2');
  }

  static async updateTcIndex(body) {
    return Vessel.fetch(`/vessels/tcIndex`, {
      method: 'POST',
      body: JSON.stringify({ "delete": body.delete, update: body.update.map(prepareRequest) }),
    }, '/api/v2');
  }

  static async updateFixedRate(body) {
    return Vessel.fetch(`/vessels/tcIndex/fixed-rate`, {
      method: 'POST',
      body: JSON.stringify({ "delete": body.delete, update: body.update.map(prepareRequest) }),
    }, '/api/v2');
  }

  static async getAllMapData(body = {}) {
    return Vessel.fetch(`/vessels/map/all`, {
      method: 'POST',
      body: JSON.stringify(body),
    }, '/api/v2');
  }
  static async getRegisterClassById(id) {
    return Vessel.fetch(`/register-class/${id}`, {
      method: 'GET',
    }, '/api/v1');
  }
}

class FetchableField {
  constructor(endpoint, fieldName, api) {
    this.endpoint = endpoint;
    this.fieldName = fieldName;
    this.api = api;
  }

  find({ name, ...restOptions }) {
    return this.api.find.call(this, { [this.fieldName]: name, ...restOptions }).catch(console.error);
  }
}

const createFetchableField = (endpoint, fieldName = 'query', api = Api) => {

  const field = new FetchableField(endpoint, fieldName, api);

  return field;
  // return {
  //   endpoint,
  //   find({ name }) {
  //     return Api.find.call(this, { [fieldName]: name }, ...rest).then((res) => {
  //       return res;
  //     });
  //   }
  // }
}


export const piClub = createFetchableField("/pi-club");
export const builderYard = createFetchableField("/yards");
export const registerClass = createFetchableField("/register-class");
export const vesselDesign = createFetchableField("/vesselDesign", 'name');
export const hsCode = createFetchableField("/hsCodes", 'code', {
  find({ code }) {
    code = code.trim()
    const onlyDigits = /^\d+$/.test(code)
    const params = onlyDigits ? { code } : { text: code }

    return Api.find.call(this, params, '/api/v2').then(res => ({
      ...res,
      data: res.data.map(hsCode => ({ ...hsCode, name: hsCode.code }))
    }));
  }
});

