import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {date, formatNumberOrEmpty, formatRange, number} from '../../../core/format';
import Label from './Label';
import Row from './Row';
import Value from './Value';
import { Text } from './../../Contracts/shared/styled';
import ShipLink from '../../Common/ShipLink';
import VesselPreviewNew from '../../Vessel/Preview/VesselPreviewNew';
import {
  ACCOUNT,
  BROKER,
  CARRIER,
  CHARTERER,
  MANAGER,
  MERCHANT,
  NOTIFY,
  OPERATOR,
  OWNER,
  RECEIVER,
  SHIPPER,
} from './../Request/CounteragentTypes';
import memoizeOne from 'memoize-one';
import PortLink from '../../Common/PortLink';
import styled from 'styled-components';
import Err from './Err';
import { getPreviewErrorMsg } from '../utils';
import {
  CargoPreviewWithStyles as CargoPreview,
  PackingListDialog,
  Terms as TermsList,
} from '../../Cargo/Preview/Preview';
import Collapse from '../../Common/Collapse';
import s from '../../Common/Collapse.scss';
import { dischargingRateTypes, LDRATE, loadingRateTypes, LRATEDAYS, LRATEHOURS } from '../LoadingDischargingRate';
import { staticPopup } from '../../../actions/runtime';
import ContractLink from '../../Common/ContractLink';
import fieldsConfig from './../fields';
import lodashCloneDeep from 'lodash/cloneDeep';
import Attachments from '../../Cargo/Preview/Attachments';
import {formatLaycanType} from "../../Common/LayCanTypeSelect";
import Diff from "../../Common/Diff";
import {constructCargoTitle} from "../constructCargoTitle";

const normalizedSpreadFields = fieldsConfig.map(field => ({ ...field, stowage: field.stowage.map(stowageField => stowageField.spread ? [stowageField, ...stowageField.spread] : stowageField).flat() }));

const ldTypeNameMap = [...loadingRateTypes, ...dischargingRateTypes].reduce((acc, t) => { acc[t.value] = t.previewLabel; return acc; }, {});

function formatLdType(type) {
  return ldTypeNameMap[type] || '';
}

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  line-height: 26px;
  font-size: 12px;
  .row {
    flex-wrap: ${props => props.wrap ? 'wrap' : 'nowrap'};
  }
  .preview_value {
    min-width: 0;
    flex: 1 1 auto;
  }
  .preview_label {
    min-width: 162px;
    max-width: 162px;
  }
  .preview_label_offline_tc {
    min-width: 200px;
    max-width: 200px;
  }
  a {
    color: var(--text-links);
    text-decoration: ${props => props.disableUnderline ? 'unset' : 'underline !important'};
  }
`;

const StyledCollapse = styled(Collapse)`
    &.${s.collapse_wrapper} {
      .${s.collapse_header} {
        border-bottom: 1px solid var(--stroke-light-gray1);
        .${s.left} {
          min-width: 0;
          flex: 0 1 80%;
          padding-right: 24px;
          .${s.title} {
            width: 98%;
            div {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
          }
        }
      }
    }
`;

export const PlaceDate = ({ place, date: dateToDisplay, ...rest }) => {
  const dateFormat = dateToDisplay && date(dateToDisplay);

  return (
    <Row {...rest}>
      <Label>
        PLACE & DATE
      </Label>
      {
        place || dateToDisplay
          ? (
            <Value style={{ display: 'flex' }}>
              <Text>
                {
                  place
                }
              </Text>
              &nbsp;
              <Text>
                {
                  dateFormat
                }
              </Text>
            </Value>
          )
          : (
            <Value>
              <Text />
            </Value>
          )
      }
    </Row>
  );
};

export const RefNo = ({ refNo, ...rest }) => (
  <Row {...rest}>
    <Label>
      REF NO
    </Label>
    <Value>
      <Text>
        {
          refNo
        }
      </Text>
    </Value>
  </Row>
);

export class Ship extends React.Component {

  static contextTypes = {
    addToRightPart: PropTypes.func,
    removeFromRightPart: PropTypes.func,
    right: PropTypes.array,
  }

  handleCloseVessel = () => {
    const { ship } = this.props;
    if (typeof ship !== 'object') return;

    const shipId = ship?._id;
    if (this.props.dialogPortId) {
      this.props.setPortDialogId({ id: null, portUid: null });
    }
    this.context.removeFromRightPart(shipId);
  }
  openVesselInfoInRightPart = () => {
    const { ship } = this.props;

    if (typeof ship !== 'object') return;

    const shipId = ship?._id;

    const isAlreadyOpen = this.context.right.find(renderer => renderer.id === shipId);
    if (this.props.dialogPortId) {
      this.props.setPortDialogId({ id: null, portUid: null });
    }
    if (isAlreadyOpen) return;

    this.context.addToRightPart({
      id: shipId,
      render: sectionInstance => (
        <VesselPreviewNew
          monitor
          shouldScrollInside
          vesselId={shipId}
          handleClose={this.handleCloseVessel}
          displayCommonHeader
        />
      ),
    });
  }

  render() {

    const { ship, details, tcLabelStyle = {}, ...rest } = this.props;

    const isExistyInDB = typeof ship === 'object' && Object.prototype.hasOwnProperty.call(ship, '_id');

    let content = null;

    if (isExistyInDB) {
      const { name, _id } = ship;

      content = (
        <div>
        <ShipLink
          onMouseUp={ev => {
            const isFollowingLink = [0, 1].includes(ev.button);
            if (isFollowingLink) {
              this.openVesselInfoInRightPart();
            }
          }}
          onKeyPress={ev => {
            const isEnter = ev.which === 13 || ev.keyCode === 13;
            if (isEnter) {
              this.openVesselInfoInRightPart();
            }
          }}
          target="_blank"
          id={_id}
        >
          {name}
        </ShipLink>
          {/* {
            details && (
              <Text>{ ` - ${details}` }
              </Text>
            )
          } */}
        </div>
      );
    }

    else {
      const name = typeof ship === 'object' ? ship.name : ship;
      content = (
        <Col>
          <Text>
            {
              name
            }
          </Text>
          {/* {
            details && (
              <Text>
                { ` - ${details}` }
              </Text>
            )
          } */}
        </Col>
      );
    }

    return (
      <Row {...rest}>
        <Label style={tcLabelStyle}>
          VESSEL
        </Label>
        <Value>
          {
            content
          }
        </Value>
      </Row>
    )
  }
}

export const Counteragent = ({ counteragent = {}, ...rest }) => {

  if (!counteragent?.company) {
    return null;
  }

  const companyName = typeof counteragent.company === 'object' ? counteragent.company.name : counteragent.company;

  return (
    <Row {...rest}>
      <Label>
        <Text fontWeight="inherit">
          {
            counteragent.type
          }
        </Text>
      </Label>
      <Value>
        <Text>
          {
            companyName
          }
        </Text>
        {counteragent.details && <Text>
          {
            counteragent.details
          }
        </Text>}
      </Value>
    </Row>
  )
}

export const counteragentsSortPriority = {
  [ACCOUNT.value]: 9999,
  [CARRIER.value]: 9998,
  [CHARTERER.value]: 9997,
  [MERCHANT.value]: 9996,
  [BROKER.value]: 9995,
  [SHIPPER.value]: 9994,
  [RECEIVER.value]: 9993,
  [MANAGER.value]: 9992,
  [OWNER.value]: 9991,
  [OPERATOR.value]: 9990,
  [NOTIFY.value]: 9989,
};

export const sortCounteragents = memoizeOne(counteragents => counteragents.sort((a, b) => {
  const aPriority = counteragentsSortPriority[a.type];
  const bPriority = counteragentsSortPriority[b.type];
  // sort in descending (bigger to lower) according to priorities
  return bPriority - aPriority;
}))

export const Counteragents = ({ counteragents, ...rest }) => {

  counteragents = Array.isArray(counteragents) && sortCounteragents(counteragents).map(agent => agent?._id ? agent : ({ ...agent, _id: uuidv4() }));

  if (!counteragents?.length) return null;

  return (
    <div {...rest}>
      {
        counteragents.map(counteragent => <Counteragent key={counteragent._id} counteragent={counteragent} />)
      }
    </div>
  );
};

export const parsePorts = memoizeOne(ports => {
  // parsed represents a multi-dimensional array, containing arrays, each array inside parsed array represents AND condition,\
  // and each obj inside nested array represents OR condition
  const parsed = [];

  for (const condition of ports) {
    if (condition.oneOf?.length) {
      parsed.push([...condition.oneOf]);
    }
  }

  return parsed;
});

const renderPortDates = (port) => {

  let content = '';

  if (port.readinessDate) {
    content = `${formatLaycanType(port.layCanType)}: ${date(port.readinessDate)}`;
    if (port.cancellingDate) {
      content += ` / `;
    }
  }

  if (port.cancellingDate) {
    content += `${date(port.cancellingDate)}`;
  }

  if (!content) return null;

  return (
    <Text isBold>
      {
        content
      }
    </Text>
  )
};

const renderPortErrors = (readinessDateError, cancellingDateError) => {
  return (
    <Err>
      {
        `${readinessDateError} ${readinessDateError && cancellingDateError ? '/' : ''} ${cancellingDateError}`
      }
    </Err>
  )
}

const CommonPortsInfo = ({ parsedPorts, errors = {} }) => {
  if (!parsedPorts?.length) return null;

  return (
    <Value>
      {
        parsedPorts.map((orPorts, j) => (
          <Col key={orPorts.id}>
            {
              orPorts.map((port, i) => {

                const isPortErrorMsg = getPreviewErrorMsg(errors.port, i + j);
                const isReadinessDateErrorMsg = getPreviewErrorMsg(errors.readinessDate, i + j);
                const isCancellingDateErrorMsg = getPreviewErrorMsg(errors.cancellingDate, i + j);

                return (
                  <Col key={port.id}>
                    <Text>
                      {
                        i > 0 && <span>OR&nbsp;</span>
                      }
                      {
                        isPortErrorMsg
                          ? <Err>{isPortErrorMsg}</Err>
                          : <PortLink port={port.port} />
                      }
                    </Text>
                    {
                      isReadinessDateErrorMsg || isCancellingDateErrorMsg
                        ? renderPortErrors(isReadinessDateErrorMsg, isCancellingDateErrorMsg)
                        : renderPortDates(port, errors)
                    }
                    {
                      port.details
                    }
                  </Col>
                )
              })
            }
          </Col>
        ))
      }
    </Value>
  )
}

export const PortsInfo = ({ loadingPorts, loadingPortsErrors, dischargingPortsErrors, dischargingPorts, ...rest }) => {
  const parsedLoadingPorts = parsePorts(loadingPorts);
  const parsedDischargingPorts = parsePorts(dischargingPorts);

  return (
    <Col>
      <Row>
        <Label>
          PORT OF LOADING
        </Label>
        <CommonPortsInfo errors={loadingPortsErrors} parsedPorts={parsedLoadingPorts} {...rest} />
      </Row>
      <Row>
        <Label>
          PORT OF DISCHARGE
        </Label>
        <CommonPortsInfo errors={dischargingPortsErrors} parsedPorts={parsedDischargingPorts} {...rest} />
      </Row>
    </Col>
  );
};
const getCargoErrorsByIndex = (errors = {}, index) => {
  if (!errors) return {};
  const cargoNameError = getPreviewErrorMsg(errors?.cargoName, index);
  const totalWeightError = getPreviewErrorMsg(errors?.unitsWeight, index);
  const totalVolumeError = getPreviewErrorMsg(errors?.unitsVolume, index);

  return {
    cargoNameError,
    totalWeightError,
    totalVolumeError,
  };
};

const renderError = (errors, i) => {
  const { cargoNameError, totalWeightError, totalVolumeError } = getCargoErrorsByIndex(errors, i || 0);
  if (!cargoNameError && !totalWeightError && !totalVolumeError) return null;

  return (
    <Err>
      {
        cargoNameError
      }
      {
        (totalWeightError || totalVolumeError) ? ', ' : null
      }
      {
        totalWeightError || totalVolumeError
      }
    </Err>
  );
};


const CargoTitle = ({ cargo, errors: { cargoNameError, totalWeightError, totalVolumeError } = {} }) => {
  let title;
  if (cargoNameError) {
    title = <Err>{cargoNameError}</Err>;
  } else {
    title = constructCargoTitle(cargo);
  }
  const cargoInfoPart =
    totalVolumeError || totalWeightError
      ? <Err>{totalVolumeError || totalWeightError}</Err>
      : (
        <span>
          {title}
        </span>
      );

  return (
    <Text ellipsis fontWeight="inherit">
      <div>
        {cargoInfoPart}
      </div>
    </Text>
  )
}
const cargoTitleStyles = {
  fontWeight: '700',
  fontSize: '12px',
  textTransform: 'initial',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
};

const CargoRow = ({ cargoObj, i, errors = {} }) => {
  const { cargoNameError, totalWeightError, totalVolumeError } = getCargoErrorsByIndex(errors, i);
  const copy = lodashCloneDeep(cargoObj);

  const isSpread = copy?.packing?.pack?.stowageSpread;

  if (copy?.packing?.pack) {
    if (!isSpread) {
      Reflect.deleteProperty(copy.packing.pack.unitsWeight || {}, 'valueMax');
      Reflect.deleteProperty(copy.packing.pack.unitsWeight || {}, 'valueMin');
      Reflect.deleteProperty(copy.packing.pack.unitsVolume || {}, 'valueMax');
      Reflect.deleteProperty(copy.packing.pack.unitsVolume || {}, 'valueMin');
    }
    if (!copy.packing.pack.sf) {
      Reflect.deleteProperty(copy.packing.pack, 'sfUnit');
    }
  }


  return (
    <StyledCollapse
      titleStyle={cargoTitleStyles}
      title={<CargoTitle cargo={copy} errors={{ cargoNameError, totalWeightError, totalVolumeError }} />}
      additionals={<span style={{ ...cargoTitleStyles, whiteSpace: 'nowrap', flex: 'unset' }}>HS Code:&nbsp;<Text ellipsis fontWeight="inherit">{cargoObj?.hsCode?.code}</Text></span>}
      defaultOpen={false}
    >
      {
        cargoObj?.cargoName
          ? (
            <CargoPreview
              fields={normalizedSpreadFields}
              index={i}
              errors={errors ? Object.keys(errors).filter(key => Array.isArray(errors[key]) ? errors[key].find(err => err.index == i) : errors[key].index == i).reduce((acc, key) => {
                const error = errors[key];
                if (error) {
                  const errorMsg = Array.isArray(error) ? error.find(err => err.index == i).config.error : error.config.error;

                  acc[key] = errorMsg;
                }
                return acc;
              }, {}) : null}
              cargo={copy}
            />
          )
        : renderError(errors, i)
      }
      {
        copy.packingList?.length ? <PackingListDialog data={copy.packingList} /> : null
      }
    </StyledCollapse>
  )
}

export const CargoInfo = ({ cargo, errors = {},files , ...rest }) => {
  let content = null;

  if (!cargo?.length) {
    content = renderError(errors);
  }
  else {
    content = cargo.filter(c => !!c).map((cargoObj, i) => {
      return <CargoRow key={cargoObj._id} errors={errors} i={i} cargoObj={cargoObj} />;
    });
  }
  return (
    <Row style={{ flexWrap: 'nowrap' }}>
      <Label>
        CARGO
      </Label>
      <Value style={{ flexShrink: 1, maxWidth: 'calc(100% - 170px)' }}>
        {
          content
        }
        <Attachments attachments={files} />
      </Value>
    </Row>
  )
};

const Rate = ({ value, types = [], label, prevValue = {} }) => {

  if (!value) return null;

  let content = ``;

  if (value.type || prevValue.type) {
    const parts = [];
    const type = formatLdType(value.type);
    const prevType = formatLdType(prevValue.type);
    if (type || prevType) {
      parts.push(<Diff newValue={type} oldValue={prevType} />);
    }
    const secondPart = [];
    if (value.quantity || prevValue.quantity) {
      if (parts.length) {
        parts.push(' ');
      }
      parts.push(<Diff newValue={formatNumberOrEmpty(value.quantity, '')} oldValue={formatNumberOrEmpty(prevValue.quantity,'')} />);
      if ((value.dimension || prevValue.dimension)) {
        parts.push(' ');
        parts.push(<Diff newValue={value.dimension || ''} oldValue={prevValue.dimension || ''} />);
      }
    }
    if (value.speed || prevValue.speed) {
      secondPart.push(' ');
      secondPart.push(<Diff newValue={value.speed || ''} oldValue={prevValue.speed || ''} />);
    }
    if (value.abbreviation || prevValue.abbreviation) {
      secondPart.push(' ');
      secondPart.push(<Diff newValue={value.abbreviation || ''} oldValue={prevValue.abbreviation || ''} />);
    }
    if (secondPart.length) {
      if (parts.length) {
        parts.push(', ');
      }
      parts.push(...secondPart);
    }

    if (value.term || prevValue.term) {
      if (secondPart.length || parts.length) {
        parts.push(', ');
      }
      parts.push(<Diff className="uppercased" newValue={value.term || ''} oldValue={prevValue.term || ''} />);
    }
    content = parts;
  }

  return (
        <Text>
          { label }
          {
            content
          }
        </Text>
  )
}

const loadingEmptyLabel = { [LDRATE.value]: 1, [LRATEDAYS.value]: 1, [LRATEHOURS.value]: 1 };

function addEmptyMarkToLdLabel(label = '', rate, prevRate = rate){
  if (label && !rate.type && !prevRate.type) {
    label += '---';
  }
  return label;
}

export function LDRatesView({ loading = {}, discharging = {}, prevLoading = loading, prevDischarging = discharging, ...rest }) {
  loading = { ...loading };
  let loadingLabel = loadingEmptyLabel[loading?.type] ? '' : 'Loading: ';
  if (loading.type === LRATEDAYS.value || loading.type === LRATEHOURS.value) {
    loading.dimension = undefined;
  }
  let prevLoadingLabel;
  let prevDischargingLabel;
  prevLoadingLabel = loadingEmptyLabel[prevLoading?.type] ? '' : 'Loading: ';
  prevDischargingLabel = prevLoadingLabel ? 'Discharging: ' : '';
  if (prevLoading.type) {
    if (prevLoading.type === LRATEDAYS.value || prevLoading.type === LRATEHOURS.value) {
      prevLoading.dimension = undefined;
    }
  }
  let dischargingLabel = loadingLabel ? 'Discharging: ' : '';
  loadingLabel = addEmptyMarkToLdLabel(loadingLabel, loading, prevLoading);
  prevLoadingLabel = addEmptyMarkToLdLabel(prevLoadingLabel, loading, prevLoading);
  dischargingLabel = addEmptyMarkToLdLabel(dischargingLabel, discharging, prevDischarging);
  prevDischargingLabel = addEmptyMarkToLdLabel(prevDischargingLabel, discharging, prevDischarging);

  let content = '';

  if (!loading && !discharging) {
    content = (
      <Value>
        <Text />
      </Value>
    )
  }
  else {
    content = (
      <Value>
        <Rate label={<Diff newValue={loadingLabel} oldValue={prevLoadingLabel}/>} types={loadingRateTypes} value={loading} prevValue={prevLoading} />
        {
          loadingLabel || prevLoadingLabel
            ? <Rate label={<Diff newValue={dischargingLabel} oldValue={prevDischargingLabel}/>} value={discharging} types={dischargingRateTypes} prevValue={prevDischarging} />
            : null
        }
      </Value>
    );
  }

  return (
    <Row>
      <Label>
        L/D RATES
      </Label>
      {
        content
      }
    </Row>
  )
}

export class Terms extends React.Component {
  static contextTypes = {
    store: PropTypes.object,
    indexedVoyageTerms: PropTypes.array,
  };

  openTermsDetails = (termsLink) => {
    this.context.store.dispatch(staticPopup(termsLink, true));
  };

  render() {

    const { terms, tcLabelStyle = {}, ...rest } = this.props;

    return (
      <Row>
        <Label style={tcLabelStyle}>Terms</Label>
        <div style={{ width: '100%' }}>
          {
            terms?.user?.length || terms?.default?.length
              ? (
                <TermsList
                  headerStyle={{ height: '32px' }}
                  openTermsDetails={this.openTermsDetails}
                  terms={terms}
                  indexedTerms={this.context.indexedVoyageTerms}
                  {...rest}
                />
              )
              : <Value><Text /></Value>
          }
        </div>
      </Row>
    )
  }
}

// eslint-disable-next-line react/prefer-stateless-function
export class Contract extends React.Component {

  static contextTypes = {
    contracts: PropTypes.array,
  };

  render() {
    const { contractId, tcLabelStyle = {}, ...rest } = this.props;

    let content = '';

    if (contractId) {
      content = <ContractLink contract={this.context.contracts.find(c => c._id === contractId)} {...rest} />
    }

    return (
      <Row>
        <Label style={tcLabelStyle}>
          CONTRACT
        </Label>
        <Value>
          <Text>
            {content}
          </Text>
        </Value>
      </Row>
    )
  }
}

export const Commission = ({ commission, deductible, pus, ...rest }) => (
  <Row {...rest}>
    <Label>
      COMMISSION
    </Label>
    <Value>
      <Text>
        {number(commission, '', '%')}
        {
          pus ? ` PUS` : ''
        }
        {
          deductible ? ` Deductable` : ''
        }
      </Text>
    </Value>
  </Row>
)
