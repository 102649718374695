import React, { Component } from 'react'
import NarrowFormsyText from '../NewInputs/NarrowFormsyText';
import styled from 'styled-components';
import IconButton from 'material-ui/IconButton';
import DropDownArrow from 'material-ui/svg-icons/navigation/arrow-drop-down';
import CheckboxPopover from './CheckboxPopover';

/*
* TODO this component should be refactored, div as child of input should be removed
*
* */

const Input = styled.div`
  height: 100%;
  flex: 1 1 90%;
  overflow: hidden;
  width: 90%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  div {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    height: unset !important;
    text-overflow: ellipsis;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? "none" : "initial"}
`;

const Icon = styled(IconButton)`
  transform: scale(0.5);
  margin-right: -10px !important;
  & svg {
    transform: scale(2);
  }
`;

const Popover = styled(CheckboxPopover)`
  .custom-checkbox {
    & > div {
      justify-content: flex-start;
    }
  }
  div[role="presentation"] {
    width: 100% !important;
    max-height: 460px;
  }
`;

export class MultiCheckbox extends Component {

  constructor(props) {

    super(props);

    this.state = {
      open: props.open || false,
      list: this.mapList(props.list),
      anchor: null,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({
        open: nextProps.open,
      });
    }

    if (nextProps.list !== this.props.list || nextProps.value !== this.props.value) {
      this.setState({
        list: this.mapList(nextProps.list, nextProps.value),
      });
    }
  }

  handleChange = (item, val) => {
    const listItemIndex = this.state.list.findIndex(i => i.id === item.id);

    if (listItemIndex < 0) return;

    const newItem = {
      ...this.state.list[listItemIndex],
      checked: val,
    };
    const newList = [...this.state.list];
    newList[listItemIndex] = newItem;
    // if controlled update via props change
    if (this.props.onChange || {}.hasOwnProperty.call(this.props, 'value')) {
      this.props.onChange(newList.filter(li => li.checked).map(li => li.value));
    }
    // otherwise update state and change value in formsy
    else {
      this.setState({
        list: newList,
      })
    }
  }

  handleSelectAll = (e, val) => {
    if (this.props.onChange) {
      this.props.onChange(val ? this.state.list.map(li => li.value) : []);
    }
  }

  mapList = (list, value = this.props.value) => (list || []).map(item => {

    const values = (value ? Array.isArray(value) ? value : value.split(',') : []).map(val => String(val).trim());
    const isPrimitive = typeof item !== 'object' && item !== null;
    let copy = {};
    if (isPrimitive) {
      copy.name = item;
      copy.id = item;
      copy.value = item;
      copy.checked = values.includes(item);
    }

    else {
      copy = {
        ...item,
        checked: values.includes(item.value) || values.includes(item.name),
      }
    }

    if (!copy.onChange) {
      copy.onChange = this.handleChange.bind(this, copy);
    }

    copy.id = copy.id ?? copy.value;
    copy.labelPosition = copy.labelPosition ?? "right";

    return copy;
  });

  handleClose = () => this.setState({
    open: false,
  });

  handleOpen = () => this.setState({
    open: true,
  })

  setRef = el => this.setState({
    anchor: el,
  })

  render() {

    const { disabled, value, name, popoverProps = {}, floatingLabelText = 'Select', selectAllAvailable, iconStyle, normalisedTitleStyle = {}, ...rest } = this.props;
    const { open, anchor, list } = this.state;
    const { handleClose, setRef, handleOpen } = this;

    const normalizedValue = {}.hasOwnProperty.call(this.props, "value") ? Array.isArray(value) ? value.join(', ') : value : list.filter(item => !!item.checked).map(item => item.id).join(', ');
    const normalizedTitle = {}.hasOwnProperty.call(this.props, "value") ? Array.isArray(value) ? list.filter(l => value.indexOf(l.value) !== -1).map(l => l.name).join(', ') : value : list.filter(item => !!item.checked).map(item => item.name).join(', ');
    const { style = {}, ...restPopoverProps } = popoverProps;

    return (
        <NarrowFormsyText
          name={name}
          value={normalizedValue}
          style={{ cursor: 'pointer' }}
          floatingLabelText={floatingLabelText}
          disableBlurHandler // disable default blur handler, div does not have "value" property
          disabled={disabled}
          {...rest}
        >
          <Wrapper
            innerRef={setRef}
            onClick={handleOpen}
            disabled={disabled}
            // need to pass value because TextFields mui component checks "value" prop on its children in order to elevate label
            value={normalizedValue}
          >
            <input value={normalizedValue} type="hidden" />
            <Input>
              <div style={normalisedTitleStyle}>
                {
                  normalizedTitle
                }
              </div>
            </Input>
            <Icon
              disabled={!!disabled}
              onKeyDown={handleOpen}
              style={iconStyle}
            >
              <DropDownArrow />
            </Icon>
            <Popover
              style={{ width: anchor ? anchor.offsetWidth : "100%", ...style }}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              list={list}
              handleClose={handleClose}
              open={open}
              anchor={anchor}
              handleSelectAll={this.handleSelectAll}
              selectAllAvailable={selectAllAvailable}
              {...restPopoverProps}
            />
          </Wrapper>
        </NarrowFormsyText>
    )
  }
}

export default MultiCheckbox;
