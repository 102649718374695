import React, { Component } from 'react';
import PropTypes from 'prop-types';
import s from './Tags.scss';
import c from './../CustomAutocomplete/CustomAutocomplete.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import BasicCard from '../CustomAutocomplete/BasicCard';
import BasicAutocompleteList from '../CustomAutocomplete/BasicAutocompleteList';
import { withPopover } from '../../../core/HOC';
import Tags from '../../../core/api/Tags'
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { v4 as uuidv4 } from 'uuid';
import { debounceWithoutFirstCall as debounce, grabErrorMessage } from '../../../core/utils';
import { computeColorOrDefault, determineIfText } from './utils';
import ActionSearch from 'material-ui/svg-icons/action/search';
import { TagItem } from '../CustomAutocomplete/TagsAutocomplete';
import TagsChipSmall from "./TagsChipSmall";

const OR = {
  text: "or (/)",
  value: "OR",
  key: "/",
  isNot: false,
}
const AND = {
  text: "and (+)",
  value: "AND",
  key: "+",
  isNot: false,
}
const NOT = {
  text: "not (-)",
  value: "NOT",
  key: "-",
  isNot: true,
}
const NOTAG = {
  text: "no tag",
  value: "NOTAG",
  key: "",
  isNot: false,
  noTag: true,
}


const DELETE_ACTION = {
  key: "Backspace",
  which: 8
};
const ENTER_ACTION = {
  key: "Enter",
  which: 13
};
const COMMA_ACTION = {
  key: ",",
  which: [188, 191]
};
const SPACE_ACTION = {
  key: " ",
  which: 32
};

const EXECUTABLE_ACTIONS = [
  DELETE_ACTION,
  ENTER_ACTION,
  COMMA_ACTION
]

const OPERATORS = [OR, AND, NOT];
export class ChipsAutocomplete extends Component {

  static defaultProps = {
    placeholder: "Enter tag or text",
    tags: { condition: [] },
  };

  static contextTypes = {
    showMessage: PropTypes.func
  };

  constructor(props) {
    super(props);

    this._container = null;
    this._tagItems = [];
    this._input = null;

    this.state = {
      tags: [],
      chips: [],
      search: "",
      isLoading: false,
      isNoTag: false,
    };
    this.state.chips = this.convertConditionsToChips(props.tags.condition);
  }
  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    if (nextProps.tags?.condition !== this.props.tags.condition) {
      this.setState({ chips: this.convertConditionsToChips(nextProps.tags.condition) });
    }
  }

  componentDidMount() {
    if (this.props.refNo) {
      const condition = [
        {
          anyOf: [
            { search: this.props.refNo },
          ],
        },
      ];
      this.setState({ chips: this.convertConditionsToChips(condition) });
    }
  }

  mapSearch = search => search?.[0] === "#" ? search.slice(1) : search

  loadTags = debounce(async () => {
    let { search } = this.state;
    search = this.mapSearch(search);
    const lengthWithHashtag = search?.[0] === "#" ? 3 : 2;
    try {
      if (search.length < lengthWithHashtag) {
        if (search.length === 0) {
          this.props.closeSelect();
        }
        return;
      }
      this.setState({
        isLoading: true
      });
      this.props.showSelect()
      const res = await Tags.getAllTags({
        entity: this.props.entity,
        q: encodeURIComponent(this.mapSearch(search))
      });
      if (res.data) {
        this.setState({
          tags: res.data
        });
      }
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: "error",
        _id: new Date().getMilliseconds(),
        autoDismiss: 5,
        message: `Error fetching tags for query ${search}: ${grabErrorMessage(error)}`
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }, 400);

  searchTags = ({ target: { value } }) => {
    this.setState({
      search: value
    });
      this.loadTags();
  }

  showSuggestions = () => {
    let { search } = this.state;
    search = this.mapSearch(search);
    this.props.showSelect();
  }

  focusCard = ev => {
    ev.preventDefault();

    if (this.select) {
      this.select.focus();
    }
  }

  createOperator = (operator) => {
    return ({
      type: "operator",
      value: operator.value,
      isNot: operator.isNot,
      id: uuidv4(),
    });
  }

  handleClick = item => {
    this.setState(state => {
      const { chips } = state;
      if (chips?.length === 0 || this.checkIfOperator(chips?.[chips?.length - 1])) {
        return ({
          ...state,
          chips: [...state.chips, item],
          search: ""
        })
      }
      else {
        return ({
          ...state,
          chips: [...state.chips, this.createOperator(OR), item],
          search: ""
        })
      }
    }, () => {
      this.props.closeSelect();
      this.scrollInput(this._container.scrollWidth);
      this.parseOperators(this.state.chips);
      if (this._input) {
        this._input.focus?.();
      }
    });

  }

  scrollInput = (x = 0, container = this._container) => {
    if (container) {
      this._container.scroll(x, 0);
    }
  }

  checkIfOperator = obj => obj?.type === "operator" && obj?.value !== "NOTAG"

  deleteChip = chip => new Promise(async (res, rej) => {
    try {
      const newChips = [...this.state.chips];

      const onlyTags = newChips.filter(chip => !this.checkIfOperator(chip));

      const index = newChips.findIndex(el => el._id === chip._id);

      if (onlyTags[0] === newChips[index]) {
        const nextOperator = newChips[index + 1];
        if (this.checkIfOperator(nextOperator)) {
          await this.deleteOperator(nextOperator);
        }
      }
      if (index > 0) {
        const prevOperator = newChips[index - 1];

        if (this.checkIfOperator(prevOperator)) {
          await this.deleteOperator(prevOperator);
        }
      }
      this.setState(state => ({
        ...state,
        chips: state.chips.filter((item, i) => item._id !== chip._id),
      }), () => {
        this.parseOperators(this.state.chips);
        res();
      });
    } catch (error) {
      console.error(error);
      rej();
    }
  })

  adjustRef = el => {
    this._container = el;
  }

  scrollContainer = ev => {
    let container = this._container || ev.currentTarget;
    const key = ev.keyCode || ev.which;
    const SCROLL_AMOUNT = 50;

    switch (key) {
      case 37: {
        this.scrollInput(container.scrollLeft - SCROLL_AMOUNT, container);
        break;
      } case 39: {
        this.scrollInput(Number(container.scrollLeft) + Math.min(SCROLL_AMOUNT, this._container.scrollWidth), container);
        break;
      } case 40: {
        this._tagItems?.[0]?.focus?.();
      }
    }
  }

  isTag = input => input?.[0] === "#"

  determineAction = ev => {

    let key = ev.key;
    if (key === "ArrowDown") {
      ev.preventDefault();
    }
    if ((key === SPACE_ACTION.key || ev.which === SPACE_ACTION.which) && this.state.search?.length) {
      const lastChar = this.state.search[this.state.search.length - 1];
      const operator = OPERATORS.find(operator => String(operator.value).toLowerCase().trim() === String(this.state.search).toLowerCase().trim() || (operator.key === lastChar && String(this.state.search).trim().replace(lastChar, "").length === 0));
      if (operator) {
        this.addOperator(operator);
        this.setState({
          search: ""
        });
        ev.preventDefault();
      }
    }
    switch (key) {
      case DELETE_ACTION.key: {
        const { chips, search } = this.state;
        if (search.length) return;
        const lastChip = chips[chips.length - 1];
        if (this.checkIfOperator(lastChip) || lastChip.value === "NOTAG") {
          if (lastChip.noTag) {
            this.deleteNoTag(lastChip);
          } else {
            this.deleteOperator(lastChip);
          }
        } else if (lastChip) {
          this.deleteChip(lastChip);
        }
        break;
      }
      case ENTER_ACTION.key:
      case COMMA_ACTION.key: {
        ev.preventDefault();
        let { search } = this.state;
        search = String(search).trim();
        const REQUIRED_LENGTH = search?.[0] === "#" ? 3 : 2;

        if (String(search).trim().length < REQUIRED_LENGTH) return;

        let tag = null;

        if (this.isTag(search)) {
          tag = this.createCustomTag(search);
        }
        else {
          tag = this.createTextNode(search);
        }

        this.handleClick(tag);
        break;
      }
    }
  }

  createCustomTag = input => ({ value: input?.[0] === "#" ? input.slice(1) : input, _id: uuidv4(), isCustom: true })

  createTextNode = input => ({ search: input, _id: uuidv4() })

  deleteOperator = operator => new Promise((res, rej) => this.setState(state => {
    try {
      const newChips = [...state.chips];
      let index = newChips.findIndex(chip => (this.checkIfOperator(chip) && chip.id === operator.id));
      if (newChips[index]?.isNot && index !== 0) {
        index = index - 1;
      }
      // check if there is also NOT operator next to operator we are deleting
      const count = newChips[index + 1]?.isNot ? 2 : 1;

      newChips.splice(index, count);

      return ({
        ...state,
        chips: newChips,
      })

    } catch (error) {
      console.error(error);
      rej();
      return state;
    }

  }, res))

  deleteNoTag = operator => new Promise(async (res, rej) => {
    try {
      const newChips = [...this.state.chips];
      const index = newChips.findIndex(chip => (chip.noTag && chip.id === operator.id));
      const onlyTags = newChips.filter(chip => !this.checkIfOperator(chip));

      if (onlyTags[0] === newChips[index]) {
        const nextOperator = newChips[index + 1];
        if (this.checkIfOperator(nextOperator)) {
          await this.deleteOperator(nextOperator);
        }
      }
      if (index > 0) {
        const prevOperator = newChips[index - 1];
        if (this.checkIfOperator(prevOperator)) {
          await this.deleteOperator(prevOperator);
        }
      }
      this.setState(state => ({
        ...state,
        chips: state.chips.filter((item, i) => item.id !== operator.id),
        isNoTag: false,
      }), () => {
        this.parseOperators(this.state.chips);
        res();
      });
    } catch (error) {
      console.error(error);
      rej();
    }
  })

  addOperator = operator => {
    const newOperator = this.createOperator(operator);
    this.setState(state => {
      const { chips } = state;
      if (chips.length === 0 && !newOperator.isNot) {
        return state;
      }
      if (this.checkIfOperator(chips[chips.length - 1])) {
        if (chips[chips.length - 1]?.isNot || chips[chips.length - 1]?.value === "NOTAG") {
          return state;
        }
        let newChips = [...chips];

        if (newOperator.isNot) {
          newChips = [...newChips, newOperator];
        }
        else {
          newChips[newChips.length - 1] = newOperator;
        }
        return ({
          ...state,
          chips: newChips,
        });
      }
      else {
        return ({
          ...state,
          chips: [...state.chips, ...(newOperator.isNot && state.chips.length ? [this.createOperator(OR), newOperator] : [newOperator])]
        })
      }
    }, () => this.scrollInput(this._container?.scrollWidth));
  }

  addNoTag = operator => {
    const newOperator = this.createOperator(operator);
    newOperator.type = undefined;
    newOperator.noTag = operator.noTag;
    this.setState(state => {
      const { chips } = state;
      const newChips = [...chips, newOperator];
      return ({
        ...state,
        chips: newChips,
        isNoTag: true,
      });
    }, () => {
      this.scrollInput(this._container?.scrollWidth);
      this.parseOperators(this.state.chips);
    });
  }

  persistSelect = () => {
    this.props.showSelect();
  }

  clearSearch = () => {
    this.setState({ search: "", tags: [] });
    this.props.closeSelect();
  }

  mapChips = chips => chips.filter(chip => !this.checkIfOperator(chip)).map(chip => chip.search ? chip : ((chip.noTag && { noTag: true }) || { value: chip.value, negate: !!chip.negate, ...(chip.category ? { categoryId: chip.category._id || chip.categoryId, categoryName: chip.category.value } : {}), category: chip.category, isCustom: chip.isCustom }))

  parseOperators = chips => {
    const chipsCopy = [...chips];

    const indexes = [];

    let conditions = [];

    chipsCopy.forEach((chip, i, arr) => {
      if (this.checkIfOperator(chip) && chip.value === AND.value) {
        indexes.push(i);
      }
      if (this.checkIfOperator(chip) && chip.isNot) {
        if (chipsCopy[i + 1]) {
          chipsCopy[i + 1] = {
            ...arr[i + 1],
            negate: true
          }
        }
      }
    })

    if (!indexes.length) {

      conditions.push({
        anyOf: this.mapChips(chipsCopy)
      })
    }

    if (indexes[0]) {
      conditions.push({
        anyOf: this.mapChips(chipsCopy.slice(0, indexes[0]))
      })
    }

    if (indexes.length > 0) {
      indexes.forEach((index, i) => {
        let condition = {};
        let chips = chipsCopy.slice(index, indexes[i + 1]);
        condition.anyOf = this.mapChips(chips);
        conditions.push(condition);
      })
    }
    const filtersObj = {
      ...(this.props.filters || {}),
    }
    Reflect.deleteProperty(filtersObj, "tags")
    if (conditions.find(c => c?.anyOf?.length)) {
      filtersObj.tags = {
        condition: conditions
      }
    }
    if (this.props.handleSearch) {
      let isError = false;
      if (chipsCopy.some(o => o.noTag)
        && (
          (chipsCopy.some(o => o.value === OR.value && o.type === "operator")
            && chipsCopy.some(o => o.value === AND.value && o.type === "operator"))
          || (chipsCopy.filter(o => o.value === OR.value && o.type === "operator").length > 1
            || chipsCopy.filter(o => o.value === AND.value && o.type === "operator").length > 1)
          || (chipsCopy.some(o => o.value === AND.value && o.type === "operator")
            && chipsCopy.some(o => o.value !== "NOTAG" && o.type !== "operator" && !o.search))
          || (chipsCopy.some((o, i, conds) => o.value === NOT.value && o.type === "operator" && conds[i + 1].noTag)))
      ) {
        this.context.showMessage({
          level: "error",
          autoDismiss: 5,
          message: "Invalid input in search field",
        });
        isError = true;
      }
      if (!isError) {
        this.props.handleSearch(filtersObj);
      }
    }
  }

  convertConditionsToChips(conditions = []) {
    const chips = [];
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i];
      if (i > 0) {
        chips.push({ type: 'operator', value: 'AND', isNot: false, id: uuidv4() });
      }
      if (condition.value) {
        condition._id = uuidv4();
      }
      for (let j = 0; j < condition.anyOf.length; j++) {
        let anyOfElement = condition.anyOf[j];
        if (anyOfElement.value) {
          anyOfElement._id = uuidv4();
        }
        if (anyOfElement.inAddressesOnly) {
          anyOfElement._id = uuidv4();
        }
        if (j > 0) {
          chips.push({ type: 'operator', value: 'OR', isNot: false, id: uuidv4() });
        }
        if (anyOfElement.negate) {
          chips.push({ type: 'operator', value: 'NOT', isNot: true, id: uuidv4() });
        }
        if (anyOfElement.noTag) {
          anyOfElement = { noTag: true, value: 'NOTAG', isNot: false, id: uuidv4() };
        }
        chips.push(anyOfElement);
      }
    }
    return chips;
  }

  render() {
    const { placeholder, open, setAnchorEl, className = "" } = this.props;
    let { tags, isLoading, search, chips } = this.state;
    return (
      <div className={s.chips_autocomplete_wrapper} ref={setAnchorEl}>
        <div tabIndex="-1" onKeyDown={this.scrollContainer} ref={this.adjustRef} className={cx(s.chips_autocomplete, className)}>
          {
            chips.map(chip => (
              chip.value === "NOTAG" || this.checkIfOperator(chip)
                ? <Operator operator={chip} handleDelete={() => this.deleteNoTag(chip)}/>
                : <TagsChipSmall handleDelete={() => this.deleteChip(chip)} tag={chip} />
            ))
          }
          <div className={s.chips_autocomplete_input_wrapper}>
            {
              chips.length === 0
                ? (
                  <ActionSearch color="var(--text-medium)" style={{ height: 18, width: 18, marginRight: 4, transform: 'scale(-1,1)', }} ></ActionSearch>
                )
                : null
            }
            <input ref={el => this._input = el} placeholder={placeholder} onBlur={this.focusCard} value={search} onKeyDown={this.determineAction} onClick={this.showSuggestions} onChange={this.searchTags} className={s.chips_autocomplete_input} style={chips.length === 0 ? { width: '100%' } : undefined} type="text" />
            {
              search.length >= 2
                ? (
                  <CloseIcon onClick={this.clearSearch} style={{ width: 16, height: 16, marginLeft: 4, cursor: "pointer" }}></CloseIcon>
                )
                : null
            }
          </div>
        </div>
        <BasicCard
          open={open}
          adjustRef={node => (this.select = node)}
          handleClick={this.persistSelect}
          className={s.tags_card}
          showLoader={isLoading}
        >
          <div className={s.chips_autocomplete_operators}>
            {
              OPERATORS.map(operator => <button onClick={() => this.addOperator(operator)} disabled={chips.length < 1 && !operator.isNot} className={s.chips_autocomplete_operatorBtn}>{operator.text}</button>)
            }
            { this.props.folder === "exchange" &&
              <button onClick={() => this.addNoTag(NOTAG)} disabled={this.state.isNoTag === true} className={s.chips_autocomplete_operatorBtn}>no tag</button>
            }
          </div>
          <div
           className={s.chips_autocomplete_scrollable}>
            <BasicAutocompleteList
              list={tags}
              renderItem={(listItem, i) => (
                <TagItem
                  adjustRef={el => this._tagItems[i] = el}
                  handleClick={(ev, item) => this.handleClick(item)}
                  keyword={this.mapSearch(search)}
                  item={listItem}
                ></TagItem>
              )}
            />
          </div>
        </BasicCard>
      </div>
    )
  }
}

function Operator({ operator, handleDelete = () => undefined }) {
  if (operator.value === 'NOTAG') {
    return (
      <span className={cx(s.chips_autocomplete_operatorBtn, s.operator_chips)}>
        { NOTAG.text}
        <CloseIcon onClick={handleDelete} color="#fff" style={{ cursor: "pointer", width: 12, height: 12, marginLeft: 4 }}/>
      </span>
    );
  }
  return (
    <span className={s.chips_autocomplete_operator}>
      { operator.value}
    </span>
  )
}

function Chip({ tag = {}, handleDelete = () => undefined }) {
  const categoryColor = computeColorOrDefault(tag);

  const isText = determineIfText(tag);

  return (
    <div className={s.chips_autocomplete_chip} style={{ backgroundColor: categoryColor, border: isText || tag.isCustom ? "1px solid var(--stroke-light-gray1)" : 'initial' }}>
      <span className={s.chips_autocomplete_chip_text}>
        {
          isText
            ? tag.search
            : `#${tag.value}`
        }
      </span>
      <CloseIcon onClick={handleDelete} color="var(--default-dark-text)" style={{ cursor: "pointer", width: 12, height: 12, marginLeft: 4 }}></CloseIcon>
    </div>
  )
}

export default withStyles(s, c)(withPopover(ChipsAutocomplete))
