import React from 'react';
import Layout from '../../components/Layout';
import Page from '../../components/Page/Page';
import history from '../../core/history';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
export default {
  path: '/cargo',
  children: [
    {
      path: '/:type/create',
     async action({ params, hash, ...rest }) {
       const Cargo = ((await import(/* webpackChunkName: 'AddCargo' */ '../../components/Cargo')).default);
        return {
          title: 'New Cargo Request: Ports, Dates, Rates & Terms | My Cargo Services',
          description: "Easily manage your cargo requests with My Cargo. Set ports, dates, rates, and terms for loading and discharging efficiently.",
          component: (
            <Layout>
                <Page
                  headerTitle={'MY CARGO: NEW REQUEST'}
                  headerRight={<a><NavigationClose onClick={() => history.push('/')} /></a>}
                >
                  <Cargo showHeader={false} hash={hash} {...params} actionType="create" />
                </Page>
            </Layout>
          ),
        };
      },
    },
  ],
};

export const requestList = {
  path: '/cargo/requests/:cargoId?',

  async action({ params, hash, store, next }) {
    if(history.replace) {
      return history.replace('/my/cargo/');
    }
    return ({
      component: <Layout>
           <div />
      </Layout>,
    })
  },
};
