import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../Home.scss';
import VisibilitySensor from 'react-visibility-sensor';
import Link from '../../Link/Link';
import Media from "react-media";
import SolutionsMobile from './SolutionsMobile';
import SolutionsMarketplace from './SolutionsMarketplace';
import TradingDesk from '../../Icons/TradingDesk';
import CargoMonitor from '../../Icons/CargoMonitor';
import FleetMonitor from '../../Icons/FleetMonitor';
import Exchange from '../../Icons/Exchange';
import Documents from '../../Icons/Documents';
import PortsIcon from '../../Icons/PortsIcon';
import SolutionsCargoMonitor from './SolutionsCargoMonitor';
import SolutionsFleetMonitor from './SolutionsFleetMonitor';
import SolutionsExchange from './SolutionsExchange';
import SolutionsDocuments from './SolutionsDocuments';
import SolutionsPorts from './SolutionsPorts';

class Solutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameSolutions: 'marketplace',
    };
  }
  toggleSolutions = (name) => {
    this.setState({ nameSolutions: name });
  };
  render() {
    const { user } = this.props;
    return (
      <div>
        <Media query="(max-width: 767px)" defaultMatches={false}>
          {matches =>
            matches ? (
              <SolutionsMobile user={user} />
            ) : (
              <div>
                <div className={s.wrapper_solutions_tab}>
                  <div className={s.container_home}>
                    <ul>
                      <li
                        onClick={this.toggleSolutions.bind(this, 'marketplace')}
                        className={this.state.nameSolutions === 'marketplace' ? s.active_tab : null}
                      >
                        <TradingDesk />
                        <p><span className={s.first_title}>Shipnext</span><br />Trading Desk</p>
                      </li>
                      <li
                        onClick={this.toggleSolutions.bind(this, 'cargo-monitor')}
                        className={this.state.nameSolutions === 'cargo-monitor' ? s.active_tab : null}
                      >
                        <CargoMonitor />
                        <p><span className={s.first_title}>Shipnext</span><br />Cargo Monitor</p>
                      </li>
                      <li
                        onClick={this.toggleSolutions.bind(this, 'fleet-monitor')}
                        className={this.state.nameSolutions === 'fleet-monitor' ? s.active_tab : null}
                      >
                        <FleetMonitor />
                        <p><span className={s.first_title}>Shipnext</span><br />Fleet Monitor</p>
                      </li>
                      <li
                        onClick={this.toggleSolutions.bind(this, 'exchange')}
                        className={this.state.nameSolutions === 'exchange' ? s.active_tab : null}
                      >
                        <Exchange />
                        <p><span className={s.first_title}>Shipnext</span><br />Exchange</p>
                      </li>
                      <li
                        onClick={this.toggleSolutions.bind(this, 'documents')}
                        className={this.state.nameSolutions === 'documents' ? s.active_tab : null}
                      >
                        <Documents />
                        <p><span className={s.first_title}>Shipnext</span><br />Documents</p>
                      </li>
                      <li
                        onClick={this.toggleSolutions.bind(this, 'ports')}
                        className={this.state.nameSolutions === 'ports' ? s.active_tab : null}
                      >
                        <PortsIcon />
                        <p><span className={s.first_title}>Shipnext</span><br />Ports</p>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className={s.container_home}>
                  {this.state.nameSolutions === 'marketplace' &&
                    <SolutionsMarketplace user={user} />
                  }
                  {this.state.nameSolutions === 'cargo-monitor' &&
                    <SolutionsCargoMonitor user={user} />
                  }
                  {this.state.nameSolutions === 'fleet-monitor' &&
                    <SolutionsFleetMonitor user={user} />
                  }
                  {this.state.nameSolutions === 'exchange' &&
                    <SolutionsExchange user={user} />
                  }
                  {this.state.nameSolutions === 'documents' &&
                    <SolutionsDocuments user={user} />
                  }
                  {this.state.nameSolutions === 'ports' &&
                    <SolutionsPorts user={user} />
                  }
                </div>
              </div>
            )
          }
        </Media>
      </div>
    );
  }
}
export default withStyles(s)(Solutions);
