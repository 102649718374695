export default function(state, action) {
  const { vesselList : { data, filtered } } = state;

  const { payload } = action;

  const isDelete = payload.delete;

  const newData = data.map(vessel => {
    const isChanged = payload.selectedVessels.find(selectedVessel => selectedVessel._id === vessel._id);
    if (isChanged) {
      return { ...vessel, inSelectedVessels: !isDelete }
    }

    return vessel;
  });
  const newFiltered = filtered.map(vessel => {
    const isChanged = payload.selectedVessels.find(selectedVessel => selectedVessel._id === vessel._id);
    if (isChanged) {
      return { ...vessel, inSelectedVessels: !isDelete }
    }

    return vessel;
  });

  return { ...state, vesselList: { ...state.vesselList, data: newData, filtered: newFiltered } };

}
