import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { leftHoursFromMillis,date } from '../../core/format';

class Timer extends Component {
  static propTypes = {
    timeToEnd: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    handleExpired: PropTypes.func,
    _id: PropTypes.string,
  };
  state = { text: '' };

  constructor(props) {
    super(props);
    this.state.timeToEnd = Timer.parseTime(this.props.timeToEnd);
    this.state.text = leftHoursFromMillis(this.state.timeToEnd, date(this.state.timeToEnd));
  }

  static parseTime(timeToEnd) {
    if (!timeToEnd) {
      return timeToEnd;
    }
    const type = typeof timeToEnd;
    if (type === 'number') {
      return timeToEnd;
    }
    if (type === 'string') {
      return new Date(timeToEnd).getTime();
    }
    if (timeToEnd.getTime) {
      return timeToEnd.getTime();
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextState.text !== this.state.text;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.timeToEnd !== this.props.timeToEnd) {
      const timeToEnd = Timer.parseTime(nextProps.timeToEnd);
      this.setState({ text: leftHoursFromMillis(timeToEnd, date(timeToEnd)), timeToEnd });
    }
  }

  componentDidMount() {
    this.updateText();
    if (typeof window !== 'undefined') {
      this.interval = setInterval(this.updateText.bind(this), 1000 * 60);
    }
  }

  componentWillUnmount(nextProps, nextState, nextContext) {
    clearInterval(this.interval);
  }

  updateText() {
    const text = leftHoursFromMillis(this.state.timeToEnd, '') || date(this.state.timeToEnd);
    if (text === 'expired') {
      this.props.handleExpired && this.props.handleExpired(this.props._id);
    }
    this.setState({ text });
  }

  render() {
    return <span>{this.state.text}</span>;
  }
}

export default Timer;
