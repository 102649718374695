import React, { PureComponent } from 'react';
import ComposeEmailDialog from "./ComposeEmailDialog";
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import { handleGlobalComposeDialog } from '../../actions/login';

class GlobalComposeEmailDialog extends PureComponent {

  componentDidMount() {
    PubSub.subscribe('GlobalComposeEmailDialog', this.onMessage);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.opened && nextProps.path !== this.props.path){
      const path = this.props.path.split('/');
      const nextPath = nextProps.path.split('/');
      if (path[1] !== nextPath[1]) {
        this.props.handleGlobalComposeDialog(false);
        this.setState({ opened: false });
      }
    }
    if (this.props.globalComposeDialog && !nextProps.globalComposeDialog) {
      this.onClose();
    }
  }


  onMessage = (topic, { to = [], cc = [], position = 'right' }) => {
    this.props.handleGlobalComposeDialog(true);
    this.setState({ opened: true, position, composeProps: { to, cc } });
  }

  onClose = () => {
    this.props.handleGlobalComposeDialog(false);
    this.setState({ opened: false });
  }

  transitionLockerPredicate = (newLocation, action) => {
    const path = window.location.pathname.split('/');
    const nextPath = newLocation.pathname.split('/');
    return path[1] !== nextPath[1];
  }

  state = { opened: false };
  render() {
    if (!this.state.opened) {
      return null;
    }
    return (
      <ComposeEmailDialog
        position={this.state.position}
        composeProps={this.state.composeProps}
        onClose={this.onClose}
        transitionLockerPredicate={this.transitionLockerPredicate}
        shouldConfirmClose
      />
    );
  }
}

const mapStateToProps = (state) => ({
  globalComposeDialog: state.login.globalComposeDialog,
}
);
const mapDispatchToProps = (dispatch) => ({
  handleGlobalComposeDialog: (open) => dispatch(handleGlobalComposeDialog(open)),
}
);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalComposeEmailDialog);
