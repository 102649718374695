import Api from './api';
import { prepareRequest } from '../utils';
import {calcDays} from '../../components/Calculator/utils';
import Price from './Price';
/**
 * @class
 */
export default class Port extends Api {

  static endpoint = '/ports';
  _id;
  _name;
  _portId;
  _coordinates;
  _portLimitations;
  _facilities;
  _sub;
  _alt;

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get portId() {
    return this._portId;
  }

  get coordinates() {
    return this._coordinates;
  }

  get portLimitations() {
    return this._portLimitations;
  }

  get facilities() {
    return this._facilities;
  }

  get sub() {
    return this._sub;
  }

  get alt() {
    return this._alt;
  }

  toJSON() {
    return {
      _id: this._id,
      name: this._name,
      portId: this._portId,
      coordinates: this._coordinates,
      portLimitations: this._portLimitations,
      facilities: this._facilities,
      sub: this._sub,
      alt: this._alt,
    };
  }

  static async getChanges(_id) {
    return Port.fetch(`/ports/${_id}/history`, { method: 'GET' });
  }

  static async updatePort(port) {
    return Port.fetch(`/ports/${port._id}`, { method: 'PUT', body: JSON.stringify(prepareRequest(port)) });
  }

  static async upsertProvider(port, provider) {
    if (!provider._id) {
      return Port.fetch(`/ports/${port._id}/provider`, { method: 'POST', body: JSON.stringify(provider) });
    }
    return Port.fetch(`/ports/provider/${provider._id}`, { method: 'PUT', body: JSON.stringify(provider) });
  }

  static async getAllUserAreas() {
    return Port.fetch('/ports/my/area', { method: 'GET' });
  }

  static async getAllPortsWithCoordinates() {
    return Port.fetch('/ports/allPorts', { method: 'GET' });
  }

  static async createMyArea(area) {
    return Port.fetch('/ports/my/area', { method: 'POST', body: JSON.stringify(area) });
  }

  static async updateMyArea(area) {
    return Port.fetch(`/ports/my/area/${area._id}`, { method: 'PUT', body: JSON.stringify(area) });
  }

  static async deleteMyArea(area) {
    return Port.fetch(`/ports/my/area/${area._id}`, { method: 'DELETE' });
  }

  static async calculateCoordinates(coordinates) {
    return Port.fetch(`/ports/calculate`, { method: 'POST', body: JSON.stringify(coordinates) });
  }

  static async getList(page, limit, filters) {
    // console.log('page, limit, filters', page, limit, filters)
    return Port.fetch(`/ports/?page=${page}&query=${encodeURIComponent(filters.query || '')}&country=${filters.country || ''}`, { method: 'GET' });
  }
  static async getPublic(_id) {
    return Port.fetch(`/ports/public/${encodeURIComponent(_id)}`, { method: 'GET' });
  }

  static async create(port) {
    return Port.fetch('/ports', { method: 'POST', body: JSON.stringify(port) });
  }

  static async getDistance(fromId, toId) {
    if (fromId === toId) {
      return {
        status: 200,
        data: [{
          'id': 5818242,
          'from_id': fromId,
          'to_id': toId,
          'min_distance': 0,
          'depthRestriction': 0,
          'via': [],
          'reverse': false,
          'source': 3,
        }],
      };
    }
    return {
      status: 200,
      data: [{
        'id': 5818242,
        'from_id': fromId,
        'to_id': toId,
        'min_distance': 7026,
        'depthRestriction': 18.9,
        'via': [{
          _id: 3309,
          name: 'GIBRALTAR',
          days: 0.2,
          length: 35.1,
          speedLimit: 8,
        },],
        'reverse': false,
        'source': 3,
      }],
    };
  }

  static async getLegsV2(req, open, ship){
    const legs = req.legs;
    const reqList = [];
    for (let i = 0; i < legs.length; i++) {
      const leg = legs[i];
      const nextLeg = legs[i+1];
      if(!nextLeg) {
        break;
      }
     reqList.push({ legs:[leg,nextLeg] ,includePoints:false, optimize:false})
    }
    const resList =  await Promise.all(reqList.map(req => Port.fetch('/ports/distance/legs', { method: 'POST', body: JSON.stringify(req) }, '/api/v1.5', 5)) )
    let newLegs = []
    for (let i = 0; i < resList.length; i++) {
      const resListElement = resList[i];
      const prevResListElement = resList[i-1]
      if(i===0 || resListElement.data.legs.length === 1 || (prevResListElement.data.legs.length === 1) ) {
        newLegs = [...newLegs, ...resListElement.data.legs]
      }else {
        newLegs = [...newLegs, ...resListElement.data.legs.slice(1,resListElement.data.legs.length) ]
      }
    }
    return {data:{legs:newLegs}};

  }

  static async getRoutePart(leg1, leg2, version = '') {
    leg2 = { ...leg2 };
    /*if (version === '') { // TODO crunch "cape of good hope and suez", remove after via in breakbulk calculator
      if (leg1.port._id === '5ada202e4fd3c343860e48e4' || leg2.port._id === '5ada202e4fd3c343860e48e4') {
        leg2.via = ["5ada202e4fd3c343860e48e4"];
      }
    }*/

    const res = await Port.fetch('/ports/distance/legs' + version, {
      method: 'POST',
      body: JSON.stringify({
        legs: [{ port: { _id: leg1.port._id }, avoidSeca: leg1.avoidSeca }, { port: { _id: leg2.port._id }, via: leg2.via, avoidSeca: leg2.avoidSeca }],
        includePoints: true,
        optimize: false,
      }),
    }, '/api/v1.5', 5)
    res.data.legs = res.data.legs.slice(1, res.data.legs.length);
    return res;

  }

  static async getLegs(req, open, ship) {
    const idForPrices = req.legs.filter(l=> !l.port.isChannel).map(l => l.port._id);
    req.legs = req.legs.map(l => ({ port: { _id: l.port._id } }));
    let skippedPrices = [];
    if(open && idForPrices[0] === open._id) {
      skippedPrices = idForPrices.splice(0, 1);
    }
    let prices = [];
    try {
      prices = (await Price.priceByPorts(idForPrices, ship)).data;
    } catch (e) {
      console.error(e);
    }

    const res = await Port.fetch('/ports/distance/legs', { method: 'POST', body: JSON.stringify(req) }, '/api/v1.5', 5);
    if (req.legs[0].port._id === req.legs[1].port._id && req.legs[0].port._id === res.data.legs[0].port._id) {
      res.data.legs.unshift(JSON.parse(JSON.stringify(res.data.legs[0])));
      res.data.legs[0]._id = res.data.legs[0].port._id;
      for (let i = 1; i < res.data.legs.length; i++) {
        const l = res.data.legs[i];
        l._id = res.data.legs[0]._id + '|' + l._id;
        if (!l.port.isChannel) {
          break;
        }
      }
    }
    res.data.legs.forEach((l) => {
      if (l.port.isChannel) {
        l.distance = l.port.length;
        l.lsDistance = undefined;
        l.port.onLs = true;
        if (l.port.onLs) {
          l.lsDistance = l.port.length;
        }
        if (l.port.days) {
          l.idle = l.port.days;
        }
      } else {
        const price = prices.find(p => p.portId === l.port._id);
        if (price) {
          l.price = price;
          l.da = price.pda.averagePortCostsUsdDay;
          l._da = price.pda.averagePortCostsUsdDay;
        }
        l.distance = l.totalDistance;
        l.lsDistance = l.totalLsDistance;
      }
    });
    return res;
  }

  static async printCalculator(req) {
    return await Port.fetch('/calculator/calculate', { method: 'POST', body: JSON.stringify(req) });
  }

  static async populatePortsInfo(ports){
    const promises = []
    for (let i = 0; i < ports.length; i++) {
      const port = ports[i];
      const req = this.get(port._id);
      if(port.isChannel) {
        promises.push({data:[], status:200})
        continue;
      }
      promises.push(req);
    }
   const responses =  await Promise.all(promises);
    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      const data = response?.data || {};
      const port = ports[i];
      Object.assign(port, data);
    }
    return ports;
  }

  static async getVias() {
    return await Port.fetch('/vessels/via', { method: 'GET' }, '/api/v2');
  }

}
