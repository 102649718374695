import React from "react";
import pure from "recompose/pure";

let GeneralCargoIcon = ({ fill = '#75C422', ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill={fill} {...props} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M10.4956 6.25281C11.0105 6.25281 11.504 6.25281 12.0523 6.25281C12.0523 8.19361 12.0523 10.0928 12.0523 12.064C10.149 12.064 8.29435 12.064 6.37891 12.064C6.37891 10.1264 6.37891 8.24641 6.37891 6.27521C6.88473 6.27521 7.36321 6.27521 7.93739 6.27521C7.93739 6.88001 7.93739 7.42721 7.93739 8.06401C8.79106 8.06401 9.66927 8.03999 10.4956 8.03999C10.4956 7.39999 10.4956 6.91999 10.4956 6.25281Z" />
      <path d="M8.60938 6.23999C9.03925 6.23999 9.38254 6.23999 9.80482 6.23999C9.80482 6.66719 9.80482 7.04159 9.80482 7.48799C9.41444 7.48799 9.04988 7.48799 8.60938 7.48799C8.60938 7.08639 8.60938 6.71039 8.60938 6.23999Z" />
      <path d="M7.1538 0.0128174C7.66874 0.0128174 8.16217 0.0128174 8.71053 0.0128174C8.71053 1.95362 8.71053 3.85282 8.71053 5.82402C6.80724 5.82402 4.95255 5.82402 3.03711 5.82402C3.03711 3.88642 3.03711 2.00642 3.03711 0.0352173C3.54293 0.0352173 4.02141 0.0352173 4.59559 0.0352173C4.59559 0.640017 4.59559 1.18722 4.59559 1.82402C5.44926 1.82402 6.32747 1.8 7.1538 1.8C7.1538 1.16 7.1538 0.680005 7.1538 0.0128174Z" />
      <path d="M5.26758 0C5.69745 0 6.04074 0 6.46302 0C6.46302 0.4272 6.46302 0.8016 6.46302 1.248C6.07264 1.248 5.70808 1.248 5.26758 1.248C5.26758 0.8464 5.26758 0.4704 5.26758 0Z" />
      <path d="M4.11669 6.25281C4.63163 6.25281 5.12506 6.25281 5.67342 6.25281C5.67342 8.19361 5.67342 10.0928 5.67342 12.064C3.77013 12.064 1.91544 12.064 0 12.064C0 10.1264 0 8.24641 0 6.27521C0.505823 6.27521 0.984304 6.27521 1.55848 6.27521C1.55848 6.88001 1.55848 7.42721 1.55848 8.06401C2.41215 8.06401 3.29036 8.03999 4.11669 8.03999C4.11669 7.39999 4.11669 6.91999 4.11669 6.25281Z" />
      <path d="M2.23047 6.23999C2.66034 6.23999 3.00363 6.23999 3.42591 6.23999C3.42591 6.66719 3.42591 7.04159 3.42591 7.48799C3.03553 7.48799 2.67098 7.48799 2.23047 7.48799C2.23047 7.08639 2.23047 6.71039 2.23047 6.23999Z" />
    </g>
  </svg>
);

GeneralCargoIcon = pure(GeneralCargoIcon);
GeneralCargoIcon.displayName = "GeneralCargoIcon";
GeneralCargoIcon.muiName = "SvgIcon";

export default GeneralCargoIcon;
