import React from 'react';

import Cargo from '../../core/api/Cargo';
import Orders from '../../core/api/Orders';
import Layout from '../../components/Layout';

import {MONITOR_UPDATE_VESSEL_LOCATION, MONITOR_SAVE_VESSEL_ID} from './../../constants/monitor';
import {getAccountFilters} from "../../actions/login";


async function monitorAction(actionType, { params, hash, store, token, path, previousPath }) {
  const Monitor = ((await import(/* webpackChunkName: 'Monitor' */ '../../components/Monitor')).default);
  if (actionType) {
    params.actionType = actionType;
  }
  const user = store.getState().login.user;
  let cargoErr;
  let shipErr;
  const inTcDetails = params.type === 'tc' && params.cargoId && params.cargoId !== '-';
  let { cargo, vessel } = await Cargo.getCargoAndVessel(!inTcDetails ? params.cargoId : '', params.vesselId, user, token);
  let order = undefined;
  if (inTcDetails) {
    // TODO: implement separate route for orderId
    try {
      const { data } = await store.dispatch({
        type: "GET_TC_ORDER",
        promise: Orders.getById(params.cargoId, token)
      });
      order = data ? data : undefined;
    } catch (error) {
      console.error(error);

    }
  }
  if (vessel && vessel.vessel) {
    store.dispatch({
      type: MONITOR_UPDATE_VESSEL_LOCATION,
      payload: {
        _id: vessel._id,
        vessel: {
          hasMyExclusions: vessel.vessel.hasMyExclusions
        }
      }
    });
    store.dispatch({
      type: MONITOR_SAVE_VESSEL_ID,
      payload: {id: vessel?.vessel?._id || vessel?._id || '' }
    })
  }
  if (actionType === 'tc-offer' && vessel.channels?.length) {
    vessel.channels = vessel.channels.filter(c => c.canOfferHire);
  }
  /*if (params.cargoId && params.cargoId.length === 24) {
    try {
      cargo = (await Cargo.getMonitorCargoById(params.cargoId)).data;
    } catch (e) {
      cargoErr = e;
      console.error('error', e);
    }
  }
  if (params.vesselId) {
    if (params.vesselId === 'TBN') {
      vessel = {};
    } else if (params.vesselId.length === 24) {
      try {
        vessel = (await Vessel.getMonitorVesselById(params.vesselId, cargo && cargo._id)).data;
        //vessel.channels = (await Vessel.getChannels(vessel.vessel)).data;
        if(cargo && !cargo.addedByUser && vessel.channels) {
          const bidChannel = vessel.channels.find(c => !!c.status.bid);
          cargo.bidChannel = bidChannel;
          vessel.channels = vessel.channels.filter(c => !c.status.bid);
        }
        try {
          if (typeof window !== 'undefined' && (params.actionType === 'offer' || params.actionType === 'request')) {
            const info = (await Vessel.getRequestInfo(params.vesselId, params.cargoId)).data.info;
            if (info && (info.bidId && (((info.endAt && info.endAt.getTime() < Date.now()) && info.status !== 'reject') || info.status === 'fix'))) {
              history.push('/bid/cargo/' + info.bidId);
            }
          }
        } catch (e) {
        }
      } catch (e) {
        shipErr = e;
        console.error('error', e);
      }
    }
  }*/
  try {
    if (!previousPath || path.slice(12) !== previousPath.slice(12)) {
      await store.dispatch(getAccountFilters(token));
    }
  } catch (e) {
    console.error(e);
  }
  return {
    title: user
      ? 'SHIPNEXT - Shipping Marketplace and Transportation Platform'
      : 'Login to SHIPNEXT: Access Instant Freight and Cargo Search, Chartering & More',
    description: user 
      ? 'SHIPNEXT is an international Online Shipping Marketplace for dry-bulk and wet bulk, general and oversized cargo, steels and containers. It allows an instant search for suitable Cargo or Ships by sending a request in random form to fix@shipnext.com or on-line in just a few clicks. SHIPNEXT includes Cargo and Ship matching, fully integrated range of industry data-bases, online trading and Contract Management tools.'
      : "Log in to SHIPNEXT for free access to freight search, cargo matching, chartering, CRM, and supply-chain management. Register now and optimize your shipping needs!",
    component: (
      <Layout>
          <Monitor
            {...params}
            selectedCargo={cargo ? cargo : undefined}
            selectedVessel={vessel}
            selectedOrder={order}
            hash={hash}
            shipErr={shipErr}
            cargoErr={cargoErr}
          />
      </Layout>
    ),
    public:params.type !== 'general' || (!params.actionType && !params.cargoId),
  };
}

export default {
  path: '/main-deck',
  children: [
    {
      path: '/:type',
      children: [
        {
          path: '/',
          action: monitorAction.bind(this, null),
        },
        {
          path: '/:vesselId/tc-offer',
          action: monitorAction.bind(this, 'tc-offer'),
        },
        {
          path: '/:vesselId/edit',
          action: monitorAction.bind(this, 'edit'),
        },
        {
          path: '/:cargoId/:vesselId?/:actionType?/:offerId?', //actionType request,offer
          action: monitorAction.bind(this, null),
        },
      ]
    },
    {
      path: '/tc/:orderId/:vesselId/create',
      action: monitorAction.bind(this, 'create')
    },
  ],
};
