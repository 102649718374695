import RequestRourceFilter from '../Monitor/RequestRourceFilter';
import React, { PureComponent } from 'react';
import { SharedIcon, PrivateIcon } from './Icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  form {
    min-width: 135px !important;
  }
`;

const keys = [
  'shared',
  'private',
];
const titles = ['Shared', 'Private'];
const icons = [<SharedIcon />, <PrivateIcon />];

export default class EmailsSharedFilter extends PureComponent {
  convertFromServer = (filters) => {
    filters = { ...filters };
    filters.access = {};
    if (typeof filters.private === 'boolean') {
      filters.access.private = filters.private;
      filters.access.shared = !filters.private;
    } else {
      filters.access.private = true;
      filters.access.shared = true;
    }
    return filters;
  }
  convertToServer = (filters) => {
    filters = { ...filters };
    if ((filters.access.private && filters.access.shared) || (!filters.access.private && !filters.access.shared)) {
      filters.private = undefined;
    } else {
      filters.private = filters.access.private || !filters.access.shared;
    }
    filters.access = undefined;
    return filters;
  }
  onFilter = (filters) => {
    this.props.handleFilter(this.convertToServer(filters));
  }
  render() {
    return (
      <Wrapper>
        <RequestRourceFilter
          handleFilter={this.onFilter}
          filters={this.convertFromServer(this.props.filters)}
          endingText={''}
          filtersKey="access"
          allText={'All Type'}
          noDots
          keys={keys}
          titles={titles}
          icons={icons}
        />
      </Wrapper>
    );
  }
}

