import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Marketplace = props => (
  <span {...props}>
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
	    <circle cx="36" cy="36" r="36" fill="#F5F5F5" />
	    <g>
	        <path fillRule="evenodd" clipRule="evenodd" d="M18.8618 19.1552C28.1978 9.81912 43.2871 9.74266 52.7174 18.9258L49.3641 19.0855L49.4827 21.5762L56.786 21.2284L57.1338 13.9251L54.6431 13.8065L54.4832 17.1649C44.0791 7.00563 27.4092 7.08135 17.0986 17.392C8.32442 26.1662 6.96206 39.5454 13.0115 49.7497L15.1183 48.4124C9.68153 39.1733 10.9293 27.0876 18.8618 19.1552ZM56.5072 23.6752L58.6132 22.3384C64.8765 32.5804 63.5764 46.143 54.713 55.0065C44.4021 65.3174 27.7317 65.3928 17.3277 55.2328L17.1677 58.5923L14.677 58.4737L15.0248 51.1704L22.328 50.8227L22.4466 53.3133L19.0945 53.473C28.5248 62.6558 43.6139 62.5793 52.9499 53.2433C60.9716 45.2216 62.1573 32.9526 56.5072 23.6752Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.8618 19.1552C28.1978 9.81912 43.2871 9.74266 52.7174 18.9258L49.3641 19.0855L49.4827 21.5762L56.786 21.2284L57.1338 13.9251L54.6431 13.8065L54.4832 17.1649C44.0791 7.00563 27.4092 7.08135 17.0986 17.392C8.32442 26.1662 6.96206 39.5454 13.0115 49.7497L15.1183 48.4124C9.68153 39.1733 10.9293 27.0876 18.8618 19.1552ZM56.5072 23.6752L58.6132 22.3384C64.8765 32.5804 63.5764 46.143 54.713 55.0065C44.4021 65.3174 27.7317 65.3928 17.3277 55.2328L17.1677 58.5923L14.677 58.4737L15.0248 51.1704L22.328 50.8227L22.4466 53.3133L19.0945 53.473C28.5248 62.6558 43.6139 62.5793 52.9499 53.2433C60.9716 45.2216 62.1573 32.9526 56.5072 23.6752Z" fill="#82C3FF" />
	    </g>
      <rect width="2.63414" height="3.5122" transform="matrix(-1 0 0 1 36 23.7074)" fill="#6C6C6C" />
      <rect width="2.63414" height="3.5122" transform="matrix(-1 0 0 1 38.6343 23.7074)" fill="#3E3E3E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M27.2193 27.2196H22.8291V29.5439L27.2193 30.2756V37.7562H35.9998V27.2196H27.4776H27.2193Z" fill="#B5D7FF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M36 27.2196H44.5222H44.7805H49.1707V29.5439L44.7805 30.2756V37.7562H36V27.2196Z" fill="#82C3FF" />
      <path d="M49.1709 35.7221L36.0002 32.4879V47.4147H49.1709V35.7221Z" fill="#285596" />
      <path d="M22.8291 35.7221L35.9998 32.4879V47.4147H22.8291V35.7221Z" fill="#4380C7" />
      <rect opacity="0.7" width="13.1707" height="3.5122" transform="matrix(-1 0 0 1 49.1709 43.9026)" fill="#FF1F00" />
      <rect width="13.1707" height="3.5122" transform="matrix(-1 0 0 1 36 43.9026)" fill="#FF6F32" />
    </svg>
  </span>
);

Marketplace = pure(Marketplace);
Marketplace.displayName = 'Marketplace';
Marketplace.muiName = 'SvgIcon';

export default Marketplace;
