import React from 'react'
import BlurIfNoSubscription from './../Signup/BlurIfNoSubscription';

const defaultShouldBlurFn = field => props => !!field;
function ConditionalBlur({ isBlurred = false, shouldBlurFn, children, dontBlur,spanStyle={}, ...rest }) {
  shouldBlurFn = shouldBlurFn || defaultShouldBlurFn(isBlurred);

  const el = children.type ? React.Children.map(children, child => React.cloneElement(child, { ...(child.props || {}), isBlurred: isBlurred })) : children;
  return (
    <BlurIfNoSubscription spanStyle={spanStyle} dontBlur={dontBlur} shouldBlurFn={shouldBlurFn}>
      {
        el
      }
    </BlurIfNoSubscription>
  )
}

export default ConditionalBlur
