import React, { Component } from 'react';
import styled from "styled-components";
import {MinusButton, PlusButton} from "../Common/AddButton";
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";
import ContactBookCompanyAutocomplete from "./ContactBookCompanyAutocomplete";

const emailValidationProps = {
  validations: "isEmail",
  validationError: "please enter valid email",
  requiredError: "required",
};

const StyleWrapper = styled.div`
  >div {
    display: flex;
    align-items: center;
    >div:nth-child(1) {
      flex: 1;
    }
  }
`;


class ContactCompaniesEdit extends Component {

  handleChangeCompany = (i, value) => {
    const companies = [...this.props.companies];
    companies[i] = value;
    this.props.onChange(null, companies);
  }
  handleRemoveCompany = (i) => {
    const companies = this.props.companies.filter((company, j) => j !== i);
    this.props.onChange(null, companies);
  }
  handleAddCompany = () => {
    const companies = [...this.props.companies, {}];
    this.props.onChange(null, companies);
  }

  render() {
    return (
      <StyleWrapper>
        {this.props.companies.map((company, i) => {
          return (<div>
            <div>
              <ContactBookCompanyAutocomplete
                value={company}
                name={`companies[${i}]`}
                floatingLabelText={'Company'}
                validationErrors={{ isDefaultRequiredValue: 'required' }}
                validationError="Please select company"
                fullWidth
                onNewRequest={(value) => { this.handleChangeCompany(i, value); }}
              />
            </div>
            <div>{i === 0 ? null : <MinusButton onClick={this.handleRemoveCompany.bind(this, i)} />}</div>
          </div>);
        })}
      </StyleWrapper>
    );
  }
}

export default ContactCompaniesEdit;
