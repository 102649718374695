import React from 'react'
import RaisedButton from './RaisedButton';

const flexCenter = {display: 'flex', alignItems: 'center', flex: '1', minHeight: '28px'};
const btnStyle = {
  ...flexCenter,
  padding: '0px',
  lineHeight: '28px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #D2D2D2'
}
const label = {
  ...flexCenter,
  color: '#4380C7',
  fontSize: '11px',
  lineHeight: '13px',
  textTransform: 'capitalize',
  paddingLeft: '12px',
  paddingRight: '12px',
  fontWeight: 700,
  whiteSpace: 'nowrap',
}

function RoundBorderedBtn({ children, labelStyle = {}, buttonStyle = {}, ...rest }) {
  return (
    <RaisedButton labelColor={labelStyle.color} labelStyle={{ ...label, ...labelStyle }} rippleStyle={flexCenter} overlayStyle={flexCenter} buttonStyle={{...btnStyle, ...buttonStyle}} label={children} {...rest} />
  );
}

export default RoundBorderedBtn
