import { sortByPath } from '../../core/utils';

export function filterContract(contractList) {
  if (contractList.filters.query) {
    const newContractList = { ...contractList };
    const q = new RegExp(
      contractList.filters.query.replace(
        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
        '\\$&',
      ),
      'i',
    );
    newContractList.filtered = sortByPath(
      newContractList.data.filter(c => q.test(c.search)),
      contractList.sort.field,
      contractList.sort.value,
    );
    return newContractList;
  }
  contractList.filtered = sortByPath(
      contractList.data,
      contractList.sort.field,
      contractList.sort.value,
    );
  return contractList;
}

export default { filterContract, sortByPath };
