import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Api from './../../../core/api/api.js'

function debounce(func, time) {
  let timer = null;
  return function(...args) {
    const resolve = () => {
      func.apply(this, args);
      timer = null;
    };
    if (timer) {
      clearTimeout(timer);
      timer = setTimeout(resolve, time)
    }
    else {
      timer = setTimeout(resolve, time)
    }
  }
}

export default class withFetch extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    this.load = debounce(this.load, 250).bind(this);
  }
    static propTypes = {
        url: PropTypes.string
    }
    static defaultProps = {
        url: '',
        getFetchArgs: (text, url) => [
          `${url}?query=${text}`,
          {
            method: 'GET'
          }
      ]
    }
    componentDidMount() {
      this.load()
    }

    async load(text = '') {
      const {url} = this.props;
      try {
        const res = await Api.fetch(...this.props.getFetchArgs(text, url))
        let sortedData = Array.from(res.data);
        this.setState({data: sortedData})
      } catch (e) {
        console.error(e);
      }
    }


    render = () => {
      return this.props.render(this.state.data, this.load);
    }
  }
