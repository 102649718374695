import React from 'react'

function ChangesTriangle({vessel}) {
  if (!vessel) return null;
  const vesselHasChanges = vessel?.changes ? vessel.changes?.lastSeenVersion ? vessel.changes?.lastSeenVersion < vessel.changes?.lastDocVersion : true : null;
  return (
      vesselHasChanges === null
        ? null
        : vesselHasChanges === true
          ? <span className="gray_triangle_change small green"></span>
          : <span className="gray_triangle_change small"></span>
  )
}

export default ChangesTriangle
