/** @name MONITOR_DRAGEND_CARGO_REDUCER */
export default function MONITOR_DRAGEND_CARGO(state, action) {
  const newState = {};
  const vesselList = { ...state.vesselList };
  newState.vesselList = vesselList;
  vesselList.data = vesselList.data.map((v) => {
    v = { ...v };
    v.disabled = false;
    return v;
  });
  return { ...state, ...newState };
}
