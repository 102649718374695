/**@see  {@link EMAILS_GET_LIST_REDUCER}*/
export const EMAILS_GET_LIST = 'EMAILS_GET_LIST';
/**@see  {@link EMAILS_GET_LIST_REQUEST_REDUCER}*/
export const EMAILS_GET_LIST_REQUEST = 'EMAILS_GET_LIST_REQUEST';
/**@see  {@link EMAILS_GET_LIST_FAILED_REDUCER}*/
export const EMAILS_GET_LIST_FAILED = 'EMAILS_GET_LIST_FAILED';
/**@see  {@link EMAILS_TOGGLE_THEME_REDUCER}*/
export const EMAILS_TOGGLE_THEME = 'EMAILS_TOGGLE_THEME';
/**@see  {@link EMAILS_CHECK_EMAIL_REDUCER}*/
export const EMAILS_CHECK_EMAIL = 'EMAILS_CHECK_EMAIL';
/**@see  {@link EMAILS_CHECK_ALL_REDUCER}*/
export const EMAILS_CHECK_ALL = 'EMAILS_CHECK_ALL';
/**@see  {@link EMAILS_ARCHIVE_REDUCER}*/
export const EMAILS_ARCHIVE = 'EMAILS_ARCHIVE';
/**@see  {@link EMAILS_ARCHIVE_REQUEST_REDUCER}*/
export const EMAILS_ARCHIVE_REQUEST = 'EMAILS_ARCHIVE_REQUEST';
/**@see  {@link EMAILS_ARCHIVE_FAILED_REDUCER}*/
export const EMAILS_ARCHIVE_FAILED = 'EMAILS_ARCHIVE_FAILED';
/**@see  {@link EMAILS_LIST_CHANGES_REDUCER}*/
export const EMAILS_LIST_CHANGES = 'EMAILS_LIST_CHANGES';
/**@see  {@link EMAILS_GET_EMAIL_REDUCER}*/
export const EMAILS_GET_EMAIL = 'EMAILS_GET_EMAIL';
/**@see  {@link EMAILS_GET_EMAIL_REQUEST_REDUCER}*/
export const EMAILS_GET_EMAIL_REQUEST = 'EMAILS_GET_EMAIL_REQUEST';
/**@see  {@link EMAILS_GET_EMAIL_FAILED_REDUCER}*/
export const EMAILS_GET_EMAIL_FAILED = 'EMAILS_GET_EMAIL_FAILED';
/**@see  {@link EMAIL_SEND_REPLY_REDUCER}*/
export const EMAIL_SEND_REPLY = 'EMAIL_SEND_REPLY';
/**@see  {@link EMAIL_SEND_REPLY_REQUEST_REDUCER}*/
export const EMAIL_SEND_REPLY_REQUEST = 'EMAIL_SEND_REPLY_REQUEST';
/**@see  {@link EMAIL_SEND_REPLY_FAILED_REDUCER}*/
export const EMAIL_SEND_REPLY_FAILED = 'EMAIL_SEND_REPLY_FAILED';

/**@see  {@link EMAILS_EMAIL_READ_REDUCER}*/
export const EMAILS_EMAIL_READ_REDUCER = 'EMAILS_EMAIL_READ_REDUCER';
/**@see  {@link EMAILS_EMAIL_READ_REDUCER_REQUEST_REDUCER}*/
export const EMAILS_EMAIL_READ_REDUCER_REQUEST = 'EMAILS_EMAIL_READ_REDUCER_REQUEST';
/**@see  {@link EMAILS_EMAIL_READ_REDUCER_FAILED_REDUCER}*/
export const EMAILS_EMAIL_READ_REDUCER_FAILED = 'EMAILS_EMAIL_READ_REDUCER_FAILED';

/**@see  {@link EMAILS_MARK_REDUCER}*/
export const EMAILS_MARK = 'EMAILS_MARK';
/**@see  {@link EMAILS_MARK_REQUEST_REDUCER}*/
export const EMAILS_MARK_REQUEST = 'EMAILS_MARK_REQUEST';
/**@see  {@link EMAILS_MARK_FAILED_REDUCER}*/
export const EMAILS_MARK_FAILED = 'EMAILS_MARK_FAILED';

/**@see  {@link EMAILS_CHANGE_TAGS_REDUCER}*/
export const EMAILS_CHANGE_TAGS = 'EMAILS_CHANGE_TAGS';
/**@see  {@link EMAILS_CHANGE_TAGS_REQUEST_REDUCER}*/
export const EMAILS_CHANGE_TAGS_REQUEST = 'EMAILS_CHANGE_TAGS_REQUEST';
/**@see  {@link EMAILS_CHANGE_TAGS_FAILED_REDUCER}*/
export const EMAILS_CHANGE_TAGS_FAILED = 'EMAILS_CHANGE_TAGS_FAILED';

/**@see  {@link EMAILS_SEND_NEW_MESSAGE_REDUCER}*/
export const EMAILS_SEND_NEW_MESSAGE = 'EMAILS_SEND_NEW_MESSAGE';
/**@see  {@link EMAILS_SEND_NEW_MESSAGE_REQUEST_REDUCER}*/
export const EMAILS_SEND_NEW_MESSAGE_REQUEST = 'EMAILS_SEND_NEW_MESSAGE_REQUEST';
/**@see  {@link EMAILS_SEND_NEW_MESSAGE_FAILED_REDUCER}*/
export const EMAILS_SEND_NEW_MESSAGE_FAILED = 'EMAILS_SEND_NEW_MESSAGE_FAILED';

/**@see  {@link EMAILS_SHARE_EMAIL_REDUCER}*/
export const EMAILS_SHARE_EMAIL = 'EMAILS_SHARE_EMAIL';
/**@see  {@link EMAILS_SHARE_EMAIL_REQUEST_REDUCER}*/
export const EMAILS_SHARE_EMAIL_REQUEST = 'EMAILS_SHARE_EMAIL_REQUEST';
/**@see  {@link EMAILS_SHARE_EMAIL_FAILED_REDUCER}*/
export const EMAILS_SHARE_EMAIL_FAILED = 'EMAILS_SHARE_EMAIL_FAILED';








