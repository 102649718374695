import React from 'react';
import s from './Home.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

function MainPageHero({
  children,
  className = "",
  ...rest
}) {
  return (
    <div className={cx(s.column, className)} {...rest}>
      <h1 className={cx(s.home_title)}>
        <span className={s.desktop}>The shipping platform</span>
      </h1>
      <h5 className={s.home_subtitle}>
        manage your shipping data, trade and automate work-flows
      </h5>
      <div className={cx(s.desktop_buttons)}>
        {
          children
        }
      </div>
    </div>
  )
}

export default withStyles(s)(MainPageHero);
