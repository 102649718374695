/** @name EMAILS_ARCHIVE_REDUCER */
export default function EMAILS_ARCHIVE(state, action) {
  const ids = action.payload.ids;
  const newState = {
    emailList: {
      ...state.emailList,
      data: state.emailList.data.filter(e => ids.indexOf(e.id) === -1),
    },
  };
  return { ...state, ...newState };
}
