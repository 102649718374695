import cx from "classnames";
import { gearLabels, gearTypes } from "../../../Monitor/Edit/config";
import MenuItem from "material-ui/MenuItem";
import NarrowFormsyText from "../../../NewInputs/NarrowFormsyText";
import React from "react";
import { MinMaxInput } from "./MinMaxInput";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { NarrowSelect } from "../../../NewInputs/NarrowSelect";
import s from './GearsForm.scss'
import { ContentAddButton, ContentClearButton } from "../../../Common/AddButton";

const CAPACITY_MIN = 0;
const CAPACITY_MAX = 600;

export const GearsForm = withStyles(s)(({
  geared,
  prefix,
  gearV2,
  blockTitleClass,
  onChangeGeared,
  onChange,
}) => {
  const rows = gearV2?.summary?.length ? gearV2.summary : [{}]

  const changeValue = (index, key, value) => {
    onChange({
      geared: true,
      gearV2: {
        summary: rows.map((x, i) =>
          i === index ? { ...x, [key]: value } : x
        )
      },
    })
  }

  const handleAddRow = () => {
    onChange({ gearV2: { summary: [...rows, {}] } })
  }

  const handleRemoveRow = (index) => {
    onChange({ gearV2: { summary: rows.filter((_, i) => i !== index) } })
  }

  return (
    <div>
      <div className={cx(s.flex, s.items_center)}>
        <h6 className={cx(s.mr_18px, blockTitleClass)}>GEAR</h6>
        <div className={s.flex} style={{ width: '100px' }}>
          <NarrowSelect
            inputStyles={{ fontWeight: 700 }}
            underlineStyle={{ display: 'none' }}
            name={`${prefix}[geared]`}
            fullWidth
            value={geared}
            onChange={(_, value) => onChangeGeared(value)}
          >
            <MenuItem value={undefined} primaryText="Gears not set"/>
            <MenuItem value={true} primaryText="Geared"/>
            <MenuItem value={false} primaryText="Gearless"/>
          </NarrowSelect>
        </div>
      </div>
      <div className={cx(s.flex, s.w_full)}>
        <div className={cx(s.w_full)}>
          {rows.map((row, i) => (
            <div key={i} className={cx(s.flex, s.items_center, s.w_full, s.gap_16px)}>
              <div style={{ width: '26%' }}>
                <NarrowSelect
                  floatingLabelText="Type"
                  name={`${prefix}[gearV2][summary][${i}][type]`}
                  maxHeight={271}
                  fullWidth
                  value={row?.type || ''}
                  onChange={(_, value) => changeValue(i, 'type', value)}
                  disabled={geared === false}
                >
                  {gearTypes.map(gearType => (
                    <MenuItem key={gearType} value={gearType} primaryText={gearLabels[gearType] || gearType}/>
                  ))}
                </NarrowSelect>
              </div>
              <div style={{ width: '10%' }}>
                <NarrowFormsyText
                  floatingLabelText="Quantity"
                  validations={'requiredIf:' + prefix + '[gearV2][' + i + '][capacity],isNumeric,isInt,min:0,max:20'}
                  validationErrors={{
                    isInt: 'only integer',
                    isNumeric: 'only numbers',
                    min: '0 - 20',
                    max: '0 - 20',
                    requiredIf: "required"
                  }}
                  disabled={!row?.type || geared === false}
                  onBlur={(_, value) => changeValue(i, 'quantity', value)}
                  value={row?.quantity || ''}
                  name={`${prefix}[gearV2][${i}][quantity]`}
                  fullWidth
                />
              </div>
              <div style={{ width: '19%' }}>
                <MinMaxInput
                  val={row.capacityMax}
                  min={CAPACITY_MIN}
                  max={CAPACITY_MAX}
                >
                  <NarrowFormsyText
                    floatingLabelText="Capacity min, mt"
                    disabled={!row?.type || geared === false}
                    onBlur={(_, value) => changeValue(i, 'capacityMin', value)}
                    name={`${prefix}[gearV2][${i}][capacityMin]`}
                    value={row?.capacityMin || ''}
                    fullWidth
                  />
                </MinMaxInput>
              </div>
              <div style={{ width: '19%' }}>
                <MinMaxInput
                  val={row?.capacityMin}
                  min={CAPACITY_MIN}
                  max={CAPACITY_MAX}
                  type="max"
                >
                  <NarrowFormsyText
                    floatingLabelText="Capacity max, mt"
                    disabled={!row?.type || geared === false}
                    onBlur={(_, value) => changeValue(i, 'capacityMax', value)}
                    name={`${prefix}[gearV2][${i}][capacityMax]`}
                    value={row?.capacityMax || ''}
                    fullWidth
                  />
                </MinMaxInput>
              </div>
              <div style={{ width: '16.5%' }}>
                <NarrowFormsyText
                  floatingLabelText="Outreach, m"
                  validations="isNumeric,min:0,max:50"
                  validationErrors={{
                    isNumeric: 'only numbers',
                    min: '0 - 50',
                    max: '0 - 50',
                  }}
                  disabled={!row?.type || geared === false}
                  name={`${prefix}[gearV2][${i}][outreach]`}
                  value={row?.outreach || ''}
                  fullWidth
                  onBlur={(_, value) => changeValue(i, 'outreach', value)}
                />
              </div>
              <div>
                {i === rows.length - 1
                  ? <ContentAddButton onClick={handleAddRow}/>
                  : <ContentClearButton onClick={() => handleRemoveRow(i)}/>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
});
