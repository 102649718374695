import { clearCookie } from '../../core/utils';
import finishInvite from './finishInvite';

export default function REGISTER(state, { res }) {
  const newState = {
    user: res.data,
    error: null,
    loading: false,
    message: null,
    afterQueue: [],
  };
  if (res.status === 200 && typeof document !== 'undefined') {
    clearCookie('redirectAfterLogin');
    finishInvite(state);
  }
  return { ...state, ...newState };
}
