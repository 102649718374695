
export default function FLEET_VESSEL_CHANGES_SEEN(state, {payload: {id, changes}}) {
  const {data, filtered} = state.vesselList;
  const newChanges = {
    ...changes,
    lastSeenVersion: changes.lastDocVersion
  }
  const newData = updateVesselChanges(data, id, newChanges);
  const newFiltered = updateVesselChanges(filtered, id, newChanges)
  return { ...state, vesselList: {...state.vesselList, data: newData, filtered: newFiltered}};
}

export function updateVesselChanges(arr, id, changes) {
  return updateVessel(arr, id, vessel => ({ changes: { ...vessel.changes, ...changes } }));
}

export function updateVessel(arr, id, updateObj) {
  const index = arr.findIndex(v => v._id === id);
  if (index < 0) {
    return arr;
  }
  const newVessel = {
    ...arr[index],
    ...(typeof updateObj === 'function' ? updateObj(arr[index]) : updateObj)
  }
  const newArr = [...arr];
  newArr[index] = newVessel;
  return newArr;
}
