import React, { Component } from 'react';
import s from '../Monitor/TableNew.scss';
import ArrowPopover from './ArrowPopover';
import Link from '../Link';
import MenuItem from 'material-ui/MenuItem';


class NavigationPopover extends Component {

  static defaultProps = {
    links: [],
  }

  renderLinks = list => {
    return list.map(item => {
      const url = `${item.url}`;
      return (
        <Link to={url} key={item.url}>
          <MenuItem primaryText={item.label || item.name} value={item.name} />
        </Link>
      )
    })
  }

  render() {
    return (
      <ArrowPopover renderer={this.renderLinks} val={this.props.val || this.props.links[0]?.name}
                    list={this.props.links} displayLabel={(val => {
                      let link = this.props.links.find(l => l.name === val);
                      if (!link) {
                        link = this.props.links[0];
                      }
                      return link.label || link.name;
                    })} />
    );
  }
}

export default NavigationPopover;
