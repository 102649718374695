import React from 'react'
import t from './../TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
function Col({ isBold = false, right = false, w, minW, style = {}, children, className = "", ...rest }) {
  return (
    <div className={cx(t.text, t.row_block, isBold && t.bold_text, className)} style={{ ...style, ...(minW ? { minWidth: minW } : {}), ...(w ? { width: w } : {}), ...(right ? { alignItems: 'flex-end' } : {}) }} {...rest}>
      {
        children
      }
    </div>
  )

}

export default Col;
