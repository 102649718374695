import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSubscribeDialog } from '../../actions/login';
import { isUserSubscribedToMarketplace } from '../../core/utils';
import history from '../../core/history';


const blurStyle = { filter: 'blur(3px)', display: 'inline-block', cursor: 'pointer'};
const dontBlurStyle = {cursor: 'pointer'};



class BlurIfNoSubscription extends PureComponent {

  static propTypes = {
    user: PropTypes.object,
    shouldBlurFn: PropTypes.func,
    dontBlur: PropTypes.bool,
  };
  static defaultProps = {
    user:null,
    shouldBlurFn: () => true,
    dontBlur: false,
  };
  state = {};
  constructor(props) {
    super(props);
    this._placeholder = "********";
    this.state.subscribedToMarketplace = isUserSubscribedToMarketplace(this.props.user);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.user !== this.props.user) {
      this.setState({subscribedToMarketplace: isUserSubscribedToMarketplace(nextProps.user)});
    }
  }

  handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
      e.target.checked = !e.target.checked;
    }
    if(!this.props.user) {
      return history.push('/login')
    }
    this.props.toggleSubscribeDialog(true);
  };

  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    let _children = this.props.children;
    const {spanStyle={}}=this.props;
    if(!_children) {
      return null;
    }
    const dontBlur = this.state.subscribedToMarketplace || !this.props.shouldBlurFn(this.props);
    if(dontBlur) {
      if(!_children.type) {
        return <span title={this.props.title} style={spanStyle}>{this.props.children}</span>;
      } else {
        return this.props.children;
      }

    }
    if (typeof _children === "function") {
      _children = _children({
        shouldBlur: !dontBlur
      })
    }
    if(_children.type) {
      _children = React.cloneElement(_children,{className: `${_children.props.className} ${this.props.classes || ""}`,style: {..._children.props.style, pointerEvents: 'none'}, href: undefined, onClick:undefined, title: undefined})
    }
    if (this.props.showPlaceholder && typeof _children === 'string') {
      const text = String(_children).replace(/-*/gi, "");
      _children = text.length > 0 ? _children : this._placeholder
    }
    return <span className="blur-container" style={this.props.dontBlur ? {...dontBlurStyle,...spanStyle} : {...blurStyle,...spanStyle}} onClick={this.handleClick}>{_children}</span>
  }

}
const _BlurIfNoSubscription = connect(
  state => (state.login),
  { toggleSubscribeDialog },
)(BlurIfNoSubscription);
export default _BlurIfNoSubscription


export class BlurEmailIfNoSubscription extends PureComponent{
  static shouldBlurEmail = function(props) {
    if(!props.children){
      return false;
    }
    if(typeof props.children === 'string' && props.children.indexOf('******@') === 0) {
      return true;
    }
    if(props.children.type === 'a'){
      if(props.children.props.href && props.children.props.href.indexOf('mailto:******@') === 0 ) {
        return true;
      }
    }

    if(props.children.type &&  typeof props.children.props.children === 'string' && props.children.props.children.indexOf("******@") === 0 ){
      return true;
    }

    return false;
  };

  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return <_BlurIfNoSubscription {...this.props}  />
  }

}
