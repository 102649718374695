import React from 'react'
import s from './RoundActionBtn.scss';
import IconButton from 'material-ui/IconButton';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CustomTooltip from './CustomTooltip';
import IconMenu from 'material-ui/svg-icons/navigation/menu';
import cx from 'classnames';
function RoundActionBtn({ children, tooltipProps = {}, tooltip = "", iconClassName = "", tooltipClassName = "", dark = true,  ...rest }) {

  const internals = (
    <IconButton className={cx(s.btn_icon, iconClassName)} {...rest}>
        {
          typeof children === 'function' ? children() : children
        }
    </IconButton>
  )

  return (
    <div className={s.btn_wrapper}>
      {
        tooltip
          ? (
            <CustomTooltip dark={dark} tooltip={tooltip} className={tooltipClassName} {...tooltipProps}>
              {internals}
            </CustomTooltip>
          )
          : internals
      }
    </div>
  )
}

export default withStyles(s)(RoundActionBtn);
