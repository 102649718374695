import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sn from './ProfileInfo.scss';
import FormsyText from '../../components/Common/FormsyText';
import SectorSelect from '../../components/Common/SectorSelect';
import OrganizationSelect from '../../components/Common/OrganizationSelect';
import CompanyAutocomplete from '../../components/Common/CompanyAutocomplete';
import CityCountry from '../../components/Common/CityCountry';
import FormsyPhoneNumber from '../Common/FormsyPhoneNumber';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from 'material-ui/svg-icons/content/remove';
import { removeFromArray } from '../../core/utils';
import cx from 'classnames';
import Camera from 'material-ui/svg-icons/action/camera-enhance';
import RaisedButton from '../Common/RaisedButton';


const hintStyle = { fontSize: '14px' };
const floatingLabelStyle = { fontSize: '14px', color: 'rgba(#000000, .87)' };
const floatingLabelFocusStyle = { fontSize: '16px', color: '#999999' };
const floatingLabelShrinkStyle = { fontSize: '16px', color: '#999999' };
const inputStyle = { fontSize: '14px' };

class _ProfileEdit extends Component {
  static PropTypes = {
    profile: PropTypes.object,
    uploadImage: PropTypes.func,
  };
  static defaultProps = {
    profile: { phones: [] },
  };
  constructor(props) {
    super(props);
    this.state = { ...props.profile };
    this.state.phones = (this.state.phones && this.state.phones.slice(1)) || [];
    this.state.icon = props.profile.company.icon;
  }

  state = {};

  handleCountryChange = (country) => {
    this.setState({ country });
  };

  uploadImg = (e) => {
    const input = e.target;
    if (!input.files || !input.files[0]) return;
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('type', 'image');
    this.props.uploadImage(formData).then((res) => {
      if (res.status === 200) {
        this.setState({ icon: res.data._id });
      }
    })
  };

  render() {
    return (
      <div className={cx(sn.wrapper_profile, sn.form, sn.wrapper_profile_edit)}>
        <div className={cx(sn.info_owner_edit)}>
          <div>
            <FormsyText
              hintText="Your Name*"
              floatingLabelText="Your Name*"
              validations="minLength:2"
              validationError="at least 2 characters"
              requiredError="required"
              name="name"
              required
              fullWidth
              hintStyle={hintStyle}
              floatingLabelStyle={floatingLabelStyle}
              floatingLabelFocusStyle={floatingLabelFocusStyle}
              floatingLabelShrinkStyle={floatingLabelShrinkStyle}
              inputStyle={inputStyle}
            />
            <FormsyText
              hintText="Surname*"
              floatingLabelText="Surname*"
              validations="minLength:2"
              validationError="at least 2 characters"
              requiredError="required"
              name="surname"
              required
              fullWidth
              hintStyle={hintStyle}
              floatingLabelStyle={floatingLabelStyle}
              floatingLabelFocusStyle={floatingLabelFocusStyle}
              floatingLabelShrinkStyle={floatingLabelShrinkStyle}
              inputStyle={inputStyle}
            />
          </div>
          <div className={cx(sn.contacts_edit)}>
            <div className={sn.email_edit}>
              <FormsyText
                className={sn.wrapper_email}
                hintText="E-mail*"
                floatingLabelText="E-mail*"
                validations="minLength:2"
                validationError="at least 2 characters"
                requiredError="required"
                name="email"
                disabled
                required
                fullWidth
                hintStyle={hintStyle}
                floatingLabelStyle={floatingLabelStyle}
                floatingLabelFocusStyle={floatingLabelFocusStyle}
                floatingLabelShrinkStyle={floatingLabelShrinkStyle}
                inputStyle={inputStyle}
              />
              <div className={sn.add_email_button}>
              </div>
            </div>
            <Phones country={this.state.country} phones={this.props.profile.phones} name="phones" />
          </div>
        </div>
        {this.props.profile.companyRole === 'owner' ? (
        <div className={sn.info_company_edit}>
          <div className={sn.about_company}>
            <div className={sn.wrapper_image}>
              <div className={sn.image}>
                {this.state.icon ?
                  <img src={this.state.icon.url || ('/api/v1/file/' + (this.state.icon._id || this.state.icon))} alt="company logo" />
                  : null}
                <Camera
                  style={{
                    position: 'absolute',
                    right: '-2px',
                    top: '0',
                    zIndex: '2',
                    width: '32px',
                    height: '32px',
                    padding: '2px',
                    backgroundColor: '#7FC931',
                  }}
                  color={'#ffffff'}
                />
                <input name="img" id="img" type="file" accept="image/*" onChange={this.uploadImg} />
                <FormsyText
                  name="icon"
                  value={this.state.icon}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className={sn.info}>
              <div>
                <h2>{this.props.profile.company.name}</h2>
              </div>

              <div className={sn.sector_company_edit}>
                <div className={cx(sn.text_block, sn.sector)}>
                  <SectorSelect
                    className={sn.sector}
                    hintText="Industry*"
                    floatingLabelText="Industry*"
                    requiredError="required"
                    name="company[sector]"
                    autoWidth
                    required
                    fullWidth
                    hintStyle={hintStyle}
                    floatingLabelStyle={floatingLabelStyle}
                    floatingLabelFocusStyle={floatingLabelFocusStyle}
                    floatingLabelShrinkStyle={floatingLabelShrinkStyle}
                    inputStyle={inputStyle}
                  />
                </div>
                <div className={cx(sn.text_block, sn.organization)}>
                  <OrganizationSelect
                    className={sn.organization}
                    hintText="Organization*"
                    floatingLabelText="Organization*"
                    requiredError="required"
                    name="company[organization]"
                    required
                    fullWidth
                    autoWidth
                    hintStyle={hintStyle}
                    floatingLabelStyle={floatingLabelStyle}
                    floatingLabelFocusStyle={floatingLabelFocusStyle}
                    floatingLabelShrinkStyle={floatingLabelShrinkStyle}
                    inputStyle={inputStyle}
                  />
                </div>
              </div>

              <div className={sn.contacts_edit}>
                <Emails
                  name={'company[emailAddresses]'}
                  emails={this.props.profile && this.props.profile.company && this.props.profile.company.emailAddresses}
                />
                <Phones name="company[phones]" country={this.state.country} phones={this.props.profile && this.props.profile.company && this.props.profile.company.phones} />
              </div>

              <div className={sn.e_contacts}>
                <div className={sn.web_site}>
                  <FormsyText
                    hintText="Web Site"
                    floatingLabelText="Web Site"
                    validations="isSite"
                    validationError="please enter valid URL"
                    requiredError="required"
                    name="company[site]"
                    fullWidth
                    hintStyle={hintStyle}
                    floatingLabelStyle={floatingLabelStyle}
                    floatingLabelFocusStyle={floatingLabelFocusStyle}
                    floatingLabelShrinkStyle={floatingLabelShrinkStyle}
                    inputStyle={inputStyle}
                  />
                </div>
              </div>

              <div>
                <div className={sn.address}>
                  <FormsyText
                    hintText="Address*"
                    floatingLabelText="Address*"
                    name="company[address]"
                    required
                    requiredError="required"
                    fullWidth
                    hintStyle={hintStyle}
                    floatingLabelStyle={floatingLabelStyle}
                    floatingLabelFocusStyle={floatingLabelFocusStyle}
                    floatingLabelShrinkStyle={floatingLabelShrinkStyle}
                    inputStyle={inputStyle}
                  />
                </div>
              </div>

              <div>
                <div className={sn.contacts}>
                  <CityCountry
                    countryName="company[country]"
                    cityName="company[city]"
                    required
                    onCountryChange={this.handleCountryChange}
                    ref="cityCountry"
                    style={{ width: ' 48%' }}
                    wrapperStyle={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    objClassName={true}
                    hintStyle={hintStyle}
                    floatingLabelStyle={floatingLabelStyle}
                    floatingLabelFocusStyle={floatingLabelFocusStyle}
                    floatingLabelShrinkStyle={floatingLabelShrinkStyle}
                    inputStyle={inputStyle}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        ): null}
      </div>
    );
  }
}

class Phones extends Component {
  static propTypes = {
    phones: PropTypes.array,
    country: PropTypes.object,
    name: PropTypes.string,
  };

  static defaultProps = {
    phones: [],
    name: 'phones',
  };

  state = {};

  constructor(props) {
    super(props);
    this.state.phones = props.phones.slice(1);
  }
  handleAddPhone = () => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ phones: [...this.state.phones, { _id: Math.random() }] });
  };
  handleRemovePhone = (i) => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ phones: removeFromArray(this.state.phones, i) });
  };

  render() {
    return <div className={sn.phone_edit_wrapper}>
      <div className={sn.phone_edit}>
        <FormsyPhoneNumber
          name={this.props.name + '[0]'}
          validations="isPhoneNumber"
          validationErrors={{
            isPhoneNumber: 'please enter valid phone number',
          }}
          spPhone={this.props.spPhone || null}
          defaultCountry={this.props.country}
          defaultValue={this.props.phones[0]}
          previousValue={this.props.originPhones ? this.props.originPhones[0] || '' : null}
        />
        <span onClick={this.handleAddPhone} className={sn.add_phone_button}>
          <ContentAdd />
        </span>
      </div>
      {this.state.phones.map((phone, i) => {
        return (
          <div key={phone._id} className={sn.phone_edit}>
            <FormsyPhoneNumber
              name={this.props.name + '[' + (i + 1) + ']'}
              validations="isPhoneNumber"
              validationErrors={{
                isPhoneNumber: 'please enter valid phone number',
              }}
              noAsterisk
              defaultCountry={this.props.country}
              spPhone={this.props.spPhone || null}
              defaultValue={this.props.originPhones ? this.props.phones[i + 1] || '' : this.state.phones[i]}
              previousValue={this.props.originPhones ? this.props.originPhones[i + 1] || '' : null}

            />
            <span
              onClick={this.handleRemovePhone.bind(this, i)}
              className={sn.add_phone_button}
            >
              <ContentRemove />
            </span>
          </div>
        );
      })}
    </div>;
  }
}

class Emails extends Component {

  static propTypes = {
    emails: PropTypes.array,
    name: PropTypes.string,
  };
  static defaultProps = {
    name: 'emailAddresses',
    emails: [''],
  };

  state={};
  handleAddEmail = () => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ emails: [...this.state.emails, { _id: Math.random() }] });
  };
  handleRemoveEmail = (i) => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ emails: removeFromArray(this.state.emails, i) });
  };
  constructor(props) {
    super(props);
    this.state.emails = this.props.emails.slice(1).map(s => ({ value: s, _id: Math.random() }));
  }

  render() {
    const { floatingLabelStyle, floatingLabelFocusStyle, floatingLabelShrinkStyle, inputStyle, hintText, floatingLabelText } = this.props;
    return (<div className={sn.email_edit_wrapper}>
      <div className={sn.email_edit}>
        <FormsyText
          className={sn.wrapper_email}
          hintText={hintText ? hintText : "E-mail*"}
          floatingLabelText={floatingLabelText ? floatingLabelText : "E-mail*"}
          validations="isEmail"
          validationError="please enter valid email"
          requiredError="required"
          name={`${this.props.name}[0]`}
          required
          fullWidth
          hintStyle={hintStyle}
          floatingLabelStyle={floatingLabelStyle}
          floatingLabelFocusStyle={floatingLabelFocusStyle}
          floatingLabelShrinkStyle={floatingLabelShrinkStyle}
          inputStyle={inputStyle}
          defaultValue={this.props.emails[0] || ''}
          previousValue={this.props.originEmails ? this.props.originEmails[0] || '' : null}
        />
        <div className={sn.add_email_button}>
          <span className={sn.add_phone_button} onClick={this.handleAddEmail}>
            <ContentAdd />
          </span>
        </div>
      </div>
      {this.state.emails.map((email, i) => {
        return (
          <div key={email._id} className={sn.email_edit}>
            <FormsyText
              className={sn.wrapper_email}
              hintText="E-mail:"
              floatingLabelText="E-mail:"
              validations="isEmail"
              validationError="please enter valid email"
              requiredError="required"
              name={`${this.props.name}[${i + 1}]`}
              required
              fullWidth
              hintStyle={hintStyle}
              floatingLabelStyle={floatingLabelStyle}
              floatingLabelFocusStyle={floatingLabelFocusStyle}
              floatingLabelShrinkStyle={floatingLabelShrinkStyle}
              inputStyle={inputStyle}
              defaultValue={this.props.originEmails ? this.props.emails[i + 1] || '' : this.state.emails[i] && this.state.emails[i].value}
              previousValue={this.props.originEmails ? this.props.originEmails[i + 1] || '' : null}
            />
            <div className={sn.add_email_button}>
              <span className={sn.add_phone_button} onClick={this.handleRemoveEmail.bind(this, i)} >
                <ContentRemove />
              </span>
            </div>
          </div>
        );
      })}

    </div>);
  }
}

export const ProfileEdit = withStyles(sn)(_ProfileEdit);

export const Phone = withStyles(sn)(Phones);

export const Email = withStyles(sn)(Emails);
