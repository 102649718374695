import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Main_Deck = ({ backColor = "#FFFFFF", opacity = "0.4", ...props }) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1">
        <defs />
        <g
          id="WEB"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={opacity}
        >
          <g
            id="Menu-Opened"
            transform="translate(-15.000000, -114.000000)"
            fill={backColor}
          >
            <g
              id="Icons/Main-Deck-Copy"
              transform="translate(12.000000, 110.000000)"
            >
              <path
                d="M21,4 L21,19.9966184 L13,19.9966184 L13,4 L21,4 Z M16.1715729,9.99661838 L14.5857864,11.5824048 L16,12.9966184 L20,8.99661838 L19,8.99661838 L20,8.99661838 L16,4.99661838 L14.5857864,6.41083195 L16.1715729,7.99661838 L13,7.99661838 L13,9.99661838 L16.1715729,9.99661838 Z"
                id="Combined-Shape"
              />
              <path
                d="M11,4 L11,19.9966184 L3,19.9966184 L3,4 L11,4 Z M6.17157288,9.99661838 L4.58578644,11.5824048 L6,12.9966184 L10,8.99661838 L9,8.99661838 L10,8.99661838 L6,4.99661838 L4.58578644,6.41083195 L6.17157288,7.99661838 L3,7.99661838 L3,9.99661838 L6.17157288,9.99661838 Z"
                id="Combined-Shape-Copy-2"
                transform="translate(7.000000, 11.998309) rotate(180.000000) translate(-7.000000, -11.998309) "
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  </span>
);

Main_Deck = pure(Main_Deck);
Main_Deck.displayName = 'Main_Deck';
Main_Deck.muiName = 'SvgIcon';

export default Main_Deck;
