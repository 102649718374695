export default function SNP_UPDATE_SINGLE(state, action) {
  const snpList = [...state.snpList.data];

  const filteredList = [
    ...state.snpList.filtered
  ];

  const index = snpList.findIndex(order => order._id === action.payload._id);

  if (index < 0) {
    return {...state}
  }

  snpList[index] = {
    ...snpList[index],
    ...action.payload
  }

  const filteredIndex = filteredList.findIndex(order => order._id === action.payload._id);

  if (filteredList[filteredIndex]) {
    filteredList[filteredIndex] = {
      ...filteredList[filteredIndex],
      ...action.payload
    }
  }

  //TODO implement reducer
  return { ...state, snpList: { ...state.snpList, filtered: filteredList, data: [...snpList] } };
}
