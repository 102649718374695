import React, { Component } from 'react'
import Blur from './../../Signup/BlurIfNoSubscription';
import cx from "classnames";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Loader from './../../Common/Loader'
import s from './Comments.scss';
import Vessel from '../../../core/api/Vessel';
import SortIcon from 'material-ui/svg-icons/action/swap-vert';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import {PublicPrivate} from './../../Common/RatingBlock'
import Comment from './Comment.js';
import PubSub from 'pubsub-js';
import Pagination from './../../Common/Pagination';
import CommentsApi from '../../../core/api/CommentsApi';

const rowsPerPage = [5,50,100];

export class Comments extends Component {
  static defaultProps = {
    alertClasses: "",
    entityType: 'vessel',
    publicToggleEnabled: true,
  }
  constructor(props) {
    super(props);
    this.state = {
      commentsRequested: false,
      comments: [],
      sort: -1,
      page: 1,
      limit: 5,
      isPrivate: true,
      containerHeight: 0,
      count: null
    }
    this.getComments = this.getComments.bind(this);
  }
  componentDidMount() {
    PubSub.subscribe(`${this.props.entityType}:commentsUpdated`, this.getComments);
    this.getComments();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.entity._id !== prevProps.entity._id) {
      this.getComments();
    }
  }
  componentWillUnmount() {
    PubSub.unsubscribe(this.getComments);
  }
  getComments(topic, newComment) {
    let isPrivate = this.state.isPrivate;
    if (newComment) {
      if ((newComment.entityId === this.props.entity._id || newComment.entityId === this.props.entity.id) && newComment.entityType === this.props.entityType) {
        if (isPrivate && newComment.isPrivate === false) {
          this.setState({ isPrivate: false });
          isPrivate = false;
        }
      } else {
        return;
      }
    }
    const commentObj = {
      entityId: this.props.entity._id,
      entityType: this.props.entityType,
      sort: this.state.sort,
      page: this.state.page,
      limit: this.state.limit,
      isPrivate,
    };
    if (!this.props.publicToggleEnabled) {
      commentObj.isPrivate = undefined;
    }
    this.setState({
      containerHeight: this.refs.comments ? this.refs.comments.offsetHeight : 300
    })
    this.loaderTimeout = setTimeout(() => this.setState({commentsRequested: false}), 600);
    if (this.props.isContactComment) {
      commentObj.entityId = this.props.entity.id;
      CommentsApi.getContactComments(commentObj)
      .then(res => {
        if (this.loaderTimeout) {
          clearTimeout(this.loaderTimeout);
          this.loaderTimeout = null;
        }
        if (res.data) {
          this.setState({
            comments: res.data,
            count: res.headers.get("X-Total-Count"),
          });
          if (this.props.commentsLoaded) {
            this.props.commentsLoaded({
              total: res.headers.get("X-Total-Count"),
            });
          }
        }
        this.setState({
          commentsRequested: true
        })
      })
      .catch(e => {
        console.log(e);
        this.setState({
          commentsRequested: true
        })
      })
    } else {
      Vessel.getComments(commentObj)
      .then(res => {
        if (this.loaderTimeout) {
          clearTimeout(this.loaderTimeout);
          this.loaderTimeout = null;
        }
        if (res.status === 200) {
          this.setState({
            comments: res.data.comments,
            count: res.data.count,
            total: res.data.total,
          });
          if (this.props.commentsLoaded) {
            this.props.commentsLoaded(res.data);
          }
        }
        this.setState({
          commentsRequested: true
        })
      })
      .catch(e => {
        console.log(e);
        this.setState({
          commentsRequested: true
        })
      })
    }
  }
  changeSort = (val) => {
    if (this.state.sort === val) return;
    this.setState({
      sort: val
    }, this.getComments)
  }
  changeToggle = (val) => {
    if (this.state.isPrivate === val) return;
    this.setState({
      isPrivate: val,
      page: 1
    }, this.getComments)
  }
  handlePagination = ({page, numberOfRows: limit}) => {
    if (this.state.page === page && this.state.limit === limit) return;
    this.setState(state => ({...state, limit, page, }), this.getComments);
  }
  render() {
    const {sort, comments, count, containerHeight, page, limit, total} = this.state;
    let bars;
    switch (this.props.entityType) {
      case 'vessel':
        bars = <Bars rating={this.props.entity?.rating} />;
        break;
      default:
        bars = null;
    }
    return (
      <div className={s.comments}>
        <Blur classes={s.disable_events}>
          <div>
            {bars}
            {
              !this.state.commentsRequested
                ? <div style={{position: 'relative', height: containerHeight + 'px', width: '100%'}}>
                  <Loader></Loader>
                </div>
                : (
                  <div ref="comments" className={s.comments_wrapper}>
                    <div className={s.comments_header}>
                      <div className={s.left_side}>
                        {
                          comments && comments.length
                            ? (
                              <p className={s.comments_count}>
                                {count} {count === 1 ? 'comment' : 'comments'}
                              </p>
                            )
                            : <p className={s.comments_count}>
                                0 comments
                              </p>
                        }
                        <SortPopover sort={sort} changeSort={this.changeSort}></SortPopover>
                      </div>
                      {this.props.publicToggleEnabled ? <PublicPrivate bottomArrow offset={{x: 8, y: -100}} whiteArrow={false} horizontalPosition="left" dark={true} positionInsideTransform={false} alertClasses={this.props.alertClasses} selectedtoggle={this.state.isPrivate} changeToggle={this.changeToggle}></PublicPrivate> : null}
                   </div>

                      {
                        comments?.length
                          ? (
                            <div>
                              <div className={s.comments_body}>
                                  {
                                    comments?.length && comments.map((comment, index) => <Comment key={comment?._id ?? index} comment={comment} />)
                                  }
                              </div>
                              <Pagination text="Comments per page" hidePageSelection updateRows={this.handlePagination} page={page} numberOfRows={limit} rowsPerPage={rowsPerPage} total={count}></Pagination>
                            </div>
                          )
                          : null
                      }
                  </div>
                )
            }
          </div>
        </Blur>
      </div>
    )
  }
}

class SortPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchor: null
    };
  }
  handleRequestClose = () => {
    this.setState({open: false});
  }
  showPopover = (ev) => {
    this.setState({open: true, anchor: ev.currentTarget});
  }
  changeSort = (ev, val) => {
    this.setState({
      open: false
    }, () => this.props.changeSort(val))
  }
  render() {
    const {sort, changeSort} = this.props;
    return (
             <div className={cx(s.text, s.sort)}>
                  Sort reviews by:
                  <div onClick={this.showPopover}>
                    <SortIcon color="#4D9E0E" style={{margin: '0px 8px', width: 24, height: 24}} />
                    <span>
                      {
                        sort > 0 ? 'Older to newer' : 'Newer to older'
                      }
                    </span>
                    <Popover
                        open={this.state.open}
                        anchorEl={this.state.anchor}
                        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                        targetOrigin={{horizontal: 'left', vertical: 'top'}}
                        onRequestClose={this.handleRequestClose}
                      >
                        <Menu onChange={this.changeSort}>
                          <MenuItem value={-1} primaryText="Newer to older" />
                          <MenuItem value={1} primaryText="Older to newer" />
                        </Menu>
                    </Popover>
                </div>
            </div>
    )
  }
}

function Bars(props) {
  return (
    <div className={s.bars}>
      <div className={s.technical}>
        <p className={s.text}>Technical condition</p>
        <span className={s.bar}>
        <span className={s.progress} style={{width: props?.rating?.averageTechnicalCondition ? props.rating.averageTechnicalCondition * 100 + "%" : '0%'}}></span>
        </span>
        <p className={s.text}>{props?.rating?.averageTechnicalCondition ? (props.rating.averageTechnicalCondition * 5).toFixed(1) : 0}</p>
      </div>
      <div className={s.operational}>
        <p className={s.text}>Operational Response</p>
        <span className={s.bar}>
          <span className={s.progress} style={{width: props?.rating?.averageOperationResponse ? props.rating.averageOperationResponse * 100 + "%" : '0%'}}></span>
        </span>
        <p className={s.text}>{props?.rating?.averageOperationResponse ? (props.rating.averageOperationResponse * 5).toFixed(1) : 0}</p>
      </div>
    </div>
  )
}

export default withStyles(s)(Comments);
