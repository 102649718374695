import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let OpenEmailIcon = props => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="1" width="19" height="19" rx="3.5" fill="white"/>
    <g>
      <path d="M16.66 8.49998C16.66 8.01998 16.4133 7.59998 16.0333 7.36665L10 3.83331L3.96667 7.36665C3.58667 7.59998 3.33333 8.01998 3.33333 8.49998V15.1666C3.33333 15.9 3.93333 16.5 4.66667 16.5H15.3333C16.0667 16.5 16.6667 15.9 16.6667 15.1666L16.66 8.49998ZM10 11.8333L4.49333 8.39331L10 5.16665L15.5067 8.39331L10 11.8333Z" fill="#4380C7"/>
    </g>
    <rect x="0.5" y="1" width="19" height="19" rx="3.5" stroke="#D2D2D2"/>
  </svg>
);

OpenEmailIcon = pure(OpenEmailIcon);
OpenEmailIcon.displayName = 'OpenEmailIcon';
OpenEmailIcon.muiName = 'SvgIcon';

export default OpenEmailIcon;
