import React, {PureComponent} from 'react';
import {BaseContactInfoWrapper, Body, ContactEdit, SocialMediaEdit} from "./ContactEdit";
import {Pane} from "../Common/SplitPane";
import ExtendedForm from "../Common/ExtendedForm";
import FormsyAttachments from "../Common/FormsyAttachments/FormsyAttachments";
import RaisedButton from "../Common/RaisedButton";
import SpinnerButton from "../Common/SpinnerButton";
import {connect} from "react-redux";
import {createCompany, deleteContacts, updateCompany} from "../../actions/contacts";
import AvatarEdit from "./AvatarEdit";
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";
import ContactEmailsEdit from "./ContactEmailsEdit";
import ContactPhonesEdit from "./ContactPhonesEdit";
import NarrowCountryAutocomplete from "../NewInputs/NarrowCountryAutocomplete";
import {NarrowCityAutocomplete} from "../NewInputs/NarrowCityAutocomplete";
import ContactBookCompanyAutocomplete from "./ContactBookCompanyAutocomplete";
import ContactBookContactAutocomplete from "./ContactBookContactAutocomplete";
import {ContactsHeader, ReduxlessContactsTable} from "./ContactsTable";
import {Collapse} from "../Common/Collapse";
import { noop } from "../../core/utils";
import Search from "../Common/Search";
import Sort from "../Common/Sort";
import styled from "styled-components";
import cx from "classnames";
import history from "../../core/history";
import NarrowIndustrySelect from "../NewInputs/NarrowIndustrySelect";
import NarrowSectorSelect from "../NewInputs/NarrowSectorSelect";
import {sortContacts} from "../../reducers/contacts/CONTACTS_CREATE_GROUP";
import FormsyTextarea from "../Common/FormsyTextarea";


class BaseCompanyInfoEdit extends PureComponent {
  now = new Date();

  render(){
    const { changeHandlers, contact } = this.props;
    return (<BaseContactInfoWrapper>

      <div className={'avatar-wrapper'}>
        <AvatarEdit onChange={changeHandlers.logoId} photoId={contact.logoId} />
      </div>
      <div className={'main-part'}>
        <div className={'left-part'}>
          <NarrowFormsyText
            value={contact.name}
            name={`name`}
            required
            onChange={changeHandlers.name}
            floatingLabelText={'Name*'}
            fullWidth
            validations="maxLength:1024"
            validationError="max 1024 characters"
            requiredError="required"
          />
          <NarrowIndustrySelect
            name={'industry'}
            value={contact.industry}
            onChange={changeHandlers.industry}
            floatingLabelText="Industry"
            fullWidth
            autoWidth
          />
          <NarrowFormsyText
            value={contact.postalCode}
            name={`index`}
            onChange={changeHandlers.postalCode}
            floatingLabelText={'Postal Code'}
            fullWidth
            validations="maxLength:32"
            validationError="max 32 characters"
            requiredError="required"
          />
          <NarrowCountryAutocomplete
            name={"country"}
            value={contact.country}
            floatingLabelText={'Country'}
            validationErrors={{ isDefaultRequiredValue: 'required', isObj: 'Please select country' }}
            validations="isObj"
            validationError="Please select country"
            fullWidth
            onNewRequest={(value) => { changeHandlers.country(null, value); }}
          />
          <ContactPhonesEdit
            phones={contact.phones}
            onChange={changeHandlers.phones}
          />
        </div>
        <div className={'right-part'}>
          <ContactEmailsEdit onChange={changeHandlers.emails} emails={contact.emails} entityType={this.props.entityType} firstRequired={false} />
          <NarrowSectorSelect
            name={'sector'}
            value={contact.sector}
            onChange={changeHandlers.sector}
            industry={contact.industry}
            key={contact.industry}
            floatingLabelText="Sector"
            fullWidth
            autoWidth
          />
          <NarrowFormsyText
            value={contact.address}
            name={`address`}
            onChange={changeHandlers.address}
            floatingLabelText={'Address'}
            fullWidth
            validations="maxLength:1024"
            validationError="max 1024 characters"
          />
          <NarrowCityAutocomplete
            name={"city"}
            value={contact.city}
            floatingLabelText={'City'}
            validationErrors={{ isDefaultRequiredValue: 'required', isObj: 'Please select city' }}
            validations="isObj"
            validationError="Please select city"
            fullWidth
            onNewRequest={(value) => { changeHandlers.city(null, value); }}
            params={{ country: contact.country?._id }}
          />
          <NarrowFormsyText
            value={contact.website}
            name={`website`}
            onChange={changeHandlers.website}
            floatingLabelText={'Website'}
            fullWidth
            validations="isSite"
            validationError="please enter valid website"
          />
        </div>
      </div>
      <div className={'first-contact-part'}>
        <h4>ADDITIONAL DETAILS</h4>
        <FormsyTextarea
          name={'details'}
          placeholder={'Enter details...'}
          onChange={changeHandlers.details}
          value={contact.details}
        />
      </div>
    </BaseContactInfoWrapper>);
  }
}

export class CompanyEdit extends ContactEdit {

  static labelsMap = {
    create: {
      title: "CREATE COMPANY",
      addButtonLabel: "CREATE",
      cancelButtonLabel: "CANCEL",
      deleteButtonLabel: "DELETE COMPANY",
    },
    edit: {
      title: "EDIT COMPANY",
      addButtonLabel: "UPDATE",
      cancelButtonLabel: "CANCEL",
      deleteButtonLabel: "DELETE COMPANY",
    }
  };

  entityType = 'company';

  handleAddMember = (member) => {
    if (!member?.id) {
      return;
    }
    const members = [...this.state.contact.persons, member];
    this.changeHandlers.persons(null, members);

  }

  renderMembersPart() {
    return (<Collapse title={'MEMBERS'}>
      <div>
        <ContactBookContactAutocomplete
          name={"user"}
          hintText={'Select User'}
          validationErrors={{ isDefaultRequiredValue: 'required'}}
          validationError=""
          fullWidth
          popoverProps={{ }}
          forceSelection
          onNewRequest={this.handleAddMember}
          key={this.state.contact.persons.length}
        />
        <MembersTableWrapper members={this.state.contact.persons} onChange={this.changeHandlers.persons} />
      </div>
    </Collapse>);
  }


  render() {
    return (
      <Pane
        bodyProps={{ style: { height: '100%' } }}
        headerProps={{
          useBorder: true,
          children: this.labels.title,
          onClose: this.handleClose,
        }}
        scrollable
      >
        <Body id={this.containerId}>
          <ExtendedForm noValidate autoComplete="off" ref={this.refForm}>
            <BaseCompanyInfoEdit contact={this.state.contact} changeHandlers={this.changeHandlers} entityType={this.entityType} />
            <div className={'members-part'}>
              {this.renderMembersPart()}
            </div>
            <div className={'social-media-part'}>
              <SocialMediaEdit contact={this.state.contact} changeHandlers={this.changeHandlers} />
            </div>
            <div className={'attachments-part'}>
              <FormsyAttachments name={'attachments'} files={this.state.contact.attachments} onChange={this.changeHandlers.attachments} privateAttachment />
            </div>
            <footer>
              { this.props.contact ? (<SpinnerButton className={'delete-button'} secondary label={"Delete"} onClick={this.handleDelete} />) : null }
              <RaisedButton secondary onClick={this.handleClose} label={this.labels.cancelButtonLabel} />
              <SpinnerButton primary label={this.labels.addButtonLabel} onClick={this.handleCreateContact} />
            </footer>
          </ExtendedForm>
          {this.state.confirmDeletionDialog}
        </Body>
      </Pane>
    );
  }
}

export const CompanyEditConnected = connect(state => ({ ...state.contacts, user: state.login.user, tagCategoriesByName: state.login.tagCategoriesByName }), { createContact: createCompany, updateContact: updateCompany, deleteContacts })(CompanyEdit);

const sortFields =  {
  'name': {
    field: 'name', value: 1, label: 'Name',
  },
  'date': {
    field: 'date',
    value: -1,
    label: 'Created',
  },
};

export const MembersHeader = styled(({filters, sort, handleSort, handleSearch, className},) =>
  <div className={cx(className, "align-items-center", "w-100", "space-between")}>
    <div className="flex margin_children8">
      <div className="flex">
        <Search
          query={filters.q}
          handleSearch={handleSearch}
          isNarrow
        />
      </div>
      <Sort values={sortFields} sort={sort} handleSort={handleSort} />
    </div>
  </div>)`

`;

class MembersTable extends ReduxlessContactsTable {
  tableHeaderProps = { background: "transparent" };
  headerProps = { bg: "transparent" };

  componentDidMount() {

  }
  handleFilter = (filters) => {

  }

  handleSort = (sort) => {

  }

  handleLoadMore = () => {

  }
  handleContactSelect = (id) => {
    history.push(`/$1/$2/person:${id}/-/-/---`);
  }

  handleToggleFavorites = null;

  renderHeader() {
    return <MembersHeader user={this.props.user} {...this.props.contactList} handleSearch={this.props.handleFilter} handleSort={this.props.handleSort} />;
  }

  render() {
    if (!this.props.contactList.data.length && !this.props.contactList.filters.q) {
      return null;
    }
    return super.render();
  }


}

export class MembersTableWrapper extends PureComponent {
  state = { filters: {}, sort: { field: 'name', value: 1 } };

  checkAll = (checked) => {
    const members = this.props.members.map(m => ({ ...m, selected: checked }));
    this.props.onChange(null, members);
  }
  checkRow = (id) => {
    const members = this.props.members.map(m => (m.id === id ? { ...m, selected: !m.selected } : m));
    this.props.onChange(null, members);
  }
  handleFilter = (q) => {
    this.setState({ filters: { q } });
  }
  handleSort = (sort) => {
    this.setState({ sort });
  }
  deleteSelectedMembers = () => {
    const members = this.props.members.filter((m) => !m.selected);
    this.props.onChange(null, members);
  }
  mapTableProps = () => {
    const contactList = sortContacts({ data: this.filterMembers(this.props.members), page: 1, filters: this.state.filters, sort: this.state.sort });
    return {
      contactList,
      handleFilter: this.handleFilter,
      handleLoadMore: noop(),
      handleSort: this.handleSort,
      getContactList: noop,
      checkAll: this.checkAll,
      checkRow: this.checkRow,
      deleteSelectedMembers: this.deleteSelectedMembers,
    };
  }
  filterMembers = (members = []) => {
    if (!this.state.filters.q) {
      return members;
    }
    const q = this.state.filters.q.toLowerCase();
    return members.filter(m => {
      const fts = `${m.name} ${m.surname} ${m.emails.join(' ')} ${m.phones.map(p => p.phone).join(' ')}`.toLowerCase();
      return fts.indexOf(q) !== -1;
    });
  }



  render() {
    return (
      <div>
        <MembersTable {...this.mapTableProps()} readonly={this.props.readonly} />
      </div>
    );
  }

}
