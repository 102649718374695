import React, { Component } from 'react'
import styles from './muistyles'
import cx from 'classnames'
import PortAutocomplete from '../Common/PortAutocomplete';
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class NarrowPortAutocomplete extends Component {
    static defaultProps = {
        shouldReset: false
    }
    componentDidMount() {
      this.props.innerRef?.(this.refs.autocomplete);
    }
    componentDidUpdate() {
      this.props.innerRef?.(this.refs.autocomplete);
    }
    selectItem = item => {
        const {shouldReset} = this.props;
        if (this.props.onNewRequest) {
            this.props.onNewRequest(item)
        }
        if (shouldReset && this.refs.autocomplete) {
            this.refs.autocomplete.resetValue()
        }
    }
    render() {
        const {inputStyles, classes, onNewRequest, ...props} = this.props;
        return (
            <PortAutocomplete
                className={cx("input_default", classes)}
                textFieldStyle={styles.container}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={styles.text.label}
                hintStyle={styles.text.label}
                errorStyle={styles.error}
                inputStyle={{...styles.text.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={styles.text.underline}
                underlineFocusStyle={styles.text.underlineFocus}
                ref="autocomplete"
                onNewRequest={this.selectItem}
                {...props}
            >

            </PortAutocomplete>
        )
    }
}

export default withStyles(s)(NarrowPortAutocomplete)
