import React from 'react'
import styled from 'styled-components'
import RaisedButton from './RaisedButton'

export default function EmptyListResult({ onClear,titleStyle={},isBgDark=false}) {
  return (
    <StyledComponent isBgDark={isBgDark} style={titleStyle}>
            <span className="material-icons">search_off</span>
            No results found
            <span className='empty-state'>Sorry, we couldn’t locate any results matching your criteria.</span>
            {onClear && <RaisedButton style={{backgroundColor:'none'}} disableTouchRipple
                    disableFocusRipple primary styles='xs' buttonStyle={{
                    background: "#4380C7",
                    color: "#fff"
                  }} label='Clear all' onClick={onClear} />}
    </StyledComponent>
  )
}
const StyledComponent = styled.div`
font-size: 18px;
line-height: 150%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
gap:8px;
flex-direction: column;
color:${props => props.isBgDark ? '#FFFFFF' : '#333'};
font-weight: 400;
.material-icons {
    font-size: 48px;
    color:#D2D2D2;
}
.empty-state{
  color: ${props => props.isBgDark ? 'rgba(255,255,255,0.7)' : '#787878'};
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
`

export const EmptyListContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
`
