import React, { Component } from "react";
import cx from "classnames";
import s from "./IssueReport.scss";

import Info from "../Icons/Info";
import CircleCloseBtn from "../Common/CircleCloseBtn";
import CheckCircleIcon from 'material-ui/svg-icons/action/check-circle';
import CircleApproveBtn from "../Common/CircleApproveBtn";


class IssueReportFloatButton extends Component {
  clearSelectedElements = () => {
    this.props.clearSelectedElements();
    this.props.showForm();
  }
  render() {
      const { showForm, isOpen, isShown, selectedElementsList } = this.props;
      return (
          <div
              className={cx(s.wrapper_closed, selectedElementsList.length > 0 ? s.show_selected : "")}
              style={{
                  display: isOpen && isShown ? "none" : "flex"
              }} >
                {selectedElementsList.length > 0 ?
                  <div onClick={showForm} className={s.selected_counter}>
                    <span className={s.count}>{`${selectedElementsList.length} ${selectedElementsList.length === 1 ? 'select' : 'selects'}`}</span>
                    <span className={s.approve} onClick={showForm}>
                      <CircleApproveBtn />
                    </span>
                    <span className={s.reject} onClick={this.clearSelectedElements}>
                      <CircleCloseBtn />
                    </span>
                  </div>
                : <div onClick={showForm} >
                    <Info
                      width="20px"
                      height="20px"
                      color="#ffffff"
                    />
                  </div>
                }
          </div>
      );
  }

}

export default IssueReportFloatButton;
