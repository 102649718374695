export const HS_CODES_TO_CARGO_TYPE = {
  'Consumer Goods': [
    { section: 8 }, // SECTION VIII - RAW HIDES AND SKINS, LEATHER, FURSKINS AND ARTICLES THEREOF; SADDLERY AND HARNESS; TRAVEL GOODS, HANDBAGS AND SIMILAR CONTAINERS; ARTICLES OF ANIMAL GUT (OTHER THAN SILKWORM GUT)
    { section: 11 }, // SECTION XI - TEXTILES AND TEXTILE ARTICLES
    { section: 12 }, // SECTION XII - FOOTWEAR, HEADGEAR, UMBRELLAS, SUN UMBRELLAS, WALKING-STICKS, SEAT-STICKS, WHIPS, RIDING-CROPS AND PARTS THEREOF; PREPARED FEATHERS AND ARTICLES MADE THEREWITH; ARTIFICIAL FLOWERS; ARTICLES OF HUMAN HAIR
    { section: 18 }, // SECTION XVIII - OPTICAL, PHOTOGRAPHIC, CINEMATOGRAPHIC, MEASURING, CHECKING, PRECISION, MEDICAL OR SURGICAL INSTRUMENTS AND APPARATUS; CLOCKS AND WATCHES; MUSICAL INSTRUMENTS; PARTS AND ACCESSORIES THEREOF
    { section: 20 }, // SECTION XX - MISCELLANEOUS MANUFACTURED ARTICLES
  ],
  'Equipment & Machinery': [
    { section: 16 }, // SECTION XVI - MACHINERY AND MECHANICAL APPLIANCES; ELECTRICAL EQUIPMENT; PARTS THEREOF; SOUND RECORDERS AND REPRODUCERS, TELEVISION IMAGE AND SOUND RECORDERS AND REPRODUCERS, AND PARTS AND ACCESSORIES OF SUCH ARTICLES
    { section: 17 }, // SECTION XVII - VEHICLES, AIRCRAFT, VESSELS AND ASSOCIATED TRANSPORT EQUIPMENT
  ],
  'Fertilizers': [
    {
      section: 6, // Section VI: Products of the Chemical or Allied Industries
      chapters: [31], // Chapter 31: Fertilizers.
    },
  ],
  'Food and Beverages': [
    { section: 3 }, // SECTION III - ANIMAL OR VEGETABLE FATS AND OILS AND THEIR CLEAVAGE PRODUCTS; PREPARED EDIBLE FATS; ANIMAL OR VEGETABLE WAXES
    { section: 4 }, // SECTION IV - PREPARED FOODSTUFFS; BEVERAGES, SPIRITS AND VINEGAR; TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES
  ],
  'Forest Products': [
    { section: 9 }, // SECTION IX - WOOD AND ARTICLES OF WOOD; WOOD CHARCOAL; CORK AND ARTICLES OF CORK; MANUFACTURES OF STRAW, OF ESPARTO OR OF OTHER PLAITING MATERIALS; BASKETWARE AND WICKERWORK
    { section: 10 }, // SECTION X - PULP OF WOOD OR OF OTHER FIBROUS CELLULOSIC MATERIAL; RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD; PAPER AND PAPERBOARD AND ARTICLES THEREOF
  ],
  'Grains & Agricultural Products': [
    { section: 2 }, // SECTION II - VEGETABLE PRODUCTS
  ],
  'Live Stock': [
    { section: 1 }, // SECTION I - LIVE ANIMALS; ANIMAL PRODUCTS
  ],
  'Metals & Steel Products': [
    { section: 15 }, // SECTION XV - BASE METALS AND ARTICLES OF BASE METAL
  ],
  'Minerals and Rocks': [
    { section: 5 }, // SECTION V - MINERAL PRODUCTS
    { section: 8 }, // SECTION XIII - ARTICLES OF STONE, PLASTER, CEMENT, ASBESTOS, MICA OR SIMILAR MATERIALS; CERAMIC PRODUCTS; GLASS AND GLASSWARE
  ],
  'Oil & Chemicals': [
    { section: 6 }, // SECTION VI - PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES
  ],
  'Organic Material': [
    { section: 7 }, // SECTION VII - PLASTICS AND ARTICLES THEREOF; RUBBER AND ARTICLES THEREOF
  ],
  'Other': [
    { section: 14 }, // SECTION XIV - NATURAL OR CULTURED PEARLS, PRECIOUS OR SEMI-PRECIOUS STONES, PRECIOUS METALS, METALS CLAD WITH PRECIOUS METAL, AND ARTICLES THEREOF; IMITATION JEWELLERY; COIN
    { section: 21 }, // SECTION XXI - WORKS OF ART, COLLECTORS' PIECES AND ANTIQUES
  ],
  'Perishables': [
    {
      section: 1, // Live Animals; Animal Products
      chapters: [
        1, // Chapter 1: Live animals
        2, // Chapter 2: Meat and edible meat offal
        3, // Chapter 3: Fish and crustaceans, molluscs, and other aquatic invertebrates (seafood)
        4, // Chapter 4: Dairy produce; birds' eggs; natural honey
        5, // Chapter 5: Products of animal origin, not elsewhere specified or included
      ],
    },
    {
      section: 2, // Section II: Vegetable Products
      chapters: [
        6, // Chapter 6: Live trees and other plants; bulbs, roots, and the like; cut flowers and ornamental foliage
        7, // Chapter 7: Edible vegetables and certain roots and tubers
        8, // Chapter 8: Edible fruit and nuts; peel of citrus fruits or melons
      ],
    },
    {
      section: 3, // Section III: Animal or Vegetable Fats and Oils and Their Cleavage Products
      chapters: [
        15, // Chapter 15: Animal or vegetable fats and oils, often used in perishable food production
      ],
    },
    {
      section: 4, // Section IV: Prepared Foodstuffs; Beverages, Spirits, and Vinegar; Tobacco
      chapters: [
        16, // Chapter 16: Preparations of meat, fish, or crustaceans
        17, // Chapter 17: Sugars and sugar confectionery
        18, // Chapter 18: Cocoa and cocoa preparations
        19, // Chapter 19: Preparations of cereals, flour, starch, or milk; pastrycooks' products
        20, // Chapter 20: Preparations of vegetables, fruit, nuts, or other parts of plants
        21, // Chapter 21: Miscellaneous edible preparations
      ],
    },
  ],
  'Project & General cargo': [
    { section: 19 }, // SECTION XIX - ARMS AND AMMUNITION; PARTS AND ACCESSORIES THEREOF
  ],
}

/**@see  {@link CARGO_REQUEST_REDUCER}*/
export const CARGO_REQUEST = 'CARGO_REQUEST';
/**@see  {@link CARGO_REQUEST_REQUEST_REDUCER}*/
export const CARGO_REQUEST_REQUEST = 'CARGO_REQUEST_REQUEST';
/**@see  {@link CARGO_REQUEST_FAILED_REDUCER}*/
export const CARGO_REQUEST_FAILED = 'CARGO_REQUEST_FAILED';

/**@see  {@link CARGO_REQUEST_LIST_REDUCER}*/
export const CARGO_REQUEST_LIST = 'CARGO_REQUEST_LIST';
/**@see  {@link CARGO_REQUEST_LIST_REQUEST_REDUCER}*/
export const CARGO_REQUEST_LIST_REQUEST = 'CARGO_REQUEST_LIST_REQUEST';
/**@see  {@link CARGO_REQUEST_LIST_FAILED_REDUCER}*/
export const CARGO_REQUEST_LIST_FAILED = 'CARGO_REQUEST_LIST_FAILED';

/**@see  {@link CARGO_SEND_FREIGHT_REQUEST_REDUCER}*/
export const CARGO_SEND_FREIGHT_REQUEST = 'CARGO_SEND_FREIGHT_REQUEST';
/**@see  {@link CARGO_SEND_FREIGHT_REQUEST_REQUEST_REDUCER}*/
export const CARGO_SEND_FREIGHT_REQUEST_REQUEST =
  'CARGO_SEND_FREIGHT_REQUEST_REQUEST';
/**@see  {@link CARGO_SEND_FREIGHT_REQUEST_FAILED_REDUCER}*/
export const CARGO_SEND_FREIGHT_REQUEST_FAILED =
  'CARGO_SEND_FREIGHT_REQUEST_FAILED';

/**@see  {@link CARGO_SEND_OFFER_REDUCER}*/
export const CARGO_SEND_OFFER = 'CARGO_SEND_OFFER';
/**@see  {@link CARGO_SEND_OFFER_REQUEST_REDUCER}*/
export const CARGO_SEND_OFFER_REQUEST = 'CARGO_SEND_OFFER_REQUEST';
/**@see  {@link CARGO_SEND_OFFER_FAILED_REDUCER}*/
export const CARGO_SEND_OFFER_FAILED = 'CARGO_SEND_OFFER_FAILED';

/**@see  {@link CARGO_FIX_REDUCER}*/
export const CARGO_FIX = 'CARGO_FIX';
/**@see  {@link CARGO_FIX_REQUEST_REDUCER}*/
export const CARGO_FIX_REQUEST = 'CARGO_FIX_REQUEST';
/**@see  {@link CARGO_FIX_FAILED_REDUCER}*/
export const CARGO_FIX_FAILED = 'CARGO_FIX_FAILED';

/**@see  {@link CARGO_REJECT_REDUCER}*/
export const CARGO_REJECT = 'CARGO_REJECT';
/**@see  {@link CARGO_REJECT_REQUEST_REDUCER}*/
export const CARGO_REJECT_REQUEST = 'CARGO_REJECT_REQUEST';
/**@see  {@link CARGO_REJECT_FAILED_REDUCER}*/
export const CARGO_REJECT_FAILED = 'CARGO_REJECT_FAILED';
/**@see  {@link CARGO_REQUEST_REFRESH_STATUS_REDUCER}*/
export const CARGO_REQUEST_REFRESH_STATUS = 'CARGO_REQUEST_REFRESH_STATUS';
/**@see  {@link CARGO_REQUEST_REFRESH_STATUS_REQUEST_REDUCER}*/
export const CARGO_REQUEST_REFRESH_STATUS_REQUEST = 'CARGO_REQUEST_REFRESH_STATUS_REQUEST';
/**@see  {@link CARGO_REQUEST_REFRESH_STATUS_FAILED_REDUCER}*/
export const CARGO_REQUEST_REFRESH_STATUS_FAILED = 'CARGO_REQUEST_REFRESH_STATUS_FAILED';
/**@see  {@link CARGO_REQUEST_DELETE_REDUCER}*/
export const CARGO_REQUEST_DELETE = 'CARGO_REQUEST_DELETE';
/**@see  {@link CARGO_REQUEST_DELETE_REQUEST_REDUCER}*/
export const CARGO_REQUEST_DELETE_REQUEST = 'CARGO_REQUEST_DELETE_REQUEST';
/**@see  {@link CARGO_REQUEST_DELETE_FAILED_REDUCER}*/
export const CARGO_REQUEST_DELETE_FAILED = 'CARGO_REQUEST_DELETE_FAILED';

/**@see  {@link MY_CARGO_DETAILS_REDUCER}*/
export const MY_CARGO_DETAILS = 'MY_CARGO_DETAILS';
/**@see  {@link MY_CARGO_DETAILS_REQUEST_REDUCER}*/
export const MY_CARGO_DETAILS_REQUEST = 'MY_CARGO_DETAILS_REQUEST';
/**@see  {@link MY_CARGO_DETAILS_FAILED_REDUCER}*/
export const MY_CARGO_DETAILS_FAILED = 'MY_CARGO_DETAILS_FAILED';

/**@see  {@link SEND_VOYAGE_REMAINDER_REDUCER}*/
export const SEND_VOYAGE_REMAINDER = 'SEND_VOYAGE_REMAINDER';
/**@see  {@link SEND_VOYAGE_REMAINDER_REQUEST_REDUCER}*/
export const SEND_VOYAGE_REMAINDER_REQUEST = 'SEND_VOYAGE_REMAINDER_REQUEST';
/**@see  {@link SEND_VOYAGE_REMINDER_FAILED_REDUCER}*/
export const SEND_VOYAGE_REMAINDER_FAILED = 'SEND_VOYAGE_REMAINDER_FAILED';

/**@see  {@link CANCEL_VOYAGE_REMAINDER_REDUCER}*/
export const CANCEL_VOYAGE_REMAINDER = 'CANCEL_VOYAGE_REMAINDER';
/**@see  {@link CANCEL_VOYAGE_REMAINDER_REQUEST_REDUCER}*/
export const CANCEL_VOYAGE_REMAINDER_REQUEST = 'CANCEL_VOYAGE_REMAINDER_REQUEST';
/**@see  {@link CANCEL_VOYAGE_REMAINDER_FAILED_REDUCER}*/
export const CANCEL_VOYAGE_REMAINDER_FAILED = 'CANCEL_VOYAGE_REMAINDER_FAILED';


export const UPDATE_CARGO_DETAILS = 'UPDATE_CARGO_DETAILS';
export const UPDATE_CARGO_DETAILS_REQUEST = 'UPDATE_CARGO_DETAILS_REQUEST';
export const UPDATE_CARGO_DETAILS_FAILED = 'UPDATE_CARGO_DETAILS_FAILED';
