exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".eYzOT {\n  max-width: 680px !important;\n  width: 100% !important; }\n  .eYzOT > div > div:first-child svg {\n    display: none !important; }\n  ._1hO4O {\n  overflow-y: auto;\n  height: inherit;\n  max-height: 778px;\n  padding-left: 28px !important;\n  padding-bottom: 14px !important; }\n  ._1fksB {\n  padding: 28px 0 12px;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--text-dark); }\n  ._2QFtJ {\n  margin-top: 16px; }\n  ._2QFtJ a {\n    font-size: 14px;\n    line-height: 16px;\n    color: #4D9E0E; }\n  ._2QFtJ a:first-child, ._2QFtJ a:nth-child(2) {\n      padding-right: 6px;\n      border-right: 1px solid #C7C7C7; }\n  ._2QFtJ a:nth-child(2) {\n      padding-left: 9px; }\n  ._2QFtJ a:nth-child(3) {\n      padding-left: 9px; }\n", ""]);

// Exports
exports.locals = {
	"dialogContent": "eYzOT",
	"dialogBody": "_1hO4O",
	"wrapper_access": "_1fksB",
	"info": "_2QFtJ"
};