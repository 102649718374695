import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field, Formik } from 'formik';
import ShareAutocomplete from "../Common/Share/ShareAutocomplete";
import { validateEmail } from '../../core/utils';

class FreightShareAutocomplete extends React.Component {
  static propTypes = {
    emails: PropTypes.array,
    formik: PropTypes.object,
    updateEmails: PropTypes.func,
  };
  static defaultProps = {
    emails: [],
  }
  validateAutocomplete = value => {
    if (value && !validateEmail(value)) return "Please enter valid email address";
  }

  addNewEmail = chip => {
    const { form: { setFieldValue }, field: { value = [], name } } = this.props;
    const isEmailsEqual = (first, second) =>  String(first.email).trim() === String(second.email)

    if (value.find(c => Array.isArray(chip) ? chip.find(el => isEmailsEqual(c, el)) : isEmailsEqual(c, chip))) {
      return;
    }

    const updatedEmails = value.concat(chip);

    setFieldValue(name, updatedEmails);
  }

  deleteEmail = _id => {
    const { form: { setFieldValue }, field: { value = [], name } } = this.props;

    const updatedEmails = value.filter(chip => chip._id !== _id);

    setFieldValue(name, updatedEmails);
  }

  render () {
    const { label,buttons, meta, required } = this.props;

    const { field: { name, value = [], onChange, onBlur, ...rest }, form: { errors, touched, values, ...formProps } } = this.props;
    const placeholder = value.length ? "Add more" : "Enter email address";
    const inputProps = { ...rest, ...formProps, name, autoComplete: "off", placeholder, onBlur };
    return (
      <ShareAutocomplete
        chips={value}
        handleAddChip={this.addNewEmail}
        handleDeleteChip={this.deleteEmail}
        inputProps={inputProps}
        errorText={touched[name] ? errors[name] : ""}
        label={label}
        required={required}
        buttons={buttons}
      />
    );
  }
}

export default FreightShareAutocomplete;
