import { extendStowageFields, getDefaultPorts } from '../utils';
import { v4 as uuidv4 } from 'uuid';
import { constructCargoTitle } from '../constructCargoTitle';
import {flattenPorts} from "../../../core/utils";

export default (recognizedFields, cargoTypes) => {
  // cargo and ports are controlled (used in state)
    // rest keeps all fields that are uncontrolled
    // but i have to setValue of inputs from cargo (cargoList), loading, unloading (ports) because although they are controlled
    // inputs themselves are uncontrolled and therefore need to be setted manually
    let { cargo, loading, unloading, terms,charges,freights, incoterms, ...rest } = recognizedFields || {};
    cargo = cargo ? cargo.map(c => {
      const cargoType = cargoTypes.find(ct => ct._id === c.cargoType) || {
        name: c.cargoTypeName,
        _id: c.cargoType,
      };
      const name = c.cargoName;
      let stowage = { ...(c?.packing?.pack || {}), packingList: c.packingList || [], category: c?.packing?.category || "" };
      const clauses = c.clauses || [];
      const normalizedClauses = clauses.reduce((acc, item) => {
        const { name, value } = item;

        acc[name] = {
          value,
        };

        return acc;
      }, {});
      const cargo = { _id: c.cargoId, name, cargoType, parent: { red: cargoType }, containerForm: c.containerForm, details: c.details };
      stowage.containerDetails = c.containerDetails;
      stowage = extendStowageFields(stowage, c);

      let title = name;
      try {
        title = constructCargoTitle({ cargo, stowage });
      } catch (e) {
        console.error(e);
      }
      const typeOfTransportation = computeTypeOfTransportation(c.typeOfTransportation, stowage.category);
      return ({
        stowage,
        clauses: normalizedClauses,
        id: uuidv4(),
        cargo,
        clientRefNo: cargo.clientRefNo,
        hsCode: c.hsCode,
        title,
        name,
        cargoType,
        typeOfTransportation,
        additionalCargoDetails: c.additionalCargoDetails,
      });
    }) : [];

    const ports = {
      loadingPorts: flattenPorts(loading),
      unloadingPorts: flattenPorts(unloading),
      incoterms,
    };
    if (freights) {
      freights = freights.map(f => ({ key: uuidv4(), curr: { ...f } }));
    }
    if (charges) {
      charges = charges.map(f => ({ key: uuidv4(), curr: { ...f } }));
    }
    const populatedFields = {
      cargoList: cargo,
      ports,
      terms,
      commission: {
        comm: rest.comm || '',
        deductible: !!rest.deductible,
        pus: !!rest.pus,
      },
      freights,
      charges,
      ...rest,
    };
    return populatedFields;
}

const packToTTMap = {
  bulk: 'bulk',
  packed: 'breakbulk',
  unpacked: 'breakbulk',
  loose: 'breakbulk',
  container: 'container',
  wetbulk: 'wetbulk',
  wetBulk: 'wetbulk',
  tank: 'wetbulk',
};

function computeTypeOfTransportation(typeOfTransportation, stowage){
  if (typeOfTransportation) {
    return typeOfTransportation;
  }
  return packToTTMap[stowage.category] || 'breakbulk';
}
