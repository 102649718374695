import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import NavigationPopover from './NavigationPopover';


class FolderTypeSelect extends NavigationPopover {
  renderLinks = list => {
    const { handleSelect } = this.props;
    return list.map(item => (
          <MenuItem primaryText={item.label || item.name} value={item.name} onClick={() => { handleSelect(item.name); }}/>
      )
    );
  }
}

export default FolderTypeSelect;
