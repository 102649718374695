import produce from 'immer';

/** @name EMAILS_EMAIL_READ_REDUCER_REDUCER */
export default function EMAILS_EMAIL_READ_REDUCER(state, action) {
  return produce(state, draft => {
    const message = draft.emailList.data.find( m => m.id === action.payload.id);
    if (message) {
      message.read = true;
    }
  });
}
