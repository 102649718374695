import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Common/ProgressVesselPosition.scss';
import cx from 'classnames';
import PortLink from './PortLink';
import { date, handleCapitalize } from '../../core/format';
import styled from 'styled-components';

export const ProgressLine = styled(({ className, onRoute, loadingStatus = '', showPercent = true, ...props }) =>  {
  const route = onRoute?.route;
  if (!route) {
    return null;
  }
  loadingStatus = loadingStatus || onRoute.loadingStatus || onRoute.details?.loadingStatus;
  const progress = route.progress || 0;
  const progressLineWidth = `${Math.round(progress * 100)}%`;
  let lineWidth = '100%';
  if(loadingStatus !== 'ballast') {
    lineWidth = `calc(${100 - Math.round(progress * 100)}% - 1px)`;
  }
  if (progress === 0) {
    showPercent = false;
  }
  return <div
    className={cx(
      className,
      progress > 0 ? 'started-progress' : null,
      progress === 1 ? 'full-progress' : null,
      (loadingStatus),
    )}>
    <span className={cx('line')} style={{ width: lineWidth }}/>
    <span
      className={cx(
        'progress_line',
        progress === 1 || progress === 0 ? 'no-divider' : null,
      )}
      style={{ width: progressLineWidth }}
    />
    {showPercent ? <span className={'percent'}>{progressLineWidth}</span> : null}
  </div>;
})`
    position: relative;
    z-index: 0;
    width: calc(100% - 18px);
    height: 2px;
    margin-left: 6px;
    margin-right: 12px;
    &:before,
    &:after {
      position: absolute;
      content: '';
    }
    &:before {
      top: -2px;
      left: -6px;
      width: 6px;
      height: 6px;
      box-sizing: border-box;
      border: 2px solid var(--text-light);
      background: var(--text-light);
      border-radius: 50%;
      display: none;
    }
    &:after {
      top: -3px;
      right: -8px;
      display: inline-block;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 8px solid var(--text-light);
    }
    &.full-progress {
      &:after {
        border-left: 8px solid var(--text-links);
      }
    }
    &.started-progress {
      &:before {
        border-color: var(--text-links);
      }
    }
    .line {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      display: inline-block;
      height: 2px;
      background-color: var(--text-light);
    }
    .progress_line {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      height: 2px;
      background-color: var(--text-links);
      &.no-divider {
        &:after {
          display: none;
        }
      }
    }
    .percent {
      position: absolute;
      top: -12px;
      left: 50%;
      width: 30px;
      margin-left: -15px;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: var(--text-links);
    }
    &.ballast {
      width: calc(100% - 24px);
      margin-left: 12px;
      &:before {
        background-color: transparent;
        display: inline-block;
      }
      &.started-progress {
        &:before {
          border-color: var(--text-links);
        }
      }
      .line {
        background-color: transparent;
        background-image: linear-gradient(to right, var(--text-light) 66.6666%, rgba(255,255,255,0) 0%);
        background-position: left;
        background-size: 3px 2px;
        background-repeat: repeat-x;
      }
      .progress_line {
        background-color: transparent;
        background-image: linear-gradient(to right, var(--text-links) calc(66.6666%), rgba(255,255,255,0) 0%);
        background-position: left;
        background-size: 3px 2px;
        background-repeat: repeat-x;
        &:after {
          display: none;
        }
      }
    }
  &.laden {
    width: calc(100% - 24px);
    margin-left: 12px;
    &:before {
      display: inline-block;
    }
    &.started-progress {
      &:before {
        border-color: var(--text-links);
        background-color: var(--text-links);
      }
    }
  }
`

class ProgressVesselPosition extends Component {


  static formatDate(dateValue) {
    const locale = 'en-US';
    const date = new Date(dateValue);

    return `${date.getUTCDate()} ${date.toLocaleDateString(locale, {
      timeZone: 'UTC',
      month: 'short',
    }).toUpperCase()}, ${date.getUTCFullYear()}`;
  }

  render() {
    const onRoute = this.props.onRoute;
    const route = onRoute?.route;
    if (!route){
      return null;
    }
    return (
      route ?
        <div className={cx(s.route_info, this.props.publicVessel ? s.route_info_vessel_public : null)}>
          {route.from?.name ? (
            <div className={s.port}>
              {route.from.portId ?
                <PortLink className={cx(s.name)} port={{ name: route.from.name, _id: route.from.portId }}/>
                :
                <span className={s.name}>{handleCapitalize(route.from.name)}</span>
              },

              <span className={s.date}> {date(route.from.date)}</span>
            </div>
          ) : (
            <div className={s.port} />
            ) }
          <div className={s.progress_wrapper}>
            <ProgressLine onRoute={onRoute}/>
          </div>
          <div className={s.port}>
            {route.to.portId ?
              <PortLink className={cx(s.name)} port={{ name: route.to.name, _id: route.to.portId }}/>
              :
              <span className={s.name}>{handleCapitalize(route.to.name)}</span>
            },
            {route.to?.date ? <span className={s.date}>&nbsp;{date(route.to.date)}</span> : null}
          </div>
        </div>
        : null
    );
  }
}

export default (withStyles(s)(ProgressVesselPosition));
