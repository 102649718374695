import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../Pages.scss';

class ShipnextEcosystem extends Component {
  render() {
    return (
        <div className={s.shipnext_ecosystem}>
          <div className={s.container}>
            <div className={s.title}>
              <h2>SHIPNEXT Ecosystem</h2>
            </div>
            <div className={s.body_ecosystem_dev}>
              <div className={s.part_ecosystem_dev}>
                <div className={s.top_ecosystem_dev}>
                  <h2>Launched</h2>
                  <p>SHIPNEXT Marketplace</p>
                </div>
                <div className={s.bottom_ecosystem_dev}>
                  <img src="/images/ecosystemdev/launched@2x.png" alt="Stage A" />
                  <p>
                    Shipping Digital Automated Shipping Marketplace for conventional, oversized and dry-bulk cargo.
                  </p>
                  <ul>
                    <li></li>
                    <li>Centralized E-mail and data-flow processing (SHIPNEXT)</li>
                    <li>Internal customary E-mail and data-flow processing (SHIPNEXT CONNECT)</li>
                    <li>Instant Cargo-to-Ship Matching and Analytics</li>
                    <li>Centralized Reverse Auctioning</li>
                    <li>Interconnectivity between Shipowners/Carriers and Shipper's individual reverse freight
                      auction platforms.
                    </li>
                    <li>Freight (Voyage Charter) Trading and Chartering</li>
                    <li>Ship (Time Charter) Trading and Chartering</li>
                    <li>Fleet analysis & Rating (in progress)</li>
                    <li>Charter Party Building/Printing</li>
                    <li>Electronic Bill of Lading</li>
                    <li>Fleet and Port data-base</li>
                    <li>Cargo Tracking</li>
                  </ul>
                </div>
              </div>
              <div className={s.part_ecosystem_dev}>
                <div className={s.top_ecosystem_dev}>
                  <h2>PHASE 2 (current)</h2>
                  <p>Decentralized Transportation Platform</p>
                </div>
                <div className={s.bottom_ecosystem_dev}>
                  <img src="/images/ecosystemdev/phase2@2x.png" alt="Stage B" />
                  <p>Building an interconnected network between SHIPNEXT-based algorithms and other
                    transport-related infrastructure, Ports, Shipping Companies, Banks & Banking platforms,
                    Insurance companies and other modes of Transportation.</p>
                  <ul>
                    <li>Milestones:</li>
                    <li>3rd party service providers integration (banks, insurance companies, others)</li>
                    <li>Distributed ledger of transportation related data by interconnectivity with other platforms
                      and service providers
                    </li>
                    <li>Secure smart-contract based transactions</li>
                    <li>Integration of Port and Terminal-related real-time data</li>
                    <li>Integration of Road-, Rail- and Air-transportation Solutions and infrastructure.</li>
                  </ul>
                </div>
              </div>
              <div className={s.part_ecosystem_dev}>
                <div className={s.top_ecosystem_dev}>
                  <h2>PHASE 3</h2>
                  <p>SHIPNEXT Ecosystem</p>
                </div>
                <div className={s.bottom_ecosystem_dev}>
                  <img src="/images/ecosystemdev/phase3@2x.png" alt="Stage C" />
                  <p>Integration of Transportation Network-related algorithms and processes into Private Supply
                    Chains to create a blockchain-based Decentralized Supply Chain Ecosystem.</p>
                  <ul>
                    <li>Milestones:</li>
                    <li>Integration of Transportation Network-related algorithms and processes into Individual
                      Supply Chain
                    </li>
                    <li>Cargo delivery Sequence-related transportation solutions</li>
                    <li>Real-time based information and documentation flows</li>
                    <li>Distributed data ledger and secure interconnectivity</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default (withStyles(s)(ShipnextEcosystem));
