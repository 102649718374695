import React from 'react';

let AddCapacity = props => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="11.5" transform="matrix(-1 0 0 1 12 12)" stroke="#285596"/>
    <rect x="11.25" y="6.75" width="1.5" height="10.5" fill="#285596"/>
    <rect x="6.75" y="12.75" width="1.5" height="10.5" transform="rotate(-90 6.75 12.75)" fill="#285596"/>
    </svg>
);

export default AddCapacity;
