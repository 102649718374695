import React from "react";
import styled from "styled-components";
import Collapse from "../Common/Collapse";
import { dateTime } from "../../core/format";
import OpenEmailIcon from "../Icons/OpenEmailIcon";

const ActivityWrapper = styled.div`
  margin: 12px 0;
  .activity-field {
    display: flex;
    font-size: 12px;
    line-height: 24px;
    .activity-field-name {
      width: 110px;
      color: #999999;
    }
    .activity-field-username {
      color: #4380C7;
      margin-right: 6px;
    }
    .activity-field-date {
      text-transform: uppercase;
    }
    .activity-field-email-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }
`;

const Activity = ({ contact, openMailThread, isGlobalPopover = false }) => {
  return (
    <Collapse defaultOpen title="ACTIVITY">
      <ActivityWrapper>
        {
          contact.createdByUser !== null &&
            <div className="activity-field">
              <div className="activity-field-name">Created by:</div>
              <div className="activity-field-username">{`${contact.createdByUser.name || ""} ${contact.createdByUser.surname || ""} <${contact.createdByUser.email}>`}</div>
              <div className="activity-field-date">{dateTime(contact.createdAt)}</div>
            </div>
        }
        {
          contact.updatedByUser !== null &&
            <div className="activity-field">
              <div className="activity-field-name">Edited by:</div>
              <div className="activity-field-username">{`${contact.updatedByUser.name || ""} ${contact.updatedByUser.surname || ""} <${contact.updatedByUser.email}>`}</div>
              {contact.updatedAt && <div className="activity-field-date">{dateTime(contact.updatedAt)}</div>}
            </div>
        }
        {
          contact.lastEmailAt &&
            <div className="activity-field">
              <div className="activity-field-name">Last Email received:</div>
              <div className="activity-field-date">{dateTime(contact.lastEmailAt)}</div>
              {(contact.lastEmailId && !isGlobalPopover) && <div className="activity-field-email-icon"><OpenEmailIcon onClick={openMailThread}/></div>}
            </div>
        }
      </ActivityWrapper>
    </Collapse>
  );
};

export default Activity;
