import PropTypes from 'prop-types';
import React, { Component } from "react";

import {ForSaleHeader, TableCell, Wrapper, Text} from "./StyledComponents";

const ForSaleWrapper = (props) => {

  return <Wrapper>
    <ForSaleHeader>
      <TableCell>
        <Text>
          Added
        </Text>
      </TableCell>
      <TableCell>
        <Text>
          Company
        </Text>
      </TableCell>
      <TableCell right>
        <Text>
          Price Idea / Sale Price
        </Text>
      </TableCell>
      <TableCell>
        {/* empty one */}
      </TableCell>
    </ForSaleHeader>
    {props.children}
  </Wrapper>
}

export default ForSaleWrapper
