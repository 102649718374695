import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../../Home.scss';
import cx from 'classnames';
import c from '../../Pages/Pages.scss';
import Link from '../../../Link/Link';

const partners = [
  {
    link: 'http://www.varamar.com',
    maskImage: '/images/partners/varamar-b.png',
    image: '/images/partners/varamar-logo.png',
    name: 'Varamar',
  },
  {
    link: 'https://ifchor.com/',
    maskImage: '/images/partners/ifchor-b.png',
    image: '/images/partners/ifchor.png',
    name: 'Ifchor',
  },
  {
    link: 'https://www.epnetwork.net/',
    maskImage: '/images/partners/epn-b.png',
    image: '/images/partners/epn.png',
    name: 'EPN',
  },
  {
    link: 'https://www.uni-tankers.com/',
    maskImage: '/images/partners/uni-tankers-b.png',
    image: '/images/partners/uni-tankers.png',
    name: 'Uni-tankers',
  },
  {
    link: 'https://www.leewaybrokers.com/',
    maskImage: '/images/partners/leeway-b.png',
    image: '/images/partners/leeway.png',
    name: 'Leeway',
  },
  {
    link: 'https://www.martrade-group.com/',
    maskImage: '/images/partners/martrade-b.png',
    image: '/images/partners/martrade-group.png',
    name: 'Martrade group',
  },
  {
    link: 'https://www.renkaede.com',
    maskImage: '/images/partners/renkaedeag-b.png',
    image: '/images/partners/renkaedeag.png',
    name: 'Renkaede',
  },
  {
    link: 'https://www.jindalshadeed.com/',
    maskImage: '/images/partners/jindal-b.png',
    image: '/images/partners/jindal.png',
    name: 'Jindal shadeed',
  },
  {
    maskImage: '/images/partners/antracholding-b.png',
    image: '/images/partners/antracholding.png',
    name: 'Antrac holding',
  },
  {
    link: 'http://www.gjunquera.com/',
    maskImage: '/images/partners/gjunquera-b.png',
    image: '/images/partners/gjunquera.png',
    name: 'Gjunquera',
  },
  {
    // link: 'http://www.lmc.cd/',
    maskImage: '/images/partners/lmc-b.png',
    image: '/images/partners/lmc.png',
    name: 'LMC',
  },
  {
    link: 'http://tlc-com.ch',
    maskImage: '/images/partners/tlc20-b.png',
    image: '/images/partners/tlc20.png',
    name: 'TLC',
  },
  {
    link: 'https://www.almadinalogistics.com/',
    maskImage: '/images/partners/madina-b.png',
    image: '/images/partners/madina.png',
    name: 'Almadina logistics',
  },
  {
    link: 'https://www.paretoship.com',
    maskImage: '/images/partners/paretoship-b.png',
    image: '/images/partners/paretoship.png',
    name: 'Paretoship',
  },
  {
    link: 'http://www.shipforsale.net',
    maskImage: '/images/partners/shipforsale-b.png',
    image: '/images/partners/shipforsale.png',
    name: 'Shipforsale',
  },
  {
    link: 'http://www.microsoft.com/',
    maskImage: '/images/partners/microsoft-logo-b.png',
    image: '/images/partners/microsoft-logo.png',
    name: 'Microsoft',
  },
  {
    link: 'http://www.safina-eg.com/',
    maskImage: '/images/partners/safina-b.png',
    image: '/images/partners/safina.png',
    name: 'Navideep',
  },
  {
    link: 'http://www.seatram.com.au',
    maskImage: '/images/partners/seatram-b.png',
    image: '/images/partners/seatram.png',
    name: 'Seatram',
  },
  {
    link: 'http://kabokyyachting.com',
    maskImage: '/images/partners/kabokyyachting-b.png',
    image: '/images/partners/kaboky.png',
    name: 'Kaboky yachting',
  },
  {
    link: 'http://www.interpipe.biz',
    maskImage: '/images/partners/interpipe-b.png',
    image: '/images/partners/interpipe.png',
    name: 'Interpipe',
  },
  {
    link: 'http://acirfa.es/',
    maskImage: '/images/partners/acirfa-b.png',
    image: '/images/partners/acirfa.png',
    name: 'Acirfa',
  },
  {
    link: 'https://www.orient-transport.com/',
    maskImage: '/images/partners/orient-b.png',
    image: '/images/partners/orient-logo.png',
    name: 'Orient',
  },
  {
    link: 'https://www.kogaship.com/',
    maskImage: '/images/partners/koga-b.png',
    image: '/images/partners/koga.png',
    name: 'Koga',
  },
  {
    link: 'https://www.thalassamaritime.com',
    maskImage: '/images/partners/thalassa-b.png',
    image: '/images/partners/thalassa.png',
    name: 'Thalassa maritime',
  },
  {
    link: 'https://sbbusa.com/',
    maskImage: '/images/partners/sbbusa-b.png',
    image: '/images/partners/sbbusa.png',
    name: 'SBB Shipping USA',
  },
  {
    link: 'https://www.al-cargo.com',
    maskImage: '/images/partners/al-cargo-b.png',
    image: '/images/partners/al-cargo.png',
    name: 'A&L cargo',
  },
  {
    link: 'https://www.balticline.no/',
    maskImage: '/images/partners/balticline-b.png',
    image: '/images/partners/balticline.png',
    name: 'Baltic line',
  },
  {
    link: 'http://www.anchor-up.de',
    maskImage: '/images/partners/anchor-up-b.png',
    image: '/images/partners/anchor-up.png',
    name: 'Anchor up',
  },
  {
    link: 'http://www.fairwind.co.uk/',
    maskImage: '/images/partners/fairwind-b.png',
    image: '/images/partners/fairwind.png',
    name: 'Fairwind',
  },
  {
    link: 'http://www.afagegroup.com/',
    maskImage: '/images/partners/afagegroup-b.png',
    image: '/images/partners/afagegroup.png',
    name: 'Afage group',
  },
];

class AboutUsPartners extends Component {
  render() {
    return (
      <div className={cx(s.partners_all, this.props.fromPagePartners ? s.partners_all_from_page : null)}>
          <div className={s.container}>
            <div>
              <h2>Clients & Partners</h2>
              <div className={s.wrapper_partners_top}>
                {partners.map((item, i) => (
                    item.link ?
                    <a className={s.wrapper_image} key={i} href={item.link} target={'_blank'}>
                      <img className={s.logo} src={item.image} alt={item.name} />
                      <img className={s.logo_mask} src={item.maskImage} alt={item.name} />
                    </a>
                    : <span className={s.wrapper_image} key={i}>
                        <img className={s.logo} src={item.image} alt={item.name} />
                        <img className={s.logo_mask} src={item.maskImage} alt={item.name} />
                      </span>
                ))
                }
              </div>
              <div
                className={s.wrapper_btn_show}
                style={{
                  display: this.props.fromPagePartners ? 'none' : 'flex',
                }}
              >
                <Link className={cx(s.show_more, s.show_all)} to={'/partners'}>Show all</Link>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default (withStyles(s)(AboutUsPartners));
