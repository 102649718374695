import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FormsySelect from 'formsy-material-ui/lib/FormsySelect';
import SelectField from 'material-ui/SelectField';
const dirtyStyle = { color: '#D0021B' };
const defaultStyle = {fontSize: '13px'};
const defaultIconStyle = {padding: '0' ,width: '30px'};
class Select extends FormsySelect {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.defaultValue !== void 0 && !this.props.value) {
      this.setValue(this.props.defaultValue);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const currentValue = this.getValue();
    if (nextProps.children !== this.props.children && currentValue && nextProps.children) {
      const currentChild = Array.from(nextProps.children).filter(c => c?.props).find(
        c => c.props.value === currentValue,
      );
      if (!currentChild) {
        //this.setValue(nextProps.defaultValue);
      }
    }
    if (
      nextProps.defaultValue &&
      nextProps.defaultValue !== this.props.defaultValue &&
      !this.getValue()
    ) {
      this.setValue(nextProps.defaultValue);
    }
    this.setValidations(nextProps.validations, nextProps.required);
  }
  focus() {
    const domNode = ReactDOM.findDOMNode(this.muiComponent);
    const button = domNode.querySelector('button');
    if (button) {
      button.dispatchEvent(
        new MouseEvent('mouseup', {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );
    }
  }

  render() {
    let { value } = this.props;

    const {
      validations, // eslint-disable-line no-unused-vars
      validationError, // eslint-disable-line no-unused-vars
      validationErrors, // eslint-disable-line no-unused-vars
      requiredError,
      className = '',
      previousValue,
      id,
      style = {},
      controlledErrorText = "",
      valid = true,
      disabled=false,
      ...rest
    } = this.props;
    const _style = {...defaultStyle, ...style}
    value = this.state.hasChanged ? this.getValue() : value;
    const { isRequired, isPristine, isValid, isFormSubmitted } = this;
    const isRequiredError =
      isRequired() &&
      !isPristine() &&
      !isValid() &&
      isFormSubmitted() &&
      requiredError;
    const errorText = this.getErrorMessage() || isRequiredError || (controlledErrorText && !valid);
    let cn = className + ' select';
    let v = this.getValue()
    if(this.props.showEmptyOnDisabled && (disabled || this.isFormDisabled())){
     v = this.getValue() && this.getValue()!==false ? this.getValue() : ((this.isFormDisabled())) ? '—' : '';
    }
    let labelStyle;
    if (previousValue !== void 0 && previousValue !== v) {
      cn += ' dirty';
      labelStyle = dirtyStyle;
    }
    if (errorText) {
      cn +=" with_error";
    }
    return (
      <SelectField
        id={id || rest.name}
        disabled={disabled || this.isFormDisabled()}
        {...rest}
        errorText={errorText}
        onChange={this.handleChange}
        ref={this.setMuiComponentAndMaybeFocus}
        value={v}
        className={cn}
        labelStyle={labelStyle}
        data-valid={!errorText}
        style={_style}
        iconStyle={this.props.iconStyle || defaultIconStyle}
      >
        {this.props.children}
      </SelectField>
    );
  }
}

Select.propTypes = FormsySelect.propTypes;
Select.defaultProps = FormsySelect.defaultProps;

export default Select;
