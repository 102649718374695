import React from 'react'
import cx from 'classnames';
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ColoredText from '../../Monitor/RowBlocks/ColoredText';
import SecondText from '../../Monitor/RowBlocks/SecondText';
import Col from '../../Monitor/RowBlocks/Col';
import { number } from '../../../core/format';
function DwtCapacity({ className = "", dwt, capacity, ...rest }) {

  const normalizedDwt = number(dwt);
  const normalizedCapacity = number(capacity);

  return (
    <div className={cx(s.text_dwtcapacity, s.text, className)} {...rest}>
      <ColoredText>{normalizedDwt || ""}</ColoredText>
      <SecondText>{normalizedCapacity || ""}</SecondText>
    </div>
  )
}

export default withStyles(s)(DwtCapacity);
