import { date } from '../../core/format';
import { filterVesselRequest } from './filter';

/** @name VESSEL_REQUEST_LIST_REDUCER */
export default function VESSEL_REQUEST_LIST(state, { res, payload = {} }) {
  const newRequestList = { ...state.requestList };

  res.data = res.data.map((v, i) => {
    v.key = v._id + '|' + v.cargoRequest._id + '|' + (v.bidId || Math.random());
    return v;
  });
  if (res.page === 1) {
    newRequestList.data = res.data;
  } else {
    newRequestList.data = [...newRequestList.data, ...res.data];
  }

  newRequestList.page = res.page;
  newRequestList.total = res.total;
  newRequestList.next = res.next;
  newRequestList.loading = false;
  newRequestList.filters = payload.filters || {};
  const newState = { requestList: newRequestList };
  return { ...state, ...newState };
}
