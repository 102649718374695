import React, {Component} from 'react';
import Collapse from '../Common/Collapse';
import {MembersTableWrapper} from "./CompanyEdit";
import styled from "styled-components";

export const NoMembers = styled(({ text = 'No members yet', ...props}) => {
  return <div {...props}>No members yet</div>;
})`
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 80px;
  color:var(--stroke-light-gray1);
`;


class CompanyMembers extends Component {

  render() {
    return (
      <Collapse
        defaultOpen
        title="MEMBERS"
      >{this.props.members?.length ? (
        <MembersTableWrapper
          members={this.props.members}
          readonly
        />
      ) : <NoMembers />}
      </Collapse>
    );
  }
}

export default CompanyMembers;
