import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let ChartIcon = props => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            d="M20.9297 19.9375H4.0625V3.07031C4.0625 2.52344 3.61719 2.07812 3.07031 2.07812C2.52344 2.07812 2.07812 2.52344 2.07812 3.07031V20.9297C2.07812 21.4766 2.52344 21.9219 3.07031 21.9219H20.9297C21.4766 21.9219 21.9219 21.4766 21.9219 20.9297C21.9219 20.3828 21.4766 19.9375 20.9297 19.9375Z"
            fill="white"
          />
          <path
            d="M7.81248 14.6055L11.1797 10.3945L14.3789 12.793C14.7734 13.0898 15.3281 13.0508 15.6758 12.6992L20.6367 7.73828C21.0234 7.35156 21.0234 6.72266 20.6367 6.33594C20.25 5.94922 19.6211 5.94922 19.2344 6.33594L14.8828 10.6914L11.6015 8.23047C11.1719 7.91016 10.5664 7.98437 10.2304 8.40234L6.26169 13.3633C5.91794 13.793 5.98826 14.4141 6.41794 14.7578C6.76951 15.0391 7.48044 15.0234 7.81248 14.6055Z"
            fill="white"
          />
        </g>
      </svg>
    </span>
  </span>
);

ChartIcon = pure(ChartIcon);
ChartIcon.displayName = "ChartIcon";
ChartIcon.muiName = "SvgIcon";

export default ChartIcon;
