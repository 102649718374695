import React from 'react'
import Link from '../Link/Link'
import styled from 'styled-components';

const StyledLink = styled.a`
  color: var(--text-links);
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline !important;
  line-height: 26px;
`;

function ShipLink({ children, id, to = `/fleetmonitor/myfleet/${id}`, ...rest }) {
  return (
    <StyledLink
      {...rest}
      to={to}
    >
      {
        children
      }
    </StyledLink>
  );
}

export default ShipLink;
