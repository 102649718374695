import { removeFromArray } from '../../core/utils';
//noinspection JSUnusedGlobalSymbols
/** @name MONITOR_CARGO_REMOVE_REDUCER
 *  @param {object} state
 *  @param {MonitorCargo} payload
 * */
export default function MONITOR_CARGO_REMOVE(state, { payload }) {
  const newState = {};
  const cargoList = { ...state.cargoList };
  cargoList.data = removeFromArray(
    cargoList.data,
    cargoList.data.findIndex(c => c._id === payload._id),
  );
  newState.cargoList = cargoList;
  return { ...state, ...newState };
}
