import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PublicVessel.scss';
import Dialog from '../Common/Dialog';
import ImageGallery from 'react-image-gallery';
import ZoomInIcon from 'material-ui/svg-icons/action/zoom-in';
import cx from 'classnames';

class ImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state= {
      dialogOpened: false,
    }
  }
  handleOpenDialog = () => {
    this.setState({ dialogOpened: true });
    this.setState({ handleClose: true });
  };
  handleCloseDialog = () => {
    this.setState({ dialogOpened: false });
  };
  render() {
    return(
      <div className={s.wrapper_slider}>
        { this.props.images.length > 1 ?
          <span className={s.zoom}>
            <ZoomInIcon
              color={'#ffffff'}
              style={{
                width: '28px',
                height: '28px',
              }}
              onClick={this.handleOpenDialog}
            />
          </span>
          : null
        }
        <img src={this.props.images[0].original} alt="" />
        {this.state.dialogOpened ?
          <Dialog
            className={cx(s.modal_slider, 'modal-slider-public-vessel')}
            actionsContainerClassName={s.wrpper_modal_slider}
            bodyClassName={s.wrapper_modal_slider}
            overlayClassName={s.wrapper_modal_slider}
            paperClassName={s.wrapper_modal_slider}
            contentClassName={s.content_modal_slider}
            open
            handleClose={this.handleCloseDialog}
            title={this.props.title}
            contentStyle={{maxWidth: '600px'}}
          >
            <h2>{this.props.name}</h2>
            <ImageGallery
              showBullets
              showNav
              useBrowserFullscreen={false}
              showFullscreenButton={false}
              showPlayButton={false}
              showThumbnails={false}
              items={this.props.images}
            />
          </Dialog>
          : null
        }
      </div>
    )
  }
}

export default withStyles(s)(ImageSlider);
