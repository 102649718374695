/*
 * @Author: salterok
 * @Date: 2017-10-04 14:48:37
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2019-09-09 11:53:35
 */

import {
  CHAT_MESSAGES_RECEIVED,
  CHAT_INITIAL_INFO_RECEIVED,
  CHAT_INCREMENTAL_UPDATE,
  CHAT_SHOW_PREPARED_ROOM,
  CHAT_SELECT_CONTACT,
  CHAT_SET_VISIBILITY,
  CHAT_SET_TARGET_ROOM,
  CHAT_ROOMS_CHANGED,
} from "./actions";

export default {
  [CHAT_MESSAGES_RECEIVED](state, action) {
    const messages = action.payload;

    const nextMessages = {
      ...state.messages,
    };

    const nextRooms = {
      ...state.rooms,
    };

    for (const message of messages) {
      nextMessages[message._id] = message;
      const now = message.sentAt.getTime();

      if (!nextRooms[message.roomId] || nextRooms[message.roomId].lastReceivedAt < now) {
        nextRooms[message.roomId] = {
          ...nextRooms[message.roomId],
          lastReceivedAt: now,
        };
      }
    }

    return {
      ...state,
      messages: nextMessages,
      rooms: nextRooms,
    };
  },
  [CHAT_INITIAL_INFO_RECEIVED](state, action) {
    const rooms = action.payload.rooms.reduce((acc, val) => { acc[val._id] = { lastReceivedAt: 0, ...val }; return acc; }, {});
    const users = action.payload.users.reduce((acc, val) => { acc[val._id] = val; return acc; }, {});

    return {
      ...state,
      rooms,
      users,
      userId: action.payload.userId,
    };
  },
  [CHAT_INCREMENTAL_UPDATE](state, action) {
    const nextState = { ...state };

    for (const room of action.payload.rooms) {
      nextState.rooms[room._id] = Object.assign({ lastReceivedAt: Date.now() }, nextState.rooms[room._id], room);
    }
    for (const user of action.payload.users) {
      nextState.users[user._id] = Object.assign({}, nextState.users[user._id], user);
    }

    return nextState;
  },
  [CHAT_ROOMS_CHANGED](state, action) {
    const rooms = action.payload.reduce((acc, room) => {
      acc[room._id] = Object.assign({ lastReceivedAt: Date.now() }, state.rooms[room._id], room);
      return acc;
    }, {});

    return {
      ...state,
      rooms: {
        ...state.rooms,
        ...rooms,
      }
    };
  },
  [CHAT_SET_VISIBILITY](state, action) {
    return {
      ...state,
      isShown: action.payload,
    };
  },
  [CHAT_SELECT_CONTACT](state, action) {
    return {
      ...state,
      selectedContact: action.payload,
    };
  },
  [CHAT_SHOW_PREPARED_ROOM](state, action) {
    const room = action.payload;

    const users = room.restrictedUsers.reduce((acc, val) => (acc[val._id] = val, acc), {});
    room.restrictedUsers = room.restrictedUsers.map(u => u._id);
    room.lastReceivedAt = Date.now();

    return {
      ...state,
      users: {
        ...state.users,
        ...users,
      },
      rooms: {
        ...state.rooms,
        [room._id]: room,
      }
    };
  },
  [CHAT_SET_TARGET_ROOM](state, action) {
    const { userId, roomId } = action.payload;
    return {
      ...state,
      targetRoom: {
        ...state.targetRoom,
        [userId]: roomId,
      }
    }
  }
}

