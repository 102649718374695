import React from 'react'
import s from './Hoverable.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

const defaultElement = ({ children, ...rest }) => <div {...rest}>{children}</div>

function Hoverable({ el, className = "", exactSize = false, isDark = false, disabled = false, onClick = () => undefined, children, ...rest }) {
  const Element = el || defaultElement;
  return (
    <Element className={cx(className, exactSize ? s.exact : "", isDark ? s.dark : "", disabled ? s.disabled : "", s.hover_container)} onClick={disabled ? ev => (ev.stopPropagation(), ev.preventDefault()) : onClick} {...rest}>
      {
        children
      }
    </Element>
  )
}

export default withStyles(s)(Hoverable);
