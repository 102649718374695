import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import t from './../../Monitor/TableNew.scss';
import cx from 'classnames';
function ColoredText({ className, children, ...rest }) {
  return (
    <div className={cx(t.text_green, className)} {...rest}>
      {
        children
      }
    </div>
  )
}

export default withStyles(t)(ColoredText)
