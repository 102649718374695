import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../FreightRequest/FreightRequestDialog.scss';
import Dialog from '../../Common/Dialog';
import FormsyText from '../../Common/FormsyText';
import SpinnerButton from '../../Common/SpinnerButton';
import Formsy from 'formsy-react';
import OfferInMainDeck from '../OfferInMainDeck';
import FormsyDateTime from '../../Common/FormsyDateTime';
import { date, dateTimeLocal, leftHours, leftHoursFromMillis } from '../../../core/format';
import RoundBorderedBtn from '../../Common/RoundBorderedBtn';
import {isUserSubscribedToMarketplace, toUTC} from '../../../core/utils';
import {Formik} from "formik";
import MailHeadersEdit from "../../Common/Share/MailHeadersEdit";
import Cargo from "../../../core/api/Cargo";
import {isOfferToMarket, mapInitialEmails} from "../Offer/utils";

const Form = Formsy.Form;
const floatingLabelStyle = {fontSize: '13px', fontWeight: '400', color: 'var(--text-light)'};
const floatingLabelFocusStyle = {fontSize: '15px', fontWeight: '400', color: 'var(--text-light)'};
const inputStyle = {fontSize: '13px', fontWeight: '400', color: 'var(--text-light)'};
const hintStyle = {fontSize: '13px', fontWeight: '400', color: 'var(--text-light)'};

class SendReminderDialog extends PureComponent {
  state={}

  static defaultProps = {
    bid: {},
    vesselRequest: {},
  }

  handleSubmit = () => {
    this.refs.form.submit();
    const values = this.refs.form.getModel();
    values.shareEmails = {
      cc: this.state.cc?.map(se => se.email),
      bcc: this.state.bcc?.map(se => se.email),
      replyTo: this.state.replyTo?.map(se => se.email),
    };
    return this.props.handleSendReminder({
      cargoRequestId: this.props.cargo._id,
      bidId: this.props.bid._id,
      vesselRequestId: this.props.vesselRequest._id,
      freightRequestId: this.props.vesselRequest.status?.tradeId,
      sendAt: this.state.sendAt,
      ...values,
    });
  };


  handleClose = () => {
    this.props.handleClose();
  };
  componentDidMount() {
    this.getStartInfo();
  }

  componentWillMount() {
    const newState = {};
    let maxDate;
    if(this.props.bid._id) {
      maxDate = this.props.bid.endAt;
    } else {
      maxDate = this.props.vesselRequest.status.end;
    }
    if (maxDate < new Date()) {
      maxDate = undefined;
    }
    newState.maxDate = maxDate;
    if(this.props.reminder) {
      newState.reminder = { ...this.props.reminder };
      newState.reminder.sendAt = toUTC(newState.reminder.sendAt);
    }
    if (newState.reminder?.status === 'scheduled') {
      newState.sendAt = newState.reminder.sendAt;
    }
    if (newState.reminder?.status === 'sent') {
      const diffMinutes = Math.round((Date.now() - newState.reminder.sendAt) / (1000 * 60));
      if (diffMinutes <= 15) {
        newState.minDate = new Date(newState.reminder.sendAt);
        newState.minDate.setMinutes(newState.reminder.sendAt.getMinutes() + 15);
        if (newState.minDate > new Date()) {
          newState.sendAt = new Date(newState.minDate.getTime() + (1000 * 60));
          newState.sendAt.setSeconds(0, 0);
        }
      }
    }
    this.setState(newState);
  }

  changeDateTime = (e, datetime) => {
    this.setState({ sendAt: datetime });
  }

  handleCancelReminder = () => {
      this.props.handleCancelReminder(this.props.reminder);
  }
  async getStartInfo() {
    try {
      const startInfo = await Cargo.getTradeStartInfo();
      const shareEmails = this.props.bid?.trading?.shareEmails || this.props.vesselRequest?.status?.shareEmails;
      let cc = [];
      let replyTo = startInfo.data?.replyTo || [];
      let bcc = [];
      if (shareEmails) {
        cc = shareEmails.cc || [];
        bcc = shareEmails.bcc || [];
      }
      this.setState({ replyTo, cc }, this.refs.shareEmailForm.resetForm(mapInitialEmails(replyTo, cc, bcc)));
    } catch (e) {
      console.error(e.message);
    }
  }


  updateEmails = ({ cc, bcc, replyTo }) => {
    this.state.cc = cc;
    this.state.bcc = bcc;
    this.state.replyTo = replyTo;
  };



  render(){
    const vesselRequest = this.props.vesselRequest;
    const user = this.props.user;
    const cargo = { ...this.props.cargo };
    const bid = this.props.bid;
    const now = new Date();
    const reminder = this.state.reminder;
    const canSend = !reminder || (reminder.status !== 'scheduled' && (!this.state.minDate || this.state.minDate < now));
    const canSchedule = !!this.state.sendAt && (!reminder || reminder.status !== 'scheduled' || parseInt(this.state.sendAt / 1000) !== parseInt(reminder.sendAt / 1000)) && (!this.state.minDate || this.state.sendAt >= this.state.minDate);
    const isPublic = (bid?._id ? isOfferToMarket(user, cargo, cargo.bidChannel || vesselRequest, bid) : vesselRequest?.computedChannel === "market") && !this.state.cc?.length;
    const SHARE_AVAILABLE_FIELDS = { cc: !isPublic, bcc: true, replyTo: isUserSubscribedToMarketplace(user), subject: false, to: false };
    if (typeof this.props.vesselRequest.status?.comm === 'number') {
      cargo.comm = this.props.vesselRequest.status.comm;
      cargo.deductible = this.props.vesselRequest.status.deductible;
      cargo.pus = this.props.vesselRequest.status.pus;
    }
    return (
      <Dialog
        open
        handleClose={this.handleClose}
        contentClassName={cx(s.dialogContent, s.dialogContent_select_channels, s.send_reminder_dialog)}
        bodyClassName={s.dialogBody}
        modal
      >
        <Form className={s.form_select_date}><h3 className={s.header_dialog}>
          <div>
          <div>Send reminder</div>
          <ReminderStatus reminder={reminder} />
         </div> <span className={s.snooze}>Schedule: <div className={s.snooze_date}>
          <FormsyDateTime initialTime={this.state.sendAt} minDate={this.state.minDate || now} maxDate={this.state.maxDate} placeholder="Select date & time"  onChange={this.changeDateTime} name={'date'} clearable className={cx(s.picker)} />
          <span className={cx("material-icons", s.icon)}>
            date_range
          </span>
        </div> </span></h3>

        </Form>
        <Form ref="form" className={cx(s.form_select_channels,)}>
          {this.state.minDate && this.state.minDate > now ?
            <div className={s.warning}>You can send the reminder in {leftHoursFromMillis(this.state.minDate.getTime()).replace(/^0hr/,'')}. Please schedule your reminder by setting the date and time.</div>
            : null}
          {this.state.maxDate && this.state.sendAt && this.state.sendAt > this.state.maxDate ?
            <div className={s.warning}>Schedule date can&lsquo;t be greater than {dateTimeLocal(this.state.maxDate)} </div>
            : null}
          {bid._id ? <h4>REF: {cargo.refNo}</h4> : <h4>SHIP: <span style={{fontWeight: 400}}> M/V {this.props.vesselRequest.name}</span> </h4>}
          {user && !bid._id ? <h4>{user.company.name}, {user.name} {user.surname}</h4> : null}
          <div className={s.to_cc_bcc_wrapper}>
            <Formik initialValues={mapInitialEmails(this.state.replyTo)} onSubmit={() => {}} ref="shareEmailForm">
              {(formik) => <Form>
                <MailHeadersEdit availableFields={SHARE_AVAILABLE_FIELDS}/>
                {this.updateEmails(formik.values)}
              </Form>}
            </Formik>
          </div>
          <FormsyText
            name={'message'}
            hintText={'Your Message (optional)'}
            floatingLabelStyle={floatingLabelStyle}
            hintStyle={hintStyle}
            inputStyle={inputStyle}
            floatingLabelFocusStyle={floatingLabelFocusStyle}
            requiredError="required"
            fullWidth
            multiLine
            rows={1}
            rowsMax={10}
            maxLength={10000}
            defaultValue={reminder?.message}
          />
          <OfferInMainDeck cargo={cargo} bid={this.props.bid} sendReminder vessel={this.props.vessel}/>
          <FormsyText
            name={'footerMessage'}
            hintText={'Your Message (optional)'}
            floatingLabelStyle={floatingLabelStyle}
            hintStyle={hintStyle}
            inputStyle={inputStyle}
            floatingLabelFocusStyle={floatingLabelFocusStyle}
            requiredError="required"
            fullWidth
            multiLine
            rows={1}
            rowsMax={10}
            maxLength={10000}
            defaultValue={reminder?.footerMessage}
          />
          <div className={s.wrapper_buttons}>
            {reminder && reminder.status === 'scheduled' ?
            <RoundBorderedBtn
              label="CANCEL SCHEDULED REMINDER"
              onClick={this.handleCancelReminder}
              labelStyle={{ fontSize: '11px'}}
              buttonStyle={{ height: '32px', minHeight: '32px', lineHeight: '32px' }}
            />
               : null}
            {canSend || canSchedule ?
              <SpinnerButton
                primary
                label="Cancel"
                style={{
                  minWidth: '100px',
                }}
                buttonStyle={{
                  backgroundColor: '#E6E6E6',
                  borderRadius: '15px',
                  lineHeight: '30px',
                }}
                labelStyle={{
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  fontSize: '11px',
                  fontWeight: '500',
                  webkitFontSmoothing: 'antialiased',
                  textTransform: 'uppercase',
                  color: '#999999',
                }}
                onClick={this.handleClose}
              />
              : null}
            {canSchedule ?
              <SpinnerButton
                primary
                label={'Schedule'}
                onClick={this.handleSubmit}
                style={{
                  minWidth: '100px',
                }}
                buttonStyle={{
                  borderRadius: '15px',
                  backgroundColor: 'var(--text-links)',
                  lineHeight: '30px',
                }}
                labelStyle={{
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  fontSize: '11px',
                  fontWeight: '500',
                  webkitFontSmoothing: 'antialiased',
                  textTransform: 'uppercase',
                }}
              />
              :
              (canSend && <SpinnerButton
                primary
                label={'Send'}
                onClick={this.handleSubmit}
                style={{
                  minWidth: '100px',
                }}
                buttonStyle={{
                  borderRadius: '15px',
                  lineHeight: '31px',
                }}
                labelStyle={{
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  fontSize: '11px',
                  fontWeight: '500',
                  webkitFontSmoothing: 'antialiased',
                  textTransform: 'uppercase',
                }}
              />)
            }
          </div>
        </Form>
      </Dialog>)
  }
}

export default withStyles(s)(SendReminderDialog);


function formatDate(value) {
  if(!value) {
    return "";
  }
  return `${prependZero(value.getDate())}.${prependZero(value.getMonth() + 1)}.${prependZero(value.getFullYear())}, ${prependZero(value.getHours())}:${prependZero(value.getMinutes())}`;
}
function prependZero(v) {
  return ("0" + v).slice(-2);
}

function ReminderStatus({reminder}) {
  if (!reminder) {
    return null;
  }
  return <small>Reminder {reminder.status} by: {reminder.initiator.name} {reminder.initiator.surname} on {dateTimeLocal(reminder.sendAt)}</small>;
}
