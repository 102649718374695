/** @name SET_INDEX_CHART_REQUEST_DATA */
export default function SET_INDEX_CHART_REQUEST_DATA(state, action) {
    const newState = {};
    const { dateFrom, dateTo, dateType, dateRange } = action.payload; 
  
    newState.balticIndex = {
      ...state.balticIndex,
      dateFrom,
      dateTo,
      dateType,
      dateRange,
    };
  
    return { ...state, ...newState };
  }
