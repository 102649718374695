import CustomCheckbox from '../Common/CustomCheckbox';
import s from './IndeterminateCheckbox.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import React, { Component } from 'react'
import cx from 'classnames';

export class IndeterminateCheckbox extends Component {

  _checkbox = null

  componentWillReceiveProps(nextProps) {
    if (nextProps.indeterminate !== this.props.indeterminate || nextProps.value !== this.props.value || nextProps.checked !== this.props.checked) {
      this.toggleIndeterminate(nextProps);
    }
  }

  componentDidMount() {
    this.toggleIndeterminate();
  }

  toggleIndeterminate = (props = this.props) => {
    if (!this._checkbox) return;

    if (props.checked || props.value) {
      this.hideIndeterminate();
      return;
    };
    if (props.indeterminate) {
      this.showIndeterminate();
    }
    else {
      this.hideIndeterminate();
    }
  }

  hideIndeterminate = () => {
    const { checkbox } = this._checkbox.refs?.enhancedSwitch?.refs;
    if (!checkbox) return;
    checkbox.classList.remove(s.checkbox__indeterminate);
    checkbox.removeAttribute("indeterminate");
  }

  showIndeterminate = () => {
    const { checkbox } = this._checkbox.refs?.enhancedSwitch?.refs;
    if (!checkbox) return;
    checkbox.classList.add(s.checkbox__indeterminate);
    checkbox.setAttribute("indeterminate", true);
  }

  handleChecked = (ev) => {
    const { indeterminate } = this.props;

    if (this.props.onCheck) {
      this.props.onCheck(indeterminate ? false : ev.target.checked)
    }
  }

  render() {
    const { className = "", onCheck, ...rest } = this.props;
    return (
      <CustomCheckbox className={cx(s.indeterminate_checkbox, className)} innerRef={el => this._checkbox = el} onCheck={this.handleChecked} {...rest} />
    )
  }
}

export default withStyles(s)(IndeterminateCheckbox);
