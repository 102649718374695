import Api, { convertToSameTimeZ } from './api';
import lodash from 'lodash';
import transformChannelsToVia from '../../components/Calculator/functions/transformChannelsToVia';
import transformViaToChannel from '../../components/Calculator/functions/transformViaToChannel';
import calculateTotals from '../../components/Calculator/functions/calculateTotals';
import calculateCargoTotals from '../../components/Calculator/functions/calculateCargoTotals';
import {buildViaDaDetailsFromVia, or0, round} from "../../components/Calculator/utils";
import {calculateOnBoard} from "../../components/Calculator/functions/calculateMaxIntake";
import calculateRotationTotals from "../../components/Calculator/functions/calculateRotationTotals";
/**
 * @class
 */

const codeNameToPreferenceName = {
  'breakbulk': 'calculator',
  'drybulk': 'calculatorDrybulk',
}
export default class Calculator extends Api {

  static endpoint = '/preferences/byType/calculator';

  static async getList(calculatorCodeName = 'breakbulk') {
    const preferenceName = codeNameToPreferenceName[calculatorCodeName];
    const res = await Calculator.fetch(`/preferences/byType/calculator,calculatorDrybulk?onlyMeta&expand=user&select=vessel.name`, {method: 'GET'});
    if(res.data && Array.isArray(res.data)) {
      res.data.forEach(c => c.calculator = c.calculator || c.calculatorDrybulk);
    }
    return res;
  }

  static async create(calculator, name, calculatorCodeName = 'breakbulk') {
    calculator = convertToSameTimeZ(lodash.cloneDeep(calculator));
    calculator.version = 3;
    return await Calculator.fetch(`/preferences/${codeNameToPreferenceName[calculatorCodeName]}/${window.encodeURIComponent(name)}`, { method: 'POST', body: JSON.stringify(calculator) });
  }

  static async update(_id, calculator) {
    calculator = convertToSameTimeZ(lodash.cloneDeep(calculator));
    calculator.version = 3;
    return await Calculator.fetch(`/preferences/${_id}/${window.encodeURIComponent(calculator.name)}`, { method: 'PUT', body: JSON.stringify(calculator) });
  }

  static async rename(_id,name){
    return await Calculator.fetch(`/preferences/${_id}/meta`, { method: 'PUT', body: JSON.stringify({name}) });
  }
  static async remove(_id,name){
    return await Calculator.fetch(`/preferences/${_id}`, { method: 'DELETE' });
  }

  static async findById(_id, calculatorCodeName = 'breakbulk') {
    const preferenceName = codeNameToPreferenceName[calculatorCodeName];
    const res = await Calculator.fetch(`/preferences/${_id}`, { method: 'GET' });
    if(res.data) {
      res.data.calculator = res.data.calculatorDrybulk || res.data.calculator;
      res.data.calculator = transformChannelsToVia(res.data.calculator);
      fixCalculatorFromApi(res.data.calculator, res.data.type);

    }
    return res;
  }

  static async populateFullInfo(calculatorList, calculatorCodeName = 'breakbulk'){
    for (let i = 0; i < calculatorList.length; i++) {
      const calculatorListElement = calculatorList[i];
      const res = await this.findById(calculatorListElement._id, calculatorCodeName);
      Object.assign(calculatorListElement,res.data);
    }
    return calculatorList;
  }

  static async printCalculatorList(calculatorList, calculatorCodeName = 'breakbulk') {
    calculatorList = await this.populateFullInfo(calculatorList,calculatorCodeName);
    for (let i = 0; i < calculatorList.length; i++) {
      const calculatorListElement = calculatorList[i];
      await this.printCalculator(calculatorListElement.calculator,calculatorCodeName);
    }
  }

  static async printCalculator(calculator) {
    calculator = convertToSameTimeZ(lodash.cloneDeep(calculator));
    let printType = 'intermodal';
    if (calculator.type === 'freightCalculator') {
      printType = 'general';
      if (calculator.planned?.parameters) {
        calculator.planned.parameters = transformViaToChannel(calculator.planned.parameters);
        calculator.planned.parameters = prepareDrybulkForPrint(calculator.planned.parameters);
      }
      if (calculator.actual?.parameters) {
        calculator.actual.parameters = transformViaToChannel(calculator.actual.parameters);
        calculator.actual.parameters = prepareDrybulkForPrint(calculator.actual.parameters);
      }
    }


    return await Calculator.fetch(`/calculator/printPdf?${printType}`, { method: 'POST', body: JSON.stringify(calculator) });
  }

  static async getSettings(){
    try {
      return await Calculator.fetch('/preferences/singleByType/calculatorSettings')
    } catch (e) {
      return {data:{calculatorSettings:{unitSystem: 'imperial'}}};
    }
  }
  static async saveSettings(settings){
    if(settings._id){
      return await Calculator.fetch('/preferences/'+settings._id, {method: 'PUT', body: JSON.stringify(settings)})
    }else {
      return await Calculator.fetch('/preferences/calculatorSettings/', {method: 'POST', body: JSON.stringify(settings)})
    }

  }

  static async getCustomLdTerms() {
    try {
      const res = await Calculator.fetch('/preferences/byType/calculatorCustomLdTerms?expand=updatedBy');
      return res;
    } catch (e) {
      return { data: { terms: [] } };
    }
  }

  static async saveCustomLdTerms(settings) {
    if (settings._id) {
      return await Calculator.fetch('/preferences/' + settings._id, { method: 'PUT', body: JSON.stringify(settings) })
    } else {
      return await Calculator.fetch('/preferences/calculatorCustomLdTerms/', {
        method: 'POST',
        body: JSON.stringify(settings),
      })
    }
  }
}


export function fixCalculatorFromApi(calculator, type) {
  calculator = transformChannelsToVia(calculator);
  if(!calculator.unitSystem) {
    calculator.unitSystem = 'imperial';
    for (let i = 0; i < calculator.legList.length; i++) {
      const leg = calculator.legList[i];
      if(leg.sf) {
        leg.sfCbf = leg.sf * 35.3147;
      }

    }
  }
  if(calculator.vessel) {
    if(!calculator.vessel.consumptions) {
      calculator.vessel.consumptions = {};
    }
    if(!calculator.vessel.capacity) {
      calculator.vessel.capacity = {};
    }
    if(!calculator.vessel.speeds) {
      calculator.vessel.speeds = {};
    }
  }
  calculator.ops = calculator.ops || {};
  calculator.cargoList.forEach(cargo => {
    const loading = calculator.legList.find(l => l.cargoId === cargo._id && l.type === 'loading');
    const discharging = calculator.legList.find(l => l.cargoId === cargo._id && l.type === 'discharging');
    if (!cargo.loadingPort && loading) {
      cargo.loadingPort = loading.port;
    }
    if (!cargo.unloadingPort && discharging) {
      cargo.unloadingPort = discharging.port;
    }
    if (!cargo.sf && loading) {
      cargo.sf = loading.sf;
      cargo.sfCbf = loading.sfCbf;
    }
  });
  if (!calculator.version || calculator.version < 3) {
    calculator.totals.currency = 'USD';
    calculator.ops.other = or0(calculator.ops.other) + or0(calculator.ops.supplies);
    calculator.ops.supplies = 0;
  }
  const fixer = new OldCalculatorFixer();
  if (type === 'calculator') {
    calculator = fixer.fixBreakbulkV1(calculator);
    calculator.type = undefined;
  } else if (!calculator.version || calculator.version < 3) {
    calculator = fixer.fixDrybulkV1(calculator);
  }
  fixer.fixViaDaDetails(calculator);
  calculator.version = 3;
  return calculator;
}

function prepareDrybulkForPrint(calculator) {
  calculator = { ...calculator };
  calculator.totals = { ...calculator.totals };
  calculator.totals.idle = calculator.totals.additionalIdleDays;
  for (let i = 0; i < calculator.legList.length; i++) {
    const leg = { ...calculator.legList[i] };
    leg.idleDays = leg.additionalIdleDays || 0;
    calculator.legList[i] = leg;
  }
  return calculator;
}


export class OldCalculatorFixer {
  calculateTotals = calculateTotals;
  calculateCargoTotals = calculateCargoTotals;
  calculateOnBoard = calculateOnBoard;
  calculateRotationTotals = calculateRotationTotals;
  usdToCurrentCurrency = (val, totals) => {
    const currency = totals.currency || 'USD';
    const rate = totals.currencyRates?.[currency] || 1;
    if (!rate || !val || currency === 'USD') {
      return val;
    }
    return or0(val) * rate;
  }
  fixBreakbulkV1(data){
    data.cargoList.forEach(cargo => {
      const comm = (1 - ((or0(cargo.addComm) + or0(cargo.brokComm) + or0(cargo.frtTax)) / 100));
      cargo.quantity = cargo.quantity || 1;
      if (!cargo.freight) {
        cargo.freight = cargo.revenue / cargo.quantity;
      }
      cargo.grossFreight = cargo.freight;
      cargo.manuallyChangedFields = cargo.manuallyChangedFields || {};
      cargo.manuallyChangedFields.grossFreight = cargo.grossFreight;

      cargo.freight = round(or0(cargo.grossFreight) * comm,4);
      cargo.revenue = round(cargo.quantity * cargo.freight);
      cargo.frt = cargo.quantity;
      if (cargo.unit !== 'MT' || cargo.unit !== 'CBM') {
        cargo.unit = 'mt';
      }
      data.legList.forEach(leg => {
        if (leg.cargoId === cargo._id) {
          leg.intake = { unit: cargo.unit?.toLowerCase() || 'mt', value: cargo.quantity };
          leg.additionalIdleDays = leg.idleDays;
          leg.idleDays = 0;
          leg.manuallyChangedFields = { ...(leg.manuallyChangedFields || {}), intake_value: cargo.quantity, idleDays: 0.0000000000001, additionalIdleDays: leg.additionalIdleDays };
        }
      });
    });
    data.legList = this.calculateOnBoard(data.legList, data.vessel);
    const rotationTotals = this.calculateRotationTotals(data.legList, data.consumptions[data.consumptions.mode], data.fuelPrices, data.cargoList, {} );
    const cargoTotals = this.calculateCargoTotals(data.cargoList);
    let totals = data.totals;
    totals = this.calculateTotals('duration', round(rotationTotals.duration, 5), totals);
    totals = this.calculateTotals('totalBunker', round(rotationTotals.totalBunker, 5), totals);
    totals = this.calculateTotals('totalDa', round(rotationTotals.totalDa, 5), totals);
    totals = this.calculateTotals('atSea', round(rotationTotals.atSea, 5), totals);
    totals = this.calculateTotals('work', round(rotationTotals.work, 2), totals);
    totals = this.calculateTotals('atPort', round(rotationTotals.atPort, 5), totals);
    totals = this.calculateTotals('tt', round(rotationTotals.tt, 5), totals);
    totals = this.calculateTotals('idle', round(rotationTotals.idle, 5), totals);
    totals = this.calculateTotals('additionalIdleDays', round(rotationTotals.additionalIdleDays, 5), totals);
    totals = this.calculateTotals('totalCanal', round(rotationTotals.totalCanal, 5), totals);
    totals = this.calculateTotals('totalDistance', round(rotationTotals.totalDistance, 5), totals);
    totals = this.calculateTotals('totalSecaDistance', round(rotationTotals.totalSecaDistance, 5), totals);
    totals = this.calculateTotals('totalCarbonCost', round(this.usdToCurrentCurrency(rotationTotals.totalCarbonCost / (totals.currencyRates?.EUR || 1), totals), 5), totals);
    totals.bunkersByFuelType = rotationTotals.bunkersByFuelType;
    const ops = data.ops;
    ops.other = or0(ops.other) - or0(ops.demurrage) + or0(ops.dispatch) + or0(ops.security)
      + or0(ops.ilhoc) + or0(ops.cev) + or0(ops.supplies) + or0(ops.survey)
      + or0(ops.supervision);
    ops.demurrage = 0;
    ops.dispatch = 0;
    ops.security = 0;
    ops.ilhoc = 0;
    ops.cev = 0;
    ops.supplies = 0;
    ops.survey = 0;
    ops.supervision = 0;
    totals = this.calculateTotals('totalLiner', round(cargoTotals.totalLiner, 0), totals);
    totals = this.calculateTotals('totalAddress', round(cargoTotals.totalAddress, 0), totals);
    totals = this.calculateTotals('totalBrokerage', round(cargoTotals.totalBrokerage, 0), totals);
    totals = this.calculateTotals('totalFrt', round(cargoTotals.totalFrt, 0), totals);
    totals = this.calculateTotals('totalRevenue', round(cargoTotals.totalRevenue, 2), totals);
    totals = this.calculateTotals('totalRevenueGross', round(cargoTotals.totalRevenueGross, 2), totals);
    totals = this.calculateTotals('totalFreightTons', round(cargoTotals.totalFreightTons, 2), totals);
    totals = this.calculateTotals('averageFreightComm', round(cargoTotals.averageFreightComm, 2), totals);
    totals.tw = cargoTotals.tw;
    totals.tv = cargoTotals.tv;
    data.totals = totals;
    return data;
  }

  fixDrybulkV1(data){
    let totals = data.totals;
    data.legList.forEach(leg => {
      if (leg.type === 'passage') {
        leg.da = 0;
      }
    });
    totals = this.calculateTotals('totalBunker', totals.totalBunker, totals, true);
    data.totals = totals;
    return data;
  }

  fixViaDaDetails(data) {
    data.legList.forEach(leg => {
      if (!leg.viaDaDetails) {
        leg.viaDaDetails = buildViaDaDetailsFromVia(leg.via || []);
        if (leg.viaDa > 0) {
          leg.viaDaDetails.total = leg.viaDa;
          const suezDa = leg.viaDaDetails?.daList.find(da => da.canalId === '5ada202ea63c49531e40f0bc');
          if (suezDa) {
            suezDa.value = leg.viaDa;
            suezDa.manuallyChanged = true;
            return;
          }
          if (leg.viaDaDetails.daList.length === 2) {
            leg.viaDaDetails.daList[0].value = leg.viaDa;
          } else {
            leg.viaDaDetails.daList[leg.viaDaDetails.daList.length - 1].value = leg.viaDa;
          }
        }
      }
    });
  }
}
