import orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import {getDefaultPorts} from "../components/Documents/utils";
import {stringify, v4} from "uuid";

export const capitalize = str => str ? `${String(str.slice(0, 1).toUpperCase())}${String(str.slice(1, str.length))}` : ""
export const isShipnextOrVaramar = user => user?.email ? /@(shipnext\.com|varamar\.com)$/i.test(user.email) : false
export const grabErrorMessage = error => error?.message?.errors?.[0]?.messages ? Array.isArray(error?.message?.errors?.[0]?.messages) ? error?.message?.errors?.[0]?.messages?.[0] : error?.message?.errors?.[0]?.messages : error?.message?.errors?.[0]?.message || error?.message || error
// can be bound to this context (used in MyFleet and SpFleet)
export function selector(select, state, ...rest) {
  return typeof select === 'function' ? select(this?.state ?? state, this?.props, ...rest) : (this?.state?.[select] ?? state[select]);
};

export const returnMockByType = variable => {
  const variableType = typeof(variable);

  let mock;

  switch (variableType) {
    case 'object': {
      if (variable === null) {
        mock = null;
      }
      else if (Array.isArray(variable)) {
        mock = [];
      }
      else {
        mock = {};
      }
      break;
    }
    case 'string': {
      mock = '';
      break;
    }
    case 'number': {
      if (isNaN(variable)) {
        mock = NaN;
      }
      mock = 0;
      break;
    }
    case 'function': {
      mock = function () {};
      break;
    }
    case 'boolean': {
      mock = false;
      break;
    }
    default: {
      mock = {};
    }
  }

  return mock;
}

export const validateEmail = value => value && /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})|(.*<[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}>)$/i.test(value);

export const isInputWithError = (instance) => {
  const {
    requiredError,
    className = '',
  } = instance.props;
  const { isRequired, isPristine, isValid, isFormSubmitted } = instance;
  const isRequiredError =
    isRequired() &&
    !isPristine() &&
    !isValid() &&
    isFormSubmitted() &&
    requiredError;
  let cn = className;
  const errorText = (!isPristine() && instance.getErrorMessage()) || isRequiredError || (instance.props.invalid ? instance.props.errorText : '');
  if (errorText) {
    cn += " with_error";
  }
  return {
    className: cn,
    errorText,
  };
};
export const isInputWithErrorFormSubmitted = instance => {
  const { isFormSubmitted } = instance;

  const result = isInputWithError(instance);

  if (!isFormSubmitted()) {
    result.errorText = null;
    result.className = instance.props.className;
  }

  return result;
};

export const isIfchor = user => user && /@ifchor\.(ch|com)$/i.test(user.email);

export const sortByName = (arr, order = "asc") => {
  if (!arr) return [];

  const orderDictionary = {
    "asc": [1, -1],
    "desc": [-1, 1]
  }

  const sortHelper = (a,b) => {
    const firstNormalizedName = String(a.name).toLowerCase().trim();
    const secondNormalizedName = String(b.name).toLowerCase().trim();

    const returnOrder = orderDictionary[order];

    return firstNormalizedName > secondNormalizedName ? returnOrder[0] : firstNormalizedName < secondNormalizedName ? returnOrder[1] : 0
  };

  const arrCopy = [...arr];

  return arrCopy.sort(sortHelper);

}

export function fileToFormData(file, type = 'attachment') {
  if (!file) return;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return formData
}

export const throttle = (fn, timeout = 100) => {
  let id = null,
      isThrottling = false,
      savedArgs = null,
      savedContext = null;
  return function _throttle(...args) {

    if (isThrottling) {
      savedArgs = args;
      savedContext = this;
      return;
    }

    const fireFn = () => {
      isThrottling = false;
      if (savedArgs) {
        _throttle.apply(savedContext, savedArgs);
        savedContext = null;
        savedArgs = null;
        id = null;
      }
    }

    fn.apply(this, args);

    isThrottling = true;

    if (!id) {
      setTimeout(fireFn, timeout);
    }

  }
}

export const debounce = (fn, timeout = 250) => {
  let id = null;
  return function(...args) {
    const clear = () => {
      id = null;
      fn.apply(this, args);
    }
    if (!id) {
      fn.apply(this, args);
      id = setTimeout(() => id = null, timeout);
      return;
    }
    else {
      clearTimeout(id);
      id = setTimeout(clear, timeout);
    }
  }
}
export const asyncDebounce = (fn, timeout = 250) => {
  let id = null;
  return async function(...args) {
    try {
      const clear = async () => {
        id = null;
        await fn.apply(this, args);
      }
      if (!id) {
        await fn.apply(this, args);
        id = setTimeout(() => id = null, timeout);
        return;
      }
      else {
        clearTimeout(id);
        return new Promise((res, rej) => {
          id = setTimeout(async () => {
            await clear();
            res();
          }, timeout);
        })
      }
    } catch (error) {
      console.error(error);
    }
  }
}
export const debounceWithoutFirstCall = (fn, timeout = 250) => {
  let id = null;
  return function(...args) {
    const clear = () => {
      id = null;
      fn.apply(this, args);
    }
    if (!id) {
      id = setTimeout(clear, timeout);
      return;
    }
    else {
      clearTimeout(id);
      id = setTimeout(clear, timeout);
    }
  }
}
export const once = (fn, timeout = 250) => {
  let id = null;
  return function(...args) {
    const clear = () => {
      id = null;
    }
    if (!id) {
      id = setTimeout(clear, timeout);
      fn.apply(this, args);
      return;
    }
  }
}

export function scrollToAnchor(node) {
  if (!node) {
    return null;
  }
  const anchor = document.getElementById(`${node.dataset?.link}`);
  if (anchor) {
    anchor.scrollIntoView({
      behavior: 'smooth'
    });
  }
  else {
    node.scrollIntoView({
      behavior: 'smooth'
    });
  }
}

export function getIntegratorIdFromURL() {
  if (!window || !window.location) return null;
  const {
    location: { search }
  } = window;
  const integratorIdParam = new URLSearchParams(search);
  return integratorIdParam.get("integratorId");
}

export function sleep(ms = 0) {
  return new Promise(r => setTimeout(r, ms));
}
export function removeFromArray(array, index) {
  if (index === -1) return array;
  return [...array.slice(0, index), ...array.slice(index + 1)];
}
export function replaceInArray(array, index, value) {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
}

/**
 * Converts recursively {pol:{_id:"someid",...}} to {pol:"someid"}
 * @param obj
 * @returns {{}|*}
 */
export function prepareRequest(obj) {
  obj = { ...obj };
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (Object.prototype.toString.call(value) === '[object Object]') {
      if (value._id) {
        obj[key] = value._id;
      } else {
        obj[key] = prepareRequest(value);
      }
    }
  });
  return obj;
}

export function findKeyAndAssignValue(initialObj, key, value, cb) {
  Object.keys(initialObj).forEach(initialObjKey => {
      if (typeof initialObj[initialObjKey] === 'object' && initialObj[initialObjKey] !== null) {
        findKeyAndAssignValue(initialObj[initialObjKey], key, value[initialObjKey], cb)
      }
      else {
        if (initialObjKey === key) {
          initialObj[key] = cb ? cb(value[key]) : value[key];
        }
      }
  })

}

export function prepareRequestWithExceptions(exceptionsArr, transformKeyCallback) {
  return function(obj) {
    let transformedObj = prepareRequest(obj);
    exceptionsArr.forEach(exception => findKeyAndAssignValue(transformedObj, exception, obj, transformKeyCallback));
    return transformedObj
  }
}

export function toUTC(date) {
  if (!date.getDate) {
    date = new Date(date);
  }
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}
export function fromUTC(date) {
  if (!date.getDate) {
    date = new Date(date);
  }
  return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
}

export function noop() {}
export function stopPropagation(e) {
  e && e.stopPropagation();
}

export function indexByAsArray(array = [], field) {
  const res = [];
  array.forEach((element) => {
    if (element) {
      res[element[field]] = element;
    }
  });
  return res;
}

export function getCookie(name) {
  if (typeof document === 'undefined') {
    return;
  }
  const reg = new RegExp(name + '=([^; ]*)');
  const match = reg.exec(document.cookie);
  return match && match[1] && decodeURIComponent(match[1]);
}

export function clearCookie(name) {
  if (typeof document === 'undefined') {
    return;
  }
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
}

export function getAndClearCookie(name) {
  const value = getCookie(name);
  clearCookie(name);
  return value;
}

const unsubscribedReg = // eslint-disable-next-line
  /^\/((\/)|(main-deck\/general)|(main-deck\/liquid)|(main-deck\/containers)|(settings\/[a-z0-1\-_.]*)|(help)|(cookies)|(legal)|(privacy)|(login)|(register)|(rules-regulations)|(copyright)|(invest)|(connect)|(partner)|(conference)|(forgot-password.*)|(verify-email.*)|(confirm-email.*)|(unsubscribe.*)|(imprint)|(port.*)|(port\/.+)|(news.*)|(news\/.+)|(cargo\/general\/create.*)|(timecharter\/.*)|(bid\/tc.*)|(contracts.*)|(my\/.*))\/?$/i;

export function isAllowedPathForUnsubscribed(path) {
  return true; //TODO e.fedorov temporary solution remove all logic based on user endAt
  if (path === '/') {
    return true;
  }
  return unsubscribedReg.test(path);
}


const notVerifiedReg = /(cargo\/general\/create.*)|(my\/.*)/i;
export function isNotAllowedForNotVerified(path) {
  return notVerifiedReg.test(path);
}

export function escapeRegExp(text, flags = 'i') {
  return new RegExp(
    text.replace(
      /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
      '\\$&',
    ),
    flags);
}

export function sortByPath(array, path, sort = 1) {
  const parsedPath = path.split('.');
  return orderBy(
    array,
    [ v => v.weight || 0,
      v => v.status?.weight || 0,
      function (o) {
        let value = o[parsedPath[0]];
        for (let i = 1; i < parsedPath.length; i++) {
          if (value === undefined) return;
          value = value[parsedPath[i]];
        }
        return value;
      },
    ],
    ['desc','desc', sort > 0 ? 'asc' : 'desc'],
  );
}

export function clearEmptyStrings(obj, recursive = false) {
  if (!obj) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] === '') {
      obj[key] = undefined;
      return;
    }
    if (recursive) {
      const type = Object.prototype.toString.call(obj[key]);
      if (type === '[object Object]' || type === '[object Array]') {
        obj[key] = clearEmptyStrings(obj[key], recursive);
      }
    }
  });
  return obj;
}

export function clearNullishValues(obj, recursive = false) {
  if (!obj) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] == null) {
      obj[key] = undefined;
      return;
    }
    if (recursive) {
      const type = Object.prototype.toString.call(obj[key]);
      if (type === '[object Object]' || type === '[object Array]') {
        obj[key] = clearNullishValues(obj[key], recursive);
      }
    }
  });
  return obj;
}

export function sortObject(obj) {
  if (typeof obj !== 'object') {
    return obj;
  }
  const temp = {};
  const keys = [];
  for (const key in obj) {
    keys.push(key);
  }
  keys.sort();
  for (const index in keys) {
    temp[keys[index]] = sortObject(obj[keys[index]]);
  }
  return temp;
}

export function normalizeUrl(url) {
  if (!url) {
    return url;
  }

  if (url.indexOf('http') !== 0) {
    url = 'http://' + url;
  }
  return url;
}

/**
 * Load some module by condition for example
 * @param path String - load relative path from root (src), bad idea but fast realization (need use alias instead in webpack) :)
 * @return Promise with lib data or not :)
 * */
export function loadModule(path = '') {
   // return new Promise((res, rej) => {
   //   return res(require(`../${path}`));
   // })
}


let mapLoadedPromise;
export function loadMap() {
  if (mapLoadedPromise) {
    return mapLoadedPromise;
  }
  mapLoadedPromise = new Promise((resole, reject) => {
    window.onGoogleMapLoaded = resole;
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD-l0poDxzTePu37V1vm5_4emQt5KBY4SE&libraries=geometry,drawing&callback=onGoogleMapLoaded';
    document.body.appendChild(script);
  });
  return mapLoadedPromise;
}

const UCM = {
  kg: { mt: 0.001 },
  mm: { m: 0.001, cm: 0.1 },
  cm: { m: 0.01, mm: 10 },
}
export function convert({ value, unit, ...rest }, newUnit) {
  let coef;
  if (!unit || !newUnit || (unit === newUnit)) {
    return { value, unit, ...rest };
  }
  if (UCM[unit] && UCM[unit][newUnit]) {
    coef = UCM[unit][newUnit];
  } else if (UCM[newUnit] && UCM[newUnit][unit]) {
    coef = 1 / UCM[unit][newUnit];
  } else {
    return { value, unit, ...rest };
  }
  return { value: value * coef, unit: newUnit, ...rest };
}

export const getPortsFromRegion = (region = { childs: [] }, ports) => {
  return ports.filter((port) => {
    return region.childs.includes(port._id);
  }).sort((a, b) => a.name.localeCompare(b.name));
};

export const clearDuplicatesCoordinates = (ports) => {
  const temp = new Set();

  return ports.map((port) => {
    let latlng = port.coordinates;

    if (latlng) {
      const key = latlng[0] + "" + latlng[1];
      if (temp.has(key)) {
        let newLat = latlng[1] + (Math.random() - .5) / 1500;
        let newLng = latlng[0] + (Math.random() - .5) / 1500;

        port.coordinates = [newLng, newLat];
      }
      else {
        temp.add(key);
      }
    }

    return {
      ...port,
    };
  });

};

export const toLatLng = (array) => {
  return array.map(point => ({ lng: point[0], lat: point[1] }));
};

export const hasValidPolygon = (polygon) => {
  return polygon && polygon.length >= 3;
};

export function postRegularForm(url, data, target = '_blank') {
  const form = document.createElement('form');
  const input = document.createElement('input');
  form.name = 'form' + Math.random();
  form.method = 'POST';
  form.target = target;
  form.action = url;
  input.type = 'hidden';
  input.name = 'data';
  input.value = JSON.stringify(data);
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
  form.remove();
}

export function apiErrorToFormsyError({ errors = [] }){
  const result = {};
  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];
    if (!error.field) {
      continue;
    }
    let key = error.field.replace(/\.([^.])\.?/ig, '[$1]').replace(/]([^\[\]]+)\[?/ig, '][$1][');
    if (key[key.length - 1] === '[') {
      key = key.slice(0, -1);
    }
    result[key] = error.messages.join(', ').replace(/"\d+"/ig, '');
  }

  return result;
}

const tradingStatuses = ['freight', 'waiting', 'bid','offer', 'fixed', 'fix','reject','rejected', 'freight-in', 'freight-out', 'closed', 'remove', 'removed'];
export function isTrading(status) {
  return tradingStatuses.indexOf(status) !== -1;
}

const removedStatuses = ['closed', 'remove', 'removed'];
export function isRemoved(status){
  return removedStatuses.indexOf(status) !== -1;
}

export function isExpired(status) {
  return status.end < new Date();
}


export function computeAndSetChannel(obj, user) { // company, broker, market
  if(obj.computedChannel) {
    return obj;
  }
  obj.computedChannel = 'market';
  if(obj.source) {
    obj.computedChannel = 'broker';
    return obj;
  }

  if(!obj.companyId && !obj.source) {
    obj.computedChannel = 'market';
    return obj;
  }
  if(obj.addedBy === user?._id || obj.companyId === user?.company?._id) {
    obj.computedChannel = 'company';
  }
  return obj;
}

const frStatuses = ['freight','freight-in', 'freight-out'];

export function isFreightRequest(status) {
  return frStatuses.indexOf(status) !== -1;
}

const rejectedStatuses = ['reject','rejected','closed', 'remove', 'removed' ];
export function isRejected(status){
  return rejectedStatuses.indexOf(status) !== -1;
}


export const uniqBy = _uniqBy;

export function getId(o){

  if(!o) {
    return o
  }
  return o._id || o;
}

export function isSameOrCloneCargoRequest(a,b) {
  if(!a || !b) {
    return false;
  }
  const a_id = getId(a);
  const a_cloneId = getId(a?.cloneCargo);
  const b_id = getId(b);
  const b_cloneId = getId(b?.cloneCargo);

  if(a_id === b_id || a_id === b_cloneId || a_cloneId === b_id || a_cloneId === b_cloneId) {
    return true;
  }
  return false;
}


const srcMap = new Map();

export function loadScript(src) {
  if (srcMap.has(src)) {
    return srcMap.get(src);
  }

  const promise = new Promise(resolve => {
    const el = document.createElement('script');
    el.async = true;
    el.onload = resolve;
    el.src = src;
    document.body.appendChild(el);
  })
  srcMap.set(src, promise);
  return promise;
}

const plansOrder = { 'marketplace':0 , 'connect':1, 'marketplace,connect': 2, 'connect,marketplace':2 }
export function convertPlans(plans){
  plans.month.forEach(plan => plan.key = plan.products.join());
  plans.year.forEach(plan => {
    plan.key = plan.products.join();
    const oppositePlan = plans.month.find(p => p.key === plan.key);
    plan.realMoPrice = round(0.01 * plan.amount / 12, 2);
    plan.realAmount = round(0.01 * plan.amount, 2);
    oppositePlan.realMoPrice = round(0.01 * oppositePlan.amount, 2);
    oppositePlan.realAmount = round(0.01 * oppositePlan.amount, 2);
    plan.saving = round(0.01 * (oppositePlan.amount * 12  -  plan.amount), 2);
    oppositePlan.saving = -1 * plan.saving;
    plan.moSaving = round(plan.saving / 12, 2);
    oppositePlan.moSaving = round(oppositePlan.saving / 12, 2);
    plan.cycleText = 'annually';
    plan.perShort = 'yr';
    oppositePlan.perShort = 'mo';
    oppositePlan.cycleText =  'monthly';
    plan.oppositePlan = { ...oppositePlan };
    oppositePlan.oppositePlan = { ...plan, oppositePlan: undefined };
  });
  plans.year.sort((a,b) => a.amount - b.amount);
  plans.month.sort((a,b) => a.amount - b.amount);
  plans.year.sort((a,b) => plansOrder[a.key] - plansOrder[b.key]);
  plans.month.sort((a,b) => plansOrder[a.key] - plansOrder[b.key])
  let lastPlan = plans.year[plans.year.length -1 ];
  lastPlan.isRecommended = true;
  lastPlan.oppositePlan.isRecommended = true;
  lastPlan = plans.month[plans.month.length -1 ];
  lastPlan.isRecommended = true;

  return plans;
}

export function isUserSubscribedToMarketplace(user){
  try {
    if (!user) {
      return false;
    }
    if (user.activeProducts.indexOf('shipnext:paid') !== -1) {
      return true;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
  return false;
}

export function isUserOnFreePlan(user){
  if(!user) {
    return false;
  }
  return user.activeProducts.indexOf('shipnext:free') !== -1;

}

export function getFractional(price) {
  if(!price) {
    return;
  }
  return Math.round((price - parseInt(price)) * 100) || '00';
}

export  async function redirectToCheckout(data) {
  const {sessionId, pk} = data;
  await loadScript('https://js.stripe.com/v3/');
  const stripe = Stripe(pk);
  await stripe.redirectToCheckout({ sessionId });
};



const productsRating = {
  connect:10,
  marketplace: 10
};

export function isUpgradePlan(currentPlan, plan) {
  if(!currentPlan) {
    return true;
  }
  if(!plan) {
    return false;
  }

  return plan.products.length > currentPlan.products.length || (currentPlan.products.join() === plan.products.join() && plan.interval === 'year');

}

export function round(number, fractionDigits = 2) {
  const coef = Math.pow(10, fractionDigits);
  return Math.round(number * coef) / coef;
}

export function roundStep(value, step) {
  step || (step = 1.0);
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

/**
 * Sets the value at path of object
 * @param o Object
 * @param path String
 * @param value any
 * @returns {*}
 */
export function set(o, pathStr, value) {
  const root = o
  const path: string[] = pathStr.split('.');
  const lastPathElement = path[path.length - 1]
  for (let i = 0; i < path.length - 1; i++) {
    const pathElement = path[i];
    if (typeof o[pathElement] === 'undefined') {
      o[pathElement] = {};
    }
    o[pathElement] = { ...o[pathElement] }
    o = o[pathElement];
  }
  o[lastPathElement] = value;
  return root;
}

export const someProp = (obj, fn) => {
  for (const key in obj) {
    if (fn(obj[key])) {
      return true;
    }
  }
  return false;
};

export const somePropIsTrue = (obj) => someProp(obj, (v => v === true));

export function UUIDToObjectId(uuid){
  const uidParts = uuid.split('-');
  const nsHex = uidParts[2].slice(1) + uidParts[1] + uidParts[0];
  const sHex = ((((parseInt(nsHex, 16) / 10000) - 12219292800000) / 1000) | 0).toString(16).padStart(8, '0');
  const correction = ((parseInt(uidParts[0].slice(6),16) & 0x3) << 14);
  const counter = (parseInt(uidParts[3], 16) & 0x3fff) | correction;
  const nodeAndCounter = uidParts[4] + counter.toString(16).padStart(4, '0');
  return sHex + nodeAndCounter;
}

export function isTagsFitted(entity, tags, skipTextMatch){
  const entityTags = [...entity.tags];
  if (entityTags.length === 0) {
    entityTags.push('shipnextNoTag2023');
  }
  if (tags?.condition?.length && entity.fullTextSearch) {
    for (let i = 0; i < tags.condition.length; i++) {
      const conditionElement = tags.condition[i];
      let passed = false;
      for (let j = 0; j < conditionElement.anyOf.length; j++) {
        let anyOfElement = conditionElement.anyOf[j];
        if (anyOfElement.noTag) {
          anyOfElement = { value: 'shipnextNoTag2023' };
        }
        if (anyOfElement.value) {
          passed = passed || (entityTags.includes(anyOfElement.value) ^ anyOfElement.negate);
        } else {
          if (skipTextMatch) {
            return true;
          }
          const reg = escapeRegExp(anyOfElement.search?.toLowerCase());
          passed = passed || (reg.test(entity.fullTextSearch.toLowerCase()) ^ anyOfElement.negate);
        }
      }
      if (!passed) {
        return false;
      }
    }
  }
  return true;
}

export function safeDate(mayBeDate) {
  if (!mayBeDate) {
    return null;
  }
  if (mayBeDate instanceof Date) {
    return mayBeDate;
  }
  if (typeof mayBeDate === 'string' && mayBeDate.length === 10) {
    mayBeDate += 'T00:00:00.000';
  }
  return new Date(mayBeDate);
}

export const flattenPorts = ports => {
  if (!ports) return getDefaultPorts();
  return ports.map(loadingUnloadingWithTypeNext => {
    const { oneOf, _id } = loadingUnloadingWithTypeNext;

    if (!oneOf) return [];

    return oneOf.map(( loadingUnloading, index ) => index === 0 ? ({ ...loadingUnloading, type: "next", groupId: _id }) : ({ ...loadingUnloading, type: "alt", groupId: _id }));
  }).flat(1).map((port, index) => ({ ...port, i: index, _id: port._id || v4() }));
};

export function muteEvent(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
}


export function getVesselPublicHref(baseUrl = '/vessel/', vessel) {
  return `${baseUrl}${vessel.imoNumber}-${vessel.name.replace(/ /g, '-').toLowerCase()}`;
}


export function hexToBytes(string) {
  const bytes = new Uint8Array(Math.ceil(string.length / 2));
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(string.substr(i * 2, 2), 16);
  }
  return bytes;
}

export function objectIdToUUID(hexId: string) {
  let i = 0;
  const ab = new Uint8Array(16);
  let msecs = parseInt(hexId.slice(0, 8), 16) * 1000;
  // Convert from unix epoch to Gregorian epoch
  msecs += 12219292800000;
  //preserve counter hi byte in last node byte
  const node = hexToBytes(hexId.slice(8, 20));
  const counter = parseInt(hexId.slice(18), 16) & 0xffffff;
  const clockseq = counter & 0x3fff;

  // `time_low`
  const tl = ((msecs & 0xfffffff) * 10000) % 0x100000000;
  ab[i++] = (tl >>> 24) & 0xff;
  ab[i++] = (tl >>> 16) & 0xff;
  ab[i++] = (tl >>> 8) & 0xff;
  // preserve clock_seq_hi  2 bits (later corrupted by variant) in time, adding some ns to time its not a big deal
  ab[i++] = (tl & 0xfc) | ((counter & 0xffff) >>> 14);
  const tmh = ((msecs / 0x100000000) * 10000) & 0xfffffff;
  ab[i++] = (tmh >>> 8) & 0xff;
  ab[i++] = tmh & 0xff;

  // `time_high_and_version`
  ab[i++] = ((tmh >>> 24) & 0xf) | 0x10; // include version
  ab[i++] = (tmh >>> 16) & 0xff;
  // `clock_seq_hi_and_reserved` (include variant)
  ab[i++] = (clockseq >>> 8) | 0x80;

  // `clock_seq_low`
  ab[i++] = clockseq & 0xff;
  // `node`
  for (let n = 0; n < 6; ++n) {
    ab[i + n] = node[n];
  }
  return stringify(ab);
}

export function extractEmail(email) {
  if (email.indexOf('<') !== -1) {
    email = (/<(.*)>/.exec(email)?.[1]) || email;
  }
  return email.toLowerCase();
}

export function extractNameFromEmail(email) {
  const i = email.indexOf('<');
  if (i < 1) {
    return '';
  }
  return email.substring(0, i);
}

const INLINE_EXT = ['txt', 'pdf', 'jpg', 'jpeg', 'png', 'tiff', 'bmp', 'gif', 'webp', 'raw'];
export function getAttachmentAProps(file){
  const ret = { };
  const ext = file.name.split('.').slice(-1)[0];
  const inline = INLINE_EXT.indexOf(ext) !== -1;
  ret.href = ret.url || `/api/v1/file/${file._id}`;
  if (file.relativeUrl) {
    ret.href = file.relativeUrl;
  }
  if (inline) {
    ret.href += '?inline=true';
    if (file._id) {
      ret.href = `/api/v1/file/${encodeURIComponent(file.name)}?inline=true&_id=${file._id}`;
    }
    ret.target = '_blank';
  } else {
    ret.download = file.name;
  }
  return ret;
}
