import {
  ISSUE_SELECT_ELEMENT,
  ISSUE_REMOVE_SELECTED_ELEMENT,
  ISSUE_SELECTABLE_ELEMENT,
  ISSUE_CLEAR_SELECTED_ELEMENTS,
} from "../actions";

export default {
  [ISSUE_SELECTABLE_ELEMENT](state, action) {
    return {
      ...state,
      selectable: action.payload,
    };
  },
  [ISSUE_SELECT_ELEMENT](state, action) {
    if (state.selectedElements[action.payload.entity]?.find(el => el.id === action.payload.id)) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      selectedElements: { ...state.selectedElements, [action.payload.entity]: [...state.selectedElements[action.payload.entity], action.payload] },
    };
  },
  [ISSUE_REMOVE_SELECTED_ELEMENT](state, action) {
    return {
      ...state,
      selectedElements: { ...state.selectedElements, [action.payload.entity]: state.selectedElements[action.payload.entity]?.filter(e => e.id !== action.payload.id && e.entityId !== action.payload.entityId) },
    };
  },
  [ISSUE_CLEAR_SELECTED_ELEMENTS](state, action) {
    return {
      ...state,
      selectedElements: {
        cargos: [],
        vessels: [],
        positions: [],
        ais: [],
        orders: [],
        mails: [],
        mailThreads: [],
        snpVessels: [],
        myVessels: [],
      },
      selectable: false,
    };
  },
};

