import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import MainStepper, { SlightlyOptimizedAnimatedStep } from '../../../Common/Stepper';
import { grabErrorMessage, scrollToAnchor } from '../../../../core/utils';
import s from './Request.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import HiddenRequiredStepInput from '../../../Monitor/TCOrders/HiddenRequiredStepInput';
import styled from 'styled-components';
import SpinnerBtn from '../../../Common/_SpinnerBtn';
import Preamble from '../../../Documents/Request/Preamble';
import { Cargo } from '../../../Monitor/TCOrders/Steps/Cargo';
import VesselRequirements from './VesselRequirements';
import factory from '../../../Documents/Request/FormStateProvider';
import PlaceAndDates from './PlaceAndDates';
import CharterDetails from './CharterDetails';
import FormsyInput from '../../../Common/FormsyInput';
import RaisedButton from '../../../Common/RaisedButton';
import Terms from '../../../Documents/Terms';
import NarrowContractSelect from '../../../NewInputs/NarrowContractSelect';
import NarrowFormsyText from '../../../NewInputs/NarrowFormsyText';
import { _SpeedConsumptions } from '../../../Monitor/Edit/SpeedConsumptions';
import Vessel from '../../../../core/api/Vessel';

const StyledStepper = styled.div`
  .stepper_label.valid svg, .stepper_label.active svg {
    fill: var(--bg-blue) !important;
  }
  .stepper_transition:not(.animated_step_opened_transition_complete) {
    .terms_autocomplete_bar {
      position: relative !important;
    }
  }

`;

const formValidateDelay = 250;

function toCamelCase(str) {
  return str.replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
}

function removeEmpty(obj) {
  for (const [key, value] of Object.entries(obj)) {
    const filteredArray = value.filter(item => {
      if (typeof item === 'object' && Object.values(item).every(val => val === undefined)) {
        return false;
      }
      return true;
    });
    if (filteredArray.length === 0) {
      delete obj[key];
    } else {
      obj[key] = filteredArray;
    }
  }
  return obj;
}

class Request extends factory(React.PureComponent) {
  static contextTypes = {
    cargoTypes: PropTypes.array,
    timeTerms: PropTypes.array,
    showMessage: PropTypes.func,
    countries: PropTypes.array,
  };

  static defaultProps = {
    isEditing: false,
  }
  static DEFAULT_REQUEST_CONTRACT_ID = "58fa240405526733006959a4";

  constructor(props) {
    super(props);

    const state = this.getDefaultState(props);

    this.fields = {};
    this._form = props.tc;
    this._mainForm = null;
    this._mainStepper = null;

    this.state = state;
    this.initialState = state;
    this.genericRef = this.genericRef.bind(this);
    this.handleTermsInvalidSubmit = this.handleTermsInvalidSubmit.bind(this);
    this.handleContractChange = this.handleContractChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleGenericChange = values => {
    const ret = new Promise((res, rej) => {
      this.setState(state => {
        const newState = typeof values === "function" ? values(state) : { ...values };
        return ({
          ...state,
          ...newState,
        });
      }, () => {
        const newRequest = Object.keys(this.getDefaultRequestState()).reduce((acc, key) => ({ ...acc, [key]: this.state[key] }), {});
        res(this.state);
        if (this.props.onChange) {
          this.props.onChange(newRequest);
        }
      });
    });
    return ret;
  }

  getDefaultRequestState = () => ({
    preamble: this.getDefaultPreambleState(),
    ports: {},
    vesselRequirements: {
      registerClasses: [],
      flag: undefined,
      bunkers: [
        {},
      ],
      constantQuantity: "",
      dwt: "",
      imoNumber: "",
      draft: "",
      gt: "",
      nt: "",
      grain: "",
      bale: "",
      teu: "",
      feu: "",
      bhp: "",
      portOfRegistry: "",
      registryNumber: "",
      additionalDetails: "",
      previousCargos: "",
      speedConsumptions: '',
    },
    charterDetails: {},
    cargo: {},
    contract: this.constructor.DEFAULT_REQUEST_CONTRACT_ID,
    terms: {
      default: [],
      user: [],
    },
  })

  getDefaultPreambleState = () => ({
    clientPlace: '',
    clientDate: '',
    clientContractRefNo: '',
    vessel: '',
    vesselDetails: '',
    counteragents: [{
      key: 1,
      type: 'Broker',
      company: '',
      details: '',
    }],
  })

  getDefaultState = (props) => ({

    blockedSteps: {
      isTermsStepBlocked: true,
      isPreambleStepBlocked: true,
      isCargoStepBlocked: true,
    },
    activeStepIndex: 0,
    ...(Object.assign({}, this.getDefaultRequestState(), Object.fromEntries(Object.entries({ ...(props.request || {}) }).filter(([key, val]) => !!val)))),
  })
  componentWillReceiveProps(nextProps) {
    if (nextProps.request !== this.props.request) {
      const newValues = Object.assign({}, this.getDefaultRequestState(), nextProps.request);
      this.setState(state => ({
        ...state,
        ...newValues,
      }));
    }

    if (nextProps.isEditing !== this.props.isEditing) {
      nextProps.isEditing ? this.unblockAllSteps() : this.blockAllSteps();
    }

    if (this.props.hash === '#preview' && nextProps.hash !== '#preview') {
      this.unblockAndBlockTransition();
      this.setState({ preview: undefined });
    }
  }
  openFirstStep = () => {
    this.openSpecificFormStep("[data-id='preamble']");
  }
  componentDidMount() {
    if (this.props.innerRef) {
      this.props.innerRef(this);
    }
    if (this.props.isEditing) {
      this.unblockAllSteps();
    }
    // query inputs with required attribute and add asterisk to it's placeholders
    // implemented to avoid wasting time to look for every input and change it's placeholder
    // some inputs by default include asterisk in their's placeholders, some does not.
    if (this._mainForm) {
      const requiredInputs = this._mainForm.querySelectorAll('input[required]');
      Array.from(requiredInputs).forEach(requiredInput => {
        if (!requiredInput) return;
        const parent = requiredInput.parentNode;
        const children = parent && parent.childNodes;
        if (!children) return;

        for (const child of children) {
          if (!child || child === requiredInput) return;

          if (!child.textContent || child.textContent?.includes?.('*')) return;

          child.textContent += '*';
        }
      });
    }
  }

  handleSend() {
    this.genericUnblockStep('isTermsStepBlocked', false).then(() => this.fields.requestType.setValue('request'));
  }

  unblockAllSteps = () => {
    return Promise.all(Object.keys(this.state.blockedSteps).map(step => this.genericUnblockStep(step, false)));
  }

  blockAllSteps = () => {
    return Promise.all(Object.keys(this.state.blockedSteps).map(step => this.genericUnblockStep(step, true)));
  }

  genericRef(name, element) {
    this.fields[name] = element;
  }

  handleCommChange = (ev, val) => {
    this.handleGenericChange({ comm: val || ev.target.value || "" });
  }

  handleContractChange(ev, contract) {
    this.handleGenericChange({ contract: contract?._id || "" });
    this.setState({ contractType: contract });
  }

  handleTermsInvalidSubmit() {
    const selector = '.terms_tab [data-valid=false]';
    const invalidNodes = document.querySelectorAll(selector);
    if (!invalidNodes || !invalidNodes.length) {
      return;
    }
    const tabAttr = invalidNodes[0].attributes.getNamedItem('data-tab');
    if (tabAttr && tabAttr.value === 'standard-terms') {
      try {
        const list = this._termsRef?.refs?.standardList;
        if (!list?.state?.open) {
          list.handleNestedListToggle(new MouseEvent('click'));
        }
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        scrollToAnchor(invalidNodes[0]);
      }, 0);
    } else {
      scrollToAnchor(invalidNodes[0]);
    }
  }
  parseSpeedConsOnLoad = speedCons => {
    let defaultRows = _SpeedConsumptions.defaultRows || [];
    const rows = _SpeedConsumptions.parseRows(speedCons);
    if (defaultRows && defaultRows.length) {
      defaultRows = defaultRows.map(row => {
        const inRows = rows.findIndex(i => i.mode.trim() === row.mode);
        if (inRows >= 0) {
          const obj = {
            ...rows[inRows],
            id: row.id,
          };
          rows.splice(inRows, 1);
          return obj;
        }
        return row;
      });
    }
    const parsed = [...defaultRows, ...rows].map(item => ({ ...item, id: item.id ? item.id : String(Math.random()).replace('.', '') }));
    return parsed;
  }
  getVesselDetails = async (id) => {
    try {
      const vessel = await Vessel.getMonitorVesselById(id);
      return vessel.data.vessel;
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: 'error',
        message: `Error get vessel details: ${grabErrorMessage(error)}`,
      });
    }

  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.preamble.vessel !== prevState.preamble.vessel) {
      if (typeof this.state.preamble.vessel === "object") {
        const vesselDetails = await this.getVesselDetails(this.state.preamble.vessel._id);
        const { dw, bunkers, draft, capacity, imoNumber, gearV2All, speedConsumptionV2 } = this.state.preamble.vessel;
        const { registerClass, flag, homePort, rt } = vesselDetails;
        this.setState({
          vesselRequirements: {
          registerClasses: registerClass ? [{ registerClass: registerClass, _id: registerClass?._id, iacsRegister: registerClass?.iacsRegister }] : [],
          bunkers: bunkers.length ? bunkers : [
            {},
          ],
          dwt: dw.summer || "",
          imoNumber: imoNumber || "",
          draft: draft || "",
          grain: capacity.grain || "",
          bale: capacity.bale || "",
          teu: capacity.teu || "",
          feu: capacity.feu || "",
          gear: gearV2All?.join(", ") || "",
          portOfRegistry: homePort || "",
          flag: flag ? this.context.countries.find(c => c._id === flag._id) : undefined,
          gt: rt.gt || "",
          nt: rt.nt || "",
          speedConsumptionV2: this.parseSpeedConsOnLoad(speedConsumptionV2),
          },
        });
      }
    }
  }

  handleSubmit = (request) => {
    const values = { ...request, ...request.preamble };
    delete values.vesselRequirements;
    delete values.preamble;
    delete values.counteragents;
    if (request.preamble.counteragents.length) {
      values.counteragents = request.preamble.counteragents.map(c => {
        const counteragent = {
        type: c.type,
        company: c.company !== "" ? c.company : undefined,
        details: c.details !== "" ? c.details : undefined,
      };
      if (typeof c.company === "string" && c.company !== "") {
        counteragent.company = {
          name: c.company,
        };
      }
      return counteragent;
    });
    }
    if (values.clientDate !== "") {
      values.clientDate = new Date(
        values.clientDate.getTime() -
        (values.clientDate.getTimezoneOffset() * 60000),
      );
    }
    values.readinessDate = new Date(
      values.readinessDate.getTime() -
      (values.readinessDate.getTimezoneOffset() * 60000),
    );
    values.cancellingDate = new Date(
      values.cancellingDate.getTime() -
      (values.cancellingDate.getTimezoneOffset() * 60000),
    );
    delete values.vessel;
    if (request.preamble.vessel) {
      values.vessel = {
        _id: request.preamble.vessel._id,
        name: request.preamble.vessel.name || request.preamble.vessel,
      };
    }
    if (values.constantQuantity) {
      values.constantQuantity = String(values.constantQuantity);
    }
    delete values.portOfRegistry;
    if (request.portOfRegistry) {
      values.portOfRegistry = request.portOfRegistry.name;
    }
    values.bunkers = request.bunkers.filter(b => b !== null && (b.quantity !== "" && b.typeOfFuel));
    delete values.redeliveryPlaces;
    if (request.redeliveryPlaces.filter(p => p.port).length) {
      values.redeliveryPlaces = request.redeliveryPlaces?.map(p => ({ region: p?.port?._id, term: "", name: p?.port?.name, details: p?.details }));
    }
    values.deliveryPlaces = request.deliveryPlaces?.map(p => ({ region: p.port._id, term: "", name: p?.port?.name, details: p?.details }));
    delete values.registerClasses;
    if (this.state.vesselRequirements.registerClasses.length) {
      values.registerClasses = this.state.vesselRequirements.registerClasses.filter(r => r.registerClass !== null).length ? this.state.vesselRequirements.registerClasses.filter(r => r.registerClass !== null) : undefined;
    }
    delete values.cve;
    if (request.cve.value) {
      values.cve = request.cve;
    }
    delete values.hirePerDay;
    if (request.hirePerDay.value) {
      values.hirePerDay = request.hirePerDay;
    }
    delete values.ilhoc;
    if (request.ilhoc.value) {
      values.ilhoc = request.ilhoc;
    }

    delete values.speedConsumptionV2;
    const preparedSpeedConsuptions = request.speedConsumptionV2.reduce((val, sc) => {
      if (!sc.mode) {
        return val;
      }
      const mode = toCamelCase(sc.mode);
      const modeNormalize = mode[0].toLowerCase() + mode.substring(1);
      val[modeNormalize] = [
        {
          speed: sc.speed !== "" && sc.lngCons !== "" ? sc.speed : undefined,
          quantity: sc.lngCons !== "" ? sc.lngCons : undefined,
          typeOfFuel: sc.lngCons !== "" ? 'LNG' : undefined,
        },
        {
          speed: sc.speed !== "" && sc.ifo.cons !== "" ? sc.speed : undefined,
          quantity: sc.ifo.cons !== "" ? sc.ifo.cons : undefined,
          typeOfFuel: sc.ifo.typeIfo !== "" ? sc.ifo.typeIfo : undefined,
        },
        {
          speed: sc.speed !== "" && sc.mdo.cons !== "" ? sc.speed : undefined,
          quantity: sc.mdo.cons !== "" ? sc.mdo.cons : undefined,
          typeOfFuel: sc.mdo.typeMdo !== "" ? sc.mdo.typeMdo : undefined,
        },
      ];
      return val;
    }, {});
    values.speedConsumptions = removeEmpty(preparedSpeedConsuptions);
    delete values.cargo.excluded;
    if (this.state.charterDetails.cargo?.excluded?.length) {
      values.cargo.excluded = this.state.charterDetails.cargo.excluded.map(c => ({
        cargoName: (c.cargoName.name ? c.cargoName.name : (c.cargoName !== "" ? c.cargoName : undefined)),
        bulk: c.bulk,
        dangerous: c.dangerous,
      }));
    }
    delete values.cargo.included;
    if (this.state.charterDetails.cargo?.included?.length) {
      values.cargo.included = this.state.charterDetails.cargo.included.map(c => ({
        cargoName: (c.cargoName.name ? c.cargoName.name : (c.cargoName !== "" ? c.cargoName : undefined)),
        bulk: c.bulk,
        dangerous: c.dangerous,
      }));
    }
    if (request.terms) {
      if (request.terms.default) {
        values.terms.default = request.terms?.default?.filter(t => t !== null);
      }
      if (request.terms.user) {
        values.terms.user = request.terms?.user?.filter(t => t);
      }
    }
    Object.entries(values).map(([key, value]) => value === "" ? delete values[key] : values[key]);
    if (this.props.isEditing || this.state.contractType?.noPdf) { // super hot asap crunch to skip preview step for unsupported contract types
     return this.props.handleSubmit(null, values);
    }
    this.props.handlePreview(values, null);
  }

  openStepWithInvalidInput = () => {
    if (!this._forms) return;

    const invalidFormIndex = this._forms.findIndex(form => !form.state.isValid);

    if (invalidFormIndex >= 0) {
        this.manuallyOpenStep(invalidFormIndex);
        setTimeout(() => {
          // also display errors in inputs
          this._forms[invalidFormIndex].submit();
        }, 1);
    }
    else {
      // if all forms are valid, head back to vessel Requirements step
      this.openSpecificFormStep("[data-id='vesselRequirements']");
    }
  }

  openSpecificFormStep = (selector = "") => {
    if (!this._forms) return;

    this._forms.forEach((form, index) => {
      const FormNode = ReactDOM.findDOMNode(form);

      if (!FormNode) return;

      const isFormInWantedStep = FormNode.querySelector(selector);

      if (isFormInWantedStep) {
        this.manuallyOpenStep(index);
      }
    })
  }

  manuallyOpenStep = (stepIndex) => {
    const { _mainStepper } = this;

    if (!_mainStepper) return;

    _mainStepper.handleOpenStep(stepIndex);
  }
  getAllFormsRefs = forms => {
    this._forms = forms;
  }
  genericUnblockStep = (name, val = false) => {
    return new Promise((res, rej) => this.setState(state => ({
      ...state,
      blockedSteps: {
        ...state.blockedSteps,
        [name]: val,
      }
    }), () => setTimeout(res, formValidateDelay + 1)));
  }

  saveStepperRef = el => {
    this._mainStepper = el;
  }

   handleNext = () => {
     this._mainStepper.handleNext();
   }
   checkShipImo = (value) => {
    if (!value || value.trim().length !== 7) {
        return;
      }
      Vessel
        .getVesselByIMO({ query: value.trim() })
        .then((response) => {
          if (response.data.length && response.data[0].imoNumber === value.trim()) {
            this.setState({
              preamble: { ...this.state.preamble, vessel: response.data[0] },
            });
          }
        });
}
  render() {
    const { terms, termsKey } = this.state;
    const { errors } = this.props;
    const now = new Date();
    now.setDate(now.getDate() + 1);

    return (<StyledStepper innerRef={el => this._mainForm = el}>
      <MainStepper
        submitLabel="Next"
        handleSubmit={this.handleSubmit}
        errors={errors}
        defaultActive={0}
        noButtons
        getAllFormsRefs={this.getAllFormsRefs}
        ref={this.saveStepperRef}
        transitionEl={SlightlyOptimizedAnimatedStep}
        formValidateDelay={formValidateDelay}
      >

        <div unblockStep={() => this.genericUnblockStep('isPreambleStepBlocked')} label="PREAMBLE">
          <Preamble isTcOffline preamble={this.state.preamble} />
          <HiddenRequiredStepInput value={undefined} name="isTermsComplete" required={this.state.blockedSteps.isPreambleStepBlocked} />
        </div>

        <div label={'VESSEL DESCRIPTION'} >
          <VesselRequirements checkShipImo={this.checkShipImo} vesselRequirements={this.state.vesselRequirements} handleNext={this.handleGenericChange} />
        </div>

        <div label="CHARTER DETAILS">
          <CharterDetails ports={this.state.ports} charterDetails={this.state.charterDetails} handleNext={this.handleGenericChange} />
        </div>

        <div
          className="terms_tab"
          onInvalidSubmit={this.handleTermsInvalidSubmit}
          label="TERMS"
        >
          <HiddenRequiredStepInput value={undefined} name="isTermsComplete" required={this.state.blockedSteps.isTermsStepBlocked} />
          <div style={{ display: "flex" }}>
            <div>
              <NarrowContractSelect
                floatingLabelText="Select contract*"
                name={`contract`}
                maxHeight={271}
                value={this.state.contract?._id || this.state.contract || ''}
                onChange={this.handleContractChange}
                type={`timeCharter`}
                required
                requiredError="required"
              />
            </div>
            <div style={{ marginLeft: "12px", width: "92px" }}>
              <NarrowFormsyText
                floatingLabelText={"Comm, %"}
                name="comm"
                validationError="0 - 25"
                validations="isNumeric,min:0,max:25"
                fullWidth
                type={'number'}
                step={0.25}
                min={0}
                max={25}
                onChange={this.handleCommChange}
              />
            </div>

          </div>
          <Terms
            showStandard
            termsType="tcTerms"
            key={termsKey || "1"}
            standardTermsList={this.context.timeTerms}
            selectedTerms={terms}
            ref="terms"
            topOffset={49}
            attachRef={this.attachTermsRef}
            softDelete={false}
            categoriesSelectorEnabled={false}
          />
          <div>
            <FormsyInput
              ref={element => this.genericRef('requestType', element)}
              type="hidden"
              name="requestType"
            />
            {
              this.props.isEditing || this.state.contractType?.noPdf
                ? (
                  <SpinnerBtn
                    label="Save"
                    disableTouchRipple
                    disableFocusRipple
                    primary
                    type="submit"
                    onClick={this.handleSend}
                    style={{ margin: '0px 12px 30px 0' }}
                    disabled={this.props.loading}
                    loading={this.props.loading}
                  />
                )
                : (
                  <RaisedButton
                    label="Preview"
                    disableTouchRipple
                    disableFocusRipple
                    primary
                    type="submit"
                    onClick={this.handleSend}
                    style={{ margin: '0px 12px 30px 0' }}
                  />
                )
            }

          </div>
        </div>
      </MainStepper>
    </StyledStepper>);
  }

  attachTermsRef = (el) => {
    this._termsRef = el;
  }
}

export default withStyles(s)(Request);
