/* @flow */
import React from 'react';
import type { Freight, TradeAble } from '../../../../interfaces/types';
import styled from 'styled-components';
import Diff from '../../../Common/Diff';
import { number } from '../../../../core/format';
import cx from 'classnames';

type Props = {
  freights: TradeAble<Freight>[];
  className: string;
}


function MultiFreightView(props: Props) {
  return (
    <div className={props.className}>
      {props.freights?.map(f => <FreightRow key={f.key} freight={f} />)}
    </div>
  );
}

function FreightRow(props: { freight: TradeAble<Freight> }) {
  let { curr, prev } = props.freight;
  if (!curr && !prev) {
    return null;
  }
  let isNew = false;
  let isDeleted = false;
  if (!prev || prev.value == null) {
    isNew = true;
    prev = curr;
  }
  if (!curr || curr.value == null) {
    isDeleted = true;
    curr = prev;
  }
  return (<div className={cx(isNew && 'new-freight', isDeleted && 'deleted-freight')}>
    <Diff newValue={number(curr.value, curr.currency)} oldValue={number(prev.value, prev.currency)} />
    <Diff newValue={curr.term} oldValue={prev.term} />
    {(prev.method || curr.method ? <span>,&nbsp;<Diff newValue={String(curr.method).toUpperCase()} oldValue={String(prev.method).toUpperCase()} /> </span> : null)}
    {(prev.details || curr.details) ? <span>,&nbsp;<Diff newValue={curr.details} oldValue={prev.details} /> </span> : null}
  </div>);
}

export default styled(MultiFreightView)`
  width: 100%;
  .new-freight {
    color: #D0021B;
  }
  .deleted-freight {
    text-decoration: line-through;
    text-decoration-color: #D0021B;
  }
`;
