import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import s from '../Monitor.scss';
import NarrowCompanyAutocomplete from "../../NewInputs/NarrowCompanyAutocomplete";
import cx from 'classnames';
import NarrowSelect from "../../NewInputs/NarrowSelect";
import MenuItem from 'material-ui/MenuItem';
import NarrowVesselAutocomplete from "../../NewInputs/NarrowVesselAutocomplete";
import RaisedButton from '../../Common/RaisedButton';
import Terms from '../../Documents/Terms';
import SpinnerButton from '../../Common/SpinnerButton';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import FormsyInput from '../../Common/FormsyInput';
import LoadingDischargingRate from '../../Documents/LoadingDischargingRate';
import { CargoListView } from '../../Cargo/Preview/Preview';
import ContractSelect from '../../Common/ContractSelect';
import Commission from '../../Common/Commission';
import formDataToObject from 'form-data-to-object';
import { Subject } from '../TcOffer/TcOffer';
import {isOfferToMarket, mapInitialEmails, prepareOffer} from './utils';
import {dateRange, getDemmurageTimeOpts, getDemmurageTermsOpts} from '../../../core/format';
import VesselLink from '../../Vessel/Preview/VesselLink';
import ExtendedForm from "../../Common/ExtendedForm";
import {
  toUTC,
  uniqBy,
  scrollToAnchor,
  isUserSubscribedToMarketplace,
  removeFromArray,
  flattenPorts,
} from '../../../core/utils';
import { FormStateProvider } from '../../Documents/Request/FormStateProvider';
import VesselRequirementsPreview, { hasVesselRequirements } from "../../Cargo/Preview/VesselRequirementsPreview"

import InfoOutlineIcon from 'material-ui/svg-icons/action/info-outline';
import PortLink from '../../Common/PortLink';
import {orderBy} from 'lodash';
import { BlurEmailIfNoSubscription } from '../../Signup/BlurIfNoSubscription';
import CurrencySelect from '../../Common/CurrencySelect';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import MailHeadersEdit from '../../Common/Share/MailHeadersEdit';
import Cargo from '../../../core/api/Cargo';
import FormsyRadioGroup from 'formsy-material-ui/lib/FormsyRadioGroup';
import FormsyRadio from 'formsy-material-ui/lib/FormsyRadio';
import FormsyDateTime from '../../Common/FormsyDateTime';
import {PortsSubForm} from "../../Cargo/Request/Ports";
import { convertPortsToServer } from "../../Cargo/utils";
import MultiFreightEditNarrow from "./MultiFreight/MultiFreightEditNarrow";
import ChargesEditNarrow from "./Charges/ChargesEditNarrow";
import FormsyAttachments from "../../Common/FormsyAttachments/FormsyAttachments";
import FormsyText from "../../Common/FormsyText";
import {mapTypeOfTransportationsToTerms} from "../../Documents/utils";
import ContactDetailsPopover from "../../ContactBook/ContactDetailsPopover";

// remove when all clients will support multifreight
export function SingleFreight(props: { previousValues: {} }) {
  return <div className={cx(s.right_table_offer_freight, s.wrapper_freight)}>
    <div>
      <NarrowFormsyText
        hintText="Freight"
        validations="isNumeric,gt0"
        validationErrors={{
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        }}
        requiredError="required"
        name="freight[value]"
        required
        fullWidth
        previousValue={
          props.previousValues[
            'freight[value]'
            ]
        }
      />
    </div>
    <CurrencySelect
      name="freight[currency]"
      className={s.currency_select}
      previousValue={
        props.previousValues[
          'freight[currency]'
          ]
      }
    />
    <div>
      <NarrowSelect
        hintText=" "
        requiredError="required"
        name="freight[term]"
        fullWidth
        autoWidth
        required
        defaultValue={'per mt'}
        previousValue={
          props.previousValues['freight[term]']
        }
      >
        <MenuItem
          key="per mt"
          value="per mt"
          primaryText="per mt"
        />
        <MenuItem
          key="per cbm"
          value="per cbm"
          primaryText="per cbm"
        />
        <MenuItem
          key="lump sum"
          value="lump sum"
          primaryText="lump sum"
        />
        <MenuItem
          key="per w/m"
          value="per w/m"
          primaryText="per w/m"
        />
        <MenuItem
          key="per unit"
          value="per unit"
          primaryText="per unit"
        />
        <MenuItem
          key="per box"
          value="per box"
          primaryText="per box"
        />
        <MenuItem
          key="per TEU"
          value="per TEU"
          primaryText="per TEU"
        />
        <MenuItem
          key="per FEU"
          value="per FEU"
          primaryText="per FEU"
        />
      </NarrowSelect>
    </div>
    <div>
      <NarrowSelect
        hintText=" "
        requiredError="required"
        name="freight[method]"
        fullWidth
        autoWidth
        required
        defaultValue={'FIOS'}
        previousValue={
          props.previousValues[
            'freight[method]'
            ]
        }
      >
        <MenuItem
          key="fios"
          value="fios"
          primaryText="FIOS"
        />
        <MenuItem
          key="filo"
          value="filo"
          primaryText="FILO"
        />
        <MenuItem
          key="lifo"
          value="lifo"
          primaryText="LIFO"
        />
        <MenuItem
          key="lilo"
          value="lilo"
          primaryText="LILO"
        />
      </NarrowSelect>
    </div>
  </div>;
}

const demmurageTimeOpts = getDemmurageTimeOpts();
const demmurageTermsOpts = getDemmurageTermsOpts();

class NewOffer extends FormStateProvider {
  static propTypes = {
    cargo: PropTypes.object.isRequired,
    vessel: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    bid: PropTypes.object,
    userType: PropTypes.oneOf(['cargoOwner', 'shipOwner']),
    channel: PropTypes.object,
  };
  static defaultProps = {
    userType: 'shipOwner',
  };
  static contextTypes = {
    voyageTerms: PropTypes.array,
    contracts: PropTypes.array,
  };

  state = { selectedOffers: [], message: '', additionalMessage: '', previousValues: {}, cc: [], bcc: [], replyTo: [], attachmentsKey: 0, attachments: [] };

  labels = { charterer: "Charterer/Merchant" };

  constructor(props, context) {
    super(props, context);
    const { offer, previousValues } = prepareOffer(props.cargo, props.bid , props.vessel, props.user,true, {forOffer: true} );
    this.isMultiFreight = true;
    if (props.bid?.trading?._id){
      this.isCounter = true;
      if (!offer.freights?.length) {
        this.isMultiFreight = false; // remove when all clients will support multifreight
      }
    }

    this.tt = offer.cargo[0].typeOfTransportation;
    if (this.tt === 'wetbulk') {
      this.isWetBulk = true;
      if (!this.isCounter) {
        const defaultWetContract = context.contracts.find(c => c._id === '64a58599941dcde391ad5d38');
        if (defaultWetContract) {
          offer.contract = defaultWetContract;
          previousValues.contract = defaultWetContract;
        }
        offer.totalWeightDeviation = { value: 5, term: 'moloo' };
        offer.totalVolumeDeviation = { value: 5, term: 'moloo' };
        previousValues.totalWeightDeviation = { value: 5, term: 'moloo' };
        previousValues.totalVolumeDeviation = { value: 5, term: 'moloo' };
        offer.demurrageOpts = { currency: 'USD', timeUnit: 'perDayProRata', terms: "" };
        previousValues.demurrageOpts = { currency: 'USD', timeUnit: 'perDayProRata', terms: "" };
        offer.ldRates.loading = {
          "type": "LoadingRate",
          "quantity": "",
          "dimension": "mt",
          "speed": "per hour",
          "abbreviation": "SHINC",
          "term": "",
        };
        offer.ldRates.discharging = {
          "type": "DischargingRate",
          "quantity": "",
          "dimension": "mt",
          "speed": "per hour",
          "abbreviation": "SHINC",
          "term": "",
        };
      }
      this.labels.charterer = "Charterer";
    }
    this.state.cargo = offer;
    this.state.ld = offer.ldRates;
    this.state.previousValues = formDataToObject.fromObj(previousValues);
    this.state.attachments = offer.attachments ? offer.attachments : offer.frChannel?.status?.attachments || [];
    this.state.attachmentsKey = offer.attachments?.length - 1 ?? 0;
    const minDate = new Date();
    minDate.setHours(minDate.getHours() + 2);
    minDate.setMinutes(60);
    this.state.minDate = minDate;
    this.state.ports = {
      loadingPorts: flattenPorts(offer.loading),
      unloadingPorts: flattenPorts(offer.unloading),
      incoterms: offer.incoterms,
    };
    this.state.termsCategories = mapTypeOfTransportationsToTerms(this.state.cargo.cargo.map(c => c.typeOfTransportation)).filter(tt => tt);
    if (this.state.previousValues.contract?.name === 'TBA') {
      this.state.previousValues.contract = {};
    }
    }

  async getStartInfo() {
    try {
      const startInfo = await Cargo.getTradeStartInfo();
      let shareEmails = this.state.cargo.shareEmails;
      if (this.props.cargo.frChannel?.status?.shareEmails) {
        shareEmails = this.props.cargo.frChannel.status.shareEmails;
      }
      if (this.props.cargo.bidChannel?.status?.bid?.trading?.shareEmails) {
        shareEmails = this.props.cargo.bidChannel.status.bid.trading.shareEmails;
      }
      let cc = startInfo?.data?.cc || [];
      if (shareEmails && !isOfferToMarket(this.props.user, this.props.cargo, this.props.cargo.bidChannel || this.props.cargo.frChannel || this.props.channel, this.props.bid)) {
        cc = [...(shareEmails.cc || []), ...(shareEmails.replyTo || [])];
      }
      const senderGates = this.props.mailGatesState?.senderGates;
      let defaultGate = null;
      if (senderGates.length) {
        defaultGate = senderGates.find(g => g.userId === this.props.user._id);
        if (!defaultGate) {
          defaultGate = senderGates.find(g => g.shared && g.companyDefault);
        }
      }
      if (defaultGate) {
        const defCc = defaultGate.cc && defaultGate.cc.enabled ? defaultGate.cc.emails.map(e => e.address) : (cc || []);
        const defBcc = defaultGate.bcc && defaultGate.bcc.enabled ? defaultGate.bcc.emails.map(e => e.address) : [];
        const defReplyTo = defaultGate.replyTo && defaultGate.replyTo.enabled ? defaultGate.replyTo.emails.map(e => e.address) : (startInfo.data.replyTo || []);
        if (defaultGate.overrideMode && defaultGate.overrideMode === "add") {
          this.setState({ replyTo: [...defReplyTo, ...startInfo.data.replyTo], cc: [...defCc, ...cc], bcc: defBcc }, this.refs.shareEmailForm.resetForm(mapInitialEmails([...defReplyTo, ...startInfo.data.replyTo], [...defCc, ...cc], defBcc)));
        } else {
          this.setState({ replyTo: defReplyTo, cc: defCc, bcc: defBcc }, this.refs.shareEmailForm.resetForm(mapInitialEmails(defReplyTo, defCc, defBcc)));
        }
      } else {
        this.setState({ replyTo: startInfo.data.replyTo, cc }, this.refs.shareEmailForm.resetForm(mapInitialEmails(startInfo.data.replyTo, cc)));
      }

    } catch (e) {
      console.error(e.message);
    }
  }

  componentDidMount() {
    this.getStartInfo();
    let formData;
    let contract = this.state.cargo.contract;
    if (contract?.name === 'TBA') {
      contract = {};
    }

    formData = formDataToObject.fromObj({...this.state.cargo, contract: contract?._id });
    formData.attachments = this.state.attachments;
    this.refs.form.reset(formData);
    this.setState({ mounted: true });
  }


  handleSend = async () => {
    this.portsForm.submit();
    this.refs.form.submit();
    if (!this.refs.form.state.isValid || !this.portsForm.isValid() ) {
      return;
    }
    const ports = this.portsForm.getPorts();
    const values = this.refs.form.getModel();
    values.loading = convertPortsToServer(ports.loadingPorts);
    values.unloading = convertPortsToServer(ports.unloadingPorts);
    values.incoterms = ports.incoterms;
    if (values.incoterms && !values.incoterms.kind) {
      values.incoterms = undefined;
    }
    if (!values.terms) {
      values.terms = {};
    }
    if (!values.terms.default) {
      values.terms.default = [];
    }
    if (!values.terms.user) {
      values.terms.user = [];
    }
    values.terms.default = values.terms.default
      .filter(t => t && t.checked !== undefined)
      .sort((a, b) => a.index - b.index);
    values.terms.user = values.terms.user.filter(t => !!t);
    const fullOffer = {};
    values.loading.forEach(lg => lg.oneOf.forEach((l) => {
      l.port = l.port._id;
      l.readinessDate = toUTC(l.readinessDate);

      l.cancellingDate = toUTC(l.cancellingDate);
    }));
    values.unloading.forEach(lg => lg.oneOf.forEach((l) => {
      l.port = l.port._id;
    }));
    values.cargoDetails = values.cargo;
    delete values.cargo;
    fullOffer.changedRequest = values;
    fullOffer.message = values.message;
    fullOffer.additionalMessage = values.additionalMessage;
    if(values.channels && values.channels.length) {
      const selectedChannel = values.channels.find(c => c.selected);
      if(selectedChannel) {
        fullOffer.vesselRequest = selectedChannel._id;
      }
    }
    if(!fullOffer.vesselRequest) {
      fullOffer.vesselRequest = this.props.vessel?.vessel?.openChannel?._id;
    }
    fullOffer.vessel = values.vessel;
    delete values.vessel;
    fullOffer.changedRequest.vesselDetails = values.vesselDetails;
    fullOffer.cargoRequest = this.state.cargo._id;
    if (values.replyDurationType === "timeForReply") {
      fullOffer.timeForReply = values.timeForReply;
    }
    if (values.replyDurationType === "dateForReply") {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      fullOffer.timeForReply = undefined;
      if (values.dateForReply) {
        fullOffer.dateForReply = values.dateForReply;
      } else {
        fullOffer.dateForReply = now;
      }
    }
    fullOffer.shareEmails = {
      cc: this.state.cc?.map(se => se.email),
      bcc: this.state.bcc?.map(se => se.email),
      replyTo: this.state.replyTo?.map(se => se.email),
    };
    if (isOfferToMarket(this.props.user, this.props.cargo, this.props.cargo.bidChannel || this.props.cargo.frChannel || this.props.channel, this.props.bid)) {
      fullOffer.shareEmails.cc = [];
    }
    delete values._freights;
    if (this.isMultiFreight) {
      fullOffer.freights = this.multiFreight.getValue();
      fullOffer.charges = this.charges.getValue();
      fullOffer.changedRequest.freight = fullOffer.freights[0];
      fullOffer.tradingType = 'shipment';
    }
    if (!fullOffer.changedRequest.ldRates.loading.type) {
      fullOffer.changedRequest.ldRates.loading.type = "";
    }
    if (!fullOffer.changedRequest.ldRates.discharging.type) {
      fullOffer.changedRequest.ldRates.discharging.type = "";
    }
    if (fullOffer.changedRequest.ldRates.loading.quantity === "") {
      fullOffer.changedRequest.ldRates.loading.quantity = undefined;
    }
    if (fullOffer.changedRequest.ldRates.discharging.quantity === "") {
      fullOffer.changedRequest.ldRates.discharging.quantity = undefined;
    }
    fullOffer.changedRequest.totalVolumeDeviation.term = fullOffer.changedRequest.totalWeightDeviation.term;
    return this.props.handleSend(fullOffer);
  };

  updateEmails = ({ cc, bcc, replyTo }) => {
    this.state.cc = cc;
    this.state.bcc = bcc;
    this.state.replyTo = replyTo;
  };

  handleInvalidSubmit = () => { setTimeout(() => {
    const selector = '.' + s.wrapper_dialog_offer_freight_static + ' [data-valid=false]';
    let invalidNodes = document.querySelectorAll(selector);
    if (!invalidNodes || !invalidNodes.length) {
      return;
    }
    const tabAttr = invalidNodes[0].attributes.getNamedItem('data-tab');
    // custom validator for formik based form
    if (this.refs.shareEmailForm?.state?.errors?.email) {
      const baseDialogSelector = '.' + s.wrapper_dialog_offer_freight_static;
      invalidNodes = [document.querySelector(baseDialogSelector), ...invalidNodes];
    }
    if (tabAttr && tabAttr.value === 'standard-terms') {
      try {
        const list = this.refs.terms?._reactInternalInstance?._renderedComponent
        ?._renderedComponent?._instance?.refs?.standardList || this.refs.terms?._reactInternalInstance?._renderedComponent
        ?._renderedComponent?._renderedComponent?._instance?.refs?.standardList;
        if (!list?.state?.open) {
          // eslint-disable-next-line no-undef
          list.handleNestedListToggle(new MouseEvent('click'));
        }
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        scrollToAnchor(invalidNodes[0]);
      }, 0);
    } else {
      scrollToAnchor(invalidNodes[0]);
    }
  })};

  handleRemove = (e, _id) => {
    const index = this.state.attachments.findIndex(at => at._id === _id);
    this.setState({ attachments: removeFromArray(this.state.attachments, index) });
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
  }

  preventEnterSubmit(e) {
    const keyCode = e.which;
    if (keyCode === 13) { //enter
      e.nativeEvent.cancelBubble = true;
      e.stopPropagation();
      if (e.nativeEvent.target.nodeName !== 'TEXTAREA') {
        e.preventDefault();
      }
      e.nativeEvent.stopImmediatePropagation();
      return false;
    }
  }

  refMultiFreight = (c) => {
    this.multiFreight = c;
  }
  refCharges = (c) => {
    this.charges = c;
  }
  refPortsForm = (portsForm) => {
    this.portsForm = portsForm;
  }

  handleDurationType = (e) => {
    if (e.target.name === "dateForReply") {
      this.refs.replyDurationType.setValue("dateForReply");
    } else {
      this.refs.replyDurationType.setValue("timeForReply");
      this.refs.dateForReply.setValue(undefined);
    }
  }

  render() {
    const { cargo, ld } = this.state;
    const firstLoading = cargo.loading[0].oneOf[0];
    const vessel = this.props.vessel?.vessel || this.props.vessel;
    const channels = this.props.vessel?.channels || this.props.channels || [];
    const { user } = this.props;
    const isPublic = isOfferToMarket(user, this.props.cargo, this.props.cargo.bidChannel || this.props.cargo.frChannel || this.props.channel, this.props.bid);
    const SHARE_AVAILABLE_FIELDS = { cc: !isPublic, bcc: true, replyTo: isUserSubscribedToMarketplace(user), subject: false, to: false };
    return (<div className={s.wrapper_dialog_offer_freight_static}>
      <div>
        <div>
          <div className={s.start_info}>
            <h2>{firstLoading.port.name}, {dateRange(firstLoading.readinessDate, firstLoading.cancellingDate)}</h2>
            <p>Ref: {cargo.refNo} {cargo.clientRefNo ? ` / CARGO ID: ${cargo.clientRefNo}` : null} </p>
          </div>
          <ExtendedForm
            noValidate
            ref="form"
            onInvalidSubmit={this.handleInvalidSubmit}
          >
            <div className={s.wrapper_top_data_offer_freight} onKeyDown={this.preventEnterSubmit}>
              <div className={s.to_cc_bcc_wrapper}>
                <Formik initialValues={mapInitialEmails(this.state.replyTo, this.state.cc)} onSubmit={() => {}} ref="shareEmailForm">
                  {(formik) => <Form>
                    <MailHeadersEdit availableFields={SHARE_AVAILABLE_FIELDS}/>
                    {this.updateEmails(formik.values)}
                  </Form>}
                </Formik>
              </div>
              <NarrowFormsyText
                hintText="Your message (optional)"
                fullWidth
                classes={s.message}
                name="message"
                multiLine
                maxLength={10000}
              />

              {!this.isCounter ?
                <Channels
                  vessel={vessel}
                  channels={channels}
                  cargo={cargo}
                />
            : null}
              <div className={s.wrapper_table_offer_freight} >
                <div className={s.left_table_offer_freight}>{this.labels.charterer}</div>
                <div className={s.right_table_offer_freight}>
                  {this.props.bid ?
                    <NarrowCompanyAutocomplete
                      hintText={this.labels.charterer + "*"}
                      name="charterer"
                      required
                      fullWidth
                      validationErrors={{
                        isDefaultRequiredValue: 'required',
                      }}
                      previousValue={
                        this.state.previousValues.charterer
                      }
                    />
                    : <span className={s.company_name}>{cargo.charterer.name}</span>
                  }
                </div>
              </div>

              {this.props.bid ?
                <div className={cx(s.wrapper_table_offer_freight, s.wrapper_select_lifted_by)}>
                  <div className={s.left_table_offer_freight}>Subjs to be lifted by</div>
                  <div className={cx(s.right_table_offer_freight)}>
                    <div className={s.wrapper_subject}>
                      <Subject
                        name="chartererSubject"
                        currentValue={
                          cargo &&
                          cargo.chartererSubject
                        }
                        previousValue={
                          this.state.previousValues[
                            'chartererSubject[value]'
                          ]
                        }
                      />
                    </div>
                  </div>
                </div>

                : null}

              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}>Owner/Carrier</div>
                <div className={s.right_table_offer_freight}>
                  <NarrowCompanyAutocomplete
                    hintText="Owner/Carrier*"
                    name="owner"
                    required
                    fullWidth
                    validationErrors={{
                      isDefaultRequiredValue: 'required',
                    }}
                    previousValue={
                      this.state.previousValues.owner
                    }
                  />
                </div>
              </div>
              {!this.isWetBulk ? (
                <div className={cx(s.wrapper_table_offer_freight, s.wrapper_select_lifted_by)}>
                  <div className={s.left_table_offer_freight}>Subjs to be lifted by</div>
                  <div className={cx(s.right_table_offer_freight)}>
                    <div className={s.wrapper_subject}>
                      <Subject
                        name="ownerSubject"
                        currentValue={
                          this.state.cargo &&
                          this.state.cargo.ownerSubject
                        }
                        previousValue={
                          this.state.previousValues[
                            'ownerSubject[value]'
                            ]
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <hr className={s.line_dotted} />

            <div className={s.wrapper_ship}>

                <div className={s.wrapper_table_offer_freight}>
                  <div className={s.left_table_offer_freight}>Vessel*</div>
                  <div className={s.right_table_offer_freight}>
                    <div className={s.vessel}>
                      {(!vessel || !vessel._id) ?
                        <div>
                          <NarrowVesselAutocomplete
                            hintText="Vessel"
                            name="vessel"
                            requiredError="required"
                            validationErrors={{
                              isDefaultRequiredValue: 'required',
                            }}
                            forceSelection
                            fullWidth
                            required
                          />
                        </div>
                        :
                        <div>
                        <VesselLink vessel={vessel} />
                        </div>
                      }
                      <div className={s.details}>
                        <NarrowFormsyText
                          hintText="Details"
                          name="vesselDetails"
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                </div>
              <PortsSubForm ref={this.refPortsForm} ports={this.state.ports} withSubmitButton={false} insideOffer incotermsEnabled={!this.isWetBulk} />
            </div>
            <hr className={s.line_dotted} />

            <div className={s.wrapper_cargo}>
              <div className={cx(s.wrapper_table_offer_freight, s.wrapper_table_offer_freight_cargo)}>
                <div className={s.left_table_offer_freight}>CARGO</div>
                <div className={cx(s.right_table_offer_freight, s.cargo_details_wrapper)}>
                  <CargoListView request={cargo} additionalDetailsEditable additionalDetailsProps={{ as: FormsyText, buttonsEnabled: false }} />
                </div>
              </div>
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}>TTL WEIGHT/VOLUME +-,%</div>
                <div className={cx(s.right_table_offer_freight, s.total_weight)}>
                  <div>
                  <div>
                    <NarrowFormsyText
                      hintText="Weight"
                      validations="isNumeric,isPercent"
                      validationErrors={{
                        isNumeric: 'only numbers',
                        isPercent: 'invalid percent',
                      }}
                      requiredError="required"
                      name="totalWeightDeviation[value]"
                      required
                      fullWidth
                      previousValue={
                        this.state.previousValues [
                          'totalWeightDeviation[value]'
                        ]
                      }
                    />
                  </div>
                  <div>
                    <NarrowFormsyText
                      hintText="Total volume +-,%"
                      validations="isNumeric,isPercent"
                      validationErrors={{
                        isNumeric: 'only numbers',
                        isPercent: 'invalid percent',
                      }}
                      requiredError="required"
                      name="totalVolumeDeviation[value]"
                      required
                      fullWidth
                      previousValue={
                        this.state.previousValues [
                          'totalVolumeDeviation[value]'
                          ]
                      }
                    />
                  </div>
                  <div>
                    <NarrowSelect
                      hintText=" "
                      requiredError="required"
                      name="totalWeightDeviation[term]"
                      fullWidth
                      autoWidth
                      defaultValue={''}
                      previousValue={
                        this.state.previousValues[
                          'totalWeightDeviation[term]'
                        ]
                      }
                    >
                      <MenuItem
                        key=""
                        value=""
                        primaryText=" "
                      />
                      <MenuItem
                        key="molchopt"
                        value="molchopt"
                        primaryText="molchopt"
                      />
                      <MenuItem
                        key="moloo"
                        value="moloo"
                        primaryText="moloo"
                      />

                    </NarrowSelect>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <hr className={s.line_dotted} />

            <div className={s.wrapper_loading_discharging}>
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}>FREIGHT*</div>
                {this.isMultiFreight ? // remove when all clients will support multifreight
                  <div className={cx(s.right_table_offer_freight)}>
                    {this.state.mounted && <MultiFreightEditNarrow value={this.state.cargo.freights} innerRef={this.refMultiFreight} typeOfTransportation={this.tt} />}
                  </div>
                  :
                  (<SingleFreight previousValues={this.state.previousValues} />)
                }

              </div>
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}></div>
                <div className={cx(s.right_table_offer_freight)}>
                  {this.state.mounted && this.isMultiFreight && <ChargesEditNarrow value={this.state.cargo.charges} innerRef={this.refCharges} />}
                </div>
              </div>
              <div className={cx(s.wrapper_table_offer_freight, s.wrapper_cqd)}>
                <div className={s.left_table_offer_freight}>L/D Rates</div>
                <div className={cx(s.right_table_offer_freight, s.wrapper_info_cqd)}>
                  <LoadingDischargingRate loading={ld.loading} discharging={ld.discharging} showNextButton={false} />
                </div>
              </div>
            </div>
            <hr className={s.line_dotted} />

            {hasVesselRequirements(cargo.vesselRequirements) && (
              <div className={s.wrapper_vessel_requirements}>
                <div className={s.title}>VESSEL REQUIREMENTS</div>
                <div className={s.flex_grow}>
                  <VesselRequirementsPreview
                    vesselRequirements={cargo.vesselRequirements}
                    buttonClass={s.button}
                    contentClass={s.content}
                  />
                </div>
              </div>
            )}

            <div className={s.bottom_info}>
              <div className={s.wrapper_table_offer_freight}>
                <div className={s.left_table_offer_freight}>DEMURRAGE</div>
                <div className={cx(s.right_table_offer_freight, s.wrapper_demurrage_detention)}>
                  <div className={cx("row", s.demurrage_detention_row)}>
                    <div>
                      <div>
                        <NarrowFormsyText
                          hintText="Demurrage"
                          validations="isNumeric"
                          validationErrors={{
                            isNumeric: 'only numbers',
                          }}
                          requiredError="required"
                          name="demurrage"
                          fullWidth
                          errorStyle={{ bottom: '7px' }}
                          previousValue={
                            this.state.previousValues.demurrage
                          }
                        />
                      </div>
                      <div>
                        <CurrencySelect
                          name="demurrageOpts[currency]"
                          className={s.currency_select}
                          previousValue={
                            this.state.previousValues[
                              'demurrageOpts[currency]'
                              ]
                          }
                          as={NarrowSelect}
                        />
                      </div>
                    </div>
                    <div>
                      <NarrowSelect
                        hintText=" "
                        requiredError="required"
                        name="demurrageOpts[timeUnit]"
                        fullWidth
                        autoWidth
                        required
                        defaultValue={'perDay'}
                        previousValue={
                          this.state.previousValues[
                            'demurrageOpts[timeUnit]'
                          ]
                        }
                      >
                        {demmurageTimeOpts.map(to => <MenuItem
                          key={to._id}
                          value={to._id}
                          primaryText={to.label}
                        /> )}
                      </NarrowSelect>
                    </div>
                    {!this.isWetBulk ? (
                      <div>
                        <NarrowSelect
                          hintText=" "
                          requiredError="required"
                          name="demurrageOpts[terms]"
                          fullWidth
                          autoWidth
                          defaultValue={''}
                          previousValue={
                            this.state.previousValues[
                              'demurrageOpts[terms]'
                              ]
                          }
                        >
                          {demmurageTermsOpts.map(to => <MenuItem
                            key={to._id}
                            value={to._id}
                            primaryText={to.label}
                          /> )}
                        </NarrowSelect>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {!this.isWetBulk ? (
                <div className={s.wrapper_table_offer_freight}>
                  <div className={s.left_table_offer_freight}>DETENTION</div>
                  <div className={cx(s.right_table_offer_freight, s.wrapper_demurrage_detention)}>
                    <div className={cx("row", s.demurrage_detention_row)}>
                      <div>
                        <div>
                          <NarrowFormsyText
                            hintText="Detention"
                            validations="isNumeric"
                            validationErrors={{
                              isNumeric: 'only numbers',
                            }}
                            requiredError="required"
                            name="detention"
                            fullWidth
                            previousValue={
                              this.state.previousValues.detention
                            }
                            errorStyle={{ bottom: '7px' }}
                          />
                        </div>
                        <div>
                          <CurrencySelect
                            name="detentionOpts[currency]"
                            className={s.currency_select}
                            previousValue={
                              this.state.previousValues[
                                'detentionOpts[currency]'
                                ]
                            }
                            as={NarrowSelect}
                          />
                        </div>
                      </div>
                      <div>
                        <NarrowSelect
                          hintText=" "
                          requiredError="required"
                          name="detentionOpts[timeUnit]"
                          fullWidth
                          autoWidth
                          required
                          defaultValue={'perDay'}
                          previousValue={
                            this.state.previousValues[
                              'detentionOpts[timeUnit]'
                              ]
                          }
                        >
                          {demmurageTimeOpts.map(to => <MenuItem
                            key={to._id}
                            value={to._id}
                            primaryText={to.label}
                          /> )}
                        </NarrowSelect>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={cx(s.wrapper_table_offer_freight, s.wrapper_contract)}>
                <div className={s.left_table_offer_freight}>CONTRACT</div>
                <div className={cx(s.right_table_offer_freight)}>
                  <ContractSelect
                    hintText="Contract"
                    requiredError="required"
                    name="contract"
                    required
                    autoWidth
                    fullWidth
                    previousValue={
                      this.state.previousValues?.contract?._id
                    }
                    isBidding
                  />
                </div>
              </div>
              <div className={cx(s.wrapper_table_offer_freight, s.wrapper_comm)}>
                <div className={s.left_table_offer_freight}>COMM</div>
                <div className={cx(s.right_table_offer_freight)}>
                  <Commission floatingLabelText={''} previousValues={this.state.previousValues} />
                </div>
              </div>
              <div className={cx(s.wrapper_table_offer_freight, s.wrapper_time_for_reply)}>
                <div className={s.left_table_offer_freight}>TIME FOR REPLY</div>
                <div className={cx(s.right_table_offer_freight)}>
                  <FormsyRadioGroup
                    name="replyDurationType"
                    required
                    requiredError="required"
                    className={s.radio_group}
                    value="timeForReply"
                    ref="replyDurationType"
                  >
                   <FormsyRadio className={s.radio_time} value="timeForReply" />
                   <FormsyRadio className={s.radio_date} value="dateForReply" />
                  </FormsyRadioGroup>
                      <NarrowSelect
                        className={s.time_for_reply}
                        requiredError="required"
                        name="timeForReply"
                        autoWidth
                        fullWidth
                        required
                        onClick={this.handleDurationType}
                        defaultValue={
                          this.state.previousValues.timeForReply ||
                          24
                        }
                        previousValue={
                          this.state.previousValues &&
                          this.state.previousValues.timeForReply
                        }
                      >
                        <MenuItem
                          key="24"
                          value={24}
                          primaryText="24 hr"
                        />
                        <MenuItem
                          key="12"
                          value={12}
                          primaryText="12 hr"
                        />
                        <MenuItem
                          key="6"
                          value={6}
                          primaryText="6 hr"
                        />
                        <MenuItem
                          key="3"
                          value={3}
                          primaryText="3 hr"
                        />
                        <MenuItem
                          key="2"
                          value={2}
                          primaryText="2 hr"
                        />
                        <MenuItem
                          key="1"
                          value={1}
                          primaryText="1 hr"
                        />
                        <MenuItem
                          key="0.5"
                          value={0.5}
                          primaryText="30 min"
                        />
                        <MenuItem
                          key="0.25"
                          value={0.25}
                          primaryText="15 min"
                        />
                      </NarrowSelect>
                  <FormsyDateTime
                    className={s.date_for_reply}
                    style={{ right: 0 }}
                    ref="dateForReply"
                    withIcon name="dateForReply"
                    placeholder="Select date & time"
                    minDate={this.state.minDate}
                    onClick={this.handleDurationType}
                  />
                </div>
              </div>
              <div className={cx(s.wrapper_table_offer_freight, s.wrapper_terms)}>
                <div className={s.left_table_offer_freight}>Terms</div>
                <div className={cx(s.right_table_offer_freight)}>
                  <Terms
                    showStandard
                    standardTermsList={this.context.voyageTerms}
                    termsType="voyageTerms"
                    // topStyles={63}
                    inDialog
                    isCounterOffer={true}
                    showBillOfLading
                    ref="terms"
                    selectedTerms={cargo.terms}
                    billOfLading={cargo.billOfLading}
                    previousValues={
                      this.state.previousValues &&
                      this.state.previousValues
                    }
                    selectedCategories={this.state.termsCategories}
                  />

                </div>
              </div>
              <hr style={{ borderTop: "1px dashed #D2D2D2" }}/>
              <div style={{ marginBottom: "8px" }}>
                <FormsyAttachments files={this.state.attachments} name="attachments" oldFilesLocked />
              </div>
              <NarrowFormsyText
                hintText="Your message (optional)"
                fullWidth
                classes={s.message}
                name="additionalMessage"
                multiLine
                maxLength={10000}
              />
              <div className={cx(s.wrapper_table_offer_freight)}>
                  <div className={s.sending_offer_notice} style={{ width: "100%" }}>
                    <InfoOutlineIcon /> <span>Your freight Offer will be copied by email.</span>
                  </div>
                </div>
            </div>

            <div className={s.wrapper_buttons_offer}>
              <RaisedButton
                label="Cancel"
                disableTouchRipple
                disableFocusRipple
                secondary
                style={{
                  width: '68px',
                  height: '28px',
                  minWidth: '68px',
                  marginRight: '8px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '15px',
                  lineHeight: '28px',
                }}
                buttonStyle={{
                  height: '28px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '15px',
                  lineHeight: '28px',
                  fontSize: '0',
                }}
                overlayStyle={{
                  borderRadius: '15px',
                }}
                labelStyle={{
                  top: '1px',
                  padding: '0',
                  fontSize: '11px',
                  lineHeight: '28px',
                  color: '#999999',
                }}
                onClick={this.props.handleClose}
              />

              <SpinnerButton
                label="Send"
                disableTouchRipple
                disableFocusRipple
                onClick={this.handleSend}
                primary
                style={{
                  width: '68px',
                  height: '28px',
                  minWidth: '68px',
                  lineHeight: '28px',
                  borderRadius: '15px',
                }}
                buttonStyle={{
                  height: '28px',
                  borderRadius: '15px',
                  lineHeight: '28px',
                  fontSize: '0',
                }}
                overlayStyle={{
                  borderRadius: '15px',
                }}
                labelStyle={{
                  top: '1px',
                  padding: '0',
                  fontSize: '11px',
                }}
              />
            </div>
          </ExtendedForm>
        </div>
      </div>
    </div>);
  }
}








export default connect(
  state => ({ mailGatesState: state.login.mailGatesState, user: state.login.user }),
  null
)(NewOffer);
export const NewOfferStyled =  withStyles(s)(NewOffer);
export const ReduxLessNewOffer = NewOffer;

class Channels extends PureComponent {
  state = {};

  constructor(props){
    super(props);
    const cargo = props.cargo || {};
    this.state.channels=[...props.channels];
    this.state.channels = orderBy(this.state.channels, [c => Number(!!c.addedByUser),c => Number(!!c.addedByCompany)],['desc','desc']);
    this.state.channels = uniqBy(this.state.channels, (c) => c.refNo);
    if(cargo.frChannel) {
      const respondChannel = {_id: cargo.frChannel.status.vesselRequest, open:{}, company:{}, status:{} };
      if(respondChannel) {
        this.state.channels = [respondChannel];
      }
    }
    let companyChannels = this.state.channels.filter(c => !!(c.addedByUser || c.addedByCompany));
    if(companyChannels.length){
      this.state.channels = companyChannels;
    }
    this.state.channels[0] && (this.state.channels[0].selected=true);
    if(this.state.channels.length < 2) {
      this.hidden = true;
    }
  }
  componentDidMount() {

  }
  handleSelect = (i) => {
    const channels = this.state.channels.map(c=>({...c, selected:false}));
    channels[i].selected = true;
    this.setState({channels});
  }

  render(){
    const channels=this.state.channels;
    return <div style={{display: this.hidden ? 'none' : 'block' }} >
      <div className={s.wrapper_table_offer_freight} >
        <div className={s.left_table_offer_freight}>SELECT CHANNEL</div>
      </div>
      <div>
        <div className={s.wrapper_channels_popup}>
          {channels.map((c,i) => (
            <div>
          <span
            className={cx(s.radio, c.selected && s.active)}
            onClick={this.handleSelect.bind(this, i)}
          />
              <div>Ref {c.refNo}</div>
              <div title={c.open?.name} ><PortLink port={c.open}  /></div>
              <div>{dateRange(c.open.from,c.open.until)}</div>
              <div>
                <BlurEmailIfNoSubscription>{c.realUser ?
                  <ContactDetailsPopover email={c.realUser.email}><a href={`mailto:${c.realUser.email}`}>{c.realUser.email}</a></ContactDetailsPopover>
                  : c.company.name }</BlurEmailIfNoSubscription>
              </div>
              <div>{c.isClientRequest ? 'Received' : c.role}</div>
              <FormsyInput type="hidden" name={`channels[${i}][_id]`} value={c._id} />
              <FormsyInput type="hidden" name={`channels[${i}][selected]`} value={c.selected} />
            </div>
          ))}
        </div>
      </div>
    </div>


  }
}
