import React, { Component } from 'react'
import p from './ArrowPopover.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/Menu';
import Popover from 'material-ui/Popover';
import ActionPrint from 'material-ui/svg-icons/action/print';
import BlurIfNoSubscription from '../Signup/BlurIfNoSubscription';
import Hoverable from './Hoverable';
export class PrintPopover extends Component {

  static defaultProps = {
    label: "T/C index",
    iconEl: ActionPrint,
    labelPrefix: 'Print',
    format: 'pdf',
  }

  state = {
    open: false,
    anchorEl: null
  }

  handleRequestClose = () => {
    this.setState({
      open: false
    })
  }

  showPopover = (ev) => {
    if (this.props.canPrint && this.props.handlePrint) {
      this.setState({
        open: true,
        anchorEl: ev.currentTarget
      })
    }
  }

  handlePrint = (showTcRate) => {
    this.props.handlePrint(showTcRate, this.props.format);
    this.handleRequestClose();
  }

  render() {

    let { style = {}, canPrint, iconStyle = {} } = this.props;
    if (!canPrint) {
      style = {
        ...style,
        ...{ cursor: 'initial', pointerEvents: 'none' },
        position: "relative",
        zIndex: "9999",
      }
    }
    const IconEl = this.props.iconEl;
    return (
      <BlurIfNoSubscription classes dontBlur>
        <Hoverable onClick={this.showPopover}>
          <a
            style={style}
          >
            <IconEl color={canPrint ? '#285596' : ''} style={{ marginRight: '5px', marginLeft: '5px', ...(iconStyle) }} />
            <Popover
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              targetOrigin={{ horizontal: 'left', vertical: 'top' }}
              onRequestClose={this.handleRequestClose}
              className={p.default_popover_style}
            >
              <Menu>
                  <MenuItem onClick={this.handlePrint.bind(null, true)} primaryText={`${this.props.labelPrefix} with ${this.props.label}`} />
                  <MenuItem onClick={this.handlePrint.bind(null, false)} primaryText={`${this.props.labelPrefix} without ${this.props.label}`} />
              </Menu>
            </Popover>
          </a>
        </Hoverable>
      </BlurIfNoSubscription>

    )
  }
}

export default withStyles(p)(PrintPopover);
