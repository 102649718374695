import * as handlers from './emails/index';
/** @typedef {Object} EmailsState
 * @property {List} emailList
 */
export default function emails(
  state = {
    emailList: {
      data: [],
      cursor: undefined,
      filters: { type: { cargo: true, vessel: true, sales: true, tc: true, spam: true }, tagged: undefined },
      folder: "received",
      filtersPerFolder: {
        "received": { type: { cargo: true, vessel: true, sales: true, tc: true, spam: true }, tagged: undefined },
        "sent": { type: { cargo: true, vessel: true, sales: true, tc: true, spam: true }, tagged: undefined },
        "archive": { type: { cargo: true, vessel: true, sales: true, tc: true, spam: true }, tagged: undefined },
      },
      sort: {
        field: 'addedAt', value: -1,
      },
      loading: true,
    },
    details: {
      loading: false,
      store: {},
    },
    dark: false,
  },
  action,
) {
  if (!handlers[action.type]) {
    return state;
  }
  try {
    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
