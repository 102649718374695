import { removeFromArray } from '../../core/utils';

/** @name VESSEL_TC_NEW_REDUCER */
//noinspection JSUnusedGlobalSymbols
/**
 *
 * @param {VesselState} state
 * @param payload
 * @param currentUser
 * @returns {VesselState}
 */
export default function VESSEL_TC_NEW(state, { payload, currentUser }) {
  const newState = {};
  let key = 'sentTcOfferList';
  if (payload.chapter === 'request') {
    key = 'tcOfferList';
  }
  const index = state[key].data.findIndex(
    offer => offer._id === payload._id,
  );
  newState[key] = { ...state[key] };
  let newOffer;
  if (index !== -1) {
    newOffer = { ...state[key].data[index], ...payload };
    newState[key].data = removeFromArray(
      newState[key].data,
      index,
    );
  } else {
    newOffer = payload;
  }
  newOffer.key = newOffer._id;
  newOffer.endAt = new Date(newOffer.endAt);
  newState[key].data = [newOffer, ...newState[key].data];
  return { ...state, ...newState };
}
