export default function FLEET_UPDATE_SINGLE_VESSEL(state, action) {
  const fleet = [...state.vesselList.data];

  const filteredFleet = [
    ...state.vesselList.filtered
  ];

  const index = fleet.findIndex(vessel => vessel._id === action.payload._id);

  if (index < 0) {
    return {...state}
  }

  fleet[index] = {
    ...fleet[index],
    ...action.payload
  }

  const filteredIndex = filteredFleet.findIndex(vessel => vessel._id === action.payload._id);

  if (filteredFleet[filteredIndex]) {
    filteredFleet[filteredIndex] = {
      ...filteredFleet[filteredIndex],
      ...action.payload
    }
  }

  return { ...state, vesselList: { ...state.vesselList, filtered: filteredFleet, data: [...fleet] } };
}
