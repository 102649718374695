import { replaceInArray } from '../../core/utils';

/** @name CARGO_REQUEST_REFRESH_STATUS_REDUCER */
export default function CARGO_REQUEST_REFRESH_STATUS(state, { res }) {
  const index = state.requestList.data.findIndex(r => r._id === res.data._id);
  if (index === -1) {
    return state;
  }
  const request = { ...state.requestList.data[index], status: res.data.status };
  const newState = {
    requestList: {
      ...state.requestList,
      data: replaceInArray(state.requestList.data, index, request),
    },
  };
  return { ...state, ...newState };
}
