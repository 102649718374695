import React, { Component } from "react";
import Dialog from "../Common/Dialog";
import RaisedButton from "./../Common/RaisedButton";
import { connect } from "react-redux";
import Api from './../../core/api/api';
import Company from './../../core/api/Company';
import {getIntegratorIdFromURL} from './../../core/utils';
import history from './../../core/history';
const buttonStyle = {
  overflow: "hidden",
  marginLeft: "8px",
  borderRadius: "50px"
};
const textStyle = {
  fontFamily: "Roboto",
  fontSize: 14,
  marginTop: 0,
  textAlign: "center",
  color: "#333333"
};
export class ConfirmIntegrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      integratorId: null,
      open: false,
      integratorInfo: null,
    };

    this.handleClose = this.handleClose.bind(this);
    this.confirm = this.confirm.bind(this);
    this.reject = this.reject.bind(this);
    this.terminate = this.terminate.bind(this);
    this.clearSession = this.clearSession.bind(this);
    this.openIfIntegratorSpecified = this.openIfIntegratorSpecified.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.user !== this.props.user || nextState.open !== this.state.open || nextState.integratorInfo !== this.state.integratorInfo) {
      return true;
    }
    else {
      return false;
    }
  }
  componentDidUpdate(prevState) {
        this.openIfIntegratorSpecified();
  }
  async openIfIntegratorSpecified() {
    const integratorId = getIntegratorIdFromURL();
    const pendingIntegratorKey = this.props?.user?.pendingIntegratorKey;
    if (integratorId) {
      this.clearSession();
    }
    if (!integratorId && !pendingIntegratorKey) return;
    if (pendingIntegratorKey === 'no') return;
    if (this.props.user.integratorId) return;
    const info = this.state.integratorInfo || await Company.getIntegratorInfo(integratorId || pendingIntegratorKey);
    if (!info) return;
    if (integratorId && !pendingIntegratorKey) {
      await Company.rememberIntegrator(info.key);
    }
    const isIntegratorTerminated = sessionStorage.getItem('integratorTerminated');
    if (!this.props.user.approved || !this.props.user.emailVerified) return;
    if (!integratorId && pendingIntegratorKey && isIntegratorTerminated !== "false") return;
    this.setState({
      integratorInfo: info,
      open: true
    })
  }
  componentDidMount() {
    this.openIfIntegratorSpecified()
  }

  clearSession() {
    sessionStorage.setItem('integratorTerminated', false);
  }

  handleClose(ev, reload = true) {
    const {location: {pathname}} = window;
    history.replace(pathname);
    if (reload) {
      document.location.reload();
    }
    else {
      this.setState({
        open: false
      })
    }
  }
  async terminate() {
    sessionStorage.setItem('integratorTerminated', true);
    this.handleClose(null, false);
  }
  async reject() {
    const {integratorInfo} = this.state;
    try {
      const res = await Company.rejectIntegrator(integratorInfo.key);
    }  finally {
        this.handleClose();
        this.clearSession();
    }
  }
  async confirm() {
    const {integratorInfo} = this.state;
    try {
      const res = await Company.linkAccountToIntegrator(integratorInfo.key);
    }  finally {
        this.handleClose();
        this.clearSession();
    }
  }
  render() {
    const { open, integratorInfo } = this.state;
    const { handleClose, confirm, reject, terminate } = this;
    if (!open) {
      return null;
    }
    return (
      <Dialog
        contentStyle={{ maxWidth: "340px" }}
        open={open}
        handleClose={terminate}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "0px"
          }}
        >
          <p style={textStyle}>Connect to SHIPNEXT as a {integratorInfo ? integratorInfo.name : ''} user?</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <RaisedButton
              onClick={reject}
              style={buttonStyle}
              label={"NO"}
              secondary
            />
            <RaisedButton
              style={buttonStyle}
              buttonStyle={{ borderRadius: "50px" }}
              label={"CONFIRM"}
              onClick={confirm}
              primary
            />
          </div>
        </div>
      </Dialog>
    );
  }
}
export default connect(
  state => ({ user: state.login.user }),
  null
)(ConfirmIntegrator);
