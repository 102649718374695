//noinspection JSUnusedGlobalSymbols
/** @name USER_STATS_REDUCER */
export default function USER_STATS(state, { res, page, numberOfRows }) {
    const newState = { statistics: {
          ...state.statistics,
          page,
          numberOfRows,
          data: res.data,
          total: res.total ? res.total : res.data.length,
          loading: false
        } 
      };
    
    return { ...state, ...newState };
  }
