/** @name GET_INDEXES_LIST */
export default function GET_INDEXES_LIST(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    loading: true,
    errorMessage: '',
  };

  return { ...state, ...newState };
}
