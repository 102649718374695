/** @name POSITION_UPDATE_BY_ID_REDUCER */
import { fromUTC } from '../../core/utils';

export default function POSITION_UPDATE_BY_ID(state, { payload }) {
  const newState = {};
  const positionsList = { ...state.positionsList };

  newState.positionsList = positionsList;

  const newPositionsList = positionsList.data.map((position) => {
    if (position._id === payload.positionId) {
      position.open.from = fromUTC(payload.request.from);
      position.open.until = fromUTC(payload.request.until);
      position.open.where = payload.request.where;
      position.open.name = payload.request.name;
      position.open._id = payload.request.where;
      position.preferredCargo = payload.request.preferredCargo;
    }

    return position;
  });

  newState.positionsList.data = newPositionsList;

  return { ...state, ...newState };
}
