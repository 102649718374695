import React, { Component } from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import IconButton from 'material-ui/IconButton';

import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right';

import withStyles from 'isomorphic-style-loader/lib/withStyles';

import p from './Pagination.scss';

export class Pagination extends Component {
    static defaultProps = {
        total: 0,
        page: 1,
        rowsPerPage: [10, 20, 30],
        numberOfRows: 10,
        text: 'Rows per page',
        hidePageSelection: false
    };
    static propTypes = {
        total: PropTypes.number,
        page: PropTypes.number,
        numberOfRows: PropTypes.number,
        rowsPerPage: PropTypes.array,
        updateRows: PropTypes.func,
        hidePageSelection: PropTypes.bool,
        text: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.selectRowsPerPage = this.selectRowsPerPage.bind(this);
        this.selectPageNumber = this.selectPageNumber.bind(this);

        this.renderRowsPerPage = this.renderRowsPerPage.bind(this);
        this.renderRowRange = this.renderRowRange.bind(this);

        this.numberOfPages = this.numberOfPages.bind(this);

        this.incrementPage = this.incrementPage.bind(this);
        this.decrementPage = this.decrementPage.bind(this);
    }
    selectRowsPerPage(event){
        const updatedState =  Object.assign({}, this.props);
        updatedState.numberOfRows = parseInt(event.target.innerText);
        if( updatedState.numberOfRows * this.props.page > this.props.total ) {
            let updatedPage = Math.ceil(this.props.total / updatedState.numberOfRows);
            updatedState.page = updatedPage;
            this.props.updateRows(updatedState);
        } else {
            this.props.updateRows(updatedState);
        }
    }

    selectPageNumber(event){
        const updatedState =  Object.assign({}, this.props);
        updatedState.page = parseInt(event.target.innerText);
        this.props.updateRows(updatedState);
    }

    numberOfPages(){
        let numArray = [];
        for(let i = 0; i < Math.ceil(this.props.total/this.props.numberOfRows); i++){
            numArray.push(i+1);
        }        

        return numArray.map((pageValue, index) => {
            return (
                <MenuItem key={index} value={pageValue} primaryText={pageValue}/>
            );
        });
    }

    incrementPage(){
        if (this.props.page >= this.props.total / this.props.numberOfRows) {
            return;
        }

        let updatedState = Object.assign({}, this.props);
        updatedState.page++;
        this.props.updateRows(updatedState);
    }

    decrementPage(){
        if (this.props.page <= 1) {
            return;
        }

        let updatedState = Object.assign({}, this.props);
        updatedState.page--;
        this.props.updateRows(updatedState);
    }

    renderRowsPerPage(){
        return this.props.rowsPerPage.map((rowValue, index) => {
            return (
                <MenuItem key={index} value={rowValue} primaryText={rowValue}/>
            );
        });
    }

    renderRowRange() {
        const page = (this.props.page || 1);
        const to = Math.min(page * this.props.numberOfRows, this.props.total);
        const from = Math.min((page - 1) * this.props.numberOfRows + 1, to);
        return (
            <span>
                {from} - {to}
            </span>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={cx(p.paginationContainer, classes)}>

                {
                    !this.props.hidePageSelection && (<div className={p.paginationSection}>
                        <div className={p.paginationText}>
                            Page:
                        </div>
                        <SelectField
                            
                            className={p.paginationSelect}
                            value={this.props.page}
                            onChange={this.selectPageNumber}
                            style={{ maxHeight: '42px' }}
                        >
                            {this.numberOfPages()}
                        </SelectField>
                    </div>)
                }

                <div className={p.paginationSection}>
                    <div className={p.paginationText}>
                        {this.props.text}
                    </div>
                    <SelectField
                        
                        className={p.paginationSelect}
                        value={this.props.numberOfRows}
                        onChange={this.selectRowsPerPage}
                        style={{ maxHeight: '42px' }}
                    >
                        {this.renderRowsPerPage()}
                    </SelectField>
                </div>

                <div className={p.paginationSection}>
                    <div className={p.paginationText}>
                        {this.renderRowRange()}  of {this.props.total}
                    </div>
                </div>

                <div className={p.paginationSection}>
                    <IconButton
                        name={"navigationLeft"}
                        disabled={this.props.page <= 1}
                        onClick={this.decrementPage}>
                        <ChevronLeft
                        />
                    </IconButton>
                    <IconButton
                        name={"navigationRight"}
                        disabled={this.props.page >= this.props.total / this.props.numberOfRows}
                        onClick={this.incrementPage}>
                        <ChevronRight
                        />
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default withStyles(p)(Pagination);
