import React from 'react';
import { CargoInfo, Counteragents, PlaceDate, RefNo, Ship } from './../shared/preview';
import PolPodPreview from '../../Cargo/Preview/PolPodPreview';
import Row from "../shared/Row";
import Value from "../shared/Value";
import Label from "../shared/Label";
import { connect } from 'react-redux';
import { setPortDialogId } from '../../../actions/port';

function MainPart(props) {

  const { request = {}, errorsTable = {}, ...rest } = props;
  return (
    <div style={{ width: '100%', overflow: 'hidden' }} {...rest}>
      <PlaceDate date={request.clientDate} place={request.clientPlace} />
      <RefNo refNo={request.clientContractRefNo ?? request.refNo} />
      <Ship details={request.vesselDetails} ship={request.vessel} dialogPortId={props.dialogPortId} setPortDialogId={props.setPortDialogId} />
      <Counteragents counteragents={request.counteragents} />
     {/* <PortsInfo loadingPortsErrors={errorsTable['POL']} dischargingPortsErrors={errorsTable['POD']} loadingPorts={request.loading} dischargingPorts={request.unloading} />*/}
      { request.incoterms?.kind ? <Row>
          <Label>INCOTERMS</Label>
           <Value>
             { `${request.incoterms.kind}` }
             { request.incoterms.place ? `, Port or place: ${request.incoterms.place}` : null }
           </Value>
        </Row>
       : null }
      <PolPodPreview errors={errorsTable} loading={request.loading} unloading={request.unloading} shipId={request.vessel && request.vessel?._id}/>
      <CargoInfo errors={errorsTable["CARGO"]} cargo={request.cargo} files={request.files} />
    </div>
  )
}
const mapStateToProps = (state) => ({
    dialogPortId: state.port.dialogPortId,
  }
);
const mapDispatchToProps = (dispatch) => ({
    setPortDialogId: (ids) => dispatch(setPortDialogId(ids)),
  }
);
export default connect(mapStateToProps, mapDispatchToProps)(MainPart);
