import PubSub from 'pubsub-js';


export default function(eventName, timeout = 3000, delay = 500, filter = (data) => true) {
  return new Promise((resolve) => {
    const handler = function (ev, data){
      if (data && !filter(data)) {
        return;
      }
      PubSub.unsubscribe(handler);
      clearTimeout(timer);
      setTimeout(()=> resolve(data), delay);
    };
    PubSub.subscribe(eventName, handler);
    const timer = setTimeout(handler,timeout)
  })
}
