import React from 'react'
import ContentFilterList from 'material-ui/svg-icons/content/filter-list';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames'
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/content/clear';

import CustomTooltip from './CustomTooltip'

import styles from './FilterButton.module.scss'

function FilterButton ({tooltipProps = {}, tooltip = "", isDirty, tooltipClassName = "", dark = true,onClear,...rest }) {
  return (
    <div {...rest}>
      {
        tooltip && isDirty
          ? (
            <CustomTooltip dark={dark} tooltip={tooltip} className={tooltipClassName} {...tooltipProps}>
              <FilterChildren isDirty={isDirty}  onClear={onClear}/>
            </CustomTooltip>
          )
          : <FilterChildren isDirty={isDirty}  onClear={onClear}/>
      }
    </div>
  )
}

 function FilterChildren ({isDirty,onClear}){
    return <div className={cx(styles.filterWrapper,{ [styles.dirty]: isDirty })}>
        <ContentFilterList className={cx(styles.filterIcon)} width='16px' height='16px' />
        <span>Filter </span>
        {isDirty && <IconButton className={cx(styles.clearBtn)} onClick={onClear}><CloseIcon /></IconButton>}
    </div>
}


export default withStyles(styles)(FilterButton)