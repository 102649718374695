import React, {PureComponent} from 'react';
import {BaseContactInfoWrapper, Body, ContactEdit, SocialMediaEdit} from "./ContactEdit";
import {Pane} from "../Common/SplitPane";
import ExtendedForm from "../Common/ExtendedForm";
import FormsyAttachments from "../Common/FormsyAttachments/FormsyAttachments";
import RaisedButton from "../Common/RaisedButton";
import SpinnerButton from "../Common/SpinnerButton";
import {connect} from "react-redux";
import {createGroup, deleteContacts, updateGroup} from "../../actions/contacts";
import AvatarEdit from "./AvatarEdit";
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";
import ContactEmailsEdit from "./ContactEmailsEdit";
import ContactPhonesEdit from "./ContactPhonesEdit";
import NarrowCountryAutocomplete from "../NewInputs/NarrowCountryAutocomplete";
import {NarrowCityAutocomplete} from "../NewInputs/NarrowCityAutocomplete";
import ContactBookCompanyAutocomplete from "./ContactBookCompanyAutocomplete";
import ContactBookContactAutocomplete from "./ContactBookContactAutocomplete";
import {ContactsHeader, ReduxlessContactsTable} from "./ContactsTable";
import {Collapse} from "../Common/Collapse";
import { noop } from "../../core/utils";
import Search from "../Common/Search";
import Sort from "../Common/Sort";
import styled from "styled-components";
import cx from "classnames";
import history from "../../core/history";
import {CompanyEdit, MembersHeader, MembersTableWrapper} from "./CompanyEdit";
import {CompaniesTable} from "./CompaniesTable";



class GroupEdit extends CompanyEdit {

  static labelsMap = {
    create: {
      title: "CREATE GROUP",
      addButtonLabel: "CREATE",
      cancelButtonLabel: "CANCEL",
      deleteButtonLabel: "DELETE GROUP",
    },
    edit: {
      title: "EDIT GROUP",
      addButtonLabel: "UPDATE",
      cancelButtonLabel: "CANCEL",
      deleteButtonLabel: "DELETE GROUP",
    }
  };

  entityType = 'group';

  constructor(props, context) {
    super(props, context);
    this.state.contact.companies = this.state.contact.companies.filter(c => c.id);
  }

  handleAddCompany = (member) => {
    if (!member?.id) {
      return;
    }
    const companies = [...this.state.contact.companies, member];
    this.changeHandlers.companies(null, companies);

  }

  renderMembersPart() {
    return (<Collapse title={'Companies'}>
      <div>
        <ContactBookCompanyAutocomplete
          name={"user"}
          hintText={'Select Company'}
          validationErrors={{ isDefaultRequiredValue: 'required'}}
          validationError=""
          fullWidth
          popoverProps={{ }}
          forceSelection
          onNewRequest={this.handleAddCompany}
          key={this.state.contact.companies.length}
        />
        <CompaniesAsMembersTableWrapper members={this.state.contact.companies} onChange={this.changeHandlers.companies} />
      </div>
    </Collapse>);
  }

}

export const GroupEditConnected = connect(state => ({ ...state.contacts, user: state.login.user, tagCategoriesByName: state.login.tagCategoriesByName }), { createContact: createGroup, updateContact: updateGroup, deleteContacts })(GroupEdit);

class CompaniesAsMembersTable extends CompaniesTable {
  tableHeaderProps = { background: "transparent" };
  headerProps = { bg: "transparent" };

  componentDidMount() {

  }
  handleFilter = (filters) => {

  }

  handleSort = (sort) => {

  }

  handleLoadMore = () => {

  }
  handleContactSelect = (id) => {
    history.push(`/$1/$2/company:${id}/-/-/---`);
  }

  renderHeader() {
    return <MembersHeader user={this.props.user} {...this.props.contactList} handleSearch={this.props.handleFilter} handleSort={this.props.handleSort} />;
  }

  render() {
    if (!this.props.contactList.data.length && !this.props.contactList.filters.q) {
      return null;
    }
    return super.render();
  }


}

export class CompaniesAsMembersTableWrapper extends MembersTableWrapper {

  render() {
    return (
      <div>
        <CompaniesAsMembersTable {...this.mapTableProps()} readonly={this.props.readonly} withCheckbox={!this.props.readonly} />
      </div>
    );
  }

}
