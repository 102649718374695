import ROLES from '../../components/BillOfLading/ROLES';
import { date } from '../../core/format';

function rolesToString(roles) {
  if (!roles) {
    return '';
  }
  return roles.map(r => ROLES[r].label).join(', ');
}

export default function calculateStatus(bl) {
  let status = { text: bl.stage };
  const ps = bl.personalizedStatus;
  if (bl.stage !== 'issued' && bl.endAt.getTime() < Date.now()) {
    bl.stage = 'expired';
  }
  switch (bl.stage) {
    case 'collecting':
      switch (ps.should) {
        case 'inviteParticipants':
          status.text = 'You should invite ' + rolesToString(ps.roles);
          status.color = 'red';
          return status;
        case 'acceptParticipation':
          status.text = 'Confirm your participation as ' + rolesToString(ps.roles);
          status.color = 'red';
          return status;
        case 'approveParticipants':
          status.text = 'Requires confirmation of signatories';
          status.color = 'green';
          return status;
        case 'waitParticipants':
          status.text = 'Awaiting participants';
          status.color = 'blue';
          return status;
        case '':
          break;
      }
      status.text = 'Awaiting confirmation from participants';
      status.color = 'blue';
      break;
    case 'signing':
      switch (ps.should) {
        case 'approveSigns':
          status.text = 'Issue B/L';
          status.color = 'green';
          return status;
        case 'waitApproveSigns':
          status.text = 'Signed';
          status.color = 'blue';
          return status;
        case 'signFields':
          status.text = 'Requires signature';
          status.color = 'red';
          return status;
        case 'waitSigns':
          status.text = 'Awaiting signatures';
          status.color = 'blue';
          return status;
      }
      break;
    case 'issued':
      status.text = `B/L issued ${date(bl.status[bl.status.lastIssueApprover + 'IssueApproval'].approvedAt)}`;
      status.color = 'blue';
      return status;
    case 'expired':
      status.text = `Expired ${date(bl.endAt)}`;
      status.color = 'red';
      return status;
  }
  return status;
}
