import { removeFromArray, replaceInArray } from '../../core/utils';

/** @name PROFILE_DELETE_ACCOUNT_REDUCER */
export default function PROFILE_DELETE_ACCOUNT(state, action) {
  const account = action.payload;
  const newState = { ...state };
  const index = newState.departments.findIndex(a => a._id === account._id);

  if (index === -1) {
    return state;
  } 
  newState.departments = removeFromArray(newState.departments, index);
  
  return { ...state, ...newState };
}
