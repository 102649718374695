import PropTypes from 'prop-types';
import React, { Component } from 'react'
import ShareButton from './ShareButton';

import BlurIfNoSubscription from './../../Signup/BlurIfNoSubscription';
import { grabErrorMessage } from '../../../core/utils';
import Vessel from '../../../core/api/Vessel';
import loadable from '../../loadable';




const ShareDialog = loadable(() => import(/* webpackPrefetch: true */ /* webpackChunkName: 'ShareDialog' */ './ShareDialog'));

export class Share extends Component {
  static defaultProps = {
    loadVesselsInfo: Vessel.getShareInfo.bind(Vessel),
  }

  static contextTypes = {
    showMessage: PropTypes.func,
  }

  state = {
    isSharing: false,
    vessels: [],
    isLoadingVesselsInfo: false,
  }

  loadVesselsInfo = async (vesselIds) => {

    this.setState({
      isLoadingVesselsInfo: true,
    })

    try {
      const res = await this.props.loadVesselsInfo(vesselIds);

      this.setState(state => ({
        vessels: this.props.selectedVessels.map(vessel => {
          const newVessel = res.data.find(v => v._id === vessel._id);

          if (newVessel) return newVessel;

          return state.vessels.find(v => v._id === vessel._id);
        })
      }))

    } catch (error) {
      console.error(error);

      this.context.showMessage({
        level: "error",
        message: `Error loading vessels list info: ${grabErrorMessage(error)}`
      })
    } finally {
      this.setState({
        isLoadingVesselsInfo: false,
      })

    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedVessels !== this.props.selectedVessels) {
      this.setState(state => ({
        vessels: state.vessels.map((vessel, i) => {
          const first = this.props.selectedVessels.findIndex(v => v._id === vessel._id);
          const second = nextProps.selectedVessels.findIndex(v => v._id === vessel._id);
          const isPersisted = first >= 0 && second >= 0 && this.props.selectedVessels[first] && nextProps.selectedVessels[second];
          const needsUpdate = !isPersisted || this.props.selectedVessels[first] !== nextProps.selectedVessels[second];

          return ({
            ...vessel,
            dirty: needsUpdate,
          })
        })
      }))
    }
  }

  handleOpenShareDialog = () => {
    this.setState({ isSharing: true });

    const newVessels = this.state.vessels.filter(v => !v.dirty && this.props.selectedVessels.find(vessel => vessel._id === v._id));
    const shouldLoadIds = this.props.selectedVessels.filter(vesselId => !newVessels.find(v => v._id === vesselId._id)).map(v => v._id);
    if (shouldLoadIds.length) return this.loadVesselsInfo(shouldLoadIds);
    this.setState({
      vessels: newVessels,
    })
  };
  handleCloseShareDialog = () => this.setState({ isSharing: false });

  render() {

    const { isSharing, vessels, isLoadingVesselsInfo } = this.state;

    const { selectedVessels } = this.props;

    const { handleOpenShareDialog, handleCloseShareDialog } = this;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BlurIfNoSubscription dontBlur>
          <ShareButton onClick={handleOpenShareDialog} />
        </BlurIfNoSubscription>
        <ShareDialog vessels={vessels} loading={isLoadingVesselsInfo} shouldRender={isSharing} handleClose={handleCloseShareDialog} defaultEmail={this.props.defaultEmail} />
      </div>
    )
  }
}

export default Share;
