import saveFiltersState from './saveFiltersState';
//noinspection JSUnusedGlobalSymbols
/** @name MONITOR_VESSEL_LIST_REDUCER */
export default function MONITOR_VESSEL_LIST(state, { res, payload }) {
  const newVesselList = { ...state.vesselList, loading: false };

  if (res.page === 1) {
    newVesselList.data = res.data;
  } else {
    newVesselList.data = [...newVesselList.data, ...res.data];
  }
  if (payload) {
    if (payload.filters) {
      //res.filters.openPort = res.filters.openPortInfo;
      newVesselList.filters = { ...payload.filters, ...res.filters, channels: payload.filters.channels, search: payload.filters.search };
    }
  }
  newVesselList.page = res.page;
  newVesselList.total = res.total;
  newVesselList.next = res.next;
  newVesselList.sort = payload.sort;
  newVesselList.hiddenCount = res.hiddenVesselsCount;
  const newState = { vesselList: newVesselList };

  saveFiltersState({ ...state, ...newState });

  return { ...state, ...newState };
}
