/*
 * @Author: Sergiy Samborskiy
 * @Date: 2017-10-20 13:56:33
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-20 14:58:44
 */

import React from "react";
import s from "../Chat.scss";
import cx from "classnames";
import { connect } from "react-redux";

import IconChat from "material-ui/svg-icons/communication/chat-bubble-outline";
import ChatOpener from "../../Icons/StartChatIcon";

import { getContacts, getUnreadContactMessages, getMe, getUnreadChatsCounter } from "../selectors";

class ChatFloatBtnImpl extends React.Component {

    render() {
        const { showChat, isShown, unreadChats } = this.props;
        return (
            <div
                className={s.wrapper_closed}
                onClick={showChat}
                style={{
                    display: isShown ? "none" : "flex"
                }} >
                <ChatOpener
                    style={{
                        width: "20px",
                        height: "20px",
                        margin: "0 10px"
                    }}
                    color={"#ffffff"}
                />
                {/* <span className={s.name}>chat</span> */}

                <span className={cx(s.unreadChatsCount, { [s.show]: unreadChats })}>{ unreadChats }</span>
            </div>
        );
    }

}

function mapStateToProps(state, props) {
  return {
    unreadChats: getUnreadChatsCounter(state),
  };
}

export const ChatFloatBtn = connect(mapStateToProps)(ChatFloatBtnImpl);
