import { escapeRegExp, isIfchor } from '../../core/utils';
import orderBy from 'lodash/orderBy'
import { replaceAccountName } from './helpers';
export default function MONITOR_ORDER_NEW(state, { payload, currentUser }) {
  let newState = {};
  try {
    let tcList = { ...state.tcList };
    /* computeAndSetChannel(payload, currentUser);
    payload.addedByUser = currentUser && payload.addedBy === currentUser._id;
    const newCargo = filterCargo(cargoList.filters, payload);
    if (!newCargo) {
      return state;
    }
    if (cargoList.filters.search) {
      const reg = escapeRegExp(cargoList.filters.search);
      if (!reg.test(payload.fullTextSearch)) {
        return state;
      }
    }
    if (payload.status && payload.status.splice) {
      payload.status = payload.status[0];
    } */
    if (tcList.filters.search) {
      const query = escapeRegExp(tcList.search);
      if (!payload.fullTextSearch || !query.test(payload.fullTextSearch)) {
        throw Error('[SOCKET_NEW_ORDER] payload doesn"t have fullTextSearch or order doesn"t match user"s search');
      }
    }
    let passedFilters = false;

    if (tcList.filters) {
      let filters = {
        ...tcList.filters
      }

      delete filters.search;

      if (Object.values(filters).filter(val => !!val).length > 0) {
        passedFilters = checkFiltersForNewOrder(filters, currentUser, payload);
      }
    }
    else {
      passedFilters = true;
    }
    /* if (!tcList.filtered) {
     tcList.filtered = {
       ...tcList.data
     }
    }
    if (tcList.filtered.findIndex(item => item._id === payload?._id) === -1 && passedFilters) {
      tcList.filtered.unshift(payload);
    } */
    if (isIfchor(currentUser)) {
      payload = replaceAccountName(payload);
    }
    if (tcList.data.findIndex(item => item._id === payload?._id) === -1 && passedFilters) {
      const list = [payload, ...tcList.data];
      if (tcList.sort && tcList.sort.field) {
        const { field, value = 1 } = tcList.sort;
        const { data } = tcList;
        const fieldsDictionary = {
          "dwtMin": item => item?.vesselRequirements?.dwtMin || "",
          "cargo": item => item?.cargo?.included?.[0]?.cargo?.name,
          "typeSize": item => item?.vesselRequirements?.typeSizes?.[0],
          "readinessDate": item => item?.readinessDate
        }

        const selectedSortField = fieldsDictionary[field];
        let newData = [];

        const fetchField = item => {
          let val;
          if (typeof selectedSortField === 'function') {
            val = selectedSortField(item);
          }
          else if (typeof selectedSortField === 'string') {
            val = item[selectedSortField];
          }
          return typeof val === 'string' ? String(val).toLowerCase().trim() : val;
        }

        const empty = list.filter((item , i) => {
          let val = fetchField(item);
          if (!val) {
            return true;
          }
          else {
            newData.push(item);
            return false;
          }
        });
        console.log('empty', empty);
        newData = newData.filter(item => !!item);
        console.log('data', newData);

        const sorted = orderBy(newData, order => {
          let val = fetchField(order);
          return val;
        }, value > 0 ? 'asc' : 'desc');

        /* const sorted = data.slice().sort((a, b) => {
          const selectedSortField = fieldsDictionary[field];
          let firstItem = value > 0 ? a : b;
          let secondItem = value > 0 ? b : a;
          if (typeof selectedSortField === 'function') {
            const selector = selectedSortField;
            firstItem = selector(firstItem);
            secondItem = selector(secondItem);
            if (typeof firstItem === 'object' && typeof secondItem === 'object' && (!Array.isArray(firstItem) && !Array.isArray(secondItem))) {
              const { min: firstMin, max: firstMax } = firstItem;
              const { min: secondMin, max: secondMax } = secondItem;
              return firstMin - secondMin || firstMax - secondMax;
            }
            else if (typeof firstItem === 'number' && typeof secondItem === 'number') {
              if (!firstItem && secondItem) {
                return -1;
              }
              else if (firstItem && !secondItem) {
                return 1;
              }
              return firstItem > secondItem ? 1 : firstItem < secondItem ? -1 : 0;
            }
            else if (Array.isArray(firstItem) && Array.isArray(secondItem)) {
              firstItem = firstItem[0]?.toLowerCase() || "";
              secondItem = secondItem[0]?.toLowerCase() || "";
            }
          }
          else if (Array.isArray(selectedSortField)) {
            const sortFields = selectedSortField;
            let field = sortFields.find(field => {
              const a = firstItem?.vesselRequirements?.[field];
              const b = secondItem?.vesselRequirements?.[field];
              if (Array.isArray(a) && Array.isArray(b)) {
                return a.length > 0 && b.length > 0;
              }
              else {
                return !!a && !!b;
              }
            });
            if (Array.isArray(field)) {
              field = field[0];
            }
            const a = firstItem.vesselRequirements[field];
            const b = secondItem.vesselRequirements[field];
            if (a < b) {
              return -1;
            }
            else if (a > b) {
              return 1;
            }
            else {
              return 0;
            }
          }
          else if (typeof selectedSortField === 'object') {
            const { min, max } = selectedSortField;
            return firstItem[min] - secondItem[min] || firstItem[max] - secondItem[max];
          }
          else if (typeof selectedSortField === 'string' && selectedSortField) {
            firstItem = firstItem[selectedSortField];
            secondItem = secondItem[selectedSortField];
            if (Array.isArray(firstItem) && Array.isArray(secondItem)) {
              firstItem = firstItem[0]?.toLowerCase() || "";
              secondItem = secondItem[0]?.toLowerCase() || "";
            }
            else if (Object.prototype.toString.call(firstItem) === '[object Date]' && Object.prototype.toString.call(secondItem) === '[object Date]') {
              return new Date(firstItem) - new Date(secondItem);
            }
            else {
              firstItem = String(firstItem);
              secondItem = String(secondItem);
            }
          }
          if (!firstItem && secondItem) {
            return -1;
          }
          else if (firstItem && !secondItem) {
            return 1;
          }
          else if (!firstItem && !secondItem) {
            return 0;
          }
          return ('' + firstItem).localeCompare(secondItem);
        }) */
        const merged = [
          ...sorted, ...empty
        ];
        if (merged[merged.length - 1]?._id === payload?._id && tcList.next > tcList.page) {
          return;
        }
        tcList.data = merged;
      }
      else {
        tcList.data = list;
      }
    }

    tcList.filtered = [
      ...tcList.data
    ]
    //cargoList.data = orderBy(cargoList.data, [c=>(c.weight || 1), cargoList.sort.field], ['desc', sortOrders[cargoList.sort.value]]);
    newState.tcList = tcList;
  } catch (error) {
    console.error(error);
  } finally {
    return { ...state, ...newState };
  }
}

const curry = fn => {
  const fnExpectsArgs = fn.length;
  return function _curry(...args) {
    if (args.length < fnExpectsArgs) {
      return _curry.bind(this, ...args);
    }
    return fn.apply(this, args);
  }
}

const deliveryRedeliverySelector = item => (item?.port?._id || item?.portId || item?.port?.name || '');
const cargoSelector = item => {
  const cargoIds = {
    cargoTypeId: item?.cargoTypeId || item?.cargoType?._id ||null,
    cargoId: item?.cargoId || item?.cargo?._id || null
  }
  return cargoIds;
};
const flagsSelector = item => (item?.flag?._id || item?.flag?.name || item?._id);
const itemSelector = item => item || "";

const genericFilterSelector = item => (item._id || item.name);
const flagsFilterSelector = item => (item?.country?._id || item?.country?.name || item?._id);
const vesselTypeFilterSelector = item => (item?.type || item?.id);
const typesFilterSelector = field => (item) => (field ? item[field]?.type : item?.type)?.toLowerCase()?.trim();

const lowerCaseTrim = item => String(item).toLowerCase().trim();
const upperCaseTrim = item => String(item).toUpperCase().trim();

const matchAtLeastOneId = (selector, filterSelector, filtersArr, arr) => {
  let isMatching = false;
  const ids = arr.reduce((acc, item) => ([...acc, selector(item)]), []);
  console.log('ids', ids);
  const filtersLength = filtersArr.length;
  const isEveryExcluded = filtersLength ? filtersArr.every(item => !!item.exclude) : null;
  if (isEveryExcluded && ids.length === 0) {
    return true;
  }
  else if (!isEveryExcluded && ids.length === 0) {
    return false;
  }
  for (let i = 0; i < filtersArr.length; i++) {
    const item = filtersArr[i];
    const selectedField = filterSelector(item);
    const filterIncludesId = ids.includes(selectedField);
    // old logic based on if order has at least one instance (cargo, delivery, redelivery, etc) which is excluded in filter it doesn't pass it
    /* if (filterIncludesId) {
      if (item.exclude || selectedField?.exclude) {
        isMatching = false;
        break;
      }
      else {
        isMatching = true;
      }
    } */
    if (isEveryExcluded) {
      if (filterIncludesId) {
        isMatching = false;
        break;
      }
      else {
        isMatching = true;
      }
    }
    else if (filterIncludesId) {
      isMatching = true;
    }
  }
  /* if (!filtersArr.some(item => {
    const filterIncludesId = ids.includes(item._id);
    if (item.exclude && filterIncludesId) {
      return false;
    }
    return filterIncludesId;
  })) {
    console.log('matchAtLeastOneId did not pass')
    return false;
  } */
  console.log('filtersArr', filtersArr, 'orderArr', arr, 'matched ?', isMatching);
  return isMatching;
}

const curriedMatchAtLeastOneId = curry(matchAtLeastOneId);

const matchCargo = curriedMatchAtLeastOneId(cargoSelector, cargoSelector);
const matchFlags = curriedMatchAtLeastOneId(flagsSelector, flagsFilterSelector);
const matchVesselType = curriedMatchAtLeastOneId(itemSelector, vesselTypeFilterSelector);
const matchDeliveryRedelivery = curriedMatchAtLeastOneId(deliveryRedeliverySelector, genericFilterSelector)

function checkFiltersForNewOrder(filters = {}, user, order) {
  if (!order) throw Error('Can"t match filters to non existent order');
  const {context, readinessDate, cancellingDate, vesselRequirements: {
    typeByPurpose, typeBySpecialty, typeByConstruction, dwtMin, dwtMax, ageMin, ageMax, geared
    }
  } = order;
  if (filters.channels) {
    if(!filters.channels.mine && (context?.kind === 'public' && user?.company?._id === context?.companyId)) {
      return false;
    }
    if(!filters.channels.broker && (context?.kind === 'broker')) {
      return false;
    }
    if(!filters.channels.market && (context?.kind === 'public' && user?.company?._id !== context?.companyId)) {
      return false;
    }
  }
  if (filters.delivery && filters.delivery.length) {
    const orderDeliveryArr = order?.deliveryPlaces;
    const filtersArr = filters.delivery;
    const res = matchDeliveryRedelivery(filtersArr, orderDeliveryArr);
    if (!res) return res;
  }
  if (filters.redelivery && filters.redelivery.length) {
    const orderRedeliveryArr = order?.redeliveryPlaces;
    const filtersArr = filters.redelivery;
    const res = matchDeliveryRedelivery(filtersArr, orderRedeliveryArr);
    if (!res) return res;
  }
  /* if (
    (filters.readinessDate &&
    readinessDate?.getTime() > filters.readinessDate.getTime()) ||
    (readinessDate?.getTime() < filters.readinessDate.getTime() && cancellingDate?.getTime() > filters.readinessDate.getTime())
  ) {
    return true;
  } */
  if (
    (filters.readinessDate) &&
    (!readinessDate || readinessDate?.getTime() < filters.readinessDate.getTime()) &&
    (cancellingDate ? cancellingDate.getTime() < filters.readinessDate.getTime() : true)
  ) {
    return false;
  }
  /* if (
    (filters.cancellingDate &&
    cancellingDate.getTime() < filters.cancellingDate.getTime()) ||
    (cancellingDate.getTime() > filters.cancellingDate.getTime() && readinessDate?.getTime() > filters.cancellingDate.getTime())
  ) {
    return true;
  } */
  if (
    (filters.cancellingDate) &&
    (!cancellingDate || cancellingDate?.getTime() > filters.cancellingDate.getTime()) &&
    (readinessDate ? readinessDate?.getTime() > filters.cancellingDate.getTime() : true)
  ) {
    return false;
  }
  if (filters.cargo && filters.cargo.length) {
    if (!order?.cargo?.included || !order?.cargo?.included.length === 0) {
      return false;
    }
    const arr = order.cargo.included;
    const filtersArr = filters.cargo;
    let res = false;
    const ids = arr.reduce((acc, item) => ([...acc, cargoSelector(item)]), []);
    console.log('cargo ids', ids);
    const filtersLength = filtersArr.length;
    const isEveryExcluded = filtersLength ? filtersArr.every(item => !!item.exclude) : null;
    if (isEveryExcluded && ids.length === 0) {
      res = true;
    }
    else if (!isEveryExcluded && ids.length === 0) {
      res = false;
    }
    for (let i = 0; i < filtersArr.length; i++) {
      const filterIds = cargoSelector(filtersArr[i]);
      console.log(filterIds);
      const filterIncludesId = ids.find(({ cargoTypeId, cargoId } = {}) => {
        if ((filterIds.cargoTypeId && cargoTypeId === filterIds.cargoTypeId) || (filterIds.cargoId && cargoId === filterIds.cargoId)) {
          return true;
        }
        else {
          return false;
        }
      })
      if (isEveryExcluded) {
        if (filterIncludesId) {
          res = false;
          break;
        }
        else {
          res = true;
        }
      }
      else if (filterIncludesId) {
        res = true;
      }
    }
    if (!res) return res;
  }
  if (filters.flags && filters.flags.length) {
    const orderFlagsArr = order?.vesselRequirements?.flags;
    const filterFlags = filters.flags;
    const res = matchFlags(filterFlags, orderFlagsArr);
    if (!res) return res;
  }
  if (filters.typesBySize && filters.typesBySize.length) {
    const orderVesselTypesArr = order?.vesselRequirements?.typeSizes;
    const filterVesselTypes = filters.typesBySize;
    const res = matchVesselType(filterVesselTypes, orderVesselTypesArr);
    if (!res) return res;
  }
  if (filters.typeByConstruction) {
    if (lowerCaseTrim(typeByConstruction) !== lowerCaseTrim(filters.typeByConstruction)) {
      return false;
    }
  }
  /* if (filters.types && filters.types.length) {
    const { types } = filters;
    const inPurpose = matchAtLeastOneId(lowerCaseTrim, typesFilterSelector('typeByPurpose'), types, typeByPurpose ? [typeByPurpose] : []);
    const inSpecialty = matchAtLeastOneId(lowerCaseTrim, typesFilterSelector('typeBySpecialty'), types, typeBySpecialty ? [typeBySpecialty] : []);
    console.log('purpose', inPurpose, 'specialty', inSpecialty);
    if (!inPurpose && !inSpecialty) {
      return false;
    }
  } */
  if (filters.typesByPurpose) {
    const orderVesselType = order?.vesselRequirements?.typeByPurpose;
    const filterVesselTypes = filters.typesByPurpose;
    const res = matchAtLeastOneId(upperCaseTrim, vesselTypeFilterSelector, filterVesselTypes, orderVesselType ? [orderVesselType] : []);
    if (!res) return res;
  }
  /* if (filters.typesByConstruction && filters.typesByConstruction.length) {
    const { typesByConstruction } = filters;

    const inConstruction = matchAtLeastOneId(lowerCaseTrim, typesFilterSelector(null), typesByConstruction, typeByConstruction ? [typeByConstruction] : []);
    console.log('construction', inConstruction);
    if (!inConstruction) return inConstruction;
  } */
  if (
    (filters.minDWT) &&
    (!dwtMin || (dwtMin < filters.minDWT && dwtMax < filters.minDWT) || (dwtMin > filters.maxDWT && dwtMax > filters.maxDWT))
  ) {
    return false;
  }
  /* if (filters.minDWT) {
    if (dwtMin < filters.minDwt) {
      console.log("not passed min dwt", dwtMin , filters.minDwt);
      return false;
    }
  } */
  /* if (
    (filters.maxDWT) &&
    (!dwtMax || dwtMax > filters.maxDWT || dwtMax < filters.minDWT) &&
    (dwtMin ? (dwtMin > filters.maxDWT || dwtMin < filters.minDWT) : true)
  ) {
    return false;
  } */
  if (
    (filters.maxDWT) &&
    (!dwtMax || (dwtMax < filters.minDWT && dwtMin < filters.minDWT) || (dwtMax > filters.maxDWT && dwtMin > filters.maxDWT))
  ) {
    return false;
  }
  if (
    (filters.minAge) &&
    ((!ageMin && !ageMax) || (ageMin < filters.minAge && ageMax < filters.minAge) || (ageMin > filters.ageMax && ageMax > filters.ageMax))
  ) {
    return false;
  }
  /* if (filters.minAge) {
    if (ageMin < filters.minAge) {
      console.log("not passed min age", ageMin , filters.minAge);
      return false;
    }
  } */
  if (
    (filters.maxAge) &&
    ((!ageMin && !ageMax) || (ageMax < filters.minAge && ageMin < filters.minAge) || (ageMax > filters.maxAge && ageMin > filters.maxAge))
  ) {
    return false;
  }
  /* if (filters.maxAge) {
    if (ageMax > filters.maxAge) {
      console.log("not passed max age", ageMax , filters.maxAge);
      return false;
    }
  } */
  if (filters.gear) {
    if (!geared) {
      console.log("not passed gear", geared , filters.gear);
      return false;
    }
  }
  return true;
}
