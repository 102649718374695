import Api, { convertToSameTimeZ } from './api';
import { isFreightRequest, isRemoved, isTrading, prepareRequest, toUTC } from '../utils';
import Vessel from './Vessel';
import {orderBy, cloneDeep} from 'lodash';
/**
 * @class
 */
export default class Cargo extends Api {
  // constructor(data) {
  //   super(data);
  // }

  static endpoint = '/cargo';

  static recognizeSimpleForm(simpleFormText) {
    return this.fetch("/simpleCargo", {
      method: "POST",
      body: JSON.stringify({
        text: simpleFormText,
      })
    }, "/api/v2");
  }

  static async uploadFile(formData, opts = {}) {
    return Cargo.fetch(`/file/upload?${opts.privateAttachment ? 'private=1' : ''}`, {
      method: 'post',
      body: formData,
      headers: {},
    });
  }

  static saveFreightIdea(cargoId, data) {
    return this.fetch(`/requests/cargo/${cargoId}/freight-idea`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static saveFixedRate(cargoId, data) {
    return this.fetch(`/requests/cargo/${cargoId}/fixed-rate`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static async saveAccountName(cargoId, data) {
    const res = await this.fetch(`/requests/cargo/${cargoId}/account`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
    res.data = { account: (await this.getMonitorCargoById(cargoId)).data.account };
    return res;
  }

  static async getRequestList(
    { filters = {}, sort = { field: 'status.weight', value: -1 }, page = 1 },
    token,
  ) {
    filters = { ...filters };
    if (filters.readinessDate) {
      filters.readinessDate = toUTC(filters.readinessDate);
    }
    if (filters.cancellingDate) {
      filters.cancellingDate = toUTC(filters.cancellingDate);
    }
    return Cargo.fetch('/requests/my/cargo', {
      method: 'POST',
      body: JSON.stringify({
        filters,
        sort,
        page,
      }),
      token,
    });
  }

  static async getCargoFilters() {
    return Cargo.fetch('/preferences/byType/deckCargoFilter', {
      method: 'GET',
    });
  }

  static async saveCargoFilter(name, body) {
    return Cargo.fetch(`/preferences/deckCargoFilter/${name}`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async updateCargoFilter(filterId, formData) {
    return Cargo.fetch(`/preferences/${filterId}`, {
      method: 'PUT',
      body: JSON.stringify(formData),
    });
  }

  static async deleteCargoFilter(filterId) {
    return Cargo.fetch(`/preferences/${filterId}`, {
      method: 'DELETE'
    });
  }

  static async getCargoList({
    filters = {},
    sort = { field: 'addedAt', value: -1 },
    page = 1,
  }) {
    const params = {
      filters: { ...filters },
      sort,
      page,
    };
    if (params.filters.readinessDate) {
      params.filters.readinessDate = toUTC(params.filters.readinessDate);
    }
    if (params.filters.cancellingDate) {
      params.filters.cancellingDate = toUTC(params.filters.cancellingDate);
    }
    return Cargo.fetch('/deck/cargo', {
      method: 'post',
      body: JSON.stringify(params),
    });
  }
  static sendRequest = async function (request = {}) {
    return Cargo.fetch(`/requests/cargo/${request._id || ''}`, {
      method: request?._id ? 'PUT' : 'POST',
      body: JSON.stringify(request),
    });
  };

  static async getMonitorCargoById(_id,token) {
    const res = await Cargo.fetch('/monitor/cargo/' + _id,{method: 'GET', token});
    if(res.data && res.data.cloneCargo) {
      try {
       const res2 = await Cargo.fetch('/monitor/cargo/' + res.data.cloneCargo, { method: 'GET', token });
       if(res2.data) {
         res.data.originalCargo = res2.data;
       }
      } catch (e) {
        console.error(e);
      }
    }
    return res;
  }

  static async getCargoById(_id) {
    return Cargo.fetch('/requests/my/cargo/' + _id);
  }

  static async sendOffer(offer) {
    if (offer.bidId) {
      return Cargo.fetch('/bids/' + offer.bidId, {
        method: 'POST',
        body: JSON.stringify(prepareRequest(offer)),
      });
    }
    return Cargo.fetch('/bids/offer/' + offer.cargoRequest, {
      method: 'POST',
      body: JSON.stringify(prepareRequest(offer)),
    });
  }

  static async fixOffer(bidId, body = {}) {
    return Cargo.fetch('/bids/fix/' + bidId, { method: 'POST', body: JSON.stringify(body) });
  }

  static async fixTcOffer(bidId) {
    return Cargo.fetch('/timecharts/fix/' + bidId, { method: 'POST' });
  }
  static async getBidById(bidId, token) {
    return Cargo.fetch('/bids/' + bidId, { method: 'GET', token });
  }

  static async getTcBidById(bidId, token) {
    return Cargo.fetch('/timecharts/' + bidId, { method: 'GET', token });
  }
  static async rejectOffer(bidId) {
    return Cargo.fetch('/bids/reject/' + bidId, { method: 'POST' });
  }

  static async rejectTcOffer(bidId) {
    return Cargo.fetch('/timecharts/reject/' + bidId, { method: 'POST' });
  }

  static async findRelevantShips(_id, page = 1, filters={}, hidden = false, sort) {
    if(!_id) {
      return;
    }
    let qs = `?page=${page}&filters=${encodeURIComponent(JSON.stringify(filters))}&hidden=${hidden}&sort=${encodeURIComponent(JSON.stringify(sort))}`;
    if(filters.tab === 'archive') {
      qs+='&archive=true'
    }
    return Cargo.fetch('/deck/cargo/' + _id + '/relevant'+qs, { method: 'GET', ignoreError:true})
  }

  static async sendTcOffer(offer) {
    if (offer._id) {
      return Cargo.fetch('/timecharts/counter/' + offer._id, {
        method: 'POST',
        body: JSON.stringify(prepareRequest(offer)),
      });
    }
    return Cargo.fetch('/timecharts/', {
      method: 'POST',
      body: JSON.stringify(prepareRequest(offer)),
    });
  }

  static async refreshCargoRequest(_id) {
    return Cargo.fetch(`/requests/my/cargo/${_id}/refresh`);
  }

  static deleteRequest(_id) {
    return Cargo.fetch(`/requests/cargo/${_id}`, { method: 'DELETE' });
  }
  static getAllTerms() {
    return Cargo.fetch('/terms/voyage');
  }
  static getTradeStartInfo() {
    return Cargo.fetch('/bids/tradeStartInfo ', { method: 'POST' });
  }

  static getOriginalMail(_id){
    return Cargo.fetch(`/requests/fetchOriginalMail/cargo/${_id}`);
  }

  static sendReminder({ message, footerMessage, cargoRequestId, vesselRequestId, bidId, sendAt, freightRequestId, shareEmails }) {
    return Cargo.fetch('/reminders/voyage', {
      method: 'POST',
      body: JSON.stringify({ message, footerMessage, cargoRequestId, vesselRequestId, bidId, sendAt, freightRequestId, shareEmails }),
    }, '/api/v2');
  }

  static cancelReminder(reminder) {
    return Cargo.fetch(`/reminders/voyage/${reminder._id}`, {
      method: 'DELETE',
    }, '/api/v2');
  }

  static async updateCargoDetails(cargo, cargoDetails) {
    return Cargo.fetch(`/requests/cargo/${cargo._id}/details`, {
      method: 'PUT',
      body: JSON.stringify({ cargoDetails: cargoDetails }),
    }, '/api/v1');
  }

  static async populateBids(entities = [], token) {
    const promises = [];
    if (!Array.isArray(entities)) {
      entities = [];
    }
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      if (entity.bidId) {
        promises.push(this.getBidById(entity.bidId), token);
      } else {
        promises.push(null);
      }
      await Promise.all(promises);
    }
    for (let i = 0; i < entities.length; i++) {
      entities[i].bid = promises[i];
    }
  }

  static async getBids(page = 1, filters={}, sort = {field:"addedAt", value:-1}) {
    filters = {...filters};
    const types = filters.types || {};
    let qs = `?page=${page}`;
    if(filters.cancellingDate) {
      filters.cancellingDate = toUTC(filters.cancellingDate)
    }
    if(filters.readinessDate) {
      filters.readinessDate = toUTC(filters.readinessDate)
    }
    if(filters.lastTradeDateFrom) {
      filters.lastTradeDateFrom = toUTC(filters.lastTradeDateFrom)
    }
    if(filters.lastTradeDateTo) {
      filters.lastTradeDateTo = toUTC(filters.lastTradeDateTo);
      filters.lastTradeDateTo.setDate(filters.lastTradeDateTo.getDate());
      filters.lastTradeDateTo.setUTCHours(23, 59, 59, 0);
    }
    if((types.starred && types.unstarred) || (!types.starred && !types.unstarred)) {
      filters.favorite = undefined;
    } else {
      filters.favorite = types.starred || !types.unstarred;
    }
    if (types.myQuotations) {
      filters.myQuotations = types.myQuotations;
    }
    if (!types.myQuotations) {
      filters.myQuotations = undefined;
    }

    return Cargo.fetch('/bids/myCargoList'+qs, { method: 'POST', body:JSON.stringify({ filters, sort})})
  }

  static async getCargoAndVessel(cargoId, vesselId, user,token){
    let cargoPromise = Promise.resolve({data: undefined});
    let vesselPromise = Promise.resolve({data: undefined});
    let results;
    let validResults;
    if(cargoId && cargoId.length === 24) {
      cargoPromise = Cargo.getMonitorCargoById(cargoId, token);
    }
    if(vesselId && (vesselId.length === 24 || vesselId.length === 7) ) {
      vesselPromise =  Vessel.getMonitorVesselById(vesselId,undefined, token);
    }
    try {
      const results = await Promise.all([cargoPromise, vesselPromise].map(p => p.catch(e => e)));
      const validResults = results.filter(result => !(result instanceof Error) && result.name !== 'FetchException');
      if(!validResults.length) {
        return { cargo: results[0], vessel: results[1] }
      }
      let cargo = getDataOrError(results[0]);
      let vessel = getDataOrError(results[1]);
      if(cargo?._id && vessel?._id) {
        let cargoVesselBids;
        try {
          cargoVesselBids = await Cargo.getCargoVesselBids(cargoId, vesselId, token);
          cargo.bidChannel = cargoVesselBids.cargoBidChannel;
          if (cargo.bidChannel) {
            cargo.bidChannel = computeChannelStatuses(cargo.bidChannel, user);
            if (isFreightRequest(cargo.bidChannel.status.status)) {
              cargo.frChannel = cargo.bidChannel;
              cargo.bidChannel = undefined;
              if (typeof cargo.frChannel.status.comm === 'number') {
                cargo.comm = cargo.frChannel.status.comm;
                cargo.deductible = cargo.frChannel.status.deductible;
                cargo.pus = cargo.frChannel.status.pus;
              }
            }
          }
          vessel.channels = cargoVesselBids.vesselChannels;
        } catch (e) {
          console.error(e);
          return { cargo: e, vessel: results[1] }
        }
      }

      if(cargo) {
        cargo.expired = new Date(cargo.cancellingDate) < new Date() || cargo.deleteBy;
        cargo.addedByCompany = (cargo.addedBy?.company?._id || cargo.companyId) === user?.company._id;
      }
      if (vessel && vessel.channels) {
        vessel.vessel.canFreightRequest = false;
        vessel.vessel.canOfferHire = false;
        /*vessel.channels = vessel.channels.filter(c => {
         return  !(c.refNo && c.refNo.slice(-2) === '-C' && !c.status.status && !c.status.bid);
        }); // remove cloned channels without trading*/
        //vessel.channels.reverse();
        const freightRequestsIds = {};
        vessel.channels.forEach(c => computeChannelStatuses(c,user));
        vessel.channels = sortChannels(vessel.channels);
        const fixedChannel = vessel.channels.find(c => c.isFixed);
        vessel.channels.forEach(c => {
          c.canDelete = false;
          if((c.addedByUser && !(c?.hasOfferOrFreight))) {
            c.canDelete = true;
          }
          c.canFreightRequest = canFreightRequest(cargo,c, {fixedChannel});

          if(c.canFreightRequest) {
            if((cargo.realUser && c.realUser && c.realUser._id === cargo.realUser._id) || (freightRequestsIds[(c.realUser?.companyId) || (c.realUser?.email) || (c.company?._id)])) {
              c.canFreightRequest = false;
            }
          }



          if(c.companyCargoSide && (isTrading(c.status.status)) && !c.expired) {
            freightRequestsIds[(c.realUser?.companyId) || (c.realUser?.email) || (c.company?._id)] = 1;
          }
          if (isFreightRequest(c.status.status) && !c.expired ) {
            freightRequestsIds[(c.realUser?.companyId) || (c.realUser?.email) || (c.company?._id)] = 1;
          }
          if (c.preferredCargo || c.canViewOriginalMail || c.status.bid || isFreightRequest(c.status.status) && c.status.message) {
            c.haveDetails = true;
          }
         c.canSendReminder = (c.canChangeFreight || c.status.status === 'freight-out') || (c.status.bid && c.status.bid.endAt < new Date());
          if (c.canSendReminder) {
            c.haveDetails = true;
          }
          c.name = vessel.vessel.name;
        });
        vessel.vessel.canFreightRequest = vessel.channels.reduce((acc, c) => acc || c.canFreightRequest, false);
        vessel.vessel.canOfferHire = vessel.channels.reduce((acc, c) => acc || c.canOfferHire, false);
        vessel.vessel.dontBlurOfferHire = vessel.channels.reduce((acc, c) => acc || (c.canOfferHire && (c.isClientRequest || c.userInWhiteList)), false);
        vessel.vessel.dontBlurFreightRequest = vessel.channels.reduce((acc, c) => acc || (c.canFreightRequest && (c.isClientRequest || c.userInWhiteList)), false);
        const openChannel = vessel.channels.find(c => c.addedByUser) || vessel.channels.find(c => c.addedByCompany) || vessel.channels[0];
        vessel.vessel.open = openChannel && openChannel.open;
        vessel.vessel.openChannel = openChannel;
      }


      return { cargo, vessel };
    } catch (e) {
      console.error(e);
      return {};
    }
  }


  static async getCargoVesselBids(cargoRequestId, vesselId, token) {
    const {data, status}= await Cargo.fetch(`/deck/cargoVesselBids/${cargoRequestId}/${vesselId}`, { method: 'GET', token });
    const cargoBidChannel = data.cargoSide[data.lastCargoTrade];
    const vesselChannels = data.vesselSide;
    return {cargoBidChannel, vesselChannels};
  }

  static async printCargoBidsPdf(cargoList) {
    const body = { cargoes: convertToSameTimeZ(cloneDeep(cargoList))};
    return this.fetch('/bids/myCargoList/pdf', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  static async getAnalytics({ startDate, endDate, groupBy }, filters = {}) {
    if(startDate.getTime() === endDate.getTime()) {
      endDate.setMilliseconds(endDate.getMilliseconds() + 1);
    }
    return this.fetch(`/monitor/cargo/statistics/offers`, {
      method: 'POST',
      body: JSON.stringify({
        groupBy,
        filters: {
          ...filters,
          readinessDate: toUTC(startDate).toISOString(),
          cancellingDate: toUTC(endDate).toISOString(),
        },
      }),
    }, '/api/v2');
  }

  static async setStatus(_id, statuses){
    return this.fetch(`/cargo-requests/${_id}/setStatus`, {
      method: 'POST',
      body: JSON.stringify({ statuses }),
    }, '/api/v2');
  }

  static async setLastSeenDate(cargo, lastSeenDate) {
    return this.fetch(`/cargo-request-changes/${cargo._id}/set-as-seen/all`, {
      method: 'POST',
      body: JSON.stringify({ lastSeenDate }),
    });
  }

  static async getNewMonitorCargo(cargo){
    return (await this.getCargoList({
      filters: { search: cargo.refNo },
      sort: { field: 'addedAt', value: -1 },
      page: 1,
      limit: 5,
    }))
      ?.data[0];
  }

  static async getContainerTypes(types = []) {
    const type = types.find(t => t.name === 'Containers');
    if (!type) {
      return Promise.resolve([]);
    }
    return (await Cargo.find({ type: type._id })).data;
  }

}

function sortChannels(channels) {
  channels = [...channels];
  return orderBy(channels, [c => !!c.status.status,c => c.status.end],['desc','desc']);
}

function getDataOrError(res ={}){
  if((res instanceof Error) || res.name === 'FetchException') {
    return res;
  }
  return res.data;
}

function computeChannelStatuses(c, user){

    if (c.status?.bid?.status === 'fixed') {
      c.status.status = 'fixed';
      c.status.end = c.status?.bid?.endAt;
      c.status.weight = 200;
    }
    if (c.status?.bid?.status === 'rejected') {
      c.status.status = 'reject';
      c.status.end = c.status?.bid?.endAt;
    }
    if(c.status?.state === 'removed') {
      c.status.status = 'reject';
    }
    c.expired = c.status.end < Date.now();
    c.isDateOpened = c.open?.until > Date.now();
    c.addedByCompany = c.company?._id === user.company?._id;
    c.isFixed = c.status.status === 'fixed';
    c.canOfferHire = (!c.addedByCompany || c.isClientRequest);
    c.canCounter = !c.expired && c.status.status === 'bid' && (c.status.bid.vesselSide.user === user._id || c.status.bid.cargoSide.user === user._id);
    c.canChangeFreight = !c.expired && c.status.status === 'waiting' && (c.status.bid.vesselSide.user === user._id || c.status.bid.cargoSide.user === user._id);
    c.myVesselSide = c.status.bid && (c.status.bid.vesselSide?.user === user._id);
    c.companyVesselSide = c.status.bid && (c.status.bid.vesselSide?.company === user.company?._id || c.myVesselSide);
    c.myCargoSide = c.status.bid && (c.status.bid.cargoSide?.user === user._id);
    c.companyCargoSide = c.status.bid && (c.status.bid.cargoSide?.company === user.company?._id || c.myCargoSide );
    c.canOpenReminder = (c.status.bid && (c.status.status === 'waiting' || c.expired))
      || (c.status.status && (c.status.status === 'freight-out' || (c.status.status === 'freight-in' && c.expired)))
      || c.status.reminder;
  return c;
}


function canFreightRequest(cargo,c,{ fixedChannel}) {

  if(!cargo || cargo.expired || c.isFixed || (cargo.fixed && fixedChannel) || (isTrading(c.status.status) && !c.expired) || (c.addedByCompany && !c.isClientRequest) || isRemoved(c.status.status) ) {
    return false;
  }
  return true;
 /*return cargo && !cargo.expired && !c.isFixed  && (!isTrading(c.status.status) || c.expired) && (!c.addedByCompany || c.isClientRequest) && (
   (!c.status.bid || (c.status.status === 'reject') || (isTrading(c.status.status) && c.expired))
 )*/
}
