import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Dialog from '../Common/DialogGreyTitle';
import s from './Monitor.scss';
import cx from 'classnames';
import NewOffer from './Offer/NewOffer';
import DialogHalfScreen from "../Common/DialogHalfScreen";
import {Pane} from "../Common/SplitPane";


class OfferFreightDialog extends PureComponent {


  render() {
    return (
      <DialogHalfScreen
        open
        handleClose={this.props.handleClose}
        contentClassName={cx(s.dialogContent, s.dialog_content_offer_freight)}
        bodyClassName={cx(s.dialog_body_offer_freight)}
        modal
        title={'FREIGHT OFFER'}
        position={this.props.position || "right"}
      >
        <Pane headerProps={{ onClose: this.props.handleClose, children: <b>FREIGHT OFFER</b>, useBorder: true, p: "0 12px 0 20px" }}>
        <div>
          <div>
            <div>
             <NewOffer {...this.props} />
            </div>
          </div>
        </div>
        </Pane>
      </DialogHalfScreen>
    );
  }
}

export default withStyles(s)(OfferFreightDialog);
