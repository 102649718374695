import React from 'react'
import ShareIcon from '../../Icons/ShareIcon';
import Hoverable from './../Hoverable';

import styled from 'styled-components';

const Ico = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  svg {
    height: 20px;
    width: 20px;
    transform: scale(1.2);
  }
`

function ShareButton(props) {
  return (
    <Hoverable {...props}>
      <Ico>
        <ShareIcon />
      </Ico>
    </Hoverable>
  )
}

export default ShareButton
