import React from 'react'
import CurrencySelect from '../../Common/CurrencySelect';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import NarrowSelect from '../../NewInputs/NarrowSelect';
import AbstractFormHandler from './AbstractFormHandler';
import SurchargeSelect from './SurchargeSelect';
import UnitsSelect, { MT } from './UnitsSelect';

export class Charges extends AbstractFormHandler {

  static getPropsValues = props => props.charge;

  componentDidMount() {
    const { charge = {}, onChange } = this.props;
    if (charge?.currency && charge?.term) return;
    const copy = {
      ...charge,
    }

    if (!copy.currency) {
      copy.currency = 'USD';
    }
    if (!copy.term) {
      copy.term = MT.value;
    }
    onChange(copy);
  }

  render() {
    const { change } = this;
    const { namePrefix = "charges", charge = {}, style = {}, className = '', ...rest } = this.props;

    const { type, value, currency, term, details } = charge;

    return (
      <div className={`row ${className}`} style={style}>
        <div className="col-4">
          <SurchargeSelect
            name={`${namePrefix}[type]`}
            value={type || ""}
            autoWidth
            fullWidth
            hintText={`Surcharge Type${value ? "*" : ""}`}
            requiredError="required"
            required={!!value}
            onChange={change("type")}
          />
        </div>
        <div className="col-3">
          <NarrowFormsyText
            name={`${namePrefix}[value]`}
            value={value || ""}
            hintText={`Surcharge${type ? "*" : ""}`}
            required={!!type}
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
            requiredError="required"
            fullWidth
            onBlur={change("value")}
          />
        </div>
        <div className="col-2">
          <CurrencySelect
            style={{ width: '100%' }}
            as={NarrowSelect}
            name={`${namePrefix}[currency]`}
            value={currency || ""}
            hintText="Currency"
            fullWidth
            onChange={change("currency")}
          />
        </div>
        <div className="col-3">
          <UnitsSelect
            name={`${namePrefix}[term]`}
            value={term}
            hintText="Term"
            fullWidth
            onChange={change("term")}
          />
        </div>
        <div className="col-12">
          <NarrowFormsyText
            name={`${namePrefix}[details]`}
            value={details}
            hintText="Details"
            fullWidth
            onChange={ev => change("details")(ev.target.value)}
            multiLine
            adaptHeightByValue={this.props.adaptHeightByValue}
          />
        </div>
      </div>
    )
  }
}

export default Charges;
