import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import createHelpers from './createHelpers';
import createLogger from './logger';
import promiseMiddleware from '../core/promiseMiddleware';

export default function configureStore(initialState, helpersConfig) {
  const helpers = createHelpers(helpersConfig);
  const middleware = [thunk.withExtraArgument(helpers), promiseMiddleware];

  let enhancer;

  if (__DEV__) { // eslint-disable-line no-undef
    middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    let devToolsExtension = f => f;
    if (process.env.BROWSER && window.devToolsExtension) {
      devToolsExtension = window.devToolsExtension();
    }

    enhancer = compose(applyMiddleware(...middleware), devToolsExtension);
  } else {
    enhancer = applyMiddleware(...middleware);
  }
  try {
    if (typeof window !== 'undefined') {
      if (window.sessionStorage) {
        const now = Date.now();
        const savedState = JSON.parse(window.sessionStorage.getItem('state'));
        if (initialState.login && initialState.login.user) {
          if (savedState && savedState.monitor) {
            const vesselFilters = savedState.monitor.vesselList &&
              savedState.monitor.vesselList.filters;
            const cargoFilters = savedState.monitor.cargoList &&
              savedState.monitor.cargoList.filters;
            if (vesselFilters) {
              if (vesselFilters.openFrom) {
                vesselFilters.openFrom = new Date(vesselFilters.openFrom);
                if (vesselFilters.openFrom.getTime() <= now) {
                  vesselFilters.openFrom = undefined;
                  vesselFilters.openFromCheckbox = false;
                }
              }
              if (vesselFilters.openUntil) {
                vesselFilters.openUntil = new Date(vesselFilters.openUntil);
                if (vesselFilters.openUntil.getTime() <= now) {
                  vesselFilters.openUntil = undefined;
                  vesselFilters.openUntilCheckbox = false;
                }
              }
              vesselFilters.openFromCheckbox = vesselFilters.openFromCheckbox ||
                vesselFilters.openUntilCheckbox;
              vesselFilters.openUntilCheckbox = vesselFilters.openFromCheckbox ||
                vesselFilters.openUntilCheckbox;
            }
            if (cargoFilters) {
              if (cargoFilters.readinessDate) {
                cargoFilters.readinessDate = new Date(cargoFilters.readinessDate);
                if (cargoFilters.readinessDate.getTime() <= now) {
                  cargoFilters.readinessDate = undefined;
                  cargoFilters.readinessDateCheckbox = false;
                }
              }
              if (cargoFilters.cancellingDate) {
                cargoFilters.cancellingDate = new Date(cargoFilters.cancellingDate);
                if (cargoFilters.cancellingDate.getTime() <= now) {
                  cargoFilters.cancellingDate = undefined;
                  cargoFilters.cancellingDateCheckbox = false;
                }
              }
              cargoFilters.readinessDateCheckbox = cargoFilters.readinessDateCheckbox ||
                cargoFilters.cancellingDateCheckbox;
              cargoFilters.cancellingDateCheckbox = cargoFilters.readinessDateCheckbox ||
                cargoFilters.cancellingDateCheckbox;
            }
            if (savedState.monitor.vesselList && savedState.monitor.vesselList.sort && savedState.monitor.vesselList.sort.field === 'addedAt') {
              savedState.monitor.vesselList.sort.field = 'updateAt';
            }
            Object.assign(initialState.monitor, savedState.monitor);
          }
        } else {
          window.sessionStorage.setItem('state', null);
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(rootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) { // eslint-disable-line no-undef
    module.hot.accept(
      '../reducers',
      () => store.replaceReducer(require('../reducers').default), // eslint-disable-line global-require
    );
  }
  return store;
}
