import MenuItem from 'material-ui/MenuItem';
import FormsySelect from './Select';
import React from 'react';

export default function CurrencySelect(props) {

  const { as: El, ...restProps } = props;
  return (<El
    hintText=" "
    requiredError="required"
    defaultValue={props.value ? undefined : 'USD'}
    style={{ width: '60px' }}
    {...restProps}
  >
    <MenuItem
      key="USD"
      value="USD"
      primaryText="USD"
    />
    <MenuItem
      key="EUR"
      value="EUR"
      primaryText="EUR"
    />
  </El>);
}

CurrencySelect.defaultProps = { as: FormsySelect };
