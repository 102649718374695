import Vessel from '../../core/api/Vessel';
import Autocomplete from './AutoComplete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MenuItem from 'material-ui/MenuItem';
import Highlight from '../Monitor/Highlight';
import { escapeRegExp } from '../../core/utils';
import commonVessels from '../Calculator/commonVessels';


function renderer(ships, props = {}) {
  const search = escapeRegExp(this.state.searchText || '');
  return ships.map((ship) => {
    return {
      text: ship.name,
      _value: ship,
      value: (
        <MenuItem
          primaryText={<div style={{ fontSize: '9px', overflow: "hidden", width: '100%', paddingRight: '4px', textOverflow: "ellipsis", textTransform: 'uppercase', fontWeight: 'bold' }}><Highlight
            search={search}>{ship.name}</Highlight></div>}
          secondaryText={<span style={{ fontSize: '9px', float: "unset" }}>{ship.imoNumber ? 'IMO NO:':''}&nbsp;<Highlight search={search} >{ship.imoNumber}</Highlight>&nbsp;<span
            style={{
              fontSize: '9px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              minWidth: '70px',
              display: 'inline-block',
              textAlign: 'right',
            }}>DWT: {parseInt(ship.dw.summer)}MT</span></span>}
          className="vessel_autocomplete_item vessel_autocomplete_item_vessel_name_ellipsis"
          {...props}
        />
      ),
    };
  });
}

class VesselAutocomplete extends Autocomplete {

  static defaultProps = {
    menuProps: {
      menuItemStyle: {
        minHeight: '32px',
        lineHeight: '32px',
        height: '32px',
        fontSize: '15px',
        fontWeight: '500',
        border: '1px solid #E0E0E0',
        borderTop: 'none',
      },
      className: 'vessel_autocomplete_menu',
    },
    withCommonTypes: false
  }

  constructor(props) {
    super(props);
    if(!this.props.withCommonTypes){
      this.Api = Vessel;
    }else{
      this.Api = {
        find: async (params)=> {
          const search = escapeRegExp(params.name);
          const types = commonVessels.filter(cv => search.test(cv.name))
          const res = await Vessel.find(params);
          if(Array.isArray(res.data)) {
            res.data = [...types,...res.data];
          }
          return res;
        }
      }
    }

    this.renderer = renderer;
  }
}

VesselAutocomplete.propTypes = {};

export default VesselAutocomplete;
