// PASS FUNCTION AS DATA IF YOU NEED TO MERGE OBJECT AND SAVE EXISTING FIELDS
import { updateItem } from './helpers'
export default function UPDATE_TC_ORDERS_LIST(state, { payload: { orderId, data } }) {
  const newState = { ...state.tcList };
  let newList = updateItem([...newState.data], orderId, data);
  newState.data = newList;
  newState.filtered = newList;
  //return { ...state, tcList: filterTCOrders(newState) };
  return { ...state, tcList: newState };
}
