import React, { PureComponent } from 'react';
import cx from 'classnames';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import s from './Channels.scss';
import sm from '../TableNew.scss';
import { dateRange, handleCapitalize, number } from '../../../core/format';
import { CreateChatWrapper } from '../../Chat/CreateChat';
import PortLink from '../../Common/PortLink';
import Link from '../../Link/Link';
import RaisedButton from '../../Common/RaisedButton';
import Formsy from 'formsy-react';
import OfferInMainDeck from '../OfferInMainDeck';
import ArrowIcon from '../../Icons/Arrow';
import Timer from '../Timer';
import OfferFreightDialog from '../OfferFreightDialog';
import { sendOffer, rejectOffer, fixOffer, sendReminder, cancelReminder} from '../../../actions/cargo';
import { connect } from 'react-redux';
import VesselApi from '../../../core/api/Vessel';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import ChangeFreightDialog from '../ChangeFreightDialog';
import history from '../../../core/history';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { toggleDialogMessage } from '../../../actions/runtime';
import { isFreightRequest } from '../../../core/utils';
import BlurIfNoSubscription, { BlurEmailIfNoSubscription } from '../../Signup/BlurIfNoSubscription';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import OriginalMessageBody from './../../Common/OriginalMessageBody';

import CustomCheckbox from '../../Common/CustomCheckbox';

import ChannelStatus from './../../Common/ChannelStatus';
import ConditionalBlur from './../../Common/ConditionalBlur'
import { DisplayMaintenance } from '../../Vessel/Positions/Maintenance';
import SendReminderDialog from '../SendReminder/SendReminderDialog';
import Attachments from '../../Cargo/Preview/Attachments';
import OriginalMessage from "../OriginalMessage";
import OpenEmailIcon from "../../Icons/OpenEmailIcon";
import WithTooltip from "../../Common/WithTooltip";
import ContactDetailsPopover from "../../ContactBook/ContactDetailsPopover";
import ConfirmDialog from '../../Common/ConfirmDialog';

function ChannelDetails(props: { channel: any, handleOpenSendReminderDialog: () => void, reminder: any, handleOpenOffer: () => void, handleFix: (() => Promise<void>) | any, handleReject: (() => Promise<void>) | any }) {
  return <div className={cx(s.details)}>
    {props.channel.companyCargoSide && !props.channel.myCargoSide ? <CompanyAlreadyTrading /> : null}
    <div
      className={s.text}
    >
      <div
        className={s.title_ref_in_toggle}
      >
        <OriginalMessageContainer
          inChannel
          refNo={props.channel.refNo}
          _id={props.channel._id}
          canViewOriginalMail={props.channel.canViewOriginalMail}
          canOpenReminder={props.channel.canOpenReminder}
          handleOpenSendReminderDialog={props.handleOpenSendReminderDialog}
          reminder={props.reminder}
        />
      </div>
      {props.channel.preferredCargo ?
        <div
          className={s.preferences}
        >
          <h4>Preferences</h4>
          <p>{props.channel.preferredCargo}</p>
        </div>
        : null
      }
      {props.channel.status.message ?
        <div
          className={s.preferences}
        >
          <h4>Message</h4>
          <p>{props.channel.status.message}</p>
        </div>
        : null
      }
      {props.channel.status.additionalMessage ?
        <div
          className={s.preferences}
        >
          <h4>Additional message</h4>
          <p>{props.channel.status.additionalMessage}</p>
        </div>
        : null
      }
      {props.channel.status.attachments && !props.channel.status.bid ?
        <div
          className={s.preferences}
        >
          <Attachments
            isNew={false}
            attachments={props.channel.status.attachments}
          />
        </div>
        : null
      }
      <div>
        {props.channel.status.bid &&
          <div className={s.wrapper_bidding_counter_offer}>
            <OfferInMainDeck
              bid={props.channel.status.bid}
              cargo={props.cargo}
              isVesselSide
            />
          </div>
        }
        {props.channel.status.status === "bid" && !props.channel.expired && props.channel.myCargoSide && <BidButtons
          handleOpenOffer={props.handleOpenOffer}
          handleFix={props.handleFix}
          handleReject={props.handleReject}
        />}
      </div>

    </div>
  </div>;
}

class Channel extends PureComponent {

  static defaultProps = {
    basePath:'/main-deck/general/',
    channelsChanged: () => undefined
  }
  constructor(props) {
    super(props);
    if(props.channelsCount === 1) {
      this.state.opened = true;
    }
  }
  state = {
    opened:false,
  };

  handleToggle = ()=>{
    if (!this.props.channel.haveDetails) return;
    this.setState({opened: !this.state.opened});
  };
  handleOpenOffer = () => {
    this.setState({offerDialogOpened:true});
  };
  handleCloseOffer = () => {
    this.setState({
      confirmDialog: (<ConfirmDialog
        title="DATA IS NOT SAVED"
        open
        handleClose={(confirm) => {
          this.setState({ confirmDialog: undefined });
          if (confirm) {
            this.setState({ offerDialogOpened: false });
          }
        }}
      >
        <span>Are you sure you want to leave this page?</span>
      </ConfirmDialog>),
    });
  };

  handleSendOffer = async (offer) => {
    const bid =  this.props.channel?.status?.bid;
    let res;
    if(bid) {
      offer.bidId = bid._id;
      res = await this.props.sendOffer(offer, false);
    }else {
      res = await this.props.sendOffer(offer, false);
    }
    this.setState({offerDialogOpened:false});
    this.refresh();

  }

  changeStatus = status => {
    this.props.changePositionStatus(this.props.channel._id, status);
  }

  handleFix = async () => {
    const bid =  this.props.channel?.status?.bid;
    await this.props
      .fixOffer({
        bidId: bid._id,
        cargoRequest: bid.cargoRequest,
        bid,
      });
    this.refresh();
  }

  handleReject = async () => {
    const bid =  this.props.channel.status?.bid;
    this.props
      .rejectOffer({
        bidId: bid._id,
        cargoRequest: bid.cargoRequest,
      });
    this.refresh();
  };

  componentWillUnmount() {
    this.unmounting = true;
  }

  refresh = async () => {
    if(this.unmounting) {
      return;
    }
    history.replace(window.location.pathname);
  };

  handleSendReminder = async (body) => {
    await this.props.sendReminder(body);
    this.refresh();
    this.handleCloseSendReminderDialog();
  }
  handleCancelReminder = async (reminder) => {
    await this.props.cancelReminder(reminder);
    this.refresh();
    this.handleCloseSendReminderDialog();
  }

  handleDeleteChannel = async () => {
    this.props.toggleDialogMessage({
      level: 'error', message: 'Are you sure you want to delete position?', dismissAble: true, action: {
        callback: async (decision) => {
          if(decision){
            try {
              await VesselApi.deletePositionById(this.props.vessel._id, this.props.channel._id);
              this.refresh();
              this.props.channelsChanged()
            } catch (error) {
              error = {
                messages: error?.messages || ["Position can't be deleted. Please, refresh the page"]
              }
              this.props.showErrorMessage(error?.messages[0]);
            }
          }
        },
      },
    })

  }

  handleOpenSendReminderDialog = () => {
    this.setState({sendReminderDialogOpened: true});
  }
  handleCloseSendReminderDialog = () => {
    this.setState({sendReminderDialogOpened: false});
  }

  render(){
    const channel = this.props.channel;
    const { vessel, cargo } = this.props;
    const status = channel.status || {};
    const canChangeFreight = channel.canChangeFreight;
    const reminder = status.reminder;
    const canSendReminder = channel.canSendReminder;
    let companyTitle;
    if(channel.isClientRequest && channel.realUser) {
      companyTitle=channel.realUser.email;
    }else {
      companyTitle=handleCapitalize(channel.company?.name)
    }
    return (
      <div style={{width: '100%'}}>
        <div className="hidden__id">{channel._id}</div>
        <div
          key={channel._id}
          className={cx(s.headers, s.channel, s.background, this.state.opened && s.opened, status.bid && s.bidding, !channel.haveDetails && s.without_details)}
        >
          <div className={s.left_part}>
            <div className={s.position}>
              {channel.haveDetails || channel.canViewOriginalMail ?
                <div
                  onClick={this.handleToggle}
                  className={cx(s.icon, s.toggle_button)}
                >
                  <ArrowDownIcon />
                </div>
                : null}
              <div
                className={cx(s.part_row, s.open, s.header)}
              >
                <div>
                  <div
                    title={channel.open.name}
                    className={cx('ellipsis', s.name_port)}
                  >
                    <span className={s.open_ex_dd}>
                      <PortLink port={channel.open} />
                      <DisplayMaintenance maintenance={channel?.maintenance} />
                    </span>
                    <div className={cx(s.date)}>{dateRange(channel.open.from, channel.open.until)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx(s.part_row, s.header, s.company)}>
            <span className={s.name_company}>
              <BlurEmailIfNoSubscription title={companyTitle}>
                {channel.isClientRequest && channel.realUser ?
                  <ContactDetailsPopover email={channel.realUser.email}>
                    <a
                      title={companyTitle}
                      href={`mailto:${channel.realUser.email}`}
                    >{channel.realUser.email}</a>
                  </ContactDetailsPopover>
                  : handleCapitalize(channel.company?.name)
                }
               </BlurEmailIfNoSubscription>
              </span>
              <div className={s.role_chat}>
                {channel.threadId ? (
                    <WithTooltip
                      horizontalPosition="left"
                      whiteArrow={false} tip={
                      <div className={cx("tooltip-default", "dark")} >Open Message</div>
                    }>
                      <OpenEmailIcon
                        className={cx(s.open_thread_icon, "pointer")}
                        onClick={this.props.openMailThread}
                      />
                    </WithTooltip>
                  )
                   : null}
                <div className={cx(s.part_row, s.chat_icon)}>
                  <CreateChatWrapper
                    fill
                    addedBy={channel.realUser?._id || channel.addedBy}
                    refNo={channel.refNo}
                    hideForSelf={true}
                  />
                </div>
                <div className={cx(s.part_row, s.role)}>{(channel.isClientRequest && 'Received') || channel.role}</div>
              </div>
            </div>
            {channel.status && channel.status.bid && channel.companyCargoSide ? (
              <div
                onClick={this.handleToggle}
                data-status={channel.status.status}
                className={cx(s.right_part, s.right_part_steps, sm.wrapper_status, sm[channel.status.status])}
              >
                <div className={sm.sum}>
                  {canChangeFreight && (<span
                    className={s.change_data_channels}
                    onClick={this.handleOpenOffer}
                  >
                  Change
                  </span>)}
                  <StatusText status={channel.status} /> {number(channel.status.bid.trading.changedRequest.freight.value, channel.status.bid.trading.changedRequest.freight.currency)}
                </div>
                <div>
                  <span><Timer timeToEnd={channel.status.end} /></span>
                  <span style={{transform: `translateX(8px)`}}>
                <ArrowIcon className={sm.arrow} />
                </span>

                </div>
              </div>
            ) : (
              <div
                onClick={this.handleToggle}
                className={cx(s.right_part, s.links)}
              >
                {/*{channel.canFreightRequest && <button onClick={this.handleSelectChannels}>Freight Request</button> }*/}
                {!vessel?.isVesselOwnerInSameCompany && !vessel?.isUserOwner && channel.canFreightRequest && (channel.realUser?.companyId !== channel.company?._id) &&
                  <ConditionalBlur isBlurred={!channel?.userInWhiteList && !channel?.isClientRequest } dontBlur><Link to={`${this.props.basePath}${this.props.cargo._id}/${this.props.vessel._id}/request?channel=${channel._id}`}>Request Freight</Link></ConditionalBlur>}
                {!vessel?.isVesselOwnerInSameCompany && !vessel?.isUserOwner && channel.canOfferHire && (!isFreightRequest(channel.status.status) || channel.canFreightRequest) && (channel.realUser?.companyId !== channel.company?._id) &&
                  <ConditionalBlur isBlurred={!channel?.userInWhiteList && !channel?.isClientRequest } dontBlur><Link to={`/main-deck/${this.props.cargo ? 'general' : 'tc'}/${this.props.cargo ? this.props.cargo._id : (this.props.orderId || '-')}/${this.props.vessel._id}/tc-offer?channel=${channel._id}`}>Offer
                    hire</Link></ConditionalBlur>}
                {(isFreightRequest(channel.status.status) && !channel.canFreightRequest) && (
                  <a>Freight Request {channel.status.status === 'freight-in' ? 'Received' : 'Sent'} </a>
                )}

              </div>
            )}
            <div style={{marginRight: channel?.canDelete ? '4px' : '40px'}}>
              <ConditionalBlur isBlurred={this.props.vessel?.userGroupsBlurred}>
                <ChannelStatus
                  labelStyle={{fontSize: '12px'}}
                  labelClass={sm.dark_channel_status}
                  channelId={this.props.channel?._id}
                  handleChange={this.changeStatus}
                  vesselStatuses={vessel => vessel?.userGroups?.groups}
                  vessel={this.props.vessel}
                  value={this.props?.channel?.userStatus?.status}
                ></ChannelStatus>
              </ConditionalBlur>
            </div>
            {
              channel.canDelete
                ? (
                  <span
                    onClick={this.handleDeleteChannel}
                    style={{
                      marginRight: '12px',
                      marginTop: '-2px',
                      cursor: 'pointer',
                      padding: '0px 4px',
                      borderRadius: '50%',
                      background: '#E6E6E6'
                    }}
                  >
                  <CloseIcon style={{width: 16, height: 16}}></CloseIcon>
                </span>
                )
                : null
            }

          </div>


          {/*<div className={cx(s.right_part, s.right_part_steps, s.without_arrow, sm.wrapper_status, sm.fix)}>
                <div className={sm.sum}>
                  <span className={s.offer_info_channels}>Offer:</span>
                  <span className={s.change_data_channels}>Change Rate</span>
                  Counter offer: $ 56.00
                </div>
                <span>7h 36m</span>
              </div>*/}
          {this.state.opened ? (
            <ChannelDetails
              channel={channel}
              handleOpenSendReminderDialog={this.handleOpenSendReminderDialog}
              reminder={reminder}
              handleOpenOffer={this.handleOpenOffer}
              handleFix={this.handleFix}
              handleReject={this.handleReject}
              cargo={cargo}
            />
          ) : null}

          {this.state.offerDialogOpened && (
              <OfferFreightDialog
                handleSend={this.handleSendOffer}
                user={this.props.user}
                handleClose={this.handleCloseOffer}
                cargo={this.props.cargo}
                bid={status.bid || {}}
                vessel={this.props.vessel}
                channel={channel}
              />
          )}

          {this.state.sendReminderDialogOpened ?
            <SendReminderDialog
              user={this.props.user}
              cargo={this.props.cargo}
              bid={status.bid}
              vesselRequest={channel}
              handleClose={this.handleCloseSendReminderDialog}
              handleSendReminder={this.handleSendReminder}
              reminder={reminder}
              handleCancelReminder={this.handleCancelReminder}
            />
            : null}
          {this.state.confirmDialog}
          {/*{this.state.offerDialogOpened ? <OfferFreightDialog handleSend={this.handleSendOffer} handleClose={this.handleCloseOffer} cargo={this.props.cargo} trading={status.bid?.trading || {}} vessel={this.props.vessel}  /> : null}*/}
        </div>
      </div>
    );
  }
}

const showErrorMessage = text => ({
  type: 'MESSAGE',
  payload: text
})

export default connect(state => ({ user: state.login.user }),{ sendOffer, rejectOffer, fixOffer, toggleDialogMessage, showErrorMessage, sendReminder, cancelReminder })(Channel);

function BidButtons({handleOpenOffer, handleFix, handleReject}){
  return (
    <div className={s.wrapper_buttons_offer}>
      <RaisedButton
        primary
        styles="small"
        label="Reject"
        onClick={handleReject}
        style={{
          minWidth: '72px',
          marginRight: '8px',
          borderRadius: '15px',
        }}
        buttonStyle={{
          backgroundColor: '#ffffff',
          border: '1px solid #E6E6E6',
          borderRadius: '15px',
        }}
        labelStyle={{
          paddingLeft: '12px',
          paddingRight: '12px',
          fontSize: '11px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: '#787878',
        }}
      />
      <RaisedButton
        primary
        styles="small"
        label="Fix"
        onClick={handleFix}
        style={{
          minWidth: '72px',
          marginRight: '8px',
          borderRadius: '15px',
        }}
        buttonStyle={{
          backgroundColor: '#ffffff',
          border: '1px solid #E6E6E6',
          borderRadius: '15px',
        }}
        labelStyle={{
          paddingLeft: '12px',
          paddingRight: '12px',
          fontSize: '11px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: '#787878',
        }}
      />
      <RaisedButton
        primary
        styles="small"
        label="Counter offer"
        onClick={handleOpenOffer}
        style={{
          borderRadius: '15px',
        }}
        buttonStyle={{
          borderRadius: '15px',
        }}
        labelStyle={{
          paddingLeft: '12px',
          paddingRight: '12px',
          fontSize: '11px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      />
    </div>
  )
}

class OriginalMessageContainer extends PureComponent {
  state = {};

  render() {
    let reminderLabel = 'Send reminder';
    if(this.props.reminder) {
      reminderLabel = `Reminder ${this.props.reminder.status}`;
    }
    return (<div className={s.original_message_container}>
      <BlurIfNoSubscription dontBlur>
        <div className={s.title}><span>
          {this.props.canOpenReminder && <span onClick={this.props.handleOpenSendReminderDialog}>
            <span className="material-icons" style={{ fontSize: "16px", color: "#B2B2B2" }}>
              notifications
            </span>
            <p>{reminderLabel}</p>
          </span>}
          </span>
          Ref: {this.props.refNo}
        </div>
      </BlurIfNoSubscription>
      {this.props.canViewOriginalMail ?
        <div className={s.original_message}>
          <OriginalMessage _id={this.props._id} apiLoad={VesselApi.getOriginalMail.bind(VesselApi)} />
        </div>
        : null}
    </div>)
  }
}


function CompanyAlreadyTrading () {
  return (<div className={s.already_trading}>
    User from your company already trading with this Channel.
  </div>);
}

const statusTexts = {
  fix:'Fixed',
  fixed:'Fixed',
  reject:'Rejected',
  rejected: 'Rejected',
  closed: 'Rejected',
  removed: 'Removed',
  expired: 'Expired',
  freight: 'Freight Request',
  'freight-in': 'Freight Request',
  'freight-out': 'Freight Request',
}

function StatusText({status}) {
  const _status = status.status || status.state;
  let statusText = statusTexts[_status];
  if(!statusText && status.end < Date.now()) {
    statusText = 'Expired';
  }
  return <span>{statusText}</span>
}
