import React from "react";
import Copy from "material-ui/svg-icons/content/content-copy.js";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
function CopyToClipboard(props) {
  function copyToClipboard(ev) {
    const name = props.text;
    const { dispatch } = props;
    if (!navigator.clipboard) {
      const textArea = document.createElement("textarea");
      textArea.value = name;
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      let messageObj = {};
      try {
        var successful = document.execCommand("copy");
        messageObj = {
          title: "Success copy",
          message: "URL has been copied to clipboard!",
          level: "success",
          _id: new Date().getMilliseconds(),
          autoDismiss: 3
        };
      } catch (err) {
        messageObj = {
          title: "Error",
          message: "Error copying URL!" + err,
          level: "error",
          _id: new Date().getMilliseconds(),
          autoDismiss: 3
        };
      }
      dispatch({
        type: "MESSAGE",
        payload: messageObj
      });
      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard
      .writeText(name)
      .then(() =>
        dispatch({
          type: "MESSAGE",
          payload: {
            title: "Success copy",
            message: "URL has been copied to clipboard!",
            level: "success",
            _id: new Date().getMilliseconds(),
            autoDismiss: 3
          }
        })
      )
      .catch(err =>
        dispatch({
          type: "MESSAGE",
          payload: {
            title: "Error",
            message: "Error copying URL!" + err,
            level: "error",
            _id: new Date().getMilliseconds(),
            autoDismiss: 3
          }
        })
      );
  }
  let style = {};
  if (props.noPadding) {
      style = {
        padding: 0,
        width: 'auto',
        height: 'auto',
        marginLeft: '10px'
      }
  }
  return (
    <IconButton
      onClick={copyToClipboard}
      style={style}
      iconStyle={{ width: 18, height: 18 }}
    >
      <Copy></Copy>
    </IconButton>
  );
}

export default connect(null, dispatch => ({ dispatch }))(CopyToClipboard);
