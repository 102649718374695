exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3qdFt {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box; }\n  ._3qdFt > div {\n    border-radius: 8px !important;\n    padding-bottom: 10px; }\n  ._3qdFt > div > div:first-child {\n      padding: 0 !important; }\n  ._3qdFt > div > div:first-child svg {\n        z-index: 10; }\n  ._3qdFt._2E9ff > div {\n    padding-top: 8px !important; }\n  ._35Isu > div {\n  border-radius: 8px !important; }\n  ._3nF9P {\n  width: 50vw !important;\n  -webkit-transition: none !important;\n  transition: none !important;\n  padding: 0 !important; }\n  ._3nF9P._2Oem_ {\n    left: 0 !important; }\n  ._3nF9P._2rmEI {\n    left: 50% !important; }\n  ._3nF9P._2rmEI > div:first-child:after {\n      -webkit-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);\n              box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2); }\n  ._3nF9P > div:first-child {\n    height: 100%;\n    -webkit-clip-path: inset(0);\n            clip-path: inset(0); }\n  ._3nF9P > div:first-child:after {\n      content: \" \";\n      height: 100%;\n      width: 0px;\n      position: absolute;\n      left: 0;\n      top: 0;\n      z-index: 1500; }\n  ._3nF9P > div > div:first-child {\n    background: white;\n    width: 100% !important;\n    max-width: 100% !important;\n    height: 100% !important;\n    margin: 0 !important;\n    -webkit-transition: none !important;\n    transition: none !important;\n    -webkit-transform: none !important;\n            transform: none !important; }\n  ._3nF9P > div > div:first-child > div:first-child {\n      border-radius: 0 !important;\n      -webkit-box-shadow: none !important;\n              box-shadow: none !important;\n      height: 100% !important; }\n  ._3nF9P > div > div:first-child > div:first-child > div {\n        height: 100% !important;\n        max-height: 100% !important;\n        padding: 0 !important;\n        font-size: inherit !important; }\n", ""]);

// Exports
exports.locals = {
	"dialogContent": "_3qdFt",
	"empty_title": "_2E9ff",
	"dialog_container": "_35Isu",
	"rightHalfDialog": "_3nF9P",
	"left": "_2Oem_",
	"right": "_2rmEI"
};