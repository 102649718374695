import React, { Component } from 'react';
import styled from "styled-components";
import {MinusButton, PlusButton} from "../Common/AddButton";
import MenuItem from "material-ui/MenuItem";
import FormsySelect from '../Common/Select';
import PhoneNumberInput from "./PhoneNumberInput";
import NarrowSelect from "../NewInputs/NarrowSelect";
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";


const StyleWrapper = styled.div`
  >div {
    display: flex;
    align-items: center;
    >div:nth-child(2) {
      flex: 1;
      margin: 0 12px;
    }
    >div:nth-child(1) {
      width: 90px;
    }
  }
`;

export const phoneTypeMap = {
  landline: "Phone",
  fax: "Fax",
  direct: "Dir",
  mobile: "Mob",
  whatsapp: "Whatsapp",
  viber: "Viber",
  wechat: "Wechat",
  telegram: "Telegram",
  botim: "Botim",
  zalo: "Zalo",
  ice: "Ice",
  qq: "QQ",
  kakao: "Kakao",
  yahoo: "Yahoo",
}
export const phoneTypes = Object.keys(phoneTypeMap).map((id) => ({ id, name: phoneTypeMap[id] }));

 function PhoneTypeSelect(props) {

  return (<NarrowSelect
    floatingLabelText={'Type'}
    requiredError="required"
    fullWidth
    autoWidth
    {...props}
  >
    {phoneTypes.map(p => (<MenuItem
      key={p.id}
      value={p.id}
      primaryText={p.name}
    />))}
  </NarrowSelect>);
}

class ContactPhonesEdit extends Component {

  handleChangePhone = (i, e, value) => {
    const phones = [...this.props.phones];
    phones[i].number = value;
    this.props.onChange(null, phones);
  }
  handleChangeType = (i, e, value) => {
    const phones = [...this.props.phones];
    phones[i].type = value;
    this.props.onChange(null, phones);
  }
  handleRemovePhone = (i) => {
    const phones = this.props.phones.filter((phone, j) => j !== i);
    this.props.onChange(null, phones);
  }
  handleAddPhone = () => {
    const phones = [...this.props.phones, { type: "landline", number: "" }];
    this.props.onChange(null, phones);
  }

  render() {
    return (
      <StyleWrapper>
        {this.props.phones.map((phone, i) => {
          return (<div>
            <div>
              <PhoneTypeSelect
                name={`phones[${i}][type]`}
                value={phone.type}
                onChange={this.handleChangeType.bind(this, i)}
              />
            </div>
            <div>
              <NarrowFormsyText
                name={`phones[${i}][number]`}
                validations="minLength:7"
                validationErrors={{
                  minLength: 'at least 7 characters',
                }}
                onChange={this.handleChangePhone.bind(this, i)}
                value={phone.number}
                fullWidth
              />
            </div>
            <div>{i === 0 ? <PlusButton onClick={this.handleAddPhone} /> : <MinusButton onClick={this.handleRemovePhone.bind(this, i)} />}</div>
          </div>)
        })}
      </StyleWrapper>
    );
  }
}

export default ContactPhonesEdit;
