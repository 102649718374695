import React from 'react';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import Opts from './Opts';

function Detention(props) {
  const {
    namePrefix = "detentionOpts",
    onChange = () => {},
    detention,
    detentionOpts = {},
  } = props;

  return (
    <div className="row">
      <div className="col-3">
        <NarrowFormsyText
          name="detention"
          value={detention || ""}
          hintText="Detention"
          validations="isNumeric,gt0"
          validationErrors={{
            isNumeric: "only numbers",
            gt0: "must be greater than 0"
          }}
          floatingLabelText="Detention"
          fullWidth
          onBlur={(_, val) => val === detention ? null : onChange("detention")(val)}
        />
      </div>
      <Opts
        onChange={onChange(namePrefix)}
        namePrefix={namePrefix}
        opts={detentionOpts}
        termsEnabled={false}
      />
    </div>
  );
}
export default Detention;
