import { indexByAsArray } from '../../../core/utils';
import mergeTerms from '../../Cargo/mergeTerms';
import {cloneDeep, uniq} from "lodash";
import {v4} from "uuid";


const DEFAULTS = {
  totalWeightDeviation: { value: 0, term: ''},
  totalVolumeDeviation: { value: 0, term: ''},
  freight: { currency: 'USD', term: 'per mt', method: 'fios' },
  demurrageOpts: { currency: 'USD', timeUnit: 'perDay', terms: '' },
  detentionOpts: { currency: 'USD', timeUnit: 'perDay', terms: '' },
  timeForReply: 24,

}

function fixLdRatesForOffer(cargoRequest){
  if (!cargoRequest.ldRates.loading.quantity) {
    cargoRequest.ldRates.loading.quantity = undefined;
  }
  if (!cargoRequest.ldRates.discharging.quantity) {
    cargoRequest.ldRates.discharging.quantity = undefined;
  }
  if (cargoRequest.ldRates.loading.cqd !== true && cargoRequest.ldRates.loading.cqd !== false && cargoRequest.ldRates.loading.cqd !== null) {
    cargoRequest.ldRates.loading.cqd = false;
  }
  if (cargoRequest.ldRates.discharging.cqd !== true && cargoRequest.ldRates.discharging.cqd !== false && cargoRequest.ldRates.discharging.cqd !== null) {
    cargoRequest.ldRates.discharging.cqd = false;
  }
  return cargoRequest;
}

export function prepareOffer(cargo, bid, vessel,user = {}, contractAsId = true, opts = {}){
 let offer = { incoterms: {}, ...cargo };
  if (opts.forOffer) {
    fixLdRatesForOffer(offer);
  }
 const trading = bid?.trading;
 if (trading && trading.changedRequest) {
   if (!trading.originalRequest.cargo) {
     trading.originalRequest.cargo = cloneDeep(cargo.cargo);
   }
   if (!trading.changedRequest.cargo) {
     trading.changedRequest.cargo = cloneDeep(cargo.cargo);
   }
   mergeCargoDetails(trading.changedRequest);
   mergeCargoDetails(trading.originalRequest);
   const changedRequest = { incoterms: {}, ...trading.changedRequest };
   if (opts.forOffer) {
     fixLdRatesForOffer(changedRequest);
   }
   changedRequest.terms = mergeTerms(
     trading.changedRequest.terms,
     bid.status!=='fixed' ? trading.originalRequest.terms : trading.changedRequest.terms,
   );
   trading.originalRequest.terms = mergeTerms(
     trading.originalRequest.terms,
     trading.originalRequest.terms,
   );
   if (opts.forOffer) {
     fixLdRatesForOffer(trading.originalRequest);
   }
   changedRequest.terms = { ...changedRequest.terms };
   changedRequest.terms.default = changedRequest.terms.default.slice(0);
   changedRequest.message = trading.message;
     const terms = [...changedRequest.terms.default];
     const prTerms = trading.originalRequest.terms.default;
     for (let i = 0; i < prTerms.length; i++) {
       let t = prTerms[i];
       if (t && !terms[i]) {
         terms[i] = t;
       }
     }
   offer = {...offer, ...changedRequest };
   trading.originalRequest.terms.default = indexByAsArray(
     trading.originalRequest.terms.default,
     'index',
   );
   if(bid.status==='fixed'){
     offer.terms.default = offer.terms.default.filter(t=> t.checked);
   }

 }
 if (!trading && cargo.frChannel?.status?.additionalMessage) {
  offer.additionalMessage = cargo.frChannel?.status?.additionalMessage;
 }
 if (!trading && cargo.frChannel?.status?.attachments) {
  offer.attachments = cargo.frChannel?.status?.attachments;
 }
  offer.terms.default = indexByAsArray(offer.terms.default, 'index');
  //offer.contract = (contractAsId && offer.contract && offer.contract._id) || offer.contract;
  offer = {...DEFAULTS, ...findDeviations(offer), ...findChartererAndOwner(offer, vessel, user), ...offer};
  let previousValues = JSON.parse(JSON.stringify(offer));
  if(trading && bid.status!=='fixed') {
    previousValues = { ...previousValues, incoterms: {}, ...trading.originalRequest };
    //offer.contract = (contractAsId && previousValues.contract && previousValues.contract._id) || previousValues.contract;
  }
  offer.files = cargo.files;
  if(bid.freights) {
    offer.freights = bid.freights;
  }
  if(bid.charges) {
    offer.charges = bid.charges;
  }
  if(cargo.freights && !bid.freights) {
    offer.freights = cargo.freights.map(f => ({ prev: f, curr: f, key: f.key }));
    if(cargo.charges) {
      offer.charges = cargo.charges.map(c => ({ prev: c, curr: c, key: c.key }));
    }
  }
  if (!offer.demurrage) {
    offer.demurrage = undefined;
  }
  if (!offer.detention) {
    offer.detention = undefined;
  }
  if (!previousValues.demurrage) {
    previousValues.demurrage = undefined;
  }
  if (!previousValues.detention) {
    previousValues.detention = undefined;
  }
  if (!Array.isArray(offer.attachments)) {
    offer.attachments = [];
  }
  if (offer.cargo.length && offer.cargo[0].typeOfTransportation === 'wetbulk') {
    offer.incoterms = {};
    previousValues.incoterms = {};
  }
 return { offer, previousValues };
}

function findDeviations(offer) {
  let totalWeightDeviation = offer.totalWeightDeviation;
  let totalVolumeDeviation = offer.totalVolumeDeviation;
  const ret = {}
  if(!totalWeightDeviation && offer.cargo[0].packing.pack.unitsWeight && offer.cargo[0].packing.pack.unitsWeight.deviation) {
    totalWeightDeviation = {
      value: offer.cargo[0].packing.pack.unitsWeight.deviation,
      term: offer.cargo[0].packing.pack.unitsWeight.term,
    };
  }
  if(!totalVolumeDeviation && offer.cargo[0].packing.pack.unitsVolume && offer.cargo[0].packing.pack.unitsVolume.deviation) {
    totalVolumeDeviation = {
      value: offer.cargo[0].packing.pack.unitsVolume.deviation,
      term: offer.cargo[0].packing.pack.unitsVolume.term,
    };
  }
  if(totalWeightDeviation) {
    ret.totalWeightDeviation = totalWeightDeviation;
  }
  if(totalVolumeDeviation) {
    ret.totalVolumeDeviation = totalVolumeDeviation;
  }
  return ret;

}

function findChartererAndOwner(offer, vessel, user = {}){
  let charterer  = offer.charterer || offer.account || undefined;
  let owner  = offer.owner || user.company || (vessel && vessel.addedBy && vessel.addedBy.company);
  return { charterer, owner }
}

export function mergeCargoDetails(request){
  if (request.cargoDetails?.length && request.cargo) {
    request.cargo.forEach((c, i) => {
      c.additionalCargoDetails = request.cargoDetails[i]?.additionalCargoDetails || "";
    });
  }
}

export function prepareOfferForView(cargo, bid, vessel,user = {}, contractAsId = true, opts = {}){
  const { offer, previousValues } = prepareOffer(cargo, bid, vessel,user = {}, contractAsId = true, opts = {});
  if (!offer.demurrage) {
    offer.demurrage = undefined;
    offer.demurrageOpts = { timeUnit: "", terms: "" };
  }
  if (!previousValues.demurrage) {
    previousValues.demurrage = undefined;
    previousValues.demurrageOpts = { timeUnit: "", terms: "" };
  }
  if (!offer.detention) {
    offer.detention = undefined;
    offer.detentionOpts = { timeUnit: "", terms: "" };
  }
  if (!previousValues.detention) {
    previousValues.detention = undefined;
    previousValues.detentionOpts = { timeUnit: "", terms: "" };
  }
  return { offer, previousValues };
}

export const mapEmailsToChips = (emails = []) => {
  return emails.map(email => ({ _id: v4(), email }));
}

export const mapInitialEmails = (replyTo = [], cc = [], bcc = []) => {
  const values = {
    cc: mapEmailsToChips(uniq(cc)),
    bcc: mapEmailsToChips(bcc),
    replyTo: mapEmailsToChips(uniq(replyTo.filter(e => e))),
  };
  return values;
};

function getMySide(user, bid) {
  if (bid?.cargoSide) {
    if (user._id === bid.cargoSide.user || user.company.id === bid.cargoSide.company) {
      return 'cargoSide';
    } else {
      return 'vesselSide';
    }
  } else {
    return "vesselSide";
  }
}
export function isOfferToMarket(user, cargoRequest, vesselRequest, bid) {
  const mySide = getMySide(user, bid);
  let entity = cargoRequest;
  switch (mySide){
    case 'cargoSide':
      entity = vesselRequest;
      break;
    case 'vesselSide':
      entity = cargoRequest;
      break;
  }
  return entity.computedChannel === 'market';
}
