import { escapeRegExp } from '../../core/utils';

export function filterTCOrders(tcList) {
  let res = {};
  try {
    res = {
      ...tcList
    }
    if (res.filters.search) {
      const q = escapeRegExp(res.filters.search);
      res.filtered = res.data.filter(c => c.deliveryPlaces.findIndex(d => q.test(d.port.name.toLowerCase())) >= 0
        || c.redeliveryPlaces.findIndex(r => q.test(r.port.name.toLowerCase())) >= 0
        || q.test(c.refNo));
    }
    else {
      res.filtered = res.data;
    }
  } catch (error) {
    console.log(error);
    res.filtered = res.data;
  } finally {
    return res;
  }
}

export function filterCargo(filters = {}, cargo) {
  filters.channels = filters.channels || {mine:true, broker: true, market:true};
  const readinessDate = filters.readinessDate && new Date(filters.readinessDate);
  const cancellingDate = filters.cancellingDate && new Date(filters.cancellingDate);
  const weightMin = ((1000 * filters.weightMin)) || 0;
  const weightMax = ((1000 * filters.weightMax)) || Number.MAX_VALUE;
  const volumeMin = (filters.volumeMin) || 0;
  const volumeMax = (filters.volumeMax) || Number.MAX_VALUE;
  const loadingPort = filters.loadingPorts?.length && filters.loadingPortsInfo?.childs;
  const unloadingPort = filters.unloadingPorts?.length && filters.unloadingPortsInfo?.childs;
  const cargoNames = filters.cargoNames;
  const cargoTypes = filters.cargoTypes;
  const category = filters.transportation;
  if(!filters.channels.mine && (cargo.computedChannel === 'company')) {
    return null;
  }
  if(!filters.channels.broker && (cargo.computedChannel === 'broker')) {
    return null;
  }
  if(!filters.channels.market && (cargo.computedChannel === 'market')) {
    return null;
  }
  if (filters.search) {
    return cargo;
  }
  if (
    cargo.totalValues.weight < weightMin ||
    cargo.totalValues.weight > weightMax
  ) {
    return null;
  }
  if (
    cargo.totalValues.volume < volumeMin ||
    cargo.totalValues.volume > volumeMax
  ) {
    return null;
  }
  if (
    readinessDate &&
    cargo.readinessDate.getTime() < readinessDate.getTime()
  ) {
    return null;
  }
  if (
    cancellingDate &&
    cargo.cancellingDate.getTime() > cancellingDate.getTime()
  ) {
    return null;
  }

  if (loadingPort?.length) {
    const loadingPortsId = cargo.loading.map(({oneOf}) => oneOf.map(p => p.port._id)).reduce((acc,val) => acc.concat(val),[]);
    if(!loadingPortsId.some(_id => loadingPort.includes(_id))) {
      return null;
    }

  }

  if (unloadingPort?.length) {
    const unloadingPortsId = cargo.unloading.map(({oneOf}) => oneOf.map(p => p.port._id)).reduce((acc,val) => acc.concat(val),[]);
    if(!unloadingPortsId.some(_id => unloadingPort.includes(_id))) {
      return null;
    }

  }
  if (category && cargo.category && !category.some(type => cargo.category.indexOf(type) !== -1)) {
    return null;
  }

  /* if (loadingPort && cargo.loadingPort._id !== loadingPort._id &&
     ((loadingPort.childs || []).indexOf(cargo.loadingPort._id) === -1)) {
     return null;
   }
   if (unloadingPort && cargo.unloadingPort._id !== unloadingPort._id &&
     ((unloadingPort.childs || []).indexOf(cargo.unloadingPort._id) === -1)) {
     return null;
   }*/
  if (cargoTypes && cargo.cargoType && cargoTypes.indexOf(cargo.cargoType._id) === -1) {
    return null;
  }
  if (cargoNames?.length && cargo.cargo) {
    const cargoName = cargo.cargo.join().toLocaleLowerCase();
    if (!cargoNames.some(cn => cargoName.indexOf(cn.toLocaleLowerCase()) !== -1)) {
      return null;
    }

  }

  return cargo;
}
/** @typedef {Object} VesselFilter
 * @property {Boolean} gear
 * @property {Boolean} gearCheckbox
 * @property {Number} maxDWT
 * @property {Boolean} maxDWTCheckbox
 * @property {Boolean} minDWTCheckbox
 * @property {Boolean} onlyMy
 * @property {Boolean} onlyMyCheckbox
 * @property {String} openFrom
 * @property {Boolean} openFromCheckbox
 * @property {Array} openPort
 * @property {Boolean} openPortCheckbox
 * @property {String} openUntil
 * @property {Boolean} openUntilCheckbox
 * @property {String} type
 * @property {Boolean} typeCheckbox
 */
/**
 *
 * @param {VesselFilter} filters
 * @param vessel
 */
export function filterVessel(filters = {}, vessel) {
  const openFrom = filters.openFromCheckbox && filters.openFrom && new Date(filters.openFrom);
  const openUntil = filters.openUntilCheckbox && filters.openUntil && new Date(filters.openFrom);
  const gear = filters.gearCheckbox && filters.gear;
  const onlyMy = filters.onlyMyCheckbox && filters.onlyMy;
  let openPort = filters.openPortCheckbox && filters.openPort;
  const type = filters.typeCheckbox && filters.type;
  const minDWT = (filters.minDWTCheckbox && filters.minDWT) || 0;
  const maxDWT = (filters.maxDWTCheckbox && filters.maxDWT) || Number.MAX_VALUE;

  if (vessel.dwt < minDWT || vessel.dwt > maxDWT) {
    return null;
  }
  if (type && vessel.type !== type) {
    return null;
  }
  if (onlyMy && !vessel.addedByUser) {
    return null;
  }
  if (openPort && filters.openPortInfo && filters.openPortInfo.childs) {
    openPort = filters.openPortInfo.childs;
  }
  if (openPort && (!vessel.open || !openPort.includes(vessel.open._id))) {
    return null;
  }

  /*if (openPort &&
    (vessel.open.where.indexOf(openPort._id) === -1 &&
      vessel.open._id !== openPort._id &&
      ((openPort.childs || []).indexOf(vessel.open._id) === -1))) {
    return null;
  }*/

  if (gear && vessel.gear.length === 0) {
    return null;
  }
  if (openFrom && (!vessel.open || vessel.open.from.getTime() < openFrom.getTime())) {
    return null;
  }
  if (openUntil && (!vessel.open || vessel.open.until.getTime() > openUntil.getTime())) {
    return null;
  }
  if (filters.search) {
    const reg = escapeRegExp(filters.search);
    if (!reg.test((vessel.fullTextSearch || (vessel.name + vessel.imoNumber)))) {
      return null;
    }
  }
  return vessel;
}
