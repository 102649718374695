import * as React from 'react';
import FormsySelect from '../../Common/Select';
import s from './Filter.scss';
import { typeByPurposeList } from './common';
import MenuItem from 'material-ui/MenuItem';
import { formatTypeByPurposePlural } from '../../../core/format';

const iconStyle = { top: '-1px', position: 'relative', marginRight: '4px' };

export class TypeByPurposeSelect extends React.Component {

  static defaultProps = {
    pluralCount: 2,
  }
  onChange = (e, type) => {
    this.props.onChange && this.props.onChange(e, type);
  };
  selectionRenderer = (t) => (
    <div style={{overflow: "hidden", textOverflow: "ellipsis", width: "90px", fontSize: "12px"}}>
      {t && <span style={iconStyle}>
        <img alt="icon" src={`/images/vessel-icons/moving/${t}.png`}/>
        </span>
      }
      <span>{formatTypeByPurposePlural(t, this.props.pluralCount)}</span>
    </div>
  );
  render() {
    return (<FormsySelect
      selectionRenderer={this.selectionRenderer}
      style={{ minWidth: '112px', maxWidth: "112px" }}
      {...this.props}
      name="typeByPurpose"
      fullWidth
      ref="typeByPurpose"
      className={s.select}
      hintText="By purpose"
      dropDownMenuProps={{
      menuStyle: {width: "unset"}
      }}
      onChange={this.onChange}
    >
      {typeByPurposeList.filter(t => t).map(t => (
      <MenuItem
        key={t}
        value={t}
        primaryText={
          <div>
            {t && <span style={iconStyle}>
              <img alt="icon" src={`/images/vessel-icons/moving/${t}.png`}/>
              </span>
            }
            <span>{formatTypeByPurposePlural(t, this.props.pluralCount)}</span>
          </div>
        }
      ></MenuItem>))}
    </FormsySelect>);
  }
}
