import React, { Component } from 'react';
import styled from "styled-components";
import {MinusButton, PlusButton} from "../Common/AddButton";
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";
import ContactBookApi from "../../core/api/ContactBookApi";
import validationRules from "formsy-react/lib/validationRules";

const emailValidationProps = {
  validations: "isEmail",
  requiredError: "required",
  validationErrors: { isEmail: "please enter valid email", duplicate: "email is already used" },
};

const StyleWrapper = styled.div`
  >div {
    display: flex;
    align-items: center;
    >div:nth-child(1) {
      flex: 1;
      margin-right: 12px;
    }
  }
`;


class ContactEmailsEdit extends Component {

  static defaultProps = {
    emails: [],
    entityType: 'person',
    firstRequired: true,
  }

  state = { alreadyTaken: [], originalEmails: [] }

  componentDidMount() {
    this.state.originalEmails = [...this.props.emails];
  }


  handleChangeEmail = (i, e, value) => {
    const emails = [...this.props.emails];
    emails[i] = value;
    this.props.onChange(null, emails);
  }
  handleRemoveEmail = (i) => {
    const emails = this.props.emails.filter((email, j) => j !== i);
    this.props.onChange(null, emails);
  }
  handleAddEmail = () => {
    const emails = [...this.props.emails, ''];
    this.props.onChange(null, emails);
  }

  handleBlur = async (i) => {
    const email = this.props.emails[i];
    if (!validationRules.isEmail(email) || this.state.alreadyTaken.indexOf(email) !== -1 || this.state.originalEmails.indexOf(email) !== -1) {
      return;
    }
      const uniqRes =  (await ContactBookApi.checkEmail(this.props.entityType, email)).data;
      if (uniqRes.unique === false) {
        this.setState((state) => {
          return { alreadyTaken: [...state.alreadyTaken, email] };
        });
      }
  }


  render() {
    return (
      <StyleWrapper>
        {this.props.emails.map((email, i) => {
          let validations = emailValidationProps.validations;
          if (this.state.alreadyTaken.indexOf(email) !== -1) {
            validations += ',duplicate';
          }
          let label = i === 0 ? 'Email (Primary)' : 'Email (Additional)';
          const required = i === 0 && this.props.firstRequired;
          if (required) {
            label += '*';
          }
          return (<div>
            <div>
              <NarrowFormsyText
                value={email}
                name={`email[${i}]`}
                required={required}
                onChange={this.handleChangeEmail.bind(this, i)}
                floatingLabelText={label}
                fullWidth
                onBlur={this.handleBlur.bind(this, i)}
                {...emailValidationProps}
                validations={validations}
              />
            </div>
            <div>{i === 0 ? <PlusButton onClick={this.handleAddEmail} /> : <MinusButton onClick={this.handleRemoveEmail.bind(this, i)} />}</div>
          </div>);
        })}
      </StyleWrapper>
    );
  }
}

export default ContactEmailsEdit;
