/** @name GET_INDEX_CHART_DATA_ERROR.js */
export default function GET_INDEX_CHART_DATA_ERROR(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    chartLoading: false,
    error: action.payload,
  };

  return { ...state, ...newState };
}
