import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NarrowCompanyAutocomplete from '../../NewInputs/NarrowCompanyAutocomplete';
import s from './DisponentOwner.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import DoneIcon from 'material-ui/svg-icons/action/done';
import Formsy from 'formsy-react';
import Vessel from "../../../core/api/Vessel";
import cx from 'classnames';
import { debounce, grabErrorMessage } from '../../../core/utils';

import {HiddenButtons} from "../../Common/HiddenButtons";
// HAD TO USE DERIVED STATE BECAUSE AUTOCOMPLETE EMITS ONCHANGE EVENT EVEN AFTER VALUE FROM PROPS CHANGE
// THEREFORE CAN'T DISTINGUISH BETWEEN USER INPUT AND PROPS CHANGE
export class ChangeCompanyAutocomplete extends Component {

  constructor(props) {
    super(props);

   this.state = {
      isChanged: false,
      value: props.value || "",
      autocompleteKey: 0,
      wasValue: !!props.value,
    }
  }

  static contextTypes = {
    showMessage: PropTypes.func,
  }

  static defaultProps = {
    saveField: Vessel.saveDisponentOwner,
    name: "disponentOwner",
    label: "Disponent Owner",
    saveReference: () => undefined
  }

  componentDidMount() {
    this.props.saveReference(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value
      })
    }
  }

  handleChange = val => {
    if (String(val).trim().length === 0 && !this.state.wasValue) return;
    const value = this.createObj(val)
    this.setState({
      isChanged: true,
      value
    })
    //this.notifyParent(val);
  }

  /* notifyParent = val => {
    const disponentOwner = this.createObj(val)
    this.props.handleChange && this.props.handleChange(disponentOwner);
  } */

  cancelEditing = ev => {
    //this.notifyParent("");
    this.setState( state => ({
      ...state,
      value: this.props.value || "",
      isChanged: false,
      autocompleteKey: state.autocompleteKey + 1
    }))
  }

  hasValue = disponentOwner => disponentOwner && disponentOwner.name.length > 2

  createObj = val => {
    let company = {
      name: String(val?.name || val).trim()
    };
    if (val._id) {
      company._id = val._id
    };
    return company;
  }

  saveDisponentOwner = debounce(async () => {
    try {
      const res = await this.props.saveField(this.props._id, this.state.value);
      this.props.fieldSaved && this.props.fieldSaved({
        response: res,
        value: this.state.value
      });
      this.setState({
        isChanged: false,
        wasValue: this.state.value?.hasOwnProperty("name") ? !!this.state.value.name : !!this.state.value
      })
      if (res.status === 200) {
        this.context.showMessage({ message: this.state.value?.name?.length ? `${this.props.label} has been changed to ${this.state.value?.name}` : `${this.props.label} has been removed` });
      }
    } catch (error) {
      console.error(error);
      this.context.showMessage(
        { level: 'error', message: `Error saving ${this.props.label} : ${grabErrorMessage(error)}` }
      );
    }
  }, 400)

  render() {
    const { _id, classes = "", name, label } = this.props;
    const { autocompleteKey, value, isChanged } = this.state;
    const canSave = this.state.isChanged;
    // const icoColor = canSave ? "#75C422" : "#B2B2B2";
    return (
      <Formsy.Form className={cx(s.disponent_owner, canSave && s.buttons_visible, classes)}>
        <NarrowCompanyAutocomplete
          classes={s.narrow_input}
          hintText={`Enter ${label}`}
          name={name}
          key={autocompleteKey}
          value={value?.name || ""}
          onNewRequest={this.handleChange}
        />
        <div>
          {canSave ? (<HiddenButtons className={s.buttons} handleSubmit={this.saveDisponentOwner} handleCancel={this.cancelEditing} shouldShow />) : (this.props.children)}
        </div>
      </Formsy.Form>
    )
  }
}

export default withStyles(s)(ChangeCompanyAutocomplete);
