exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".XH7HR {\n  padding-top: 0 !important;\n  overflow-y: scroll !important; }\n  .XH7HR > div {\n    height: 100%; }\n  .XH7HR ._2sjEL {\n    width: 100% !important;\n    max-width: 100% !important;\n    -webkit-transform: none !important;\n            transform: none !important;\n    height: 100%; }\n  .XH7HR ._2sjEL > div {\n      height: 100%; }\n  .XH7HR ._2WLfK {\n    max-height: 100% !important;\n    padding: 0 !important; }\n  .XH7HR ._32mkw {\n    width: 136px;\n    margin-left: 20px; }\n  .XH7HR h2 {\n    margin-bottom: 10px;\n    margin-left: 20px;\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 22px;\n    letter-spacing: 0.5px; }\n  .XH7HR ._18DZH {\n    margin-bottom: 20px;\n    padding-bottom: 72%;\n    background-image: url(/images/about_mob.png);\n    background-repeat: no-repeat;\n    background-size: cover; }\n  .XH7HR .JA3dc {\n    padding-left: 20px;\n    padding-right: 20px; }\n", ""]);

// Exports
exports.locals = {
	"modal": "XH7HR",
	"content_modal": "_2sjEL",
	"wrapper_modal": "_2WLfK",
	"logo": "_32mkw",
	"laptop": "_18DZH",
	"container_app": "JA3dc"
};