import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import FormsySelect from './Select';
const values = [
  {
    _id: 'dlosp [port] atdnsshinc',
    fullName: 'dropping last outward sea pilot any time day or night, sundays and holidays included',
    name: 'dlosp atdnsshinc',
    type: 'port',
  },
  {
    _id: 'dop [port] atdnsshinc',
    fullName: 'dropping outward pilot any time day or night, sundays and holidays included',
    name: 'dop atdnsshinc',
    type: 'port',
  },
  {
    _id: 'afsps [port] atdnsshinc',
    fullName: 'arrival first sea pilot station any time day or night, sundays and holidays included',
    name: 'afsps atdnsshinc',
    type: 'port',
  },

  {
    _id: 'aps [port] atdnsshinc',
    fullName: 'arrival pilot station any time day or night, sundays and holidays included',
    name: 'aps atdnsshinc',
    type: 'port',
  },
  {
    _id: 'passing [port] atdnsshinc',
    fullName: 'passing any time day or night, sundays and holidays included',
    name: 'passing atdnsshinc',
    type: 'port',
  },
  {
    _id: 'dlosp 1 sp [range/region] in oo atdnsshinc',
    fullName: "dropping last outward sea pilot 1 safe port in owners' option any time day or night, sundays and holidays included",
    name: 'dlosp 1 sp in oo atdnsshinc',
    type: 'region',
  },
  {
    _id: 'dop 1 sp [range/region] in oo atdnsshinc',
    fullName: "dropping outward pilot 1 safe port in owners' option any time day or night, sundays and holidays included",
    name: 'dop 1 sp in oo atdnsshinc',
    type: 'region',
  },
  {
    _id: 'aps 1 sp [range/region] inchopt atdnsshinc',
    fullName: "arrival pilot station 1 safe port in charterers' option any time day or night, sundays and holidays included",
    name: 'aps 1 sp inchopt atdnsshinc',
    type: 'region',
  },
  {
    _id: 'afsps 1 sp [range/region] inchopt atdnsshinc',
    fullName: "arrival first sea pilot station 1 safe port in charterers' option any time day or night, sundays and holidays included",
    name: 'afsps 1 sp inchopt atdnsshinc',
    type: 'region',
  },
  {
    _id: '[port] outer anchorage atdnsshinc',
    fullName: 'outer anchorage any time day or night, sundays and holidays included',
    name: 'outer anchorage atdnsshinc',
    type: 'port',
  },
  {
    _id: '',
    fullName: '',
    name: '',
    type: 'port',
  },
];

function DeliveryTermsSelect(props) {
  return (
    <FormsySelect defaultValue={values[0].name} {...props}>
      {values.map(value => {
        return (
          <MenuItem
            key={value._id}
            value={value.name}
            title={value.fullName}
            primaryText={value.name}
          />
        );
      })}
    </FormsySelect>
  );
}

export default DeliveryTermsSelect;
