import * as handlers from './allFleet/index';
import { defaultSortField } from '../components/Vessel/AllFleet/sort';
import createReducer from './createReducer';

const defaultState = {
  data: [],
  page: 1,
  next: 2,
  filters: {
    search: "",
  },
  sort: defaultSortField,
  fetchFailed: false,
  isHidden: false,
  // set to true when navigating from All Fleet page to vessel's details page to keep pagination and scroll
  saveRowsAndScroll: false,
};

export default createReducer(
  handlers,
  defaultState,
);
