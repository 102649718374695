import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Link from '../Link';
import cx from 'classnames';
import s from './News.scss';
import IO from '../../core/api/IO';
import { date, dateString } from '../../core/format';

class NewsHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { newsList: [] };
  }

  async componentDidMount() {
    const newsList = await IO.getNewsList({ limit: 3, offset: 0 });
    this.setState({ newsList });
  }


  render() {
    return (
      <div className={s.root}>
        <noindex>
          <div id="news-releases" className={s.news_releases} id="news">
            <div className={s.wrapper_container_main_page}>
              <h2>News</h2>
              <div className={cx(s.wrapper_news, s.more)}>
                <Link className={s.show_more} to="/news">See all news</Link>
                {
                  this.state.newsList.map((item, index) => (
                    <div
                      className={s.wrap}
                      style={{
                        width: '30%',
                      }}
                      key={index}
                    >
                      <div className={s.more_new}>
                        <Link
                          className={s.wrapper_image_news}
                          to={`/news/${encodeURIComponent(item.link || item.label)}`}
                          style={{
                            backgroundImage: `url(${item.imageData.path})`,
                          }}
                        />
                        <p className={s.upper}>{dateString(item.publishDate)}</p>
                        <h3>
                          <Link to={`/news/${encodeURIComponent(item.link || item.label)}`} ><span dangerouslySetInnerHTML={{__html:item.title}} /></Link>
                        </h3>
                        <p className={s.description} dangerouslySetInnerHTML={{__html:item.shortDescription || item.description.slice(0, 200) + '...'}} />
                        <Link className={s.all_releases} to={`/news/${encodeURIComponent(item.link || item.label)}`}>Read more</Link>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </noindex>
      </div>
    );
  }
}

export default (withStyles(s)(NewsHomePage));
