import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './Preview.scss';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import FormsyText from '../../Common/FormsyText';
import { fetch } from './../../../core/api/api';
import NarrowAutocomplete from '../../NewInputs/NarrowAutocomplete';
import { MenuItem } from 'material-ui';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';


const addressBarRenderer = function(items) {
  return items.map(item => {
    return {
      text: item.email,
      _value: item.email,
      value: <MenuItem style={{fontSize: '13px'}} primaryText={item.email} />,
    };
  });
};


const styles = {
  radioButton: {
    width: "max-content",
    marginRight: 16,
    whiteSpace: "nowrap",
  },
  radioButtonLabel: {
    color: 'var(--text-light)',
  },
  group: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  form: {
    height: '48px',
    marginBottom: '1rem',
  },
  hr: {
    marginTop: "0px",
  }
};

const MY = "public";
const RECEIVED = "broker";

export class CargoRequestType extends Component {

  constructor(props) {
    super(props);
    if (this.props.email) {
      this.state.email = this.props.email;
      this.state.requestType = RECEIVED;
    }
  }

  state = {
    email: "",
    requestType: MY,
  }

  changeRequestType = (ev, val) => {
    this.setState({
      requestType: val,
    });
  }

  changeEmail = (email) => {
    this.setState({
      email,
    })
  }

  normalizeEmail = email => String(email).trim().toLowerCase();

  validateEmail = memoizeOne((email, currentUserEmail) => this.normalizeEmail(email) !== this.normalizeEmail(currentUserEmail))
   addressBookAPI = {
    find: ({ name } = {}) => {
      if (!this.props.user) {
        return { data: [] };
      }
      return fetch(`/addressBook/${name}`, {
        method: "GET",
      }, "/api/v2");
    }
  }

  render() {

    const { requestType, email } = this.state;
    const { myCargoDisabled = false } = this.props;

    const isEmailValid = this.props.user?.email ? this.validateEmail(this.state.email, this.props.user.email) : true;

    return (
      <div style={styles.main}>
        <div className="w-100" style={{ ...styles.group, ...styles.form }}>
          <div className={cx("col preview_label", s.pol)}>CREATE CARGO AS: </div>
          <div
            className={cx(
              'col-12 col-md-auto preview_value grow',
              s.port,
            )}
            style={styles.group}
          >
            {
              // propagate requestKind field to Formsy.Form submit
            }
            <div style={{ display: "none" }}>
              <FormsyText
                name="requestKind"
                required
                type="hidden"
                value={requestType}
              />
            </div>
            <RadioButtonGroup style={styles.group} name="requestKind" onChange={this.changeRequestType} valueSelected={requestType}>
              <RadioButton
                value={MY}
                label="My cargo"
                style={styles.radioButton}
                labelStyle={styles.radioButtonLabel}
                disabled={myCargoDisabled}
              />
              <RadioButton
                value={RECEIVED}
                label="Received From"
                style={styles.radioButton}
                labelStyle={styles.radioButtonLabel}
              />
            </RadioButtonGroup>
            {
              requestType === RECEIVED
              && (
                <NarrowAutocomplete
                  validations="isEmail"
                  validationError="please enter valid email"
                  requiredError="required"
                  name="realUserEmail"
                  required
                  api={this.addressBookAPI}
                  invalid={!isEmailValid}
                  errorText="You can't specify your account's email"
                  classes={s.address_book_autocomplete}
                  hintText="Email (example@mail.com)*"
                  renderer={addressBarRenderer}
                  onNewRequest={this.changeEmail}
                  value={email}
                />
              )
            }
          </div>
        </div>
        <hr style={styles.hr} />
      </div>

    )
  }
}

export default connect(state => ({ user: state.login.user }))(withStyles(s)(CargoRequestType))
