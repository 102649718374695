import {FetchException} from "./api/api";

export default {
  log: (logObject, error) => {
    try {
      if (typeof window !== "undefined") {
        if (window._LTracker) {
          logObject.href = window.location.href;
          if (error) {
            if (error instanceof Error || error instanceof FetchException) {
              logObject.error = {
                message: error.message,
                stack: error.stack,
                status: error.status,
              };
            } else {
              logObject.error = error;
            }
          }
          window._LTracker.push(logObject);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
}
