import React from 'react'
import cx from 'classnames';
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SanctionsFlag from '../../Common/SanctionsFlag';
import VesselName from './VesselName';

function SanctionsName({ vessel, search, className = "", ...rest }) {
  return (
    <div className={cx("align-items-center", className)} {...rest}>
      <SanctionsFlag hasSanctions={vessel?.hasSanctions} small />
      <VesselName vessel={vessel} search={search} />
    </div>
  )
}

export default withStyles(s)(SanctionsName)
