import { removeFromArray } from '../../core/utils';

/** @name POSITION_REMOVE_BY_ID_REDUCER */

export default function POSITION_REMOVE_BY_ID(state, { payload }) {
  const newState = {};
  const positionsList = { ...state.positionsList };

  positionsList.data = removeFromArray(
    positionsList.data,
    positionsList.data.findIndex(c => c._id === payload),
  );
  newState.positionsList = positionsList;

  return { ...state, ...newState };
}
