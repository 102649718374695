import React, {Component} from 'react';
import Page from "../Page/Page";
import cx from "classnames";
import SplitPane, {Pane} from "../Common/SplitPane";
import ContactsTable from "./ContactsTable";
import {CompaniesTableConnected} from "./CompaniesTable";
import DialogHalfScreen from "../Common/DialogHalfScreen";
import {ContactEditConnected} from "./ContactEdit";
import {CompanyEditConnected} from "./CompanyEdit";
import { ContactDetailsConnected } from "./ContactDetails";
import history from "../../core/history";
import {CompanyDetailsConnected} from "./CompanyDetails";
import {GroupEditConnected} from "./GroupEdit";
import {GroupDetailsConnected} from "./GroupDetails";
import AllFleetMap from "../Vessel/AllFleet/AllFleetMap";
import {connect} from "react-redux";
import {getCompanyList, getContactList, getHeatmap} from "../../actions/contacts";
import RaisedButton, {RaisedButtonBlue} from "../Common/RaisedButton";
import EmailsInMonitor from '../Emails/EmailsInMonitor';

const pageContentStyle = {position: 'relative', overflow: 'hidden', width: '100%', height: '100%'};

class ContactBookPage extends Component {
  state = {
    mailsListOpened: false,
    emailsForFilter: [],
  }
  openEmailsList = (emails) => {
    this.setState({ mailsListOpened: true, emailsForFilter: emails });
  }
  closeEmailsList = () => {
    this.setState({ mailsListOpened: false, emailsForFilter: [] });
  }
  renderLeftPart(){
    if (this.state.mailsListOpened) {
      return <EmailsInMonitor position="left" isContactBook emailsForFilter={this.state.emailsForFilter} closeMails={this.closeEmailsList} />;
    }
    switch (this.props.folder) {
      case 'companies':
        return <CompaniesTableConnected {...this.props} />;
      case 'persons':
      default:
        return <ContactsTable {...this.props} />;
    }
  }

  handleCloseCreateContact = ({ action, id } = {}) => {

    switch (action) {
      case 'add-person':
      case 'add-company':
      case 'add-group':
        history.push(`/$1/$2/${action.slice(4)}:${id}/-/-/---`);
    }
    history.push('/$1/$2/$3/-/-/---');
  }

  renderDetails(){
    if (this.props.contactId) {
      switch (this.props.entity) {
        case 'person':
          return (<DialogHalfScreen open>
            <ContactDetailsConnected openEmailsList={this.openEmailsList} closeEmailsList={this.closeEmailsList} contactId={this.props.contactId} action={this.props.action} />
          </DialogHalfScreen>);
        case 'company':
          return (<DialogHalfScreen open>
            <CompanyDetailsConnected openEmailsList={this.openEmailsList} closeEmailsList={this.closeEmailsList} contactId={this.props.contactId} action={this.props.action} />
          </DialogHalfScreen>);
        case 'group':
         return (<DialogHalfScreen open>
            <GroupDetailsConnected openEmailsList={this.openEmailsList} closeEmailsList={this.closeEmailsList} contactId={this.props.contactId} action={this.props.action} />
          </DialogHalfScreen>);
      }
    }
    return null;
  }

  render() {
    return (
      <Page headerTitle={"CONTACTS"}>
        <div style={pageContentStyle}>
          <SplitPane
            className={cx('h-100')}
            left={this.renderLeftPart()}
            right={<ContactsMap folder={this.props.folder} />}
          />
          {this.props.action === 'add-person' ? (
            <DialogHalfScreen open>
              <ContactEditConnected onClose={this.handleCloseCreateContact} action={this.props.action} />
            </DialogHalfScreen>
          ) : null}
          {this.props.action === 'add-company' ? (
            <DialogHalfScreen open>
              <CompanyEditConnected onClose={this.handleCloseCreateContact} action={this.props.action} />
            </DialogHalfScreen>
          ) : null}
          {this.props.action === 'add-group' ? (
            <DialogHalfScreen open>
              <GroupEditConnected onClose={this.handleCloseCreateContact} action={this.props.action} />
            </DialogHalfScreen>
          ) : null}
          {this.renderDetails()}
        </div>
      </Page>
    );
  }
}

const ContactsMap = connect(state => ({
  contacts: state.contacts.heatmap,
  contactList: state.contacts.contactList,
  companyList: state.contacts.companyList,
}), {
  getHeatmap,
  getContactList,
  getCompanyList,
})(class _ContactsMap extends Component {
  componentDidMount() {
    if (this.props.getHeatmap) {
      this.props.getHeatmap();
    }
  }

  handleCountrySelected = (countryId) => {
    const { list, getList } = this.getActiveList();
    const { filters, sort } = list;
    getList({ page: 1, filters: { ...filters, countryId }, sort });
  }

  getActiveList = () => {
    if (this.props.folder === 'companies') {
      return { list: this.props.companyList, getList: this.props.getCompanyList };
    } else {
      return { list: this.props.contactList, getList: this.props.getContactList };
    }
  }
  render() {
    const { list } = this.getActiveList();
    return (<Pane
      headerProps={{
        useBorder: true,
        children: <div className={"w-100 flex space-between align-items-center"}>MAP <div><RaisedButtonBlue onClick={() => this.handleCountrySelected()} disabled={!list.filters.countryId} styles={'xs'} label={'SHOW ALL'}/></div></div> }}
      bodyProps={{ style: { height: '100%' } }}
    >
      <AllFleetMap contacts={this.props.contacts} for={'contacts'} onCountrySelected={this.handleCountrySelected} selectedCountryId={list.filters.countryId} />
    </Pane>);
  }
});

export default ContactBookPage;
