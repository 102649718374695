/**@see  {@link VESSEL_REQUEST_REDUCER}*/
export const VESSEL_REQUEST = 'VESSEL_REQUEST';
/**@see  {@link VESSEL_REQUEST_REQUEST_REDUCER}*/
export const VESSEL_REQUEST_REQUEST = 'VESSEL_REQUEST_REQUEST';
/**@see  {@link VESSEL_REQUEST_FAILED_REDUCER}*/
export const VESSEL_REQUEST_FAILED = 'VESSEL_REQUEST_FAILED';

/**@see  {@link VESSEL_REQUEST_LIST_REDUCER}*/
export const VESSEL_REQUEST_LIST = 'VESSEL_REQUEST_LIST';
/**@see  {@link VESSEL_REQUEST_LIST_REQUEST_REDUCER}*/
export const VESSEL_REQUEST_LIST_REQUEST = 'VESSEL_REQUEST_LIST_REQUEST';
/**@see  {@link VESSEL_REQUEST_LIST_FAILED_REDUCER}*/
export const VESSEL_REQUEST_LIST_FAILED = 'VESSEL_REQUEST_LIST_FAILED';

/**@see  {@link VESSEL_REQUEST_FILTER_REDUCER}*/
export const VESSEL_REQUEST_FILTER = 'VESSEL_REQUEST_FILTER';

/**@see  {@link VESSEL_REQUEST_EXPIRED_REDUCER}*/
export const VESSEL_REQUEST_EXPIRED = 'VESSEL_REQUEST_EXPIRED';
/**@see  {@link VESSEL_REQUEST_GET_CARGO_REDUCER}*/
export const VESSEL_REQUEST_GET_CARGO = 'VESSEL_REQUEST_GET_CARGO';
/**@see  {@link VESSEL_REQUEST_GET_CARGO_REQUEST_REDUCER}*/
export const VESSEL_REQUEST_GET_CARGO_REQUEST =
  'VESSEL_REQUEST_GET_CARGO_REQUEST';
/**@see  {@link VESSEL_REQUEST_GET_CARGO_FAILED_REDUCER}*/
export const VESSEL_REQUEST_GET_CARGO_FAILED =
  'VESSEL_REQUEST_GET_CARGO_FAILED';

/**@see  {@link VESSEL_TCOFFER_LIST_REDUCER}*/
export const VESSEL_TCOFFER_LIST = 'VESSEL_TCOFFER_LIST';
/**@see  {@link VESSEL_TCOFFER_LIST_REQUEST_REDUCER}*/
export const VESSEL_TCOFFER_LIST_REQUEST = 'VESSEL_TCOFFER_LIST_REQUEST';
/**@see  {@link VESSEL_TCOFFER_LIST_FAILED_REDUCER}*/
export const VESSEL_TCOFFER_LIST_FAILED = 'VESSEL_TCOFFER_LIST_FAILED';

/**@see  {@link VESSEL_TCOFFER_FILTER_REDUCER}*/
export const VESSEL_TCOFFER_FILTER = 'VESSEL_TCOFFER_FILTER';
/**@see  {@link VESSEL_TCOFFER_EXPIRED_REDUCER}*/
export const VESSEL_TCOFFER_EXPIRED = 'VESSEL_TCOFFER_EXPIRED';

/**@see  {@link VESSEL_TCOFFER_UPDATED_REDUCER}*/
export const VESSEL_TCOFFER_UPDATED = 'VESSEL_TCOFFER_UPDATED';

/**@see  {@link VESSEL_TCOFFER_SENT_REDUCER}*/
export const VESSEL_TCOFFER_SENT = 'VESSEL_TCOFFER_SENT';
/**@see  {@link VESSEL_TCOFFER_SENT_REQUEST_REDUCER}*/
export const VESSEL_TCOFFER_SENT_REQUEST = 'VESSEL_TCOFFER_SENT_REQUEST';
/**@see  {@link VESSEL_TCOFFER_SENT_FAILED_REDUCER}*/
export const VESSEL_TCOFFER_SENT_FAILED = 'VESSEL_TCOFFER_SENT_FAILED';

/**@see  {@link VESSEL_TCOFFER_REJECTED_REDUCER}*/
export const VESSEL_TCOFFER_REJECTED = 'VESSEL_TCOFFER_REJECTED';
/**@see  {@link VESSEL_TCOFFER_REJECTED_REQUEST_REDUCER}*/
export const VESSEL_TCOFFER_REJECTED_REQUEST =
  'VESSEL_TCOFFER_REJECTED_REQUEST';
/**@see  {@link VESSEL_TCOFFER_REJECTED_FAILED_REDUCER}*/
export const VESSEL_TCOFFER_REJECTED_FAILED = 'VESSEL_TCOFFER_REJECTED_FAILED';

/**@see  {@link VESSEL_TCOFFER_FIXED_REDUCER}*/
export const VESSEL_TCOFFER_FIXED = 'VESSEL_TCOFFER_FIXED';
/**@see  {@link VESSEL_TCOFFER_FIXED_REQUEST_REDUCER}*/
export const VESSEL_TCOFFER_FIXED_REQUEST = 'VESSEL_TCOFFER_FIXED_REQUEST';
/**@see  {@link VESSEL_TCOFFER_FIXED_FAILED_REDUCER}*/
export const VESSEL_TCOFFER_FIXED_FAILED = 'VESSEL_TCOFFER_FIXED_FAILED';

/**@see  {@link VESSEL_TC_NEW_REDUCER}*/
export const VESSEL_TC_NEW = 'VESSEL_TC_NEW';

/**@see  {@link MY_VESSEL_LIST_REDUCER}*/
export const MY_VESSEL_LIST = 'MY_VESSEL_LIST';
/**@see  {@link MY_VESSEL_LIST_REQUEST_REDUCER}*/
export const MY_VESSEL_LIST_REQUEST = 'MY_VESSEL_LIST_REQUEST';
/**@see  {@link MY_VESSEL_LIST_FAILED_REDUCER}*/
export const MY_VESSEL_LIST_FAILED = 'MY_VESSEL_LIST_FAILED';
/**@see  {@link MY_VESSEL_LIST_FILTER_REDUCER}*/
export const MY_VESSEL_LIST_FILTER = 'MY_VESSEL_LIST_FILTER';
/**@see  {@link SNP_LIST_FILTER_REDUCER}*/
export const SNP_LIST_FILTER = 'SNP_LIST_FILTER';

/**@see  {@link POSITIONS_BY_VESSEL_ID_REDUCER}*/
export const POSITIONS_BY_VESSEL_ID = 'POSITIONS_BY_VESSEL_ID';
/**@see  {@link POSITIONS_BY_VESSEL_ID_REQUEST_REDUCER}*/
export const POSITIONS_BY_VESSEL_ID_REQUEST = 'POSITIONS_BY_VESSEL_ID_REQUEST';
/**@see  {@link POSITIONS_BY_VESSEL_ID_FAILED_REDUCER}*/
export const POSITIONS_BY_VESSEL_ID_FAILED = 'POSITIONS_BY_VESSEL_ID_FAILED';

/**@see  {@link POSITION_REMOVE_BY_ID_REDUCER}*/
export const POSITION_REMOVE_BY_ID = 'POSITION_REMOVE_BY_ID';
/**@see  {@link POSITION_REMOVE_BY_ID_REQUEST_REDUCER}*/
export const POSITION_REMOVE_BY_ID_REQUEST = 'POSITION_REMOVE_BY_ID_REQUEST';
/**@see  {@link POSITION_REMOVE_BY_ID_FAILED_REDUCER}*/
export const POSITION_REMOVE_BY_ID_FAILED = 'POSITION_REMOVE_BY_ID_FAILED';

/**@see  {@link POSITION_UPDATE_BY_ID_REDUCER}*/
export const POSITION_UPDATE_BY_ID = 'POSITION_UPDATE_BY_ID';
/**@see  {@link POSITION_UPDATE_BY_ID_REQUEST_REDUCER}*/
export const POSITION_UPDATE_BY_ID_REQUEST = 'POSITION_UPDATE_BY_ID_REQUEST';
/**@see  {@link POSITION_UPDATE_BY_ID_FAILED_REDUCER}*/
export const POSITION_UPDATE_BY_ID_FAILED = 'POSITION_UPDATE_BY_ID_FAILED';

/**@see  {@link VESSEL_NEW_FREIGHT_REQUEST_REDUCER}*/
export const VESSEL_NEW_FREIGHT_REQUEST = 'VESSEL_NEW_FREIGHT_REQUEST';

/**@see  {@link VESSEL_CLAIM_RIGHTS_REDUCER}*/
export const VESSEL_CLAIM_RIGHTS = 'VESSEL_CLAIM_RIGHTS';
/**@see  {@link VESSEL_CLAIM_RIGHTS_REQUEST_REDUCER}*/
export const VESSEL_CLAIM_RIGHTS_REQUEST = 'VESSEL_CLAIM_RIGHTS_REQUEST';
/**@see  {@link VESSEL_CLAIM_RIGHTS_FAILED_REDUCER}*/
export const VESSEL_CLAIM_RIGHTS_FAILED = 'VESSEL_CLAIM_RIGHTS_FAILED';
/**@see  {@link VESSEL_CREATE_REDUCER}*/
export const VESSEL_CREATE = 'VESSEL_CREATE';
/**@see  {@link VESSEL_CREATE_REQUEST_REDUCER}*/
export const VESSEL_CREATE_REQUEST = 'VESSEL_CREATE_REQUEST';
/**@see  {@link VESSEL_CREATE_FAILED_REDUCER}*/
export const VESSEL_CREATE_FAILED = 'VESSEL_CREATE_FAILED';
/**@see  {@link VESSEL_UPDATE_REDUCER}*/
export const VESSEL_UPDATE = 'VESSEL_UPDATE';
/**@see  {@link VESSEL_UPDATE_REQUEST_REDUCER}*/
export const VESSEL_UPDATE_REQUEST = 'VESSEL_UPDATE_REQUEST';
/**@see  {@link VESSEL_UPDATE_FAILED_REDUCER}*/
export const VESSEL_UPDATE_FAILED = 'VESSEL_UPDATE_FAILED';

/**@see  {@link SENT_TCOFFER_LIST_REDUCER}*/
export const SENT_TCOFFER_LIST = 'SENT_TCOFFER_LIST';
/**@see  {@link SENT_TCOFFER_LIST_REQUEST_REDUCER}*/
export const SENT_TCOFFER_LIST_REQUEST = 'SENT_TCOFFER_LIST_REQUEST';
/**@see  {@link SENT_TCOFFER_LIST_FAILED_REDUCER}*/
export const SENT_TCOFFER_LIST_FAILED = 'SENT_TCOFFER_LIST_FAILED';
/**@see  {@link SENT_TCOFFER_TOGGLE_STYLE_REDUCER}*/
export const SENT_TCOFFER_TOGGLE_STYLE = 'SENT_TCOFFER_TOGGLE_STYLE';

/**@see  {@link VESSEL_DELETE_REDUCER}*/
export const VESSEL_DELETE = 'VESSEL_DELETE';
/**@see  {@link VESSEL_DELETE_REQUEST_REDUCER}*/
export const VESSEL_DELETE_REQUEST = 'VESSEL_DELETE_REQUEST';
/**@see  {@link VESSEL_DELETE_FAILED_REDUCER}*/
export const VESSEL_DELETE_FAILED = 'VESSEL_DELETE_FAILED';

/**@see {@link VESSEL_RATING_SAVE} */
export const VESSEL_RATING_SAVE = 'VESSEL_RATING_SAVE';
/**@see {@link VESSEL_RATING_SAVE_FAILED} */
export const VESSEL_RATING_SAVE_FAILED = 'VESSEL_RATING_SAVE_FAILED';

export const VESSEL_UPDATE_TC = 'VESSEL_UPDATE_TC';
export const VESSEL_UPDATE_HIRE_IDEA = 'VESSEL_UPDATE_HIRE_IDEA';
export const VESSEL_UPDATE_SALE_OFFER = "VESSEL_UPDATE_SALE_OFFER";

/**@see  {@link MY_SNP_LIST_REDUCER}*/
export const MY_SNP_LIST = 'MY_SNP_LIST';
export const SNP_UPDATE_SINGLE = "SNP_UPDATE_SINGLE";
export const SNP_UPDATE_ALL = "SNP_UPDATE_ALL";
export const SHOW_HIDE_OFFERS = "SHOW_HIDE_OFFERS";
export const SNP_REMOVE_BY_ID = "SNP_REMOVE_BY_ID";
export const SNP_ADD_SINGLE = "SNP_ADD_SINGLE";
export const SNP_ADD_SINGLE_FAILED = 'SNP_ADD_SINGLE_FAILED';

export const FLEET_UPDATE_SINGLE_VESSEL = "FLEET_UPDATE_SINGLE_VESSEL";
export const FLEET_ADD_CREATED_VESSEL = "FLEET_ADD_CREATED_VESSEL";
export const MONITOR_VESSEL_CHANGES_SEEN = "MONITOR_VESSEL_CHANGES_SEEN";
export const VESSEL_ADD_TO_SELECTED = "VESSEL_ADD_TO_SELECTED";
export const FLEET_UPDATE_ALL_VESSELS = "FLEET_UPDATE_ALL_VESSELS";
export const VESSEL_FILTER_FLEET = "VESSEL_FILTER_FLEET";
export const FLEET_VESSEL_CHANGES_SEEN = "FLEET_VESSEL_CHANGES_SEEN";
export const MONITOR_VESSEL_CHANGED = "MONITOR_VESSEL_CHANGED";
export const FLEET_VESSEL_CHANGED = "FLEET_VESSEL_CHANGED";
export const SNP_VESSEL_CHANGED = "SNP_VESSEL_CHANGED";

/**@see  {@link VESSEL_GET_REDUCER}*/
export const VESSEL_GET = 'VESSEL_GET';
/**@see  {@link VESSEL_GET_REQUEST_REDUCER}*/
export const VESSEL_GET_REQUEST = 'VESSEL_GET_REQUEST';
/**@see  {@link VESSEL_GET_FAILED_REDUCER}*/
export const VESSEL_GET_FAILED = 'VESSEL_GET_FAILED';

/**@see  {@link VESSEL_GET_TCINDEX_REDUCER}*/
export const VESSEL_GET_TCINDEX = 'VESSEL_GET_TCINDEX';
/**@see  {@link VESSEL_GET_TCINDEX_REQUEST_REDUCER}*/
export const VESSEL_GET_TCINDEX_REQUEST = 'VESSEL_GET_TCINDEX_REQUEST';
/**@see  {@link VESSEL_GET_TCINDEX_FAILED_REDUCER}*/
export const VESSEL_GET_TCINDEX_FAILED = 'VESSEL_GET_TCINDEX_FAILED';

/**@see  {@link VESSEL_UPDATE_TCINDEX_REDUCER}*/
export const VESSEL_UPDATE_TCINDEX = 'VESSEL_UPDATE_TCINDEX';
/**@see  {@link VESSEL_UPDATE_TCINDEX_REQUEST_REDUCER}*/
export const VESSEL_UPDATE_TCINDEX_REQUEST = 'VESSEL_UPDATE_TCINDEX_REQUEST';
/**@see  {@link VESSEL_UPDATE_TCINDEX_FAILED_REDUCER}*/
export const VESSEL_UPDATE_TCINDEX_FAILED = 'VESSEL_UPDATE_TCINDEX_FAILED';


