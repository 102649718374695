export const ISSUE_SELECT_ELEMENT = "ISSUE_SELECT_ELEMENT";
export const ISSUE_REMOVE_SELECTED_ELEMENT = "ISSUE_REMOVE_SELECTED_ELEMENT";
export const ISSUE_SELECTABLE_ELEMENT = "ISSUE_SELECTABLE_ELEMENT";
export const ISSUE_CLEAR_SELECTED_ELEMENTS = "ISSUE_CLEAR_SELECTED_ELEMENTS";

export function setIssueSelectable(selectable) {
  return {
    type: ISSUE_SELECTABLE_ELEMENT,
    payload: selectable,
  };
}

export function selectElement(element) {
  return {
    type: ISSUE_SELECT_ELEMENT,
    payload: element,
  };
}

export function removeSelectedElement(element) {
  return {
    type: ISSUE_REMOVE_SELECTED_ELEMENT,
    payload: element,
  };
}
export function clearSelectedElements() {
  return {
    type: ISSUE_CLEAR_SELECTED_ELEMENTS,
  };
}

