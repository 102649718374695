import { date } from '../../core/format';
import { filterContract } from './filter';
//noinspection JSUnusedGlobalSymbols
/** @name CONTRACT_LIST_REDUCER */
export default function CONTRACT_LIST(state, action) {
  /*const o = action.res.data[0];
  (new Array(200)).fill(1).forEach((u,i)=>{
    action.res.data.push({...o,_id:o._id+i});
  });*/
  action.res.data.map((c) => {
    let search = '';
    try {
      if (c.type === 'voyage') {
        Object.assign(c.cargoRequest, c.changedRequest);
        search += date(c.addedAt) + ' ';
        search += c.cargoRequest.loadingPort.name + ' ';
        search += c.cargoRequest.unloadingPort.name + ' ';
        if (c.cargoRequest.cargo && c.cargoRequest.cargo.join) {
          search += c.cargoRequest.cargo.join(', ') + ' ';
        }
        search += (c.cargoRequest.totalValues.weight / 1000) + ' ';
        search += c.cargoRequest.totalValues.volume + ' ';
        search += date(c.cargoRequest.readinessDate) + ' - ';
        search += date(c.cargoRequest.cancellingDate) + ' - ';
        search += c.vesselRequest.name + ' ';
        if (c.cargoRequest.freight) {
          search +=
            '$' + c.cargoRequest.freight.value + ' ' + c.cargoRequest.freight.term;
        }
        c.search = search;
      }
    } catch (e) {
      console.error(e);
    } finally {
      return c;
    }
  });

  const newState = {
    contractList: filterContract({
      ...state.contractList,
      data: action.res.data,
      loading: false,
    }),
  };
  return { ...state, ...newState };
}
