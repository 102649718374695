import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import { connect } from 'react-redux';
import IconMenu from 'material-ui/svg-icons/navigation/menu';
import s from './ActionsMenu.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';

import CustomCheckbox from '../../Common/CustomCheckbox';
import { toggleSubscribeDialog } from '../../../actions/login';
import { isUserSubscribedToMarketplace } from '../../../core/utils';
import { sendOpenPosition } from '../../../actions/vessel';

import PositionDialog from './../../Vessel/Positions/PositionDialog';
import {
  deleteVessel,
  claimRights
} from '../../../actions/vessel';
import history from './../../../core/history';
import { scrollToAnchor } from './../../../core/utils';
import { toggleDialogMessage } from '../../../actions/runtime';

import { addToSelected, getSnpList } from './../../../actions/vessel';
import BurgerActions from '../../Common/BurgerActions';

export class ActionsMenu extends Component {

  static defaultProps = {
    baseUrl: '/main-deck/general/'
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      forSale: false,
      addToSelected: this.props.ship?.inSelectedVessels || false,
      selectedCallback: () => undefined,
      anchor: null,
      addPosition: false
    }

    this.ownersMenu = [
      /* {
        name: 'For Sale',
        checkbox: true,
        checked: 'forSale',
        handler: this.handleForSale
      }, */
      {
        name: 'Add Position',
        checkbox: false,
        handler: this.handleAddPosition
      },
      {
        name: 'Edit',
        checkbox: false,
        handler: this.handleEdit
      },
      /* {
        name: 'Delete',
        checkbox: false,
        handler: this.handleDelete
      }, */
      {
        name: 'Hire Indexes',
        checkbox: false,
        handler: this.openHireIndices,
      },
    ];

    this.usersMenu = [
      {
        name: 'Add to selected',
        checkbox: true,
        checked: 'addToSelected',
        onlyForSubscribed: true,
        handler: this.handleAddToSelected
      },
      /* {
        name: 'For Sale',
        checkbox: true,
        checked: 'forSale',
        handler: this.handleForSale
      }, */
      {
        name: 'Add Position',
        checkbox: false,
        handler: this.handleAddPosition
      },
      {
        name: 'Edit Exclusions',
        checkbox: false,
        handler: this.handleEditExclusions
      },
      {
        name: 'Edit Preferences',
        checkbox: false,
        handler: this.handleEditPreferences,
      },
      {
        name: 'Claim Rights',
        checkbox: false,
        handler: this.handleClaim
      },
      {
        name: 'Hire Indexes',
        checkbox: false,
        handler: this.openHireIndices,
      },
    ]
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.ship?.id !== nextProps.ship?.id || this.props.ship?.inSelectedVessels !== nextProps.ship?.inSelectedVessels) {
      this.setState({
        addToSelected: nextProps.ship.inSelectedVessels
      })
    }
  }

  handleClaim = async () => {
    if (!this.props.ship || !this.props.ship._id) {
      return;
    }
    try {
      await this.props.claimRights(this.props.ship);
      this.props.claimUpdate && this.props.claimUpdate();
      this.props.spfleet && this.props.getSnpList();
    } catch (error) {
      console.log(error);
    }
  }

  handleEditExclusions = () => {
    if (!this.props.exclusionsAnchor?.exclusions) {
      return;
    }
    try {
      this.props.exclusionsAnchor?.openVesselParticularsCollapse();
      const anchor = ReactDOM.findDOMNode(this.props.exclusionsAnchor.exclusions);
      setTimeout(() => scrollToAnchor(anchor), 1)
    } catch (error) {
      console.log(error)
    }
  }
  handleEditPreferences = () => {
    if (!this.props.exclusionsAnchor?.exclusions) {
      return;
    }
    try {
      this.props.exclusionsAnchor?.openVesselParticularsCollapse();
      const anchor = ReactDOM.findDOMNode(this.props.exclusionsAnchor.preferences);
      setTimeout(() => scrollToAnchor(anchor), 1);
    } catch (error) {
      console.log(error)
    }
  }

  handleAddToSelected = async (ev, val) => {

    try {
      const body = {
        'delete': !val,
        selectedVessels: [this.props.ship]
      }
      await this.props.addToSelected(body);
      this.setState({
        addToSelected: val
      }, () => {
        this.props.updateCurrentVessel({
          _id: this.props.ship._id,
          inSelectedVessels: val
        });
        this.props.selectedCallback(val);
      })
    } catch (e) {
      console.log(e)
    }

    this.setState({
      addToSelected: val
    })
  }

  handleForSale = (ev, val) => {
    this.setState({
      forSale: val
    })
  }


  handleDelete = () => {
    if (!this.props.ship || !this.props.ship._id) {
      return;
    }
    try {
      this.props.toggleDialogMessage({
        level: 'error', message: 'Are you sure you want to delete this Vessel?', dismissAble: true, action: {
          callback: async (decision) => {
            if(decision){
              try {
                this.props.deleteVessel(this.props.ship);
                window.location.replace(this.props.baseUrl);
              } catch (error) {
                error = {
                  messages: error?.messages || ["Vessel can't be deleted. Please, refresh the page"]
                }
                this.props.showErrorMessage(error?.messages[0]);
              }
            }
          },
        },
      })

    } catch (error) {
      console.log(error)
    }
  }

  handleEdit = () => {
    if (!this.props.ship || !this.props.ship._id) {
      return;
    }
    if (this.props.baseUrl.includes('my/cargo')) {
      history.push('/$1/$2/$3/$4/edit');
      return;
    }
    if (this.props.baseUrl.indexOf('main-deck') !== -1) {
      return history.push('edit');
    }
    history.push(`${this.props.baseUrl}${this.props.ship._id}/edit`);
  }

  handleAddPosition = () => {
    this.setState({
      addPosition: true
    })
  }

  closePositionDialog = () => {
    this.setState({
      addPosition: false
    })
  }

  sendPosition = (position) => {
    this.props.sendOpenPosition(position, this.props.user).then((res) => {
      if (res.status === 200) {
        this.setState({ open: false, anchor: null, addPosition: false }, () => {
          history.replace(window.location.pathname);
          this.props.onAddedChannel && this.props.onAddedChannel();
        });

      }
    });
  }

  openHireIndices = () => {
    this.props.exclusionsAnchor?.openHireIndices();
  }

  render() {
    const { ship = {} } = this.props;
    const { open, anchor, addPosition } = this.state;
    const { openMenu, closeMenu } = this;

    let list = ship.isVesselOwnerInSameCompany ? this.ownersMenu : this.usersMenu;
    if (ship?.typeByPurpose === "TANK") {
      list = list.filter(menuItem => menuItem.name !== "Edit");
    }

    return (
      <span>
        {
          addPosition
          ? (
            <PositionDialog handleSendNewPosition={this.sendPosition} vessel={ship} handleClose={this.closePositionDialog}></PositionDialog>
          )
          : null
        }
        <BurgerActions list={list} parentState={this.state} />
      </span>
    )
  }
}

const showErrorMessage = text => ({
  type: 'MESSAGE',
  payload: text
})

export default connect(state => ({user: {...state.login.user}}), { deleteVessel, claimRights, toggleSubscribeDialog, sendOpenPosition, toggleDialogMessage, showErrorMessage, addToSelected, getSnpList })(withStyles(s)(ActionsMenu))
