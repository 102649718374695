import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NarrowFormsyText from './../../../NewInputs/NarrowFormsyText';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import NarrowPortAutocomplete from './../../../NewInputs/NarrowPortAutocomplete';
import NarrowAutocomplete from './../../../NewInputs/NarrowAutocomplete';
import RaisedButton from '../../../Common/RaisedButton';
import tc from '../../../Monitor/TCOrders/TC.scss';
import s from './Request.scss';
import ContentAdd from 'material-ui/svg-icons/content/add';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import { connect } from 'react-redux';
import Collapse from './../../../Common/Collapse';
import { scrollToAnchor } from './../../../../core/utils';
import NarrowFormsyDate from '../../../NewInputs/NarrowFormsyDate';

const POR = ({ por, i, handleChanged }) => (
  <div className={cx("row", s.step_row)}>
    <div className="col-sm-6">
      <NarrowPortAutocomplete
          params={{
            withAreas: true,
          }}
          floatingLabelText="Place of redelivery*"
          validations="isObj"
          validationError="Please, select place of redelivery"
          validationErrors={{
            isObj: 'Please, select place of delivery',
            isDefaultRequiredValue: 'required',
          }}
          requiredError="required"
          name={`redeliveryPlaces[${i}][port]`}
          required
          fullWidth
          value={por.port}
          onNewRequest={handleChanged.bind(this, 'port')}
      />
    </div>
    <div className={cx("col-sm-6", tc.autofill_colored_input)}>
      <NarrowFormsyText
          floatingLabelText="Details (optional)"
          name={`redeliveryPlaces[${i}][details]`}
          fullWidth
          value={por.details}
          onChange={(ev, val) => handleChanged('details', val)}
      />
    </div>
  </div>
)

function POD({ i, pod, pods, main, handleChange, children, alternative = false, field = 'pod', altField = 'pods', attachRef = () => undefined }) {
  const handleChanged = (key, val) => {
    const newPod = pods.find((p, index) => p.id === pod.id);
    const newpods = [...pods];
    newpods[i] = {
      ...newPod,
      [key]: val,
    };
    handleChange(altField, null, newpods);
  };

  const deleteAlt = () => {
    handleChange(altField, null, pods.filter((a, index) => a.id !== pod.id));
  };

  const add = alternative
    ? (
      <NavigationClose onClick={deleteAlt} color="#B2B2B2" style={{ width: 14, height: 14 }} />
    )
    : undefined;

  const { port, details } = pod;

  const title = alternative
    ? port
        ? `OR ${port?.name || port}`
        : main && main.port && main.port.name
            ? `ENTER ALT PLACE FOR ${pods[i-1]?.port?.name || main?.port?.name}`
            : `ENTER ALT PLACE`
    : `${port?.name ? `${field.toUpperCase()}: ${port?.name || ''}` : 'ENTER PORT'}`

  return (
    <Collapse headerClass={tc.port_collapse_header} titleClass={tc.collapse_title} innerRef={attachRef} defaultOpen={alternative} classes={cx(tc[field], alternative && tc.alt)} title={title} additionals={add}>
      <div className={tc.pod_body}>
        {
          children
          ? children(pod, i, handleChanged)
          : (
            <div className={cx("row", s.step_row)}>
              <div className="col-sm-6">
                <NarrowPortAutocomplete
                    params={{
                      withAreas: true,
                    }}
                    floatingLabelText="Place of delivery*"
                    validations="isObj"
                    validationError="Please, select place of delivery"
                    validationErrors={{
                      isObj: 'Please, select place of delivery',
                      isDefaultRequiredValue: 'required',
                    }}
                    requiredError="required"
                    name={`deliveryPlaces[${i}][port]`}
                    required
                    fullWidth
                    value={port}
                    onNewRequest={handleChanged.bind(this, 'port')}
                />
              </div>
              <div className={cx("col-sm-6", tc.autofill_colored_input)}>
                <NarrowFormsyText
                    floatingLabelText="Details (optional)"
                    name={`deliveryPlaces[${i}][details]`}
                    fullWidth
                    value={details}
                    onChange={(ev, val) => handleChanged('details', val)}
                />
              </div>
            </div>
          )
        }
      </div>
    </Collapse>
  );
}

POD.schema = {
  port: "",
  details: "",
  main: false,
};

POR.schema = POD.schema;

export class PlaceAndDates extends Component {

  static wogs = [
    {
      val: 'abt',
      name: 'ABT',
    },
    {
      val: 'agw',
      name: 'AGW',
    },
    {
      val: 'wp',
      name: 'WP',
    },
    {
      val: 'wog',
      name: 'WOG',
    },
    {
      val: 'agw wp',
      name: 'AGW WP',
    },
    {
      val: 'agw wp wog',
      name: 'AGW WP WOG',
    },
    {
      val: 'agw wog',
      name: 'AGW WOG',
    },
    {
      val: 'wp wog',
      name: 'WP WOG',
    },
    {
      val: 'abt agw wp',
      name: 'ABT AGW WP',
    },
    {
      val: 'abt agw wp wog',
      name: 'ABT AGW WP WOG',
    },
    {
      val: 'abt wp wog',
      name: 'ABT WP WOG',
    },
  ]
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this._collapses = [];
  }

  getState = () => {
    return this.state;
  }

  getDefaultState = () => {
    const state = {
      presentPositionOfVessel: this.props.presentPositionOfVessel || '',
      presentPositionOfVesselDetails: this.props.presentPositionOfVesselDetails || '',
      deliveryPlaces: this.props.deliveryPlaces || ([
        {
          ...POD.schema,
          id: Math.random().toString(),
          main: true,
        },
      ]),
      redeliveryPlaces: this.props.redeliveryPlaces || ([
        {
          ...POR.schema,
          id: Math.random().toString(),
          main: true,
        },
      ]),
      readinessDate: this.props.readinessDate || undefined,
      cancellingDate: this.props.cancellingDate || undefined,
      portDatesDetails: this.props.portDatesDetails || '',
      duration: this.props.duration || '',
    };
    return {
      ...state,
    };
  }

  componentDidMount() {
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
  }

  openCollapses = () => {
    let alreadyScrolled = false;
    this._collapses.forEach(({ instance, group }) => {
      if (!instance) return;
      const node = ReactDOM.findDOMNode(instance);
      if (!node) return;
      const containsInvalidInput = node.querySelector('input[data-valid="false"]');
      if (containsInvalidInput) {
        instance.show && instance.show();
        if (!alreadyScrolled) {
          scrollToAnchor(node);
          alreadyScrolled = true;
        }
      }
    })
  }

  handleChange = (field, _, val) => {
    this.setState({
      [field]: val,
    });
  }

  addPOD = (field, schema = POD.schema) => {
    if (this._collapses && this._collapses.length) {
      this._collapses.filter(({ instance, group }) => group.toLowerCase() === field.toLowerCase())
        .forEach(({ instance }) => {
          instance.hide && instance.hide();
        });
    }
    const pods = this.state[field] || [];
    const arr = [
        ...pods,
        {
          ...schema,
          id: Math.random().toString(),
        },
    ];
    if (pods.length < 1) {
      this.handleChange(field, null, [
          {
            ...schema,
            id: Math.random().toString(),
            main: true,
          },
          ...arr,
      ]);
      return;
    }
    this.handleChange(field, null, [
      ...arr,
    ]);
  }

  changePOD = (name = 'deliveryPlaces', field, schema = POD.schema, val) => {
    const pods = this.state[name] || [];
    pods[0] = {
      ...schema,
      ...pods[0],
      [field]: val,
      main: true,
    };

    this.handleChange(name, null, pods);
  }

  refCollapse = (group, id) => instance => instance ? this._collapses.push({ instance, id, group }) : (this._collapses = this._collapses.filter(c => c.id !== id));

  handleTransformObj = (field, key) => val => this.handleChange(key, null, val ? val[field] ? val[field] : val : "")

  handleNext = () => {
    this.openCollapses();
    this.props.handleNext({ ports: this.getState() });
  }

  render() {
    const { deliveryPlaces = [], redeliveryPlaces = [], cancellingDate, readinessDate, duration } = this.state || {};

    const { wogs } = this.constructor;

    const { port, details } = deliveryPlaces[0] || {};
    const { port: redeliveryPort, details: redeliveryDetails } = redeliveryPlaces[0] || {};
    return (

            <div className={cx(s.step, tc.place_dates)}>
              <div className={cx("row", s.step_row)}>
                <div className="col-sm-6" >
                  <NarrowFormsyText
                    floatingLabelText="Present position of vessel"
                    name={"presentPositionOfVessel"}
                    fullWidth
                    maxLength="100"
                    value={""}
                    onChange={this.handleChange.bind(this, 'presentPositionOfVessel')}
                  />
                </div>
                <div className="col-sm-6" >
                  <NarrowFormsyText
                    floatingLabelText="Details (optional)"
                    name={"presentPositionOfVesselDetails"}
                    fullWidth
                    maxLength="100"
                    value={""}
                    onChange={this.handleChange.bind(this, 'presentPositionOfVesselDetails')}
                  />
                </div>
              </div>
              {
                deliveryPlaces?.length > 1
                ? (
                  <div className={tc.col}>
                      {
                        deliveryPlaces.map((pod, i) => <POD key={pod.id} attachRef={this.refCollapse('deliveryPlaces', pod.id)} main={deliveryPlaces[0]} pods={deliveryPlaces} field="pod" altField="deliveryPlaces" i={i} pod={pod} alternative={!pod.main} handleChange={this.handleChange} />)
                      }
                  </div>
                )
                : (
                  <div className={cx("row", s.step_row)}>
                    <div className="col-sm-6">
                      <NarrowPortAutocomplete
                          params={{
                            withAreas: true,
                          }}
                          floatingLabelText="Place of delivery*"
                          validations="isObj"
                          validationError="Please, select place of delivery"
                          validationErrors={{
                            isObj: 'Please, select place of delivery',
                            isDefaultRequiredValue: 'required',
                          }}
                          requiredError="required"
                          name="deliveryPlaces[0][port]"
                          required
                          fullWidth
                          value={port}
                          onNewRequest={this.changePOD.bind(this, 'deliveryPlaces', 'port', POD.schema)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <NarrowFormsyText
                          floatingLabelText="Details (optional)"
                          name="deliveryPlaces[0][details]"
                          fullWidth
                          value={details}
                          onChange={(ev, val) => this.changePOD('deliveryPlaces', 'details', POD.schema, val)}
                      />
                    </div>
                  </div>
                )
              }
              <div>
                  <div className={tc.add_btn}>
                    <ContentAdd
                        color="#285596"
                        onClick={this.addPOD.bind(this, 'deliveryPlaces', POD.schema)}
                    />
                     Add alternative place of delivery
                  </div>
              </div>
              {
                redeliveryPlaces?.length > 1
                ? (
                  <div className={tc.col}>
                      {
                        redeliveryPlaces.map((por, i) => <POD key={por.id} attachRef={this.refCollapse('redeliveryPlaces', por.id)} main={redeliveryPlaces[0]} field="por" pods={redeliveryPlaces} altField="redeliveryPlaces" i={i} pod={por} alternative={!por.main} handleChange={this.handleChange}>
                          {(por, i, handleChanged) => <POR por={por} i={i} handleChanged={handleChanged} />}
                        </POD>)
                      }
                  </div>
                )
                : (
                  <div className={cx("row", s.step_row)}>
                    <div className="col-sm-6">
                      <NarrowPortAutocomplete
                          floatingLabelText="Place of redelivery"
                          validations="isObj"
                          validationError="Please, select place of redelivery"
                          validationErrors={{
                            isObj: 'Please, select place of redelivery',
                            isDefaultRequiredValue: 'required',
                          }}
                          params={{
                            withAreas: true,
                          }}
                          requiredError="required"
                          name="redeliveryPlaces[0][port]"
                          fullWidth
                          value={redeliveryPort}
                          onNewRequest={this.changePOD.bind(this, 'redeliveryPlaces', 'port', POR.schema)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <NarrowFormsyText
                          floatingLabelText="Details (optional)"
                          name="redeliveryPlaces[0][details]"
                          fullWidth
                          value={redeliveryDetails}
                          onChange={(ev, val) => this.changePOD('redeliveryPlaces', 'details', POR.schema, val)}
                      />
                    </div>
                  </div>
                )
              }
              <div>
                  <div className={tc.add_btn}>
                    <ContentAdd
                        color="#285596"
                        onClick={this.addPOD.bind(this, 'redeliveryPlaces', POR.schema)}
                    />
                     Add alternative place of redelivery
                  </div>
              </div>

              <div className={cx("row", s.step_row, tc.dates_row)}>
                <div className="col-sm-4">
                  <NarrowFormsyDate
                    fullWidth
                    name="readinessDate"
                    container="inline"
                    hintText="Readiness date*"
                    floatingLabelText="Readiness date*"
                    requiredError="Define readiness date."
                    autoOk
                    className={tc.date_input}
                    startDate={readinessDate || new Date()}
                    maxDate={cancellingDate || undefined}
                    defaultDate={readinessDate}
                    onChange={this.handleChange.bind(this, 'readinessDate')}
                    required
                  />
                </div>
                <div className="col-sm-4">
                  <NarrowFormsyDate
                    fullWidth
                    name="cancellingDate"
                    container="inline"
                    hintText="Cancelling date*"
                    floatingLabelText="Cancelling date*"
                    requiredError="Define cancelling date."
                    autoOk
                    className={tc.date_input}
                    startDate={readinessDate || new Date()}
                    minDate={readinessDate || undefined}
                    defaultDate={cancellingDate}
                    onChange={this.handleChange.bind(this, 'cancellingDate')}
                    required
                  />
                </div>
                <div className="col-sm-4">
                  <NarrowFormsyText
                    floatingLabelText="Details (optional)"
                    name={"portDatesDetails"}
                    fullWidth
                    maxLength="100"
                    value={""}
                    onChange={this.handleChange.bind(this, 'portDatesDetails')}
                  />
                </div>
              </div>
              <div className={tc.row} style={{ alignItems: 'flex-end' }}>

                  <div className={tc.expand}>
                    <NarrowAutocomplete
                      floatingLabelText="T/C Duration"
                      name="duration"
                      fullWidth
                      value={duration}
                      fixedValues
                      dataSource={wogs}
                      onNewRequest={this.handleTransformObj('name', 'duration')}
                    />
                  </div>

              </div>
            </div>
    );
  }
}

export default connect(state => ({ user: { ...state.login.user } }), null)(withStyles(tc)(PlaceAndDates));
