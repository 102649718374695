import createPipeline from './pipelineFactory';
import { convertPortsToServer, resetCargoId } from './utils';
import { cloneDeep } from 'lodash';
// functions that represent pipeline adaptation of request before passing it down to Preview component

export const preambleStep = request => {
  request = cloneDeep({ ...(request || {}) });
  if (typeof request.preamble === "object") {
    const preambleCopy = { ...request.preamble };

    if (preambleCopy.vessel && typeof preambleCopy.vessel === 'string') {
      preambleCopy.vessel = {
        name: preambleCopy.vessel,
      };
    }
    request = {
      ...request,
      ...preambleCopy,
    };
    if (request.counteragents?.length) {
      request.counteragents = request.counteragents.filter(c => c.company).map(agent => ({ ...agent, company: typeof agent.company === 'object' ? agent.company : { name: agent.company } }))
    }

    Reflect.deleteProperty(request, "preamble");
  }
  return request;
};

export const portsStep = request => {
  const loading = convertPortsToServer(request.loadingPorts);
  const unloading = convertPortsToServer(request.unloadingPorts);
  request.loadingPort = loading[0]?.oneOf?.[0].port;
  request.unloadingPort = unloading[0]?.oneOf?.[0]?.port;
  request.readinessDate = loading[0]?.oneOf?.[0]?.readinessDate;
  request.cancellingDate = loading[0]?.oneOf?.[0]?.cancellingDate;
  request.loading = loading;
  request.unloading = unloading;
  if (request.incoterms && !request.incoterms.kind){
    delete request.incoterms;
  }

  return request;
};

export const cargoStep = request => {
  if (!request.cargo) return request;
  request.cargo.forEach(cargo => {
    if (!cargo?.cargoName) return;
    cargo = resetCargoId(cargo);

    if (!cargo.cargoType) {
      cargo.cargoType = cargo.cargoName.parent.red._id;
    }

    const packing = cargo.packing;

    if (typeof cargo.dangerous?.class === "string") {
      cargo.dangerous.class = cargo.dangerous?.class
        .split(",")
        .map(c => String(c).trim());
    }

    if (packing) {
      if (!packing.category) {
        packing.category = "unpacked";
      }

      if (packing.pack) {
        cargo.packingList = packing.pack.packingList;

        if (typeof packing.pack.stowageSpread === 'undefined') {
          packing.pack.stowageSpread = false;
        }

        if (packing.pack.densities) {
          packing.pack.densities = packing.pack.densities.filter(d => d.density);
        }
        if (cargo.typeOfTransportation === 'container') {
          cargo.containerDetails = cargo.packing.pack.containerDetails;
        }

        if (cargo.packingList) {
          cargo.packingList = cargo.packingList.filter(row => {
            const keys = Object.keys(row);

            for (let i = 0; i < keys.length; i++) {
              if (row[keys[i]]) {
                return true;
              }
            }
            return false;
          });

          if (cargo.packingList.length === 0) {
            cargo.packingList = undefined;
          }
        }

        packing.pack.packingList = undefined;
        Object.keys(packing.pack).forEach(key => {
          const o = packing.pack[key];
          let hasValue = false;
          if (Object.prototype.toString.call(o) === '[object Object]') {
            const keys = Object.keys(o ?? {});
            // if field doesn't have value* (value, valueMin, valueMax, ...), count it as empty and delete it
            hasValue = keys.find(key => /value\w*/.test(key) && !!o[key]);
          } else if (Array.isArray(o)){
            hasValue = o.length;
          } else {
            hasValue = o ?? false;
          }
          if (!hasValue) {
            packing.pack[key] = undefined;
          }
        });
      }
    }

    if (cargo.clauses && !Array.isArray(cargo.clauses)) {
      const clauses = [];

      Object.keys(cargo.clauses).forEach(key => {
        const clause = cargo.clauses[key];

        if (clause.value === false) {
          return;
        }

        clauses.push({ name: key, value: clause.value });
      });

      cargo.clauses = clauses;
    }
  });

  request.cargoType = request.cargo?.[0]?.cargoType;

  return request;
};

export const termsStep = request => {
  if (!request.terms) {
    request.terms = {};
  }

  if (!request.terms.default) {
    request.terms.default = [];
  }

  if (!request.terms.user) {
    request.terms.user = [];
  }

  request.terms.default = request.terms.default.filter(t => t);

  return request;
};

const previewPipeline = createPipeline([preambleStep, portsStep, cargoStep, termsStep]);

export default previewPipeline;
