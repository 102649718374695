/*
 * @Author: salterok
 * @Date: 2017-10-05 19:23:13
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-11-02 14:08:37
 */

import React from "react";
import { connect } from "react-redux";
import { ListItem } from "material-ui/List";
import s from "../Chat.scss";
import cx from "classnames";
import Avatar from "material-ui/Avatar";
import { selectAllContactMessages, selectContactRooms, getUnreadContactMessages, getMe, getContactNamesPair, getShortName } from "../selectors";
import { BlurEmailIfNoSubscription } from '../../Signup/BlurIfNoSubscription';

// TODO: connect to store for user and last message info

export function ContactItem_(props) {
  const { user, lastMessage, unreadCount, isOnline, selectedContact, selectRoom } = props;
  const isSelected = selectedContact === user._id

  const shortName = getShortName(user);
  const [contactName, companyName] = getContactNamesPair(user);

  return (
    <ListItem
      hoverColor={"transparent"}
      value={user._id}
      onClick={() => selectRoom(user._id)}
      style={{
        marginTop:"8px",
        borderRadius: "4px",
        backgroundColor: (isSelected ?
            "rgba(67, 128, 199, 0.15)"
            : "transparent"
        )
      }}
      innerDivStyle={{
        height:"inherit",
        paddingTop:"4px",
        paddingBottom:"4px",
        paddingLeft:"64px",
        borderBottom:(isSelected ?
            "1px solid transparent"
            : "1px solid #E6E6E6"
        )
      }}
      className={cx(s.wrapper_contact, selectedContact && s.minimized)}
      leftAvatar={
        <div
          className={s.wrapper_avatar}
          style={{
            top:"7px",
            left:"8px",
            paddingBottom:"5px",
            borderBottom:(!unreadCount && isSelected ?
              "1px solid #E6E6E6"
              : "1px solid transparent"
            )
          }}
        >
          {isOnline && (
            <div className={s.online_circle} />
          )}

          <span
            className={s.active_contact}
            style={{
              backgroundColor: user.active ? "#7FC931" : "#FFFFFF",
              border: unreadCount ? "2px solid #ECF7E0" : "2px solid #FFFFFF",
              boxShadow: user.active ? "0 0 0 1px #7FC931 inset" : "0 0 0 1px #9c9c9c inset",
            }}
          />
          <Avatar className={s.avatar} src={user.avatar} >
            { shortName }
          </Avatar>
        </div>
      }
      primaryText={
        <div>
          <div className={s.name_contact}>
            <BlurEmailIfNoSubscription><p title={contactName}>{ contactName }</p></BlurEmailIfNoSubscription>
            <span>{formatDate(lastMessage && lastMessage.sentAt)}</span>
          </div>
          <BlurEmailIfNoSubscription><div title={companyName} className={s.company_contact}>{ companyName }</div></BlurEmailIfNoSubscription>
          {!!unreadCount && (<span className={s.number_message}>{unreadCount}</span>)}
        </div>
      }
      secondaryText={
        <div
          className={s.message}
          style={{
            display:"flex",
            height:"25px",
            marginTop: "0"
          }}
        >
          <p>
            {lastMessage && lastMessage.content || "No last message available"}
          </p>
          <span className={s.number}>
        {user.refId}
      </span>
        </div>
      }
      secondaryTextLines={2}
    />
  );
}

/**
 *
 * @param {Date} date
 */
function formatDate(date) {
  if (!date) {
    return "";
  }

  return date.toLocaleDateString();
}


export const ContactItem = connect(
  (state, props) => {

    const me = getMe(state);

    const { user } = props;

    const messages = selectAllContactMessages(state, user._id);
    const rooms = selectContactRooms(state, user._id);


    let lastMessage = undefined;
    if (messages.length > 0) {
      lastMessage = messages[messages.length - 1];
    }

    const unreadCount = getUnreadContactMessages(state, me, user._id).length;

    return {
      lastMessage,
      unreadCount
    };
  }
)(ContactItem_);
