function areFiltersApplied(filtersData) {
    if(!filtersData) return false;
    const filters = JSON.parse(JSON.stringify(filtersData))
    if(filters.sort) filters.sort=''
    if(filters.tab) delete filters.tab
    if(filters.tags) {
      if(filters.tags?.condition?.length >0) return true;
      else filters.tags=''
    }
    if(filters.filterKey) delete filters.filterKey;
    if(filters.filterId) delete filters.filterId;
    if (Object.keys(filters).length === 0) {
      return false;
    }
    return Object.values(filters).some(isValidElement);
  }

  function isValidElement(element) {
    if (typeof element === 'object' && element !== null) {
      if (Array.isArray(element)) {
        if(element.length > 0 && element.some(item => Boolean(item))) return true;
      } else {
        if(Object.keys(element).length > 0 && Object.values(element).some(item => !Boolean(item))) return true;
      }
    } else if (typeof element === 'string') {
      if (element !== '') return true;
    } else if (typeof element === 'number' || typeof element === 'boolean') {
      return true;
    }
    return false;
  }
  
  export {areFiltersApplied}
