import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import Form from '../About/Form';
import Link from '../../Link';
import RoundThemableButton from '../../Common/RoundThemableButton';
import TradingDesk from '../../Icons/TradingDesk';
import Exchange from '../../Icons/Exchange';
import FleetMonitor from '../../Icons/FleetMonitor';
import CargoMonitor from '../../Icons/CargoMonitor';
import Documents from '../../Icons/Documents';
import PortsIcon from '../../Icons/PortsIcon';

class PageSolutionsDocuments extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_documents_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Solution of Contract management,<br />Charter Party printing</h1>
                </div>

                <div className={s.wrapper_icon}>
                  <Documents />
                  <p>SHIPNEXT Documents</p>
                </div>
                <div className={s.wrapper_links}>
                  <a href="#how-it-works" className={s.wrapper_links_link}>How it works</a>
                  <a href="#use-case" className={s.wrapper_links_link}>Use Case</a>
                  {/* {!this.props.user && <RoundThemableButton className={s.wrapper_links_link_color} component={Link} to={'/register'}>Register</RoundThemableButton>} */}
                </div>
              </div>
              <div className={s.right_part}>
                <Form
                  label={'Request demo'}
                  requestType={'shipnextDemo'}
                />
                <button
                  onClick={this.props.handleChangeForm.bind(this, 'Request demo', 'shipnextDemo')}
                >
                  Request demo
                </button>
              </div>
            </div>

          </div>
        </div>

        <span className={s.anchor} id="how-it-works" />
        <div className={cx(s.how_it_works, s.how_it_works_doc)}>
          <div className={s.container}>
            <h2 className={s.title_block}>How it works</h2>
          </div>
          <div className={s.first_step}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>1</div>
                  <div className={s.right_part}>
                    <div className={s.wrapper_info}>
                      <div className={s.info_left}>
                        <h3>Why use SHIPNEXT Documents?</h3>
                        <p className={s.description}>
                        Creating and storing Charter Parties and Contracts is still usually done the old way. This is usually a manual process, that involved manual work. Analytics performed thereafter is also time consuming and is usually carried out quarterly or annually.
                        </p>
                        <p className={s.description}>
                        SHIPNEXT provides tools based on Artificial Intelligence to create Contracts, pull out valuable terms and conditions from the contract to track execution and compliance with the agreed terms. In addition, the commercial data stored in the Contracts can be used for real-time KPI and analytics.
                        </p>
                      </div>

                      <div className={s.info_right}>
                        <div className={s.wrapper_image}>
                          <img src="/images/solutions/doc_step_1.png" alt="Why use SHIPNEXT Documents?" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className={cx(s.second_step, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>2</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Interconnectivity and Interoperability</h3>
                      <p className={s.description}>
                      SHIPNEXT provides interoperability and interconnectivity between the different parties involved in the transportation process. Users can choose between using the Data, stored in the Contracts, Charter Parties or Bills of Lading privately, or share it while building up their digital interconnectivity with other players in the supply-chain. Building digital interconnectivity and interoperability can help build a more reliable ecosystem and ensure efficient cargo delivery.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/pages/diagram-doc.png" alt="Interconnectivity and Interoperability" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.third_step, s.divide_figure)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>3</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Facilitating Freight negotiations</h3>
                      <p className={s.description}>
                      Terms and Conditions stored in the Charter Parties and Contracts on SHIPNEXT can be properly indexed, and reused multiple times in the future for all further quotations, tenders, freight negotiations and contracting. SHIPNEXT Users can also create templates up front, in order to use them in their daily work on the Trading Desk. It takes up to two weeks to upload any Customized Charter Party, Booking Note or Contract form.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/doc_step_3.png" alt="Facilitating Freight negotiations" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.fourth_step, s.container_solutions, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>4</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Electronic Bills of Lading</h3>
                      <p className={s.description}>
                      SHIPNEXT offers a unique solution for creating Electronic Bills of Lading. Shippers, Traders or Carriers can themselves define, agree upon and invite multiple signatories or data sources to create and eB/L.
                      </p>
                      <p className={s.description}>
                      SHIPNEXT promotes the use of satellite data to ensure the actual timely arrival of the vessel at port, interconnectivity with the port and terminal data and even surveyor companies with the aim of ensuring the use of reliable data in the transaction.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/doc_step_4.png" alt="Electronic Bills of Lading" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="use-case" />
        <div className={cx(s.use_case, s.use_case_doc)}>
          <div className={s.container}>
            <h2 className={s.title_block}>Use Case</h2>
            <div className={s.wrapper_use_case}>
              <div className={s.wrapper_svg}>
                <Documents />
              </div>
              <div className={s.description_use_case}>
                <ul>
                  <li><h3>Carriers or Shipping Companies</h3> use this solution to create and store Charter Parties, Contracts, Booking Notes and Bills of Lading, and use the terms & conditions stored therein for quotations and firm negotiations in the future. SHIPNEXT also allows to integrate customized Charter Parties, Contracts or Booking Note forms, and upload the standard individual terms and conditions.<br />
                  Terms and Conditions stored in the Contracts can be used to track execution and facilitate legal process if the agreed terms and conditions are not met.<br />
                  Electronic Bills of Lading, available on SHIPNEXT, can be used independently or integrated with other digital and blockchain infrastructure.<br />
                  <p>SHIPNEXT Documents can be used to build valuable analytics, manage CRM or track KPIs.</p></li>
                  <li><h3>Shipbrokers</h3> use this solution to facilitate the process of building and printing charter parties and Contracts. SHIPNEXT allows copy-pasting the recap, thus simplifying the process of creating a Charter Party even if the negotiations were held through the old way of exchanging counters through email or chat. SHIPNEXT Documents can be used to build valuable analytics, manage CRM or track KPIs.</li>
                  <li><h3>Traders and Shippers</h3> use this solution to create and store Charter Parties, Contracts, Booking Notes and Bills of Lading, and use the terms & conditions stored therein for quotations and firm negotiations in the future. SHIPNEXT also allows to integrate customized Charter Parties, Contracts or Booking Note forms, and upload the standard individual terms and conditions. Terms and Conditions stored in the Contracts can be used to track execution and facilitate legal process if the agreed terms and conditions are not met.<br />
                  Electronic Bills of Lading, available on SHIPNEXT, can be used independently or integrated with other digital and blockchain infrastructure.<br />
                  <p>SHIPNEXT Documents can be used to build valuable analytics, manage CRM or track KPIs.</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <span className={s.anchor} id="other-solutions" />
        <div className={s.other_solutions}>
          <div className={s.container}>
            <h2 className={s.title_block}>Other solutions</h2>
            <div className={s.links_other_solutions}>
              <Link className={s.part_link} to="/solution-shipnext-marketplace">
                <div>
                  <TradingDesk />
                  <h3>SHIPNEXT<br /> Trading Desk</h3>
                </div>
                <p>Ship Next Your Cargo</p>
              </Link>
              <Link className={s.part_link} to="/solution-shipnext-cargo-monitor">
                <div>
                  <CargoMonitor />
                  <h3>SHIPNEXT<br /> Cargo Monitor</h3>
                </div>
                <p>Digitize and Automate Your Supply Chain</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-fleet-monitor'}>
                <div>
                  <FleetMonitor />
                  <h3>SHIPNEXT<br /> Fleet Monitor</h3>
                </div>
                <p>Track Your Fleet</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-exchange'}>
                <div>
                  <Exchange />
                  <h3>SHIPNEXT <br /> Exchange</h3>
                </div>
                <p>Optimize E-mail management and stay Connected</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-ports'}>
                <div>
                  <PortsIcon />
                  <h3>SHIPNEXT <br /> Ports</h3>
                </div>
                <p>Port Data-search Base</p>
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(s)(PageSolutionsDocuments);
