//noinspection JSUnusedGlobalSymbols
/** @name MONITOR_DRAGSTART_CARGO_REDUCER */
export default function MONITOR_DRAGSTART_CARGO(state, action) {
  const newState = {};
  const cargo = action.payload;
  const tons = cargo.totalValues.weight / 1000;
  const vesselList = { ...state.vesselList };
  newState.vesselList = vesselList;
  vesselList.data = vesselList.data.map((v) => {
    if (!v.addedBy) {
      v.disabled = true;
      return v;
    }
    v = { ...v };
    v.disabled = v.dwt < tons; // || (v.addedBy && cargo.addedBy && v.addedBy._id === cargo.addedBy)
    return v;
  });
  return { ...state, ...newState };
}
