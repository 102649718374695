import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Android = props => (
  <span {...props}>
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1">

        <title>Android</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Start"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Shipnext-1440" transform="translate(-341.000000, -1458.000000)" fill="#ffffff">
            <g id="Group-11" transform="translate(100.000000, 1381.000000)">
                <g id="Group-2" transform="translate(216.000000, 60.000000)">
                    <g id="android-ico" transform="translate(25.000000, 14.000000)">
                        <g transform="translate(0.000000, -0.000000)" id="Combined-Shape">
                            <path d="M20.5623618,12.7694167 C20.8125197,12.9002917 20.9620066,13.1345833 20.9619605,13.3960417 C20.9619605,13.6574583 20.8124737,13.89175 20.5623158,14.0222917 L16.0215724,16.3935417 L12.8340395,13.60575 L16.3217434,10.5554583 L20.5623618,12.7694167 Z M15.6019408,10.1792083 L12.2593487,13.1029583 L0.708059211,3 L10.9353355,7.74179167 L15.6019408,10.1792083 Z M0.877901316,24.0624583 L12.2593487,14.1085 L15.3018158,16.7697083 L10.9528816,19.0411667 L0.877901316,24.0624583 Z M0.0257434211,3.40895833 L11.6845197,13.6055417 L0.0149210526,23.8119167 L0.0257434211,3.40895833 Z" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        </g>
      </svg>
  </span>
);

Android = pure(Android);
Android.displayName = 'Android';
Android.muiName = 'SvgIcon';

export default Android;
