import React from 'react'
import s from './CustomTooltip.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

/**
 * Is styled, but isn't suitable when the tooltip content is wider than a container with `overflow: auto`.
 */
export const CustomTooltip = withStyles(s)(function ({
  children,
  dark = true,
  horizontalAlign = "center",
  verticalAlign = "bottom",
  tooltip,
  className = "",
  tooltipStyle= {},
  show,
  noArrow,
  hide = false,
  ...rest
}) {
  if (hide) return children;
  return (
    <div
      className={
        cx(
          s.tooltip_wrapper,
          horizontalAlign === "right" ? s.right_aligned : "",
          horizontalAlign === "left" ? s.left_aligned : "",
          verticalAlign === 'top' && s.top_aligned,
          verticalAlign === 'center' && s.verticalCenterAligned,
          show && s.show,
          noArrow && s.noArrow,
        )
      }
      {...rest}
    >
      { children }
      <div style={tooltipStyle} className={cx(s.tooltip_wrapper_tip, dark ? s.dark : "", className)}>
        { tooltip }
      </div>
    </div>
  )
})
