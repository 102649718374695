import produce from 'immer';

/** @name EMAILS_CHANGE_TAGS_REDUCER */
export default function EMAILS_CHANGE_TAGS(state, action) {
  return produce(state, draft => {
    const messageInList = draft.emailList.data.find( m => m.id === action.payload.id);
    if (draft.details.selectedEmail?.id === action.payload.id) {
      draft.details.selectedEmail.tags = action.res.data;
    }
    if (messageInList) {
      messageInList.tags = action.res.data.map(tag => tag.value);
    }
  });
}
