/**@see  {@link PORT_GET_CHANGES_REDUCER}*/
export const PORT_GET_CHANGES = 'PORT_GET_CHANGES';
/**@see  {@link PORT_GET_CHANGES_REQUEST_REDUCER}*/
export const PORT_GET_CHANGES_REQUEST = 'PORT_GET_CHANGES_REQUEST';
/**@see  {@link PORT_GET_CHANGES_FAILED_REDUCER}*/
export const PORT_GET_CHANGES_FAILED = 'PORT_GET_CHANGES_FAILED';

/**@see  {@link PORT_UPDATE_REDUCER}*/
export const PORT_UPDATE = 'PORT_UPDATE';
/**@see  {@link PORT_UPDATE_REQUEST_REDUCER}*/
export const PORT_UPDATE_REQUEST = 'PORT_UPDATE_REQUEST';
/**@see  {@link PORT_UPDATE_FAILED_REDUCER}*/
export const PORT_UPDATE_FAILED = 'PORT_UPDATE_FAILED';

/**@see  {@link PORT_UPSERT_PROVIDER_REDUCER}*/
export const PORT_UPSERT_PROVIDER = 'PORT_UPSERT_PROVIDER';
/**@see  {@link PORT_UPSERT_PROVIDER_REQUEST_REDUCER}*/
export const PORT_UPSERT_PROVIDER_REQUEST = 'PORT_UPSERT_PROVIDER_REQUEST';
/**@see  {@link PORT_UPSERT_PROVIDER_FAILED_REDUCER}*/
export const PORT_UPSERT_PROVIDER_FAILED = 'PORT_UPSERT_PROVIDER_FAILED';

/**@see  {@link PORT_GET_LIST_REDUCER}*/
export const PORT_GET_LIST = 'PORT_GET_LIST';
/**@see  {@link PORT_GET_LIST_REQUEST_REDUCER}*/
export const PORT_GET_LIST_REQUEST = 'PORT_GET_LIST_REQUEST';
/**@see  {@link PORT_GET_LIST_FAILED_REDUCER}*/
export const PORT_GET_LIST_FAILED = 'PORT_GET_LIST_FAILED';

/**@see  {@link PORT_CREATE_REDUCER}*/
export const PORT_CREATE = 'PORT_CREATE';
/**@see  {@link PORT_CREATE_REQUEST_REDUCER}*/
export const PORT_CREATE_REQUEST = 'PORT_CREATE_REQUEST';
/**@see  {@link PORT_CREATE_FAILED_REDUCER}*/
export const PORT_CREATE_FAILED = 'PORT_CREATE_FAILED';

/**@see  {@link SET_PORT_DIALOG_ID_REDUCER}*/
export const SET_PORT_DIALOG_ID = 'SET_PORT_DIALOG_ID';
