import React, { Component } from 'react';
import { connect } from "react-redux";
import { createCompany, getCompanyDetails, updateCompany } from "../../actions/contacts";
import Loader from "../Common/Loader";
import CompanyView from './CompanyView';
import {ContactDetails} from "./ContactDetails";
import {CompanyEditConnected} from "./CompanyEdit";

export class CompanyDetails extends ContactDetails {

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    if (!this.state.contact?.id) {
      return this.renderError();
    }
    if (this.props.action === 'edit') {
      return <div style={{ height: "100%" }}>
        <CompanyEditConnected contact={this.state.contact} onClose={this.closeEdit} action={'edit-contact'} />
      </div>
    }
    return (
      <div style={{ height: "100%" }}>
        <CompanyView openEmailsList={this.props.openEmailsList} closeEmailsList={this.props.closeEmailsList} company={this.state.contact} onEdit={this.startEdit} updateCompany={this.props.updateCompany}/>
      </div>
    );
  }
}

export const CompanyDetailsConnected = connect(state => ({ ...state.contacts, user: state.login.user, tagCategoriesByName: state.login.tagCategoriesByName }), { createContact: createCompany, getContactDetails: getCompanyDetails, updateCompany })(CompanyDetails);
