import React, { PureComponent } from "react";
import s from "./PublicPort.scss";
import cx from "classnames";

export default class PortWeather extends PureComponent {

  render() {
    const weather = this.props.weather;
    function getDay(date) {
      const day = new Date(date);
      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const dateToRender = `${weekdays[day.getDay()]} ${day.getDate()}`;
      return dateToRender
    }

    function getTempIntegers(t) {
      const tempArr = t.split("-");
      const tempToRender = tempArr.map((item => (
        item !=='' ? Math.round(+item) : ''
      ))).join('-')
      return tempToRender.replace(/(\d)-/g, "$1 - ").replace(/-0/g, "0");
   }

    if(!weather?.current?.condition) return null;
    return (

      <div className={s.wrapper_weather}>
        <div className={s.wrapper_weather_title}>Weather in port</div>
        <div className={s.wrapper_weather_body}>
          <div className={s.wrapper_weather_column}>
            <div></div>
            <div style={{height: '42px'}}>Forecast</div>
            <div>Temperature</div>
            <div>Wind Speed</div>
            <div>Wind Direction</div>
            <div>Precipitation</div>
          </div>
          {weather.previousDays?.length && weather.previousDays.map((day => (
          <div className={s.wrapper_weather_column}>
            <div>{getDay(day.date)}</div>
            <div style={{height: '42px'}}><img className={s.forecast_icon} src={day.condition.icon} alt="forecast-image"/></div>
            <div>{getTempIntegers(day.temp_c)}°C</div>
            <div>{day.wind_kph || ''} km/h</div>
            <div><span>{day.wind_dir?.toUpperCase()}</span><img src="/images/weather/arrow.svg" alt="arrow" style={{transform: `rotate(${day.wind_degree || '0'}deg)`}}/></div>
            <div>{Math.max(+day.daily_chance_of_rain, +day.daily_chance_of_snow.toString()) || ''}%</div>
          </div>
          )))}
          <div className={s.wrapper_weather_column}>
            <div className={s.cell_active}>Today</div>
            <div style={{height: '42px'}}><img className={s.forecast_icon} src={weather.current.condition.icon} alt="forecast-image"/></div>
            <div>{getTempIntegers(weather.current.temp_c)}°C</div>
            <div>{weather.current.wind_kph || ''} km/h</div>
            <div><span>{weather.current.wind_dir?.toUpperCase()}</span><img src="/images/weather/arrow.svg" alt="arrow" style={{transform: `rotate(${weather.current.wind_degree || '0'}deg)`}}/></div>
            <div>{Math.max(+weather.current.daily_chance_of_rain, +weather.current.daily_chance_of_snow).toString() || ''}%</div>
          </div>
          {weather.forecast?.length && weather.forecast.map((day => (
          <div className={s.wrapper_weather_column}>
            <div>{getDay(day.date)}</div>
            <div style={{height: '42px'}}><img className={s.forecast_icon} src={day.condition.icon} alt="forecast-image"/></div>
            <div>{getTempIntegers(day.temp_c)}°C</div>
            <div>{day.wind_kph || ''} km/h</div>
            <div><span>{day.wind_dir?.toUpperCase()}</span><img src="/images/weather/arrow.svg" alt="arrow" style={{transform: `rotate(${day.wind_degree || '0'}deg)`}}/></div>
            <div>{Math.max(+day.daily_chance_of_rain, +day.daily_chance_of_snow).toString() || ''}%</div>
          </div>
          )))}
        </div>
      </div>
    )
  }
}
