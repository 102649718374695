const defaultState = {
  selectable: false,
  selectedElements: {
    cargos: [],
    vessels: [],
    positions: [],
    ais: [],
    orders: [],
    mails: [],
    mailThreads: [],
    snpVessels: [],
    myVessels: [],
  },
};
import createReducer from '../../../reducers/createReducer';
import reducers from './reducers';

export default createReducer(reducers, defaultState);
