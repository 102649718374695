import PropTypes from 'prop-types';
import React, { Component } from "react";
import { connect } from "react-redux";
import CustomCheckbox from "../../Common/CustomCheckbox";

import Vessel from "../../.././core/api/Vessel";
import { getSnpList } from "../../../actions/vessel";
import BlurIfNoSubscription from "../../Signup/BlurIfNoSubscription";
import { debounceWithoutFirstCall as debounce } from './../../../core/utils';

class ForSaleCheckbox extends Component {

  static defaultProps = {
    setCheckbox: () => undefined
  }

  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      disabled: false
    };
  }

  changeOwnStateAndParentState = ({ checked, disabled}) => {
    this.setState({
      checked,
      disabled
    });
    this.props.setCheckbox(checked);
  }

  handleStateUpdate = props => {
    const { changeOwnStateAndParentState } = this;

    if (props.salesOffers.ownerOffer) {
      changeOwnStateAndParentState({ checked: true, disabled: true });
      // this.setState({ checked: true, disabled: true });
      return;
    }

    switch (props.salesOffers.checkboxStatus) {
      case 2:
        changeOwnStateAndParentState({ checked: true, disabled: true });
        // this.setState({ checked: true, disabled: true });
        break;
      case 0:
        changeOwnStateAndParentState({ checked: false, disabled: false });
        /* this.setState({
          checked: false,
          disabled: false
        }); */
        break;
      case 1:
        changeOwnStateAndParentState({ checked: true, disabled: false });
        /* this.setState({
          checked: true,
          disabled: false
        }); */
        break;
    }
  };

  componentWillMount() {
    this.handleStateUpdate(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps))
      this.handleStateUpdate(nextProps);
  }

  handleChange = debounce(event => {
    this.setState({ checked: !this.state.checked }, () => {

      let promise = null;
      // const promise = new Promise(resolve => setTimeout(resolve, 2000));

      if (this.state.checked) {
        promise = Vessel.markAsForSale(this.props.vessel._id)
      }
      else if (this.props.salesOffers.myOffer && !this.state.checked) {
        promise = Vessel.deleteOfferByVessel(this.props.salesOffers.myOffer._id);
      }
      else {
        return;
      }
      promise
        .then(() => {
          this.props.getForSaleInfo(this.props.vessel._id);
          this.props.getSnpList();
          this.props.refetchVessel && this.props.refetchVessel();
        })
        .catch(err => {
          this.setState({ checked: false });
        });
    });
  }, 700);

  render() {
    const { children } = this.props;
    const el = children && typeof children === 'function'
      ? children({ checked: this.state.checked, disabled: this.state.disabled, onChange: this.handleChange })
      : (
        <div
          style={{
            display: "flex",
            maxWidth: "100px",
            alignItems: "center",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            paddingTop: "10px"
          }}
        >
          <CustomCheckbox
            style={{ display: this.state.disabled ? "none" : "block" }}
            disabled={this.state.disabled}
            checked={this.state.checked}
            id={"checkboxForSale"}
            size={"small"}
            onClick={this.handleChange}
          />
          <label
            htmlFor={"checkboxForSale"}
            style={{
              whiteSpace: "nowrap",
              cursor: "pointer",
              marginLeft: "-8px",
              marginTop: "2px",
              fontWeight: this.state.checked ? "bold" : "normal",
              color: this.state.checked ? "#333333" : "#999999" }}
          >
            For Sale
          </label>
        </div>
      )
    return el;
  }
}

export default connect(null, { getSnpList })(ForSaleCheckbox);
