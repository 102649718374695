import Api from './api';
/**
 * @class
 */
export default class Company extends Api {
  static async linkAccountToIntegrator(id) {
    try {
      const res = await Api.fetch(`/integrators/linkAccount?integratorId=${id}`, {
        method: 'POST',
        body: JSON.stringify({
          accept: true
        })
      }, '/api/v2');
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  static async rememberIntegrator(id) {
    try {
      const res = await Api.fetch(`/integrators/linkAccount?integratorId=${id}`, {
        method: 'POST',
      }, '/api/v2');
      return res;
    } catch (error) {
      console.log(error);
    }
  }
  static async rejectIntegrator(id) {
    try {
      const res = await Api.fetch(`/integrators/linkAccount?integratorId=${id}`, {
        method: 'POST',
        body: JSON.stringify({
          accept: false
        })
      }, '/api/v2');
      return res;
    } catch (error) {
      console.log(error);
    }
    
  }
  static async getIntegratorInfo(integratorId) {
    try {
      const res = await Company.fetch(`/integrators/${integratorId}`, {
        method: 'GET'
      }, '/api/v2');
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
  static endpoint = '/companies';
  static find(params) {
    params.query = params.name;
    return Api.find.call(this, params);
  }
}
