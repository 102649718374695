export const fillStyleMap = {
  'BULK': '#A4F23C',
  'BULKTANK': '#D0ED93',
  'CAR': '#2FC3CC',
  'CONT': '#C1DAFF',
  'LIVESTOCK': '#FFCE22',
  'MPP': '#71E5FF',
  'PASS': '#93FFE5',
  'PASSCAR': '#8094FF',
  'REEFER': '#73A423',
  'TANK': '#F06451',
  'OTHER': '#AFD6CC',
}
export const strokeStyleMap = {
  'BULK': '#294700',
  'BULKTANK': '#3A5600',
  'CAR': '#003033',
  'CONT': '#334880',
  'LIVESTOCK': '#8E6700',
  'MPP': '#004B5C',
  'PASS': '#3D6572',
  'PASSCAR': '#001375',
  'REEFER': '#3C6100',
  'TANK': '#700404',
  'OTHER': '#00634B',
}


const stoppedPath = 'M0,4a4,4 0 1,0 8,0a4,4 0 1,0 -8,0';
const movingPath = 'M5 1 L1 13 L3 17 L5 14 L7 17 L9 13 L5 1';
export function getIconForGoogleMap(vessel = {}, pos){
  const type = vessel.typeByPurpose || vessel.details?.typeByPurpose || vessel.details?.type || vessel.type;
  return {
    path: pos.speed > 0.1 ? movingPath : stoppedPath,
    fillColor: fillStyleMap[type] || fillStyleMap['OTHER'],
    fillOpacity: 1,
    strokeColor: strokeStyleMap[type] || strokeStyleMap['OTHER'],
    rotation: pos.angle,
    anchor: new google.maps.Point(4, 8),
  }
}
