import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';


export default class Highlight extends PureComponent {
  static propTypes = {
    children: PropTypes.string,
    search: PropTypes.instanceOf(RegExp),
    refNo: PropTypes.string,
  };


  render() {

    const { title } = this.props;

    if (!this.props.search || !this.props.children || !this.props.children.replace) {
      return <span title={title ? title : ''}>{this.props.children}</span>;
    }
    let previousMatchIndex = 0;
    const highlighted = [];
    this.props.children.replace(this.props.search, (match, offset) => {
      const before = this.props.children.substring(previousMatchIndex, offset);
      previousMatchIndex = offset + match.length;
      highlighted.push(before);
      highlighted.push(<span data-refno={this.props.refNo} className="highlight">{match}</span>);
    });

    const after = this.props.children.substring(previousMatchIndex);
    highlighted.push(after);
    return <span title={title ? title : ''}>{highlighted.map((t, i) => <span key={i}>{t}</span>)}</span>;
  }
}
