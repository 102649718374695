// functions that represent pipeline adaptation of request before sending it to the server
// this mainly exists because backend can accept totally different interfaces for same values
// so we need to map existing structure to one that backend accepts.

import { clearEmptyStrings, toUTC } from '../../../core/utils';
import createPipeline from './pipelineFactory';
import { resetCargoId } from './utils';
import { cloneDeep } from 'lodash';

export const simpleFormStep = request => {
  request = cloneDeep(request);
  if (
    !request.isUsedRecognition ||
    !String(request.simpleForm).trim().length
  ) {
    Reflect.deleteProperty(request, "simpleForm");
    Reflect.deleteProperty(request, "isUsedRecognition");
    Reflect.deleteProperty(request, "mailId");
  }

  return request;
};

export const portsStep = request => {
  request.readinessDate = toUTC(request.readinessDate);
  request.cancellingDate = toUTC(request.cancellingDate);
  request.loading = request.loading.map(lg =>
    ({
      ...lg,
      oneOf: lg.oneOf.map(l => ({
        ...l,
        readinessDate: toUTC(l.readinessDate),
        cancellingDate: toUTC(l.cancellingDate),
        port: l.port._id,
      })),
    })
  );
  request.unloading = request.unloading.map(lg =>
    ({
      ...lg,
      oneOf: lg.oneOf.map(l => ({
        ...l,
        port: l.port._id,
      })),
    })
  );
  request.loadingPort && (request.loadingPort = request.loadingPort._id);
  request.unloadingPort && (request.unloadingPort = request.unloadingPort._id);
  if (request.clientDate) {
    request.clientDate = toUTC(request.clientDate);
  }
  return request;
};

export const cargoStep = request => {
  request.cargo = request.cargo.filter(c => !!c);
  request.cargo = request.cargo.map(cargo => {
    cargo = resetCargoId(cargo);
    cargo.cargoName =
      cargo.cargoName && (cargo.cargoName.name || cargo.cargoName);
    if (typeof cargo.hsCode === "object") {
      cargo.hsCodeId = cargo.hsCode._id;
    }
    if (cargo.typeOfTransportation === 'container') {
      cargo.containerDetails = cargo.containerDetails || cargo.packing.pack.containerDetails;
    }
    return cargo;
  });
  if (request.charges) {
    request.charges = request.charges.filter(c => c.value);
  }
  return request;
};

export const filesAdapter = request => {
  if (request.files) {
    request.files = request.files.filter(f => !!f._id).map(f => f._id);
  }

  return request;
};

const finalStep = request => {
  if (!request.ldRates.loading.type) {
    request.ldRates.loading.type = "";
  }
  if (!request.ldRates.discharging.type) {
    request.ldRates.discharging.type = "";
  }
  if (request.ldRates.loading.quantity === "") {
    request.ldRates.loading.quantity = undefined;
  }
  if (request.ldRates.discharging.quantity === "") {
    request.ldRates.discharging.quantity = undefined;
  }
  if (typeof request.account === "string") {
    request.account = { name: request.account };
  }
  return request;
}

const serverPipeline = createPipeline([simpleFormStep, portsStep, cargoStep, filesAdapter, req => clearEmptyStrings(req, true), finalStep]);

export default serverPipeline;
