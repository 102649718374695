import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import Form from '../About/Form';
import Link from '../../Link';
import RoundThemableButton from '../../Common/RoundThemableButton';
import TradingDesk from '../../Icons/TradingDesk';
import Exchange from '../../Icons/Exchange';
import FleetMonitor from '../../Icons/FleetMonitor';
import CargoMonitor from '../../Icons/CargoMonitor';
import Documents from '../../Icons/Documents';
import PortsIcon from '../../Icons/PortsIcon';

class PageSolutionsPorts extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_ports_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Solution for Port Data-search,<br />Resctriction, Available Equipment,<br />Weather, Lineup and Congestion </h1>
                </div>

                <div className={s.wrapper_icon}>
                  <PortsIcon />
                  <p>SHIPNEXT Ports</p>
                </div>
                <div className={s.wrapper_links}>
                  <a href="#how-it-works" className={s.wrapper_links_link}>How it works</a>
                  <a href="#use-case" className={s.wrapper_links_link}>Use Case</a>
                  {/* {!this.props.user && <RoundThemableButton className={s.wrapper_links_link_color} component={Link} to={'/register'}>Register</RoundThemableButton>} */}
                </div>
              </div>
              <div className={s.right_part}>
                <Form
                  label={'Request demo'}
                  requestType={'shipnextDemo'}
                />
                <button
                  onClick={this.props.handleChangeForm.bind(this, 'Request demo', 'shipnextDemo')}
                >
                  Request demo
                </button>
              </div>
            </div>

          </div>
        </div>

        <span className={s.anchor} id="how-it-works" />
        <div className={cx(s.how_it_works, s.how_it_works_ports)}>
          <div className={s.container}>
            <h2 className={s.title_block}>How it works</h2>
          </div>
          <div className={s.first_step}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>1</div>
                  <div className={s.right_part}>
                    <div className={s.wrapper_info}>
                      <div className={s.info_left}>
                        <h3>Why use SHIPNEXT Ports</h3>
                        <p className={s.description}>
                        Port data on SHIPNEXT is a way to stay up to date with valuable and important port data. Port description, restrictions, facilities and berths, congestion, port line up, weather, cargo in port and other data are all part of this solution.
                        </p>
                        <p className={s.description}>
                        For larger companies that aim at including their customized data, SHIPNEXT can be a solution to integrate and combine their inhouse data with that on SHIPNEXT, enabling a more advanced analytics and optimization.
                        </p>
                      </div>

                      <div className={s.info_right}>
                        <div className={s.wrapper_image}>
                          <img src="/images/solutions/ports_step_1.png" alt="Why use SHIPNEXT Ports" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className={cx(s.second_step, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>2</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Connectivity</h3>
                      <p className={s.description}>
                      SHIPNEXT Ports can be used to interact with service providers (agents, surveyors, suppliers). Our users can use online request and order forms to communicate with their service providers to request the necessary service.
                      </p>
                      <p className={s.description}>
                      Ports with a significant level of digitalization can use SHIPNEXT to build a more reliable interconnectivity with between the shippers and carriers through API integration.<br />
                      SHIPNEXT Ports can be accessed through can be accessed through desktop and mobile app.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/pages/diagram-port.png" alt="Connectivity" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="use-case" />
        <div className={cx(s.use_case, s.use_case_ports)}>
          <div className={s.container}>
            <h2 className={s.title_block}>Use Case</h2>
            <div className={s.wrapper_use_case}>
              <div className={s.wrapper_svg}>
                <PortsIcon />
              </div>
              <div className={s.description_use_case}>
                <ul>
                  <li><h3>Brokers, Carriers, or Shipping Companies</h3> use SHIPNEXT Ports to check the port restrictions and berth congestion in port. For daily commercial and operational matters they can also add and store the contact details of the service providers they use in each particular port, such as Agents, Suppliers, Surveyors, etc.</li>
                  <li><h3>Agents</h3> use this service to edit and store port-data,  promote themselves and offer their services. SHIPNEXT does allow integration and data-exchange through API among ports and agents on the Fleet data, vessel’s performance and ETAs, and other valuable information.</li>
                  <li><h3>Ports and Terminals</h3> use this services to describe and promote their infrastructure and services, available facilities and general information.<br />
                  SHIPNEXT promotes interoperability and interconnectivity between the ports and the maritime fleet. This means digital and encrypted data-exchange, including but not limited to vessel’s certificates, compliance and past performance.<br />
                  SHIPNEXT also allows integration and data-exchange through API among ports and agents on the Fleet data, vessel’s performance and ETAs, and other valuable information.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <span className={s.anchor} id="other-solutions" />
        <div className={s.other_solutions}>
          <div className={s.container}>
            <h2 className={s.title_block}>Other solutions</h2>
            <div className={s.links_other_solutions}>
              <Link className={s.part_link} to="/solution-shipnext-marketplace">
                <div>
                  <TradingDesk />
                  <h3>SHIPNEXT<br /> Trading Desk</h3>
                </div>
                <p>Ship Next Your Cargo</p>
              </Link>
              <Link className={s.part_link} to="/solution-shipnext-cargo-monitor">
                <div>
                  <CargoMonitor />
                  <h3>SHIPNEXT<br /> Cargo Monitor</h3>
                </div>
                <p>Digitize and Automate Your Supply Chain</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-fleet-monitor'}>
                <div>
                  <FleetMonitor />
                  <h3>SHIPNEXT<br /> Fleet Monitor</h3>
                </div>
                <p>Track Your Fleet</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-exchange'}>
                <div>
                  <Exchange />
                  <h3>SHIPNEXT <br /> Exchange</h3>
                </div>
                <p>Optimize E-mail management and stay Connected</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-documents'}>
                <div>
                  <Documents />
                  <h3>SHIPNEXT <br /> Documents</h3>
                </div>
                <p>Your Contract Management</p>
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(s)(PageSolutionsPorts);
