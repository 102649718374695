import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let IconMoon = props => (
  <span {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.65547 8.57132C8.65547 12.3584 11.7255 15.4285 15.5126 15.4285C17.5156 15.4285 19.3181 14.5696 20.5718 13.2001C19.9881 17.3658 16.4102 20.5713 12.0837 20.5713C7.34985 20.5713 3.51229 16.7338 3.51229 11.9999C3.51229 7.67308 6.71825 4.09506 10.8843 3.51172C9.5145 4.76544 8.65547 6.56806 8.65547 8.57132Z" fill="#B2B2B2"/>
    </svg>
  </span>
);

IconMoon = pure(IconMoon);
IconMoon.displayName = 'IconMoon';
IconMoon.muiName = 'SvgIcon';

export default IconMoon;
