import * as handlers from './cargo/index';

export default function login(
  state = {
    loading: false,
    requestList: {
      data: [],
      page: 1,
      next: 2,
      filters: {},
      sort: { field: 'status.weight', value: -1 },
    },
    tcOfferList: {
      data: [],
      filtered: [],
      page: 1,
      next: 2,
      filters: {},
      sort: { field: 'addedAt', value: -1 },
      loading: false,
    },
    myCargoDetails: {
      store: {},
      loading: false,
      selected: null,
    },
  },
  action,
) {
  if (!handlers[action.type]) {
    return state;
  }
  try {
    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
