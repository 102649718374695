import PropTypes from 'prop-types';
import React from 'react';
import s from './Request.scss';
import cx from "classnames";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import RaisedButton from '../../../Common/RaisedButton';
import NarrowFormsyText from '../../../NewInputs/NarrowFormsyText';
import RegisterClass from './RegisterClass';
import NarrowCountrySelect from '../../../NewInputs/NarrowCountrySelect';
import Bunkers from './Bunkers';
import SpeedConsumptions from '../../../Monitor/Edit/SpeedConsumptions';
import NarrowPortAutocomplete from '../../../NewInputs/NarrowPortAutocomplete';

class VesselRequirements extends React.PureComponent {
  static contextTypes = {
    onChange: PropTypes.func,
    selector: PropTypes.func,
  }

  // onChange = this.context.onChange("vesselRequirements");

  constructor(props) {
    super(props);
    this.state = {
      vesselRequirements: props.vesselRequirements,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.vesselRequirements !== this.props.vesselRequirements) {
      this.setState({ vesselRequirements: nextProps.vesselRequirements });
    }
  }
  handleChange = (key, _, val) => {
    this.setState(state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        [key]: val,
      },
    }), () => {
      this.props.handleNext({ vesselRequirements: this.state.vesselRequirements });
    });
  }
  handleImoChange = (key, e, val) => {
    this.props.checkShipImo(val);
    this.setState(state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        [key]: val,
      },
    }));
  }
  countrySelector = flag => flag;

  handleNext = () => {
    this.props.handleNext({ vesselRequirements: this.state.vesselRequirements });
  }

  handleSpeedConsChange = cons => {
    this.setState({
      vesselRequirements: {
        ...this.state.vesselRequirements,
      speedConsumptions: cons,
      },
    });
  }

  render() {
    const { registerClasses, flag, bunkers, imoNumber, dwt, draft, gt, nt, grain, bale, teu, feu, gear, bhp, portOfRegistry, registryNumber, additionalDetails, speedConsumptionV2, constantQuantity, previousCargos } = this.state.vesselRequirements;
    return (<div className={s.step} data-id="vesselRequirements" id="vessel_requirements">
      <div className={cx("row", s.step_row)} >
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="IMO"
              name={"imoNumber"}
              fullWidth
              maxLength="7"
              validations="isInt,isLength:7"
              validationErrors={{
                isInt: 'Only digits',
                isLength: 'Must be 7 digits',
              }}
              value={imoNumber}
              onChange={(ev, val) => this.handleImoChange("imoNumber", ev, val)}
            />
          </div>
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="DWT (summer), mt*"
              name={"dwt"}
              fullWidth
              maxLength="100"
              value={dwt}
              onChange={(ev, val) => this.handleChange("dwt", ev, val)}
              required
              requiredError="required"
              validations="isNumeric,min:650,max:400000"
              validationErrors={{
                isNumeric: 'only numbers',
                min: '650 - 400000',
                max: '650 - 400000',
              }}
            />
          </div>
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="GT"
              name={"gt"}
              fullWidth
              maxLength="100"
              value={gt}
              onChange={(ev, val) => this.handleChange("gt", ev, val)}
              validations="isNumeric,min:0,max:500000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 500000',
                  max: '0 - 500000',
              }}
            />
          </div>
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="NT"
              name={"nt"}
              fullWidth
              maxLength="100"
              value={nt}
              onChange={(ev, val) => this.handleChange("nt", ev, val)}
              validations="isNumeric,min:0,max:500000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 500000',
                  max: '0 - 500000',
              }}
            />
          </div>
      </div>
      <div className={cx("row", s.step_row)} >
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="Grain, m3"
              name={"grain"}
              fullWidth
              maxLength="100"
              value={grain}
              onChange={(ev, val) => this.handleChange("grain", ev, val)}
              validations="isNumeric,min:0,max:600000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 600000',
                  max: '0 - 600000',
              }}
            />
          </div>
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="Bale, m3"
              name={"bale"}
              fullWidth
              maxLength="100"
              value={bale}
              onChange={(ev, val) => this.handleChange("bale", ev, val)}
              validations="isNumeric,min:0,max:600000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 600000',
                  max: '0 - 600000',
              }}
            />
          </div>
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="Draft, m"
              name={"draft"}
              fullWidth
              maxLength="100"
              value={draft}
              onChange={(ev, val) => this.handleChange("draft", ev, val)}
              validations="isNumeric,min:0,max:400000"
              validationErrors={{
                isNumeric: 'only numbers',
                min: '0 - 40000',
                max: '0 - 40000',
              }}
            />
          </div>
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="BHP"
              name={"bhp"}
              fullWidth
              maxLength="100"
              value={bhp || ""}
              onChange={(ev, val) => this.handleChange("bhp", ev, val)}
              validations="isNumeric"
              validationErrors={{
                  isNumeric: 'only numbers',
              }}
            />
          </div>
      </div>
      <div className={cx("row", s.step_row)} >
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="TEU"
              name={"teu"}
              fullWidth
              maxLength="100"
              value={teu}
              onChange={(ev, val) => this.handleChange("teu", ev, val)}
              validations="isNumeric,min:0,max:30000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 30000',
                  max: '0 - 30000',
              }}
            />
          </div>
          <div className="col-sm-3" >
            <NarrowFormsyText
              floatingLabelText="FEU"
              name={"feu"}
              fullWidth
              maxLength="100"
              value={feu}
              onChange={(ev, val) => this.handleChange("feu", ev, val)}
              validations="isNumeric,min:0,max:30000"
              validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 30000',
                  max: '0 - 30000',
              }}
            />
          </div>
          <div className="col-sm-6" >
            <NarrowFormsyText
              floatingLabelText="Gear"
              name={"gear"}
              fullWidth
              maxLength="100"
              value={gear}
              onChange={(ev, val) => this.handleChange("gear", ev, val)}
            />
          </div>
      </div>
      <div className={cx("row", s.step_row)}>

        <div className="col-sm-3" >
          <NarrowPortAutocomplete
            floatingLabelText="Port of Registry"
            name="portOfRegistry"
            forceSelection={false}
            validations="isObj"
            validationError="Please, select port of registry"
            validationErrors={{
              isObj: 'Please, select port of registry'
            }}
            value={portOfRegistry}
            onBlur={(ev, val) => this.handleChange("portOfRegistry", ev, val)}
          />
        </div>
        <div className="col-sm-3" >
          <NarrowFormsyText
            floatingLabelText="Registry №"
            name={"registryNumber"}
            fullWidth
            maxLength="100"
            value={registryNumber || ""}
            onChange={(ev, val) => this.handleChange("registryNumber", ev, val)}
          />
        </div>
        <div className="col-sm-3" >
          <NarrowCountrySelect
            hintText="Flag"
            floatingLabelText="Flag"
            name={"flag"}
            fullWidth
            value={flag}
            onChange={(ev, val) => this.handleChange("flag", ev, val)}
            maxHeight={271}
            countrySelector={this.countrySelector}
            valueSelector={this.countrySelector}
          />
        </div>
      </div>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-12">
          <RegisterClass registerClasses={registerClasses} handleChange={this.handleChange} />
        </div>
      </div>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-12" >
          <NarrowFormsyText
            floatingLabelText="Previous cargo(s)"
            name={"previousCargos"}
            fullWidth
            maxLength="100"
            value={previousCargos || ""}
            onChange={(ev, val) => this.handleChange("previousCargos", ev, val)}
          />
        </div>
      </div>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-12" >
          <NarrowFormsyText
            floatingLabelText="Additional details"
            name={"additionalDetails"}
            fullWidth
            maxLength="100"
            value={additionalDetails || ""}
            onChange={(ev, val) => this.handleChange("additionalDetails", ev, val)}
          />
        </div>
      </div>
      <div className={cx("row", "space-between", s.step_row)}>
        <div className="col-sm-8">
        <p className={s.subtitle}>BUNKER ON BOARD</p>
          <Bunkers namePrefix={"bunkers"} bunkers={bunkers} handleChange={this.handleChange}/>
        </div>
        <div className="col-sm-3">
        <p className={s.subtitle}>CONSTANT</p>
          <NarrowFormsyText
            floatingLabelText="Quantity, mt"
            name={"constantQuantity"}
            fullWidth
            maxLength="100"
            value={constantQuantity || ""}
            validations="isNumeric"
            validationErrors={{
                isNumeric: 'only numbers',
            }}
            onChange={(ev, val) => this.handleChange("constantQuantity", ev, val)}
          />
        </div>
      </div>
      <p className={s.subtitle}>SPEED AND CONSUMPTION</p>
      <div className={cx("row", s.step_row)}>
        <div className="col-sm-12">
          <SpeedConsumptions rows={speedConsumptionV2} handleChange={this.handleSpeedConsChange} />
        </div>
      </div>
      <div style={{ margin: '12px 0' }}>
        <RaisedButton
          label="NEXT"
          disableTouchRipple
          disableFocusRipple
          primary
          type="submit"
          style={{ margin: '13px 0 6px 0' }}
          onClick={this.handleNext}
        />
      </div>
    </div>);
  }
}

export default withStyles(s)(VesselRequirements);
