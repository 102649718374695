import { memoize } from "lodash";

const dictionaries = { containerTypes: [], cargoTypes: [], sectors: [], industries: [], countries: [] };
export function injectDictionaries(data) {
  Object.assign(dictionaries, data);
}

export default dictionaries;

export const findContainerTypeById = memoize((_id) => dictionaries.containerTypes.find(ct => ct._id === _id));

export const getDefaultContainerType = () => dictionaries.containerTypes[0];

export const findCargoTypeById = (_id) => dictionaries.cargoTypes.find(ct => ct._id === _id);
export const findIndustryById = memoize((_id) => dictionaries.industries.find(ct => ct._id === _id));
export const findSectorById = memoize((_id) => dictionaries.sectors.find(ct => ct._id === _id));
