exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CqCkt {\n  display: -ms-flexbox;\n  display: flex; }\n\n._3l_oQ {\n  padding-right: 0 !important;\n  border-right: 1px solid var(--border-color);\n  height: 100%;\n  -webkit-box-shadow: 6px 0px 18px rgba(128, 128, 128, 0.14);\n          box-shadow: 6px 0px 18px rgba(128, 128, 128, 0.14);\n  z-index: 10;\n  width: 50%; }\n\n._26-NJ {\n  padding-left: 0 !important;\n  height: 100%;\n  z-index: 10;\n  width: 50%; }\n\n._3l_oQ._2Lfdb, ._26-NJ._2Lfdb {\n  z-index: 11 !important; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "CqCkt",
	"left": "_3l_oQ",
	"right": "_26-NJ",
	"main": "_2Lfdb"
};