import { v4 as uuidv4 } from 'uuid';
import memoizeOne from 'memoize-one';
export const transformTagBeforeSend = tag => {
  const newTag = {
    value: tag?.value ?? tag,
  };
  if (tag?.category?._id || tag.categoryId) {
    newTag.categoryId = tag.category?._id || tag.categoryId;
  }
  return newTag;
}

export const createGroup = tags => category => {
  const categoryTags = tags.filter(t => t.categoryId === category._id);
  const group = {
    [category.value]: {
      category,
      tags: categoryTags
    }
  }
  return group;
}
export function escapeRegex(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export const WITHOUT_CATEGORY = {
  category: null,
  key: "other",
}

export const groupTags = (categories, tags) => {
  let groups = {
    [WITHOUT_CATEGORY.key]: {
      ...WITHOUT_CATEGORY,
      tags: tags.filter(tag => !tag.categoryId)
    }
  }
  if (categories.length) {
    groups = {
      ...groups,
      ...createGroups(tags, categories)
    }
  }
  return groups;
}

export const createGroups = (tags, categories, acc = {}) => categories.map(createGroup(tags)).reduce((acc, group) => Object.assign(acc, group), acc)

export const addUniqueIds = tags => tags.map(tag => tag._id ? tag : {...tag, _id: uuidv4()})
export const memoizedGroupTags = memoizeOne(groupTags);

export const computeColorOrDefault = ({category, isCustom = false, search, system}) => {
  const TEXT_COLOR = "#FFF";
  const DEFAULT_COLOR = "#D2D2D2"
  const SYSTEM_COLOR = "#E6E6E6"

  if (search || isCustom) {
    return TEXT_COLOR;
  }

  if (!category || !category.color) {
    return DEFAULT_COLOR;
  }

  if (system && !category?.color) {
    return SYSTEM_COLOR;
  }

  const isHex = category.color[0] === "#";
  const isRGB = category.color.includes("rgb");
  return isHex || isRGB ? category.color : ("#" + category.color);
}

export const mapEntity = entity => {
  const dictionary = {
    "cargoRequest": "Cargo"
  }

  return dictionary[entity];
}

export const determineIfText = tag => tag.hasOwnProperty("search")
