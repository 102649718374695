const text = {
    input: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '24px',
        color: 'var(--text-dark)',
        marginTop: '0px',
        paddingTop: '0px',


    },
    textarea: {
      minHeight: '28px',
      marginBottom: '0px',
      marginTop: '0px',
      lineHeight: '24px',
    },
    label: {
        fontSize: '12px', top: '10px',
        color: 'var(--text-light)',
        lineHeight: '22px',

},
    labelFocus: {
        top: '4px',
        color: 'var(--secondary-color)',
    },
    labelShrink: {
        top: '4px',
        transform: `translate(0px, -8px)`
    },
    underline: {
        top: '31px',
        borderColor:'var(--stroke-light-gray1)'
    },
    underlineFocus: {
        top: '31px'
    },
};

const select = {
    input: {
        ...text.input,
        marginTop: '0px',
        paddingTop: '0px',
        lineHeight: '44px',
    }
}

export default {
    container: {
        height: '44px',
        maxHeight: '44px',
        marginTop: '0px',
        display: 'block',
    },
    textareaContainer: {
        minHeight: '44px',
        marginTop: '0px',
        paddingTop: 12,
        paddingBottom: 4,
    },
    selectContainer: {
        height: '44px',
        maxHeight: '44px',
        marginTop: '0px',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '44px',
        display: 'block',
    },
    text,
    select,
    error: {
      fontSize: '11px',
      lineHeight: '11px',
      position: 'absolute',
      top: '35px',
      bottom: 'unset',
    },
}
