import React, { Component } from 'react';
import FreightShareAutocomplete from '../../Monitor/FreightShareAutocomplete';
import { Field } from 'formik';
import styled from 'styled-components';
import ArrowPopover from '../ArrowPopover';
import MenuItem from 'material-ui/MenuItem';
import AlertErrorIcon from 'material-ui/svg-icons/alert/error';
import popoverStyles from '../ArrowPopover.scss';
import cx from 'classnames';
import AutoGrowTextArea from "../AutoGrowTextArea";
import ToPopover from './ToPopover';
import type { IMessageDetails } from '../../Emails/types';


type Props = {
  availableFields: { cc: boolean, bcc: boolean, replyTo: boolean, subject: boolean, to: boolean, from: boolean },
  inreplyTo: any,
  subjectProps: { disabled: boolean },
  replyTo: IMessageDetails
};
type State = {};

const Styles = styled.div`
 > div + div {
   > div > div {
     border-top: none;
     &:after {
       top:-1px;
       height: calc(100% + 1px);
     }
   }
 }
`

const Buttons = styled.span`
  font-size: 12px;
  color: #B2B2B2;
  >span {
    margin-right: 8px;
  }
`

const SubjectField = styled(({ className, field, inreplyTo, disabled, ...props }) => {
  return <div className={className}>
        <div>
          <label>Subject:</label>
          <AutoGrowTextArea {...field} disabled={disabled} />
          {inreplyTo ? <div>{inreplyTo}</div> : null}
        </div>
  </div>
})`

  position: relative;
  min-height: 40px;
  width: 100%;
  color: var(--text-dark333);
  line-height: 20px;
  font-size: 12px;
  >div {
    position: relative;
    height: auto;
    display: flex;
    align-items: flex-start;
    border: 1px solid var(--stroke-light-gray1);
    border-top: none;
    padding-right: 12px;
    padding-top: 9px;
    >div:nth-child(3) {
      min-width:120px;
      font-size: 10px;
      color: #787878;
    }
    >div:nth-child(2) {
      min-height: 30px;
      flex: 1;
    }
  }
  label {
    font-weight: bold;
    margin: 0 4px 0 0;
  }
  textarea {
    outline: none;
    border: none;
    width: 100%;
    font-weight: 400;
    line-height: 20px;
    resize: none;
    min-height: 30px;
    height: 30px;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    color: var(--text-dark333);
    background-color: transparent;
    &:disabled {
      color: var(--text-dark333);
      background-color: transparent;
    }

  }
  > div:after {
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    top: 0;
    height: calc(100% + 1px);
    left: 0;
  }
  &:focus-within {
    > div:after {
      border-bottom: 1px solid var(--text-green-light);
    }
  }
`

const FromSelectWrapper = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  color: var(--text-dark333);
  line-height: 20px;
  font-size: 12px;
  >div {
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid var(--stroke-light-gray1);
    >div {
      font-size: 12px !important;
      font-weight: 400 !important;
    }
  }
  label {
    font-weight: bold;
    margin: 0 4px 0 0;
  }
`

const ErrorIcon = styled((props) => <AlertErrorIcon {...props} />)`
  width: 20px !important;
  height: 20px !important;
  fill: var(--text-warning) !important;
`

class FromSelect extends Component {

  handleSelect = (val) => {
    const { form: { setFieldValue }, field } = this.props;
    setFieldValue(field.name, val);
    if (this.props.handleFromChange) {
      this.props.handleFromChange(val);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.list && nextProps.list !== this.props.list && nextProps.list.length && !this.props.field.value) {
      this.handleSelect(nextProps.list[0]);
    }
  }

  renderSelected = () => {
    const val = this.props.field.value || {};
    return <div>{val.address}&nbsp;{val.broken && <ErrorIcon /> }</div>;
  }

  render(){
    const { field, list = [] } = this.props;
    const value = field.value;
   return (<FromSelectWrapper>
     <div>
       <label>From:</label>
       {list.length > 1 ? (
         <ArrowPopover
           val={value?.address}
           displayLabel={this.renderSelected}
           popoverClassName={cx(popoverStyles.default_popover_style, popoverStyles.from_popover)}
           renderer={() => list.map(el => {
             const id = el.__id || el._id;
             const label = el.name ? `${el.name} <${el.address}>` : el.address;
             return (
              <MenuItem
                value={id}
                primaryText={<div className="flex align-items-center justify-content-start">
                     <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <span>{label}</span>
                     </div>
                    &nbsp;{el.broken && <ErrorIcon /> }
                  </div>}
                onClick={this.handleSelect.bind(this, el)}
                key={id}
              />);
           })}
         />
       ) : (
         <div>{value?.name ? `${value.name} <${value.address}>(${value?.address})` : value?.address}</div>
       ) }
     </div>
    </FromSelectWrapper>);
  }
}

function validateTo(value) {
  if (!value?.length) {
    return 'Required';
  }
}

class MailHeadersEdit extends Component<Props, State> {

  static defaultProps={
    availableFields: { cc: true, bcc: true, replyTo: true, subject: true, to: true, from: true },
    subjectProps: { disabled: true },
  }
  state = {
    enabledFields: { cc: false, bcc: false, replyTo: false },
    open: false,
    anchor: null,
  }


  constructor(props, context) {
    super(props, context);
    if (props.values) {
      if (props.values.cc?.length) {
        this.state.enabledFields.cc = true;
      }
      if (props.values.bcc?.length) {
        this.state.enabledFields.bcc = true;
      }
      if (props.values.replyTo?.length) {
        this.state.enabledFields.replyTo = true;
      }
    }
    if (!props.availableFields.to) {
      Object.keys(this.state.enabledFields).forEach(key => { this.state.enabledFields[key] = props.availableFields[key]; });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.availableFields.to) {
      if (nextProps.availableFields.cc !== this.props.availableFields.cc) {
        this.setState({ enabledFields: { ...this.state.enabledFields, cc: nextProps.availableFields.cc } });
      }
    }
  }
  showPopover = ev => {
    ev.preventDefault();
    this.setState({
      open: true,
      anchor: ev.currentTarget
    })
  }

  closePopover = () => this.setState({
    open: false,
  })

  toggleField = (e) => {
    const field = e.target.getAttribute('data-field');
      if(!field) {
        return;
      }
    this.setState({ enabledFields: { ...this.state.enabledFields, [field]: !this.state.enabledFields[field] } });
}
  renderButtons = () => {
    const buttons = [];
    if (this.props.availableFields.replyTo && !this.state.enabledFields.replyTo) {
      buttons.push(<span data-field="replyTo">Reply to</span>);
    }
    if (this.props.availableFields.cc && !this.state.enabledFields.cc && !this.props.values?.cc?.length) {
      buttons.push(<span data-field="cc">Cc</span>);
    }
    if (this.props.availableFields.bcc && !this.state.enabledFields.bcc) {
      buttons.push(<span data-field="bcc">Bcc</span>);
    }

    return buttons;
  }
  render() {
    const { isNotNew = false, subjectProps, handleForward, handleReply, handleSubject } = this.props;
    return (
      <Styles>
        {this.props.availableFields.from && <Field name={"from"} label="From:" list={this.props.fromList} handleFromChange={this.props.handleFromChange} component={FromSelect} /> }
        {this.props.availableFields.to && <Field name={"to"} validate={validateTo} label={<ToPopover isNotNew={isNotNew} replyTo={this.props.replyTo} handleForward={handleForward} handleReply={handleReply} handleSubject={handleSubject} />} buttons={<Buttons onClick={this.toggleField}>{this.renderButtons()}</Buttons>} component={FreightShareAutocomplete} /> }
        {(this.state.enabledFields.replyTo || this.props.values?.replyTo?.length > 0) && <Field name={"replyTo"} label="Reply to:" component={FreightShareAutocomplete} /> }
        {this.props.availableFields.cc && (this.state.enabledFields.cc || this.props.values?.cc?.length > 0) && <Field name={"cc"} label="Cc:" component={FreightShareAutocomplete} /> }
        {(this.state.enabledFields.bcc || this.props.values?.bcc?.length > 0) && <Field name={"bcc"} label="Bcc:" component={FreightShareAutocomplete} /> }
        {this.props.availableFields.subject && <Field className={'subjectField'} name={"subject"} placeholder="Subject:" {...subjectProps} inreplyTo={this.props.inreplyTo} component={SubjectField}/> }
      </Styles>
    );
  }
}

export default MailHeadersEdit;
