import React, { Component } from 'react'
import NarrowFormsyText from './../../../NewInputs/NarrowFormsyText';
import cx from 'classnames';
import NarrowSelect from './../../../NewInputs/NarrowSelect';
import RaisedButton from '../../../Common/RaisedButton'
import tc from './../TC.scss';
import v from './../../../Monitor/Edit/VesselEdit.scss'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import MenuItem from 'material-ui/MenuItem';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { CheckBox as Checkbox } from './../../../Monitor/Edit/VesselEdit';
import {typeByPurposeList, typeByConstructionList, specialties as _specialties, bunkersList, gearTypes, gearLabels, grabTypes, _iceClass} from './../../../Monitor/Edit/config'
import RegisterClass from './../RegisterClass';
import {TechEquipment} from './../../../Monitor/Edit/VesselEdit';
import { withRows } from './../../../../core/HOC';
import CountriesExclusions from './../CountriesExclusions';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentClear from 'material-ui/svg-icons/content/clear';
import commonVessels from './../commonVessels';
import FittedSelect  from '../../../Common/FittedSelect';
import { minBlack } from 'material-ui/styles/colors';
import { formatTypeByPurposePlural } from '../../../../core/format';

export const Gears = withRows(_Gears);
export const Grabs = withRows(_Grabs);


export class VesselRequirements extends Component {

  static types = [
    'DWT',
    'Vessel Type'
  ]

  static defaultProps = {
    vesselRequirements: {}
  }

  static prefix = 'vesselRequirements'

  static DWT_MIN = 650
  static DWT_MAX = 400000
  static TEU_MIN = 50
  static TEU_MAX = 30000
  static LINER_METER_MIN = 0
  static LINER_METER_MAX = 9999999
  static TEU_MAX = 30000
  static CAPACITY_MIN = 0
  static CAPACITY_MAX = 600000
  static AGE_MIN = 1
  static AGE_MAX = 40

  constructor(props) {
    super(props);

    this.state = {
      vesselRequirements: this.getDefaultState()
    }
  }

  getState = () => {
    return this.state;
  }

  isTypeByPurpose = (types = []) => types.includes(this.state.vesselRequirements.typeByPurpose);

  isLinerMeterAllowed = () => {
    const allowedTypesByPurpose = ["MPP", "PASSCAR", "REEFER"];

    if (!this.state.vesselRequirements.typeByPurpose || !this.state.vesselRequirements.typeBySpecialty) return false

    return this.isTypeByPurpose(allowedTypesByPurpose) && this.state.vesselRequirements.typeBySpecialty.match(/Ro-[RL]o/)
  }

  isTeuAllowed = () => {
    const allowedTypesByPurpose = ["MPP", "CONT"];

    if (!this.state.vesselRequirements.typeByPurpose) return false;

    return this.isTypeByPurpose(allowedTypesByPurpose)
  }

  getDefaultState = () => {
    const vesselRequirements = {
      type: this.constructor.types[0],
      vesselType: "",
      dwtMin: "",
      dwtMax: "",
      teuMin: "",
      teuMax: "",
      linerMeterMin: "",
      linerMeterMax: "",
      loaMax: "",
      beamMax: "",
      draftMax: "",
      piClubMember: false,
      capacityMin: "",
      capacityMax: "",
      typeByPurpose: "",
      typeBySpecialty: "",
      typeByConstruction: "",
      iceClass: "",
      ageMax: "",
      registerClasses: [],
      flags: [],
      ageMin: "",
      boxShaped: null,
      openHatch: null,
      gearV2: {
        summary: []
      },
      grabV2: {
        summary: []
      },
      grabbed: false,
      geared: undefined,
      ventilationType: '',
      ventilationCheckbox: null,
      imoApp: '',
      imoClass: '',
      imoFitted: null,
      referPlugFitted: null,
      quantity: '',
      co2Fitted: null,
      a60BulkheadFitted: null,
      cementHolesFitted: null,
      itfFitted: null,
      logsFitted: null,
      strengthenHeavy: null,
      lakesFitted: null,
      aussieFitted: null,
    }
    return {
      ...vesselRequirements,
      ...(this.props.vesselRequirements || {})
    }
  }

  componentDidMount() {
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
  }

  handleChange = (key, _, val) => new Promise((res, rej) => {
    this.setState(state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        [key]: val
      }
    }), res)
  })

  transformVal = (objField) => {
    return (key, _, val) => {
      this.handleChange(key, null, {
        [objField]: val
      })
    }
  }

  changeVesselType = (ev, t) => {
    /* console.log(commonVessels);
    const common = commonVessels.find(c => c._id === t._id);

    const {
      dw: {
        summer
      } = {},
      capacity: {
        grain
      } = {},
      typeByPurpose,
      typeByConstruction,
      fitted: {
        co2,
        grab,
        strengthened
      } = {}
    } = common || {}; */
    this.handleChange('vesselType', null, t);
    /* this.setState(state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        dwtMax: summer || state.dwtMax,
        dwtMin: summer || state.dwtMin,
        capacityMin: grain || state.capacityMin,
        capacityMax: grain || state.capacityMax,
        typeByPurpose: typeByPurpose || state.typeByPurpose,
        typeByConstruction: typeByConstruction || state.typeByConstruction,
        co2Fitted: co2 || state.co2Fitted,
        grabbed: grab || state.grabbed,
        strengthenHeavy: strengthened || state.strengthenHeavy
      }
    })) */
    this.setState(state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        dwtMax: t?.dwtMax || state.dwtMax,
        dwtMin: t?.dwtMin || state.dwtMin,
      }
    }))
  }

  handleGeared = (ev, value) => {
    let newState = {
      grabbed: value
    }
    if (value) {
      newState.geared = true;
    }
    this.setState(state => ({vesselRequirements: {...state.vesselRequirements, ...newState}}))
  }

  changeGeared = (ev, value) => {
    this.setState( state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        geared: value,
        grabbed: value ? state.vesselRequirements.grabbed : false
      }
    }));
  }

  handleTech = newState => {
    this.setState(state => ({
      vesselRequirements: {
        ...state.vesselRequirements,
        ...newState
      }
    }))
  }

  changeTypeBySpecialty = async (ev, val) => {
    await this.handleChange('typeBySpecialty', ev, val);
    if (!this.isLinerMeterAllowed()) {
      this.handleChange("linerMeterMin", null, "");
      this.handleChange("linerMeterMax", null, "");
    }
  }

  render() {

    const { type, vesselType, dwtMin, dwtMax, capacityMin, capacityMax, typeByPurpose,
       typeBySpecialty, typeByConstruction, iceClass, ageMax, ageMin, boxShaped, openHatch, flags,
        geared, grabbed, gearV2, grabV2, ventilationType, ventilationCheckbox,
        imoApp, imoClass, imoFitted, referPlugFitted, quantity, co2Fitted,
        a60BulkheadFitted,
        cementHolesFitted,
        scrubberFitted,
        itfFitted,
        logsFitted,
        strengthenHeavy,
        lakesFitted,
        aussieFitted, teuMin, teuMax, linerMeterMin,
        linerMeterMax,
        loaMax,
        beamMax,
        draftMax,
        piClubMember, registerClasses } = this.state.vesselRequirements;

    const { types, prefix, DWT_MIN, DWT_MAX, CAPACITY_MAX, CAPACITY_MIN, AGE_MIN, AGE_MAX, TEU_MIN, TEU_MAX, LINER_METER_MAX, LINER_METER_MIN } = this.constructor;

    return (
      <div className={tc.step_wrapper}>
          <div className={cx(tc.row, tc.radio_buttons)} style={{alignItems: 'center', minHeight: '44px'}}>
            <RadioButtonGroup onChange={this.handleChange.bind(this, 'type')} name="vesselRequirements[type]" className={cx(tc.row, tc.radio_buttons)} defaultSelected={type}>
              {
                types.map(type => (<RadioButton className={tc.radio_btn} value={type} label={type} key={type}></RadioButton>))
              }
            </RadioButtonGroup>
            {
              type === 'Vessel Type'
                ? (
                  <span style={{width: 215, display: 'flex'}}>
                    <NarrowSelect
                        floatingLabelText="Select type*"
                        name={`${prefix}[vesselType]`}
                        autoWidth
                        maxHeight={400}
                        required
                        value={vesselType}
                        classes={tc.vessel_type}
                        requiredError="required"
                        onChange={this.changeVesselType}
                    >
                      {
                        commonVessels.map(type => <MenuItem key={type.id} value={type} primaryText={type.name}></MenuItem>)
                      }
                    </NarrowSelect>
                  </span>
                )
                : null
            }
          </div>
          <div className={cx(tc.row, tc.equal_divs)}>
              <div>
                <MinMaxInput
                  val={dwtMax}
                  min={DWT_MIN}
                  max={DWT_MAX}
                  extraDef={{
                    isInt: 'only integer',
                  }}
                >
                  <NarrowFormsyText
                      floatingLabelText={`DWT min, mt*`}
                      name={`${prefix}[dwtMin]`}
                      fullWidth
                      required
                      value={dwtMin}
                      requiredError="required"
                      onBlur={this.handleChange.bind(this, 'dwtMin')}
                  />
                </MinMaxInput>
                {/* <NarrowFormsyText
                      floatingLabelText={`DWT min, mt*`}
                      name={`${prefix}[dwtMin]`}
                      fullWidth
                      validations="isNumeric,isInt,min:650,max:400000"
                      validationErrors={{
                        isInt: 'only integer',
                        isNumeric: 'only numbers',
                        min: '650 - 400000',
                        max: '650 - 400000',
                      }}
                      required
                      value={dwtMin}
                      requiredError="required"
                      onBlur={this.handleChange.bind(this, 'dwtMin')}
                  /> */}
              </div>
              <div>
                <MinMaxInput
                    val={dwtMin}
                    min={DWT_MIN}
                    max={DWT_MAX}
                    extraDef={{
                      isInt: 'only integer',
                    }}
                    type="max"
                >
                    <NarrowFormsyText
                        floatingLabelText={`DWT max, mt*`}
                        name={`${prefix}[dwtMax]`}
                        fullWidth
                        required
                        value={dwtMax}
                        requiredError="required"
                        onBlur={this.handleChange.bind(this, 'dwtMax')}
                    />
                </MinMaxInput>
                {/* <NarrowFormsyText
                    floatingLabelText={`DWT max, mt*`}
                    validations="isNumeric,isInt,min:650,max:400000"
                    validationErrors={{
                      isInt: 'only integer',
                      isNumeric: 'only numbers',
                      min: '650 - 400000',
                      max: '650 - 400000',
                    }}
                    name={`${prefix}[dwtMax]`}
                    fullWidth
                    required
                    value={dwtMax}
                    requiredError="required"
                    onBlur={this.handleChange.bind(this, 'dwtMax')}
                /> */}
              </div>
              <div>
                <MinMaxInput
                    val={capacityMax}
                    min={CAPACITY_MIN}
                    max={CAPACITY_MAX}
                >
                  <NarrowFormsyText
                      floatingLabelText="Capacity min, m3"
                      name={`${prefix}[capacityMin]`}
                      fullWidth
                      value={capacityMin}
                      onBlur={this.handleChange.bind(this, 'capacityMin')}
                  />
                </MinMaxInput>
                {/* <NarrowFormsyText
                    floatingLabelText="Capacity min, m3"
                    name={`${prefix}[capacityMin]`}
                    validations="isNumeric,min:0,max:600000"
                    validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 600000',
                      max: '0 - 600000',
                    }}
                    fullWidth
                    value={capacityMin}
                    onBlur={this.handleChange.bind(this, 'capacityMin')}
                /> */}
              </div>
              <div>
                <MinMaxInput
                    val={capacityMin}
                    min={CAPACITY_MIN}
                    max={CAPACITY_MAX}
                    type="max"
                >
                    <NarrowFormsyText
                        floatingLabelText="Capacity max, m3"
                        name={`${prefix}[capacityMax]`}
                        fullWidth
                        value={capacityMax}
                        onBlur={this.handleChange.bind(this, 'capacityMax')}
                    />
                </MinMaxInput>
                {/* <NarrowFormsyText
                    floatingLabelText="Capacity max, m3"
                    name={`${prefix}[capacityMax]`}
                    validations="isNumeric,min:0,max:600000"
                    validationErrors={{
                      isNumeric: 'only numbers',
                      min: '0 - 600000',
                      max: '0 - 600000',
                    }}
                    fullWidth
                    value={capacityMax}
                    onBlur={this.handleChange.bind(this, 'capacityMax')}
                /> */}
              </div>
          </div>
          <div className={cx(tc.row, tc.equal_divs)}>
              <div>
                <NarrowSelect
                    floatingLabelText="Type By Purpose"
                    name={`${prefix}[typeByPurpose]`}
                    fullWidth
                    autoWidth
                    value={typeByPurpose}
                    onChange={async (ev, val) => {
                      const prevPurpose = this.state.vesselRequirements.typeByPurpose;
                      await this.handleChange('typeByPurpose', ev, val);
                      if (!this.isTeuAllowed()) {
                        this.handleChange("teuMin", null, "");
                        this.handleChange("teuMax", null, "");
                      }
                      if (val !== prevPurpose) {
                        this.changeTypeBySpecialty(null, "");
                      }
                    }}
                >
                    {
                        typeByPurposeList.map((item, i) => (
                          <MenuItem key={i} value={item} primaryText={formatTypeByPurposePlural(item, 1)} />
                        ))
                    }
                </NarrowSelect>
              </div>
              <div>
                <NarrowSelect
                    floatingLabelText="Type By Specialty"
                    name={`${prefix}[typeBySpecialty]`}
                    disabled={!typeByPurpose}
                    autoWidth
                    fullWidth
                    value={typeBySpecialty}
                    onChange={this.changeTypeBySpecialty}
                    dropDownMenuProps={{
                      popoverProps: {
                          style: {
                            background: 'red'
                        }
                      }
                    }}
                >
                  {
                    _specialties[typeByPurpose ? typeByPurpose.toLowerCase() : 'bulk'].map((item, i) => (
                        <MenuItem key={i} value={item} primaryText={item} />
                      ))
                  }
                </NarrowSelect>
              </div>
              <div>
                <NarrowSelect
                    floatingLabelText="Type By Construction"
                    name={`${prefix}[typeByConstruction]`}
                    fullWidth
                    value={typeByConstruction}
                    onChange={this.handleChange.bind(this, 'typeByConstruction')}
                >
                    {
                        typeByConstructionList.map((item, i) => (
                          <MenuItem key={i} value={item} primaryText={item} />
                        ))
                    }
                </NarrowSelect>
              </div>
              <div>
                <NarrowSelect
                    floatingLabelText="Ice Class"
                    name={`${prefix}[iceClass]`}
                    fullWidth
                    value={iceClass}
                    onChange={this.handleChange.bind(this, 'iceClass')}
                >
                  {
                    _iceClass.map(item => <MenuItem value={item} primaryText={item}></MenuItem>)
                  }
                </NarrowSelect>
              </div>
          </div>
          <div className={cx(tc.row, tc.equal_divs)}>
              <div>
                <MinMaxInput
                  val={teuMax}
                  min={TEU_MIN}
                  max={TEU_MAX}
                >
                  <NarrowFormsyText
                      floatingLabelText={`TEU min`}
                      name={`${prefix}[teuMin]`}
                      fullWidth
                      value={teuMin}
                      disabled={!this.isTeuAllowed()}
                      onBlur={this.handleChange.bind(this, 'teuMin')}
                  />
                </MinMaxInput>
              </div>
              <div>
                <MinMaxInput
                    val={teuMin}
                    min={TEU_MIN}
                    max={TEU_MAX}
                    type="max"
                >
                    <NarrowFormsyText
                        floatingLabelText={`TEU max`}
                        name={`${prefix}[teuMax]`}
                        fullWidth
                        disabled={!this.isTeuAllowed()}
                        value={teuMax}
                        onBlur={this.handleChange.bind(this, 'teuMax')}
                    />
                </MinMaxInput>
              </div>
              <div>
                <MinMaxInput
                    val={linerMeterMax}
                    min={LINER_METER_MIN}
                    max={LINER_METER_MAX}
                >
                  <NarrowFormsyText
                      floatingLabelText="Lane meter min"
                      name={`${prefix}[linerMeterMin]`}
                      fullWidth
                      value={linerMeterMin}
                      disabled={!this.isLinerMeterAllowed()}
                      onBlur={this.handleChange.bind(this, 'linerMeterMin')}
                  />
                </MinMaxInput>

              </div>
              <div>
                <MinMaxInput
                    val={linerMeterMin}
                    min={LINER_METER_MIN}
                    max={LINER_METER_MAX}
                    type="max"
                >
                    <NarrowFormsyText
                        floatingLabelText="Lane meter max"
                        name={`${prefix}[linerMeterMax]`}
                        fullWidth
                        value={linerMeterMax}
                        disabled={!this.isLinerMeterAllowed()}
                        onBlur={this.handleChange.bind(this, 'linerMeterMax')}
                    />
                </MinMaxInput>

              </div>
          </div>
          <div className={cx(tc.row, tc.equal_divs)}>
              <div>
                <NarrowFormsyText
                  floatingLabelText="LOA max, m"
                  validations="isNumeric"
                  validationErrors={{
                    isNumeric: 'only numbers',
                  }}
                  value={loaMax}
                  name={`${prefix}[loaMax]`}
                  onBlur={this.handleChange.bind(this, 'loaMax')}
                  fullWidth
                />
              </div>
              <div>
                <NarrowFormsyText
                  floatingLabelText="Beam max, m"
                  validations="isNumeric"
                  validationErrors={{
                    isNumeric: 'only numbers',
                  }}
                  value={beamMax}
                  name={`${prefix}[beamMax]`}
                  onBlur={this.handleChange.bind(this, 'beamMax')}
                  fullWidth
                />
              </div>
              <div>
                <NarrowFormsyText
                  floatingLabelText="Draft max, m"
                  validations="isNumeric"
                  validationErrors={{
                    isNumeric: 'only numbers',
                  }}
                  value={draftMax}
                  name={`${prefix}[draftMax]`}
                  onBlur={this.handleChange.bind(this, 'draftMax')}
                  fullWidth
                />
              </div>
              <div>
                <Checkbox style={{width: '97px'}} className={tc.checkbox}  checked={piClubMember} label="P&I Club" onChange={this.handleChange.bind(this, 'piClubMember')} name={`${prefix}[piClubMember]`} />
              </div>

          </div>
          <div className={cx(tc.row, tc.equal_divs)}>
              <div>
                <MinMaxInput
                    val={ageMin}
                    min={AGE_MIN}
                    max={AGE_MAX}
                    extraDef={{
                      isInt: 'only integer',
                    }}
                    type="max"
                >
                    <NarrowFormsyText
                        floatingLabelText="Age max, years"
                        name={`${prefix}[ageMax]`}
                        fullWidth
                        value={ageMax}
                        onBlur={this.handleChange.bind(this, 'ageMax')}
                    />
                </MinMaxInput>

              </div>
              <div>
                <MinMaxInput
                    val={ageMax}
                    min={AGE_MIN}
                    max={AGE_MAX}
                    extraDef={{
                      isInt: 'only integer',
                    }}
                >
                    <NarrowFormsyText
                        floatingLabelText="Age min, years"
                        name={`${prefix}[ageMin]`}
                        fullWidth
                        value={ageMin}
                        onBlur={this.handleChange.bind(this, 'ageMin')}
                    />
                </MinMaxInput>

              </div>
            <div>
              <FittedSelect
                name={`${prefix}[openHatch]`}
                onChange={this.handleChange.bind(this, 'openHatch')}
                value={openHatch}
                floatingLabelText={'Open Hatch'}
              />
            </div>
            <div>
              <FittedSelect
                name={`${prefix}[boxShaped]`}
                onChange={this.handleChange.bind(this, 'boxShaped')}
                value={boxShaped}
                floatingLabelText={'Box Shaped'}
              />
            </div>
          </div>
          <RegisterClass registerClasses={registerClasses} handleChange={this.handleChange} />
          <CountriesExclusions prefix={prefix} handleChange={this.handleChange} flags={flags} />
          <div className={cx(tc.col, v.tc_gears)}>
              <div className={cx(tc.row_start_wrap, tc.vert_center)}>
                  <h6 className={tc.green_title}>GEAR</h6>
                  <div style={{width: '100px', display: 'flex'}} className={tc.gears}>
                    <NarrowSelect
                        underlineStyle={{display: 'none'}}
                        name={`${prefix}[geared]`}
                        fullWidth
                        value={geared}
                        onChange={this.changeGeared}
                      >
                        <MenuItem value={undefined} primaryText="Gears not set" />
                        <MenuItem value={true} primaryText="Geared" />
                        <MenuItem value={false} primaryText="Gearless" />
                    </NarrowSelect>
                  </div>
              </div>
              <div className={tc.row}>
                  <Gears geared={geared} prefix={`${prefix}[gearV2][summary]`} withoutTitle handleChange={this.transformVal('summary').bind(this, 'gearV2', null)} setGeared={this.handleChange.bind(this, 'geared', null, true)} rows={gearV2.summary} />
              </div>
          </div>
          <div className={cx(tc.col, v.tc_gears)}>
              <div className={cx(tc.row_start_wrap, tc.vert_center)}>
                  <h6 className={tc.green_title}>GRABS</h6>
                  <div style={{width: '150px', display: 'flex'}}>
                    <Checkbox
                      className={tc.checkbox}
                      name={`${prefix}[grabbed]`}
                      label={'Grab Fitted'}
                      checked={grabbed}
                      key={grabbed ? grabbed.toString() : 'false'}
                      onChange={this.handleGeared}
                    />
                  </div>
              </div>
              <div className={tc.row}>
                  <Grabs setGrabbed={this.handleGeared.bind(this, null, true)} geared={geared} prefix={`${prefix}[grabV2][summary]`} withoutTitle  handleChange={this.transformVal('summary').bind(this, 'grabV2', null)} setGeared={this.handleChange.bind(this, 'geared', null, true)} rows={grabV2.summary}></Grabs>
              </div>
          </div>
          <div className={cx(tc.row_start_wrap, tc.vert_center)} style={{marginTop: 10}}>
            <h6 className={tc.green_title}>TECHNICAL EQUIPMENT / CERTIFICATE</h6>
          </div>
          <div className={v.tc_tech}>
            <TechEquipment
              ventilationChecked={ventilationCheckbox}
              ventilationType={ventilationType}
              imoApp={imoApp}
              imoClass={imoClass}
              imoFitted={imoFitted}
              referPlugFitted={referPlugFitted}
              quantity={quantity}
              co2Fitted={co2Fitted}
              a60BulkheadFitted={a60BulkheadFitted}
              cementHolesFitted={cementHolesFitted}
              itfFitted={itfFitted}
              logsFitted={logsFitted}
              strengthenHeavy={strengthenHeavy}
              lakesFitted={lakesFitted}
              aussieFitted={aussieFitted}
              scrubberFitted={scrubberFitted}
              handleChange={this.handleTech}
              prefix={`${prefix}`}
              withoutIceClass
              tcOrder
            />
          </div>
          <span>
            <RaisedButton
              label="NEXT"
              disableTouchRipple
              disableFocusRipple
              primary
              type="submit"
              style={{ margin: '40px 0 6px 0' }}
            />
          </span>
      </div>
    )
  }
}

function _Gears(props) {

  const prefix = props.prefix || 'gearV2[summary]';

  /* const specifyQuantity = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'quantity'}, ev, val)
  }

  const changeMaxSWL = (row, ev, val) => {
    props.setGeared();
    ['maxSWL', 'combinable'].forEach(field => props.changeField({id: row?.id ?? row, field}, ev, field === 'combinable' ? true : +val))
  }

  const changeComby = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'combinable'}, ev, val)
  }

  const changeCapacity = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'capacity'}, ev, val)
  }

  const changeOutreach = (row, ev, val) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'outreach'}, ev, val)
  }

  const changeType = (row, ev, value) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'type'}, ev, value);
  } */

  const handleChanged = (field, row, ev, value) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field}, ev, value);
  }

  const CAPACITY_MIN = 0,
        CAPACITY_MAX = 600;

  return (
    <div className={cx(v.gears)}>
        {
          props.withoutTitle
          ? null
          : (
            <h5 className={v.section_title}>
                GEAR
            </h5>
          )
        }
        {props.rows?.map((row, i) => (<div key={row?.id ?? row} className={v.gear}>
          <div>
            <NarrowSelect
              floatingLabelText="Type"
              name={`${prefix}[${i}][type]`}
              maxHeight={271}
              className={v.select}
              fullWidth
              value={row?.type || ''}
              onChange={handleChanged.bind(this, 'type', row)}
              disabled={props.geared === false}
            >
              {
                gearTypes.map(gearType => (
                  <MenuItem key={gearType} value={gearType} primaryText={gearLabels[gearType] || gearType} />
                ))

              }
            </NarrowSelect>
          </div>
          <div>
            <NarrowFormsyText
              floatingLabelText="Quantity"
              validations={'requiredIf:${prefix}[' + i + '][capacity],isNumeric,isInt,min:0,max:20'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: '0 - 20',
                max: '0 - 20',
                "requiredIf": "required"
              }}
              disabled={!row?.type || props.geared === false}
              onBlur={handleChanged.bind(this, 'quantity', row)}
              value={row?.quantity || ''}
              name={`${prefix}[${i}][quantity]`}
              fullWidth
            />
          </div>
          <div>
            <MinMaxInput
              val={row?.capacityMax}
              min={CAPACITY_MIN}
              max={CAPACITY_MAX}
            >
              <NarrowFormsyText
                  floatingLabelText="Capacity min, mt"
                  disabled={!row?.type || props.geared === false}
                  onBlur={handleChanged.bind(this, 'capacityMin', row)}
                  name={`${prefix}[${i}][capacityMin]`}
                  value={row?.capacityMin || ''}
                  fullWidth
              />
            </MinMaxInput>
            {/* <NarrowFormsyText
                floatingLabelText="Capacity min, mt"
                validations={`isNumeric,min:0,max:${Math.min((row?.capacityMax || CAPACITY_MAX), CAPACITY_MAX)}`}
                validationErrors={{
                  isNumeric: 'only numbers',
                  min: `0 - ${Math.min((row?.capacityMax || CAPACITY_MAX), CAPACITY_MAX)}`,
                  max: `0 - ${Math.min((row?.capacityMax || CAPACITY_MAX), CAPACITY_MAX)}`,
                }}
                disabled={!row?.type || props.geared === false}
                onBlur={handleChanged.bind(this, 'capacityMin', row)}
                name={`${prefix}[${i}][capacityMin]`}
                value={row?.capacityMin || ''}
                fullWidth
            /> */}
          </div>
          <div>
            <MinMaxInput
                val={row?.capacityMin}
                min={CAPACITY_MIN}
                max={CAPACITY_MAX}
                type="max"
              >
                 <NarrowFormsyText
                    floatingLabelText="Capacity max, mt"
                    disabled={!row?.type || props.geared === false}
                    onBlur={handleChanged.bind(this, 'capacityMax', row)}
                    name={`${prefix}[${i}][capacityMax]`}
                    value={row?.capacityMax || ''}
                    fullWidth
                />
            </MinMaxInput>
           {/*  <NarrowFormsyText
                floatingLabelText="Capacity max, mt"
                validations={`isNumeric,min:${Math.max((row?.capacityMin > CAPACITY_MAX ? CAPACITY_MIN : row.capacityMin), CAPACITY_MIN)},max:600`}
                validationErrors={{
                  isNumeric: 'only numbers',
                  min: `${Math.max((row?.capacityMin > CAPACITY_MAX ? CAPACITY_MIN : row.capacityMin), CAPACITY_MIN)} - 600`,
                  max: `${Math.max((row?.capacityMin > CAPACITY_MAX ? CAPACITY_MIN : row.capacityMin), CAPACITY_MIN)} - 600`,
                }}
                disabled={!row?.type || props.geared === false}
                onBlur={handleChanged.bind(this, 'capacityMax', row)}
                name={`${prefix}[${i}][capacityMax]`}
                value={row?.capacityMax || ''}
                fullWidth
              /> */}
          </div>
           <div>
            <NarrowFormsyText
                floatingLabelText="Outreach, m"
                validations="isNumeric,min:0,max:50"
                validationErrors={{
                  isNumeric: 'only numbers',
                  min: '0 - 50',
                  max: '0 - 50',
                }}
                disabled={!row?.type || props.geared === false}
                name={`${prefix}[${i}][outreach]`}
                value={row?.outreach || ''}
                fullWidth
                onBlur={handleChanged.bind(this, 'outreach', row)}
              />
           </div>
            <div className={v.gear_rest}>

                <span className={v.add_line} style={{marginLeft: 10}}>
                {
                  props.geared === false
                  ? null
                  : (
                    i === props.rows?.length-1 ?
                      <ContentAdd
                          className={v.add_icon}
                          onClick={props.handleAddRow}
                      />
                      :
                      <ContentClear

                          className={v.delete}
                          onClick={props.handleRemoveRow.bind(this, row)}
                      />
                  )
                }
                </span>
            </div>

          </div>
        ),
        )}
      </div>
  )
}

const computeMax = (val, max = 0) => Math.min((val || max), max);
const computeMin = (val, min = 0, max = 0) => Math.max((val > max || (val == undefined || val == null) ? min : val), min);

export function MinMaxInput({ val, min = 0, max, type = 'min', children, extraDef, bothRequired= false }) {
  const def = `isNumeric,${extraDef ? Object.keys(extraDef).join(',') + ',' : ''}`;
  let validations;
  let minMaxError;
  const extraProps = {};
  if (type === 'min') {
    const _max = computeMax(val, max);
    validations = `${def}min:${min},max:${_max}`;
    minMaxError = `${min} - ${_max}`;
  } else {
    const _min = computeMin(val, min, max);
    validations = `${def}min:${_min},max:${max}`;
    minMaxError = `${_min} - ${max}`;
  }

  if (bothRequired && val) {
    extraProps.required = true;
    extraProps.requiredError = "required";
  }

  const vErrors = {
    isNumeric: 'only numbers',
    min: minMaxError,
    max: minMaxError,
    ...(extraDef || {})
  }
  return (
    <div>
      {
        React.Children.map(children, child => React.cloneElement(child, {
          ...child.props,
          ...extraProps,
          validations,
          validationErrors: vErrors
        }))
      }
    </div>
  )

}

_Gears.parseRows = rows => {
  return rows.map(row => !!row ? row : {})
}
function _Grabs(props) {

  const prefix = props.prefix || 'grabV2[summary]'

  /* const changeType = (row, ev, value) => {
    props.setGeared();
    props.setGrabbed && props.setGrabbed();
    props.changeField({id: row?.id ?? row, field: 'type'}, ev, value)
  }

  const changeQuantity = (row, ev, value) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'quantity'}, ev, value)
  }

  const changeVolume = (row, ev, value) => {
    props.setGeared();
    props.changeField({id: row?.id ?? row, field: 'volume'}, ev, value)
  } */

  const handleChanged = (field, row, ev, value) => {
    const FITTED_FIELDS = ["type", "quantity"];
    if (FITTED_FIELDS.includes(field)) {
      props.setGrabbed && props.setGrabbed();
    }
    props.setGeared();
    props.changeField({id: row?.id ?? row, field}, ev, value);
  }

  const VOLUME_MIN = 0,
        VOLUME_MAX = 2000;

  return (
    <div className={v.grabs}>
        {
          props.withoutTitle
          ? null
          : (
            <h5 className={v.section_title}>
                GRABS
            </h5>
          )
        }
        {props.rows?.map((row, i) => (<div key={row?.id ?? row} className={v.grab}>
          <div>
            <NarrowSelect
              floatingLabelText="Type"
              name={`${prefix}[${i}][type]`}
              maxHeight={271}
              className={v.select}
              fullWidth
              value={row?.type || ''}
              onChange={handleChanged.bind(this, 'type', row)}
              disabled={props.geared === false}
            >
              {
                grabTypes.map(grabType => (
                  <MenuItem key={grabType} value={grabType} primaryText={grabType} />
                ))

              }
            </NarrowSelect>
          </div>
          <div>
            <NarrowFormsyText
              floatingLabelText="Quantity"
              validations="isNumeric,isInt,min:0,max:20"
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only integer',
                min: '0 - 20',
                max: '0 - 20',
              }}
              value={row?.quantity || ''}
              name={`${prefix}[${i}][quantity]`}
              onBlur={handleChanged.bind(this, 'quantity', row)}
              fullWidth
              required={row?.volumeMin !== undefined || row?.volumeMax !== undefined}
              requiredError="required"
            />
          </div>
          <div>
              <MinMaxInput
                val={row?.volumeMax}
                min={VOLUME_MIN}
                max={VOLUME_MAX}
              >
                <NarrowFormsyText
                  floatingLabelText="Volume min, cbm"
                  value={row?.volumeMin || ''}
                  name={`${prefix}[${i}][volumeMin]`}
                  onBlur={handleChanged.bind(this, 'volumeMin', row)}
                  fullWidth
                />
              </MinMaxInput>
          </div>
          <div>
              <MinMaxInput
                val={row?.volumeMin}
                min={VOLUME_MIN}
                max={VOLUME_MAX}
                type="max"
              >
                <NarrowFormsyText
                  floatingLabelText="Volume max, cbm"
                  value={row?.volumeMax || ''}
                  name={`${prefix}[${i}][volumeMax]`}
                  onBlur={handleChanged.bind(this, 'volumeMax', row)}
                  fullWidth
                />
              </MinMaxInput>
          {/* <NarrowFormsyText
              floatingLabelText="Volume max, cbm"
              validations="isNumeric,min:0,max:2000"
              validationErrors={{
                isNumeric: 'only numbers',
                min: '0 - 2000',
                max: '0 - 2000',
              }}
              disabled={!row?.type || props.geared === false}
              value={row?.volumeMax || ''}
              name={`${prefix}[${i}][volumeMax]`}
              onBlur={handleChanged.bind(this, 'volumeMax', row)}
              fullWidth
            /> */}
          </div>
          <div className={v.gear_rest}>
                <span className={v.add_line}>
                {
                  props.geared === false
                  ? null
                  : (
                    i === props.rows?.length-1 ?
                      <ContentAdd
                          className={v.add_icon}
                          onClick={props.handleAddRow}
                      />
                      :
                      <ContentClear

                          className={v.delete}
                          onClick={props.handleRemoveRow.bind(this, row)}
                      />
                  )
                }
                </span>
            </div>

          </div>
        ),
        )}
      </div>
  )
}
_Grabs.parseRows = rows => {
  return rows.map(row => !!row ? row : {})
}


export default withStyles(tc, v)(VesselRequirements);
