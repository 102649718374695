import React, { Children, PureComponent } from 'react'
import { debounce } from '../../core/utils';
import { isEqual } from 'lodash';

export class VirtualList extends PureComponent {
  // ROW_THRESHOLD specifies when to load more rows. When we scroll to rows.length - ROWS_THRESHOLD it will request more rows (page + 1)
  static defaultProps = {
    ROW_HEIGHT: 48,
    ROW_THRESHOLD: 5,
    handleLoadMore: () => undefined,
  }

  _prevScroll = null;
  _wrapper = null;

  state = {
    topMock: 0,
    onScreen: 100,
  }

  isEncounteringThreshold = ({ scrollTop, scrollHeight, clientHeight }) => {
    const { ROW_HEIGHT, ROW_THRESHOLD } = this.props;

    const scrolled = scrollTop + clientHeight;
    const shouldLoad = scrollHeight < (scrolled + ROW_HEIGHT * ROW_THRESHOLD);


    return shouldLoad;
  }

  handleLoadMore = debounce(() => {
    this.props.handleLoadMore();
  })

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (!isEqual(prevProps.filters, this.props.filters)) {
      this._wrapper.scrollTop = 0;
    }
    if (prevProps.rows !== this.props.rows) {
      this.handleScroll({target: this._wrapper});
    }
  }


  handleScroll = ev => {
    if (this.props.isLoading) return;

    //ev.persist();
    const { target } = ev;

    const { scrollTop, scrollHeight, clientHeight } = target;

    if (scrollTop > this._prevScroll && (this.isEncounteringThreshold({ scrollTop, scrollHeight, clientHeight }))) {
     this.handleLoadMore();
    }
    const offsets = this.calculateTopBottomMockOffset({ scrollTop, scrollHeight, clientHeight });
    if (offsets.onScreen !== this.state.onScreen || offsets.topMock !== this.state.topMock) {
      this.setState(offsets);
    }
    this._prevScroll = scrollTop;
  }

  assignWrapperRef = el => {
    this._wrapper = el;
    this.props.scrollWrapperRef && this.props.scrollWrapperRef(el);
  }

  renderVirtualScroll = (mergeProps = {}) => {
    const { children, rows } = this.props;
    const { topMock, onScreen } = this.state;
    const slicedRows = rows.slice(topMock, topMock + onScreen);
    const bottomMock = Math.max(0, rows.length - topMock - onScreen);
    return <div>
      <div style={{height: topMock * this.props.ROW_HEIGHT}} />
      {children({
      topMock,
      bottomMock,
      rows: slicedRows,
      ...mergeProps,
    })}
      <div style={{ height: bottomMock * this.props.ROW_HEIGHT }} />
    </div>;
  }
  calculateTopBottomMockOffset = ({ scrollTop, scrollHeight, clientHeight }) => {
    const topMock = Math.max(0, Math.floor(scrollTop / this.props.ROW_HEIGHT) - this.props.ROW_THRESHOLD);
    const onScreen = Math.floor(clientHeight / this.props.ROW_HEIGHT) + (this.props.ROW_THRESHOLD * 2);
    return {
      topMock,
      onScreen,
    };
  }

  render() {

    const { rows, style = {}, className = '', useVirtual = true, children, ...rest } = this.props;

    const content = useVirtual ? this.renderVirtualScroll(rest) : children({ rows, ...rest });

    return (
      <div ref={this.assignWrapperRef} onScroll={this.handleScroll} style={{ overflow: 'auto', height: '100%', ...style }} className={className}>
        {
          content
        }
      </div>
    );
  }
}

export default VirtualList;
