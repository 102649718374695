import produce from "immer";

/** @name CONTACTS_DELETE_CONTACT_REDUCER */
 export default function CONTACTS_DELETE_CONTACT(state, { payload }) {
  const { contactIds } = payload;
  return produce(state, draft => {
    draft.contactList.data = draft.contactList.data.filter(c => contactIds.indexOf(c.id) === -1);
    draft.companyList.data = draft.companyList.data.filter(c => contactIds.indexOf(c.id) === -1);
    draft.companyList.data.forEach(c => {
      if (c.companies) {
        c.companies = c.companies.filter(cc => contactIds.indexOf(cc.id) === -1);
      }
    });
  });
}
