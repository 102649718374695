import Fleet from './../core/api/Fleet';
import { GET_VESSELS_LIST, ALLFLEET_SET_SAVE_ROWS_AND_SCROLL, ALLFLEET_UPDATE_ALL_VESSELS, ALLFLEET_UPDATE_SINGLE_VESSEL, ALLFLEET_UPDATE_VESSELS } from '../constants/allFleet';


export function updateVesselInFleet(vessel) {
  return {
    type: ALLFLEET_UPDATE_SINGLE_VESSEL,
    payload: vessel
  }
}

export function updateVesselsInFleet(vessels) {
  return {
    type: ALLFLEET_UPDATE_VESSELS,
    payload: vessels
  }
}

export function setSaveRowsAndScroll(scrollTop) {
  return {
    type: ALLFLEET_SET_SAVE_ROWS_AND_SCROLL,
    payload: scrollTop
  }
}

export function updateAllVesselsInFleet({
  field,
  val
}) {
  return {
    type: ALLFLEET_UPDATE_ALL_VESSELS,
    payload: {
      field,
      val
    }
  }
}

export const getVesselsList = ({ filters, sort, page, isHidden } = {}) => (dispatch, getState) => {

  const { user, lastFilters } = getState().login;

  page = page || 1;
  isHidden = !!isHidden;

  const configuration = {
    filters,
    sort,
    hidden: isHidden,
    page,
  };
  if (lastFilters.allFleetFilter) {
    configuration.filters = { ...configuration.filters, ...lastFilters.allFleetFilter };
      delete configuration.filters.search;
      if (configuration.filters.tags && !configuration.filters.tags.condition.length) {
        delete configuration.filters.tags;
      }
    configuration.sort = { ...configuration.sort, ...lastFilters.allFleetFilter.sort };
  }
  // anonymous users don't have any filters or sort

  if (!user) {
    // save "search" field in filters because anonymous users can search vessels though.
    configuration.filters = {
      search: configuration.filters?.search,
    };
    Reflect.deleteProperty(configuration, "sort");
  }

  const promise = Fleet.getAllFleet(configuration);

  return dispatch({
    type: GET_VESSELS_LIST,
    promise,
    payload: { sort: configuration.sort || sort, filters: configuration.filters, isHidden }
  })
}

export const eraseVesselsList = () => ({
  type: GET_VESSELS_LIST,
  res: {
    data: []
  }
})
