/** @name INVOICES_SELECT_REDUCER */
export default function INVOICES_SELECT(state, { payload }) {
  const newInvoicesList = { ...state.invoicesList };
  let selectedIds = [];
  if (newInvoicesList.selectedIds && newInvoicesList.selectedIds.length) {
    selectedIds = [...newInvoicesList.selectedIds];
  }
  if (payload.selectedIds && payload.selectedIds.length) {
    if (payload.value === false) {
      selectedIds = selectedIds.filter(id => !payload.selectedIds.includes(id));
    }
    if (payload.value === true) {
      selectedIds = [...selectedIds, ...payload.selectedIds];
    } 
  }
  newInvoicesList.selectedIds = selectedIds;
  const newState = { invoicesList: newInvoicesList };
  return { ...state, ...newState };
}
