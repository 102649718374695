import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Pages.scss';
import DataSecurityIcon from '../../../Icons/DataSecurity';

class DataSecurity extends Component {
  render() {
    return (
      <div className={s.data_security}>

        <div className={s.container}>
          <div className={s.title}>
            <h2>Data Security</h2>
          </div>
          <div className={s.body_data_security}>
            <DataSecurityIcon />
            <div className={s.wrapper_description}>
              <div className={s.part_description}>
                <h3>Website</h3>
                <p>Our website is protected by a 256-bit SSL certificate and hosted in a distributed, isolated AWS infrastructure. The location of the servers is geographically distributed to provide the highest data accessibility between Frankfurt & North California Data-centers. They are  Encrypted on both levels: the database level and the file system level. This means that your data has both physical and electronic security, which is ensured by high FIPS standards.
                </p>
              </div>
              <div className={s.part_description}>
                <h3>Services</h3>
                <p>Our servers are connected in a single network, and the connection between them is organized through VPN tunnels with 4096-bit encryption, this allows you to be sure that the information will not be intercepted on network nodes or on intermediate servers.<br />
                  To receive an incoming mail, SHIPNEXT uses reliable IMAP protocol with SSL encryption, which guarantees confidentiality during data transfer and excludes the possibility of receiving data by a third party.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(DataSecurity));
