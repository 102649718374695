import React from 'react';
import Layout from '../../components/Layout';

const title = 'Regions';

export default {
  path: '/regions',

  async action() {
    const Regions = ((await import(/* webpackChunkName: 'Regions' */ '../../components/Regions')).default);
    return {
      component: (
        <Layout>
          <Regions />
        </Layout>),
    };
  },
};
