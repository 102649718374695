exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2najp {\n  font-size: 10px;\n  font-weight: 500;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  line-height: 14px;\n  cursor: pointer; }\n  ._2najp span {\n    white-space: normal; }\n  ._2najp ._2mT2I {\n    white-space: nowrap;\n    width: 100%;\n    text-overflow: ellipsis;\n    overflow: hidden; }\n  ._3l5i_ {\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  /*  max-width: 100%;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: flex-end;\n  & > div {\n    max-width: 100% !important;\n    & > div > div > div:nth-child(2) {\n      padding-right: 24px !important;\n    }\n  }*/ }\n  .TFtlv {\n  border-radius: 4px !important; }\n  .TFtlv div, .TFtlv a {\n    font-size: 13px !important;\n    color: #333333 !important; }\n  .TFtlv span, .TFtlv div {\n    line-height: 24px !important;\n    min-height: 24px !important; }\n  .TFtlv button {\n    top: 8px !important; }\n  .TFtlv > div > div {\n    top: -2px !important; }\n  ._18slk._2HnW5 div, ._18slk._2HnW5 span {\n  color: var(--text-green-dark) !important; }\n", ""]);

// Exports
exports.locals = {
	"default_label": "_2najp",
	"channel_label": "_2mT2I",
	"wrapper": "_3l5i_",
	"default_popover_style": "TFtlv",
	"menu_item": "_18slk",
	"active": "_2HnW5"
};