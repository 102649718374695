import React, { Component } from 'react'

import SpeedConsumptions, { _SpeedConsumptions } from './SpeedConsumptions';
import FormsyText from './../../Common/FormsyText'
import { PublicPrivate } from './../../Common/RatingBlock';
import {DarkCompanyChangesTooltip} from './../../Common/CompanyChangesTooltip';
import s from './VesselEdit.scss';
import { isShipnextOrVaramar } from '../../../core/utils';
import { connect } from 'react-redux';
import {HiddenButtons} from "../../Common/HiddenButtons";
const col = {display: 'flex', flexDirection: 'column', position: 'relative'};
const row = {display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', position: 'relative'};
export class PublicPrivateSpeedCons extends Component {

  static defaultProps = {
    withHiddenButtons: true,
    isCreatingVessel: false
  }

  // ifchor company ids to block them from saving public speedcons
  static onlyPrivateIds = ["5efa0b1080bb3f215f2e0f21", "5efa0b1080bb3f215f2e0f21"]

  constructor(props) {
    super(props);
    this.state = {
      isPrivate: false,
      publicKey: 1,
      privateKey: 1,
      publicConsumptionsChanged: false,
      privateConsumptionsChanged: false,
      publicCons: props.publicCons || [],
      privateCons: [],

      /* public: {
        initial: {},
        edit: {},
        iscHANGED: false
      } */
    }
  }

  cancelPublicConsumptions = () =>
    this.setState(state => ({
      publicKey: state.publicKey + 1,
      publicConsumptionsChanged: false,
    }), () => {
      this.setState({
        publicCons: this.parseCons(this.props.publicCons)
      })
    });

  cancelPrivateConsumptions = () =>
    this.setState(state => ({
      privateKey: state.privateKey + 1,
      privateConsumptionsChanged: false,
    }), () => {
      const privateCons = this.parseCons(this.props.privateCons ? this.props.privateCons : []);
      this.setState({
        privateCons
      })
    });

  componentDidMount() {
    const publicCons = this.parseCons(this.props.publicCons);
    let privateCons = this.state.privateCons;
    let isPrivate = false;
    if (this.props.privateCons && this.props.privateCons.length) {
      privateCons = this.parseCons(this.props.privateCons);
      isPrivate = true;
    }
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
    this.setState({
      publicCons,
      privateCons,
      isPrivate,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.publicCons && nextProps.publicCons !== this.props.publicCons) {
      const publicCons = this.parseCons(nextProps.publicCons);
      this.setState({
        publicCons,
        publicConsumptionsChanged: false
      })
    }
    if (nextProps.privateCons && nextProps.privateCons !== this.props.privateCons) {
      const privateCons = this.parseCons(nextProps.privateCons);
      this.setState({
        privateCons,
        privateConsumptionsChanged: false
      })
    }
  }

  parseCons = (consumptions) => {
    let defaultRows = _SpeedConsumptions.defaultRows || [];
    const rows = _SpeedConsumptions.parseRows(consumptions || []);
    if (defaultRows && defaultRows.length) {
      defaultRows = defaultRows.map(row => {
        const inRows = rows.findIndex(i => String(i.mode)?.trim() === String(row.mode)?.trim());
        if (inRows >= 0) {
          const obj = {
            ...rows[inRows],
            id: row.id
          };
          rows.splice(inRows, 1);
          return obj;
        }
        return row;
      });
    }
    /* this.setState({
      rows: [...defaultRows, ...rows]
    }) */
    return [...defaultRows, ...rows];
  }

  changeView = val => {
    if (!this.props.privateCons && !this.state.privateCons.length) {
      const privateCons = this.parseCons(this.props.publicCons && this.props.publicCons.length ? this.props.publicCons : this.state.publicCons);
      this.setState({
        privateCons,
        privateConsumptionsChanged: true
      })
    }
    this.setState({
      isPrivate: val
    })
  }

  resetTable = (field, key) => {
    field && this.setState(state => ({...state, [field]: key ?? (state[field] + 1)}));
    switch(field) {
      case "publicKey": {
        const publicCons = this.parseCons(nextProps.publicCons);
        this.setState({
          publicCons,
        });
        break;
      }
      case "privateCons": {
        const privateCons = this.parseCons(nextProps.privateCons);
        this.setState({
          privateCons,
        });
        break;
      }
    }
  }

  isDisabled = () => this.props.isCreatingVessel ? false : (!this.props.isVesselOwnerInSameCompany && !isShipnextOrVaramar(this.props.user))/*  || this.constructor.onlyPrivateIds.includes(this.props.companyId) */

  handlePrivateChanged = privateCons => {
    this.setState({
      privateCons,
      privateConsumptionsChanged: true
    })
  }
  handlePublicChanged = publicCons => {
    this.setState({
      publicCons,
      publicConsumptionsChanged: true
    })
  }

  setChanged = field => val => this.setState({[field]: val})

  render() {
    const { publicCons = [], privateCons = [], publicConsumptionsChanged, privateConsumptionsChanged, isPrivate, publicKey, privateKey } = this.state;
    const { changeView, isDisabled } = this;
    const { isVesselOwnerInSameCompany = false, ...rest } = this.props;
    const disabled = isDisabled();
    return (
      <div style={col}>
        <FormsyText type="hidden" style={{display: 'none'}} value={isPrivate} name="isPrivate"></FormsyText>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-32px', alignSelf: 'flex-end'}}>
          <PublicPrivate offset={{ x: 6, y: 0 }} Tooltip={<DarkCompanyChangesTooltip />} whiteArrow={false} horizontalPosition="left" dark={true} positionInsideTransform={false} selectedtoggle={isPrivate} changeToggle={changeView}></PublicPrivate>
        </div>
        <div style={{width: "100%", cursor: !disabled || isPrivate ? "initial" : "not-allowed"}}>
          {
            isPrivate
            ? (
              <Cons
                {...rest}
                withHiddenButtons={this.props.withHiddenButtons}
                cancelChanges={this.cancelPrivateConsumptions}
                isChanged={privateConsumptionsChanged}
                attachRef={el => this.public = el}
                setChanged={this.setChanged('privateConsumptionsChanged')}
                handleChange={this.handlePrivateChanged}
                key={privateKey}
                rows={privateCons || []}
              >
                {
                  !this.props.privateCons
                  ? (<span className={s.green_sub}>Save your private speed & consumption data</span>)
                  : null
                }
              </Cons>
            )
            : (
              <Cons
                {...rest}
                withHiddenButtons={this.props.withHiddenButtons}
                cancelChanges={this.cancelPublicConsumptions}
                isChanged={publicConsumptionsChanged}
                attachRef={el => this.private = el}
                setChanged={ !disabled ? this.setChanged('publicConsumptionsChanged') : undefined}
                handleChange={ !disabled ? this.handlePublicChanged : undefined}
                key={publicKey}
                rows={publicCons}
                disabled={disabled}
              />
            )
          }
        </div>

      </div>
    )
  }
}

const Cons = ({ disabled = false, isChanged = false, withHiddenButtons = true, handleChange = () => undefined, setChanged = () => undefined, key, attachRef, cancelChanges, rows, children, ...rest }) => {
  return (
    <div style={col} className={disabled ? s.disabled_cons_table : ""}>
      <div>
        <SpeedConsumptions {...rest}
          key={key}
          changesCallback={setChanged}
          handleChange={handleChange}
          attachAsRef={attachRef}
          rows={ rows }
        ></SpeedConsumptions>
      </div>
      {
        isChanged
        ? (
          <div style={row}>
            {children}
            {
              withHiddenButtons
              ? (
                <div style={row}>
                  <HiddenButtons
                    shouldShow={isChanged}
                    handleCancel={cancelChanges}
                  ></HiddenButtons>
                </div>
              )
              : null
            }
          </div>
        )
        : null
      }
    </div>
  )
}

export default connect(state => ({ user: state.login.user }))(PublicPrivateSpeedCons);
