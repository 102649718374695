import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

const _Documents = props => (
  <span {...props}>
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="29" fill="#F5F5F5"/>
      <path d="M41.9679 39.2996C42.6265 39.2996 42.9999 38.4526 42.9999 38.4526L41.9679 39.2996Z" fill="#BE2F2D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.0517 19.6735C37.0298 19.2594 36.7547 18.9017 36.36 18.7744L32.8911 17.6556V13H33.7582H41.9999C42.5522 13 42.9999 13.4477 42.9999 14V22.34V38.4526L41.9679 39.2996H38.0854L37.0517 19.6735Z" fill="#BE2F2D"/>
      <path d="M23.6316 13C23.0886 13 22.6484 13.4402 22.6484 13.9832V17.6556H32.8911V13H23.6316Z" fill="#FF6F32"/>
      <path d="M39.4171 41.8492C40.0758 41.8492 40.4491 41.0022 40.4491 41.0022L39.4171 41.8492Z" fill="#94D352"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.0977 16.5327C20.0977 15.9897 20.5387 15.5496 21.0817 15.5496C24.4916 15.5496 26.6803 15.5496 30.3403 15.5496V21.8013H20.0977L20.0977 16.5327Z" fill="#94D352"/>
      <path d="M31.2074 15.5496H30.3403V21.8013L32.919 22.8752C33.2917 23.0304 33.5346 23.3946 33.5346 23.7983V41.7541L39.4171 41.8492L40.4491 41.0022V24.8896V16.5496C40.4491 15.9973 40.0014 15.5496 39.4491 15.5496H31.2074Z" fill="#4D9E0E"/>
      <path d="M15.9832 19.4185C15.4402 19.4185 15 19.8586 15 20.4016V44.8711C15 45.1838 15.1512 45.3907 15.3383 45.522C15.5197 45.6492 15.7349 45.7054 15.8791 45.7181H25.2427V19.4185H15.9832Z" fill="#B5D7FF"/>
      <path d="M35.3515 44.8711C35.3515 45.1482 35.3515 44.5834 35.3515 44.8711C35.3515 38.7669 35.3515 35.094 35.3515 28.7585V20.4185C35.3515 19.8662 34.9037 19.4185 34.3515 19.4185H26.1097H25.2427V45.7181H34.3194L35.3515 44.8711Z" fill="#82C3FF"/>
      <path d="M34.3194 45.7181C34.9781 45.7181 35.3515 44.8711 35.3515 44.8711L34.3194 45.7181Z" fill="#82C3FF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.2436 25.2715H20.4881C19.882 25.2715 19.3906 25.7628 19.3906 26.3689C19.3906 26.975 19.882 27.4663 20.4881 27.4663H25.2436V25.2715Z" fill="#4380C7"/>
      <path d="M29.9991 27.4663C30.6052 27.4663 31.0966 26.975 31.0966 26.3689C31.0966 25.7628 30.6052 25.2715 29.9991 25.2715H25.2436V27.4663H29.9991Z" fill="#285596"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.2436 29.661H20.4881C19.882 29.661 19.3906 30.1523 19.3906 30.7584C19.3906 31.3645 19.882 31.8559 20.4881 31.8559H25.2436V29.661Z" fill="#4380C7"/>
      <path d="M29.9991 31.8559C30.6052 31.8559 31.0966 31.3645 31.0966 30.7584C31.0966 30.1523 30.6052 29.661 29.9991 29.661H25.2436V31.8559H29.9991Z" fill="#285596"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.2436 34.0509H20.4881C19.882 34.0509 19.3906 34.5422 19.3906 35.1483C19.3906 35.7544 19.882 36.2458 20.4881 36.2458H25.2436V34.0509Z" fill="#4380C7"/>
      <path d="M29.9991 36.2458C30.6052 36.2458 31.0966 35.7544 31.0966 35.1483C31.0966 34.5422 30.6052 34.0509 29.9991 34.0509H25.2436V36.2458H29.9991Z" fill="#285596"/>
    </svg>
  </span>
);

const Documents = pure(_Documents);
Documents.displayName = 'Documents';
Documents.muiName = 'SvgIcon';

export default Documents;
