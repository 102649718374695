export default function CHANGE_POSITION_STATUS(state, action) {
  const newState = { vesselList: { ...state.vesselList } };

  const indexes = newState.vesselList.data.reduce((acc, vessel, index) => {
    if (vessel._id === action.payload.vesselId && vessel.vesselRequestId === (action.payload.positionId || action.payload.vesselRequestId)) {
      acc.push(index);
    }
    return acc;
  }, []);

  if (indexes.length === 0) return;
  indexes.forEach(i => {
    let newVessel = {
      ...(newState.vesselList.data[i]),
      channelUserStatus: {
        ...(newState.vesselList.data[i]?.channelUserStatus || {}),
        status: action.payload.positionStatus.status
      }
    }
    newState.vesselList.data[i] = newVessel;
  })
  return { ...state, ...newState };
}
