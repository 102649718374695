import Api from './api';

export default class City extends Api {
  _id;
  _name;
  _code;
  _country;

  // constructor(data) {
  //   super(data);
  // }
  static endpoint = '/cities';

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get code() {
    return this._code;
  }

  get country() {
    return this._country;
  }
  static find(params) {
    params.city = params.name;
    return Api.find.call(this, params).then(({ data }) => {
      data.forEach((c) => {
        c.name = `${c.name}, ${c.subdivision}, ${c.country.name}`;
      });
      return { data };
    });
  }
  toJSON() {
    return {
      _id: this.id,
      name: this.name,
      code: this.code,
      country: this.country,
    };
  }
}
