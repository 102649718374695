export default function FLEET_ADD_CREATED_VESSEL(state, action) {
  const fleet = state.vesselList.data.filter(i => {
    const toRemove = action.payload.find(a => a?._id === i?._id);
    return toRemove ? i.isUserOwner : true;
  });
  const filtered = state.vesselList.filtered.filter(i => {
    const toRemove = action.payload.find(a => a?._id === i?._id);
    return toRemove ? i.isUserOwner : true;
  });
  return { ...state, vesselList: { ...state.vesselList, filtered, data: fleet } };
}
