import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let ChatAndMailIcon = props => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.4604 6.40479H6.78133C6.04715 6.40479 5.45312 6.91162 5.45312 7.53109V18.085L8.11552 15.4152H17.4604C18.1946 15.4152 18.7953 14.9084 18.7953 14.2889V7.53109C18.7953 6.91162 18.1946 6.40479 17.4604 6.40479ZM12.2014 11.8032L17.4607 9.10402V7.53113L12.1212 10.3469L6.78164 7.53113L6.80295 9.10402L12.2014 11.8032Z" fill="#4380C7"/>
    <path d="M20.4844 10.5952H21.8594C22.2375 10.5952 22.5469 10.9046 22.5469 11.2827V21.5952L19.7969 18.8452H12.2344C11.8563 18.8452 11.5469 18.5358 11.5469 18.1577V16.7827H20.4844V10.5952Z" fill="#4380C7"/>
    <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" stroke="#D2D2D2"/>
  </svg>
);

ChatAndMailIcon = pure(ChatAndMailIcon);
ChatAndMailIcon.displayName = 'ChatAndMailIcon';
ChatAndMailIcon.muiName = 'SvgIcon';

export default ChatAndMailIcon;
