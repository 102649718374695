/** @name GET_INDEX_CHART_DATA_SUCCESS.js */
export default function GET_INDEX_CHART_DATA_SUCCESS(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    chartLoading: false,
    chartData: action.payload.data?.data[0].entries || [],
  };

  return { ...state, ...newState };
}
