/** @name SET_NEWS_SEARCH_PARAMS */
export default function SET_NEWS_SEARCH_PARAMS(state, action) {
  const { sort, q, categoryIds, page, limit, loading } = action.payload;
  const newState = {};

  newState.newsList = {
    ...state.newsList,
    sort,
    search: q,
    limit,
    page,
    selectedCategories: categoryIds,
    loading: loading,
  };

  return { ...state, ...newState };
}
