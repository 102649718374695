import React from 'react'
import s from './RatingBlock.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRatingAndComment } from '../../core/HOC';
import cx from 'classnames';

const { ratingGradation } = withRatingAndComment;

function RatingStars({ gradation = ratingGradation, handleStars, techRating, operRating, dark }) {
  return (
    <div className={cx(dark ? s.wrapper_tooltip_rating_dark : "", s.wrapper_rating_stars)}>
      <div className={s.raiting_choose}>
        <p>Technical Condition:</p>
        <div className={s.wrapper_stars}>
          {gradation.map(i => {
            return (
              [
                <input checked={techRating === i} onChange={handleStars.bind(this, 'techRating', i)}
                  type='radio' name='rating-technical' id={`star-technical-${i}`} value={i} />,
                <label htmlFor={`star-technical-${i}`}>
                </label>
              ]
            )
          })}
        </div>
      </div>
      <div className={s.raiting_choose}>
        <p>Operational Response:</p>
        <div className={s.wrapper_stars}>
          {gradation.map(i => {
            return (
              [
                <input checked={operRating === i} onChange={handleStars.bind(this, 'operRating', i)}
                  type='radio' name='rating-operational' id={`star-operational-${i}`} value={i} />,
                <label htmlFor={`star-operational-${i}`}>
                </label>
              ]
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default withStyles(s)(RatingStars);
