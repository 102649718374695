export default function buildUndoDeleteButton() {
    function UndoDeleteButton() {

        this.div_ = document.createElement('div');

        this.div_.style.width = '30px';
        this.div_.style.height = '27px';
        this.div_.style.overflow = 'hidden';
        this.div_.style.position = 'absolute';
        this.div_.style.cursor = 'pointer';

        this.img_ = document.createElement('img');
        this.img_.src = 'https://maps.gstatic.com/mapfiles/undo_poly.png';
        this.img_.style.position = 'absolute';
        this.img_.style.left = '0';
        this.img_.style.top = '0';

        this.img_.style.padding = '0';
        this.img_.style.margin = '0';

        this.img_.style.maxWidth = 'none';
        this.img_.style.width = '90px';
        this.img_.style.height = '27px';

        this.div_.appendChild(this.img_);

        google.maps.event.addDomListener(this.div_, 'click', () => {
            let path = this.get('path');
            let vertex = this.get('vertex');
            let position = this.get('position');

            if (!path || vertex == undefined) {
                this.close();
                return;
            }

            path.insertAt(vertex, position);
            this.close();
        });
    }

    UndoDeleteButton.prototype = new google.maps.OverlayView();

    UndoDeleteButton.prototype.onAdd = function () {
        let self = this;
        let map = this.getMap();
        this.getPanes().floatPane.appendChild(this.div_);

        // mousedown anywhere on the map except on the menu div will close the
        // menu.
        this.divListener_ = google.maps.event.addDomListener(map.getDiv(), 'mousedown', function (e) {
            if (e.target != self.img_) {
                self.close();
            }
        }, true);
    };

    UndoDeleteButton.prototype.onRemove = function () {
        google.maps.event.removeListener(this.divListener_);
        this.div_.parentNode.removeChild(this.div_);

        // clean up
        this.set('position');
        this.set('path');
        this.set('vertex');
    };

    UndoDeleteButton.prototype.close = function () {
        this.setMap(null);
    };

    UndoDeleteButton.prototype.draw = function () {
        let position = this.get('position');
        let projection = this.getProjection();

        if (!(position && projection)) {
            return;
        }

        let point = projection.fromLatLngToDivPixel(position);
        this.div_.style.top = point.y + 'px';
        this.div_.style.left = point.x + 'px';
    };

    UndoDeleteButton.prototype.open = function ( map, path, vertex ) {
        this.set('position', path.getAt(vertex));
        this.set('path', path);
        this.set('vertex', vertex);
        this.setMap(map);
        this.draw();
    };

    return new UndoDeleteButton();
}