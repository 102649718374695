import { filterVessel } from './filter';
import { escapeRegExp, removeFromArray, replaceInArray, sortByPath } from '../../core/utils';
import { orderBy } from 'lodash';
// noinspection JSUnusedGlobalSymbols
const sortVessels = { 1: 'asc' };
sortVessels[-1] = 'desc';
/** @name MONITOR_UPDATE_VESSEL_REDUCER */
export default function MONITOR_UPDATE_VESSEL(state, action) {
  const newState = {};
  const vesselList = { ...state.vesselList };
  newState.vesselList = vesselList;
  const index = vesselList.data.findIndex(v => v.vessel === action.payload.vessel);
  const vessel = vesselList.data[index];
  const hasExpired = state.vesselList.data.findIndex(v => v.expired === 1) !== -1;

  if (vessel && action.skipSort) {
    vesselList.data = replaceInArray(vesselList.data, index, action.payload);
    return { ...state, ...newState };
  }

  if (vessel) {
    vesselList.data = removeFromArray(vesselList.data, index);
  }

  if (action.payload.refNo || hasExpired || !vesselList.next) {
    vesselList.data = [action.payload, ...vesselList.data];
  }

  vesselList.data = orderBy(vesselList.data, [vesselList.sort.biddingStatus ? (v => v.weight || 0, v => v.status?.weight || 0) : null, vesselList.sort.field], ['desc', sortVessels[vesselList.sort.value]]);
  return { ...state, ...newState };
}
