import React from 'react';
import styles from './muistyles';
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import SectorSelect from "../Common/SectorSelect";

export function NarrowSectorSelect(props) {
  const {inputStyles, className, ...restProps} = props;
  return (
    <SectorSelect
      className={cx("select_default", className)}
      style={styles.selectContainer}
      floatingLabelFocusStyle={styles.text.labelFocus}
      floatingLabelStyle={styles.text.label}
      hintStyle={styles.text.label}
      inputStyle={{...styles.select.input, ...inputStyles}}
      floatingLabelShrinkStyle={styles.text.labelShrink}
      underlineStyle={styles.text.underline}
      underlineFocusStyle={styles.text.underlineFocus}
      errorStyle={styles.error}
      {...restProps}
    />
  );
}

export default withStyles(s)(NarrowSectorSelect);
