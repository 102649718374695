import type { Cargo, LegI, Vessel } from '../types';
import { getMtCmbFrtFromIntake, mtToOriginalIntakeUnit, or0, round } from '../utils';

export async function calculateIntake (legList: LegI[], vessel: Vessel, cargoList: Cargo[]){
  legList = [...legList];
  if(!vessel?.dw?.summer) {
    cargoList = this.syncIntakeToCargo(legList, cargoList);
    legList = this.calculateOnBoard(legList,vessel);
    return {legList, cargoList};
  }
  if(!vessel.consumptions) {
    vessel.consumptions = {};
  }
  if(!vessel.capacity) {
    vessel.capacity = {};
  }
  legList = this._calculateMaxIntake(legList, vessel);
  legList = this.syncDischarging(legList, vessel);
  legList =  this.calculateOnBoard(legList,vessel);
  let rearrangedRes = this.rearrangeNegativeMaxIntake(legList,vessel);
  legList = this.syncDischarging(rearrangedRes.legList, vessel);
  legList = this.calculateOnBoard(legList,vessel);
  legList = this._calculateMaxIntake(legList, vessel,rearrangedRes.corrected);
  legList = this.syncDischarging(legList, vessel);
  legList = await this.populateDistances(legList, this.state.current.consumptions[this.state.current.consumptions.mode]); // recalc ballast/laden
  cargoList = this.syncIntakeToCargo(legList, cargoList);
  legList =  this.calculateOnBoard(legList,vessel);
  return {legList, cargoList};
}



export function _calculateMaxIntake(legList: LegI[], vessel: Vessel, corrected = []  ) :LegI[]{
  legList = this.syncDischarging(legList,vessel);
  legList =  this.calculateOnBoard(legList,vessel);
  const dw = vessel.dw?.summer || 0;
  const capacity = (vessel.capacity?.grain || vessel.capacity?.bale) || dw;
  const vesselSf = capacity / dw;
  const tpc = vessel.consumptions?.tpc || 0;
  for (let i = 0; i < legList.length; i++) {
    let leg = legList[i];
    leg.intakeMax = {};
    if(leg.type === 'opening') {
      leg.sf = vesselSf;
    }
    const dischargings = legList.filter(l => l.type === 'discharging' && l.cargoId === leg.cargoId);
    const draft = Math.max(leg.onBoard.before.draft, leg.onBoard.after.draft, vessel.draft);
    let draftCorrection = 0;
    if(leg.draft < draft) {
      draftCorrection = tpc * 100 * (draft - leg.draft);
    }
    leg.intakeMax.mtValue = dw - leg.onBoard.before.mt - leg.onBoard.draftCorrection;
    leg.intakeMax.cbmValue = capacity - leg.onBoard.before.cbm;
    leg.intakeMax.mtValue = Math.min(leg.intakeMax.mtValue, leg.intakeMax.cbmValue / (leg.sf || 1) )
    legList[i] = {...leg};
      if(leg.type === 'loading') {
        if(corrected.indexOf(leg._id) === -1 && this.isIntakeCanBeAdjusted(leg)) {
          let newValue = leg.intakeMax.mtValue;
          if(newValue < 0 ) {
            newValue = 0;
          }
          leg = this.setIntake(leg,newValue, vessel);
          corrected.push(leg._id);

          if(dischargings.length > 0 && this.isIntakeCanBeAdjusted(dischargings[0])) {
            legList[legList.indexOf(dischargings[0])] = this.setIntake(dischargings[0], newValue, vessel );
          }

          legList[i] = {...leg};
          return this._calculateMaxIntake(legList, vessel, corrected)
        }
        /*if(dischargings.length === 1) {
          dischargings[0].intake.value = leg.intakeMax.mtValue;
          legList[legList.indexOf(dischargings[0])] = {...dischargings[0]}
        } else {
          let toUnload = leg.intake.value;
          for (let j = 0; j < dischargings.length; j++) {
            const discharging = dischargings[j];

          }*/
        }
      if(leg.type === 'opening') {
        leg = this.setIntake(leg,leg.intakeMax.mtValue, vessel);
        legList[i] = {...leg};
      }
    }

  return legList;
}

export function calculateOnBoard(legList, vessel){
  //TODO calculate used fuel
  if(!vessel.consumptions) {
    vessel.consumptions = {};
  }
  if(!vessel.capacity) {
    vessel.capacity = {};
  }
  let onBoardMt = 0, onBoardCbm = 0, maxFuelCorrection = 0, onBoardFrt = 0;
  onBoardMt+= or0(vessel?.capacity?.constant);
  onBoardMt+= or0(vessel.bunkerOnBoard);
  maxFuelCorrection = or0(vessel.bunkerOnBoard) - 200;
  const dwt = vessel.dw?.summer || 0;
  let onBoardCargoesWeight = { };
  let onBoardCargoesFrt = { };
  // calculate onboard first stage
  for (let i = 0; i < legList.length; i++) {
    const leg = legList[i];
    leg.onBoard = {
      before: {
        mt: 0,
        cbm: 0,
        frt: 0,
      }, after: {
        mt: 0,
        cbm: 0,
        frt: 0,
      },
    }
    let {mt, cbm, frt} = getMtCmbFrtFromIntake(leg.intake,leg.sf);
    leg.onBoard.before.mt = onBoardMt;
    leg.onBoard.before.cbm = onBoardCbm;
    leg.onBoard.before.frt = onBoardFrt;
    leg.onBoard.before.cargoWeightMap = {...onBoardCargoesWeight};
    leg.onBoard.before.cargoFrtMap = {...onBoardCargoesFrt};
    leg.onBoard.maxMt = dwt;
    leg.onBoard.draftCorrection = 0;
    const waterDensCorrection = dwt -  dwt * ((leg.waterDens || 1.025) / 1.025)
    if((leg.draft || 100) < (vessel.draft || 0)) {
      leg.onBoard.draftCorrection = or0(vessel.consumptions.tpc) * 100 * (vessel.draft - leg.draft) * ((leg.waterDens || 1.025) / 1.025);
    }
    leg.onBoard.draftCorrection = Math.max(leg.onBoard.draftCorrection, waterDensCorrection,0);
    switch (leg.type) {
      case 'loading':
        onBoardMt += mt;
        onBoardCbm += cbm;
        onBoardFrt += frt;
        leg.onBoard.after = { mt: onBoardMt, cbm: onBoardCbm, frt: onBoardFrt };
        onBoardCargoesWeight[leg.cargoId] = mt;
        onBoardCargoesFrt[leg.cargoId] = frt;
        break;
      case 'bunkering':
        onBoardMt += mt;
        //onBoardCbm += cbm;
        leg.onBoard.after = { mt: onBoardMt, cbm: onBoardCbm, frt: onBoardFrt };
        break;
      case 'discharging':
        frt = Math.min(onBoardCargoesFrt[leg.cargoId] || 0, frt);
        mt = Math.min(onBoardCargoesWeight[leg.cargoId] || 0, mt);
        onBoardMt -= mt;
        onBoardCbm -= cbm;
        onBoardFrt -= frt;
        onBoardMt = Math.max(onBoardMt,0);
        onBoardCbm = Math.max(onBoardCbm,0);
        onBoardFrt = Math.max(onBoardFrt,0);
        onBoardCargoesWeight[leg.cargoId] -= mt;
        onBoardCargoesWeight[leg.cargoId] = Math.max(onBoardCargoesWeight[leg.cargoId] || 0, 0);
        onBoardCargoesFrt[leg.cargoId] -= frt;
        onBoardCargoesFrt[leg.cargoId] = Math.max(onBoardCargoesFrt[leg.cargoId] || 0, 0);
        if(onBoardCargoesWeight[leg.cargoId] <= 0) {
          delete onBoardCargoesWeight[leg.cargoId];
        }
        if(onBoardCargoesFrt[leg.cargoId] <= 0) {
          delete onBoardCargoesFrt[leg.cargoId];
        }
        leg.onBoard.after = { mt: onBoardMt, cbm: onBoardCbm, frt: onBoardFrt };
        break;
      default:
        leg.onBoard.after = {...leg.onBoard.before};

    }
    leg.onBoard.after.cargoWeightMap = { ...onBoardCargoesWeight };
    leg.onBoard.after.cargoFrtMap = { ...onBoardCargoesFrt };
    if(vessel.consumptions.tpc) {
      leg.onBoard.before.draft = Math.min(vessel.draft - (((dwt - leg.onBoard.before.mt) / vessel.consumptions.tpc) / 100), vessel.draft);
      leg.onBoard.after.draft = Math.min(vessel.draft - (((dwt - leg.onBoard.after.mt) / vessel.consumptions.tpc) / 100), vessel.draft);
    }

  }
  return legList;
}

export function syncDischarging(legList, vessel){
  legList =  this.calculateOnBoard(legList, vessel);
  for (let i = 0; i < legList.length; i++) {
    const leg = legList[i];
    if(leg.type === 'discharging' && this.isIntakeCanBeAdjusted(leg)) {
      const onBoard = leg.onBoard.before.cargoWeightMap[leg.cargoId];
      legList[i] = this.setIntake(leg,onBoard, vessel);
    }
  }
  return legList;
}

export function setIntake(leg, mtValue, vessel){
  //TODO add unit conversion
  let value = parseInt(mtToOriginalIntakeUnit(leg.intake, mtValue, vessel, leg.sf));
  if(value < 1.1) {
    value = 0
  }
  if(leg?.intake?.value !== value) {
    console.log('set intake', value);
    leg = this.recalculateLeg('intake.value',value, leg, undefined, this.state.current.cargoList);
  }
  return leg;
}

export function isIntakeCanBeAdjusted(leg) {
  if(leg.type !== 'loading' && leg.type !== 'discharging') {
    return false;
  }
  return  leg?.manuallyChangedFields?.intake_value === undefined;
}

export function rearrangeNegativeMaxIntake(legList,vessel) {
  let corrected = []
  let freeze = [];
  for (let i = 0; i < legList.length; i++) {
      const leg = legList[i];
      /*if(!this.isIntakeCanBeAdjusted(leg)) {
        continue;
      }*/
       try {
      if (leg.intakeMax.mtValue < 0 ) {
        let rest = -1 * leg.intakeMax.mtValue;

        const cargoIds = Object.entries(leg.onBoard.before.cargoWeightMap).filter(([id, mt]) => typeof mt === 'number').map(([id]) => id);
        const loadingLegsBefore = legList.slice(0, i).filter(l => l.type === 'loading' && cargoIds.indexOf(l.cargoId) !== -1);
        freeze = [...freeze, ...loadingLegsBefore.map(l=> l._id)]; //try to freeze legs before
        loadingLegsBefore.reverse();
        for (let j = 0; j < loadingLegsBefore.length; j++) {
          let llb = loadingLegsBefore[j];
          if(!this.isIntakeCanBeAdjusted(llb)) {
            continue;
          }
            const {mt} = getMtCmbFrtFromIntake(llb.intake,llb.sf);
            let decr = Math.min(rest, leg.onBoard.before.cargoWeightMap[llb.cargoId], mt);
            //corrected.push(llb._id)
            legList[legList.indexOf(llb)] = this.setIntake(llb,mt - decr, vessel);
            rest -= decr;
            if (rest <= 0) {
              break;
            }

        }
        if(this.isIntakeCanBeAdjusted(leg)) {
          legList[i] = this.setIntake(leg,-1 * rest,vessel);
        }
        legList = this._calculateMaxIntake(legList,vessel,legList.map(l => l._id));
        freeze.push(leg._id);
      }
    } catch (e) {
      debugger
      throw e;
    }
  }
  corrected = [...corrected, ...freeze];
  return {legList, corrected};
}

export function syncIntakeToCargo(legList = [], cargoList = []) {
  if (!this.shouldSyncIntakeToCargo) {
    return cargoList;
  }
  cargoList = [...cargoList];
  for (let i = 0; i < cargoList.length; i++) {
    const cargo = { ...cargoList[i] };
    const leg = legList.find(l => l.type === 'loading' && l.cargoId === cargo._id);
    const dischargingLeg = legList.find(l => l.type === 'discharging' && l.cargoId === cargo._id);
    if (!dischargingLeg) {
      cargo.unloadingPort = undefined;
    }else {
      cargo.unloadingPort = dischargingLeg.port;
    }
    if (!leg) {
      cargo.loadingPort = undefined;
      // eslint-disable-next-line no-continue
      continue;
    } else {
      cargo.loadingPort = leg.port;
    }

    const prevLegs = this.getCurrentState().legList;
    const prevLeg = prevLegs.find(l => l._id === leg._id && l.type === 'loading' && l.cargoId === cargo._id);
    const { mt, cbm, frt } = getMtCmbFrtFromIntake(leg.intake, leg.sf);
    cargo.weight = mt;
    cargo.volume = cbm;
    if (typeof cargo.manuallyChangedFields?.frt === "undefined") {
      cargo.frt = frt;
      cargo.quantity = leg.intake.value;
      cargo.revenue = round(cargo.frt * cargo.freight);
    }
    cargo.unit = leg.intake.unit?.toUpperCase();
    cargo.sf = leg.sf;
    cargo.sfCbf = leg.sfCbf;
    cargoList[i] = cargo;
  }
  return cargoList;
}

function clean(legList){
  for (let i = 0; i < legList.length; i++) {
    const leg = legList[i];
    leg.onBoard = undefined;
  }
}
