import React, { Component } from "react";

//COMPONENTS
import CustomTooltip from "./CustomTooltip"

//STYLED COMPONENTS
import { Icon } from "./StyledComponents";

//ACTIONS
import Visibility from "material-ui/svg-icons/action/visibility";
import VisibilityOff from "material-ui/svg-icons/action/visibility-off";
import Hoverable from './Hoverable';
import { debounce } from '../../core/utils';

class ShowHideEye extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHiding: false
    }
  }

  toggle = debounce(async (ev, selectedOrdersCount) => {
    this.setState({
      isHiding: true
    })
    try {
      const res = await Promise.resolve(this.props.toggleShowHideOrders(ev, selectedOrdersCount));
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isHiding: false
      })
    }
  })

  render() {
    const { isHiding } = this.state;
    const { selectedOrdersCount, showHiddenOrders, hiddenCount, toggleShowHideOrders, isDark = false, tooltipClassName = "", tooltipProps = {}, disabled = false, style = {}, ...rest } = this.props;
    let eyeTooltip;
    if (selectedOrdersCount) {
      eyeTooltip = showHiddenOrders ? `Remove ${selectedOrdersCount} selected from hidden` : `Hide ${selectedOrdersCount} selected elements`;
    } else {
      eyeTooltip = showHiddenOrders ? "Show visible" : "Show hidden";
    }

    return (
      <CustomTooltip
        dark={!isDark} tooltip={eyeTooltip} className={tooltipClassName} {...tooltipProps}
      >
        <Hoverable onClick={(ev) => this.toggle(ev, selectedOrdersCount)} style={{display: "flex", alignItems: "center", padding: "2px 3px", justifyContent: "center", ...style}} disabled={isHiding || disabled} exactSize {...rest}>
          <Icon
            style={{ marginRight: '4px' }}
          >
            {!!hiddenCount &&
              <span style={{color: selectedOrdersCount ? '#285596' : '#787878', fontSize: '11px', marginLeft: '4px'}}>
                {hiddenCount}
              </span>
            }
            {showHiddenOrders ?
              <Visibility
                color={selectedOrdersCount ? '#285596' : '#787878'}
                style={{ marginLeft: '3px', width: '20px', height: '20px' }}
              /> :
              <VisibilityOff
                color={selectedOrdersCount ? '#285596' : '#787878'}
                style={{ marginLeft: '3px', width: '20px', height: '20px' }}
              />
            }
          </Icon>
        </Hoverable>
      </CustomTooltip>
    )
  }
}

export default ShowHideEye;
