/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const NAVIGATE = 'NAVIGATE';
/**@see  {@link DICTIONARY_REDUCER}*/
export const DICTIONARY = 'DICTIONARY';
export const MESSAGE = 'MESSAGE';

/**@see  {@link ADD_TOP_MESSAGE_REDUCER}*/
export const ADD_TOP_MESSAGE = 'ADD_TOP_MESSAGE';

/**@see  {@link REMOVE_TOP_MESSAGE_REDUCER}*/
export const REMOVE_TOP_MESSAGE = 'REMOVE_TOP_MESSAGE';
