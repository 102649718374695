import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from './Dialog';

export default class ContractLink extends Component {
  state = {};
  static propTypes = {
    contract: PropTypes.object.isRequired,
  };

  static contextTypes = {
    contracts: PropTypes.array,
  };

  handleOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.contract._id !== nextProps.contract._id || this.state.dialogOpened !== nextState.dialogOpened;
  }

  render() {
    if (!this.props.contract) {
      return null;
    }
    const contract = this.context.contracts.find(c => c._id === this.props.contract._id);
    if (!contract) {
      return null;
    }
    let name;
    if (contract.noPdf) {
      name = contract.name;
    } else {
      name = <a onClick={this.handleOpen}>{contract.name}</a>;
    }
    return this.props.contract
      ? <span>
        {name}
        {this.state.dialogOpened ?
          <Dialog
            title={this.props.contract.name + ' sample copy'}
            open
            handleClose={this.handleClose}
            bodyStyle={{overflowY: 'hidden', padding: '0px'}}
          >
            <div style={{ height: 'calc(100vh - 205px)', width: '100%' }} >
              <object
                style={{ height: 'calc(100vh - 205px)' }} width="100%" type="application/pdf"
                data={`/sample_contracts/${this.props.contract.name.replace(/ /g, '_')}.pdf?#zoom=85&scrollbar=0&toolbar=0&navpanes=0`} id="pdf_content"
              >
                <p>This browser does not support PDFs. Please download the PDF to view it:
                  <a download href={`/sample_contracts/${this.props.contract.name.replace(/ /g, '_')}.pdf`}>Download PDF</a>.</p>
              </object>
            </div>
          </Dialog>
          : null}
      </span>
      : null;
  }
}
