import React from 'react';
import Layout from '../../components/Layout';
import {
  clearSelectedContract,
  getFixedContracts,
  getVoyageContractById,
  getVoyageContractInvoices
} from '../../actions/contracts';

import { getBl } from '../../actions/bl';
import { getInvoice } from '../../actions/invoices';
import {getAccountFilters} from "../../actions/login";

const availableContractTypes = ['voyage', 'tc', 'bookingnote'];

export default {
  path: '/contracts',
  children: [
    {
      path: '/bill-of-lading/:bolId?',
      async action({ params, store, token, next }) {
        const BillOfLadingPage = ((await import(/* webpackChunkName: 'BillOfLandings' */ '../../components/Contracts/BillOfLading/BillOfLadingPage')).default);
        if (typeof window !== 'undefined' && params.bolId) {
          const res = await store.dispatch(getBl(params.bolId, token));
          if (res.status !== 200) {
            return await next();
          }
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: <Layout><BillOfLadingPage selectedBolId={params.bolId} /></Layout>,
        };
      },
    },
    {
      path: '/invoice/:invoiceId?/:action?',
      async action({ params, store, token, next, previousPath, pathname }) {
        const InvoicePage = ((await import(/* webpackChunkName: 'Invoices' */ '../../components/Contracts/Invoice/InvoicePage')).default);
        if (typeof window !== 'undefined' && params.invoiceId && params.invoiceId!=='-') {
          try {
            const res = await store.dispatch(getInvoice(params.invoiceId, token));
            if(res.status && res.status!==200 && res.status!==304) return await next();
          } catch (error) {
            console.log(error);
            return await next();
          }
        }
        try {
          if (!previousPath || pathname.slice(12) !== previousPath.slice(12)) {
            await store.dispatch(getAccountFilters(token));
          }
        } catch (e) {
          console.error(e);
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: <Layout><InvoicePage action={params.action} invoiceId={params.invoiceId} /></Layout>,
        };
      },
    },
    /*{
      path: '/bill-of-lading/:bolId',
      async action({ params, store, token, next }) {
        const BillOfLading = ((await import(/!* webpackChunkName: 'BillOfLadings' *!/ '../../components/BillOfLading/BillOfLading')).default);
        if (typeof window !== 'undefined') {
          const res = await store.dispatch(getBl(params.bolId, token));
          if (res.status !== 200) {
            return await next();
          }
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: <Layout><BillOfLading {...params} /></Layout>,
        };
      },
    },*/
    {
      path: '/:type/:contractId?/:action?',
      async action({
        params,
        path,
        previousPath,
        hash,
        store,
        token,
        next,
        pathname,
        ...rest
      }) {
        const Contracts = ((await import(/* webpackChunkName: 'Contracts' */ '../../components/Contracts/Contracts')).default);
        let err;
        if (!availableContractTypes.includes(params.type)) {
          return await next();
        }
        if (typeof window !== 'undefined') {
          if (!params.contractId) {
             store.dispatch(clearSelectedContract());
          }
          try {
            if (!previousPath || (previousPath.indexOf('/contracts/voyage') !== 0 && previousPath.indexOf('/contracts/tc') !== 0)) {
              // DEPRECATED: each section now fetches contract list on mount
              //await store.dispatch(getFixedContracts());
            }
            const addNewReservedWords = ['-', 'create'];
            if (params.contractId && !addNewReservedWords.includes(String(params.contractId).toLowerCase())) {
              await store.dispatch(getVoyageContractById(params.contractId, params.type));
              await store.dispatch(getVoyageContractInvoices(params.contractId))
            }
          } catch (e) {
            err = e;
          }
        }
        try {
          if (!previousPath || pathname.slice(12) !== previousPath.slice(12)) {
            await store.dispatch(getAccountFilters(token));
          }
        } catch (e) {
          console.error(e);
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: <Layout><Contracts err={err} {...params} /></Layout>,
        };
      },
    },
  ],
};
