import { filterVessel } from './filter';
import { escapeRegExp, sortByPath } from '../../core/utils';
//noinspection JSUnusedGlobalSymbols
/** @name MONITOR_VESSEL_NEW_REDUCER */
export default function MONITOR_VESSEL_NEW(state, action) {
  const newState = {};
  const vesselList = { ...state.vesselList };
  let group = vesselList.data.find(vr => vr.groupId === action.payload.groupId);
  const addedByUser =
    state.user && action.payload.addedBy._id === state.user._id;
  action.payload.addedByUser = addedByUser;
  //noinspection JSCheckFunctionSignatures
  if (!filterVessel(state.vesselList.filters, action.payload)) {
    return state;
  }
  if (vesselList.filters.search) {
    const reg = escapeRegExp(vesselList.filters.search);
    if (!reg.test(action.payload.fullTextSearch)) {
      return state;
    }
  }
  if (!group) {
    action.payload.channels = [];
    action.payload.expired = 0;
    vesselList.data.unshift(action.payload);
    newState.vesselList = vesselList;
  } else {
    const index = vesselList.data.indexOf(group);

    if (addedByUser) {
      group = { ...group, expired: 0 };
      vesselList.data = [...vesselList.data];
      const channel = {
        addedAt: group.addedAt,
        addedBy: group.addedBy,
        endAt: group.endAt,
        open: group.open,
        refNo: group.refNo,
        vesselRequest: group._id,
      };
      group.channels.push(channel);
      group._id = action.payload._id;
      group.addedAt = action.payload.addedAt;
      group.addedBy = action.payload.addedBy;
      group.addedByUser = addedByUser;
      group.refNo = action.payload.refNo;
      group.endAt = action.payload.endAt;
      vesselList.data[index] = group;
    } else {
      const channel = {
        addedAt: action.payload.addedAt,
        addedBy: action.payload.addedBy,
        endAt: action.payload.endAt,
        open: action.payload.open,
        refNo: action.payload.refNo,
        vesselRequest: action.payload._id,
      };
      group.channels.push(channel);
    }
  }
  vesselList.data = sortByPath(vesselList.data, vesselList.sort.field, vesselList.sort.value);
  newState.vesselList = vesselList;
  return { ...state, ...newState };
}
