import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Header.scss';
import { connect } from 'react-redux';
import { logout, openLogin } from '../../actions/login';
import LeftBar from '../LeftBar/LeftBar';
import cx from 'classnames';
//import Logo_Header from '../Icons/Logo_Header';

function Header2(props) {

  //const { logo } = this.props;

  return (
    <div id="shipnext-header" className={s.root + ' ' + s.secondary}>
      <div className={s.container}>
        <LeftBar/>
        <div className={s.title}>{props.title}</div>
        <div className={s.left}>{props.left}</div>
      </div>
      <div className={cx(s.right, props.iconClose ? s.style_for_close : null)}>{props.right}</div>
    </div>
  );
}
Header2.propTypes = {
  title: PropTypes.any,
  left: PropTypes.element,
  right: PropTypes.node,
};

export default connect(state => state.login, { logout, openLogin })(
  withStyles(s)(Header2),
);
