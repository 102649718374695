/** @name GET_NEWS_LIST */
export default function GET_NEWS_LIST(state, action) {
  const newState = {};

  newState.newsList = {
    ...state.newsList,
    loading: true,
    page: 1,
    errorMessage: '',
  };

  return { ...state, ...newState };
}
