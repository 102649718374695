import NarrowSelect from '../NewInputs/NarrowSelect';
import MenuItem from 'material-ui/MenuItem';
import React from 'react';

export default function FittedSelect(props) {
  return (<NarrowSelect
    fullWidth
    {...props}
  >
    <MenuItem value={true} primaryText="fitted" />
    <MenuItem value={false} primaryText="not fitted" />
    <MenuItem value={null} primaryText="" />
    <MenuItem style={{ display: 'none' }} value={undefined} primaryText="" />
  </NarrowSelect>);
}
