import history from '../../core/history';

/** @name VESSEL_REQUEST_GET_CARGO_REDUCER */
export default function VESSEL_REQUEST_GET_CARGO(state, { res, payload }) {
  const newState = {};
  if (!res.data) {
    newState.selectedCargo = null;
    newState.selected = null;
    return { ...state, ...newState };
  }
  if (res.data.info && res.data.info.bidId) {
    history.push('/bid/cargo/' + res.data.info.bidId);
    return state;
  }
  newState.selectedCargo = res.data;
  newState.selected = {
    cargoRequest: res.data,
    vesselRequest: { ...res.data.vesselRequest },
  };
  return { ...state, ...newState };
}
