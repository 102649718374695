/** @name SENT_TCOFFER_LIST_REQUEST_REDUCER */
 export default function SENT_TCOFFER_LIST_REQUEST(state, action) {
  let offerList;

  if (action) {
    const { filters = {}, sort = {}, page = 1 } = action.payload;
    offerList = {
      ...state.sentTcOfferList,
      filters,
      sort,
      page,
     };
  } else {
    offerList = {
      ...state.sentTcOfferList,
      filters: {},
      sort: {},
      page: 1,
    };
  }

     const newState = { sentTcOfferList: offerList };
    return { ...state, ...newState };
}
