import React from 'react';
import FormsyText from '../Common/FormsyText';
import { floatingLabelFocusStyle, floatingLabelShrinkStyle, floatingLabelStyle, inputStyle } from './settings';

const labelStyle = { fontSize: '12px', lineHeight: '12px', color: '#999999', top: '46px', transform: 'scale(1) translate(0px, 0px)' };
const labelShrinkStyle = { transform: 'scale(1) translate(0px, -20px)' };

export default function Restrictions(props) {
  const {
    ...rest
  } = props;
  return (
    <span>
      <FormsyText
        autoComplete="off"
        floatingLabelStyle={labelStyle}
        floatingLabelShrinkStyle={labelShrinkStyle}
        fullWidth
        {...rest}
      />
    </span>
  );
}

