import Sort from "../../Common/Sort";

export const sortFields = {
  capacity: {
    field: 'capacity', value: 1, label: 'Capacity',
  },
  deadweight: {
    field: 'deadweight',
    value: 1,
    label: 'Deadweight',
  },
  category: {
    field: 'category',
    value: 1,
    label: 'Category',
  },
};
export const defaultSortField = sortFields.category;


class SortMyFleet extends Sort {
  static defaultProps = {
    sort: { field: 'category', value: 1, label: 'Category' },
    values: {
      category: { field: 'category', value: 1, label: 'Category' },
    },
  };
}
export default SortMyFleet;
