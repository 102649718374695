/**@see  {@link BL_GET_REDUCER}*/
export const BL_GET = 'BL_GET';
/**@see  {@link BL_GET_INVOICES_REDUCER}*/
export const BL_GET_INVOICES = 'BL_GET_INVOICES';
/**@see  {@link BL_GET_REQUEST_REDUCER}*/
export const BL_GET_REQUEST = 'BL_GET_REQUEST';
/**@see  {@link BL_GET_FAILED_REDUCER}*/
export const BL_GET_FAILED = 'BL_GET_FAILED';
/**@see  {@link BL_CREATE_REDUCER}*/
export const BL_CREATE = 'BL_CREATE';
/**@see  {@link BL_CREATE_REQUEST_REDUCER}*/
export const BL_CREATE_REQUEST = 'BL_CREATE_REQUEST';
/**@see  {@link BL_CREATE_FAILED_REDUCER}*/
export const BL_CREATE_FAILED = 'BL_CREATE_FAILED';
/**@see  {@link BL_INVITE_REDUCER}*/
export const BL_INVITE = 'BL_INVITE';
/**@see  {@link BL_INVITE_REQUEST_REDUCER}*/
export const BL_INVITE_REQUEST = 'BL_INVITE_REQUEST';
/**@see  {@link BL_INVITE_FAILED_REDUCER}*/
export const BL_INVITE_FAILED = 'BL_INVITE_FAILED';
/**@see  {@link BL_CONFIRM_PARTICIPATION_REDUCER}*/
export const BL_CONFIRM_PARTICIPATION = 'BL_CONFIRM_PARTICIPATION';
/**@see  {@link BL_CONFIRM_PARTICIPATION_REQUEST_REDUCER}*/
export const BL_CONFIRM_PARTICIPATION_REQUEST = 'BL_CONFIRM_PARTICIPATION_REQUEST';
/**@see  {@link BL_CONFIRM_PARTICIPATION_FAILED_REDUCER}*/
export const BL_CONFIRM_PARTICIPATION_FAILED = 'BL_CONFIRM_PARTICIPATION_FAILED';
/**@see  {@link BL_REJECT_PARTICIPATION_REDUCER}*/
export const BL_REJECT_PARTICIPATION = 'BL_REJECT_PARTICIPATION';
/**@see  {@link BL_REJECT_PARTICIPATION_REQUEST_REDUCER}*/
export const BL_REJECT_PARTICIPATION_REQUEST = 'BL_REJECT_PARTICIPATION_REQUEST';
/**@see  {@link BL_REJECT_PARTICIPATION_FAILED_REDUCER}*/
export const BL_REJECT_PARTICIPATION_FAILED = 'BL_REJECT_PARTICIPATION_FAILED';
/**@see  {@link BL_REMIND_REDUCER}*/
export const BL_REMIND = 'BL_REMIND';
/**@see  {@link BL_REMIND_REQUEST_REDUCER}*/
export const BL_REMIND_REQUEST = 'BL_REMIND_REQUEST';
/**@see  {@link BL_REMIND_FAILED_REDUCER}*/
export const BL_REMIND_FAILED = 'BL_REMIND_FAILED';
/**@see  {@link BL_SIGN_REDUCER}*/
export const BL_SIGN = 'BL_SIGN';
/**@see  {@link BL_SIGN_REQUEST_REDUCER}*/
export const BL_SIGN_REQUEST = 'BL_SIGN_REQUEST';
/**@see  {@link BL_SIGN_FAILED_REDUCER}*/
export const BL_SIGN_FAILED = 'BL_SIGN_FAILED';
/**@see  {@link BL_AMEND_REDUCER}*/
export const BL_AMEND = 'BL_AMEND';
/**@see  {@link BL_AMEND_REQUEST_REDUCER}*/
export const BL_AMEND_REQUEST = 'BL_AMEND_REQUEST';
/**@see  {@link BL_AMEND_FAILED_REDUCER}*/
export const BL_AMEND_FAILED = 'BL_AMEND_FAILED';

/**@see  {@link BL_GET_LIST_REDUCER}*/
export const BL_GET_LIST = 'BL_GET_LIST';
/**@see  {@link BL_GET_LIST_REQUEST_REDUCER}*/
export const BL_GET_LIST_REQUEST = 'BL_GET_LIST_REQUEST';
/**@see  {@link BL_GET_LIST_FAILED_REDUCER}*/
export const BL_GET_LIST_FAILED = 'BL_GET_LIST_FAILED';

/**@see  {@link BL_CONFIRM_PARTICIPANTS_REDUCER}*/
export const BL_CONFIRM_PARTICIPANTS = 'BL_CONFIRM_PARTICIPANTS';
/**@see  {@link BL_CONFIRM_PARTICIPANTS_REQUEST_REDUCER}*/
export const BL_CONFIRM_PARTICIPANTS_REQUEST = 'BL_CONFIRM_PARTICIPANTS_REQUEST';
/**@see  {@link BL_CONFIRM_PARTICIPANTS_FAILED_REDUCER}*/
export const BL_CONFIRM_PARTICIPANTS_FAILED = 'BL_CONFIRM_PARTICIPANTS_FAILED';
/**@see  {@link BL_CHANGE_FIELD_REDUCER}*/
export const BL_CHANGE_FIELD = 'BL_CHANGE_FIELD';
/**@see  {@link BL_CHANGE_FIELD_REQUEST_REDUCER}*/
export const BL_CHANGE_FIELD_REQUEST = 'BL_CHANGE_FIELD_REQUEST';
/**@see  {@link BL_CHANGE_FIELD_FAILED_REDUCER}*/
export const BL_CHANGE_FIELD_FAILED = 'BL_CHANGE_FIELD_FAILED';

/**@see  {@link BL_ISSUE_REDUCER}*/
export const BL_ISSUE = 'BL_ISSUE';
/**@see  {@link BL_ISSUE_REQUEST_REDUCER}*/
export const BL_ISSUE_REQUEST = 'BL_ISSUE_REQUEST';
/**@see  {@link BL_ISSUE_FAILED_REDUCER}*/
export const BL_ISSUE_FAILED = 'BL_ISSUE_FAILED';

/**@see  {@link BL_ENTRUST_REDUCER}*/
export const BL_ENTRUST = 'BL_ENTRUST';
/**@see  {@link BL_ENTRUST_REQUEST_REDUCER}*/
export const BL_ENTRUST_REQUEST = 'BL_ENTRUST_REQUEST';
/**@see  {@link BL_ENTRUST_FAILED_REDUCER}*/
export const BL_ENTRUST_FAILED = 'BL_ENTRUST_FAILED';

/**@see  {@link BL_REMIND_APPROVAL_REDUCER}*/
export const BL_REMIND_APPROVAL = 'BL_REMIND_APPROVAL';
/**@see  {@link BL_REMIND_APPROVAL_REQUEST_REDUCER}*/
export const BL_REMIND_APPROVAL_REQUEST = 'BL_REMIND_APPROVAL_REQUEST';
/**@see  {@link BL_REMIND_APPROVAL_FAILED_REDUCER}*/
export const BL_REMIND_APPROVAL_FAILED = 'BL_REMIND_APPROVAL_FAILED';

