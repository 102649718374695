import MenuItem from 'material-ui/MenuItem';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import { NarrowSelect } from '../../NewInputs/NarrowSelect';

export const surchargeMapper = memoizeOne(surcharges => Object.keys(surcharges).map(charge => ({
  label: `${charge} - ${surcharges[charge]}`,
  value: charge,
})));
class SurchargeSelect extends React.Component {

  static contextTypes = {
    surchargeTypes: PropTypes.array,
  }

  renderCollection = (surcharges) => {
    return surcharges.map(surcharge => <MenuItem value={surcharge.value} primaryText={surcharge.label} key={surcharge.value} />);
  }

  render() {
    const { as, name = 'surchargeType', ...rest } = this.props;
    const { surchargeTypes = [] } = this.context;

    const normalizedCharges = surchargeMapper(surchargeTypes);

    const El = as ?? NarrowSelect;

    return (
      <El
        name={name}
        {...rest}
      >
        {
          this.renderCollection(normalizedCharges)
        }
      </El>
    )
  }

}

export default SurchargeSelect
