import React, { PureComponent } from 'react';
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";
import {AddButtonBlue, ContentClearButton} from "../Common/AddButton";
import RemoveButton from "../Common/RemoveButton";

type Props = {
  value: [{ value: number, temp: number }],
  name: string,
  onChange: () => undefined
};

type State = {};
class Densities extends PureComponent<Props, State> {

  static defaultProps = {
    value: [{ density: "", temperature: "" }],
  }
  addDensity = () => {
      const densities = this.getValue();
      this.onChange([...densities, { density: "", temperature: "" }]);
  }
  removeDensity = (i) => {
    const densities = this.getValue().filter((d, j) => i !== j);
    this.onChange(densities);
  }

  onDensityFieldChange = (i, name, e, val) => {
    const densities = this.getValue();
    const d = densities[i];
    d[name] = val;
    this.onChange(densities);
  }

  onChange = (value) => {
    this.props.onChange(null, value);
  }

  getValue = () => {
    if (!this.props.value?.[0]) {
      return [[{ density: "", temperature: "" }]];
    }
    return this.props.value;
  }
  render() {
    const { value, className, style, name, fullName, ...rest } = this.props;
    const densities = this.getValue();
    return (
      <div className={className} style={style}>
        {
          densities.map((d, i) => <div key={i} className={"row"}>
            <div className={"col-3"}>
              <NarrowFormsyText
                value={d.density}
                name={`${name}[${i}][density]`}
                floatingLabelText={'Density, kg/m3'}
                onChange={this.onDensityFieldChange.bind(this, i, 'density')}
                fullWidth
                validations={'isNumeric'}
                validationErrors={{
                  'isNumeric': 'must be a number',
              }}
              />
            </div>
            <div className={"col-3"}>
              <NarrowFormsyText
                value={d.temperature}
                name={`${name}[${i}][temperature]`}
                floatingLabelText={'Temperature, °C'}
                onChange={this.onDensityFieldChange.bind(this, i, 'temperature')}
                fullWidth
                validations={'isNumeric'}
                validationErrors={{
                  'isNumeric': 'must be a number',
                }}
              />
            </div>
            <div className={"col-6 flex align-items-center"}>
              { i === 0 ?
                <AddButtonBlue onClick={this.addDensity} />
                :
                <ContentClearButton onClick={this.removeDensity.bind(this, i)} />
              }
            </div>
          </div>)
        }
      </div>
    );
  }
}

export default Densities;
