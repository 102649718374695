import React from "react";
import Layout from "../../components/Layout";

export default {
  path: "/markets",
  async action() {
    const MarketMonitor = ((await import(/* webpackChunkName: 'Monitor' */ '../../components/MarketMonitor/MarketMonitor')).default);
    return {
        title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
        component: (
          <Layout>
            <MarketMonitor />
          </Layout>
        ),
        public: false,
      };
  },
};
