import React from 'react'
import cx from 'classnames';
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Highlight from '../../Monitor/Highlight';
function VesselName({ vessel, search, className = "", ...rest }) {
  const normalizedName = String(vessel.name).toLowerCase().trim();
  const normalizedSearch = search instanceof RegExp ? search : String(search).toLowerCase().trim();
  return (
    <div className={cx(s.vessel_name, className)}>
      <Highlight className={className} title={vessel.name} search={normalizedSearch} {...rest}>
        {normalizedName}
      </Highlight>
    </div>
  )
}

export default withStyles(s)(VesselName)
