import React from 'react'
import RaisedButton from './RaisedButton';
import s from './SpinnerButton.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

function _SpinnerBtn(props) {
  const { loading = false, label, ...restProps } = props;
  let _label = label;
  if (loading) {
    _label = <div className={s.preloader_spinner} />;
  }
  return (
    <RaisedButton {...restProps} label={_label} />
  );
}

export default withStyles(s)(_SpinnerBtn);
