import PropTypes from 'prop-types';
import React from 'react';
import FormsyText from './FormsyText';
import Diff from './Diff';

export default function BankDetails({ bankingDetails, required, disabled, previousValues }) {
  // style={{ paddingTop: disabled ? '8px' : '0px' }}
  return (
    <div className="row">
      <div className="col-12">
        {disabled ?
          <span >
            <span>Bank: </span>
            <Diff newValue={bankingDetails.bank} oldValue={previousValues.bank} />
          </span>
          :
          <FormsyText
            hintText="Bank"
            name="bankingDetails[bank]"
            requiredError="required"
            required={required}
            previousValue={previousValues.bank}
            fullWidth
          />
        }
      </div>
      <div className="col-12">
        {disabled ?
          <span>
            <span>Bank address: </span>
            <Diff
              newValue={bankingDetails.bankAddress}
              oldValue={previousValues.bankAddress}
            />
          </span>
          :
          <FormsyText
            hintText="Bank address"
            name="bankingDetails[bankAddress]"
            requiredError="required"
            required={required}
            previousValue={previousValues.bankAddress}
            fullWidth
          />
        }
      </div>
      <div className="col-12">
        {disabled ?
          <span>
            <span>Beneficiary Name: </span>
            <Diff
              newValue={bankingDetails.beneficiaryName}
              oldValue={previousValues.beneficiaryName}
            />
          </span>
          :
          <FormsyText
            hintText="Beneficiary Name"
            name="bankingDetails[beneficiaryName]"
            requiredError="required"
            required={required}
            previousValue={previousValues.beneficiaryName}
            fullWidth
          />
        }
      </div>
      <div className="col-12">
        {disabled ?
          <span>
            <span>SWIFT code: </span>
            <Diff
              newValue={bankingDetails.swiftCode}
              oldValue={previousValues.swiftCode}
            />
          </span>
          :
          <FormsyText
            hintText="SWIFT code"
            name="bankingDetails[swiftCode]"
            requiredError="required"
            required={required}
            previousValue={previousValues.swiftCode}
            fullWidth
          />
        }
      </div>
      <div className="col-12">
        {disabled ?
          <span>
            <span>Account No.: </span>
            <Diff
              newValue={bankingDetails.accountNo}
              oldValue={previousValues.accountNo}
            />
          </span>
          :
          <FormsyText
            hintText="Account No."
            name="bankingDetails[accountNo]"
            requiredError="required"
            required={required}
            previousValue={previousValues.accountNo}
            fullWidth
          />
        }
      </div>
      <div className="col-12">
        {disabled ?
          <span>
            <span>IBAN No.: </span>
            <Diff
              newValue={bankingDetails.ibanNo}
              oldValue={previousValues.ibanNo}
            />
          </span>
          :
          <FormsyText
            hintText="IBAN No."
            name="bankingDetails[ibanNo]"
            requiredError="required"
            required={required}
            previousValue={previousValues.ibanNo}
            fullWidth
          />
        }
      </div>
    </div>
  );
}

BankDetails.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  bankingDetails: PropTypes.object,
  previousValues: PropTypes.object,
};

BankDetails.defaultProps = {
  required: false,
  disabled: false,
  bankingDetails: {},
  previousValues: {},
};

