import cx from "classnames";
import ReactTelInput from "react-telephone-input";
import PropTypes from 'prop-types';
import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
const css = require('./NarrowFormsyPhoneNumber._css');

const styles = {
  focused: { color: 'rgb(127, 201, 49)' },
  invalid: { color: 'rgb(244, 67, 54)' },
};

function PhoneNumberInputUi(props: {
  errorText: false | any,
  focused: any,
  required: boolean,
  value: any,
  onChange: () => void,
  onBlur: () => void,
  onFocus: () => void
}, context) {
  return <div className={cx("formsy-tel-input", props.errorText ? "invalid" : null)}>
    <label
      className="tel-input-label"
      style={
        props.focused
          ? props.errorText ? styles.invalid : {
            color: (context.muiTheme?.palette?.primary1Color || styles.focused.color)
          }
          : null
      }
    >
      {props.required ? "Phone number*" : "Phone number"}
    </label>
    <ReactTelInput
      defaultCountry="us"
      flagsImagePath="/images/flags.png"
      autoFormat={false}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      buttonProps={props.buttonProps}
      inputProps={{style:{borderBottom:props.disabled ? 'none' : ''}}}
    />
    <hr
      style={{transform: props.focused ? "scaleX(1)" : "scaleX(0)",display: props.disabled ? 'none' : 'block'}}
      className="hr_focused"
    />
    <div className="error_text">{props.errorText}</div>
  </div>;
}
PhoneNumberInputUi.contextTypes = {
  muiTheme: PropTypes.object,
};

export default withStyles(css)(PhoneNumberInputUi);
