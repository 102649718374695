/* @flow */
import React from 'react';
import s from '../../Monitor.scss';
import CurrencySelect from '../../../Common/CurrencySelect';
import MenuItem from 'material-ui/MenuItem';
import styled from 'styled-components';
import { ContentAddButton, ContentClearButton } from '../../../Common/AddButton';
import { MultiFreightEdit } from '../MultiFreight/MultiFreightEdit';
import NarrowFormsyText from '../../../NewInputs/NarrowFormsyText';
import { NarrowSelect } from '../../../NewInputs/NarrowSelect';


class MultiFreightEditNarrow extends MultiFreightEdit {
  static RowComponent = FreightRow;

}

function FreightRow({ item: { curr, prev = {} }, i, onChange, onDelete, onAdd, methodEnabled = true }) {
  prev = prev || {};
  curr = curr || {};
  return (
    <div>
    <div className={'freight_row'}>
    <div className={"first_part"}>
      <div>
        <NarrowFormsyText
          floatingLabelText="Freight*"
          validations="isNumeric,gt0"
          validationErrors={{
            isNumeric: 'only numbers',
            gt0: 'must be greater than 0',
          }}
          requiredError="required"
          name={`_freights[${i}][value]`}
          required
          fullWidth
          previousValue={prev.value}
          value={curr.value}
          onBlur={onChange.bind(this, 'value')}
        />
      </div>
      <div>
        <CurrencySelect
          as={NarrowSelect}
          name={`_freights[${i}][currency]`}
          className={s.currency_select}
          previousValue={prev.currency}
          value={curr.currency}
          onChange={onChange.bind(this,'currency')}
        />
      </div>
      <div>
        <NarrowSelect
          hintText=" "
          requiredError="required"
          name={`_freights[${i}][term]`}
          fullWidth
          autoWidth
          required
          defaultValue={'per mt'}
          previousValue={prev.term}
          value={curr.term}
          onChange={onChange.bind(this,'term')}
        >
          <MenuItem
            key="per mt"
            value="per mt"
            primaryText="per mt"
          />
          <MenuItem
            key="per cbm"
            value="per cbm"
            primaryText="per cbm"
          />
          <MenuItem
            key="lump sum"
            value="lump sum"
            primaryText="lump sum"
          />
          <MenuItem
            key="per w/m"
            value="per w/m"
            primaryText="per w/m"
          />
          <MenuItem
            key="per unit"
            value="per unit"
            primaryText="per unit"
          />
          <MenuItem
            key="per box"
            value="per box"
            primaryText="per box"
          />
          <MenuItem
            key="per TEU"
            value="per TEU"
            primaryText="per TEU"
          />
          <MenuItem
            key="per FEU"
            value="per FEU"
            primaryText="per FEU"
          />
        </NarrowSelect>
      </div>
    </div>
    <div className={"second_part"}>
      {methodEnabled ? (
        <div>
          <NarrowSelect
            hintText=" "
            requiredError="required"
            name={`_freights[${i}][method]`}
            fullWidth
            autoWidth
            required
            defaultValue={'fios'}
            previousValue={prev.method}
            value={curr.method}
            onChange={onChange.bind(this,'method')}
          >
            <MenuItem
              key="fios"
              value="fios"
              primaryText="FIOS"
            />
            <MenuItem
              key="filo"
              value="filo"
              primaryText="FILO"
            />
            <MenuItem
              key="lifo"
              value="lifo"
              primaryText="LIFO"
            />
            <MenuItem
              key="lilo"
              value="lilo"
              primaryText="LILO"
            />
          </NarrowSelect>
        </div>
      ) : null}
      <div>
        <NarrowFormsyText
          floatingLabelText="Details"
          name={`_freights[${i}][details]`}
          fullWidth
          previousValue={prev.details}
          value={curr.details}
          onBlur={onChange.bind(this,'details')}
        />
      </div>
    </div>
  </div>
      <div className="button_wrapper">
        {i === 0 ?
          <ContentAddButton onClick={onAdd} />
          :
          <ContentClearButton onClick={onDelete} />
        }
      </div>
    </div>);
}

export default styled(MultiFreightEditNarrow)`
  width: 100%;
  >div {
    width: 100%;
    display: flex;
  }
  .freight_row {
    flex: 1;
    display: flex;
    margin: 0 -6px;

    .first_part {
      display: flex;
      max-width: 53.142857143%;
      flex: 0 0 53.142857143%;
      min-width: 225px;
      > div {
        padding: 0 6px;
      }
     > div:nth-child(1){
        max-width: 33.33333333333%;
        flex: 0 0 33.33333333333%;
        min-width: 74px;
      }
     > div:nth-child(2){
        max-width: 33.33333333333%;
        flex: 0 0 33.33333333333%;
        min-width: 74px;
       >div {
         width: 100% !important;
       }
      }
     > div:nth-child(3){
        max-width: 33.33333333333%;
        flex: 0 0 33.33333333333%;
        min-width: 74px;
      }

    }
    .second_part {
      display: flex;
      max-width: 46.857142857%;
      flex: 0 0 46.857142857%;
      > div {
        padding: 0 6px;
      }
      > div:nth-child(1){
        max-width: 33.8888888888889%;
        flex: 0 0 33.8888888888889%;
      }
      > div:last-child{
        flex: 1;
        max-width: 100%;
      }
    }
    @media (max-width: 1299px) {
      flex-wrap: wrap;
      .first_part {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
      .second_part {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  .button_wrapper {
    display: flex;
    align-items: center;
    width: 36px;
    padding-left: 12px;
  }
`;


