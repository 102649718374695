import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CountryAutocomplete from '../../components/Common/CountryAutocomplete';
import CityAutocomplete from '../../components/Common/CityAutocomplete';

class CityCountry extends Component {
  handleCityChange = city => {
    this.city = city;
    if (city && city.country) {
      const country = this.context.countries.find(
        country => country._id === city.country._id,
      );
      this.countryInput.setValue(country);
      this.props.onCountryChange && this.props.onCountryChange(country);
    }
  };
  handleCountryChange = country => {
    if (this.country && country && this.country._id === country._id) {
      return;
    }
    this.country = country;
    this.props.onCountryChange && this.props.onCountryChange(country);
    if (country && this.city && this.city.country && this.city.country._id !== country._id) {
      this.cityInput.resetValue();
    }
  };
  setValue = city => {
    this.cityInput.setValue(city);
    this.handleCityChange(city);
  };
  ref(name, element) {
    this[name] = element;
  }
  render() {
    const {
      style,
      wrapperStyle,
      hintStyle,
      floatingLabelStyle,
      floatingLabelFocusStyle,
      floatingLabelShrinkStyle,
      inputStyle
    } = this.props;
    let cityLabel = 'City';
    let countryLabel = 'Country';
    if (this.props.required) {
      cityLabel += '*';
      countryLabel += '*';
    }
    return (
      <div style={wrapperStyle}>
        <div style={style}>
          <CityAutocomplete
            hintText={cityLabel}
            floatingLabelText={cityLabel}
            name={this.props.cityName}
            fullWidth={true}
            onNewRequest={this.handleCityChange}
            ref={this.ref.bind(this, 'cityInput')}
            required={this.props.required}
            validationErrors={{ isDefaultRequiredValue: 'required' }}
            hintStyle={hintStyle}
            floatingLabelStyle={floatingLabelStyle}
            floatingLabelFocusStyle={floatingLabelFocusStyle}
            floatingLabelShrinkStyle={floatingLabelShrinkStyle}
            inputStyle={inputStyle}
          />
        </div>
        <div style={style}>
          <CountryAutocomplete
            hintText={countryLabel}
            floatingLabelText={countryLabel}
            name={this.props.countryName}
            fullWidth={true}
            ref={this.ref.bind(this, 'countryInput')}
            onNewRequest={this.handleCountryChange}
            required={this.props.required}
            validationErrors={{ isDefaultRequiredValue: 'required' }}
            hintStyle={hintStyle}
            floatingLabelStyle={floatingLabelStyle}
            floatingLabelFocusStyle={floatingLabelFocusStyle}
            floatingLabelShrinkStyle={floatingLabelShrinkStyle}
            inputStyle={inputStyle}
          />
        </div>
      </div>
    );
  }
}

CityCountry.propTypes = {
  cityName: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  onCountryChange: PropTypes.func,
};
CityCountry.defaultProps = {};

CityCountry.contextTypes = {
  countries: PropTypes.array,
};

export default CityCountry;
