import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import Dialog from '../Common/Dialog';
import s from './Subscription.scss';
import plans from './plans';
import Link from '../Link';
import RaisedButton from '../Common/RaisedButton';
import SpinnerButton from '../Common/SpinnerButton';
import PlanDetailsFull from './PlanDetailsFull';


class ConfirmDowngradeDialog extends PureComponent {
  state = {
    openedFeaturesCurrent: false,
    openedFeaturesSuggest: true,
    openedFeaturesCurrentMob: false,
    openedFeaturesSuggestMob: false,
  }
  static propTypes = {
    handleClose: PropTypes.func,
  }

  handleCancel = () => {
    return this.props.handleClose(false);
  };

  handleConfirm = () => {
    return this.props.handleClose(true ,this.props.selectedPlan);
  };

  render() {
    const { currentPlan, selectedPlan } = this.props;
    return <Dialog
      bodyClassName={s.bodyPopupChangePlan}
      contentClassName={s.contentPopupChangePlan}
      open handleClose={this.handleCancel}
    >
      <div>
        <h2 className={s.title_warning}>Are you sure to change package?</h2>
        <p className={s.description_warning}>These features of the current package will not be available to you:</p>
        <PlanDetailsFull plan={currentPlan} defaultClosedFeatures={true} />
        <p className={s.description_warning_chosen}>You have chosen this limited package</p>
        <PlanDetailsFull plan={selectedPlan || plans.free } />
        <div className={cx(s.wrapper_links_popup, s.wrapper_links_popup_cancel_subscription)}>
          <a onClick={this.handleCancel} className={s.link_view_all_plans}>
            <RaisedButton
              style={{
                display: 'block',
                minWidth: '100%',
                maxWidth: '100%',
                height: '32px',
              }}
              buttonStyle={{
                backgroundColor: '#75C422',
                borderRadius: '50px',
              }}
              labelStyle={{
                display: 'inline-block',
                padding: '0',
                fontSize: '11px',
                fontWeight: '600',
                lineHeight: '32px',
              }}
              primary
              label={'View all plans'}
            />
          </a>
          <div className={s.right_part_links}>
            <RaisedButton
              style={{
                display: 'block',
                minWidth: '180px',
                maxWidth: '180px',
                height: '32px',
              }}
              buttonStyle={{
                backgroundColor: '#e6e6e6',
                borderRadius: '50px',
              }}
              labelStyle={{
                display: 'inline-block',
                padding: '0',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '32px',
                color: '#787878',
              }}
              primary
              label={<span>Cancel </span>}
              onClick={this.handleCancel}
            />
            <SpinnerButton
              style={{
                display: 'block',
                minWidth: '180px',
                maxWidth: '180px',
                height: '32px',
              }}
              buttonStyle={{
                backgroundColor: '#e6e6e6',
                borderRadius: '50px',
              }}
              labelStyle={{
                display: 'inline-block',
                padding: '0',
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '32px',
                color: '#787878',
              }}
              primary
              label={<span>Change <span className={s.delete_on_mob}>anyway</span></span>}
              onClick={this.handleConfirm}
            />
          </div>
        </div>
      </div>
    </Dialog>
  }
}

export default withStyles(s)(ConfirmDowngradeDialog);
