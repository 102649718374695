import React, {  PureComponent } from 'react'
import Page from '../Page/Page';
import { connect } from 'react-redux';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import t from './../Settings/UserStatistics/UserStatistics.scss'
import TextField from 'material-ui/TextField';
import Pagination from './../Common/Pagination';
import {User} from './../../core/api/api'
import Loader from './../Common/Loader';
export class AdminTable extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            rowsArr: [15, 30, 50],
            page: 1,
            numberOfRows: 15,
            stats: null,
            filteredStats: null,
            errors: '',
            loading: true,
        }

        this.search = this.search.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.parseDate = this.parseDate.bind(this);
        this.filterStats = this.filterStats.bind(this);
        this.updateRows = this.updateRows.bind(this);
        this.callApi = this.callApi.bind(this);
        this.compareStats = this.compareStats.bind(this);
    }

    compareStats({company: {name: companyName}, name, email, surname}, filter) {
        return `${companyName} ${email} ${name} ${surname}`.toLowerCase().includes(filter.toLowerCase());
    }

    search(ev) {
        this.setState({
            filter: ev.target.value,
            filteredStats: this.filterStats(ev.target.value)
        })
    }

    filterStats(filter) {
        const {filteredStats, stats} = this.state;
        filter = filter.toLowerCase() || '';
        if (filter.length < 1) return stats;
        else {
            const newFilteredStats = 
                stats.filter(stat => this.compareStats(stat, filter))
            return newFilteredStats
        }
    }

    parseDate(date = new Date()) {
        const d = new Date(date);
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
        return `${da}.${mo}.${ye}`
    }

    renderBody() {
        const {filteredStats} = this.state;
        if (!filteredStats) return false;
        return filteredStats.map(({company, callStats, email, endAt, startAt, name, surname, subscription}) => (
            <TableRow key={name}>
                        <TableRowColumn className={t.SmallRow}>
                            {company.name}
                        </TableRowColumn>
                        <TableRowColumn className={t.SmallRow}>
                            {`${name} ${surname}`}
                        </TableRowColumn>
                        <TableRowColumn className={t.SmallRow}>
                            {email}
                        </TableRowColumn>
                        
                        <TableRowColumn className={t.SmallRow}>
                            {callStats.map(c => (<p key={c.name}>{c.name}</p>))}
                        </TableRowColumn>
                        <TableRowColumn className={t.SmallRow}>
                            {callStats.map(c => (<p key={c.name}>{c.used}/{c.avail ? c.avail : '∞'}</p>))}
                        </TableRowColumn>
                        <TableRowColumn className={t.SmallRow}>
                            {this.parseDate(startAt)}
                        </TableRowColumn>
                        <TableRowColumn className={t.SmallRow}>
                            {this.parseDate(endAt)}
                        </TableRowColumn>
                    </TableRow>
        ))
    }

    componentDidMount() {
        this.setState({loading: true}, this.callApi)
    }

    callApi() {
        const {page,numberOfRows, filter} = this.state;
        User.adminStatistics(page, numberOfRows)
        .then(stats => this.setState({loading: false, stats: stats.data, total: stats.total ? stats.total : stats.data.length, filteredStats: filter ? this.filterStats(filter) : stats.data}))
        .catch(err => this.setState({loading: false, errors: err}))
    }

    updateRows({page, numberOfRows}) {
        this.setState({
            page,
            numberOfRows
        }, this.callApi)
    }

    render() {
        const {filter, page, numberOfRows, rowsArr, total, errors, filteredStats, loading} = this.state;
        const {search, renderBody, updateRows} = this;
        let content = null
        if (errors && errors.message) {
            const {status = '', statusText= 'Unknown error', errors: err} = errors.message;
            content = (
                <div style={{textAlign: 'center'}}>
                    <p>{`${status} ${statusText}`}</p>
                    {err && err.map(err => <p>{err.messages.join(' ')}</p>)}
                </div>
            )
        }
        else if (filteredStats && filteredStats.length < 1 && filter.length === 0) {
            content = <p>Users list is empty</p>
        }
        else {
            content = 
            (<div className={t.TableWrapper} style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <div
                    className={t.BetweenContainer}
                >
                    <TextField floatingLabelText="Enter company name or user" value={filter} onChange={search} id="search-users"
                    ></TextField>
                    <Pagination total={total} updateRows={updateRows} page={page} numberOfRows={numberOfRows} rowsPerPage={rowsArr}></Pagination>
                </div>
                <Table displayBorder={false} allRowsSelected={false} selectable={false} className={t.BorderedTable}>
                    <TableHeader style={{border: 'none'}} adjustForCheckbox={false} enableSelectAll={false} displaySelectAll={false}>
                        <TableRow style={{border: 'none'}}>
                            <TableHeaderColumn className={t.SmallHeader}>
                                Company name
                            </TableHeaderColumn>
                            <TableHeaderColumn className={t.SmallHeader}>
                                Name, surname
                            </TableHeaderColumn>
                            <TableHeaderColumn className={t.SmallHeader}>
                                Email
                            </TableHeaderColumn>
                            
                            <TableHeaderColumn className={t.SmallHeader}>
                                List of API
                            </TableHeaderColumn>
                            <TableHeaderColumn className={t.SmallHeader}>
                                API limits (used)
                            </TableHeaderColumn>
                            <TableHeaderColumn className={t.SmallHeader}>
                                Billing period <br /> start date
                            </TableHeaderColumn>
                            <TableHeaderColumn className={t.SmallHeader}>
                                Billing period <br /> end date
                            </TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody displayRowCheckbox={false}>
                        {renderBody()}
                    </TableBody>
                </Table>
            </div>)
        }
        return (
            <Page headerTitle={'STATISTICS'}>
                {loading ? <Loader /> : content}
            </Page>
        )
    }
}

export default connect()(withStyles(t)(AdminTable));
