import produce from "immer";
import {sortContacts} from "./CONTACTS_CREATE_GROUP";

/** @name CONTACTS_CREATE_CONTACT_REDUCER */
 export default function CONTACTS_CREATE_CONTACT(state, { res, payload }) {
   const newContact = res.data;
  return produce(state, draft => {
    draft.contactList.data = [newContact, ...draft.contactList.data];
    draft.contactList = sortContacts(draft.contactList);
  });
}
