export default function saveFiltersState(state) {
  try {
    if (typeof window !== 'undefined') {
      if (window.sessionStorage && (state.cargoList.page === 1 || state.vesselList.page === 1)) {
        window.sessionStorage.setItem('state', JSON.stringify({
          monitor: {
            cargoList: {
              ...state.cargoList,
              data: [],
              page: 1,
            },
            vesselList: { ...state.vesselList, data: [], page: 1 },
          },
        }));
      }
    }
  } catch (e) {
    console.error(e);
  }
}
