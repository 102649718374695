import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import FormsySelect from './Select';
import MenuItem from 'material-ui/MenuItem';

let _items;
function renderItems(items) {
  if (_items) return _items;
  _items = [];
  items.forEach(i => {
    _items.push(<MenuItem key={i._id} value={i._id} primaryText={i.name} />);
  });
  return _items;
}
class OrganizationSelect extends PureComponent {
  render() {
    return (
      <FormsySelect {...this.props}>
        {renderItems(this.context.organizations)}
      </FormsySelect>
    );
  }
}

OrganizationSelect.propTypes = FormsySelect.propTypes;
OrganizationSelect.defaultProps = FormsySelect.defaultProps;
OrganizationSelect.contextTypes = {
  organizations: PropTypes.array,
  formsy: PropTypes.object,
};
export default OrganizationSelect;
