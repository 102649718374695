/** @name PROFILE_PARTIAL_UPDATE */

export default function PROFILE_PARTIAL_UPDATE(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      ...action.payload,
    }
  };
}
