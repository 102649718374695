import React from 'react';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import CurrencySelect from './../../Common/CurrencySelect';
import NarrowSelect from './../../NewInputs/NarrowSelect';
import AbstractFormHandler from './AbstractFormHandler';
import MethodsSelect, { FIOS } from './MethodsSelect';
import UnitsSelect, { MT } from './UnitsSelect';

export class Freight extends AbstractFormHandler {

  componentDidMount() {
    const { freight = {}, onChange } = this.props;
    const copy = {
      ...freight,
    }

    if (!copy.currency) {
      copy.currency = 'USD';
    }
    if (!copy.term) {
      copy.term = MT.value;
    }
    if (!copy.method) {
      copy.method = FIOS.value;
    }
    onChange(copy);
  }

  render() {
    const { namePrefix = "freight", freight = {}, style = {}, className = '', ...rest } = this.props;
    const { change } = this;

    const { value = "", currency, term, method, details } = freight;

    return (
      <div className={`row ${className}`} style={style}>
        <div className="col-4">
          <NarrowFormsyText
            name={`${namePrefix}[value]`}
            value={value || ""}
            hintText="Freight*"
            validations="isNumeric,gt0"
            validationErrors={{
              isNumeric: 'only numbers',
              gt0: 'must be greater than 0',
            }}
            required
            requiredError="required"
            fullWidth
            onBlur={change("value")}
          />
        </div>
        <div className="col-2">
          <CurrencySelect
            as={NarrowSelect}
            name={`${namePrefix}[currency]`}
            value={currency || ""}
            hintText="Currency"
            style={{ width: '100%' }}
            fullWidth
            onChange={change("currency")}
          />
        </div>
        <div className="col-3">
          <UnitsSelect
            name={`${namePrefix}[term]`}
            value={term}
            hintText="Term"
            fullWidth
            onChange={change("term")}
          />
        </div>
        <div className="col-3">
          <MethodsSelect
            name={`${namePrefix}[method]`}
            value={method}
            hintText="Method"
            fullWidth
            onChange={change("method")}
          />
        </div>
        <div className="col-12">
          <NarrowFormsyText
            name={`${namePrefix}[details]`}
            value={details}
            hintText="Details"
            fullWidth
            onChange={ev => change("details")(ev.target.value)}
            multiLine
            adaptHeightByValue={this.props.adaptHeightByValue}
          />
        </div>
      </div>
    );
  }
}

export default Freight;
