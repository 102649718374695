import React from 'react';
import pure from 'recompose/pure';

const _ContactsFacebook = props => (
  <span {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M24 11.9962C23.9999 9.7033 23.3426 7.4584 22.1057 5.52741C20.8689 3.59641 19.1044 2.06022 17.0213 1.10076C14.9381 0.141299 12.6236 -0.201238 10.3517 0.113717C8.07984 0.428672 5.94584 1.38792 4.20245 2.87787C2.45906 4.36782 1.17931 6.32604 0.514748 8.52063C-0.14981 10.7152 -0.171331 13.0542 0.452734 15.2607C1.0768 17.4671 2.3203 19.4485 4.03598 20.9703C5.75167 22.492 7.86765 23.4904 10.1333 23.8471V15.4708H7.08089V11.9962H10.128V9.35168C10.128 6.34632 11.92 4.68458 14.6613 4.68458C15.5613 4.69717 16.4591 4.77558 17.3476 4.91918V7.87122H15.8347C14.3431 7.87122 13.8791 8.79717 13.8791 9.74445V11.9962H17.2071L16.6738 15.4637H13.8756V23.8471C16.6979 23.3998 19.2682 21.9607 21.124 19.7883C22.9799 17.616 23.9997 14.853 24 11.9962Z" fill="#285596"/>
      <path d="M16.6714 15.4709L17.2047 12.0035H13.8767V9.74457C13.8767 8.79728 14.3407 7.87133 15.8323 7.87133H17.3451V4.91929C16.4567 4.77569 15.5589 4.69728 14.6589 4.68469C11.9176 4.68469 10.1256 6.34644 10.1256 9.35179V11.9964H7.08203V15.4709H10.1291V23.8543C11.372 24.0486 12.6374 24.0486 13.8803 23.8543V15.4709H16.6714Z" fill="white"/>
    </svg>
  </span>
);

const ContactsFacebook = pure(_ContactsFacebook);
ContactsFacebook.displayName = 'ContactsFacebook';
ContactsFacebook.muiName = 'SvgIcon';

export default ContactsFacebook;
