import React, { Component } from 'react'
import FloatingPopup from './FloatingPopup';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import s from './RequestDemo.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Formsy from "formsy-react";
import InputBtn from './InputBtn';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { sendDemoRequest } from '../../actions/home';
import demoAvatar1 from './images/demo_avatar1.png';
import demoAvatar2 from './images/demo_avatar2.png';

export const avatarUrls = [
  demoAvatar1,
  demoAvatar2,
];

class RequestDemo extends PureComponent {

  static INTERVAL = 5 * 60 * 1000
  static TIMEOUT = 10 * 1000

  /* chooseAvatar(cb) {
    const index = Math.floor(Math.random() * avatarUrls.length);
    this.setState({
      member: index
    }, () => {
      if (cb) {
        cb();
      }
    })
  } */

  state = {
    isOpen: false
  }
  open = () => this.setState({
    isOpen: true
  })
  close = () => this.setState({
    isOpen: false
  })

  componentDidMount() {
    this._timeoutId = setTimeout(() => {
      this.open();
    }, this.constructor.TIMEOUT);
    this._intervalId = setInterval(this.intervalExpand, this.constructor.INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this._intervalId);
    clearTimeout(this._timeoutId);
  }

  intervalExpand = () => {
    this.open();
  }

  handleClose = (cb = () => undefined) => (...args) => {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    if (this._intervalId) {
      clearInterval(this._intervalId);
      this._intervalId = setInterval(this.intervalExpand, this.constructor.INTERVAL);
    }
    cb(...args);
  }

  handleSubmit = async request => {
    try {
      const res = await this.props.sendDemoRequest(request);
      this.close();
      if (this._intervalId) {
        clearInterval(this._intervalId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <FloatingPopup
        isOpen={this.state.isOpen}
        close={this.close}
        open={this.open}
        openedClass={s.open}
        closedClass={s.close}
        className={s.floating_popup}
        animatingClass={s.animating}
        ref={el => this._floatingPopup = el}
        forceClose
      >
        {
          (isOpen, setRef, close) => isOpen
            ? (
              <RequestDemoWindow handleChange={this.handleChange} handleSubmit={this.handleSubmit} avatar={avatarUrls[Math.floor(Math.random() * avatarUrls.length)]} setRef={setRef} handleClose={this.handleClose(close)}></RequestDemoWindow>
            )
            : (
              <RequestDemoBtn setRef={setRef}></RequestDemoBtn>
            )
        }
      </FloatingPopup>
    )
  }
}
function RequestDemoBtn({ setRef }) {
  return (
      <div ref={setRef} className={s.floating_btn}>
        <p>request</p>
        <h5>demo</h5>
      </div>
  )
}

class RequestDemoWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    }
  }

  handleEmail = (val) => {
    this.setState({
      email: val
    })
  }
  render() {
    const { handleClose, setRef, handleSubmit, avatar = demoAvatar1 } = this.props;
    return (
      <div className={s.main_window} ref={setRef}>
        <span className={s.close_ico} onClick={handleClose}>
          <CloseIcon color="#fff" style={{width: 18, height: 18}}></CloseIcon>
        </span>
        <div className={s.main_window_header}>
          <div className={s.main_window_avatar}>
            <img src={avatar} alt="" className={s.avatar} />
          </div>
          <h5 className={s.main_window_title}>
            Hello! Would you like to learn more about Shipnext?
          </h5>
        </div>
        <div className={s.main_window_wrapper}>
          <div className={s.main_window_body}>
            <p className={s.main_window_text}>
              Please feel free to set up a conference call or leave your email address, and I will get back to you within an hour.
            </p>
            <Formsy.Form onValidSubmit={handleSubmit}>
              <InputBtn value={this.state.email} handleChange={this.handleEmail} btnLabel="Leave Email" placeholder="Email*" />
            </Formsy.Form>
          </div>
          <div className={s.main_window_footer}>
            <a href={`https://calendly.com/p-walisch/30min`} target="_blank" className={s.schedule}>SCHEDULE A DEMO</a>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(null, { sendDemoRequest })(withStyles(s)(RequestDemo));
