import React from "react";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './FormsyAttachments/FormsyAttachments.scss';
import cx from 'classnames';

function UploadedFileExtensionErr(props) {
  const { erroredFiles, accept = ".xls, .xlsx, .txt, .csv, .pdf, .jpg, .jpeg, .png, .doc, .docx, .rtf, .tiff, .dwg, .dxf, .vsd, .xlsm, .zip, .rar, .7z", maxSizeMb = 10, maxTotalSizeMb = 25, remainingSize = 25 } = props;
  return (
    <div className={cx(s.errors_block, props.className)}>
      {remainingSize <= 0 ? <div className={s.error}>
        <div>{`All attachments should not exceed the maximum limit (${maxTotalSizeMb}MB)`}</div>
      </div> : null}
      {erroredFiles.map(file => {
        return <div className={s.error}>
          <div>{file.errorMessage}</div>
          <div>{file.name}</div>
        </div>;
      })}
    </div>
  );
}

export default withStyles(s)(UploadedFileExtensionErr);
