import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Dialog from '../Common/DialogGreyTitle';
import s from './Monitor.scss';
import cx from 'classnames';
import ChangeFreight from './Offer/ChangeFreight';


class ChangeFreightDialog extends PureComponent {


  render() {
    return (
      <Dialog
        open
        handleClose={this.props.handleClose}
        contentClassName={cx(s.dialogContent, s.dialog_content_offer_freight)}
        bodyClassName={cx(s.dialog_body_offer_freight)}
        repositionOnUpdate
        title={'CHANGE OFFER'}
        modal
      >
        <div className={s.wrapper_dialog_offer_freight_static}>
          <div>
            <div>
             <ChangeFreight {...this.props} />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(s)(ChangeFreightDialog);
