import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import WithTooltip from './WithTooltip';
import RatingBlock from './RatingBlock';
import PubSub from 'pubsub-js';
import r from './RatingBlock.scss';
import s from './VesselRating.scss';
import StarIcon from 'material-ui/svg-icons/toggle/star';
import StarIconBorder from 'material-ui/svg-icons/toggle/star-border';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
export class VesselRating extends PureComponent {

  static propTypes = {
    vessel: PropTypes.object,
    children: PropTypes.any,
    dark: PropTypes.bool,
    whiteArrow: PropTypes.bool,
    dontMutate: PropTypes.bool,
    tooltipHorizontalPosition: WithTooltip.propTypes.horizontalPosition,
  }
  static defaultProps = {
    whiteArrow: false,
    dontMutate: false,
  }
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    PubSub.subscribe('vessel:ratingUpdated', this.onRatingChanged);

  }
  componentWillUnmount(): void {
    PubSub.unsubscribe(this.onRatingChanged);
  }

  handleUpdateVesselRating = (vessel) => {
    PubSub.publish('vessel:ratingUpdated', vessel);
  }

  onRatingChanged = (msg, vessel) => {
    if (this.props.dontMutate) return;
    if(this.props.vessel._id === vessel._id) {
      this.props.vessel.rating = vessel.rating;
      this.forceUpdate();
    }
  }


  tip = ({handleClose}) => {
    const {reviews, average} = this.props?.vessel?.rating || {};

    return ( <div className={cx(r.wrapper_tooltip_rating, this.props.marketplace && r.wrapper_tooltip_rating_marketplace, this.props.dark && r.wrapper_tooltip_rating_dark )} style={{width: '468px', padding: '5px 12px'}}>
              <div className={r.title_rating}>
                <p className={r.quantity}>{reviews || 0} reviews</p>
                <DisplayRating dark={this.props.dark} average={average}></DisplayRating>
              </div>
              <RatingBlock whiteArrow={this.props.whiteArrow ? this.props.whiteArrow : false} entity={this.props.vessel} handleClose={handleClose} horizontalPosition="right" updateRating={this.handleUpdateVesselRating} dark={this.props.dark} />
           </div>
    )
  }

  render() {

    return (
      <WithTooltip
        tip={this.tip}
        showOnEventName="onClick"
        vessel={this.props.vessel}
        horizontalPosition={this.props.tooltipHorizontalPosition}
      >
        {React.cloneElement(this.props.children,{rating: this.props.vessel.rating})}
      </WithTooltip>
    );
  }

}

function _DisplayRating({average, dark = false}) {
  return (
              <div className={r.rating_star}>
                <div className={cx(r.raiting_number, dark && r.dark)}>
                    <span>{average ? (average * 5).toFixed(1) : null}</span>
                    {
                      average ?
                        <StarIcon
                          style={{
                            width:'16px',
                            height: '16px'
                          }}
                          color='#fff'
                        />
                        :
                        null
                    }
                </div>
              </div>
  )
}

export default withStyles(r, s)(VesselRating);

const DisplayRating = withStyles(r, s)(_DisplayRating);
export {
  DisplayRating
}
