import { v4 as uuidv4 } from 'uuid';

const vesselTypeBySizes = [];
const byIdMap = {};
const typesCsv = "Baby-cape,100000,119999,\n" +
  "Capesize,120000,189999,\n" +
  "Handysize,24000,34999,\n" +
  "Handymax,35000,49999,\n" +
  "Kamsarmax,80000,82999,\n" +
  "Newcastlemax,190000,209999,\n" +
  "Panamax,65000,79999,\n" +
  "Post-panamax,83000,99999,\n" +
  "Supramax,50000,59999,\n" +
  "Ultramax,60000,64999,\n" +
  "Valemax,380000,400000,\n" +
  "Vloc,210000,379999,\n" +
  "Aframax,80000,120000,\n" +
  "Amur,3329,3329,\n" +
  "Large Modern Ecotype,80000,99999,\n" +
  "Omskiy,3174,3174,\n" +
  "Sibirskiy,3427,3427, \n" +
  "Sormovskiy,3353,3353,\n" +
  "Suezmax,120000,200000,\n" +
  "Volgo Balt,3474,3474,\n" +
  "Volgo Don,5885,5885,\n" +
  "Aframax/Suezmax,80000,200000,\n" +
  "Amur/Sormovskiy,3329,3353,\n" +
  "Amur/Sormovskiy/Volgo Balt,3329,3474,\n" +
  "Amur/Sormovskiy/Volgo Balt/Volgo Don,3329,5885,\n" +
  "Baby-cape/Capesize,100000,189999,\n" +
  "Capesize/Newcastlemax,120000,209999,\n" +
  "Handymax/Baby-cape,35000,119999,\n" +
  "Handymax/Large Modern Ecotype,35000,99999,\n" +
  "Handymax/Panamax,35000,79999,\n" +
  "Handymax/Post-panamax,35000,99999,\n" +
  "Handymax/Supramax,35000,59999,\n" +
  "Handymax/Supramax/Large Modern Ecotype,35000,99999,\n" +
  "Handymax/Supramax/Panamax,35000,79999,\n" +
  "Handymax/Supramax/Panamax/Kamsarmax,35000,82999,\n" +
  "Handymax/Supramax/Panamax/Post-panamax,35000,99999,\n" +
  "Handymax/Supramax/Ultramax,35000,64999,\n" +
  "Handymax/Supramax/Ultramax/Panamax,35000,79999,\n" +
  "Handymax/Ultramax,35000,64999,\n" +
  "Handymax/Ultramax/Large Modern Ecotype,35000,99999,\n" +
  "Handysize/Capesize,24000,189999,\n" +
  "Handysize/Handymax,24000,49999,\n" +
  "Handysize/Handymax/Panamax,24000,79999,\n" +
  "Handysize/Handymax/Supramax,24000,59999,\n" +
  "Handysize/Handymax/Supramax/Panamax,24000,79999,\n" +
  "Handysize/Handymax/Supramax/Ultramax,24000,64999, \n" +
  "Handysize/Kamsarmax,24000,82999,\n" +
  "Handysize/Panamax,24000,79999,\n" +
  "Handysize/Post-panamax,24000,99999,\n" +
  "Handysize/Supramax,24000,59999,\n" +
  "Handysize/Supramax/Panamax,24000,79999,\n" +
  "Handysize/Supramax/Ultramax,24000,64999,\n" +
  "Handysize/Supramax/Ultramax/Kamsarmax,24000,82999,\n" +
  "Handysize/Supramax/Ultramax/Panamax,24000,79999,\n" +
  "Handysize/Ultramax,24000,64999,\n" +
  "Handysize/Ultramax/Panamax,24000,79999,\n" +
  "Kamsarmax/Baby-cape,80000,119999,\n" +
  "Kamsarmax/Capesize,80000,189999,\n" +
  "Kamsarmax/Large Modern Ecotype,80000,99999,\n" +
  "Kamsarmax/Post-panamax,80000,99999,\n" +
  "Kamsarmax/Post-panamax/Baby-cape,80000,119999,\n" +
  "Kamsarmax/Post-panamax/Capesize,80000,189999,\n" +
  "Large Modern Ecotype/Baby-cape,80000,119999,\n" +
  "Large Modern Ecotype/Capesize,80000,189999,\n" +
  "Large Modern Ecotype/Kamsarmax/Baby-cape,80000,119999,\n" +
  "Large Modern Ecotype/Kamsarmax/Post-panamax,80000,99999,\n" +
  "Large Modern Ecotype/Kamsarmax/Post-panamax/Baby-cape,80000,119999,\n" +
  "Large Modern Ecotype/Post-panamax,80000,99999,\n" +
  "Newcastlemax,190000,209999,\n" +
  "Omskiy/Amur/Sormovskiy/Volgo Balt,3174,3474,\n" +
  "Omskiy/Sibirskiy,3174,3427,\n" +
  "Omskiy/Sibirskiy/Volgo Don,3174,5885,\n" +
  "Omskiy/Sormovskiy,3174,3353,\n" +
  "Omskiy/Sormovskiy/Volgo Balt,3174,3474,\n" +
  "Omskiy/Sormovskiy/Volgo Balt/Volgo Don,3174,5885,\n" +
  "Omskiy/Volgobalt,3174,3474,\n" +
  "Omskiy/Volgobalt/Volgo Don,3174,5885,\n" +
  "Panamax/Baby-cape,65000,119999,\n" +
  "Panamax/Baby-cape/Capesize,65000,189999,\n" +
  "Panamax/Capesize,65000,189999,\n" +
  "Panamax/Kamsarmax,65000,82999,\n" +
  "Panamax/Kamsarmax/Baby-cape,65000,119999,\n" +
  "Panamax/Kamsarmax/Capesize,65000,189999,\n" +
  "Panamax/Kamsarmax/Large Modern Ecotype,65000,99999,\n" +
  "Panamax/Kamsarmax/Large Modern Ecotype/Capesize,65000,189999,\n" +
  "Panamax/Kamsarmax/Post-panamax,65000,99999,\n" +
  "Panamax/Kamsarmax/Post-panamax/Baby-cape,65000,119999,\n" +
  "Panamax/Kamsarmax/Post-panamax/Large Modern Ecotype,65000,99999,\n" +
  "Kamsarmax/Post-panamax/Large Modern Ecotype/Capesize,80000,189999,\n" +
  "Panamax/Large Modern Ecotype,65000,99999,\n" +
  "Panamax/Large Modern Ecotype/Post-panamax,65000,99999,\n" +
  "Panamax/Post-panamax,65000,99999,\n" +
  "Panamax/Post-panamax/Baby-cape,65000,119999,\n" +
  "Panamax/Post-panamax/Capesize,65000,189999,\n" +
  "Post-panamax/Baby-cape,83000,119999,\n" +
  "Post-panamax/Baby-cape/Capesize,83000,189999,\n" +
  "Post-panamax/Capesize,83000,189999,\n" +
  "Sormovskiy/Volgo Balt,3353,3474,\n" +
  "Sormovskiy/Volgo Balt/Volgo Don,3353,5885,\n" +
  "Supramax/Baby-cape,50000,119999,\n" +
  "Supramax/Capesize,50000,189999,\n" +
  "Supramax/Kamsarmax,50000,82999,\n" +
  "Supramax/Large Modern Ecotype,50000,99999,\n" +
  "Supramax/Panamax,50000,79999,\n" +
  "Supramax/Panamax/Kamsarmax,50000,82999,\n" +
  "Supramax/Panamax/Post-panamax,50000,99999,\n" +
  "Supramax/Panamax/Post-panamax/Baby-cape,50000,119999,\n" +
  "Supramax/Post-panamax,50000,99999,\n" +
  "Supramax/Ultramax,50000,64999,\n" +
  "Supramax/Ultramax/Baby-cape,50000,119999,\n" +
  "Supramax/Ultramax/Kamsarmax,50000,82999,\n" +
  "Supramax/Ultramax/Kamsarmax/Large Modern Ecotype,50000,99999,\n" +
  "Supramax/Ultramax/Large Modern Ecotype,50000,99999,\n" +
  "Supramax/Ultramax/Panamax,50000,79999,\n" +
  "Supramax/Ultramax/Panamax/Post-panamax,50000,99999,\n" +
  "Supramax/Ultramax/Panamax/Kamsarmax ,50000,82999,\n" +
  "Ultramax/Kamsarmax,60000,82999,\n" +
  "Ultramax/Kamsarmax/Large Modern Ecotype,60000,99999,\n" +
  "Ultramax/Large Modern Ecotype,60000,99999,\n" +
  "Ultramax/Panamax,60000,79999,\n" +
  "Ultramax/Panamax/Kamsarmax,60000,82999,\n" +
  "Ultramax/Panamax/Large Modern Ecotype,60000,99999,\n" +
  "Ultramax/Panamax/Kamsarmax/Large Modern Ecotype ,60000,99999,\n" +
  "Ultramax/Panamax/Kamsarmax/Post-panamax,60000,99999,\n" +
  "Ultramax/Post-panamax,60000,99999,\n" +
  "Ultramax/Panamax/Post-panamax,60000,99999,";


function transformCsvTypes(csv){
  const lines = csv.split("\n");
  for (let i = 0; i < lines.length; i++) {
    let [name, dwtMin, dwtMax] = lines[i].split(',');
    name = name.trim();
    dwtMin = parseInt(dwtMin.trim(), 10);
    dwtMax = parseInt(dwtMax.trim(), 10);
    const id = name.toUpperCase()
      .replace(/ /ig, '_')
      .replace(/-/ig, '');
    const t = {
      id,
      name,
      dwtMin,
      dwtMax,
      val: id,
    };
    vesselTypeBySizes.push(t);
    byIdMap[t.id] = t;
  }
}

transformCsvTypes(typesCsv);

export default vesselTypeBySizes;

export function findVesselSizeTypeById(id) {
  return byIdMap[id];
}
