import React from 'react'
import CircularProgress from 'material-ui/CircularProgress';
const wrapperStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '75px',
  height: '75px'
}
function Loader({ style = {}, ...rest }) {
  return (
    <div {...rest} style={{ ...wrapperStyle, ...style }}>
      <CircularProgress />
    </div>
  )
}

export default Loader
