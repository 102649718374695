import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Tags.scss';
import TagsAPI from '../../../core/api/Tags';
import TagsChips from './TagsChips';
import cx from 'classnames';
import TagCreation from './TagCreation';
import { transformTagBeforeSend, addUniqueIds } from './utils';
import Collapse from '../Collapse';
import Link from './../../Link/Link'
import TagsTooltip from './TagsTooltip';
import { objectIdToUUID } from '../../../core/utils';

export class Tags extends Component {

  static contextTypes = {
    showMessage: PropTypes.func
  }

  constructor(props) {
    super(props);

    this.state = {
      tags: addUniqueIds(this.props.tags || []),
      currentTag: "",
      currentCategory: "",
      tagError: "",
      isTagValid: true
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tags !== this.props.tags) {
      this.setState({
        tags: addUniqueIds(nextProps.tags || [])
      })
    }
  }

  componentDidMount() {
    this.setState({
      tags: addUniqueIds(this.props.tags || [])
    })
  }



  saveTags = tag => {

    if (this.props.savePromise) {
      return this.props.savePromise(this.props.entityId, [...this.state.tags, tag]).catch(this.errorSaving);
    }
    if (this.props.isContactTags) {
      const newTag = {
        categoryName: tag.category?.value || null,
        categoryId: tag.category ? objectIdToUUID(tag.category._id) : null,
        label: tag.value ?? tag,
      };
      return this.props.handleSave(newTag).catch(this.errorSaving);
    }
    const newTag = transformTagBeforeSend(tag);
    const obj = {
      toAdd: [newTag],
    }
    if (this.props.handleSave) {
      return this.props.handleSave(this.props.entityId, obj).then(this.updateTags).catch(this.errorSaving);
    }
    return this.deleteOrCreate(obj);
  }

  updateTags = res => {
    this.context.showMessage({
      message: `Tags have been updated!`,
    })
    if (res?.data) {
      if (this.props.handleSuccess) {
        this.props.handleSuccess(res.data);
      }
      this.setState({
        tags: addUniqueIds(res.data),
      });
      return res.data;
    }
  }

  errorSaving = err => {
    console.error(err);
    this.context.showMessage({
      level: "error",
      message: `Error saving tags: ${err?.errors?.[0]?.message ? err.errors[0].message : err?.message ?? err}`,
    })
  }

  deleteOrCreate = (obj) => {
    return TagsAPI.saveCargoTags(this.props.entityId, obj).then(this.updateTags).catch(this.errorSaving);
  }

  handleDelete = tag => {
    if (!tag) return;
    if (this.props.handleDelete) {
      this.props.handleDelete(tag);
      return;
    }
    const changes = { toRemove: [transformTagBeforeSend(tag)] };
    if (this.props.handleSave) {
      this.props.handleSave(this.props.entityId, changes).then(this.updateTags).catch(this.errorSaving);
      return;
    }
    return this.deleteOrCreate(changes);
  }

  render() {
    const { entity, opened = true, collapseProps = {
      additionals: <Info />,
    } } = this.props;
    const { tags } = this.state;
    return (
      <Collapse title={`TAGS (${tags.length})`} defaultOpen={opened} {...collapseProps}>
        <div className={cx(s.tags)}>
          <TagCreation tags={tags} entity={entity} saveTags={this.saveTags} autocompleteProps={this.props.autocompleteProps} />
          <TagsChips handleDelete={this.handleDelete} tags={tags} />
        </div>
      </Collapse>
    )
  }
}

export function Info({ link, ...rest }) {

  link = link || <Link to="/settings/tags">Open tags settings</Link>;

  return (
    <div className={s.tags_info}>
      {
        React.cloneElement(link, {
          className: s.tags_info_link,
          ...link.props,
        })
      }
      <TagsTooltip />
    </div>
  )
}

export default withStyles(s)(Tags);
