exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".fflI7 {\n  white-space: nowrap;\n  font-size: 12px; }\n  .fflI7 label {\n    color: var(--text-light) !important; }\n  ._1Goyp {\n  display: -ms-flexbox;\n  display: flex; }\n  ._2Yz1x {\n  -ms-flex-align: center;\n      align-items: center; }\n  ._2Mw2j {\n  margin-right: 18px; }\n  ._2A2pi {\n  width: 100%; }\n  ._1tICq {\n  gap: 16px; }\n", ""]);

// Exports
exports.locals = {
	"checkbox": "fflI7",
	"flex": "_1Goyp",
	"items_center": "_2Yz1x",
	"mr_18px": "_2Mw2j",
	"w_full": "_2A2pi",
	"gap_16px": "_1tICq"
};