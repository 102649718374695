import { filterContract } from './filter';
import { replaceInArray } from '../../core/utils';

//noinspection JSUnusedGlobalSymbols
/** @name CONTRACT_VOYAGE_SUBJECT_UPDATED_REDUCER
 * @param {ContractState} state
 * @param {Object} payload
 * @param {String} payload._id
 * @param {String} payload.action
 * @param {String} payload.status
 * */
export default function CONTRACT_VOYAGE_SUBJECT_UPDATED(state, { payload }) {
  return state;
  const index = state.contractList.data.findIndex(c => c._id === payload._id);
  if (index === -1) {
    return state;
  }
  const contract = { ...state.contractList.data[index] };
  contract.status = { name: payload.status };
  const newState = {
    contractList: filterContract({
      ...state.contractList,
      data: replaceInArray(state.contractList.data, index, contract),
    }),
  };
  return { ...state, ...newState };
}
