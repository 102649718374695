import Cargo from '../core/api/Cargo';
import Vessel from '../core/api/Vessel';
import {
  CARGO_REQUEST,
  CARGO_REQUEST_LIST,
  CARGO_SEND_FREIGHT_REQUEST,
  CARGO_FIX,
  CARGO_SEND_OFFER,
  CARGO_REJECT,
  CARGO_REQUEST_REFRESH_STATUS,
  CARGO_REQUEST_DELETE,
  MY_CARGO_DETAILS,
  SEND_VOYAGE_REMAINDER, CANCEL_VOYAGE_REMAINDER, UPDATE_CARGO_DETAILS,
} from '../constants/cargo';
import { NAVIGATE } from '../constants/index';
import { AFTER_REGISTER } from '../constants/register';
import { prepareRequest } from '../core/utils';

export function uploadFile(formData, opts) {
  return Cargo.uploadFile(formData, opts);
}

export function sendRequest(request, user, withoutRedirect) {
  return (dispatch) => {
    if (!user) {
      dispatch({
        type: AFTER_REGISTER,
        payload: {
          message: 'Please sign up or sign in to complete your request.',
          after: sendRequest.bind(this, request),
        },
      });

      return dispatch({
        type: NAVIGATE,
        payload: { route: '/register' },
      });
    }

    return dispatch({
      type: CARGO_REQUEST,
      promise: Cargo.sendRequest(request),
      redirect: request._id || withoutRedirect ? undefined : { route: '/main-deck/general' },
      payload: request,
    });
  };
}

export function getRequestList(params = {}) {
  return (dispatch, getState, history) => {
    params = { ...params };
    if (!params.filters) {
      const state = getState();
      params.filters = state.cargo.requestList.filters;
      params.sort = state.cargo.requestList.sort;
    }
    params.filters = { ...params.filters };
    if (params.filters) {
      if (params.filters.weightMin) {
        params.filters.weightMin *= 1000;
      }
      if (params.filters.weightMax) {
        params.filters.weightMax *= 1000;
      }
    }
    return dispatch({
      type: CARGO_REQUEST_LIST,
      promise: Cargo.getRequestList(prepareRequest(params)),
      payload: params,
    });
  };
}

export function sendFreightRequest(request, redirect = true) {
  return {
    type: CARGO_SEND_FREIGHT_REQUEST,
    payload: request,
    promise: Vessel.sendFreightRequest(request),
    redirect: redirect ?  { route: '/my/cargo/' + request.cargo } : undefined,
    successMessage: 'Freight request sent',
  };
}

export function sendOffer(offer, navigateAfter) {
  return {
    type: CARGO_SEND_OFFER,
    payload: offer,
    promise: Cargo.sendOffer(offer),
    redirect: navigateAfter !== false
      ? { route: navigateAfter || `/my/cargo/${offer.cargoRequest}` }
      : undefined,
    successMessage: 'Offer sent',
  };
}
export function fixOffer({ bidId, cargoRequest, bid }) {
  const body = {};
  if (bid.freights) {
    body.freights = bid.freights.filter(f => f.curr).map((f) => ({ ...f.curr, key: f.key, version: f.currVersion }));
  }
  if (bid.charges) {
    body.charges = bid.charges.filter(f => f.curr).map((f) => ({ ...f.curr, key: f.key, version: f.currVersion }));
  }
  return {
    type: CARGO_FIX,
    payload: { bidId, cargoRequest },
    promise: Cargo.fixOffer(bidId, body),
    successMessage: 'Offer fixed',
  };
}
export function rejectOffer({ bidId, cargoRequest }) {
  return {
    type: CARGO_REJECT,
    payload: { bidId, cargoRequest },
    promise: Cargo.rejectOffer(bidId),
    successMessage: 'Offer rejected',
  };
}

export function refreshCargoRequest(_id) {
  return {
    type: CARGO_REQUEST_REFRESH_STATUS,
    payload: _id,
    promise: Cargo.refreshCargoRequest(_id),
  };
}

export function deleteCargoRequest(_id) {
  return {
    type: CARGO_REQUEST_DELETE,
    payload: _id,
    promise: Cargo.deleteRequest(_id),
  };
}

export function getMyCargoDetails(_id, select = true, clear = true) {
  return {
    type: MY_CARGO_DETAILS,
    payload: { _id, select, clear },
    promise: Cargo.getCargoById(_id),
  };
}

export function sendReminder(body) {
  return {
    type: SEND_VOYAGE_REMAINDER,
    payload: body,
    promise: Cargo.sendReminder(body),
  };
}

export function cancelReminder(reminder) {
  return {
    type: CANCEL_VOYAGE_REMAINDER,
    payload: reminder,
    promise: Cargo.cancelReminder(reminder),
  };
}

export function updateCargoDetails(cargoRequest, cargoDetails){
  return {
    type: UPDATE_CARGO_DETAILS,
    payload: cargoDetails,
    promise: Cargo.updateCargoDetails(cargoRequest, cargoDetails),
  };
}
