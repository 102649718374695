export default function SNP_VESSEL_CHANGED(state, {payload: {id, changes}}) {
  const {data, filtered} = state.snpList;
  const newData = updateVesselChanges(data, id, changes);
  const newFiltered = updateVesselChanges(filtered, id, changes);
  return { ...state, snpList: {...state.snpList, data: newData, filtered: newFiltered}};
}

export function updateVesselChanges(arr, id, changes) {
  const index = arr.findIndex(v => v?.vessel?._id ? v.vessel._id === id : v._id === id);
  if (index < 0) {
    return arr;
  }
  const newVessel = {
    ...arr[index],
    vessel: {
      ...arr[index].vessel,
      changes: {
        ...arr[index].vessel.changes,
        ...changes,
      }
    }
  }
  const newArr = [...arr];
  newArr[index] = newVessel;
  return newArr;
}
