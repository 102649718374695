import React, { Component, PureComponent } from 'react';
import DefaultPopover from './DefaultPopover';
import styled from 'styled-components';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import CustomCheckbox from './CustomCheckbox';

const Popover = styled(DefaultPopover)`
    border-radius: 4px !important;
    &, div {
      font-size: 13px !important;

    }
    label {
      color: #333333 !important;
      font-size: 13px !important;
    }
    span {
      min-height: 24px !important;
      line-height: 24px !important;
      overflow: hidden;
      & > div {
        height: 100% !important;
        & > div {
          padding: 0px 8px !important;
        }
      }
      input ~ div {
        justify-content: space-between;
      }
    }
    & > div {
      height:100%;
      >div {
        height:100%;
        >div {
          height:100%;
          overflow:scroll;
        }
      }
    }
`;

const defaultCheckboxRenderer = (status, index) => {
  return (
    <MenuItem style={status.disabled ? { opacity: 0.5, backgroundColor: 'transparent' } : undefined} key={status.id} value={status}>
      <CustomCheckbox disabled={status.disabled || false} checked={status.checked} onCheck={(ev, val) => !status.disabled && status.onChange(val)} labelPosition={status.labelPosition || "left"} label={status.name} />
    </MenuItem>
  )
}

class CheckboxPopover extends PureComponent<{
  handleClose: any,
  list: any[],
  extras: null,
  renderer: (status, index) => any,
  open: boolean,
  anchor: any,
  handleSelectAll: (e: any, val: boolean) => any,
  selectAllAvailable: boolean,
}> {
  static defaultProps = { list: [], extras: null, renderer: defaultCheckboxRenderer, open: true }

onScroll= (e) => {
    this.scrollContainer = e.target;
    this.scrollTop = e.target.scrollTop;
}

componentDidUpdate(prevProps, prevState) {
    if(this.scrollContainer) {
      this.scrollContainer.scrollTop = this.scrollTop;
    }
  }

  render() {
    const { handleClose, list, extras, renderer, open, anchor, ...rest } = this.props;
    const allSelected = list.every(li => li.checked);
    return (
      <Popover handleClose={handleClose} open={open} anchor={anchor} {...rest} >
        <div onScroll={this.onScroll}>
          {this.props.selectAllAvailable ? (
            <Menu style={{ borderBottom: '1px solid #E6E6E6' }} >
              <MenuItem key={'selectAll'} value={'selectAll'}>
                <CustomCheckbox checked={allSelected} onCheck={this.props.handleSelectAll} labelPosition={"right"} label={"Select All"} />
              </MenuItem>
            </Menu>
          ) : null}
        <Menu >
          {
            list.map((status, index) => status.render ? status.render(status, index) : renderer(status, index))
          }
          {
            extras
          }
        </Menu>
        </div>
      </Popover>
    )
  }
};

export default CheckboxPopover;
