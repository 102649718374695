import * as handlers from './marketMonitor/index';
/** @typedef {Object} MonitorState
 * @property {List} newsList
 */

const currentDate = new Date();
const lastYearDate = new Date();
lastYearDate.setFullYear(currentDate.getFullYear() - 1);

export default function marketMonitor(
  state = {
    newsList: {
      data: [],
      page: 1,
      limit: 6,
      total: 0,
      sort: {
        sort: "date",
        order: "desc",
      },
      errorMessage: '',
      loading: false,
      loadMoreLoading: false,
      categoriesList: [],
      selectedCategories: [],
      tags: {
        condition: [],
      },
      search: '',
    },
    balticIndex: {
      loading: true,
      search: '',
      selectedIndex: null,
      indexesList: [],
      dateFrom: lastYearDate,
      dateTo: currentDate,
      dateType: 'days',
      dateRange: '1 year',
      selectedIndexes: [],
      errorMessage: '', 
      setId: '',
      chartLoading: false,
      chartData: [],
      error: '',
    },
  },
  action,
) {
  if (!handlers[action.type]) {
    return state;
  }
  try {
    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
