import React from 'react'
import IconButton from 'material-ui/IconButton';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';

const btnStyles = {
  background: "var(--stroke-light-gray2)",
  width: '24px',
  height: '24px',
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  outline: 'none',
  border: 'none'
}

const iconStyles = {
  width: '18px',
  height: '18px'
}

function CircleEditBtn({ icon, style = {}, iconStyle = {}, iconProps = {}, ...rest }) {

  const Icon = icon ? icon : EditIcon;

  return (
    <IconButton {...rest} iconStyle={{...iconStyles, ...iconStyle }} style={{...btnStyles, ...style}}>
      <Icon color="var(--profile-grey-label)" {...iconProps}></Icon>
    </IconButton>
  )
}

export default CircleEditBtn
