import { replaceFromWithAliases } from "../../components/Emails/utils";

/** @name EMAILS_GET_LIST_REDUCER */
export default function EMAILS_GET_LIST(state, action) {
  let data = [];
  const { payload } = action;
  if (payload.cursor) {
    data = state.emailList.data;
  }
  if (payload.res.data && payload.res.data.length) {
    payload.res.data.forEach(replaceFromWithAliases);
  }
  const emailList = { ...state.emailList, data: [...data, ...payload.res.data || []], ...payload, cursor: payload.res.cursor, loading: false };
  if (payload.filters) {
      emailList.filtersPerFolder[emailList.folder] = payload.filters;
  }
  return { ...state, emailList };
}
