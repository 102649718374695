import React, { Component } from 'react'
import s from './VesselPreviewNew.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Vessel from './../../../core/api/Vessel';
import PreviousPort from './PreviousPort';
const MAX_PORTS = 20;
const SHRINKED_PORTS = 3;

export class PreviousPorts extends Component {
    /* static defaultProps = {
        previousPorts: [
            {
                port: 'Odessa',
                date: new Date(),
                speed: '12,5kn',
                duration: '25 days',
                cargo: ''
            },
            {
                port: 'Chernomorsk ',
                date: new Date(),
                speed: '12,5kn',
                duration: '25 days',
                cargo: ''
            },
            {
                port: 'Odessa',
                date: new Date(),
                speed: '12,5kn',
                duration: '25 days',
                cargo: ''
            },
            {
                port: 'Odessa',
                date: new Date(),
                speed: '12,5kn',
                duration: '25 days',
                cargo: ''
            },
            {
                port: 'Odessa',
                date: new Date(),
                speed: '12,5kn',
                duration: '25 days',
                cargo: ''
            },
            {
                port: 'Odessa',
                date: new Date(),
                speed: '12,5kn',
                duration: '25 days',
                cargo: ''
            },
        ]
    } */
    constructor(props) {
        super(props);
        this.state = {
            showAll: false,
            previousPorts: []
        }
        this.renderPreviousPorts = this.renderPreviousPorts.bind(this);
        this.defaultField = this.defaultField.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
    }

    async loadPreviousPorts() {
      try {
        const res = await Vessel.getPreviousPorts(this.props.vessel.imoNumber);
        if (res.data) {
          this.setState({
            previousPorts: res.data
          })
        }
      } catch (error) {
        console.error(error);
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.vessel?.imoNumber !== this.props.vessel?.imoNumber) {
        this.loadPreviousPorts();
      }
    }

    componentDidMount() {
      if (!this.props.vessel) return;
      this.loadPreviousPorts();
    }

    defaultField(field) {
        return field || '---'
    }

    toggleAll() {
        this.setState(state => ({showAll: !state.showAll}))
    }

    renderPreviousPorts() {
        const { previousPorts } = this.state;
        const { defaultField } = this;

        const ports = this.state.showAll ? previousPorts.slice(0, MAX_PORTS) : previousPorts.slice(0,SHRINKED_PORTS);

        return ports.map((port) => (
          <PreviousPort defaultField={defaultField} key={port.id ?? port.port._id} port={port}></PreviousPort>
        ))
    }

    render() {
        const { previousPorts } = this.state;
        if (!previousPorts?.length) {
          return <span>---</span>
        }
        return (
            <div className={s.collapsible_table}>
                <div className={s.headers}>
                    <div className={cx(s.header, s.port)}>Port / Date</div>
                    <div className={cx(s.header, s.speed)}>Average speed</div>
                    <div className={cx(s.header, s.arrival_date)}>Arrival date</div>
                    <div className={cx(s.header, s.duration)}>Duration of stay</div>
                    {/* <div className={cx(s.header, s.cargo)}>Cargo</div> */}
                </div>
                {this.renderPreviousPorts()}
                {
                  previousPorts.length > 3
                    ? (
                      <button onClick={this.toggleAll} className={s.show_more}>Show {this.state.showAll ? 'less' : 'more'}</button>
                    )
                    : null
                }
            </div>
        )
    }
}

export default withStyles(s)(PreviousPorts);
