import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PublicVessel.scss';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Link from '../Link/Link';
import cx from 'classnames';
import NavigationIcon from 'material-ui/svg-icons/navigation/arrow-forward';
import {number} from "../../core/format";
import PortLinkNew from '../Common/PortLinkNew';
import PublicPort from './PublicPort';
import ModalDownloadApp from './ModalDownloadApp';
import ProgressVesselPosition from '../Common/ProgressVesselPosition';
import MapPosition from '../Common/MapPosition';
import ImageSlider from './ImageSlider';

const generalCargo = [
  {
    id: '0',
    title: 'General Cargo',
    mt: '1200MT',
    name: 'Glasgow',
    dateBegin: '16.08.18',
    dateEnd: '24.08.18',
  },
  {
    id: '1',
    title: 'General Cargo',
    mt: '800mt',
    name: 'Hamburg',
    dateBegin: '19.08.18',
    dateEnd: '24.08.18',
  },
  {
    id: '2',
    title: 'General Cargo',
    mt: '1200MT',
    name: 'Glasgow',
    dateBegin: '16.08.18',
    dateEnd: '24.08.18',
  },
];

const vessel = {
  blt: 2008,
  flag: {
    name: 'GERMANY',
  },
  class: 'L.R. 100 A1, LMC, UMS, SCM',
  fitted: {
    ice: '1A',
  },
  type: 'Boxshaped / Multy purpose',
  homePort: {
    name: 'Sneek, Netherlands',
  },
  dw: {
    summer: '7,214.6',
    tropical: '7,214.6',
    dwcc: '---',

  },
  rt: {
    gt: '5,132',
    nt: '2,891',
    suezGt: '---',
    suezNt: '---',
    panamaNt: '---',
  },
  consumptions: {
    tpc: '70.30',
    tpi: '27.68',
  },
  dimensions: {
    loa: '134',
    beam: '120',
    depth: '20',
  },
  capacity: {
    grain: '34.959',
    bale: '34.959',
    teu: '234',
    feu: '---',
  },
  holds: {
    quantity: '2',
  },
  hatches: {
    quantity: '2',
    hatchType: 'combined',
  },
  areas: {
    mainDeck: '1032.3',
    tweenDeck: '956.3',
    weatherDeck: '1141.4',
    total: '3130',
  },
  gears: {
    all: [],
  },
  speeds: {
    ballast: '14',
    eco: '12.50',
    laden: '14',
  },
};

const images = [
  {
    original: 'http://www.nautilusminerals.com/irm/content/images/vessel1.jpg',
  },
  {
    original: 'http://www.maersksupplyservice.com/Featured%20Assets/Anchor_handler.png',
  },
  {
    original: 'http://www.sovereigncharterers.com/images/gallery/de421/03.jpg',
  },
  {
    original: 'http://www.maersksupplyservice.com/Style%20Library/MssBranding/images/stingrey.png',
  }
];

class PublicVessel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handleClose: false,
      vesselPosition: {
        name: 'Beautrophy',
        imo: '9369306',
        lastPos: {
          lastUpdated: '2018-01-23T13:25:58.392Z',
          coords: [34.123234234, 45.23423423],
        },
        route: {
          from: {
            name: 'SHANGHAI',
            date: '2017-12-29T16:11:56.797Z',
          },
          to: {
            name: 'ODESSA',
            date: '2018-02-01T16:11:56.797Z',
          },
          progress: 0.2,
        },
      },
      vessel: {
        name: 'Mediterranean Sea',
        lastPos: {
          coords: [4.16219711, 52.7796288],
        },
      },
    };
  }
  static propTypes = {
    vessel: PropTypes.object,
    vesselId: PropTypes.string,
    claimRights: PropTypes.func,
    addNewVessel: PropTypes.func,
    updateVesselById: PropTypes.func,
    showName: PropTypes.bool,
  };

  render() {
    return (
      <div
        className={s.public_vessel}
        style={{
          height: this.props.user && !this.props.user.emailVerified ? 'calc(100vh - 50px)' : '100vh'
        }}
      >
        <Header scroll login/>
        <div className={cx(s.more_info, s.container_home)}>
          <h2>International Shipping Marketplace</h2>
          <Link to={'/'}>
            more info
            <NavigationIcon
              color={'#ffffff'}
              style={{
                width: '16px',
                height: '16px'
              }}
            />
          </Link>
        </div>
        <div className={cx(s.title, s.container_home)}>
          <div>
            <h3>
              {this.state.vesselPosition.name}<br />
              <span className={s.imo}>Imo:&nbsp;{this.state.vesselPosition.imo}</span>
            </h3>
          </div>
          <div className={s.wrapper_button}>
            <Link to="/login" className={s.link}>Request freight</Link>
            <Link to="/register" className={s.link}>Offer hire</Link>
          </div>
          <div className={cx(s.wrapper_button, s.wrapper_button_mob)}>
            <ModalDownloadApp className={s.link} name="Request freight" />
            <ModalDownloadApp className={s.link} name="Offer hire" />
          </div>
        </div>
        <div className={cx(s.description, s.container_home)}>
          <div className={s.wrapper_map}>
            <ImageSlider images={images} name={this.state.vessel.name}/>
            <div className={s.from_to}>
              {
                this.state.vesselPosition.route ?
                  <ProgressVesselPosition vessel={this.state.vesselPosition} publicVessel />
                  : null
              }
            </div>
            <MapPosition vessel={this.state.vessel} publicVessel/>
            <div className={s.destination}>
              <span className={s.bold}>Additional destination:</span>
              <div className={s.wrapper_destination}>
                <PortLinkNew name="Chornomorsk" />,&nbsp;
                <PortLinkNew name="Yuzhne" />,&nbsp;
                <PortLinkNew name="Ochakiv" />,&nbsp;
                <PortLinkNew name="Mykolaiv" />
              </div>
            </div>
          </div>
          <div className={s.info}>
            <div className={s.planned_cargoes}>
              <h3>Recommended planned cargoes</h3>
              {
                generalCargo.map((item, i) => (
                  <div className={s.wrapper_general_cargo} key={i}>
                    <p>{item.title}</p>
                    <p>{item.mt}</p>
                    <PortLinkNew name={item.name} />
                    <p>
                      {item.dateBegin}
                      <span className={s.divide_date}>-</span>
                      <span className={s.date_end}>{item.dateEnd}</span>
                    </p>
                  </div>
                ))
              }
            </div>
            <div className={s.vessel_particulars}>
              <h3>Vessel Particulars</h3>
              <div className={s.wrapper_vessel}>
                <div className={s.line}>
                  <p>Year</p>
                  <p>{vessel.blt || '---'}</p>
                </div>
                <div className={s.line}>
                  <p>Flag</p>
                  <p>{vessel.flag && vessel.flag.name ? vessel.flag.name : '---'}</p>
                </div>
                <div className={s.line}>
                  <p>Class</p>
                  <p>{vessel.class ? vessel.class : '---'}</p>
                </div>
                <div className={s.line}>
                  <p>Ice Class</p>
                  <p>{vessel.fitted && vessel.fitted.ice ? vessel.fitted.ice : '---'}</p>
                </div>
                <div className={s.line}>
                  <p>Type</p>
                  <p>{vessel.type}</p>
                </div>
                <div className={s.line}>
                  <p>PORT OF REGISTRY</p>
                  <p>{vessel.homePort ? vessel.homePort.name : '---'}</p>
                </div>
                <div className={s.line}>
                  <p>TONNAGE</p>
                  <ul>
                    <li>
                      DWT summer/tropical: {number(vessel.dw && vessel.dw.summer)} / {number(vessel.dw && vessel.dw.tropical)}
                    </li>
                    <li>
                      GT/NT: {number(vessel.rt && vessel.rt.gt)} / {number(vessel.rt && vessel.rt.nt)}
                    </li>
                    <li>DWCC: {number(vessel.dw && vessel.dw.dwcc)}</li>
                    <li>Suez GT/NT: {number(vessel.rt && vessel.rt.suezGt)} / {number(vessel.rt && vessel.rt.suezNt)}</li>
                    <li>Panama NT: {number(vessel.rt && vessel.rt.panamaNt)}</li>
                    <li>TPC: {number(vessel.consumptions && vessel.consumptions.tpc)}</li>
                    <li>TPI: {number(vessel.consumptions && vessel.consumptions.tpi)}</li>
                  </ul>
                </div>
                <div className={s.line}>
                  <p>DIMENSIONS</p>
                  <ul>
                    <li>LOA: {number(vessel.dimensions && vessel.dimensions.loa, '', ' M')}</li>
                    <li>Beam: {number(vessel.dimensions && vessel.dimensions.beam, '', ' M')}</li>
                    <li>Depth: {number(vessel.dimensions && vessel.dimensions.depth, '', ' M')}</li>
                  </ul>
                </div>
                <div className={s.line}>
                  <p>CAPACITY</p>
                  <ul>
                    <li>Grain: {number(vessel.capacity && vessel.capacity.grain, '', ' M3')}</li>
                    <li>Bale: {number(vessel.capacity && vessel.capacity.bale, '', ' M3')}</li>
                    <li>TEU: {number(vessel.capacity && vessel.capacity.teu, '', ' M3')}</li>
                    <li>FEU: {number(vessel.capacity && vessel.capacity.feu,'', ' M3')}</li>
                  </ul>
                </div>
                <div className={s.line}>
                  <p>HOLDES / HATCHES</p>
                  <ul>
                    <li>
                      HO/ HA: {number(vessel.holds && vessel.holds.quantity)} / {number(vessel.hatches && vessel.hatches.quantity)}
                    </li>
                    <li>Hatch type: {(vessel.hatches && vessel.hatches.hatchType) || '---'}</li>
                    <li>
                      Area main deck: {number(vessel.areas && vessel.areas.mainDeck, '', ' m2')}
                    </li>
                    <li>
                      Area tween deck: {number(vessel.areas && vessel.areas.tweenDeck, '', ' m2')}
                    </li>
                    <li>
                      Area weather deck: {number(vessel.areas && vessel.areas.weatherDeck, '', ' m2')}
                    </li>
                    <li>
                      Total: {number(vessel.areas && vessel.areas.total, '', ' m2')}
                    </li>
                  </ul>
                </div>
                <div className={s.line}>
                  <p>CARGO GEAR</p>
                  <ul>
                    {vessel.gears && vessel.gears.all && vessel.gears.all.length
                      ? vessel.gears.all.map((g, i) => <li key={i}> {g}</li>)
                      : '---'}
                  </ul>
                </div>
                <div className={s.line}>
                  <p>SPEED</p>
                  <ul>
                    <li>Ballast: {number(vessel.speeds && vessel.speeds.ballast, '', ' KN')}</li>
                    <li>Eco: {number(vessel.speeds && vessel.speeds.eco, '', ' KN')}</li>
                    <li>Laden: {number(vessel.speeds && vessel.speeds.laden, '', ' KN')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={cx(s.wrapper_button, s.wrapper_button_mob)}>
            <ModalDownloadApp className={s.link} name="Freight request" />
            <ModalDownloadApp className={s.link} name="Offer hire" />
          </div>
        </div>
        <Footer publicInfo />
      </div>
    )
  }
}

export default withStyles(s)(PublicVessel);


