import { date, number } from '../../../core/format';

export const prepareDataForRender = (data) => {
	try {
		const newData = Object.assign({}, data);
		const typeText = ["My price", "Owner", "Received"];
		return {
			addedDate: date(newData.createdAt),
			addedBy: `${data?.user?.name || "---"} ${data?.user?.surname || "---"}`,
			refNo: newData.refNo || "---",
			ideaPrice: newData.ideaPrice
			? number(newData.ideaPrice, '$')
			: "---",
			salePrice: newData.salePrice
			? newData.salePrice < 0
				? "SOLD"
				: number(newData.salePrice, '$')
			: "---",
			company: newData.company?.name ? newData.company?.name?.toString() : "---",
			chatId: newData?.user?._id || null,
			renderType: isFinite(newData?.type) ? typeText[newData.type] : "---",
			isReceived: newData.type ? newData.type === 2 : false,
			realUserEmail: newData?.realUserEmail ? newData?.realUserEmail?.toString() : null,
			mailId: newData.mailId ? newData.mailId?.toString() : null,
			vessel: {
        ...(newData?.vessel ?? {}),
				name: newData.vessel?.name ? newData.vessel?.name?.toString() : "---",
				imo: newData.vessel?.imoNumber ? newData.vessel?.imoNumber?.toString() : "---",
				type: newData.vessel?.typeByPurpose ? newData.vessel?.typeByPurpose?.toString() : "---",
				blt: newData.vessel?.blt ? newData.vessel?.blt?.toString() : "---",
				dwt: number(newData.vessel?.dw?.summer),
				capacity: number(newData.vessel?.capacity?.grain),
				manager: newData.vessel?.manager?.name ? newData.vessel?.manager?.name.toString() :  "---",
				owner: newData.vessel?.owner?.name ? newData.vessel?.owner?.name?.toString() : "---",
				design: newData.vessel?.design?.name ? newData.vessel?.design?.name?.toString() : "---",
			}
		}
	}
	catch (e) {
		console.error(e);
	}
};
