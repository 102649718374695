import React from 'react'
import Highlight from '../../Monitor/Highlight'
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SecondText from '../../Monitor/RowBlocks/SecondText';
function ImoType({ vessel, search, className = "", ...rest }) {
  return (
      <SecondText className={className} title={vessel?.imoNumber + ' / ' + (vessel?.type || "")} {...rest}>
        <Highlight search={search}>
          {vessel?.imoNumber + ' / ' + (vessel?.typeByPurpose || vessel?.type)}
        </Highlight>
      </SecondText>
  )
}

export default withStyles(s)(ImoType);
