import RequestRourceFilter from "../Monitor/RequestRourceFilter";
import React from "react";
import styled from "styled-components";

const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 16px;
  max-height: 16px;
  min-width: 16px;
  background-color: #E34848;
  color: white;
  padding: 0 5px;
  font-size: 10px;
  line-height: 16px;


`

class ContactTypeFilter extends React.Component<{}> {
  static keys = ['createdByMe', 'createdByTeam', null, 'new'];

  render() {
    const counters = this.props.counters;
    let counter = null;
    if(counters?.newPersons) {
      counter = <Counter>{counters.newPersons > 99 ? '99+' : counters.newPersons}</Counter>;
    }
    const titles = [{ text: 'My contacts' }, { text: 'Team contacts' }, null, { text: 'New suggestions', rightIcon: counter } ];
    return <RequestRourceFilter
      handleFilter={this.props.handleFilter}
      filters={this.props.filters}
      endingText={""}
      filtersKey="types"
      allText={"All Contacts"}
      noDots
      keys={ContactTypeFilter.keys}
      titles={titles}
      style={this.props.style}
      rightIcon={counter}
    />
  }
}

export default ContactTypeFilter;

