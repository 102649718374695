import Api from './api';

export default class CommentsApi extends Api {
  static async saveComment(body) {
    return CommentsApi.fetch(`/comments`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  static saveContactComment(body) {
    return this.fetch(`/comments`, {
      method: 'POST',
      body: JSON.stringify(body),
    }, '/api/v2');
  }
  static async getContactComments({ entityId, page = 1, limit = 5, sort = -1, isPrivate = false }) {
    return this.fetch(`/comments?entityId=${entityId}&page=${page}&limit=${limit}&sorted=${sort}&isPrivate=${isPrivate}`, {
      method: 'GET',
      withHeaders: true,
    }, '/api/v2');
  }
}
