import PropTypes from 'prop-types';
import React, { Children, isValidElement, cloneElement } from 'react';
import List from 'material-ui/List';
import Formsy from 'formsy-react';
import formDataToObject from 'form-data-to-object';
import {Tonnage} from './Monitor/Edit/VesselEdit';

const NestedList = (props) => {
  const { children, open, nestedLevel, style = {} } = props;

  if (!open) {
    style.display = 'none';
  } else {
    style.display = 'block';
  }

  return (
    <List style={style}>
      { // eslint-disable-next-line
        Children.map(children, (child) => {
          return isValidElement(child)
          ? cloneElement(child, {
            nestedLevel: nestedLevel + 1,
          })
          : child;
        })}
    </List>
  );
};

NestedList.propTypes = {
  children: PropTypes.node,
  nestedLevel: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.object,
};
require('material-ui/List/NestedList').default = NestedList;

function fromObj(obj, excludePredicate = (key, val) => false) {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
  function recur(newObj, propName, currVal, key) {
    if (
      !excludePredicate(key, currVal) &&
      (Array.isArray(currVal) ||
      (Object.prototype.toString.call(currVal) === '[object Object]' &&
        (!currVal._id || propName.indexOf('bunkers') !== -1 || propName.indexOf('billOfLading') !== -1) || (currVal && currVal.oneOf))
      )
    ) {
      Object.keys(currVal).forEach((v) => {
        recur(newObj, propName + '[' + v + ']', currVal[v], v);
      });
      return newObj;
    }
    if (typeof currVal === 'string' && reISO.exec(currVal)) {
      // eslint-disable-next-line
      newObj[propName] = new Date(currVal);
    } else {
      // eslint-disable-next-line
      newObj[propName] = currVal;
    }

    return newObj;
  }

  const keys = Object.keys(obj);
  // eslint-disable-next-line
  return keys.reduce((newObj, propName) => {
    return recur(newObj, propName, obj[propName], propName);
  }, {});
}

formDataToObject.fromObj = fromObj;

Formsy.addValidationRule('gt0', (values, value) => {
  if (value === '' || value === undefined) {
    return true;
  }
  return Number(value) > 0;
});
Formsy.addValidationRule('isSite', (values, value) => {
  if (value === '' || value === undefined) {
    return true;
  }
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  // eslint-disable-next-line
  return pattern.test(
    value,
  );
});
Formsy.addValidationRule('isPercent', (values, value) => {
  if (value === undefined || value === null) {
    return true;
  }
  return value >= 0 && value <= 100;
});
Formsy.addValidationRule('gteField', (values, value, field) => {
  if (!isFinite(value)) {
    return true;
  }

  return value >= (values[field] || 0);
});
Formsy.addValidationRule('gtField', (values, value, field) => {
  if (!isFinite(value)) {
    return true;
  }
  return value > (values[field] || 0);
});
Formsy.addValidationRule('gte', (values, value, otherValue) => {
  if (!isFinite(value) || value === '') {
    return true;
  }
  return +value >= otherValue;
});
Formsy.addValidationRule('gt', (values, value, otherValue) => {
  if (!isFinite(value)) {
    return true;
  }
  return value > otherValue;
});
Formsy.addValidationRule('imdgClass', (values, value) => {
  const classes = [
    1,
    1.1,
    1.2,
    1.3,
    1.4,
    1.5,
    1.6,
    2,
    2.1,
    2.2,
    2.3,
    3,
    4,
    4.1,
    4.2,
    4.3,
    5,
    5.1,
    5.2,
    6,
    6.1,
    6.2,
    7,
    8,
    9,
  ];
  if (!isFinite(value)) {
    return true;
  }
  return classes.indexOf(Number(value)) !== -1;
});

Formsy.addValidationRule('max', (values, value, otherValue) => {
  const v = Number(value);
  return !isFinite(v) || v <= otherValue;
});
Formsy.addValidationRule('min', (values, value, otherValue) => {
  if (value === '') return true;
  const v = Number(value);
  return !isFinite(v) || v >= otherValue;
});

Formsy.addValidationRule('maxWeight', () => true); //dummy validation,due to dynamic units this type of validation controls inside FormsyText component 'validation' mixin

Formsy.addValidationRule('totalWeight', () => true);
Formsy.addValidationRule('totalVolume', () => true);

Formsy.addValidationRule('requiredIf', (values, value, otherFieldName) => {
  return !(!value && values[otherFieldName]);
})

Formsy.addValidationRule('requiredIfInArrInputs', (values, value, type) => {
  if (value) return false;
  const arr = Tonnage.cells.map(cell => `${Tonnage.objectName}[${type}${cell.field}]`);
  const fieldHasValue = arr.find(field => values[field]);
  if (!value && fieldHasValue) return true;
})

Formsy.addValidationRule('isObj', (values, value) => {
  return (typeof value === 'object' && value !== null) || !value
})

Formsy.addValidationRule('isAllowed', (values, value, length = 0) => {
  return +length <= 0
})
Formsy.addValidationRule('duplicate', (values, value, length = 0) => {
  return false;
})

Formsy.addValidationRule('lteDateField', (values, value, field) => {
  if (!value) {
    return true;
  }
  let otherValue = values[field];
  if (!otherValue) {
    return true;
  }
  value = resetTime(value);
  otherValue = resetTime(otherValue);

  return value <= otherValue;
});

Formsy.addValidationRule('forceInvalid', (values, value, field, ...rest) => {
  return !value;
});
Formsy.addValidationRule('unNumber', (values, value, field, ...rest) => {
  if (!value) {
    return true;
  }
  return /^\d{4}$/.test(value);
});
export default function () {}

function resetTime(date){
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
}
