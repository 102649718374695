import React from 'react'
import { date } from '../../../core/format';
import SecondText from '../../Monitor/RowBlocks/SecondText';
import cx from 'classnames';
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
function To({ routeObj }) {

  const comingTo = routeObj?.route?.to?.name || "---";

  const arrivalDate = routeObj?.route?.to?.date ? date(routeObj.route.to.date) : "---";

  return (

    <div className={s.text}>
      <span title={comingTo ? comingTo : undefined} className={cx(s.bold_text, s.uppercased, s.arrival_port)}>
        {
          comingTo
        }
      </span>
      <span className={s.row_block}>
        <SecondText>
          {
            arrivalDate
          }
        </SecondText>
      </span>
    </div>
  )
}

export default withStyles(s)(To);
