/** @typedef {Object} List
 * @property {Array} data
 * @property {Array} filtered
 * @property {Object} filters
 * @property {Boolean} loading
 * @property {Number} next
 * @property {Number} page
 * @property {Object} sort
 */
/** @typedef {Object} VesselState
 * @property {List} requestList
 * @property {List} tcOfferList
 */

import * as handlers from './vessel/index';

import { defaultSortField as defaultSortFieldMyFleet } from './../components/Vessel/MyFleet/sort';
import { defaultSortField as defaultSortFieldSpFleet } from './../components/Vessel/Spfleet/sort';
export default function login(
  state = {
    requestList: {
      data: [],
      filtered: [],
      page: 1,
      next: 2,
      filters: {},
      sort: { field: 'status.weight', value: -1 },
      loading: false,
    },
    tcOfferList: {
      data: [],
      filtered: [],
      page: 1,
      next: 2,
      filters: {},
      sort: { field: 'lastUpdate', value: -1 },
      loading: false,
    },
    sentTcOfferList: {
      data: [],
      filtered: [],
      page: 1,
      next: 2,
      filters: {},
      sort: { field: 'lastUpdate', value: -1 },
      loading: false,
    },
    positionsList: {
      data: [],
      filtered: [],
      page: 1,
      next: 2,
      filters: {},
      sort: { field: 'addedAt', value: -1 },
      loading: false,
    },
    vesselList: {
      data: [],
      filtered: [],
      page: 1,
      next: 2,
      filters: {channels:{mine:true, broker:true, market: true}},
      'sort': defaultSortFieldMyFleet,
      loading: false,
	 },
    snpList: {
      data: [],
      filtered: [],
      filters: {},
      page: 1,
      next: 2,
      hidden: false,
      'sort' : defaultSortFieldSpFleet,
      loading: false
    },
  },
  action,
) {
  if (!handlers[action.type]) {
    return state;
  }
  try {
    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
