import City from '../../core/api/City';
import Autocomplete from './AutoComplete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class CityAutocomplete extends Autocomplete {
  static defaultProps = {
    forceSelection: true,
  };
  constructor(props) {
    super(props);
    this.Api = City;
  }
}

CityAutocomplete.propTypes = {};

export default CityAutocomplete;
