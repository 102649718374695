import memoizeOne from 'memoize-one';
import {uniq} from "lodash";

export const parseErrorsArray = memoizeOne(errors => Array.isArray(errors) ? errors.reduce((acc, error) => {
  // errors are object with row name (e.g. POL, POD, ...) as fields; each field is an object with input field name (e.g port, cancellingDate, cargoName, ...),
  // input field name in this object can be either array of objects (errors) or object (error itself)
  // if row in Preview renders array (for example POL), errors for each input field will be an arrays to display error message for each POL

  const rowName = error.type;

  const rowNameErrors = acc[rowName];

  const inputFieldName = error.fieldName;

  if (rowNameErrors) {
    return {
      ...acc,
      [rowName]: {
        ...rowNameErrors,
        [inputFieldName]:
          typeof error.index !== 'undefined'
            ? [ ...(Array.isArray(rowNameErrors[inputFieldName]) ? rowNameErrors[inputFieldName] : []), error ]
            : error
      }
    }
  }

  return {
    ...acc,
    [rowName]: {
      [inputFieldName]:
        typeof error.index !== 'undefined'
          ? [ error ]
          : error
    }
  }
}, {}) : errors || {});

export function convertRequestToServer(request = { }){
  const loading = convertPortsToServer(request.loadingPorts);
  const unloading = convertPortsToServer(request.unloadingPorts);
  request.loadingPort = loading[0]?.oneOf?.[0].port;
  request.unloadingPort = unloading[0]?.oneOf?.[0]?.port;
  request.readinessDate = loading[0]?.oneOf?.[0]?.readinessDate;
  request.cancellingDate = loading[0]?.oneOf?.[0]?.cancellingDate;
  request.loading = loading;
  request.unloading = unloading;
  return request;
}

export const getNamesInBrackets = val => {
  const nesting = String(val).match(/\[(\w|\s)+\]/g);

  if (!nesting?.length) return [];

  return nesting;
}

export const stripBrackets = (name) => String(name).replace(/[\[\]]/g, '');

export const accessObjectValueWithName = memoizeOne((object, name) => {
  const nesting = getNamesInBrackets(name);

  if (!nesting.length) return "";

  return nesting.reduce((val, item) => val?.[stripBrackets(item)] ?? "", object);
});

export const getDefaultPorts = () => [{ type: 'next', i: 0, opened: true }];

function convertPortsToServer(ports = []) {
  const ret = [];
  if (!ports.length) {
    return ports;
  }
  let curLeg = { oneOf: [] };
  for (let i = 0; i < ports.length; i++) {
    const port = ports[i];
    if (port) {
      if (port.type !== 'alt') {
        curLeg = { oneOf: [{ port: port.port, details: port.details, readinessDate: port.readinessDate, cancellingDate: port.cancellingDate, layCanType: port.layCanType }] };
        ret.push(curLeg);
      } else {
        curLeg.oneOf.push({ port: port.port, details: port.details, readinessDate: port.readinessDate, cancellingDate: port.cancellingDate });
      }
    }
  }
  return ret;
}

export const getPreviewErrorMsg = (errorObject, index) => {
  let errMsg = "";

  if (typeof index !== 'undefined') {
    const foundErrorByIndex = errorObject?.find?.(error => error?.index == index);
    if (foundErrorByIndex) {
      errMsg = foundErrorByIndex.config?.error;
    }
  }
  else if (errorObject) {
    errMsg = errorObject.config.error;
  }

  return errMsg;

};

export const extendStowageFields = (stowage = {}, cargoObject) => {
  // this holds list of fields that are stored outside of "packing.pack" object in server's cargo details response
  // but this filds belong to stowage section (they are rendered in Stowage component)
  // so to make them reflect value and update, i map them to stowage object
  const cargoFieldsThatBelongToStowageSection = ['dangerous', 'military', 'propelled'];

  return Object.assign({}, stowage, cargoFieldsThatBelongToStowageSection.reduce((acc, field) => {
    if (stowage[field]) {
      acc[field] = stowage[field];
    }
    else {
      acc[field] = cargoObject[field] || '';
    }

    return acc;
  }, {}));
};

const ttToTermsMap = {
  bulk: 'drybulk',
  wetbulk: 'wetbulk',
  breakbulk: 'breakbulk',
  container: 'breakbulk',
};

export function mapTypeOfTransportationsToTerms(ttList) {
  return uniq(ttList.map(tt => ttToTermsMap[tt]));
}
