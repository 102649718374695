exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1snmI {\n  position: relative; }\n  ._1snmI.Jqngo .xWHLo svg path {\n    fill: var(--text-links); }\n  ._1snmI.Jqngo .xWHLo svg:hover path {\n    fill: var(--bg-blue); }\n  ._1snmI.Jqngo ._3Fqes {\n    background: #ffffff;\n    -webkit-box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);\n            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3); }\n  ._1snmI.Jqngo ._3Fqes p {\n      color: var(--text-medium); }\n  ._1snmI .xWHLo {\n    display: inline-block;\n    width: 16px;\n    height: 16px;\n    margin-left: 8px;\n    cursor: pointer; }\n  ._1snmI .xWHLo svg {\n      width: 100%;\n      height: 100%; }\n  ._1snmI .xWHLo svg path {\n        fill: var(--stroke-light-gray1); }\n  ._1snmI .xWHLo svg:hover path {\n        fill: var(--text-light); }\n  ._1snmI ._3Fqes {\n    display: none;\n    top: 28px;\n    left: -38px;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    width: 68px;\n    height: 36px; }\n  ._1snmI ._3Fqes:before {\n      right: 6px;\n      left: auto; }\n  ._1snmI:hover ._3Fqes {\n    display: -ms-flexbox;\n    display: flex; }\n  .PmEpp, ._1snmI ._3Fqes {\n  position: absolute;\n  z-index: 998;\n  background: var(--bg-table-dark);\n  border-radius: 4px;\n  cursor: default; }\n  .PmEpp p, ._1snmI ._3Fqes p {\n    margin: 0;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 20px;\n    color: #FFFFFF; }\n  .Dm-GV {\n  background: #ffffff; }\n  .Dm-GV p {\n    color: var(--text-medium); }\n", ""]);

// Exports
exports.locals = {
	"wrapper_toggle": "_1snmI",
	"wrapper_toggle_dark": "Jqngo",
	"toggle_icon_moon": "xWHLo",
	"tooltip_toggle": "_3Fqes",
	"tooltip": "PmEpp",
	"dark_tooltip": "Dm-GV"
};