exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1FV5F {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  gap: 2px;\n  height: 24px;\n  width: 56px;\n  border-radius: 100px;\n  background: #fff;\n  border: 1px solid #e6e6e6;\n  padding: 0 6px;\n  font-family: Roboto;\n  font-size: 11px;\n  font-weight: 400;\n  line-height: 14px;\n  letter-spacing: 0px;\n  color: #333; }\n  ._1FV5F ._2ELym {\n    width: 16px !important;\n    height: 16px !important;\n    fill: #787878 !important; }\n  ._1FV5F.UVTIj {\n    width: 72px;\n    background: var(--active-filter-sort) !important;\n    border: 1px solid var(--active-filter-sort-brd);\n    color: var(--active-filter-sort-clr); }\n  ._1FV5F.UVTIj ._2ELym {\n      fill: var(--active-filter-sort-clr) !important; }\n  .QAHwA {\n  width: 15px !important;\n  height: 15px !important;\n  background: #0F4778 !important;\n  border-radius: 50%;\n  padding: 0px !important;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center; }\n  .QAHwA svg {\n    fill: #fff !important;\n    width: 12px !important;\n    height: 12px !important; }\n", ""]);

// Exports
exports.locals = {
	"filterWrapper": "_1FV5F",
	"filterIcon": "_2ELym",
	"dirty": "UVTIj",
	"clearBtn": "QAHwA"
};