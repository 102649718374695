export default function LOGIN_FAILED(state, { error }) {
  const newState = {};
  newState.loading = false;
  if (error && error.status === 400) {
    newState.errors = error.message.errors;
  } else {
    newState.error = 'Please check your internet connection and try again';
  }
  return { ...state, ...newState };
}
