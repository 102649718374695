import React, {Component} from "react";

export default class AutoGrowTextArea extends Component {

  componentDidUpdate(prevProps, prevState, prevContext) {
    this.resize();
  }

  refEl = (el) => {
    this.el = el;
    this.resize();
  }

  onChange =(e) => {
    this.props.onChange(e, e.currentTarget.value);
    this.resize();
  }

  resize() {
    if (!this.el) {
      return;
    }
    this.el.style.height = '30px';
    this.el.style.height = `${this.el.scrollHeight + 2}px`;
  }

  render(){
    return <textarea {...this.props} onChange={this.onChange} ref={this.refEl} />;
  }
}
