import ContentClear from "material-ui/svg-icons/content/clear";
import React from "react";
import styled from "styled-components";
const Round = styled.div`
  width: 24px;
  height: 24px;
  background: var(--bg-light-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  cursor: pointer;
  border-radius: 50%;
  svg {
    fill: var(--text-light) !important;
  }
`;

function RoundFilledCross(props) {
  const { className = "", onClick, ...rest } = props;

  return (
    <Round onClick={onClick} className={className}>
      <ContentClear {...rest} />
    </Round>
  );
}

export default RoundFilledCross;
