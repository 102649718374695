const ui = {
    Checkbox() {
        const checkbox = document.createElement('input');

        checkbox.type = 'checkbox';
        checkbox.id = 'remove-ports';

        checkbox.style.display = 'inlineBlock';
        checkbox.style.width = '15px';
        checkbox.style.height = '15px';
        checkbox.style.cursor = 'pointer';

        return checkbox;
    },
    Label() {
        const label = document.createElement('label');

        label.htmlFor = 'remove-ports';
        label.innerHTML = 'Remove ports';

        label.style.fontSize = '18px';
        label.style.marginRight = '10px';
        label.style.display = 'inlineBlock';
        label.style.cursor = 'pointer';

        return label;
    },
    ParentControl() {
        const controlDiv = document.createElement('div');

        controlDiv.type = 'checkbox';
        controlDiv.id = 'parent-block-remove-ports';

        controlDiv.style.backgroundColor = '#fff';
        controlDiv.style.width = '160px';
        controlDiv.style.height = '24px';
        controlDiv.style.marginBottom = '5px';
        controlDiv.style.padding = '10px';
        controlDiv.style.display = 'flex';
        controlDiv.style.justifyContent = 'center';
        controlDiv.style.alignItems = 'center';

        return controlDiv;
    },
    ButtonDelete(text = 'DELETE PORTS') {
    const button = document.createElement('button');

    button.style.backgroundColor = '#fff';
    button.style.fontSize = '16px';
    button.innerHTML = text;

    return button;
}
};

export default ui;