export default [
  {
    'featureType': 'all',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#6c747d',
      },
    ],
  },
  {
    'featureType': 'all',
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'visibility': 'on',
      },
      {
        'color': '#13263c',
      },
      {
        'weight': 2,
      },
      {
        'gamma': '1',
      },
    ],
  },
  {
    'featureType': 'all',
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off',
      },
    ],
  },
  {
    'featureType': 'administrative',
    'elementType': 'geometry',
    'stylers': [
      {
        'weight': 0.6,
      },
      {
        'color': '#223347',
      },
      {
        'gamma': '0',
      },
    ],
  },
  {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#324558',
      },
      {
        'weight': '10',
      },
      {
        'lightness': '-10',
      },
    ],
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#2f3f51',
      },
    ],
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#283b51',
      },
    ],
  },
  {
    'featureType': 'road',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#182b40',
      },
      {
        'lightness': '0',
      },
    ],
  },
  {
    'featureType': 'transit',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#304358',
      },
    ],
  },
  {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#26374c',
      },
      {
        'lightness': '-10',
      },
    ],
  },
]
