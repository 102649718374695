import React, { Component } from 'react'
import AddPositionDialog from '../Vessel/Positions/PositionDialog';
import RaisedButton from '../Common/RaisedButton';
export class AddPosition extends Component {

  state = {
    addPositionDialogOpened: false,
  }

  handleToggleAddPositionDialog = () => this.setState(state => ({ addPositionDialogOpened: !state.addPositionDialogOpened }))

  handleAddPosition = async position => {
    try {
      const res = await Promise.resolve(this.props.handleAddPosition(position));
      if (res?.status === 200) {
        this.handleToggleAddPositionDialog();
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {

    const { handleToggleAddPositionDialog, handleAddPosition } = this;
    const { baseUrl } = this.props;
    const { addPositionDialogOpened } = this.state;

    return (
      <div>
        {
          addPositionDialogOpened
            ? (
              <AddPositionDialog
                createLink={`${baseUrl}create`}
                handleClose={handleToggleAddPositionDialog}
                handleSendNewPosition={handleAddPosition}
              />
            )
            : null
        }
        <RaisedButton
          label="add position"
          primary={true}
          onClick={handleToggleAddPositionDialog}
          styles="xs"
          style={{ marginLeft: 8 }}
        />
      </div>
    )
  }
}

export default AddPosition
