import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormsySelect from './Select';
import MenuItem from 'material-ui/MenuItem';
import ActionPrint from 'material-ui/svg-icons/action/print';
import Dialog from './Dialog';
import NarrowSelect from '../NewInputs/NarrowSelect';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  info: {
    flexShrink: '0',
    cursor: 'pointer',
    marginLeft: '6px',
  },
  select: {
    maxWidth: 'calc(100% - 24px)',
  },
  separator: {
    padding: '0px 12px',
    margin: '0px 12px',
    color: 'var(--text-medium)',
    borderBottom: '1px solid var(--stroke-light-gray2)',
    pointerEvents: 'none',
    lineHeight: '35px',
    marginBottom: '4px',
  }
};
const dropDownMenuProps = { listStyle: {paddingTop: '8px'} };

class ContractSelect extends Component {
  /*shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !!(nextProps.previousValue && nextProps.previousValue!==this.props.previousValue);
  }*/

  static defaultProps = {
    Icon: ActionPrint,
    type: 'voyage',
    isBidding: true,
  }

  state = {pdfContracts: [], noPdfContracts: []};

  componentWillMount() {
    const contracts = this.context.contracts.filter(c => c.type === this.props.type);
    const pdfContracts = contracts.filter(c => !c.noPdf);
    const noPdfContracts = contracts.filter(c => c.noPdf && (!this.props.isBidding || !c.noBid));
    this.state.pdfContracts = pdfContracts;
    this.state.noPdfContracts = noPdfContracts;
  }


  ref = (input) => {
    if (!this.state.input) {
      setTimeout(() => {
        this.setState({ input });
      }, 0);
    }
    this.input = input;
  };
  getValue() {
    return this.input.getValue();
  }
  setValue(value) {
    this.props.onChange && this.onChange(null, value);
    this.setState({ _value: value });
    return this.input.setValue(value && (value._id || value));
  }
  getFullValue() {
    if (!this.input) {
      return;
    }
    const _id = this.input.getValue();
    return this.context.contracts.find(t => t._id === _id);
  }
  onChange = (e, _id) => {
    const obj = this.context.contracts.find(t => t._id === _id);
    this.setState({ _value: obj });
    setTimeout(() => {
      this.props.onChange && this.props.onChange(e, obj);
    }, 0);
  };
  openDetails = () => {
    this.setState({ dialogOpened: true });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  render() {
    const { onChange, Icon, ...props } = this.props;
    const selectedContract = this.getFullValue();
    const { pdfContracts, noPdfContracts } = this.state;
    return (
      <span style={styles.container}>
        <NarrowSelect
          innerRef={this.ref}
          onChange={this.onChange}
          style={styles.select}
          dropDownMenuProps={dropDownMenuProps}
          maxHeight={400}
          {...props}
        >
          <div style={styles.separator}>Auto PDF Contracts</div>
          {pdfContracts.map(contract => (
              <MenuItem
                key={contract._id}
                value={contract._id}
                primaryText={contract.name}
              />
            )
          )}
          <div style={styles.separator}>No PDF Contracts</div>
          {noPdfContracts.map(contract => (
              <MenuItem
                key={contract._id}
                value={contract._id}
                primaryText={contract.name}
              />
            )
          )}
        </NarrowSelect>
        {selectedContract && !selectedContract.noPdf ? <Icon style={{ ...styles.info, ...(selectedContract ? { fill: 'var(--main-color)' } : {}) }} onClick={this.openDetails} /> : null}
        {this.state.dialogOpened && selectedContract ?
          <Dialog
            title={selectedContract.name + ' sample copy'}
            open
            handleClose={this.handleClose}
            bodyStyle={{overflowY: 'hidden', padding: '0px'}}
          >
            <div style={{ height: 'calc(100vh - 205px)', width: '100%' }} >
              <object
                style={{ height: 'calc(100vh - 205px)' }} width="100%" type="application/pdf"
                data={`/sample_contracts/${selectedContract.name.replace(/ /g, '_')}.pdf?#zoom=85&scrollbar=0&toolbar=0&navpanes=0`} id="pdf_content"
              >
                <p>This browser does not support PDFs. Please download the PDF to view it:
                  <a download href={`/sample_contracts/${selectedContract.name.replace(/ /g, '_')}.pdf`}>Download PDF</a>.</p>
              </object>
            </div>
          </Dialog>
          : null}
      </span>
    );
  }
}

ContractSelect.propTypes = {
  type: PropTypes.string,
};
ContractSelect.contextTypes = {
  contracts: PropTypes.array,
  formsy: PropTypes.object,
};
export default ContractSelect;
