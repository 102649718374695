import { orderBy } from 'lodash';
import MONITOR_CARGO_NEW from './MONITOR_CARGO_NEW';
import {upsertAccount} from "../../actions/login";
//noinspection JSUnusedGlobalSymbols

const sortOrders = { 1: 'asc' };
sortOrders[-1] = 'desc';

/** @name MONITOR_CARGO_UPDATE_REDUCER
 * @param {Object} state
 * @param {MonitorCargo} payload
 * @param currentUser
 * @param options
 * */
export default function MONITOR_CARGO_UPDATE(state, { payload, currentUser, options = { upsert: false } }) {
  const newState = {};
  const index = state.cargoList.data.findIndex(c => c._id === payload._id);
  if (index === -1) {
    if (options.upsert === true) {
      return MONITOR_CARGO_NEW(state, { payload, currentUser });
    }
    return state;
  }
  const cargo = { ...state.cargoList.data[index] };
  cargo.status = payload.status;
  if (payload.weight !== undefined) {
    cargo.weight = payload.weight;
  }
  if (options.upsert) {
    Object.assign(cargo, payload);
  }
  newState.cargoList = { ...state.cargoList };
  newState.cargoList.data = [...state.cargoList.data];
  newState.cargoList.data[index] = cargo;
  newState.cargoList.data = orderBy(newState.cargoList.data, [newState.cargoList.sort.biddingStatus ? c => (c.weight || 1) : null, newState.cargoList.sort.field], ['desc', sortOrders[newState.cargoList.sort.value]]);
  return { ...state, ...newState };
}
