import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from './Home.scss';
import { confirmEmail, unsubscribe } from '../../actions/login';
import history from '../../core/history';
import Header from '../Header';
import Footer from '../Footer';
import { getLandingAction } from '../../actions/runtime';
import DialogForm from './About/DialogForm';
import { debounce } from 'formsy-material-ui/lib/utils';

import RequestDemo from './RequestDemo';

class PageGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: false,
      label: '',
      direction: '',
      lastScrollPos: 0,
      dialogOpened: false,
      handleClose: false,
      labelDialogForm: '',
      requestType: '',
    };
    this.visibilityState = {};
  }

  static propTypes = {
    confirmEmail: PropTypes.func,
    token: PropTypes.string,
    unsubscribeToken: PropTypes.string,
    unsubscribe: PropTypes.func,
    getLandingAction: PropTypes.func,
    landing: PropTypes.object,
    selectedAboutTab: PropTypes.string,
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  componentDidMount() {
    if (this.props.params?.token && this.props.params?.token.length > 20) {
      this.props.confirmEmail(this.props.params?.token, this.props.user).then((res) => {
        if (res && res.status === 200) {
          history.push('/settings/profile');
        } else {
          history.push('/');
        }
      });
    }
    if (this.props.params?.unsubscribeToken) {
      this.props.unsubscribe(this.props.params?.unsubscribeToken).then((res) => {
        history.push('/');
      });
    }
    window.addEventListener('scroll', this.handleScroll);
    if (!this.props.landing || !this.props.landing.actual || Date.now() - this.props.landing.actual > 1000 * 60 * 5) {
      this.props.getLandingAction();
    }
    if (this.props.hash){
      this.hash = this.props.hash;
      const scrollEl = document.getElementById(this.props.hash.replace('#', ''));
      if (scrollEl) {
        scrollEl.scrollIntoView({ block: 'start', inline: 'nearest' });
        history.replace({
          hash: '',
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    let scrollEl;
    if (this.props.hash) {
      this.hash = this.props.hash;
      const id = this.hash.replace('#', '');
      scrollEl = document.getElementById(id);

    } else if (this.props.path !== prevProps.path) {
      document.querySelector(`.${s.home}`).scrollTo(0,0);
      return;
    }
    if (scrollEl) {
      scrollEl.scrollIntoView({ block: 'start', inline: 'nearest' });
      history.replace({
        hash: '',
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };
  handleScroll = (e) => {
    const scrolling = e.currentTarget.scrollTop !== 0;
    if (this.state.scrolling !== scrolling) {
      this.setState({ scrolling });
    }
    if (e.currentTarget.scrollTop <= 0) {
      this.setState({
        direction: '',
        lastScrollPos: e.currentTarget.scrollTop,
      });
    } else if (this.state.lastScrollPos < e.currentTarget.scrollTop) {
      this.setState({
        direction: 'bottom',
        lastScrollPos: e.currentTarget.scrollTop,
      });
    } else if (this.state.lastScrollPos > e.currentTarget.scrollTop) {
      this.setState({
        direction: 'top',
        lastScrollPos: e.currentTarget.scrollTop,
      });
    }
  };

  handleChangeForm = (labelButton, requestType) => {
    this.setState(
      {
        dialogOpened: true,
        handleClose: true,
        label: labelButton,
        requestType,
      }
    );
  };
  handleCloseDialog = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeAnchor = (id, isVisible, i = 0) => {
    if (i > 10) {
      return;
    }
    this.visibilityState[id] = isVisible;
    let otherVisible;
    for( let key in this.visibilityState ) {
      if (!this.visibilityState.hasOwnProperty(key)) {
        continue;
      }
      if (this.visibilityState[key] && key !== id) {
        otherVisible = key;
      }
    }
    if (isVisible && otherVisible && this.hash === '#' + otherVisible) {
      return;
    }
    if (!isVisible && otherVisible) {
      return this.handleChangeAnchor(otherVisible, true, ++i);
    }
    if (isVisible && id !== 'top' && this.visibilityState['top']) {
      return;
    }
    if (id === 'top') {
      id = undefined;
    }
    if (isVisible) {
      this.changeHash(id);
    }
  };

  changeHash = debounce((id) => {
    const hash = '#' + id;
    if(hash === this.hash) {
      return;
    }
    this.hash = hash;
    history.push({
      hash: id,
    });
  }, 100);

  render() {
    const { statistics } = this.props.landing;
    return (
      <div
        className={s.home}
        onScroll={this.handleScroll}
        style={{
          height: this.props.user && !this.props.user.emailVerified ? 'calc(100vh - 50px)' : '100vh',
        }}
      >
        <Header
          scroll={this.state.direction}
          label={this.state.label}
          main
        />

        <div>
          { React.cloneElement(
            this.props.children,
            {
              handleChangeForm: this.handleChangeForm,
              handleCloseDialog: this.handleCloseDialog,
              statistics: statistics,
              user: this.props.user,
            },
          )}
        </div>
        <RequestDemo />
        <Footer />
        {this.state.dialogOpened ?
          <DialogForm
            handleCloseDialog={this.handleCloseDialog}
            dialogOpened={this.handleChangeForm}
            label={this.state.labelDialogForm}
            requestType={this.state.requestType}
          />
          : null
        }
      </div>
    );
  }
}


export default connect(state => ({ ...state.login, landing: state.runtime.landing }), { confirmEmail, unsubscribe, getLandingAction })(withStyles(s)(PageGeneral));
