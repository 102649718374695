import React from 'react';
import styled from 'styled-components';
import ChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';

const Head = styled.div`
  height: 100%;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background: var(--bg-light-grey);
  margin: 0px !important;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  & > * {
    margin-right: 8px;
  }
  & > span {
    margin-left: -4px !important;
  }
`;
const Back = styled.button`
  outline: none;
  border: none;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px;
  background: transparent;
  svg {
    height: 100% !important;
    width: 100% !important;
    color: var(--text-light) !important;
    transform: scale(1.3);
    margin-right: 4px !important;
  }
`;

const Title = styled.h5`
  color: var(--default-dark-text);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin: 0px;
  text-transform: uppercase;
  margin-left: 4px;
`;

const Container = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-right: 12px;
`;

function TableActionsHead({ selectedCount, handleClose, children, label = 'vessel', ...rest }) {
  const title = `${selectedCount} ${label}${selectedCount > 1 ? "s" : ""} selected`;
  return (
    <Head {...rest}>
      <Container onClick={handleClose}>
        <Back type="button" >
          <ChevronLeft />
        </Back>
        <Title>
          {
            title
          }
        </Title>
      </Container>
      {
        children
      }
    </Head>
  )
}

export default TableActionsHead
