import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormsyCheckbox from 'formsy-material-ui/lib/FormsyCheckbox';
import cx from 'classnames';
const defaultIconStyle = {}
class CheckBox extends Component {
  state = {};
  handleChange = (e, value) => {
    this.setState({ checked: value });
    this.props.onChange && this.props.onChange(e, value);
  };
  constructor(props) {
    super(props);
    if (this.props.defaultValue) {
      this.state.checked = true;
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.refs.input) {
        const value = this.refs.input.getValue();
        if (this.state.checked !== value) {
          this.setState({ checked: value });
        }
      }
    }, 0);
  }


  getValue() {
    return this.state.checked;
  }
  setValue(value) {
    return this.handleChange(null, value);
  }

  _setValue(value) {
    this.setState({ checked: value });
  }

  render() {
    let { className = '', defaultValue, ...rest } = this.props;
    className += (this.state.checked ? ' checkbox checked' : ' checkbox');
    if (this.props.previousValue !== undefined && this.state.checked !== this.props.previousValue) {
      className += ' dirty';
    }
    return (
      <FormsyCheckbox
        value={this.state.checked}
        className={cx("custom-checkbox", className)}
        {...rest}
        onChange={this.handleChange}
        ref="input"
      />
    );
  }
}

CheckBox.propTypes = {};
CheckBox.defaultProps = {};

export default CheckBox;
