import React, { Component } from 'react'
import Collapse from '../../Common/Collapse';
import Charges from './Charges';
import RoundBorderedPlus from './../../Common/RoundBorderedPlus';
import styled from 'styled-components';
import { number } from './../../../core/format';
import { currencyMap } from '../../Contracts/shared/Freight';
import ContentClear from 'material-ui/svg-icons/content/clear';

const Body = styled.div`
  padding: 18px 50px;
`;
const Title = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: var(--default-dark-text);
  text-transform: initial;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Details = styled(Title)`
  font-weight: 400;
  width: 90%;
  overflow: hidden;
  text-align: right;
  margin-right: ${props => props.index === 0 ? '18px' : '0'};
`;


export class Multicharge extends Component {
  // used to store references to collapses to manipulate open/close when there are errors
  _collapses = [];

  generatePrefix = (name, index) => `${name}[${index}]`;


  handleChange = index => chargeObj => {
    return this.props.onChange(index)(prevCharge => ({ ...prevCharge, ...chargeObj }));
  }

  handleAddAdditional = async () => {
    await this.props.onChange()(charges => ([...charges, this.constructDefaultCharge({ key: charges.length + 1 })]));
    this.closeAllCollapsesExceptLast(this._collapses);
  }

  handleDeleteCharge = index => () => {
    this.props.onChange()(charges => charges.filter((c, i) => i !== index));
  }

  constructDefaultCharge = options => ({
    type: '',
    value: '',
    currency: 'USD',
    term: '',
    details: '',
    key: 1,
    ...options,
  })

  defaultWrapper = el => el;

  renderCharges = (wrapper, charges) => {
    const { style, className, ...rest } = this.props;
    wrapper = wrapper || this.defaultWrapper;
    return charges.map(({ key, ...charge }, index) => wrapper(<Charges adaptHeightByValue={this.props.adaptHeightByValue} style={style} key={key} index={index} onChange={this.handleChange(index)} namePrefix={this.generatePrefix('charges', index)} charge={charge} />));
  }

  attachCollapseRefs = el => this._collapses.push(el);

  computeTitle = props => {
    const { charge = {} } = props;

    const { type = "", value, currency, term } = charge;

    if (!value || isNaN(+value)) return "Enter charge";

    const typePart = type ? `${String(type).toUpperCase()}: ` : '';
    const mainPart = `${number(` ${value} `, currencyMap[currency || 'USD'], term || '')}`;

    return `${typePart}${mainPart}`;
  }

  closeAllCollapsesExceptLast = (collapsesRefs = this._collapses) => Array.from(collapsesRefs || []).slice(0, -1).map(collapse => collapse?.hide?.());

  wrapToCollapse = el => {
    const { index, charge = {} } = el.props;

    const details = charge.details || "";
    return (
      <Collapse
        noBorder
        headerStyle={{ border: 'none', padding: '0px 12px' }}
        style={{ backgroundColor: "#F5F5F5" }}
        additionals={
          <div className="align-items-center" style={{ width: "70%", justifyContent: "flex-end", }}>
            {
              details && <Details index={index}>{details}</Details>
            }
            {
              index > 0 && (
                <ContentClear
                  style={{ width: 18, height: 18, color: 'var(--icon-default)' }}
                  onClick={this.handleDeleteCharge(index)}
                />
              )
            }
          </div>
        }
        innerRef={this.attachCollapseRefs}
        title={<Title>{this.computeTitle(el.props)}</Title>}
      >
        <Body>
          {el}
        </Body>
      </Collapse>
    )
  }

  openInvalidCollapses = () => {
    Array.from(this._collapses || []).forEach(collapse => collapse?.findInvalidInputs());
  };


  componentDidMount() {
    if (this.props.interceptHandleNext) {
      this.props.interceptHandleNext(this.openInvalidCollapses);
    }
    if (!this.props.charges?.length) {
      this.handleAddAdditional();
    }
  }

  isLastAdditionalBlockEmpty = ({ type, value } = {}) => !type || !value;

  render() {

    const { charges = [] } = this.props;

    const isMulti = charges.length > 1;

    const children = this.renderCharges(isMulti && this.wrapToCollapse, charges);

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          children
        }
        <RoundBorderedPlus disabled={this.isLastAdditionalBlockEmpty(charges[charges.length - 1])} onClick={this.handleAddAdditional} style={{ marginTop: 12 }} label="Add additional charges" />
      </div>
    )
  }
}

export default Multicharge;
