exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._33qJI {\n  position: relative;\n  width: 100%;\n  z-index: 999; }\n  ._33qJI .sGQm6 {\n    padding: 13px;\n    background: #FFFDD6;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: justify;\n        justify-content: space-between; }\n  @media (max-width: 600px) {\n      ._33qJI .sGQm6 {\n        -ms-flex-align: start;\n            align-items: flex-start; } }\n  ._33qJI .sGQm6 + .sGQm6 {\n    border-top: 1px solid white; }\n  ._33qJI ._3z80I {\n    cursor: pointer; }\n  ._33qJI ._1Iyjv {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    width: 100%; }\n", ""]);

// Exports
exports.locals = {
	"root": "_33qJI",
	"message": "sGQm6",
	"close": "_3z80I",
	"upgrade_subscription_message": "_1Iyjv"
};