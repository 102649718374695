import AbstractAdapter from './abstract';
import editPipeline from './editPipeline';
import previewPipeline from './previewPipeline';
import serverPipeline from './serverPipeline';

export const createPreviewAdapter = (
  pipeline = previewPipeline
) => new AbstractAdapter(pipeline);

export const createServerAdapter = (
  pipeline = serverPipeline
) => new AbstractAdapter(pipeline);

export const createEditAdaptert = (
  pipeline = editPipeline
) => new AbstractAdapter(pipeline);
