import React from 'react';
import _RaisedButton from 'material-ui/RaisedButton';
import cx from 'classnames';

const S = {
  xs: { height: 24, lineHeight: '21px', boxShadow: 'none', borderRadius: 50 },
  small: { height: 28, lineHeight: '27px', boxShadow: 'none', borderRadius: 0 },
  normal: { boxShadow: 'none', borderRadius: 50 },
};

const LS = {
  xs: { fontSize: '11px', paddingLeft: '12px', paddingRight: '12px' },
  small: { fontSize: '12px', paddingLeft: '13px', paddingRight: '13px' },
};

const buttonStyles = {
  xs: { borderRadius: '50px', lineHeight: 'inherit', fontSize: 'initial', minWidth: '68px' },
  small: { borderRadius: '50px', lineHeight: 'inherit' },
  normal: { borderRadius: '50px' },
}
export default function RaisedButton({ squared = false, style = {}, overlayStyle = {}, buttonStyle = {}, styles, className, ...rest }) {
  const s = { ...S[styles], ...style };
  const labelStyle = LS[styles];

  let btn = <_RaisedButton className={cx('raised-button', className)} overlayStyle={{...overlayStyle, borderRadius: '50px',}} rippleStyle={buttonStyles[styles]} buttonStyle={{ ...buttonStyles[styles], borderRadius: '50px', overflow: 'hidden', zIndex: 'unset', ...buttonStyle}} labelStyle={labelStyle} {...rest} style={{...s, borderRadius: '50px'}} />;

  if (squared) {
    btn = <_RaisedButton className={cx('raised-button', className)} overlayStyle={{...overlayStyle}} rippleStyle={buttonStyles[styles]} buttonStyle={{ ...buttonStyle, ...buttonStyles[styles]}} {...rest} style={{...s}} />;
  }
  return btn;
}
RaisedButton.defaultProps = {
  styles: 'normal',
};

const blueButtonProps = { backgroundColor: 'var(--text-links)', disabledLabelColor: 'rgba(255,255,255,0.7)', disabledBackgroundColor: 'rgba(67, 128, 199, 0.5)', labelColor: 'white' };
export function RaisedButtonBlue(props) {
  return <RaisedButton {...blueButtonProps} {...props} />;
}
