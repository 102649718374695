import saveFiltersState from './saveFiltersState';
//noinspection JSUnusedGlobalSymbols
/** @name MONITOR_CARGO_LIST_REDUCER */
export default function MONITOR_CARGO_LIST(state, { res, payload }) {
  const newCargoList = { ...state.cargoList, loading: false };

  if (res.page === 1) {
    newCargoList.data = res.data;
  } else {
    newCargoList.data = [...newCargoList.data, ...res.data];
  }

  if (payload) {
    if (payload.filters) {
      // res.filters.loadingPort = res.filters.loadingPortInfo;
      // res.filters.unloadingPort = res.filters.unloadingPortInfo;
      const filters = { ...payload.filters, ...res.filters, channels: payload.filters.channels, tags: payload.filters.tags };
      if (filters.weightMin) {
        filters.weightMin /= 1000;
      }
      if (filters.weightMax) {
        filters.weightMax /= 1000;
      }
      newCargoList.filters = filters;
    }
  }
  newCargoList.page = res.page;
  newCargoList.total = res.total;
  newCargoList.next = res.next;
  newCargoList.sort = payload.sort;
  newCargoList.activeRequests = res.activeRequests;
  const newState = { cargoList: newCargoList };
  saveFiltersState({ ...state, ...newState });
  return { ...state, ...newState };
}
