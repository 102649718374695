const globalCacheStore = {};
export default class TtlMap {
  store = new Map();
  constructor(ttlOffset = 1000, ttlSelector = () => Date.now()) {
    this.ttlOffset = ttlOffset;
    this.ttlSelector = ttlSelector;
  }

  get(key) {
    const item = this.store.get(key);
    if (item) {
      return item.data;
    }
  }

  delete(key) {
    this.store.delete(key);
  }

  set(key, data) {
      this.store.set(key, { data: data, ttl: this.ttlSelector(data) + this.ttlOffset });
  }

  clearExpired() {
    const now = Date.now();
    for (const entry of this.store.entries()) {
      if (entry[1].ttl < now) {
        this.store.delete(entry[0]);
      }
    }
  }
}

export function getOrCreateTtlMap(name, ttlOffset, ttlSelector) {
  if (!globalCacheStore[name]) {
    globalCacheStore[name] = new TtlMap(ttlOffset, ttlSelector);
  }
  return globalCacheStore[name];
}
