import * as constants from '../constants/contacts';
import ContactBookApi from '../core/api/ContactBookApi';
import {CONTACTS_SELECT_CONTACT} from "../constants/contacts";


export function getContactList(payload: { filters: any, page: number}) {
  return {
    type: constants.CONTACTS_GET_LIST,
    promise: ContactBookApi.getContactList(payload),
    payload,
    takeLatest: true,
  };
}


export function checkContact(id, checked) {
  return {
    type: constants.CONTACTS_SELECT_CONTACT,
    payload: { id, selected: checked },
  };
}

export function checkAll(checked) {
  return {
    type: constants.CONTACTS_SELECT_CONTACT,
    payload: { selected: checked },
  };
}

export function getContactDetails(id, silent = false) {
  return (dispatch, getState) => {
    const { user } = getState().login;
    return dispatch({
      type: constants.CONTACTS_GET_CONTACT,
      promise: ContactBookApi.getContact(id, user),
      payload: { id, silent },
      takeLatest: true,
    })
  }
}

export function createContact(contact) {

  return async (dispatch, getState) => {
    const res = await dispatch({
      type: constants.CONTACTS_CREATE_CONTACT,
      promise: ContactBookApi.createContact(contact),
      payload: { contact },
    });
    dispatch(getCounters());
    return res;
  };
}

export function updateContact(contact) {
  return async (dispatch, getState) => {
    const res = await dispatch({
      type: constants.CONTACTS_UPDATE_CONTACT,
      promise: ContactBookApi.updateContact(contact),
      payload: { contact },
    });
    dispatch(getCounters());
    return res;
  };
}

export function getCompanyList(payload: { filters: any, page: number}) {
  return {
    type: constants.CONTACTS_GET_COMPANIES_LIST,
    promise: ContactBookApi.getCompanyList(payload),
    payload,
    takeLatest: true,
  };
}

export function getCompanyDetails(id, silent = false) {
  return (dispatch, getState) => {
    const { user } = getState().login;
    return dispatch({
      type: constants.CONTACTS_GET_COMPANY,
      promise: ContactBookApi.getCompany(id, user),
      payload: { id, silent },
      takeLatest: true,
    })
  }
}

export function createCompany(company) {
  return async (dispatch, getState) => {
    const res = await dispatch({
      type: constants.CONTACTS_CREATE_COMPANY,
      promise: ContactBookApi.createCompany(company),
      payload: { company },
    });
    const state = getState();
    dispatch(getCounters());
    return res;
  };
}

export function updateCompany(company) {
  return async (dispatch, getState) => {
   const res = await dispatch({
      type: constants.CONTACTS_UPDATE_COMPANY,
      promise: ContactBookApi.updateCompany(company),
      payload: { company },
    });
    return res;
  };
}

export function getGroupDetails(id, silent = false) {
  return (dispatch, getState) => {
    const { user } = getState().login;
    return dispatch({
      type: constants.CONTACTS_GET_GROUP,
      promise: ContactBookApi.getGroup(id, user),
      payload: { id, silent },
      takeLatest: true,
    })
  }
}

export function createGroup(company) {
  return async (dispatch, getState) => {
   const res = await dispatch({
      type: constants.CONTACTS_CREATE_GROUP,
      promise: ContactBookApi.createGroup(company),
      payload: { company },
    });
    dispatch(getCounters());
    return res;
  };
}


async function updateAndGetGroup(group, oldGroup = group) {
  const deletedCompanies = (oldGroup.companies?.filter(oc => group.companyIds.indexOf(oc.id) === -1)) || [];
  const updateRes = await ContactBookApi.updateGroup(group);
    if (updateRes.status > 399) {
      return updateRes;
    }
  const companiesToElevate = [];
  for (let i = 0; i < deletedCompanies.length; i++) {
    const deletedCompany = (await ContactBookApi.getCompany(deletedCompanies[i].id)).data;
    if (!deletedCompany.groups.length){
      companiesToElevate.push(deletedCompany);
    }
  }
  return ContactBookApi.getGroup(updateRes.data.id).then(res => {
    if (res.data) {
      res.data.companiesToElevate = companiesToElevate;
    }
    return res;
  });
}

export function updateGroup(company, oldGroup) {
  return async (dispatch, getState) => {
   const res = await dispatch({
      type: constants.CONTACTS_UPDATE_GROUP,
      promise: updateAndGetGroup(company, oldGroup),
      payload: { group: company, oldGroup },
    });
    dispatch(getCounters());
    return res;
  };
}

export function expandGroup(companyId) {
  return {
    type: constants.CONTACTS_EXPAND_GROUP,
    payload: { companyId },
  };
}

export function deleteContacts(contactIds) {
  return async (dispatch, getState) => {
    const res = await dispatch({
      type: constants.CONTACTS_DELETE_CONTACT,
      payload: { contactIds },
      promise: ContactBookApi.deleteContacts(contactIds),
    });
    dispatch(getCounters());
    return res;
  };
}

export function deleteCompanies(contactIds) {
  return {
    type: constants.CONTACTS_DELETE_COMPANY,
    payload: { contactIds },
    promise: ContactBookApi.deleteContacts(contactIds),
  };
}

export function toggleFavorites(contacts) {
  const faved = contacts.some(ct => !ct.faved);
  const ids = contacts.map(ct => ct.id);
  return {
    type: constants.CONTACTS_TOGGLE_FAVORITES,
    payload: { ids, faved },
    promise: faved ? ContactBookApi.addToFavorites(ids) : ContactBookApi.removeFromFavorites(ids),
  };
}

export function mergeContacts(reqBody) {
  return async (dispatch, getState) => {
    const res = await dispatch({
      type: constants.CONTACTS_MERGE_CONTACTS,
      payload: reqBody,
      promise: ContactBookApi.mergeContacts(reqBody),
    });
    dispatch(getCounters());
    return res;
  };
}

export function getCounters() {
  return {
    type: constants.CONTACTS_GET_COUNTERS,
    payload: {},
    promise: ContactBookApi.getCounters(),
  };
}
export function getHeatmap() {
  return {
    type: constants.CONTACTS_GET_HEATMAP,
    payload: {},
    promise: ContactBookApi.getHeatmap(),
  };
}


