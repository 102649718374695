import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './styles/Dashboard.scss';
import cx from 'classnames';
import PortList from './List/PortList';

import List from './List';
import Map from '../../Regions/Map';

import api from '../../../core/api/Port';
import Loader from '../../Common/Loader';
let allPorts;
let allRegions;
class Dashboard extends Component {
  static contextTypes = {

  };

  static propTypes = {

  };

  static defaultProps = {

  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRegion: undefined,
      selectedPort: undefined,
      allRegions: allRegions || [],
      allPorts: allPorts || [],
    };
  }

  async componentDidMount() {
    if (!this.state.allPorts || !this.state.allPorts.length) {
      api.getAllPortsWithCoordinates().then((res) => {
        if (res.status === 200) {
          allPorts = res.data;
          this.setState({
            allPorts: res.data,
          });
        }
      });
    }
    if (!this.state.allRegions || !this.state.allRegions.length) {
      api.getAllUserAreas().then((res) => {
        if (res.status === 200) {
          this.setState({
            allRegions: res.data,
          });
        }
      });
    }
  }

  changeParentState = (name, value, cb) => {
    this.setState({
      [name]: value,
    }, () => {
      cb && cb();
    });
  };

  render() {
    const { selectedRegion, allPorts, allRegions } = this.state;
    const { toggleDialog, updatePort, name, isExcluded } = this.props;

    return (
      <div>
        <div className={cx(s.header)}>
          <div className={cx(s.title_region)} >
            Region
          </div>
          <a href="/regions" target="_blank" className={s.edit_region}>
            Edit regions
          </a>
        </div>

        <div className={cx(s.wrapper_choose_region)}>
          <List
            changeParentState={this.changeParentState}
            selectedRegion={selectedRegion}
            toggleDialog={toggleDialog}
            updatePort={updatePort}
            allRegions={allRegions}
            allPorts={allPorts}
            name={name}
            isExcluded={isExcluded}
          />
          <div className={cx(s.search_port, selectedRegion ? s.showPorts : null)}>
            <PortList
              selectedRegion={selectedRegion}
              allRegions={allRegions}
              allPorts={allPorts}
            />
          </div>
          <div className={cx(s.map, this.state.selectedRegion ? s.squeeze : null)}>
            {allRegions.length && allPorts.length ?
              <Map
                selectedRegion={selectedRegion}
                allRegions={allRegions}
                allPorts={allPorts}
                changeParentState={this.changeParentState}
              />
              : <Loader />}
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(s)(Dashboard);
