import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import s from './Subscription.scss';
import Link from '../Link';
import Dialog from '../Common/Dialog';
import RaisedButton from '../Common/RaisedButton';
import PlanDetailsFull from './PlanDetailsFull';
import plansDescription from './plans';
import { number } from '../../core/format';
import { User } from '../../core/api/api';
import { redirectToCheckout } from '../../core/utils';
import SpinnerButton from '../Common/SpinnerButton';
import waitForEvent from '../../core/waitForEvent';
import { toggleDialogMessage } from '../../actions/runtime';


function PayButton(props: { onClick: () => Promise<void> }) {
  return <SpinnerButton
    style={{
      display: 'block',
      minWidth: '112px',
      maxWidth: '112px',
      height: '32px',
    }}
    buttonStyle={{
      borderRadius: '50px',
    }}
    labelStyle={{
      display: 'inline-block',
      padding: '0',
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '32px',
    }}
    primary
    label={'Pay now'}
    onClick={props.onClick}
  />
}

class UpgradePlanDialog extends PureComponent {
  state = {

  };
  static propTypes = {
    handleClose: PropTypes.func,
    toggleDialogMessage: PropTypes.func,
  };



  constructor(props) {
    super(props);
    this.state.recommendedPlan = this.findRecommendedPlan();
    this.state.currentPlan = User.getPlanByProducts(this.props.user.activeProducts);
  }

  async componentDidMount() {
    this.setState({currentPlan: (await User.getCurrentPlan(this.props.user))});
  }



  findRecommendedPlan = (billingCycle = 'year') => {
    const recommendedStripePlan = this.props.plans[billingCycle].find( p => p.isRecommended);
    const recommendedPlan = { ...plansDescription[recommendedStripePlan.key]};
    recommendedPlan.stripePlan = recommendedStripePlan;
    return recommendedPlan;
  };



  handleCancel = () => {
    return this.props.handleClose(false);
  };

  handlePay = async () => {
    try {
      const res = await User.changeSubscription(this.state.recommendedPlan.stripePlan.id);
      if (res.status === 200) {
        if (res.data.redirectToPayment) {
          await redirectToCheckout(res.data);
        } else {
          await waitForEvent('user:updateProfile', 3000);
          window.location.reload();
        }
      } else {
        throw res;
      }
    } catch (e) {
      console.error(e);
      this.props.toggleDialogMessage({ level: 'error', message: e.message?.errors?.[0]?.messages?.[0] || 'Sorry error occurred. please try again later.', dismissAble: false});
    }
  };

  handleChangeBillingCycle=(e,isYear)=> {
    this.setState({recommendedPlan: this.findRecommendedPlan(isYear? 'year' : 'month')});
  };

  render() {
    const { currentPlan } = this.state;
    const styleMonth = { color: this.state.Toggled ? '#ffffff' : 'rgba(255,255,255, 0.5)' };
    const styleYear = { color: this.state.Toggled ? 'rgba(255,255,255, 0.5)' : '#ffffff' }; //TODO e.fedorov move to styles
    return <Dialog
      bodyClassName={s.bodyPopupChangePlan}
      contentClassName={s.contentPopupChangePlan}
      open handleClose={this.props.handleClose}
    >
      <div>
        <h2 className={s.title_warning}>To access the feature you need a paid subscription</h2>
        <p className={s.description_warning}>Your current plan</p>
        <PlanDetailsFull plan={currentPlan} defaultClosedFeatures/>
        <div className={s.description_warning_chosen}>
          <p>Recommended plan</p>
          <Link onClick={this.handleCancel} to={'/pricing'} className={s.link_view_all_plans}>View all plans</Link>
        </div>

        <PlanDetailsFull plan={this.state.recommendedPlan} defaultClosedFeatures cycleToggleEnabled
                         onBillingCycleToggled={this.handleChangeBillingCycle}/>
        <div className={cx(s.wrapper_links_popup, s.wrapper_links_popup_change_subscription)}>
          <div className={s.left_part_links}>
            <Link onClick={this.handleCancel} to={'/legal'}>Terms&Conditions</Link>
            <Link onClick={this.handleCancel} to={'/privacy'}>Privacy policy</Link>
            <a href='mailto:support@shipnext.com' className={s.link_support}>Contact us to pay by invoice</a>
          </div>
          <div className={s.right_part_links}>
            <div>
              <div className={s.total_sum}>Total: {number(this.state.recommendedPlan.stripePlan.amount * 0.01)}$</div>
              <a href='mailto:support@shipnext.com' className={s.link_support}>Pay by invoice</a>
            </div>

            <PayButton onClick={this.handlePay}/>
          </div>
        </div>
      </div>
    </Dialog>
  }
}

const actionProps={toggleDialogMessage};

export default withStyles(s)(connect(state => ({user: state.login.user, plans: state.runtime.plans }), actionProps)(UpgradePlanDialog))
