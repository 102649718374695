import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './ComponentsIco.scss';
import PortsIcon from '../../Icons/Ports';
import DistanceIcon from '../../Icons/Distance';
import ShipsIcon from '../../Icons/Ships';
import DbsIcon from '../../Icons/DBS';
import ClientsIcon from '../../Icons/Clients';
import CargoStatIcon from '../../Icons/CargoStat';
import { dateTimeLocal, dateStringWithTime } from '../../../core/format';
import Counter from '../../Common/Counter';

class ShipnextToday extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const statistics = this.props.statistics;
    const distanceArray = statistics.distances.split(' ');
    const distanceDescrip = distanceArray.slice(1).join(' ');
    return (
      <div className={s.root}>
        <div className={cx(s.shipnext_today_ico, s.container_home)}>
          <div className={s.wrapper_container_main_page}>
            <div>
              <h2 className={s.title}>SHIPNEXT today</h2>
              <div className={s.title_description}>
                <p>
                  SHIPNEXT is a Digital Shipping Marketplace and Transportation Platform, that provides instant data and e-mail processing, instant Cargo-to-Ship matching, freight trading and contract management, digital documentation flow, freight finance and supply chain management solutions.
                </p>
              </div>
            </div>
            <div className={s.wrapper_shipnext_today}>
              <div className={s.list_shipnext_today}>
                <div>
                  <p>Instant Cargo to Ship Matching</p>
                  <p>Instant automatic e-mail processing</p>
                  <p>Open and Closed Freight Tendering</p>
                  <p>Reverse Auctioning</p>
                </div>
                <div>
                  <p>SHIPNEXT Freight Index</p>
                  <p>Freight Negotiations</p>
                  <p>Cargo and Ship Tracking</p>
                  <p>Stowage and Freight Estimations</p>
                </div>
                <div>
                  <p>Online Shipping Marketplace</p>
                  <p>Contract Management & Transactions</p>
                  <p>Electronic Bill of Lading & Smart Contract</p>
                  <p>Online Chat and Shipping Network</p>
                </div>
              </div>

              <article>
                <div className={s.figures_info_shipnext_today}>
                  <div className={s.shipnext_today_description_part}>
                    <PortsIcon />
                    <div className={s.shipnext_today_text_part}>
                      <p className={s.title_ship_today_part}>Ports</p>
                      <div className={s.wrapper_figures}>
                        <span className={cx(s.figures, s.figures_number)}>
                          <Counter endValue={statistics.ports} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={s.shipnext_today_description_part}>
                    <DistanceIcon />
                    <div className={s.shipnext_today_text_part}>
                      <p className={s.title_ship_today_part}>Distance</p>
                      <div className={s.wrapper_figures}>
                        <span className={cx(s.figures, s.figures_number)}>
                          <Counter endValue={distanceArray[0]} />
                        </span>
                        <p>
                          <span>{distanceDescrip}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={s.shipnext_today_description_part}>
                    <ShipsIcon />
                    <div className={s.shipnext_today_text_part}>
                      <p className={s.title_ship_today_part}>DAILY SHIP'S POSITIONS</p>
                      <div className={s.wrapper_figures}>
                        <span className={cx(s.figures, s.figures_number)}>
                          <Counter endValue={statistics.vessels} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={s.shipnext_today_description_part}>
                    <DbsIcon />
                    <div className={s.shipnext_today_text_part}>
                      <p className={s.title_ship_today_part}>Data Bases</p>
                      <div className={s.wrapper_figures}>
                        <span className={cx(s.figures, s.figures_number)}>
                          {statistics.dbs}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={s.shipnext_today_description_part}>
                    <ClientsIcon />
                    <div className={s.shipnext_today_text_part}>
                      <p className={s.title_ship_today_part}>Clients</p>
                      <div className={s.wrapper_figures}>
                        <span className={cx(s.figures, s.figures_number)}>
                          <Counter endValue={statistics.users} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={s.shipnext_today_description_part}>
                    <CargoStatIcon />
                    <div className={s.shipnext_today_text_part}>
                      <p className={s.title_ship_today_part}>DAILY CARGO &amp; FREIGHT REQUESTS</p>
                      <div className={s.wrapper_figures}>
                        <span className={cx(s.figures, s.figures_number)}>
                          <Counter endValue={statistics.cargoes} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className={s.actual_date}>
                Actual data at
                <span> {dateStringWithTime(new Date())} </span>
              </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(ShipnextToday));
