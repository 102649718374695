import { updateVesselChanges } from '../vessel/FLEET_VESSEL_CHANGES_SEEN';

export default function FLEET_VESSEL_CHANGES_SEEN(state, { payload: { id, changes } }) {
  const { data } = state;
  const newChanges = {
    ...changes,
    lastSeenVersion: changes.lastDocVersion,
  };
  const newData = updateVesselChanges(data, id, newChanges);
  return { ...state, data: newData };
}
