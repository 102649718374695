import { filterVesselList } from './filter';
//noinspection JSUnusedGlobalSymbols

/** @name MY_VESSEL_LIST_REDUCER */
export default function MY_VESSEL_LIST(state, { res, payload }) {
  const newMyVesselList = { ...state.vesselList };

  try {
    res.data = res.data.map((v, i) => {
      const vessel = state.vesselList.data.find(item => item._id === v._id);

      let search = '';
      search += v.imoNumber + ' ';
      search += v.name + ' ';
      search += v.description + ' ';
      v.search = search;
      v.selected = vessel ? vessel.selected : false;
      return v;
    });

    newMyVesselList.data = res.data;
    newMyVesselList.page = res.page;
    newMyVesselList.total = res.total;
    newMyVesselList.sort = payload.sort || state.vesselList.sort;
    newMyVesselList.filters = payload.filters || state.vesselList.filters;
    newMyVesselList.next = res.next;
    newMyVesselList.loading = false;
  } catch (error) {
    console.log(error);
  }
  const newState = { vesselList: filterVesselList(newMyVesselList) };
  return { ...state, ...newState };
}
