import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import s from './VesselLink.scss';
import VesselPreviewDialog from './VesselPreviewDialog';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class VesselLink extends PureComponent {
  static propTypes = {
    vessel: PropTypes.object,
  };
  state = { open: false };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    if(!this.props.vessel) {
      return null;
    }
    return (
      <span>
        <a
          onClick={this.handleOpen}
          className={s.vessel_link}
        >{`${this.props.vessel.name} ${this.props.vessel.imoNumber ? '(' + this.props.vessel.imoNumber + ')' : ''}`}</a>
        {this.state.open
          ? <VesselPreviewDialog
              vessel={this.props.vessel}
              handleClose={this.handleClose}
            />
          : null}
      </span>
    );
  }
}

export default withStyles(s)(VesselLink);
