import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Filter.scss';
import cx from 'classnames';
import EarthIcon from 'material-ui/svg-icons/action/language';

class ButtonForViewRegion extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.openTimer = null;
    this.state = { open: false }
  }

  toggleTooltip = () => {
    this.setState({
      open: !this.state.open
    })
  };

  openTooltip = () => {
    clearTimeout(this.timeout);
    this.openTimer = setTimeout(() => {
      this.setState({
        open: true,
      });
    }, 200);
  };

  closeTooltip = (force) => {
    if (this.openTimer) {
      clearTimeout(this.openTimer);
      this.openTimer = null;
    }
    if (force) {
      return this.setState({
        open: false,
      });
    }

    this.timeout = setTimeout(() => {
      this.setState({
        open: false
      });
    }, 500);
  };

  render() {
    const { handleToggleDialog, positionInCargo, disabled } = this.props;
    const { open } = this.state;

    return (
      <div className={cx(s.wrapper_icon, s.icon, positionInCargo ? s.position_in_cargo : '', disabled ? s.disabled : null)}>
        <div
          className={cx(s.btn_open_region)}
          onClick={handleToggleDialog}
          onMouseEnter={this.openTooltip}
          onMouseLeave={() => {
            this.closeTooltip(false);
          }}
          style={{
            display:'inline-block',
            width: '22px',
            height: '22px',
            background: 'none',
            border: 'none',
            outline: 'none',
            cursor: 'pointer'
          }}
        >
          <EarthIcon
            className={s.tooltip_on_hover}
            style={{
              width: '22px',
              height: '22px'
            }}
          />
        </div>
        <div
          onMouseEnter={this.openTooltip}
          onMouseLeave={() => {
            this.closeTooltip();
          }}
          className={s.tooltip_map}
          style={{
            display: open ? 'flex' : 'none'
          }}
        >
          <span className={s.text_tooltip}>View regions on the map</span>
        </div>
      </div>

    )
  }
}

export default withStyles(s)(ButtonForViewRegion);
