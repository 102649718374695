import produce from "immer";
import orderBy from "lodash/orderBy";

/** @name CONTACTS_CREATE_GROUP_REDUCER */
 export default function CONTACTS_CREATE_GROUP(state, { res }) {
  const newContact = res.data;
  newContact.type = 'group';
  return produce(state, draft => {
    draft.companyList.data = [newContact, ...draft.companyList.data];
    if (newContact.companies) {
      newContact.companies.forEach((c)=> {
        draft.companyList.data = draft.companyList.data.filter(cc => cc.id !== c.id); //remove companies from root
      });
    }
    draft.companyList = sortContacts(draft.companyList);
  });
}

const sortOrders = { 1: 'asc' };
sortOrders[-1] = 'desc';
const sortFieldMap = {
  name: 'name',
  'date': 'createdAt',
};

export function sortContacts(contactList){
  const  { sort } = contactList;
  const keys = [(c) => c.approved, (c) => c.duplicateIds?.length > 0];
  const orders = ['asc', 'desc'];

  if (sort.favedFirst) {
    keys.push('faved');
    orders.push('desc');
  }

  if (sort.field === 'name') {
    keys.push('name', 'surname');
    orders.push(sortOrders[sort.value], sortOrders[sort.value]);
  } else {
    keys.push(sortFieldMap[sort.field]);
    orders.push(sortOrders[sort.value]);
  }

   contactList.data = orderBy(contactList.data, keys, orders);
   return contactList;
}
