import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import Form from '../About/Form';
import Link from '../../Link';
import AppDownload from '../../Common/AppDownload';
import ImageGallery from 'react-image-gallery';
import Media from 'react-media';
import RoundThemableButton from '../../Common/RoundThemableButton';
import TradingDesk from '../../Icons/TradingDesk';
import Exchange from '../../Icons/Exchange';
import FleetMonitor from '../../Icons/FleetMonitor';
import CargoMonitor from '../../Icons/CargoMonitor';
import Documents from '../../Icons/Documents';
import PortsIcon from '../../Icons/PortsIcon';

const images = [
  {
    original: '/images/pages/screen1_mob.png',
    label: 'Cargo',
    index: 0,
  },
  {
    original: '/images/pages/screen2_mob.png',
    label: 'Ship',
    index: 1,
  },
  {
    original: '/images/pages/screen3_mob.png',
    label: 'Freight',
    index: 2,
  },
];

class PageSolutionsMarketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeOfApp: 'desktop',
      activeSolution: images[0],
    };
  }
  toggleTypeOfApp = (name) => {
    this.setState({ typeOfApp: name });
  };
  handleSlide = (index) => {
    this.setState({ activeSolution: images[index] });
  };
  refSlider = (el) => {
    this.slider = el;
  };
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_marketplace_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Process Shipping Data<br /> for instant Freight search,<br /> Trade and Management</h1>
                </div>

                <div className={s.wrapper_icon}>
                  <TradingDesk />
                  <p>SHIPNEXT Trading Desk</p>
                </div>
                <div className={s.wrapper_links}>
                  <a href="#how-it-works" className={s.wrapper_links_link}>How it works</a>
                  <a href="#use-case" className={s.wrapper_links_link}>Use Case</a>
                  {!this.props.user && <RoundThemableButton className={s.wrapper_links_link_color} component={Link} to={'/register'}>Register</RoundThemableButton>}
                </div>
              </div>
              <div className={s.right_part}>
                <Form
                  label={'Request demo'}
                  requestType={'shipnextDemo'}
                />
                <button
                  onClick={this.props.handleChangeForm.bind(this, 'Request demo', 'shipnextDemo')}
                >
                  Request demo
                </button>
              </div>
            </div>

          </div>
        </div>

        <span className={s.anchor} id="how-it-works" />
        <div className={cx(s.how_it_works, s.how_it_works_td)}>
          <div className={s.container}>
            <h2 className={s.title_block}>How it works</h2>
          </div>
          <div className={s.first_step}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>1</div>
                <Media query="(max-width: 600px)" defaultMatches={false}>
                  {matches =>
                    matches ? (
                      <div className={s.wrapper_first_step_mob}>
                        <div className={s.download_free_app}>
                          <p className={s.title}>Download free app</p>
                          <AppDownload />
                        </div>
                        <div className="wrapper_slider">
                            <ImageGallery
                              showNav={false}
                              useBrowserFullscreen={false}
                              showFullscreenButton={false}
                              showPlayButton={false}
                              showThumbnails={false}
                              items={images}
                              onSlide={this.handleSlide}
                              ref={this.refSlider}
                              showBullets={true}
                            />
                          </div>

                      </div>
                    ) : (
                      <div className={s.right_part}>
                        <div className={s.wrapper_info}>
                          <div className={s.info_left}>
                            <h3>What’s a Trading Desk</h3>
                            <p className={s.description}>
                              The Trading Desk is the best way to set up your work environment if you are a Shipbroker, Carrier, or Trader. The split-screen design allows for setting up a personalized environment with Cargo and Fleet-related data. This could include open market data, or proprietary data from emails (if email flow is connected) and data from other interconnected sources (ERP, platforms, operational software, etc).
                            </p>
                            <p className={s.description}>
                              Each user is free to choose whether his Cargo or Fleet positions are shared with the Market (other SHIPNEXT users), shared with companies on their Whitelist, or stored privately.
                            </p>
                          </div>

                          <div className={s.info_right}>
                          <div className={s.tabs_app}>
                            <div className={s.wrapper_button}>
                              <span
                                className={this.state.typeOfApp === 'desktop' && s.active}
                                onClick={this.toggleTypeOfApp.bind(this, 'desktop')}
                              >
                                Desktop
                              </span>
                              <span
                                className={this.state.typeOfApp === 'mobile' && s.active}
                                onClick={this.toggleTypeOfApp.bind(this, 'mobile')}
                              >
                                Mobile app
                              </span>
                            </div>
                          </div>
                          {this.state.typeOfApp === 'desktop' &&
                          <div className={s.wrapper_image}>
                            <img src="/images/solutions/td_step_1.png" alt="Trading Desk desktop" />
                          </div>
                          }
                          {this.state.typeOfApp === 'mobile' &&
                          <div className={s.wrapper_image_mobile}>
                            <img src="/images/pages/screen-marketplace-mobile.png" alt="Main Deck mobile" />
                            <div className={s.download_free_app}>
                              <AppDownload />
                            </div>
                          </div>
                          }
                          </div>
                        </div>
                      </div>
                    )
                  }
                </Media>
              </div>
            </div>
          </div>

          <div className={cx(s.second_step, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>2</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Real-time data search</h3>
                      <p className={s.description}>
                      We help users a better insight into their proprietary and market data with the help of AI-based technology. By connecting email- and other electronic data-flows containing Cargo data, Freight requests, Open Fleet positions, S&P Fleet, our users can get their data processed and structured in seconds.
                      </p>
                      <p className={s.description}>
                      By connecting more and better data sources, our users adapt their Trading Desk to match their own trade and get better and more professional use of SHIPNEXT.<br />
                      The Trading Desk helps search and negotiate freight, search cargo, search fleet, and search freight- and hire indexes.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/td_step_2.png" alt="Real-time data search" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.third_step, s.divide_figure)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>3</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Freight tendering and Freight-search</h3>
                      <p className={s.description}>
                      SHIPNEXT is designed to facilitate instant freight search by building interconnectivity with Carriers. However, SHIPNEXT also facilitates its users to get a freight quote and freight solutions from Brokers, Carriers and Forwarders.
                      </p>
                      <p className={s.description}>
                      Our users may also choose to hold freight tenders, and this may include integrating their customized contract forms, e-tendering procedures, terms and conditions.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/td_step_3.png" alt="Freight tendering and Freight-search" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.fourth_step, s.container_solutions, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>4</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Freight and Hire indexes</h3>
                      <p className={s.description}>
                        As a solution for shipping professionals and shipbrokers, SHIPNEXT helps accumulate and store personal data related to freight and time-charter indexes, as well as get access to anonymized market data.
                      </p>
                      <p className={s.description}>
                        The data on Freight ideas, hire ideas, freight offers, and other commercial data can be used privately or shared with the team if required. This facilitates a better, faster, and more productive collaboration inside one company, or between a group of companies.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/td_step_4.png" alt="Freight and Hire indexes" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.fifth_step, s.container_solutions, s.divide_figure)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>5</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Building up Collaboration, Tagging and Chat</h3>
                      <p className={s.description}>
                        Regardless of whether it’s used as a personal chartering tool or a platform to facilitate collaboration between brokers, SHIPNEXT provides advanced features to work with data. This includes tagging, commenting, data entry, and data storage.
                      </p>
                      <p className={s.description}>
                        In addition, SHIPNEXT also allows users to choose whether to communicate with their customers or colleagues using Chat, Email or other communication tools.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/td_step_5.png" alt="Building up Collaboration, Tagging and Chat" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.sixth_step, s.container_solutions, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>6</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Voyage Costs and Freight Calculation</h3>
                      <p className={s.description}>
                      As a solution for freight brokers, carriers, and traders, SHIPNEXT offers an advanced solution for calculating voyage cost and freight.
                      </p>
                      <p className={s.description}>
                      Freight Calculator includes such data as vessel’s data (including TPC, speed and consumption), bunker prices, port restrictions, DA cost, Loading/discharge rates. All these insights allow our users to make advanced voyage cost calculations, intake calculations, and more.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/td_step_6.png" alt="Freight tendering and Freight-search" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="use-case" />
        <div className={s.use_case}>
          <div className={s.container}>
            <h2 className={s.title_block}>Use Case</h2>
            <div className={s.wrapper_use_case}>
              <div className={s.wrapper_svg}>
                <TradingDesk />
              </div>

              <div className={s.description_use_case}>
                <ul>
                  <li><h3>Carriers or Shipping Companies</h3> use the Trading Desk to work with incoming freight requests, cargo and orders, which they can study, handle and offer on. Offers can be sent both on spot, as well as future shipments and COAs. Cargo-to-Ship and Ship-to-Cargo matching is used to find employment for their own fleet, or, replacement and alternative fleet for cargo which can not be carried by own vessels.<br />
                  Carriers use Trading desk to filter out the Cargo, Orders or Fleet they would like to concentrate on. <br />
                  Creating and Applying filters on Cargo, Orders and Fleet also tags incoming emails.</li>
                  <li><h3>Shipbrokers</h3> use the Trading Desk to build internal collaboration, exchange comments and tag incoming Cargo and Fleet positions. In their case the Trading Desk can be used to hold freight negotiations simultaneously on both sides, one with the Charterer/Shipper, and the other with the Carrier, directly or indirectly through brokers who stand behind open fleet positions. Personal data related to fleet data, cargo data, freight ideas and hire ideas could be stored for further internal use.<br />
                With the help of the instant cargo-to-ship matching, Brokers can build a more efficient work with any cargo regardless of its size, type, shape or destination.</li>
                  <li><h3>Traders and Shippers</h3> use the Trading Desk to search freight for spot shipments and collect offers for future shipments & COAs. The Trading Desk is also used for freight tenders, allowing Traders and Shippers to create their standard terms and conditions, and reuse them for new shipments, or even apply their customized Charter Party (Contract) form.<br />
                The Trading Desk can be used to search for spot replacement vessels or liner services.</li>
                  <li><h3>International groups of companies, companies undergoing M&A, and Company Networks</h3> use the Trading Desk to build up transparent cooperation and consolidate all cargo on Cargo, Freight Requests, Orders, Fleet positions and synchronize work among the entire group.</li>
                  <li><h3>Organizations and Marketplaces</h3> use the Trading Desk to get an insight on the daily Shipping markets, Time Charter and Freight indexes. Freight analytics can help build valuable freight market reports.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <span className={s.anchor} id="other-solutions" />
        <div className={s.other_solutions}>
          <div className={s.container}>
            <h2 className={s.title_block}>Other solutions</h2>
            <div className={s.links_other_solutions}>
              <Link className={s.part_link} to="/solution-shipnext-cargo-monitor">
                <div>
                  <CargoMonitor />
                  <h3>SHIPNEXT<br /> Cargo Monitor</h3>
                </div>
                <p>Digitize and Automate Your Supply Chain</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-fleet-monitor'}>
                <div>
                  <FleetMonitor />
                  <h3>SHIPNEXT<br /> Fleet Monitor</h3>
                </div>
                <p>Track Your Fleet</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-exchange'}>
                <div>
                  <Exchange />
                  <h3>SHIPNEXT <br /> Exchange</h3>
                </div>
                <p>Optimize E-mail management and stay Connected</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-documents'}>
                <div>
                  <Documents />
                  <h3>SHIPNEXT <br /> Documents</h3>
                </div>
                <p>Your Contract Management</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-ports'}>
                <div>
                  <PortsIcon />
                  <h3>SHIPNEXT <br /> Ports</h3>
                </div>
                <p>Port Data-search Base</p>
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(s)(PageSolutionsMarketplace);
