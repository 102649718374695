import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Clients = props => (
  <span {...props}>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g id="clients" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="evenodd">
          <path d="M15.8979127,11.3657425 C12.8139051,11.3657425 10.3041846,8.8165933 10.3041846,5.68287124 C10.3041846,2.54914918 12.8139051,0 15.8979127,0 C18.9819204,0 21.4916409,2.54914918 21.4916409,5.68287124 C21.4916409,8.8165933 18.9819204,11.3657425 15.8979127,11.3657425 Z M23.4029028,14.120435 C25.4274792,14.7924367 26.7937683,16.7107272 26.7937683,18.8724468 L26.7937683,26.6861805 C26.7937683,27.8536119 25.8629089,28.7999 24.7143346,28.7999 L7.07943373,28.7999 C5.93085945,28.7999 5,27.8536119 5,26.6861805 L5,18.8998754 C5,16.7278701 6.3148604,14.6432935 8.40115129,14.1170064 C9.16401033,13.9232917 11.4577303,13.5958623 11.5057304,13.5958623 C11.7097309,13.5958623 11.8983028,13.724434 11.970303,13.9284345 L15.328597,23.2867435 C15.5205975,23.8267448 16.2731708,23.8267448 16.4668856,23.2867435 L19.8234653,13.9284345 C19.9143227,13.6764339 20.1834662,13.541005 20.4354669,13.6198624 C20.4354669,13.6198624 22.6657581,13.937006 23.3960456,14.1187207 C23.3960456,14.1187207 23.3994742,14.120435 23.4029028,14.120435 Z M17.163573,13.4408905 C17.3710021,13.6706053 17.4018593,14.0031776 17.2544304,14.2620354 L16.4641427,15.4723241 L16.8344294,18.6420462 L16.1058561,20.6100511 C16.0355702,20.8071945 15.7595695,20.8071945 15.6875694,20.6100511 L14.9607104,18.6420462 L15.3292828,15.4723241 L14.5407094,14.2620354 C14.3932804,14.0031776 14.4224234,13.6706053 14.6298525,13.4408905 C14.7635671,13.2934615 14.9589961,13.2180328 15.1578538,13.2180328 L16.6355717,13.2180328 C16.8344294,13.2180328 17.0298584,13.2934615 17.163573,13.4408905 Z" id="Combined-Shape-Copy-6" fill="#FFFFFF"></path>
        </g>
      </g>
    </svg>
  </span>
);

Clients = pure(Clients);
Clients.displayName = 'Clients';
Clients.muiName = 'SvgIcon';

export default Clients;
