import React, {Component} from 'react';
import PhoneNumberInputUi from "../NewInputs/PhoneNumberInputUi";
import Formsy, { HOC } from 'formsy-react';
Formsy.addValidationRule('isPhoneNumberFull', (values, value) => !value || value.length < 5 || (value && value.length > 6 && value.length < 17));
class PhoneNumberInput extends Component {
  state = {};
  onChange = (formatted, { dialCode }) => {
    this.props.onChange(null, formatted);
  }
  onBlur = () => {
    this.setState({ focused: false });
  }
  onFocus = () => {
    this.setState({ focused: true });
  }

  render() {
    const {
      setValidations,
      resetValue,
      getValue,
      hasValue,
      getErrorMessage,
      getErrorMessages,
      isFormDisabled,
      isValid,
      isPristine,
      isFormSubmitted,
      isRequired,
      showRequired,
      showError,
      isValidValue,
      value,
    } = this.props;
    const errorText = !isPristine() && !isValid() && getErrorMessage();
    const focused = this.state.focused;
    const required = isRequired();
    return (
      <PhoneNumberInputUi
        focused={focused}
        value={value}
        errorText={errorText}
        required={required}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
      />
    );
  }
}

export default HOC(PhoneNumberInput);
