import React from 'react';
import styled, { css } from 'styled-components';
import Search from '../../Common/Search';
import WithTooltip from '../../Common/WithTooltip';
import BurgerActions from '../../Common/BurgerActions';
import s from './../../Vessel/Preview/ActionsMenu.scss';

export const StyledBurgerActions = styled(BurgerActions)`
  &.${s.actions_menu} {
    width: 120px;
    min-width: 120px;
    div[role="presentation"], div[role="menu"] {
      width: 100% !important;
      display: block !important;
    }
  }
`;

export const TextStyles = styled.div`
  font-weight: ${props => props.isBold ? '500' : '400'};
  ${props => {
    let styles = ``;
    if (props.textAlign) {
      styles += `text-align: ${props.textAlign};`;
    }
    if (props.color) {
      styles += `color: ${props.color};`;
    }
    if (props.ellipsis) {
      styles += `
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `;
    }
    if (props.fontWeight) {
      styles += `
          font-weight: ${props.fontWeight};
        `;
    }
    return css`${styles}`;
  }
  }
`;

export const Text = ({ children, emptyValue = '---' , ...rest }) => {
  return (
    <TextStyles {...rest}>
      {
        children || emptyValue
      }
    </TextStyles>
  )
}

export const headerStyles = props => props.header && css`
  ${TextStyles} {
    color: ${props.isDark ? 'rgba(255,255,255,0.7)' : 'var(--text-medium)'};
  }
`;

export const SearchField = styled(Search)`
  width: 224px !important;
  max-width: 290px;
  height: 24px !important;
`;

export const TooltipContainer = styled.div`
  background: ${props => props.isDark ? '#fff' : 'var(--bg-table-dark)'};
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
`;
export const Label = styled.span`
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
`;
export const Tooltip = styled(WithTooltip)`
  z-index: 99;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  &:nth-child(even) {
    margin-top: 10px;
  }
`;

export const ContractType = styled.div`
  position: relative;
  height: 8px;
  width: 8px;
  max-width: 20px;
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => props.color ?? 'var(--icon-default)'};
  }
`;
export const ContractTypeTwoColor = styled(ContractType)`
  &::after {
    border-left: 4px solid ${props => props.leftColor ?? 'var(--icon-default)'};
    background-color: ${props => props.rightColor ?? 'var(--icon-default)'};
  }
`;

export const RowWrapper = styled.div`
  width: 100%;
  height: ${props => props.h ?? '48px'};
  border-bottom: 1px solid var(--stroke-light-gray2);
  padding: ${props => props.p ?? '0px 0px 0px 9px'};
  display: flex;
  background-color: initial;
  ${props => props.noHover ? '' : css`
    cursor: pointer;
    &:hover {
      position: relative;
      z-index: 1;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
    }
  `}
  ${props => props.isDark && css`
      border-bottom: 1px solid var(--stroke-dark);
      background-color:var(--bg-table-dark);
      ${!props.noHover && `
        &:hover {
            background-color: var(--bg-table-dark-hover);
        }
      `}
    `
  }
  .${s.ref_checkbox} {
    margin-right: 0px;
  }
  ${headerStyles}
`;
