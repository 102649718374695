import MenuItem from 'material-ui/MenuItem';
import React from 'react'
import { NarrowSelect } from '../../NewInputs/NarrowSelect';

export const MT = {
  label: 'per mt',
  value: 'per mt',
};
export const CBM = {
  label: 'per cbm',
  value: 'per cbm',
};
export const LUMP = {
  label: 'lump sum',
  value: 'lump sum',
};
export const WM = {
  label: 'per w/m',
  value: 'per w/m',
};

export const units = [
  MT,
  CBM,
  LUMP,
  WM,
];

function UnitsSelect({ as, value = MT.value, name = 'term', ...props }) {

  const El = as ?? NarrowSelect;

  return (
    <El
      name={name}
      value={value}
      {...props}
    >
      {
        units.map(({ value, label }) => <MenuItem value={value} primaryText={label} key={value} />)
      }
    </El>
  )
}

export default UnitsSelect;
