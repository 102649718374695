import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Dialog from '../../Common/Dialog';
import VesselPreview from './VesselPreviewNew';

export default function VesselPreviewDialog({ vessel, handleClose }) {
  return (
    <Dialog contentStyle={{transform: "unset"}} open={true} handleClose={handleClose}>
      <div
        style={{ paddingLeft: 15, marginTop: 16, marginBottom: 28, color: '#333333' }}
        className="bold"
      >
        VESSEL INFO
      </div>
      <VesselPreview vessel={vessel} dialog/>
    </Dialog>
  );
}
