//noinspection JSUnusedGlobalSymbols
/** @name MONITOR_DRAGSTART_VESSEL_REDUCER */
export default function MONITOR_DRAGSTART_VESSEL(state, action) {
  const newState = {};
  const vessel = action.payload;
  const kgs = vessel.dwt * 1000;
  const cargoList = { ...state.cargoList };
  newState.cargoList = cargoList;
  cargoList.data = cargoList.data.map((c) => {
    c = { ...c };
    c.disabled = kgs < c.totalValues.weight; //|| (c.addedBy && vessel.addedBy && c.addedBy === vessel.addedBy._id);
    return c;
  });
  return { ...state, ...newState };
}
