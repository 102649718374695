import React, { PureComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import sg from './General.scss';
import s from './Port.scss';
import AddCapacity from '../Icons/AddCapacity';
import Dialog from '../Common/Dialog';
import ButtonForm from './ButtonForm';
import Formy from 'formsy-react';
import FormsyText from '../../components/Common/FormsyText';
import { List, ListItem } from 'material-ui/List';
import CustomCheckbox from '../Common/CustomCheckbox';
import IconButton from 'material-ui/IconButton';
import ContentEditor from 'material-ui/svg-icons/editor/mode-edit';
import ContentRemove from 'material-ui/svg-icons/navigation/close';
import ActionRestore from 'material-ui/svg-icons/action/settings-backup-restore';
import cx from 'classnames';
import OldDiff from '../Common/Diff';
import { removeFromArray } from '../../core/utils';
import Apple from '../Icons/Apple';
import Arrow from '../Icons/Arrow';
import Restore from '../Icons/Restore';
import Close from '../Icons/Close';
import EditIcon from 'material-ui/svg-icons/image/edit';
import CheckIcon from 'material-ui/svg-icons/navigation/check';
import BlockIcon from 'material-ui/svg-icons/content/block';
import { consolidateStreamedStyles } from 'styled-components';

const Form = Formy.Form;

const labelStyle = { fontSize: '12px', lineHeight: '12px', color: '#999999', top: '46px', transform: 'scale(1) translate(0px, 0px)' };
const labelShrinkStyle = { transform: 'scale(1) translate(0px, -20px)' };

function Diff(props) {
  return <OldDiff {...props} colorScheme="green" />;
}

function mergeGroup(group, originGroup) {
  group = { ...group };
  let dirty = group.checked !== originGroup.checked;
  group.details = group.details.map((crane) => {
    let origin = originGroup.details.find(oc => oc._id === crane._id);
    let status = crane.status;
    if (!origin) {
      origin = { name: '', value: '' };
      status = 'new';
    }
    if (crane.name !== origin.name || crane.value !== origin.value) {
      dirty = true;
      crane.dirty = true;
    }
    return { ...crane, origin, status };
  });
  originGroup.details.forEach((oc, i) => {
    const currentIndex = group.details.findIndex(c => c._id === oc._id);
    if (currentIndex === -1) {
      dirty = true;
      group.details.splice(i, 0, { _id: oc._id, name: '', value: '', origin: oc, status: 'removed' });
    }
  });
  group.dirty = dirty;
  return group;
}

class AvailableLiftCranes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.group.checked || false,
      addCapacity: false,
      toggled: false,
      group: mergeGroup(props.group, props.originGroup),
    };
  }

  static defaultProps = {
    group: { details: [] },
    originGroup: { details: [] },
  };

  getModel = () => {
    const group = { ...this.state.group};
    !group.checked && (group.details = []);
    delete group.dirty;
    group.details = group.details.filter(d => d.status !== 'removed');
    group.details.forEach((d) => {
      delete d.status;
      delete d.dirty;
      delete d.origin;
    })
    return group;
  };

  componentDidUpdate() {
    this.props.handleAddConfirmModal()
  }

  handleCheck = () => {
    const newState = {
      checked: !this.state.checked,
      toggled: true,
    };
    this.state.group.checked = !this.state.checked;
    if (!newState.checked) {
      newState.toggled = false;
      newState.group = mergeGroup({ ...this.state.group, checked: false }, this.props.originGroup);
    }
    this.setState(newState);
  };

  _addCapacity = () => {
    this.handleSaveCrane()
    this.setState({
      addCapacity: true,
      selectedCrane: undefined
    });
  };

  handleToggle = () => {
    this.setState({
      toggled: !this.state.toggled,
    });
  };

  handleEditCrane = (crane) => {
    this.setState({
      addCapacity: false,
      selectedCrane: crane,
    });
  };

  handleSaveCrane = (...args) => {
    const [e, newCrane] = args;

    if (!newCrane) {
      return this.setState({ selectedCrane: undefined, addCapacity: false });
    }
    if (this.state.selectedCrane) {
      this.state.selectedCrane.id ? newCrane.id = this.state.selectedCrane.id : newCrane._id = this.state.selectedCrane._id;
      const index = newCrane.id ? this.state.group.details.findIndex(cr => cr.id === newCrane.id) : this.state.group.details.findIndex(cr => cr._id === newCrane._id);
      this.state.group.details[index] = newCrane;
    } else {
      newCrane.id = uuidv4();
      this.state.group.details.push(newCrane);
    }
    this.setState({
      selectedCrane: undefined,
      addCapacity: false,
      group: mergeGroup(this.state.group, this.props.originGroup),
    });
  };

  handleRemoveCrane = (crane) => {
    const group = { ...this.state.group };
    group.details = group.details.filter(c => c._id !== crane._id);
    this.setState({ group: mergeGroup(group, this.props.originGroup) });
  };

  handleRestoreCrane = (crane) => {
    if (crane.status === 'new') {
      return this.handleRemoveCrane(crane);
    }
    const index = this.state.group.details.findIndex(cr => cr._id === crane._id);
    this.state.group.details[index] = { ...crane.origin, origin: crane.origin };
    this.setState({ group: mergeGroup(this.state.group, this.props.originGroup) });
  };

  isDirty = () => {
    const group = this.state.group;
    return (group.dirty || group.checked !== this.props.originGroup.checked) && (group.checked || this.props.originGroup.checked);
  }

  render() {
    const group = this.state.group;
    const dirty = this.isDirty();
    return (
      <div key={this.props.uid} className={sg.wrapper_lift_cranes}>
        <List className={sg.wrapper_list} style={{padding: '0' }}>
          <ListItem
            key={0}
            className={s.wrapper_checkbox}
            style={{
              borderBottom: '1px dashed #E0E0E0', padding: '0', cursor: 'auto'
            }}
            innerDivStyle={{
              padding: '9px 0 9px 1px',
            }}
            nestedListStyle={{
              padding: '0',
            }}
            touchRippleColor="transparent"
            hoverColor="transparent"
            initiallyOpen={false}
            open={this.state.toggled && this.state.checked}
            onNestedListToggle={this.handleToggle}
            nestedItems={[
              <ListItem
                key={0}
                className={sg.wrapper_listitem}
                touchRippleColor="transparent"
                hoverColor="transparent"
                style={{
                  cursor: 'auto',
                }}
                innerDivStyle={{
                  margin: '0',
                  padding: '0',
                }}
                primaryText={
                  <div>
                    <div className={sg.wrapper_list_liftCranes}>
                      {group.details ?
                        group.details.map((crane, i) => (
                          <Crane
                            key={i}
                            crane={crane}
                            mtRange={this.props.mtRange}
                            addCapacity={this.state.addCapacity}
                            handleClose={this.handleSaveCrane}
                            handleEdit={this.handleEditCrane.bind(this, crane)}
                            handleRemove={this.handleRemoveCrane.bind(this, crane)}
                            handleRestore={this.handleRestoreCrane.bind(this, crane)}
                          />
                        ))
                        : null
                      }
                    </div>
                    {this.state.addCapacity
                      ? <EditCrane
                        handleClose={this.handleSaveCrane}
                        crane={this.state.selectedCrane}
                        mtRange={this.props.mtRange}
                      />
                      : null
                    }
                    {!this.state.addCapacity ?
                    <div className={sg.add_capacity}>
                      <div
                        onClick={this._addCapacity}
                        style={{
                          marginRight: '12px',
                          cursor: 'pointer',
                        }}>
                        <AddCapacity/>
                      </div>
                      <span>Add capacity</span>
                    </div>
                    : null
                    }
                  </div>
                }
              />,
            ]}
            primaryText={
              <div>
                <CustomCheckbox
                  className={cx('checkbox', sg.checkbox, dirty && 'dirty', this.state.checked && 'checked')}
                  style={{
                    alignItems: 'center',
                    width: '135px',
                    cursor: 'auto',
                  }}
                  labelStyle={{
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: '#999999',
                  }}
                  label={this.props.mtRange + ' mt'}
                  onCheck={this.handleCheck}
                  checked={this.state.checked}
                />
                {this.state.checked ?
                  <p
                    className={s.more}
                    onClick={this.handleToggle}
                  >
                    More details
                  </p>
                  : null
                }
              </div>
            }
            rightIconButton={
              this.state.checked ? null : <IconButton style={{ display: 'none' }} />
            }
          />
        </List>
      </div>
    );
  }
}

class Crane extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditCrane: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.addCapacity) {
      this.setState({
        isEditCrane: false,
      });
    }
  }

  handleEdit = (crane) => {
    this.props.handleEdit(crane);
    this.setState({
      isEditCrane: true,
    });
  };

  handleClose = (...args) => {
    this.props.handleClose(...args);
    this.setState({
      isEditCrane: false,
    });
  }

  render() {
		const { crane, addCapacity, handleRemove, handleRestore, mtRange } = this.props;
    return (
    <div>
      { this.state.isEditCrane && !addCapacity?
      <EditCrane
        mtRange={mtRange}
        crane={crane}
        handleClose={this.handleClose}
      />
      :
      <div className={sg.list_liftCranes}>
        <div className={sg.values}>
          <Diff newValue={crane.value} oldValue={crane.origin.value} />
          mt
        </div>
        <div className={sg.description}>
          <p><Diff newValue={crane.name} oldValue={crane.origin.name} /></p>
        </div>
        <div className={sg.icons}>
          {
            crane.status !== 'new' && (crane.dirty || crane.status === 'removed') ?
              <span title="Restore">
                <ActionRestore
                  title="Restore"
                  onClick={handleRestore}
                />
              </span>
              : null
          }
          {crane.status !== 'removed' ?
          [
            <span key={0} title="Edit">
              <ContentEditor
                title="Edit"
                key={0}
                onClick={() => this.handleEdit(crane)}
              />
            </span>,
            <span title="Remove">
              <ContentRemove
                title="Remove"
                key={1}
                onClick={handleRemove}
              />
            </span>]
            : null
          }
        </div>
      </div>
      }
    </div>
    );
  }
}

function EditCrane(props) {
  const { handleClose, crane = {}, mtRange } = props;
  return (
    <Form onValidSubmit={handleClose.bind(null, null)} className={sg.form_add} noValidate>
      <div className={sg.body}>
        <FormsyText
          autoComplete="off"
          floatingLabelText="Capacity, mt"
          isInteger
          validations={`isNumeric,${mtRange.replace('+', '-1000').split('-').map((c, i) => (i ? 'max:' : 'min:') + c).join(',')}`}
          validationError={mtRange}
          requiredError="required"
          name="value"
          required
          fullWidth
          floatingLabelStyle={labelStyle}
          floatingLabelShrinkStyle={labelShrinkStyle}
          defaultValue={crane.value}
        />
        <FormsyText
          autoComplete="off"
          floatingLabelText="Description"
          validations="minLength:3,maxLength:255"
          validationError="3 - 255 characters"
          name="name"
          fullWidth
          floatingLabelStyle={labelStyle}
          floatingLabelShrinkStyle={labelShrinkStyle}
          defaultValue={crane.name}
        />
        <div className={sg.form_add_buttons}>
          <button type="submit"><CheckIcon style={{fill: "#75C422"}}/></button>
          <button onClick={handleClose}><BlockIcon /></button>
        </div>
      </div>
    </Form>
  );
}


export default AvailableLiftCranes;
