import React, { Component } from 'react'
import ContractSelect from './../Common/ContractSelect';
import styles from './muistyles'
import cx from 'classnames'
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class NarrowContractSelect extends Component {
    render() {
        const {inputStyles, className, classes, labelStyle = {}, containerStyle = {}, underlineStyle = {}, ...props} = this.props;
        return (
            <ContractSelect
                className={cx("select_default", className, classes)}
                style={{...styles.selectContainer, ...containerStyle}}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={{...styles.text.label, ...labelStyle}}
                inputStyle={{...styles.select.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={{...styles.text.underline, ...underlineStyle}}
                underlineFocusStyle={styles.text.underlineFocus}
                {...props}
            >

            </ContractSelect>
        )
    }
}

export default withStyles(s)(NarrowContractSelect)
