// @flow
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Layout from '../../components/Layout';
import s from './Content.css';
import Page from '../../components/Page/Page';
import Footer from '../../components/Footer';

const handleClick = function (e) {
  const target = e.target;
  if (target.className.indexOf('accordion') !== -1) {
    target.classList.toggle('active');
    const answer = target.nextElementSibling;
    if (answer.style.maxHeight) {
      answer.style.maxHeight = null;
    } else {
      answer.style.maxHeight = answer.scrollHeight + 'px';
    }
  }
};

class Content extends PureComponent<{
  path: string,
  content: string,
  title: string,
}> {


  render() {
    let { path, title, content, titleHeader } = this.props;
    return (
      <Layout>
        <Page headerTitle={(path !== '/' && titleHeader) || ''}>
          <div style={{ width: '100%' }}>
            <div // eslint-disable-line jsx-a11y/no-static-element-interactions
              role="button"
              onClick={handleClick}
              className={s.container}
              dangerouslySetInnerHTML={{ __html: content }} // eslint-disable-line react/no-danger
            />
            <Footer />
          </div>
        </Page>
      </Layout>
    );
  }
}

Content.propTypes = {
  path: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default withStyles(s)(Content);
