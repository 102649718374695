import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Ships = props => (
  <span {...props}>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <g id="ships" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fillRule="evenodd">
          <path d="M7.71338374,7.24985373 L24.8382226,7.24985373 L24.8382226,13.008034 L16.2749121,10.1494388 L7.71338374,13.008034 L7.71338374,7.24985373 Z M4.78528402,26.0998299 L4.85657068,26.0998299 C7.14130826,26.0998299 9.16763169,24.8237986 10.5666325,23.2002448 C11.9656333,24.8237986 13.9901745,26.0998299 16.2749121,26.0998299 C18.5560853,26.0998299 20.5841909,24.8237986 21.9814096,23.2002448 C23.3804103,24.8237986 25.4085159,26.0998299 27.6896892,26.0998299 L27.7609758,26.0998299 L30.4591761,16.4137544 C30.5732347,16.0377172 30.5447201,15.6296011 30.3754142,15.2838608 C30.2025441,14.932774 29.8888828,14.6743598 29.5164099,14.5567368 L27.6914713,13.949018 L27.6914713,7.24985373 C27.6914713,5.65481463 26.4065292,4.35026867 24.8364404,4.35026867 L20.5556763,4.35026867 L20.5556763,0 L11.9923658,0 L11.9923658,4.35026867 L7.71338374,4.35026867 C6.1415128,4.35026867 4.85657068,5.65481463 4.85657068,7.24985373 L4.85657068,13.949018 L3.01559258,14.5567368 C2.64668409,14.6743598 2.33124061,14.932774 2.16015261,15.2838608 C1.98906462,15.6296011 1.94629262,16.0377172 2.07460862,16.4137544 L4.78528402,26.0998299 Z M27.6914713,28.9994149 C25.7061378,28.9994149 23.7243685,28.315063 21.9831917,27.0835858 C18.5026203,29.5643618 14.048986,29.5643618 10.5666325,27.0835858 C8.82367354,28.3168451 6.84190427,28.9994149 4.85657068,28.9994149 L2.00332195,28.9994149 L2.00332195,31.899 L4.85657068,31.899 C6.82764694,31.899 8.76842637,31.3928647 10.5666325,30.4625737 C14.1612625,32.3338487 18.3867795,32.3338487 21.9831917,30.4625737 C23.7813978,31.4053399 25.7203951,31.899 27.6914713,31.899 L30.5465022,31.899 L30.5465022,28.9994149 L27.6914713,28.9994149 Z" id="Fill-1-Copy-2" fill="#FFFFFF"></path>
        </g>
      </g>
    </svg>
  </span>
);

Ships = pure(Ships);
Ships.displayName = 'Ships';
Ships.muiName = 'SvgIcon';

export default Ships;
