import RaisedButton from "./RaisedButton";
import cx from "classnames";
import React from "react";

export function HiddenButtons({
                                submitButton,
                                submitProps = {},
                                cancelButton,
                                shouldShow,
                                handleCancel,
                                handleSubmit = () => undefined,
                                className = "",
                                buttonClassName = ""
                              }) {
  let Submit = submitButton ? submitButton : RaisedButton;
  let Cancel = cancelButton ? cancelButton : RaisedButton;
  return (
    shouldShow
      ? (
        <div
          className={cx("row", className)}
          style={{justifyContent: 'flex-end'}}
        >
          <Cancel
            label="cancel"
            disableTouchRipple={true}
            disableFocusRipple={true}
            style={{
              marginRight: "8px",
              borderRadius: "15px",
              overflow: "hidden"
            }}
            onClick={handleCancel}
            buttonStyle={{
              borderRadius: "15px"
            }}
            secondary={true}
            onTouchTap={handleCancel}
            className={buttonClassName}
          />
          <Submit
            label={"SAVE"}
            disableTouchRipple={true}
            disableFocusRipple={true}
            primary={true}
            type="submit"
            style={{
              borderRadius: "15px"
            }}
            buttonStyle={{
              borderRadius: "15px"
            }}
            onClick={handleSubmit}
            className={buttonClassName}
            {...submitProps}
          />
        </div>
      ) : null)
}
