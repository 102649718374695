import React from 'react';
import pure from 'recompose/pure';

const _ScreenshotIcon = ({ ...props }) => (
  <span {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_36511_21389)">
        <path d="M16.6665 2.49976H3.33317C2.40817 2.49976 1.6665 3.24142 1.6665 4.16642V14.1664C1.6665 15.0831 2.40817 15.8331 3.33317 15.8331H6.6665V17.4998H13.3332V15.8331H16.6665C17.5832 15.8331 18.3332 15.0831 18.3332 14.1664V4.16642C18.3332 3.24142 17.5832 2.49976 16.6665 2.49976ZM16.6665 14.1664H3.33317V4.16642H16.6665V14.1664Z" fill="#4380C7"/>
        <path d="M5.4165 6.24976H7.49984V4.99976H4.1665V8.33309H5.4165V6.24976Z" fill="#4380C7"/>
        <path d="M15.8333 9.99976H14.5833V12.0831H12.5V13.3331H15.8333V9.99976Z" fill="#4380C7"/>
      </g>
      <defs>
        <clipPath id="clip0_36511_21389">
          <rect width="20" height="20" fill="white" transform="translate(0 -0.000244141)"/>
        </clipPath>
      </defs>
    </svg>

  </span>
);

const ScreenshotIcon = pure(_ScreenshotIcon);
ScreenshotIcon.displayName = 'ScreenshotIcon';
ScreenshotIcon.muiName = 'SvgIcon';

export default ScreenshotIcon;
