import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PublicPort.scss';
import cx from 'classnames';
import VesselsList from './VesselsList';
import { connect } from 'react-redux';

class VesselsListTab extends PureComponent {
    render() {
        return (
            <div className={s.wrapper_vessels_table}>
                <div className={cx(s.general, s.header)}>
                    <div className={s.name}>Name</div>
                    <div className={s.dwt}>DWT</div>
                    <div className={s.blt}>BLT</div>
                    <div className={s.departure}>DEPARTURE</div>
                    <div className={s.progress_line_wrapper} />
                    <div className={s.arrival}>ARRIVAL</div>
                </div>
                <div className={s.body}>
                <VesselsList
                    vessels={this.props.vessels}
                    port={this.props.port}
                    handleVesselPositionClick={this.props.handleVesselPositionClick}
                    user={this.props.user}
                />
                </div>
            </div>
        )
    }
}

export default connect(state => ({ user: state.login.user }), {})(withStyles(s)(VesselsListTab));
