import React from 'react';


let Logo_Color = props => (
<span {...props}>
  <span className="logo_color_big"></span>
</span>

);


export default Logo_Color;
