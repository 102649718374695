import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let ContactGroupIcon = ({ fill = "#B2B2B2", ...props }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <g>
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.37265 16.7368H11.9241V15.7878H10.0956L10.0957 14.4771H14.6236V15.7878H12.7952V16.7368H15.3466V5.59664H9.37275L9.37265 16.7368ZM14.6236 8.37354H13.0913H10.0956L10.0957 9.74116H13.0913H14.6236V8.37354ZM14.6236 6.34962H13.0913H10.0956L10.0957 7.71735H13.0913H14.6236V6.34962ZM13.0913 10.4081H14.6236V11.7757H13.0913H10.0957L10.0956 10.4081H13.0913ZM14.6236 12.4621H13.0913H10.0956L10.0957 13.8298H13.0913H14.6236V12.4621Z" fill={fill}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.06151 16.7368H5.79512L5.79521 7.90593H5.42188L5.42188 7.27803H9.06154L9.06151 7.90593V8.51048H6.66213V9.87798H9.06151V10.5254H6.66213V11.8931H9.06151V12.56H6.66213V13.9276H9.06151V14.5945H6.66213V15.9621H9.06151V16.7368ZM15.9482 6.02601H8.77113V4H15.9482V6.02601Z" fill={fill}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M15.6578 16.7368H18.9242L18.9241 7.90593H19.2974V7.27803H15.6578V7.90593L15.6578 8.51048H18.0572V9.87798H15.6578V10.5254H18.0572V11.8931H15.6578V12.56H18.0572V13.9276H15.6578V14.5945H18.0572V15.9621H15.6578L15.6578 16.7368Z" fill={fill}/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.35938 17.128H19.3594V18.4975H5.35938V17.128Z" fill={fill}/>
      </g>
    </g>
  </svg>
);

ContactGroupIcon = pure(ContactGroupIcon);
ContactGroupIcon.displayName = 'ContactGroupIcon';
ContactGroupIcon.muiName = 'SvgIcon';

export default ContactGroupIcon;
