import React from 'react';
import IconButton from 'material-ui/IconButton';
import Close from 'material-ui/svg-icons/navigation/close';

const btnStyles = {
  background: "var(--stroke-light-gray2)",
  width: '24px',
  height: '24px',
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  outline: 'none',
  border: 'none',
};

function CircleCloseBtn({ icon, style = {}, iconStyle = {}, iconProps = {}, ...rest }) {
  const Icon = icon ? icon : Close;

  return (
    <IconButton {...rest} iconStyle={{ ...iconStyle }} style={{ ...btnStyles, ...style }}>
      <Icon color="var(--profile-grey-label)" {...iconProps} />
    </IconButton>
  );
}

export default CircleCloseBtn;
