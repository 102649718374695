import PropTypes from 'prop-types';
import React from 'react'
import styled from 'styled-components';
import { StyledAddPortForm } from '../Port/PortList';

const Part = styled.div`
  width: 100%;
  background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border-color);
  height: 100%;
`;

const AddPortWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  background: #fff;
  height: 100%;
  width: calc(100vw - 100%);
  overflow: hidden;
  & > div {
    width: 100% !important;
    height: 100%;
  }
`;
const Body = styled.div`
  position: relative;
  z-index: 1001;
  width: 100%;
  height: 100%;
  /*box-shadow: 6px 0px 18px rgb(128 128 128 / 14%);*/
`;

class CargoPart extends React.Component {
  static childContextTypes = {
    interceptWindowOpen: PropTypes.func,
  }

  getChildContext() {
    return {
      interceptWindowOpen: this.interceptWindowOpen,
    };
  }

  state = {
    isAddingNewPort: false,
  }

  interceptWindowOpen = () => {
    this.setState({ isAddingNewPort: true });
  }

  handleCloseAddPort = () => {
    this.setState({
      isAddingNewPort: false,
    })
  }

  render() {
    const { children, ...rest } = this.props;

    const { isAddingNewPort } = this.state;

    return (
      <Part {...rest}>
        <Body>
          {
            children
          }
        </Body>
        {
          isAddingNewPort && (
            <AddPortWrapper>
              <StyledAddPortForm handleCloseAddPort={this.handleCloseAddPort} />
            </AddPortWrapper>
          )
        }
      </Part>
    )
  }
}

export default CargoPart;
