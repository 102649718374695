import PropTypes from 'prop-types';
import React, { Component } from 'react';
import s from './AppDownload.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Apple from '../Icons/Apple';
import Android from '../Icons/Android';

class AppDownload extends Component {
  render() {
    return (
      <div className={s.wrapper_app}>
        <a target="_blank" href="https://itunes.apple.com/us/app/shipnext/id1230901217">
          <Apple />
          <p>Iphone</p>
        </a>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.shipnext">
          <Android />
          <p>Android</p>
        </a>
      </div>
    )
  }
}
export default withStyles(s)(AppDownload);
