exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3hvCA {\n  text-decoration: line-through;\n  -webkit-text-decoration-color: #D0021B;\n          text-decoration-color: #D0021B; }\n  ._3hvCA a {\n    text-decoration: line-through !important;\n    -webkit-text-decoration-color: #D0021B !important;\n            text-decoration-color: #D0021B !important; }\n  ._3hvCA + ._25ZMt, ._1Fq9E {\n  color: #D0021B;\n  margin-left: 3px; }\n  ._3hvCA + ._25ZMt a, ._1Fq9E a {\n    color: #D0021B !important;\n    -webkit-text-decoration-color: #D0021B !important;\n            text-decoration-color: #D0021B !important; }\n  ._1_ie1 ._3hvCA {\n  -webkit-text-decoration-color: inherit;\n          text-decoration-color: inherit; }\n  ._1_ie1 ._3hvCA + ._25ZMt {\n  color: inherit;\n  background-color: rgba(127, 201, 49, 0.2); }\n  ._1_ie1 ._25ZMt {\n  margin-right: 3px; }\n  ._3e6y3 ._3hvCA {\n  -webkit-text-decoration-color: inherit;\n          text-decoration-color: inherit;\n  white-space: break-spaces;\n  text-align: justify; }\n  ._3e6y3 ._3hvCA + ._25ZMt {\n  color: inherit;\n  background-color: rgba(127, 201, 49, 0.2); }\n  ._3e6y3 ._25ZMt {\n  white-space: break-spaces;\n  margin-right: 3px;\n  text-align: justify; }\n  ._25ZMt._2Rnck {\n  margin-left: 0; }\n  ._2VWj3 {\n  white-space: pre-line; }\n", ""]);

// Exports
exports.locals = {
	"old": "_3hvCA",
	"new": "_25ZMt",
	"newforced": "_1Fq9E",
	"green": "_1_ie1",
	"green_preLine": "_3e6y3",
	"nomargin": "_2Rnck",
	"multiline": "_2VWj3"
};