/* eslint-disable import/prefer-default-export */
import { fetch } from '../core/api/api';
import { ADD_TOP_MESSAGE, REMOVE_TOP_MESSAGE, SET_RUNTIME_VARIABLE } from '../constants';
import IO from '../core/api/IO';

export function setRuntimeVariable({ name, value }) {
  return {
    type: SET_RUNTIME_VARIABLE,
    payload: {
      name,
      value,
    },
  };
}

export function navigate(route) {
  return {
    type: 'NAVIGATE',
    payload: {
      route,
    },
  };
}

export function staticPopup(path, fromApi) {
  if (fromApi) {
    return {
      type: 'STATIC_POPUP',
      promise: fetch(
        path,
        {
          method: 'GET',
        },
        ' ',
        3,
      ).then((res) => { res.data.content = res.data.text; res.data = { content: res.data }; return res; }),
      errorMessage: 'Failed to fetch ' + path,
    };
  }
  return {
    type: 'STATIC_POPUP',
    promise: fetch(
      'graphql',
      {
        method: 'POST',
        body: JSON.stringify({
          query: '{content(path:"/' +
            path +
            '"){path,title,content,component}}',
        }),
      },
      '/',
    ),
    errorMessage: 'Failed to fetch ' + path,
  };
}

export function toggleDialogMessage(message) {
  return {
    type: 'DIALOG_MESSAGE',
    payload: message,
  };
}

export function getLandingAction() {
  return {
    type: 'LANDING',
    payload: {},
    promise: IO.getLanding(),
  };
}

export function addTopMessage(message) {
  return {
    type: ADD_TOP_MESSAGE,
    payload: message,
  }
}
export function removeTopMessage(message) {
  return {
    type: REMOVE_TOP_MESSAGE,
    payload: message,
  }
}
