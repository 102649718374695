import styled, { css } from "styled-components";
import TextField from "material-ui/TextField";

export const Input = styled(TextField)`
	height: 42px !important;

	label {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px!important;
		line-height: 24px!important;
		color: #999999!important;
		top: 24px!important;
		${props => props.value || props.focus ? `transform: translate(0, -28px)!important;` : `transform: translate(0, -8px)!important;`}
	}

	input {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px!important;
		line-height: 24px!important;
		color: #333333!important;
		margin-top: 8px!important;
	}

	hr {
		border-bottom: 1px solid #bbbbbb !important;
		bottom: 0px !important;
	}
`;