import React, { Component } from 'react';
import s from "./IssueReport.scss";
import cx from "classnames";
import { uploadImage } from '../../actions/login';

export default class ScreenCapture extends Component {
  static defaultProps = {
    onStartCapture: () => null,
    onEndCapture: () => null,
  }

  state = {
    on: false,
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
    crossHairsTop: 0,
    crossHairsLeft: 0,
    isMouseDown: false,
    windowWidth: 0,
    windowHeight: 0,
    borderWidth: 0,
    cropPositionTop: 0,
    cropPositionLeft: 0,
    cropWidth: 0,
    cropHeigth: 0,
    imageURL: '',
  }

  componentDidMount = () => {
    this.handleWindowResize();
    document.addEventListener("keydown", this.handleAbort);
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleAbort);
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    this.setState({
      windowWidth,
      windowHeight,
    });
  }

  handStartCapture = () => this.setState({ on: true })

  getCropSizes = (e,callback)=>{
    const {startX, startY } = this.state;
    const endX = e.clientX;
    const endY = e.clientY;
    const cropWidth = Math.abs(endX - startX);
    const cropHeigth = Math.abs(endY - startY);
    const cropPositionTop = Math.min(startY, endY);
    const cropPositionLeft = Math.min(startX, endX);
    this.setState({
      cropPositionTop,
      cropPositionLeft,
      cropWidth,
      cropHeigth,
    },callback);
  }

  handleMouseMove = (e) => {
    const { isMouseDown, windowWidth, windowHeight, startX, startY, borderWidth } = this.state;

    const endX = e.clientX;
    const endY = e.clientY;
    const isStartTop = endY >= startY;
    const isStartBottom = endY <= startY;
    const isStartLeft = endX >= startX;
    const isStartRight = endX <= startX;
    const isStartTopLeft = isStartTop && isStartLeft;
    const isStartTopRight = isStartTop && isStartRight;
    const isStartBottomLeft = isStartBottom && isStartLeft;
    const isStartBottomRight = isStartBottom && isStartRight;
    let newBorderWidth = borderWidth;

    if (isMouseDown) {
      if (isStartTopLeft) {
        newBorderWidth = `${startY}px ${windowWidth - endX}px ${windowHeight - endY}px ${startX}px`;
      }

      if (isStartTopRight) {
        newBorderWidth = `${startY}px ${windowWidth - startX}px ${windowHeight - endY}px ${endX}px`;
      }

      if (isStartBottomLeft) {
        newBorderWidth = `${endY}px ${windowWidth - endX}px ${windowHeight - startY}px ${startX}px`;
      }

      if (isStartBottomRight) {
        newBorderWidth = `${endY}px ${windowWidth - startX}px ${windowHeight - startY}px ${endX}px`;
      }
    }

    this.setState({
      crossHairsTop: e.clientY,
      crossHairsLeft: e.clientX,
      borderWidth: newBorderWidth,
    });
  }

  handleMouseDown = (e) => {
    const startX = e.clientX;
    const startY = e.clientY;

    this.setState((prevState) => ({
      startX,
      startY,
      isMouseDown: true,
      borderWidth: `${prevState.windowWidth}px ${prevState.windowHeight}px`,
    }));
  }
handleAbort = (e) => {
  if (e.keyCode === 27) {
    this.setState({
      on: false,
    });
  }
  return;
}
  handleMouseUp = (e) => {
    this.getCropSizes(e,() => {
      this.handleClickTakeScreenShot();
      this.setState({
        on: false,
        isMouseDown: false,
        borderWidth: 0,
      });
    })
  }

  handleClickTakeScreenShot = async () => {
    const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = this.state;
    try {
      const stream = await window.navigator.mediaDevices.getDisplayMedia({preferCurrentTab:true, video: true });
      const track = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(track);
      const bitmap = await imageCapture.grabFrame();
      
        const croppedCanvas = document.createElement('canvas');
        const croppedCanvasContext = croppedCanvas.getContext('2d');

        const coef = bitmap.width / window.innerWidth;

        croppedCanvas.width = cropWidth*coef;
        croppedCanvas.height = cropHeigth*coef;

        croppedCanvasContext.drawImage(bitmap, cropPositionLeft*coef, cropPositionTop*coef, cropWidth*coef, cropHeigth*coef, 0, 0, cropWidth*coef, cropHeigth*coef);
        stream.getVideoTracks()[0].stop();
        const file = this.dataURIToBlob(croppedCanvas.toDataURL());
        const formData = new FormData();
        formData.append('file', file, 'screenshot.png');
        formData.append('type', 'attachment');
        this.props.showForm();
        uploadImage(formData).promise.then(data => {
          this.props.onEndCapture(data);
        });
      
    } catch (e) {
      console.error(e.message);
      this.props.showForm();
    }
  }
  dataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
  renderChild = () => {
    const { children } = this.props;

    const props = {
      onStartCapture: this.handStartCapture,
    };

    if (typeof children === 'function') return children(props);
    return children;
  }

  render() {
    const {
      on,
      crossHairsTop,
      crossHairsLeft,
      borderWidth,
      isMouseDown,
    } = this.state;

    if (!on) return this.renderChild();

    return (
      <div
        onMouseMove={this.handleMouseMove}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        {this.renderChild()}
        <div
          className={cx(s.overlay, isMouseDown && s.highlighting)}
          style={{ borderWidth }}
        />
        <div
          className={s.crosshairs}
          style={{ left: crossHairsLeft + 'px', top: crossHairsTop + 'px' }}
        />
      </div>
    );
  }
}
