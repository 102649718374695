import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import Form from '../About/Form';
import Link from '../../Link';
import RoundThemableButton from '../../Common/RoundThemableButton';
import TradingDesk from '../../Icons/TradingDesk';
import Exchange from '../../Icons/Exchange';
import FleetMonitor from '../../Icons/FleetMonitor';
import CargoMonitor from '../../Icons/CargoMonitor';
import Documents from '../../Icons/Documents';
import PortsIcon from '../../Icons/PortsIcon';

class PageSolutionsFleetMonitor extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_fleet_monitor_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Solution for tracking fleet, carrying<br /> out compliance checks and other<br /> fleet analytics</h1>
                </div>

                <div className={s.wrapper_icon}>
                  <FleetMonitor />
                  <p>SHIPNEXT Fleet Monitor</p>
                </div>
                <div className={s.wrapper_links}>
                  <a href="#how-it-works" className={s.wrapper_links_link}>How it works</a>
                  <a href="#use-case" className={s.wrapper_links_link}>Use Case</a>
                  {/* {!this.props.user && <RoundThemableButton className={s.wrapper_links_link_color} component={Link} to={'/register'}>Register</RoundThemableButton>} */}
                </div>
              </div>
              <div className={s.right_part}>
                <Form
                  label={'Request demo'}
                  requestType={'shipnextDemo'}
                />
                <button
                  onClick={this.props.handleChangeForm.bind(this, 'Request demo', 'shipnextDemo')}
                >
                  Request demo
                </button>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="how-it-works" />
        <div className={cx(s.how_it_works, s.how_it_works_fm)}>
          <div className={s.container}>
            <h2 className={s.title_block}>How it works</h2>
          </div>
          <div className={s.first_step}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>1</div>
                  <div className={s.right_part}>
                    <div className={s.wrapper_info}>
                      <div className={s.info_left}>
                        <h3>What’s a Fleet Monitor</h3>
                        <p className={s.description}>
                          The Fleet Monitor is an easy way to manage your fleet data, monitor the vessel’s commercial and operational performance, track it’s past and current employment.
                        </p>
                        <p className={s.description}>
                          With the help of SHIPNEXT Monitor, our users can also build up their own database of information and historic records on each vessel. Advanced tools allow storage of time-charter-related data, check sanction and compliance checks, and port state control inspection detentions.
                        </p>
                      </div>
                      <div className={s.info_right}>
                        <div className={s.wrapper_image}>
                          <img src="/images/solutions/fm_step_1.png" alt="What’s a Cargo Monitor" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className={cx(s.second_step, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>2</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>S&P Fleet</h3>
                      <p className={s.description}>
                        As part of the Fleet Monitor, SHIPNEXT allows its users the search for vessels on sale. This is a combination of open market data and that, which is sourced from the user’s emails once the email flow is connected and processed with the help of the SHIPNEXT Exchange.
                      </p>
                      <p className={s.description}>
                        This solution can be used to store price idea, sale price, and the full set of technical and historic data of the vessel that may be relevant to the sale and purchase of the vessel.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/fm_step_2.png" alt="Cargo Tracking and Execution" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.third_step, s.divide_figure)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>3</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Building up a personal Fleet list</h3>
                      <p className={s.description}>
                        Working with a database of 87.000+ vessels may be more complicated than with a short list of vessels that may be of greater importance. SHIPNEXT allows its users, depending on their activity, to build a personal list of vessels to track.
                      </p>
                      <p className={s.description}>
                        While SHIPNEXT remains mainly a platform for commercial activity, the Fleet Monitor may also be used as an operational tool to automate and digitalize the workflows.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/fm_step_3.png" alt="Building up a personal Fleet list" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="use-case" />
        <div className={cx(s.use_case, s.bg_color, s.use_case_fm)}>
          <div className={s.container}>
            <h2 className={s.title_block}>Use Case</h2>
            <div className={s.wrapper_use_case}>
              <div className={s.wrapper_svg}>
                <FleetMonitor />
              </div>
              <div className={s.description_use_case}>
                <ul>
                  <li><h3>Carriers or Shipping Companies</h3> use Fleet Monitor to organize the display data related to their own fleet, and the fleet they charter in. The Fleet Monitor is also used for operational purposes, allowing operators to create their personal fleet lists and monitor their performance.</li>
                  <li><h3>Shipbrokers, Traders and Shippers</h3> use the Fleet Monitor to search for Fleet-related data, perform sanction and compliance checks, and monitor vessel’s performance prior fixing. They create their personal fleet list to follow them more closely on daily basis.  Shipbrokers use the Fleet Monitor to search and work with the Fleet which is for sale.</li>
                  <li><h3>Organizations and Marketplaces</h3> use the Fleet Monitor to get an overview of vessel’s current position, past and present performance, and compliance to international sanctions. The Fleet Monitor clearly shows each vessel’s route tracking, no matter whether the vessel’s radars were off, since data is collected from satellites, AIS and dynamic AIS signals.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="other-solutions" />
        <div className={s.other_solutions}>
          <div className={s.container}>
            <h2 className={s.title_block}>Other solutions</h2>
            <div className={s.links_other_solutions}>
              <Link className={s.part_link} to="/solution-shipnext-marketplace">
                <div>
                  <TradingDesk />
                  <h3>SHIPNEXT<br /> Trading Desk</h3>
                </div>
                <p>Ship Next Your Cargo</p>
              </Link>
              <Link className={s.part_link} to="/solution-shipnext-cargo-monitor">
                <div>
                  <CargoMonitor />
                  <h3>SHIPNEXT<br /> Cargo Monitor</h3>
                </div>
                <p>Digitize and Automate Your Supply Chain</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-exchange'}>
                <div>
                  <Exchange />
                  <h3>SHIPNEXT <br /> Exchange</h3>
                </div>
                <p>Optimize E-mail management and stay Connected</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-documents'}>
                <div>
                  <Documents />
                  <h3>SHIPNEXT <br /> Documents</h3>
                </div>
                <p>Your Contract Management</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-ports'}>
                <div>
                  <PortsIcon />
                  <h3>SHIPNEXT <br /> Ports</h3>
                </div>
                <p>Port Data-search Base</p>
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(s)(PageSolutionsFleetMonitor);
