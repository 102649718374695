import cx from "classnames";
import { CheckBox as Checkbox } from "../../../Monitor/Edit/VesselEdit";
import React from "react";
import { grabTypes } from "../../../Monitor/Edit/config";
import MenuItem from "material-ui/MenuItem";
import NarrowFormsyText from "../../../NewInputs/NarrowFormsyText";
import { MinMaxInput } from "./MinMaxInput";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { NarrowSelect } from "../../../NewInputs/NarrowSelect";
import s from './GrabsForm.scss'
import { ContentAddButton, ContentClearButton } from "../../../Common/AddButton";

const VOLUME_MIN = 0;
const VOLUME_MAX = 2000;

export const GrabsForm = withStyles(s)(({
  grabbed,
  geared,
  prefix,
  grabV2,
  blockTitleClass,
  onChangeGrabbed,
  onChange,
}) => {
  const rows = grabV2?.summary?.length ? grabV2.summary : [{}]

  const changeValue = (index, key, value) => {
    onChange({
      grabbed: key === 'type' || key === 'quantity' ? true : grabbed,
      geared: true,
      grabV2: {
        summary: rows.map((x, i) =>
          i === index ? { ...x, [key]: value } : x
        )
      },
    })
  }

  const handleAddRow = () => {
    onChange({ grabV2: { summary: [...rows, {}] } })
  }

  const handleRemoveRow = (index) => {
    onChange({ grabV2: { summary: rows.filter((_, i) => i !== index) } })
  }

  return (
    <div>
      <div className={cx(s.flex, s.items_center)}>
        <h6 className={cx(s.mr_18px, blockTitleClass)}>GRABS</h6>
        <div style={{ width: '150px', display: 'flex' }}>
          <Checkbox
            className={s.checkbox}
            name={`${prefix}[grabbed]`}
            label={'Grab Fitted'}
            checked={grabbed}
            onChange={onChangeGrabbed}
          />
        </div>
      </div>
      <div className={cx(s.flex, s.w_full)}>
        <div className={cx(s.w_full)}>
          {rows.map((row, i) => (
            <div key={i} className={cx(s.flex, s.items_center, s.w_full, s.gap_16px)}>
              <div style={{ width: '50%' }}>
                <NarrowSelect
                  floatingLabelText="Type"
                  name={`${prefix}[grabV2][${i}][type]`}
                  maxHeight={271}
                  fullWidth
                  value={row?.type || ''}
                  onChange={(_, value) => changeValue(i, 'type', value)}
                  disabled={geared === false}
                >
                  {grabTypes.map(grabType => (
                    <MenuItem key={grabType} value={grabType} primaryText={grabType}/>
                  ))}
                </NarrowSelect>
              </div>
              <div style={{ width: '10%', minWidth: '60px' }}>
                <NarrowFormsyText
                  floatingLabelText="Quantity"
                  validations="isNumeric,isInt,min:0,max:20"
                  validationErrors={{
                    isInt: 'only integer',
                    isNumeric: 'only integer',
                    min: '0 - 20',
                    max: '0 - 20',
                  }}
                  value={row?.quantity || ''}
                  name={`${prefix}[grabV2][${i}][quantity]`}
                  onBlur={(_, value) => changeValue(i, 'quantity', value)}
                  fullWidth
                  required={row?.volumeMin !== undefined || row?.volumeMax !== undefined}
                  requiredError="required"
                  disabled={!row?.type || grabbed === false}
                />
              </div>
              <div style={{ width: '24%' }}>
                <MinMaxInput
                  val={row?.volumeMax}
                  min={VOLUME_MIN}
                  max={VOLUME_MAX}
                >
                  <NarrowFormsyText
                    floatingLabelText="Volume min, cbm"
                    value={row?.volumeMin || ''}
                    name={`${prefix}[grabV2][${i}][volumeMin]`}
                    onBlur={(_, value) => changeValue(i, 'volumeMin', value)}
                    fullWidth
                    disabled={!row?.type || grabbed === false}
                  />
                </MinMaxInput>
              </div>
              <div style={{ width: '24%' }}>
                <MinMaxInput
                  val={row?.volumeMin}
                  min={VOLUME_MIN}
                  max={VOLUME_MAX}
                  type="max"
                >
                  <NarrowFormsyText
                    floatingLabelText="Volume max, cbm"
                    value={row?.volumeMax || ''}
                    name={`${prefix}[grabV2][${i}][volumeMax]`}
                    onBlur={(_, value) => changeValue(i, 'volumeMax', value)}
                    fullWidth
                    disabled={!row?.type || grabbed === false}
                  />
                </MinMaxInput>
              </div>
              <div>
                {i === rows.length - 1
                  ? <ContentAddButton onClick={handleAddRow}/>
                  : <ContentClearButton onClick={() => handleRemoveRow(i)}/>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
});
