import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import t from './../../Monitor/TableNew.scss';
import cx from 'classnames';
function SecondText({ children, style = {}, textAlign = 'unset', className = "", ...rest }) {
  return (
    <div style={{ ...style, textAlign }} className={cx(t.second_text, t.column_row, className)} {...rest}>
      {
        children
      }
    </div>
  )
}

export default withStyles(t)(SecondText)
