import PropTypes from 'prop-types';
import React, { Component } from "react";
import NarrowSelect from "../../NewInputs/NarrowSelect";
import Tags from "../../../core/api/Tags";
import MenuItem from "material-ui/MenuItem";
import Loader from './../CustomAutocomplete/Loader';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Tags.scss';
import cx from 'classnames'
import { computeColorOrDefault } from "./utils";
export class TagCategorySelect extends Component {
  static contextTypes = {
    showMessage: PropTypes.func
  };

  static defaultProps = {
    isDisabled: false
  }

  state = {
    categories: [],
    isLoading: true
  };

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    this.setState({
      isLoading: true
    })
    try {
      const res = await Tags.fetchCategories(this.props.entity);
      if (res.data) {
        this.setState({
          categories: res.data
        });
      }
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: "error",
        message: `Error fetching categories for ${this.props.entity} tags: ${error?.message ||
          error}`
      });
    } finally {
      this.setState({
        isLoading: false
      })
    }
  };

  renderCategories() {
    const { categories } = this.state;
    if (!categories.length) {
      return <MenuItem value={undefined}></MenuItem>;
    }

    return categories.map(category => (
      <MenuItem
        key={category._id || category.value}
        value={category._id}
        primaryText={<div className={s.category_info}>
          <span className={s.category_color} style={{backgroundColor: computeColorOrDefault({category})}}></span>
          <span className={s.category_name}>{category.value ?? "without category"}</span>
        </div>}
        className={s.category_item}
      >
      </MenuItem>
    ));
  }

  render() {
    const { isLoading } = this.state;
    const { className = "", loaderClassName = "", isDisabled } = this.props;
    return (
      <NarrowSelect
        name={"category"}
        autoWidth
        disabled={isDisabled}
        floatingLabelText="Category"
        style={{ marginLeft: "15px" }}
        {...this.props}
        className={className}
        value={this.props.value || ""}
        onChange={(ev, val) => this.props.handleChange(this.state.categories.find(c => c._id === val))}
      >
        {
          isLoading
            ? (
              <div className={cx(className, s.select_loader, loaderClassName)}>
                <Loader></Loader>
              </div>
            )
            : this.renderCategories()
        }
      </NarrowSelect>
    );
  }
}

export default withStyles(s)(TagCategorySelect);
