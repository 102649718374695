import React, { Component, PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import RaisedButton from '../../Common/RaisedButton';
import Formsy from 'formsy-react';
import SpinnerButton from '../../Common/SpinnerButton';
import FormsyText from '../../Common/FormsyText';
import cx from 'classnames';
import FormsyInput from '../../Common/FormsyInput';
import FormsyDateTime from '../../Common/FormsyDateTime';
import { connect } from 'react-redux';
import { sendDemoRequest } from '../../../actions/home';

const Form = Formsy.Form;

class RequestDemo extends Component {
  render() {
    return (
      <div className={s.wrapper_form}>
        <h3>{this.props.label}</h3>
        <form className={s.form_about}>
          <input type="text" placeholder={'Full name'} />
          <input type="text" placeholder={'Company'} />
          <input type="tel" placeholder={'Telephone'} />
          <input type="email" placeholder={'Email'} />
          <input type="text" placeholder={'Сonvenient date for the presentation (optional)'} />
          <input type="text" placeholder={'Your message (optional)'} />
          <RaisedButton
            label={
              true ?
                'Send request'
                :
                <div className={s.preloader_spinner} />
            }
            type={'submit'}
            primary
            style={{
              width: '142px',
              height: '40px',
              marginTop: '30px',
              marginLeft: 'auto',
            }}
            labelStyle={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              lineHeight: '40px',
            }}
          />
        </form>
        <div className={s.answer_send}>
          <p>
            Thank you,<br />
            your message is sent.
          </p>
          <RaisedButton
            label={'Ok'}
            type={'submit'}
            primary
            style={{
              width: '142px',
              height: '40px',
              marginTop: '24px',
              marginLeft: 'auto',
              display: 'inherit',
            }}
            labelStyle={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              lineHeight: '40px',
            }}
            onClick={this.props.handleCloseDialog ? this.props.handleCloseDialog : null}
          />
        </div>
      </div>
    );
  }
}


class RequestForm extends PureComponent {

  constructor(props) {
    super(props);
    const minDate = new Date();
    minDate.setHours(minDate.getHours() + 2);
    minDate.setMinutes(60);
    this.state = { minDate, sent: false };
  }
  static prevValues= {};

  componentDidMount() {
    //this.refs.form.reset(RequestForm.prevValues);
   /* if (this.state.sent) {
      this.setState({ sent: false });
    }*/
  }

  handleSubmit = () => {
    const form = this.refs.form;
    form.submit();
    if (!form.state.isValid) {
      return;
    }
    const request = form.getModel();
    if (request.date) {
      request.date = request.date.toISOString();
    }
    return this.props.sendDemoRequest(request).then((res) => {
      if (res.status === 200) {
        this.setState({ sent: true });
        RequestForm.prevValues.message = '';
        delete RequestForm.prevValues.date;
      }
      return res;
    });
  }
  handleOk = () => {
    this.refs.form.reset({name:"", company: "", email:"", phone:"", message:"" });
    this.setState({ sent: false });
    if (this.props.handleCloseDialog) {
      this.props.handleCloseDialog();
    }
  }
  handleChange = (currentValues, isChanged) => {
    if (isChanged) {
      delete currentValues.requestType;
      RequestForm.prevValues = { ...RequestForm.prevValues, ...currentValues };
    }
  }


  render() {
    return (
      <div className={cx(s.wrapper_form, this.state.sent && s.sent)}>
        <h3>{this.props.label}</h3>
        <Form onChange={this.handleChange} ref={'form'} className={s.form_about}>
          <FormsyInput type="hidden" name="requestType" value={this.props.requestType} />
          {/*{*/}
            {/*this.props.requestType === 'invest' ?*/}
              {/*<p className={s.text_for_contact_shipnext}>SHIPNEXT always welcomes new partners, creative ideas and cooperation. Interconnectivity and Interoperability is one of our key aims.</p>*/}
            {/*: null*/}
          {/*}*/}
          <FormsyText
            type="text" name="name" fullWidth
            floatingLabelStyle={{
              fontSize: '14px',
            }}
            inputStyle={{
              fontSize: '14px',
            }}
            hintText="Full Name*" className={s.input}
            validations="minLength:3"
            validationError="at least 3 characters"
          />
          <FormsyText
            type="text" name="company" fullWidth hintText="Company*" className={s.input}
            validations="minLength:3"
            validationError="at least 3 characters"
          />
          <FormsyText type="tel" name="phone" fullWidth hintText="Telephone*" className={s.input} />
          <FormsyText
            type="email" name="email" required requiredError="required" fullWidth
            hintText="Email*"
            className={s.input}
            validations="isEmail"
            validationError="please enter valid email"
          />
          <FormsyDateTime
            name="date" minDate={this.state.minDate} fullWidth
            hintText="Convenient date for the presentation"
            className={s.input}
          />
          <FormsyText type="text" multiLine name="message" fullWidth hintText="Your Message" className={s.input} rows={2} rowsMax={2} hintStyle={{ top: '12px', bottom: 'auto' }} />
          <SpinnerButton
            label="Send"
            onClick={this.handleSubmit}
            primary
            style={{
              width: '120px',
              height: '44px',
              marginTop: '14px',
              marginLeft: 'auto',
            }}
            buttonStyle={{
              borderRadius: '100px',
            }}
            labelStyle={{
              top: '-2px',
              display: 'inline-block',
              fontFamily: 'Roboto',
              fontSize: '14px',
              lineHeight: '44px',
              textTransform: 'capitalize',
            }}
          />
        </Form>
        <div className={s.answer_send}>
          <p>
            Thank you,<br />
            your message sent successfully.
          </p>
          <RaisedButton
            label={'Ok'}
            type={'submit'}
            primary
            style={{
              width: '120px',
              height: '44px',
              marginTop: '14px',
              marginLeft: 'auto',
              display: 'inherit',
            }}
            buttonStyle={{
              borderRadius: '100px',
            }}
            labelStyle={{
              top: '-2px',
              display: 'inline-block',
              fontFamily: 'Roboto',
              fontSize: '14px',
              lineHeight: '44px',
            }}
            onClick={this.handleOk}
          />
        </div>
      </div>
    );
  }
}

export default connect(state => (state.login), { sendDemoRequest })(withStyles(s)(RequestForm));
