const frontSortingKeys = (sort) => {
  const {field, value} = sort;

  if (field === 'added_date') {
    if (value === -1) return (elem1, elem2) => new Date(elem2.createdAt) - new Date(elem1.createdAt);
    if (value === 1) return (elem1, elem2) => new Date(elem1.createdAt) - new Date(elem2.createdAt);
    // return {
    //   '-1': (elem1, elem2) => new Date(elem2.createdAt) - new Date(elem1.createdAt),
    //   '1': (elem1, elem2) => new Date(elem1.createdAt) - new Date(elem2.createdAt)
    // }
  }
  if (field === "dwt") {
    if (value === -1) return (elem1, elem2) => elem2.vessel?.dw?.summer - elem1.vessel?.dw?.summer;
    if (value === 1) return (elem1, elem2) => elem1.vessel?.dw?.summer - elem2.vessel?.dw?.summer;
    // return {
    //   '-1': (elem1, elem2) => elem2.vessel?.dw?.summer - elem1.vessel?.dw?.summer,
    //   '1': (elem1, elem2) => elem1.vessel?.dw?.summer - elem2.vessel?.dw?.summer,
    // }
  }
  if (field === "capacity") {
    if (value === -1) return (elem1, elem2) => elem2.vessel?.capacity?.grain - elem1.vessel?.capacity?.grain;
    if (value === 1) return (elem1, elem2) => elem1.vessel?.capacity?.grain - elem2.vessel?.capacity?.grain;
    // return {
    //   '-1': (elem1, elem2) => elem2.vessel?.capacity?.grain - elem1.vessel?.capacity?.grain,
    //   '1': (elem1, elem2) => elem1.vessel?.capacity?.grain - elem2.vessel?.capacity?.grain,
    // }
  }
}

export default function SNP_ADD_SINGLE(state, { res, payload }) {
  const {sort} = payload;
  const dataList = [ ...state.snpList.data ];
  const filteredList = [
    ...state.snpList.filtered
  ];

  const searchFiled = (v) => {
    let search = '';
    search += v.vessel.imoNumber + ' ';
    search += v.vessel.name + ' ';
    search += v.vessel.refNo + ' ';
    v.vessel.owner ? search += v.vessel.owner.name + ' ' : '';
    return search;
  }

  const newOrder = {...res.data, search: searchFiled(res.data) }

  filteredList.push(newOrder);
  dataList.push(newOrder);

  const newFiltered = filteredList.sort(frontSortingKeys(sort));
  const newData = dataList.sort(frontSortingKeys(sort));

  return { ...state, snpList: { ...state.snpList, filtered: [...newFiltered], data: [...newData]}};
}
