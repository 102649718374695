/*
 * @Author: salterok
 * @Date: 2017-10-04 14:53:59
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-19 17:43:30
 */

import reducers from "./reducers";
import * as actions from "./actions";
import * as eventHandlers from "./eventHandler";
import Chat from "./Chat";

const defaultState = {
  messages: {},
  rooms: {},
  users: {},
  isShown: false,
  selectedContact: undefined,
  targetRoom: {}
};

const reducer = wrapReducers(reducers, defaultState);

const SocketMessageToAction = {
  "chat:messages": eventHandlers.messagesReceived,
  "chat:initialInfo": eventHandlers.initialInfo,
  "chat:incrementalUpdate": eventHandlers.incrementalUpdate,
  "chat:rooms": eventHandlers.changeRooms
};

const [handlers, mappers] = mapEventHandlers(eventHandlers, SocketMessageToAction);

export { reducer, actions, handlers, mappers, Chat };
export { CreateChatWrapper } from "./CreateChat";

// TODO: move to utils
function wrapReducers(reducers, defaultState) {
  return function(state = defaultState, action) {
    if (!reducers[action.type]) {
      return state;
    }
    try {
      return reducers[action.type](state, action) || state;
    } catch (e) {
      console.error(e);
      try {
        if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
          window.Raven.captureException(e);
        }
      } catch (e2) {
        console.error(e2);
      }
      return state;
    }
  };
}

function mapEventHandlers(handlers, map) {
  const resultsHandlers = {};
  const resultMap = {};

  const tempMapper = new Map();
  const prefix = Math.random() + "";

  for (const handlerName of Object.keys(handlers)) {
    const newName = prefix + (handlerName); // Symbol does not supported by redux logger lib
    tempMapper.set(handlers[handlerName], newName);
    resultsHandlers[newName] = handlers[handlerName];
  }

  for (const mapperName of Object.keys(map)) {
    const eventHandler = map[mapperName];
    const newName = tempMapper.get(eventHandler);
    resultMap[mapperName] = newName;
  }

  tempMapper.clear();
  return [resultsHandlers, resultMap];
}
