import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Medium = props => (
  <span {...props}>
    <svg width="45px" height="44px" viewBox="0 0 45 44" version="1.1">

        <title>Medium</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Web---Start"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Shipnext-1440" transform="translate(-1262.000000, -1754.000000)" fill="#FFFFFF">
            <g id="Group-5" transform="translate(951.000000, 1754.000000)">
                <g id="Group-3" transform="translate(87.000000, 0.000000)">
                    <g id="Icon/Social/Medium" transform="translate(224.551724, 0.000000)">
                        <circle id="Oval-4-Copy-5" opacity="0.33350317" cx="22" cy="22" r="22"></circle>
                        <path d="M15.6992476,18.6753279 C15.7197715,18.4725451 15.6424416,18.2721169 15.4910221,18.1356364 L13.9486102,16.2775556 L13.9486102,16 L18.737799,16 L22.4395874,24.1185023 L25.6940764,16 L30.2596154,16 L30.2596154,16.2775556 L28.9408533,17.5419758 C28.8271615,17.6286382 28.7707646,17.7710742 28.7943242,17.9120499 L28.7943242,27.2024539 C28.7707646,27.3434297 28.8271615,27.4858655 28.9408533,27.5725281 L30.2287672,28.8369482 L30.2287672,29.1145038 L23.7506375,29.1145038 L23.7506375,28.8369482 L25.0848237,27.5416886 C25.2159287,27.4106206 25.2159287,27.3720712 25.2159287,27.1716144 L25.2159287,19.6621924 L21.5064282,29.0836643 L21.0051444,29.0836643 L16.6863912,19.6621924 L16.6863912,25.9765831 C16.650383,26.2420579 16.7385518,26.5093329 16.9254651,26.7013118 L18.6606784,28.8061087 L18.6606784,29.0836643 L13.7403846,29.0836643 L13.7403846,28.8061087 L15.4755979,26.7013118 C15.6611502,26.5090146 15.7441779,26.2399666 15.6992476,25.9765831 L15.6992476,18.6753279 Z" id="Shape" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
        </g>
      </svg>
  </span>
);

Medium = pure(Medium);
Medium.displayName = 'Medium';
Medium.muiName = 'SvgIcon';

export default Medium;
