/** @name MY_CARGO_DETAILS_REQUEST_REDUCER */
export default function MY_CARGO_DETAILS_REQUEST(state, action) {
  const newState = {
    myCargoDetails: {
      ...state.myCargoDetails,
      selectedId: action.payload._id,
      loading: true,
      selected: action.payload.clear ? null : state.myCargoDetails.selected,
    },
  };
  return { ...state, ...newState };
}
