import React, {Component} from 'react';
import styled from "styled-components";
import {computeColorOrDefault, determineIfText} from "./utils";
import CloseIcon from "material-ui/svg-icons/navigation/close";

export default styled(({ className, tag = {}, handleDelete = () => undefined, ...props }) => {
  const categoryColor = computeColorOrDefault(tag);

  const isText = determineIfText(tag);

  return (
    <div className={className} style={{ backgroundColor: categoryColor, border: isText || tag.isCustom ? "1px solid var(--stroke-light-gray1)" : 'initial' }}>
      <span className={'chip_text'}>
        {
          isText
            ? tag.search
            : `#${tag.value || tag.label}`
        }
      </span>
      <CloseIcon onClick={handleDelete} color="var(--default-dark-text)" style={{ cursor: "pointer", width: 12, height: 12, marginLeft: 4 }}></CloseIcon>
    </div>
  );
})`
  max-width: 200px;
  margin-right: 4px;
  height: 16px;

  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  overflow: hidden;
  background: #A8C3DC;
  min-width: fit-content;
  .chip_text {
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    color: var(--default-dark-text);

    font-size: 10px;
  }
`
