export default function buildDeletePolygon() {
    function deletePolygonBtn() {
        this.div_ = document.createElement('div');
        this.div_.className = 'delete-menu';
        this.div_.innerHTML = 'Delete polygon';

        let self = this;

        google.maps.event.addDomListener(this.div_, 'click', function () {
            self.removeCurrentPolygon();
        });
    }

    deletePolygonBtn.prototype = new google.maps.OverlayView();

    deletePolygonBtn.prototype.removeCurrentPolygon = function() {
        this.get('onClick')();

        this.close();
    };

    deletePolygonBtn.prototype.onAdd = function () {
        let deleteMenu = this;
        let map = this.getMap();
        this.getPanes().floatPane.appendChild(this.div_);

        this.divListener_ = google.maps.event.addDomListener(map.getDiv(), 'mousedown', function (e) {
            if (e.target != deleteMenu.div_) {
                deleteMenu.close();
            }
        }, true);
    };

    deletePolygonBtn.prototype.onRemove = function () {
        google.maps.event.removeListener(this.divListener_);
        this.div_.parentNode.removeChild(this.div_);

        // clean up
        this.set('position');
        this.set('polygon');
        this.set('onClick');
    };

    deletePolygonBtn.prototype.open = function(map, polygon, event, onClick) {
        this.set('position', event.latLng);
        this.set('polygon', polygon);
        this.set('onClick', onClick);

        this.setMap(map);
        this.draw();
    };

    deletePolygonBtn.prototype.close = function() {
        this.setMap(null);
    };

    deletePolygonBtn.prototype.draw = function() {
        let position = this.get('position');
        let projection = this.getProjection();

        if (!position || !projection) {
            return;
        }

        let point = projection.fromLatLngToDivPixel(position);
        this.div_.style.top = point.y + 'px';
        this.div_.style.left = point.x + 'px';
    };

    return new deletePolygonBtn();
}
