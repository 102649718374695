export function convertPortsToServer(ports = []) {
  const ret = [];
  if (!ports.length) {
    return ports;
  }
  let curLeg = { oneOf: [] };
  for (let i = 0; i < ports.length; i++) {
    const port = { ...ports[i] };
    if (port._id && port._id.length > 24) {
      port._id = undefined;
    }
    if (port) {
      if (port.type !== 'alt') {
        curLeg = { _id: port.groupId, oneOf: [{ _id: port._id, port: port.port, details: port.details, readinessDate: port.readinessDate, cancellingDate: port.cancellingDate, layCanType: port.layCanType, additionalLayCanDetails: port.additionalLayCanDetails }] };
        ret.push(curLeg);
      } else {
        curLeg.oneOf.push({ _id: port._id, port: port.port, details: port.details, readinessDate: port.readinessDate, cancellingDate: port.cancellingDate, layCanType: port.layCanType, additionalLayCanDetails: port.additionalLayCanDetails });
      }
    }
  }
  return ret;
}
export const resetCargoId = cargo => {
  // if simple form recognition returned cargo without _id field, mock _id to fill cargo autocomplete but don't send cargoId to backend
  if (!cargo?.cargoName?.cargoWithoutId) {
    cargo.cargoId = cargo.cargoName && cargo.cargoName._id;
  }
  else {
    Reflect.deleteProperty(cargo.cargoName, "_id");
    Reflect.deleteProperty(cargo.cargoName, "cargoWithoutId");
  }
  return cargo;
};
