import PropTypes from 'prop-types';
import React from 'react';
import s from './Cargo.scss';
import Stowage from './Stowage';

export default class Clauses extends Stowage {
  static propTypes = {
    clausesFields: PropTypes.array,
    tabIndex: PropTypes.number,
    namePrefix: PropTypes.string,
  };

  render() {
    return (
      <div className={s.packing_container}>

        <div className={s.stowage_title}>
          CLAUSES
        </div>
        <div className={s.packing_container}>
          <div className="row">
            {this.renderFields([], this.props.clausesFields)}
          </div>
        </div>
      </div>
    );
  }
}
