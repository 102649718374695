/* @flow */
import React, { PureComponent } from 'react';
import type { Charge, TradeAble } from '../../../../interfaces/types';
import { v4 } from 'uuid';
import s from '../../Monitor.scss';
import FormsyText from '../../../Common/FormsyText';
import CurrencySelect from '../../../Common/CurrencySelect';
import FormsySelect from '../../../Common/Select';
import MenuItem from 'material-ui/MenuItem';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentClear from 'material-ui/svg-icons/content/clear';
import styled from 'styled-components';
import { ContentAddButton, ContentClearButton } from '../../../Common/AddButton';
import { MultiFreightEdit } from '../MultiFreight/MultiFreightEdit';
import { chargeTypesList } from './chargeTypes';

type Props = {
  charges: TradeAble<Charge>[];
  className: string;
  onChange: () => TradeAble<Charge>[];
  refMultiCharge: () => any;
}
type State = {
  charges: TradeAble<Charge>[];
}

export class ChargesEdit extends MultiFreightEdit<Props, State> {
  static RowComponent = ChargeRow;
  getNewRow() {
    return { key: Symbol(v4()), curr: { term: 'per mt', type: undefined, currency: 'USD' } };
  }


}


function ChargeRow({ item: { curr, prev = {} }, i, onChange, onDelete, onAdd }) {
  prev = prev || {};
  curr = curr || {};
  const isTypeRequired = !!curr.value;
  const isValueRequired = !!curr.type;
  return (<div className={'charge_row'}>
    <div>
      <FormsySelect
        hintText={`Surcharge Type${isTypeRequired ? '*' : ''}`}
        name={`_charges[${i}][type]`}
        fullWidth
        autoWidth
        previousValue={prev.type}
        value={curr.type}
        onChange={onChange.bind(this, 'type')}
        requiredError="required"
        required={isTypeRequired}
      >
        {chargeTypesList.map(ct => <MenuItem
          key={ct._id}
          value={ct._id}
          primaryText={ct.name}
        />)}
      </FormsySelect>
    </div>
    <div>
      <FormsyText
        hintText={`Surcharge${isValueRequired ? '*' : ''}`}
        validations="isNumeric,gt0"
        validationErrors={{
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        }}
        requiredError="required"
        name={`_charges[${i}][value]`}
        fullWidth
        previousValue={prev.value}
        value={curr.value}
        onBlur={onChange.bind(this, 'value')}
        required={isValueRequired}
      />
    </div>
    <CurrencySelect
      name={`_charges[${i}][currency]`}
      className={s.currency_select}
      previousValue={prev.currency}
      value={curr.currency}
      onChange={onChange.bind(this,'currency')}
    />
    <div>
      <FormsySelect
        hintText=" "
        requiredError="required"
        name={`_charges[${i}][term]`}
        fullWidth
        autoWidth
        required
        defaultValue={'per mt'}
        previousValue={prev.term}
        value={curr.term}
        onChange={onChange.bind(this,'term')}
      >
        <MenuItem
          key="per mt"
          value="per mt"
          primaryText="per mt"
        />
        <MenuItem
          key="per cbm"
          value="per cbm"
          primaryText="per cbm"
        />
        <MenuItem
          key="lump sum"
          value="lump sum"
          primaryText="lump sum"
        />
        <MenuItem
          key="per w/m"
          value="per w/m"
          primaryText="per w/m"
        />
        <MenuItem
          key="per unit"
          value="per unit"
          primaryText="per unit"
        />
        <MenuItem
          key="per box"
          value="per box"
          primaryText="per box"
        />
        <MenuItem
          key="per TEU"
          value="per TEU"
          primaryText="per TEU"
        />
        <MenuItem
          key="per FEU"
          value="per FEU"
          primaryText="per FEU"
        />
      </FormsySelect>
    </div>

    <div>
      <FormsyText
        hintText="Details"
        name={`_charges[${i}][details]`}
        fullWidth
        previousValue={prev.details}
        value={curr.details}
        onBlur={onChange.bind(this,'details')}
      />
    </div>
    <div>
      {i === 0 ?
        <ContentAddButton onClick={onAdd} />
        :
        <ContentClearButton onClick={onDelete} />
      }
    </div>
  </div>);
}

export default styled(ChargesEdit)`
  width: 100%;

  .charge_row {
    display: flex;

    > div + div {
      margin-left: 12px;
    }

    > div:last-child {
      margin-top: 14px;
    }

    > div:nth-child(1) {
      width: 131px;
    }

    > div:nth-child(2) {
      width: 109px;
    }

    > div:nth-child(3) {
      width: 60px;
    }

    > div:nth-child(4) {
      width: 110px;
    }

    > div:nth-child(5) {
      width: 224px;
    }
    .select >div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
      padding-right: 20px !important;
    }
  }
`;

