import React from 'react'
import BlurIfNoSubscription from '../../Signup/BlurIfNoSubscription';
import cx from 'classnames';
import ColoredText from '../../Monitor/RowBlocks/ColoredText';
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
function DisponentOwner({ vessel, className = "", ...rest }) {
  return (
    <ColoredText title={vessel?.disponentOwner?.name ? vessel.disponentOwner.name : undefined} className={cx(s.disponent_owner, className)} {...rest}>
      <BlurIfNoSubscription showPlaceholder>
        {vessel?.disponentOwner?.name ? vessel.disponentOwner.name : "---"}
      </BlurIfNoSubscription>
    </ColoredText>
  )
}

export default withStyles(s)(DisponentOwner);
