/** @name NEWS_ERROR */
export default function NEWS_ERROR(state, action) {
  const newState = {};

  newState.newsList = {
    ...state.newsList,
    loading: false,
    errorMessage: action.payload,
  };

  return { ...state, ...newState };
}
