import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import ContentAddCircle from 'material-ui/svg-icons/content/add-circle';
import s from './AddButton.scss';
import styled from 'styled-components';
import ContentClear from 'material-ui/svg-icons/content/clear';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from "material-ui/svg-icons/content/remove";


export default withStyles(s)((props) => {
  const { className, label, ...rest } = props;
  return (
    <span className={cx(s.add_button, className)} {...rest}>
      <ContentAddCircle /> {label}
    </span>
  );
});

export const ContentAddButton = styled(({ className, ...props }) => <ContentAdd className={className} {...props} />)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  color: #285596 !important;
  border: 1px solid #285596;
  background: transparent;
`;
export const ContentClearButton = styled(({ className, ...props }) => <ContentClear
  className={className} {...props} />)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  background: #F5F5F5;
`;

export const AddButtonBlue = styled(function({ className, label, ...props }){
  return <span className={className} {...props}>
    <span><ContentAdd /></span>
    <span>{label}</span>
  </span>;
})`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  color: var(--bg-blue);
  cursor: pointer;
   svg {
     width: 24px;
     height: 24px;
     border: 1px solid var(--bg-blue);
     border-radius: 50%;
     padding: 2px;
     margin-right: 12px;
     fill: var(--bg-blue) !important;
  }

`;

export const PlusButton = styled(({ className, ...props }) => <ContentAdd className={className} {...props} />)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  color: #979797 !important;
  background: #EDEDED;
`;

export const PlusButtonGreen = styled(PlusButton)`
  color: white !important;
  background: var(--secondary-color);
`

export const PlusButtonBlue = styled(PlusButton)`
  color: white !important;
  background: var(--bg-blue) !important;
`

export const MinusButton = styled(({ className, ...props }) => <ContentRemove className={className} {...props} />)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  color: #979797 !important;
  background: #EDEDED;
`;
