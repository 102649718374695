import React from 'react';
import { connect } from 'react-redux';

import { getInvoicePreview } from '../../actions/invoices';

export default {
    path: '/invoice-auth',
    async action({accessToken,store,invoiceId,next}) {
      const InvoiceAuth = ((await import(/* webpackChunkName: 'InvoiceAuth' */ './InvoiceAuth')).default);
      if (typeof window !== 'undefined' && invoiceId && invoiceId!=='-') {
        try {
          const res = await store.dispatch(getInvoicePreview(invoiceId, accessToken));
          if(res.status && res.status!==200) return await next();
        } catch (error) {
          console.log(error);
          return await next();
        }
      }
      return {
        title: 'Invoice preview',
        component: <InvoiceAuth invoiceId={invoiceId} />,
        public:true,
        robots: "noindex,follow",
      };
    },
};
