import features from './features';

function getFeaturesForPlan(features, featureCheck) {
  var listFeaturesCurrent = [];
  features.map((feature, i) => {
    featureCheck.includes(i) && listFeaturesCurrent.push(feature);
  })
  return listFeaturesCurrent;
}
function computeFeatures(featuresAll) {
    let featuresPlan= getFeaturesForPlan(features, featuresAll);
    return {
      featuresAll,
      featuresAllFirstHalf: featuresPlan.slice(0, Math.ceil(featuresPlan.length/2)),
      featuresAllLastHalf: featuresPlan.slice(Math.ceil(featuresPlan.length/2)),
    };
}
let plans = {
  'marketplace': {
    key: 'marketplace',
    className: 'marketplace',
    title: 'SHIPNEXT Trading Desk',
    titleSubscription: 'SHIPNEXT Trading Desk',
    shortDescriptionSubscription: 'Your Trading Desk on the Digital Shipping Marketplace',
    shortDescription: 'Trading Desk to access the Shipping Marketplace',
    featuresShort: [
      '24/7 Shipping Marketplace',
      'Instant Cargo-to-Ship or Ship-to-Cargo matching',
      'Import of open Ship positions and Cargo/Freight Requests',
      'White & Black lists',
      'Search Cargo/Freight requests',
      'Search for open Ship positions or Shipping solutions',
      'Automated Freight calculator',
    ],
    ...computeFeatures([0,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]),
  },
  'connect': {
    key: 'connect',
    title: 'SHIPNEXT Connect',
    className: 'connect',
    titleSubscription: 'SHIPNEXT Connect',
    shortDescriptionSubscription: 'E-mail processing and Cargo-to-Ship matching',
    shortDescription: 'E-mail processing and Cargo-to-Ship matching',
    featuresShort: [
      '24/7 Shipping Marketplace',
      'Instant Cargo-to-Ship or Ship-to-Cargo matching',
      'Import of open Ship positions and Cargo/Freight Requests',
      'Real-time email processing',
      'Distribute incoming emails to open Ship Positions',
    ],
    ...computeFeatures([0,2,3,19,20]),
  },
  'marketplace,connect': {
    key: 'marketplace,connect',
    className: 'professional',
    title: 'SHIPNEXT Professional',
    titleSubscription: 'SHIPNEXT Professional',
    shortDescriptionSubscription: 'Unlimited plan including the use of the Trading Desk and SHIPNEXT Connect',
    shortDescription: 'Unlimited plan including the use of the Trading Desk and SHIPNEXT Connect',
    featuresShort: [
      '24/7 Shipping Marketplace',
      'Market insights with News and Indexes',
      'Instant Cargo-to-Ship or Ship-to-Cargo matching',
      'Import of open Ship positions and Cargo/Freight Requests',
      'White & Black lists',
      'Search Cargo/Freight requests',
      'Real-time email processing',
      'Distribute incoming emails to open Ship Positions',
    ],
    ...computeFeatures([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]),
  },
  'free': {
    key: 'free',
    className: 'free_plan',
    title: 'SHIPNEXT FREE',
    titleSubscription: 'LIMITED Free Access',
    shortDescriptionSubscription: 'Free subscription',
    shortDescription: 'Limited Free subscription',
    ...computeFeatures([0,2,4,5,6,7,8,9,10]),
  },

};

plans['connect,marketplace'] = { ...plans['marketplace,connect'] };
plans['connect,marketplace'].key = 'connect,marketplace';


export default plans;
