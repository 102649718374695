exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2gQi7 ._3OUNo {\n  display: -ms-flexbox;\n  display: flex; }\n  ._2gQi7 ._3OUNo._19JLt {\n    -ms-flex-pack: end;\n        justify-content: flex-end; }\n  ._2gQi7 ._3OUNo._2UcVX {\n    -ms-flex-pack: start;\n        justify-content: flex-start; }\n  ._2gQi7 .FYG_C {\n  display: -ms-flexbox;\n  display: flex;\n  cursor: pointer;\n  -ms-flex-align: center;\n      align-items: center;\n  width: 116px;\n  height: 24px;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 24px;\n  margin: 8px 0;\n  color: var(--text-links);\n  position: relative; }\n  ._2gQi7 .FYG_C._3W4TX {\n    pointer-events: none;\n    cursor: initial;\n    opacity: 0.7;\n    color: var(--gray-color); }\n  ._2gQi7 .FYG_C._3W4TX ._2Lzc2 {\n      fill: var(--gray-color) !important; }\n  ._2gQi7 .FYG_C > input {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  visibility: hidden;\n  height: 100%;\n  opacity: 0; }\n  ._2gQi7 ._2Lzc2 {\n  fill: var(--text-links) !important;\n  margin-right: 8px;\n  -webkit-transform: rotate(45deg);\n          transform: rotate(45deg);\n  width: 20px !important;\n  height: 20px !important; }\n  ._2gQi7 ._32Xxk {\n  fill: var(--text-medium) !important;\n  margin-right: 8px; }\n  ._2SNGo {\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 22px;\n  margin-top: 16px;\n  margin-bottom: 16px; }\n  ._2SNGo ._2o5Mo {\n    background: #FFFDD6;\n    color: var(--text-warning);\n    border: 1px solid rgba(227, 72, 72, 0.3);\n    border-radius: 4px;\n    padding: 8px 12px 8px 12px;\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box; }\n  ._2SNGo ._2o5Mo > div:nth-child(2) {\n      border-top: 1px solid rgba(227, 72, 72, 0.3);\n      padding-top: 8px;\n      margin-top: 8px;\n      color: var(--text-dark333); }\n  ._2SNGo ._2o5Mo + ._2o5Mo {\n    margin-top: 4px; }\n", ""]);

// Exports
exports.locals = {
	"attachments": "_2gQi7",
	"attach_file_wrapper": "_3OUNo",
	"right": "_19JLt",
	"left": "_2UcVX",
	"attach_file": "FYG_C",
	"disabled": "_3W4TX",
	"attach_icon": "_2Lzc2",
	"attach_icon_second": "_32Xxk",
	"errors_block": "_2SNGo",
	"error": "_2o5Mo"
};