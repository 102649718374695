
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let ShareIcon = props => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 15.06C15.93 15.06 15.42 15.285 15.03 15.6375L9.6825 12.525C9.72 12.3525 9.75 12.18 9.75 12C9.75 11.82 9.72 11.6475 9.6825 11.475L14.97 8.3925C15.375 8.7675 15.9075 9 16.5 9C17.745 9 18.75 7.995 18.75 6.75C18.75 5.505 17.745 4.5 16.5 4.5C15.255 4.5 14.25 5.505 14.25 6.75C14.25 6.93 14.28 7.1025 14.3175 7.275L9.03 10.3575C8.625 9.9825 8.0925 9.75 7.5 9.75C6.255 9.75 5.25 10.755 5.25 12C5.25 13.245 6.255 14.25 7.5 14.25C8.0925 14.25 8.625 14.0175 9.03 13.6425L14.37 16.7625C14.3325 16.92 14.31 17.085 14.31 17.25C14.31 18.4575 15.2925 19.44 16.5 19.44C17.7075 19.44 18.69 18.4575 18.69 17.25C18.69 16.0425 17.7075 15.06 16.5 15.06Z" fill="#787878" />
      </svg>
    </span>
  </span>
);

ShareIcon = pure(ShareIcon);
ShareIcon.displayName = 'Ship';
ShareIcon.muiName = 'SvgIcon';

export default ShareIcon;
