import { getOrCreateTtlMap } from '../../core/ttlMap';
import MONITOR_CARGO_NEW from './MONITOR_CARGO_NEW';

const newCargoCache = getOrCreateTtlMap('MONITOR_CARGO_NEW', 10000);
/** @name MONITOR_CARGO_PATCH_REDUCER */
export default function MONITOR_CARGO_PATCH(state, { payload, currentUser }) {
  const newState = { ...state };
  if (!Array.isArray(payload)) {
    payload = [payload];
  }
  const toInsert = [];
  let someCargoPatched = false;
  newState.cargoList = { ...state.cargoList };
  newState.cargoList.data = [...state.cargoList.data];
  for (let i = 0; i < payload.length; i++) {
    const patch = payload[i];
    const index = state.cargoList.data.findIndex(c => c._id === patch._id);
    const oldCargo = state.cargoList.data[index];
    if (!oldCargo) {
      const cachedCargo = newCargoCache.get(patch._id);
      if (cachedCargo) {
        toInsert.push({ ...cachedCargo, ...patch });
        newCargoCache.delete(payload._id);
      }
    } else {
      const preserved = {};
      if (patch.userStatus) {
        if (oldCargo.userStatus && !oldCargo.userStatus.isPublic && patch.userStatus.isPublic) {
          preserved.userStatus = oldCargo.userStatus;
        }
      }
      if (patch.lastChange) {
        if (patch.lastChange.setBy === currentUser?._id) {
          patch.lastChange.lastSeenDate = patch.lastChange.setAt;
        }
      }
      const cargo = { ...oldCargo, ...patch, ...preserved };
      newState.cargoList.data[index] = cargo;
      someCargoPatched = true;
    }
  }
  let stateAfterInsert = newState;
  if (toInsert.length) {
    // eslint-disable-next-line new-cap
    stateAfterInsert = MONITOR_CARGO_NEW(state, { payload: toInsert, currentUser });
  }
  if (!someCargoPatched && stateAfterInsert === newState){
    return state;
  }
  return stateAfterInsert;
}
