import ContentAdd from 'material-ui/svg-icons/content/add';
import React from 'react'
import styled, { css } from 'styled-components';

const Round = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s ease-out;
  svg {
    fill: var(--main-color) !important;
  }
  &:hover {
    background-color: var(--main-color);
    svg {
      fill: #fff !important;
    }
  }
  ${props =>
    props.disabled && css`
      cursor: initial;
      pointer-events: none;
      opacity: 0.8;
    `
  }
`;

const Label = styled.h6`
  margin: 0px;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: ${props => props.color ?? 'var(--main-color)'};
  margin-left: 10px;
`;

const Wrapper = styled.div`
  cursor: pointer;
  transition: 0.3s ease-out;
  ${props =>
    props.disabled && css`
      cursor: initial;
      pointer-events: none;
      opacity: 0.6;
    `
  }
`;

function RoundBorderedPlus(props) {
  const { className = '', style = {}, label = '', onClick, disabled = false, ...rest } = props;
  return (
    <Wrapper disabled={disabled} onClick={disabled ? () => { } : onClick} style={style} className="align-items-center">
      <Round className={className}>
        <ContentAdd {...rest} />
      </Round>
      {
        label && <Label>{label}</Label>
      }
    </Wrapper>
  )
}

export default RoundBorderedPlus;
