import React, { Component } from 'react'
import styled, { css } from 'styled-components';

const _Layer = styled.div`
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${props => props.fullWidth ? css`
    & > * {
      width: 100% !important;
    }
  ` : ''}
`;

const Layers = styled.div`
  width: 100%;
  position: relative;
  z-index: 999;
  height: 100%;
`;
// LIFO component renders each child as a layer on top of each other.
// Last child has highest z-index therefore it's visible on the screen.
// Other layers are rendered but stay underneath the top layer.
// Used to keep DOM state (especially useful when dealing with forms or list scrolls)
export class LIFO extends Component {

  static Layer = ({ layer, children, fullWidth = true, style = {}, ...rest }) => (
    <_Layer fullWidth={fullWidth} style={{ zIndex: 1000 + layer, ...style, }} {...rest}>
      {
        children
      }
    </_Layer>
  )

  mergeStyles = (shouldHideLayerFromDOM = false, ...styles) => {
    let style = {};
    if (typeof style !== "object" || style === null) {
      style = {};
    }
    if (shouldHideLayerFromDOM) {
      style = {
        ...style,
        display: "none",
      };
    }
    const result = Object.assign(style, ...styles);
    return result;
  }

  mapChildLayer = (child, i) => {
    const { Layer = this.constructor.Layer } = this.props;
    const { children: stack, hideBottomLayers = false, layerProps = {} } = this.props;
    const { layerProps: childLayerProps = {} } = child.props;
    const additionalProps = (typeof layerProps === 'function' ? layerProps(child, i) : layerProps);

    const { style = {}, ...restAdditionalProps } = additionalProps;
    const { style: childLayerStyle = {}, ...restChildLayerProps } = childLayerProps;
    return (
      <Layer
        key={child.props?.keyId ?? i}
        style={this.mergeStyles(hideBottomLayers && i < stack.length - 1, style, childLayerStyle)}
        layer={i}
        {...Object.assign(restAdditionalProps, restChildLayerProps)}
      >
        { child }
      </Layer>
    );
  };

  renderStack = stack => {
    // React.Children.map adds index as prefix to each child key
    // this cause remount of each child on each update
    // to prevent remounting every child, i check if children is array and map it directly
    // to preserve authentic child key without any indexes
    if (Array.isArray(stack)) {
      return stack.map(this.mapChildLayer);
    }

    return this.mapChildLayer(stack, 0);
  }

  render() {
    const { children: stack, layerProps, ...rest } = this.props;

    return (
      <Layers {...rest}>
        {
          this.renderStack(stack)
        }
      </Layers>
    );
  }
}

export default LIFO;
