import React, { Component } from 'react';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';

export class CollapseField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleCollapse = () => {
    this.setState((state) => ({
      open: !state.open,
    }));
  };

  render() {
    const { title, children } = this.props;
    const { open } = this.state;
    let { headerStyle = {} } = this.props;
        headerStyle = { cursor: !children ? 'default' : 'pointer', ...headerStyle };

    return (
      <div>
        <div style={{ display: "flex", alignItems: "end", ...headerStyle }} onClick={this.toggleCollapse}>
          <div style={{ marginRight: "5px" }}>{title}</div>
          {
            children
              ? <ArrowUp style={{ transition: '0.2s ease-in-out', transform: `rotate(${open ? '0deg' : '-180deg'})` }} />
              : null
          }
        </div>
        {open && (
          <div>{children}</div>
        )}
      </div>
    );
  }
}

export default CollapseField;
