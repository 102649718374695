import PropTypes from 'prop-types';
import React, { Component } from 'react'
import cx from 'classnames';
import TagsAutocomplete from '../CustomAutocomplete/TagsAutocomplete';
import Formsy from 'formsy-react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Tags.scss';
import TagCategorySelect from './TagCategorySelect';
import { withReset } from '../../../core/HOC';
import SpinnerButton from './../SpinnerButton';
import {HiddenButtons} from "../HiddenButtons";
export class TagCreation extends Component {

  static defaultProps = {
    tags: [],
    saveTags: () => { },
    autocompleteProps: {},
  }

  static contextTypes = {
    showMessage: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      currentTag: "",
      currentCategory: "",
      tagError: "",
      isTagValid: true
    }
  }

  handleChangeTag = async val => {
    try {
      await this.handleGenericChange(state => ({
        currentTag: val,
        currentCategory: val.length >= 1 ? state.currentCategory : "",
      }));
      this.setChangedBasedOnTags();
    } catch (error) {
      console.error(error);
    }
    /* this.setState(state => ({
      currentTag: val,
      currentCategory: val.length >= 1 ? state.currentCategory : "",
      tagError: "",
      isTagValid: true
    }), this.setChangedBasedOnTags); */
  }

  handleSelectTag = async tag => {
    /* this.setState( state => ({
      currentCategory: tag.category || "",
      currentTag: tag
    }));
    this.props.setChanged(true); */
    try {
      await this.handleGenericChange({
        currentCategory: tag.category || "",
        currentTag: tag
      });
      this.props.setChanged(true);
    } catch (error) {
      console.error(error);
    }
  }

  handleChangeCategory = val => {
    /* this.setState({
      currentCategory: val
    }) */
    return this.handleGenericChange({
      currentCategory: val
    });
  }

  setChangedBasedOnTags = () => {
    const val = typeof this.state.currentTag === 'string' ? this.state.currentTag : this.state.currentTag.value;
    //this.props.setChanged(val.length >= 2 || (this.state.tags.length ? this.state.tags !== this.props.tags : false))
    this.props.setChanged(val.length > 0);
  }

  resetForm = async () => {
    /* this.setState({
      currentTag: "",
      currentCategory: "",
      tagError: "",
      isTagValid: true
    });
    this.props.setChanged(false); */
    try {
      await this.handleGenericChange({
        currentTag: "",
        currentCategory: ""
      });
      this.props.setChanged(false);
    } catch (error) {
      console.error(error);
    }
  }

  mapTagValue = value => typeof value === 'string' ? value : value?.value || ""

  handleGenericChange = values => {
    return new Promise((resolve, reject) => {
      this.setState(state => {
        let draft = {
          ...state,
          tagError: "",
          isTagValid: true,
        };
        try {
          if (typeof values === 'function') {
            draft = {
              ...draft,
              ...values(state)
            };
          }
          else if (!!values) {
            draft = {
              ...draft,
              ...values
            };
          }
        } catch (error) {
          console.error(error);
          reject(error)
        } finally {
          return draft;
        }
      }, resolve)
    })
  }

  saveTags = () => {
    const alreadyExists = this.props.tags.find(tag => {
      return String(tag.value).toLowerCase().trim() === String(this.mapTagValue(this.state.currentTag)).toLowerCase().trim() && tag.category?._id === this.state.currentCategory?._id
    });

    if (alreadyExists) {
      /* this.setState({
        tagError: "This tag already exists",
        isTagValid: false
      }); */
      this.handleGenericChange({
        tagError: "This tag already exists",
        isTagValid: false
      })
      return;
    }
    const tag = {
      ...(typeof this.state.currentTag === 'object' ? this.state.currentTag : { value: this.state.currentTag }),
    }
    if (this.state.currentCategory) {
      tag.categoryId = this.state.currentCategory?._id || "";
      tag.category = this.state.currentCategory ? this.state.currentCategory : this.state.currentTag.category
    }
    return this.props.saveTags(tag).then(this.successSaving)
  }

  successSaving = res => {
    this.resetForm();
  }

  render() {
    const { currentCategory, currentTag, tagError, isTagValid } = this.state;
    const { entity, reset, isChanged } = this.props;
    const invalid = isTagValid === false ? true : undefined;
    return (
      <div className={cx(s.tags_wrapper, invalid ? s.invalid : "")}>
        <Formsy.Form>
          <TagsAutocomplete invalid={invalid} errorText={tagError} value={currentTag} handleChange={this.handleChangeTag} handleSelect={this.handleSelectTag} className={s.tags_autocomplete} entity={entity} autocompleteProps={this.props.autocompleteProps}/>
          <TagCategorySelect isDisabled={!currentTag?._id && currentTag?.length < 2} value={currentCategory?._id} handleChange={this.handleChangeCategory} className={s.category_select} entity={entity}/>
          <div className={s.buttons}>
            <HiddenButtons
              shouldShow={isChanged}
              handleCancel={reset}
              handleSubmit={this.saveTags}
              buttonClassName={s.buttons_btn}
              submitButton={SpinnerButton}
            />
          </div>
        </Formsy.Form>
      </div>

    )
  }
}

export default withStyles(s)(withReset()(TagCreation));
