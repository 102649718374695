export default function analytics(state = {}, { type }) {
  try {
    if (type && type !== 'LOADING' && type !== 'MONITOR_UPDATE_VESSEL_LOCATION') {
      if (typeof window !== 'undefined') {
        if (window.ga) {
          //window.ga('send', 'event', 'all', type);
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
  return state;
}
