import { isUserSubscribedToMarketplace, grabErrorMessage, postRegularForm } from './../../core/utils';

// export function vesselSelectMenu() {
//   return [
//     {
//       id: 1,
//       name: () => this.state.showingHidden ? "Show" : "Hide",
//       disabled: (props) => isUserSubscribedToMarketplace(props.user) && props.vessels.some(row => !!row?.selected),
//       handler: this.hideUnhide
//     },
//   ]
// }
// was extracted to separate file to prevent cross dependency (e.g when component imports actions file and actions file imports component)
// sort fields for VesselTableNew component
export const sortFields = {
  updateAt: {
    field: 'updateAt', value: -1, label: 'Last updated',
  },
  dwt: {
    field: 'dwt',
    value: 1,
    label: 'Deadweight',
  },
  capacity: {
    field: 'capacity',
    value: 1,
    label: 'Capacity',
  },
  status: {
    field: 'status',
    value: -1,
    label: 'Status',
    isLinerTraderFirst: true
  }
};
// used in reducers/monitor and actions/monitor to set default sort field
export const defaultSortField = sortFields.status;

export const cargoSortFields = {
  addedAt: {
    field: 'addedAt', value: -1, label: 'Status',
  },
  'totalValues.weight': {
    field: 'totalValues.weight',
    value: 1,
    label: 'Weight',
  },
  'totalValues.volume': {
    field: 'totalValues.volume',
    value: 1,
    label: 'Volume',
  },
  readinessDate: {
    field: 'readinessDate',
    value: -1,
    label: 'Laycan',
  }
};

export const defaultCargoSortField = cargoSortFields.addedAt;

const isSelected = row => !!row?.selected

// selectors shared by VesselTableNew and VesselTableOpenCargo
export const showHideName = (state, props) => props.isHidden ? "Show" : "Hide";
export const showHideDisabled = props => isUserSubscribedToMarketplace(props.user);

// helpers
export const ROW_HEIGHT = 48;
// after hide / unhide i count remaining rows and if there are enough to show scroll do nothing; else load new rows to preventuser from reloading page
// because he doesn't have scroll to load more rows.
export const loadMoreRowsIfNoScroll = ({
  wrapper: _wrapper,
  header: _header,
}, rows, loadMoreCallback) => {
  // load more vessels only if there's no space to scroll to prevent user from reloading page
  if (_wrapper && _header) {
    const bodyHeight = _wrapper.clientHeight - _header.clientHeight;
    const rowsHeight = (rows?.length - 3) * ROW_HEIGHT;
    if (rowsHeight > bodyHeight) return;
    if (loadMoreCallback) {
      loadMoreCallback();
    }
  }
}
export const isSomeVesselsSelected = vessels => vessels.some(isSelected);
export const getSelected = vessels => vessels ? vessels.filter(isSelected) : [];
export const transformSelected = vessels => getSelected(vessels).map(row => row._id)
export const formErrorMessage = (error, shouldHide) => ({
  level: "error",
  message: `Error ${shouldHide ? "hiding" : "showing"} vessels : ${grabErrorMessage(error)}`
});
export const markVesselAsSelected = (vessel, isDelete) => ({
  vesselId: vessel._id,
  data: {
    ...vessel,
    inSelectedVessels: !isDelete,
  }
})
