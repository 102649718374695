import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Channels.scss';
import sm from '../TableNew.scss';
import Channel from './Channel';
import cx from 'classnames';
import DialogHalfScreen from "../../Common/DialogHalfScreen";
import EmailDetails from "../../Emails/EmailDetails";
import SelectableElement from '../../IssueReport/SelectableElement';

class Channels extends PureComponent {
  static propTypes = {
    channels: PropTypes.array,
    selectable: PropTypes.bool,
    cargo: PropTypes.object,
    vessel: PropTypes.object,
    basePath: PropTypes.string
  };

  static defaultProps = {
    channels: [],
    selectable: true,
    channelsChanged: () => undefined
  };
  state = {
    toggled: {},
  };

  openMailThread = (channel) => {
    this.setState({ mailThreadOpened: true, threadId: channel.threadId });
  }
  closeMailThread = () => {
    this.setState({ mailThreadOpened: false, threadId: null });
  }


  render() {
    const {channels} = this.props;
    const haveDetails = channels ? channels.find(c => c.haveDetails) : false;
    return (<div className={s.select_chanel_list}>
      <div className={s.headers}>
        {/* <div className={cx(s.header, s.fixed)}>Fixed</div> */}
        <div style={{ marginLeft: '8px', minHeight: 'auto' }} className={cx(s.header, s.position)}>Position</div>
        <div style={{ marginLeft: "-4px" }} className={cx(s.header, s.company)}>Company</div>
        <div className={cx(s.header, s.status)}>Status</div>
      </div>
      {channels.map((channel) => {
        return (
          <SelectableElement entity="positions" entityId={channel._id} type="position ref" id={channel.refNo}>
            <Channel
              changePositionStatus={this.props.changePositionStatus}
              orderId={this.props.orderId}
              channelsChanged={this.props.channelsChanged}
              channel={channel}
              channelsCount={channels.length}
              cargo={this.props.cargo}
              vessel={this.props.vessel}
              basePath={this.props.basePath}
              openMailThread={this.openMailThread.bind(this, channel)}
            />
          </SelectableElement>

        );
      })}
      {this.state.mailThreadOpened ? <DialogHalfScreen key={this.state.threadId} position="left" open><EmailDetails
        emailId={this.state.threadId}
        handleClose={this.closeMailThread}
        showRecognizedItems={false}
        mailFlowProps={{ transitionLockerPredicate: (location, action) => location.pathname.indexOf(`/${this.props.vessel._id}/`) === -1 }}
        shouldConfirmClose
      /></DialogHalfScreen> : null}
    </div>);
  }
}

export default withStyles(s)(withStyles(sm)(Channels));
