export default function(state, { res, payload = {} }) {

  let { data, page, ...rest } = res;

  if (page > state.page) {
    data = [
      ...state.data,
      ...data
    ]
  }

  const { filters = {}, sort = {}, isHidden = false } = payload;

  let newState = {
    ...state,
    ...rest,
    filters,
    isHidden,
    sort,
    data,
    page,
    fetchFailed: false,
  };

  if (!res.next) {
    Reflect.deleteProperty(newState, "next");
  }

  return newState;
}
