import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../Pages.scss';

class ShipnextSupplyChainEcosystem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={s.shipnext_supply_chain_ecosystem}>

        <div className={s.container}>
          <div className={s.title}>
            <h2>Supply Chain Ecosystem</h2>
          </div>
          <div className={s.body_shipnext_ecosystem}>

            <div className={s.wrap_ecosystem}>
              <div className={s.eco_left}>
                <h2 className={cx(s.title_wrap, s.title_desktop)}>Future Logistics Ecosystem</h2>
                <p>An integrated and decentralized blockchain solution across the entire door-to-door Supply Chain, connecting private supply chains into one reliable, cost- and time-efficient Supply Chain Ecosystem.
                </p>
                <br/>
                <p>This includes all sectors of transportation:</p>
                <ul>
                  <li> maritime transport (bulk, breakbulk and containers)</li>
                  <li>road transport</li>
                  <li>rail transport</li>
                  <li>air transportation</li>
                </ul>
                <p>transport infrastructure (ports, terminals, etc), organizations and service providers with the help of transparent and reliable algorithm-based processes, Smart Contracts as well as advanced
                  and secure electronic transactions.</p>
                <p className={s.dop_text}><span className={s.title_input}>SHIPNEXT Supply Chain</span><br /> A demand driven production and supply chain ecosystem, accompanied by accurate demand driven supply planning and trade finance, enabling secure, cost- and time-efficient logistics, transportation and settlement, improved production planning.</p>
              </div>
              <div className={s.future_logistic}>
                <h2 className={cx(s.title_wrap, s.title_mob)}>Future Logistics Ecosystem</h2>
                <img src="/images/shipnextecosystem/future-logistics@2x.png" alt="Future Logistics Ecosystem" />
              </div>
            </div>

            <div className={s.wrap_ecosystem}>
              <div className={s.eco_left}>
                <h2 className={cx(s.title_wrap, s.title_desktop)}>Environmental Impact</h2>
                <p>The use of Linear programming, big-data analysis and algorithms will result in higher efficiency of shipping through reduced ballast run and voyage optimization.
                  SHIPNEXT algorithms can also help increase the utilization of Ship's cargo intake, thus optimizing supply chain and reducing emission. </p>
              </div>
              <div>
                <h2 className={cx(s.title_wrap, s.title_mob)}>Environmental Impact</h2>
                <img src="/images/shipnextecosystem/environmental-impact@2x.png" alt="Environmental Impact" className="environment" />
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default (withStyles(s)(ShipnextSupplyChainEcosystem));
