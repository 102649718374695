import React, { Component } from 'react'
import CompanyAutocomplete from '../Common/CompanyAutocomplete';
import styles from './muistyles'
import cx from 'classnames'
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class NarrowCompanyAutocomplete extends Component {
    render() {
        const {inputStyles, classes, ...props} = this.props;
        return (
            <CompanyAutocomplete
                className={cx("input_default", classes)}
                textFieldStyle={styles.container}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={styles.text.label}
                hintStyle={styles.text.label}
                inputStyle={{...styles.text.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={styles.text.underline}
                underlineFocusStyle={styles.text.underlineFocus}
                {...props}
            >

            </CompanyAutocomplete>
        )
    }
}

export default withStyles(s)(NarrowCompanyAutocomplete)
