import React from 'react';
import s from './Attachments.scss';
import ContentClear from 'material-ui/svg-icons/content/clear';
import IconAttachFile from 'material-ui/svg-icons/editor/attach-file';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { uploadImage } from './../../../actions/login';
import {fileToFormData, getAttachmentAProps} from './../../../core/utils';
import { connect } from 'react-redux';

function Attachments({ attachments, isNew = false, handleRemove }) {
  if (!attachments || !attachments.length) {
    return null;
  }
  if (attachments && attachments.length > 0) {
    attachments = attachments.map(a => {
      a._id = a._id || a.fileId;
      return a;
    });
  }
  return (
    <div style={{ paddingTop: "8px" }}>
      {isNew ?
        attachments.map(file => {
          if (!file._id) {
            return null;
          }
          return (
            <Attachment handleRemove={handleRemove} attachment={file} />
          );
        })
      :
        attachments.map(file => {
          if (!file._id) {
            return null;
          }
          return (
            <div className={s.attachment_file}>
              <IconAttachFile className={s.attach_icon} />
              <a
                {...getAttachmentAProps(file)}
              >
                {file.name}
              </a>
            </div>
          );
        })
      }
    </div>
  );
}
export async function uploadFile(upload = uploadImage, e, uploadOpts) {
  const input = e.target;
  const formData = fileToFormData(input.files && input.files[0], 'attachment');
  if (!formData) return;
  return upload(formData, uploadOpts);
}

export function _AddAttachment({ success, uploadImage, changesCallback = () => undefined, uploadOpts }) {
  const updated = async ev => {
    try {
      const res = await uploadFile(uploadImage, ev, uploadOpts);
      if (!res?.errors?.length) {
        success(res.data);
        changesCallback();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={s.add_attachments}>
      <label htmlFor={'attach_file_offer'}>
          <IconAttachFile
              style={{
                  width: '20px',
                  height: '20px',
                  transform: 'rotate(45deg)',
              }}
          />
          <span>Attach file</span>
          <input accept=".xls, .xlsx, .txt, .csv, .pdf, .jpg, .jpeg, .png, .doc, .docx, .rtf, .tiff, .dwg, .dxf, .vsd, .xlsm, .zip, .rar, .7z" id={'attach_file_offer'} type="file" onChange={updated} />
      </label>
    </div>
  );
}

export const AddAttachment = connect(null, { uploadImage })(withStyles(s)(_AddAttachment));

export function Attachment({ attachment, handleRemove = null }) {
  if (!attachment._id) {
    return null;
  }
  return (
    <div className={s.label_select}>
      <div className={s.label_name}>
        <IconAttachFile
          style={{
              width: '20px',
              height: '20px',
              transform: 'rotate(45deg)',
              marginRight: '8px',
          }}
          color={'#787878'}
        />
        <a {...getAttachmentAProps(attachment)}>
            {attachment.name}
        </a>
      </div>
      {
        handleRemove
          ? <ContentClear style={{ marginLeft: '8px' }} onClick={e => handleRemove(e, attachment._id)} className={s.delete} />
          : null
      }
    </div>
  );
}

export default withStyles(s)(Attachments);
