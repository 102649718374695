/** @name SENT_TCOFFER_LIST_REDUCER */
export default function SENT_TCOFFER_LIST(state, { res, payload }) {
  const newTcOfferList = { ...state.sentTcOfferList };
  if (res.page === 1) {
    newTcOfferList.data = res.data;
  } else {
    newTcOfferList.data = [...newTcOfferList.data, ...res.data];
  }
  newTcOfferList.page = res.page;
  newTcOfferList.total = res.total;
  newTcOfferList.next = res.next;
  newTcOfferList.filters = payload.filters;
  newTcOfferList.sort = payload.sort;
  newTcOfferList.loading = false;
  newTcOfferList.dark = true;
  const newState = { sentTcOfferList: newTcOfferList };
  return { ...state, ...newState };
}
