export const sortFields = {
  capacity: {
    field: 'capacity', value: 1, label: 'Capacity',
  },
  deadweight: {
    field: 'deadweight',
    value: 1,
    label: 'Deadweight',
  },
  status: {
    field: 'status',
    value: 1,
    label: 'Status',
  },
};
export const defaultSortField = {
  ...sortFields.status,
  value: -1,
};
