import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../styles/Dashboard.scss';
import { TextField } from 'material-ui';
import CustomCheckbox from './../../../Common/CustomCheckbox'
import cx from 'classnames';

import { AutoSizer, List } from 'react-virtualized'

class RegionList extends Component {
  static contextTypes = {
    areas: PropTypes.array,
  };

  static propTypes = {

  };

  static defaultProps = {

  };

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      onlyMyRegions: false,
      filteredRegions: [],
    };

    this.renderRow = this.renderRow.bind(this);
    this.showOnlyMy = this.showOnlyMy.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
  }

  componentDidMount() {
    this.handleFiltering(this.props.allRegions);
  }

  componentWillReceiveProps(nextProps) {
    this.handleFiltering(nextProps.allRegions);
  }

  showOnlyMy(e, value) {
    this.setState({
      onlyMyRegions: value,
    }, () => {
      this.handleFiltering(this.props.allRegions);
    });
  }

  handleSearch(e, value) {
    this.setState({
      search: value,
    }, () => {
      this.handleFiltering(this.props.allRegions);
    });
  };

  handleFiltering(allRegions) {
    const normalized = String(this.state.search.toLowerCase().trim());
    const filteredRegions = allRegions.filter((region) => {
        if(this.state.onlyMyRegions) {
          return region.addedBy && region.name.toLowerCase().includes(this.state.search.toLowerCase());
        } else {
          return region.name.toLowerCase().includes(normalized) || region.alt?.find?.(altName => altName && String(altName).toLowerCase().trim().includes(normalized));
        }
    });

    this.setState({
      filteredRegions,
    })
  }

  renderRow({ index, key, style }) {
    const area = this.state.filteredRegions[index];
    const activeArea = area._id === (this.props.selectedRegion && this.props.selectedRegion._id);

    return (
      <div
        style={{ ...style, display: 'flex', cursor: 'pointer', justifyContent: 'space-between'}}
        key={key}
        className={cx(activeArea ? s.active_item : null)}
        onClick={() => { this.props.changeParentState('selectedRegion', area) }}
      >
        {area.name}
      </div>
    );
  }

  render() {
    const { search, onlyMyRegions, filteredRegions } = this.state;
    const { selectedRegion } = this.props;

    return <div>
      <CustomCheckbox
        value={onlyMyRegions}
        label='Only my regions'
        onCheck={this.showOnlyMy}
        style={{
          marginTop:'20px'
        }}
        labelStyle={{
          fontSize:'14px',
          lineHeight: '25px',
          color: 'var(--text-dark)'
        }}
        iconStyle={{
          marginRight: '10px'
        }}
      />

      <TextField
        hintText='Search'
        floatingLabelText='Search'
        name='search'
        value={search}
        onChange={this.handleSearch}
        inputStyle={{
          fontSize: '14px',
          color: 'rgba(0,0,0,0.87)'
        }}
        style={{
          width: 'calc(100% - 20px)'
        }}
      />

      <div className={s.wrapper_list_regions}>
        <AutoSizer>
          {
            ({ width, height }) => {
              return <List
                ref={list => { this.list = list; } }
                _updateOnly={filteredRegions.length}
                selectedRegion={selectedRegion}
                rowRenderer={this.renderRow}
                height={height}
                width={width}
                rowHeight={32}
                rowCount={filteredRegions.length}
                className={s.list_regions}
              />
            }
          }
        </AutoSizer>
      </div>
    </div>
  }
}

export default withStyles(s)(RegionList);
