import buildUndoDeleteButton from './UndoDeleteButton';

export default function buildDeleteBtn() {
    function DeleteMenu() {
        this.div_ = document.createElement('div');
        this.div_.className = 'delete-menu';
        this.div_.innerHTML = 'Delete';

        let menu = this;
        this.undoDeletePoligon = buildUndoDeleteButton();

        google.maps.event.addDomListener(this.div_, 'click', function () {
            menu.removeVertex();
        });
    }

    DeleteMenu.prototype = new google.maps.OverlayView();

    DeleteMenu.prototype.onAdd = function () {
        let deleteMenu = this;
        let map = this.getMap();
        this.getPanes().floatPane.appendChild(this.div_);

        // mousedown anywhere on the map except on the menu div will close the
        // menu.
        this.divListener_ = google.maps.event.addDomListener(map.getDiv(), 'mousedown', function (e) {

            if (e.target != deleteMenu.div_) {
                deleteMenu.close();
            }
        }, true);
    };

    DeleteMenu.prototype.onRemove = function () {
        google.maps.event.removeListener(this.divListener_);
        this.div_.parentNode.removeChild(this.div_);

        // clean up
        this.set('position');
        this.set('path');
        this.set('vertex');
    };

    DeleteMenu.prototype.close = function () {
        this.setMap(null);
    };

    DeleteMenu.prototype.draw = function () {
        let position = this.get('position');
        let projection = this.getProjection();

        if (!(position && projection)) {
            return;
        }

        let point = projection.fromLatLngToDivPixel(position);
        this.div_.style.top = point.y + 'px';
        this.div_.style.left = point.x + 'px';
    };

    DeleteMenu.prototype.open = function (map, path, vertex) {
        this.set('position', path.getAt(vertex));
        this.set('path', path);
        this.set('vertex', vertex);
        this.setMap(map);
        this.draw();
    };

    DeleteMenu.prototype.removeVertex = function () {
        let path = this.get('path');
        let vertex = this.get('vertex');

        if (!path || vertex == undefined) {
            this.close();
            return;
        }

        this.undoDeletePoligon.open(this.map, path, vertex);

        path.removeAt(vertex);
        this.close();
    };

    return new DeleteMenu();
}
