import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import Link from '../../Link/Link';

class SolutionsMarketplace extends Component {
  render() {
    return (
      <div className={s.solution}>
        <div className={s.tab_block}>
          <div className={s.left_part}>
            <div className={s.wrapper_screen}>
              <img src="/images/solutions_screen/trading_desk1.png" alt="Trading Desk" />
            </div>
          </div>
          <div className={s.right_part}>
            <h2>
              <span className={s.title}>SHIPNEXT Trading Desk</span>
            </h2>
            <div>
              <p>SHIPNEXT as a solution for instant Cargo-to-Ship matching, Shipping solution-search, and Freight negotiations. We cover breakbulk, dry-bulk, wet-bulk and containerized cargo.</p>
              <p>Shipbrokers, Carriers, Forwarders, and Traders use SHIPNEXT to find freight solutions, open Fleet positions, hold freight negotiations, search shipping solutions, store their data related to Cargo or Fleet and communicate.</p>
              <p>SHIPNEXT products can be white-labelled to meet the needs of larger companies, for better customisation and “look and feel” experience, and upgraded with customized features which may not yet be available.</p>
            </div>
            <div className={s.wrapper_buttons_tab}>
              <Link to={'/solution-shipnext-marketplace'} className={s.learn_more}>Learn more</Link>
              {/*{!this.props.user && <Link to={'/register'} className={s.register_free}>Register free</Link>}*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(s)(SolutionsMarketplace);
