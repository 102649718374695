export default [
  {
    _id: '5ada202e1f61dd8d78e8b51c',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 16.2,
    name: 'Bosporus',
    speedLimit: 10,
    coordinates: [28.997995376586914, 41.02080535888672],
    hidden: true,
    isVia: true,
  },
  {
    _id: '5ada202ecf58bb08cdc969f8',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 64.8,
    name: 'Dardanelles straits',
    speedLimit: 10,
    coordinates: [26.38688850402832, 40.19023132324219],
    hidden: true,
    isVia: true,
  },
  {
    _id: '5ada202e58ec9d632e6ad937',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 432,
    name: 'Malacca strait',
    speedLimit: 12,
    coordinates:  [100.76530456542969, 3.001850128173828],
    isVia: true,
  },
  {
    _id: '5ada202e38a79dc172b5d692',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: 'Messina strait',
    speedLimit: 8,
    coordinates:  [15.591178894042969, 38.147850036621094],
    isVia: true,
  },
  {
    _id: '5ada202e70e43d636adbf262',
    areaParent: null,
    days: 0.87,
    isChannel: true,
    length: 48,
    name: 'Panama canal',
    speedLimit: 8,
    coordinates:  [-79.92145538330078, 9.284141540527344],
    isVia: true,
  },
  {
    _id: '5ada202ea042ad4da6d1f75e',
    areaParent: null,
    days: 0.12,
    isChannel: true,
    length: 0,
    name: 'Kiel canal',
    speedLimit: 0,
    coordinates:  [9.502446174621582, 54.18614959716797],
    isVia: true,
  },
  {
    _id: '5ada202ed5c03aaa2ddea880',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 35.1,
    name: 'Gibraltar',
    speedLimit: 8,
    coordinates:  [-5.764645576477051, 35.955650329589844],
    isVia: true,
  },
  {
    _id: '5ada202ea63c49531e40f0bc',
    areaParent: null,
    days: 0.21,
    isChannel: true,
    length: 87.5,
    name: 'Suez canal',
    speedLimit: 8,
    coordinates: [32.316280364990234, 30.836042404174805],
    isVia: true,
    excludeFromAutocomplete: true,

  },
  {
    _id: '5ada202e4fd3c343860e48e3',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 280.8,
    name: 'English channel',
    speedLimit: 0,
    coordinates: [1.5432310104370117, 51.04518127441406],
    isVia: true,
  },
  {
    _id: '5ada202e4fd3c343860e48e5',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: 'SKAW',
    speedLimit: 0,
    coordinates: [10.49, 57.45],
    isVia: true,
  },
  {
    _id: '5ada202e4fd3c343860e48e6',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: 'Cape Passero',
    speedLimit: 0,
    coordinates: [15.09, 36.41],
    isVia: false,
  },
  {
    _id: '5ada202e4fd3c343860e48e7',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: 'Cape Cod',
    speedLimit: 0,
    coordinates: [-70.34, 41.46],
    isVia: true,
  },
  {
    _id: '5ada202e4fd3c343860e48e4',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: 'Cape of Good Hope',
    speedLimit: 0,
    coordinates: [18.474691, -34.355835],
    isVia: true,
  },
  {
    _id: '5ada202e4fd3c343860e48e8',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: 'Cape Horn',
    speedLimit: 0,
    coordinates: [-67.16, -55.59],
    isVia: true,
  },
  {
    _id: '5ada202e4fd3c343860e48e9',
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: 'Cape Leeuwin',
    speedLimit: 0,
    coordinates: [115.09, -34.22],
  },
  {
    __id: 4798 ,
    _id: "5ada202e4fd3c343860e48ea",
    areaParent: null,
    days: 0,
    isChannel: true,
    length: 0,
    name: "Singapore",
    speedLimit: 0,
    coordinates: [104.17, 115], //<lat>115</lat><lon>10417</lon>
    isVia: true,
    excludeFromAutocomplete: true,
  },
  /*{
    __id: 3657,
    _id: "5ada202e4fd3c343860e48eb",
    areaParent: null,
    days: 0,
    isChannel: false,
    length: 0,
    name: "Las Palmas",
    speedLimit: 0,
    coordinates: [-15.25, 28.09], //<lat>2809</lat><lon>-1525</lon>
    isVia: false,
  },
*/
];
