import React from "react";
import s from "./CustomAutocomplete.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
function BasicAutocompleteList(props) {
  let { list, renderItem, fallback } = props;
  return (
    <ul className={s.list}>
      {list.length === 0 && fallback
        ? fallback
        : list.map(renderItem)}
    </ul>
  );
}

export default withStyles(s)(BasicAutocompleteList);
