/* eslint-disable no-use-before-define */
import React from 'react';
import imoClasses from '../Monitor/Edit/imoClasses';
import { imoClassOptions } from '../Monitor/Edit/VesselEdit';
import s from '../Documents/Cargo.scss';
export default [
  {
    // 0
    packingCategories: ['unpacked', 'packed'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
      dangerous(),
      {
        name: '[propelled]',
        label: 'Self-propelled',
        type: 'checkbox',
        col: 'col-12 col-md-4',
      },
    ],
    unpacked: [
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        required: false,
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: 'col-12 col-md-4',
      },
      stackable({}),
      unitsQuantity({ col: 'col-12 col-md-4', required: false }),
      unitLength({
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWidth({
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitHeight({
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWeight({
        col: 'col-12 col-md-4',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitsWeight.value*1/state.unitsQuantity',
        required: false,
      }),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    packed: [
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        required: false,
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: 'col-12 col-md-4',
      },
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleBeam({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      unitWeight({
        label: 'Unit weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      unitsQuantity({
        label: 'Quantity ({{cargoName}})',
        col: 'col-12 col-md-4',
        calculated: '1*state.unitsWeight.value/state.unitWeight.value',
        required: false,
      }),
      unitsPerBundleQuantity({
        label: 'Quantity ({{cargoName}} in {{packingName}})',
        required: false,
      }),
      bundlesQuantity({
        label: 'Quantity ({{packingName}})',
        calculated: 'Math.ceil(state.unitsQuantity/state.unitsPerBundleQuantity)',
        required: false,
      }),
      bundleWeight({
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitWeight.value*state.unitsPerBundleQuantity',
        required: false,
      }),

      tiers({ required: false }),
      dunnaging(),
    ],

    clauses: [],
    drawings: true,
    packingList: true,
    flipNested: true,
  }, // /0,
  { // 1 Aeroplane
    packingCategories: ['unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      unitBeam({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      unitHeight({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      unitWeight({ units: { mt: 'mt', gb: 'lb' }, required: false }),
      {
        name: '[propelled]',
        label: 'Self-propelled',
        type: 'checkbox',
        col: 'col-12 col-md-4',
      },
    ],
    packed: [],
    unpacked: [],
    clauses: [
      {
        name: '[Shipment][value]',
        label: 'Shipment',
        required: true,
        type: 'select',
        defaultValue: 'under deck',
        values: [
          { label: 'under deck', value: 'under deck' },
          { label: 'on deck', value: 'on deck' },
          { label: 'on/under deck', value: 'on/under deck' },
        ],
        col: 'col-12 col-md-4',
      },
      {
        name: '[Cradles to be for][value]',
        label: 'Cradles to be for',
        required: true,
        type: 'select',
        defaultValue: "Charterer's account",
        values: [
          { label: "Charterer's account", value: "Charterer's account" },
          {
            label: "Charterer's account, and time",
            value: "Charterer's account, and time",
          },
          {
            label: "Charterer's account, risk and time",
            value: "Charterer's account, risk and time",
          },
          { label: "Owner's account", value: "Owner's account" },
          {
            label: "Owner's account, and time",
            value: "Owner's account, and time",
          },
          {
            label: "Owner's account, risk and time",
            value: "Owner's account, risk and time",
          },
        ],
        col: 'col-12 col-md-8',
      },
    ],
    drawings: true,
    packingList: true,
  },
  {
    // 2 Pipes
    packingCategories: ['unpacked', 'packed'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
      <div style={{ width: "100%" }} />,
      unitLength({
        units: { mt: 'mm', gb: 'foot' },
        required: false,
      }),
      unitDiam({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      {
        name: '[weightPer][value]',
        label: 'Unit Weight,{{unit}}',
        type: 'text',
        units: { mt: 'mt', gb: 'lb' },
        nextLine: true,
        required: false,
        defaultValue: '',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
          'maxWeight:10000': 'max 10000mt',
        },
        fields: [
          {
            name: '[weightPer][term]',
            label: ' ',
            required: true,
            type: 'select',
            defaultValue: 'per_unit',
            values: [
              { label: 'per unit', value: 'per_unit' },
              { label: 'per meter', value: 'per_meter' },
            ],
            col: 'col-12 col-md-4',
          },
        ],
        col: 'col-12 col-md-4',
      },
    ],
    unpacked: [
      stackable({}),
      unitsQuantity({ nextLine: true, col: 'col-12 col-md-4', required: false }),
      unitWeight({
        label: 'Pipe weight, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitsWeight.value*1/state.unitsQuantity',
        required: false,
      }),
      tiers({ nextLine: true, required: false }),
      dunnaging(),
      nested(),
    ],
    packed: [
      stackable({}),
      bundleLength({
        label: 'Length, {{unit}} (bundle)',
        nextLine: true,
        units: { mt: 'mm', gb: 'feet' },
        calculated: '1*state.unitLength.value+200',
        required: false,
      }),
      bundleBeam({
        label: 'Beam, {{unit}} (bundle)',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      bundleHeight({
        label: 'Height, {{unit}} (bundle)',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWeight({
        label: 'Pipe weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      unitsQuantity({
        label: 'Quantity (pipes)',
        col: 'col-12 col-md-4',
        calculated: '1*state.unitsWeight.value/state.unitWeight.value',
        required: false,
      }),
      unitsPerBundleQuantity({ label: 'Quantity (pipes in bundle)', required: false }),
      bundleWeight({
        label: 'Bundle weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitWeight.value*state.unitsPerBundleQuantity',
        required: false,
      }),
      bundlesQuantity({
        label: 'Quantity (bundles)',
        calculated: 'Math.ceil(state.unitsQuantity/state.unitsPerBundleQuantity)',
        required: false,
      }),
      tiers({ nextLine: true, required: false }),
      dunnaging(),
    ],

    clauses: [
      {
        name: '[Shipment][value]',
        label: 'Shipment',
        required: true,
        type: 'select',
        defaultValue: 'under deck',
        values: [
          { label: 'under deck', value: 'under deck' },
          { label: 'on deck', value: 'on deck' },
          { label: 'on/under deck', value: 'on/under deck' },
        ],
        col: 'col-12 col-md-4',
      },
      <div style={{ width: "100%" }} />,
      {
        name: '[Pipes not to be overstowed with other cargo][value]',
        label: 'Pipes not to be overstowed with other cargo',
        type: 'checkbox',
        col: 'col-12 col-md-7',
      },
      {
        name: '[Box shaped hold only][value]',
        label: 'Box shaped hold only',
        type: 'checkbox',
        col: 'col-12 col-md-5',
      },
      {
        name: '[Pipes with flanges and not streight on ends][value]',
        label: 'Pipes with flanges and not streight on ends',
        type: 'checkbox',
        col: 'col-12 col-md-7',
      },
      {
        name: '[Wooden dunnaging][value]',
        label: 'Wooden dunnaging',
        type: 'checkbox',
        col: 'col-12 col-md-5',
      },
    ],
    drawings: true,
    packingList: true,
  }, // /2

  {
    // 3 Metals & Steel Products
    packingCategories: ['loose', 'packed'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
    ],
    unpacked: [
      stackable({}),
      maxUnitLength({
        nextLine: true,
        units: { mt: 'mm', gb: 'feet' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      maxUnitWidth({ units: { mt: 'mm', gb: 'feet' }, col: 'col-12 col-md-4', required: false }),
      maxUnitWeight({ label: "Unit Weight, {{unit}}", units: { mt: 'mt', gb: 'lb' }, col: 'col-12 col-md-4', required: false }),
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: 'col-12 col-md-4',
      },
      tiers({ required: false }),
      dunnaging(),
    ],
    packed: [
      stackable({}),
      {
        nextLine: true,
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleWidth({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleHeight({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
      bundleWeight({
        label: 'Unit Weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      tiers({ required: false }),
      dunnaging(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /3
  {
    // 4 Aluminium Billets
    packingCategories: ['bulk', 'packed'],
    stowage: [unitsWeight({})],
    unpacked: [
      { type: 'dummy', col: 'col-md-6', name: '', label: '' },
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        required: false,
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: `col-12 col-md-4 ${s.small_col}`,
      },
    ],
    packed: [
      stackable({}),
      {
        nextLine: true,
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}}', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}}', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}}', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
      bundleWeight({
        label: 'Unit Weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      tiers({ required: false }),
      dunnaging(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /4
  {
    // 5 Wire Rod
    packingCategories: ['unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
      unitLength({
        nextLine: true,
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitDiam({
        label: 'Max Diameter, {{unit}}',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitsQuantity({ required: false }),
      unitsVolume(
        {
          calculated: '3.14159265358979*(state.unitDiam.value/2000)*(state.unitDiam.value/2000)*(state.unitLength.value/1000)*state.unitsQuantity',
          required: false,
        },
        false,
      ),
      stackable({ col: `col-12 col-md-2 stackable ${s.small_col}` }),
      tiers({ required: false }),
      dunnaging({ className: 'col-12 col-md-4' }),
      nested({ className: 'col-12 col-md-2' }),
    ],
    unpacked: [],
    packed: [],
    clauses: [],
    drawings: false,
    packingList: true,
    flipNested: true,
  }, // /5
  {
    // 6 Forest Products
    packingCategories: ['bulk', 'packed'],
    stowage: [unitsWeight({})],
    unpacked: [
      { type: 'dummy', col: 'col-md-6', name: '', label: '' },
      maxUnitWeight({ label: "Unit Weight, {{unit}}", units: { mt: 'mt', gb: 'lb' }, col: 'col-12 col-md-4', required: false }),
      maxUnitLength({
        units: { mt: 'mm', gb: 'inch' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        required: false,
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: 'col-12 col-md-4',
      },
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        nextLine: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
      unitsVolume(
        {
          label: 'Volume, {{unit}} ({{packingName}})',
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
          nextLine: false,
        },
        false,
      ),

      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /6
  {
    // 7 Wooden Poles
    packingCategories: ['bulk', 'packed'],
    stowage: [unitsWeight({})],
    unpacked: [
      { type: 'dummy', col: 'col-md-6', name: '', label: '' },
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        required: false,
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: 'col-12 col-md-4',
      },
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        nextLine: true,
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
      unitsVolume(
        {
          nextLine: false,
          label: 'Volume, {{unit}} ({{packingName}})',
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
        },
        false,
      ),

      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    clauses: [],
    drawings: false,
    packingList: false,
  }, // /7
  {
    // 8 Food and Beverages, Perishables, Organic Material, Grains & Agricultural Products
    packingCategories: ['bulk', 'packed'],
    stowage: [unitsWeight({})],
    unpacked: [
      { type: 'dummy', col: 'col-md-6', name: '', label: '' },
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        required: false,
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: 'col-12 col-md-4',
      },
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        nextLine: true,
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
      unitsVolume(
        {
          nextLine: false,
          label: 'Total Volume, {{unit}}',
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
        },
        false,
      ),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /8
  {
    // 9 Fertilizers, Oil & Chemicals, Minerals and Rocks
    packingCategories: ['bulk', 'packed'],
    stowage: [dangerous(), unitsWeight({ nextLine: true })],
    unpacked: [
      { type: 'dummy', col: 'col-md-6', name: '', label: '' },
      {
        name: '[sf]',
        label: 'SF',
        type: 'text',
        required: false,
        defaultValue: '',
        validations: 'isNumeric',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
        },
        col: 'col-12 col-md-4',
      },
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        defaultValue: '',
        nextLine: true,
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
      unitsVolume(
        {
          label: 'Volume, {{unit}} ({{packingName}})',
          nextLine: false,
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
        },
        false,
      ),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /9
  {
    // 10 Consumer Goods, Project & General cargo
    packingCategories: ['unpacked', 'packed'],
    stowage: [miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' })],
    unpacked: [
      stackable({}),
      unitsQuantity({ nextLine: true, col: 'col-12 col-md-4', required: false }),
      unitLength({
        label: 'Length, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWidth({
        label: 'Width, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitHeight({
        label: 'Height, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWeight({
        col: 'col-12 col-md-4',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitsWeight.value*1/state.unitsQuantity',
        required: false,
      }),
      tiers({ nextLine: true, required: false }),
      dunnaging(),
      nested(),
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        nextLine: true,
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: "Length, {{unit}} ({{packingName}})", units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleBeam({ label: "Beam, {{unit}} ({{packingName}})", units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: "Height, {{unit}} ({{packingName}})", units: { mt: 'mm', gb: 'inch' }, required: false }),
      unitWeight({
        label: 'Unit weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      unitsQuantity({
        label: 'Quantity ({{cargoName}})',
        col: 'col-12 col-md-4',
        calculated: '1*state.unitsWeight.value/state.unitWeight.value',
        required: false,
      }),
      unitsPerBundleQuantity({
        label: 'Quantity ({{cargoName}} in {{packingName}})',
        required: false,
      }),
      bundlesQuantity({
        label: 'Quantity ({{packingName}})',
        calculated: 'Math.ceil(state.unitsQuantity/state.unitsPerBundleQuantity)',
        required: false,
      }),
      bundleWeight({
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitWeight.value*state.unitsPerBundleQuantity',
        required: false,
      }),

      tiers({ required: false }),
      dunnaging(),
    ],

    clauses: [],
    drawings: true,
    packingList: true,
    flipNested: true,
  }, // /10
  {
    //11 Diesel engines
    packingCategories: ['unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitBeam({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitHeight({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitWeight({
        units: { mt: 'mt', gb: 'lb' },
        required: false,
      }),
      {
        name: '[propelled]',
        label: 'Self-propelled',
        type: 'checkbox',
        col: 'col-12 col-md-4',
      },
      {
        name: '[military]',
        label: 'Military',
        type: 'checkbox',
        fields: [dangerous()],
        col: 'col-12 col-md-2',
      },
    ],
    packed: [],
    unpacked: [],
    clauses: [],
    drawings: true,
    packingList: true,
  }, // /11
  {
    //12 Rotor
    packingCategories: ['unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitBeam({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitHeight({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitWeight({
        units: { mt: 'mt', gb: 'lb' },
        required: false,
      }),
      stackable({}),
      tiers({ required: false }),
      dangerous(),
    ],
    packed: [],
    unpacked: [],
    clauses: [],
    drawings: true,
    packingList: true,
  }, // /12
  {
    //13 Equipment & Machinery
    packingCategories: ['unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || undefined' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || undefined' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitBeam({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitHeight({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitWeight({
        units: { mt: 'mt', gb: 'lb' },
        required: false,
      }),
      stackable({}),
      tiers({ required: false }),
      dangerous(),
    ],
    packed: [],
    unpacked: [],
    clauses: [],
    drawings: true,
    packingList: true,
  }, // /13
  {
    //14 Live Stock
    packingCategories: ['unpacked'],
    stowage: [
      unitsWeight(
        { calculated: '1*state.unitsQuantity*state.unitWeight.value' },
        false,
        true,
      ),
      unitsQuantity({ label: 'Number of heads' }),
      unitWeight({
        label: 'Weight {{unit}} per head',
        units: { mt: 'mt', gb: 'lb' },
      }),
    ],
    packed: [],
    unpacked: [],
    clauses: [],
    drawings: false,
    packingList: false,
  }, // /14
];

function unNumber({
  label = 'UN Number',
  required = true,
  className = 'col-12 col-md-3',
  calculated,
  ...rest
}) {
  return {
    name: '[dangerous][unNumber]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function unitsQuantity({
  label = 'Number of units',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitsQuantity]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function unitWeight({
  label = 'Unit Weight, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitWeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'maxWeight:10000': 'max 10000mt',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function unitsWeight(
  {
    label = 'Total Weight, {{unit}}',
    required = true,
    className = `col-12 col-md-3 ${s.error_inline} ${s.medium_col}`,
    calculated,
    ...rest
  },
  deviation = true,
  hidden = false,
) {
  return {
    name: '[unitsWeight][value]',
    label: label,
    type: 'text',
    units: { mt: 'mt', gb: 'mt' },
    required: required,
    defaultValue: '',
    validationErrors: {
      totalWeight: 'Total Weight or Total Volume required',
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
    },
    col: className,
    hidden: hidden,
    calculated: calculated,
    fields: deviation
      ? [
          {
            name: '[unitsWeight][deviation]',
            label: '+/-, %',
            type: 'text',
            required: false,
            validationErrors: {
              isNumeric: 'only numbers',
              isPercent: 'invalid percent',
            },
            col: `col-12 col-md-3 ${s.xs_col}`,
          },
          {
            name: '[unitsWeight][term]',
            label: ' ',
            type: 'select',
            defaultValue: 'molchopt',
            values: [
              { label: 'molchopt', value: 'molchopt' },
              { label: 'moloo', value: 'moloo' },
            ],
            col: `col-12 col-md-3 ${s.medium_col}`,
          },
        ]
      : null,
    ...rest,
  };
}
function unitsVolume(
  {
    label = 'Total Volume, {{unit}}',
    required = true,
    className = `col-12 col-md-6 ${s.error_inline} ${s.medium_col}`,
    calculated,
    nextLine = true,
    ...rest
  },
  deviation = true,
  hidden,
) {
  return {
    name: '[unitsVolume][value]',
    label: label,
    nextLine,
    type: 'text',
    units: { mt: 'm3', gb: 'm3' },
    required: required,
    defaultValue: '',
    validationErrors: {
      totalVolume: 'Total Volume or Total Weight required',
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
    },
    col: className,
    calculated: calculated,
    hidden: hidden,
    fields: deviation
      ? [
          {
            name: '[unitsVolume][deviation]',
            label: '+/-, %',
            type: 'text',
            required: false,
            validationErrors: {
              isNumeric: 'only numbers',
              isPercent: 'invalid percent',
            },
            col: `col-12 col-md-3 ${s.xs_col}`,
          },
          {
            name: '[unitsVolume][term]',
            label: ' ',
            type: 'select',
            defaultValue: 'molchopt',
            values: [
              { label: 'molchopt', value: 'molchopt' },
              { label: 'moloo', value: 'moloo' },
            ],
            col: `col-12 col-md-3 ${s.medium_col}`,
          },
        ]
      : null,
    ...rest,
  };
}

function unitLength({
  label = 'Max Length, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitLength][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:300000': 'max 300000mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitWidth({
  label = 'Width, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitWidth][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'max:100000': 'max 100000mm',

    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function maxUnitWidth({
  label = 'Max Width, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitWidth][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function maxUnitWeight({
  label = 'Max Unit Weight, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitWeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'maxWeight:10000': 'max 10000mt',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function maxUnitLength({
  label = 'Max Length, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitLength][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:300000': 'max 300000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitBeam({
  label = 'Max Beam, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitBeam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitHeight({
  label = 'Max Height, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitHeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:200000': 'max 200000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitDiam({
  label = 'Max Diam, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitDiam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

//---bundles

function bundlesQuantity({
  label = 'Quantity',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundlesQuantity]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function unitsPerBundleQuantity({
  label = 'Quantity',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitsPerBundleQuantity]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleWeight({
  label = 'Weight, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleWeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'maxWeight:10000': 'max 10000mt',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function bundleLength({
  label = 'Max Length, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleLength][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'max:300000': 'max 300000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleWidth({
  label = 'Max Width, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleBeam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleBeam({
  label = 'Max Beam, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleBeam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleHeight({
  label = 'Max Height, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleHeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:200000': 'max 200000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

///--bundles

function imdgClass({
  label = 'Class',
  required = true,
  className = 'col-12 col-md-3',
  calculated,
  ...rest
}) {
  return {
    name: '[dangerous][class][0]',
    label: label,
    type: 'select',
    values: imoClasses.map(option => ({ label: option, value: option })),
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      imdgClass: 'invalid imdg class',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function dangerous() {
  return {
    name: '[dangerous][value]',
    label: 'IMDG',
    type: 'checkbox',
    fields: [imdgClass({}), unNumber({})],
    col: 'col-12 col-md-2',
  };
}

function stackable({ col = 'col-12 col-md-6 stackable' }) {
  return {
    name: '[stackable][value]',
    label: 'Stackable',
    type: 'checkbox',
    col: col,
  };
}

function nested({ className = 'col-12 col-md-4' } = {}) {
  return {
    name: '[stackable][nested]',
    label: 'Nested',
    type: 'checkbox',
    col: className,
    if: '[stackable][value]',
  };
}
function dunnaging({ className = 'col-12 col-md-4' } = {}) {
  return {
    name: '[stackable][dunnaging]',
    label: 'Inter-tier dunnaging',
    type: 'checkbox',
    col: className,
    if: '[stackable][value]',
  };
}
function tiers({ required = true, className = `col-12 col-md-4 ${s.small_col}`, ...rest } = {}) {
  return {
    name: '[stackable][tiers]',
    label: 'Tiers',
    type: 'text',
    required: required,
    validations: 'isNumeric',
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
    },
    col: className,
    if: '[stackable][value]',
    ...rest,
  };
}

function miniPackingList({
                      label = '',
                      required = false,
                      className = 'col-12',
                      calculated,
                      ...rest
                    }) {
  return {
    name: '[packingList]',
    label: label,
    type: 'miniPackingList',
    required: required,
    validationErrors: {
      isExisty: 'invalid',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
