exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3LlCj {\n  height: 44px; }\n\n._1L7uE {\n  display: -ms-flexbox;\n  display: flex; }\n\n.lxWr7 {\n  -ms-flex-align: center;\n      align-items: center; }\n\n._3YtpK {\n  width: 100%; }\n\n._1EXWC {\n  font-weight: bold; }\n\n._2x6YS {\n  gap: 16px; }\n", ""]);

// Exports
exports.locals = {
	"h_44px": "_3LlCj",
	"flex": "_1L7uE",
	"items_center": "lxWr7",
	"w_full": "_3YtpK",
	"font_bold": "_1EXWC",
	"gap_16px": "_2x6YS"
};