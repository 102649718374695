/** @name MONITOR_DRAGEND_VESSEL_REDUCER */
export default function MONITOR_DRAGEND_VESSEL(state, action) {
  const newState = {};
  const cargoList = { ...state.cargoList };
  newState.cargoList = cargoList;
  cargoList.data = cargoList.data.map((v) => {
    v = { ...v };
    v.disabled = false;
    return v;
  });
  return { ...state, ...newState };
}
