import { removeFromArray } from '../../core/utils';
// noinspection JSUnusedGlobalSymbols
/** @name CARGO_REQUEST_DELETE_REDUCER */
export default function CARGO_REQUEST_DELETE(state, action) {
  const newRequestList = { ...state.requestList };
  const index = newRequestList.data.findIndex(c => c._id === action.payload);
  if (index === -1) {
    return state;
  }
  newRequestList.data = removeFromArray(newRequestList.data, index);
  const newState = { requestList: newRequestList };
  return { ...state, ...newState };
}
