import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../Home.scss';
import Marketplace from '../../Icons/Marketplace';
import VisibilitySensor from 'react-visibility-sensor';
import Link from '../../Link/Link';
import Media from "react-media";
import SolutionsMarketplace from "./SolutionsMarketplace";
import TradingDesk from '../../Icons/TradingDesk';
import CargoMonitor from '../../Icons/CargoMonitor';
import SolutionsCargoMonitor from './SolutionsCargoMonitor';
import SolutionsPorts from './SolutionsPorts';
import PortsIcon from '../../Icons/PortsIcon';
import SolutionsDocuments from './SolutionsDocuments';
import Documents from '../../Icons/Documents';
import SolutionsExchange from './SolutionsExchange';
import Exchange from '../../Icons/Exchange';
import SolutionsFleetMonitor from './SolutionsFleetMonitor';
import FleetMonitor from '../../Icons/FleetMonitor';

class SolutionsMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketplaceOpened: true,
      cargoMonitorOpened: false,
      fleetMonitorOpened: false,
      exchangeOpened: false,
      documentsOpened: false,
      portsOpened: false,
    };
  }
  toggleMarketplaceOpen = () => {
    this.setState({ marketplaceOpened: !this.state.marketplaceOpened });
  };
  toggleCragoMonitorOpen = () => {
    this.setState({ cargoMonitorOpened: !this.state.cargoMonitorOpened });
  };
  toggleFleetMonitorOpen = () => {
    this.setState({ fleetMonitorOpened: !this.state.fleetMonitorOpened });
  };
  toggleExchangeOpen = () => {
    this.setState({ exchangeOpened: !this.state.exchangeOpened });
  };
  toggleDocumentsOpen = () => {
    this.setState({ documentsOpened: !this.state.documentsOpened });
  };
  togglePortsOpen = () => {
    this.setState({ portsOpened: !this.state.portsOpened });
  };
  render() {
    const { user } = this.props;
    return (
      <div>
        <div
          className={cx(s.title_solutions_mob, this.state.marketplaceOpened ? s.active : null)}
          onClick={this.toggleMarketplaceOpen}
        >
          <div>
            <TradingDesk />
            <p>SHIPNEXT Trading Desk</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.marketplaceOpened &&
          <SolutionsMarketplace user={user} />
        }
        <div
          className={cx(s.title_solutions_mob, this.state.cargoMonitorOpened ? s.active : null)}
          onClick={this.toggleCragoMonitorOpen}
        >
          <div>
            <CargoMonitor />
            <p>SHIPNEXT Cargo Monitor</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.cargoMonitorOpened &&
          <SolutionsCargoMonitor user={user} />
        }
        <div
          className={cx(s.title_solutions_mob, this.state.fleetMonitorOpened ? s.active : null)}
          onClick={this.toggleFleetMonitorOpen}
        >
          <div>
            <FleetMonitor />
            <p>SHIPNEXT Fleet Monitor</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.fleetMonitorOpened &&
          <SolutionsFleetMonitor user={user} />
        }
        <div
          className={cx(s.title_solutions_mob, this.state.exchangeOpened ? s.active : null)}
          onClick={this.toggleExchangeOpen}
        >
          <div>
            <Exchange />
            <p>SHIPNEXT Exchange</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.exchangeOpened &&
          <SolutionsExchange user={user} />
        }
        <div
          className={cx(s.title_solutions_mob, this.state.documentsOpened ? s.active : null)}
          onClick={this.toggleDocumentsOpen}
        >
          <div>
            <Documents />
            <p>SHIPNEXT Documents</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.documentsOpened &&
          <SolutionsDocuments user={user} />
        }
        <div
          className={cx(s.title_solutions_mob, this.state.portsOpened ? s.active : null)}
          onClick={this.togglePortsOpen}
        >
          <div>
            <PortsIcon />
            <p>SHIPNEXT Ports</p>
            <span className={s.arrow_show} />
          </div>

        </div>
        { this.state.portsOpened &&
          <SolutionsPorts user={user} />
        }
      </div>
    );
  }
}
export default withStyles(s)(SolutionsMobile);
