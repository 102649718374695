import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import TagsAutocomplete from '../CustomAutocomplete/TagsAutocomplete';
import Formsy from 'formsy-react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Tags.scss';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { withReset } from '../../../core/HOC';
import { computeColorOrDefault, determineIfText } from './utils';

const Wrapper = styled.div`
&>div {
  &>span {
    &>div {
      &>input {
        color: ${props => props.theme.name !== 'dark_theme' && "#fff !important"};
      }
    }
  }
}
`;

export class TagCreationTooltip extends Component {

  static defaultProps = {
    tags: [],
    saveTag: () => { },
    deleteTag: () => { },
    autocompleteProps: {},
  }

  static contextTypes = {
    showMessage: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      currentTag: "",
      currentCategory: "",
      tagError: "",
      isTagValid: true
    }
  }

  handleSelectTag = async tag => {
    try {
      await this.handleGenericChange({
        currentCategory: tag.category || "",
        currentTag: tag
      });
      const alreadyExists = this.props.tags.find(t => {
          return String(t.value).toLowerCase().trim() === String(this.mapTagValue(tag)).toLowerCase().trim() && t.category?._id === tag.category?._id
        });

        if (alreadyExists) {
          this.handleGenericChange({
            tagError: "This tag already exists",
            isTagValid: false
          })
          return;
        }
      this.props.saveTag(tag).then(this.successSaving);
    } catch (error) {
      console.error(error);
    }
    finally {
      this.resetForm();
    }
  }
  handleChangeTag = async (val, e) => {
    try {
      await this.handleGenericChange(state => ({
        currentTag: val,
        currentCategory: val.length >= 1 ? state.currentCategory : "",
      }));
      this.setChangedBasedOnTags();
    } catch (error) {
      console.error(error);
    }
  }

  setChangedBasedOnTags = () => {
    const val = typeof this.state.currentTag === 'string' ? this.state.currentTag : this.state.currentTag.value;
    this.props.setChanged(val.length > 0);
  }

  resetForm = async () => {
    try {
      await this.handleGenericChange({
        currentTag: "",
        currentCategory: ""
      });
      this.props.setChanged(false);
    } catch (error) {
      console.error(error);
    }
  }

  mapTagValue = value => typeof value === 'string' ? value : value?.value || ""

  handleGenericChange = values => {
    return new Promise((resolve, reject) => {
      this.setState(state => {
        let draft = {
          ...state,
          tagError: "",
          isTagValid: true,
        };
        try {
          if (typeof values === 'function') {
            draft = {
              ...draft,
              ...values(state)
            };
          }
          else if (!!values) {
            draft = {
              ...draft,
              ...values
            };
          }
        } catch (error) {
          console.error(error);
          reject(error)
        } finally {
          return draft;
        }
      }, resolve)
    })
  }

  saveTag = (value) => {
    const preparedTag = value.tag.slice(1);
    this.handleSelectTag(preparedTag);
    this.setState({ clearAutocomplete: true });
  }

  successSaving = res => {
    this.resetForm();
  }

  render() {
    const { currentTag, tagError, isTagValid, clearAutocomplete } = this.state;
    const { entity, tags, deleteTag } = this.props;
    const invalid = isTagValid === false ? true : undefined;
    return (
      <div className={cx(s.tags_wrapper, s.tags_wrapper_tooltip, invalid ? s.invalid : "")}>
        <Formsy.Form onSubmit={this.saveTag}>
          <div>
            <Wrapper className={s.tooltip_autocomplete}>
              <TagsAutocomplete placeholder={"#Select tag"} invalid={invalid} errorText={tagError} value={currentTag} handleChange={this.handleChangeTag} handleSelect={this.handleSelectTag} className={s.tags_autocomplete} entity={entity} forceReset={clearAutocomplete} autocompleteProps={this.props.autocompleteProps}/>
            </Wrapper>
            <div>
              <div className={s.tag_list}>
                {
                  tags.map(tag =>
                    <span style={{ marginBottom: "8px" }}>
                      <Chips handleDelete={deleteTag} key={`${tag.id}${tag.categoryId || ""}`} tag={tag} {...this.props} />
                    </span>)
                }
              </div>
            </div>
          </div>
        </Formsy.Form>
      </div>
    );
  }
}

export function Chips({ tag = {}, handleDelete = () => undefined }) {
  const categoryColor = computeColorOrDefault(tag);

  const isText = determineIfText(tag);

  const deleteTag = () => {
    handleDelete(tag);
  };

  return (
    <div className={s.chips_autocomplete_chip} style={{ backgroundColor: categoryColor, border: isText || tag.isCustom ? "1px solid var(--stroke-light-gray1)" : 'initial' }}>
      <span className={s.chips_autocomplete_chip_text}>
        {
          isText
            ? tag.search
            : `#${tag.value}`
        }
      </span>
      <CloseIcon onClick={deleteTag} color="var(--default-dark-text)" style={{ cursor: "pointer", width: 12, height: 12, marginLeft: 4 }} />
    </div>
  )
}
export default withStyles(s)(withReset()(TagCreationTooltip));
