import React, { Component } from 'react';
import styled from 'styled-components';
import DialogGreyTitle from '../Common/DialogGreyTitle';
import cx from 'classnames';
import MessageContent from './MessageContent';
import RaisedButton from '../Common/RaisedButton';
import EmailHeaders, {EmailHeadersCollapsed} from './EmailHeaders';

const Wrapper = styled.div`
  display: flex;
  .open_in_new_icon {
    font-size: 16px;
    cursor: pointer;
    margin-top: -2px;
    margin-right: 4px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 24px;
  color: var(--text-dark333);
  display: flex;
  flex-direction: column;
  section {
    overflow: auto;
    flex: 1;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    margin-top:12px;
    padding: 12px;
    > div {
      overflow: visible;
    }
  }
  a {
    text-decoration: underline;
  }
  header {
    margin-top: 20px;
    min-height: 70px;
    label {
      margin-right: 4px;
      font-weight: bold;
      width: 45px;
      display: inline-block;
    }
  }
  footer {
    height: 48px;
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
`

class EmailDetailsDialog extends Component {

  state = { mode: 'html'};

  open = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ opened: true });
  }
  close = () => {
    this.setState({ opened: false });
  }

  afterRender = () => {
    this.forceUpdate();
  }

  toggleMode = (e) => {
      if (this.state.mode === 'html') {
        this.setState({ mode: 'text' });
      } else {
        this.setState({ mode: 'html' });
      }
      return false;
  }


  render() {
    const message = this.props.message;
    let content = message.html;
    if (this.state.mode === 'text') {
      content = `<pre style="margin: 0; white-space: pre-wrap">${message.text}</pre>`;
    }
    return (
      <Wrapper>
         <span className={cx("material-icons-outlined", "open_in_new_icon")} onClick={this.open}>open_in_new</span>
        {this.state.opened ?
          <DialogGreyTitle title="Message" handleClose={this.close} open autoScrollBodyContent={true} contentStyle={{width:'75%', maxWidth:'75%'}} bodyStyle={{display: 'flex', height: '100%'}} >
            <ContentWrapper>
              <header>
                <EmailHeadersCollapsed message={message} />
              </header>
              <section>
                <MessageContent key={this.state.mode} refNo={this.props.message.refNo} afterRender={this.afterRender} >
                  {content}
                </MessageContent>
              </section>
              <footer>
                <RaisedButton secondary label={`SWITCH TO ${this.state.mode === 'html' ? 'text' : 'html'}`} onClick={this.toggleMode} />
              </footer>
            </ContentWrapper>
          </DialogGreyTitle>
          : null}
      </Wrapper>
    );
  }
}

export default EmailDetailsDialog;
