import React from "react";

export const setSeoScript = (script) => (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(script) }}
    />
  );

export const mainPageScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "What types of cargo does SHIPNEXT handle in its Online Shipping Marketplace and Freight Management Platform?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Shipnext handles dry bulk, liquid bulk, breakbulk, containerized, heavy and oversized cargo in its Online Shipping Marketplace and Freight Management Platform."
        }
    }, {
      "@type": "Question",
      "name": "How does SHIPNEXT facilitate Cargo-to-Ship matching in its Digital Shipping Marketplace?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT facilitates Cargo-to-Fleet matching using AI and machine learning algorithms to analyze shipping requirements and vessel availability in real-time, ensuring optimal matches between cargo and suitable ships."
      }
    }, {
      "@type": "Question",
      "name": "What sets SHIPNEXT apart in terms of its digital documentation flow and freight finance services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT facilitates Cargo-to-Fleet matching using AI and machine learning algorithms to analyze shipping requirements and vessel availability in real-time, ensuring optimal matches between cargo and suitable ships."
      }
    }, {
      "@type": "Question",
      "name": "Is SHIPNEXT exclusively for large-scale freight management, or does it cater to smaller shipping needs as well?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT caters to both large-scale freight management and smaller shipping needs, offering scalable solutions for a wide range of shipping requirements. As a result, SHIPNEXT is used by small, medium and large shipping companies, freight brokers, forwarding companies, traders and exporters."
      }
    }
  ]
};

export const solutionMarketplaceScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "How can I customize my work environment on the SHIPNEXT Trading Desk?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You can customize your work environment on the SHIPNEXT Trading Desk by using its customizable dashboard, which allows you to personalize the layout, track specific shipments, set alerts, and integrate various tools and data sources relevant to your needs. This enables a tailored and efficient user experience."
      }
    }, {
      "@type": "Question",
      "name": "What data can be integrated into the SHIPNEXT Trading Desk, and how does it enhance Freight search and Trade Management?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT is a SaaS and a Marketplace, quite like Booking.com, where each user can discretely book his or her own travel, and yet use it as a marketplace to rent out their real estate. The SHIPNEXT Trading Desk can integrate a wide range of data, including real-time fleet positions, cargo availability, freight rates, port information, weather conditions. This integration enhances freight search and trade management by providing comprehensive, up-to-date information, enabling better decision-making, optimized route planning, and efficient freight-matching."
      }
    }, {
      "@type": "Question",
      "name": "Is it possible to control the visibility of my Cargo and Fleet positions on the SHIPNEXT Trading Desk, and if so, how?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, it is possible to control the visibility of your Cargo and Fleet positions on the SHIPNEXT Trading Desk. Users can manage visibility settings through the platform's privacy controls, allowing them to selectively share or restrict information with specific partners, stakeholders. This ensures that sensitive data is only accessible to authorized parties, enhancing security and confidentiality."
      }
    }
  ]
};

export const solutionCargoMonitorScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": {
    "@type": "Question",
    "name": "How can SHIPNEXT Cargo Monitor enhance collaboration between Carriers and Brokerage firms in the context of Freight negotiations and quotations?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "SHIPNEXT Cargo Monitor can enhance collaboration between carriers and brokerage firms by providing real-time visibility into cargo availability and demand. This allows brokerage firms to access up-to-date information on available cargo, enabling them to negotiate better freight rates and provide accurate quotations to carriers. Additionally, the platform facilitates communication and collaboration between parties, streamlining the negotiation process and fostering stronger partnerships between carriers and brokerage firms."
    }
  }
};

export const solutionExchangeScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Why is email communication still a significant aspect of everyday shipping, and how does SHIPNEXT Exchange address the challenges associated with it?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Email is still widely used in shipping, but it can be inefficient and lead to missed messages, data-loss, time-loss. As data accumulated and stays in emails in its unstructured form, it’s not being analyzed, and does not allow automation around Client Relationship Management, Contract management, Freight management and Data management processes. SHIPNEXT provides a seamless integration of data from emails into its filing structure, moving Users onto a more advanced form of communication through Emails or Chat, giving them an experience of a Software and Marketplace at the same time."
      }
    }, {
      "@type": "Question",
      "name": "In what ways does SHIPNEXT Exchange contribute to internal collaboration within shipping, shipbroking, and trading firms, and how does it optimize the email flow for more efficient communication?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT facilitates communication using its Trading Desk, providing a “look and feel” of a sand box, where companies that want to be connected – stay connected. This gives them a better access to tool for document sharing, CRM, real-time Fleet data, port data and much more. Improving internal collaboration by providing real-time updates and reducing reliance on traditional email exchanges, leading to more efficient workflow and coordination within shipping, shipbroking, traders and shippers."
      }
    }, {
      "@type": "Question",
      "name": "How does SHIPNEXT Exchange utilize Artificial Intelligence to assist users in extracting valuable cargo-, fleet-, and freight-related data, and what impact does this have on the time spent on email-related tasks?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT Exchange uses AI to process, analyze, categorize, and tag emails, extracting valuable Contact-, Cargo-, Fleet-, and Freight-related data. This automation reduces time spent on email-related tasks, allowing users to focus on more strategic activities."
      }
    }, {
      "@type": "Question",
      "name": "Can you elaborate on the role of filters and tags in SHIPNEXT Exchange, and how these features enhance the management and storage of emails for shipping and transportation professionals?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Tagging on SHIPNEXT is one of the most important use-cases of Artificial Intelligence in general, and Language Models in particular. Each email gets instantly processed, tagged and filed the second its received, facilitating and automating some of the routine work-flows in the company. Filters and tags in SHIPNEXT Exchange allow shipping professionals to sort and prioritize emails based on predefined criteria and categorize them for easy retrieval. These features enhance email management by streamlining organization and improving productivity."
      }
    }
  ]
};

export const solutionDocumentsScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "How does SHIPNEXT use Artificial Intelligence to streamline the creation and management of Contracts and Charter Parties in the shipping industry?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT uses AI to automate email-processing, Contact management, CRM, Data-management and Freight negotiations. SHIPNEXT also provides AI-driven process for freight analytics and market research, which allows our users to benefit from the historic and real-time data."
      }
    }, {
      "@type": "Question",
      "name": "What challenges does SHIPNEXT address in the traditional manual process of creating and storing Charter Parties, and how does it enhance the efficiency of analytics and compliance tracking?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT simplifies the manual process of creating by automating tasks, providing a centralized digital platform for document management, and offering advanced analytics and compliance tracking features. This enhances efficiency and ensures better organization and compliance in the shipping industry."
      }
    }, {
      "@type": "Question",
      "name": "In what ways does SHIPNEXT promote real-time Key Performance Indicator (KPI) tracking and analytics by leveraging the commercial data stored in Contracts and Charter Parties?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT enables real-time KPI tracking and analytics by automatically capturing commercial data from Contracts and Charter Parties and presenting it on a user-friendly dashboard. Users can generate customizable reports to analyze trends and make data-driven decisions for performance optimization in the shipping industry."
      }
    }, {
      "@type": "Question",
      "name": "Can you explain the significance of SHIPNEXT's emphasis on interoperability and interconnectivity in the transportation process, and how it contributes to building a more reliable ecosystem for efficient cargo delivery?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT's focus on interoperability and interconnectivity ensures smooth communication between different systems and stakeholders in the transportation process. This leads to optimized coordination, enhanced visibility, and ultimately more reliable and efficient cargo delivery. SHIPNEXT is a SaaS and a Marketplaces, that uses algorithms for freight-matching (uberization), thus enabling better utilization, increased intake and interoperability between the different participants and stakeholders of the transportation process."
      }
    }
  ]
};
export const solutionPortsScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "What types of port data are covered by the SHIPNEXT solution?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT solution covers port capacities, berthing availability, tariffs, customs regulations, services, infrastructure details, congestion, and weather updates, aiding efficient shipping operations."
      }
    }, {
      "@type": "Question",
      "name": "How does SHIPNEXT facilitate integration for larger companies looking to include customized port data?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT offers APIs for larger companies to seamlessly integrate their inhouse and customized tools, platforms and products into SHIPNEXT. It is also possible to customize SHIPNEXT, allowing larger companies to customize the products they have on SHIPNEXT, as well as integrate the platform with their internal work-flows and processes."
      }
    }, {
      "@type": "Question",
      "name": "In what ways can SHIPNEXT Ports be utilized for communication with service providers, and what forms are available for this purpose?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT Ports allow communication with service providers via direct messaging, RFQs, service requests, and documentation exchange, streamlining interactions for port logistics. Agents that register themselves on SHIPNEXT as service providers have to be members of FONASBA or their local Agency Associations."
      }
    }, {
      "@type": "Question",
      "name": "How does SHIPNEXT contribute to enhancing interconnectivity between shippers and carriers, particularly in ports with a high level of digitalization?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "SHIPNEXT is a SaaS and a Marketplace solution. Shippers that seek greater visibility of the freight market, use SHIPNEXT as a tendering tool. This way they can onboard their carriers and service providers, run a KYC process, add their shipments and along with requested terms and conditions, and manage their procurement and contracting. SHIPNEXT enhances interconnectivity between shippers and carriers, especially in highly digitalized ports, by providing a centralized platform for communication, collaboration, and transaction management."
      }
    }
  ]
};

