import React from "react";
import pure from "recompose/pure";

let LiquidCargoIcon = ({ fill = '#82C3FF', ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.55634 11.9066C6.09994 11.9674 5.60223 12 5.08181 12C2.84833 12 1.03821 11.3899 1.03821 10.6366C1.03821 10.4888 1.10842 10.3465 1.23749 10.2142V9.47537C1.08364 9.32761 1 9.12546 1 9.00308C1 8.8807 1.01962 8.84626 1.05576 8.77011C1.09397 8.80456 1.1363 8.83901 1.18174 8.87073C1.82813 9.32942 3.28305 9.61588 4.79475 9.65667C5.1004 9.66483 5.40811 9.66302 5.71375 9.65123C5.69 9.76092 5.67761 9.8688 5.67761 9.97848C5.67761 10.361 5.75919 10.7236 5.92234 11.0645C6.07 11.3718 6.28064 11.6528 6.55634 11.9066Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.56458 8.7571C5.25457 8.77119 4.93865 8.77368 4.62568 8.76539C3.23997 8.72975 1.91725 8.48689 1.34938 8.09899C1.29427 8.06169 1.24801 8.02356 1.20963 7.98461C1.21553 7.9788 1.22045 7.97383 1.22636 7.96886V5.13168C1.07972 4.99741 1 4.8134 1 4.69985C1 4.58629 1.01968 4.55148 1.05708 4.47937C1.09251 4.51004 1.13188 4.53988 1.17321 4.56806C1.7893 4.98829 3.176 5.24938 4.61683 5.28668C5.85983 5.319 7.15008 5.18473 8.02796 4.84241C7.96301 4.90955 7.9138 4.98912 7.88919 5.07946C7.68055 5.84284 7.08808 6.58715 6.5645 7.24527C6.1354 7.78568 5.74469 8.27719 5.56458 8.7571Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.93211 0C5.9771 0 6.92837 0.155533 7.62137 0.407671C8.37221 0.680097 8.83687 1.07618 8.83687 1.53022C8.83687 1.69251 8.77705 1.84805 8.66637 1.99199V3.65069C8.57862 3.7415 8.47292 3.82458 8.35227 3.90186C7.53961 4.42159 6.07881 4.6235 4.67186 4.58196C3.26889 4.54042 1.92875 4.25737 1.35341 3.80526C1.30056 3.76372 1.25469 3.72121 1.2168 3.67774L1.22876 3.66518V2.02966C1.09814 1.8751 1.02734 1.707 1.02734 1.53022C1.02734 1.07618 1.49201 0.681063 2.24285 0.407671C2.93585 0.155533 3.88712 0 4.93211 0Z" />
    <path d="M10.5337 8.12445C9.91047 7.04152 8.93029 5.92283 8.65109 4.76068C8.39383 5.84265 7.52433 6.88985 6.90212 7.90516C5.74146 9.79765 6.31381 11.8708 8.65109 11.8708C10.7949 11.8708 11.5906 9.96091 10.5337 8.12445Z" />
  </svg>
);

LiquidCargoIcon = pure(LiquidCargoIcon);
LiquidCargoIcon.displayName = "LiquidCargoIcon";
LiquidCargoIcon.muiName = "SvgIcon";

export default LiquidCargoIcon;
