import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import Link from '../../Link/Link';

class SolutionsPorts extends Component {
  render() {
    return (
      <div className={s.solution}>
        <div className={s.tab_block}>
          <div className={s.left_part}>
            <div className={s.wrapper_screen}>
              <img src="/images/solutions_screen/ports1.png" alt="SHIPNEXT Ports" />
            </div>
          </div>
          <div className={s.right_part}>
            <h2>
              <span className={s.title}>SHIPNEXT Ports</span>
            </h2>
            <div>
              <p>SHIPNEXT Ports is a solution for Port data, along with its description, general data, restrictions, facilities, service providers, congestion, lineup and other useful data. This solution is also a way to store your port data and promote your company’s services to other SHIPNEXT users.</p>
              <p>SHIPNEXT promotes interoperability and interconnectivity between the land transport, the ports and the maritime transport. This may include any cargo- or fleet- related data that would make supply-chain management more efficient and reliable.</p>
            </div>
            <div className={s.wrapper_buttons_tab}>
              <Link className={s.learn_more} to={'/solution-shipnext-ports'}>Learn more</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(s)(SolutionsPorts);
