import React, {Component} from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Chat.scss';
import cx from 'classnames';
import { connect } from 'react-redux';

import IconChat from 'material-ui/svg-icons/communication/chat-bubble-outline';

import { setChatVisibility } from "./actions";
import { ChatPanel } from "./Panel/ChatPanel";
import { ChatFloatBtn } from "./Panel/ChatFloatBtn";

class Chat extends Component {
  constructor(...args) {
    super(...args);

    this.handleToggleChatVisibility = this.handleToggleChatVisibility.bind(this);
  };

  handleToggleChatVisibility() {
    const { isShown, setChatVisibility } = this.props;

    setChatVisibility(!isShown);
  }

  render() {
    const { isShown, rooms, perRoomMessages } = this.props;
    if (!this.props.user) {
      return null;
    }
    return (
      <div className={s.wrapper_chat}>
        <ChatFloatBtn showChat={this.handleToggleChatVisibility} isShown={isShown} />
        {isShown ?
          <ChatPanel that={this} rooms={rooms} hideChat={this.handleToggleChatVisibility} />
        : null
        }

      </div>
    )
  }
};

function mapStateToProps(state) {
  const rooms = state.chat.rooms;

  const messages = Object.keys(state.chat.messages).map(key => state.chat.messages[key]);

  const perRoomMessages = {};

  for (const key of Object.keys(state.chat.rooms)) {
    perRoomMessages[key] = messages.filter(message => message.roomId === key);
  }

  return {
    isShown: state.chat.isShown,
    rooms,
    perRoomMessages,
    user: state.login.user,
  };
}

export default connect(
  mapStateToProps,
  {
    setChatVisibility
  }
)(withStyles(s)(Chat));
