import {filterTCOrders} from './filter';
import { uniqBy } from 'lodash';

export default function GET_TC_ORDERS(state, { res, payload: { filters = {}, sort = {}, next, page } }) {
  const limit = (res?.limit || 50);
  let data;
  if (page > 1) {
    data = uniqBy([...state.tcList.data, ...res.data], '_id');
  } else {
    data = res.data || [];
  }
  const newState = { ...state.tcList, filters, sort, data: data, filtered: data, loading: false, next: res.next, page: res.page };
  return { ...state, tcList: newState };
}
