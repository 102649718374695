import { filterCargo } from './filter';
import { computeAndSetChannel, isIfchor, isTagsFitted } from '../../core/utils';
import orderBy from 'lodash/orderBy';
import { replaceAccountName } from './helpers';
import  { getOrCreateTtlMap } from '../../core/ttlMap';
//noinspection JSUnusedGlobalSymbols
/**
 * @name MONITOR_CARGO_NEW_REDUCER
 * @param {Object} state
 * @param {MonitorCargo} payload
 * @param {Object} currentUser
 *
 */
const sortOrders = { 1: 'asc' };
sortOrders[-1] = 'desc';
const newCargoCache = getOrCreateTtlMap('MONITOR_CARGO_NEW', 10000);
export default function MONITOR_CARGO_NEW(state, { payload, currentUser }) {
  const newState = {};
  const cargoList = { ...state.cargoList };
  let stateChanged = false;
  if (!Array.isArray(payload)) {
    payload = [payload];
  }
  newCargoCache.clearExpired();
  for (let i = 0; i < payload.length; i++) {
    let newCargo = payload[i];
    computeAndSetChannel(newCargo, currentUser);
    newCargo.addedByUser = currentUser && newCargo.addedBy === currentUser._id;
    newCargo = filterCargo(cargoList.filters, newCargo);
    if (!newCargo) {
      continue;
    }
    if (!isTagsFitted(newCargo, cargoList.filters.tags)) {
      newCargoCache.set(newCargo._id, newCargo);
      continue;
    }
    if (newCargo.status && newCargo.status.splice) {
      newCargo.status = newCargo.status[0];
    }
    if (isIfchor(currentUser)) {
      newCargo = replaceAccountName(newCargo);
    }
    cargoList.data.unshift(newCargo);
    stateChanged = true;
  }
  if (!stateChanged) {
    return state;
  }
  cargoList.data = orderBy(cargoList.data, [cargoList.sort.biddingStatus ? c => (c.weight || 1) : null, cargoList.sort.field], ['desc', sortOrders[cargoList.sort.value]]);
  newState.cargoList = cargoList;
  return { ...state, ...newState };
}
