export function prepareStatusFilter(params) {
  const preparedFilters = { ...params };
  if (params.status) {
    preparedFilters.ownership = {
      user: params.status.user,
      company: params.status.company,
    };
    if ((params.status.user && params.status.company) || (!params.status.user && !params.status.company)) {
      preparedFilters.ownership = undefined;
    }
    preparedFilters.paymentMethod = {
      escrow: params.status.escrow,
      bank_transfer: params.status.bank_transfer,
    };
    if ((params.status.escrow && params.status.bank_transfer) || (!params.status.escrow && !params.status.bank_transfer)) {
      preparedFilters.paymentMethod = undefined;
    }
    preparedFilters.sent = params.status.sent;
    
    if (params.status.notSent && !params.status.sent) {
      preparedFilters.sent = false;
    }
    if ((params.status.sent && params.status.notSent) || (!params.status.sent && !params.status.notSent)) {
      preparedFilters.sent = undefined;
    }
    preparedFilters.status = {
      draft: params.status.draft,
      issued: params.status.issued,
      paid: params.status.paid,
      uncollectible: params.status.uncollectible,
      void: params.status.void,
      overdue: params.status.overdue,
    };
    if ((params.status.draft
        && params.status.issued
        && params.status.paid
        && params.status.uncollectible
        && params.status.void
        && params.status.overdue) || (!params.status.draft
        && !params.status.issued
        && !params.status.paid
        && !params.status.uncollectible
        && !params.status.void
        && !params.status.overdue)) {
      preparedFilters.status = undefined;
    }
  }
  if (!params.filters?.search) {
    delete preparedFilters.search;
  } else {
    preparedFilters.search = params.filters.search;
  }
  
  if (params.type) {
    preparedFilters.type = {
      received: params.type.received,
      issued: params.type.issued,
    };
    if ((params.type.received && params.type.issued) || (!params.type.received && !params.type.issued)) {
      preparedFilters.type = undefined;
    }
  }
  if (params.accounts) {
    preparedFilters.accounts = params.accounts.map(a => a._id);
  }
  if (params.dueDate) {
    preparedFilters.dueDate = params.dueDate;
  }
  if (params.laycanDate) {
    preparedFilters.laycanDate = params.laycanDate;
  }
  if (params.invoiceDate) {
    preparedFilters.invoiceDate = params.invoiceDate;
  }
  if (params.loadingPorts) {
    preparedFilters.loadingPorts = params.loadingPorts;
  }
  if (params.unloadingPorts) {
    preparedFilters.unloadingPorts = params.unloadingPorts;
  }
  return preparedFilters;
}

export const convertToDateObj = (from = undefined, to = undefined) => ({ from, to });

export const paymentMethodFormat = (method) => {
  const paymentMethods = {
    "escrow": "Mercurion Escrow",
    "bank_transfer": "Bank Transfer",
  };
  return paymentMethods[method];
};
