import produce from "immer";
import {sortContacts} from "./CONTACTS_CREATE_GROUP";

/** @name CONTACTS_MERGE_CONTACTS_REDUCER */
 export default function CONTACTS_MERGE_CONTACTS(state, action) {
  const newContact = action.res.data;
  const sourceIds = action.payload.sourceIds;
  return produce(state, draft => {
    const index = draft.contactList.data.findIndex(c => c.id === newContact.id);
    if (index !== -1) {
      draft.contactList.data[index] = newContact;
    }
    draft.contactList.data = draft.contactList.data.filter(c => sourceIds.indexOf(c.id) === -1);
    draft.contactList = sortContacts(draft.contactList);
  });
}
