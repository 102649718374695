import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as vesselActions from '../../actions/vessel';
import * as monitorActions from '../../actions/monitor';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './RatingBlock.scss';
import cx from 'classnames';
import Link from '../Link/Link';
import RaisedButton from '../Common/RaisedButton';
import StyledToggled from './StyledToggle';
import StarIcon from 'material-ui/svg-icons/toggle/star';
import StarIconBorder from 'material-ui/svg-icons/toggle/star-border';
import FormsyText from '../Common/FormsyText';
import Formsy from 'formsy-react';
import WithTooltip from '../Common/WithTooltip';
import InfoIcon from 'material-ui/svg-icons/action/info';
import TextArea from './../Monitor/Edit/TextArea'
import { toggleSubscribeDialog } from '../../actions/login';
import { isUserSubscribedToMarketplace } from '../../core/utils';
import LeavePageConfirmDialog from './../Monitor/Edit/LeavePageConfirmDialog'
import Vessel from './../../core/api/Vessel';
import PubSub from 'pubsub-js';
import CompanyChangesTooltip from './CompanyChangesTooltip';
import RatingStars from './RatingStars';
import { withRatingAndComment } from '../../core/HOC';
import history from '../../core/history';
const Form = Formsy.Form;

const debounce = (fn, timeout = 250) => {
  let id = null;
  return function(...args) {
    const clear = () => {
      id = null;
      fn.apply(this, args);
    }
    if (!id) {
      fn.apply(this, args);
      id = setTimeout(() => id = null, timeout);
      return;
    }
    else {
      clearTimeout(id);
      id = setTimeout(clear, timeout);
    }
  }
}

function CommentFooter({ publicToggleEnabled = true, ...props }: {
  offset: any,
  horizontalPosition: any,
  whiteArrow: any,
  dark: any,
  alertClasses: any,
  positionInsideTransform: any,
  selectedtoggle: boolean,
  changeToggle: (val: any) => any,
  showButtons: boolean,
  comment: any,
  section: any,
  onClear: (val: any) => any,
  onSave: (val: any) => any,
  publicToggleEnabled: boolean,
}) {
  return props.comment.trim().length > 0
    ? (<div className={s.footer_rating}>
      {publicToggleEnabled ? (<PublicPrivate offset={props.offset} horizontalPosition={props.horizontalPosition} whiteArrow={props.whiteArrow}
                                             dark={props.dark} alertClasses={props.alertClasses}
                                             positionInsideTransform={props.positionInsideTransform} selectedtoggle={props.selectedtoggle}
                                             changeToggle={props.changeToggle} />) : null}
    {
      props.showButtons
        ? (
          <div className={s.wrapper_buttons}>
            <Button
              label={props.section ? 'Clear' : 'Cancel'}
              backgroundColor='#e6e6e6'
              color='#0F4778'
              marginRight='8px'
              onClick={props.onClear}
            />
            <Button
              label={props.section ? 'Add' : 'Save'}
              onClick={props.onSave}
            />
          </div>
        )
        : null
    }

  </div>)
  : null
}

const StyledCommentFooter = withStyles(s)(CommentFooter);

export { StyledCommentFooter as CommentFooter };

class RatingBlock extends Component {
  static defaultProps = {
    showButtons: true,
    positionInsideTransform: true,
    horizontalPosition: 'left',
    section: false,
    alertClasses: "",
    whiteArrow: true,
    offset: {x: 0, y: 0},
    handleClose: () => undefined,
  }
  constructor(props) {
    super(props);
    this.state = {
      selectedtoggle: false,
      subscribedToMarketplace: isUserSubscribedToMarketplace(this.props.user)
    };
    this.handleStars = debounce(this._handleStars, 1000).bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.user !== this.props.user) {
      this.setState({subscribedToMarketplace: isUserSubscribedToMarketplace(nextProps.user)});
    }
  }

  handleToggle = (e) => {
    e.stopPropagation();
    this.setState(state => ({
      selectedtoggle: !state.selectedtoggle
    }))
  }
  stopEvent = (e) => {
    e.stopPropagation();
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleSaveRating = (e) => {
    if (!this.state.subscribedToMarketplace) {
      this.props.toggleSubscribeDialog(true);
      return;
    };
   /*  let techRating = Array.from(document.getElementsByName('rating-technical')).find(item => !!item.checked)?.value || 0;
    let operRating = Array.from(document.getElementsByName('rating-operational')).find(item => !!item.checked)?.value || 0; */

   this.props.handleSaveRating();
  }

  setRatingFormRef = (node) => {
    this.ratingFormRef = node;
  }

  _handleStars = (type, i) => {
    const { user } = this.props;
    if (!user) {
      return history.push("/login");
    }
    if (!this.state.subscribedToMarketplace) {
      this.props.toggleSubscribeDialog(true);
      return;
    }
    this.props.handleStars(type, i);
  }

  handleSaveComment = async (e) => {
    e.preventDefault();
    const { user } = this.props;
    if (!user) {
      return history.push("/login");
    }
    try {
      if (!this.state.subscribedToMarketplace) {
        this.props.toggleSubscribeDialog(true);
        this.props.handleClose();
        return;
      }
      await this.props.handleSaveComment(this.state.selectedtoggle);
    } catch (error) {
      console.error(error);
    } finally {
      this.handleClose(e);
    }
  }
  handleClickOutside = e => {
    if (this.props.section) return;
    let ratingForm = document.getElementById('ratingFormId');
    if (ratingForm && !ratingForm.contains(e.target)) {
      setTimeout(async () => {
        try {
          if (this.props.comment) {
            await this.leaveDialog.show();
            this.props.handleSaveComment(this.state.selectedtoggle);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.props.handleClose();
        }
      }, 300);
    }
  }

  handleClose = (e) => {
    e.preventDefault();
    if (!this.props.section) {
      this.props.handleClose();
    }
    else {
      this.props.setComment("")
    }
  }

  changeToggle = (val) => {
    this.setState({selectedtoggle: val})
  }

  render() {
    const { marketplace, dark, coords, coordsParent, vessels = [], currentVesselRatingIndex, alertClasses, comment, operRating, techRating, average, reviews } = this.props;
    let { vessel } = this.props;
    if(!vessel) {
      vessel = {};
    }
    const {subscribedToMarketplace} = this.state;
    return (
      <Form
        onClick={this.stopEvent}
        className={cx(s.wrapper_tooltip_rating, marketplace && s.wrapper_tooltip_rating_marketplace, dark && s.wrapper_tooltip_rating_dark)}
        // had to use id, because ref gives error for contains method
        id='ratingFormId'
      >
        <div className={s.body_rating}>
          <RatingStars operRating={operRating} techRating={techRating} handleStars={this.handleStars} />
          <TextArea
            validations="minLength:1,maxLength:1000"
            validationError="1 - 1000 characters"
            style={{ resize: 'none', marginBottom: '12px', borderRadius: "8px" }}
            name="comments"
            placeholder="Comment"
            value={comment}
            classes={s.focus_textarea}
            onChange={this.props.setComment}
          />
        </div>
        <CommentFooter offset={this.props.offset} horizontalPosition={this.props.horizontalPosition}
                      whiteArrow={this.props.whiteArrow} dark={this.props.dark} alertClasses={alertClasses}
                      positionInsideTransform={this.props.positionInsideTransform}
                      selectedtoggle={this.state.selectedtoggle} changeToggle={this.changeToggle}
                      showButtons={this.props.showButtons} comment={comment} section={this.props.section}
                      onClear={this.handleClose} onSave={this.handleSaveComment}/>
        <LeavePageConfirmDialog titleClass={s.dialog_title} bodyClass={s.dialog_body}
                                attachRef={instance => this.leaveDialog = instance} title="COMMENT IS NOT SAVED"
                                ref="leaveDialog">Would you like to save comment?</LeavePageConfirmDialog>
        {
          this.props.section
            ? <div style={{ width: '100%', marginBottom: '15px', borderBottom: '1px dashed #999999' }}></div>
            : null
        }
      </Form>
    )
  }
}

export default withRatingAndComment(connect(state => state.login, { toggleSubscribeDialog })(
  withStyles(s)(RatingBlock),
));

function _PublicPrivate({disabled = false, bottomArrow = false, offset = {x: 0, y:0}, selectedtoggle, changeToggle, whiteArrow = false, positionInsideTransform = true, alertClasses = '', horizontalPosition="left", dark = false, publicLabel = "PUBLIC", privateLabel = 'PRIVATE', Tooltip}) {

  const tooltipProps = {
    bottomArrow,
    offset,
    positionInsideTransform,
    whiteArrow,
    horizontalPosition,
    dark,
    classes: `${alertClasses}`,
    children: <InfoIcon color="#DADADA" style={{marginLeft: '12px', zIndex: 9999, position: 'relative', width: '18px', height: '18px', pointerEvents: 'all'}} />
  }

  const tooltip = Tooltip !== undefined ? React.cloneElement(Tooltip, {
    ...Tooltip.props,
    ...tooltipProps
  }): <CompanyChangesTooltip {...tooltipProps}></CompanyChangesTooltip>
  const handler = val => {
    if (disabled) return;
    changeToggle(val)
  }
  return (
    <div className={cx(s.wrapper_toggle, dark && s.dark, disabled && s.disabled)}>
      <span onClick={handler.bind(this, false)} className={selectedtoggle ? null : s.active}>{publicLabel}</span>
      <StyledToggled style={{width: 'auto'}} name="isPrivate" toggled={selectedtoggle} onToggle={handler.bind(this, !selectedtoggle)} />
      <span onClick={handler.bind(this, true)} className={!selectedtoggle ? null : s.active}>{privateLabel}</span>
      {tooltip ? tooltip : null}
    </div>
  )
}

const PublicPrivate = withStyles(s)(_PublicPrivate);
export {
  PublicPrivate
}

const Button = ({ backgroundColor, marginRight, label, color, onClick, disabled = false } = props) => (
  <RaisedButton
    onClick={onClick}
    disabled={disabled}
    styles="small"
    style={{
      display: 'block',
      minWidth: '68px',
      maxWidth: '68px',
      marginRight: marginRight
    }}
    overlayStyle={{
      borderRadius: '15px'
    }}
    buttonStyle={{
      ...(
        {
          borderRadius: '15px',
          opacity: disabled ? 0.45 : 1
        }
      ),
      ...(
         backgroundColor ? {
          backgroundColor: backgroundColor,
         }
         : {}
      )
    }}
    labelStyle={{
      ...({
        display: 'inline-block',
        padding: '0',
        fontSize: '11px',
        lineHeight: '28px',
      }),
      ...(color ? {
        color: color
      } : {})
    }}
    primary
    label={label}
  />
)
