import React from 'react';
import s from './OriginalMessageBody.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {dateTime} from './../../core/format';
import {EmailHeadersCollapsed} from "../Emails/EmailHeaders";

function OriginalMessageBody({ originalMsg = {}, className = "", ...rest }) {
  let { sender, context, subject, text, date, from = [], to = [], cc = [], bcc = [], replyTo = [], refNo } = originalMsg;

  from = from.map(f => f.address);
  to = to.map(t => t.address);
  cc = cc.map(t => t.address);
  bcc = bcc.map(t => t.address);
  replyTo = replyTo.map(t => t.address);

  return (
    <div className={cx(s.body_message, className)} {...rest}>
      <div className={cx(s.body_message_header)}>
        <EmailHeadersCollapsed message={{ from, to, subject, cc, bcc, replyTo, refNo }} />
        <div>
          <div className={s.body_message_light}>{date ? dateTime(date) : '---'}</div>
          <div className={cx(s.body_message_light, s.ref)}>REF: {refNo}</div>
        </div>
      </div>
      <p className={s.body_message_text}>
        <pre>{text || "---"}</pre>
      </p>
    </div>
  );
}

export default withStyles(s)(OriginalMessageBody);
