import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import FormsySelect from './Select';
import MenuItem from 'material-ui/MenuItem';


const seaAndRiverTransportId = '56085c40d5497167554c6388';

function renderItems(items, industry) {
  const _items = [];

  const firstItem = items.find(item => item._id === seaAndRiverTransportId);
  if (firstItem) {
    items = items.filter(item => item._id !== firstItem._id);
    items.unshift(firstItem);
  }

  items.forEach(i => {
     if (industry && industry !== i.industry._id) {
       return;
     }
    _items.push(<MenuItem key={i._id} value={i._id} primaryText={i.name_en} />);
  });
  return _items;
}
class SectorSelect extends PureComponent {
  render() {
    const { industry, ...restProps } = this.props;
    return (
      <FormsySelect {...restProps}>
        {renderItems(this.context.sectors, industry)}
      </FormsySelect>
    );
  }
}

SectorSelect.propTypes = FormsySelect.propTypes;
SectorSelect.defaultProps = FormsySelect.defaultProps;
SectorSelect.contextTypes = {
  sectors: PropTypes.array,
  formsy: PropTypes.object,
};
export default SectorSelect;
