export default function transformChannelsToVia(calculator) {
  let via = [];
  let distance = 0;
  let lsDistance = 0;
  let atSeaDays = 0;
  let atSeaLsDays = 0;
  let atSeaTotalDays = 0;
  let da = 0;
  let idleDays = 0;
  let atPortDays = 0;
  let points = [];
  const newLegs = []
  for (let i = 0; i < calculator.legList.length; i++) {
    const leg = calculator.legList[i];
    if (leg.type === 'channel' || leg.type === 'auto-channel' || (leg.type === 'manual' && leg.port.isChannel)) {
      if (i === 0) {
        leg.type = 'passage';
        // eslint-disable-next-line no-continue
        newLegs.push(leg);
        continue;
      }
      via.push(leg.port._id);
      points = [...points, ...leg.points];
      distance += (leg.distance || 0);
      lsDistance += (leg.lsDistance || 0);
      atSeaDays += (leg.atSeaDays || 0);
      atSeaLsDays += (leg.atSeaLsDays || 0);
      atSeaTotalDays += (leg.atSeaTotalDays || 0);
      da += (leg.da || 0);
      idleDays += (leg.idleDays || 0);
      atPortDays += (leg.atPortDays || 0);
    } else {
      if (via.length) {
        leg.via = via;
        leg.distance += distance;
        leg.lsDistance += lsDistance;
        leg.atSeaDays += atSeaDays;
        leg.atSeaLsDays += atSeaLsDays;
        leg.atSeaTotalDays += atSeaTotalDays;
        leg.viaDa = da;
        leg.viaIdleDays = idleDays;
        leg.viaAtPortDays = atPortDays;
        leg.points = [...points, ...leg.points];
        via = [];
        points = [];
        distance = 0;
        lsDistance = 0;
        atSeaDays = 0;
        atSeaLsDays = 0;
        atSeaTotalDays = 0;
        da = 0;
        idleDays = 0;
        atPortDays = 0;
      }
      newLegs.push(leg);
    }
  }
  for (let i = 0; i < newLegs.length; i++) {
    const prevLeg = newLegs[i - 1];
    const leg = newLegs[i];
    const nextLeg = newLegs[i + 1];
    leg.prevPort = prevLeg?.port;
    leg.nextPort = nextLeg?.port;
  }
  calculator.legList = newLegs;
  return calculator;
}
