/** @name GET_INDEXES_LIST_FAILED */
export default function GET_INDEXES_LIST_FAILED(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    loading: false,
    errorMessage: action.payload,
  };

  return { ...state, ...newState };
}
