import React from 'react';
import { MenuItem } from "material-ui";

const style = { fontSize: "13px", height: '28px', minHeight: '28px', lineHeight: '28px' };
const innerDivStyle = { padding: "0 12px" };
export default function NarrowMenuItem(props) {
  return <MenuItem {...props} style={style} innerDivStyle={innerDivStyle} />;
};

NarrowMenuItem.muiName = 'MenuItem';
