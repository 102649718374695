import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Apple = props => (
  <span {...props}>
    <svg width="17px" height="21px" viewBox="0 0 17 21" version="1.1">

        <title>Apple</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Web---Start"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Shipnext-1440" transform="translate(-121.000000, -1456.000000)" fill="#ffffff">
            <g id="Group-11" transform="translate(100.000000, 1381.000000)">
                <g id="Group-3" transform="translate(0.000000, 60.000000)">
                    <g id="ios-ico" transform="translate(21.000000, 14.000000)">
                        <g>
                            <path d="M11.5697729,4.35408572 C12.2983232,3.46503601 12.7901872,2.23127805 12.6558183,1 C11.6067551,1.04339852 10.3345657,1.70305605 9.58136056,2.5908658 C8.90581812,3.37699901 8.31410211,4.63431617 8.47312579,5.83955511 C9.64423039,5.93131198 10.8399898,5.24065551 11.5697729,4.35408572 M14.1979782,12.1583798 C14.1720906,9.49867044 16.3540434,8.22399387 16.45143,8.15951606 C15.2260847,6.35537753 13.3153351,6.10862594 12.6348617,6.07886695 C11.0088754,5.91395257 9.46301736,7.04107417 8.63708043,7.04107417 C7.81484173,7.04107417 6.54141957,6.10366611 5.19280017,6.12846526 C3.42135038,6.15450438 1.78673491,7.16506995 0.874506062,8.76089557 C-0.967210011,11.9748661 0.402365997,16.7350639 2.19723789,19.3414551 C3.07371722,20.6173716 4.12031491,22.0495228 5.4923564,21.9986845 C6.81508823,21.9453664 7.31434861,21.1381539 8.91321457,21.1381539 C10.5108478,21.1381539 10.9595658,21.9986845 12.3599603,21.9714055 C13.7813115,21.9453664 14.6824456,20.6719297 15.553994,19.3922933 C16.561144,17.9130237 16.9753452,16.4796326 17,16.4064751 C16.9679487,16.3915956 14.2263312,15.3351516 14.1979782,12.1583798"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
        </g>
      </svg>
  </span>
);

Apple = pure(Apple);
Apple.displayName = 'Apple';
Apple.muiName = 'SvgIcon';

export default Apple;
