import React, { Component } from 'react';
import styled from "styled-components";
import AutoGrowTextArea from "./AutoGrowTextArea";
import { HOC } from "formsy-react";


export default styled(HOC((props) => {
  return <AutoGrowTextArea {...props} />;
}))`
  width: 100%;
  border: 1px solid var(--stroke-light-gray1);
  border-radius: 8px;
  background: #F1F9FF;
  font-size: 12px;
  color: var(--text-dark333);
  min-height: 74px;
  padding: 12px 8px;
  &::placeholder {
    color: var(--text-light);
  }
  outline: none;
  resize: none;
`;

