import React, { Component } from "react";
import s from "./IssueReport.scss";
import cx from "classnames";
import IconClose from "material-ui/svg-icons/navigation/close";
import Info from "../Icons/Info";
import RaisedButton from "../Common/RaisedButton";
import FormsyAttachments from "../Common/FormsyAttachments/FormsyAttachments";
import Formsy from "formsy-react";
import AutoGrowTextArea from "../Common/AutoGrowTextArea";
import TopicAutocomplete from "./TopicAutocomplete";
import SpinnerButton from "../Common/SpinnerButton";
import Dialog from "../Common/Dialog";
import ScreenshotIcon from "../Icons/ScreenshotIcon";
import SelectElements from "../Icons/SelectElements";
import { uploadImage } from "../../actions/login";

const Form = Formsy.Form;
class IssueReportPanel extends Component {
  state = {
    notReportedDialogOpen: false,
    dragActive: false,
  };
  startCaprture = () => {
    const { hideForm, onStartCapture } = this.props;
    hideForm();
    onStartCapture();
  }
  setDragActive = (active) => {
    this.setState({ dragActive: active });
  }
  attachmentsChanges = (_, files) => {
    this.props.attachmentsChanges(files);
  }
  textAreaChange = (_, text) => {
    this.props.textAreaChange(text);
  }
  newRequest = (item) => {
    this.props.newRequest(item);
  }
  removeTopic = (id) => {
    this.props.removeTopic(id);
  }

  closeDialog = () => {
    const { notReportedDialogOpen } = this.state;
    const { topics, attachments, text } = this.props;
    if (topics.length || attachments.length || text || Object.values(this.props.selectedElements).flat().length) {
      this.setState({ notReportedDialogOpen: !notReportedDialogOpen });
    } else {
      this.closeFormWithoutSave();
    }
  }

  disableSelect = () => {
    if (this.props.path) {
      const currentLocation = this.props.path.split('/')[1];
      if (currentLocation === "settings" || currentLocation === "port" || currentLocation === "calculator") {
      return true;
      }
      const selectedElementsList = Object.values(this.props.selectedElements).flat();
      if (selectedElementsList.length >= 20) {
        return true;
      }
  }
    return false;
  }

  closeFormWithoutSave = () => {
    this.props.resetForm();
    this.props.closeForm();
  }

  handleStartSelect = (ev) => {
    if (Object.values(this.props.selectedElements).flat().length < 20) {
      this.props.hideForm();
      ev.preventDefault();
      ev.stopPropagation();
      this.props.handleStartSelectElements(true);
    }
  }
  handleRemoveSelected = (el) => {
    this.props.handleRemoveSelectedElement(el);
  }
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setDragActive(true);
    } else if (e.type === "dragleave") {
      this.setDragActive(false);
    }
  }
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      Array.from(e.dataTransfer.files).forEach(file => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'attachment');
        uploadImage(formData).promise.then(fileData => {
          this.props.attachmentsChanges([...this.props.attachments, fileData.data]);
        });
      });
    }
  };

  render() {
    const { hideForm, isShown, topics, attachments, text, handleSubmit, resetForm, selectedElements } = this.props;
    const { notReportedDialogOpen } = this.state;
    const selectedElementsList = Object.values(selectedElements).flat();
    return (
      <div className={s.wrapper_opened_form} style={{ display: isShown ? "block" : "none" }}>
        <div className={s.header}>
          <div className={s.left_part}>

            <span className={s.name}>send issue report</span>
            {/* <Info /> */}
          </div>
          <div className={s.right_part}>
            <div
            className={s.collapse_icon_wrapper}
              onClick={hideForm}
            >
              <div className={s.collapse_icon}/>
            </div>

          <IconClose
            style={{
              marginRight: "8px",
              cursor: "pointer",
            }}
            onClick={this.closeDialog}
          />
          </div>

        </div>
        <Form>
          <div className={s.body}>
            <div onClick={this.disableSelect() ? null : this.handleStartSelect}>
              <RaisedButton
                className={cx(this.disableSelect() && s.select_diabled)}
                primary
                styles="normal"
                label={<span>
                        <SelectElements
                          style={{
                            marginRight: '6px',
                          }}
                        />
                        Select Elements
                      </span>}
                style={{
                  minWidth: 'unset',
                  width: '100%',
                  borderRadius: '50px',
                  height: '32px',
                  marginBottom: '12px',
                }}
                buttonStyle={{
                  background: '#4380C7',
                  borderRadius: '50px',
                }}
                labelStyle={{
                  height: '100%',
                  fontSize: '13px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </div>
            <div className={s.selected_elements}>
                {selectedElementsList.length ?
                  selectedElementsList.map(el => (
                    <div key={`${el.entity}${el.id}`} className={s.selected_element}>
                      <span className={s.selected_element_text}>{`${el.type}: ${el.id}`}</span>
                      <IconClose
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={this.handleRemoveSelected.bind(this, el)}
                      />
                    </div>
                  ))
                  : null
                }
            </div>
            <div className={s.topic_autocomplete_wrapper}>
            <TopicAutocomplete
              handleAddChip={this.newRequest}
              handleDeleteChip={this.removeTopic}
              chips={topics}
              popoverProps={{ className: s.topic_autocomplete_popover }}
              name={"topics"}
              label={"Topics:"}
            />
            </div>
            <AutoGrowTextArea
              value={text}
              onChange={this.textAreaChange}
              name="text"
              className={s.issue_report_textarea}
            />
            <div className={s.issue_report_attachments_wrapper} >
              <div onDragEnter={this.handleDrag} className={cx(s.issue_report_attachments, attachments.length >= 10 && s.disabled)}>
                <FormsyAttachments onChange={this.attachmentsChanges} files={attachments} name={"attachments"} />
                {(this.state.dragActive && attachments.length < 10) && <span className={s.drag_element} onDragEnter={this.handleDrag} onDragLeave={this.handleDrag} onDragOver={this.handleDrag} onDrop={this.handleDrop} />}
              </div>
              <div onClick={this.startCaprture} className={cx(s.issue_report_screenshot_button, attachments.length >= 10 && s.disabled)}>
                <div className={s.button_label}>
                  <ScreenshotIcon />
                  take screenchot
                </div>
              </div>
            </div>
            {attachments.length || text.length || topics.length || selectedElementsList.length ?
              <div className={s.issue_report_footer}>
                <RaisedButton
                  label={'CANCEL'}
                  disableTouchRipple
                  disableFocusRipple
                  secondary
                  onClick={resetForm}
                  style={{
                    borderRadius: '15px',
                    marginRight: "8px",
                  }}
                  buttonStyle={{
                    borderRadius: '15px',
                  }}
                />
                <SpinnerButton
                  label={"SEND"}
                  disableTouchRipple
                  disableFocusRipple
                  primary
                  onClick={handleSubmit}
                  style={{
                    borderRadius: '15px',
                  }}
                  buttonStyle={{
                    borderRadius: '15px',
                  }}
                />
              </div>
          : null
            }

          </div>
        </Form>
        <NotReportedDialog open={notReportedDialogOpen} close={this.closeDialog} accept={this.closeFormWithoutSave} />
      </div>
    );
  }
}

export default IssueReportPanel;

function NotReportedDialog({ accept, open, close }) {
  return (
    <Dialog
      title="ISSUE IS NOT REPORTED"
      titleClassName={s.dialogTitle}
      handleClose={close}
      open={open}
      modal
      contentClassName={s.dialogContent}
      bodyClassName={s.dialogBody}
      actionsContainerClassName={s.actions}
      actions={
        <div className={s.actions}>
          <RaisedButton label="NO" secondary onTouchTap={close} />
          <RaisedButton label="YES" style={{ marginLeft: '8px' }} primary onTouchTap={accept} />
        </div>
      }
    >
        Would you like to close reporting?
    </Dialog>
  )
}
