import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import ShipnextEcosystem from './Technology/ShipnextEcosystem';
import ShipnextSupplyChainEcosystem from './Technology/ShipnextSupplyChainEcosystem';
import BlockchainTechnology from './Technology/BlockchainTechnology';
import DataSecurity from './Technology/DataSecurity';
import ShipnextSolutions from './Technology/ShipnextSolutions';
import Interconnectivity from './Technology/Interconnectivity';

class PageTechnology extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_technology_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Technology</h1>
                </div>
              </div>
            </div>

          </div>
        </div>

        <span className={s.anchor} id="shipnext-ecosystem" />
        <ShipnextEcosystem />

        <span className={s.anchor} id="shipnext-supply-chain-ecosystem" />
        <ShipnextSupplyChainEcosystem />

        <span className={s.anchor} id="interconnectivity" />
        <Interconnectivity />


        <span className={s.anchor} id="blockchain-technology" />
        <BlockchainTechnology />


        <span className={s.anchor} id="data-security" />
        <DataSecurity />


        <span className={s.anchor} id="shipnext-solutions" />
        <ShipnextSolutions />
      </div>
    );
  }
}

export default withStyles(s)(PageTechnology);
