import React, { Component } from 'react';
import styled from 'styled-components';
import DropDownIco from 'material-ui/svg-icons/navigation/arrow-drop-down';
import Paper from 'material-ui/Paper';
import Highlight from '../Monitor/Highlight';
import enhanceWithClickOutside from 'react-click-outside';
import WithTooltip from "../Common/WithTooltip";
import ContactDetailsPopover from "../ContactBook/ContactDetailsPopover";


function mapEmail(searchRegExp, email){
  return <div><ContactDetailsPopover email={email}>
    <a target="_blank" href={`mailto:${email}`}><Highlight search={searchRegExp}>{email}</Highlight></a>
  </ContactDetailsPopover></div>;
}
const EmailHeaders = styled(({ className, searchRegExp, ...props }) => {
  const { from = [], to = [], cc = [], bcc = [], shouldReplyTo = [], subject = '' } = props;
  const _mapEmail = mapEmail.bind(this, searchRegExp);
  return <div className={className}>
    <div>
      <label>From:</label>
      <div>{from.map(_mapEmail)}</div>
    </div>
    <div>
      <label>To:</label>
      <div>{to.map(_mapEmail)}</div>
    </div>
    {shouldReplyTo.length?
      <div>
        <label>Reply to:</label>
        <div>{shouldReplyTo.map(_mapEmail)}</div>
      </div>
      : null}
    {cc.length ?
      <div>
        <label>Cc:</label>
        <div>{cc.map(_mapEmail)}</div>
      </div>
      : null}
    {bcc.length ?
      <div>
        <label>Bcc:</label>
        <div>{bcc.map(_mapEmail)}</div>
      </div>
      : null}
    <div>
      <label>Subject:</label>
      <div><strong><Highlight search={searchRegExp}>{subject}</Highlight></strong></div>
    </div>
  </div>
})`
  font-size: 12px;
  line-height: 24px;
  color: var(--text-dark333);
  text-align: left;
  > div {
    display: flex;
    label {
      min-width: 52px;
      font-weight: 700;

    }

  }
  strong {
    font-weight: 700;
  }
  a {
    text-decoration: underline;
  }
`;

export default EmailHeaders;

const PopoverContentWrapper = styled.div`
  padding: 12px;
  width: 345px;
`

const PopoverOutsideClickWrapper = enhanceWithClickOutside(
  class extends Component{
    handleClickOutside (e) {
      this.props.handleClickOutside(e);
    }
   render() {
    return <PopoverContentWrapper>{this.props.children}</PopoverContentWrapper>;
   }
  }
)

export class EmailHeadersPopover extends Component {
  state = { open: false };
  toggle = (e) => {
    this.setState({ open: !this.state.open, anchor: e.target });
    e.preventDefault();
    e.stopPropagation();
  }
  handleClickOutside = () => {
    if (!this.state.open) {
      return;
    }
    this.setState({ open: false });
  }
  render() {
    const message = this.props.message;
    if (!(message.from[1] || message.to[1] || message.cc?.length || message.bcc?.length || message.shouldReplyTo?.length)) {
      return null;
    }
    return (
      <span>
        <WithTooltip
          showOnEventName="onClick"
          position="absolute"
          noArrow
          style={{ position: 'relative' }}
          tip={({ handleClose }) => (<Paper>
            <PopoverOutsideClickWrapper handleClickOutside={() => setTimeout(handleClose)} >
              <EmailHeaders from={message.from} to={message.to} shouldReplyTo={message.shouldReplyTo} cc={message.cc} bcc={message.bcc} subject={message.subject} />
            </PopoverOutsideClickWrapper>
        </Paper>)}
        >
           <DropDownIco style={{ cursor: 'pointer' }} />
        </WithTooltip>
      </span>
    );
  }
}

export const EmailHeadersCollapsed = styled(({ className, searchRegExp, message, ...props }) => {
  const { from = [], to = [], subject = '' } = message;
  return <div className={className}>
    <div>
      <label>From:</label>
      <ContactDetailsPopover email={from[0]}>
        <a href={`mailto:${from[0]}`}>
          <Highlight search={searchRegExp}>{from[0]}</Highlight>
        </a>
      </ContactDetailsPopover>
    </div>
    <div>
      <label>To:</label>
      <ContactDetailsPopover email={to[0]}>
        <a href={`mailto:${to[0]}`}>
          <Highlight search={searchRegExp}>{to[0]}</Highlight>
        </a>
      </ContactDetailsPopover>
        <EmailHeadersPopover message={message} />
    </div>
    <div>
      <label>Subject:</label>
      <div><strong><Highlight search={searchRegExp}>{subject}</Highlight></strong></div>
    </div>
  </div>
})`
  font-size: 12px;
  line-height: 24px;
  color: var(--text-dark333);
  text-align: left;
  > div {
    display: flex;
    label {
      min-width: 52px;
      font-weight: 700;

    }

  }
  strong {
    font-weight: 700;
  }
  a {
    text-decoration: underline;
  }
`;

