import React, { Component } from 'react'
import cx from 'classnames';
import s from './ArrowPopover.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import DropDownIco from 'material-ui/svg-icons/navigation/arrow-drop-down';

import Hoverable from './Hoverable';


const defaultIcon = <DropDownIco style={{width: 24, height: 24}} />;

export class ArrowPopover extends Component {

  static defaultProps = {
    list: [],
    handleSelect: () => undefined,
    displayLabel: val => val,
    style: { fontWeight: 700, whiteSpace: "nowrap", color: '#333333', fontSize: '14px', lineHeight: '18px', cursor: 'not-allowed' }
  }

  constructor(props) {

    super(props);

    this.state = {
      open: false,
      anchor: null
    }
  }

  handleOpenClose = (val, ev) => {
    this.setState({
      open: val,
      anchor: ev ? ev?.currentTarget || null : null,
    })
  }

  handleClose = (ev) => {
    this.handleOpenClose(false, ev);
  }
  handleOpen = (ev) => {
    if (this.props.disabled) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    this.handleOpenClose(true, ev);
  }

  defaultRenderer = () => {
    const { list } = this.props;
    const { handleSelect } = this;
    return list?.map(c => <MenuItem onClick={handleSelect.bind(this, c)} value={c} primaryText={c} key={c.id}></MenuItem>);
}

  handleSelect = (val) => {
    this.handleOpenClose(false);
    this.props.handleSelect(val);
  }

  render() {

    const {list = [], ico = defaultIcon, style = {}, className, labelClassName = "", popoverClassName = s.default_popover_style, val = 'Select', displayLabel = val => val, renderer = this.defaultRenderer, disabled, popoverProps = {},  ...rest} = this.props;

    const { open, anchor } = this.state;
    return (
      <div className={cx(className, open && 'opened')} style={{display: 'flex', color: '#333333', fontSize: 14, fontWeight: 400, ...style}}>
        <Hoverable disabled={disabled} isDark={this.props.isDark} className={cx(s.label_ico, labelClassName)} onClick={this.handleOpen}>
          <span>
            {displayLabel(val)}
          </span>
          {
            ico
          }
        </Hoverable>
        <Popover
          anchorEl={anchor}
          open={open}
          anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
          targetOrigin={{horizontal: 'left', vertical: 'top'}}
          onRequestClose={this.handleClose}
          className={popoverClassName}
          {...popoverProps}
          {...rest}
        >
          <Menu onItemTouchTap={this.handleClose} value={val}>
            {
              renderer(list).map(e => React.cloneElement(e, {
                onClick: ev => {
                  if (e.props.onClick) {
                    e.props.onClick(e, ev);
                  }
                  this.handleClose();
                }
              }))
            }
          </Menu>
        </Popover>
      </div>
    )
  }
}

export default withStyles(s)(ArrowPopover);
