import React, { Component } from 'react';
import cx from 'classnames';

import { MenuItem } from 'material-ui';
import popoverStyles from '../ArrowPopover.scss';
import ArrowPopover from '../ArrowPopover';

const valueList = {
  reply: <span className="material-icons-outlined">reply</span>,
  forward: <span className="material-icons">forward</span>,
  subject: <span className="material-icons">edit</span>,
};

class ToPopover extends Component {
  state ={
    selected: "reply",
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.replyTo) {
      if (nextProps.replyTo.id !== this.props.replyTo?.id || nextProps.replyTo.actionType !== this.props.replyTo?.actionType) {
        this.setState({ selected: nextProps.replyTo.actionType });
      }
    }
  }
  handleSelect = (val) => {
    this.setState({ selected: val });
  }
  renderer = (list) => {
    return list.map(item => <MenuItem leftIcon={item.icon} value={item.value} onClick={item.handler} primaryText={item.name} />);
  }
  renderSelected = () => valueList[this.state.selected];

  render(){
    const { handleForward, handleReply, handleSubject, replyTo, isNotNew } = this.props;
    const { selected } = this.state;
    return (
      <div style={{ display: "flex" }}>
        {
          isNotNew &&
          <ArrowPopover
            val={selected}
            list={[
              {
                name: 'Reply',
                icon: <span className="material-icons-outlined">reply</span>,
                handler: () => { handleReply(replyTo); this.handleSelect("reply"); },
                value: "reply",
              },
              {
                name: 'Forward',
                icon: <span className="material-icons">forward</span>,
                handler: () => { handleForward(replyTo); this.handleSelect("forward"); },
                value: "forward",
              },
              {
                name: 'Edit subject',
                icon: <span className="material-icons">edit</span>,
                handler: () => { handleSubject(); },
                value: "subject",
              },
            ]}
            displayLabel={this.renderSelected}
            popoverClassName={cx(popoverStyles.to_popover)}
            labelClassName={cx(popoverStyles.to_label_ico)}
            renderer={this.renderer}
          />
        }
        To:
      </div>

    );
  }
}

export default ToPopover;
