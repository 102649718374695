import React from 'react'
import cx from 'classnames';
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SecondText from '../../Monitor/RowBlocks/SecondText';
import VesselRating from '../../Common/VesselRating';
import VesselRatingInsideTableView from '../../Monitor/VesselRatingInsideTableView';
function GearRating({ className = "", isDark = false, gear, vessel, ...rest }) {
  gear = gear && gear.length
      ? gear.join(', ')
      : '---';
  return (
    <div className={cx(s.text, className)} {...rest}>
      <div style={{ fontWeight: '400' }}>{gear}</div>
      <SecondText className={cx(s.second_text, s.raiting_wrapper)}>
        {
          vessel?.status?.name === 'notConfirmed' || vessel?.confirmed === false
            ? null
            : (
              <div
                className={s.raiting_button_open}
              >
                <VesselRating vessel={vessel} dark={isDark} whiteArrow={isDark}>
                  <VesselRatingInsideTableView />
                </VesselRating>
              </div>
            )
        }
      </SecondText>
    </div>
  )
}

export default withStyles(s)(GearRating);
