import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import s from "./IssueReport.scss";
import { selectElement, setIssueSelectable, removeSelectedElement } from "./actions";
import CircleApproveBtn from "../Common/CircleApproveBtn";

class SelectableElement extends Component {
  handleSelect = (ev) => {
    if (Object.values(this.props.selectedElements).flat().length === 20) {
      return;
    }
    if (Object.values(this.props.selectedElements).flat().length < 20) {
      const element = {
        entity: this.props.entity,
        type: this.props.type,
        id: this.props.id,
        entityId: this.props.entityId,
      };
      this.props.selectElement(element);
    }
  }
  handleUnselect = (ev) => {
      const element = {
        entity: this.props.entity,
        type: this.props.type,
        id: this.props.id,
        entityId: this.props.entityId,
      };
      this.props.removeSelectedElement(element);
  }
  render() {
    const { style, children, selectable, selectedElements } = this.props;
    const isSelected = selectedElements[this.props.entity]?.find(el => Object.values(el).includes(this.props.id));
    return (
      selectable
        ? <div className={cx(s.selectable_element_wrapper, isSelected ? s.selected : "")} onClick={isSelected ? this.handleUnselect : this.handleSelect}>
        <div style={{ ...style }} className={s.selectable_element}>
          {children}
          <div className={s.selected_icon}>
            <CircleApproveBtn />
          </div>

        </div>
      </div>
        : <div>{children}</div>
    );
  }
}
const mapStateToProps = (state) => (
  {
    selectedElements: state.issueReport.selectedElements,
    selectable: state.issueReport.selectable,
  }
);
const mapDispatchToProps = (dispatch) => (
  {
    selectElement: (el) => dispatch(selectElement(el)),
    setIssueSelectable: (selectable) => dispatch(setIssueSelectable(selectable)),
    removeSelectedElement: (el) => dispatch(removeSelectedElement(el)),
  }
);
export default connect(mapStateToProps, mapDispatchToProps)(SelectableElement);
