/** @name GET_INDEX_CHART_DATA */
export default function GET_INDEX_CHART_DATA(state, action) {
    const newState = {};
  
    newState.balticIndex = {
      ...state.balticIndex,
      chartLoading: true,
    };
  
    return { ...state, ...newState };
  }
