exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ARua9 {\n  display: -ms-flexbox;\n  display: flex; }\n\n._2SQzA {\n  -ms-flex-align: center;\n      align-items: center; }\n\n._2Q6MN {\n  width: 100%; }\n\n._3_S-a {\n  gap: 16px; }\n\n._3pi-- {\n  display: grid; }\n\n.Ojb4Z {\n  grid-template-columns: repeat(4, minmax(0, 1fr)); }\n\n._1Synh {\n  margin-left: 16px; }\n", ""]);

// Exports
exports.locals = {
	"flex": "ARua9",
	"items_center": "_2SQzA",
	"w_full": "_2Q6MN",
	"gap_16px": "_3_S-a",
	"grid": "_3pi--",
	"grid_cols_4": "Ojb4Z",
	"ml_16px": "_1Synh"
};