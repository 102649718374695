import calculateStatus from './calculateStatus';
/** @name BL_GET_LIST_REDUCER */
export default function BL_GET_LIST(state, { res, payload }) {
  const newState = { blList: { ...state.blList, sort: payload.sort, filters: payload.filters } };
  res.data = res.data.map((bl) => {
    bl.ps = calculateStatus(bl);
    return bl;
  });
  if (res.page === 1) {
    newState.blList.data = res.data;
  } else {
    newState.blList.data = [...newState.blList.data, ...res.data];
  }
  newState.blList.loading=false;
  return { ...state, ...newState };
}
