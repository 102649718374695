import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

const _Exchange = props => (
  <span {...props}>
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="29" fill="#F5F5F5"/>
      <g clipPath="url(#clip0_35055_31589)">
        <path d="M34.1669 40.0927C37.0552 40.1013 39.9425 40.1184 42.8304 40.12C43.6086 40.1204 44.3869 40.1196 45.1652 40.1173C46.1213 40.1146 46.6531 39.5737 46.6588 38.6935C46.6588 38.69 46.6589 38.6864 46.6589 38.6828C46.6662 33.6023 46.6692 28.5219 46.6442 23.4415C46.6427 23.1606 46.381 22.8811 46.2413 22.6016C45.2328 23.3469 44.2302 24.0977 43.2172 24.8376C41.3619 26.1927 39.511 27.5518 37.6409 28.8891C36.5074 29.6988 35.3416 30.4689 34.1905 31.2567L34.1772 31.2704C34.1728 34.2093 34.1699 37.151 34.1669 40.0927Z" fill="#50A011"/>
        <path d="M45.1652 40.1173C46.1213 40.1146 46.6531 39.5737 46.6588 38.6935V44.1861C46.6588 44.3735 46.4243 44.458 46.3048 44.3137L42.8304 40.12C43.6086 40.1204 44.3869 40.1196 45.1652 40.1173Z" fill="#50A011"/>
        <path d="M34.1616 40.0926C34.1645 37.151 34.1689 34.2106 34.1719 31.269C34.0425 31.2101 33.897 31.1703 33.7852 31.0895C30.9419 29.0288 28.0987 26.9709 25.2671 24.8979C24.1983 24.1155 23.1604 23.2962 22.1078 22.4933C22.0004 22.8029 21.8108 23.1112 21.8005 23.4236C21.7623 24.6718 21.7799 25.9214 21.7755 27.1709C21.774 29.7605 21.7711 32.3486 21.7696 34.9382C21.7755 36.2124 21.7755 37.4866 21.7887 38.7609C21.7976 39.5679 22.3136 40.1214 23.178 40.1228C26.8373 40.131 30.4994 40.105 34.1616 40.0926Z" fill="#94D352"/>
        <path d="M22.1102 22.4933C23.1628 23.2962 24.2007 24.1156 25.2695 24.8979C28.101 26.9709 30.9458 29.0289 33.7876 31.0895C33.8993 31.1704 34.0449 31.2101 34.1743 31.269L34.1875 31.2567C34.186 28.2849 34.1846 25.3131 34.1831 22.3413C34.1787 22.2549 34.1743 22.1686 34.1684 22.0823C30.4606 22.0755 26.7529 22.0576 23.0466 22.0796C22.7335 22.0809 22.4218 22.3495 22.1102 22.4933Z" fill="#B9F07F"/>
        <path d="M34.1854 22.34C34.1869 25.3118 34.1884 28.2836 34.1898 31.2554C35.341 30.4676 36.5083 29.6976 37.6418 28.8878C39.5118 27.5519 41.3627 26.1914 43.2181 24.8363C44.231 24.0965 45.2351 23.347 46.2422 22.6003C45.9408 22.4276 45.6409 22.255 45.3395 22.0837C45.0955 22.1687 44.8514 22.3276 44.6074 22.329C41.1319 22.3454 37.6594 22.34 34.1854 22.34Z" fill="#69C026"/>
        <path d="M34.1872 22.3399C37.6612 22.3399 41.1352 22.3454 44.6077 22.3289C44.8517 22.3276 45.0958 22.1686 45.3398 22.0837C41.6174 22.0823 37.895 22.0823 34.1711 22.0809C34.1769 22.1673 34.1828 22.2536 34.1872 22.3399Z" fill="#69C026"/>
        <path d="M25.5048 33.3625C22.6166 33.3712 19.7293 33.3884 16.8415 33.3899C16.0632 33.3903 15.285 33.3896 14.5067 33.3873C13.5506 33.3845 13.0188 32.8415 13.0131 31.9578C13.013 31.9542 13.013 31.9506 13.013 31.947C13.0057 26.8464 13.0027 21.7458 13.0277 16.6452C13.0292 16.3632 13.2909 16.0826 13.4305 15.802C14.4391 16.5503 15.4417 17.3041 16.4546 18.0469C18.3099 19.4074 20.1609 20.7719 22.0309 22.1145C23.1644 22.9274 24.3302 23.7005 25.4813 24.4915L25.4946 24.5052C25.499 27.4558 25.5019 30.4092 25.5048 33.3625Z" fill="#4380C7"/>
        <path d="M14.5067 33.3873C13.5506 33.3845 13.0188 32.8415 13.0131 31.9578V37.3911C13.0131 37.5777 13.2459 37.6626 13.3661 37.5198L16.8415 33.3899C16.0632 33.3903 15.285 33.3896 14.5067 33.3873Z" fill="#4380C7"/>
        <path d="M25.5064 33.3625C25.5034 30.4092 25.499 27.4572 25.4961 24.5039C25.6255 24.4447 25.771 24.4048 25.8827 24.3237C28.726 22.2548 31.5693 20.1887 34.4008 18.1075C35.4696 17.322 36.5075 16.4994 37.5601 15.6934C37.6674 16.0042 37.8571 16.3137 37.8674 16.6274C37.9056 17.8805 37.888 19.135 37.8924 20.3895C37.8938 22.9894 37.8968 25.5878 37.8982 28.1876C37.8924 29.4669 37.8924 30.7462 37.8791 32.0255C37.8703 32.8357 37.3543 33.3914 36.4898 33.3928C32.8307 33.401 29.1685 33.3749 25.5064 33.3625Z" fill="#285596"/>
        <path d="M37.5616 15.6934C36.509 16.4995 35.4711 17.3221 34.4023 18.1075C31.5708 20.1887 28.726 22.2548 25.8843 24.3237C25.7725 24.4049 25.627 24.4448 25.4976 24.5039L25.4844 24.4915C25.4858 21.5079 25.4873 18.5243 25.4888 15.5407C25.4932 15.454 25.4976 15.3674 25.5035 15.2807C29.2112 15.2738 32.9189 15.256 36.6251 15.278C36.9383 15.2793 37.25 15.549 37.5616 15.6934Z" fill="#72B8F9"/>
        <path d="M25.5118 15.2822C25.5103 18.2658 25.4795 21.5067 25.478 24.4903C24.3269 23.6993 23.1596 22.9263 22.0261 22.1133C20.1561 20.7721 18.3052 19.4062 16.4499 18.0457C15.4369 17.3029 14.4328 16.5505 13.4258 15.8008C13.7272 15.6275 14.0271 15.4542 14.3285 15.2822C14.6354 15.2823 15.2811 15.2808 15.5252 15.2822C19.0006 15.2987 22.0378 15.2822 25.5118 15.2822Z" fill="#9AC8FF"/>
      </g>
      <defs>
        <clipPath id="clip0_35055_31589">
          <rect width="36" height="32" fill="white" transform="translate(11 13)"/>
        </clipPath>
      </defs>
    </svg>
  </span>
);

const Exchange = pure(_Exchange);
Exchange.displayName = 'Exchange';
Exchange.muiName = 'SvgIcon';

export default Exchange;
