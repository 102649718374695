import React, { Component } from 'react'
import Tag from './Tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Tags.scss';
export class TagsChips extends Component {
  render() {

    const { tags, handleDelete } = this.props;

    return (
      <div className={s.tag_list}>
        {
          tags.map(tag => <Tag handleDelete={handleDelete} key={`${tag._id}${tag.categoryId || ""}`} tag={tag} {...this.props} />)
        }
      </div>
    )
  }
}

export default withStyles(s)(TagsChips);
