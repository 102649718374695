export default function CHANGE_VESSEL_STATUS(state, action) {
  const newState = { vesselList: { ...state.vesselList } };

  const index = newState.vesselList.data.findIndex(vessel => vessel._id === action.payload.vesselId);

  let newVessel = {
    ...(newState.vesselList.data[index]),
    vesselUserGroups: {
      ...(newState.vesselList.data[index]?.vesselGroups || {}),
      groups: action.payload.vesselGroups.groups
    }
  }
  newState.vesselList.data[index] = newVessel;
  return { ...state, ...newState };
}
