/** @name PROFILE_GET_ACCOUNT_REDUCER */
export default function PROFILE_GET_ACCOUNT(state, { res }) {
    const index = state.departments.findIndex(el => el._id === res.data._id);
    let newState;
    if(index !== -1){
      const updatedDepartments = state.departments.map((el, i) =>
      i === index ? res.data : el
    );
    newState = { departments: updatedDepartments };
    } else {
      newState = { departments: [...state.departments,res.data]}
    }
    return { ...state, ...newState };
  }
  