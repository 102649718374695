import React from "react";
import pure from "recompose/pure";

let OtherCargoIcon = ({ fill = '#999999', ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.85714 1.8H10V3.51016L6.59766 6H5.28748L2 3.59421V1.8H3.14286V0H4.28571V1.8H7.71429V0H8.85714V1.8ZM5.42857 8.4V7.27561C5.61121 7.22625 5.80269 7.2 6 7.2C7.26237 7.2 8.28571 8.27452 8.28571 9.6C8.28571 10.9255 7.26237 12 6 12C4.73763 12 3.71429 10.9255 3.71429 9.6H4.85714C4.85714 10.2627 5.36882 10.8 6 10.8C6.63118 10.8 7.14286 10.2627 7.14286 9.6C7.14286 8.93726 6.63118 8.4 6 8.4H5.42857Z" fill={fill}/>
  </svg>
);

OtherCargoIcon = pure(OtherCargoIcon);
OtherCargoIcon.displayName = "OtherCargoIcon";
OtherCargoIcon.muiName = "SvgIcon";

export default OtherCargoIcon;
