const changeArrItem = (list, field, val) => list.map(item => ({...item, [field]: val}))

export default function SNP_UPDATE_ALL(state, {payload: {field, value}}) {

  const snpList = changeArrItem(state.snpList.data, field, value);

  const filteredSnpList = changeArrItem(state.snpList.filtered, field, value);

  return { ...state, snpList: { ...state.snpList, filtered: filteredSnpList, data: snpList } };
}
