import {someProp} from "../../core/utils";

export function mapChipsToEmail(chips = []) {
  return chips.map(c => c.email);
}

export function isExchangeFiltersEmpty(filters = {}) {
  const { scopes, ...restFilters } = filters;
  if (scopes && scopes.length > 0 && scopes.length < 9) {
    return false;
  }
  return !someProp(restFilters, (val) => val !== undefined);
}

export function flattenAliases(gates){
  const flatGates = [];
  gates.forEach((gate, i) => {
    const { _id, userId, address, broken, cc, bcc, replyTo } = gate;
    let { senderAliases = [] } = gate;
    let ccList = [];
    let bccList = [];
    let replyToList = [];
    if (cc && cc.enabled) {
      ccList = cc.emails.map(e => e.address);
    }
    if (bcc && bcc.enabled) {
      bccList = bcc.emails.map(e => e.address);
    }
    if (replyTo && replyTo.enabled) {
      replyToList = replyTo.emails.map(e => e.address);
    }
    flatGates.push({ __id: _id, _id, userId, address, broken, cc: ccList, bcc: bccList, replyTo: replyToList });
    if (!Array.isArray(senderAliases)) {
      senderAliases = [];
    }
    senderAliases.forEach((sa) => {
      const { _id: senderAlias, name, address, enabled } = sa;
      if (!address) {
        return;
      }
      flatGates.push({ __id: `${_id},${senderAlias}`, _id, senderAlias, name, address, enabled, broken, cc: ccList, bcc: bccList, replyTo: replyToList });
    });
  });
  return flatGates;
}

export function replaceFromWithAliases(o){
  if (o.senderAliases && o.senderAliases.length && o.from && o.from.length) {
      o.from = o.senderAliases;
  }
  return o;
}
