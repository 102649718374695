import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { debounceWithoutFirstCall as debounce, grabErrorMessage, validateEmail } from './../../../core/utils';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BasicCard from "./../CustomAutocomplete/BasicCard";
import BasicAutocompleteList from "./../CustomAutocomplete/BasicAutocompleteList";
import { withPopover } from "./../../../core/HOC";
import { CardTitle } from "material-ui/Card";
import Highlight from "./../../Monitor/Highlight.js";
import styled, { css } from 'styled-components';
import Misc from './../../../core/api/Misc';
import BasicItem from '../CustomAutocomplete/BasicItem';

import { v4 as uuidv4 } from 'uuid';
import { Chip as _Chip } from 'material-ui';
import Info from 'material-ui/svg-icons/action/info';
import CustomTooltip from '../CustomTooltip';
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import {MenuItem} from "material-ui/Menu/index";
import NarrowMenuItem from "../../NewInputs/NarrowMenuItem";
import ContactBookApi from "../../../core/api/ContactBookApi";
import {ContactBookContactAutocompleteItem} from "../../ContactBook/ContactBookContactAutocomplete";

const InputContainer = styled.div`
  width: 100%;
  min-height: 41px;
  background: #fff;
  border-bottom: 1px solid var(--stroke-light-gray1);
  overflow-y: visible;
  flex-wrap: wrap;
  display: flex;

  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    ${
      props => props.invalid && css`border-bottom: 1px solid var(--text-warning) !important;z-index: 2`
    }
  }
  &:focus-within {
    &:after {
      border-bottom: 1px solid var(--text-green-light);
    }
  }
  .tooltip_error {
    right: -8px !important;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  flex: 1;
  min-width: 120px;
  padding: 9px 0px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  &::placeholder {
    color: var(--text-light);
  }

`

const Card = styled(BasicCard)`
  margin-top: 6px;
`;

const Chip = styled(_Chip)`
  height: 24px;
  overflow: hidden;
  align-items: center;
  margin: 7px 8px 0px 0px !important;
  span {
    padding-right: 8px !important;
    font-size: 12px !important;
    font-weight: 400;
    color: var(--default-dark-text) !important;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
    transform: scale(1.2);
    margin: 0px 8px 0px 0px !important;
  }
`;

const ErrorMsg = styled.div`
  color: var(--text-warning);
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  margin-top: -2px;
`;

const EmailInfo = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 12px !important;
    font-weight: 400;
  }
`;

const CC = styled.span`
  font-weight: bold;
  font-size: 12px;
  display: inline-block;
  margin: 10px 4px 0 0px;
  height: 20px;
  color: var(--text-dark333);
  line-height: 20px;
`;

const Buttons = styled.span`
  display: inline-block;
  height: 20px;
  align-self: flex-end;
  margin-bottom: 11px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
`

// functions that trigger when certain keys are pressed in autocomplete's input
// for bound keys see autocompleteActions
const focusInput = input => {
  if (input) {
    input.focus();
  }
};

const focusCard = (componentInstance, ev) => componentInstance.focusCard(ev);
const addChip = (componentInstance, ev) => {
  const { value } = ev.target;
  ev.preventDefault();
  ev.stopPropagation();
  componentInstance.props.closeSelect();
  if (!validateEmail(value)) {
    // keep autocomplete suggestions popover open
    // ev.preventDefault();
    // ev.stopPropagation();
    return componentInstance.props.inputProps.setFieldError(componentInstance.props.inputProps.name, "Please enter valid email");
  };
  componentInstance.addChip(componentInstance.createChipObj(value));
};
const deleteChip = (componentInstance, ev) => {
  const { value } = ev.target;
  const { chips, handleDeleteChip } = componentInstance.props;

  if (!value && chips.length) {
    handleDeleteChip(chips[chips.length - 1]._id);
  }
  focusInput(componentInstance.input);
};

const autocompleteActions = {
  "ArrowDown": focusCard,
  "Enter": addChip,
  "Backspace": deleteChip,
};


export class ShareAutocomplete extends Component {

  static contextTypes = {
    showMessage: PropTypes.func
  }

  state = {
    dataSource: [],
    isLoading: false,
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onWindowKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onWindowKeyDown);
  }


  handleChange = (ev) => {
    const { value } = ev.target;
    const { inputProps: { onChange = () => { } } = {} } = this.props;

    onChange(ev);

    this.setState({
      isLoading: true,
      inputValue: value,
    }, () => this.props.showSelect());

    this.loadAddressBook(value);

  }

  componentDidUpdate() {
    // clear autocomplete suggestions only if we deleted all chips and cleared input's value
    if (!this.state.inputValue && !this.props.chips.length && this.state.dataSource.length) {
      this.setState({ dataSource: [] });
      this.props.closeSelect();
    }
  }

  loadAddressBook = debounce(async value => {
    try {
      const res = await ContactBookApi.autocompleteContacts({ filters: { q: value } });

      if (res?.data) {
        this.setState({
          dataSource: res.data,
        });
      }
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: "error",
        message: `Error getting address book emails: ${grabErrorMessage(error)}`,
      })
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  })

  focusCard = ev => {
    ev.preventDefault();

    if (this.select) {
      this.select.focus();
    }
  }

  handleClick = (ev, contact) => {
    ev.preventDefault();
    ev.stopPropagation();
    const name = `${contact.name || ''} ${contact.surname || ''}`.trim();
    let email = contact.emails[0];
/*    if (name) {
      email = `${name} <${email}>`;
    }*/
    const chip = { _id: contact.id, email, name };

    if (chip.email.length < 1) return;

    if (!chip._id) {
      chip._id = uuidv4();
    }

    this.addChip(chip);
  }

  addChip = chip => {
    this.props.handleAddChip(chip);
    this.props.inputProps.setFieldError(this.props.inputProps.name, "");
    this.setState({ dataSource: [], inputValue: "" });
    this.props.closeSelect();
    focusInput(this.input);
  }

  resetValue = () => this.setState({ inputValue: "" });

  parsePasteEmails = (ev) => {
    const paste = (ev.clipboardData || window.clipboardData).getData('text');

    if (!paste.length) return;

    const chips = paste.split(/\s+/).map(val => validateEmail(val) && this.createChipObj(val)).filter(chip => !!chip);

    if (chips.length) {
      ev.preventDefault();
      this.addChip(chips);
    }

  }

  createChipObj = val => ({
    email: val,
    _id: uuidv4(),
  })

  handleInputBlur = (e) => {
    const { value } = e.target;
    if (this.props.inputProps?.onBlur) {
      this.props.inputProps?.onBlur(e);
    }
    if (!value || !value.trim()){
      return;
    }
    addChip(this, e);
  }

  onDragOver = (e) => {
    e.preventDefault();
  }
  onDragStart = (chip, e) => {
    e.dataTransfer.setData('text/plain', chip.email);
    e.dataTransfer.effectAllowed = "copyMove";
    this.draggingChip = chip;
}
onDrop = (e) => {
  e.preventDefault();
  this.draggingChip = undefined;
  const email = e.dataTransfer.getData('text/plain');
  if (email && !this.props.chips.some(chip => chip.email === email)) {
    this.props.handleAddChip(this.createChipObj(email));
    e.dataTransfer.dropEffect = 'move';
  }
  e.dataTransfer.dropEffect = 'none';
}

onDragEnd = (chip, e) => {
  try {
    if (this.draggingChip?._id === chip._id) {
      if (e.dataTransfer.dropEffect === 'move') {
        this.props.handleDeleteChip(chip._id);
      }
    }
  } finally {
    this.draggingChip = undefined;
  }
  }

  onChipContextMenu = (chip, e) => {
      e.preventDefault();
      this.setState({ menuAnchorEl: e.currentTarget, selectedChip: chip });
  }
  onMenuClose = () => {
    this.setState({ menuAnchorEl: null, selectedChip: null });
  }

  startSelectedChipEdit = (e, chip = this.state.selectedChip ) => {
    if (e) {
      e.preventDefault();
    }
    setTimeout(()=> {
      this.setState({ inputValue: chip.email, menuAnchorEl: null, selectedChip: null }, () => {
        this.props.handleDeleteChip(chip._id);
        this.input.focus();
      });
    })
  }

  copySelectedChipToClipboard = (e, chip = this.state.selectedChip) => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(chip.email);
    if (this.state.menuAnchorEl) {
      this.setState({ menuAnchorEl: null, selectedChip: null });
    }
  }
  copyFocusedChip = (id, chip) => {
    this.copySelectedChipToClipboard(null, this.findChipById(id, chip));
  }
  editFocusedChip = (id, chip) => {
    this.startSelectedChipEdit(null, this.findChipById(id, chip));
  }
  findChipById = (id, chip) => {
    if (chip) {
      return chip;
    }
    return this.props.chips.find(c => c._id === id);
  }

  onChipKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey) {
      switch (e.key) {
        case 'c':
        case 'C':
          this.copyFocusedChip(e.currentTarget.id, this.state.selectedChip);
          e.preventDefault();
          return;
      }
    }
    if (e.shiftKey) {
      switch (e.key) {
        case 'e':
        case 'E':
          this.editFocusedChip(e.currentTarget.id, this.state.selectedChip);
          e.preventDefault();
          return;
      }
    }
  }

  onWindowKeyDown = (e) => {
    if (this.state.selectedChip && this.state.menuAnchorEl) {
      this.onChipKeyDown(e);
    }
  }

  render() {

    const { open, setAnchorEl, className = "", inputProps = {}, errorText, chips, handleDeleteChip, label, buttons } = this.props;

    let { dataSource, isLoading } = this.state;

    // filter dataSource from already selected chips to prevent duplicates

    // dataSource = dataSource.filter(el => !chips.find(chip => chip.email === el.email));

    return (
      <div ref={setAnchorEl} className={className} style={{ position: "relative" }} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragEnter={this.onDragOver} >
        <div style={{ position: "relative" }}>
          <InputContainer invalid={!open && !!errorText} label={label}>
            {
              label && <CC>{label}</CC>
            }
            {
              chips.map((chip, i) => (
                <Chip
                  id={chip._id}
                  onRequestDelete={() => handleDeleteChip(chip._id)}
                  draggable="true"
                  onDragStart={this.onDragStart.bind(this, chip)}
                  onDragEnd={this.onDragEnd.bind(this, chip)}
                  onContextMenu={this.onChipContextMenu.bind(this, chip)}
                  onKeyDown={this.onChipKeyDown}
                >
                {
                  chip.email
                }
              </Chip>))
            }
            <Input
              onKeyDown={ev => {
                const action = autocompleteActions[ev.key];
                if (action) {
                  action(this, ev);
                }
              }}
              onFocus={dataSource?.length && this.props.showSelect}
              tabIndex="1"
              {...inputProps}
              value={this.state.inputValue}
              onChange={this.handleChange}
              onPaste={this.parsePasteEmails}
              innerRef={el => this.input = el}
              onBlur={this.handleInputBlur}
            />
            {(!open && !!errorText) &&
              <CustomTooltip style={{ alignSelf: "center", marginRight: "5px" }} className="tooltip_error" tooltip={errorText} horizontalAlign="left">
                <Info color="#E34848" />
              </CustomTooltip>
            }
            {buttons && <Buttons style={{ marginRight: `${buttons.props?.children?.length > 0 ? "10px" : "0px"}` }}>{buttons}</Buttons> }
          </InputContainer>
          <Card
            open={open}
            adjustRef={node => (this.select = node)}
            handleClick={this.persistSelect}
            showLoader={isLoading}
            focusInput={focusInput.bind(this, this.input)}
          >
            <div>
              <BasicAutocompleteList
                fallback={isLoading ? null :
                  <CardTitle
                    style={{ padding: "12px" }}
                    subtitle={"No emails found"}
                  />
                }
                list={dataSource}
                renderItem={listItem => (
                  <EmailItem
                    handleClick={this.handleClick}
                    keyword={inputProps.value || ""}
                    item={listItem}
                  />
                )}
              />
            </div>
          </Card>
        </div>
       {/* {
            errorText
              && <ErrorMsg>{ errorText }</ErrorMsg>
        }*/}
        {this.state.menuAnchorEl ?
          <Popover
            open
            anchorEl={this.state.menuAnchorEl}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            onRequestClose={this.onMenuClose}
          >
            <Menu style={{ width: '256px' }} onKeyDown={this.onChipKeyDown}>
              <NarrowMenuItem primaryText="Copy" onClick={this.copySelectedChipToClipboard} secondaryText="Ctrl+C" />
              <NarrowMenuItem primaryText="Edit" onClick={this.startSelectedChipEdit} secondaryText="Shift+E" />
            </Menu>
          </Popover>
          : null}
      </div>
    )
  }
}

export function EmailItem({ item, handleClick, keyword, ...rest }) {
  const regExp = new RegExp(
    keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
    "i"
  );
  return (
    <BasicItem
      item={item}
      handleClick={handleClick}
      {...rest}
    >
      <ContactBookContactAutocompleteItem contact={item} />
    </BasicItem>
  )
}

export default withPopover(ShareAutocomplete)
