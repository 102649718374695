/*
 * @Author: Sergiy Samborskiy
 * @Date: 2017-10-19 19:34:13
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-27 18:12:20
 */

import React from "react";

import s from "../Chat.scss";
import cx from "classnames";

import IconSend from "material-ui/svg-icons/content/send";
import IconAttachFile from "material-ui/svg-icons/editor/attach-file";

import TextField from "material-ui/TextField";

export function SendMessageArea(props) {
    const { sendMessage } = props;
    let textField;

    function onEnter(event) {
        if (event.key === "Enter" && event.ctrlKey) {
            send(textField);
        }
    }

    function send(textField) {
        sendMessage(textField.getValue());
        textField.getInputNode().value = "";
        textField.input.setValue("");
    }

    return (
        <div className={s.enter_message}>
            <div className={s.wrapper_attach}>
                <label>
                    <IconAttachFile
                        style={{
                            width: "20px",
                            height: "20px",
                            transform: "rotate(45deg)",
                        }}
                        color={"#7FC931"}
                    />
                    {<input type="file" />}
                </label>
            </div>
            <TextField
                ref={field => textField = field}
                multiLine={true}
                underlineFocusStyle={{ borderColor: '#4380C7' }}
                hintText="Type a message here"
                hintStyle={{
                    color: "#B3B3B3",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    lineHeight: "24px"
                }}
                style={{
                    width: "302px",
                    fontSize: "14px",
                    lineHeight: "24px"
                }}
                onKeyUp={onEnter}
            />
            <IconSend
                className={s.send_icon}
                onClick={() => send(textField)}
            />
        </div>
    );
}
