exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3-dwV {\n  position: relative;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: start;\n      align-items: flex-start;\n  -ms-flex-pack: start;\n      justify-content: flex-start;\n  background: #fff;\n  -webkit-transition: .3s ease-in-out;\n  transition: .3s ease-in-out;\n  height: auto;\n  overflow: hidden;\n  outline: none; }\n  ._3-dwV:focus {\n    outline: none; }\n  ._3-dwV._3vxiR {\n    -webkit-transition: none;\n    transition: none; }\n  ._3-dwV ._6rflW {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: justify;\n        justify-content: space-between;\n    border-bottom: 1px solid #e6e6e6;\n    margin-bottom: 1px;\n    cursor: pointer;\n    width: 100%;\n    -ms-flex: 1 0 32px;\n        flex: 1 0 32px;\n    max-height: 32px; }\n  ._3-dwV ._6rflW ._1tGC7 {\n      color: #333333;\n      font-family: 'Roboto';\n      font-weight: 600;\n      font-size: 16px;\n      text-transform: uppercase;\n      line-height: 24px;\n      margin: 0px; }\n  ._3-dwV ._6rflW ._35H4G {\n      display: -ms-flexbox;\n      display: flex;\n      -ms-flex-align: center;\n          align-items: center;\n      height: 100%;\n      min-height: 32px;\n      -ms-flex: 1 1;\n          flex: 1 1; }\n  ._3-dwV ._6rflW ._35H4G svg {\n        margin-right: 8px;\n        min-width: 24px; }\n  ._3-dwV .S3NQH {\n    height: auto;\n    width: 100%;\n    outline: none; }\n  ._3-dwV .S3NQH:focus {\n      outline: none; }\n", ""]);

// Exports
exports.locals = {
	"collapse_wrapper": "_3-dwV",
	"no_animation": "_3vxiR",
	"collapse_header": "_6rflW",
	"title": "_1tGC7",
	"left": "_35H4G",
	"collapse_body": "S3NQH"
};