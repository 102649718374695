import React, { PureComponent } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'countup.js';

export default class Counter extends PureComponent {


  static defaultProps = {
    duration: 2,
    startValue: 0,
    endValue: 0,
    prefix: '',
    suffix: '',
  };

  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !this.counter;
  }


  handleVisibilityChange = (isVisible) => {
    this.setState({ isVisible }, () => {
      if (isVisible && !this.counter) {
        this.counter = new CountUp(this.container, parseInt(this.props.startValue, 10), parseInt(this.props.endValue, 10), 0, this.props.duration, {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: this.props.prefix,
          suffix: this.props.suffix,
        });
        this.counter.start();
      }
    });
  };
  handleContainerRef = (container) => {
    this.container = container;
  };

  render() {
    return (
      <VisibilitySensor active={!this.state.isVisible} onChange={this.handleVisibilityChange}>
        <div ref={this.handleContainerRef}>{this.props.startValue}</div>
      </VisibilitySensor>
    );
  }
}
