// PASS FUNCTION AS DATA IF YOU NEED TO MERGE OBJECT AND SAVE EXISTING FIELDS
import { updateItem, updateItemsInArr } from './helpers'
export default function UPDATE_VESSELS_LIST(state, { payload }) {
  const newState = { ...state.vesselList };
  let newList = [...newState.data];
  if (Array.isArray(payload)) {
    newList = updateItemsInArr(newList, payload);
  }
  else {
    const { vesselId, data } = payload;
    newList = updateItem([...newState.data], vesselId, data);
  }
  newState.data = newList;
  //return { ...state, tcList: filterTCOrders(newState) };
  return { ...state, vesselList: newState };
}
