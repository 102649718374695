export const specialties = {
  'bulk': [
    'Cement Carrier',
    'Wood Chips Carrier',
    'Aggregate Carrier',
    'Limestone Carrier',
    'Ore/Sulphuric Acid Carrier',
    'Ore Carrier',
    'Salt Carrier',
    'Slurry Carrier',
    'Urea Carrier',
    'Self Discharging Bulk Carrier',
    'Powder Carrier',
    'Bauxite Carrier',
    'Grain Carrier',
    'Refined Sugar Carrier',
    'Bulk Dry Storage Ship',
    'Pulp Carrier',
  ],
  'livestock': [
      'Livestock Carrier',
      'Open Livestock Carriers',
      'Close Livestock Carriers',
  ],
  'bulktank': [
    'Bulk/Oil Carrier',
    'Bulk/Liquid Carrier',
    'OBO Carrier (ore/bulk/oil)',
  ],
  'mpp': [
    'Container/Break Bulk Ship',
    'Container/Bulk Ship',
    'Deck Cargo Carrier',
    'Heavy Load Carrier',
    'Heavy Lift Vessel',
    'Landing Craft',
    'Palletised Cargo Carrier',
    'Ro-Ro/Pass Carrier',
    'Ro-Lo Cargo Carrier',
    'Ro-Ro Cargo Carrier',
    'Ro-Ro/Container Carrier',
    'Timber Carrier',
  ],
  'reefer': [
    'Reefer/General Cargo',
    'Reefer Fish Carrier',
    'Reefer/Pallets Carrier',
    'Reefer/Ro-Ro Cargo',
    'Reefer/Containership',
  ],
  'tank': [
    'Chemical Tanker',
    'Chemical/Oil Products Tanker',
    'Oil Products Tanker',
    'Fruit Juice Carrier',
    'Methanol Carrier',
    'Molten Sulphur Carrier',
    'Oil/Liquid Gas Carrier',
    'Oil Recovery Tanker',
    'Phosphoric Acid Carrier',
    'Shuttle Tanker',
    'Sulphuric Acid Carrier',
    'Waste Disposal Carrier',
    'Water Carrier',
    'Asphalt/Bitumen Carrier',
    'Bunkering Tanker',
    'LNG Tanker',
    'LPG Tanker',
    'Crude Oil Tanker',
    'CO2 Tanker',
    'Wine Tanker',
    'Edible Oil Tanker',
    'Vegetable Oil Carrier',
    'Caprolactam Carrier',
    'Molasses Carrier',
    'Latex Carrier',
    'Nuclear Fuel Carrier',
    'Beer Carrier',
    'FSO/FPSO Tanker',
  ],
  'cont': [
    'Barge/Lichter Carrier',
    'Fully Cellular Container Ship',
    'Container/Passenger Ship',
  ],
  'car': [
    'Vehicles Carrier',
    'Rail/Vehicles Carrier',
  ],
  'passcar': [
    'Passenger/Ro-Ro Cargo Ship',
    'Passenger/Cargo Ship',
  ],
  'pass': [
    'Cruise/Passenger Ship',
  ],
};

export const typeByPurposeList = ["BULK", "BULKTANK", "CAR", "CONT", "LIVESTOCK", "MPP", "PASS", "PASSCAR", "REEFER", "TANK"];
export const liquidTypeByPurposeList = ['BULKTANK', 'TANK'];
export const typeByConstructionList = ["","SID", "TWEEN"];

export const piClub = [
  "American Steamship Owners Mutual Protection and Indemnity Association, Inc",
  "The Britannia Steam Ship Insurance Association Limited",
  "Gard P&I (Bermuda) Ltd",
  "The Japan Ship Owners' Mutual Protection & Indemnity Association",
  "The London Steam-Ship Owners' Mutual Insurance Association Limited",
  "The North of England Protecting & Indemnity Association Limited",
  "The Shipowners' Mutual Protection & Indemnity Association (Luxembourg)",
  "Assuranceforeningen Skuld",
  "The Standard Club Ltd",
  "The Steamship Mutual Underwriting Association (Bermuda) Limited",
  "Sveriges Ångfartygs Assurans Förening / The Swedish Club",
  "United Kingdom Mutual Steamship Assurance Association (Bermuda) Ltd",
  "The West of England Ship Owners Mutual Insurance Association (Luxembourg)",
]
export const bunkersList = [
  {
    type: "HFO 180"
  },
  {
    type: "HFO 380"
  },
  {
    type: "MGO"
  },
  {
    type: "LS MGO"
  },
  {
    type: "LNG"
  },
]

export const gearTypes = [
  'CR', 'BT', 'DR', 'GN', 'HC',
];
export const gearLabels = {
  CR: 'Crane',
  BT: 'Boom Transporter',
  DR: 'Derrick',
  GN: 'Gantry',
  HC: 'Hose Crane',
};

export const grabTypes = [
  'Electro-hydraulic',
  'Mechanical',
  'Electro-hydraulic remote control',
  'Mechanical remote control'
]

export const _iceClass = [
  "",
  "fitted",
  "1AS",
  "1A",
  "1B",
  "1C",
  "1D",
]

export const PUMP_TYPES = [
  "Unknown",
  "Centrifugal",
  "Vac-Strip",
  "Reciprocating",
  "Screw",
  "Gear",
  "Eductors / Ejector",
  "Peristaltic",
  "Lobe",
  "Piston",
  "Deepwell",
  "BDPS",
  "Desmi Rotordynamic",
  "Marflex Deepwell",
  "Hydraulic Driven",
  "Framo",
];

export const TANK_COATING_TYPES = [
  "Epoxy",
  "Hempel Hempadur Phenolic Epoxy",
  "Jotun Primastic Unversal Alu Red Toned",
  "Jotun Universal Alu Turquoise",
  "Korepox / Eh 2350",
  "Marine Line",
  "Marine Line 784 (Advanced Polymer Coating)",
  "Phenolic Epoxy",
  "Stainless Steel",
  "Zinc",
];

export const HEATING_MEDIUM = ['Water', 'Steam', 'Thermal Oil'];

export const IGS_TYPES = ['Flue gas', 'Inert gas (IG) generator', 'Nitrogen'];

export const IMO_APP_NAMES = [
  'Appendix a',
  'Appendix b',
  'Appendix c',
  'Appendix a, b',
  'Appendix a, c',
  'Appendix b, c',
  'Appendix a, b, c',
];
