import React from 'react'
import Checkbox from 'material-ui/Checkbox';
import cx from 'classnames';

// 18.06.21 changed appearance of checkboxes on whole website.
// look for "custom-checkbox" selector in Layout.css file.
// dark styles is used for tags chips and exlusions for now.

export function CustomCheckbox({ as, isDark = false, className = "", innerRef = () => { }, ...rest }) {
  const El = as || Checkbox;
  return (
    <El ref={innerRef} className={cx("custom-checkbox", isDark ? "custom-checkbox-dark" : "", className)} {...rest} />
  )
}

export default CustomCheckbox
