import React from 'react';

import AdminTable from './../../components/AdminTable'
import Layout from '../../components/Layout';
import history from '../../core/history';
import { getProfile } from '../../actions/login';

const title = 'Admin Page';
const isAdmin = false;


export default {
  path: '/admin',
  children: [
    {
      path: '/',
      async action() {
        
    
        const Admin = await new Promise((resolve) => {
          require.ensure(
            [],
            require => resolve(require('./Admin').default),
            'admin',
          );
        });
    
        return {
          title,
          chunk: 'admin',
          component: <Admin title={title} />,
        };
      },
    },
    {
      path: '/statistics',
      async action({params}) {
          
          return {
              title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
              component: (
                <Layout>
                  <AdminTable {...params} />
                </Layout>
              ),
              
          };
      }
    },

  ]
 
  
};
