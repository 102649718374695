import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let ExcelIcon = props => (
  <span {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6647 5.20244H14.6348V6.33919H16.4122V8.13751H14.6348V8.71047H16.4122V10.5111H14.6348V11.1001H16.4122V12.8029H14.6348V13.4851H16.4122V15.1917H14.6348V15.8739H16.4122V17.5905H14.6348V18.8426H20.6647C20.76 18.8136 20.8395 18.699 20.9033 18.5004C20.967 18.3002 21 18.1375 21 18.0145V5.41024C21 5.31245 20.967 5.25363 20.9033 5.233C20.8395 5.21314 20.76 5.20244 20.6647 5.20244ZM19.8825 17.589H16.9897V15.8739H19.8825V17.5905V17.589ZM19.8825 15.1917H16.9897V13.4843H19.8825V15.1917ZM19.8825 12.8021H16.9897V11.107H19.8825V12.8021ZM19.8825 10.5103H16.9897V8.71199H19.8825V10.5103ZM19.8825 8.12758H16.9897V6.33995H19.8825V8.13904V8.12758ZM3 4.91902V19.1291L13.62 21V3L3 4.92513V4.91902ZM9.29475 15.6684C9.25425 15.5569 9.06375 15.0833 8.72625 14.2452C8.3895 13.4079 8.18625 12.9206 8.127 12.7815H8.10825L6.969 15.5432L5.4465 15.4385L7.2525 12.0008L5.59875 8.56303L7.15125 8.47976L8.17725 11.1696H8.1975L9.35625 8.35753L10.9605 8.25439L9.05025 11.9748L11.019 15.7701L9.29475 15.6669V15.6684Z" fill="#787878"/>
    </svg>
  </span>
);

ExcelIcon = pure(ExcelIcon);
ExcelIcon.displayName = 'ExcelIcon';
ExcelIcon.muiName = 'SvgIcon';

export default ExcelIcon;
