// @flow
import React, { PureComponent} from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PolPodPreview.scss';
import { dateRange } from '../../../core/format';
import PortLink from '../../Common/PortLink';
import Diff from '../../Common/Diff';
import type { Loading1 } from '../../../interfaces/types';
import {formatLaycanType} from "../../Common/LayCanTypeSelect";
import cx from 'classnames';
import {getPreviewErrorMsg} from "../utils";
import CustomTooltip from '../../Common/CustomTooltip';
import ActionInfo from 'material-ui/svg-icons/action/info';

type Props = {
  loading:[Loading1],
  unloading:[Loading1],
  previousLoading:[Loading1],
  previousUnloading:[Loading1],
  errors: {},
  isVesselSide: boolean,

}
type State = {};

class PolPodPreview extends PureComponent<Props,State> {

  static defaultProps = {
    errors: { },
    loading: [],
    unloading: [],
    previousLoading: [],
    previousUnloading: [],
    isVesselSide: false,
}

  transformPorts = (props = this.props) => {
    let { loading, unloading, previousLoading, previousUnloading, loadingDiff, unloadingDiff } = this.props;
    loading = [...loading];
    unloading = [...unloading];
    if (!loading[0]?.oneOf?.[0]) {
      loading.push({ oneOf: [{}] });
    }
    if (!unloading[0]?.oneOf?.[0]) {
      unloading.push({ oneOf: [{}] });
    }
    let ports = [];
    if (loadingDiff && unloadingDiff && loading[0].oneOf[0]._id ) { // old format without _id;
      for (let i = 0; i < loadingDiff.length; i++) {
        const loadingDiffElement = loadingDiff[i];
        const port = { ...loadingDiffElement.next || loadingDiffElement.prev };
        port.section = 'POL';
        port.pv = { ...loadingDiffElement.prev || {} };
        if (!loadingDiffElement.next) {
          port.isDeleted = true;
        } else if (!loadingDiffElement.prev) {
          port.isNew = loadingDiffElement.portChanged;
        }
        if (port.sign === 'OR') {
          port.isAlt = true;
        }
        port.ij = i;
        ports.push(port);
      }
      for (let i = 0; i < unloadingDiff.length; i++) {
        const unloadingDiffElement = unloadingDiff[i];
        const port = { ...unloadingDiffElement.next || unloadingDiffElement.prev };
        port.section = 'POD';
        port.pv = { ...unloadingDiffElement.prev || {} };
        if (!unloadingDiffElement.next) {
          port.isDeleted = true;
        } else if (!unloadingDiffElement.prev) {
          port.isNew = unloadingDiffElement.portChanged;
        }
        if (port.sign === 'OR') {
          port.isAlt = true;
        }
        port.ij = i;
        ports.push(port);
      }
      return ports;
    }

    loading.map(({oneOf}, i) => {
      oneOf.map((p, j) => {
        const isAlt = j > 0 && !oneOf[j - 1].disabled;
        if (p.disabled) {
          return null;
        }
        const pv = (previousLoading[i] && previousLoading[i].oneOf && previousLoading[i].oneOf[j]) || {};
        ports.push({isAlt, pv, ...p, section: 'POL', ij: i + j })
      })
    })
    unloading.map(({oneOf}, i) => {
      oneOf.map((p, j) => {
        const isAlt = j > 0 && !oneOf[j - 1].disabled;
        if (p.disabled) {
          return null;
        }
        const pv = (previousUnloading[i] && previousUnloading[i].oneOf && previousUnloading[i].oneOf[j]) || {};
        ports.push({isAlt, pv, ...p, section: 'POD', ij: i + j });
      })
    })
    return ports;
  }

  renderPort = (p, pv, i) => {

    const portErrMsg = getPreviewErrorMsg(this.props.errors[p.section]?.port, p.ij);
    if (portErrMsg) {
      return (<div className={cx(s.port, s.created)}>
        <span>
          {portErrMsg}
        </span>
      </div>);
    }
    return (<div className={cx(s.port, p.isDeleted && s.deleted, p.isNew && s.created)}><span>
              {p.isAlt && 'OR '}
      {pv.port && pv.port._id !== p.port._id ?
        [<PortLink className={s.old} port={pv.port} shipId={this.props.shipId} closeRightDialogs={this.props.closeRightDialogs} />,
          <br className={s.old}/>]
        : null}
      <PortLink className={s.new} port={p.port} shipId={this.props.shipId} closeRightDialogs={this.props.closeRightDialogs} />
            </span>
    </div>);
  }

  renderDates = (current, old, p) => {
    if (p.section === 'POD') {
      return <div></div>;
    }
    const readinessDateErrMsg = getPreviewErrorMsg(this.props.errors[p.section]?.readinessDate, p.ij);
    const cancellingDateErrMsg = getPreviewErrorMsg(this.props.errors[p.section]?.cancellingDate, p.ij);
    if (readinessDateErrMsg || cancellingDateErrMsg) {
      return (<div className={cx(s.date_range, s.created)}>{
        [readinessDateErrMsg, cancellingDateErrMsg].join(' - ')
      }</div>);
    }

    return (<div className={cx(s.date_range, p.isDeleted && s.deleted, p.isNew && s.created)}>{p.readinessDate && (
      <Diff newValue={current.dates} oldValue={old.dates} br />
    )}</div>);
  }

  render() {
    const ports = this.transformPorts(this.props);
    return (
      <div className={cx(s.wrapper, this.props.isVesselSide && s.vessel_side)}>
        {ports.map((p,i) => {
          const pv = p.pv;
          const old = {};
          const current = {};
          current.layCanType = formatLaycanType(p.layCanType) + ":";
          current.additionalLayCanDetails = p.additionalLayCanDetails || "";
          current.dates = dateRange(p.readinessDate, p.cancellingDate);
          if (pv.port) {
            old.additionalLayCanDetails = pv.additionalLayCanDetails || "";
            old.layCanType = formatLaycanType(pv.layCanType) + ":";
            old.dates = dateRange(pv.readinessDate, pv.cancellingDate);
            if (old.dates && (old.dates !== current.dates || old.layCanType !== current.layCanType || old.additionalLayCanDetails !== current.additionalLayCanDetails )) {
              old.dates += "\u2063";
              old.layCanType += "\u2063";
              old.additionalLayCanDetails += "\u2063";
            }
          }
          return (
            <div>
              <div style={{ display: "flex" }}>
                <div className={s.port_label}>
                  <div className={s.title} style={{ display: "flex", alignItems: "center" }}>
                    {(!ports[i - 1] || ports[i - 1].section !== p.section) ? p.section : null }
                    {(p.section === "POL" && p.ij === 0) &&
                      <div style={{ margin: "0 2px" }}>
                        <CustomTooltip
                          style={{ alignSelf: "center", cursor: "pointer" }}
                          tooltip={
                            <div>
                              <div>Readiness date to count from 00:00</div>
                              <div>Cancelling date to count until 23:59</div>
                            </div>
                          }
                          horizontalAlign="right"
                        >
                          <ActionInfo style={{ width: "16px", height: "16px" }} />
                        </CustomTooltip>
                      </div>
                    }
                  </div>
                </div>
                <div className={s.port_details}>
                  <div className={s.port_wrapper}>
                    {this.renderPort(p, pv, i)}
                    <div className={cx(p.section, s.details, p.isDeleted && s.deleted, p.isNew && s.created)}><Diff newValue={p.details} oldValue={pv.details} br /></div>
                  </div>
                  <div className={s.laycan_wrapper}>
                    <div className={cx(s.laycan_type, p.isDeleted && s.deleted, p.isNew && s.created)}>
                      {p.section === 'POL' ? (<Diff newValue={current.layCanType} oldValue={old.layCanType} br />) : null}
                    </div>
                    {this.renderDates(current, old, p)}
                    <div className={cx(p.section, s.laycan_details, p.isDeleted && s.deleted, p.isNew && s.created)}><Diff newValue={p.additionalLayCanDetails} oldValue={old.additionalLayCanDetails} br /></div>
                  </div>
                </div>
              </div>
              <div className={s.separator} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default withStyles(s)(PolPodPreview);

