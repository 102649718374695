import React from 'react';
import CompanyView from "./CompanyView";
import {Collapse} from "../Common/Collapse";
import {CompaniesAsMembersTableWrapper} from "./GroupEdit";
import {NoMembers} from "./CompanyMembers";

class GroupView extends CompanyView {
    renderMembersPart = () => {
      return (
        <Collapse
          defaultOpen
          title="COMPANIES"
        >
          {this.props.company?.companies ? (
            <CompaniesAsMembersTableWrapper
              members={this.props.company.companies}
              readonly
            />
          ) : <NoMembers text={'No companies yet'} />}

        </Collapse>
      );
    }
}

export default GroupView;
