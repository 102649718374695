/* eslint-disable import/prefer-default-export */

export const MONITOR_CARGO_LIST = 'MONITOR_CARGO_LIST';
export const MONITOR_CARGO_LIST_REQUEST = 'MONITOR_CARGO_LIST_REQUEST';
export const MONITOR_CARGO_LIST_FAILED = 'MONITOR_CARGO_LIST_FAILED';

export const MONITOR_VESSEL_LIST = 'MONITOR_VESSEL_LIST';
export const MONITOR_VESSEL_LIST_REQUEST = 'MONITOR_VESSEL_LIST_REQUEST';
export const MONITOR_VESSEL_LIST_FAILED = 'MONITOR_VESSEL_LIST_FAILED';

export const MONITOR_TOGGLE_CARGO_STYLE = 'MONITOR_TOGGLE_CARGO_STYLE';
export const MONITOR_TOGGLE_VESSEL_STYLE = 'MONITOR_TOGGLE_VESSEL_STYLE';

export const MONITOR_DRAGSTART_CARGO = 'MONITOR_DRAGSTART_CARGO';
export const MONITOR_DRAGEND_CARGO = 'MONITOR_DRAGEND_CARGO';
export const MONITOR_DRAGSTART_VESSEL = 'MONITOR_DRAGSTART_VESSEL';
export const MONITOR_DRAGEND_VESSEL = 'MONITOR_DRAGEND_VESSEL';

export const MONITOR_DROP_CARGO = 'MONITOR_DROP_CARGO';
export const MONITOR_DROP_VESSEL = 'MONITOR_DROP_VESSEL';

/**@see  {@link MONITOR_SEND_FREIGHT_REQUEST_REDUCER}*/
export const MONITOR_SEND_FREIGHT_REQUEST = 'MONITOR_SEND_FREIGHT_REQUEST';
/**@see  {@link MONITOR_SEND_FREIGHT_REQUEST_REQUEST_REDUCER}*/
export const MONITOR_SEND_FREIGHT_REQUEST_REQUEST =
  'MONITOR_SEND_FREIGHT_REQUEST_REQUEST';
/**@see  {@link MONITOR_SEND_FREIGHT_REQUEST_FAILED_REDUCER}*/
export const MONITOR_SEND_FREIGHT_REQUEST_FAILED =
  'MONITOR_SEND_FREIGHT_REQUEST_FAILED';

/**@see  {@link MONITOR_SEND_OFFER_REDUCER}*/
export const MONITOR_SEND_OFFER = 'MONITOR_SEND_OFFER';
/**@see  {@link MONITOR_SEND_OFFER_REQUEST_REDUCER}*/
export const MONITOR_SEND_OFFER_REQUEST = 'MONITOR_SEND_OFFER_REQUEST';
/**@see  {@link MONITOR_SEND_OFFER_FAILED_REDUCER}*/
export const MONITOR_SEND_OFFER_FAILED = 'MONITOR_SEND_OFFER_FAILED';

/**@see  {@link MONITOR_CARGO_NEW_REDUCER}*/
export const MONITOR_CARGO_NEW = 'MONITOR_CARGO_NEW';
/**@see  {@link MONITOR_VESSEL_NEW_REDUCER}*/
export const MONITOR_VESSEL_NEW = 'MONITOR_VESSEL_NEW';

/**@see  {@link MONITOR_CARGO_REMOVE_REDUCER}*/
export const MONITOR_CARGO_REMOVE = 'MONITOR_CARGO_REMOVE';

/**@see  {@link MONITOR_NEW_OFFER_REDUCER}*/
export const MONITOR_NEW_OFFER = 'MONITOR_NEW_OFFER';

/**@see  {@link MONITOR_CARGO_UPDATE_REDUCER}*/
export const MONITOR_CARGO_UPDATE = 'MONITOR_CARGO_UPDATE';

/**@see  {@link MONITOR_BID_NEW_REDUCER}*/
export const MONITOR_BID_NEW = 'MONITOR_BID_NEW';

/**@see  {@link MONITOR_FIND_RELEVANT_SHIPS_REDUCER}*/
export const MONITOR_FIND_RELEVANT_SHIPS = 'MONITOR_FIND_RELEVANT_SHIPS';
/**@see  {@link MONITOR_FIND_RELEVANT_SHIPS_REQUEST_REDUCER}*/
export const MONITOR_FIND_RELEVANT_SHIPS_REQUEST =
  'MONITOR_FIND_RELEVANT_SHIPS_REQUEST';
/**@see  {@link MONITOR_FIND_RELEVANT_SHIPS_FAILED_REDUCER}*/
export const MONITOR_FIND_RELEVANT_SHIPS_FAILED =
  'MONITOR_FIND_RELEVANT_SHIPS_FAILED';

/**@see  {@link MONITOR_FIND_RELEVANT_CARGOES_REDUCER}*/
export const MONITOR_FIND_RELEVANT_CARGOES = 'MONITOR_FIND_RELEVANT_CARGOES';
/**@see  {@link MONITOR_FIND_RELEVANT_CARGOES_REQUEST_REDUCER}*/
export const MONITOR_FIND_RELEVANT_CARGOES_REQUEST =
  'MONITOR_FIND_RELEVANT_CARGOES_REQUEST';
/**@see  {@link MONITOR_FIND_RELEVANT_CARGOES_FAILED_REDUCER}*/
export const MONITOR_FIND_RELEVANT_CARGOES_FAILED =
  'MONITOR_FIND_RELEVANT_CARGOES_FAILED';

/**@see  {@link MONITOR_SEND_TCOFFER_REDUCER}*/
export const MONITOR_SEND_TCOFFER = 'MONITOR_SEND_TCOFFER';
/**@see  {@link MONITOR_SEND_TCOFFER_REQUEST_REDUCER}*/
export const MONITOR_SEND_TCOFFER_REQUEST = 'MONITOR_SEND_TCOFFER_REQUEST';
/**@see  {@link MONITOR_SEND_TCOFFER_FAILED_REDUCER}*/
export const MONITOR_SEND_TCOFFER_FAILED = 'MONITOR_SEND_TCOFFER_FAILED';

/**@see  {@link MONITOR_UPDATE_TC_REDUCER}*/
export const MONITOR_UPDATE_TC = 'MONITOR_UPDATE_TC';

/**@see  {@link MONITOR_UPDATE_VESSEL_REDUCER}*/
export const MONITOR_UPDATE_VESSEL = 'MONITOR_UPDATE_VESSEL';

/**@see  {@link MONITOR_UPDATE_VESSEL_LOCATION_REDUCER}*/
export const MONITOR_UPDATE_VESSEL_LOCATION = 'MONITOR_UPDATE_VESSEL_LOCATION';

/**@see  {@link MONITOR_CARGO_PATCH_REDUCER}*/
export const MONITOR_CARGO_PATCH = 'MONITOR_CARGO_PATCH';

/**@see  {@link BID_UPDATE_REDUCER}*/
export const BID_UPDATE='BID_UPDATE';
export const MONITOR_SAVE_VESSEL_ID='MONITOR_SAVE_VESSEL_ID';
export const HANDLE_LOAD_TC_ORDERS='HANDLE_LOAD_TC_ORDERS';
export const HANDLE_CHANGE_FIELD='HANDLE_CHANGE_FIELD';
export const HANDLE_RESET_FORM='HANDLE_RESET_FORM';
export const SET_TC_FORM='SET_TC_FORM';
export const CREATE_TC_ORDER='CREATE_TC_ORDER';
export const GET_TC_ORDERS='GET_TC_ORDERS';
export const TC_ORDERS_FILTER='TC_ORDERS_FILTER';
export const UPDATE_TC_ORDERS_LIST='UPDATE_TC_ORDERS_LIST';
export const UPDATE_CARGO_LIST='UPDATE_CARGO_LIST';
export const UPDATE_VESSELS_LIST='UPDATE_VESSELS_LIST';
export const CHANGE_POSITION_STATUS='CHANGE_POSITION_STATUS';
export const CHANGE_VESSEL_STATUS='CHANGE_VESSEL_STATUS';
export const MAIN_DECK_UPDATE_ALL_VESSELS = 'MAIN_DECK_UPDATE_ALL_VESSELS';
export const SHOW_HIDE_VESSELS = 'SHOW_HIDE_VESSELS';
export const TOGGLE_HIDDEN_VESSELS = 'TOGGLE_HIDDEN_VESSELS';
export const ERASE_VESSEL_LIST = 'ERASE_VESSEL_LIST';

/**@see  {@link MONITOR_CHANGE_CARGO_STATUS_REDUCER}*/
export const MONITOR_CHANGE_CARGO_STATUS = 'MONITOR_CHANGE_CARGO_STATUS';
/**@see  {@link MONITOR_CHANGE_CARGO_STATUS_REQUEST_REDUCER}*/
export const MONITOR_CHANGE_CARGO_STATUS_REQUEST = 'MONITOR_CHANGE_CARGO_STATUS_REQUEST';
/**@see  {@link MONITOR_CHANGE_CARGO_STATUS_FAILED_REDUCER}*/
export const MONITOR_CHANGE_CARGO_STATUS_FAILED = 'MONITOR_CHANGE_CARGO_STATUS_FAILED';

/**@see  {@link MONITOR_SET_SEEN_CARGO_CHANGES_REDUCER}*/
export const MONITOR_SET_SEEN_CARGO_CHANGES = 'MONITOR_SET_SEEN_CARGO_CHANGES';
/**@see  {@link MONITOR_SET_SEEN_CARGO_CHANGES_REQUEST_REDUCER}*/
export const MONITOR_SET_SEEN_CARGO_CHANGES_REQUEST = 'MONITOR_SET_SEEN_CARGO_CHANGES_REQUEST';
/**@see  {@link MONITOR_SET_SEEN_CARGO_CHANGES_FAILED_REDUCER}*/
export const MONITOR_SET_SEEN_CARGO_CHANGES_FAILED = 'MONITOR_SET_SEEN_CARGO_CHANGES_FAILED';


