import React from 'react';
import Demurrage from './Demurrage';
import Detention from './Detention';
import Freight from './Freight';
import Multicharge from './Multicharge';

class FreightSection extends React.Component {
  render(props) {
    props = this.props;

    const { demurrage, demurrageOpts, detention, detentionOpts, onChange } = props;

    return (
      <div className="flex w-100" style={{ flexDirection: "column" }}>
        <div style={{ paddingTop: "8px" }}>
          <Demurrage
            demurrage={demurrage}
            demurrageOpts={demurrageOpts}
            onChange={onChange}
          />
          <Detention
            detention={detention}
            detentionOpts={detentionOpts}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

export default FreightSection;
