export const chargesTypesMap = {
  "AMF": "Transport Document Amendment Fee",
  "BAF": "Bunker Adjustment Factor",
  "BAS": "Basis ocean freight",
  "BLF": "Transport Document Issuance Fee",
  "CPS": "Canal Passage Surcharge",
  "CAF": "Currency Adjustment Factor",
  "CAM": "Cargo Declaration Amendment Fee",
  "CAP": "Capatazia",
  "CAS": "Controlled Atmosphere Gas Regulation Charge",
  "CCL": "Container Cleaning",
  "CDD": "Submission of Cargo Declaration Data Fee",
  "CER": "Government Agency Certification",
  "CFI": "Customs Fine",
  "CIP": "Cargo Insurance Premium",
  "CLF": "Freight Collection Fee",
  "CLL": "Cancellation Fee",
  "CNS": "Container Nomination Service Fee",
  "COD": "Change of Destination",
  "CON": "Congestion Surcharge",
  "CSC": "Container Service Charge",
  "CSH": " Container Shifting Charge",
  "CTO": "Container Terminal Order Fee",
  "CTS": "Cold Treatment Charge",
  "CUS": "Customs Clearance",
  "DRF": "Drayage fee",
  "DCF": "Dangerous Cargo Documentation Fee",
  "DCI": "Dangerous Cargo Surcharge= Inland",
  "DDF": "Documentation Fee= Destination",
  "DHC": "Handling Charge= Destination",
  "DIT": "Detention in Transit",
  "DMR": "Demurrage Equipment Charge",
  "DPA": "Transport Arbitrary= Destination",
  "DTS": "Detention/Demurrage Combination Equipment Charge (Merchant Haulage)",
  "EBS": "Emergency Bunker Surcharge",
  "EDI": "Electronic Data Interchange Fee",
  "EFS": "Export Intermodal Fuel Surcharge",
  "EMF": "Equipment Management Fee.",
  "ERI": "Ecological and Radiological Fee",
  "ERS": "Emergency Risk Surcharge",
  "EXA": "Examination Charge",
  "EXP": "Export Service Charge",
  "FFC": "Freight Forwarder Commission/Brokerage= Origin.",
  "FOC": "Freight Forwarder Commission/Brokerage= Destination.",
  "FRI": "Free In",
  "FRO": "Free Out",
  "FTS": "Freight Tax Surcharge",
  "FUM": "Fumigation",
  "GEN": "Genset",
  "HLS": "Heavy Lift Surcharge",
  "HBL": "House Transport Document Fee",
  "HDL": "Lift On Lift Off",
  "HEC": "Garments on Hangers",
  "IBC": "Transfer to Bonded Warehouse",
  "ICF": "Inland Cancellation Fee",
  "IEC": "Inspection Empty Containers",
  "IFS": "Import Intermodal Fuel Surcharge",
  "IHE": "Inland Haulage Export",
  "IHI": "Inland Haulage Import",
  "IHW": "Inland Haulage Container Weighing",
  "IMO": "Dangerous Cargo Surcharge",
  "IMP": "Import Service Charge",
  "JTC": "Probe Charge",
  "LSF": "Low Sulphur Fuel Charge",
  "LBC": "Carrier Provided Packaging",
  "LDS": "Late Documentation Fee",
  "LEG": "Legalisation of Documents",
  "LGC": "Late Gate Fee",
  "MDF": "Manual Documentation Processing Fee",
  "MHH": "Merchant Haulage",
  "MST": "Stop-Off Charge",
  "ODF": "Documentation Fee= Origin",
  "OHC": "Handling Charge= Origin",
  "OOG": "Out Of Gauge Additional",
  "OPA": "Transport Arbitrary= Origin",
  "PAE": "Port Additionals / Port Dues= Export",
  "PAI": "Port Additionals / Port Dues= Import",
  "PCC": "Panama Transit Fee",
  "PHI": "Government Agency Inspection",
  "PHY": "Phytosanitary Certificate",
  "PIO": "Pick-Up/Drop-Off",
  "POS": "Equipment Positioning Surcharge",
  "PPT": "Piracy Protection Charge",
  "PRM": "Primage Surcharge",
  "PSE": "Port Security Charge= Export",
  "PSI": "Port Security Charge= Import",
  "PSS": "Peak Season Surcharge",
  "PTI": "Pre-Trip Inspection",
  "REB": "Rebate",
  "RFM": "Reefer Monitoring / Plug-in Fee",
  "RPS": "River Toll Charge",
  "SEP": "Special Equipment Surcharge",
  "SER": "Carrier Security Charge",
  "SGF": "Space Guarantee",
  "SOC": "Shipper-Owned/Leased Equipment Charge",
  "SPC": "Container Stripping / Stuffing",
  "SPO": "Shanghai Port Surcharge",
  "STT": "Suez Transit Fee",
  "SWC": "Switch Transport Document",
  "T1D": "T1 Documentation",
  "TAX": "Government and Port Taxes",
  "TCE": "Temporary Customs Export",
  "TCI": "Temporary Customs Import",
  "TLX": "Electronic Cargo Release",
  "TRS": "Triaxle Chassis Usage",
  "ULF": "Logistics Fee",
  "VAT": "Value Added Tax",
  "VET": "Veterinary Certificate",
  "VGM": "Fee",
  "WRS": "War Risk Surcharge",
  "WDF": "Weight Discrepancy Fee",
  "WFC": "Wharfage",
  "WSC": "Winter Surcharge",
  "WTI": "Inland Haulage Waiting Time",
  "XTR": "Cross Booking Fee",
};

export const chargeTypesList = Object.keys(chargesTypesMap).map(_id => ({ _id, name: `${_id} - ${chargesTypesMap[_id]}` }));
chargeTypesList.unshift({ _id: undefined, name: ` ` });
