import React, { PureComponent } from 'react';

import { loadMap } from '../../../core/utils';
import GmapStyles from '../../Common/GmapStyles';
import Vessel from '../../../core/api/Vessel';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  generateTerminalInfoPopup,
  generateVesselInfoWindowContent,
  generateVesselInfoWindowError,
} from './VesselMapInfoWindow';
import s from '../../Public/MapPositionVessel.scss';
import pointInPolygonNested from 'point-in-polygon/nested';
import pip from '@mapbox/leaflet-pip/leaflet-pip.min.js';

let resources;

class AllFleetMap extends PureComponent{
  vesselsLayer = null;
  features = [];
  firstDraw = true;
  containers = {};
  state = null;
  pixiOverlay = null;
  sprites = [];

  static defaultProps= {
    for: 'fleet',
  }

  async componentDidMount() {
    if (this.props.innerRef) {
      this.props.innerRef(this);
    }
    await import("!!style-loader!css-loader!leaflet/dist/leaflet.css");
    await import("!!style-loader!css-loader!leaflet.markercluster/dist/MarkerCluster.css");
    await import(/* webpackChunkName: 'leaflet' */ 'leaflet');
    await import(/* webpackChunkName: 'leaflet' */ 'leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant.js');
    await import(/* webpackChunkName: 'leaflet' */ 'leaflet.heat/dist/leaflet-heat.js');
    await import(/* webpackChunkName: 'leaflet' */ 'leaflet.markercluster/dist/leaflet.markercluster.js');

    if(!window.PIXI) {
      window.PIXI = await import(/* webpackChunkName: 'leaflet' */ 'pixi.js');
    }
    await import(/* webpackChunkName: 'leaflet' */ 'leaflet-pixi-overlay');
    this.L = window.L;
    await loadMap();
    this.initMap();
    if (this.props.for === 'fleet') {
      await this.prepareResources();
      this.prepareContainers();
      if (this.props.vesselsForMap) {
        this.prepareFeatures(this.props.vesselsForMap);
      }
      this.drawPixi();
      this.initTerminalsLayer();
      this.drawTerminals(this.props.terminalsForMap, { fitBounds: true });
    }
    if (this.props.for === 'contacts') {
      this.initContactsLayers();
      this.drawContactsHeatmap();
    }
  }

  async prepareResources() {
    if(resources) {
      return;
    }
    const loader = new PIXI.Loader();
    ['BULK', 'BULKTANK', 'CAR', 'CONT', 'LIVESTOCK', 'MPP', 'PASS', 'PASSCAR', 'REEFER', 'TANK', 'OTHER'].forEach((t) => {
      loader.add(`vessel${t}moving`, `/images/vessel-icons/moving/${t}.png`);
      loader.add(`vessel${t}stopped`, `/images/vessel-icons/stopped/${t}.png`);
    })
    resources = await new Promise((resolve, reject) => {
      loader.load((loader, _resources) => {
        resolve(_resources);

      });
    })
  }

  prepareFeatures = (featureCollection = {features: []}) => {
    const features = [];
    for (let i = 0; i < featureCollection.features.length; i++) {
      const x = featureCollection.features[i];
      features.push({
        ...x,
        geometry: { ...x.geometry, coordinates: [x.geometry.coordinates[1], x.geometry.coordinates[0]] },
      })
      features.push({
        ...x,
        geometry: { ...x.geometry, coordinates: [x.geometry.coordinates[1] , x.geometry.coordinates[0] - 360 ] },
      })
      features.push({
        ...x,
        geometry: { ...x.geometry, coordinates: [x.geometry.coordinates[1] , x.geometry.coordinates[0] + 360 ] },
      })

    }
    this.features = features;
  }

  prepareContainers = () => {
    const stage = new PIXI.Container();
    stage.interactiveChildren = false;
    const containers = {};
    this.containers = containers;
    this.stage = stage;
    ['OTHER', 'BULKTANK', 'CAR', 'CONT', 'LIVESTOCK', 'PASS', 'PASSCAR', 'REEFER', 'TANK','BULK','MPP'].forEach((t) => {
      let container = new PIXI.ParticleContainer(150000, { rotation: true, vertices: true });
      containers[`vessel${t}stopped`] = container;
      container.interactiveChildren = false;
      stage.addChild(container);
      container = new PIXI.ParticleContainer(150000, { rotation: true, vertices: true });
      containers[`vessel${t}moving`] = container;
      container.interactiveChildren = false;
      stage.addChild(container);
    })
  }
  clear = () => {
    for (const [key, container] of Object.entries(this.containers)) {
      container.removeChildren();
    }
    this.sprites = [];
    this.firstDraw = true;
  }

  drawPixi () {
    let prevZoom;
    const pixiOverlay = L.pixiOverlay(utils => {
      const zoom = utils.getMap().getZoom();
      const renderer = utils.getRenderer();
      const project = utils.latLngToLayerPoint;
      const scale = utils.getScale();
      this.project = project;
      this.scale = scale;
      if (this.firstDraw) {
        this.features.forEach(f => {
          const textureKey = `vessel${f.properties.typeByPurpose || 'OTHER'}${f.properties.speed > 0.1 ? 'moving' : 'stopped'}`;
          const container = this.containers[textureKey];
          const texture = resources[textureKey].texture;
          const markerCoords = project(f.geometry.coordinates);
          const sprite = new PIXI.Sprite(texture);
          const angle = Math.round((f.properties.angle || 0) / 5) * 5;
          const rotate = angle * Math.PI / 180;
          sprite.x = markerCoords.x;
          sprite.y = markerCoords.y;
          sprite.rotation = rotate;
          sprite.anchor.set(0.5, 0.5);
          sprite.scale.set(1 / scale);
          sprite.feature = f;
          this.sprites.push(sprite);
          container.addChild(sprite);
        });
      }

      if (prevZoom !== zoom) {
        for (let i = 0; i < this.sprites.length; i++) {
          this.sprites[i].scale.set(1 / scale);
        }
      }

      this.firstDraw = false;
      prevZoom = zoom;
      renderer.render(this.stage);
    }, this.stage, { pane: 'vesselsPane' });
    pixiOverlay.addTo(this.map);
    this.pixiOverlay = pixiOverlay;
  }

  onMapClick = (e) => {
    if (!this.project) {
      return;
    }
    const p = this.project([e.latlng.lat, e.latlng.lng]);
    const delta = 8 / this.scale;
    const x1 = p.x - delta;
    const x2 = p.x + delta;
    const y1 = p.y - delta;
    const y2 = p.y + delta;
    let nearest = null;
    let minDistance = Number.MAX_VALUE;
    for (let i = 0; i < this.sprites.length; i++) {
      const sprite = this.sprites[i];
      if(sprite.x > x1 && sprite.y > y1 && sprite.x < x2 && sprite.y < y2) {
        const distance = (Math.abs(sprite.x - p.x) + Math.abs(sprite.y - p.y));
       if(distance < minDistance) {
         nearest = sprite;
         minDistance = distance;
       }
      }
    }
    if (nearest) {
      this.showVesselDetailsPopup(nearest.feature);
    }
    return nearest;
  }



  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.vesselsForMap !== nextProps.vesselsForMap) {
      this.clear();
      this.prepareFeatures(nextProps.vesselsForMap);
      this.pixiOverlay?.redraw();
    }
    if (this.props.terminalsForMap !== nextProps.terminalsForMap) {
      this.drawTerminals(nextProps.terminalsForMap);
    }
    if (this.props.contacts !== nextProps.contacts || this.props.selectedCountryId !== nextProps.selectedCountryId) {
      this.drawContactsHeatmap(nextProps);
    }
    console.log(nextProps)
  }

  componentWillUnmount() {
    this.clear();
    this.stage?.destroy();
    this.map.remove();
    this.stage = undefined;
    this.map = undefined;
    this.L = undefined;
  }


  initMap = () => {
    this.map = this.L.map('all-fleet-leaflet-map', {
      preferCanvas: true,
      minZoom: 1,
      maxBounds: this.L.latLngBounds(this.L.latLng(90, -540), this.L.latLng(-90, 540)),
      maxBoundsViscosity: 1,
      zoomControl: false,
    }).setView([51.505, -0.09], 3);
    if (window.google?.maps && !__DEV__) {
      const roadLayer = this.L.gridLayer
        .googleMutant({
          center: { lng: 0, lat: 0 },
          zoom: 2,
          fullscreenControl: false,
          mapTypeControl: true,
          streetViewControl: false,
          styles: GmapStyles,
          type: 'roadmap',
        }).addTo(this.map);
      const satLayer = this.L.gridLayer
        .googleMutant({
          center: { lng: 0, lat: 0 },
          zoom: 2,
          fullscreenControl: false,
          mapTypeControl: true,
          streetViewControl: false,
          styles: GmapStyles,
          type: 'hybrid',
        });
      this.L.control.layers({ "Map": roadLayer, "Satellite": satLayer },{},{ collapsed: false, position: 'topleft' }).addTo(this.map);
      this.map.on('baselayerchange', (e) => {
        const labels = this.map.getPane('mapPane').parentElement.querySelectorAll('.leaflet-control-layers-base label');
        labels.forEach(l => {
          if (l.innerText === e.name) {
            l.firstChild.firstChild.setAttribute('checked', 'checked');
          } else {
            l.firstChild.firstChild.removeAttribute('checked');
          }
        });
      });
    } else {
      this.L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png').addTo(this.map);
    }
    this.L.control.zoom({ position: 'bottomright' }).addTo(this.map);
    this.map.createPane('vesselsPane');
    this.map.getPane('vesselsPane').style.zIndex = 600;
    this.map.getPane('vesselsPane').style.pointerEvents = 'none';
    this.map.on('click', this.onMapClick);
    this.map.on('popupclose', () => this.map.setMaxBounds(this.L.latLngBounds(this.L.latLng(90, -540), this.L.latLng(-90, 540))));
  }


  showVesselDetailsPopup = (feature, opts = { pan: false, coordinatesReversed: false }) => {
    const vessel = feature.properties;
    let point;
    if (opts.coordinatesReversed) {
      point = this.L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
    } else {
      point = this.L.latLng(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
    }
    let html;
    Vessel.getMapPositionInfo(vessel.imoNumber)
      .then(res => html = generateVesselInfoWindowContent({
        imo: vessel.imoNumber,
        lastPos: {},
        vesselUrl: this.props.vesselUrl || '/vessel',
        ...res.data,
      }))
      .catch(error => html = generateVesselInfoWindowError(error))
      .finally(() => {
        this.map.setMaxBounds(null);
        this.L.popup()
        .setLatLng(point)
        .setContent(html)
        .openOn(this.map);
      });
    if (opts.pan) {
      this.map.setView(this.L.latLng(point), 14);
    }
  }

  showTerminalDetailsPopup = (feature, e) => {
    let html
    try {
      const vessels = this.props.vesselsForMap.features.filter(vf => pointInPolygonNested(vf.geometry.coordinates, feature.geometry.coordinates[0]));
      html = generateTerminalInfoPopup(feature.properties, vessels);
    } catch (e) {
      console.error(e);
      html = generateVesselInfoWindowError(e);
    }
    this.L.popup()
      .setLatLng(this.L.latLng(e.latlng))
      .setContent(html)
      .openOn(this.map);
  }

  drawTerminals = (terminalsCollection, opts = {}) => {
    if (!terminalsCollection || !this.terminalsLayer) {
      return;
    }
    this.terminalsLayer.clearLayers();
    if (this.terminalsTooltipLayer) {
      this.terminalsTooltipLayer.removeFrom(this.map);
    }
    this.terminalsTooltipLayer = this.L.layerGroup([]);
    this.terminalsTooltipLayer.addTo(this.map);
    this.terminalsLayer.addData(terminalsCollection);
    if (opts.fitBounds) {
      if (terminalsCollection.features.length) {
        const bounds = this.terminalsLayer.getBounds();
        this.map.fitBounds(bounds);
      } else {
        if (this.props.port && this.props.port.coordinates) {
          this.map.setView(this.L.latLng(this.props.port.coordinates[1], this.props.port.coordinates[0]), 12);
        }
      }
    }
  }

  initTerminalsLayer = () => {
    this.terminalsLayer = this.L.geoJSON({
      "type": "FeatureCollection",
      "features": [],
    }, { style: (feature) => {
        let fillColor = 'rgb(153, 153, 153)';
        let color = 'rgb(43, 53, 65)';
        let fillOpacity = 0.4;
        let opacity = 1;
        switch (feature.properties.type) {
          case 'T-':
          case 'T-Dry':
          case 'T-Wet':
            fillColor = 'rgba(153, 153, 153, 0)';
            color = 'rgba(43, 53, 65, 0)';
            break;
          case 'B-':
            fillColor = 'rgb(153, 153, 153)';
            color = 'rgb(210, 210, 210)';
            break;
          case 'B-Dry':
            fillColor = 'rgb(117, 196, 34)';
            color = 'rgb(210, 210, 210)';
            break;
          case 'B-Wet':
            fillColor = 'rgb(130, 195, 255)';
            color = 'rgb(210, 210, 210)';
            break;
        }
        if (feature.properties.selected === false && !feature.properties.parentSelected) {
          fillOpacity = 0.2;
          opacity = 0.2;
        }
        if (feature.properties.selected) {
          color = 'rgb(178, 178, 178)';
        }
        return { color, fillColor, fillOpacity, opacity, weight: 1 };
      },
      onEachFeature: (feature, layer) => {
        layer.on('click', this.onTerminalClick.bind(this, feature));
        if (feature.properties.selected) {
          const coordinates = [...feature.geometry.coordinates[0]];
          coordinates.sort((a, b) => ((a[0] + a[1]) - (b[0] + b[1]))).reverse();
          const point = coordinates[0];
          if (!point) {
            return;
          }
         /* layer._tooltip.setLatLng();
          layer.openTooltip(this.L.latLng(point[1], point[0]));*/
            setTimeout( () => {
              /*layer.openTooltip(this.L.latLng(point[1], point[0]));*/
              const marker = this.L.marker(this.L.latLng(point[1], point[0]), { icon: this.L.divIcon({ className: 'selected-terminal-hidden-marker' }) });
              marker.bindTooltip(feature.properties.name, {
                permanent: true,
                direction: 'right',
                className: 'terminal-tooltip-container',
                offset: this.L.point(9, -13),
              });
                this.terminalsTooltipLayer.addLayer(marker);
                const bounds = layer.getBounds();
                const maxZoom = this.map.getBoundsZoom(bounds) - 1;
                this.map.fitBounds(bounds, { maxZoom });

            }, 0);
          }
    },
    });
    this.terminalsLayer.addTo(this.map);

  }

  onTerminalClick = (feature, e) => {
    if (this.onMapClick(e)) {
      this.L.DomEvent.stopPropagation(e);
      this.L.DomEvent.preventDefault(e);
    } else {
      if (!feature.properties.berthID) {
        return;
      }
      this.showTerminalDetailsPopup(feature, e);
    }
  }


  initContactsLayers = () => {
    this.heatLayer = this.L.heatLayer([], { radius: 25, blur: 15, maxZoom: 5, max: 50, gradient: { 0.4: '#81C1FC', 0.85: '#4380C7', 1: 'white' } }).addTo(this.map);
    this.contactsCountriesLayer = this.L.geoJSON({
      "type": "FeatureCollection",
      "features": [],
    }, {
      style: (feature) => {
        let opacity = 0.2;
        if(feature.properties.selected) {
          opacity = 0.4;
        }
        return { color: 'rgb(210, 210, 210)', fillColor: 'rgb(130, 195, 255)', fillOpacity: opacity, opacity: opacity, weight: 1 };
        },
      onEachFeature: (feature, layer) => {
        layer.on('click', () => {
          this.props.onCountrySelected(feature.properties.id);
        });
      },

    });
    this.contactsClusterLayer = this.L.markerClusterGroup({
      chunkedLoading: true,
      iconCreateFunction: (cluster) =>  {
        return this.L.divIcon({
          html: '<b>' + cluster.getChildCount() + '</b>',
          className: 'marker-cluster-icon',
          iconSize: [34, 34],
        });
      },
      singleMarkerMode: true,
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
    });

    this.contactsClusterLayer.on('click', ({ latlng }) =>  {
      const [layer] = pip.pointInLayer(latlng, this.contactsCountriesLayer, true);
      if (layer) {
        this.props.onCountrySelected(layer.feature.properties.id);
      }
    });

    this.contactsClusterLayer.on('clusterclick', ({ latlng }) => {
      const [layer] = pip.pointInLayer(latlng, this.contactsCountriesLayer, true);
      if (layer) {
        this.props.onCountrySelected(layer.feature.properties.id);
      }
    });

    this.map.on('zoomend',  () =>  {
      if (this.map.getZoom() > 3 && this.map.hasLayer(this.heatLayer)) {
        this.map.removeLayer(this.heatLayer);
        this.map.addLayer(this.contactsClusterLayer);
        this.map.addLayer(this.contactsCountriesLayer);
      }
      if (this.map.getZoom() <= 3 && !this.map.hasLayer(this.heatLayer))
      {
        this.map.removeLayer(this.contactsClusterLayer);
        this.map.removeLayer(this.contactsCountriesLayer);
        this.map.addLayer(this.heatLayer);
        this.drawContactsHeatmap();
      }
    });

  }

  drawContactsHeatmap = (props = this.props) => {
    const contactsData = props.contacts;
    const heatMapData = [];
    const markers = [];
    if (!this.contactsCountriesLayer || !this.contactsClusterLayer) {
      return;
    }
    if (contactsData) {
      if (contactsData.points) {
        contactsData.points.forEach(p => {
          p.contacts.forEach(c => {
            const marker = this.L.marker(this.L.latLng(p.location.coordinates[1], p.location.coordinates[0]), { });
            markers.push(marker);
            heatMapData.push([p.location.coordinates[1], p.location.coordinates[0]]);
          });
        });
        this.contactsClusterLayer.clearLayers();
        this.contactsClusterLayer.addLayers(markers);
      }
      if (contactsData.countries) {
        this.contactsCountriesLayer.clearLayers();
        const featureCollection = {
          "type": "FeatureCollection",
          "features": [],
        };
        featureCollection.features = contactsData.countries.map(c => {
          return {
            "type": "Feature",
            "geometry": c.country.polygon,
            "properties": {
              "id": c.country.id,
              "name": c.country.name,
              "count": c.count,
              "selected": c.country.id === props.selectedCountryId,
            },
          };
        });
        this.contactsCountriesLayer.addData(featureCollection);
      }
    }
    if (this.map.hasLayer(this.heatLayer)) {
      this.heatLayer.setLatLngs([]);
      this.heatLayer.setOptions({ radius: 25, blur: 15, maxZoom: 5, max: Math.max(2, Math.ceil(heatMapData.length / 50)), gradient: { 0.4: '#81C1FC', 0.85: '#4380C7', 1: 'white' } });
      this.heatLayer.setLatLngs(heatMapData);
    }

  }


  render() {
    return <div style={{ width: '100%', height: '100%', background: '#13263c' }} id="all-fleet-leaflet-map" />
  }
}

export default withStyles(s)(AllFleetMap);
