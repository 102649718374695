import PropTypes from 'prop-types';
import React from 'react';
import { returnMockByType } from '../../../core/utils';

const factory = (WhatToExtend = React.Component) => {
  return class FormStateProvider extends WhatToExtend {
    onChange = (stepName = "") => (inputName) => (val) => {
      console.debug(stepName, inputName, val);
      if (!Object.prototype.hasOwnProperty.call(this.state, stepName)) {
        const msg = "[CARGO REQUEST]: onChange was called with non existent step name.";
        console.error(msg, stepName, val);
        return Promise.reject(msg);
      }

      const stateUpdater = state => ({
        ...state,
        [stepName]: typeof state[stepName] === "object" && typeof inputName !== 'undefined'
          ? Object.assign(returnMockByType(state[stepName]) || {}, state[stepName], { [inputName]: typeof val === 'function' ? val(state[stepName][inputName]) : val })
          : typeof val === 'function' ? val(state[stepName]) : val,
      });
      if (this.handleGenericChange) {
        return this.handleGenericChange(stateUpdater);
      }

      return new Promise((res, rej) => {
        this.setState(stateUpdater, () => {
          res(this.state);
          // PureComponent won't rerender if setState was called with NEW object whose nested object was mutated (e.g Object.assign(state[nested], {foo 'bar}), ...)
          // because it uses shallowCompare on props and state and compares only keys of new state, deeply nested state objects can produce bugs.
          // to prevent possible rerender blocking always use forceUpdate.
          if (WhatToExtend?.prototype?.isPureReactComponent) {
            this.forceUpdate();
          }
        });
      });
    };

    static childContextTypes = {
      selector: PropTypes.func,
      onChange: PropTypes.func,
    };

    getChildContext() {
      return {
        selector: property => property ? this.state[property] : this.state,
        onChange: this.onChange,
      };
    }
  };
};


export const FormStateProvider = factory();

export default factory;
