import * as React from 'react';
import cx from 'classnames';
import s from './Filter.scss';
import FormsyCheckbox from "../../Common/CheckBox";
import {makeNumberIfValid, styleForm} from "./common";
import FormsyText from "../../Common/FormsyText";
import FormsySelect from "../../Common/Select";
import MenuItem from "material-ui/MenuItem";

export default function SharedVesselFilterPart({ handleGenericChange, filter, handleSelectField, handleDependentChange }) {
  const isTankerSelected = filter.typeByPurposes?.length === 1 && filter.typeByPurposes[0] === 'TANK';
  return (<div>
    <div className="row align-items-center">
      <div className={cx("col-4", s.checkbox_wrapper)}>
        <FormsyCheckbox
          label="DWT:"
          name="minDWTCheckbox"
          className={s.checkbox}
          onChange={handleGenericChange('minDWTCheckbox')}
          value={filter.minDWTCheckbox}
          labelStyle={styleForm.labelStyle}
        />
      </div>
      <div className={cx("col-4 align-items-center flex max-h-42", s.pr0)}>
        <FormsyText
          hintText="Min"
          name="minDWT"
          fullWidth
          validations="isNumeric,gt0"
          validationError="invalid"
          className={s.input}
          onChange={handleGenericChange('minDWT', 'minDWT', true, 'maxDWT')}
          value={makeNumberIfValid(filter.minDWT)}
        />
      </div>
      <div className={cx("col-4 align-items-center flex max-h-42", s.pr0)}>
        <FormsyText
          hintText="Max"
          name="maxDWT"
          fullWidth
          validations="isNumeric,gt0,gteField:minDWT"
          validationError="invalid"
          className={s.input}
          onChange={handleDependentChange('maxDWT', 'minDWTCheckbox')}
          value={makeNumberIfValid(filter.maxDWT)}
        />
      </div>
    </div>
    <div className="row align-items-center">
      <div className={cx("col-4", s.checkbox_wrapper)}>
        <FormsyCheckbox
          label="Age, years:"
          name="minAgeCheckbox"
          onChange={handleGenericChange('minAgeCheckbox')}
          className={s.checkbox}
          value={filter.minAgeCheckbox}
          labelStyle={styleForm.labelStyle}
        />
      </div>
      <div className={cx("col-4 align-items-center flex max-h-42", s.pr0)}>
        <FormsyText
          hintText="Min"
          name="minAge"
          fullWidth
          validations="isNumeric,gt0"
          validationError="invalid"
          className={s.input}
          onChange={handleGenericChange('minAge', 'minAge', true, 'maxAge')}
          value={makeNumberIfValid(filter.minAge)}
        />
      </div>
      <div className={cx("col-4 align-items-center flex max-h-42", s.pr0)}>
        <FormsyText
          hintText="Max"
          name="maxAge"
          fullWidth
          validations="isNumeric,gt0,gteField:minAge"
          validationError="invalid"
          className={s.input}
          onChange={handleDependentChange('maxAge', 'minAgeCheckbox')}
          value={makeNumberIfValid(filter.maxAge)}
        />
      </div>
    </div>
    {!isTankerSelected ? (
      <div className="row align-items-center">
        <div className="col-4">
          <FormsyCheckbox
            label="Geared"
            name="gearCheckbox"
            className={cx(s.checkbox, s.gear)}
            onChange={handleGenericChange('gearCheckbox')}
            value={filter.gearCheckbox}
            labelStyle={styleForm.labelStyle}
          />
        </div>
        <div className={cx("col-4 align-items-center flex max-h-42", s.pr0)}>
          <FormsySelect
            name="gear"
            fullWidth
            className={s.select}
            onChange={handleSelectField('gear', 'gearCheckbox')}
            hintText=" "
            value={filter.gear}
            style={{minWidth: '133px'}}
          >
            <MenuItem key={1} value={undefined} primaryText={"Geared/Gearless"} />
            <MenuItem key={2} value={true} primaryText={"Geared"} />
            <MenuItem key={3} value={false} primaryText={"Gearless"} />
          </FormsySelect>
        </div>
        <div className={cx("col-4 align-items-center flex max-h-42", s.pr0)}>
          <FormsyText
            hintText="Capacity, min"
            name="minGearCapacity"
            fullWidth
            disabled={!filter.gear}
            validations="isNumeric,gt0"
            validationError="invalid"
            className={s.input}
            onChange={handleDependentChange('minGearCapacity', 'gearCheckbox')}
            value={makeNumberIfValid(filter.minGearCapacity)}
          />
        </div>
      </div>
    ) : null}

    <div className={cx(s.filter_new2col_wrapper)}>
      <div>
        <div className={s.field_wrapper}>
          <div className={cx(s.checkbox_wrapper)}>
            <FormsyCheckbox
              label="Beam, m:"
              name="maxBeamCheckbox"
              onChange={handleGenericChange('maxBeamCheckbox')}
              className={s.checkbox}
              value={filter.maxBeamCheckbox}
              labelStyle={styleForm.labelStyle}
            />
          </div>
          <div className="align-items-center flex max-h-42">
            <FormsyText
              hintText="Max"
              name="maxBeam"
              fullWidth
              validations="isNumeric,gt0"
              validationError="invalid"
              className={s.input}
              onChange={handleGenericChange('maxBeam')}
              value={makeNumberIfValid(filter.maxBeam)}
            />
          </div>
        </div>

        <div className={s.field_wrapper}>
          <div className={cx(s.checkbox_wrapper)}>
            <FormsyCheckbox
              label="Speed, kn:"
              name="minSpeedCheckbox"
              onChange={handleGenericChange('minSpeedCheckbox')}
              className={s.checkbox}
              value={filter.minSpeedCheckbox}
              labelStyle={styleForm.labelStyle}
            />
          </div>
          <div className="align-items-center flex max-h-42">
            <FormsyText
              hintText="Min"
              name="minSpeed"
              fullWidth
              validations="isNumeric,gt0"
              validationError="invalid"
              className={s.input}
              onChange={handleGenericChange('minSpeed')}
              value={makeNumberIfValid(filter.minSpeed)}
            />
          </div>
        </div>

        <div className={s.field_wrapper}>
          <div className={cx(s.checkbox_wrapper)}>
            <FormsyCheckbox
              label="Last update:"
              name="maxPositionAgeCheckbox"
              onChange={handleGenericChange('maxPositionAgeCheckbox')}
              className={s.checkbox}
              value={filter.maxPositionAgeCheckbox}
              labelStyle={styleForm.labelStyle}
            />
          </div>
          <div className="align-items-center flex max-h-42">
            <FormsyText
              hintText="Max, days"
              name="maxPositionAge"
              fullWidth
              validations="isNumeric,gt0"
              validationError="invalid"
              className={s.input}
              onChange={handleGenericChange('maxPositionAge')}
              value={makeNumberIfValid(filter.maxPositionAge)}
            />
          </div>
        </div>
        {!isTankerSelected ? [
          <div key={0} className={s.field_wrapper}>
            <div className={cx(s.checkbox_wrapper)}>
              <FormsyCheckbox
                label="Hold length:"
                name="minHoldLengthCheckbox"
                onChange={handleGenericChange('minHoldLengthCheckbox')}
                className={s.checkbox}
                value={filter.minHoldLengthCheckbox}
                labelStyle={styleForm.labelStyle}
              />
            </div>
            <div className="align-items-center flex max-h-42">
              <FormsyText
                hintText="Min, m"
                name="minHoldLength"
                fullWidth
                validations="isNumeric,gt0"
                validationError="invalid"
                className={s.input}
                onChange={handleGenericChange('minHoldLength')}
                value={makeNumberIfValid(filter.minHoldLength)}
              />
            </div>
          </div>,
          <div key={1} className={s.field_wrapper}>
            <div className={cx("align-items-center flex max-h-42", s.pr0, s.checkbox_wrapper)}>
              <FormsyCheckbox
                label="ITF Compliant"
                name="itfFitted"
                onChange={handleGenericChange('itfFitted')}
                className={s.checkbox}
                value={filter.itfFitted}
                labelStyle={styleForm.labelStyle}
              />
            </div>
            <div className={cx("align-items-center flex max-h-42", s.pr0)}>
              <FormsyCheckbox
                label="Open hatch"
                name="openHatch"
                className={cx(s.checkbox, s.gear)}
                onChange={handleGenericChange('openHatch')}
                value={filter.openHatch}
                labelStyle={styleForm.labelStyle}
              />
            </div>
          </div>,
        ] : null}
      </div>
      <div className={s.second_col}>
        <div className={s.field_wrapper}>
          <div className={cx(s.checkbox_wrapper)}>
            <FormsyCheckbox
              label="LOA, m:"
              name="maxLoaCheckbox"
              onChange={handleGenericChange('maxLoaCheckbox')}
              className={s.checkbox}
              value={filter.maxLoaCheckbox}
              labelStyle={styleForm.labelStyle}
            />
          </div>
          <div className="align-items-center flex max-h-42">
            <FormsyText
              hintText="Max"
              name="maxLoa"
              fullWidth
              validations="isNumeric,gt0"
              validationError="invalid"
              className={s.input}
              onChange={handleGenericChange('maxLoa', "maxLoa", true)}
              value={makeNumberIfValid(filter.maxLoa)}
            />
          </div>
        </div>

        <div className={s.field_wrapper}>
          <div className={cx(s.checkbox_wrapper)}>
            <FormsyCheckbox
              label="Draft, m:"
              name="maxDraftCheckbox"
              onChange={handleGenericChange('maxDraftCheckbox')}
              className={s.checkbox}
              value={filter.maxDraftCheckbox}
              labelStyle={styleForm.labelStyle}
            />
          </div>
          <div className="align-items-center flex max-h-42">
            <FormsyText
              hintText="Max"
              name="maxDraft"
              fullWidth
              validations="isNumeric,gt0"
              validationError="invalid"
              className={s.input}
              onChange={handleGenericChange('maxDraft')}
              value={makeNumberIfValid(filter.maxDraft)}
            />
          </div>
        </div>

        <div className={s.field_wrapper}>
          <div className={cx("align-items-center flex max-h-42", s.pr0, s.checkbox_wrapper)}>
            <FormsyCheckbox
              label="Ice class"
              name="iceClass"
              className={s.checkbox}
              onChange={handleGenericChange('iceClass')}
              value={filter.iceClass}
              labelStyle={styleForm.labelStyle}
            />
        </div>
      </div>
        {!isTankerSelected ? [
          <div key={0} className={s.field_wrapper}>
            <div className={cx("align-items-center flex max-h-42", s.pr0, s.checkbox_wrapper)}>
              <FormsyCheckbox
                label="IMDG Fitted"
                name="imoFitted"
                onChange={handleGenericChange('imoFitted')}
                className={s.checkbox}
                value={filter.imoFitted}
                labelStyle={styleForm.labelStyle}
              />
            </div>
          </div>,
          <div key={1} className={s.field_wrapper}>

            <div className={cx("align-items-center flex max-h-42", s.pr0, s.checkbox_wrapper)}>
              <FormsyCheckbox
                label="Box shaped"
                name="boxShaped"
                className={s.checkbox}
                onChange={handleGenericChange('boxShaped')}
                value={filter.boxShaped}
                labelStyle={styleForm.labelStyle}
              />
            </div>
          </div>,
        ] : null}
    </div>
  </div>
  </div>);
}
