import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let DataSecurity = props => (
  <span {...props}>
    <svg width="131" height="158" viewBox="0 0 131 158" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M130.642 24.6859C103.655 24.6859 82.9802 16.9832 65.4975 0C48.0167 16.9832 27.343 24.6859 0.358441 24.6859C0.358441 68.9137 -8.80216 132.27 65.4971 158C139.802 132.27 130.642 68.9142 130.642 24.6859ZM60.1012 102.515L38.3989 80.8305L48.1158 71.1236L60.1012 83.0999L82.8815 60.3415L92.598 70.0484L60.1012 102.515Z" fill="url(#paint0_linear)"/>
      <defs>
        <linearGradient id="paint0_linear" x1="152" y1="79" x2="-130" y2="-18" gradientUnits="userSpaceOnUse">
          <stop stopColor="#75C422"/>
          <stop offset="1" stopColor="#75C422" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  </span>
);

DataSecurity = pure(DataSecurity);
DataSecurity.displayName = 'Marketplace';
DataSecurity.muiName = 'SvgIcon';

export default DataSecurity;
