export default function SNP_REMOVE_BY_ID(state, {payload}) {
  const snpList = [...state.snpList.data];

  const filteredList = [
    ...state.snpList.filtered
  ];

  const newData = snpList.filter(order => order._id !== payload);
  const newFiltered = filteredList.filter(order => order._id !== payload);

  return { ...state, snpList: { ...state.snpList, filtered: [...newFiltered], data: [...newData] } };
}
