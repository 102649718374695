import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Footer.scss';
import Link from '../Link';
import cx from "classnames";
import Facebook from '../Icons/Facebook';
import Twitter from '../Icons/Twitter';
import Reddit from '../Icons/Reddit';
import CircleLinkedIn from '../Icons/CircleLinkedIn';
import Telegram from '../Icons/Telegram';
import Medium from '../Icons/Medium';
import Media from "react-media";
import FooterMobile from "./FooterMobile";

function Footer(props) {
  return (
    <div className={s.root}>
      <div className={cx(s.footer, s.container_home)}>
        <div className={cx(s.wrapper_container_main_page, props.publicInfo ? s.public_info : '')}>
          <Media query="(max-width: 767px)" defaultMatches={false}>
            {matches =>
              matches ? (
                <FooterMobile />
              ) : (
                <div className={s.subscribe_social}>
                  <div className={s.info}>
                    <div className={s.part_info}>
                      <h2>SOLUTIONS </h2>
                      <ul>
                        <li><Link to={'/solution-shipnext-marketplace'}>SHIPNEXT Trading Desk</Link></li>
                        <li><Link to={'/solution-shipnext-cargo-monitor'}>SHIPNEXT Cargo Monitor</Link></li>
                        <li><Link to={'/solution-shipnext-fleet-monitor'}>SHIPNEXT Fleet Monitor</Link></li>
                        <li><Link to={'/solution-shipnext-exchange'}>SHIPNEXT Exchange</Link></li>
                        <li><Link to={'/solution-shipnext-documents'}>SHIPNEXT Documents</Link></li>
                        <li><Link to={'/solution-shipnext-ports'}>SHIPNEXT Ports</Link></li>
                      </ul>
                    </div>
                    <div className={s.part_info}>
                      <h2>TECHNOLOGY </h2>
                      <ul>
                        <li><Link to={'/technology#shipnext-ecosystem'}>SHIPNEXT Ecosystem</Link></li>
                        <li><Link to={'/technology#shipnext-supply-chain-ecosystem'}>Supply Chain Ecosystem</Link></li>
                        <li><Link to={'/technology#blockchain-technology'}>Blockchain</Link></li>
                        <li><Link to={'/technology#data-security'}>Data Security</Link></li>
                      </ul>
                    </div>
                    <div className={s.part_info}>
                      <h2>Free PRODUCTS</h2>
                      <ul>
                        <li><Link to={'/port'}>Ports</Link></li>
                        <li><Link to={'/fleetmonitor/allfleet/'}>Fleet Monitor</Link></li>
                        <li><Link to={'/calculator/-/-/-'}>Freight Calculator</Link></li>
                        {/* <li><a href="/fleetmonitor/allfleet/">Fleet Monitor</a></li>
                        <li><a href="/calculator/-/-/-">Freight Calculator</a></li> */}
                      </ul>
                    </div>
                    <div className={s.part_info}>
                      <h2>Company</h2>
                      <ul>
                        <li><Link to={'/about-us#our-story'}>Our Story</Link></li>
                        <li><Link to={'/partners'}>Partners</Link></li>
                        <li><Link to={'/#press'}>Press</Link></li>
                        <li><Link to="/news">News</Link></li>
                        <li><Link to="/help">Help & Feedback</Link></li>
                      </ul>
                    </div>
                    <div className={s.part_info}>
                      <h2>HEADQUARTERS</h2>
                      <div className={s.wrapper_address}>
                        SHIPNEXT BV<br />
                        Koningin Elisabethlei 16<br />
                        Antwerp, 2018, Belgium<br />
                      </div>
                    </div>
                    <div className={s.part_info}>
                      <h2>CONTACT</h2>
                      <div className={s.wrapper_contact}>
                        T <a href="tel:+ 32 (487) 651114">+32 487 65 11 14</a><br />
                        E <a href="mailto:support@shipnext.com">support@shipnext.com</a><br />
                        <Link to="/register">Free Registration</Link>
                      </div>
                    </div>
                  </div>

                </div>
              )
            }
          </Media>
          <div className={s.wrapper_footer}>
            <div>
              <p className={cx(s.copyright, s.copyright_desktop)}>Copyright © {(new Date()).getFullYear()} SHIPNEXT. All rights reserved.</p>
              <div className={s.wrapper_links}>
                <Link to="/legal">
                  Terms & Conditions
                </Link>
                <Link to="/rules-regulations">
                  Rules & Regulations
                </Link>
                <Link to="/copyright">
                  Copyright Policy
                </Link>
                <Link to="/privacy">
                  Privacy Policy
                </Link>
                <Link to="/cookies">
                  Cookies Policy
                </Link>
              </div>
            </div>
            <div className={s.social_footer}>
              <div className={s.wrapper_social}>
                <a target="_blank" href="https://www.facebook.com/Shipnext-1680590622270295/">
                  <Facebook />
                </a>
                <a target="_blank" href="https://twitter.com/shipnextcom">
                  <Twitter />
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/ship-next">
                  <CircleLinkedIn />
                </a>
                <a target="_blank" href="https://t.me/shipnextcommunity">
                  <Telegram />
                </a>
                <a target="_blank" href="https://medium.com/@shipnext">
                  <Medium />
                </a>
              </div>
            </div>
            <p className={cx(s.copyright, s.copyright_mob)}>Copyright © {(new Date()).getFullYear()} SHIPNEXT. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(s)(Footer);
