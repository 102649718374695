import React, {Component} from 'react';
import styled from "styled-components";
import NarrowFormsyDate from "../NewInputs/NarrowFormsyDate";
import ExtendedForm from "./ExtendedForm";
import {dateISOLocal, dateLocalTwoDigit} from "../../core/format";
import formDataToObject from 'form-data-to-object';
import {safeDate} from "../../core/utils";

const StyleWrapper = styled.div`
  width: 140px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid var(--stroke-light-gray2) !important;
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 1px;

  > form {
    display: flex;

    > span:first-child {
      &:after {
        display: block;
        content: "";
        position: absolute;
        width: 1px;
        height: 14px;
        background-color: var(--stroke-light-gray2);
        top: 3px;
        right: 0px;
      }
    }
  }

  .input_default {
    width: 66px;
    height: 22px !important;
    overflow: hidden;
    margin: 0;

    > div:first-child {
      width: 66px !important;
      height: 22px !important;
      display: flex !important;

      input {
        margin-top: 0 !important;
        text-align: left;
        padding-left: 4px !important;
        color: var(--text-dark) !important;
        font-size: 11px !important;
        line-height: 22px !important;
        padding-right: 2px !important;
      }

    }
  }
  .clear_icon {
    width: 14px!important;
    height: 14px!important;
    margin: 0;
    top:3px;
  }

`


class RoundedDateRangeFilter extends Component {
  static defaultProps = {
    name: 'receivedAt',
    filters: {},
    handleFilter: () => undefined,
  }
  onChange = (values) => {
    values = formDataToObject.toObj(values);

    const value = values[this.props.name];
    const prevValue = this.props.filters[this.props.name] || [];
    if (value[0]?.getTime && value[1]?.getTime && value[0].getTime() > value[1].getTime()){
      value[1] = null;
    }
    if (value[0]) {
      value[0] = dateISOLocal(value[0]);
    } else {
      value[0] = null;
    }
    if (value[1]) {
      value[1] = dateISOLocal(value[1]);
    } else {
      value[1] = null;
    }
    // noinspection EqualityComparisonWithCoercionJS
    // eslint-disable-next-line eqeqeq
    if (value[0] != prevValue[0] || value[1] != prevValue[1]) {
      this.props.handleFilter({ ...this.props.filters, ...values });
    }
  }

  render() {
    const value = this.props.filters[this.props.name] || [];
    const from = safeDate(value[0]);
    const to = safeDate(value[1]);
    return (
      <StyleWrapper>
        <ExtendedForm onChange={this.onChange}>
          <NarrowFormsyDate
            placeholder="From"
            value={from}
            name={`${this.props.name}[0]`}
            clearable
            container="inline"
            autoOk
            formatDate={dateLocalTwoDigit}
            maxDate={new Date()}
          />
          <NarrowFormsyDate
            placeholder="To"
            value={to}
            name={`${this.props.name}[1]`}
            clearable
            container="inline"
            autoOk
            formatDate={dateLocalTwoDigit}
            minDate={from}
          />
        </ExtendedForm>
      </StyleWrapper>
    );
  }
}

export default RoundedDateRangeFilter;
