import adaptCargoFieldsFromServer from './adaptCargoFieldsFromServer';
import createPipeline from './pipelineFactory';

export const simpleFormStep = offlineContract => {
  const { simpleForm = "", cargoRequest = {} } = offlineContract;

  const copy = { ...cargoRequest };

  copy.simpleForm = simpleForm;

  offlineContract.cargoRequest = copy;

  return offlineContract;
};

export const preambleStep = offlineContract => {
  const {
    cargoRequest = {},
    counteragents,
    clientDate,
    clientPlace,
    clientContractRefNo,
    vesselDetails,
  } = offlineContract;

  const mapVessel = offlineContract => {
    const {
      vessel,
      vesselRequest,
      vesselName
    } = offlineContract;

    let result = {};

    if (vessel) {
      result = typeof vessel === 'object' ? { ...vessel } : vessel;
    }
    else if (vesselRequest) {
      result = vesselRequest._id ? { ...vesselRequest } : vesselName;
    }
    return result;
  }

  return ({
    ...offlineContract,
    cargoRequest: {
      ...cargoRequest,
      preamble: {
        counteragents,
        clientDate,
        clientPlace,
        clientContractRefNo,
        vessel: mapVessel(offlineContract),
        vesselDetails,
      }
    }
  })
}

export const oldFieldsMapper = (offlineContract, contextCargoTypes) => {
  return ({
    ...offlineContract,
    cargoRequest: adaptCargoFieldsFromServer(offlineContract.cargoRequest, contextCargoTypes),
  });
};

export const ldRateStep = offlineContract => {
  return ({
    ...offlineContract,
    cargoRequest: {
      ...offlineContract.cargoRequest,
      ld: offlineContract.cargoRequest.ldRates,
    },
  });
}

const editPipeline = [simpleFormStep, preambleStep, oldFieldsMapper, ldRateStep];

export default createPipeline(editPipeline);
