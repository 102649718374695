import React from 'react';
import s from './Styles/Regions.scss';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { getPortsFromRegion, hasValidPolygon, loadMap, sleep, toLatLng } from '../../core/utils';
import history from '../../core/history';

import ui from './mapUI/ui';

import buildDeleteBtn from './mapUI/DeleteButton';
import buildDeletePolygon from './mapUI/DeletePolygon';

function isIdEquals(one = {}, two = {}) {
  return one._id === two._id;
}

class Map extends React.Component {
  static contextTypes = {

  };

  static propTypes = {

  };

  constructor(props) {
    super(props);

    this.state = {};

    this.ports = [];

    this.drawingManager = undefined;
    this.polygons = [];

    this.DMforDeletePolygon = undefined;
    this.polygonForDelete = undefined;

    this.markerClusterer = undefined;
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.selectedRegion) {
      this.clearMap();
    }

    if (this.props.selectedRegion !== nextProps.selectedRegion) {
      this.ports = getPortsFromRegion(nextProps.selectedRegion, nextProps.allPorts);
      this.addMarkerClusterToMap(this.ports);
      if (nextProps.selectedRegion) {
        if (!nextProps.selectedRegion.skipSetBounds) {
          this.setBoundsCoordinates(this.ports);
        }
        delete nextProps.selectedRegion.skipSetBounds;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const isSameArea = prevProps.selectedRegion && this.props.selectedRegion
      && this.props.selectedRegion._id === prevProps.selectedRegion._id
      && this.props.status === prevProps.status;
    if (isSameArea) {
      return;
    }

    if (this.drawingManager) {
      this.clearDrawingManager();
    }

    if (this.props.status === 'edit-regions') {
      this.setupDrawingManager();
    }
    this.removePolygon();

    if (!this.props.selectedRegion) {
      this.clearMap();
    }

    this.drawAreas();
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }

    const MarkerClusterer = require('./markerClusterer');

    loadMap().then(() => {
      this.map = new google.maps.Map(this.refs.map, {
        zoom: this.props.zoom || 2,
        center: {
          lat: 49.171839,
          lng: 10.433250,
        },
        disablePanMomentum: true,
        streetViewControl: false,
        gestureHandling: 'greedy',
        zoomControlOptions: {
          position: this.props.portMap ?  google.maps.ControlPosition.RIGHT_BOTTOM : google.maps.ControlPosition.RIGHT_CENTER,
        },
        styles: this.props.styles || [
          {
              "featureType": "all",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "color": "#6c747d"
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#13263c"
                  },
                  {
                      "weight": 2
                  },
                  {
                      "gamma": "1"
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry",
              "stylers": [
                  {
                      "weight": 0.6
                  },
                  {
                      "color": "#223347"
                  },
                  {
                      "gamma": "0"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#324558"
                  },
                  {
                      "weight": "10"
                  },
                  {
                      "lightness": "-10"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#2f3f51"
                  }
              ]
          },
          {
              "featureType": "poi.park",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#283b51"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#182b40"
                  },
                  {
                      "lightness": "0"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#304358"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#26374c"
                  },
                  {
                      "lightness": "-10"
                  }
              ]
          }
        ],
      });
      this.map.addListener('zoom_changed', this.handleZoom);
      this.markerClusterer = new MarkerClusterer.default(this.map, [], {
        legend: {
          noSelected: '#ffffff',
          selected: '#4380C7',
        },
        styles: [{
          url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
          textColor: '#000000',
          textSize: 10,
          height: 35,
          width: 35,
        }],
        gridSize: 60,
      });
      this.SELECTED_ICON = {
        labelOrigin: new google.maps.Point(11, 50),
        url: '/images/blue_port.svg',
        size: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(11, 40),
      };
      this.NOT_SELECTED_ICON = {
        labelOrigin: new google.maps.Point(11, 50),
        url: '/images/white_port.svg',
        size: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(11, 40),
      };
      this.allPorts = JSON.parse(JSON.stringify(this.props.allPorts));
      setTimeout(() => {
        this.createPortsMarkers().then(() => {
          const markers = this.allPorts.map(p => p.marker);
          this.markerClusterer.addMarkers(markers);
         if (this.ports) {
           this.changeMarkersSelection(this.ports);
         }
        });
      }, 0);
    });
  }

  setupDrawingManager() {
    if (this.drawingManager) {
        return;
    }
    this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon']
        },
        polygonOptions: {
            editable: true,
            fillColor: '#fff',
            strokeColor: '#fff',
        }
    });

    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
        this.handlePolygon(polygon);
    });

    this.drawingManager.setMap(this.map);
  }

  removePolygon(only) {
    let toRemove = this.polygons;
    if (Array.isArray(only)) {
        toRemove = this.polygons.filter(p => only.includes(p));
    }
    for (const polygon of toRemove) {
        polygon.setMap(null);
        google.maps.event.clearInstanceListeners(polygon);
    }
    this.polygons = this.polygons.filter(p => !toRemove.includes(p));
  }

  handleZoom = () => {
    const zoom = this.map.getZoom();
    this.markerClusterer.setGridSize(50 - (zoom * 3));
  };
  handlePortDblClick = (portId, e) => {
    if (this.props.status === 'edit-regions') {
      let childs = [...this.props.selectedRegion.childs];
      if (this.props.selectedRegion.childs.includes(portId)) {
        childs = this.props.selectedRegion.childs.filter(item => item !== portId);
      } else {
        childs.push(portId);
      }
      this.props.changeParentState('selectedEditRegion', {
        ...this.props.selectedRegion,
        childs,
        skipSetBounds: true,
      });
    }
  }

  createPortsMarkers = async () => {
    let markers = [];
    for (let i = 0; i < this.allPorts.length; i++) {
      const port = this.allPorts[i];
      if (!(i % 500)) {
        await sleep(100);
      }
      if (port.coordinates) {
        const marker = new google.maps.Marker({
          position: {
            lat: port.coordinates[1],
            lng: port.coordinates[0],
          },
          icon: this.props.portMap ? {
            labelOrigin: new google.maps.Point(16, 48),
            url: '/images/port-marker.svg',
           }
          : this.NOT_SELECTED_ICON,
          title: port.name,
          label: {
            fontSize: this.props.portMap ? '12px' : '',
            color: this.props.portMap ? '#F5F5F5': '#fff',
            fontWeight: this.props.portMap ? 'normal' : 'bold',
            text: port.name,
            ...(this.props.portMap ? {} : {fontFamily: "Roboto"}),
          },
        });
        marker.isInArea = false;
        port.marker = marker;
        markers.push(marker)
        google.maps.event.addListener(marker, 'dblclick', this.handlePortDblClick.bind(this, port._id));
        google.maps.event.addListener(marker, 'click', function() {history.push(`/port/${port.sefName || port._id}`)});
      }
    }
  }

  clearMap() {
    this.removePolygon();
  }

  setBoundsCoordinates(ports) {
    const coords = toLatLng(ports.map(port => port.coordinates));

    if (coords.length === 0) {
      return;
    }

    if (coords.length === 1) {
      this.map.setCenter(coords[0]);
      this.map.setZoom(10);
    } else {
      this.fitBounds(coords);
    }
  }

  addMarkerClusterToMap(ports) {
    return this.changeMarkersSelection(ports);
  }

  changeMarkersSelection(selectedPorts) {
    const selectedMap = selectedPorts.reduce((accum, port) => {
      accum[port._id] = port;
      return accum;
    }, {});
    for (let i = 0; i < this.allPorts.length; i++) {
      const port = this.allPorts[i];
      const marker = port.marker;
      const isInArea = !!selectedMap[port._id];
      if (marker && isInArea !== marker.isInArea) {
        if (isInArea) {
          marker.setIcon(this.SELECTED_ICON);
        } else {
          marker.setIcon(this.NOT_SELECTED_ICON);
        }
        marker.isInArea = isInArea;
      }
    }
    this.markerClusterer.resetViewport();
    this.markerClusterer.redraw();
  }

  removePort(port) {
    if (this.props.status === 'edit-regions') {
      this.props.changeParentState('selectedEditRegion', {
        ...this.props.selectedRegion,
        childs: this.props.selectedRegion.childs.filter(item => item !== port._id),
      });
    }
  }

  clearDrawingManager() {
    this.drawingManager.setMap(null);
    google.maps.event.clearInstanceListeners(this.drawingManager);
    this.drawingManager = undefined;
  }

  drawAreas() {
    if (!this.props.selectedRegion || !this.props.selectedRegion.areaCoordinatesPolygons) {
      return;
    }
    const multiPolygons = this.props.selectedRegion.areaCoordinatesPolygons.coordinates;
    for (const polCoords of multiPolygons) {
      if (hasValidPolygon(polCoords[0])) {
        const coords = toLatLng(polCoords[0]);

        const polygon = new google.maps.Polygon({
          paths: coords,
          strokeColor: '#fff',
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: '#fff',
          fillOpacity: 0.2,
          editable: this.props.status === 'edit-regions',
          draggable: false
        });

        polygon.setMap(this.map);
        this.handlePolygon(polygon);
      }
    }
  }

  fitBounds(coords) {
    const bounds = new google.maps.LatLngBounds();

    for (const point of coords) {
      bounds.extend(point);
    }

    this.map.fitBounds(bounds);
  }

  handlePolygon(polygon) {
    this.polygons.push(polygon);

    const path = polygon.getPath();
    this.updateRegionCoordinates();

    let deleteMenu = buildDeleteBtn();
    let deletePolygon = buildDeletePolygon();

    const clean = () => {
      this.removePolygon([polygon]);
      this.updateRegionCoordinates();
    }

    google.maps.event.addListener(path, 'remove_at', (...args) => {
      if (path.getLength() < 3) {
        clean();
      }
    });

    google.maps.event.addListener(polygon, 'rightclick', (e) => {
      // Check if click was on a vertex control point
      if (e.vertex != undefined) {
        return deleteMenu.open(this.map, polygon.getPath(), e.vertex);
      }

      deletePolygon.open(
        this.map,
        polygon,
        e,
        clean,
      );
    });

    google.maps.event.addListener(path, 'set_at', () => {
      this.updateRegionCoordinates();
    });

    google.maps.event.addListener(path, 'insert_at', () => {
      this.updateRegionCoordinates();
    });
  }

  updateRegionCoordinates() {
    let polygons = [];
    let childs = [];

    for (const polygon of this.polygons) {
      const coords = polygon.getPath().getArray().map(point => [point.lng(), point.lat()]);
      polygons.push([coords]);

      childs.push(...(this.getPolyPorts(polygon) || []));
    }

    this.props.changeParentState('selectedEditRegion', {
      ...this.props.selectedRegion,
      childs: Array.from(new Set(childs)),
      areaCoordinatesPolygons: { type: "MultiPolygon", coordinates: polygons },
      skipSetBounds: true,
    });
  }
  getPolyPorts(polygon) {
    const ports = this.props.allPorts.filter(p => google.maps.geometry.poly.containsLocation(new google.maps.LatLng(p.coordinates[1], p.coordinates[0]), polygon)).map(p => p._id);
    return ports;
  }

  render() {
    return (<div ref="map" />)
  }
}

export default connect(state => ({ }), {

})(withStyles(s)(Map));
