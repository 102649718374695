import React from 'react'
import { AlertTooltip } from '../CompanyChangesTooltip'
import WithTooltip from '../WithTooltip'
import InfoIcon from 'material-ui/svg-icons/action/info';
import s from './Tags.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

function TagsTooltip(props) {
  return (
    <WithTooltip horizontalPosition="left" whiteArrow={false} tip={<AlertTooltip classes={s.tags_alert} dark={true} text="Open your list to edit and create new tags" />}><InfoIcon style={{width: 20, height: 20}}/></WithTooltip>
  )
}

export default withStyles(s)(TagsTooltip);
