import Api from "./api";

export default class Mercurion extends Api {
    static uploadDocument(accountId,formData,opts={}){
        return Mercurion.fetch(`/departments/${accountId}/compliance/documents`, {
          method: 'post',
          body: formData,
          headers: {},
        },'/api/v2');
      }

    static createKYC(data,accountId){
      return Mercurion.fetch(`/departments/${accountId}/compliance/send`,
      {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(data)
      },'/api/v2')
    }

    static signKYC(data,accountId){
      return Mercurion.fetch(`/departments/${accountId}/compliance/sign`,
      {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(data)
      },'/api/v2')
    }

    static saveComplianceForm(data,accountId){
        return Mercurion.fetch(`/departments/${accountId}/compliance`,
        {
          headers: { "content-type": "application/json" },
          method: "PUT",
          body: JSON.stringify(data)
        },'/api/v2')
    }

    static deleteComplianceForm(accountId){
      return Mercurion.fetch(`/departments/${accountId}/compliance`,
        {
          method: "DELETE",
        },'/api/v2')
    }

    static getDepartmentInfo(accountId){
        return Mercurion.fetch(`/departments/${accountId}`,{method:'GET'},'/api/v2')
    }

    static getDepartments(){
      return Mercurion.fetch('/departments',{method:'GET'},'/api/v2')
    }

    static getComplianceDocument(accountId,documentLink){
      return Mercurion.fetch(`/departments/${accountId}/compliance/documents/${documentLink}`,{method:'GET'},'/api/v2')
    }

    static requestVerificationCode(userId,accountId){
      return Mercurion.fetch(`/departments/${accountId}/compliance/requestVerificationCode`,{
        method:'POST',
        body: JSON.stringify({userId,departmentId:accountId})
      },'/api/v2')
    } 

    static calculateInvoiceFee(data){
      return Mercurion.fetch(`/invoices/calculateFee`,{
        method:'POST',
        body: JSON.stringify(data)
      },'/api/v2')
    }

    static getInvoicePrefillData(type,contractId){
      return Mercurion.fetch(`/invoices/prefill?${type}=${contractId}`,{
        method:'GET',
      },'/api/v2')
    }

    static getLinkedInvoices(id,type){
      return Mercurion.fetch(`/invoices/linked?id=${id}&type=${type}`,{
        method:'GET',
      },'/api/v2')
    }

    static createInvoice(data){
      return Mercurion.fetch(`/invoices`,{
        method:'POST',
        body: JSON.stringify(data)
      },'/api/v2')
    }

    static updateInvoice(invoiceId,data){
      return Mercurion.fetch(`/invoices/${invoiceId}`,{
        method:'PUT',
        body: JSON.stringify(data)
      },'/api/v2')
    }

    static deleteInvoice(invoiceId){
      return Mercurion.fetch(`/invoices/${invoiceId}`,{
        method:'DELETE',
      },'/api/v2')
    }

    static getInvoiceData(invoiceId){
      return Mercurion.fetch(`/invoices/${invoiceId}`,{
        method: 'GET',
      },'/api/v2')
    }

    static getInvoicePreview(invoiceId,accessToken){
      return Mercurion.fetch(`/invoices/${invoiceId}/preview?accessToken=${accessToken}`,{
        method:'GET',
      },'/api/v2')
    }

    static postAction(invoiceId,key,args){
      return Mercurion.fetch(`/invoices/${invoiceId}/action`,{
        method:'POST',
        body: JSON.stringify({key,...args})
      },'/api/v2')
    }

    static getCompaniesAutocomplete(search){
      return Mercurion.fetch(`/companies/lookup?kind=invoice&search=${search}`,{
        method:'GET',
      },'/api/v2')
    }

    static getContractsAutocomplete(type,search){
      return Mercurion.fetch(`/invoices/linkable-documents?type=${type}&query=${search}`,{
        method:'GET',
      },'/api/v2')
    }

    static getInvoicesList(data){
      return Mercurion.fetch(`/invoices/list`, {
        method: 'POST',
        body: JSON.stringify(data),
      }, '/api/v2');
    }

    static async getInvoicesFilters() {
      return this.fetch('/preferences/byType/invoiceFilters', {
        method: 'GET',
      });
    }
  
    static async saveInvoicesFilter(name, body) {
      return this.fetch(`/preferences/invoiceFilters/${name}`, {
        method: 'POST',
        body: JSON.stringify(body),
      });
    }
  
    static async updateInvoicesFilter(filterId, formData) {
      return this.fetch(`/preferences/${filterId}`, {
        method: 'PUT',
        body: JSON.stringify(formData),
      });
    }
  
    static async deleteInvoicesFilter(filterId) {
      return this.fetch(`/preferences/${filterId}`, {
        method: 'DELETE',
      });
    }
}
