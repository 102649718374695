import PropTypes from 'prop-types';
import React, { Component } from 'react';
import tc from '../../../Monitor/TCOrders/TC.scss';
import cx from 'classnames';
import s from './Request.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import NarrowFormsyText from './../../../NewInputs/NarrowFormsyText';
import NarrowSelect from './../../../NewInputs/NarrowSelect';
import NarrowCargoAutocomplete from './../../../NewInputs/NarrowCargoAutocomplete';
import { CheckBox as Checkbox } from './../../../Monitor/Edit/VesselEdit';
import WarningIcon from "material-ui/svg-icons/alert/warning";

import ContentAdd from 'material-ui/svg-icons/content/add';
import Chip from 'material-ui/Chip';
import MenuItem from 'material-ui/MenuItem';

const extractName = ([key, value]) => {
    let res = `${key}:`
    if (value && typeof value === 'object') {
      res += (value.name ? value.name : value);
    }
    else {
      res += value;
    }
    return res;
}

const createIdObj = row => Object.entries(row).map(extractName).join('');

export class Cargo extends Component {

  static defaultProps = {
    cargo: {}
  }

  componentDidMount() {
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
  }

  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getState = () => {
    return this.state;
  }

  getDefaultState = () => {
    const state = {
      included: this.props.cargo.included || [],
      excluded: this.props.cargo.excluded || [],
    };
    return state;
  }

  handleDelete = (field = 'included', row) => {
    const newRows = this.state[field].filter(item => item.id !== row.id);
    this.handleChange(field, newRows);
  }

  addItem = (field = 'included', row) => {
    const rows = this.state[field];
    const newRow = {
      ...row,
      id: createIdObj(row)
    };
    const arrToSearch = field === 'included' ? 'excluded' : 'included';
    const alreadyExistsIndex = this.state[arrToSearch].findIndex(item => item.id === newRow.id)
    if (alreadyExistsIndex >= 0) {
      this.props.dispatch({
        type: 'MESSAGE',
        payload: {
          title: "Error",
          message: `Error adding duplicate cargo to ${field}; this already exists in ${arrToSearch}.`,
          level: "error",
          _id: new Date().getMilliseconds(),
          autoDismiss: 5
        }
      });
      return;
    }
    const newRows = [
      ...rows,
      newRow
    ]
    this.handleChange(field, newRows)
  }

  handleChange = (key, val) => {
    this.setState(state => ({
        ...state,
        [key]: val,
    }));
  }

  render() {
    const { included, excluded } = this.state;
    const { handleDelete, addItem } = this;

    return (
      <div className={cx(s.step, tc.cargo)}>
        <CargoIncluded rows={included} addRow={addItem.bind(this, 'included')}>
            <CargoChips color="#D6EDBD" rows={included} handleDelete={handleDelete.bind(this, 'included')} />
        </CargoIncluded>
        <CargoExcluded rows={excluded} addRow={addItem.bind(this, 'excluded')}>
            <CargoChips rows={excluded} handleDelete={handleDelete.bind(this, 'excluded')} />
        </CargoExcluded>
      </div>
    )
  }
}

class CargoIncluded extends Component {
  static defaultProps = {
    children: <CargoChips />,
    rows: [],
  }

  static bulks = [
    {
      val: 'dry-bulk',
      name: 'Dry-bulk',
    },
    {
      val: 'wet-bulk',
      name: 'Wet-bulk',
    },
    {
      val: 'break-bulk',
      name: 'Break-bulk',
    },
  ]

  static groupName = 'included';

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      dangerous: false,
      bulk: 'dry-bulk',
      cargoName: '',
    };
  }

  getState = () => {
    return this.state;
  }

  handleChange = (key, ev, val) => {
    this.setState({
      [key]: val,
    });
  }

  resetState = () => {
    this.setState({
      ...(this.getDefaultState()),
    });
  }

  addRow = row => {
    const { dangerous = false, bulk = false, cargoName = '' } = this.state;
    this.props.addRow({
      dangerous,
      bulk,
      cargoName,
    });
    this.resetState();
  }

  render() {
    const { dangerous, bulk, cargoName } = this.state;
    const { rows } = this.props;
    const { bulks, groupName } = this.constructor;

    const id = createIdObj({
      dangerous,
      bulk,
      cargoName,
    });
    return (
      <div className={cx(tc.col)}>
        <div className={cx("row", s.step_row)}>
            <div className="col-sm-3">
              <div style={{ visibility: 'hidden', width: 0, height: 0, overflow: 'hidden' }}>
                <NarrowFormsyText value={id} name={`cargo[${groupName}][${rows.length}][id]`} />
              </div>
              <NarrowCargoAutocomplete
                floatingLabelText={groupName === 'excluded' ? `Excluded Cargo` : `Intended Cargo`}
                name={`cargo[${groupName}][${rows.length}][cargoName]`}
                fullWidth
                value={cargoName}
                updateInput={this.handleChange.bind(this, 'cargoName', null)}
                onNewRequest={this.handleChange.bind(this, 'cargoName', null)}
              />
            </div>
            <div className="col-sm-3">
              <NarrowSelect
                floatingLabelFocusStyle={{}}
                floatingLabelStyle={{visibility: 'hidden'}}
                floatingLabelText="1"
                floatingLabelShrinkStyle={{}}
                name={`cargo[${groupName}][${rows.length}][bulk]`}
                fullWidth
                autoWidth
                value={bulk}
                onChange={this.handleChange.bind(this, 'bulk')}
              >
                {
                  bulks.map(item => <MenuItem value={item.val} key={item.val} primaryText={item.name}/>)
                }
              </NarrowSelect>
            </div>
            <div className="col-sm-2 flex align-items-center">
                  <Checkbox
                    style={{maxWidth: '91px'}}
                    className={tc.checkbox}
                    checked={dangerous}
                    label="Dangerous"
                    onChange={this.handleChange.bind(this, 'dangerous')}
                    name={`cargo[${groupName}][${rows.length}][dangerous]`}
                  />
              </div>
              <div className={cx(tc.add_btn, "col-sm-1")} onClick={this.addRow}>
                <ContentAdd
                    color="#285596"
                />
              </div>
        </div>
        <div className={tc.row}>
              {this.props.children}
        </div>
      </div>
    )
  }
}

class CargoExcluded extends CargoIncluded {
  static groupName = "excluded"
}

function CargoChips({ rows, color = '#E6E6E6', handleDelete }) {

  function getInfo(row) {
    return `${row.bulk ? `(${row.bulk})` : ''}`;
  }

  return (
    <div className={tc.row_start_wrap}>
      {
        rows.map(row =>
            <Chip
              key={row.id ? row.id : (row?.cargoName?._id ?? row.cargoName)}
              onRequestDelete={handleDelete.bind(this, row)}
              backgroundColor={color}
              style={{ margin: '8px 8px 0px 0px' }}
              className={tc.chip}
            >
              {
                row.dangerous
                ? (
                    <span title="dangerous" style={{marginRight: 4}}>
                      <WarningIcon
                        style={{
                          display: "inline",
                          minWidth: '12px',
                          marginTop: '-2px',
                          width: 18,
                          height: 18,
                          color: "#E34848",
                        }}
                      />
                    </span>
                )
                : null
              }
              {
                row.cargoName
                ? `${row.cargoName?.name ? row.cargoName.name : row.cargoName} ${getInfo(row)}`
                : `Any ${getInfo(row)} cargo`
              }
            </Chip>
          )
      }
    </div>
  )
}

CargoIncluded.contextTypes = {
  cargoTypes: PropTypes.array,
  formsy: PropTypes.object,
};

CargoExcluded.contextTypes = {
  cargoTypes: PropTypes.array,
  formsy: PropTypes.object,
};
export default connect(null, dispatch => ({ dispatch }))(withStyles(tc)(Cargo));
