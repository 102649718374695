import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import Link from '../../Link/Link';

class SolutionsDocuments extends Component {
  render() {
    return (
      <div className={s.solution}>
        <div className={s.tab_block}>
          <div className={s.left_part}>
            <div className={s.wrapper_screen}>
              <img src="/images/solutions_screen/documents1.png" alt="SHIPNEXT Documents" />
            </div>
          </div>
          <div className={s.right_part}>
            <h2>
              <span className={s.title}>SHIPNEXT Documents</span>
            </h2>
            <div>
              <p>SHIPNEXT provides solutions for contract management. Using SHIPNEXT Documents, you can create an instant Charter Party, if negotiated through the platform, or “copy-paste” a recap if it was negotiated via email. Users may choose from the existing Contract Forms or upload their own.</p>
              <p>SHIPNEXT also facilitates Electronic Bill of Lading transactions. Electronic documentation flow can be integrated in other processes or exchanged electronically between the different departments or companies. All data stored in Documents on SHIPNEXT can help track execution and automate CRM.</p>
            </div>
            <div className={s.wrapper_buttons_tab}>
              <Link className={s.learn_more} to={'/solution-shipnext-documents'}>Learn more</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(s)(SolutionsDocuments);
