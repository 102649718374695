import produce from "immer";
import {sortContacts} from "./CONTACTS_CREATE_GROUP";

/** @name CONTACTS_UPDATE_GROUP_REDUCER */
 export default function CONTACTS_UPDATE_GROUP(state, { res }) {
  const newContact = res.data;
  newContact.type = 'group';
  newContact.expanded = true;
  return produce(state, draft => {
    const index = draft.companyList.data.findIndex(c => c.id === newContact.id);
    if (index !== -1) {
      draft.companyList.data[index] = newContact;
    } else {
      draft.companyList.data.push(newContact);
    }
    if (newContact.companies) {
      newContact.companies.forEach((c)=> {
        draft.companyList.data = draft.companyList.data.filter(cc => cc.id !== c.id); //remove companies from root
      });
    }
    if (newContact.companiesToElevate?.length) {
      draft.companyList.data = [...draft.companyList.data, ...newContact.companiesToElevate];
    }
    draft.companyList = sortContacts(draft.companyList);
  });
}
