import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Pages.scss';

class Interconnectivity extends Component {
  render() {
    return (
      <div className={s.interconnectivity}>
        <div className={s.container}>
          <div className={s.interconnectivity_body}>
            <div className={s.wrapper_description}>
              <div className={s.title}>
                <h2>Interconnectivity and big-data<br />analysis</h2>
              </div>
              <div className={s.part_description}>
                <p>Instead of reading your emails in one solution, and trying to search for keywords in another, you can now stay tuned to all data flows in one screen. SHIPNEXT uses big-data analysis, machine learning and AI to process the logic built in every incoming message. This helps you connect your email flow to a large number of real-time databases to make sure your decisions are fast and efficient.
                </p>
              </div>
            </div>
            <div className={s.wrapper_image}>
                <img src="/images/pages/diagram-tech-big-data.png" alt="Interconnectivity and big-data analysis" />
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(Interconnectivity));
