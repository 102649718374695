import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Popover from 'material-ui/Popover';
import cx from 'classnames';
import s from './ArrowPopover.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/Menu';
export class DefaultPopover extends Component {

  render() {
    const { open, anchor, classes, children, handleClose, ...rest } = this.props;

    return (
      <Popover
          anchorEl={anchor}
          open={open}
          anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
          targetOrigin={{horizontal: 'left', vertical: 'top'}}
          onRequestClose={handleClose}
          className={cx(s.default_popover_style, classes)}
          {...rest}
        >
          {
            children
          }
        </Popover>
    )
  }
}

export default withStyles(s)(DefaultPopover);
