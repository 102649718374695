import React, { PureComponent } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import Search from '../../Common/Search';
import RequestRourceFilter from '../../Monitor/RequestRourceFilter';
import RaisedButton from '../../Common/RaisedButton';
import AllFleetMap from '../../Vessel/AllFleet/AllFleetMap';
import GeneralCargoIcon from '../../Icons/GeneralCargoIcon';
import LiquidCargoIcon from '../../Icons/LiquidCargoIcon';
import OtherCargoIcon from '../../Icons/OtherCargoIcon';
import { escapeRegExp } from '../../../core/utils';
import Highlight from '../../Monitor/Highlight';
import orderBy from 'lodash/orderBy';
import { v4 } from 'uuid';

const StylesWrapper = styled.div`
  padding: 0 20px;
  color: var(--text-dark333);
  .port-terminals-map {
    height: 320px;
    width: 100%;
  }
  .port-terminals-header {
    display: flex;
    align-items: center;
    padding-top: 24px;
    h4 {
      margin: 0;
      padding: 0;
      color: var(--text-dark333);
      text-transform: uppercase;
      margin-right: 8px;
    }
    > div:nth-child(2) {
      width: 208px !important;
      margin: 0 !important;
    }
    >div + div {
      margin-left: 8px;
    }
    > div:nth-child(3) {
       div.raised-button {
         min-width: 64px!important; ;
         > button > div > div > span > span {
         min-width: 46px!important;
       }
       }
    }
  }
  .port-terminals-table-wrapper {
    height: 264px;
  }
  .port-terminals-table {
    width: 100%;
    font-size: 10px;
    position: relative;
    &-header {
      font-weight: 500;
      color: var(--text-light);
      overflow-y: scroll;
    }
    &-body-wrapper {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 100%;
      border: 1px solid var(--stroke-light-gray2);
      border-radius: 4px;
      pointer-events: none;
      }
      
    }
    &-body {
      margin-right: -4px;
      position: relative;
      max-height: 220px;
      overflow-y: scroll;
      .port-terminals-table-row {
        border-bottom: 1px solid var(--stroke-light-gray2);
        >div {
          border-right: 1px solid var(--stroke-light-gray2);
          &:last-child {
          border-right: none;
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &-row {
      height: 44px;
      display: flex;
      >div{
        padding: 8px;
        display: flex;
        align-items: center;
      }
      >div:nth-child(1) {
        font-weight: 500;
        min-width: 161px ;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
      >div:nth-child(2) {
        width: 102px;
        svg {
          margin-bottom: 2px;
          margin-right: 4px;
        }
      }
      >div:nth-child(3) {
        width: 136px;
      }
      >div:nth-child(4) {
        width: 82px;
      }
      >div:nth-child(5) {
        width: 82px;
      }
      &.berth {
        >div:nth-child(1) {
          padding-left: 24px;
        }
      }
      &.terminal {
        background-color: var(--bg-light-grey);
      }
      &.berth, &.terminal {
        >div:nth-child(1), >div:nth-child(2)  {
          cursor: pointer;
        }
      }
    }
  }
  .port-terminals-no-results {
    display: none;
    align-items: center;
    justify-content: center;
    height: 100px;
    >div {
      padding-left: 20px;
      font-weight: 700;
      font-size: 20px;
      color: #C4C4C4;
    }
  }
  &.no-results {
    .port-terminals-table {
      display: none;
    }
    .port-terminals-no-results {
      display: flex;
    }
    .port-terminals-header {
      > * {
        display: none !important;
      }
      > h4 {
        display: block !important;
      }
    }
    .port-terminals-table-wrapper {
      height: 100px;
    }
  }
`

function featureFromTerminal(t, selectedTerminalID) {
  const _id = t._id || t.berthID || t.terminalID;
  const selected = selectedTerminalID ? (_id === selectedTerminalID) : undefined;
  const parentSelected = t.terminalID === selectedTerminalID;
  return {
    "type": "Feature",
    "geometry": t.polygon,
    "properties": {
      "_id": _id,
      "name": t.name,
      "type": t.type,
      "selected": selected,
      "parentSelected": parentSelected,
      "berthID": t.berthID,
      "terminalID": t.terminalID,
    },
  };
}


const iconsMap = {
  'T-': <OtherCargoIcon />,
  'B-': <OtherCargoIcon />,
  'B-Dry': <GeneralCargoIcon />,
  'B-Wet': <LiquidCargoIcon />,
  'T-Dry': <GeneralCargoIcon />,
  'T-Wet': <LiquidCargoIcon />,
}
const typesLabelMap = {
  'T-': "Other",
  'B-': "Other",
  'B-Dry': "General",
  'B-Wet': "Liquid",
  'T-Dry': "General",
  'T-Wet': "Liquid",
}

function TerminalRow(props: { t: any, searchRegex: RegExp, onClick: () => void }) {
  const type = props.t.berthID ? 'berth' : 'terminal';
  return <div className={cx('port-terminals-table-row', type)}>
    <div onClick={props.onClick}>
      <div><Highlight search={props.searchRegex}>{props.t.name}</Highlight></div>
    </div>
    <div onClick={props.onClick}>
      {iconsMap[props.t.type]}<span>{typesLabelMap[props.t.type]}</span>
    </div>
    <div></div>
    <div></div>
    <div></div>
  </div>;
}

function featureFromVessel(v) {
  return {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": v.lastPos.coords,
    },
    "properties": {
      "_id": v.shipnextId || v._id,
      "imoNumber": v.imo,
      "name": v.name,
      "typeByPurpose": v.details?.type,
      "angle": v.lastPos.angle,
      "speed": v.lastPos.speed,
    },
  };
}

function terminalFromFeature(f){
  const t = { ...f.properties, berths: [] };
  t.polygon = f.geometry;
  return t;
}

function terminalsFromFeatures({ features = [] } = { features: [] }) {
  const terminals = [];
  const terminalsMap = {};
  features = orderBy(features, [(feature) => !feature.properties.berthID && !feature.properties.terminalID, (feature) => !feature.properties.berthID, (feature) => feature.properties.name], ['desc', 'desc', 'asc']);
  for (let i = 0; i < features.length; i++) {
    const feature = features[i];
    if (!feature.properties.berthID && !feature.properties.terminalID) {
      feature.properties.terminalID = v4();
    }
    if (!feature.geometry.coordinates) {
      feature.geometry.coordinates = [[]];
    }
    if (!feature.properties.berthID) {
        const terminal = terminalFromFeature(feature);
        terminals.push(terminal);
        terminalsMap[terminal.terminalID] = terminal;
        if (!terminal.type) {
          terminal.type = 'T-';
        }
    } else {
       const berth = terminalFromFeature(feature);
       if (!berth.type) {
         berth.type = 'B-';
       }
      try {
        terminalsMap[berth.terminalID].berths.push(berth);
      } catch (e) {
         console.error(e);
      }
    }
  }
  return terminals;
}

class PortTerminals extends PureComponent {
  state = { terminals: [],
    vesselsForMap: {
      "type": "FeatureCollection",
      "features": [],
    },
    filters: { type: { 'general': true, 'liquid': true, 'other': true }, search: "", searchRegex: null },
  };

  componentDidMount() {
      this.convertVesselsForMap([...this.props.port.vesselsNearPort, ...this.props.port.vesselsDirectingToPort]);
      //this.convertTerminalsForMap(portData.terminals);
      this.convertTerminalsForMap(terminalsFromFeatures(this.props.port.featureCollection));
  }

  convertVesselsForMap(vesselsNearPort){
    const featureCollection = { "type": "FeatureCollection", features: [] };
    featureCollection.features = vesselsNearPort.map(featureFromVessel);
    this.setState({ vesselsForMap: featureCollection });
  }
  convertTerminalsForMap(terminals, selectedTerminalID){
    const featureCollection = { "type": "FeatureCollection", features: [] };
    terminals.forEach(t => {
      if (t.polygon?.coordinates?.[0]?.length) {
        featureCollection.features.push(featureFromTerminal(t, selectedTerminalID));
      }
      t.berths.forEach(b => {
        if (b.polygon?.coordinates?.[0]?.length) {
          featureCollection.features.push(featureFromTerminal(b, selectedTerminalID));
        }
      });
    });
    this.setState({ terminals, terminalsForMap: featureCollection, selectedTerminalID });
  }

  renderRows = (terminals) => {
    const rows = [];
    terminals.forEach((terminal) => {
      let terminalPushed = false;
      const terminalFitted = this.isTerminalFitted(terminal);
      if (terminalFitted) {
        rows.push(terminal);
        terminalPushed = true;
      }
      terminal.berths.forEach((berth) => {
        if (terminalFitted || this.isTerminalFitted(berth)) {
          if (!terminalPushed) {
            rows.push(terminal);
            terminalPushed = true;
          }
          rows.push(berth);
        }
      });
    });
    return rows.map(t => <TerminalRow t={t} onClick={this.handleTerminalSelected.bind(this, t._id || t.berthID || t.terminalID)} searchRegex={this.state.filters.searchRegex} />);
  }

  isTerminalFitted(terminal) {
    const filters = this.state.filters;
    let fitted = false;
    if (
      (filters.type.other && terminal.type === 'T-')
      || (filters.type.general && (terminal.type === 'T-Dry' || terminal.type === 'B-Dry'))
      || (filters.type.liquid && (terminal.type === 'T-Wet' || terminal.type === 'B-Wet'))
    ){
      fitted = true;
    }
    if (fitted && filters.searchRegex) {
      fitted = filters.searchRegex.test(terminal.name);
    }
    return fitted;
  }

  handleFilter = (filters) => {
    this.setState({ filters });
  }
  handleSearch= (query) => {
    let searchRegex = null;
    if (query) {
      searchRegex = escapeRegExp(query);
    }
    this.setState({ filters: { ...this.state.filters, search: query, searchRegex } });
  }

  handleTerminalSelected = (terminalID) => {
    this.convertTerminalsForMap(this.state.terminals, terminalID);
  }

  triggerVesselMarker = (vessel) => {
    this.map.showVesselDetailsPopup(featureFromVessel(vessel), { pan: true, coordinatesReversed: true });
  }

  refMap = (el) => {
    this.map = el;
    this.props.refMap && this.props.refMap(el);
  }

  render() {
    return (
      <StylesWrapper className={cx(this.state.terminals.length === 0 && 'no-results')}>
        <div className={cx('port-terminals-map')}>
          <AllFleetMap innerRef={this.refMap} vesselsForMap={this.state.vesselsForMap} terminalsForMap={this.state.terminalsForMap} port={this.props.port} />
        </div>
        <div className={cx('port-terminals-header')}>
          <h4>Berths and terminals in port</h4>
          <Search isNarrow query={this.state.filters.search} handleSearch={this.handleSearch} />
          <RequestRourceFilter
            titles={["General", "Liquid", "Other"]}
            keys={["general", "liquid", "other"]}
            noDots
            icons={[<GeneralCargoIcon />, <LiquidCargoIcon />, <OtherCargoIcon />]}
            filters={this.state.filters}
            filtersKey={'type'}
            renderIcons
            handleFilter={this.handleFilter}
          />
          <RaisedButton disabled={!this.state.selectedTerminalID} primary styles={'xs'} label={'SHOW ALL'} onClick={this.handleTerminalSelected.bind(this, null)} />
        </div>
        <div className={cx('port-terminals-table-wrapper')}>
        <div className={cx('port-terminals-table')}>
          <div className={cx('port-terminals-table-header')}>
            <div className={cx('port-terminals-table-row')}>
              <div>NAME</div>
              <div>CARGO</div>
              <div>AVG WAITING TIME</div>
              <div>LOA</div>
              <div>DEPTH</div>
            </div>
          </div>
          <div className={cx('port-terminals-table-body-wrapper')}>
            <div className={cx('port-terminals-table-body')}>
              {this.renderRows(this.state.terminals)}
            </div>
          </div>
        </div>
        <div className={'port-terminals-no-results'}>
          <div className={'no-results'}>
            No results
          </div>
        </div>
        </div>
      </StylesWrapper>
    );
  }
}

export default PortTerminals;
