import Api from './api';
import { UUIDToObjectId } from '../utils';

export default class ContactBookApi extends Api {
  static endpoint = '/contact-persons';

  static fetch(url, params, retry = 0) {
    if (params.body) {
      params.body = JSON.stringify(params.body);
    }
    return super.fetch(url, params, '/api/v2', retry);
  }

  static async getContactList({ page = 1, filters = { tags: undefined }, sort = { field: 'name', value: 1 } }) {
    const types = { createdByMe: true, createdByTeam: true, new: true, ...(filters.types || {}) };
    if ((!types.createdByMe && !types.createdByTeam) || (types.createdByMe && types.createdByTeam)) {
      types.createdByMe = undefined;
    }
    types.approved = !types.new;
    if (types.new) {
      types.approved = undefined;
    }
    types.new = undefined;
    let countryIds;
    if (filters.countryId) {
      countryIds = [filters.countryId];
    }

    return this.fetch(`/contacts/search`, {
      method: 'POST',
      body: {
        page,
        tags: filters.tags,
        sort: sort.field,
        order: sort.value === 1 ? 'asc' : 'desc',
        limit: 50,
        expand: ['companies', 'tags'],
        types: ['person'],
        favedFirst: sort.favedFirst,
        emails: filters.emails,
        countryIds,
        ...types,
      },
    });
  }

  static async getContact(id){
    return this.fetch(`/contact-persons/${id}?expand=companies,tags,attachments,createdByUser,updatedByUser,country,city`, {
      method: 'GET',
    });
  }

  static async createContact(contact){
    return this.fetch(`/contact-persons/`, {
      method: 'POST',
      body: contact,
    });
  }

  static async updateContact(contact){
    return this.fetch(`/contact-persons/${contact.id}`, {
      method: 'PATCH',
      body: contact,
    });
  }

  static async searchCompany({ page = 1, filters = { q: undefined }, sort }) {
    return this.fetch(`/contact-companies/?sort=name&order=asc&expand=country,city&page=${page}&limit=50${filters.q ? `&q=${encodeURIComponent(filters.q)}` : ''}`, {
      method: 'GET',
    });
  }

  static async getCompanyList({ page = 1, filters = { tags: undefined }, sort }) {
    let countryIds;
    if (filters.countryId) {
      countryIds = [filters.countryId];
    }
    return this.fetch(`/contacts/companies-and-groups/`, {
      method: 'POST',
      body: {
        page,
        limit: 50,
        tags: filters.tags,
        expand: ['tags', 'country', 'city'],
        sort: sort.field,
        order: sort.value === 1 ? 'asc' : 'desc',
        countryIds,
      }
    });
  }

  static async getCompany(id){
    return this.fetch(`/contact-companies/${id}?expand=persons,tags,groups,country,city,attachments`, {
      method: 'GET',
    });
  }

  static async createCompany(contact){
    return this.fetch(`/contact-companies/`, {
      method: 'POST',
      body: contact,
    });
  }

  static async updateCompany(contact){
    return this.fetch(`/contact-companies/${contact.id}`, {
      method: 'PATCH',
      body: contact,
    });
  }

  static async searchContact({ page = 1, filters = { q: undefined }, sort }) {
    return this.fetch(`/contact-persons/?sort=name&order=asc${filters.q ? `&q=${encodeURIComponent(filters.q)}` : ''}`, {
      method: 'GET',
    });
  }

  static async autocompleteContacts({ page = 1, filters = { q: undefined }, sort }) {
    return this.fetch(`/contacts/autocomplete`, {
      method: 'POST',
      body: {
        page: 1,
        q: filters.q,
        sort: 'name',
        order: 'asc',
        limit: 50,
        types: ['person', 'company'],
        favedFirst: true,
        withEmailOnly: true,
        approved: true,
      },
    });
  }

  static async getGroup(id){
    return this.fetch(`/contact-groups/${id}?expand=companies,tags,attachments,country,city`, {
      method: 'GET',
    });
  }

  static async createGroup(group){
    return this.fetch(`/contact-groups/`, {
      method: 'POST',
      body: group,
    });
  }

  static async updateGroup(group){
    return this.fetch(`/contact-groups/${group.id}`, {
      method: 'PATCH',
      body: group,
    });
  }

  static async deleteContacts(ids) {
    return this.fetch(`/contacts/`, {
      method: 'DELETE',
      body: { ids },
    });
  }

  static async findOrCreateCompany(name) {
     const companies = (await this.searchCompany({ filters: { q: name } })).data;
     let company = companies.find(c => c.name.toLowerCase() === name.toLowerCase());
     if (!company) {
       company = (await this.createCompany({ name })).data;
     }
     return company;
  }

  static async checkPersonEmail(email) {
    return this.fetch(`/contact-persons/check-email?email=${encodeURIComponent(email)}`, {
      method: 'GET',
    });
  }

  static async checkCompanyEmail(email) {
    return this.fetch(`/contact-companies/check-email?email=${encodeURIComponent(email)}`, {
      method: 'GET',
    });
  }

  static async checkGroupEmail(email) {
    return this.fetch(`/contact-groups/check-email?email=${encodeURIComponent(email)}`, {
      method: 'GET',
    });
  }

  static async checkEmail(entity, email) {
    switch (entity) {
      case 'person':
        return this.checkPersonEmail(email);
      case 'group':
        return this.checkGroupEmail(email);
      case 'company':
        return this.checkCompanyEmail(email);
    }
  }

  static async addToFavorites(ids) {
    return this.fetch(`/contacts/add-favorite`, {
      method: 'POST',
      body: { ids },
    });
  }

  static async removeFromFavorites(ids) {
    return this.fetch(`/contacts/remove-favorite`, {
      method: 'POST',
      body: { ids },
    });
  }

  static async findContactByEmail(email) {
    const res = await this.getContactList({ filters: { emails: [email] } });
    res.data = res.data[0];
    return res;
  }

  static async mergeContacts(body) {
    return this.fetch(`/contact-persons/merge/${body.id}`, {
      method: 'PATCH',
      body,
    });
  }
  static async getCounters(){
    return this.fetch(`/contacts/counters`, {
      method: 'GET',
    });
  }
  static async getHeatmap(){
    return this.fetch(`/contacts/heatmap`, {
      method: 'GET',
    });
  }
}
