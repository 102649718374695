import React, { Component, PureComponent } from 'react'
import s from './TableNew.scss';
import cx from "classnames";
import ArrowPopover from '../Common/ArrowPopover';
import { Checkbox, MenuItem } from 'material-ui';
import IndeterminateCheckbox from './../Common/IndeterminateCheckbox';
import memoizeOne from 'memoize-one';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BlurIfNoSubscription from '../Signup/BlurIfNoSubscription';
import { connect } from 'react-redux';
import DropDownIco from 'material-ui/svg-icons/navigation/arrow-drop-down';

const allSelected = memoizeOne(vessels => vessels?.length > 0 && vessels.every(vessel => !!vessel.selected));
const someSelected = memoizeOne(vessels => vessels.some(vessel => !!vessel.selected));
const icon = <DropDownIco style={{width: 18, height: 18}} />;
export class VesselSelector extends PureComponent {

  static defaultProps = {
    vertical: false,
    blurCheckbox: false
  }

  renderRefMenu = list => {
    return list.map(item => {
		  const dis = typeof item.disabled === 'function' ? item.disabled(this.props) : item.disabled ?? false;
      const name = typeof item.name === 'function' ? item.name(this.props) : item.name;
      return (
        <MenuItem style={!dis && {opacity: 0.75}} disabled={!dis} onClick={dis ? item.handler : null} primaryText={name} value={item.id} key={item.id} />
      )
    })
  }

  handleCheck = (val) => {
    this.setState({
      everyVesselSelected: val
    })
    this.props.handleCheck(val);
  }

  displayLabel = val => {
    const { blurCheckbox, checkboxProps = {} } = this.props;
    const everyVesselSelected = allSelected(this.props.vessels);
    const isIndeterminate = !everyVesselSelected && someSelected(this.props.vessels);
    let El = ({ shouldBlur }) => <IndeterminateCheckbox indeterminate={isIndeterminate} checked={everyVesselSelected} onClick={shouldBlur ? undefined : ev => ev.stopPropagation()} onCheck={shouldBlur ? undefined : this.handleCheck} className={cx(s.ref_checkbox)} disabled={shouldBlur} {...checkboxProps} />

    if (blurCheckbox) {
      return (
        <BlurIfNoSubscription onClick={ev => (ev.stopPropagation(), ev.preventDefault())}>
            {
              El
            }
        </BlurIfNoSubscription>
      )
    }

    return <IndeterminateCheckbox indeterminate={isIndeterminate} checked={everyVesselSelected} onClick={ev => ev.stopPropagation()} onCheck={this.handleCheck} className={cx(s.ref_checkbox)} {...checkboxProps} />
  }

  render() {
    const { refList, style = {}, ...rest } = this.props;
    if (!refList || !refList.length) {
      return this.displayLabel();
    }
    return (
      <ArrowPopover isDark={this.props.isDark} renderer={this.renderRefMenu}  displayLabel={this.displayLabel} labelClassName={cx(s.arrow_checkbox, this.props.vertical ? s.vertical_arrow_checkbox : "")} style={{fontWeight: 700, marginTop: '-4px', ...style}} list={refList} {...rest} ico={icon} />
    )
  }
}

export default connect(state => ({ user: state.login.user }))(withStyles(s)(VesselSelector));
