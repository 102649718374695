import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../../../Pages/Pages.scss';
import TabsConferences from './TabsConferences';
import TabsConferencesMob from './TabsConferencesMob';
import Media from "react-media";

const conferences = [
  {
    "name": "BreakBulk China 2017",
    "html": "Shanghai, China",
    "link": null,
    "startDate": "2017-03-12T22:00:00.000Z",
    "endDate": "2017-03-15T22:00:00.000Z",
  },
  {
    "name": "Pireas 2017",
    "html": "Athens, Greece",
    "link": null,
    "startDate": "2017-06-14T21:00:00.000Z",
    "endDate": "2017-06-15T21:00:00.000Z",
  },
  {
    "name": "BreakBulk Southeast Asia 2017",
    "html": "Kuala Lumpur, Malaysia",
    "link": null,
    "startDate": "2017-09-03T21:00:00.000Z",
    "endDate": "2017-09-05T21:00:00.000Z",
  },
  {
    "name": "BreakBulk America 2017",
    "html": "Houston, USA",
    "link": null,
    "startDate": "2017-10-16T21:00:00.000Z",
    "endDate": "2017-10-18T21:00:00.000Z",
  },
  {
    "name": "Global Grain",
    "html": "Geneva, Switzerland",
    "link": "",
    "startDate": "2017-11-13T22:00:00.000Z",
    "endDate": "2017-11-15T22:00:00.000Z",
  },
  {
    "name": "World Blockchain Summit",
    "html": "Dubai, UAE",
    "link": "https://dubai.worldblockchainsummit.com/#home",
    "startDate": "2018-01-04T22:00:00.000Z",
    "endDate": "2018-01-05T22:00:00.000Z",
  },
  {
    "name": "WCEF",
    "html": "San Francisco, USA",
    "link": "http://wcef.co/",
    "startDate": "2018-01-14T22:00:00.000Z",
    "endDate": "2018-01-15T22:00:00.000Z",
  },
  {
    "name": "The North American Bitcoin Conference",
    "html": "Miami, USA",
    "link": "https://btcmiami.com/",
    "startDate": "2018-01-17T22:00:00.000Z",
    "endDate": "2018-01-17T22:00:00.000Z",
  },
  {
    "name": "London Blockchain Week",
    "html": "London, UK",
    "link": "https://www.blockchainweek.com/",
    "startDate": "2018-01-21T22:00:00.000Z",
    "endDate": "2018-01-24T22:00:00.000Z",
  },
  {
    "name": "World Economic Forum 2018",
    "html": "Davos, Switzerland",
    "link": "https://www.weforum.org/focus/davos-2018",
    "startDate": "2018-01-22T22:00:00.000Z",
    "endDate": "2018-01-25T22:00:00.000Z",
  },
  {
    "name": "Blockchain Conference",
    "html": "Moscow, Russia",
    "link": null,
    "startDate": "2018-01-26T22:00:00.000Z",
    "endDate": "2018-01-27T22:00:00.000Z",
  },
  {
    "name": "Blockchain Economic Forum",
    "html": "Singapore",
    "link": null,
    "startDate": "2018-02-02T22:00:00.000Z",
    "endDate": "2018-02-04T22:00:00.000Z",
  },
  {
    "name": "Breakbulk Conference",
    "html": "Abu Dhabi, UAE",
    "link": "http://www.breakbulk.com/events/breakbulk-middle-east-2018/",
    "startDate": "2018-02-05T22:00:00.000Z",
    "endDate": "2018-02-06T22:00:00.000Z",
  },
  {
    "name": "Middle East Grain Conference",
    "html": "Dubai, UAE",
    "link": null,
    "startDate": "2018-02-20T22:00:00.000Z",
    "endDate": "2018-02-20T22:00:00.000Z",
  },
  {
    "name": "Speech on Blockchain to CEO Club",
    "html": "Kiev, Ukraine",
    "link": "https://www.youtube.com/watch?v=lmJqXY5_8yE&feature=youtu.be",
    "startDate": "2018-02-21T22:00:00.000Z",
    "endDate": "2018-02-21T22:00:00.000Z",
  },
  {
    "name": "Blockchain Summit",
    "html": "Washington, USA",
    "link": null,
    "startDate": "2018-03-05T22:00:00.000Z",
    "endDate": "2018-03-05T22:00:00.000Z",
  },
  {
    "name": "Global Grain",
    "html": "Singapore",
    "link": null,
    "startDate": "2018-03-11T22:00:00.000Z",
    "endDate": "2018-03-13T22:00:00.000Z",
  },
  {
    "name": "Marine Money",
    "html": "Dubai, UAE",
    "link": null,
    "startDate": "2018-03-14T22:00:00.000Z",
    "endDate": "2018-03-14T22:00:00.000Z",
  },
  {
    "name": "Token Fest",
    "html": "San Francisco, USA",
    "link": null,
    "startDate": "2018-03-14T22:00:00.000Z",
    "endDate": "2018-03-14T22:00:00.000Z",
  },
  {
    "name": "Blockchain West",
    "html": "San Francisco",
    "link": "https://blockchainwest.com/san-francisco-2018/",
    "startDate": "2018-03-25T21:00:00.000Z",
    "endDate": "2018-03-27T21:00:00.000Z",
  },
  {
    "name": "BreakBulk Middle East",
    "html": "Shanghai, China",
    "link": "https://www.breakbulk.com/events/breakbulk-china-2018/",
    "startDate": "2018-03-26T21:00:00.000Z",
    "endDate": "2018-03-27T21:00:00.000Z",
  },
  {
    "name": "Global Blockchain Forum",
    "html": "Santa Clara, USA",
    "link": null,
    "startDate": "2018-04-01T21:00:00.000Z",
    "endDate": "2018-04-02T21:00:00.000Z",
  },
  {
    "name": "Big Data and Blockchain Conference",
    "html": "Santa Clara, USA",
    "link": "http://www.globalbigdataconference.com/index.html",
    "startDate": "2018-04-01T21:00:00.000Z",
    "endDate": "2018-04-01T21:00:00.000Z",
  },
  {
    "name": "Strategic Technologies Transport",
    "html": "Hamburg, Germany",
    "link": null,
    "startDate": "2018-04-09T21:00:00.000Z",
    "endDate": "2018-04-09T21:00:00.000Z",
  },
  {
    "name": "Blockchain Silicon Valley",
    "html": "USA",
    "link": null,
    "startDate": "2018-04-16T21:00:00.000Z",
    "endDate": "2018-04-16T21:00:00.000Z",
  },
  {
    "name": "Blockchain New York",
    "html": "New York, USA",
    "link": null,
    "startDate": "2018-04-18T21:00:00.000Z",
    "endDate": "2018-04-18T21:00:00.000Z",
  },
  {
    "name": "Blockchain in supply chain",
    "html": "Austin, TX",
    "link": "https://www.blockchain-supplychain.com/home",
    "startDate": "2018-04-24T21:00:00.000Z",
    "endDate": "2018-04-24T21:00:00.000Z",
  },
  {
    "name": "Blockchain Summit",
    "html": "Frankfurt, Germany",
    "link": "http://blockchainsummitfrankfurt.com/",
    "startDate": "2018-04-25T21:00:00.000Z",
    "endDate": "2018-04-25T21:00:00.000Z",
  },
  {
    "name": "Blockchain PROseed",
    "html": "Tokyo, Japan",
    "link": "http://jinsei.me/blockchain-projects-prioritytoken/",
    "startDate": "2018-05-10T21:00:00.000Z",
    "endDate": "2018-05-10T21:00:00.000Z",
  },
  {
    "name": "Blockchain for Supply Chain",
    "html": "Houston, USA",
    "link": "https://blockchainsupplychain.io/#speakers",
    "startDate": "2018-05-20T21:00:00.000Z",
    "endDate": "2018-05-21T21:00:00.000Z",
  },
  {
    "name": "Digital Business World Congress",
    "html": "Madrid, Spain",
    "link": null,
    "startDate": "2018-05-21T21:00:00.000Z",
    "endDate": "2018-05-23T21:00:00.000Z",
  },
  {
    "name": "Blockchain in Trade and Transport",
    "html": "Odessa, Ukraine",
    "link": "http://btctconference.com/",
    "startDate": "2018-05-24T21:00:00.000Z",
    "endDate": "2018-05-24T21:00:00.000Z",
  },
  {
    "name": "Breakbulk Europe",
    "html": "Bremen, Germany",
    "link": "https://www.breakbulk.com/events/breakbulk-europe-2018/",
    "startDate": "2018-05-28T21:00:00.000Z",
    "endDate": "2018-05-30T21:00:00.000Z",
  },
  {
    "name": "Poseidonia",
    "html": "Athens, Greece",
    "link": null,
    "startDate": "2018-06-04T21:00:00.000Z",
    "endDate": "2018-06-04T21:00:00.000Z",
  },
  {
    "name": "Marine Money",
    "html": "Geneva, Switzerland",
    "link": null,
    "startDate": "2018-06-11T21:00:00.000Z",
    "endDate": "2018-06-11T21:00:00.000Z",
  },
  {
    "name": "SMART Shipping Symposium",
    "html": "Glasgow, England",
    "link": "https://www.cityofglasgowcollege.ac.uk/about-us/smart-shipping-2018",
    "startDate": "2018-06-17T21:00:00.000Z",
    "endDate": "2018-06-18T21:00:00.000Z",
  },
  {
    "name": "Korea Blockchain Summit 2018",
    "html": "Korea",
    "link": "https://www.gbf.world/kbs",
    "startDate": "2018-07-11T21:00:00.000Z",
    "endDate": "2018-07-12T21:00:00.000Z",
  },
  {
    "name": "Global Big Data Conference",
    "html": "Santa Clara, USA",
    "link": "http://www.globalbigdataconference.com/santa-clara/6th-annual-global-big-data-conference-105/speaker-details/alexander-varvarenko-62655.html",
    "startDate": "2018-08-27T21:00:00.000Z",
    "endDate": "2018-08-29T21:00:00.000Z",
  },
  {
    "name": "Maritime CIO Forum",
    "html": "Tokyo, Japan",
    "link": "https://www.tokyo.thedigitalship.com/the-event",
    "startDate": "2018-08-28T21:00:00.000Z",
    "endDate": "2018-08-28T21:00:00.000Z",
  },
  {
    "name": "ASEAN Blockchain Summit 2018",
    "html": "Kuala Lumpur, Malaysia",
    "link": "https://www.gbf.world/abs",
    "startDate": "2018-09-02T21:00:00.000Z",
    "endDate": "2018-09-03T21:00:00.000Z",
  },
  {
    "name": "Azov-BlSea & Med Cargo Flow Outlook",
    "html": "Odessa, Ukraine",
    "link": "https://freight-forum.com/",
    "startDate": "2018-09-19T21:00:00.000Z",
    "endDate": "2018-09-19T21:00:00.000Z",
  },
  {
    "name": "Shipping 2030 Asia + Global Liner Asia",
    "html": "Singapore",
    "link": null,
    "startDate": "2018-09-23T21:00:00.000Z",
    "endDate": "2018-09-25T21:00:00.000Z",
  },
  {
    "name": "2nd Baltic Sea Ports & Shipping 2018",
    "html": "Riga, Latvia",
    "link": "",
    "startDate": "2018-09-24T21:00:00.000Z",
    "endDate": "2018-09-26T21:00:00.000Z",
  },
  {
    "name": "Maritime CIO Forum Rotterdam",
    "html": "Rotterdam, Netherlands",
    "link": "http://www.rotterdam.thedigitalship.com",
    "startDate": "2018-09-25T21:00:00.000Z",
    "endDate": "2018-09-25T21:00:00.000Z",
  },
  {
    "name": "Malta Maritime Summit",
    "html": "Valletta, Malta",
    "link": "http://maltamaritimesummit.com/",
    "startDate": "2018-09-30T21:00:00.000Z",
    "endDate": "2018-10-04T21:00:00.000Z",
  },
  {
    "name": "BreakBulk Americas",
    "html": "Houston, USA",
    "link": "https://www.breakbulk.com/events/breakbulk-americas-2018/",
    "startDate": "2018-10-01T21:00:00.000Z",
    "endDate": "2018-10-03T21:00:00.000Z",
  },
  {
    "name": "7th annual African Ports and Rail Evolution",
    "html": "Durban, South Africa",
    "link": null,
    "startDate": "2018-10-01T21:00:00.000Z",
    "endDate": "2018-10-02T21:00:00.000Z",
  },
  {
    "name": "Blockchain Shift",
    "html": "Miami, USA",
    "link": "https://blockchainshift.io",
    "startDate": "2018-10-10T21:00:00.000Z",
    "endDate": "2018-10-11T21:00:00.000Z",
  },
  {
    "name": "Malta Blockchain Summit",
    "html": "Malta",
    "link": "https://maltablockchainsummit.com/",
    "startDate": "2018-10-31T22:00:00.000Z",
    "endDate": "2018-11-01T22:00:00.000Z",
  },
  {
    "name": "Digital Ship Athens",
    "html": "Athens, Greece",
    "link": "http://www.athens.thedigitalship.com/",
    "startDate": "2018-11-06T22:00:00.000Z",
    "endDate": "2018-11-07T22:00:00.000Z",
  },
  {
    "name": "Marine Money Japan Ship Finance Forum 2018",
    "html": "Tokyo, Japan",
    "link": "https://www.marinemoney.com/forum/JAP18",
    "startDate": "2018-11-06T22:00:00.000Z",
    "endDate": "2018-11-06T22:00:00.000Z",
  },
  {
    "name": "Digital Commodities Summit London",
    "html": "London, UK",
    "link": "https://www.spglobal.com",
    "startDate": "2018-11-11T22:00:00.000Z",
    "endDate": "2018-11-12T22:00:00.000Z",
  },
  {
    "name": "Global Grain Awards",
    "html": "Geneva, Switzerland",
    "link": "http://interactive.globalgrainevents.com/globalgrainawards?_ga=2.76709782.2035548786.1537947672-290921274.1530095997",
    "startDate": "2018-11-12T22:00:00.000Z",
    "endDate": "2018-11-12T22:00:00.000Z",
  },
  {
    "name": "Global Liner Shipping Middle East",
    "html": "Dubai, UAE",
    "link": null,
    "startDate": "2018-11-26T22:00:00.000Z",
    "endDate": "2018-11-27T22:00:00.000Z",
  },
  {
    "name": "Breaking Waves",
    "html": "Helsinki, Finland",
    "link": "https://www.breakingwaves.fi/",
    "startDate": "2018-12-02T22:00:00.000Z",
    "endDate": "2018-12-03T22:00:00.000Z",
  },
  {
    "name": "Slush",
    "html": "Helsinki, Finland",
    "link": "https://www.slush.org/",
    "startDate": "2018-12-03T22:00:00.000Z",
    "endDate": "2018-12-04T22:00:00.000Z",
  },
  {
    "name": "Cryptoblock",
    "html": "Las Vegas, USA",
    "link": "https://www.cryptoblockcon.com/",
    "startDate": "2018-12-10T22:00:00.000Z",
    "endDate": "2018-12-11T22:00:00.000Z",
  },
  {
    "name": "SMART4SEA Conference",
    "html": "Athens, Greece",
    "link": "https://events.safety4sea.com/2019-smart4sea-conference/",
    "startDate": "2019-01-29T22:00:00.000Z",
    "endDate": "2019-01-29T22:00:00.000Z",
  },
  {
    "name": "The Maritime CIO Forum",
    "html": "London, UK",
    "link": "https://www.london.thedigitalship.com/",
    "startDate": "2019-02-28",
    "endDate": "2019-02-28",
  },
  {
    "name": "Blockchain Technology World 2019",
    "html": "London, UK",
    "link": "https://www.blockchaintechnologyworld.com/",
    "startDate": "2019-03-11T22:00:00.000Z",
    "endDate": "2019-03-12T22:00:00.000Z",
  },
  {
    "name": "Shipping 2030 Europe",
    "html": "Copenhagen, Denmark",
    "link": "https://maritime.knect365.com/shipping2030-global/attendees",
    "startDate": "2019-03-18T22:00:00.000Z",
    "endDate": "2019-03-21T22:00:00.000Z",
  },
  {
    "name": "Ecommerce week 2019 UNCTAD",
    "html": "Geneva, Switzerland",
    "link": "https://unctad.org/en/Pages/MeetingDetails.aspx?meetingid=1966",
    "startDate": "2019-04-01",
    "endDate": "2019-04-05",
  },
  {
    "name": "AntwerpXL",
    "html": "Antwerp, Belgium",
    "link": "https://www.easyfairs.com/antwerp-xl-2019/antwerp-xl-2019/",
    "startDate": "2019-05-07T21:00:00.000Z",
    "endDate": "2019-05-07T21:00:00.000Z",
  },
  {
    "name": "Global Liner Shipping",
    "html": "Hamburg, Germany",
    "link": "https://maritime.knect365.com/global-liner-shipping/",
    "startDate": "2019-05-13",
    "endDate": "2019-05-15",
  },
  {
    "name": "InNowvate 2019",
    "html": "Amersfoort, The Netherlands",
    "link": "https://www.supplychainmovement.com/announcement-5-finalists-start-up-contest/",
    "startDate": "2019-05-22",
    "endDate": "2019-05-22",
  },
  {
    "name": "IGC Grains Conference",
    "html": "London, UK",
    "link": "https://www.igc.int/en/conference/confhome.aspx",
    "startDate": "2019-06-11",
    "endDate": "2019-06-12",
  },
  {
    "name": "Multimodal 2019",
    "html": "Birmingham, UK",
    "link": "https://www.multimodal.org.uk/",
    "startDate": "2019-06-18T21:00:00.000Z",
    "endDate": "2019-06-18T21:00:00.000Z",
  },
  {
    "name": "African Trade, Transport and Energy Summit",
    "html": "Ghana",
    "link": null,
    "startDate": "2019-10-28T22:00:00.000Z",
    "endDate": "2019-10-30T22:00:00.000Z",
  },

  {
    "name": "Argus Chlor-Alkali Asia",
    "html": "Singapore",
    "link": "https://www.argusmedia.com/ru/conferences-events-listing/chlor-alkali-asia\n",
    "startDate": "2020-01-13",
    "endDate": "2020-01-14",
  },
  {
    "name": "Argus Middle East Petroleum Coke Conference",
    "html": "Abu Dhabi, UAE",
    "link": "https://www.argusmedia.com/ru/conferences-events-listing/middle-east-petroleum-coke\n",
    "startDate": "2020-01-13",
    "endDate": "2020-01-14",
  },
  {
    "name": "The North American Bitcoin Conference",
    "html": "Miami, USA",
    "link": "https://btcmiami.com/#",
    "startDate": "2020-01-15",
    "endDate": "2020-01-17",
  },
  {
    "name": "Argus Middle East LPG & Petrochemical Feedstocks Conference",
    "html": "Abu Dhabi, UAE",
    "link": "https://www.argusmedia.com/ru/conferences-events-listing/middle-east-lpg",
    "startDate": "2020-01-15",
    "endDate": "2020-01-16",
  },
  {
    "name": "Fertilizer Latino Americano",
    "html": "São Paulo, Brazil",
    "link": "https://www.argusmedia.com/ru/conferences-events-listing/fertilizer-latino-americano",
    "startDate": "2020-01-20",
    "endDate": "2020-01-22",
  },
  {
    "name": "World Economic Forum Annual Meeting",
    "html": "Davos-Klosters, Switzerland",
    "link": "https://www.weforum.org/events/world-economic-forum-annual-meeting-2020",
    "startDate": "2020-01-21",
    "endDate": "2020-01-24",
  },
  {
    "name": "Oil and Gas IOT Summit",
    "html": "Lisbon, Portugal",
    "link": "https://www.oilandgas-iot.com/",
    "startDate": "2020-01-22",
    "endDate": "2020-01-23",
  },
  {
    "name": "London Ship Finance Forum",
    "html": "London, UK",
    "link": "https://www.marinemoney.com/forum/LON20",
    "startDate": "2020-01-22",
    "endDate": "2020-01-22",
  },
  {
    "name": "Argus Americas Crude Summit",
    "html": "Houston, USA",
    "link": "https://www.argusmedia.com/ru/conferences-events-listing/americas-crude",
    "startDate": "2020-02-03",
    "endDate": "2020-02-05",
  },
  {
    "name": "Argus Chlor-Alkali Conference",
    "html": "London, UK",
    "link": "https://www.argusmedia.com/ru/conferences-events-listing/chlor-alkali",
    "startDate": "2020-02-04",
    "endDate": "2020-02-05",
  },
  {
    "name": "IFA Global Stewardship Conference",
    "html": "New York City, USA",
    "link": "https://www.fertilizer.org/Public/Events/Upcoming_Events.aspx",
    "startDate": "2020-02-04",
    "endDate": "2020-02-07",
  },
  {
    "name": "Nigeria International Petroleum Summit",
    "html": "Abuja, Nigeria",
    "link": "http://www.nigeriapetroleumsummit.com/",
    "startDate": "2020-02-09",
    "endDate": "2020-02-12",
  },
  {
    "name": "3rd Small-Scale LNG Summit",
    "html": "Milan, Italy",
    "link": "https://www.sslngevent.com/",
    "startDate": "2020-02-11",
    "endDate": "2020-02-12",
  },
];

function formatDay(date) {
  return ('0' + date.getDate()).slice(-2);
}
const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
function formatMonth(date) {
  return MONTH_NAMES[date.getMonth()];
}
function formatDate(item) {
  const startDate = new Date(item.startDate);
  const endDate = new Date(item.endDate);
  if (startDate.getTime() === endDate.getTime()) {
    return (
      <span className={s.date}>
        {formatDay(startDate)}&nbsp;{formatMonth(startDate)}
      </span>
    );
  }
  if (startDate.getMonth() !== endDate.getMonth()) {
    return (
      <span className={s.date}>
        {formatDay(startDate)} {formatMonth(startDate)} - {formatDay(endDate)} {formatMonth(endDate)}
      </span>
    );
  }
  return (
    <span className={s.date}>
      {formatDay(startDate)} - {formatDay(endDate)} {formatMonth(endDate)}
    </span>
  );
}
function Conference({ item }) {
  return (
    <li>
      <span className={s.wrapper_description}>
        {item.link ?
          <a href={item.link} rel="noopener noreferrer" target="_blank">
            <span className={s.green_active}>{item.name}</span>
          </a>
          :
          <span>{item.name}</span>
        }
        <span>{item.html}</span>
      </span>
      <span className={s.date}>
        {formatDate(item)}
      </span>
    </li>
  );
}

class Conferences extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const conferencesByYear =  (conferences || []).reduce((accum,c) => {
      const year = (new Date(c.startDate).getFullYear());
      accum[year] = accum[year] || [];
      accum[year].push(c);
      return accum;
    }, {});
    const center2017 = Math.ceil((conferencesByYear[2017] || []).length / 2);
    const center2018 = Math.ceil((conferencesByYear[2018] || []).length / 2);
    const center2019 = Math.ceil((conferencesByYear[2019] || []).length / 2);
    const center2020 = Math.ceil((conferencesByYear[2020] || []).length / 2);

    return (
      <div className={s.conferences_ico}>
        <div className={s.container}>
          <div className={s.title}>
            <h2>SHIPNEXT at Conferences</h2>
          </div>
          <div className="wrapper">
            <Media query="(max-width: 620px)" defaultMatches={false}>
              {matches =>
                matches ? (
                  <TabsConferencesMob
                    conferences2017={conferencesByYear[2017] || []}
                    conferences2018={conferencesByYear[2018] || []}
                    conferences2019={conferencesByYear[2019] || []}
                    conferences2020={conferencesByYear[2020] || []}
                  />
                ) : (
                  <TabsConferences>
                    <div label="2020">
                      <div className={s.wrapper_conferences}>
                        <ul className={s.column}>
                          {(conferencesByYear[2020] || []).slice(0, center2020).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                        <ul className={s.column}>
                          {(conferencesByYear[2020] || []).slice(center2020).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                    <div label="2019">
                      <div className={s.wrapper_conferences}>
                        <ul className={s.column}>
                          {(conferencesByYear[2019] || []).slice(0, center2019).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                        <ul className={s.column}>
                          {(conferencesByYear[2019] || []).slice(center2019).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                    <div label="2018">
                      <div className={s.wrapper_conferences}>
                        <ul className={s.column}>
                          {(conferencesByYear[2018] || []).slice(0, center2018).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                        <ul className={s.column}>
                          {(conferencesByYear[2018] || []).slice(center2018).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                    <div label="2017">
                      <div className={s.wrapper_conferences}>
                        <ul className={s.column}>
                          {(conferencesByYear[2017] || []).slice(0, center2017).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                        <ul className={s.column}>
                          {(conferencesByYear[2017] || []).slice(center2017).map((item, i) => (
                              <Conference key={i} item={item} />
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                  </TabsConferences>
                )
              }
            </Media>
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(Conferences));
