import React from 'react';
import Bookmark from 'material-ui/svg-icons/action/bookmark-border';
import Hoverable from '../Common/Hoverable';
import CustomTooltip from '../Common/CustomTooltip';
import styled from 'styled-components';
import BlurIfNoSubscription from './../Signup/BlurIfNoSubscription';

const Icon = styled.div`
  cursor: pointer;
  svg {
    width: 20px !important;
    height: 20px !important;
    color: var(--filter-sort-clr) !important;
  }
`;

function SelectedAction({ isDark, BookmarkIcon = Bookmark, tooltip, disabled, handleClick, ...rest }) {
  return (
    <CustomTooltip dark={isDark} tooltip={tooltip} style={{ height: "auto" }}>
      <BlurIfNoSubscription dontBlur>
        <Hoverable disabled={disabled} style={{ pointerEvents: "initial" }} onClick={handleClick}>
          <Icon {...rest}>
            <BookmarkIcon />
          </Icon>
        </Hoverable>
      </BlurIfNoSubscription>
    </CustomTooltip>
  )
}

export default SelectedAction;
