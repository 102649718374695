import React, { Component } from 'react'
import RoundBorderedBtn from './../../Common/RoundBorderedBtn';
import MenuItem from 'material-ui/MenuItem';
import Menu from 'material-ui/Menu';
import DefaultPopover from './../../Common/DefaultPopover';
import DropDownIco from 'material-ui/svg-icons/navigation/arrow-drop-down';
import CustomCheckbox from '../../Common/CustomCheckbox';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ActionsMenu.scss';
import t from './../../Monitor/TableNew.scss';
import { connect } from 'react-redux';
import WithTooltip from './../../Common/WithTooltip';
import { changeVesselStatus } from './../../../actions/monitor';
import cx from 'classnames';
import { debounceWithoutFirstCall as debounce } from './../../../core/utils';
import BlurIfNoSubscription from './../../Signup/BlurIfNoSubscription';
import { toggleSubscribeDialog } from '../../../actions/login';
import { isUserSubscribedToMarketplace } from '../../../core/utils';
import ForSaleCheckbox from "../../Monitor/ForSaleOffers/ForSaleCheckbox";
import history from '../../../core/history';
import CheckboxPopover from '../../Common/CheckboxPopover';

const dictionary = [
  {
    id: 'liner',
    name: 'Liner Trader (LT)',
    disabled: vessel => vessel?.deadWeight?.sDWT > 33000,
    alias: "LT",
  },
  {
    id: 'iwl',
    name: 'IWL Trader (IWL)',
  },
  {
    id: 'sf',
    name: 'Scrubber Fitted (SF)',
  },
  {
    id: 'rt',
    name: 'Restriction Trader (RT)',
  },
  {
    id: 'np',
    name: 'Neo Panama (NP)',
  },
  {
    id: 'pt',
    name: 'Period Trader (PT)',
  },
  {
    id: 's&p',
    name: 'For Sale (S&P)',
    render: function (status, index) {
      const { salesOffers, vessel, getForSaleInfo, user } = this.props;
      return (
        <ForSaleCheckbox
          salesOffers={salesOffers}
          vessel={vessel}
          getForSaleInfo={getForSaleInfo}
        >
          {({ checked, disabled, onChange }) => (
              <MenuItem onClick={ev => {
                if (disabled) return;
                status.onChange(!checked);
                onChange(ev, !checked);
              }}>
                <CustomCheckbox checked={checked} disabled={disabled} labelPosition="left" label={status.name}></CustomCheckbox>
              </MenuItem>
          )}
        </ForSaleCheckbox>
      )
    }
  },
  {
    id: 'p&c',
    name: 'P&C Position (P&C)',
  },
  {
    id: "SANCTIONED",
    name: 'Sanctioned (BDS)',
    alias: "BDS",
  },
  {
    id: "VT",
    name: 'Voyage Trader (VT)',
    alias: "VT",
  },
]

export function StatusTip({ statuses }) {
  if (!statuses || statuses?.length === 0) return null;
  return (
    <div draggable="false" className={cx(t.generic_table_tooltip, t.new, t.cargoes)}>
      <div className={t.top_loading}>
       <div>
          <p>
              <span>{statuses.map(id => dictionary.find(d => String(d.id).toLowerCase() === String(id).toLowerCase())?.name || id).join(' and ')}</span>
          </p>
       </div>
      </div>
    </div>
  )
}

export const findStatusInDictionary = id => dictionaryStatus => String(dictionaryStatus.id).toLowerCase() === String(id).toLowerCase()

export const mapVesselGroupsToDictionary = (groups, dictionary) => groups.map(status => {
  const statusObj = dictionary.find(findStatusInDictionary(status));
  if (!statusObj) return status;
  return statusObj.alias ?? String(statusObj.id).toUpperCase();
}).join(', ');

export function _DisplayStatus({ groups }) {
  if (!groups || groups?.length === 0) return null;
  if (groups.length === 1) {
    let text = "";
    const reg = /\*/gi;
    if (reg.test(groups[0])) {
      text = groups[0];
    }
    const status = dictionary.find(findStatusInDictionary(groups[0]));
    if (status && status.name) {
      text = status.name;
    }
    return <span className={t.second_text}>{text}</span>
  }
  if (groups.length > 3) {
    const text = mapVesselGroupsToDictionary(groups.slice(0, 3), dictionary);
    const rest = groups.slice(3);
    return (
      <WithTooltip endAnchor horizontalPosition="left" offset={{x: -20, y: 0}} tip={<StatusTip statuses={groups} />}>
        <span style={{display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
          <span className={t.second_text}>{text}</span>
          <span className={t.blue_more}>+{rest.length}</span>
        </span>
      </WithTooltip>
    )
  }
  return (
    <span className={t.second_text} style={{display: 'flex', textAlign: 'right', justifyContent: 'flex-end'}}>
      {mapVesselGroupsToDictionary(groups, dictionary)}
    </span>
  )
}
export const DisplayStatus = connect(state => ({user: state.login.user}), null)(_DisplayStatus);
export class VesselStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchor: null,
      statuses: this.getInitialStatuses(props)
    }
  }

  _statuses = [...dictionary]

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedStatuses !== nextProps.selectedStatuses) {
      this.setState({
        statuses: this.getInitialStatuses(nextProps)
      })
    }
  }

  isStatusEqual = selectedStatus => s => s.id.toLowerCase().trim() === String(selectedStatus?.id ?? selectedStatus).toLowerCase().trim()

  mapStatuses = (defaultStatuses, selectedStatuses) => {
    const transformed = defaultStatuses.map(status => ({...status, ...(status.render ? { render: status.render.bind(this) } : {}), checked: false, disabled: status.disabled ? !!status.disabled(this.props.vessel) : false, onChange: this.handleStatus.bind(this, status)}))
    if (!selectedStatuses || selectedStatuses.length === 0) {
      return transformed;
    }
    for (let key in selectedStatuses) {
      const selectedStatus = selectedStatuses[key];
      const isEqual = this.isStatusEqual(selectedStatus);
      let statusIndex = transformed.findIndex(isEqual);
      if (statusIndex >= 0) {
        const status = {
          ...transformed[statusIndex],
          checked: selectedStatus.checked ? selectedStatus.checked : true
        }
        transformed[statusIndex] = status;
      }
    }
    return transformed
  }

  getInitialStatuses = (props = this.props) => {
    const { _statuses: defaultStatuses } = this;
    const { mapStatuses = this.mapStatuses, selectedStatuses = [] } = props;
    return mapStatuses(defaultStatuses, selectedStatuses);
  }

  handleStatus = (status, val, shouldSendRequest = true) => {
    const { statuses } = this.state;
    const isEqual = this.isStatusEqual(status);
    const index = statuses.findIndex(isEqual);
    let newStatus = statuses[index];
    newStatus = {
      ...newStatus,
      checked: val
    };
    const newStatuses = [...statuses];
    newStatuses[index] = newStatus;
    this.setState({
      statuses: newStatuses
    }, () => {
      this.props?.handleChange(this.mapBeforeSend(this.state.statuses));
      shouldSendRequest && this.sendStatus();
    });
  }

  mapBeforeSend = statuses => statuses.filter(status => !!status.checked).map(status => String(status.id).toUpperCase())

  sendStatus = debounce(() => {
    const { statuses } = this.state;
    const payload = {
      vesselId: this.props.vessel?._id || "",
      vesselGroups: {
        groups: this.mapBeforeSend(statuses),
        setBy: this.props.user._id,
        setAt: new Date()
      }
    }
    this.props.changeVesselStatus(payload);

  }, 700)

  openStatuses = ev => {
   /*  const isSubscribed = isUserSubscribedToMarketplace(this.props.user);
    if (!isSubscribed) {
      ev.stopPropagation();
      ev.preventDefault();
      if(!this.props.user) {
        return history.push('/login')
      }
      this.props.toggleSubscribeDialog(true);
      return;
    } */
    const { isBlurred } = this.props;
    if (isBlurred) {
      ev.stopPropagation();
      ev.preventDefault();
      this.props.toggleSubscribeDialog(true);
      return;
    }
    this.setState({
      open: true,
      anchor: ev.currentTarget
    })
  }

  closeStatuses = () => {
    this.setState({
      open: false
    })
  }

  render() {
    let { open, anchor, statuses } = this.state;
    const { openStatuses, closeStatuses } = this;

    return (
          <div style={{display: 'flex', alignItems: 'flex-end'}}>
            <CheckboxPopover className={s.actions_menu} list={statuses} handleClose={closeStatuses} open={open} anchor={anchor} />
            <RoundBorderedBtn onClick={openStatuses}>Vessel Status <DropDownIco style={{width: 24, marginRight: '-6px', marginLeft: '2px', height: 24}} /></RoundBorderedBtn>
          </div>
    )
  }
}

export default connect(state => ({user: state.login.user}), { changeVesselStatus, toggleSubscribeDialog })(withStyles(s, t)(VesselStatus));
