import muiThemeable from 'material-ui/styles/muiThemeable';
import React from 'react';

const themedStyles = (theme, type) => ({
  color: theme.raisedButton[`${type}Color`] || "#7FC931",
  color: theme.raisedButton[`${type}TextColor`] || "#fff"
})

function RoundThemableButton({
  children,
  style = {},
  type = "",
  className = "",
  component: Component,
  muiTheme,
  ...rest
}) {

  let El = Component ? Component : props => <button {...props}></button>

  let props = {
    ...rest,
    className: "round_themable_btn"
  };


  if (type) {
    props.style = themedStyles(muiTheme, type);
  }
  if (style) {
    props.style = {
      ...props.style,
      ...style
    }
  }
  if (className) {
    props.className += ` ${className}`;
  }

  return (
    <El {...props}>
      {children}
    </El>
  )
}

export default muiThemeable()(RoundThemableButton);
