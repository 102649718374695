import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sn from './ProfileInfo.scss';
import FormsyText from '../../components/Common/FormsyText';
// import SectorSelect from '../../components/Common/SectorSelect';
// import OrganizationSelect from '../../components/Common/OrganizationSelect';
// import CompanyAutocomplete from '../../components/Common/CompanyAutocomplete';
// import CityCountry from '../../components/Common/CityCountry';
import FormsyPhoneNumber from '../Common/FormsyPhoneNumber';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from 'material-ui/svg-icons/content/remove';
import { removeFromArray } from '../../core/utils';
// import cx from 'classnames';
// import Camera from 'material-ui/svg-icons/action/camera-enhance';
// import RaisedButton from '../Common/RaisedButton';


const hintStyle = { fontSize: '14px' };

class Phones extends Component {
  static propTypes = {
    phones: PropTypes.array,
    country: PropTypes.object,
    name: PropTypes.string,
  };

  static defaultProps = {
    phones: [],
    name: 'phones',
  };

  state = {};

  constructor(props) {
    super(props);
    this.state.phones = this.props.phones.slice(1).map((p, i) => { const prevIndex = this.props.originPhones.findIndex((op) => `${op.code}${op.phone}` === `${p.code}${p.phone}`); return { value: p, _id: Math.random(), previousValue: prevIndex  === -1 ? "newValue" : this.props.originPhones[prevIndex] }});

  }
  handleAddPhone = () => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ phones: [...this.state.phones, { _id: Math.random() }] });
  };
  handleRemovePhone = (i) => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ phones: removeFromArray(this.state.phones, i) });
  };

  render() {
    return <div className={sn.phone_edit_wrapper}>
      <div className={sn.phone_edit}>
        <FormsyPhoneNumber
          name={this.props.name + '[0]'}
          validations="isPhoneNumber"
          validationErrors={{
            isPhoneNumber: 'please enter valid phone number',
          }}
          spPhone={this.props.spPhone || null}
          defaultCountry={this.props.country}
          defaultValue={this.props.phones[0]}
          previousValue={this.props.originPhones ? this.props.originPhones[0] || '' : null}
        />
        <span onClick={this.handleAddPhone} className={sn.add_phone_button}>
          <ContentAdd />
        </span>
      </div>
      {this.state.phones.map((phone, i) => {
        return (
          <div key={phone._id} className={sn.phone_edit}>
            <FormsyPhoneNumber
              name={this.props.name + '[' + (i + 1) + ']'}
              validations="isPhoneNumber"
              validationErrors={{
                isPhoneNumber: 'please enter valid phone number',
              }}
              noAsterisk
              defaultCountry={this.props.country}
              spPhone={this.props.spPhone || null}
              defaultValue={phone.value}
              previousValue={phone.previousValue}

            />
            <span
              onClick={this.handleRemovePhone.bind(this, i)}
              className={sn.add_phone_button}
            >
              <ContentRemove />
            </span>
          </div>
        );
      })}
    </div>;
  }
}

class Emails extends Component {

  static propTypes = {
    emails: PropTypes.array,
    name: PropTypes.string,
  };
  static defaultProps = {
    name: 'emailAddresses',
    emails: [''],
  };

  state={};
  handleAddEmail = () => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ emails: [...this.state.emails, { _id: Math.random() }] });
  };
  handleRemoveEmail = (i) => {
    this.props?.parentChangeCb && this.props?.parentChangeCb();
    this.setState({ emails: removeFromArray(this.state.emails, i) });
  };
  constructor(props) {
    super(props);
    this.state.emails = this.props.emails.map((s, i) => { const prevIndex = this.props.originEmails.findIndex((os) => os === s); return { value: s, _id: Math.random(), previousValue: prevIndex  === -1 ? "newValue" : this.props.originEmails[prevIndex] }});
  }

  render() {
    const { floatingLabelStyle, floatingLabelFocusStyle, floatingLabelShrinkStyle, inputStyle, hintText, floatingLabelText } = this.props;
    return (<div className={sn.email_edit_wrapper}>
      <div className={sn.email_edit}>
        <FormsyText
          className={sn.wrapper_email}
          hintText={hintText ? hintText : "E-mail*"}
          floatingLabelText={floatingLabelText ? floatingLabelText : "E-mail*"}
          validations="isEmail"
          validationError="please enter valid email"
          requiredError="required"
          name={`${this.props.name}[0]`}
          required
          fullWidth
          hintStyle={hintStyle}
          floatingLabelStyle={floatingLabelStyle}
          floatingLabelFocusStyle={floatingLabelFocusStyle}
          floatingLabelShrinkStyle={floatingLabelShrinkStyle}
          inputStyle={inputStyle}
          defaultValue={this.props.emails[0] || ''}
          previousValue={this.props.originEmails ? this.props.originEmails[0] || '' : null}
        />
        <div className={sn.add_email_button}>
          <span className={sn.add_phone_button} onClick={this.handleAddEmail}>
            <ContentAdd />
          </span>
        </div>
      </div>
      {this.state.emails.slice(1).map((email, i) => {
        return (
          <div key={email._id} className={sn.email_edit}>
            <FormsyText
              className={sn.wrapper_email}
              hintText="E-mail:"
              floatingLabelText="E-mail:"
              validations="isEmail"
              validationError="please enter valid email"
              requiredError="required"
              name={`${this.props.name}[${i + 1}]`}
              required
              fullWidth
              hintStyle={hintStyle}
              floatingLabelStyle={floatingLabelStyle}
              floatingLabelFocusStyle={floatingLabelFocusStyle}
              floatingLabelShrinkStyle={floatingLabelShrinkStyle}
              inputStyle={inputStyle}
              // defaultValue={this.props.originEmails ? this.props.emails[i + 1] || '' : this.state.emails[i] && this.state.emails[i].value}
              // previousValue={this.props.originEmails ? this.props.originEmails[i + 1] || '' : null}
              defaultValue={email.value}
              previousValue={email.previousValue || '' }
            />
            <div className={sn.add_email_button}>
              <span className={sn.add_phone_button} onClick={this.handleRemoveEmail.bind(this, i + 1)} >
                <ContentRemove />
              </span>
            </div>
          </div>
        );
      })}

    </div>);
  }
}

export const Phone = withStyles(sn)(Phones);

export const Email = withStyles(sn)(Emails);
