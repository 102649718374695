import produce from 'immer';
import { v4 } from 'uuid';
import { populateAutotagCategory } from './PROFILE_GET_AUTOTAGS';

/** @name PROFILE_SAVE_AUTOTAG_REDUCER */
export default function PROFILE_SAVE_AUTOTAG(state, { payload, res }) {
  const { entity, autotag } = payload;
  return produce(state, draft => {
    const index = draft.autoTags[entity].findIndex(at => at._id === autotag._id);
    draft.autoTags[entity][index] = populateAutotagCategory(state, { ...res.data.autoTags, _id: res.data._id });
  });
}
