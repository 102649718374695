import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from './Cargo.scss';
import { sendRequest } from '../../actions/cargo';
import { staticPopup } from '../../actions/runtime';
import history from '../../core/history';
import mergeTerms from './mergeTerms';
import Request from './Request';
import { grabErrorMessage } from '../../core/utils';
import CargoHeader from './CargoHeader';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import Contract from './../../core/api/Contract';
import { createPreviewAdapter, createServerAdapter } from './adapters';
import DocumentPreview from './Preview';
import { withConfirmationDialog } from '../../core/HOC';

const titles = {
  edit: 'MY CARGO: EDIT REQUEST',
  create: 'MY CARGO: NEW REQUEST',
};
class AddForm extends Component {
  static propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    sendRequest: PropTypes.func,
  };

  static contextTypes = {
    cargoTypes: PropTypes.array,
    voyageTerms: PropTypes.array,
    showMessage: PropTypes.func,
    blockTransition: PropTypes.func,
  };
  // type determines routes transition (e.g. to edit, back, etc...)
  // can be "voyage" or "bookingnote" for now
  static defaultProps = {
    previewAdapter: createPreviewAdapter(),
    serverAdapter: createServerAdapter(),
    type: 'voyage',
  }

  state = {
    titles: {
      portsTitle: 'PORTS & DATES',
    },
    termsCounter: 0,
    tags: [],
    previewErrors: null,
    request: {
      contract: this.props.defaultContractPDF,
    },
  };
  fields = {};
  // Request component ref to manipulate form steps
  _requestElement = null;

  constructor(props, context) {
    super(props);
    this._form = props.cargo;

    this.state.title = titles[props.actionType];

    if (this._form) {
      //TODO if edit send to another route and add _id
      this._form.cargo.forEach(c => (c._id = undefined));

      const cargoType = context.cargoTypes.find(
        ct => ct._id === this._form.cargoType._id,
      );

      this._form.cargoType = this._form.cargoType._id;
      this._form.contract = this._form.contract._id;
      this.state.cargoType = cargoType;
      this._form.terms = mergeTerms(this._form.terms, this._form.terms);
    }

    if (props.user && props.user.company && props.user.company.settings && props.user.company.settings.voyage) {
      this.state.voyageSettings = props.user.company.settings.voyage;
      this.state.voyageSettings.defaultContract = this.state.voyageSettings.contracts.find(c => c.default);
    }
    this.props.refEl(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.errors &&
      this.props.errors !== nextProps.errors &&
      nextProps.errors.length
    ) {
      this.handleClosePreview();

      return;
    }

    if (nextProps.error && this.props.error !== nextProps.error) {
      this.handleClosePreview();
    }

    if (this.props.hash === '#preview' && nextProps.hash !== '#preview') {
      this.props.unblockAndBlockTransition?.();
      this.setState({ preview: undefined });
    }
  }

  // must return unblock function
  //blockHistory = () => this.unblock = history.block('Are you sure you want to leave this page?');

  componentDidMount() {

  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.delayVesselListUpdate);
    // if (typeof window !== 'undefined') {
    //   window.confirmTransition = undefined;
    //   this.unblock && this.unblock();
    // }
    // if (this.context.setBlockUnblock) {
    //   this.context.setBlockUnblock({});
    // }
    if (this._isDelayed === true) {
      this._isDelayed = false;
      PubSub.publish("monitor::updateList");
    }
  }


  handleSend = () => {
    this.fields.requestType.setValue('request');
  };

  handleSaveToTemplates = () => {
    this.fields.requestType.setValue('template');
  };

  handleSaveToDrafts = () => {
    this.fields.requestType.setValue('draft');
  };

  resetCargoId = cargo => {
    // if simple form recognition returned cargo without _id field, mock _id to fill cargo autocomplete but don't send cargoId to backend
    if (!cargo?.cargoName?.cargoWithoutId) {
      cargo.cargoId = cargo.cargoName && cargo.cargoName._id;
    }
    else {
      Reflect.deleteProperty(cargo.cargoName, "_id");
      Reflect.deleteProperty(cargo.cargoName, "cargoWithoutId");
    }
    return cargo;
  }

  handlePreview = (request, previewErrors) => {
    request = this.props.previewAdapter.adapt(request);

    this.setState({
      preview: request,
      previewErrors,
    });

    this.props.unblock && this.props.unblock();
    history.push('#preview');

    return request;
  };


  handleSubmit = (request) => {

    const adaptedRequest = this.props.serverAdapter.adapt(request);
    this.props.unblock && this.props.unblock();
    if (this.props.fromExchange) {
      if (this.props.mailId) {
        adaptedRequest.mailId = this.props.mailId;
      }
      if (this.props.cargo) {
        adaptedRequest.realCargoRequestId = this.props.cargo._id;
      }
    }

    return this.createNewOfflineContract(adaptedRequest);
  };

  createNewOfflineContract = async adaptedRequest => {
    try {
      const res = await Contract.createOfflineVoyageContract(adaptedRequest);

      this.context.showMessage({
        message: `Offline contract has been created.`,
      });
      PubSub.publish("documents::contractCreated");
      
      if (this.props.fromExchange) {
        history.push('#');
        this.setState({
          preview: undefined,
        });
        this.props.handleSuccess();
        return;
      }
      history.push(`/contracts/${this.props.type}`);

      this.props?.handleSuccess?.(adaptedRequest);
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: 'error',
        message: `Error creating offline contract: ${grabErrorMessage(error)}`,
      });
    }
  }

  closePreview = () => {
    this.props.unblockAndBlockTransition?.();
    history.push('#');

    this.setState({
      preview: undefined,
    });
  }

  handleClosePreview = () => {
   this.closePreview();

    if (this._requestElement) {
      this._requestElement.openStepWithInvalidInput();
    }
  };

  handleBackToSimpleForm = () => {
    this.closePreview();

    if (this._requestElement) {
      this._requestElement.openSimpleFormStep();
    }
  }

  handleClose = () => {
    if (this.props.user) {
      history.push('/my/cargo');
    } else {
      history.push('/');
    }
  };

  saveRequestRef = el => this._requestElement = el;

  onChange = request => {
    this.setState({
      request,
    });
  }

  render() {
      const { errors, cargo, loading, preambleEnabled = true } = this.props;

    const basicTitle = "MY CARGO: ";
    const title = this.props.title ? this.props.title(this.state.preview) : `${basicTitle}${this.state.preview ? "REQUEST PREVIEW" : "NEW REQUEST"}`;

    const headerProps = {
      title,
      handleClose: this.state.preview ? this.handleClosePreview : this.props.handleClose,
    };
    return (
      <div className={s.root} style={{ display: "flex", flexDirection: "column" }}>
        <CargoHeader {...headerProps} />
        <div className={s.container}>
          <div>
            <Request
              innerRef={this.saveRequestRef}
              styles={s}
              errors={errors}
              request={this.state.request}
              onChange={this.onChange}
              cargo={cargo}
              loading={loading}
              handlePreview={this.handlePreview}
              handleClosePreview={this.handleClosePreview}
              simpleFromPreviewEnabled={false}
              accountNameEnabled={false}
              preambleEnabled={preambleEnabled}
              fromExchange={this.props.fromExchange}
            />
          </div>

        </div>
        {this.state.preview
          ?
            <div className={s.preview_container} style={{ position: "absolute", top: "48px", left: "0px", right: "0px", zIndex: 1111, overflow: "auto", background: "#fff", bottom: "0px" }}>
              <DocumentPreview
                loading={this.props.loading}
                request={this.state.preview}
                onSend={this.handleSubmit}
                onClose={this.handleClosePreview}
                handleBackToSimpleForm={this.handleBackToSimpleForm}
                cancelLabel={'BACK TO EDIT'}
                sendLabel={'CREATE'}
                errors={this.state.previewErrors}
              />
            </div>
          : null}
        {/* {this.state.confirmTransitionDialog
          ? this.state.confirmTransitionDialog
          : null} */}

      </div>
    );
  }
}

export default connect(state => ({ ...state.cargo, user: state.login.user }), {
  sendRequest,
  staticPopup,
})(withStyles(s)(withConfirmationDialog({ style: { height: '100%' } })(AddForm)));
