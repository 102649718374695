/** @name MONITOR_UPDATE_VESSEL_LOCATION_REDUCER */
import { replaceInArray } from '../../core/utils';

// CHANGES NOT ONLY LOCATION, BUT SELECTED FIELDS IN VESSEL.

export default function MONITOR_UPDATE_VESSEL_LOCATION(state, { payload }) {
  const newState = {};
  const index = state.vesselList.data.findIndex(
    vg => vg.vessel === payload._id,
  );
  if (index === -1) {
    return state;
  }
  const vesselGroup = { ...state.vesselList.data[index], ...payload.vessel };
  newState.vesselList = { ...state.vesselList };
  newState.vesselList.data = replaceInArray(
    newState.vesselList.data,
    index,
    vesselGroup,
  );
  return { ...state, ...newState };
}
