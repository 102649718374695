const defaultState = {
  settings: { unitSystem: 'imperial' },
  ldTerms: { terms: [], updatedBy: { name: '---', updatedAt: undefined } },
  calculatorList: { data: [], filters: {}, page: 1, isLoading: true },
};
import reducers from './calculatorReducers';

export default function reducer(state = defaultState, action) {
  try {
    if (reducers[action.type]) {
      return reducers[action.type].reducer(state, action);
    } else {
      return state;
    }
  } catch (e) {
    console.error(e);
    return state;
  }
}
