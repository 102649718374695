import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AutoComplete from './AutoComplete';
class CountryAutocomplete extends AutoComplete {
  static contextTypes = {
    countries: PropTypes.array,
    formsy: PropTypes.object,
  };
  static defaultProps = {
    forceSelection: true,
  };
  constructor(props, context) {
    super(props, context);
    this.Api = {
      find: async function(query) {
        query = query.name.toLocaleLowerCase();
        return {
          data: this.context.countries.filter(
            country => country.name.toLocaleLowerCase().indexOf(query) !== -1,
          ),
        };
      }.bind(this),
    };
  }
}

export default CountryAutocomplete;
