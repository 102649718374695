import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { WithUserUploads } from '../../../core/HOC';
import UploadedFileExtensionErr from '../../Common/UploadedFileExtensionErr';
import SpinnerButton from '../../Common/SpinnerButton';
import LoadableEditor from '../../Common/HtmlEditor/LoadableEditor';
import { Form, Formik } from 'formik';
import MailHeadersEdit from '../../Common/Share/MailHeadersEdit';
import { v4 } from 'uuid';
import {date, dateStringWithTime, escapeHtml} from "../../../core/format";
import {flattenAliases} from "../utils";
import {extractEmail, getAttachmentAProps} from "../../../core/utils";
import LoadableCKEditor from "../../Common/HtmlEditor/LoadableCKEditor";

const MAX_ATTACHMENTS_SIZE = 25;

const StyleWrapper = styled.div`

  margin-top: 8px;
  .editor-wrapper .ql-container {
    border-radius: 0 0 8px 8px !important;
    border-top: none !important;
  }
  .mail-headers-wrapper {
    border: 1px solid #d2d2d2;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
  }
  .mail-headers-wrapper > div > div > div{
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 0 8px;
  }
  .mail-headers-wrapper > div > div:not(:first-child) > div > div:first-child {
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 0;
  }
  .files {
    padding: 8px;
    border-left: 1px solid var(--stroke-light-gray1);
    border-right: 1px solid var(--stroke-light-gray1);
    > div {
      display: flex;
      align-items: center;
      > span:nth-child(1){
        transform: rotate(45deg);
        font-size: 20px;
        color: var(--text-medium);
      }
      > span:nth-child(2){
        flex: 1;
        margin-left: 4px;
      }
      > span:nth-child(3){
        font-size: 20px;
        cursor: pointer;
      }

    }
    a {
      word-break: break-all;
    }
    .upload_error {
      margin: 8px 0 0 0;
    }
  }
  .ck-editor__main {
    & > div:first-child {
      border-top: none !important;
      box-shadow: none !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .sticky-toolbar-wrapper {
    position: sticky;
    bottom: 0;
    padding-bottom: 48px;
    background: white;
    z-index: 1;
  }
  .ck.ck-editor__editable_inline.ck-editor__editable_inline {
    border-bottom: none!important;
    border-radius: 0!important;
  }
  .toolbar-wrapper {
    display: flex;
    max-height: 46px;
    font-size: 12px;
    justify-content: space-between;
    border: 1px solid var(--stroke-light-gray1);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;


    .editor-toolbar {
      .ck.ck-toolbar {
        border: none;
        padding: 6px 3px;
        background: none !important;
        .ck-toolbar__items {
          .ck.ck-button.ck-off, a.ck.ck-button.ck-off {
            .ck.ck-icon.ck-icon_inherit-color {
              color: #999999;
            }
          }
          .ck.ck-dropdown.ck-font-size-dropdown {
            .ck.ck-button.ck-on {
              color: var(--ck-color-list-button-on-text);
            }
          }
          .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
            color: var(--ck-color-button-on-color);
          }
        }
        .ck-button-preview {
          margin-left: auto;
          color: var(--text-links);
          text-decoration: underline;
        }
      }
    }
  }

`


const Buttons = styled(({ className, onSend, onClear, sendDisabled }) => {
  return (<div className={className}>
    <SpinnerButton secondary label={'CLEAR'} onClick={onClear}/>
    <SpinnerButton primary label={'SEND'} onClick={onSend} disabled={sendDisabled}/>
  </div>);
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;

  > div + div {
    margin-left: 8px;
  }
`;


class ComposeReply extends PureComponent {

  static defaultProps = {
    headersProps: {},
  }
  state = { uploadedFiles: [], uploadsKey: 1, totalAttachmentsSize: 0 };

  constructor(props, context) {
    super(props, context);
    this.editorToolbarId = 'editor-toolbar-' + v4();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.replyTo) {
      if (nextProps.replyTo.id !== this.props.replyTo?.id || nextProps.replyTo.actionType !== this.props.replyTo?.actionType) {
        if (nextProps.replyTo.actionType === "forward") {
          this.form.resetForm(this.mapInitialValues(nextProps.replyTo, nextProps.subject));
          this.form.setFieldValue('to', [], false);
          const attachments = Array.isArray(nextProps.replyTo.attachments) ? nextProps.replyTo.attachments : [...nextProps.replyTo.attachments.sent, ...nextProps.replyTo.attachments.received];
          this.setState({ uploadedFiles: attachments, totalAttachmentsSize: this.calculateTotalAttachmentsSize(attachments) });
        } else {
          this.form.resetForm(this.mapInitialValues(nextProps.replyTo, nextProps.subject));
          this.setState({ uploadedFiles: [], totalAttachmentsSize: 0 });
        }
        return;
      }
      if (this.props.replyTo?.replyToAll !== nextProps.replyTo.replyToAll) {
        const initialValues = this.mapInitialValues(nextProps.replyTo, nextProps.subject);
        this.form.setFieldValue('cc', initialValues.cc, false);
        this.setState({ uploadedFiles: [], totalAttachmentsSize: 0 });
      }
    }
  }


  handleUploadedFiles = (validatedFiles, uploadedFiles) => {
    const newFiles = [...this.state.uploadedFiles, ...uploadedFiles];
    this.setState({ uploadedFiles: newFiles, totalAttachmentsSize: this.calculateTotalAttachmentsSize(newFiles) }, () => {
      setTimeout(() => {
        const lastFile = newFiles[newFiles.length - 1];
        if (lastFile) {
         const el = document.getElementById('file-' + lastFile._id);
         if (el) {
           el.scrollIntoView();
         }
        }

      });
    });
  }

  handleDeleteUploadedFile = (_id) => {
    const newFiles = this.state.uploadedFiles.filter(file => file._id !== _id);
    this.setState({ uploadedFiles: newFiles, totalAttachmentsSize: this.calculateTotalAttachmentsSize(newFiles) });
  }

  getUploadedFiles = () => {
    return this.state.uploadedFiles;
  }

  clearUploadedFiles = () => {
    this.setState({ uploadedFiles: [], uploadsKey: this.state.uploadsKey + 1, totalAttachmentsSize: 0 });
  }

  calculateTotalAttachmentsSize = (files) => {
    return (files.reduce((totalSize, file) => totalSize + file.size, 0)) / (1024 * 1024);
  }

  refAttachInput = (input) => {
    this.attachInput = input;
  }
  startAttach = () => {
    if (this.state.totalAttachmentsSize >= MAX_ATTACHMENTS_SIZE) {
      return;
    }
    this.attachInput.click();
  }

  mapInitialValues = (replyTo, subject = this.props.subject) => {
    const replyTemplate = replyTo.replyTemplate || {};
    // let availableGates = replyTemplate.replyGates;
    let availableGates = this.props.mailGatesState.senderGates;
    if (this.props.mailGatesState?.own?.length) {
      availableGates = [...availableGates, ...this.props.mailGatesState.own.map(gs => ({ _id: gs._id, address: gs.receiver.username, broken: true }))];
    }
    availableGates = flattenAliases(availableGates);
    let cc = replyTemplate.cc || [];
    if (replyTo.replyToAll) {
      const ownEmails = availableGates.map(ag => ag.address.toLocaleLowerCase());
      let from = replyTo.from;
      if (replyTo.shouldReplyTo?.length) {
        from = replyTo.shouldReplyTo;
      }
      cc = [...from, ...replyTo.to, ...replyTo.cc];
      const emailsToExclude = [...ownEmails, ...replyTemplate.to.map(extractEmail)];
      cc = cc.filter(email => {
        email = extractEmail(email);
        return emailsToExclude.indexOf(email.toLocaleLowerCase()) === -1;
      });
    }
    const { cc: defaultCc = [], bcc: defaultBcc = [], replyTo: defaultReplyTo = [] } = this.findDefaultGate(replyTo, availableGates);
    const values = {
      to: this.mapEmailsToChips(replyTemplate.to),
      cc: this.mapEmailsToChips([...cc, ...defaultCc]),
      bcc: this.mapEmailsToChips(defaultBcc),
      replyTo: this.mapEmailsToChips(defaultReplyTo),
      subject: subject,
      availableGates: availableGates,
      from: this.findDefaultGate(replyTo, availableGates),
    };
  return values;
  }

  findDefaultGate(reply, availableGates){
    let gate;
    const replyGates = availableGates || reply.replyTemplate?.replyGates || [];
    const fields = ['to', 'cc', 'bcc'];
    if (this.props.lastUsedAlias) {
      const email = extractEmail(this.props.lastUsedAlias);
      gate = replyGates.find(g => g.address === email);
      if (gate) {
        return gate;
      }
    }
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      for (let j = 0; j < reply[field].length; j++) {
        const email = reply[field][j];
        gate = replyGates.find(g => g.address === email);
        if (gate) {
          return gate;
        }
      }
    }
    replyGates.sort((a, b) => {
      if (a.enabled) {
        return -1;
      }
      return 0;
    });
   return replyGates[0];
  }

  mapEmailsToChips = (emails = []) => {
    return emails.map(email => ({ _id: v4(), email }));
  }

  refForm = (form) => {
    this.form = form;
  }
  refEditor = (editor) => {
    this.editor = editor;
  }

  quoteReplyTo = (replyTo) => {
    this.editor.insertQuote(replyTo, this.props.user.signature?.value || '');
  }
  handleFromChange = ({ cc = [], bcc = [], replyTo = [] }) => {
      this.form.setFieldValue("cc", this.mapEmailsToChips(cc));
      this.form.setFieldValue("bcc", this.mapEmailsToChips(bcc));
      this.form.setFieldValue("replyTo", this.mapEmailsToChips(replyTo));
  }
  quoteForward = (forward) => {
    this.editor.insertHtml(forward, this.props.user.signature?.value || '');
  }

  render() {
    const { id, className, text = "", to, onTextChange, replyTo, onReplyToRefClick, onClearReply, onSendReply, subject, isNotNew = false, ...restProps } = this.props;
    const remainingSize = 25 - this.state.totalAttachmentsSize;
    return (
      <WithUserUploads key={this.state.uploadsKey} shouldUpload onSuccess={this.handleUploadedFiles} maxFiles={Number.MAX_VALUE} privateAttachment>
        {({ erroredFiles, validateUserUploads, uploadedFiles, accept, uploading }) => {
        return (
          <StyleWrapper id={id} className={className}>
            <Formik initialValues={this.mapInitialValues(replyTo, subject)} onSubmit={onSendReply} ref={this.refForm}>
              {(formik) => <Form>
                <div className="mail-headers-wrapper">
                <MailHeadersEdit isNotNew={isNotNew} replyTo={replyTo} handleFromChange={this.handleFromChange} values={formik.values} fromList={formik.values.availableGates} inreplyTo={replyTo?.refNo ? (<div>IN REPLY: <a onClick={onReplyToRefClick}>{replyTo.refNo}</a></div>) : null} {...this.props.headersProps} />
                </div>
                <div className="editor-wrapper">
                  <LoadableCKEditor
                  onChange={onTextChange}
                  onAttachClick={this.startAttach}
                  refEditor={this.refEditor}
                  signature={this.props.user.signature?.value}
                  scrollingContainer={this.props.scrollingContainer}
                  toolbarId={this.editorToolbarId}
                  />
                </div>
                <div>
                  <div className={"files"}>
                    {this.state.uploadedFiles.map(file => {
                      return (<div key={file._id} id={'file-' + file._id}>
                        <span className="material-icons-outlined">
                          attach_file
                        </span>
                        <span><a {...getAttachmentAProps(file)}>{file.name}</a></span>
                        <span onClick={this.handleDeleteUploadedFile.bind(this, file._id)} className="material-icons-outlined">
                          close
                        </span>
                      </div>);
                    })}
                    <div>{erroredFiles.length || (remainingSize <= 0) ? <UploadedFileExtensionErr className={'upload_error'} accept={accept} erroredFiles={erroredFiles} remainingSize={remainingSize} /> : null}</div>
                  </div>
                </div>
                <div className={'sticky-toolbar-wrapper'}>
                  <div>
                    <label>
                      <input
                        style={{ display: "none" }}
                        multiple
                        type="file"
                        accept={accept}
                        onChange={ev => validateUserUploads(ev, true)}
                        ref={this.refAttachInput}
                      />
                    </label>
                  </div>
                  <div className={'toolbar-wrapper'}>
                    <div className={'editor-toolbar'} id={this.editorToolbarId} />
                    {((text || this.state.uploadedFiles.length) && formik.values.to?.length) ? <Buttons disabled={uploading} onClear={onClearReply} onSend={() => onSendReply(formik.values)} sendDisabled={formik.values.from?.broken} /> : null}
                  </div>
                </div>


              </Form>}
            </Formik>
          </StyleWrapper>
        );
      }}
      </WithUserUploads>

    );
  }
}

export default ComposeReply;
