import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Loader.scss";
import cx from "classnames";

function LoaderNew({
  classes = "",
  className = "",
  size = 80,
  thickness = 5,
  opaque = false,
  transparent = false,
  isDark = false
}) {
  return (
    <div className={cx(s.wrapper, isDark ? s.dark : "")}>
      <div
        className={cx(
          classes,
          className,
          s.loader,
          isDark ? s.dark : "",
          opaque ? s.opaque : "",
          transparent && s.transparent
        )}
        style={{ width: size, height: size, borderWidth: thickness }}
      />
    </div>
  );
}

export default withStyles(s)(LoaderNew);
