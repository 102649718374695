/** @name USER_STATS_FAILED_REDUCER */
export default function USER_STATS_FAILED(state, action) {
    const newState = { statistics: {
          ...state.statistics,
          errors: action.error.message.errors,
          loading: false
        } 
      };
    
    return { ...state, ...newState };
  }
