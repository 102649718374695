import * as constants from '../constants/port';
import Port from '../core/api/Port';

export function getChanges(_id) {
  return {
    type: constants.PORT_GET_CHANGES,
    payload: { _id },
    promise: Port.getChanges(_id),
  };
}

export function updatePort(port) {
  return {
    type: constants.PORT_UPDATE,
    payload: port,
    promise: Port.updatePort(port),
    successMessage: `Modifications sent`,
  };
}

export function upsertProvider(port, provider) {
  return {
    type: constants.PORT_UPSERT_PROVIDER,
    payload: { port, provider },
    promise: Port.upsertProvider(port, provider),
  };
}

export function getList({ page, limit, filters }) {
  return {
    type: constants.PORT_GET_LIST,
    payload: { page: page + 1, limit, filters },
    promise: Port.getList(page + 1, limit,filters),
    takeLatest: true,
  };
}

export function create(port) {
  return {
    type: constants.PORT_CREATE,
    payload: port,
    promise: Port.create(port),
    successMessage: `Port ${port.name} added`,
  };
}
export function setPortDialogId(ids) {
  return {
    type: constants.SET_PORT_DIALOG_ID,
    payload: ids,
  };
}

