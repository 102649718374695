import React, { Component } from 'react';

const loadable = (factory, fallback = null) => {
  return class _LazyLoaded extends Component {

    static defaultProps = {
      shouldRender: true,
    }

    state = {
      Component: null,
    }

    async componentWillMount() {
      try {
        const module = await factory();

        if (module?.default) {
          this.setState({
            Component: module.default,
          })
        }
      } catch (error) {
        console.error(error);
      }
    }

    render() {
      const { Component } = this.state;
      const { shouldRender, ...rest } = this.props;
      // TODO: allow for fallback prop
      if (!Component) return fallback;

      return shouldRender && <Component {...rest} />;
    }

  }
}

export default loadable;
