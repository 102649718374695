import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Play_video = props => (
  <svg width="113" height="113" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="56.5" cy="56.5" r="54" fill="#285596" stroke="white" strokeWidth="5"/>
    <path d="M75.04 56.5L47.23 71.3668L47.23 41.6333L75.04 56.5Z" fill="white"/>
  </svg>
);

Play_video = pure(Play_video);
Play_video.displayName = 'Play_video';
Play_video.muiName = 'SvgIcon';

export default Play_video;
