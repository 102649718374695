import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../Home.scss';
import Link from '../../Link/Link';
import cx from 'classnames';

class SolutionsCargoMonitor extends Component {
  render() {
    return (
      <div className={s.solution}>
        <div className={s.tab_block}>
          <div className={s.left_part}>
            <div className={s.wrapper_screen}>
              <img src="/images/solutions_screen/cargo_monitor1.png" alt="Shipnext Cargo Monitor" />
            </div>
          </div>
          <div className={s.right_part}>
            <h2>
              <span className={s.title}>SHIPNEXT Cargo Monitor</span>
            </h2>
            <div>
              <p>Cargo Monitor is a solution for tracking and managing negotiations, quotations, and cargo-related workflow. With the advanced AI-based algorithms in place, SHIPNEXT allows extracting valuable data that can help automate CRM and KPIs.</p>
              <p>Once the Cargo is booked, SHIPNEXT Cargo Monitor can be used for cargo tracking and supply chain management. Cargo Monitor brings satellite data, and can also be used to build interconnectivity with other sources of operational information flow.</p>
              <p>Cargo Monitor can be white-labelled to meet the needs of larger companies, for better customisation and “look and feel” experience, and upgraded with customized features which may not yet be available.</p>
            </div>
            <div className={s.wrapper_buttons_tab}>
              <Link to={'/solution-shipnext-cargo-monitor'} className={s.learn_more}>Learn more</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(s)(SolutionsCargoMonitor);
