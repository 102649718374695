import React, { PureComponent } from 'react';
import CargoApi from "../../core/api/Cargo";
import {dateTime, dateTimeShiftedToLocal} from "../../core/format";
import OriginalMessageHtml from "../Emails/shared/OriginalMessageHtml";
import Loader from "../Common/Loader";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  min-height: 120px;
  position: relative;
`;

function emailObjToString(eo) {
  if (!eo.name) {
    return eo.address;
  }
  return `${eo.name} <${eo.address}>`;
}

export default class OriginalMessage extends PureComponent {
  state = {};

  static defaultProps = {
    apiLoad: CargoApi.getOriginalMail.bind(CargoApi),
  }

  componentDidMount() {
    this.handleToggleMessage();
  }


  loadMessage = async () => {
    if (this.state.loading || this.state.originalMsg) {
      return;
    }
    const {apiLoad} = this.props;
    try {
      this.setState({loading: true});
      const res = await apiLoad(this.props._id);
      this.setState({loading: false, originalMsg: this.mapToExchangeFormat(res.data)});
      return res;
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
      });
    }
  }

  mapToExchangeFormat = (origMessage) => {
    const {_id, sender, context, subject, text, html, date, attachments = [], exchangeRefNo} = origMessage;
    let { from = [], to = [], cc = [], bcc = [], replyTo = [] } = origMessage;
    from = from.map(emailObjToString);
    to = to.map(emailObjToString);
    cc = cc.map(emailObjToString);
    bcc = bcc.map(emailObjToString);
    replyTo = replyTo.map(emailObjToString);
    return {
      ...origMessage,
      from,
      to,
      subject,
      cc,
      bcc,
      shouldReplyTo: replyTo,
      refNo: exchangeRefNo,
      text,
      html: html || `<pre>${text}</pre>`,
      receivedAt: date,
      attachments,
      originalAttachments: attachments,
    };
  }

  dateRenderer = (message) => (<div>{dateTimeShiftedToLocal(message.receivedAt)}</div>)

  actionButtonsRenderer = () => null

  handleToggleMessage = async () => {
    try {
      const res = await this.loadMessage();
      this.setState({opened: !this.state.opened});
    } catch (error) {
      console.error(error);
      this.setState({opened: false})
    }
  };

  render() {
    return (<Wrapper className={this.props.className}>
      {this.state.opened && this.state.originalMsg ?
        <OriginalMessageHtml
          message={this.state.originalMsg}
          dateRenderer={this.dateRenderer}
          actionButtonsRenderer={this.actionButtonsRenderer}
        />
        : null}
      {this.state.loading ? <Loader transparent /> : null}
    </Wrapper>);
  }
}


