import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormsyToggle from 'formsy-material-ui/lib/FormsyToggle';
import muiThemeable from 'material-ui/styles/muiThemeable';
const toggleStyles = {
  thumbOff: {
    backgroundColor: 'white',
    left: '5px',
    width: '10px',
    height: '10px',
    top: '6px',
    boxShadow: 'none',
  },
  trackOff: {
    backgroundColor: '#D8D8D8',
    border: '1px solid rgb(189, 189, 189)',
    width: '28px',
  },
  thumbSwitched: {
    backgroundColor: 'white',
    width: '10px',
    height: '10px',
    top: '6px',
    boxShadow: 'none',
    left: '18px',
  },
  trackSwitched: {
    width: '28px',
  },
  rippleStyle: {
    display: 'none',
  },
};
class Toggle extends Component {
  state = {};
  handleChange = (e, value) => {
    this.setState({ checked: value });
    this.props.onChange && this.props.onChange(e, value);
  };
  constructor(props) {
    super(props);
    if (this.props.defaultValue) {
      this.state.checked = true;
    }
    if (this.props.value !== void 0) {
      this.state.checked = !!this.props.value;
    }
  }
  componentDidMount() {
    setTimeout(() => {
      if (this.refs.input) {
        const value = this.refs.input.getValue();
        if (this.state.checked !== value) {
          this.setState({ checked: value });
        }
      }
    }, 0);
  }
  getValue() {
    return this.state.checked;
  }
  setValue(value) {
    return this.handleChange(null, value);
  }

  _setValue(value) {
    this.setState({ checked: value });
  }

  render() {
    let { className = '', previousValue, ...rest } = this.props;
    className = className + (this.state.checked ? ' checked' : '');
    if (previousValue !== void 0 && previousValue !== this.state.checked) {
      className += ' toggle dirty';
    }
    console.log(`1px solid ${this.props.muiTheme.palette.primary1Color}`)
    return (
      <FormsyToggle
        value={this.state.checked}
        className={className}
        {...rest}
        onChange={this.handleChange}
        thumbStyle={toggleStyles.thumbOff}
        trackStyle={toggleStyles.trackOff}
        thumbSwitchedStyle={toggleStyles.thumbSwitched}
        trackSwitchedStyle={{
          ...toggleStyles.trackSwitched,
          backgroundColor: this.props.muiTheme.palette.primary1Color,
          border: `1px solid ${this.props.muiTheme.palette.primary1Color}`
        }}
        rippleStyle={toggleStyles.rippleStyle}
        ref="input"
      />
    );
  }
}

Toggle.propTypes = {};
Toggle.defaultProps = {};

export default muiThemeable()(Toggle);
