import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Collapse from '../Collapse';
import Comments from '../../Vessel/Preview/Comments';
import s from '../RatingBlock.scss';
import TextArea from '../../Monitor/Edit/TextArea';
import { CommentFooter } from '../RatingBlock';
import ExtendedForm from '../ExtendedForm';
import { connect } from 'react-redux';
import { saveComment, toggleSubscribeDialog, saveContactComment } from '../../../actions/login';
import { isUserSubscribedToMarketplace } from '../../../core/utils';
import PubSub from 'pubsub-js';

export class _CommonComments extends PureComponent {
  static propTypes = {
    entityType: PropTypes.string,
    entity: PropTypes.object,
    saveComment: PropTypes.func,
    toggleSubscribeDialog: PropTypes.func,
    user: PropTypes.object,
    publicToggleEnabled: PropTypes.bool,
  }

  static defaultProps = {
    publicToggleEnabled: true,
  }

  state = { comment: '', total: null, isPrivateComment: true };
  setComment = val => {
    this.setState({
      comment: val,
    });
  }
  clearComment = () => {
    this.setState({
      comment: '',
    });
  }
  saveComment = async () => {
    if (!isUserSubscribedToMarketplace(this.props.user)) {
     return this.props.toggleSubscribeDialog(true);
    }
    let res;
    if (this.props.isContactComment) {
       res = await this.props.saveContactComment({
        entityId: this.props.entity.id,
        entityType: this.props.entityType,
        text: this.state.comment,
        isPrivate: this.state.isPrivateComment,
      });
      if (res.data) {
      PubSub.publish(`${this.props.entityType}:commentsUpdated`, res.data);
      this.setState({
        comment: '',
      });
    }
    } else {
       res = await this.props.saveComment({
        entityId: this.props.entity._id,
        entityType: this.props.entityType,
        text: this.state.comment,
        isPrivate: this.state.isPrivateComment,
      });
      if (res.status === 200) {
        PubSub.publish(`${this.props.entityType}:commentsUpdated`, res.data);
        this.setState({
          comment: '',
        });
      }
    }


  }
  changePrivate = (val) => {
    this.setState({ isPrivateComment: val });
  }
  setTotal = data => {
    if (data && data.hasOwnProperty("total")) {
      this.setState({
        total: data.total
      })
    }
  }

  renderHeader() {
    return (
      <div style={{width: '100%'}}>
        <ExtendedForm>
            <TextArea
              validations="minLength:1,maxLength:1000"
              validationError="1 - 1000 characters"
              style={{ resize: 'none', marginBottom: '12px', borderRadius: "8px" }}
              name="comments"
              placeholder="Comment"
              value={this.state.comment}
              classes={s.focus_textarea}
              onChange={this.setComment}
            />
          </ExtendedForm>
          <CommentFooter
            horizontalPosition={'right'} whiteArrow={false} dark
            selectedtoggle={this.state.isPrivateComment}
            changeToggle={this.changePrivate} positionInsideTransform={false} offset={{ x: 8, y: 0 }} alertClasses={''}
            comment={this.state.comment}
            showButtons
            onClear={this.clearComment}
            onSave={this.saveComment}
            publicToggleEnabled={this.props.publicToggleEnabled}
          />
      </div>
    )
  }

  render(): React.ReactNode {
    const { comment, total } = this.state;
    const { isContactComment = false } = this.props;
    return (
      <div>
        <Collapse
          key={1}
          defaultOpen
          title={`COMMENTS ${total ? ` (${total}) ` : ""}`}
        >
          {
            this.renderHeader()
          }
          <Comments isContactComment={isContactComment} commentsLoaded={this.setTotal} entityType={this.props.entityType} entity={this.props.entity} publicToggleEnabled={this.props.publicToggleEnabled}/>
        </Collapse>
      </div>
    );
  }
}


export default connect(state => state.login, { saveComment, toggleSubscribeDialog, saveContactComment })(_CommonComments);
