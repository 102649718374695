/**@see  {@link INVOICES_GET_LIST_REDUCER}*/
export const INVOICES_GET_LIST = 'INVOICES_GET_LIST';
/**@see  {@link INVOICES_GET_LIST_REQUEST_REDUCER}*/
export const INVOICES_GET_LIST_REQUEST = 'INVOICES_GET_LIST_REQUEST';
/**@see  {@link INVOICES_SELECT_REDUCER}*/
export const INVOICES_SELECT = 'INVOICES_SELECT';
/**@see  {@link INVOICE_GET_REDUCER}*/
export const INVOICE_GET = 'INVOICE_GET';
/**@see  {@link INVOICE_GET_PREVIEW_REDUCER}*/
export const INVOICE_GET_PREVIEW = 'INVOICE_GET_PREVIEW';
/**@see  {@link INVOICE_CREATE_REDUCER}*/
export const INVOICE_CREATE = 'INVOICE_CREATE';
/**@see  {@link INVOICE_UPDATE_REDUCER}*/
export const INVOICE_UPDATE = 'INVOICE_UPDATE';
/**@see  {@link INVOICE_DELETE_REDUCER}*/
export const INVOICE_DELETE = 'INVOICE_DELETE';
/**@see  {@link INVOICE_POST_ACTION_REDUCER}*/
export const INVOICE_POST_ACTION = 'INVOICE_POST_ACTION';

