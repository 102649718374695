import React from 'react'
import Camera from 'material-ui/svg-icons/action/camera-enhance';
import v from './VesselEdit.scss'
function VesselImg({images,uploadImage}) {
    return (
        <div className={v.img}>
            {images && images[0] ?
                <img src={images[0].url} alt="ship photo" />
                :
                (
                    <div className={v.mockup}>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.4">
                            <path d="M35.1882 36.5904C32.9146 36.5904 30.5972 35.8034 28.586 34.4042C24.5634 37.2462 19.404 37.2462 15.3815 34.4042C13.3702 35.8034 11.0966 36.5904 8.77926 36.5904H5.5V39.8696H8.77926C11.0529 39.8696 13.2828 39.3012 15.3815 38.2519C19.5352 40.3943 24.4322 40.3943 28.586 38.2519C30.641 39.3449 32.9146 39.8696 35.1882 39.8696H38.4675V36.5904H35.1882ZM8.73553 33.3111H8.82298C11.4464 33.3111 13.8074 31.8682 15.4252 30.0319C17.043 31.8682 19.3603 33.3111 22.0275 33.3111C24.6509 33.3111 27.0119 31.8682 28.6297 30.0319C30.2475 31.8682 32.5648 33.3111 35.2319 33.3111H35.3194L38.4237 22.2928C38.5549 21.8556 38.5112 21.4183 38.3363 21.0248C38.1177 20.6313 37.7679 20.3253 37.3306 20.1941L35.2319 19.4945V11.8866C35.2319 10.094 33.7453 8.60739 31.9527 8.60739H26.9682V3.66664H17.0867V8.60739H12.146C10.3533 8.60739 8.8667 10.094 8.8667 11.8866V19.4945L6.72426 20.1941C6.28702 20.3253 5.93723 20.6313 5.71862 21.0248C5.5 21.4183 5.45628 21.8993 5.63117 22.2928L8.73553 33.3111ZM12.146 11.8866H31.9089V18.4452L22.0275 15.2096L12.146 18.4452V11.8866Z" fill="white"/>
                            </g>
                        </svg>
                    </div>
                )
            }
            <div className={v.wrapper_attach_image}>
                <label htmlFor={'img'}>
                    <Camera
                        style={{
                            position: 'absolute',
                            right: '0',
                            bottom: '0',
                            zIndex: '2',
                            width: '32px',
                            height: '32px',
                            padding: '2px',
                            backgroundColor: '#7FC931',
                        }}
                        color={'#ffffff'}
                    />
                    <input onChange={uploadImage} name="img" id="img" type="file" accept="image/*"  />
                </label>
            </div>
        </div>
    )
}

export default VesselImg
