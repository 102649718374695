/** @name CARGO_REQUEST_LIST_REDUCER */
export default function CARGO_REQUEST_LIST(state, { res, payload }) {
  const newRequestList = { ...state.requestList };
  if (payload) {
    if (payload.filters) {
      const filters = { ...payload.filters, ...res.filters };
      if (filters.weightMin) {
        filters.weightMin /= 1000;
      }
      if (filters.weightMax) {
        filters.weightMax /= 1000;
      }
      newRequestList.filters = filters;
    }
  }
  if (res.page === 1) {
    newRequestList.data = res.data;
  } else {
    newRequestList.data = [...newRequestList.data, ...res.data];
  }
  newRequestList.page = res.page;
  newRequestList.total = res.total;
  newRequestList.next = res.next;
  newRequestList.loading = false;
  const newState = { requestList: newRequestList };
  return { ...state, ...newState };
}
