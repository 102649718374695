import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../../Pages/Pages.scss';

const roadMap = [
  {
    "date": "2009-01-01",
    "name": "2009 - 2015",
    "html": "Collecting Data-bases related to Ships, Ports, Cargo, Transportation infrastructure, as well as building crucial relationship with all industry platers and Cargo owners",
  },
  {
    "date": "2015-05-01",
    "name": "2015, May",
    "html": "SHIPNEXT - disruptive concept of an Automated Digital Shipping Marketplace is finalized",
  },
  {
    "date": "2017-10-01",
    "name": "2017, October",
    "html": "SHIPNEXT is launched in Phase A, as an Automated Digital Shipping Marketplace for non-containerized, general, oversized and dry-bulk cargo",
  },
  {
    "date": "2018-01-01",
    "name": "2018, January",
    "html": "SHIPNEXT is widely used by over 1000 international companies, contains 8000+ Ship positions and thousands of Cargo orders at any given moment",
  },
  {
    "date": "2019-01-01",
    "name": "2019, January",
    "html": "SHIPNEXT has over 2000 users, 2500 daily cargo/freight requests, and over 15.000 open ship positions at any given moment",
  },
  {
    "date": "2019-05-01",
    "name": "2019, May",
    "html": "First Smart Contract in Non-containerized Sea Transport",
  },
  {
    "date": "2019-06-01",
    "name": "2019, June",
    "html": "Agreement signed with PPL - “Single window” operator - on the future interoperability between SHIPNEXT and the Cargo and/or Vessel inward/outward clearance process",
  },
  {
    "date": "2019-08-01",
    "name": "2019, August",
    "html": "Preliminary agreement with Singapore and other ports on integration of their line-up and operational real-time data on SHIPNEXT",
  },
  {
    "date": "2019-09-01",
    "name": "2019, September",
    "html": "Partnership with Betatron for further expansion on Asian markets, resulting in the opening of a representative office in Hong Kong",
  },
  {
    "date": "2019-10-01",
    "name": "2019, October",
    "html": "Automated Quotation on General and Dry-bulk cargo",
  },
  {
    "date": "2019-11-01",
    "name": "2019, November",
    "html": "Strategic partnership agreement with Voyager, Bolero and other digital solutions for shipping and transportation for further facilitation of interoperability and digitalization",
  },
  {
    "date": "2020-02-01",
    "name": "2020, February",
    "html": "Wet-bulk Marketplace and further development of predictive analytics",
  },
  {
    "date": "2020-04-01",
    "name": "2020, April",
    "html": "Fleet rating and comparable analysis",
  },
  {
    "date": "2020-05-01",
    "name": "2020, May",
    "html": "SHIPNEXT Cloud and full compatibly and interoperability with all e-mail solutions",
  },
  {
    "date": "2020-08-01",
    "name": "2020, August",
    "html": "Predictive analytics and deep data-processing",
  },
  {
    "date": "2020-11-01",
    "name": "2020, November",
    "html": "Containerized cargo and transport solution integration for instant comparison of solutions and pricing",
  },
  {
    "date": "2021-03-01",
    "name": "2021, March",
    "html": "Online Booking solutions and further integration into supply-\n" +
      "chain management processes",
  },
  {
    "date": "2021-03-01",
    "name": "2021, March",
    "html": "First use cases of automated freight matching and cargo booking processes (criteria driven)",
  },
  {
    "date": "2021-03-01",
    "name": "2021, March",
    "html": "First use of Freight finance using SHIPNEXT Smart contract and electronic bill of lading, with further integration in Trade finance",
  },
  {
    "date": "2021-10-01",
    "name": "2021, October",
    "html": "First use cases of supply chain management integration and customized industrial test cases",
  },
  {
    "date": "2021-11-01",
    "name": "2021, November",
    "html": "Further integration with land and other modes of transport ",
  },
  {
    "date": "2022-02-01",
    "name": "2022, February",
    "html": "First integrated supply chain in container door to door transport",
  },
  {
    "date": "2022-04-01",
    "name": "2022, April",
    "html": "Cross-trade interoperability and cooperation between different supply chain users",
  },
  {
    "date": "2022-09-01",
    "name": "2022, September",
    "html": "Integration of Different modes of Transport into one Decentralized Transportation Network",
  },
  {
    "date": "2022-09-01",
    "name": "2022, September",
    "html": "Shipnext added an advanced emailing service, that enables users to use Shipnext as a A-to-Z platform for shipping, which now covers Emailing, Chat, Email-processing, freight-matching and facilitates freight negotiations, data-management, contract management and automation of workflow",
  },
  {
    "date": "2022-10-01",
    "name": "2022, October",
    "html": "First Multimodal transport shipment, with real-time evaluation and pricing, followed by smart-contract and real-time decentralized processing",
  },
  {
    "date": "2022-12-01",
    "name": "2022, December",
    "html": "Further integration and interconnectivity of independent supply chains into a decentralized global ecosystem",
  },
  {
    "date": "2022-12-01",
    "name": "2022, December",
    "html": "Average number of daily users on Shipnext surpasses 2000 exporters, traders, brokers, carrier and shipping companies",
  },
  {
    "date": "2023-06-01",
    "name": "2023, June",
    "html": "Shipnext added new features and made significant improvements to the algorithm to containerized freight and wet-bulk",
  },
  {
    "date": "2023-08-01",
    "name": "2023, August",
    "html": "First users - shipping companies and brokering firms - in Tanker trade joined Shipnext for emailing and communication, freight-matching and trade",
  },
];
function IsPastDate(item) {
  return new Date(item.date) <= new Date();
}

class RoadMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNumber: 10
    };
  }
  _handleOpenRoadMap = () => {
    this.setState(
      { showNumber: this.state.showNumber + 10 }
    );
  };
  render() {
    return (
      <div className={s.road_map}>
        <div className={s.container}>
          <div className={s.title}>
            <h2>Road Map</h2>
          </div>
          <div className={s.right_part}>
            <div className={s.wrapper_road_map} >
              {roadMap.slice(0, this.state.showNumber).map((item, i) => (
                <div
                  key={i}
                  className={IsPastDate(item) ? s.color_last : ''}
                >
                  <h3>{ item.name }</h3>
                  <p>{ item.html }</p>
                </div>
              ))
              }
            </div>
            {this.state.showNumber >= roadMap.length ?
              null
              :
              <button
                onClick={this._handleOpenRoadMap}
                className={s.btn_show_more}
              >
                Show more
              </button>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(RoadMap));
