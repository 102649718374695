import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import s from './Request.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {List, ListItem} from 'material-ui/List';
import {dateRange} from '../../../core/format';
import FormsyInput from '../../Common/FormsyInput';
import WindowOpenInterceptor from '../../Common/WindowOpenInterceptor';
import NarrowFormsyDate from '../../NewInputs/NarrowFormsyDate';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import NarrowPortAutocomplete from '../../NewInputs/NarrowPortAutocomplete';
import LayCanTypeSelect, {formatLaycanType} from "../../Common/LayCanTypeSelect";

const texts = {
  pol: {
    hintText: "Port/Place of loading*",
    floatingLabelText: "Port/Place of loading*",
    errorText: 'Define port/place of loading.',
  },
  pod: {
    hintText: "Port/Place of discharging*",
    floatingLabelText: "Port/Place of discharging*",
    errorText: 'Define port/place of discharging.',
  },
};

function maxDate (date1, date2) {
  return new Date(Math.max(date1.getTime(), date2.getTime()));
}

class ALtNextPort extends React.Component {

  static contextTypes = {
    onChange: PropTypes.func,
    selector: PropTypes.func,
  }

  onChange = this.context.onChange("ports")(this.props.namePrefix)

  handleToggle= ()=> {
    this.props.onToggle(this.props.i);
  }

  handleChange = property => val => {
    const { onChange } = this;
    const { port } = this.props;
    const ports = this.context.selector("ports")[this.props.namePrefix];

    const newPort = {
      ...port,
      [property]: val,
    }

    onChange(ports.map((p, i) => p.i === port.i ? { ...p, ...newPort } : p));
  }

  render() {
    const { now, isWidget, port = {}, prevPort, nextPort, showDates = false, namePrefix = '', onRemove, isPol, i } = this.props;
    const isSolo = !prevPort && !nextPort;
    const portText = isPol ? texts.pol : texts.pod;
    const content = (<div className={cx('row', s.wrapper_inner, isPol ? s.pol : s.pod)}>
      <div className="col-sm-6">
        <FormsyInput
            name={`${namePrefix}[${i}][i]`}
            style={{display: "none"}}
            value={i}
            type="hidden"
        />
        <FormsyInput
          name={`${namePrefix}[${i}][_id]`}
          style={{display: "none"}}
          value={port._id}
          type="hidden"
        />
        <FormsyInput
          name={`${namePrefix}[${i}][groupId]`}
          style={{display: "none"}}
          value={port.groupId}
          type="hidden"
        />
        <WindowOpenInterceptor>
          {
            interceptFn => (
              <NarrowPortAutocomplete
                params={{
                  withAreas: true
                }}
                floatingLabelText={portText.floatingLabelText}
                name={`${namePrefix}[${i}][port]`}
                requiredError={portText.errorText}
                validationErrors={{
                  isDefaultRequiredValue: portText.errorText,
                }}
                forceSelection
                onNewRequest={this.handleChange("port")}
                required
                canCreate={!isWidget && this.props.user}
                value={port.port}
                {...(interceptFn ? { handleOpenCreatePort: interceptFn } : {})}
              />
            )
          }
        </WindowOpenInterceptor>
        <FormsyInput type="hidden" name={`${namePrefix}[${i}][type]`} value={port.type} />
      </div>
      <div className="col-sm-6" >
        <NarrowFormsyText
          floatingLabelText="Details"
          name={`${namePrefix}[${i}][details]`}
          fullWidth
          onChange={ev => this.handleChange("details")(ev.target.value)}
          value={port.details || ""}
          validations="maxLength:100"
          validationError="max 100 characters"
        />
      </div>
      {showDates ? (
        <div className="col-sm-4">
            <LayCanTypeSelect
              name={`${namePrefix}[${i}][layCanType]`}
              fullWidth
              defaultValue={'layCan'}
              value={port.layCanType}
              onChange={(e, value) => this.handleChange("layCanType")(value)}
            />
         </div>
      ): null}
      {showDates ?
        <div className="col-sm-2">
          <NarrowFormsyDate
            fullWidth
            name={`${namePrefix}[${i}][readinessDate]`}
            container="inline"
            floatingLabelText="Readiness*"
            requiredError="Define readiness date."
            autoOk
            startDate={port.readinessDate ? new Date(port.readinessDate) : new Date()}
            value={port.readinessDate ? new Date(port.readinessDate) : ""}
            onChange={(ev, val) => this.handleChange("readinessDate")(val)}
            required
            validations={`lteDateField:${namePrefix}[${i}][cancellingDate]`}
            validationErrors={{
              lteDateField: "invalid date",
            }}
          />
        </div>
        : null
      }
      {showDates ?
        <div className="col-sm-2">
          <NarrowFormsyDate
            fullWidth
            name={`${namePrefix}[${i}][cancellingDate]`}
            container="inline"
            floatingLabelText="Cancelling*"
            validations={`gteField:${namePrefix}[${i}][readinessDate]`}
            validationErrors={{
              gteField: "invalid date",
            }}
            required
            requiredError="Define cancelling date."
            autoOk
            startDate={port.readinessDate ? new Date(port.readinessDate) : undefined}
            minDate={port.readinessDate ? new Date(port.readinessDate) : new Date()}
            value={port.cancellingDate ? new Date(port.cancellingDate) : ""}
            onChange={(ev, val) => this.handleChange("cancellingDate")(val)}
          />
        </div>
        : null
      }
      {showDates ?
        <div className="col-sm-4" >
          <NarrowFormsyText
            floatingLabelText="Details"
            name={`${namePrefix}[${i}][additionalLayCanDetails]`}
            fullWidth
            value={port.additionalLayCanDetails || ""}
            validations="maxLength:100"
            validationError="max 100 characters"
            onChange={(ev, val) => this.handleChange("additionalLayCanDetails")(val)}
          />
        </div>
        : null }
    </div>)
    if(isSolo) {
      return content;
    }


    return (
      <List className={s.list}>
        <ListItem
          touchRippleColor="transparent"
          className={s.list_inner}
          key="0"
          primaryTogglesNestedList
          nestedListStyle={{ paddingBottom: '0' }}
          onNestedListToggle={this.handleToggle}
          open={port.opened}
          nestedItems={[
            <ListItem
              touchRippleColor="transparent"
              key="1"
              hoverColor="transparent"
              primaryText={content}
              innerDivStyle={{ marginLeft: '0', padding: '0' }}
            />,
          ]}
          hoverColor="transparent"
          primaryText={
            <Title isPol={isPol} port={port} prevPort={prevPort} onRemove={onRemove} />
          }
          disableKeyboardFocus
        />
      </List>
    )
  }
}

function Title({ port, isPol, onRemove, prevPort }){
  let title = isPol ? 'POL: ' : 'POD: ';
  if (port.type === 'alt') {
    if (port.port) {
      title = 'OR ' + port.port.name;
    } else {
      title = 'ENTER ALT PORT/PLACE ';
      if (prevPort && prevPort.port) {
        title += 'FOR ' + prevPort.port.name;
      }
    }
  } else {
    if (port.port) {
      title += port.port.name;
    } else {
      title = 'ENTER PORT/PLACE';
    }
  }
  return (<div className={s.header_accordion}>
    <p>{title}</p>
    {isPol && port.readinessDate && port.cancellingDate && <span className={s.date}><div>{formatLaycanType(port.layCanType)}</div> <div>{dateRange(port.readinessDate, port.cancellingDate)}</div></span>}
  </div>);
}

export default withStyles(s)(ALtNextPort);
