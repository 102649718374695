import React from 'react'
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './../../Monitor/TableNew.scss';
import WithTooltip from '../../Common/WithTooltip';
import { Text } from './styled';
import cx from 'classnames';
import { number } from '../../../core/format';
export const currencyMap = {
  'USD': '$',
  'EUR': '€',
};

export const FreightTooltip = props => {
  const { freight = [] } = props;
  return (
    <div className={cx(s.generic_table_tooltip, s.small)}>
      {
        freight.map(({ currency = 'USD', method, term, value }) =>
            `${currencyMap[currency]} ${value} ${term}, ${String(method).toUpperCase()}`
       ).join('; ')
      }
    </div>
  )
}

function Freight(props) {
  let { freight, isDark, ...rest } = props;

  freight = Array.isArray(freight) ? freight : [freight];

  freight = freight.filter(Boolean);

  if (!freight || !freight.length) return <Text />;

  const isMore = freight.length - 1;

  const { currency = 'USD', method, term, value = '' } = freight[0] ?? {};

  return (
    <WithTooltip horizontalPosition="left" tip={<FreightTooltip freight={freight} />} whiteArrow={isDark} {...rest}>
        <div className="align-items-center">
          <Text isBold ellipsis>
            {
              `${number(value, currencyMap[currency])}/${term}`
            }
          </Text>
          {
            isMore > 0 && <span className={s.blue_more}>+ {isMore}</span>
          }
        </div>
    </WithTooltip>
  )
}

export default withStyles(s)(Freight);
