let features = [
    '24/7 access to the Shipping Marketplace',
    'Market insights with News and Indexes',
    'Instant Cargo-to-Ship or Ship-to-Cargo matching',
    'Import of open Ship positions and Cargo/Freight Requests from the Shipping marketplace',
    'Create a White or Black list to limit access to your Cargo or Freight Request or open Ship position',
    'Search for open Cargo or Freight requests',
    'Search for open Ship positions or Shipping solutions',
    'Use of SHIPNEXT reverse freight auction services',
    'Online Freight Trading and Contract Management tools',
    'Access to an automated Freight calculator',
    'Access to Port-related data and restrictions',
    'Connectivity of Trading desk to individual Corporate reverse Freight-trading Platforms (MJunction, Quest, etc)',
    'Access to Charterers, Carriers, Brokers contacts',
    'Use of Electronic Bill of Lading solution',
    'Access to SHIPNEXT Chat',
    'Instant Charter Party printer',
    'Access to Instant Freight Index',
    'Access to S&P Fleet list',
    'Use of SHIPNEXT algorithm for real-time email processing inside the Trading desk',
    'Use of SHIPNEXT algorithm for real-time email processing inside the email Software',
    'Distribute incoming emails into Cargo-related data or Freight requests and open Ship Positions',
    'Optional Seamless integration between your email software and your Trading Desk',
    'Interaction between your Email software and the SHIPNEXT Trading desk during Freight negotiations',
    'Add your Charter party to SHIPNEXT',
    'Interconnectivity between different branches and offices on the SHIPNEXT Trading desk',
    'Create a Corporate Freight Trading Desk',
    'Cargo tracking',
    'Ship tracking',
    'Supply Chain management',
    'Door-to-door supply-chain integration',
];

export default features;
