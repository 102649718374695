import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomCheckbox from '../../Common/CustomCheckbox';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import s from './Channels.scss';
import { removeFromArray } from '../../../core/utils';
import { dateRange } from '../../../core/format';

import { CreateChatWrapper } from '../../Chat/CreateChat';
import PortLink from '../../Common/PortLink';
import { BlurEmailIfNoSubscription } from '../../Signup/BlurIfNoSubscription';
import ContactDetailsPopover from "../../ContactBook/ContactDetailsPopover";
import ConditionalBlur from '../../Common/ConditionalBlur';

class Channels extends Component {
  static propTypes = {
    channels: PropTypes.array,
    selectable: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    channels: [],
    selectable: true,
    onChange: () => {},
  };
  state = { selectedChannels: [], toggled: {} };

  constructor(props) {
    super(props);
    if(props.channels && props.channels.length === 1) {
      this.state.selectedChannels.push(props.channels[0]._id);
    }
  }

  handleSelectChannel(_id, e, checked) {
    let selectedChannels;
    if (checked) {
      selectedChannels = [...this.state.selectedChannels, _id];
    } else {
      selectedChannels = removeFromArray(
        this.state.selectedChannels,
        this.state.selectedChannels.indexOf(_id),
      );
    }
    this.setState({
      selectedChannels,
    });
    this.props.onChange(selectedChannels);
  }

  componentDidMount() {
    if (this.props.refInternal) {
      this.props.refInternal(this);
    }
    if(window.URLSearchParams) {
      const params =  new URLSearchParams(window.location.search);
      const selectedId = params.get('channel');
      if(selectedId) {
        const channel = this.props.channels.find(c => c._id === selectedId);
        if(channel) {
          this.setState({selectedChannels: [selectedId]});
          this.props.onChange([selectedId]);
        }
      }
    }
  }

  handleToggle(_id) {
    const toggled = { ...this.state.toggled };
    toggled[_id] = !toggled[_id];
    this.setState({ toggled });
  }
  getValue() {
    return this.state.selectedChannels;
  }
  render() {
    return (<div className={s.select_chanel_list}>
      {this.props.channels.map((channel) => {
        const checked =
          this.state.selectedChannels.indexOf(
            channel._id,
          ) !== -1;
        return (
          <div key={channel._id} className={cx('row', s.channel, this.state.toggled[channel._id] ? s.opened : '')}>
            <div className={s.left_part}>
              <div onClick={this.handleToggle.bind(this, channel._id)} className={cx(s.icon)}>
                <ArrowDownIcon />
              </div>
              <div className={cx(s.part_row, s.open)}>
                <div>
                  {this.props.selectable && this.props.channels.length > 1 ?
                    <div
                      className={cx(s.checkbox_container,)}
                    >
                      <ConditionalBlur dontBlur isBlurred={!channel.isClientRequest && !channel.userInWhiteList}>
                        <CustomCheckbox
                          checked={checked}
                          onCheck={this.handleSelectChannel.bind(
                            this,
                            channel._id,
                          )}
                        />
                      </ConditionalBlur>
                    </div>
                    : null}
                  <div title={channel.open.name} className={cx('ellipsis')}>
                    <PortLink port={channel.open} />
                  </div>
                </div>
              </div>
              <div className={cx(s.part_row, s.date)}>{dateRange(channel.open.from, channel.open.until)}</div>
              <div title={channel.company.name} className={cx(s.part_row, s.company)}>
                <BlurEmailIfNoSubscription>{channel.realUser ?
                  <ContactDetailsPopover email={channel.realUser.email}><a href={`mailto:${channel.realUser.email}`}>{channel.realUser.email}</a></ContactDetailsPopover>
                  : channel.company.name }</BlurEmailIfNoSubscription>
              </div>
            </div>
            <div className={s.right_part}>
              <div className={cx(s.part_row, s.role)}>{channel.isClientRequest ? 'RECEIVED' : channel.role}</div>
              <div className={cx(s.part_row, s.chat_icon)}>
                <CreateChatWrapper fill addedBy={(channel.realUser?._id) || channel.addedBy} refNo={channel.refNo} hideForSelf={true} />
              </div>
            </div>

            <div className="w-100" />
            <div className={cx('col-12', s.details)}>
              <div className={s.text} >
                <div className={s.title_ref_in_toggle}>
                  Ref {channel.refNo}
                </div>
                {channel.preferredCargo || 'Details not available'}
              </div>
            </div>
          </div>
        );
      })}
    </div>);
  }
}

export default withStyles(s)(Channels);
