import produce from "immer";
import {sortContacts} from "./CONTACTS_CREATE_GROUP";

/** @name CONTACTS_UPDATE_CONTACT_REDUCER */
 export default function CONTACTS_UPDATE_CONTACT(state, { res, payload}) {
  const newContact = res.data;
  return produce(state, draft => {
    const index = draft.contactList.data.findIndex(c => c.id === newContact.id);
    if (index !== -1) {
      draft.contactList.data[index] = newContact;
    }
    draft.contactList = sortContacts(draft.contactList);
  });
}
