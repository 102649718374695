import React, { Component } from 'react'
import CountrySelect from '../Common/CountrySelect';
import styles from './muistyles'
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames'
export class NarrowCountrySelect extends Component {
    render() {
        const {inputStyles, className, ...props} = this.props;
        return (
            <CountrySelect
                className={cx("select_default", "country_select", className)}
                style={styles.selectContainer}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={styles.text.label}
                hintStyle={styles.text.label}
                inputStyle={{...styles.select.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={styles.text.underline}
                underlineFocusStyle={styles.text.underlineFocus}
                errorStyle={styles.error}
                {...props}
            >

            </CountrySelect>
        )
    }
}

export default withStyles(s)(NarrowCountrySelect)
