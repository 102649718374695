import React from 'react';

export default {
  path: '/',
  children: [
    {
      path: '/login',
      async action() {
        const Login = ((await import(/* webpackChunkName: 'Register' */ './Login')).default);
        return {
          title: 'Log In',
          component: <Login />,
          public:true,
          robots: "noindex,follow",
        };
      },
    },
    {
      path: '/forgot-password',
     async action() {
       const ForgotYourPassword = ((await import(/* webpackChunkName: 'Register' */ './ForgotPassword')).default);
        return {
          title: 'Forgot your password',
          component: <ForgotYourPassword />,
          public:true,
          robots: "noindex,follow",
        };
      },
    },
    {
      path: '/forgot-password/:token',
      async action({ params }) {
        const ChangeYourPassword = ((await import(/* webpackChunkName: 'Register' */ './ChangePassword')).default);
        return {
          title: 'Change password',
          component: <ChangeYourPassword {...params} />,
          public:true,
          robots: "noindex,follow",
        };
      },
    },
  ],
};
