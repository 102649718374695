import React from 'react';
import NarrowAutocomplete from "../NewInputs/NarrowAutocomplete";
import styled from "styled-components";
import { Avatar } from "./Avatar";
import ContactBookApi from "../../core/api/ContactBookApi";
import NarrowMenuItem from "../NewInputs/NarrowMenuItem";

const Row = styled(({ contact, ...props }) => {
  return (<div {...props}>
    <Avatar contact={contact} size={'small'} />
    <b>{`${contact.name || ''} ${contact.surname || ' '} `}&nbsp;</b>
    <div>{`<${contact.emails[0]}>`}</div>
  </div>);
})`
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: normal;
  > div:first-child {
    margin-right: 8px;
  }
  b {font-weight: 500}
`;

const renderer = (items) =>  {
  return items.map(item => {
    return {
      text: item.name,
      _value: item,
      value: <NarrowMenuItem primaryText={<Row contact={item} className={"vessel_autocomplete_item"} />} />,
    };
  });
};

class ContactBookContactAutocomplete extends NarrowAutocomplete {

  constructor(props, context){
    super(props, context);
    this.Api = {
      find: async (query) => {
        return ContactBookApi.searchContact({ page: 1, filters: { q: query.name }, sort: {}});
      },
    };
    this.renderer = renderer;
  }
}

export default ContactBookContactAutocomplete;

export const ContactBookContactAutocompleteItem = Row;
