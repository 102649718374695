import PropTypes from 'prop-types';
import React from 'react'
import {NarrowSelect} from "./NarrowSelect";
import MenuItem from "material-ui/MenuItem";

export default function NarrowIndustrySelect(props, context){
  return (<NarrowSelect floatingLabelText={"Industry"} {...props}>
    {context.industries.map(industry => (
      <MenuItem key={industry._id} value={industry._id} primaryText={industry.name_en} />
    ))}
  </NarrowSelect>);
}

NarrowIndustrySelect.contextTypes = {
  sectors: PropTypes.array,
  industries: PropTypes.array,
  formsy: PropTypes.object,
}
