/*
 * @Author: salterok
 * @Date: 2017-10-11 15:07:35
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2019-09-06 17:48:51
 */

export function selectAllContactMessages(state, userId) {
  const roomIds = selectContactRooms(state, userId);
  const messages = [];

  for (const key of Object.keys(state.chat.messages)) {
    const message = state.chat.messages[key];
    if (roomIds.indexOf(message.roomId) !== -1) {
      messages.push(message);
    }
  }

  return messages.sort((a, b) => a.sentAt.getTime() - b.sentAt.getTime());
}

export function selectContactRooms(state, userId) {
  return Object.keys(state.chat.rooms)
    .filter(roomId => state.chat.rooms[roomId].restrictedUsers.indexOf(userId) !== -1);
}

export function getMe(state) {
  return state.login.user._id;
}

export function getLastUpdateTimeFor(state, userId) {
  return Math.max(0, ...selectContactRooms(state, userId).map(roomId => state.chat.rooms[roomId].lastReceivedAt));
}

export function getContacts(state, thisUser) {
  return Object.keys(state.chat.users)
    .map(uid => state.chat.users[uid])
    .filter(user => user._id !== thisUser)
    .map((user) => {
      user.lastUpdateTime = getLastUpdateTimeFor(state, user._id);
      return user;
    })
    .sort((u1, u2) => u2.lastUpdateTime - u1.lastUpdateTime);
}

export function getUnreadContactMessages(state, me, userId) {
  const messages = selectAllContactMessages(state, userId);
  return messages.filter(message => {
    const room = state.chat.rooms[message.roomId];
    if (room && message.senderId !== me) {
      return (room.readState[me] || new Date(0)) < message.sentAt;
    }
    return false;
  });
}




// TODO: move to utils
export function getName(user) {
  if (!user || (!user.name) || (!user.surname)) {
    return "";
  }
  return user.name + " " + user.surname;
}

export function getCompanyName(user) {
  return user && user.company && user.company.name || "";
}

export function getUsernameSearchString(user) {
  return getName(user) + " " + getCompanyName(user);
}

export function getContactNamesPair(user) {
  let userName = getName(user);
  let companyName = getCompanyName(user);
  if (!userName) { // is only company name exist
      userName = companyName;
      companyName = "";
  }
  return [userName, companyName];
}

export function getShortName(user) {
  if (!user || (!user.name) || (!user.surname)) {
    return user.company.name.substr(0, 2).toUpperCase();
  }
  return (user.name[0] + user.surname[0]).toUpperCase();
}

export function getUnreadChatsCounter(state) {
  try {
    if (!state.login.user) {
      return 0;
    }
    const me = getMe(state);
    const users = getContacts(state, me);

    return users.filter(user => getUnreadContactMessages(state, me, user._id).length > 0).length;
  } catch (e) {
    console.error(e);
    return 0;
  }
}
