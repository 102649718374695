export const mergeAccountName = (val) => {
  const obj = prepareGeneralObj(val);
  return order => {
    return {
      ...order,
      account: {
        ...(order.account || {}),
        ...obj
      }
    }
  }
}
export const mergeTags = (val) => {
  return order => {
    return {
      ...order,
      tags: Array.isArray(val) ? val.map(prepareTag) : [prepareTag(val)]
    }
  }
}

export const prepareTag = tag => tag?.value ? tag.value : tag

export const mergeDisponentOwner = (val) => {
  const obj = prepareGeneralObj(val);
  return vessel => {
    return {
      ...vessel,
      disponentOwner: {
        ...(vessel.disponentOwner || {}),
        ...obj
      }
    }
  }
}

export const prepareGeneralObj = val => {
  let obj = {
    _id: "",
    name: ""
  }
  if (typeof val === 'string') {
    obj.name = val;
  }
  else if (typeof val === 'object') {
    obj = {
      ...obj,
      ...val
    }
  }
  return obj;
}

export const getUpdatedItem = (item, data) => {
  let newItem = {
    ...item,
  }
  let dataToUpdate = data;
  if (typeof data === 'function') {
    dataToUpdate = data(newItem);
  }

  newItem = {
    ...newItem,
    ...dataToUpdate
  }
  return newItem;
}


export const updateItemsInArr = (list, itemsToUpdate) => {
  return list.map(item => {
    const needsUpdate = itemsToUpdate.find(itemToUpdate => itemToUpdate._id === item._id);
    if (needsUpdate) {
      return getUpdatedItem(item, needsUpdate.data);
    }
    return item;
  });
}

export const updateItem = (list, id, data) => {
  const newList = [...list];
  const index = newList.findIndex(el => el._id === id);
  if (index < 0) {
    return list;
  }
  const newItem = getUpdatedItem(newList[index], data);
  newList[index] = newItem;
  return newList;
}

export const replaceAccountName = (payload) => {
  if (!payload?.account?.realCompany?.company) {
    return payload;
  }

  const copy = {...payload};

  copy.account.name = copy.account.realCompany.company.name;
  delete copy.account.realCompany;
  return copy;
}
