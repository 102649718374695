/*
 * @Author: salterok
 * @Date: 2017-10-04 14:47:27
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-27 14:25:09
 */

export const CHAT_MESSAGES_RECEIVED = "chat:messagesReceived";
export const CHAT_INITIAL_INFO_RECEIVED = "chat:initialInfo";
export const CHAT_INCREMENTAL_UPDATE = "chat:incrementalUpdate";
export const CHAT_SHOW_PREPARED_ROOM = "chat:showPreparedRoom";
export const CHAT_SET_VISIBILITY = "chat:setVisibility";
export const CHAT_SELECT_CONTACT = "chat:selectContact";
export const CHAT_SET_TARGET_ROOM = "chat:setTargetRoom";
export const CHAT_SEND_MESSAGE = "chat:sendMessage";
export const CHAT_ROOMS_CHANGED = "chat:roomChanged";

export function messagesReceived(messages) {
  return {
    type: CHAT_MESSAGES_RECEIVED,
    payload: messages
  };
}

export function setChatVisibility(visible) {
  return {
    type: CHAT_SET_VISIBILITY,
    payload: visible
  };
}

export function selectContact(contact) {
  return {
    type: CHAT_SELECT_CONTACT,
    payload: contact
  };
}

// TODO: make this action redux compatible or emphasize somehow that they are different from regular actions
export function sendMessage(roomId, message) {
  return new Promise((resolve, reject) => {
    window.socket.emit("chat:sendMessage", roomId, message, (val) => resolve(val));
  });
}

export function setReadTimestamp(timestamps) {
  return new Promise((resolve) => {
    window.socket.emit("chat:setReadTimestamp", timestamps, (val) => resolve(val));
  });
}

/**
 *
 * @param {string} userId
 * @param {number} before
 * @returns {Promise<ChatMessage[]>}
 */
export function fetchContactHistory(userId, before) {
  return new Promise((resolve) => {
    window.socket.emit("chat:fetchContactHistory", userId, { before, limit: 100 }, (val) => resolve(val));
  });
}

export function setTargetRoom(userId, roomId) {
  return {
    type: CHAT_SET_TARGET_ROOM,
    payload: { userId, roomId }
  };
}

export function showPreparedRoom(discussion) {
  return {
    type: CHAT_SHOW_PREPARED_ROOM,
    payload: discussion
  };
}
