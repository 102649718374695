/** @typedef {Object} UpdateTCRatePayload
 * @property {String} vessel
 * @property {TcRate} rate
 */
/** @typedef {Object} TcRate
 * @property {String} currency
 * @property {Date} endAt
 * @property {Number} value
 */

import { replaceInArray } from '../../core/utils';

//noinspection JSUnusedGlobalSymbols
/**
 * @name MONITOR_UPDATE_TC_REDUCER
 * @param {MonitorState} state
 * @param {UpdateTCRatePayload} payload
 * @returns {{}}
 * @constructor
 */
export default function MONITOR_UPDATE_TC(state, { payload }) {
  const newState = {};
  const index = state.vesselList.data.findIndex(
    vg => vg.vessel === payload.vessel,
  );
  if (index === -1) {
    return state;
  }
  const vesselGroup = { ...state.vesselList.data[index] };
  vesselGroup.status = {
    ...vesselGroup.status,
    ...{
      currency: payload.rate.currency,
      end: payload.rate.endAt.getTime(),
      value: payload.rate.value,
    },
  };
  newState.vesselList = { ...state.vesselList };
  newState.vesselList.data = replaceInArray(
    newState.vesselList.data,
    index,
    vesselGroup,
  );
  return { ...state, ...newState };
}
