import React from 'react';
import PortApi from '../../core/api/Port';
import Loader from '../Common/Loader';

export default function (Child) {
  return class extends React.PureComponent {
    state = { loading: true };

    componentDidMount() {
      if (this.props.portId) {
        this.fetch(this.props.portId);
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.portId && nextProps.portId !== this.props.portId) {
        this.fetch(nextProps.portId);
      }
    }


    fetch = async (portId) => {
      try {
        this.setState({ loading: true });
        const res = await PortApi.getPublic(portId);
        if (res.data) {
          this.setState({ port: res.data });
        }
      } catch (e) {
        this.props.showMessage({
          message: e.message.errors[0].messages[0],
          level: "error",
          _id: new Date().getMilliseconds(),
          autoDismiss: 3,
        });
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      } finally {
        this.setState({ loading: false });
      }
    }
    reload = async () => {
      this.fetch(this.props.portId);
    }


    render() {
      const { ...rest } = this.props;
      if (this.state.loading || !this.state.port) {
        return <Loader />;
      }
      return <Child {...rest} port={this.state.port} reloadPort={this.reload} />;
    }
  };
}
