import React from 'react';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './RemoveButton.scss';

export default withStyles(s)((props) => {
  const { className, ...rest } = props;
  return (
    <span className={cx(s.remove_button, className)} {...rest}>
      <NavigationClose />
    </span>
  );
});
