import NarrowSelect from '../NewInputs/NarrowSelect';
import MenuItem from 'material-ui/MenuItem';
import React from 'react';
import CustomTooltip from './CustomTooltip';
import ActionInfo from 'material-ui/svg-icons/action/info';

const valuesMap = {
  layCan: 'Laycan',
  shipmentDates: 'Shipment dates',
  shipmentDatesAbout: 'Shipment dates (abt)',
  readinessDates: 'Readiness dates',
};

const menuItems = Object.entries(valuesMap).map(([k,v]) => <MenuItem key={k} value={k} primaryText={v} /> );

export default function LayCanTypeSelect(props) {
  return (
    <div style={{ position: 'relative' }}>
      <NarrowSelect
        fullWidth
        {...props}
        className={"select_with_info"}
      >
        {menuItems}
      </NarrowSelect>
      <div style={{ position: "absolute", right: "22px", top: "50%", transform: "translateY(-50%)", zIndex: "2" }}>
        <CustomTooltip
          style={{ alignSelf: "center", cursor: "pointer" }}
          tooltip={
            <div>
              <div>Readiness date to count from 00:00</div>
              <div>Cancelling date to count until 23:59</div>
            </div>
          }
        >
          <ActionInfo style={{ width: "16px", height: "16px" }} />
        </CustomTooltip>
      </div>
    </div>
  );
}

export function formatLaycanType(value = 'layCan') {
  return valuesMap[value] || value;
}
