import produce from 'immer';
import {uniqBy} from "lodash";
/** @name CONTACTS_GET_LIST_REDUCER */
 export default function CONTACTS_GET_LIST(state, { res, payload = {} }) {
  return produce(state, draft => {
    draft.contactList.filters = payload.filters;
    draft.contactList.sort = payload.sort;
    draft.contactList.loading = false;
    if (payload.page === 1) {
      draft.contactList.data = res.data;
      draft.contactList.page = 1;
    } else {
      draft.contactList.data = uniqBy([...draft.contactList.data, ...res.data], c => c.id);
      draft.contactList.page = payload.page;
    }
    if (res.data.length === 50) {
      draft.contactList.next = payload.page + 1;
    } else {
      draft.contactList.next = undefined;
    }
  });
}
