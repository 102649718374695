import Api from './api';
import { UUIDToObjectId } from '../utils';
import type { INewMessageReq } from '../../components/Emails/types';
import {replaceFromWithAliases} from "../../components/Emails/utils";

function collectAttachments(message,user){
  let lastUsedAlias = '';
  message.nestedMails.forEach(nm => {
    nm.to = nm.to || [nm.toEmail];
    nm.from = nm.from || [nm.fromEmail];
    nm.replyTemplate = message.replyTemplates?.[nm.id];
    replaceFromWithAliases(nm);
    // eslint-disable-next-line new-cap
    if (nm.sentByClient && nm.context?.userId && UUIDToObjectId(nm.context.userId) === user._id) {
       lastUsedAlias = nm.from[0];
    }
  });
  message.originalAttachments = message.nestedMails[0].attachments || [];
  message.receivedAt = message.nestedMails[0].receivedAt;
  message.to = message.nestedMails[0].to;
  message.from = message.nestedMails[0].from;
  message.cc = message.nestedMails[0].cc;
  message.bcc = message.nestedMails[0].bcc;
  message.shouldReplyTo = message.nestedMails[0].shouldReplyTo;
  message.subject = message.nestedMails[0].subject;
  message.replyTemplate = message.nestedMails[0].replyTemplate;
  message.text = message.nestedMails[0].text;
  message.html = message.nestedMails[0].html;
  message.lastUsedAlias = lastUsedAlias;
}

function calculateReadStatus(message, user) {
  message.nestedMails.forEach(nm => {
    // eslint-disable-next-line new-cap
    nm.read = nm.readBy.some(rb => UUIDToObjectId(rb.userId) === user._id);
    nm.html = nm.html || `<pre style="margin: 0; white-space: pre-wrap">${nm.text}</pre>`;
  });
  message.readBy = message.nestedMails?.[0]?.readBy || [];
  // eslint-disable-next-line new-cap
  message.read = message.readBy.some(rb => UUIDToObjectId(rb.userId) === user._id);
  return message;
}

export default class EmailsApi extends Api {
  static endpoint = '/mailer';

  static fetch(url, params, retry = 0) {
    if (params.body) {
      params.body = JSON.stringify(params.body);
    }
    return super.fetch(url, params, '/api/v2', retry);
  }

  /**
   POST: /api/v2/mailer/list
   {
    cursor: Joi.string(),

    archived: Joi.boolean().default(false),
    pageSize: Joi.number().default(50),
    }
   */
  static async getEmailsList({ cursor, filters = {}, sort, folder, cargoId, vesselId, monitorEmailsFolder }) {
    filters = { ...filters };
    let isComposed = folder === 'sent';

    if (folder === 'archive') {
       isComposed = undefined;
    }
    const { tagged, read, scopes, favorite, attached, ...fields } = filters;
    let { tags } = filters;
    if (tags?.condition?.length === 0) {
      tags = undefined;
    }
    if (monitorEmailsFolder) {
      isComposed = monitorEmailsFolder === "SENT";
      if (monitorEmailsFolder === "ARCHIVE") {
        isComposed = undefined;
      }
    }
    return this.fetch('/mailer/list?v2list=true', {
      method: 'POST',
      body: {
        pageSize: 50,
        archived: folder === 'archive' || monitorEmailsFolder === "ARCHIVE",
        isComposed,
        cursor,
        filters: { tags, scopes, fields, hasAttachments: attached },
        tagged: tagged,
        read: read,
        private: filters.private,
        favorite,
        order: {
          name: 'received',
          value: -1,
        },
        cargoId,
        vesselId,
      },
    });
  }

  /**
   POST: /api/v2/mailer/archive
   {
    archive: Joi.boolean().default(false),
    ids: Joi.array().items(UUID).min(1),
    }
   */
  static async archiveEmails({ ids, archive = false }) {
    return this.fetch('/mailer/archive', { method: 'POST', body: { ids, archive } });
  }

  static async getEmailDetails(id, user) {
    const responses = await Promise.all([this.fetch(`/mailer/${id}/details`, { method: 'GET' }, 3), this.getEmailReplies(id)]);
    const res = responses[0];
    Object.assign(res.data, responses[1].data);
    collectAttachments(res.data, user);
    calculateReadStatus(res.data, user);
    res.data.html = res.data.html || `<pre style="margin: 0; white-space: pre-wrap" >${res.data.text}</pre>`;
    if (res.data?.nestedMails?.length) {
      res.data.nestedMails = res.data.nestedMails.slice(1);
    }
    try {
      const tagsRes = await this.getTags(id);
      if (tagsRes.data) {
        res.data.tags = tagsRes.data;
      }
    } catch (e) {
      console.error(e);
    }
    return res;
  }

  static async getEmailReplies(id) {
    const res = await this.fetch(`/mailer/${id}/thread`, { method: 'GET' }, 3);
    if (res.data.nestedMails) {
        res.data.nestedMails.reduce((accumulator, currentValue, index, array) => {
        accumulator[currentValue.id] = currentValue;
        currentValue.replyToRef = accumulator[currentValue.replyTo]?.refNo;
        return accumulator;
      }, {});
    }
    return res;
  }

  static async sendReply(id, reply: INewMessageReq) {
    return this.fetch(`/mailer/${id}/reply?signatureIncluded=1`, { method: 'POST', body: reply });
  }
  static async sendShared(id, body){
    return this.fetch(`/mailer/${id}/sharing`, { method: 'POST', body: body });
  }

  static async sendNewMessage(reply: INewMessageReq) {
    return this.fetch(`/mailer/compose?signatureIncluded=1`, { method: 'POST', body: reply });
  }

  static async markAsRead(body) {
    return this.fetch(`/mailer/read-state`, { method: 'POST', body: body });
  }

  static async getTags(id){
    return await this.fetch(`/appliedTags/mail/${id}`, { method: 'GET' }, 3);
  }

  static async changeTags(id: string, body: { toAdd: any[], toRemove: any[] }) {
    return this.fetch(`/appliedTags/mail/${id}`, { method: 'POST', body: body });
  }
}
