import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import t from './Request.scss';
import MenuItem from 'material-ui/MenuItem';
import s from '../../../Monitor/TcOffer/TcOffer.scss';
import { replaceInArray } from '../../../../core/utils';
import { AddButtonBlue } from '../../../Common/AddButton';
import RemoveButton from '../../../Common/RemoveButton';
import NarrowFormsyText from '../../../NewInputs/NarrowFormsyText';
import NarrowSelect from '../../../NewInputs/NarrowSelect';

class Bunkers extends PureComponent {
  static propTypes = {
    bunkers: PropTypes.array,
    namePrefix: PropTypes.string,
  };
  static defaultProps = {
    bunkers: [
      {},
    ],
    namePrefix: 'bunkers',
  };

  constructor(props) {
    super(props);
    this.state = { bunkers: this.props.bunkers };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.bunkers !== this.props.bunkers) {
      this.setState({ bunkers: nextProps.bunkers });
    }
  }


  handleAddBunker = () => {
      this.setState({ bunkers: [...this.state.bunkers, {}] });
  };

  handleRemoveBunker = (index, _id) => {
    this.setState({
      bunkers: replaceInArray(this.state.bunkers, index, null),
    });
  };

  handleChangeBunker = (index, bunker) => {
    const updatedBunkers = [...this.state.bunkers];
    updatedBunkers[index] = bunker;
    this.props.handleChange("bunkers", null, updatedBunkers);
  }


  render() {
    return (<span>
      <div>
        {this.state.bunkers.map((bunker, index) => {
          if (!bunker) {
            return null;
          }
          if (this.props.deliveryBunkers) {
            return (<DeliveryBunker
              bunker={bunker}
              index={index}
              handleRemoveBunker={this.handleRemoveBunker}
              handleAddBunker={this.handleAddBunker}
              handleChangeBunker={this.handleChangeBunker}
              key={bunker._id || index}
              namePrefix={this.props.namePrefix}
              isLast={index === this.state.bunkers.length - 1}
            />);
          }
          return (<Bunker
            bunker={bunker}
            index={index}
            handleRemoveBunker={this.handleRemoveBunker}
            handleAddBunker={this.handleAddBunker}
            handleChangeBunker={this.handleChangeBunker}
            key={bunker._id || index}
            namePrefix={this.props.namePrefix}
            isLast={index === this.state.bunkers.length - 1}
          />);
        })}
      </div>
    </span>);
  }
}


class Bunker extends PureComponent {
  static propTypes = {
    bunker: PropTypes.object,
    index: PropTypes.number,
    handleRemoveBunker: PropTypes.func,
    handleChangeBunker: PropTypes.func,
  };

  static defaultProps= {
    namePrefix: 'bunkers',
  };

  state = {
    quantity: "",
    typeOfFuel: undefined,
    type: undefined,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state !== prevState) {
      this.props.handleChangeBunker(this.props.index, this.state);
    }
    if (prevProps.bunker !== this.props.bunker) {
      this.setState({ quantity: this.props.bunker.quantity, typeOfFuel: this.props.bunker.typeOfFuel, type: this.props.bunker.type || (this.props.bunker.typeOfFuel ? "Min/Max" : undefined) });
    }
  }

  handleAdd = () => {
    this.props.handleAddBunker();
  };
  handleRemove = () => {
    this.props.handleRemoveBunker(this.props.index, this.props.bunker._id);
  };

  render() {
    const { quantity, typeOfFuel, type } = this.state;
    return (<div className={cx("row", t.step_row)}>
      <div className="col-sm-3">
        <NarrowSelect
          floatingLabelText="Type"
          hintStyle={{ fontSize: "13px" }}
          name={`${this.props.namePrefix}[${this.props.index}][type]`}
          autoWidth
          fullWidth
          value={type}
          validations={'requiredIf:bunkers' + '[' + this.props.index + ']' + '[quantity]'}
          validationErrors={{
            "requiredIf": "required"
          }}
        >
          <MenuItem key="Min" value="Min" primaryText="Min" />
          <MenuItem key="Max" value="Max" primaryText="Max" />
          <MenuItem key="Min/Max" value="Min/Max" primaryText="Min/Max" />
          <MenuItem key="ABT" value="ABT" primaryText="ABT" />
        </NarrowSelect>
      </div>
      <div className="col-sm-3">
        <NarrowFormsyText
          floatingLabelText="Quantity, mt"
          validations="isNumeric,min:0,max:10000"
          validationErrors={{
              isNumeric: 'only numbers',
              min: '0 - 10000',
              max: '0 - 10000',
          }}
          name={`${this.props.namePrefix}[${this.props.index}][quantity]`}
          fullWidth
          value={quantity || ""}
        />
      </div>
      <div className="col-sm-3">
        <NarrowSelect
          floatingLabelText="Type of fuel"
          hintStyle={{ fontSize: "13px" }}
          hintText=" "
          name={`${this.props.namePrefix}[${this.props.index}][typeOfFuel]`}
          autoWidth
          fullWidth
          value={typeOfFuel}
          validations={'requiredIf:bunkers' + '[' + this.props.index + ']' + '[quantity]'}
          validationErrors={{
            "requiredIf": "required"
          }}
        >
          <MenuItem key="VLS FO" value="VLS FO" primaryText="VLS FO" />
          <MenuItem key="ULS FO" value="ULS FO" primaryText="ULS FO" />
          <MenuItem key="HFO 180" value="HFO 180" primaryText="HFO 180" />
          <MenuItem key="HFO 380" value="HFO 380" primaryText="HFO 380" />
          <MenuItem key="IFO 380" value="IFO 380" primaryText="IFO 380" />
          <MenuItem key="IFO 180" value="IFO 180" primaryText="IFO 180" />
          <MenuItem key="IFO 120" value="IFO 120" primaryText="IFO 120" />
          <MenuItem key="IFO 80" value="IFO 80" primaryText="IFO 80" />
          <MenuItem key="IFO 60" value="IFO 60" primaryText="IFO 60" />
          <MenuItem key="MDO" value="MDO" primaryText="MDO" />
          <MenuItem key="LSMDO" value="LSMDO" primaryText="LSMDO" />
          <MenuItem key="LSMGO" value="LSMGO" primaryText="LSMGO" />
        </NarrowSelect>
      </div>


      <div className={cx('col-sm-3', 'align-items-center')}>
        {!this.props.isLast && <RemoveButton onClick={this.handleRemove} /> }
        {this.props.isLast && <AddButtonBlue onClick={this.handleAdd} />}
      </div>
    </div>);
  }
}
class DeliveryBunker extends PureComponent {
  static propTypes = {
    bunker: PropTypes.object,
    index: PropTypes.number,
    handleRemoveBunker: PropTypes.func,
    handleChangeBunker: PropTypes.func,
  };

  static defaultProps= {
    namePrefix: 'deliveryBunkers',
  };

  state = {
    quantity: "",
    typeOfFuel: undefined,
    price: "",
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state !== prevState) {
      this.props.handleChangeBunker(this.props.index, this.state);
    }
    if (prevProps.bunker !== this.props.bunker) {
      this.setState({ quantity: this.props.bunker.quantity, typeOfFuel: this.props.bunker.typeOfFuel, type: this.props.bunker.type || (this.props.bunker.typeOfFuel ? "Min/Max" : undefined) });
    }
  }

  handleAdd = () => {
    this.props.handleAddBunker();
  };
  handleRemove = () => {
    this.props.handleRemoveBunker(this.props.index, this.props.bunker._id);
  };

  render() {
    const { quantity, typeOfFuel, price } = this.state;
    return (<div className={cx("row", t.step_row)}>
      <div className="col-sm-3">
        <NarrowSelect
          floatingLabelText="Type of fuel"
          hintStyle={{ fontSize: "13px" }}
          hintText=" "
          name={`${this.props.namePrefix}[${this.props.index}][typeOfFuel]`}
          autoWidth
          fullWidth
          value={typeOfFuel}
          validations={'requiredIf:bunkers' + '[' + this.props.index + ']' + '[quantity]'}
          validationErrors={{
            "requiredIf": "required"
          }}
        >
          <MenuItem key="VLS FO" value="VLS FO" primaryText="VLS FO" />
          <MenuItem key="ULS FO" value="ULS FO" primaryText="ULS FO" />
          <MenuItem key="HFO 180" value="HFO 180" primaryText="HFO 180" />
          <MenuItem key="HFO 380" value="HFO 380" primaryText="HFO 380" />
          <MenuItem key="IFO 380" value="IFO 380" primaryText="IFO 380" />
          <MenuItem key="IFO 180" value="IFO 180" primaryText="IFO 180" />
          <MenuItem key="IFO 120" value="IFO 120" primaryText="IFO 120" />
          <MenuItem key="IFO 80" value="IFO 80" primaryText="IFO 80" />
          <MenuItem key="IFO 60" value="IFO 60" primaryText="IFO 60" />
          <MenuItem key="MDO" value="MDO" primaryText="MDO" />
          <MenuItem key="LSMDO" value="LSMDO" primaryText="LSMDO" />
          <MenuItem key="LSMGO" value="LSMGO" primaryText="LSMGO" />
        </NarrowSelect>
      </div>
      <div className="col-sm-3">
        <NarrowFormsyText
          floatingLabelText="Quantity, mt"
          validations="isNumeric,min:0,max:10000"
          validationErrors={{
              isNumeric: 'only numbers',
              min: '0 - 10000',
              max: '0 - 10000',
          }}
          name={`${this.props.namePrefix}[${this.props.index}][quantity]`}
          fullWidth
          value={quantity || ""}
        />
      </div>
      <div className="col-sm-4">
        <NarrowFormsyText
          floatingLabelText="Price per mt, USD"
          validations="isNumeric,min:0,max:10000"
          validationErrors={{
              isNumeric: 'only numbers',
              min: '0 - 10000',
              max: '0 - 10000',
          }}
          name={`${this.props.namePrefix}[${this.props.index}][price]`}
          fullWidth
          value={price || ""}
        />
      </div>

      <div className={cx('col-sm-2', 'align-items-center')}>
        {!this.props.isLast && <RemoveButton onClick={this.handleRemove} /> }
        {this.props.isLast && <AddButtonBlue onClick={this.handleAdd} />}
      </div>
    </div>);
  }
}

export default withStyles(s)(Bunkers);
