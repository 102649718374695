import React, { Component } from 'react'
import Autocomplete from '../Common/AutoComplete';
import styles from './muistyles'
import cx from 'classnames'
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class NarrowAutocomplete extends Autocomplete {
    constructor(props) {
      super(props);
      this.Api = props.api
      if (props.renderer) {
        this.renderer = props.renderer;
      }

      if (props.fixedValues && props.dataSource) {
        this.state = {
          ...this.state,
          dataSource: this.renderer(props.dataSource),
          allValues: this.renderer(props.dataSource)
        }
        this.find = query => {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            try {
              const filtered = this.props.dataSource.filter(item => item && item.name && item.name.toLowerCase().includes(query.toLowerCase()));
              this.setState({
                dataSource: this.renderer(filtered)
              })
           } catch (error) {
              console.log(error);
              this.setState({
                dataSource: []
              })
           }
          }, 300);
        }
      }

    }

    renderer(data) {
      if (this.props.renderer) {
        return this.props.renderer(data);
      }

      return super.renderer(data);
    }

    render() {
      const {props} = this;
      const style = {
        className: cx("input_default", props.classes),
        menuStyle: { ...props.menuStyle, maxHeight: '300px', overflowY: 'auto', maxWidth: 'auto', width: 'auto' },
        menuProps: {className: `${props.smallFont && s.smaller_font}`},
        popoverProps: props.popoverProps || { canAutoPosition: true, style: {width: 'auto'} },
        textFieldStyle: styles.container,
        floatingLabelFocusStyle: styles.text.labelFocus,
        floatingLabelStyle: styles.text.label,
        hintStyle: styles.text.label,
        inputStyle: {...styles.text.input, ...props.inputStyles},
        floatingLabelShrinkStyle: styles.text.labelShrink,
        underlineStyle: styles.text.underline,
        errorStyle: styles.error,
        underlineFocusStyle: styles.text.underlineFocus,
        ref: node => this.input = node
      }
      return super.render(style)
    }
}

export default NarrowAutocomplete;
