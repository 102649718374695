import React, {Component} from 'react';
import {Pane} from "../Common/SplitPane";
import styled from "styled-components";
import {phoneTypeMap, phoneTypes} from "./ContactPhonesEdit";
import {cloneDeep, uniqBy} from "lodash";
import {Avatar} from "./Avatar";
import {RadioButton} from "material-ui/RadioButton";
import Checkbox from "material-ui/Checkbox";
import CustomCheckbox from "../Common/CustomCheckbox";
import {date} from "../../core/format";
import TagsChipSmall from "../Common/Tags/TagsChipSmall";
import Loader from "../Common/Loader";
import ContactBookApi from "../../core/api/ContactBookApi";
import {Attachments} from "../Common/FormsyAttachments/FormsyAttachments";
import SpinnerButton from "../Common/SpinnerButton";
import RaisedButton from "../Common/RaisedButton";
import {createContactAdapter, fromServerContactAdapter} from "./contactBookUtils";
import history from "../../core/history";
import cx from "classnames";
import PubSub from "pubsub-js";

const Body = styled.div`
  margin: 12px 12px 50px 12px;
  font-size: 11px;
  color: var(--text-dark333);
  overflow: scroll;
  .contact-merge-row {
    display: flex;

   > label {
      font-weight: 500;
      width: 133px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: sticky;
      left: 0;
      background: #F1F9FF;
      z-index: 1;
    }
    .contact-merge-cell {
      padding: 4px 8px;
      word-break: break-all;
      min-height: 32px;
      display: flex;
      align-items: center;
      width: ${props => props.cellWidth}%;
      min-width: ${props => props.cellWidth}%;
      background: #F1F9FF;
      &.full-width{
        width: ${props => props.rowWidth}%;
        min-width: ${props => props.rowWidth}%;
      }
      &.attachments-cell {
        >div {
          width: ${props => props.attachmentsWidth}%;
        }
      }
    }
    .contact-merge-cell, >label {
      border-bottom: 1px solid var(--stroke-gray-1, #D2D2D2);
    }

    &:first-child {
      .contact-merge-cell, >label {
        border-top: 1px solid var(--stroke-gray-1, #D2D2D2);
      }
    }
    >div {
      width: calc(100% - 133px);
      display: flex;
      >div {

      }
    }
    .contact-merge-selector {
      width: 24px;
      margin-right: 8px;
    }
    .custom-checkbox {
      width: 28px!important;
      margin-left: 4px;
    }
    &.select-all-row {
      .contact-merge-cell, >label {
        border-top: none;
      }
    }
    a {
      text-decoration: underline;
    }
    &.no-diff {
      .contact-merge-cell, > label {
        background: white;
      }
    }
    &.error {
      > label {
        color: var(--text-warning, #E34848);
      }
    }
    .contact-merge-multi-cell {
      min-height: 24px;
    }
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(1) {
        flex: 1;
        label {
          color: var(--text-dark, #333) !important;
        }
      }
      &:nth-child(2) {
        >div + div {
          margin-left: 8px;
        }
      }
    }
  }
`;

function Selector ({ field, onCheck, checked }){
  if (field.isMultiple) {
    return <CustomCheckbox checked={checked} onCheck={onCheck} />;
  }
  return <RadioButton checked={checked} onCheck={onCheck} />;
}

class MergeContactView extends Component {
  state={ loading: true, tags: [], attachments: [], noFurtherUpdates: false };
  constructor(props, context) {
    super(props, context);
    this.prepareContacts(props.contacts);
  }

  async prepareContacts(__contacts) {
     let _contacts = [];
     __contacts.forEach(c => {
       _contacts.push(c);
       if (c.duplicateIds) {
         _contacts.push(...(c.duplicateIds.map(id => ({ id }))));
       }
     });
     _contacts = uniqBy(_contacts, c => c.id);
     _contacts = await Promise.all(_contacts.map(c => ContactBookApi.getContact(c.id).then(res => res.data)));
    const { contacts, tags, attachments} = prepareContactsForMerge(_contacts);
    const newState = {};
    newState.contacts = contacts;
    newState.tags = tags;
    newState.attachments = attachments;
    const cellWidth = Math.max(100 / contacts.length, 33.333333);
    newState.cellWidth = cellWidth;
    newState.rowWidth = cellWidth * contacts.length;
    newState.attachmentsWidth = (100 * 100) / newState.rowWidth;
    newState.loading = false;
    this.setState(newState);
  }

  handleToggleField = (fieldKey, contactIndex, subValueIndex, checked,) => {
    const field = mergeFields.find(f => f.key === fieldKey);
    const contacts = [...this.state.contacts];
    const contact = contacts[contactIndex];
    if (!field.isMultiple) {
      contacts.forEach((c, index) => {
        c[fieldKey].isSelected = index === contactIndex;
      });
    } else {
      contact[fieldKey].isSelected = !contact[fieldKey].isSelected;
      if (field.isArray && subValueIndex != null) {
        const subValue = contact[fieldKey].value[subValueIndex];
        subValue.isSelected = !subValue.isSelected;
      }
    }
    this.setState({ contacts });
  }

  handleToggleMainContact = (contactIndex) => {
    const contacts = [...this.state.contacts];
    contacts.forEach((contact, index) => {
      contact.isMainContact = index === contactIndex;
    });
    this.setState({ contacts });
  }
  handleSelectAll = (contactIndex) => {
        const contacts = [...this.state.contacts];
        const contact = contacts[contactIndex];
        mergeFields.forEach(field => {
          const val = contact[field.key];
          if (val.isSelectable) {
            if (!field.isMultiple) {
              contacts.forEach(c => {
                c[field.key].isSelected = false;
              });

            } else if (field.isArray) {
              val.value.forEach(v => {
                v.isSelected = true;
              });
            }
              val.isSelected = true;
          }
        });
        this.setState({ contacts });
  }
  handleDeleteTag = (id) => {
    this.setState({ tags: this.state.tags.filter(t => t.id !== id) });
  }
  handleDeleteAttachment = (index) => {
    this.setState({ attachments: this.state.attachments.filter((t, i) => index !== i) });
  }

   handleMerge = async () => {
        if (!this.state.contacts.some(c => c.primaryEmail.isSelected)) {
          return;
        }
        let reqBody = prepareContactsForMergeOnServer(this.state.contacts);
        reqBody.attachments = this.state.attachments;
        reqBody = await createContactAdapter(reqBody);
        reqBody.tags = this.state.tags;
        reqBody.noFurtherUpdates = this.state.noFurtherUpdates;
        const res = await this.props.onMerge(reqBody);
        if (res.data.id) {
          this.props.handleClose();
          PubSub.publish('contact:updated', res.data);
          history.push(`/$1/$2/person:${res.data.id}/-/-/---`);
        }
    }
  handleToggleNoUpdates = (e, checked) => {
    this.setState({ noFurtherUpdates: checked });
  }

  render() {
    return (
      <Pane
        bodyProps={{ style: { height: '100%' } }}
        headerProps={{
          useBorder: true,
          children: 'MERGING',
          onClose: this.props.handleClose,
        }}
        scrollable
      >
        {this.state.loading ? <Loader /> : (
          <Body cellWidth={this.state.cellWidth} rowWidth={this.state.rowWidth} attachmentsWidth={this.state.attachmentsWidth}>
            <div className={'contact-merge-row select-all-row no-diff'}>
              <label></label>
              <div>
                {this.state.contacts.map((c, contactIndex) => {
                  return (<div className="contact-merge-cell"> <a onClick={this.handleSelectAll.bind(this, contactIndex)} >Select All</a> </div>);
                })}
              </div>
            </div>
            <div className={'contact-merge-row'}>
              <label>Master Record</label>
              <div>
                {this.state.contacts.map((c, contactIndex) => {
                  return (<div className="contact-merge-cell"> <div className="contact-merge-selector">
                    <RadioButton checked={c.isMainContact} onCheck={this.handleToggleMainContact.bind(this, contactIndex)} />
                  </div></div>);
                })}
              </div>
            </div>
            {mergeFields.map(field => {
              if (!this.state.contacts[0][field.key].isSomeNotEmpty) {
                return null;
              }
              const noDiff = this.state.contacts[0][field.key].noDiff;
              let hasError = false;
              if (field.required) {
                hasError = !this.state.contacts.some(c => c[field.key].isSelected);
              }
              return (<div className={cx('contact-merge-row', noDiff && 'no-diff', hasError && 'error')}>
                <label>{field.label}</label>
                <div>{this.state.contacts.map((c, contactIndex) => {
                  const val = c[field.key];
                  if (field.isArray) {
                    return (<div className="contact-merge-cell">
                      <div >{field.renderer(field, c, this.handleToggleField.bind(this, field.key, contactIndex))}</div>
                    </div>);
                  }
                  return <div className="contact-merge-cell">
                    <div className="contact-merge-selector">
                      {val.isSelectable ? <Selector field={field} checked={val.isSelected} onCheck={this.handleToggleField.bind(this, field.key, contactIndex, null)} /> : null}
                    </div>
                    <div>{field.renderer(field, c, this.handleToggleField.bind(this, field.key, contactIndex))}</div>
                  </div>;
                })}</div>
              </div>);
            })}
            {this.state.tags.length ? (<div className={'contact-merge-row'}>
              <label>Tags</label>
              <div>
                <div className="contact-merge-cell full-width">
                  {this.state.tags.map(tag => <TagsChipSmall tag={tag} handleDelete={this.handleDeleteTag.bind(this, tag.id)} />)}
                </div>
              </div>
            </div>) : null}
            {this.state.attachments.length ? (<div className={'contact-merge-row'}>
              <label>Attachments</label>
              <div>
                <div className="contact-merge-cell full-width attachments-cell">
                  <div>
                    <Attachments files={this.state.attachments} handleRemoveFile={this.handleDeleteAttachment} />
                  </div>
                </div>
              </div>
            </div>) : null}
            <footer>
              <div>
                <CustomCheckbox checked={this.state.noFurtherUpdates} label={"Do not suggest any further updates to this contact "} onCheck={this.handleToggleNoUpdates} />
              </div>
              <div>
                <RaisedButton secondary label={'CANCEL'} onClick={this.props.handleClose} />
                <SpinnerButton primary label={'MERGE'} onClick={this.handleMerge} />
              </div>
            </footer>
          </Body>
        )}
      </Pane>
    );
  }
}

export default MergeContactView;


const simpleFieldRenderer = (field, contact) => contact[field.key].value || '---';

const arrayFieldRenderer = (field, contact, onSelect) => {
  const values = contact[field.key].value;
  return values.map((val, i) => <div className={'contact-merge-multi-cell flex'}>{val.isSelectable ? <CustomCheckbox checked={val.isSelected} onCheck={() => onSelect(i)} /> : <div className="contact-merge-selector" />}<div>{field.selector(val.value)}</div></div>);
}

const mergeFields = [
  {
    key: 'photoId',
    label: "Avatar",
    isMultiple: false,
    isArray: false,
    renderer: (key, contact) => {
      const _contact = { name: contact.name.value, photoId: contact.photoId.value };
      return <Avatar contact={_contact} />;
    }
  },
  {
    key: 'name',
    label: 'Full Name',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'primaryEmail',
    label: 'Email (Primary)*',
    isMultiple: true,
    isArray: false,
    renderer: simpleFieldRenderer,
    required: true,
  },
  {
    key: 'additionalEmails',
    label: 'Email (Additional)',
    isMultiple: true,
    isArray: true,
    renderer: arrayFieldRenderer,
    selector: val => val,
  },
  ...phoneTypes.map(pt => ({
    key: pt.id + '_phone',
    label: pt.name,
    isMultiple: true,
    isArray: true,
    renderer: arrayFieldRenderer,
    selector: val => val.number,
  })),
  {
    key: 'company',
    label: 'Company',
    isMultiple: false,
    isArray: false,
    renderer: (field, contact) => contact.company?.value?.name || '---',
  },
  {
    key: 'address',
    label: 'Company address',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'altAddress',
    label: 'Alternative address',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'position',
    label: 'Position',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'positionNotes',
    label: 'Notes (for Position)',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'skype',
    label: 'Skype',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'maritalStatus',
    label: 'Marital Status',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'birthDate',
    label: 'Birth Date',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'importantDates',
    label: 'Important Dates',
    isMultiple: true,
    isArray: true,
    renderer: arrayFieldRenderer,
    selector: (val) => `${date(val.date)} ${val.note || ''}`,
  },
  {
    key: 'website',
    label: 'Website',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'details',
    label: 'Details',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'linkedin',
    label: 'linkedin',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'twitter',
    label: 'twitter',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'facebook',
    label: 'facebook',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'messenger',
    label: 'messenger',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'instagram',
    label: 'instagram',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'telegramName',
    label: 'telegram',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'iceName',
    label: 'ice',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'kakaoName',
    label: 'kakao',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'yahoo',
    label: 'yahoo',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'youtube',
    label: 'Youtube',
    isMultiple: false,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
  {
    key: 'syncComments',
    label: 'Comments',
    isMultiple: true,
    isArray: false,
    renderer: simpleFieldRenderer,
  },
];

function prepareContactForMerge(contact, i) {
  contact = cloneDeep(contact);
  contact.primaryEmail = contact.emails[0];
  contact.additionalEmails = contact.emails.slice(1);
  contact.company = contact.companies?.[0];
  phoneTypes.forEach(pt => {
    const phones = contact.phones.filter(p => p.type === pt.id);
    contact[pt.id + '_phone'] = phones;
  });
  contact.syncComments = 'Synchronize' + ((new Array(i)).fill('\u200B')).join("");
  return contact;
}

function prepareContactsForMerge(contacts){
  contacts = fromServerContactAdapter(contacts.map(prepareContactForMerge));
  let tags = [];
  let attachments = [];
  for (let i = 0; i < mergeFields.length; i++) {
    const mf = mergeFields[i];
    let isSomeNotEmpty = false;
    const valuesSet = new Set();
    let isSelected;
    let selectableCount = 0;

    for (let j = 0; j < contacts.length; j++) {
      const contact = contacts[j];
      contact.isMainContact = j === 0;
      let value = contact[mf.key];
      isSomeNotEmpty = !!(mf.isArray ? value?.length : value) || isSomeNotEmpty;
      const hasDuplicate = valuesSet.has(value?.id || value);

      valuesSet.add(value?.id || value);
      if (mf.isMultiple) {
        if (mf.isArray) {
          let someNotduplicate = false;
          value = value.map(v => {
            const _hasDuplicate = valuesSet.has(mf.selector(v));
            someNotduplicate = !_hasDuplicate || someNotduplicate;
            valuesSet.add(mf.selector(v));
            return ({ value: v, isSelected: !_hasDuplicate, isSelectable: !_hasDuplicate });
          });
          contact[mf.key] = {
            value: value,
            isSelectable: someNotduplicate,
            isSelected: someNotduplicate,
          };
        } else {
          contact[mf.key] = {
            value: value,
            isSelectable: !hasDuplicate,
            isSelected: !!value && !hasDuplicate,
          };
        }
      } else {
        contact[mf.key] = {
          value: value,
          isSelectable: !hasDuplicate,
        };
        if (value && !isSelected) {
          contact[mf.key].isSelected = true;
          isSelected = true;
        }
      }
      if (contact[mf.key].isSelectable) {
        selectableCount++;
      }
    }
    contacts.forEach(contact => {
      contact[mf.key].isSomeNotEmpty = isSomeNotEmpty;
      if (selectableCount < 2) {
        contact[mf.key].isSelectable = false;
        contact[mf.key].noDiff = true;
      }
    });
  }
  contacts.forEach(contact => {
    tags.push(...contact.tags);
    attachments.push(...contact.attachments);
  });
  tags = uniqBy(tags.filter(t => t.canEdit), "id");
 return { contacts, tags, attachments };
}

function prepareContactsForMergeOnServer(contacts){
  const mainContact = cloneDeep(contacts.find(c => c.isMainContact));
  const allContacts = cloneDeep(contacts);
  const sourceIdsForComments = [];
  const sourceIds = allContacts.filter(c => !c.isMainContact).map(c => c.id);
  const result = { id: mainContact.id, sourceIds, sourceIdsForComments };
  for (let i = 0; i < mergeFields.length; i++) {
    const field = mergeFields[i];
    let val = field.isMultiple ? [] : undefined;
    for (let j = 0; j < allContacts.length; j++) {
      const contact = allContacts[j];
      const _value = contact[field.key];
      if(field.key === 'syncComments') {
        if(_value.isSelected) {
          sourceIdsForComments.push(contact.id);
        }
        continue;
      }
      if (field.isMultiple) {
        if (field.isArray) {
          for (const subValue of _value.value) {
            if (subValue.isSelected && subValue.value) {
              val.push(subValue.value);
            }
          }
        } else {
          if (_value.isSelected) {
            val.push(_value.value);
          }
        }
      } else {
        if(_value.isSelected) {
          val = _value.value;
        }
      }
      result[field.key] = val;
    }
  }
  const phones = [];
  for (const pt of phoneTypes) {
    const values = result[pt.id + '_phone'];
    if (values?.length) {
      phones.push(...values);
    }
    result[pt.id + '_phone'] = undefined;
  }
  result.emails = [...result.primaryEmail, ...result.additionalEmails];
  result.primaryEmail = undefined;
  result.additionalEmails = undefined;
  result.phones = phones;
  result.companies = [result.company];
  result.company = undefined;
  return result;

}
