import React, {Component} from 'react';
import {connect} from "react-redux";
import { createContact, getContactDetails, updateContact, toggleFavorites } from "../../actions/contacts";
import Loader from "../Common/Loader";
import ContactView from "./ContactView";
import {ContactEditConnected} from "./ContactEdit";
import history from "../../core/history";
import ActionVisibilityOff from "material-ui/svg-icons/action/visibility-off";
import {fromServerContactAdapter} from "./contactBookUtils";
import PubSub from "pubsub-js";

function Whoops({ retry, close }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <ActionVisibilityOff style={{ width: '140px', height: '140px', fill: '#E0E0E0' }}/>
    <div>Contact could not be loaded. <a onClick={retry}>Try again</a>&nbsp;&nbsp;&nbsp;<a onClick={close}>Close</a></div>
  </div>
}

export class ContactDetails extends Component {

  static defaultProps = {
    onStartEdit: () => history.push('/$1/$2/$3/edit/-/---'),
    onCloseEdit: ({ action, contact }) => {
      if (action === 'none' && !contact.approved) {
        return history.push('/$1/$2/-/-/-/---');
      }
      history.push('/$1/$2/$3/-/-/---');
    },
    onCloseView: () => history.push(`/$1/$2/-/-/-/---`),
    companyLinkPropsGenerator: (c) => ({ to: `/$1/$2/company:${c.id}/-/-/---` }),
    getHistoryBlockPredicate: undefined,
  }

  state = { loading: true };
  async componentDidMount() {
    if (this.props.contactId) {
      await this.fetchContact(this.props.contactId);
    } else if (this.props.draft) {
      this.setState({ contact: { ...this.props.draft }, loading: false});
    }
    PubSub.subscribe('contact:updated', this.socketHandler);
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.socketHandler);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.contactId !== this.props.contactId) {
      await this.fetchContact(nextProps.contactId);
    }
  }

  socketHandler = (topic, data) => {
    if (data.id === this.props.contactId) {
      this.fetchContact(data.id);
    }
  }


  async fetchContact(id) {
    try {
      if (!id) {
        return;
      }
      this.setState({ loading: true, contact: null });
      const res = await this.props.getContactDetails(id);
      if (res.data) {
        this.setState({ contact: fromServerContactAdapter(res.data) });
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  startEdit = () => {
    this.props.onStartEdit();
  }
  closeEdit = ({ action, id }) => {
    if (action && action !== 'none') {
      this.fetchContact(this.props.contactId);
    }
    this.props.onCloseEdit({ action, id, contact: this.state.contact });
  }

  renderError(){
    return <Whoops retry={()=> this.fetchContact(this.props.contactId)} close={this.props.onCloseView}></Whoops>
  }

  handleToggleFavorites = async () => {
    const res = await this.props.toggleFavorites([this.state.contact]);
    if (res.status > 399) {
      return;
    }
    this.setState({ contact: { ...this.state.contact, faved: !this.state.contact.faved } });
  }



  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    if (!this.state.contact?.id && !this.props.draft) {
      return this.renderError();
    }
    if (this.props.action === 'edit' || (this.props.draft && !this.state.contact?.id)) {
      return <div style={{ height: "100%" }}>
        <ContactEditConnected
          contact={this.state.contact}
          onClose={this.closeEdit}
          action={'edit-contact'}
          getHistoryBlockPredicate={this.props.getHistoryBlockPredicate}
        />
      </div>
    }
    return (
      <div style={{ height: "100%" }}>
        <ContactView
          openEmailsList={this.props.openEmailsList}
          closeEmailsList={this.props.closeEmailsList}
          contact={this.state.contact}
          onEdit={this.startEdit}
          updateContact={this.props.updateContact}
          toggleFavorites={this.handleToggleFavorites}
          onClose={this.props.onCloseView}
          companyLinkPropsGenerator={this.props.companyLinkPropsGenerator}
          isGlobalPopover={this.props.isGlobalPopover}
        />
      </div>
    );
  }
}

export const ContactDetailsConnected = connect(state => ({
  ...state.contacts,
  user: state.login.user,
  tagCategoriesByName: state.login.tagCategoriesByName,
}), {
  createContact,
  getContactDetails,
  updateContact,
  toggleFavorites,
})(ContactDetails);
