const DEFAULT_STATUSTEXT = {
  empty: '',
  accepted: 'Confirmed',
  pending: 'Invited - not responded',
  rejected: 'Rejected',
};
const ROLES = {
  cargoOwner: {
    key: 'cargoOwner',
    label: 'Charter/Merchant',
    statusText: DEFAULT_STATUSTEXT,
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {
        edit: true,
      },
      receiver: {
        edit: true,
      },
      notify: {},
      shipperAgent: {
        edit: true,
        entrustTo: 'carrierAgent',
      },
      carrierAgent: {},
      captain: {},
      carrier: {},
    },
  },
  shipOwner: {
    key: 'shipOwner',
    label: 'Carrier',
    statusText: DEFAULT_STATUSTEXT,
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {},
      notify: {},
      shipperAgent: {},
      carrierAgent: {
        edit: true,
        entrustTo: 'shipperAgent',
      },
      captain: {
        edit: true,
      },
      carrier: {},
    },
  },
  carrier: {
    key: 'carrier',
    label: 'Carrier',
    statusText: DEFAULT_STATUSTEXT,
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {},
      notify: {},
      shipperAgent: {},
      carrierAgent: {
        edit: true,
        entrustTo: 'shipperAgent',
      },
      captain: {
        edit: true,
      },
      carrier: {},
    },
  },
  shipper: {
    key: 'shipper',
    label: 'Shipper',
    statusText: DEFAULT_STATUSTEXT,
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {
        edit: true,
      },
      notify: {},
      shipperAgent: {
        edit: true,
        entrustTo: 'carrierAgent',
      },
      carrierAgent: {},
      captain: {},
      carrier: {},
    },
  },
  receiver: {
    key: 'receiver',
    label: 'Receiver',
    statusText: { ...DEFAULT_STATUSTEXT, empty: 'Shipper has not added a receiver yet' },
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {},
      notify: {
        edit: true,
      },
      shipperAgent: {},
      carrierAgent: {},
      captain: {},
      carrier: {},
    },
  },
  shipperAgent: {
    key: 'shipperAgent',
    label: 'Shipper’s Agent',
    addLabel: 'Agent',
    statusText: {
      ...DEFAULT_STATUSTEXT,
      empty: 'Shipper has not added an agent yet',
      entrusted: 'Entrusted to Carrier Agent',
    },
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {},
      notify: {},
      shipperAgent: {},
      carrierAgent: {},
      captain: {},
      carrier: {},
    },
  },
  carrierAgent: {
    key: 'carrierAgent',
    label: 'Carrier’s Agent',
    addLabel: 'Agent',
    statusText: {
      ...DEFAULT_STATUSTEXT,
      empty: 'Carrier has not added an agent yet',
      entrusted: 'Entrusted to Shipper`s Agent',
    },
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {},
      notify: {},
      shipperAgent: {},
      carrierAgent: {},
      captain: {},
      carrier: {},
    },
  },
  captain: {
    key: 'captain',
    label: 'Master/Captain',
    addLabel: 'Captain',
    statusText: { ...DEFAULT_STATUSTEXT, empty: 'Carrier has not added a captain yet' },
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {},
      notify: {},
      shipperAgent: {},
      carrierAgent: {},
      captain: {},
      carrier: {},
    },
  },
  notify: {
    key: 'notify',
    label: 'Notify',
    statusText: { ...DEFAULT_STATUSTEXT, empty: 'Receiver has not added a notify yet' },
    relations: {
      cargoOwner: {},
      shipOwner: {},
      shipper: {},
      receiver: {},
      notify: {},
      shipperAgent: {},
      carrierAgent: {},
      captain: {},
      carrier: {},
    },
  },

};

export default ROLES;
