import PropTypes from 'prop-types';
import React, { Component } from 'react';
import s from './SimpleForm.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import TextArea from './../../Monitor/Edit/TextArea';
import RaisedButton from '../../Common/RaisedButton';
import Cargo from './../../../core/api/Cargo';
import Loader from './../../Common/Loader';
import { grabErrorMessage, sleep } from '../../../core/utils';
import { toggleSubscribeDialog } from './../../../actions/login';
import { connect } from 'react-redux';
import FormsyText from '../../Common/FormsyText';
import ConfirmDialog from '../../Common/ConfirmDialog';
import adaptCargoFieldsFromServer from '../adapters/adaptCargoFieldsFromServer';
import {findContainerTypeById} from "../../dictionaries";
import logger from "../../../core/logger";

const mockFields = recognizedFields => {
  recognizedFields.cargo = Array(10).fill(recognizedFields.cargo[0]);
  recognizedFields.loading = Array(10).fill(0).map((_, index) => ({...recognizedFields.loading[0], i: index}) );
  recognizedFields.unloading = Array(10).fill(0).map((_, index) => ({...recognizedFields.unloading[0], i: index}) );
  return recognizedFields;
}


const copyByType = values => {
  if (!values) return values;

  if (Array.isArray(values)) {
    return [...values];
  }
  if (typeof values === "object" && values !== null) {
    return { ...values };
  }
}

// this dictionary holds keys in recognition object that can be recognized and send from backend invalid
// if it happens, i delete invalid fields from recognition object as if it wasn't recognized to prevent setting form with invalid values

const isDateValid = value => !isNaN(new Date(value));
// if method returns true, value is valid, otherwise it's considered invalid
export const canBeRecognizedInvalid = {
  "readinessDate": isDateValid,
  "cancellingDate": isDateValid,
}

export const getRidOfPossibleInvalidFields = dictionaryFields => {
  const _eraser = (values) => {
    if (!values) return values;

    values = copyByType(values);

    Object.keys(values).forEach(key => {
      if (typeof values[key] === "object" && values[key] !== null && !(values[key] instanceof Date)) {
        values[key] = _eraser(values[key]);
      }
      if (key && Object.prototype.hasOwnProperty.call(dictionaryFields, key)) {
        const isFieldValid = dictionaryFields[key](values[key]);
        if (!isFieldValid) {
          Reflect.deleteProperty(values, key);
        }

        return values;
      }
    });

    return values;
  };

  return _eraser;
}



function adaptSimpleFormValues(recognizedFields){
  if (!recognizedFields) {
    return recognizedFields;
  }
 if (recognizedFields.cargo) {
   recognizedFields.cargo.forEach(c => {
     if (c.containerDetails) {
       if (c.containerDetails.kind?._id) {
         c.containerDetails.kind = c.containerDetails.kind._id;
         if (c.cargoId === c.containerDetails.kind) {
           const containerType = findContainerTypeById(c.containerDetails.kind);
           if (containerType){
             c.containerForm = containerType.containerForm;
           }
         }
       }
       if (c.packing?.pack) {
         const pack = c.packing.pack;
         if (pack.unitWeight && !pack.bundleWeight) {
           pack.bundleWeight = pack.unitWeight;
         }
       }
     }
   });
 }
 return recognizedFields;
}

export class SimpleForm extends Component {

  static contextTypes = {
    showMessage: PropTypes.func,
    selector: PropTypes.func,
    onChange: PropTypes.func,
    cargoTypes: PropTypes.array,
  }

  state = {
    //isSubmitted: false,
    recognizedFields: null,
    // keeps state if user used recognition in simple form (to display "show original message")
    isUsedRecognition: false,
    recognizeErr: null,
    isLoading: false,
    isUserLimited: false,
    isChanged: false,
    nothingWasRecognized: false,
  }

  onChange = this.context.onChange("simpleForm")("");

  setValue = async val => {
    try {
      await this.onChange(val);
      this.setState({
        isChanged: true,
        isSubmitted: false,
      });
    } catch (error) {
      console.error(error);
    }
  }

  handlePreview = async () => {

    const { isUserLimited } = this.state;

    //this.setSubmitted();

    try {
      const recognizedFields = await this.handleSimpleFormRecognition();

      if (isUserLimited) {
        return this.props.toggleSubscribeDialog(true);
      }

      this.props.handleSimpleFormPreview();
    } catch (error) {
      if (!error) {
        this.props.handleNext();
      }
      console.error(error);
    }
  }

  handleConfirmPopup = userActionValue => {
    if (userActionValue) {
      this.handleConfirmOk();
    }
    this.toggleConfirmationDialog(false);
  }

  handleConfirmOk = () => {
    this.props.handleNext();
  }

  toggleConfirmationDialog = val => (
    this.setState({
      nothingWasRecognized: val,
    })
  )

  handleSimpleFormRecognition = async () => {

    const { isChanged } = this.state;

    const simpleForm = this.props.value;

    if (simpleForm.length < 1 || !isChanged) return;

    this.setState({
      isLoading: true,
    })

    try {
      const res = await Cargo.recognizeSimpleForm(simpleForm);
      logger.log({ kind: 'simple-form-request', request: { text: simpleForm }, response: { status: res.status, data: res.data } });
      if (!res.data) return;

      let recognizedFields = res.data[0];
      recognizedFields = adaptSimpleFormValues(recognizedFields);
      // clean up recognition object from invalid fields (if there are any)
      recognizedFields = getRidOfPossibleInvalidFields(canBeRecognizedInvalid)(recognizedFields);

      // mockFields(recognizedFields)

      this.setState({
        recognizedFields,
        isUsedRecognition: true,
        isChanged: false,
      });

      const mappedFields = adaptCargoFieldsFromServer(recognizedFields, this.context.cargoTypes);
      this.props.populateRequestValues(mappedFields);
      await sleep(500);
      if (!recognizedFields) {
        // recognition is successfull but nothing was recognized (prevent navigating further and show popup)
        this.toggleConfirmationDialog(true);
        throw {
          name: "RecognitionError",
          message: "Nothing was recognized",
          status: 204,
        };
      }

      return recognizedFields;


    } catch (error) {
      logger.log({ kind: 'simple-form-error', request: { text: simpleForm } }, error);
      if (error?.status === 204) {
        // response is successfull but nothing was recognized (no content).
        // stop going to Preview or Next and show confirmation popup
        throw error;
      }

      this.context.showMessage({
        message: `${grabErrorMessage(error)}`,
        level: "error",
      });
      console.error(error);
      this.setState({
        recognizeErr: error,
      });

      if (error?.status === 429) {
        this.props.toggleSubscribeDialog(true);
        // prevent showing subscription dialog every time unless user changed form to let user navigate to next step
        this.setState({
          isChanged: false,
          recognizedFields: null,
          isUserLimited: true,
        }, () => {
          // if we encounter limit error, reset all values from form
          this.props.populateRequestValues(adaptCargoFieldsFromServer({}, this.context.cargoTypes));
        });
        // prevent from continuing to Preview, but keep navigation to next step
        throw null;
      }

      throw error;
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  // setSubmitted = () => this.setState({
  //   isSubmitted: true,
  // })

  handleNext = async (ev) => {
    let isErr = null;
    try {
      //this.setSubmitted();
      //ev.preventDefault();
      await this.handleSimpleFormRecognition();
      this.props.handleNext();
    } catch (error) {
      isErr = error;
      if (!isErr) {
        this.props.handleNext();
      }
      console.error(error);
    }
  }

  render() {

    const { isLoading, isSubmitted, isUsedRecognition, recognizedFields, nothingWasRecognized } = this.state;

    const simpleForm = this.props.value;
    let textAreaValidationProps = {}

    // if (isSubmitted) {
    //   textAreaValidationProps.validations = "minLength:30";
    //   textAreaValidationProps.validationError = "minimum 30 characters";
    // }

    return (
      // data-id is used to find simple form step from Request component and open it when button "BACK TO SIMPLE FORM" is clicked in Preview
      <div data-id="simpleForm">
        {
          nothingWasRecognized
            && (
              <ConfirmDialog
                handleClose={this.handleConfirmPopup}
              >
                Your request could not be processed. Please make sure your request is typed in properly and contains all the required data, or add it manually.
              </ConfirmDialog>
            )
        }
        {
          isLoading && <Loader opaque />
        }
        <FormsyText
            name="isUsedRecognition"
            style={{display: "none"}}
            value={isUsedRecognition}
        />
        {
          simpleForm?.length && isUsedRecognition
            ? (
              <FormsyText
                  name="mailId"
                  style={{ display: "none" }}
                  value={recognizedFields ? recognizedFields.mailId : ""}
              />
            )
            : null
        }
        <TextArea placeholder="Enter your cargo..." {...textAreaValidationProps} value={simpleForm} onChange={this.setValue} style={{maxWidth: "664px", width: "100%", height: "160px", resize: "none", borderRadius: "8px" }} name="simpleForm" />
        <div style={{ display: 'flex', marginTop: "25px" }}>
          <RaisedButton
            label="NEXT"
            disableTouchRipple
            disableFocusRipple
            primary
            disabled={isLoading}
            style={{ marginRight: '12px' }}
            onClick={this.handleNext}
          />
          {
            this.props.handleSimpleFormPreview && simpleForm.length > 0 && (
              <RaisedButton
                disabled={isLoading}
                label="PREVIEW"
                disableTouchRipple
                disableFocusRipple
                primary
                onClick={this.handlePreview}
              />
            )
          }
        </div>
      </div>
    )
  }
}

export default connect(null, { toggleSubscribeDialog })(withStyles(s)(SimpleForm));
