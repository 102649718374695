exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1VVl0 {\n  width: 100%;\n  height: 100%;\n  color: #333;\n  font-size: 12px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  padding-bottom: 48px; }\n  ._1VVl0 ._2LhTy {\n    color: var(--text-dark);\n    font-weight: bold;\n    font-size: 12px;\n    height: 48px;\n    text-transform: uppercase; }\n  ._1VVl0 ._1-cBq {\n    height: 100%;\n    overflow: auto; }\n  ._1VVl0 ._1K7-5 {\n    display: -ms-flexbox;\n    display: flex;\n    border-bottom: 1px solid #e6e6e6; }\n  ._1VVl0 ._1K7-5:nth-child(even) {\n    background-color: rgba(0, 0, 0, 0.02); }\n  ._1VVl0 ._3jDNd {\n    height: 48px;\n    padding: 0px 8px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center; }\n  ._1VVl0 ._3jDNd._39G3u {\n      margin-left: auto; }\n  ._1VVl0 ._3jDNd._39G3u > * + * {\n        margin-left: 8px; }\n", ""]);

// Exports
exports.locals = {
	"root": "_1VVl0",
	"header": "_2LhTy",
	"body": "_1-cBq",
	"row": "_1K7-5",
	"col": "_3jDNd",
	"actions": "_39G3u"
};