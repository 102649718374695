import Vessel from '../core/api/Vessel';
import CargoApi from '../core/api/Cargo';
import {
  MY_SNP_LIST,
  VESSEL_UPDATE_SALE_OFFER,
  VESSEL_UPDATE_TC,
  VESSEL_UPDATE_HIRE_IDEA,
  VESSEL_REQUEST,
  VESSEL_REQUEST_LIST,
  VESSEL_REQUEST_FILTER,
  VESSEL_REQUEST_EXPIRED,
  VESSEL_REQUEST_GET_CARGO,
  VESSEL_TCOFFER_FILTER,
  VESSEL_TCOFFER_LIST,
  VESSEL_TCOFFER_FIXED,
  VESSEL_TCOFFER_REJECTED,
  VESSEL_TCOFFER_SENT,
  MY_VESSEL_LIST,
  MY_VESSEL_LIST_FILTER,
  SNP_ADD_SINGLE,
  SNP_LIST_FILTER,
  SNP_UPDATE_SINGLE,
  SHOW_HIDE_OFFERS,
  SNP_UPDATE_ALL,
  SNP_REMOVE_BY_ID,
  POSITIONS_BY_VESSEL_ID,
  POSITION_REMOVE_BY_ID,
  POSITION_UPDATE_BY_ID,
  VESSEL_CLAIM_RIGHTS, SENT_TCOFFER_LIST,
  VESSEL_DELETE,
  VESSEL_RATING_SAVE,
  FLEET_UPDATE_SINGLE_VESSEL,
  FLEET_ADD_CREATED_VESSEL,
  VESSEL_UPDATE,
  MONITOR_VESSEL_CHANGES_SEEN,
  VESSEL_ADD_TO_SELECTED,
  FLEET_UPDATE_ALL_VESSELS,
  VESSEL_FILTER_FLEET,
  FLEET_VESSEL_CHANGES_SEEN,
  MONITOR_VESSEL_CHANGED,
  FLEET_VESSEL_CHANGED,
  SNP_VESSEL_CHANGED,
  VESSEL_GET, VESSEL_GET_TCINDEX, VESSEL_UPDATE_TCINDEX,
  SENT_TCOFFER_TOGGLE_STYLE,
} from '../constants/vessel';
import { NAVIGATE } from '../constants/index';
import { AFTER_REGISTER } from '../constants/register';
import { prepareRequest, grabErrorMessage } from '../core/utils';
import ChannelStatus from './../components/Common/ChannelStatus';
import {cloneDeep} from "lodash";

export function sendOpenPosition(request, user) {
  return async (dispatch) => {
    if (!user) {
      dispatch({
        type: AFTER_REGISTER,
        payload: {
          message: 'Please sign up or sign in to complete your request.',
          after: sendOpenPosition.bind(this, request),
        },
      });

      return dispatch({
        type: NAVIGATE,
        payload: { route: '/register' },
      });
    }
    const successMessage = `${ChannelStatus.statuses[request.userStatus]?.name || "Open"} position created`;

    try {
      const res = await Vessel.sendRequest(prepareRequest(request));
      if (!res.errors?.length) {
        dispatch({
          type: "MESSAGE",
          payload: {
            level: "success",
            message: successMessage
          }
        })
      }
      return res;
    } catch (error) {
      console.error(error);
      let errorMsg = grabErrorMessage(error);

      let errorMessage = {
        type: "MESSAGE",
        payload: {
          level: "error",
          autoDismiss: 5,
          message: errorMsg
        }
      }
      // because backend updates old position with the same ship, port and dates, we treat 409 (Conflict) as success and display success message saying ld position was updated.
      if (error?.message?.status === 409) {
        error.status = 200;
        return error;
      }

      dispatch(errorMessage);
      return error;
    }

    // return dispatch({
    //   type: VESSEL_REQUEST,
    //   promise: Vessel.sendRequest(prepareRequest(request)).catch(console.log),
    //   successMessage: 'Open position created',
    //   payload: request,
    // });
  };
}

export function getVesselRequestList(params, token) {
  return (dispatch, getState, history) => {
    if (!params.filters) {
      const state = getState();
      params.filters = state.vessel.requestList.filters;
      params.sort = state.vessel.requestList.sort;
    }
    dispatch({
      type: VESSEL_REQUEST_LIST,
      promise: Vessel.getVesselRequestList(prepareRequest(params), token),
      payload: params,
    });
  };
}

export function addCreatedVesselToFleet(vessel) {
  return {
    type: FLEET_ADD_CREATED_VESSEL,
    payload: vessel
  }
}

export function updateVesselInFleet(vessel) {
  return {
    type: FLEET_UPDATE_SINGLE_VESSEL,
    payload: vessel
  }
}
export function updateAllVesselsInFleet({
  field,
  val
}) {
  return {
    type: FLEET_UPDATE_ALL_VESSELS,
    payload: {
      field,
      val
    }
  }
}

export function vesselChangesSeen(id, changes) {
  return (dispatch, getState) => {
    const monitor = dispatch({
      type: MONITOR_VESSEL_CHANGES_SEEN,
      payload: {id, changes},
    });
    const fleet = dispatch({
      type: FLEET_VESSEL_CHANGES_SEEN,
      payload: {id, changes},
    });
    return { monitor, fleet }
  }
}

export function vesselChanged(id, changes) {
  return (dispatch, getState) => {
    const monitor = dispatch({
      type: MONITOR_VESSEL_CHANGED,
      payload: {id, changes},
    });
    const fleet = dispatch({
      type: FLEET_VESSEL_CHANGED,
      payload: {id, changes},
    });
    const snp = dispatch({
      type: SNP_VESSEL_CHANGED,
      payload: {id, changes},
    })
    return { monitor, fleet, snp }
  }
}

export function filterVesselRequestList(filters) {
  return {
    type: VESSEL_REQUEST_FILTER,
    payload: filters,
  };
}

export function filterVesselList(filters) {
  return {
    type: MY_VESSEL_LIST_FILTER,
    payload: filters,
  };
}

export function filterSnpList(filters) {
	return {
	  type: SNP_LIST_FILTER,
	  payload: filters,
	};
 }

export function vesselRequestExpired(key) {
  return {
    type: VESSEL_REQUEST_EXPIRED,
    payload: key,
  };
}

export function vrlGetCargoDetails({ cargoId, vesselId }) {
  return {
    type: VESSEL_REQUEST_GET_CARGO,
    promise: Vessel.getRequestInfo(vesselId, cargoId),
    payload: { cargoId, vesselId },
  };
}
export function vrlClearSelectedCargo() {
  return {
    type: VESSEL_REQUEST_GET_CARGO,
    res: { data: null },
  };
}

function prepareTcFilters(params) {
    params = cloneDeep(params);
    if (params?.filters) {
      params.filters.owner = Object.keys(params.filters.owner || {}).reduce((acc, key) => {
        if (params.filters.owner[key]) {
          acc.push(key);
        }
        return acc;
      }, []);
      if (!params.filters.search) {
        params.filters.search = undefined;
      }
    }
    return params;
}

export function getVesselTcOfferList(params, token) {
  return {
    type: VESSEL_TCOFFER_LIST,
    promise: Vessel.getVesselTcOfferList(prepareRequest(prepareTcFilters(params)), token),
    payload: params,
  };
}
export function getSentTcOfferList(params, token) {
  return {
    type: SENT_TCOFFER_LIST,
    promise: Vessel.getSentTcOfferList(prepareRequest(prepareTcFilters(params)), token),
    payload: params,
  };
}
export function getTcList(params, token) {
  return {
    type: SENT_TCOFFER_LIST,
    promise: Vessel.getTcList(prepareRequest(prepareTcFilters(params)), token),
    payload: params,
  };
}

export function changeTcStyle(style) {
  return {
    type: SENT_TCOFFER_TOGGLE_STYLE,
    payload: style,
  };
}

export function getPositionsByVesselId(vesselId) {
  return {
    type: POSITIONS_BY_VESSEL_ID,
    promise: Vessel.getPositionsByVesselId(vesselId),
  };
}

export function filterVesselTcOfferList(filters) {
  return {
    type: VESSEL_TCOFFER_FILTER,
    payload: filters,
  };
}
export function sendTcOffer(offer) {
  return {
    type: VESSEL_TCOFFER_SENT,
    payload: offer,
    promise: CargoApi.sendTcOffer(offer),
    successMessage: 'Offer sent',
  };
}
export function fixTcOffer({ bidId }) {
  return {
    type: VESSEL_TCOFFER_FIXED,
    payload: { bidId },
    promise: CargoApi.fixTcOffer(bidId),
    successMessage: 'Offer fixed',
  };
}
export function rejectTcOffer({ bidId }) {
  return {
    type: VESSEL_TCOFFER_REJECTED,
    payload: { bidId },
    promise: CargoApi.rejectTcOffer(bidId),
    successMessage: 'Offer rejected',
  };
}

export function getVesselsList({ sort, filters } = {}) {
  return function(dispatch, getState) {
    const { vessel: { vesselList: { sort: savedSort, filters: savedFilters } }, login: { lastFilters } } = getState();
    sort = sort || savedSort;
    filters = filters || savedFilters;
    if (lastFilters && lastFilters.myFleetFilter) {
      sort = { ...sort, ...lastFilters.myFleetFilter.sort };
      filters = { ...filters, ...lastFilters.myFleetFilter };
        delete filters.search;
        delete filters.query;
        if (filters.tags && !filters.tags.condition.length) {
          delete filters.tags;
        }
    }
    const promise = Vessel.getMyVessels({
      sort,
      filters
    });
    return dispatch({
      type: MY_VESSEL_LIST,
      promise,
      payload: { sort, filters }
    });
  }
}

export function removePositionById(vesselId, positionId) {
  return {
    type: POSITION_REMOVE_BY_ID,
    promise: Vessel.deletePositionById(vesselId, positionId),
    payload: positionId,
  };
}

export function updatePositionById(vesselId, positionId, request) {
  return {
    type: POSITION_UPDATE_BY_ID,
    promise: Vessel.updatePositionById(vesselId, positionId, request),
    payload: {
      request,
      positionId,
    },
  };
}

export function claimRights({ _id, name, imoNumber }) {
  return {
    type: VESSEL_CLAIM_RIGHTS,
    promise: Vessel.claimRights(_id),
    payload: _id,
    successMessage: `Claim rights request to ship ${name} (${imoNumber}) sent`,
  };
}
export function updateVesselById(vesselId, vessel) {
  return {
    type: VESSEL_CLAIM_RIGHTS,
    promise: Vessel.updateVesselById(vesselId, vessel),
    payload: vessel,
    successMessage: 'Ship updated',
  };
}

export function updateExclusionsAndRestrictions(vesselId, {cargoExclusionsV2, tradingRestrictions}) {
  return {
    type: VESSEL_UPDATE,
    promise: Vessel.updateExclusionsAndRestrictions(vesselId, {cargoExclusionsV2, tradingRestrictions}),
    payload: {cargoExclusionsV2, tradingRestrictions},
    successMessage: 'Ship updated',
  };
}

export function updatePreferences(vesselId, {cargoPreferences, areaPreferences}) {
  return {
    type: VESSEL_UPDATE,
    promise: Vessel.updatePreferences(vesselId, {cargoPreferences, areaPreferences}),
    payload: { cargoPreferences, areaPreferences },
    successMessage: 'Ship updated',
  };
}

export function updateSpeedConsumption(vesselId, payload) {
  return {
    type:VESSEL_UPDATE,
    promise: Vessel.updateSpeedConsumption(vesselId, payload),
    payload,
    successMessage: 'Ship updated',
  };
}
export function updateAttachments(vesselId, {attachments,attachmentVisibility=[]}) {
  return {
    type: VESSEL_UPDATE,
    promise: Vessel.updateAttachments(vesselId, {attachments,attachmentVisibility}),
    payload: {attachments},
    successMessage: 'Ship updated',
  };
}
export function addNewVessel(vessel) {
  return {
    type: VESSEL_CLAIM_RIGHTS,
    promise: Vessel.addNewVessel(vessel),
    payload: vessel,
    successMessage: 'Ship created',
  };
}
export function deleteVessel(vessel) {
  return {
    type: VESSEL_DELETE,
    promise: Vessel.deleteVessel(vessel),
    payload: vessel,
  };
}
export function ratingSave(payload) {
  return {
    type: VESSEL_RATING_SAVE,
    promise: Vessel.saveVesselRating(payload),
    payload,
    successMessage: 'Rating change successful',
  };
}
export function commentSave(payload) {
  return {
    type: VESSEL_RATING_SAVE,
    promise: Vessel.saveVesselComment(payload),
    payload,
    successMessage: 'Comment added successfully',
  };
}

export function saveTCIndex(id, payload) {
  return {
    type: VESSEL_UPDATE_TC,
    promise: Vessel.saveTCIndex(id, payload),
    payload: {id, ...payload},
    successMessage: 'Vessel T/C Index saved'
  }
}

export function saveHireIdea(id, payload) {
	return {
	  type: VESSEL_UPDATE_HIRE_IDEA,
	  promise: Vessel.saveHireIdea(id, payload),
	  payload: {id, ...payload},
	  successMessage: 'Vessel Hire Idea saved'
	}
 }


export function updateAllSalesOrder({
  field,
  value
}) {
  return {
    type: SNP_UPDATE_ALL,
    payload: {
      field,
      value
    }
  }
}

export function updateSingleSalesOrder(salesOrder) {
  return {
    type: SNP_UPDATE_SINGLE,
    payload: salesOrder
  }
}

export function removeSingleOrderById(id) {
  return {
    type: SNP_REMOVE_BY_ID,
    payload: id
  }
}

export function addSingleSalesOrder(id, vesselId) {
  return function(dispatch, getState) {
    const {vessel: {snpList: {data: savedData, filtered: savedFiltered, sort: savedSort, filters: savedFilters, hidden: savedHidden}}} = getState();

    const alreadyInList = savedData.find(order => order.vessel._id === vesselId);

    if (alreadyInList) {
      console.log("ALREADY IN LIST");
      return null;
    }

    console.log("NOT IN LIST");

    return dispatch({
      type: SNP_ADD_SINGLE,
      promise: Vessel.getForSaleItem({id, filters: savedFilters}),
      payload: {sort: savedSort}
    })
  }
}

export function getSnpList({ sort, filters, page, hidden } = {}) {
  return function(dispatch, getState) {
    const { vessel: { snpList: { sort: savedSort, filters: savedFilters, hidden: savedHidden } }, login: { lastFilters } } = getState();
    sort = sort || savedSort;
    filters = filters || savedFilters;
    hidden = hidden === undefined ? savedHidden : hidden;
    if (lastFilters && lastFilters.snpFleetFilter) {
      sort = { ...sort, ...lastFilters.snpFleetFilter.sort };
      filters = { ...filters, ...lastFilters.snpFleetFilter };
        delete filters.search;
        delete filters.query;
        if (filters.tags && !filters.tags.condition.length) {
          delete filters.tags;
        }
    }
    const promise = Vessel.getForSaleList({sort, filters, page, hidden});

    return dispatch({
      type: MY_SNP_LIST,
      promise,
      payload: {sort, filters, page, hidden}
    })
  }
}

export function showHideOrders(selectedOrders) {
  return function(dispatch, getState) {
    const {vessel: {snpList: {sort: savedSort, filters: savedFilters, hidden}}} = getState();

    const selectedOrdersData = {
      vesselIds: selectedOrders.map(item => item?.vessel?._id),
      hide: !hidden
    }
    const successMessage = `${selectedOrders.length} ${selectedOrders.length > 1 ? "elements" : "element"} ${hidden ? "successfully removed from hidden" : "hidden successfully"}`

    return dispatch({
      type: SHOW_HIDE_OFFERS,
      promise: Vessel.updateHiddenOffers(selectedOrdersData),
      successMessage: successMessage
    })
  }
}

export function updateSaleOffer(payload) { // to be reused
  const {ideaPrice, salePrice, vesselId, offerId, isMine} = payload;

  return {
    type: VESSEL_UPDATE_SALE_OFFER,
    promise: isMine ? Vessel.updateForSaleOffer(offerId, {ideaPrice, salePrice}) : Vessel.markAsForSale(vesselId, {ideaPrice, salePrice}),
    payload,
    successMessage: 'Vessel Sale Offer Updated'
  }
}

export function addToSelected(payload) {
  return async function(dispatch, getState) {
    try {
      const msg = `${payload.selectedVessels.length > 1 ? `Vessels ${payload.selectedVessels.map(sV => sV?.name ? sV.name : '').join(', ')}` : `Vessel ${payload.selectedVessels[0]?.name ? payload.selectedVessels[0].name : ''}`}`;
      const action = payload.delete ? 'successfully removed from selected!' : 'successfully added to selected!';

      const res = await Vessel.addToSelected({
        ...payload,
        selectedVessels: payload.selectedVessels ? payload.selectedVessels.map(s => s?._id) : []
      });

      const baseObj = {
        type: VESSEL_ADD_TO_SELECTED,
        res,
        payload,
        successMessage: `${msg} ${action}`
      }

      dispatch({
        ...baseObj,
        promise: Promise.resolve(res),
      });

      dispatch({
        type: VESSEL_FILTER_FLEET,
        payload: payload.selectedVessels
      })

    } catch (error) {
      console.log(error);
      dispatch({
          ...baseObj,
          promise: Promise.reject(res),
      });
    }
  }
}

export function getVessel(idOrImo, token) {
  idOrImo = String(idOrImo).split('-')[0];
  return async function (dispatch, getState) {
    const state = getState();
    if (state.login.user) {
      return dispatch({
        type: VESSEL_GET,
        promise: (async () => ({ data: (await CargoApi.getCargoAndVessel('-', idOrImo, state.login.user, token)).vessel }))(),
        payload: { _id: idOrImo },
      });
    }
    return dispatch({
      type: VESSEL_GET,
      promise: Vessel.getPublicVessel(idOrImo),
      payload: { _id: idOrImo },
    });
  };
}

export function getTcIndex(id, filters) {
  return {
    type: VESSEL_GET_TCINDEX,
    promise: Vessel.findTcIndex({ vesselId: id, ...filters }),
    payload: id,
  };
}

export function updateTcIndex(payload) {
  return {
    type: VESSEL_UPDATE_TCINDEX,
    promise: Vessel.updateTcIndex(payload),
    payload,
  };
}
export function updateFixedRate(payload) {
  return {
    type: VESSEL_UPDATE_TCINDEX,
    promise: Vessel.updateFixedRate(payload),
    payload,
  };
}

