import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from './Pages.scss';
import cx from 'classnames';
import { confirmEmail, unsubscribe } from '../../../actions/login';
import { getLandingAction } from '../../../actions/runtime';
import RoadMap from './AboutUs/RoadMap';
import Conferences from './AboutUs/Conferences/Conferences';
import Partners from '../Partners';
import Media from "react-media";
import Dialog from '../../Common/Dialog';
import AboutUsPartners from './AboutUs/AboutUsPartners';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpened: false,
      handleClose: false,
    };
    this.visibilityState = {};
  }
  handleOpenDialog = (labelPosition) => {
    this.setState(
      {
        dialogOpened: true,
        handleClose: true,
        label: labelPosition,
      }
    );
  };
  handleCloseDialog = () => {
    this.setState({ dialogOpened: false });
  };
  render() {
    return (
      <div className={s.about_us}>
        <span className={s.anchor} id="top" />

        <div className={cx(s.firstscreen, s.page_about_us_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>About Us</h1>
                </div>
              </div>
            </div>

          </div>
        </div>


        <span className={s.anchor} id="our-story" />
        <div className={s.our_story}>
          <div className={s.container}>
            <h2 className={s.title_block}>Our story</h2>
            <div className={s.wraper_our_story}>
              <div>
                <p className={s.text_first}>SHIPNEXT was founded in May 2015 by Alexander Varvarenko, in an attempt to digitalize the work-flow related to email and data-processing related to shipping and transportation and freight negotiations.</p>

                <h3>Problem</h3>
                <p>At the time, VARAMAR, a shipping company founded by Alexander Varvarenko, experienced an increasing problem of having to deal with thousands of incoming email. Some of which referred to areas and sectors beyond the scope of VARAMAR’s activity. Most of the emails and requests, therefore, remained unattended. So did the numerous connections gained worldwide at trade shows, exhibitions and conferences.</p>

                <p>A growing flow of emails and requests also resulted in a growing problem, related to data search and data processing. Some of which was real-time. This process, historically, remained complex and time-consuming, and some of the time Commercial managers spent on data search was inefficient.</p>
              </div>
              <div>
                <h3>Data search automation</h3>
                <p>SHIPNEXT was initially designed to digitalize and automate the whole process associated with routine data processing and transport solution search. To help all those clients, who sent out hundreds of circulars in search of a transport solution, find their solution instantly. To create one common Shipping Marketplace, which would spare the industry of the traditional email circulars and manual work.</p>

                <h3>From emails to smart real-time data management</h3>
                <p>The bottleneck, however, was taking the users through an easy transition. Those, who are still used to sending random emails. This is why SHIPNEXT designed a state-of-the-art patented algorithm, using Natural Language Processing, Machine Learning, and Artificial Intelligence. It helps processes emails instantly as they reach fix@shipnext.com, and initiates Cargo-to-Ship and Ship-to-Cargo matching.<br />
                  This finally helped initiate the transition of the Shipping sector to digital freight trading, which continues to gain pace day ever since.</p>
              </div>
            </div>
          </div>
        </div>

        {/* <span className={s.anchor} id="around-the-world" />
        <div className={s.map_address}>
          <div className={s.container}>
            <h2 className={s.title_block}>Worldwide</h2>
            <Media query="(max-width: 767px)" defaultMatches={false}>
              {matches =>
                matches ? (
                  <div className={s.wrapper_map_address}>
                    <div
                      className={cx(s.position_icon, s.position_icon1)}
                      onClick={this.handleOpenDialog.bind(this, 'position1')}
                    />
                    <div
                      className={cx(s.position_icon, s.position_icon2)}
                      onClick={this.handleOpenDialog.bind(this, 'position2')}
                    />
                    <div
                      className={cx(s.position_icon, s.position_icon3)}
                      onClick={this.handleOpenDialog.bind(this, 'position3')}
                    />
                    <div
                      className={cx(s.position_icon, s.position_icon4)}
                      onClick={this.handleOpenDialog.bind(this, 'position4')}
                    />
                    <div
                      className={cx(s.position_icon, s.position_icon5)}
                      onClick={this.handleOpenDialog.bind(this, 'position5')}
                    />
                    {this.state.dialogOpened ?
                      <Dialog
                        handleClose={this.handleCloseDialog}
                        open={true}
                        bodyClassName={s.body_dialog}
                        contentClassName={s.content_dialog}
                      >
                        {this.state.label === 'position1' &&
                        <div className={s.hover_address}>
                          <h3>USA</h3>
                          <p>
                            <a href="tel:+ 1 (302) 3849820">+ 1 (302) 3849820</a><br />
                            <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                          </p>
                        </div>
                        }
                        {this.state.label === 'position2' &&
                        <div className={s.hover_address}>
                          <h3>Ukraine</h3>
                          <p>
                            <a href="tel:+380503901017">+380503901017</a><br />
                            <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                          </p>
                        </div>
                        }
                        {this.state.label === 'position3' &&
                        <div className={s.hover_address}>
                          <h3>Belgium</h3>
                          <p>
                            <a href="tel:+32487651114">+32 487 65 11 14</a><br />
                            <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                          </p>
                        </div>
                        }
                        {this.state.label === 'position4' &&
                        <div className={s.hover_address}>
                          <h3>Hong Kong</h3>
                          <p>
                            <a href="tel:+85291774510">+85291774510</a><br />
                            <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                          </p>
                        </div>
                        }
                        {this.state.label === 'position5' &&
                        <div className={s.hover_address}>
                          <h3>Singapore</h3>
                          <p>
                            <a href="tel:+ 65 (3) 1593184">+ 65 (3) 1593184</a><br />
                            <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                          </p>
                        </div>
                        }
                      </Dialog>
                      : null
                    }
                  </div>
                ) : (
                  <div className={s.wrapper_map_address}>
                    <div className={cx(s.position_icon, s.position_icon1)}>
                      <div className={s.hover_address}>
                        <h3>USA</h3>
                        <p>
                          <a href="tel:+ 1 (302) 3849820">+ 1 (302) 3849820</a><br />
                          <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                        </p>
                      </div>
                    </div>
                    <div className={cx(s.position_icon, s.position_icon2)}>
                      <div className={s.hover_address}>
                        <h3>Ukraine</h3>
                        <p>
                          <a href="tel:+380503901017">+380503901017</a><br />
                          <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                        </p>
                      </div>
                    </div>
                    <div className={cx(s.position_icon, s.position_icon3)}>
                      <div className={s.hover_address}>
                        <h3>Belgium</h3>
                        <p>
                          <a href="tel:+32487651114">+32 487 65 11 14</a><br />
                          <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                        </p>
                      </div>
                    </div>
                    <div className={cx(s.position_icon, s.position_icon4)}>
                      <div className={s.hover_address}>
                        <h3>Hong Kong</h3>
                        <p>
                          <a href="tel:+85291774510">+85291774510</a><br />
                          <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                        </p>
                      </div>
                    </div>
                    <div className={cx(s.position_icon, s.position_icon5)}>
                      <div className={s.hover_address}>
                        <h3>Singapore</h3>
                        <p>
                          <a href="tel:+ 65 (3) 1593184">+ 65 (3) 1593184</a><br />
                          <a href="mailto:support@shipnext.com">support@shipnext.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              }
            </Media>
          </div>
        </div> */}

        <span className={s.anchor} id="road-map" />
        <RoadMap />

        {/* <span className={s.anchor} id="conferences" />
        <Conferences /> */}

        <span className={s.anchor} id="conferences" />
        <AboutUsPartners />
      </div>
    );
  }
}


export default withStyles(s)(AboutUs);
