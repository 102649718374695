exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1dcGo {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center; }\n\n._3qSPi {\n  max-width: 405px; }\n\n._18XvA {\n  color: #000;\n  font-family: Roboto;\n  font-size: 64px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal; }\n\n._1PRlO {\n  color: #000;\n  font-family: Roboto;\n  font-size: 56px;\n  font-style: normal;\n  font-weight: 300;\n  line-height: normal; }\n\n._3w1TF {\n  margin: 32px 0;\n  color: #2D4642;\n  -webkit-font-feature-settings: 'clig' off, 'liga' off;\n          font-feature-settings: 'clig' off, 'liga' off;\n  font-family: Roboto;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "_1dcGo",
	"text": "_3qSPi",
	"title": "_18XvA",
	"semiTitle": "_1PRlO",
	"description": "_3w1TF"
};