/** @name CARGO_REQUEST_LIST_REQUEST_REDUCER */
export default function CARGO_REQUEST_LIST_REQUEST(state, action) {
  const newState = {};
  if (action.payload) {
    const newRequestList = { ...state.requestList };
    if (action.payload) {
      if (action.payload.filters) {
        const filters = { ...action.payload.filters };
        if (filters.weightMin) {
          filters.weightMin /= 1000;
        }
        if (filters.weightMax) {
          filters.weightMax /= 1000;
        }
        newRequestList.filters = filters;
      }
    }
    newRequestList.sort = action.payload.sort;
    newRequestList.loading = true;
    newState.requestList = newRequestList;
  }
  return { ...state, ...newState };
}
