import MenuItem from 'material-ui/MenuItem';
import React from 'react'
import NarrowSelect from '../../NewInputs/NarrowSelect';

export const FIOS = {
  label: 'FIOS',
  value: 'fios',
};
export const FILO = {
  label: 'FILO',
  value: 'filo',
};
export const LIFO = {
  label: 'LIFO',
  value: 'lifo',
};
export const LILO = {
  label: 'LILO',
  value: 'lilo',
};

export const units = [
  FIOS,
  FILO,
  LIFO,
  LILO,
];

function MethodsSelect({ as, value = FIOS.value, name = 'method', ...props }) {
  const El = as ?? NarrowSelect;

  return (
    <El
      name={name}
      value={value}
      {...props}
    >
      {
        units.map(({ value, label }) => <MenuItem value={value} primaryText={label} key={value} />)
      }
    </El>
  )
}

export default MethodsSelect;
