/* eslint-disable import/prefer-default-export */

/**@see  {@link LOGIN_REDUCER}*/
export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

/**@see  {@link OPENLOGIN_REDUCER}*/
export const OPENLOGIN = 'OPENLOGIN';

/**@see  {@link CLOSELOGIN_REDUCER}*/
export const CLOSELOGIN = 'CLOSELOGIN';

/**@see  {@link PROFILE_UPDATE_REDUCER}*/
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
/**@see  {@link PROFILE_UPDATE_REQUEST_REDUCER}*/
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
/**@see  {@link PROFILE_UPDATE_FAILED_REDUCER}*/
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

/**@see  {@link PROFILE_GET_REDUCER}*/
export const PROFILE_GET = 'PROFILE_GET';
/**@see  {@link PROFILE_GET_REQUEST_REDUCER}*/
export const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST';
/**@see  {@link PROFILE_GET_FAILED_REDUCER}*/
export const PROFILE_GET_FAILED = 'PROFILE_GET_FAILED';

/**@see  {@link LOGIN_GET_FORGOT_LINK_REDUCER}*/
export const LOGIN_GET_FORGOT_LINK = 'LOGIN_GET_FORGOT_LINK';
/**@see  {@link LOGIN_GET_FORGOT_LINK_REQUEST_REDUCER}*/
export const LOGIN_GET_FORGOT_LINK_REQUEST = 'LOGIN_GET_FORGOT_LINK_REQUEST';
/**@see  {@link LOGIN_GET_FORGOT_LINK_FAILED_REDUCER}*/
export const LOGIN_GET_FORGOT_LINK_FAILED = 'LOGIN_GET_FORGOT_LINK_FAILED';

/**@see  {@link LOGIN_VALIDATE_FORGOT_LINK_REDUCER}*/
export const LOGIN_VALIDATE_FORGOT_LINK = 'LOGIN_VALIDATE_FORGOT_LINK';
/**@see  {@link LOGIN_VALIDATE_FORGOT_LINK_REQUEST_REDUCER}*/
export const LOGIN_VALIDATE_FORGOT_LINK_REQUEST =
  'LOGIN_VALIDATE_FORGOT_LINK_REQUEST';
/**@see  {@link LOGIN_VALIDATE_FORGOT_LINK_FAILED_REDUCER}*/
export const LOGIN_VALIDATE_FORGOT_LINK_FAILED =
  'LOGIN_VALIDATE_FORGOT_LINK_FAILED';

/**@see  {@link LOGIN_SET_PASSWORD_REDUCER}*/
export const LOGIN_SET_PASSWORD = 'LOGIN_SET_PASSWORD';
/**@see  {@link LOGIN_SET_PASSWORD_REQUEST_REDUCER}*/
export const LOGIN_SET_PASSWORD_REQUEST = 'LOGIN_SET_PASSWORD_REQUEST';
/**@see  {@link LOGIN_SET_PASSWORD_FAILED_REDUCER}*/
export const LOGIN_SET_PASSWORD_FAILED = 'LOGIN_SET_PASSWORD_FAILED';

/**@see  {@link LOGIN_TOGGLE_SUBSCRIBE_DIALOG_REDUCER}*/
export const LOGIN_TOGGLE_SUBSCRIBE_DIALOG = 'LOGIN_TOGGLE_SUBSCRIBE_DIALOG';

/**@see  {@link OPEN_GLOBAL_COMPOSE_DIALOG_REDUCER}*/
export const OPEN_GLOBAL_COMPOSE_DIALOG = 'OPEN_GLOBAL_COMPOSE_DIALOG';
/**@see  {@link CLOSE_GLOBAL_COMPOSE_DIALOG_REDUCER}*/
export const CLOSE_GLOBAL_COMPOSE_DIALOG = 'CLOSE_GLOBAL_COMPOSE_DIALOG';

/**@see  {@link LOGIN_SUBSCRIBED_REDUCER}*/
export const LOGIN_SUBSCRIBED = 'LOGIN_SUBSCRIBED';

/**@see  {@link PROFILE_GET_ACCOUNTS_REDUCER}*/
export const PROFILE_GET_ACCOUNTS = 'PROFILE_GET_ACCOUNTS';
/**@see  {@link PROFILE_UPSERT_ACCOUNT_REDUCER}*/
export const PROFILE_UPSERT_ACCOUNT = 'PROFILE_UPSERT_ACCOUNT';
/**@see  {@link PROFILE_SYNC_KYC_ACCOUNT_REDUCER}*/
export const PROFILE_SYNC_KYC_ACCOUNT = 'PROFILE_SYNC_KYC_ACCOUNT';
/**@see  {@link PROFILE_UPSERT_ACCOUNT_REQUEST_REDUCER}*/
export const PROFILE_UPSERT_ACCOUNT_REQUEST = 'PROFILE_UPSERT_ACCOUNT_REQUEST';
/**@see  {@link PROFILE_UPSERT_ACCOUNT_FAILED_REDUCER}*/
export const PROFILE_UPSERT_ACCOUNT_FAILED = 'PROFILE_UPSERT_ACCOUNT_FAILED';

/**@see  {@link PROFILE_DELETE_ACCOUNT_REDUCER}*/
export const PROFILE_DELETE_ACCOUNT = 'PROFILE_DELETE_ACCOUNT';
/**@see  {@link PROFILE_DELETE_ACCOUNT_REQUEST_REDUCER}*/
export const PROFILE_DELETE_ACCOUNT_REQUEST = 'PROFILE_DELETE_ACCOUNT_REQUEST';
/**@see  {@link PROFILE_DELETE_ACCOUNT_FAILED_REDUCER}*/
export const PROFILE_DELETE_ACCOUNT_FAILED = 'PROFILE_DELETE_ACCOUNT_FAILED';

/**@see  {@link PROFILE_APPROVE_MEMBER_REDUCER}*/
export const PROFILE_APPROVE_MEMBER = 'PROFILE_APPROVE_MEMBER';
/**@see  {@link PROFILE_APPROVE_MEMBER_REQUEST_REDUCER}*/
export const PROFILE_APPROVE_MEMBER_REQUEST = 'PROFILE_APPROVE_MEMBER_REQUEST';
/**@see  {@link PROFILE_APPROVE_MEMBER_FAILED_REDUCER}*/
export const PROFILE_APPROVE_MEMBER_FAILED = 'PROFILE_APPROVE_MEMBER_FAILED';

/**@see  {@link PROFILE_DELETE_MEMBER_REDUCER}*/
export const PROFILE_DELETE_MEMBER = 'PROFILE_DELETE_MEMBER';
/**@see  {@link PROFILE_DELETE_MEMBER_REQUEST_REDUCER}*/
export const PROFILE_DELETE_MEMBER_REQUEST = 'PROFILE_DELETE_MEMBER_REQUEST';
/**@see  {@link PROFILE_DELETE_MEMBER_FAILED_REDUCER}*/
export const PROFILE_DELETE_MEMBER_FAILED = 'PROFILE_DELETE_MEMBER_FAILED';

/**@see  {@link LOGIN_VALIDATE_EMAIL_REDUCER}*/
export const LOGIN_VALIDATE_EMAIL = 'LOGIN_VALIDATE_EMAIL';
/**@see  {@link LOGIN_VALIDATE_EMAIL_REQUEST_REDUCER}*/
export const LOGIN_VALIDATE_EMAIL_REQUEST = 'LOGIN_VALIDATE_EMAIL_REQUEST';
/**@see  {@link LOGIN_VALIDATE_EMAIL_FAILED_REDUCER}*/
export const LOGIN_VALIDATE_EMAIL_FAILED = 'LOGIN_VALIDATE_EMAIL_FAILED';

/**@see  {@link LOGIN_CONFIRM_EMAIL_REDUCER}*/
export const LOGIN_CONFIRM_EMAIL = 'LOGIN_CONFIRM_EMAIL';
/**@see  {@link LOGIN_CONFIRM_EMAIL_REQUEST_REDUCER}*/
export const LOGIN_CONFIRM_EMAIL_REQUEST = 'LOGIN_CONFIRM_EMAIL_REQUEST';
/**@see  {@link LOGIN_CONFIRM_EMAIL_FAILED_REDUCER}*/
export const LOGIN_CONFIRM_EMAIL_FAILED = 'LOGIN_CONFIRM_EMAIL_FAILED';

/**@see  {@link LOGIN_REQUEST_CONFIRM_EMAIL_REDUCER}*/
export const LOGIN_REQUEST_CONFIRM_EMAIL = 'LOGIN_REQUEST_CONFIRM_EMAIL';
/**@see  {@link LOGIN_REQUEST_CONFIRM_EMAIL_REQUEST_REDUCER}*/
export const LOGIN_REQUEST_CONFIRM_EMAIL_REQUEST = 'LOGIN_REQUEST_CONFIRM_EMAIL_REQUEST';
/**@see  {@link LOGIN_REQUEST_CONFIRM_EMAIL_FAILED_REDUCER}*/
export const LOGIN_REQUEST_CONFIRM_EMAIL_FAILED = 'LOGIN_REQUEST_CONFIRM_EMAIL_FAILED';

/**@see  {@link LOGIN_UNSUBSCRIBE_REDUCER}*/
export const LOGIN_UNSUBSCRIBE = 'LOGIN_UNSUBSCRIBE';
/**@see  {@link LOGIN_UNSUBSCRIBE_REQUEST_REDUCER}*/
export const LOGIN_UNSUBSCRIBE_REQUEST = 'LOGIN_UNSUBSCRIBE_REQUEST';
/**@see  {@link LOGIN_UNSUBSCRIBE_FAILED_REDUCER}*/
export const LOGIN_UNSUBSCRIBE_FAILED = 'LOGIN_UNSUBSCRIBE_FAILED';

/**@see  {@link LOGIN_FEEDBACK_REDUCER}*/
export const LOGIN_FEEDBACK = 'LOGIN_FEEDBACK';
/**@see  {@link LOGIN_FEEDBACK_REQUEST_REDUCER}*/
export const LOGIN_FEEDBACK_REQUEST = 'LOGIN_FEEDBACK_REQUEST';
/**@see  {@link LOGIN_FEEDBACK_FAILED_REDUCER}*/
export const LOGIN_FEEDBACK_FAILED = 'LOGIN_FEEDBACK_FAILED';

/**@see  {@link LOGIN_GET_COUNTERS_REDUCER}*/
export const LOGIN_GET_COUNTERS = 'LOGIN_GET_COUNTERS';
/**@see  {@link LOGIN_GET_COUNTERS_REQUEST_REDUCER}*/
export const LOGIN_GET_COUNTERS_REQUEST = 'LOGIN_GET_COUNTERS_REQUEST';
/**@see  {@link LOGIN_GET_COUNTERS_FAILED_REDUCER}*/
export const LOGIN_GET_COUNTERS_FAILED = 'LOGIN_GET_COUNTERS_FAILED';

/**@see  {@link PROFILE_GET_LIMITATIONS_REDUCER}*/
export const PROFILE_GET_LIMITATIONS = 'PROFILE_GET_LIMITATIONS';
/**@see  {@link PROFILE_GET_LIMITATIONS_REQUEST_REDUCER}*/
export const PROFILE_GET_LIMITATIONS_REQUEST = 'PROFILE_GET_LIMITATIONS_REQUEST';
/**@see  {@link PROFILE_GET_LIMITATIONS_FAILED_REDUCER}*/
export const PROFILE_GET_LIMITATIONS_FAILED = 'PROFILE_GET_LIMITATIONS_FAILED';

/**@see  {@link PROFILE_UPDATE_WHITELIST_REDUCER}*/
export const PROFILE_UPDATE_WHITELIST = 'PROFILE_UPDATE_WHITELIST';
/**@see  {@link PROFILE_UPDATE_WHITELIST_REQUEST_REDUCER}*/
export const PROFILE_UPDATE_WHITELIST_REQUEST = 'PROFILE_UPDATE_WHITELIST_REQUEST';
/**@see  {@link PROFILE_UPDATE_WHITELIST_FAILED_REDUCER}*/
export const PROFILE_UPDATE_WHITELIST_FAILED = 'PROFILE_UPDATE_WHITELIST_FAILED';

/**@see  {@link PROFILE_UPDATE_BLACKLIST_REDUCER}*/
export const PROFILE_UPDATE_BLACKLIST = 'PROFILE_UPDATE_BLACKLIST';
/**@see  {@link PROFILE_UPDATE_BLACKLIST_REQUEST_REDUCER}*/
export const PROFILE_UPDATE_BLACKLIST_REQUEST = 'PROFILE_UPDATE_BLACKLIST_REQUEST';
/**@see  {@link PROFILE_UPDATE_BLACKLIST_FAILED_REDUCER}*/
export const PROFILE_UPDATE_BLACKLIST_FAILED = 'PROFILE_UPDATE_BLACKLIST_FAILED';

/**@see  {@link PROFILE_TOGGLE_LIMITATIONS_REDUCER}*/
export const PROFILE_TOGGLE_LIMITATIONS = 'PROFILE_TOGGLE_LIMITATIONS';
/**@see  {@link PROFILE_TOGGLE_LIMITATIONS_REQUEST_REDUCER}*/
export const PROFILE_TOGGLE_LIMITATIONS_REQUEST = 'PROFILE_TOGGLE_LIMITATIONS_REQUEST';
/**@see  {@link PROFILE_TOGGLE_LIMITATIONS_FAILED_REDUCER}*/
export const PROFILE_TOGGLE_LIMITATIONS_FAILED = 'PROFILE_TOGGLE_LIMITATIONS_FAILED';

/**@see  {@link PROFILE_ACCEPTGDPR_REDUCER}*/
export const PROFILE_ACCEPTGDPR = 'PROFILE_ACCEPTGDPR';
/**@see  {@link PROFILE_ACCEPTGDPR_REQUEST_REDUCER}*/
export const PROFILE_ACCEPTGDPR_REQUEST = 'PROFILE_ACCEPTGDPR_REQUEST';
/**@see  {@link PROFILE_ACCEPTGDPR_FAILED_REDUCER}*/
export const PROFILE_ACCEPTGDPR_FAILED = 'PROFILE_ACCEPTGDPR_FAILED';

/**@see  {@link PROFILE_DESTROY_REDUCER}*/
export const PROFILE_DESTROY = 'PROFILE_DESTROY';
/**@see  {@link PROFILE_DESTROY_REQUEST_REDUCER}*/
export const PROFILE_DESTROY_REQUEST = 'PROFILE_DESTROY_REQUEST';
/**@see  {@link PROFILE_DESTROY_FAILED_REDUCER}*/
export const PROFILE_DESTROY_FAILED = 'PROFILE_DESTROY_FAILED';


/**@see  {@link PROFILE_GET_INVITE_INFO_REDUCER}*/
export const PROFILE_GET_INVITE_INFO = 'PROFILE_GET_INVITE_INFO';
/**@see  {@link PROFILE_GET_INVITE_INFO_REQUEST_REDUCER}*/
export const PROFILE_GET_INVITE_INFO_REQUEST = 'PROFILE_GET_INVITE_INFO_REQUEST';
/**@see  {@link PROFILE_GET_INVITE_INFO_FAILED_REDUCER}*/
export const PROFILE_GET_INVITE_INFO_FAILED = 'PROFILE_GET_INVITE_INFO_FAILED';

/**@see  {@link PROFILE_PARTIAL_UPDATE_REDUCER}*/
export const PROFILE_PARTIAL_UPDATE = 'PROFILE_PARTIAL_UPDATE';

/**@see  {@link PROFILE_GET_FREE_REDUCER}*/
export const PROFILE_GET_FREE='PROFILE_GET_FREE';
/**@see  {@link PROFILE_GET_FREE_REQUEST_REDUCER}*/
export const PROFILE_GET_FREE_REQUEST='PROFILE_GET_FREE_REQUEST';
/**@see  {@link PROFILE_GET_FREE_FAILED_REDUCER}*/
export const PROFILE_GET_FREE_FAILED='PROFILE_GET_FREE_FAILED';

/**@see  {@link PROFILE_CHANGE_PAYMENT_CARD_REDUCER}*/
export const PROFILE_CHANGE_PAYMENT_CARD = 'PROFILE_CHANGE_PAYMENT_CARD';
/**@see  {@link PROFILE_CHANGE_PAYMENT_CARD_REQUEST_REDUCER}*/
export const PROFILE_CHANGE_PAYMENT_CARD_REQUEST = 'PROFILE_CHANGE_PAYMENT_CARD_REQUEST';
/**@see  {@link PROFILE_CHANGE_PAYMENT_CARD_FAILED_REDUCER}*/
export const PROFILE_CHANGE_PAYMENT_CARD_FAILED = 'PROFILE_CHANGE_PAYMENT_CARD_FAILED';

/**@see  {@link PROFILE_CANCEL_SUBSCRIPTION_REDUCER}*/
export const PROFILE_CANCEL_SUBSCRIPTION='PROFILE_CANCEL_SUBSCRIPTION';
/**@see  {@link PROFILE_CANCEL_SUBSCRIPTION_REQUEST_REDUCER}*/
export const PROFILE_CANCEL_SUBSCRIPTION_REQUEST='PROFILE_CANCEL_SUBSCRIPTION_REQUEST';
/**@see  {@link PROFILE_CANCEL_SUBSCRIPTION_FAILED_REDUCER}*/
export const PROFILE_CANCEL_SUBSCRIPTION_FAILED='PROFILE_CANCEL_SUBSCRIPTION_FAILED';

/**@see  {@link USER_STATS_REDUCER}*/
export const USER_STATS = 'USER_STATS';
/**@see  {@link USER_STATS_REQUEST_REDUCER}*/
export const USER_STATS_REQUEST = 'USER_STATS_REQUEST';
/**@see  {@link USER_STATS_FAILED_REDUCER}*/
export const USER_STATS_FAILED = 'USER_STATS_FAILED';

/**@see  {@link COMMON_COMMENT_SAVE_REDUCER}*/
export const COMMON_COMMENT_SAVE = 'COMMON_COMMENT_SAVE';
/**@see  {@link COMMON_COMMENT_SAVE_REQUEST_REDUCER}*/
export const COMMON_COMMENT_SAVE_REQUEST = 'COMMON_COMMENT_SAVE_REQUEST';
/**@see  {@link COMMON_COMMENT_SAVE_FAILED_REDUCER}*/
export const COMMON_COMMENT_SAVE_FAILED = 'COMMON_COMMENT_SAVE_FAILED';

/**@see  {@link PROFILE_SAVE_PREFERENCES_REDUCER}*/
export const PROFILE_SAVE_PREFERENCES = 'PROFILE_SAVE_PREFERENCES';
/**@see  {@link PROFILE_SAVE_PREFERENCES_REQUEST_REDUCER}*/
export const PROFILE_SAVE_PREFERENCES_REQUEST = 'PROFILE_SAVE_PREFERENCES_REQUEST';
/**@see  {@link PROFILE_SAVE_PREFERENCES_FAILED_REDUCER}*/
export const PROFILE_SAVE_PREFERENCES_FAILED = 'PROFILE_SAVE_PREFERENCES_FAILED';

/**@see  {@link PROFILE_GET_MAILGATES_STATE_REDUCER}*/
export const PROFILE_GET_MAILGATES_STATE = 'PROFILE_GET_MAILGATES_STATE';
/**@see  {@link PROFILE_GET_MAILGATES_STATE_REQUEST_REDUCER}*/
export const PROFILE_GET_MAILGATES_STATE_REQUEST = 'PROFILE_GET_MAILGATES_STATE_REQUEST';
/**@see  {@link PROFILE_GET_MAILGATES_STATE_FAILED_REDUCER}*/
export const PROFILE_GET_MAILGATES_STATE_FAILED = 'PROFILE_GET_MAILGATES_STATE_FAILED';

/**@see  {@link PROFILE_GET_AUTOTAGS_REDUCER}*/
export const PROFILE_GET_AUTOTAGS = 'PROFILE_GET_AUTOTAGS';
/**@see  {@link PROFILE_GET_AUTOTAGS_REQUEST_REDUCER}*/
export const PROFILE_GET_AUTOTAGS_REQUEST = 'PROFILE_GET_AUTOTAGS_REQUEST';
/**@see  {@link PROFILE_GET_AUTOTAGS_FAILED_REDUCER}*/
export const PROFILE_GET_AUTOTAGS_FAILED = 'PROFILE_GET_AUTOTAGS_FAILED';

/**@see  {@link PROFILE_DELETE_AUTOTAG_REDUCER}*/
export const PROFILE_DELETE_AUTOTAG = 'PROFILE_DELETE_AUTOTAG';
/**@see  {@link PROFILE_DELETE_AUTOTAG_REQUEST_REDUCER}*/
export const PROFILE_DELETE_AUTOTAG_REQUEST = 'PROFILE_DELETE_AUTOTAG_REQUEST';
/**@see  {@link PROFILE_DELETE_AUTOTAG_FAILED_REDUCER}*/
export const PROFILE_DELETE_AUTOTAG_FAILED = 'PROFILE_DELETE_AUTOTAG_FAILED';

/**@see  {@link PROFILE_SAVE_AUTOTAG_REDUCER}*/
export const PROFILE_SAVE_AUTOTAG = 'PROFILE_SAVE_AUTOTAG';
/**@see  {@link PROFILE_SAVE_AUTOTAG_REQUEST_REDUCER}*/
export const PROFILE_SAVE_AUTOTAG_REQUEST = 'PROFILE_SAVE_AUTOTAG_REQUEST';
/**@see  {@link PROFILE_SAVE_AUTOTAG_FAILED_REDUCER}*/
export const PROFILE_SAVE_AUTOTAG_FAILED = 'PROFILE_SAVE_AUTOTAG_FAILED';

/**@see  {@link PROFILE_ADD_AUTOTAG_REDUCER}*/
export const PROFILE_ADD_AUTOTAG = 'PROFILE_ADD_AUTOTAG';

/**@see  {@link PROFILE_GET_TAGS_CATEGORIES_REDUCER}*/
export const PROFILE_GET_TAGS_CATEGORIES = 'PROFILE_GET_TAGS_CATEGORIES';
/**@see  {@link PROFILE_GET_TAGS_CATEGORIES_REQUEST_REDUCER}*/
export const PROFILE_GET_TAGS_CATEGORIES_REQUEST = 'PROFILE_GET_TAGS_CATEGORIES_REQUEST';
/**@see  {@link PROFILE_GET_TAGS_CATEGORIES_FAILED_REDUCER}*/
export const PROFILE_GET_TAGS_CATEGORIES_FAILED = 'PROFILE_GET_TAGS_CATEGORIES_FAILED';

/**@see  {@link PROFILE_GET_MEMBERS_TAGS_REDUCER}*/
export const PROFILE_GET_MEMBERS_TAGS='PROFILE_GET_MEMBERS_TAGS';
/**@see  {@link PROFILE_GET_MEMBERS_TAGS_REQUEST_REDUCER}*/
export const PROFILE_GET_MEMBERS_TAGS_REQUEST='PROFILE_GET_MEMBERS_TAGS_REQUEST';
/**@see  {@link PROFILE_GET_MEMBERS_TAGS_FAILED_REDUCER}*/
export const PROFILE_GET_MEMBERS_TAGS_FAILED='PROFILE_GET_MEMBERS_TAGS_FAILED';

/**@see  {@link PROFILE_SAVE_MEMBERS_TAGS_REDUCER}*/
export const PROFILE_SAVE_MEMBERS_TAGS='PROFILE_SAVE_MEMBERS_TAGS';
/**@see  {@link PROFILE_SAVE_MEMBERS_TAGS_REQUEST_REDUCER}*/
export const PROFILE_SAVE_MEMBERS_TAGS_REQUEST='PROFILE_SAVE_MEMBERS_TAGS_REQUEST';
/**@see  {@link PROFILE_SAVE_MEMBERS_TAGS_FAILED_REDUCER}*/
export const PROFILE_SAVE_MEMBERS_TAGS_FAILED='PROFILE_SAVE_MEMBERS_TAGS_FAILED';

/**@see  {@link PROFILE_GET_LAST_FILTERS_REDUCER}*/
export const PROFILE_GET_LAST_FILTERS = 'PROFILE_GET_LAST_FILTERS';

/**@see  {@link PROFILE_SAVE_LAST_FILTERS_REDUCER}*/
export const PROFILE_SAVE_LAST_FILTERS = 'PROFILE_SAVE_LAST_FILTERS';

/**@see  {@link PROFILE_GET_ACCOUNT_REDUCER}*/
export const PROFILE_GET_ACCOUNT = 'PROFILE_GET_ACCOUNT';

/**@see  {@link ACCOUNT_COMPLIANCE_CREATE_REDUCER}*/
export const ACCOUNT_COMPLIANCE_CREATE = 'ACCOUNT_COMPLIANCE_CREATE';

/**@see  {@link ACCOUNT_COMPLIANCE_SIGN_REDUCER}*/
export const ACCOUNT_COMPLIANCE_SIGN = 'ACCOUNT_COMPLIANCE_SIGN';

/**@see  {@link ACCOUNT_COMPLIANCE_SAVE_REDUCER}*/
export const ACCOUNT_COMPLIANCE_SAVE = 'ACCOUNT_COMPLIANCE_SAVE';

/**@see  {@link ACCOUNT_COMPLIANCE_DELETE_REDUCER}*/
export const ACCOUNT_COMPLIANCE_DELETE = 'ACCOUNT_COMPLIANCE_DELETE';
