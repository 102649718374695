import React from 'react'
import styled from 'styled-components'

export default function EmptyList({ Icon, title, description, isMaterialIcon=false, maxTextWidth='570px', titleStyle={}, iconStyle={},descriptionStyle={},button, isBgDark=false }) {
  return (
    <StyledComponent isBgDark={isBgDark} style={{ ...titleStyle}}>
        {isMaterialIcon ? 
            <span className="material-icons">{Icon}</span>
            : <Icon width='100px' height='100px' style={{width:'48px',height:'48px',...iconStyle}} className={'icon ship_icon_big'} fill={'#D2D2D2'} />}
            {title}
            {Array.isArray(description) && description.length > 0 ? 
            description.map((singleDescription)=><span style={{maxWidth:maxTextWidth,...descriptionStyle}} className='empty-state'>{singleDescription}</span>)
            : <span style={{maxWidth:maxTextWidth,...descriptionStyle}} className='empty-state'>{description}</span>}
            {button ? button : null}
    </StyledComponent>
  )
}
const StyledComponent = styled.div`
line-height: 28px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
gap:8px;
flex-direction: column;
font-size: 18px;
color:${props => props.isBgDark ? '#FFFFFF' : '#333'};
font-weight: 400;
.material-icons {
    font-size: 48px;
    color:var(--stroke-light-gray1);
}
.ship_icon_big svg{
    width: 48px !important;
    height: 48px !important;
    margin: 22px 0;
    transform: none;
    fill:  #D2D2D2 !important;
  }

  .ship_icon_big  g{
    fill: #D2D2D2 !important;
    opacity: 1!important;
  }

.empty-state{
  color: ${props => props.isBgDark ? 'rgba(255,255,255,0.7)' : '#787878'};
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
`
