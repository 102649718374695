export const CONTACTS_GET_LIST = 'CONTACTS_GET_LIST';
export const CONTACTS_GET_LIST_REQUEST = 'CONTACTS_GET_LIST_REQUEST';
export const CONTACTS_GET_LIST_FAILED = 'CONTACTS_GET_LIST_FAILED';

export const CONTACTS_GET_COMPANIES_LIST = 'CONTACTS_GET_COMPANIES_LIST';
export const CONTACTS_GET_COMPANIES_LIST_REQUEST = 'CONTACTS_GET_COMPANIES_LIST_REQUEST';
export const CONTACTS_GET_COMPANIES_LIST_FAILED = 'CONTACTS_GET_COMPANIES_LIST_FAILED';

export const CONTACTS_CREATE_CONTACT = 'CONTACTS_CREATE_CONTACT';
export const CONTACTS_CREATE_CONTACT_REQUEST = 'CONTACTS_CREATE_CONTACT_REQUEST';
export const CONTACTS_CREATE_CONTACT_FAILED = 'CONTACTS_CREATE_CONTACT_FAILED';

export const CONTACTS_UPDATE_CONTACT = 'CONTACTS_UPDATE_CONTACT';
export const CONTACTS_UPDATE_CONTACT_REQUEST = 'CONTACTS_UPDATE_CONTACT_REQUEST';
export const CONTACTS_UPDATE_CONTACT_FAILED = 'CONTACTS_UPDATE_CONTACT_FAILED';

export const CONTACTS_CREATE_COMPANY = 'CONTACTS_CREATE_COMPANY';
export const CONTACTS_CREATE_COMPANY_REQUEST = 'CONTACTS_CREATE_COMPANY_REQUEST';
export const CONTACTS_CREATE_COMPANY_FAILED = 'CONTACTS_CREATE_COMPANY_FAILED';

export const CONTACTS_UPDATE_COMPANY = 'CONTACTS_UPDATE_COMPANY';
export const CONTACTS_UPDATE_COMPANY_REQUEST = 'CONTACTS_UPDATE_COMPANY_REQUEST';
export const CONTACTS_UPDATE_COMPANY_FAILED = 'CONTACTS_UPDATE_COMPANY_FAILED';

export const CONTACTS_GET_CONTACT = 'CONTACTS_GET_CONTACT';
export const CONTACTS_GET_CONTACT_REQUEST = 'CONTACTS_GET_CONTACT_REQUEST';
export const CONTACTS_GET_CONTACT_FAILED = 'CONTACTS_GET_CONTACT_FAILED';

export const CONTACTS_GET_COMPANY = 'CONTACTS_GET_COMPANY';
export const CONTACTS_GET_COMPANY_REQUEST = 'CONTACTS_GET_COMPANY_REQUEST';
export const CONTACTS_GET_COMPANY_FAILED = 'CONTACTS_GET_COMPANY_FAILED';

export const CONTACTS_CREATE_GROUP = 'CONTACTS_CREATE_GROUP';
export const CONTACTS_CREATE_GROUP_REQUEST = 'CONTACTS_CREATE_GROUP_REQUEST';
export const CONTACTS_CREATE_GROUP_FAILED = 'CONTACTS_CREATE_GROUP_FAILED';

export const CONTACTS_UPDATE_GROUP = 'CONTACTS_UPDATE_GROUP';
export const CONTACTS_UPDATE_GROUP_REQUEST = 'CONTACTS_UPDATE_GROUP_REQUEST';
export const CONTACTS_UPDATE_GROUP_FAILED = 'CONTACTS_UPDATE_GROUP_FAILED';

export const CONTACTS_GET_GROUP = 'CONTACTS_GET_GROUP';
export const CONTACTS_GET_GROUP_REQUEST = 'CONTACTS_GET_GROUP_REQUEST';
export const CONTACTS_GET_GROUP_FAILED = 'CONTACTS_GET_GROUP_FAILED';

export const CONTACTS_EXPAND_GROUP = 'CONTACTS_EXPAND_GROUP';

export const CONTACTS_SELECT_CONTACT = 'CONTACTS_SELECT_CONTACT';

export const CONTACTS_DELETE_COMPANY = 'CONTACTS_DELETE_COMPANY';
export const CONTACTS_DELETE_COMPANY_REQUEST = 'CONTACTS_DELETE_COMPANY_REQUEST';
export const CONTACTS_DELETE_COMPANY_FAILED = 'CONTACTS_DELETE_COMPANY_FAILED';

export const CONTACTS_DELETE_CONTACT = 'CONTACTS_DELETE_CONTACT';
export const CONTACTS_DELETE_CONTACT_REQUEST = 'CONTACTS_DELETE_CONTACT_REQUEST';
export const CONTACTS_DELETE_CONTACT_FAILED = 'CONTACTS_DELETE_CONTACT_FAILED';

export const CONTACTS_TOGGLE_FAVORITES = 'CONTACTS_TOGGLE_FAVORITES';
export const CONTACTS_TOGGLE_FAVORITES_REQUEST = 'CONTACTS_TOGGLE_FAVORITES_REQUEST';
export const CONTACTS_TOGGLE_FAVORITES_FAILED = 'CONTACTS_TOGGLE_FAVORITES_FAILED';

export const CONTACTS_MERGE_CONTACTS = 'CONTACTS_MERGE_CONTACTS';
export const CONTACTS_MERGE_CONTACTS_REQUEST = 'CONTACTS_MERGE_CONTACTS_REQUEST';
export const CONTACTS_MERGE_CONTACTS_FAILED = 'CONTACTS_MERGE_CONTACTS_FAILED';

export const CONTACTS_GET_COUNTERS = 'CONTACTS_GET_COUNTERS';
export const CONTACTS_GET_COUNTERS_REQUEST = 'CONTACTS_GET_COUNTERS_REQUEST';
export const CONTACTS_GET_COUNTERS_FAILED = 'CONTACTS_GET_COUNTERS_FAILED';

export const CONTACTS_GET_HEATMAP = 'CONTACTS_GET_HEATMAP';
export const CONTACTS_GET_HEATMAP_REQUEST = 'CONTACTS_GET_HEATMAP_REQUEST';
export const CONTACTS_GET_HEATMAP_FAILED = 'CONTACTS_GET_HEATMAP_FAILED';
