exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3X117 {\n  margin: 0;\n  font-size: 12px;\n  font-weight: 400;\n  white-space: pre-line;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  padding-bottom: 14px; }\n  ._1QXCC {\n    width: 100%;\n    display: -ms-flexbox;\n    display: flex;\n    padding-bottom: 8px;\n    border-bottom: 1px solid var(--stroke-light-gray2); }\n  ._1QXCC > div:first-child {\n      -ms-flex: 1 1;\n          flex: 1 1; }\n  ._3iT24 {\n  line-height: 19px;\n  color: var(--text-dark);\n  margin: 0px;\n  width: 100%;\n  padding-top: 8px; }\n  .J5wgw {\n  font-weight: 700;\n  line-height: 18px;\n  color: #333;\n  margin: 0px;\n  font-size: 12px;\n  padding-bottom: 4px; }\n  .J5wgw._1cqMT {\n    width: 100%;\n    padding-bottom: 6px;\n    border-bottom: 1px solid #E6E6E6;\n    word-break: break-word; }\n  ._3kotf {\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  -ms-flex-pack: justify;\n      justify-content: space-between; }\n  ._3IWxg {\n  margin-left: 4px;\n  margin-top: -1px; }\n  ._3IWxg a {\n    line-height: 20px;\n    color: #4380C7;\n    text-decoration: underline !important; }\n  ._2z208 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex: 1 1;\n      flex: 1 1; }\n  ._2yceX {\n  line-height: 20px;\n  color: var(--text-medium); }\n  ._17Pax {\n  font-size: 10px;\n  text-align: right; }\n", ""]);

// Exports
exports.locals = {
	"body_message": "_3X117",
	"body_message_header": "_1QXCC",
	"body_message_text": "_3iT24",
	"body_message_bold": "J5wgw",
	"subject": "_1cqMT",
	"body_message_from_date": "_3kotf",
	"body_message_mail": "_3IWxg",
	"body_message_mails": "_2z208",
	"body_message_light": "_2yceX",
	"ref": "_17Pax"
};