import Api, {convertToSameTimeZ} from './api';
import {sleep, UUIDToObjectId} from '../utils';
import lodash from "lodash";

export default class CalculatorV2Api extends Api {
  static endpoint = '/calculator';

  static fetch(url, params, retry = 0) {
    if (params.body) {
      params.body = JSON.stringify(params.body);
    }
    return super.fetch(url, params, '/api/v2', retry);
  }

  static async create(calculator, name, type = 'freightCalculator') {
    calculator = convertToSameTimeZ(lodash.cloneDeep(calculator));
    calculator.version = 3;
    return await CalculatorV2Api.fetch('/calculator', { method: 'POST', body: { name, parameters: calculator, type } });
  }

  static async update(_id, name, status, calculator) {
    calculator = convertToSameTimeZ(lodash.cloneDeep(calculator));
    calculator.version = 3;
    const res = await CalculatorV2Api.fetch(`/calculator/${_id}/${status}`, { method: 'PUT', body: { name, parameters: calculator } });
    if (res.status >= 400) {
      return res;
    }
    return await this.findById(_id);
  }

  static async fix(_id, status) {
    const res = await CalculatorV2Api.fetch(`/calculator/${_id}/${status}/fix`, { method: 'POST', body: { } });
    if (res.status >= 400) {
      return res;
    }
    return await this.findById(_id);
  }
  static async remove(_id, status) {
    if (status === 'actual') {
      return await CalculatorV2Api.fetch(`/calculator/${_id}/${status}`, { method: 'DELETE' });
    }
    return await CalculatorV2Api.fetch(`/calculator/${_id}`, { method: 'DELETE' });
  }

  static async findById(_id) {
    return await CalculatorV2Api.fetch(`/calculator/${_id}`, { method: 'GET' });
  }

  static async getList(page = 1, _filters = {}){
    //await sleep(300);
    //return { data: generateList(page) }
    const filters = {};
    filters.search = _filters.search || undefined;
    if (!Object.keys(_filters).length) {
      filters.myCalculations = false;
    }
    if (_filters.status) {
      if (_filters.status.planned && !_filters.status.actual) {
        filters.calculations = 'planned';
      }
      if (!_filters.status.planned && _filters.status.actual) {
        filters.calculations = 'actual';
      }
    }
    if (_filters.types) {
      if (_filters.types.intermodalCalculator && !_filters.types.freightCalculator) {
        filters.type = 'intermodalCalculator';
      }
      if (!_filters.types.intermodalCalculator && _filters.types.freightCalculator) {
        filters.type = 'freightCalculator';
      }
      filters.myCalculations = _filters.types.myCalculations;
    }
    return await CalculatorV2Api.fetch(`/calculator/list`, { method: 'POST', body: { page: page, filters }});
  }
  static async getListByEntityId(entityType, entityId, page = 1, _filters = {}){
    const filters = {};
    filters.search = _filters.search || undefined;
    if (!Object.keys(_filters).length) {
      filters.myCalculations = false;
    }
    if (_filters.status) {
      if (_filters.status.planned && !_filters.status.actual) {
        filters.calculations = 'planned';
      }
      if (!_filters.status.planned && _filters.status.actual) {
        filters.calculations = 'actual';
      }
    }
    if (_filters.types) {
      if (_filters.types.intermodalCalculator && !_filters.types.freightCalculator) {
        filters.type = 'intermodalCalculator';
      }
      if (!_filters.types.intermodalCalculator && _filters.types.freightCalculator) {
        filters.type = 'freightCalculator';
      }
      filters.myCalculations = _filters.types.myCalculations;
    }
    return await CalculatorV2Api.fetch(`/calculator/${entityType}/${entityId}`, { method: 'POST', body: { page: page, filters } });
  }
}


function generateList(page) {
 return new Array(25).fill(0).map((v, i) => ({ _id: '_id' + page + i, name: "voyage " + page + i, planned: { parameters: { vessel: { name: 'tbn' } } }, actual: { parameters: { vessel: { name: 'tbn' } } }}));
}
