import {formatRange, formatTypeOfTransportation, number} from '../../core/format';
import {findContainerTypeById} from "../dictionaries";


export function constructCargoTitle(cargoInArray) {
  const { cargo, cargoType = cargo?.cargoType, stowage, packing, typeOfTransportation } = cargoInArray;
  let { containerDetails } = cargoInArray;
  const pack = stowage || packing?.pack;
  const cargoName = cargo?.name || cargoInArray?.cargoName?.name || cargoInArray?.cargoName || cargoType?.name;
  if (!cargoName) {
    return 'Enter cargo name';
  }
  containerDetails = containerDetails || pack.containerDetails;
  if (typeOfTransportation === 'container') {
    const containerType = findContainerTypeById(containerDetails?.kind) || { name: cargoName };
    if (!containerType) {
      return cargoName;
    }
    return `${pack.bundlesQuantity || 1}x${containerType.name}`;
  }
  const totalVolume = pack?.unitsVolume?.value;
  const totalVolumeUnit = pack?.unitsVolume?.unit || 'm3';
  const totalWeight = pack?.unitsWeight?.value;
  const totalWeightUnit = pack?.unitsWeight?.unit || 'mt';
  const totalVolumeMin = pack?.unitsVolume?.valueMin;
  const totalVolumeMax = pack?.unitsVolume?.valueMax;
  const totalWeightMin = pack?.unitsWeight?.valueMin;
  const totalWeightMax = pack?.unitsWeight?.valueMax;
  const isSpread = pack?.stowageSpread;
  const volume = isSpread ? formatRange(totalVolumeMin, totalVolumeMax, ' - ', number) : formatRange(totalVolume, null, ' - ', number);
  const weight = isSpread ? formatRange(totalWeightMin, totalWeightMax, ' - ', number) : formatRange(totalWeight, null, ' - ', number);

  const values = new Array(4);
  values[0] = cargoName;
 /* values[1] = formatTypeOfTransportation(typeOfTransportation);*/
  if (weight) {
    values[2] = weight + ' ' + totalWeightUnit;
  }
  if (volume) {
    values[3] = volume + ' ' + totalVolumeUnit;
  }
  return values.filter(v => !!v).join(', ');
}
