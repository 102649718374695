/** @name GET_NEWS_CATEGORIES_REDUCER */
export default function GET_NEWS_CATEGORIES(state, action) {
  const newState = {};

  newState.newsList = {
    ...state.newsList,
    categoriesList: action.res.data,
    errorMessage: '',
  };

  return { ...state, ...newState };
}
