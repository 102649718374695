import produce from 'immer';

/** @name EMAILS_SHARE_EMAIL_REDUCER */
export default function EMAILS_SHARE_EMAIL(state, action) {
  return produce(state, draft => {
    const messageInList = draft.emailList.data.find(m => m.id === action.payload.id);
    if (draft.details.selectedEmail?.id === action.payload.id) {
      draft.details.selectedEmail.visibleTo = action.res.data.visibleTo;
    }
    if (messageInList) {
      messageInList.isPrivate = action.res.data.visibleTo.length < 2;
    }
  });
}
