import React, { PureComponent } from 'react';
import cx from 'classnames';
import s from './Subscription.scss';
import { getFractional } from '../../core/utils';
import Media from 'react-media';
import StyledToggle from '../Common/StyledToggle';


export default class PlanDetailsFull extends PureComponent {
  state = {featuresOpened: true, allFeaturesOpened: false};

  constructor(props) {
    super(props);
    if(this.props.defaultClosedFeatures) {
      this.state.featuresOpened = false;
    }
  }


  toggleFeatures = () => {
    this.setState({featuresOpened : !this.state.featuresOpened}, ()=> {
      window.dispatchEvent(new Event('resize'));
    });

  };
  openAllFeatures = () => {
    this.setState({allFeaturesOpened: true})
  }


  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const currentPlan = this.props.plan;
    const stripePlan = currentPlan.stripePlan;
    const styleMonth = { color: this.state.Toggled ? '#ffffff' : 'rgba(255,255,255, 0.5)' };
    const styleYear = { color: this.state.Toggled ? 'rgba(255,255,255, 0.5)' : '#ffffff' };
    return (<div className={cx(s.wrapper_features_plan, s.wrapper_features_current_plan, s[currentPlan.className])}>
      <div className={s.title_plan} onClick={this.toggleFeatures}>
        <div>
          <h2>{currentPlan?.titleSubscription}</h2>
          {stripePlan ?
            <p className={s.wrapper_price}>
              <span className={s.currency}>$</span>
              <span className={s.figures}>{parseInt(stripePlan.realAmount)}</span>
              <span className={s.fractional}>{getFractional(stripePlan.realAmount)}</span>
              <span className={s.units}>/ {stripePlan.perShort}</span>
            </p>
            :
            <p className={s.wrapper_price}>
              <span className={s.figures}>{currentPlan.key === 'free' && 'FREE'}</span>
            </p>
          }

        </div>
        {this.props.cycleToggleEnabled && stripePlan ? (
          <div className={cx(s.toggle, s[stripePlan.interval])}>
            <span style={styleMonth} className={cx(s.period_month, stripePlan.interval !== 'year' && s.active)}>Monthly</span>
            <StyledToggle
              onClick={(e) => e.stopPropagation()}
              labelPosition="right"
              toggled={stripePlan.interval === 'year'}
              onToggle={this.props.onBillingCycleToggled}
              style={{
                display: 'inline-block',
                width: 'auto',
                verticalAlign: 'middle',
              }}
              labelStyle={{ width: 'auto' }}
              iconStyle={{ margin: '0 6px  0 15px' }}
            />
            <span style={styleYear} className={cx(s.period_year, stripePlan.interval === 'year' && s.active)}>Yearly</span>
            <br />
            <span className={s.save}>
                Save $ {Math.abs(stripePlan.saving)} a year
            </span>
          </div>
        ) : null}

      </div>
      {
        this.state.featuresOpened ?
          <div className={s.wrapper_features}>
            <ul className={s.part_features}>
              {currentPlan?.featuresAllFirstHalf.map(feature => (
                <li>{feature}</li>
              ))}
            </ul>
            <Media query="(max-width: 767px)" defaultMatches={false}>
              {matches =>
                matches ? (
                  <ul className={s.part_features}>
                    <li
                      className={s.btn_more}
                      onClick={this.openAllFeatures}
                      style={{
                        display: this.state.allFeaturesOpened && 'none'
                      }}
                    >
                      <div>+ more<span className={cx(s.arrow, this.state.allFeaturesOpened && s.open)} /></div>
                    </li>
                    {this.state.allFeaturesOpened ?
                      currentPlan?.featuresAllLastHalf.map(feature => (
                        <li>{feature}</li>
                      ))
                      : null
                    }
                  </ul>
                ) : (
                  <ul className={s.part_features}>
                    {currentPlan?.featuresAllLastHalf.map(feature => (
                      <li>{feature}</li>
                    ))}
                  </ul>
                )}
            </Media>
          </div>
          : null
      }
    </div>);
  }
}
