import React from "react";

const Info = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 4.688 4.688 2 8 2C11.312 2 14 4.688 14 8C14 11.312 11.312 14 8 14C4.688 14 2 11.312 2 8ZM8.59997 11H7.39997V7.4H8.59997V11ZM7.99998 12.8C5.35398 12.8 3.19998 10.646 3.19998 8C3.19998 5.354 5.35398 3.2 7.99998 3.2C10.646 3.2 12.8 5.354 12.8 8C12.8 10.646 10.646 12.8 7.99998 12.8ZM8.59997 6.2H7.39997V5H8.59997V6.2Z"
        fill="#285596"
      />
    </svg>
  );
};
export default Info;
