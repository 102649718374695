import produce from "immer";

/** @name CONTACTS_GET_COMPANIES_LIST_REQUEST_REDUCER */
 export default function CONTACTS_GET_COMPANIES_LIST_REQUEST(state, { payload }) {
  return produce(state, draft => {
    draft.companyList.filters = payload.filters;
    draft.companyList.sort = payload.sort;
    draft.companyList.loading = true;
    draft.contactList.filters.countryId = payload.filters.countryId;
  });
}
