import React, { Component } from 'react'
import VesselAutocomplete from '../Common/VesselAutocomplete';
import styles from './muistyles'
import cx from 'classnames'
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class NarrowVesselAutocomplete extends Component {
    render() {
        const {inputStyles, classes, ...props} = this.props;
        return (
            <VesselAutocomplete
                className={cx("input_default", classes)}
                textFieldStyle={styles.container}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={styles.text.label}
                hintStyle={styles.text.label}
                inputStyle={{...styles.text.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={styles.text.underline}
                underlineFocusStyle={styles.text.underlineFocus}
                errorStyle={styles.error}
                {...props}
                ref={node => this.input = node}
            >

            </VesselAutocomplete>
        )
    }
}

export default NarrowVesselAutocomplete;
