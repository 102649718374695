import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import styled from 'styled-components';
import { Collapse } from '../Common/Collapse';
import ExtendedForm from '../Common/ExtendedForm';
import { NarrowPortAutocomplete } from '../NewInputs/NarrowPortAutocomplete';
import RaisedButton from '../Common/RaisedButton';
import CalculatorInput from '../Calculator/Input';
import PortAutocomplete from '../Common/PortAutocomplete';
import CheckBox from '../Common/CheckBox';
import FormsyDate from '../Common/FormsyDate';
import { dateShortLocal, number } from '../../core/format';
import { v4 as uuid } from 'uuid';
import { AddButtonBlue } from '../Common/AddButton';
import { connect } from 'react-redux';
import { getTcIndex, updateTcIndex, updateFixedRate } from '../../actions/vessel';
import ViaAutocomplete from '../Common/ViaAutocomplete';
import lodash from 'lodash';
import cx from 'classnames';
import { clearEmptyStrings, toUTC } from '../../core/utils';
import BlurIfNoSubscription from '../Signup/BlurIfNoSubscription';

const Input = (props) => <CalculatorInput {...props} locale={'en-US'} decimalSeparator="." separator="," />;

class HireIndices extends PureComponent {

  static contextTypes = {
    showMessage: PropTypes.func,
  };

  state = {
    hireIdeas: [],
    tcIndices: [],
    fixedRates: [],

  };

  async componentDidMount() {
    this.setState( await this.fetchData());
  }

  componentWillReceiveProps(nextProps) {

  }

  async fetchData() {
    const { regionTo, regionFrom } = this.state;
    const res = await this.props.getTcIndex(this.props.vessel._id, { regionTo: regionTo?._id, regionFrom: regionFrom?._id });
    /*res.data.hireIdeas.expired = [...res.data.hireIdeas.alive].map(a => ({ ...a, _id: uuid() }));
    res.data.tcIndexes.expired = [...res.data.tcIndexes.alive].map(a => ({ ...a, _id: uuid() }));*/
    const hireIdeas = [...res.data.hireIdeas.alive, ...(res.data.hireIdeas.expired.map(hi => ({
      ...hi,
      expired: true,
    })))];
    const tcIndices = [...res.data.tcIndexes.alive, ...(res.data.tcIndexes.expired.map(ti => ({
      ...ti,
      expired: true,
    })))];
    const fixedRates = [...res.data.fixedRates.alive, ...(res.data.fixedRates.expired.map(fr => ({
      ...fr,
      expired: true,
    })))];
    return { hireIdeas, tcIndices, fixedRates };
  }

  saveHireIdea = async (ideas) => {
    const deleted = ideas.filter(idea => idea._id && idea.isDeleted && !idea.isNew).map(idea => idea._id);
    const updated = ideas.filter(idea => idea.isUpdated && !idea.isDeleted).map(idea => {
      let lay, can;
      if (idea.lay) {
        lay = new Date(idea.lay);
        lay.setHours(0, 0, 0, 0);
        lay = toUTC(lay);
      }
      if (idea.can) {
        can = new Date(idea.can);
        can.setHours(23, 59, 59, 999);
        can = toUTC(can);
      }
      return ({
        ...idea,
        vesselId: this.props.vessel._id,
        _id: idea.isNew ? undefined : idea._id,
        lay,
        can,
      });
    });
   const res = await this.props.updateTcIndex({ 'delete': deleted, 'update': updated });
   this.props.onSave?.();
   if (!res.errors?.length) {
    const message = res.data.message;
     this.context.showMessage({
      message,
    });
   }

  }
  saveFixedRate = async (rates) => {
    const deleted = rates.filter(rate => rate._id && rate.isDeleted && !rate.isNew).map(rate => rate._id);
    const updated = rates.filter(rate => rate.isUpdated && !rate.isDeleted).map(rate => {
      let lay, can;
      if (rate.lay) {
        lay = new Date(rate.lay);
        lay.setHours(0, 0, 0, 0);
        lay = toUTC(lay);
      }
      if (rate.can) {
        can = new Date(rate.can);
        can.setHours(23, 59, 59, 999);
        can = toUTC(can);
      }
      return ({
        ...rate,
        vesselId: this.props.vessel._id,
        _id: rate.isNew ? undefined : rate._id,
        lay,
        can,
      });
    });
   const res = await this.props.updateFixedRate({ 'delete': deleted, 'update': updated });
   this.props.onSave?.();
  //  if (!res.errors?.length) {
  //    this.setState(await this.fetchData());
  //  }
  if (!res.errors?.length) {
    const message = res.data.message;
     this.context.showMessage({
      message,
    });
   }
  }

  onIndexCollapse = (opened) => {
    this.setState({ indexCollapsed: !opened });
  }
  onHireCollapse = (opened) => {
    this.setState({ hireCollapsed: !opened });
  }
  onRatesCollapse = (opened) => {
    this.setState({ ratesCollapsed: !opened });
  }

  onRegionFromChanged = (regionFrom) => {
    this.setState({ regionFrom });
  }
  onRegionToChanged = (regionTo) => {
    this.setState({ regionTo });
  }

  clearFilters = () => {
    this.setState({ regionTo: null, regionFrom: null }, async () => this.setState(await this.fetchData()));
  }
  search = async () => {
    this.setState(await this.fetchData());
  }


  render() {
    return (
      <Wrapper>
        <Header><h4>Hire Indexes</h4>  <span onClick={this.props.onClose}
                                             className="material-icons-outlined">close</span> </Header>
        <Body>
          <Filters>
            <ExtendedForm>
              <div>
                <ClearAble value={this.state.regionFrom} onClear={this.onRegionFromChanged.bind(this, null)} >
                  <NarrowPortAutocomplete fullWidth hintText={'Place from'} value={this.state.regionFrom} name={'regionFrom'} onNewRequest={this.onRegionFromChanged} params={{ withAreas: true }} />
                </ClearAble>
                <ClearAble value={this.state.regionTo} onClear={this.onRegionToChanged.bind(this, null)} >
                  <NarrowPortAutocomplete hintText={'Place to'} value={this.state.regionTo} name={'regionTo'} onNewRequest={this.onRegionToChanged} params={{ withAreas: true  }} />
                </ClearAble>
                <RaisedButton secondary styles={'small'} label={'Clear'} onClick={this.clearFilters} />
                <RaisedButton primary styles={'small'} label={'Search'} onClick={this.search} />
              </div>
            </ExtendedForm>
          </Filters>
          <CollapsesWrapper>
            <CollapseWrapper maxHeight={this.state.hireCollapsed ? 'calc(100% - 40px)' : '50%'}><Collapse
              onToggle={this.onIndexCollapse} title={'MARKET T/C Index'}
              noBorder><IndexTable readonly rows={this.state.tcIndices} vessel={this.props.vessel} /></Collapse></CollapseWrapper>
            <CollapseWrapper maxHeight={this.state.indexCollapsed ? 'calc(100% - 88px)' : '50%'}><Collapse
              onToggle={this.onHireCollapse} title={'HIRE IDEA'} noBorder>
              <HireTable
                onSave={this.saveHireIdea}
                rows={this.state.hireIdeas}
                vessel={this.props.vessel}
              />
            </Collapse>
            </CollapseWrapper>
            <CollapseWrapper maxHeight={this.state.ratesCollapsed ? 'calc(100% - 88px)' : '50%'}>
              <Collapse
                onToggle={this.onRatesCollapse}
                title={'FIXED RATES'}
                noBorder
              >
                <RatesTable
                  onSave={this.saveFixedRate}
                  rows={this.state.fixedRates}
                  vessel={this.props.vessel}
                />
              </Collapse>
            </CollapseWrapper>
          </CollapsesWrapper>
        </Body>
      </Wrapper>
    );
  }
}

const actions = { getTcIndex, updateTcIndex, updateFixedRate };
export default connect(state => state.login, actions)(HireIndices);

class IndexTable extends PureComponent {
  columns = [
    {
      label: 'Index',
      style: { width: '40px' },
      renderer: (row, i) => row.index,
    },
    {
      label: 'Place From',
      style: { flex: 2 },
      renderer: row => row.regionFrom.name,
    },
    {
      label: 'Place To',
      style: { flex: 2 },
      renderer: row => row.regionTo.name,
    },
    {
      label: 'VIA',
      style: { flex: 1 },
      renderer: row => row.via?.name,
    },
    {
      label: 'Round Trip',
      style: { width: '32px' },
      className: 'no-padding center',
      renderer: row => <CheckboxLike value={row.roundTrip} />,
    },
    {
      label: 'Clean',
      style: { width: '32px' },
      className: 'no-padding center',
      renderer: row => <CheckboxLike value={row.clean} />,
    },
    {
      label: 'Laycan',
      style: { width: '44px' },
      renderer: row => <span>{dateShortLocal(row.lay)}<br/>{dateShortLocal(row.can)}</span>,
    },
    {
      label: 'Durtn (days)',
      style: { width: '34px', whiteSpace: 'normal' },
      renderer: row => row.duration,
    },
    {
      label: 'Balast bonus ($)',
      style: { width: '48px' },
      renderer: row => number(row.balastBonus),
    },
    {
      label: 'Intake (mt)',
      style: { width: '48px' },
      renderer: row => number(row.intake),
    },
    {
      label: 'T/C index ($/Day)',
      style: { width: '48px' },
      renderer: row => <BlurIfNoSubscription>{number(row.tcIndex)}</BlurIfNoSubscription>,
    },
    {
      label: 'Last update',
      style: { width: '44px' },
      className: 'no-padding center',
      renderer: row => dateShortLocal(row.lastUpdate.date),
    },
  ]

  componentWillMount() {
    if (!['BULK', 'BULKTANK', 'MPP'].includes(this.props.vessel.typeByPurpose)) {
      this.columns = this.columns.filter(c => c.label !== 'Clean');
    }
  }



  render() {
    return (
      <GenericTable columns={this.columns} rows={this.props.rows} readonly={this.props.readonly}/>
    )

  }
}

class HireTable extends PureComponent {
  state = {
    rows: [{
      _id: uuid(),
      isNew: true,
      lay: new Date(),
      can: new Date(new Date().setDate(((new Date()).getDate() + (7 - new Date().getDay())))),
    }],
  };
  columns = [
    {
      label: 'Place From',
      style: { flex: 2, overflow: 'visible' },
      renderer: (row, i, props) => <PortAutocomplete value={row.regionFrom}
                                                     onNewRequest={this.onAutocompleteChange.bind(this, i, 'regionFrom')}
                                                     validationErrors={{ isDefaultRequiredValue: 'required' }} required
                                                     forceSelection name={`hire[${i}][regionFrom]`} params={{ withAreas: true }} />,
      readOnlyRenderer: (row, i, props) => row.regionFrom?.name,
    },
    {
      label: 'Place To',
      style: { flex: 2, overflow: 'visible' },
      renderer: (row, i, props) => <PortAutocomplete value={row.regionTo}
                                                     onNewRequest={this.onAutocompleteChange.bind(this, i, 'regionTo')}
                                                     validationErrors={{ isDefaultRequiredValue: 'required' }} required
                                                     forceSelection name={`hire[${i}][regionTo]`} params={{ withAreas: true }} />,
      readOnlyRenderer: (row, i, props) => row.regionTo?.name,
    },
    {
      label: 'VIA',
      style: { flex: 1, overflow: 'visible' },
      renderer: (row, i, props) => <ViaAutocomplete value={row.via}
                                                     onNewRequest={this.onAutocompleteChange.bind(this, i, 'via')}
                                                     name={`hire[${i}][via]`}/>,
      readOnlyRenderer: (row, i, props) => row.via?.name,
    },
    {
      label: 'Round Trip',
      style: { width: '32px', padding: 0 , overflow: 'visible' },
      className: 'no-padding center',
      renderer: (row, i, props) => <CheckBox value={row.roundTrip}
                                             onChange={this.onFieldChange.bind(this, i, 'roundTrip')}
                                             name={`hire[${i}][roundTrip]`}/>,
      readOnlyRenderer: (row, i, props) => <CheckboxLike value={row.roundTrip} />,
    },
    {
      label: 'Clean',
      style: { width: '32px', padding: 0, overflow: 'visible' },
      className: 'no-padding center',
      renderer: (row, i, props) => <CheckBox value={row.clean} onChange={this.onFieldChange.bind(this, i, 'clean')}
                                             name={`hire[${i}][clean]`}/>,
      readOnlyRenderer: (row, i, props) => <CheckboxLike value={row.clean} />,
    },
    {
      label: 'Laycan',
      style: { width: '44px'},
      renderer: (row, i, props) => <span>
        <FormsyDate value={row.lay} onChange={this.onFieldChange.bind(this, i, 'lay')} formatDate={dateShortLocal}
                    placeholder={'Open'} name={`hire[${i}][lay]`}/>
        <FormsyDate value={row.can} minDate={row.lay} onChange={this.onFieldChange.bind(this, i, 'can')} formatDate={dateShortLocal}
                    placeholder={'Close'} name={`hire[${i}][can]`}/>
    </span>,
      readOnlyRenderer: (row, i, props) => <span>{dateShortLocal(row.lay)}<br/>{dateShortLocal(row.can)}</span>,
    },
    {
      label: 'Durtn (days)',
      style: { width: '39px', whiteSpace: 'normal', overflow: 'visible' },
      renderer: (row, i, props) => <Input value={row.duration} onChange={this.onFieldChange.bind(this, i, 'duration')}
                                          validations={{ matchRegexp: /^\d{1,3}$|^\d{1,3}-\d{1,3}$/ }}
                                          validationErrors={{matchRegexp: "days or days-days. eg 100-150"}}
                                          numeric={false} name={`hire[${i}][duration]`}/>,
      readOnlyRenderer: (row, i, props) => row.duration,
    },
    {
      label: 'Balast bonus ($)',
      style: { width: '48px', overflow: 'visible' },
      renderer: (row, i, props) => <Input value={row.balastBonus}
                                          onChange={this.onFieldChange.bind(this, i, 'balastBonus')} numeric
                                          name={`hire[${i}][balastBonus]`}/>,
      readOnlyRenderer: (row, i, props) => number(row.balastBonus),
    },
    {
      label: 'Intake (mt)',
      style: { width: '48px', overflow: 'visible' },
      renderer: (row, i, props) => <Input value={row.intake} onChange={this.onFieldChange.bind(this, i, 'intake')}
                                          numeric name={`hire[${i}][intake]`}/>,
      readOnlyRenderer: (row, i, props) => number(row.intake),
    },
    {
      label: 'Hire Idea ($/Day)',
      style: { width: '48px', overflow: 'visible' },
      renderer: (row, i, props) => <Input value={row.tcIndex} onChange={this.onFieldChange.bind(this, i, 'tcIndex')}
                                          required numeric name={`hire[${i}][tcIndex]`}/>,
      readOnlyRenderer: (row, i, props) => number(row.tcIndex),
    },
    {
      label: 'Last update',
      style: { width: '44px' },
      className: 'no-padding center bg-light-gray',
      renderer: row => dateShortLocal(row.lastUpdate?.date),
    },
    {
      label: '',
      style: { width: '21px' },
      headerStyle: { width: '21px', background: 'white', borderTop: 'none', borderLeft: '1px solid var(--stroke-light-gray1)', borderRight:'none' },
      className: 'no-padding center',
      renderer: (row, i, props) => <DeleteCell onClick={this.deleteRow.bind(this, i)}/>,
    },
  ];

  componentWillMount() {
    if (!['BULK', 'BULKTANK', 'MPP'].includes(this.props.vessel.typeByPurpose)) {
      this.columns = this.columns.filter(c => c.label !== 'Clean');
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.rows !== nextProps.rows) {
      const rows = [...nextProps.rows];
      if (rows.length === 0) {
        rows.push(this.getEmptyRow());
      }
      this.setState({ rows: rows, cleanRows: lodash.cloneDeep(rows), isUpdated: false });
    }
  }


  addRow = () => {
    this.setState({
      rows: [this.getEmptyRow(), ...this.state.rows],
    });
    this.tableBody.scrollTo(0, 0);
  }

  getEmptyRow() {
    return {
      _id: uuid(),
      isNew: true,
      lay: new Date(),
      can: new Date(new Date().setDate(((new Date()).getDate() + (7 - new Date().getDay())))),
    }
  }

  deleteRow = (i) => {
    this.onFieldChange(i, 'isDeleted', null, true);
  }

  onFieldChange = (i, name, e, value) => {
    const rows = [...this.state.rows];
    const row = { ...this.state.rows[i] };
    rows[i] = row;
    if(row[name] === value) {
      return;
    }
    row[name] = value;
    row.isUpdated = true;
    switch (name) {
      case 'lay':
        row.can = new Date(value);
        row.can.setDate(row.can.getDate() + (7 - row.can.getDay()));
        break;
    }
    this.setState({ rows, isUpdated: true });
  }

  onAutocompleteChange = (i, name, value) => {
    this.onFieldChange(i, name, null, value);
  }

  onValidSubmit = async () => {
    await this.props.onSave(this.state.rows, true);
    this.setState({ isUpdated: false });
  }
  onInvalidSubmit = async () => {
    const invalidInput = this.tableBody.querySelector('input[data-valid="false"]');
    invalidInput.scrollIntoView();
  }

  cancelEdit = () => {
    this.setState({ rows: lodash.cloneDeep(this.state.cleanRows), isUpdated: false });
  }

  refTableBody = (el) => {
    this.tableBody = el;
  }
  refForm = (form) => {
    this.form = form;
  }

  render() {
    return (
      <ExtendedForm ref={this.refForm} onValidSubmit={this.onValidSubmit} onInvalidSubmit={this.onInvalidSubmit} autoComplete={'off'} noValidate>
        <GenericTable columns={this.columns} rows={this.state.rows} isUpdated={this.state.isUpdated} footer={<HireTableFooter>
          <AddButtonBlue label={'Add route'} onClick={this.addRow} />
          {this.state.isUpdated ?
            <div>
              <RaisedButton styles={'small'} label={'Cancel'} secondary onClick={this.cancelEdit} />
              <RaisedButton styles={'small'} label={'Save'} primary type={'submit'} />
            </div>
            : null}

        </HireTableFooter>}
            bodyRef={this.refTableBody}

        />
      </ExtendedForm>
    )

  }
}

class RatesTable extends HireTable {
  columns = [
    {
      label: 'Place From',
      style: { flex: 2, overflow: 'visible' },
      renderer: (row, i, props) => <PortAutocomplete
        value={row.regionFrom}
        onNewRequest={this.onAutocompleteChange.bind(this, i, 'regionFrom')}
        validationErrors={{ isDefaultRequiredValue: 'required' }}
        required
        forceSelection
        name={`rate[${i}][regionFrom]`}
        params={{ withAreas: true }}
      />,
      readOnlyRenderer: (row, i, props) => row.regionFrom?.name,
    },
    {
      label: 'Place To',
      style: { flex: 2, overflow: 'visible' },
      renderer: (row, i, props) => <PortAutocomplete value={row.regionTo}
        onNewRequest={this.onAutocompleteChange.bind(this, i, 'regionTo')}
        validationErrors={{ isDefaultRequiredValue: 'required' }}
        required
        forceSelection
        name={`rate[${i}][regionTo]`}
        params={{ withAreas: true }}
      />,
      readOnlyRenderer: (row, i, props) => row.regionTo?.name,
    },
    {
      label: 'VIA',
      style: { flex: 1, overflow: 'visible' },
      renderer: (row, i, props) => <ViaAutocomplete
        value={row.via}
        onNewRequest={this.onAutocompleteChange.bind(this, i, 'via')}
        name={`rate[${i}][via]`}
      />,
      readOnlyRenderer: (row, i, props) => row.via?.name,
    },
    {
      label: 'Round Trip',
      style: { width: '32px', padding: 0 , overflow: 'visible' },
      className: 'no-padding center',
      renderer: (row, i, props) => <CheckBox
        value={row.roundTrip}
        onChange={this.onFieldChange.bind(this, i, 'roundTrip')}
        name={`rate[${i}][roundTrip]`}
      />,
      readOnlyRenderer: (row, i, props) => <CheckboxLike value={row.roundTrip} />,
    },
    {
      label: 'Clean',
      style: { width: '32px', padding: 0, overflow: 'visible' },
      className: 'no-padding center',
      renderer: (row, i, props) => <CheckBox
        value={row.clean}
        onChange={this.onFieldChange.bind(this, i, 'clean')}
        name={`rate[${i}][clean]`}
      />,
      readOnlyRenderer: (row, i, props) => <CheckboxLike value={row.clean} />,
    },
    {
      label: 'Laycan',
      style: { width: '44px'},
      renderer: (row, i, props) => <span>
        <FormsyDate
          value={row.lay}
          onChange={this.onFieldChange.bind(this, i, 'lay')}
          formatDate={dateShortLocal}
          placeholder={'Open'}
          name={`rate[${i}][lay]`}
        />
        <FormsyDate
          value={row.can}
          minDate={row.lay}
          onChange={this.onFieldChange.bind(this, i, 'can')}
          formatDate={dateShortLocal}
          placeholder={'Close'}
          name={`rate[${i}][can]`}
        />
      </span>,
      readOnlyRenderer: (row, i, props) => <span>{dateShortLocal(row.lay)}<br/>{dateShortLocal(row.can)}</span>,
    },
    {
      label: 'Durtn (days)',
      style: { width: '39px', whiteSpace: 'normal', overflow: 'visible' },
      renderer: (row, i, props) => <Input
        value={row.duration}
        onChange={this.onFieldChange.bind(this, i, 'duration')}
        validations={{ matchRegexp: /^\d{1,3}$|^\d{1,3}-\d{1,3}$/ }}
        validationErrors={{matchRegexp: "days or days-days. eg 100-150"}}
        numeric={false}
        name={`rate[${i}][duration]`}
      />,
      readOnlyRenderer: (row, i, props) => row.duration,
    },
    {
      label: 'Balast bonus ($)',
      style: { width: '48px', overflow: 'visible' },
      renderer: (row, i, props) => <Input
        value={row.balastBonus}
        onChange={this.onFieldChange.bind(this, i, 'balastBonus')}
        numeric
        name={`rate[${i}][balastBonus]`}
      />,
      readOnlyRenderer: (row, i, props) => number(row.balastBonus),
    },
    {
      label: 'Intake (mt)',
      style: { width: '48px', overflow: 'visible' },
      renderer: (row, i, props) => <Input
        value={row.intake}
        onChange={this.onFieldChange.bind(this, i, 'intake')}
        numeric
        name={`rate[${i}][intake]`}
      />,
      readOnlyRenderer: (row, i, props) => number(row.intake),
    },
    {
      label: 'Fixed Rate ($)',
      style: { width: '47px', overflow: 'visible' },
      renderer: (row, i, props) => <Input
        value={row.tcIndex}
        onChange={this.onFieldChange.bind(this, i, 'tcIndex')}
        required
        numeric
        name={`rate[${i}][tcIndex]`}
      />,
      readOnlyRenderer: (row, i, props) => number(row.tcIndex),
    },
    {
      label: 'Last update',
      style: { width: '45px' },
      className: 'no-padding center bg-light-gray',
      renderer: row => dateShortLocal(row.lastUpdate?.date),
    },
    {
      label: '',
      style: { width: '21px' },
      headerStyle: { width: '21px', background: 'white', borderTop: 'none', borderLeft: '1px solid var(--stroke-light-gray1)', borderRight:'none' },
      className: 'no-padding center',
      renderer: (row, i, props) => <DeleteCell onClick={this.deleteRow.bind(this, i)}/>,
    },
  ];
}

class GenericTable extends PureComponent {
  state = { scrolledToTop: true }
  static defaultProps = {
    rows: [],
  }

  onBodyScroll = (e) => {
    const scrolledToTop = e.target.scrollTop === 0;
    if (this.state.scrolledToTop !== scrolledToTop) {
      this.setState({ scrolledToTop });
    }
  }


  render() {
    const { columns, rows, footer, readonly } = this.props;
    return (
      <Table className={this.state.scrolledToTop ? 'scrolled-to-top' : ''}>
        <TableHead>
          <Row>
            {columns.map(c => (<HeaderCell style={c.headerStyle || c.style}><div>{c.label}</div></HeaderCell>))}
          </Row>
        </TableHead>
        <TableBody innerRef={this.props.bodyRef} onScroll={this.onBodyScroll}>
          {rows.map((r, i) => {
            if (!r || r.isDeleted) {
              return;
            }
            const _readonly = readonly || r.expired;
            return (<Row className={cx(r.expired && 'expired', _readonly && 'readonly')} key={r._id}>
              {columns.map((c, j) => {
                let renderer = c.renderer;
                if (_readonly && c.readOnlyRenderer) {
                  renderer = c.readOnlyRenderer
                }
                return (<Cell className={c.className} key={`${r._id}-${j}`} style={c.style}>

                  <div>{renderer(r, i, this.props)}</div>
                </Cell>);
              })}
            </Row>)
          })}

          {!rows.length && (
            <Row className="readonly" >
              {columns.map((c, j) => {
                return (<Cell className={c.className} key={`empty-${j}`} style={c.style}>

                  <div>---</div>
                </Cell>);
              })}
            </Row>
          )}
        </TableBody>
        <TableFooter>
          {footer}
        </TableFooter>
      </Table>
    );
  }
}


const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  z-index: 99999;

  .material-icons-outlined, .material-icons {
    display: block;
    color: var(--text-light);
  }

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  background: var(--bg-light-grey);
  padding: 0 20px;
  border-bottom: 1px solid var(--stroke-light-gray2);
  h4 {
    color: var(--text-dark);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }
  .material-icons-outlined, .material-icons {
    color: var(--icon-default);
    position: relative;
    right:-8px;
    cursor: pointer;
    top: -1px;
  }

`;

const Body = styled.div`
  padding: 20px 20px 0 20px;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;


const Filters = styled.div`
  form > div {
    display: flex;
    align-items: center;

    > div + div {
      margin-left: 8px;
    }

    > div:nth-child(1), > div:nth-child(2) {
      flex: 1;
    }
    .sn-port-autocomplete-wrapper {
      width: 100%;
    }
  }

  .raised-button {
    margin-bottom: 4px;
  }

  .raised-button + .raised-button {
    margin-left: 8px;
  }
`;


const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--stroke-light-gray1);
  border-left: 1px solid var(--stroke-light-gray1);
  border-top: 1px solid var(--stroke-light-gray1);
  position: sticky;
  top: 0;
  background: var(--stroke-light-gray2);
  font-weight: bold;
  font-size: 9px;
  line-height: 11px;
  height: 28px;
  & >div {
    padding: 0 0 0 4px;
  }
`;


const TableBody = styled.div`
  height: calc(100% - 27px);
  min-height: 40px;
  overflow: scroll;
  input[data-valid=false] {
    background-color: transparent !important;
  }
  .required.empty input {
    background-color: transparent !important;
  }

  .sn-autocomplete, .sn-date {
    width: 100%;

    > div > div:nth-child(1) {
      height: 28px !important;
    }

    > div {
      line-height: 28px !important;
    }

    hr {
      display: none !important;
    }

    input {
      margin-top: 0;
      margin-left: 0;
      font-size: 9px !important;
      padding-left: 4px !important;
      color: var(--text-dark) !important;
      font-family: Roboto;
      /*background-color: #FFF8A0!important;*/

      &:focus {
        background-color: white !important;
      }
    }





    .unlocode {
      display: none;
    }
  }

  .sn-date {
    overflow: hidden;

    > div:first-child {
      height: 12px !important;
      display: flex !important;
      width: 100% !important;
    }

    input {
      padding: 0 !important;
      text-align: center;
      cursor: pointer !important;
    }
  }

  .sn-autocomplete, .input-container {
    display: flex !important;
    height: 27px !important;

    input {
      background-color: transparent;
      outline: none;
      height: 29px !important;
      position: absolute !important;
      top: -1px;
      width: calc(100% + 2px) !important;
      left: -1px;
      border: 1px solid transparent !important;
      padding-left: 4px !important;
      color: var(--text-dark) !important;
      font-family: Roboto;
      &:focus {
        background-color: transparent !important;
        border: 1px solid var(--text-green-light) !important;
        z-index: 1;
      }
    }

    input::placeholder {
      text-transform: none;
    }
    &.errored-not-empty {
      input {
        border: 1px solid var(--text-warning) !important;
        z-index: 1;
      }
    }
    .validation-error {
      display: none;
    }
  }

  .sn-port-autocomplete-wrapper {
    position: unset !important;
    height: 28px;
  }

  .sn-autocomplete {
    > div:last-child {
      display: none;
    }
    &.required.empty {
      background-color: #FFFDD6!important;
      margin-top: 0.5px;
    }
  }

  .custom-checkbox {
    width: 16px !important;
    overflow: hidden !important;
    margin-left: 8px;
  }

  .input-container.required.empty {
    background-color: #FFFDD6!important;
  }
  .bg-light-gray {
    background-color: var(--bg-light-grey);
  }


`;

const CheckboxLikeStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .checkbox-checked-icon {
    font-size:21px;
  }
  .checkbox-icon {
    font-size:16px;
    width: 16px;
    height: 16px;
    color: transparent;
    border: 1px solid var(--checkbox-outline);
    border-radius: 2px;
  }
`;

function CheckboxLike({ value }) {
  return (<CheckboxLikeStyle>
    {value ? (<span className="checkbox-checked-icon material-icons">check_box</span>) :
    (<span className="checkbox-icon material-icons-outlined">check_box_outline_blank</span>)}
  </CheckboxLikeStyle>);
}

const TableHead = styled.div`
  overflow-y: scroll;
  min-height: 28px;
`;

const TableFooter = styled.div`

`;

const HireTableFooter = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 36px;

  > div:nth-child(2) > div {
    margin-left: 8px;
  }
`;

const CollapseWrapper = styled.div`
  max-height: ${props => props.maxHeight};
  display: flex;
  flex-direction: column;

  > div {
    max-height: 100% !important;

    > div:nth-child(2) {
      max-height: calc(100% - 24px);
      display: flex;
      flex-direction: column;
    }

    form {
      height: 100%;
    }
  }
`;

const CollapsesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 44px);
  max-height: calc(100% - 44px);


  > div:nth-child(2) {
  ${TableBody} {
    height: calc(100% - 59px);
  }
}
`;


const Cell = styled.div`
  border-bottom: 1px solid var(--stroke-light-gray1);
  border-left: 1px solid var(--stroke-light-gray1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
  color: var(--text-dark);
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;

  > div {
    overflow: inherit;
    text-overflow: inherit;
    flex: 1;
    padding: 0 0 0 0px;
  }
  &.no-padding {
    > div {
      padding: 0!important;
    }
  }
  &.center {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  position: relative;

`;

const DeleteCell = styled(function ({ className, ...props }) {
  return <div className={className} {...props}><span className={`material-icons-outlined`}>close</span></div>
})`
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    font-size: 10px;
    cursor: pointer;
  }
`;

const ClearAble = styled(function ({ className, onClear, value,children, ...props }) {
  return <div className={cx(className, (!value) && 'empty')} {...props}>{children}<span onClick={onClear} className={`material-icons-outlined`}>close</span></div>
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  > span:last-child{
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    right: 0;
  }
  &.empty > span:last-child {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  >div:last-child {
    border-right: 1px solid var(--stroke-light-gray1);
  }
  height: 28px;
  &.readonly {
    ${Cell} {
      overflow: hidden!important;
      background: var(--bg-light-grey);
     &>div {
        padding: 0 0 0 4px;
      }

    }
  }
  &.expired {
    ${Cell} {
      overflow: hidden!important;
      background: var(--bg-light-grey);
      color: var(--text-light);
      &>div {
        padding: 0 0 0 4px;
      }

    }
  }
`;

const Table = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  &.scrolled-to-top {
    ${TableHead} {
      min-height: 27px;

      & > ${Row} {
        height: 27px;
      }
    }

    ${HeaderCell} {
      border-bottom: none;
      height: 27px;
    }
  }

  ${TableBody} > ${Row}:first-child > ${Cell} {
    border-top: 1px solid var(--stroke-light-gray1);

    .sn-autocomplete input, .input-container input {
      height: 28px !important;
      top: 0;
    }

    .input-container, .sn-autocomplete {
      height: 26px !important;

      input {
        top: -1px;
      }
    }
    .sn-autocomplete {
      margin-top:1px;
    }
  }
`;

