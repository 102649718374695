exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2dQm7 {\n  white-space: nowrap;\n  font-size: 12px; }\n  ._2dQm7 label {\n    color: var(--text-light) !important; }\n  .Vqhy9 {\n  padding-top: 8px;\n  padding-bottom: 8px; }\n  ._2jcbO {\n  min-height: 44px; }\n  .jfpiw {\n  display: -ms-flexbox;\n  display: flex; }\n  ._1Vngh {\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap; }\n  ._2wFsM {\n  -ms-flex-align: center;\n      align-items: center; }\n  ._1t3Ho {\n  margin-top: 8px; }\n  ._2FStN {\n  margin-right: 16px; }\n  ._2-Y5l {\n  white-space: nowrap; }\n  ._18093 {\n  display: grid; }\n  .UKISg {\n  grid-template-columns: repeat(4, minmax(0, 1fr)); }\n  ._10C58 {\n  -webkit-column-gap: 16px;\n     -moz-column-gap: 16px;\n          column-gap: 16px; }\n  ._1a98_ {\n  font-size: 12px;\n  line-height: 16px; }\n  ._1403Z {\n  color: var(--text-green-dark); }\n  ._3Raga {\n  color: var(--bg-blue); }\n  ._560C8 {\n  margin: 0; }\n", ""]);

// Exports
exports.locals = {
	"checkbox": "_2dQm7",
	"py_8px": "Vqhy9",
	"form_row_min_h": "_2jcbO",
	"flex": "jfpiw",
	"flex_wrap": "_1Vngh",
	"items_center": "_2wFsM",
	"mt_8px": "_1t3Ho",
	"mr_16px": "_2FStN",
	"whitespace_nowrap": "_2-Y5l",
	"grid": "_18093",
	"grid_cols_4": "UKISg",
	"gap_x_16px": "_10C58",
	"text_xs": "_1a98_",
	"text_green_dark": "_1403Z",
	"text_bg_blue": "_3Raga",
	"m_0": "_560C8"
};