/** @name EMAILS_CHECK_EMAIL_REDUCER */
export default function EMAILS_CHECK_EMAIL(state, { payload }) {
  const idx = state.emailList.data.findIndex(e => e.id === payload.id);
  if (idx === -1) {
    return state;
  }
  const newState = { emailList: { ...state.emailList, data: [...state.emailList.data] } };
  newState.emailList.data[idx] = { ...newState.emailList.data[idx], selected: payload.selected };
  return { ...state, ...newState };
}
