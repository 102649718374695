import React, { Component } from 'react';
import Page from '../Page/Page';
import SplitPane from '../Common/SplitPane';
import EmailsTable from './EmailsTable';
import cx from 'classnames';

class EmailsPage extends Component {
  render() {
    return (
      <Page headerTitle={"EXCHANGE"}>
        <div style={{position: 'relative', overflow: 'hidden', width: '100%', height: '100%'}}>
          <SplitPane
            className={cx('h-100')}
            left={<EmailsTable {...this.props} />}
            right={this.props.rightSection?.component}
          />
        </div>
      </Page>
    );
  }
}

export default EmailsPage;
