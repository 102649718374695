import produce from "immer";
import {sortContacts} from "./CONTACTS_CREATE_GROUP";

/** @name CONTACTS_CREATE_COMPANY_REDUCER */
 export default function CONTACTS_CREATE_COMPANY(state, { payload, res }) {
  const newContact = res.data;
  newContact.type = 'company';
  return produce(state, draft => {
    draft.companyList.data = [newContact, ...draft.companyList.data];
    draft.companyList = sortContacts(draft.companyList);
  });
}
