import React, { Component } from 'react'
import { connect } from 'react-redux';
import { sendOpenPosition } from '../../../actions/vessel';
import PositionDialog from './../Positions/PositionDialog'
import RoundBorderedBtn from './../../Common/RoundBorderedBtn';
import ContentAdd from 'material-ui/svg-icons/content/add';

export class AddPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPositionDialog: false
        }
        this.openDialog = this.openDialog.bind(this);
        this.toggleDialog = this.toggleDialog.bind(this);
        this.sendPosition = this.sendPosition.bind(this);
    }

    openDialog() {
        this.setState({
            showPositionDialog: true
        })
    }

    toggleDialog() {
        this.setState(state => ({showPositionDialog: !state.showPositionDialog}));
    }

    sendPosition(position) {
        this.props.sendOpenPosition(position, this.props.user).then((res) => {
            if (res.status === 200) {
              this.setState({ showPositionDialog: false });
              this.props.afterSend && this.props.afterSend();
            }
          });
    }

    render() {
        const {vessel} = this.props;
        const {toggleDialog, sendPosition} = this;
        const {showPositionDialog} = this.state;
        return (
            <div>
                <div onClick={this.openDialog} style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                    <RoundBorderedBtn><ContentAdd style={{width:'18px',height:'18px'}} /> Add Position</RoundBorderedBtn>
                </div>
                {
                    showPositionDialog ?
                        <PositionDialog handleSendNewPosition={sendPosition} handleClose={toggleDialog} vessel={vessel}></PositionDialog>
                    : null
                }
            </div>
        )
    }
}

export default connect(state => ({user: state.login.user}), {sendOpenPosition})(AddPosition);
