import { filterVesselRequest } from './filter';
import { removeFromArray } from '../../core/utils';
/** @name VESSEL_REQUEST_EXPIRED_REDUCER */
export default function VESSEL_REQUEST_EXPIRED(state, action) {
  const newRequestList = { ...state.requestList };
  newRequestList.filters = action.payload;
  const index = newRequestList.data.findIndex(vr => vr.key === action.payload);
  newRequestList.data = removeFromArray(newRequestList.data, index);
  const newState = { requestList: filterVesselRequest(newRequestList) };
  return { ...state, ...newState };
}
