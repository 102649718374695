import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Table.scss';
import cx from 'classnames';
import Search from './Search';
import { debounce } from 'formsy-material-ui/lib/utils';

import { connect } from "react-redux";

const scrollPositionStorage = {};

class Table extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    handleLoadMore: PropTypes.func,
    data: PropTypes.array,
    filters: PropTypes.object,
    page: PropTypes.number,
    next: PropTypes.number,
    handleRowSelected: PropTypes.func,
  };
  static defaultProps = {
    cols: [],
    actions: [],
    data: [],
  };

  componentDidMount() {
    this.tableBody = document.querySelector('.' + s.body);
    if (this.props.page !== 0) {
      this.tableBody.scroll(0, scrollPositionStorage[this.props.id] || 0);
    }
  }

  previousScrollTop = 0;
  handleScroll = (e, r, t) => {
    scrollPositionStorage[this.props.id] = e.target.scrollTop;
    if (!this.props.next || this.loading) {
      return;
    }
    const st = e.target.scrollTop;
    let direction;
    if (st > this.previousScrollTop) {
      direction = 'down';
    } else {
      direction = 'up';
    }
    if (
      !this.props.loading &&
      direction === 'down' &&
      e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop < 500
    ) {
      this.loading = true;
      this.handleLoadMore();
    }
    this.previousScrollTop = st;
  };

  handleLoadMore = debounce(() => {
    this.props.handleLoadMore().then(() => {
      this.loading = false;
    });
  }, 50);

  handleSearch = (query) => {
    this.props.handleLoadMore({ ...this.props.filters, query });
  };
  handleRowSelected = (row) => {
    this.props.handleRowSelected && this.props.handleRowSelected(row);
  }

  render() {
    return (<div className={cx(s.root, 'table')}>
      <div className={cx(s.header, 'table-header')}>
        <div className={cx(s.row, 'table-row')}>
          {this.props.cols.map(col => <div key={col.name} className={s.col} style={col.style}>{col.label}
          </div>)}
        </div>
      </div>
      <div className={cx(s.body, 'table-body')} onScroll={this.handleScroll} >
        {this.props.data.map(row => <Row user={this.props.user} className={this.props.rowClassName} key={row._id} data={row} cols={this.props.cols} actions={this.props.actions} onClick={this.handleRowSelected.bind(this, row)} />)}
      </div>
    </div>);
  }
}

class Row extends PureComponent {
  static propTypes = {
    cols: PropTypes.array,
    data: PropTypes.object,
    actions: PropTypes.array,
  };
  static defaultProps= {
    actions: [],
    data: {},
    cols: [],
  };

  render() {
    const data = this.props.data;
    return (<div onClick={this.props.onClick} key={data._id} className={cx(this.props.className, s.row, 'table-row')}>{this.props.cols.map((col) => {
      let content;
      if (col.renderer) {
        content = col.renderer(col, data);
      } else {
        content = data[col.name];
      }
      return <div key={col.name} className={s.col} style={col.style}>{content}</div>;
    },
    )}

      {this.props.actions && this.props.actions.length && this.props.user?.user?._id ? <div className={cx(s.col, s.actions)}>{this.props.actions.map(renderer => renderer(data))}</div> : null }
    </div>);
  }
}

export default connect( state => ({user: {...state.login}}), null )(withStyles(s)(Table));
