import memoizeOne from 'memoize-one';

export const parseErrorsArray = memoizeOne(errors => Array.isArray(errors) ? errors.reduce((acc, error) => {
  // errors are object with row name (e.g. POL, POD, ...) as fields; each field is an object with input field name (e.g port, cancellingDate, cargoName, ...),
  // input field name in this object can be either array of objects (errors) or object (error itself)
  // if row in Preview renders array (for example POL), errors for each input field will be an arrays to display error message for each POL

  const rowName = error.type;

  const rowNameErrors = acc[rowName];

  const inputFieldName = error.fieldName;

  if (rowNameErrors) {
    return {
      ...acc,
      [rowName]: {
        ...rowNameErrors,
        [inputFieldName]:
          typeof error.index !== 'undefined'
            ? [ ...(Array.isArray(rowNameErrors[inputFieldName]) ? rowNameErrors[inputFieldName] : []), error ]
            : error
      }
    }
  }

  return {
    ...acc,
    [rowName]: {
      [inputFieldName]:
        typeof error.index !== 'undefined'
          ? [ error ]
          : error
    }
  }
}, {}) : errors || {});

export function convertRequestToServer(request = { }){
  const loading = convertPortsToServer(request.loadingPorts);
  const unloading = convertPortsToServer(request.unloadingPorts);
  request.loadingPort = loading[0]?.oneOf?.[0].port;
  request.unloadingPort = unloading[0]?.oneOf?.[0]?.port;
  request.readinessDate = loading[0]?.oneOf?.[0]?.readinessDate;
  request.cancellingDate = loading[0]?.oneOf?.[0]?.cancellingDate;
  request.loading = loading;
  request.unloading = unloading;
  return request;
}

export const getDefaultPorts = () => [{ type: 'next', i: 0, opened: true }];

export function convertPortsToServer(ports = []) {
  const ret = [];
  if (!ports.length) {
    return ports;
  }
  let curLeg = { oneOf: [] };
  for (let i = 0; i < ports.length; i++) {
    const port = { ...ports[i] };
    if (port._id && port._id.length > 24) {
      port._id = undefined;
    }
    if (port) {
      if (port.type !== 'alt') {
        curLeg = { _id: port.groupId, oneOf: [{ _id: port._id, port: port.port, details: port.details, readinessDate: port.readinessDate, cancellingDate: port.cancellingDate, layCanType: port.layCanType, additionalLayCanDetails: port.additionalLayCanDetails }] };
        ret.push(curLeg);
      } else {
        curLeg.oneOf.push({ _id: port._id, port: port.port, details: port.details, readinessDate: port.readinessDate, cancellingDate: port.cancellingDate, layCanType: port.layCanType, additionalLayCanDetails: port.additionalLayCanDetails });
      }
    }
  }
  return ret;
}

export const getPreviewErrorMsg = (errorObject, index) => {
  let errMsg = "";

  if (typeof index !== 'undefined') {
    const foundErrorByIndex = errorObject?.find?.(error => error?.index == index);
    if (foundErrorByIndex) {
      errMsg = foundErrorByIndex.config?.error;
    }
  }
  else if (errorObject) {
    errMsg = errorObject.config.error;
  }

  return errMsg;

};

export const getSubstringBetween = (str, firstChar, secondChar) => str.substring(str.indexOf(firstChar) + 1, str.lastIndexOf(secondChar));
