/** @name SET_SELECTED_INDEX */
export default function SET_SELECTED_INDEX(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    selectedIndex: action.payload,
  };

  return { ...state, ...newState };
}
