import React from 'react';
import Toggle from 'material-ui/Toggle';
import muiThemeable from 'material-ui/styles/muiThemeable';
const toggleStyles = {
  thumbOff: {
    backgroundColor: 'white',
    left: '5px',
    width: '10px',
    height: '10px',
    top: '6px',
    boxShadow: 'none',
  },
  trackOff: {
    backgroundColor: '#D8D8D8',
    border: '1px solid rgb(189, 189, 189)',
    width: '28px',
  },
  thumbSwitched: {
    backgroundColor: 'white',
    width: '10px',
    height: '10px',
    top: '6px',
    boxShadow: 'none',
    left: '18px',
  },
  trackSwitched: {
    width: '28px',
  },
  rippleStyle: {
    display: 'none',
  },
  toggledLabelStyle: {
    color: 'rgba(51, 51, 51, 1)',
    left: '-8px',
  },
  untoggledLabelStyle: {
    color: 'rgba(120, 120, 120, 1)',
    left: '-8px',
  },
};

export function StyledToggled(props) {
  let labelStyle = props.toggled ? toggleStyles.toggledLabelStyle : toggleStyles.untoggledLabelStyle;
  if(props.labelStyle) {
    labelStyle = { ...labelStyle, ...props.labelStyle}
  }
  return (<Toggle
    thumbStyle={toggleStyles.thumbOff}
    trackStyle={toggleStyles.trackOff}
    thumbSwitchedStyle={toggleStyles.thumbSwitched}
    trackSwitchedStyle={{
      ...toggleStyles.trackSwitched,
      backgroundColor: props.muiTheme.palette.primary1Color,
      border: `1px solid ${props.muiTheme.palette.primary1Color}`
    }}
    rippleStyle={toggleStyles.rippleStyle}
    {...props}
    labelStyle={labelStyle}
  />);
}
export default muiThemeable()(StyledToggled);
