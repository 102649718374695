import React, { Component } from 'react';
import styled from 'styled-components';
import t from './../../Monitor/TableNew.scss';
import cx from 'classnames';

import EmailsApi from '../../../core/api/EmailsApi';
import TagCreationTooltip from './TagCreationTooltip';
import { transformTagBeforeSend } from './utils';
import WithTooltip from '../WithTooltip';
import LeavePageConfirmDialog from '../../Monitor/Edit/LeavePageConfirmDialog';

const TooltipWrapper = styled.div`
.separator {
  text-transform: lowercase;
  color: ${props => props.theme.name === 'dark_theme' ? '#000' : '#fff'};
}
.tags_exchange {
  &>div {
    p {
      span {
        &:first-child {
          span {
            &:first-child {
              color: var(--text-links-light);
            }
            &:last-child {
              display: inline-block;
            }
          }
        }
      }

    }
  }
}
`;

class TagsTooltip extends Component {
  state = {
    tags: [],
  }

  getTagsForEmail = async (id) => {
      const res = await EmailsApi.getTags(id);
      const tags = res.data;
      this.setState({ tags: tags });
  }
  componentDidMount() {
    this.getTagsForEmail(this.props.id);
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = e => {
    const tagsTooltip = document.getElementById('draggable');
    if (tagsTooltip && !tagsTooltip.contains(e.target)) {
      this.props.handleClose();
    }
  }

  updateTags = res => {
    if (res?.data) {
      this.setState({
        tags: res.data,
      });
      return res.data;
    }
  }
  deleteTag = async (tag) => {
    const obj = {
      toRemove: [transformTagBeforeSend(tag)],
    };
    this.props.changeEmailTags(this.props.id, obj).then(this.updateTags);
  }

  saveTag = async (tag) => {
    const obj = {
      toAdd: [transformTagBeforeSend(tag)],
    };
    this.props.changeEmailTags(this.props.id, obj).then(this.updateTags);
  }

  render() {
    const { className = '', ...rest } = this.props;
    const { tags } = this.state;
    return (
      <TooltipWrapper className={cx(t.generic_table_tooltip, t.new, t.cargoes, className)} {...rest}>
        <div className={cx(t.top_loading, "tags_exchange")}>
          <div className={t.tooltip_info}>
            <TagCreationTooltip
              tags={tags}
              entity={'cargoRequest'}
              saveTag={this.saveTag}
              deleteTag={this.deleteTag}
              autocompleteProps={this.props.autocompleteProps}
            />
          </div>
        </div>
      </TooltipWrapper>
    );
  }
};

class TagsViewExchange extends Component {
  tip = ({ handleClose }) => (
    <TagsTooltip id={this.props.id} handleClose={handleClose} changeEmailTags={this.props.changeEmailTags} />
  )
  render() {
    const { tags, className, showMore = 3, id } = this.props;
    const moreTags = tags?.length - showMore;
    return (
    <Wrapper className={className}>
      <WithTooltip
        horizontalPosition="left"
        showOnEventName="onClick"
        tip={this.tip}
      >
          {
          tags?.length
          ? (
          <div className={'tag'}>
            <span>#{tags.slice(0, showMore).join(", #")}</span>&nbsp;
            {moreTags > 0 ? <span className={"more_tags"}> +{moreTags}</span> : null }
          </div>
          )
          : <span className={"tags_empty"}>No tags</span>
          }
      </WithTooltip>
    </Wrapper>);
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  overflow: hidden;
  & > div {
    display: flex;
    width: min-content;
    max-width: 100%;
  }
  .tag {
    width: min-content;

    display: flex;
    span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }
  }
  .more_tags {
    color: var(--text-links-light);
  }

  .tags_empty {
    color: ${props => props.theme.name === 'dark_theme' ? '#bec3c9' : 'var(--text-light)'};
  }
  .tag_name:first-child {
    color: ${props => props.theme.name === 'dark_theme' ? 'var(--text-medium)' : 'var(--text-links-light)'} !important;
  }
`;


export default TagsViewExchange;
