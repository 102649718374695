import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

const _FleetMonitor = props => (
  <span {...props}>
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="29" fill="#F5F5F5"/>
      <g clipPath="url(#clip0_35055_30280)">
        <rect width="2.79833" height="3.7037" transform="matrix(-1 0 0 1 28.9883 15)" fill="#495A79"/>
        <rect width="2.79833" height="3.7037" transform="matrix(-1 0 0 1 31.8047 15)" fill="#42516D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.6756 18.704H15.0117V21.155L19.6756 21.9266V29.8151H29.0034V18.704H19.95H19.6756Z" fill="#B5D7FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.9883 18.704H38.0417H38.3161H42.9799V21.155L38.3161 21.9266V29.8151H28.9883V18.704Z" fill="#82C3FF"/>
        <path d="M43 27.6698L29.0083 24.2593V40H43V27.6698Z" fill="#285596"/>
        <path d="M15.0117 27.6698L29.0034 24.2593V40H15.0117V27.6698Z" fill="#4380C7"/>
        <rect opacity="0.7" width="13.9917" height="3.70369" transform="matrix(-1 0 0 1 43 36.2963)" fill="#FF1F00"/>
        <rect width="13.9917" height="3.70369" transform="matrix(-1 0 0 1 28.9883 36.2963)" fill="#FF6F32"/>
      </g>
      <defs>
        <clipPath id="clip0_35055_30280">
          <rect width="28" height="25" fill="white" transform="translate(15 15)"/>
        </clipPath>
      </defs>
    </svg>

  </span>
);

const FleetMonitor = pure(_FleetMonitor);
FleetMonitor.displayName = 'FleetMonitor';
FleetMonitor.muiName = 'SvgIcon';

export default FleetMonitor;
