import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from './TopNotifications.scss';
import cx from 'classnames';
import { getMailGatesState } from '../../actions/login';
import { addTopMessage, removeTopMessage } from '../../actions/runtime';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import InfoIcon from 'material-ui/svg-icons/alert/error-outline';
import Link from '../Link';
import RaisedButton from '../Common/RaisedButton';
import { isUserOnFreePlan } from '../../core/utils';

class TopNotifications extends Component {


  static defaultProps = {
    topMessages:[],
  };

  static contextTypes = { path: PropTypes.string, isPublicPath: PropTypes.bool, };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.topMessages !== this.props.topMessages;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.checkSubscriptionAndAddMessage(nextProps.user);
    }
    if (nextProps.mailGatesState !== this.props.mailGatesState) {
      this.checkMailGateStateAndAddMessage(nextProps.mailGatesState);
    }
  }

  componentDidMount() {
    this.checkSubscriptionAndAddMessage(this.props.user);
    this.fetchMailGateState();
    this.mailGateCheckInterval = setInterval(this.fetchMailGateState, 1000 * 60 * 5);
  }

  componentWillUnmount() {
    clearInterval(this.mailGateCheckInterval);
  }


  checkSubscriptionAndAddMessage = (user) => {
    if(!user) {
      return;
    }
    const upgradeMessageAlreadyAdded = !!this.props.topMessages.find(m => m.id === 'upgrade-subscription');
    const userOnFreePlan = isUserOnFreePlan(user);
    if(userOnFreePlan && !upgradeMessageAlreadyAdded) {
      this.props.addTopMessage({id: 'upgrade-subscription', uncloseable:true, content: <UpgradeSubscriptionMessage />});
      return;
    }
    if(!userOnFreePlan && upgradeMessageAlreadyAdded) {
      this.props.removeTopMessage({id: 'upgrade-subscription'});
    }
  };

  checkMailGateStateAndAddMessage = (mailGatesState) => {
    const messageAdded = !!this.props.topMessages.find(m => m.id === 'mail-gate-error');
    if (!mailGatesState?.own?.length) {
      if (messageAdded) {
        this.props.removeTopMessage({ id: 'mail-gate-error' });
      }
      return;
    }
    if (messageAdded) {
      return;
    }
    const emails = mailGatesState.own.map(mg => mg.receiver.username).join();
    this.props.addTopMessage({id: 'mail-gate-error', uncloseable: true, content: <MailGateError emails={emails} />});
  }

  fetchMailGateState = () => {
    if(!this.props.user) {
      return;
    }
    this.props.getMailGatesState();
  }


  render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    if(!this.props.topMessages.length) {
      return null;
    }
    let messages = this.props.topMessages;
    if(this.context.path === '/settings/change-subscription') {
      messages = messages.filter(m => m.id !== 'upgrade-subscription');
    }
    return <div className={cx(s.root)}>
      {messages.map(message => <TopMessage key={message.id} message={message} handleClose={this.props.removeTopMessage}/>)}
    </div>
  }
}

class TopMessage extends Component {

  handleClose = () => {
    this.props.handleClose(this.props.message);
  }

  render() {
    return <div className={cx(s.message)} >
      <InfoIcon color='#F8BF50' style={{ transform: 'scale(1,-1)', marginRight: '8px' }} />
      {this.props.message.content}
      {!this.props.message.uncloseable ? <NavigationClose className={cx(s.close)}  onClick={this.handleClose} /> : null}
    </div>
  }
}


export default connect(state => ({topMessages: state.runtime.topMessages, user: state.login.user, mailGatesState: state.login.mailGatesState }), { removeTopMessage, addTopMessage, getMailGatesState })(
  withStyles(s)(TopNotifications),
);


export class UpgradeSubscriptionMessage extends Component {
  render() {
    return <div className={s.upgrade_subscription_message} > <span>Have limited access on SHIPNEXT? Upgrade to Premium and get the full access to your seamless workflow.</span><Link to={'/settings/change-subscription'}>
      <RaisedButton
        style={{
          display: 'block',
          minWidth: '112px',
          maxWidth: '112px',
          height: '28px',
          marginRight: '12px',
          borderRadius: '15px'
        }}
        buttonStyle={{
          borderRadius: '15px',
        }}
        labelStyle={{
          display: 'inline-block',
          padding: '0',
          fontSize: '11px',
          fontWeight: '600',
          lineHeight: '28px',
        }}
        primary
        label={'Upgrade now'}
      />
    </Link> </div>;
  }
}

export function MailGateError(props) {
  return <div className={s.upgrade_subscription_message}>
    <span>Your connection to mailbox <b>{props.emails}</b> has been lost.</span><Link to={'/settings/mail-gate'}>
    <RaisedButton
      style={{
        display: 'block',
        minWidth: '112px',
        maxWidth: '112px',
        height: '28px',
        marginRight: '12px',
        borderRadius: '15px',
      }}
      buttonStyle={{
        borderRadius: '15px',
      }}
      labelStyle={{
        display: 'inline-block',
        padding: '0',
        fontSize: '11px',
        fontWeight: '600',
        lineHeight: '28px',
      }}
      primary
      label={'Settings'}
    />
  </Link></div>;
}
