/** @name POSITIONS_BY_VESSEL_ID_REDUCER */
export default function POSITIONS_BY_VESSEL_ID(state, { res }) {
  const newPositionsList = { ...state.positionsList };
  newPositionsList.data = res.data;
  newPositionsList.page = res.page;
  newPositionsList.total = res.total;
  newPositionsList.next = res.next;
  newPositionsList.loading = false;
  const newState = { positionsList: newPositionsList };
  return { ...state, ...newState };
}
