import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from './Dialog';
import s from './PortLinkNew.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { List, ListItem } from 'material-ui/List';
import cx from 'classnames';
import ArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import ArrowDropUp from 'material-ui/svg-icons/navigation/arrow-drop-up';
import IconButton from 'material-ui/IconButton';
import ActionCheckCircle from 'material-ui/svg-icons/action/check-circle';
import ContentBlock from 'material-ui/svg-icons/content/block';

const serviceProviders = [
  {
    activity: 'Stevedoring Co',
    companyName: '20 Century Fox',
    email: 'contact@company.com',
    phone: '+38 063 45 78 456, m +38 063 45 78 456',
  },
  {
    activity: 'Stevedoring Co',
    companyName: 'Kulebyakin Corp',
    email: 'contact@company.com',
    phone: '+38 063 45 78 456, m +38 063 45 78 456',
  },
  {
    activity: 'Port Agency',
    companyName: 'Company name longggggg',
    email: 'contact@company.com',
    phone: '+38 063 45 78 456, m +38 063 45 78 456',
  },
  {
    activity: 'Port Agency',
    companyName: 'Company name',
    email: 'contact@company.com',
    phone: '+38 063 45 78 456, m +38 063 45 78 456',
  },
  {
    activity: 'Bunker Trader',
    companyName: 'Company name',
    email: 'contact@company.com',
    phone: '+38 063 45 78 456, m +38 063 45 78 456',
  },
];
const mtDescription = [
  {
    mt: '0-24',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  {
    mt: '25-49',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
];
const facilitiesMap = {
  breakBulk: 'Break Bulk Facilities',
  container: 'Container Facilities',
  csiComplaint: 'CSI Complaint Facilities',
  dryBulk: 'Dry Bulk Facilities',
  dryDock: 'Dry Dock Facilities',
  ispsComplaint: 'ISPS Complaint Facilities',
  liquid: 'Liquid Facilities',
  lng: 'LNG Facilities',
  lpg: 'LPG Facilities',
  multipurpose: 'Multipurpose Facilities',
  passenger: 'Passenger Facilities',
  roro: 'RORO Facilities',
};

class PortLinkNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpened: false,
      handleClose: false,
      openArrowService: false,
      openArrowLiftCranes: false,
      openArrowOther: false,
    };
  }
  _handleOpenDialog = () => {
    this.setState({ dialogOpened: true });
    this.setState({ handleClose: true });
  };
  _handleCloseDialog = () => {
    this.setState({ dialogOpened: false });
  };

  _onOpenListItemService = () => {
    this.setState({
      openArrowService: !this.state.openArrowService,
    });

  };
  _onOpenListItemLiftCranes = () => {
    this.setState({
      openArrowLiftCranes: !this.state.openArrowLiftCranes,
    });

  };
  _onOpenListItemOther = () => {
    this.setState({
      openArrowOther: !this.state.openArrowOther,
    });

  };


  render() {
    console.error('DONT USE PortLinkNew !!!!!!!!!!!!!!!!!!!');
    return (
      <p className={this.props.className ? this.props.className : null} onClick={this._handleOpenDialog}>
        {this.props.name}
        { this.state.dialogOpened
          ? <Dialog
            className={s.modal}
            contentClassName={s.content_modal}
            bodyClassName={s.wrapper_modal}
            open
            handleClose={this._handleCloseDialog}
          >
            <h2>Port info</h2>
            <p className={s.city}>{this.props.name}</p>
            <div className={s.info_zone}>
              <p>UN LOCODE: </p>
              <p>Time Zone: GMT +1</p>
              <a href='#' target="_blank" rel="noopener noreferrer">Web Site</a>
            </div>
            <List
              style={{
                padding: this.state.openArrowService ? '22px 0 0px' : '22px 0px 20px',
                borderBottom: '1px solid #E0E0E0',
              }}
            >
              <ListItem
                className={s.wrapper_title}
                innerDivStyle={{
                  padding: '0',
                }}
                key={1}
                touchRippleColor="transparent"
                hoverColor="transparent"
                initiallyOpen={false}
                open={this.state.openArrowService}
                onNestedListToggle={this._onOpenListItemService}
                nestedItems={[
                  <ListItem
                    key={2}
                    className={s.wrapper_listitem}
                    touchRippleColor="transparent"
                    hoverColor="transparent"
                    primaryText={
                      <div>
                        <div className={s.title_input_service}>
                          <p>Activity</p>
                          <p>Company Name</p>
                          <p>Contact Details</p>
                        </div>
                        { serviceProviders.map((item, i) => (
                          <div  className={s.input_service}>
                            <p>
                              <span>{item.activity}</span>
                            </p>
                            <p>
                              <span>{item.companyName}</span>
                            </p>
                            <p>{item.email} {item.phone}</p>
                          </div>
                          ))
                        }
                      </div>
                    }
                  />
                ]}
                primaryText={
                  <div>
                    <p className={s.title}>Service providers</p>
                    <p className={s.show}>{this.state.openArrowService ? 'Hide' : 'Show contacts'}</p>
                  </div>
                }
                rightIconButton={
                  <IconButton
                    className={s.button}
                    onClick={this._onOpenListItemService}
                  >
                    {this.state.openArrowService ?
                      <ArrowDropUp />
                      :
                      <ArrowDropDown />
                    }
                  </IconButton>
                }
              />
            </List>
            <div className={s.harbour_limitation}>
              <p className={s.title}>Harbour Limitations</p>
              <div className={s.limitations}>
                <p>Max Draft:</p>
                <p>Max Beam:</p>
              </div>
              <div className={s.limitations}>
                <p>Max LOA:</p>
                <p>Max DWT:</p>
              </div>
            </div>
            <div className={s.lift_cranes}>
              <p className={s.title}>Available Lift and Cranes</p>
              <List
                style={{
                  padding: '0'
                }}
              >
                <ListItem
                  className={s.wrapper_title}
                  innerDivStyle={{
                    padding: '0',
                  }}
                  key={1}
                  touchRippleColor="transparent"
                  hoverColor="transparent"
                  initiallyOpen={false}
                  open={this.state.openArrowLiftCranes}
                  onNestedListToggle={this._onOpenListItemLiftCranes}
                  nestedListStyle={{
                    padding: '18px 0 0'
                  }}
                  nestedItems={[
                    <ListItem
                      key={2}
                      className={s.wrapper_listitem}
                      touchRippleColor="transparent"
                      hoverColor="transparent"
                      primaryText={
                        <div className={s.wrapper_input_mt}>
                          { mtDescription.map((item, i) => (
                            <div>
                              <p>{item.mt}mt</p>
                              <p>{item.description}</p>
                            </div>
                          ))
                          }
                        </div>
                      }
                    />
                  ]}
                  primaryText={
                    <div>
                      <div className={s.wrapper_title_mt}>
                        <span className={s.available}>
                          <ActionCheckCircle /> 0-24mt
                        </span>
                        <span className={s.available}>
                          <ActionCheckCircle /> 25-49mt
                        </span>
                        <span className={s.not_available}>
                          <ContentBlock /> 50-99mt
                        </span>
                        <span className={s.not_available}>
                          <ContentBlock /> 100mt
                        </span>
                      </div>
                      <p className={cx(s.show, s.show_lift_cranes)}>{this.state.openArrowLiftCranes ? 'Hide' : 'Details'}</p>
                    </div>
                  }
                  rightIconButton={
                    <IconButton
                      onClick={this._onOpenListItemLiftCranes}
                      className={s.button}
                    >
                      {this.state.openArrowLiftCranes ?
                        <ArrowDropUp />
                        :
                        <ArrowDropDown />
                      }
                    </IconButton>
                  }
                />
              </List>
            </div>
            <div className={s.available_facilities}>
              <p className={s.title}>Available Facilities</p>
              <ul className={s.wrapper_available_facilities}>
                <li>Break Bulk Facilities</li>
                <li>Container Facilities</li>
                <li>CSI Complaint Facilities</li>
                <li>Dry Bulk Facilities</li>
                <li>Dry Dock Facilities</li>
                <li>ISPS Complaint Facilities</li>
                <li>Liquid Facilities</li>
                <li>LNG Facilities</li>
                <li>LPG Facilities</li>
                <li>Multipurpose Facilities</li>
                <li>Passenger Facilities</li>
                <li>RORO Facilities</li>
              </ul>
            </div>
            <List
              style={{
                padding: '25px 0 0px'
              }}
            >
              <ListItem
                className={s.wrapper_title}
                innerDivStyle={{
                  padding: '0',
                }}
                key={1}
                touchRippleColor="transparent"
                hoverColor="transparent"
                initiallyOpen={false}
                open={this.state.openArrowOther}
                onNestedListToggle={this._onOpenListItemOther}
                nestedItems={[
                  <ListItem
                    key={2}
                    className={s.wrapper_listitem}
                    touchRippleColor="transparent"
                    hoverColor="transparent"
                    primaryText={
                      <div className={s.other}>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                      </div>
                    }
                  />
                ]}
                primaryText={
                  <div>
                    <p className={s.title}>Other</p>
                    <p className={s.show}>{this.state.openArrowOther ? 'Hide' : 'View'}</p>
                  </div>
                }
                rightIconButton={
                  <IconButton
                    onClick={this._onOpenListItemOther}
                    className={s.button}
                  >
                    {this.state.openArrowOther ?
                      <ArrowDropUp />
                      :
                      <ArrowDropDown />
                    }
                  </IconButton>
                }
              />
            </List>
          </Dialog>
          : null
        }
      </p>
    );
  }
}

export default withStyles(s)(PortLinkNew);
