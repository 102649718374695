import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './Commission.scss';
import FormsyText from './FormsyText';
import CustomCheckbox from './CustomCheckbox';
import CheckBox from './CheckBox';
import NarrowFormsyText from '../NewInputs/NarrowFormsyText';

const floatingLabelStyle = {fontSize: '13px'};
const floatingLabelFocusStyle = {fontSize: '15px'};
const inputStyle = {fontSize: '13px'};
const hintStyle = {fontSize: '13px'};

function Commission({ previousValues, handleChange = () => { }, floatingLabelText, comm, deductible, pus }) {

  return (<div className={s.root}>
    <div className={s.commission}>
      <NarrowFormsyText
        floatingLabelText={floatingLabelText}
        name="comm"
        validationError={'0 - 25'}
        validations="isNumeric,min:0,max:25"
        fullWidth
        type={'number'}
        step={0.25}
        min={0}
        max={25}
        previousValue={previousValues.comm}
        value={comm}
        onChange={handleChange.bind(null, 'comm')}
      />
    </div>
    <div className={cx(s.pus, !floatingLabelText && s.h48)}>
      <CustomCheckbox
        as={CheckBox}
        name={'pus'}
        previousValue={previousValues.pus}
        label={'PUS'}
        labelStyle={{
          marginRight: '-10px',
          fontSize: '13px',
          color: 'rgba(#000000, 0.8)',
        }}
        value={pus}
        onChange={handleChange.bind(null, 'pus')}
      />
    </div>

    <div className={cx(s.deductible, !floatingLabelText && s.h48)}>
      <CustomCheckbox
        as={CheckBox}
        name={'deductible'}
        previousValue={previousValues.deductible}
        label={'Deductable'}
        labelStyle={{
          marginRight: '-10px',
          fontSize: '13px',
          color: 'rgba(#000000, 0.8)',
        }}
        value={deductible}
        onChange={handleChange.bind(null, 'deductible')}
      />
    </div>
  </div>);
}
Commission.defaultProps = {
  previousValues: {},
  floatingLabelText: 'Comm, %',
};
export default withStyles(s)(Commission);
