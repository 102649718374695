import {cloneDeep, uniq} from "lodash";
import {clearEmptyStrings, fromUTC, objectIdToUUID, toUTC, UUIDToObjectId} from "../../core/utils";
import ContactBookApi from "../../core/api/ContactBookApi";
import {findIndustryById, findSectorById} from "../dictionaries";

export async function createContactAdapter(contact) {
  contact = cloneDeep(contact);
  contact.phones = contact.phones.filter(p => p.number?.length > 4);
  contact.importantDates = contact.importantDates.filter(id => id.date).map(id => ({ ...id, date: toUTC(id.date) }));
  contact.emails = contact.emails.filter(email => email);
/*  if (contact.company) {
    contact.companyId = contact.company.id;
  }
  if (contact.group) {
    contact.groupId = contact.group.id;
  }*/
  if (contact.attachments) {
    contact.attachmentIds = contact.attachments.map(a => a._id || a.fileId);
  }
  if (contact.persons) {
    contact.memberIds = uniq(contact.persons.map(m => m.id));
    contact.persons = undefined;
  }
  if (contact.companies) {
    for (let i = 0; i < contact.companies.length; i++) {
      let c = contact.companies[i];
      if (typeof c === "string") {
        c = c.trim();
        if (c) {
          c = await ContactBookApi.findOrCreateCompany(c);
          contact.companies[i] = c;
        }
      }
    }
    contact.companyIds = uniq(contact.companies.filter(c => c && c.id).map(c => c.id));
    contact.companies = undefined;
  }
  if (contact.country) {
    contact.countryId = objectIdToUUID(contact.country._id);
  } else {
    contact.countryId = null;
  }
  if (contact.city) {
    contact.cityId = objectIdToUUID(contact.city._id);
  } else {
    contact.cityId = null;
  }
  if (!contact.website) {
    contact.website = "";
  }
  if (contact.birthDate) {
    contact.birthDate = toUTC(contact.birthDate);
  }
  contact.industry = contact.industry || null;
  contact.sector = contact.sector || null;
  contact.tags = undefined;
  return contact;
}

export function fromServerContactAdapter(contact){
  contact = cloneDeep(contact);
  if (contact.birthDate) {
    contact.birthDate = fromUTC(contact.birthDate);
  }
  return contact;
}


export function formatSector(_id) {
  if (!_id) {
    return;
  }
  return findSectorById(_id)?.name_en;
}

export function formatIndustry(_id) {
  if (!_id) {
    return;
  }
  return findIndustryById(_id)?.name_en;
}
