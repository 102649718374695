import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let ChatMailIcon = ({ fill = '#76818D', opacity = 1, ...props }) => (
  <span style={{ opacity: opacity }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.392 3H3.592C2.712 3 2 3.6075 2 4.35V17L5.19116 13.8H16.392C17.272 13.8 17.992 13.1925 17.992 12.45V4.35C17.992 3.6075 17.272 3 16.392 3ZM9.9921 9.07501L16.3921 5.70001V4.35001L9.9921 7.72501L3.5921 4.35001V5.70001L9.9921 9.07501Z" fill={fill}/>
      <path d="M19.8253 8H21.4919C21.9503 8 22.3253 8.375 22.3253 8.83333V21.3333L18.9919 18H9.82528C9.36694 18 8.99194 17.625 8.99194 17.1667V15.5H19.8253V8Z" fill={fill}/>
    </svg>
  </span>
);

ChatMailIcon = pure(ChatMailIcon);
ChatMailIcon.displayName = 'ChatMailIcon';
ChatMailIcon.muiName = 'SvgIcon';

export default ChatMailIcon;
