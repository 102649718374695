import React from 'react';
import Layout from '../../components/Layout';
import AllFleetSection from '../../components/Vessel/AllFleet/allFleetSection';
import PortListSection from '../../components/Port/PortListSection';
import ContactBookPage from "../../components/ContactBook/ContactBookPage";
import {getAccountFilters} from "../../actions/login";

const portsNavLinks = [{ name: 'PORTS', url: '/port' }, { name: 'FLEET', url: '/all-fleet' }];
const allFleetNavLinks = [{ name: 'FLEET', url: '/all-fleet' }, { name: 'PORTS', url: '/port' }];

function parseActionProps(params) {
  if (params.action.indexOf("?") === -1) {
    return;
  }
  // eslint-disable-next-line prefer-const
  let [action, props] = params.action.split("?", 2);
  const it = (new URLSearchParams(props)).entries();
  props = {};
  for (const [key, val] of it) {
    if (props[key] !== undefined) {
       if (!Array.isArray(props[key])) {
         props[key] = [props[key]];
       }
       if (val) {
         props[key].push(val);
       }
    } else {
      props[key] = val;
    }
  }
  params.action = action;
  params[`${action}Props`] = props;
  return params;
}

function parseContactEntity(params){
  if (params.contactId){
    const a = params.contactId.split(':');
    if (a.length > 1) {
      params.entity = a[0];
      params.contactId = a[1];
    }
  }
}

export default {
  path: '/contacts',
  children: [
    {
      path: '/:folder/:contactId/:action/:reserved/---',
      async action({ params, next, baseUrl, store, token, ...rest }) {
        if (params.contactId === '-'){
          params.contactId = undefined;
        }
        parseActionProps(params);
        parseContactEntity(params);
        const rightSection = await next();
        try {
          if (!rightSection.filtersFetched && (!rest.previousPath || rest.pathname.slice(12) !== rest.previousPath.slice(12))) {
            await store.dispatch(getAccountFilters(token));
          }
        } catch (e) {
          console.error(e);
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <ContactBookPage {...params} rightSection={rightSection}/>
            </Layout>
          ),
          public: false,
        };
      },
      children: [{
        path: '/all-fleet/:vesselId?/:action?/:reserved?',
        async action({ params, hash, baseUrl }) {
          if (params.vesselId === 'create') {
            params.action = 'create';
            delete params.vesselId;
          }
          const _baseUrl = baseUrl + '/all-fleet/';
          const links = allFleetNavLinks.map( l => ({ ...l, url: baseUrl + l.url }));
          return {
            name: 'all-fleet',
            title: 'FLEET',
            component: <AllFleetSection baseUrl={_baseUrl} detailsBaseUrl={_baseUrl} hash={hash} {...params}
                                        create={params.action === 'create'} navLinks={links} pickThemeFrom="vesselList" />,
          }
        },
      },
        {
          path: '/port/:portId?/:action?/:reserved?',
          async action({ params, hash, baseUrl }) {
            const _baseUrl = baseUrl + '/port/';
            if(params.portId?.length < 3) {
              delete params.portId;
            }
            const links = portsNavLinks.map( l => ({ ...l, url: baseUrl + l.url }));
            return {
              name: 'ports',
              title: 'PORTS',
              component: <PortListSection baseUrl={_baseUrl} addPortEnabled={false} navLinks={links} {...params}/>,
            }
          },
        },
      ],
    },
  ],
};
