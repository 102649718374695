export default function LOGOUT(state, action) {
  const newState = {
    user: null,
    error: null,
    loading: false,
    loginOpened: false,
    counters: {
      cargoBids: 0,
      cargoTcBids: 0,
      vesselBids: 0,
      vesselTcBids: 0,
      contractBids: 0,
      total: 0,
    },
  };
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        window.localStorage.setItem('logout-event', 'logout' + Math.random());
      }
    }
  } catch (e) {
    console.error(e);
  }
  return { ...state, ...newState };
}
