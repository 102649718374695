import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Cargo = ({ fill = "#FFFFFF", opacity = 0.4, ...props }) => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg width="14px" height="20px" viewBox="0 0 14 20" version="1.1">
        <defs />
        <g
          id="WEB"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={opacity}
        >
          <g
            id="Menu-Opened"
            transform="translate(-17.000000, -162.000000)"
            fill={fill}
          >
            <g id="Icons/Cargo" transform="translate(12.000000, 160.000000)">
              <path
                d="M17,5 L19,5 L19,7.85026279 L13.0458971,12 L10.7530927,12 L5,7.99035765 L5,5 L7,5 L7,2 L9,2 L9,5 L15,5 L15,2 L17,2 L17,5 Z"
                id="Combined-Shape"
              />
              <path
                d="M11,16.1337822 L11,14.1260175 C11.3196199,14.043753 11.6547004,14 12,14 C14.209139,14 16,15.790861 16,18 C16,20.209139 14.209139,22 12,22 C9.790861,22 8,20.209139 8,18 L10,18 C10,19.1045695 10.8954305,20 12,20 C13.1045695,20 14,19.1045695 14,18 C14,16.8954305 13.1045695,16 12,16 L11,16 L11,16.1337822 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  </span>
);

Cargo = pure(Cargo);
Cargo.displayName = 'Cargo';
Cargo.muiName = 'SvgIcon';

export default Cargo;
