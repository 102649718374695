/*
 * @Author: salterok
 * @Date: 2017-10-04 14:36:54
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-19 17:40:21
 */

import { CHAT_MESSAGES_RECEIVED, CHAT_INITIAL_INFO_RECEIVED, CHAT_INCREMENTAL_UPDATE, CHAT_ROOMS_CHANGED } from "./actions";

export function messagesReceived(messages, user, dispatch) {
  dispatch({
    type: CHAT_MESSAGES_RECEIVED,
    payload: messages
  });
}

export function initialInfo(data, user, dispatch) {
  dispatch({
    type: CHAT_INITIAL_INFO_RECEIVED,
    payload: data
  });
}

export function incrementalUpdate(data, user, dispatch) {
  dispatch({
    type: CHAT_INCREMENTAL_UPDATE,
    payload: data
  });
}

export function changeRooms(data, user, dispatch) {
  dispatch({
    type: CHAT_ROOMS_CHANGED,
    payload: data
  });
}
