import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _Dialog from 'material-ui/Dialog';
import s from './Dialog.scss';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from 'classnames';
import NavigationClose from 'material-ui/svg-icons/navigation/close';

const dialogStyles = {
  style: { top: '48px', height: 'calc(100vh - 48px)', zIndex: 1199 },
  bodyStyle: {
    padding: '0 0 32px',
    overflowY: 'hidden!important',
  },
  contentStyle: {},
  overlayStyle: { display: 'none' },
  titleStyle: {
    display: 'none',
  }
};

class DialogHalfScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  static contextTypes = {
    eddie: PropTypes.object,
  }

  getStyle = () => {
    if (typeof document === 'undefined') {
      return dialogStyles.style;
    }
    const page = document.querySelector(this.props.anchor);
    if (!page) {
      return dialogStyles.style;
    }
    const offsetTop = page.getBoundingClientRect().top;
    return { ...dialogStyles.style, top: `${offsetTop}px`, height: `calc(100vh - ${offsetTop}px)`};
  }

  async componentWillReceiveProps(nextProps) {
    if (this.context.eddie) {
      if (this.props.open !== nextProps.open){
        if (nextProps.open) {
          await this.openInEddie();
        } else {
          this.eddieCloser && this.eddieCloser();
          this.eddieCloser = null;
        }
      }
    }
  }

  async componentWillMount() {
    if (this.props.open && this.context.eddie && !this.props.noEddie) {
      await this.openInEddie();
    }
  }
   componentWillUnmount() {
     this.eddieCloser && this.eddieCloser();
  }


  async openInEddie(props = this.props) {
    this.eddieCloser = await this.context.eddie.open(this.props.position,'',undefined,({handleClose}) => {
      return this.props.children;
    });
  }


  render() {
    if (this.context.eddie && !this.props.noEddie) {
      return null;
    }
    const { header, open,title, handleClose, style = {}, ...rest } = this.props;
    const ss = { ...this.getStyle(), ...style };
    return (
      <_Dialog
        modal={false}
        open={this.props.open || this.state.open}
        onRequestClose={this.props.handleClose}
        autoScrollBodyContent={this.props.autoScrollBodyContent}
        bodyStyle={dialogStyles.bodyStyle}
        overlayStyle={dialogStyles.overlayStyle}
        repositionOnUpdate={false}
        autoDetectWindowHeight={false}
        {...dialogStyles}
        {...rest}
        style={ss}
        className={cx(s.rightHalfDialog, s[this.props.position])}
        contentClassName={cx()}
      >
        {this.props.children}
      </_Dialog>
    );
  }
}

DialogHalfScreen.propTypes = {
  handleClose: PropTypes.func.isRequired,
  autoScrollBodyContent: PropTypes.bool,
  anchor: PropTypes.string,
};
DialogHalfScreen.defaultProps = {
  autoScrollBodyContent: true,
  position: 'right',
  anchor: '#app .page_content',
};

export default withStyles(s)(DialogHalfScreen);
