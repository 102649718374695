import { escapeRegExp } from '../../core/utils';
import { date } from '../../core/format';


export function filterVesselRequest(requestList, sortByStatus = true) {
  let result;
  if (requestList.filters.query) {
	 const newRequestList = { ...requestList };
	 const q = escapeRegExp(requestList.filters.query);

	 newRequestList.filtered = newRequestList.data.filter(c => q.test(c.search));
    result = newRequestList;
  } else {
    requestList.filtered = requestList.data;
    result = requestList;
  }
  if (sortByStatus) {
    result.filtered.sort((a, b) => b.status.weight - a.status.weight);
  }
  result.vesselsOnMap = locationOnMapToGeoJSON(requestList.data);
  return result;
}

export function filterTcOffer(tcOfferList) {
  return filterVesselRequest(tcOfferList, false);
}

export function filterVesselList(vesselList) {
  return filterVesselRequest(vesselList, false);
}

export function freightOfferGenSearchString(fo) {
  try {
    let search = '';
    search += fo.refNo + ' ';
    search += fo.name + ' ';
    search += fo.imoNumber + ' ';
    search += fo.cargoRequest.loadingPort.name + ' ';
    search += fo.cargoRequest.unloadingPort.name + ' ';
    if (fo.cargoRequest.cargo && fo.cargoRequest.cargo.join) {
      search += fo.cargoRequest.cargo.join(', ') + ' ';
    }
    search += (fo.cargoRequest.totalValues.weight / 1000) + ' ';
    search += fo.cargoRequest.totalValues.volume + ' ';
    search += date(fo.cargoRequest.readinessDate) + ' - ';
    search += date(fo.cargoRequest.cancellingDate) + ' - ';
    if (fo.freight) {
      search += '$' + fo.freight.value + ' ' + fo.freight.term;
    }
    fo.search = search;
    fo.key = fo._id + '|' + fo.cargoRequest._id + '|';
  } catch (e) {
    console.error(e);
  } finally {
    return fo;
  }
}

function locationOnMapToGeoJSON(vessels) {
  const res = {
    'type': 'FeatureCollection',
  }
  vessels = vessels.map(v => v.vessel || v);
  vessels = vessels.filter(v => v.locationOnMap?.lastPos?.coords);
  res.features = vessels.map(v => {
    return {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': v.locationOnMap.lastPos.coords,
      },
      'properties': {
        '_id': v._id,
        'imoNumber': v.imoNumber,
        'typeByPurpose': v.typeByPurpose,
        'angle': v.locationOnMap.lastPos.angle,
        'speed': v.locationOnMap.lastPos.speed,
      },
    }
  })

  return res;
}
