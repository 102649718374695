import { clearCookie } from '../../core/utils';
import finishInvite from './finishInvite';
/** @name LOGIN_REDUCER */
export default function LOGIN(state, { res }) {
  if (res.status === 200 && typeof document !== 'undefined') {
    clearCookie('redirectAfterLogin');
    finishInvite(state);
  }
  const newState = {
    user: res.data,
    error: null,
    message: null,
    loading: false,
    loginOpened: false,
    afterQueue: [],
  };
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        window.localStorage.setItem('login-event', 'login' + Math.random());
      }
      if(window.Raven) {
        window.Raven.setUserContext({
          id: res.data._id
        })
      }
    }
  } catch (e) {
    console.error(e);
  }
  return { ...state, ...newState };
}
