
// /** @name MY_SNP_LIST_REDUCER */
// export default function MY_SNP_LIST(state, { res }) {
//   const data = res.data;

//   return {...state, snpList: {data, filters, filtered}}
// }


import { filterVesselList } from './filter';
//noinspection JSUnusedGlobalSymbols

/** @name MY_SNP_LIST_REDUCER */
export default function MY_SNP_LIST(state, { res, payload }) {
  const newSnpList = { ...state.snpList };

  try {
    res.data = res.data.map((v, i) => {
      let search = '';
      search += v.vessel.imoNumber + ' ';
      search += v.vessel.name + ' ';
      search += v.vessel.refNo + ' ';
      v.vessel.owner ? search += v.vessel.owner.name + ' ' : '';
      v.search = search;
      return v;
    });

    if (res.page === 1) {
      newSnpList.data = res.data;
    } else {
      newSnpList.data = [...newSnpList.data, ...res.data];
    }

    newSnpList.total = res.total;
    newSnpList.sort = payload.sort || state.snpList.sort;
    newSnpList.filters = payload.filters || state.snpList.filters;
    newSnpList.hidden = payload.hidden;
    newSnpList.page = res.page;
    newSnpList.next = res.next;
    newSnpList.loading = false;
    newSnpList.hiddenCount = res.hiddenCount
  } catch (err) {
    console.error(err)
  }

  const newState = { snpList: filterVesselList(newSnpList) };
  // const newState = { snpList: newSnpList };
  return { ...state, ...newState };
}


