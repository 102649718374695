/* eslint-disable no-use-before-define */
import React from 'react';
import imoClasses from '../Monitor/Edit/imoClasses';
import s from './Cargo.scss';
import { getNamesInBrackets, stripBrackets } from './utils';
import { formatRange, number } from '../../core/format';

import dictionaries, {findContainerTypeById} from "../dictionaries";

const densityToPreview = (pack, name) => ({ label: 'Density', value: pack[name]?.map(d => `${d.density} kg/m3${typeof d.temperature === 'number' ? ` at ${d.temperature} °C` : ''}`)?.join(', ') })

const fields = [
  {
    // 0
    packingCategories: ['bulk', 'unpacked', 'packed'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
      dangerous({
        nextLine: true,
      }),
      {
        nextLine: true,
        name: '[propelled]',
        label: 'Self-propelled',
        type: 'checkbox',
        col: 'col-12 col-md-4',
      },
    ],
    unpacked: [
      stackable({}),
      unitsQuantity({ nextLine: true, col: 'col-12 col-md-4', required: false }),
      unitLength({
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWidth({
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitHeight({
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWeight({
        col: 'col-12 col-md-4',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitsWeight.value*1/state.unitsQuantity',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        nextLine: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleBeam({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      unitWeight({
        label: 'Unit weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      unitsQuantity({
        label: 'Quantity ({{cargoName}})',
        col: 'col-12 col-md-4',
        calculated: '1*state.unitsWeight.value/state.unitWeight.value',
        required: false,
      }),
      unitsPerBundleQuantity({
        label: 'Quantity ({{cargoName}} in {{packingName}})',
        required: false,
      }),
      bundlesQuantity({
        label: 'Quantity ({{packingName}})',
        calculated: 'Math.ceil(state.unitsQuantity/state.unitsPerBundleQuantity)',
        required: false,
      }),
      bundleWeight({
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitWeight.value*state.unitsPerBundleQuantity',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ required: false }),
      dunnaging(),
    ],
    bulk: [
      stowageFactor(),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: true,
    packingList: true,
    flipNested: true,
  }, // /0,
  { // 1 Aeroplane
    packingCategories: ['bulk', 'unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      unitBeam({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      unitHeight({ units: { mt: 'mm', gb: 'feet' }, required: false }),
      unitWeight({ units: { mt: 'mt', gb: 'lb' }, required: false }),
      {
        name: '[propelled]',
        label: 'Self-propelled',
        type: 'checkbox',
        col: 'col-12 col-md-4',
      },
    ],
    packed: [],
    unpacked: [],
    bulk: [
      stowageFactor(),
      stowageFactorUnit(),
    ],
    clauses: [
      {
        name: '[Shipment][value]',
        label: 'Shipment',
        required: true,
        type: 'select',
        defaultValue: 'under deck',
        values: [
          { label: 'under deck', value: 'under deck' },
          { label: 'on deck', value: 'on deck' },
          { label: 'on/under deck', value: 'on/under deck' },
        ],
        col: 'col-12 col-md-4',
      },
      {
        name: '[Cradles to be for][value]',
        label: 'Cradles to be for',
        required: true,
        type: 'select',
        defaultValue: "Charterer's account",
        values: [
          { label: "Charterer's account", value: "Charterer's account" },
          {
            label: "Charterer's account, and time",
            value: "Charterer's account, and time",
          },
          {
            label: "Charterer's account, risk and time",
            value: "Charterer's account, risk and time",
          },
          { label: "Owner's account", value: "Owner's account" },
          {
            label: "Owner's account, and time",
            value: "Owner's account, and time",
          },
          {
            label: "Owner's account, risk and time",
            value: "Owner's account, risk and time",
          },
        ],
        col: 'col-12 col-md-8',
      },
    ],
    drawings: true,
    packingList: true,
  },
  {
    // 2 Pipes
    packingCategories: ['bulk', 'unpacked', 'packed'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
      <div style={{ width: "100%" }} />,
      unitLength({
        units: { mt: 'mm', gb: 'foot' },
        required: false,
      }),
      unitDiam({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      {
        name: '[unitWeightPerMeter][value]',
        label: 'Unit Weight, mt/meter',
        type: 'text',
        units: { mt: 'mt', gb: 'lb' },
        required: false,
        defaultValue: '',
        validationErrors: {
          isNumeric: 'only numbers',
          gt0: 'must be greater than 0',
          'maxWeight:10000': 'max 10000mt',
        },
        // fields: [
        //   {
        //     name: '[weightPer][term]',
        //     label: ' ',
        //     required: true,
        //     type: 'select',
        //     defaultValue: 'per_unit',
        //     values: [
        //       { label: 'per unit', value: 'per_unit' },
        //       { label: 'per meter', value: 'per_meter' },
        //     ],
        //     col: 'col-12 col-md-4',
        //   },
        // ],
        col: 'col-12 col-md-4',
      },
    ],
    unpacked: [
      stackable({}),
      unitsQuantity({ nextLine: true, col: 'col-12 col-md-4', required: false }),
      unitWeight({
        label: 'Pipe weight, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitsWeight.value*1/state.unitsQuantity',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ nextLine: true, required: false }),
      dunnaging(),
      nested(),
    ],
    packed: [
      stackable({}),
      bundleLength({
        label: 'Max Length, {{unit}} (bundle)',
        nextLine: true,
        units: { mt: 'mm', gb: 'feet' },
        calculated: 'state.unitLength.value ? 1*state.unitLength.value+200 : undefined',
        required: false,
      }),
      bundleBeam({
        label: 'Max Beam, {{unit}} (bundle)',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      bundleHeight({
        label: 'Max Height, {{unit}} (bundle)',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWeight({
        label: 'Pipe weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      unitsQuantity({
        label: 'Quantity (pipes)',
        col: 'col-12 col-md-4',
        calculated: '1*state.unitsWeight.value/state.unitWeight.value',
        required: false,
      }),
      unitsPerBundleQuantity({ label: 'Quantity (pipes in bundle)', required: false }),
      bundleWeight({
        label: 'Bundle weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitWeight.value*state.unitsPerBundleQuantity',
        required: false,
      }),
      bundlesQuantity({
        label: 'Quantity (bundles)',
        calculated: 'Math.ceil(state.unitsQuantity/state.unitsPerBundleQuantity)',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ nextLine: true, required: false }),
      dunnaging(),
    ],
    bulk: [
      stowageFactor(),
      stowageFactorUnit(),
    ],
    clauses: [
      {
        name: '[Shipment][value]',
        label: 'Shipment',
        required: true,
        type: 'select',
        defaultValue: 'under deck',
        values: [
          { label: 'under deck', value: 'under deck' },
          { label: 'on deck', value: 'on deck' },
          { label: 'on/under deck', value: 'on/under deck' },
        ],
        col: 'col-12 col-md-4',
      },
      <div style={{ width: "100%" }} />,
      {
        name: '[Pipes not to be overstowed with other cargo][value]',
        label: 'Pipes not to be overstowed with other cargo',
        type: 'checkbox',
        col: 'col-12 col-md-7',
      },
      {
        name: '[Box shaped hold only][value]',
        label: 'Box shaped hold only',
        type: 'checkbox',
        col: 'col-12 col-md-5',
      },
      {
        name: '[Pipes with flanges and not streight on ends][value]',
        label: 'Pipes with flanges and not streight on ends',
        type: 'checkbox',
        col: 'col-12 col-md-7',
      },
      {
        name: '[Wooden dunnaging][value]',
        label: 'Wooden dunnaging',
        type: 'checkbox',
        col: 'col-12 col-md-5',
      },
    ],
    drawings: true,
    packingList: true,
  }, // /2

  {
    // 3 Metals & Steel Products
    packingCategories: ['bulk', 'loose', 'packed'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
    ],
    unpacked: [
      stackable({}),
      maxUnitLength({
        nextLine: true,
        units: { mt: 'mm', gb: 'feet' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      maxUnitWidth({ units: { mt: 'mm', gb: 'feet' }, col: 'col-12 col-md-4', required: false }),
      maxUnitWeight({ label: "Unit Weight, {{unit}}", units: { mt: 'mt', gb: 'lb' }, col: 'col-12 col-md-4', required: false }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ required: false }),
      dunnaging(),
    ],
    bulk: [
      stowageFactor(),
      stowageFactorUnit(),
    ],
    packed: [
      stackable({}),
      bundleLength({ nextLine: true, label: 'Max Length, {{unit}} (Package)', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleWidth({ label: 'Max Width, {{unit}} (Package)', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleHeight({ label: 'Max Height, {{unit}} (Package)', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundlesQuantity({ label: 'Quantity (Packages)', required: false }),
      bundleWeight({
        label: 'Unit Weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ required: false }),
      dunnaging(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /3
  {
    // 4 Aluminium Billets
    packingCategories: ['bulk', 'packed'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
    ],
    unpacked: [
      { type: 'dummy', col: 'w-100', name: '', label: '' },
      stowageFactor(),
      stowageFactorUnit(),
    ],
    packed: [
      stackable({}),
      {
        nextLine: true,
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
      bundleWeight({
        label: 'Unit Weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ required: false }),
      dunnaging(),
    ],
    bulk: [
      { type: 'dummy', col: 'w-100', name: '', label: '' },
      stowageFactor(),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /4
  {
    // 5 Wire Rod
    packingCategories: ['bulk', 'loose'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
      unitLength({
        nextLine: true,
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitDiam({
        label: 'Max Diameter, {{unit}}',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitsQuantity({ required: false }),
      stowageFactor(),
      stowageFactorUnit(),
      stackable({ nextLine: true, col: `col-12 col-md-2 stackable ${s.small_col}` }),
      tiers({ required: false }),
      dunnaging(),
      nested({ className: 'col-12 col-md-2' }),
    ],
    unpacked: [],
    packed: [],
    bulk: [],
    clauses: [],
    drawings: false,
    packingList: true,
    flipNested: true,
  }, // /5
  {
    // 6 Forest Products
    packingCategories: ['bulk', 'loose', 'packed'],
    stowage: [
      unitsWeight({ required: false }),
      unitsVolume({ required: false }),
    ],
    unpacked: [
      { type: 'dummy', col: 'w-100', name: '', label: '' },
      maxUnitWeight({ label: "Unit Weight, {{unit}}", units: { mt: 'mt', gb: 'lb' }, col: 'col-12 col-md-4', required: false }),
      maxUnitLength({
        units: { mt: 'mm', gb: 'inch' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
    ],
    bulk: [
      stowageFactor(),
      stowageFactorUnit(),
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        nextLine: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
   /*   unitsVolume( //TODO must be bundleVolume, but there are no such field in api
        {
          label: 'Volume, {{unit}} ({{packingName}})',
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
          nextLine: false,
        },
        false,
      ),*/
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /6
  {
    // 7 Wooden Poles
    packingCategories: ['bulk', 'packed'],
    stowage: [
      unitsWeight({ required: false }),
      unitsVolume({ required: false }),
      ...temperatures(),
      ...moistures(),
      ventilation(),
    ],
    unpacked: [
      { type: 'dummy', col: 'w-100', name: '', label: '' },
      stowageFactor(),
      stowageFactorUnit(),
    ],
    bulk: [
      { type: 'dummy', col: 'w-100', name: '', label: '' },
      stowageFactor(),
      stowageFactorUnit(),
    ],
    packed: [
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        nextLine: true,
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
   /*   unitsVolume(
        {
          nextLine: false,
          label: 'Volume, {{unit}} ({{packingName}})',
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
        },
        false,
      ),*/
      stowageFactor(),
      stowageFactorUnit(),
      stackable({ col: `col-12 col-md-2` }),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    clauses: [],
    drawings: false,
    packingList: false,
  }, // /7
  {
    // 8 Food and Beverages, Perishables, Organic Material, Grains & Agricultural Products
    packingCategories: ['bulk', 'packed'],
    stowage: [
      unitsWeight({ required: false }),
      unitsVolume({ required: false }),
      ...temperatures(),
      ...moistures(),
      ventilation(),
    ],
    unpacked: [
      { type: 'dummy', col: 'col-md-12', name: '', label: '' },
      stowageFactor(),
      stowageFactorUnit(),
    ],
    bulk: [
      { type: 'dummy', col: 'col-md-12', name: '', label: '' },
      stowageFactor(),
      stowageFactorUnit(),
    ],
    packed: [
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        nextLine: true,
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
  /*    unitsVolume(
        {
          nextLine: false,
          label: 'Volume, {{unit}} ({{packingName}})',
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
        },
        false,
      ),*/
      stowageFactor(),
      stowageFactorUnit(),
      stackable({ col: `col-12 col-md-2` }),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /8
  {
    // 9 Fertilizers, Oil & Chemicals, Minerals and Rocks
    packingCategories: ['bulk', 'packed'],
    stowage: [
      unitsWeight({ required: false }),
      unitsVolume({ required: false }),
      dangerous({
        nextLine: true,
      }),
    ],
    unpacked: [
      stowageFactor({
        nextLine: true,
      }),
      stowageFactorUnit(),
    ],
    packed: [
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        required: true,
        defaultValue: '',
        nextLine: true,
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: 'Length, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleWidth({ label: 'Width, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: 'Height, {{unit}} ({{packingName}})', units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundlesQuantity({ label: 'Quantity ({{packingName}})', required: false }),
/*      unitsVolume(
        {
          label: 'Volume, {{unit}} ({{packingName}})',
          nextLine: false,
          calculated: '(state.bundleLength.value/1000)*(state.bundleBeam.value/1000)*(state.bundleHeight.value/1000)*state.bundlesQuantity',
          col: 'col-12 col-md-4',
          required: false,
        },
        false,
      ),*/
      stowageFactor(),
      stowageFactorUnit(),
      stackable({ col: `col-12 col-md-2` }),
      tiers({ required: false }),
      dunnaging(),
      nested(),
    ],
    bulk: [
      stowageFactor({
        nextLine: true,
      }),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: false,
    packingList: true,
  }, // /9
  {
    // 10 Consumer Goods, Project & General cargo
    packingCategories: ['bulk', 'unpacked', 'packed'],
    stowage: [miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' })],
    unpacked: [
      stackable({}),
      unitsQuantity({ nextLine: true, col: 'col-12 col-md-4', required: false }),
      unitLength({
        label: 'Length, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWidth({
        label: 'Width, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitHeight({
        label: 'Height, {{unit}}',
        col: 'col-12 col-md-4',
        units: { mt: 'mm', gb: 'inch' },
        required: false,
      }),
      unitWeight({
        col: 'col-12 col-md-4',
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitsWeight.value*1/state.unitsQuantity',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ nextLine: true, required: false }),
      dunnaging(),
      nested(),
    ],
    packed: [
      stackable({}),
      {
        name: '[name]',
        label: 'Packing type',
        type: 'PackingType',
        nextLine: true,
        required: true,
        defaultValue: '',
        col: 'col-12 col-md-4',
      },
      bundleLength({ label: "Length, {{unit}} ({{packingName}})", units: { mt: 'mm', gb: 'feet' }, required: false }),
      bundleBeam({ label: "Beam, {{unit}} ({{packingName}})", units: { mt: 'mm', gb: 'inch' }, required: false }),
      bundleHeight({ label: "Height, {{unit}} ({{packingName}})", units: { mt: 'mm', gb: 'inch' }, required: false }),
      unitWeight({
        label: 'Unit weight, {{unit}}',
        units: { mt: 'mt', gb: 'lb' },
        col: 'col-12 col-md-4',
        required: false,
      }),
      unitsQuantity({
        label: 'Quantity ({{cargoName}})',
        col: 'col-12 col-md-4',
        calculated: '1*state.unitsWeight.value/state.unitWeight.value',
        required: false,
      }),
      unitsPerBundleQuantity({
        label: 'Quantity ({{cargoName}} in {{packingName}})',
        required: false,
      }),
      bundlesQuantity({
        label: 'Quantity ({{packingName}})',
        calculated: 'Math.ceil(state.unitsQuantity/state.unitsPerBundleQuantity)',
        required: false,
      }),
      bundleWeight({
        units: { mt: 'mt', gb: 'lb' },
        calculated: 'state.unitWeight.value*state.unitsPerBundleQuantity',
        required: false,
      }),
      stowageFactor(),
      stowageFactorUnit(),
      tiers({ required: false }),
      dunnaging(),
    ],
    bulk: [
      stowageFactor({
        nextLine: true,
      }),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: true,
    packingList: true,
    flipNested: true,
  }, // /10
  {
    //11 Diesel engines
    packingCategories: ['bulk', 'unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitBeam({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitHeight({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitWeight({
        units: { mt: 'mt', gb: 'lb' },
        required: false,
      }),
      {
        name: '[propelled]',
        label: 'Self-propelled',
        type: 'checkbox',
        col: 'col-12 col-md-4',
      },
      {
        name: '[military]',
        label: 'Military',
        type: 'checkbox',
        fields: [dangerous({
          style: {
            maxWidth: '16.5%',
          },
        })],
        col: 'col-12 col-md-2',
      },
    ],
    packed: [],
    unpacked: [],
    bulk: [
      stowageFactor({
        nextLine: true,
      }),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: true,
    packingList: true,
  }, // /11
  {
    //12 Rotor
    packingCategories: ['bulk', 'unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitBeam({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitHeight({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitWeight({
        units: { mt: 'mt', gb: 'lb' },
        required: false,
      }),
      stackable({}),
      tiers({ required: false }),
      dangerous(),
    ],
    packed: [],
    unpacked: [],
    bulk: [
      stowageFactor({
        nextLine: true,
      }),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: true,
    packingList: true,
  }, // /12
  {
    //13 Equipment & Machinery
    packingCategories: ['bulk', 'unpacked'],
    stowage: [
      miniPackingList({}),
      unitsWeight({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+c.totalWeight,0)) || ""' }),
      unitsVolume({ required: false, calculated: '1*(state.packingList.reduce((p,c)=> p+(c.height*c.length*c.width*c.quantity),0)) || ""' }),
      unitsQuantity({ nextLine: true, required: false }),
      unitLength({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitBeam({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitHeight({
        units: { mt: 'mm', gb: 'feet' },
        required: false,
      }),
      unitWeight({
        units: { mt: 'mt', gb: 'lb' },
        required: false,
      }),
      stackable({}),
      tiers({ required: false }),
      dangerous(),
    ],
    packed: [],
    unpacked: [],
    bulk: [
      stowageFactor({
        nextLine: true,
      }),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: true,
    packingList: true,
  }, // /13
  {
    //14 Live Stock
    withoutSpread: true,
    packingCategories: ['bulk', 'unpacked'],
    stowage: [
      {
        name: '[unitsWeight][valueMin]',
        label: 'Total Weight, mt',
        type: 'text',
        hidden: true,
        calculated: '1*state.unitsQuantityMin*state.unitWeight.value',
        validationErrors: {},
        toPreview: toPreviewMinMax,
      },
      {
        name: '[unitsWeight][valueMax]',
        label: '',
        type: 'text',
        hidden: true,
        calculated: '1*state.unitsQuantityMax*state.unitWeight.value',
        validationErrors: {},
        toPreview: toPreviewDummy,
      },
      {
        name: '[unitsWeight][unit]',
        label: '',
        type: 'text',
        hidden: true,
        defaultValue: 'mt',
        validationErrors: {},
        toPreview: toPreviewDummy,
      },
      {
        name: '[stowageSpread]',
        label: '',
        type: 'text',
        hidden: true,
        defaultValue: true,
        validationErrors: {},
        toPreview: toPreviewDummy,
      },
      ...quantities(),
      unitWeight({
        label: 'Weight {{unit}} per head',
        units: { mt: 'mt', gb: 'lb' },
      }),
    ],
    packed: [],
    unpacked: [],
    bulk: [
      stowageFactor({
        nextLine: true,
      }),
      stowageFactorUnit(),
    ],
    clauses: [],
    drawings: false,
    packingList: false,
  }, // /14
];

const wetBulkConfig = {
    packingCategories: ['bulk'],
    withoutSpread: false,
    stowage: [
      unitsWeight({ required: false }),
      unitsVolume({ required: false }),
      dangerous({
        nextLine: true,
      }),
      { type: 'densities', name: '[densities]', label: 'Density', toPreview: densityToPreview, col: 'col-12' },
      ...pourPoints(),
      ...boilingPoints(),
      ...meltingPoints(),
      flammable(),
      ...heatings(),
    ],
    unpacked: [],
    packed: [],
    clauses: [],
    drawings: false,
    packingList: false,
  };


function containerType(){
  return {
    name: `[containerDetails][kind]`,
    label: 'Type of Container',
    type: 'containerTypeSelect',
    col: `col-12 col-md-6 ${s.container_type}`,
    toPreview: toPreviewDummy,
  }
}

const containerOwnersMap = {
  shipper: { label: 'Shipper\'s Owned', value: 'shipper' },
  carrier: { label: 'Carrier\'s Owned', value: 'carrier' },
  liner: { label: 'Liner Owned', value: 'liner' },
  leased: { label: 'Leased', value: 'leased' },
}

function containerOwner(){
  return {
    name: `[containerDetails][owner]`,
    label: ' ',
    type: 'select',
    defaultValue: 'carrier',
    values: [
      ...Object.values(containerOwnersMap),
    ],
    col: `col-12 col-md-4`,
    toPreview: (pack, _name, field) => ({value: containerOwnersMap[pack[_name]]?.label}),
  }
}

function containerNumber({
                    label = 'Container No',
                    required = false,
                    className = `col-12 col-md-4`,
                    ...rest
                  }) {
  return {
    name: '[containerDetails][ownNum]',
    label: label,
    type: 'text',
    required: required,
    col: className,
    validationErrors: {
      'maxLength:10000': 'max 10000 characters',
    },
    ...rest,
  };
}
function sealNumber({
                           label = 'Seal',
                           required = false,
                           className = `col-12 col-md-4`,
                           ...rest
                         }) {
  return {
    name: '[containerDetails][sealNum]',
    label: label,
    type: 'text',
    required: required,
    col: className,
    validationErrors: {
      'maxLength:10000': 'max 10000 characters',
    },
    ...rest,
  };
}
function stc({
                      label = 'STC',
                      required = false,
                      className = `col-12 col-md-6`,
                      nextLine = true,
                      ...rest
                    }) {
  return {
    name: '[containerDetails][stc]',
    label: label,
    type: 'text',
    required: required,
    col: className,
    nextLine,
    validationErrors: {
      'maxLength:10000': 'max 10000 characters',
    },
    ...rest,
  };
}

function containerTemperatures({ style = { }, nextLine = false } = {}) {
  return {
    nextLine,
    name: '[climateControlled]',
    label: '°C mode',
    type: 'checkbox',
    style,
    fields: [
      ...temperatures(),
      ...moistures(),
      ventilation(),
    ],
    col: `col-12 col-md-3`,
  };
}



const generalContainerConfig = {
  packingCategories: ['packed'],
  withoutSpread: true,
  stowage: [
    ...commonContainerFields(),
    netWeight({ }),
    containerTemperatures({}),
    dangerous({
      nextLine: false,
    }),
    miniPackingList({}),
  ],
  unpacked: [],
  packed: [],
  bulk: [],
  clauses: [],
  drawings: false,
  packingList: false,
};
const flatrackContainerConfig = {
  packingCategories: ['packed'],
  withoutSpread: true,
  stowage: [
    ...commonContainerFields(),
    netWeight({ }),
    maxUnitLength({ required: false, className: 'col-12 col-md-3', units: { mt: 'mm', gb: 'inch' } }),
    unitBeam({ required: false, units: { mt: 'mm', gb: 'inch' } }),
    unitHeight({ required: false, units: { mt: 'mm', gb: 'inch' } }),
    unitDiam({ required: false, units: { mt: 'mm', gb: 'inch' } }),
    dangerous({
      nextLine: true,
    }),
    miniPackingList({}),
  ],
  unpacked: [],
  packed: [],
  bulk: [],
  clauses: [],
  drawings: false,
  packingList: false,
};
const openTopContainerConfig = {
  packingCategories: ['packed'],
  withoutSpread: true,
  stowage: [
    ...commonContainerFields(),
    netWeight({ }),
    unitHeight({ required: false, className: 'col-12 col-md-3', units: { mt: 'mm', gb: 'inch' } }),
    dangerous({
      nextLine: true,
    }),
    miniPackingList({}),
  ],
  unpacked: [],
  packed: [],
  bulk: [],
  clauses: [],
  drawings: false,
  packingList: false,
};
const tankContainerConfig = {
  packingCategories: ['packed'],
  withoutSpread: true,
  stowage: [
    ...commonContainerFields(),
    netWeight({ }),
    containerTemperatures({}),
    dangerous({
      nextLine: false,
    }),
    { type: 'densities', name: '[densities]', label: 'Density', toPreview: densityToPreview, col: 'col-12' },
    ...pourPoints(),
    ...boilingPoints(),
    ...meltingPoints(),
    flammable(),
    ...heatings(),
  ],
  unpacked: [],
  packed: [],
  bulk: [],
  clauses: [],
  drawings: false,
  packingList: false,
};
const livestockContainerConfig = {
  packingCategories: ['packed'],
  withoutSpread: true,
  stowage: [
    ...commonContainerFields(),
    ...quantities({ nextLine: true }),
    unitWeight({
      label: 'Weight {{unit}} per head',
      units: { mt: 'mt', gb: 'lb' },
      className: 'col-12 col-md-3',
    }),
  ],
  unpacked: [],
  packed: [],
  bulk: [],
  clauses: [],
  drawings: false,
  packingList: false,
};

const containerTypeMap = {
  "standard": { config: generalContainerConfig },
  "flatrack": { config: flatrackContainerConfig },
  "open_top": { config: openTopContainerConfig },
  "tank": { config: tankContainerConfig },
  "livestock": { config: livestockContainerConfig },
}

fields[99] = wetBulkConfig;


function commonContainerFields(){
  return [
    containerType(),
    bundlesQuantity({ label: "Quantity", className: 'col-12 col-md-3' }),
    bundleWeight({ className: 'col-12 col-md-3', label: "Unit Weight, {{unit}}", units: { mt: 'mt', gb: 'lb' }, required: false }),
    containerNumber({ nextLine: true }),
    sealNumber({}),
    containerOwner({}),
    { type: 'h5', name: '', value: 'CARGO' },
    stc({}),
  ];
}
function unNumber({
  label = 'UN Number',
  required = false,
  className = `col-12 col-md-3`,
  calculated,
  ...rest
}) {
  return {
    name: '[dangerous][unNumber]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      unNumber: 'must be 4 digits',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function unitsQuantity({
  label = 'Number of units',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitsQuantity]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function unitWeight({
  label = 'Unit Weight, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitWeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'maxWeight:10000': 'max 10000mt',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function netWeight({
                      label = 'Total net weight, {{unit}}',
                      required = false,
                      className = 'col-12 col-md-3',
                      calculated,
                      ...rest
                    }) {
  return {
    name: '[netWeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationDepends: '[bundlesQuantity]',
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      forceInvalid: (qty, data) => {
        if (!data.netWeight?.value || !data.bundlesQuantity || !data.bundleWeight?.value) {
          return;
        }
        if (data.netWeight.value > data.bundlesQuantity * data.bundleWeight.value) {
          return 'Total net weight’ ≤ “quantity” x “unit weight”';
        }
      },
    },
    col: className,
    calculated: calculated,
    units: { mt: 'mt', gb: 'mt' },
    ...rest,
  };
}

function unitsWeight(
  {
    label = 'Total Weight, {{unit}}',
    required = true,
    className = `col-12 col-md-3 ${s.error_inline} ${s.medium_col}`,
    calculated,
    ...rest
  },
  deviation = true,
  hidden = false,
) {
  return {
    name: '[unitsWeight][value]',
    label: label,
    type: 'text',
    units: { mt: 'mt', gb: 'mt' },
    required: required,
    defaultValue: '',
    validationErrors: {
      totalWeight: 'Total Weight or Total Volume required',
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
    },
    changeWhenStowageIsSpread: deviation,
    col: className,
    hidden: hidden,
    calculated: calculated,
    toPreview: withDeviationToPreview('value'),
    fields: deviation
      ? [
          ...deviationOptions({ name: 'unitsWeight' }),
        ]
      : null,
    ...rest,
  };
}
function unitsVolume(
  {
    label = 'Total Volume, {{unit}}',
    required = true,
    className = `col-12 col-md-3 ${s.error_inline} ${s.medium_col}`,
    calculated,
    nextLine = true,
    ...rest
  },
  deviation = true,
  hidden,
) {
  return {
    name: '[unitsVolume][value]',
    label: label,
    nextLine,
    type: 'text',
    units: { mt: 'm3', gb: 'm3' },
    required: required,
    defaultValue: '',
    validationErrors: {
      totalVolume: 'Total Volume or Total Weight required',
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
    },
    changeWhenStowageIsSpread: deviation,
    col: className,
    calculated: calculated,
    hidden: hidden,
    toPreview: withDeviationToPreview('value'),
    fields: deviation
      ? [
          ...deviationOptions({ name: 'unitsVolume', deviationUnits: [
            { label: '%', value: '%' },
            { label: 'm3', value: 'm3' },
            { label: '', value: '' },
          ] }),
        ]
      : null,
    ...rest,
  };
}

function unitLength({
  label = 'Max Length, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitLength][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:300000': 'max 300000mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitWidth({
  label = 'Width, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitWidth][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'max:100000': 'max 100000mm',

    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function maxUnitWidth({
  label = 'Max Width, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitWidth][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function maxUnitWeight({
  label = 'Max Unit Weight, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitWeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'maxWeight:10000': 'max 10000mt',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function maxUnitLength({
  label = 'Max Length, {{unit}}',
  required = true,
  className = 'col-12 col-md-6',
  calculated,
  ...rest
}) {
  return {
    name: '[maxUnitLength][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:300000': 'max 300000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitBeam({
  label = 'Max Beam, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitBeam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitHeight({
  label = 'Max Height, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitHeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:200000': 'max 200000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function unitDiam({
  label = 'Max Diameter, {{unit}}',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitDiam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

//---bundles

function bundlesQuantity({
  label = 'Quantity',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundlesQuantity]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:500000': 'max 500 000',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function unitsPerBundleQuantity({
  label = 'Quantity',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[unitsPerBundleQuantity]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleWeight({
  label = 'Weight, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleWeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'maxWeight:10000': 'max 10000mt',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

function bundleLength({
  label = 'Max Length, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleLength][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'max:300000': 'max 300000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleWidth({
  label = 'Max Width, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleBeam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleBeam({
  label = 'Max Beam, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleBeam][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:100000': 'max 100000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}
function bundleHeight({
  label = 'Max Height, {{unit}} ({{packingName}})',
  required = true,
  className = 'col-12 col-md-4',
  calculated,
  ...rest
}) {
  return {
    name: '[bundleHeight][value]',
    label: label,
    type: 'text',
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
      'max:200000': 'max 200000 mm',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

///--bundles
///--webpack?

function imdgClass({
  label = 'Class',
  required = false,
  className = `col-12 col-md-3`,
  calculated,
  ...rest
}) {
  return {
    name: '[dangerous][class]',
    label: label,
    type: 'multiselect',
    defaultValue: [],
    values: imoClasses.map(option => ({ name: option, id: option, value: +option })),
    required: required,
    validationErrors: {
      isNumeric: 'only numbers',
      imdgClass: 'invalid imdg class',
    },
    col: className,
    calculated: calculated,
    ...rest,
    toPreview: (pack, _name, field) => ({ label: 'Class', value: pack[_name]?.join(', ') }),
  };
}

function dangerous({ style = { }, nextLine = false } = {}) {
  return {
    nextLine,
    name: '[dangerous][value]',
    label: 'IMDG',
    type: 'checkbox',
    style,
    fields: [imdgClass({}), unNumber({})],
    col: `col-12 col-md-2`,
  };
}

function stackable({ col = 'col-12 col-md-6 stackable', ...rest }) {
  return {
    name: '[stackable][value]',
    label: 'Stackable',
    type: 'checkbox',
    col: col,
    ...rest,
  };
}

function nested({ className = `col-12 col-md-4` } = {}) {
  return {
    name: '[stackable][nested]',
    label: 'Nested',
    type: 'checkbox',
    col: className,
    if: '[stackable][value]',
  };
}
function dunnaging({ className = `col-12 col-md-auto` } = {}) {
  return {
    name: '[stackable][dunnaging]',
    label: 'Inter-tier dunnaging',
    type: 'checkbox',
    col: className,
    if: '[stackable][value]',
  };
}
function tiers({ required = true, className = `col-12 col-md-2`, ...rest } = {}) {
  return {
    name: '[stackable][tiers]',
    label: 'Tiers',
    type: 'text',
    required: required,
    validations: 'isNumeric',
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
      isInt: 'Integers only',
    },
    col: className,
    if: '[stackable][value]',
    ...rest,
  };
}

function miniPackingList({
                      label = '',
                      required = false,
                      className = 'col-12',
                      calculated,
                      ...rest
                    }) {
  return {
    name: '[packingList]',
    label: label,
    type: 'miniPackingList',
    required: required,
    validationErrors: {
      isExisty: 'invalid',
    },
    col: className,
    calculated: calculated,
    ...rest,
  };
}

const spreadMinMax = type => name => getNamesInBrackets(name)?.map?.((n, i, arr) => i === arr.length - 1 ? `[${stripBrackets(n)}${type}]` : `${n}`).join('');
const termMapper = {
  chartererOption: { label: 'Charterer option', value: 'chartererOption' },
  ownerOption: { label: 'Owner option', value: 'ownerOption' },
  molchopt: { label: 'Charterer option', value: 'molchopt' },
  moloo: { label: 'Owner option', value: 'moloo' },
  carrierOption: { label: 'Carrier option', value: 'carrierOption' },
  shipperOption: { label: 'Shipper option', value: 'shipperOption' },
};
function stowageSpreadFields({
  name = '',
  label = '',
  ...rest
} = {}, { validationDependsMax, validationDependsMin }) {
  const minMaxLabel = type => label => label.split(',').map((l, i) => i === 0 ? `${l} ${type}` : l).join(',');

  return ([
    {
      ...rest,
      name: spreadMinMax('Min')(name),
      label: minMaxLabel('min')(label),
      nextLine: true,
      minMaxType: 'min',
      validationDepends: validationDependsMin,
      validationErrors: {
        ...rest.validationErrors,
        required: (val,data) => {
          if (name === '[unitsWeight][value]') {
            return !data.unitsVolume?.valueMin && !data.unitsVolume?.valueMax && 'required';
          } else if (name === '[unitsVolume][value]') {
            return !data.unitsWeight?.valueMin && !data.unitsWeight?.valueMax && 'required';
          }
          return !val && 'required';
        },
      },
      fields: [],
      calculated: `1*(state.${getNamesInBrackets(name).map(stripBrackets).join('.')}) || ${rest.calculated}`,
      if: '[stowageSpread]',
      toPreview: toPreviewDummy,
    },
    {
      ...rest,
      name: spreadMinMax('Max')(name),
      label: minMaxLabel('max')(label),
      nextLine: false,
      minMaxType: 'max',
      validationDepends: validationDependsMax,
      validationErrors: {
        ...rest.validationErrors,
        required: (val,data) => {
          if (name === '[unitsWeight][value]') {
            return !data.unitsVolume?.valueMin && !data.unitsVolume?.valueMax && 'required';
          } else if (name === '[unitsVolume][value]') {
            return !data.unitsWeight?.valueMin && !data.unitsWeight?.valueMax && 'required';
          }
          return !val && 'required';
        },
      },
      fields: [],
      calculated: `1*(state.${getNamesInBrackets(name).map(stripBrackets).join('.')}) || ${rest.calculated}`,
      if: '[stowageSpread]',
      toPreview: (pack, _name) => {
        return {};
      }
    }
  ])
};

function deviationOptions({ name, deviationUnits = [
  { label: '%', value: '%' },
  { label: 'mt', value: 'mt' },
  { label: '', value: '' }
] }) {
  return [
    {
      name: `[${name}][deviationPlus]`,
      label: '+',
      type: 'text',
      required: false,
      validationDepends: `[${name}][deviationUnit]`,
      validationErrors: {
        isNumeric: 'only numbers',
        'gte:0': '>= 0',
        isPercent: deviationUnit => deviationUnit === '%' && 'invalid percent',
      },
      col: `col-12 col-md-1 ${s.xs_col}`,
    },
    {
      name: `[${name}][deviationMinus]`,
      label: '-',
      type: 'text',
      required: false,
      validationDepends: `[${name}][deviationUnit]`,
      validationErrors: {
        isNumeric: 'only numbers',
        'gte:0': '>= 0',
        isPercent: deviationUnit => deviationUnit === '%' && 'invalid percent',
      },
      col: `col-12 col-md-1 ${s.xs_col}`,
    },
    {
      name: `[${name}][deviationUnit]`,
      label: deviationUnits.map(u => u.label).slice(0, 2).join('/'),
      type: 'select',
      required: false,
      validationErrors: {
        isPercent: 'invalid percent',
      },
      defaultValue: '',
      values: deviationUnits,
      col: `col-12 col-md-1 ${s.xs_col} ${s.deviation_unit}`,
    },
    {
      name: `[${name}]][term]`,
      label: ' ',
      type: 'select',
      defaultValue: 'molchopt',
      values: [
        { label: 'Charterer option', value: 'molchopt' },
        { label: 'Owner option', value: 'moloo' },
        { label: 'Carrier option', value: 'carrierOption' },
        { label: 'Shipper option', value: 'shipperOption' },
        { label: "", value: "" }
      ],
      col: `col-12 col-md-3 ${s.medium_col}`,
    },
  ]
}

function stowageFactor({
  name = '[sf]',
  label = 'SF',
  col = `col-6 col-md-2`,
  ...rest
} = {}) {
  return {
    name,
    label,
    type: 'text',
    required: false,
    defaultValue: '',
    validations: 'isNumeric',
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
    },
    col,
    toPreview: (pack) =>{
      if(!pack['[sf]']) {
        return { };
      }
      return { label ,value: number(pack['[sf]'], '', pack['[sfUnit]']) };
    },
    ...rest,
  }
}

function stowageFactorUnit({
  name = '[sfUnit]',
  label = 'SF unit',
  col = `col-6 col-md-2`,
  ...rest
} = {}) {
  return {
    name,
    label,
    type: 'select',
    required: false,
    validations: 'isNumeric',
    defaultValue: 'cbm/mt',
    values: [
      { label: 'cbm/mt', value: 'cbm/mt' },
      { label: 'cbf/mt', value: 'cbf/mt' },
    ],
    validationErrors: {
      isNumeric: 'only numbers',
      gt0: 'must be greater than 0',
    },
    col,
    toPreview: toPreviewDummy,
    ...rest,
  }
}



function minInput({
  name,
  label,
  validations = 'isNumeric',
  validationErrors = {
    'isNumeric': 'must be a number',
  },
  col = `col-12 col-md-3`,
  ...rest
} = {}) {
  return {
    type: "text",
    name,
    label,
    minMaxType: 'min',
    validations,
    validationErrors,
    col,
    ...rest,
  }
}

function maxInput({
  name,
  label,
  validations = 'isNumeric',
  validationErrors = {
    'isNumeric': 'must be a number',
  },
  col = `col-12 col-md-3`,
  ...rest
} = {}) {
  return {
    type: "text",
    name,
    label,
    minMaxType: 'max',
    validations,
    validationErrors,
    col,
    ...rest,
  }
}

function temperatures({
  units = { mt: 'C' },
  name = 'temperature',
  minLabel = 'Temperature min, °C',
  maxLabel = 'Temperature max, °C',
  nextLine = true,
  ...rest
} = {}) {
  return [
    minInput({
      name: `[${name}Min]`,
      label: minLabel,
      nextLine,
      minVal: -273,
      maxVal: 100,
      toPreview: toPreviewMinMax,
      ...rest,
    }),
    maxInput({
      name: `[${name}Max]`,
      maxVal: 100,
      minVal: -273,
      label: maxLabel,
      toPreview: toPreviewDummy,
      ...rest,
    }),
  ];
}

function moistures({
  units = { mt: '%' },
  name = 'moisture',
  minLabel = 'Moisture min, %',
  maxLabel = 'Moisture max, %',
  nextLine = false,
  ...rest
} = {}) {
  return [
    minInput({
      name: `[${name}Min]`,
      label: minLabel,
      nextLine,
      maxVal: 100,
      toPreview: toPreviewMinMax,
      ...rest,
    }),
    maxInput({
      name: `[${name}Max]`,
      maxVal: 100,
      label: maxLabel,
      toPreview: toPreviewDummy,
      ...rest,
    }),
  ];
}

function pourPoints({
  units = { mt: 'C' },
  name = 'pourPoint',
  minLabel = 'Pour Point min, °C',
  maxLabel = 'Pour Point max, °C',
  nextLine = true,
  ...rest
} = {}) {
  return [
    minInput({
      name: `[${name}Min]`,
      label: minLabel,
      nextLine,
      toPreview: toPreviewMinMax,
      minVal: -273,
      maxVal: 1000,
      ...rest,
    }),
    maxInput({
      name: `[${name}Max]`,
      label: maxLabel,
      toPreview: toPreviewDummy,
      minVal: -273,
      maxVal: 1000,
      ...rest,
    }),
  ];
}

function boilingPoints({
  units = { mt: 'C' },
  name = 'boilingPoint',
  minLabel = 'Boiling Point min, °C',
  maxLabel = 'Boiling Point max, °C',
  nextLine = false,
  ...rest
} = {}) {
  return [
    minInput({
      name: `[${name}Min]`,
      label: minLabel,
      nextLine,
      toPreview: toPreviewMinMax,
      minVal: -273,
      maxVal: 1000,
      ...rest,
    }),
    maxInput({
      name: `[${name}Max]`,
      label: maxLabel,
      toPreview: toPreviewDummy,
      minVal: -273,
      maxVal: 1000,
      ...rest,
    }),
  ];
}

function flashPoints({
  units = { mt: 'C' },
  name = 'flashPoint',
  minLabel = 'Flash Point min, °C',
  maxLabel = 'Flash Point max, °C',
  nextLine = false,
  ...rest
} = {}) {
  return [
    minInput({
      name: `[${name}Min]`,
      label: minLabel,
      nextLine,
      toPreview: toPreviewMinMax,
      ...rest,
    }),
    maxInput({
      name: `[${name}Max]`,
      label: maxLabel,
      toPreview: toPreviewDummy,
      ...rest,
    }),
  ];
}

function meltingPoints({
                       units = { mt: 'C' },
                       name = 'meltingPoint',
                       minLabel = 'Melting Point min, °C',
                       maxLabel = 'Melting Point max, °C',
                       nextLine = false,
                       ...rest
                     } = {}) {
  return [
    minInput({
      name: `[${name}Min]`,
      label: minLabel,
      minVal: -273,
      maxVal: 1000,
      nextLine,
      toPreview: toPreviewMinMax,
      ...rest,
    }),
    maxInput({
      name: `[${name}Max]`,
      label: maxLabel,
      minVal: -273,
      maxVal: 1000,
      toPreview: toPreviewDummy,
      ...rest,
    }),
  ];
}

function quantities({
  name = 'unitsQuantity',
  minLabel = 'Number of heads min',
  maxLabel = 'Number of heads max',
  nextLine = false,
  ...rest
} = {}) {
  return [
    minInput({
      name: `[${name}Min]`,
      label: minLabel,
      nextLine,
      col: 'col-12 col-md-4',
      toPreview: toPreviewMinMax,
      ...rest,
    }),
    maxInput({
      name: `[${name}Max]`,
      label: maxLabel,
      col: 'col-12 col-md-4',
      toPreview: toPreviewDummy,
      ...rest,
    }),
  ];
}

function ventilation({
  name = '[ventilation]',
  label = 'Ventilation',
  col = `col-12 col-md-3`,
  ...rest
} = {}) {
  return {
    name,
    label,
    type: 'checkbox',
    col,
    preserveColumn: false,
  };
}

function heatings(){
  return [
    { type: 'h5', name: '', value: 'HEATING' },
    minInput({
      name: `[voyageHeatMin]`,
      label: 'Voyage Heat min, °C',
      col: 'col-12 col-md-3',
      nextLine: true,
      toPreview: toPreviewMinMax,
    }),
    maxInput({
      name: `[voyageHeatMax]`,
      label: 'Voyage Heat max, °C',
      col: 'col-12 col-md-3',
      toPreview: toPreviewDummy,
    }),
    minInput({
      name: `[loadingHeatMin]`,
      label: 'Loading Heat min, °C',
      col: 'col-12 col-md-3',
      toPreview: toPreviewMinMax,
    }),
    maxInput({
      name: `[loadingHeatMax]`,
      label: 'Loading Heat max, °C',
      col: 'col-12 col-md-3',
      toPreview: toPreviewDummy,
    }),
    minInput({
      name: `[dischargeHeatMin]`,
      label: 'Discharge Heat min, °C',
      col: 'col-12 col-md-3',
      toPreview: toPreviewMinMax,
    }),
    maxInput({
      name: `[dischargeHeatMax]`,
      label: 'Discharge Heat max, °C',
      col: 'col-12 col-md-3',
      toPreview: toPreviewDummy,
    }),
    {
      name: '[maxAdjacentHeat]',
      label: 'Max Adjacent Heat, °C',
      type: 'text',
      validationErrors: {
        isNumeric: 'only numbers',
      },
      col: 'col-12 col-md-3',
      toPreview: toPreviewMinMax,
    },
  ]
}

function flammable({ style = { }, nextLine = false } = {}) {
  return {
    nextLine,
    name: '[flammable]',
    label: 'Flammable',
    type: 'checkbox',
    style,
    fields: [{
      name: '[flashPointMin]',
      label: 'Flash Point, °C',
      type: 'text',
      validationErrors: {
        isNumeric: 'only numbers',
      },
      col: `col-12 col-md-3`,
      toPreview: toPreviewMinMax,
    }],
    col: `col-12 col-md-3`,
  };
}

const finalFields = fields.map(field => {
  let { stowage } = field;

  stowage = [...(stowage || [])];


  for (const stowageField of stowage) {
    if (stowageField.changeWhenStowageIsSpread) {
      let dependableField = '';

      if (stowageField.name.indexOf('unitsWeight') >= 0) {
        dependableField = stowageField.name.replace('unitsWeight', 'unitsVolume');
      }

      else if (stowageField.name.indexOf('unitsVolume') >= 0) {
        dependableField = stowageField.name.replace('unitsVolume', 'unitsWeight');
      }

      const validationDependsMin = dependableField ? spreadMinMax('Min')(dependableField) : '';
      const validationDependsMax = dependableField ? spreadMinMax('Max')(dependableField) : '';
      stowageField.spread = [
        ... stowageSpreadFields(stowageField, { validationDependsMin, validationDependsMax }),
      ];
      const minName = stowageField.spread.find(field => field.minMaxType === 'min').name;
      const stateNesting = getNamesInBrackets(minName).map(stripBrackets).join('.');
      stowageField.calculated = `1*(state.${stateNesting}) || ${stowageField.calculated}`;
    }
  }

  return {
    ...field,
    stowage,
  }
});

export default finalFields;

export function getConfigFields(cargo, cargoType, typeOfTransportation, stowage) {
  const configIndex =
    (cargo && cargo.details) ||
    (cargoType && cargoType.details) || 0;
  let config = finalFields[configIndex];
  const typesOfTransportation = cargoType?.typesOfTransportation || ['breakbulk', 'bulk', 'container', 'wetbulk'];
  if (typeOfTransportation === 'wetbulk') {
    config = finalFields[99];
  }
  if (configIndex === 15) {
    typeOfTransportation = 'container';
  }
  switch (typeOfTransportation) {
    case 'wetbulk':
      config = finalFields[99];
      break;
    case 'container':
      config = generalContainerConfig;
      if (stowage?.containerDetails?.kind) {
        const containerCargoType = cargo?.containerForm ? cargo : findContainerTypeById(stowage.containerDetails.kind);
        config = containerTypeMap[containerCargoType?.containerForm || 'standard'].config;
      }
      break;
  };
  return {
    config,
    configIndex,
    typesOfTransportation: typesOfTransportation,
  };
}

function toPreviewMinMax(pack, _name, field){
  let minVal = pack[_name];
  let maxVal = pack[_name.replace('Min', 'Max')];
  const labelParts = field.label.split(',');
  let label = labelParts[0].replace(' min', '');
  const unit = labelParts[1];

  if (minVal !== undefined && maxVal !== undefined && minVal !== maxVal) {
    label += ' (min/max)';
  }
  if (unit === ' °C') {
    if (minVal > 0) {
      minVal = '+' + minVal;
    }
    if (maxVal > 0) {
      maxVal = '+' + maxVal;
    }
  }
  let value = formatRange(minVal, maxVal, '/', number);
  if (!value) {
    return {};
  }
  if (unit) {
    value += unit;
  }

  return { label, value };


}

function toPreviewDummy(){
  return {};
}

function withDeviationToPreview(nameToReplace = 'value') {
  return (pack, _name, field) => {
    let deviationMinus = pack[_name.replace(nameToReplace, 'deviationMinus')];
    let deviationPlus = pack[_name.replace(nameToReplace, 'deviationPlus')];
    const deviationPlusMinus = pack[_name.replace(nameToReplace, 'deviation')];
    const deviationUnit = pack[_name.replace(nameToReplace, 'deviationUnit')];
    const deviationTerm = pack[_name.replace(nameToReplace, 'term')];
    const unit = pack[_name.replace(nameToReplace, 'unit')];
    const currentVal = pack[_name];
    let minVal = pack[_name.replace(nameToReplace, 'valueMin')];
    let maxVal = pack[_name.replace(nameToReplace, 'valueMax')];
    if(!minVal && currentVal) {
      minVal = currentVal;
    }
    let value = formatRange(minVal, maxVal, ' - ', number);
    if (!value) {
      return {};
    }
    if (unit) {
      value += (' ' + unit);
    }
    let _label = field.label.replace(', {{unit}}', '').replace(' min', '');
    if (minVal && maxVal && minVal !== maxVal) {
      _label += ' (min/max)';
    }
    if (deviationPlusMinus && !deviationPlus && !deviationMinus) {
      deviationPlus = deviationPlusMinus;
      deviationMinus = deviationPlusMinus;
    }

    if (deviationMinus || deviationPlus || deviationUnit) {
      value += `, ${deviationPlus ? `+${number(deviationPlus)}` : '---'}/${deviationMinus ? `-${number(deviationMinus)}` : '---'} ${deviationUnit || ''}, ${deviationTerm ? termMapper[deviationTerm]?.label || '' : ''}`;
    }
    return { label: _label, value };
  }
}
