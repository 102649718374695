import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getTcIndex } from '../../../actions/vessel';
import { number } from '../../../core/format';
import Loader from '../../Common/Loader';
import BlurIfNoSubscription from '../../Signup/BlurIfNoSubscription';
import { toUTC } from '../../../core/utils';


const LABELS_BY_TYPE = {
  "hireIdeas": {
    title: "HIRE IDEA",
    index: "Hire idea ($/Day)"
  },
  "tcIndexes": {
    title: "T/C index",
    index: "T/C index ($/Day)"
  },
  "fixedRates": {
    title: "FIXED RATES",
    index: "Fixed rate ($)"
  },
}

class HireIdeaTooltip extends Component {
  state = { loading: true };
  async componentDidMount() {
      await this.loadData(this.props.vessel);
  }
  async loadData(vessel) {
    this.setState({ loading: true });
    const filters = {};
    if (this.props.channels) {
      const regionFrom = this.props.channels.map(channel => ({
        _id: channel.open._id,
        fromDate: toUTC(channel.open.from),
        untilDate: toUTC(channel.open.until),
      }));
      if (regionFrom.length) {
        filters.regionFrom = regionFrom;
      }
    }
    if (!filters.regionFrom && this.props.type === 'tcIndexes' ){
     return this.setState({ indices: [], loading: false }, () => {
        setTimeout(this.props.reposition);
      });
    }
    const res = await this.props.getTcIndex(vessel._id, filters);
    const indices = res.data[this.props.type].alive.slice(0, 5);
    this.setState({ indices, loading: false }, () => {
      setTimeout(this.props.reposition);
    });
  }
  render() {
    let tableBody;
    const labels = LABELS_BY_TYPE[this.props.type];
    if(this.state.indices?.length) {
      tableBody = this.state.indices.map(hi => (<div>
        <div>{hi.regionFrom?.name || '---'}</div>
        <div>{hi.regionTo?.name || '---' }</div>
        <div>{hi.via?.name || '---'}</div>
        <div>{this.props.type === 'tcIndexes' ?
          <BlurIfNoSubscription>{number(hi.tcIndex)}</BlurIfNoSubscription>
          :
          number(hi.tcIndex)
        }
        </div>
      </div>));
    } else {
      tableBody = (<div>
        <div>---</div>
        <div>---</div>
        <div>---</div>
        <div>---</div>
      </div>);
    }
    return (
      <Root>
        <h4>{labels.title}
          <div className={"explore_button"} onClick={this.props.openHireIndices}>
           OTHER ROUTES
          </div>
        </h4>
        <div className={"hire_idea_table"}>
          <div>
            <div>Place from</div>
            <div>Place to</div>
            <div>VIA</div>
            <div>{labels.index}</div>
          </div>
          {tableBody}
        </div>
        {this.state.loading ? <Loader /> : null}
      </Root>
    );
  }
}

const Root = styled.div`
  width: 611px;
  padding: 16px;
  background: white;
  box-shadow: 1px 1px 15px 0px #0000004D;
  @media (max-width: 1240px) {
    width: 520px;
  }
  h4 {
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hire_idea_table {
    margin-top: 16px;
    font-size: 12px;
    line-height: 140%;
    color: var(--text-dark333);
    position: relative;
    > div {
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--stroke-light-gray2);
      &:first-child {
        font-weight: 500;
        border-top: 1px solid var(--stroke-light-gray2);
      }
      >div:nth-child(1) {
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height:32px;
        line-height:32px;
      }
      >div:nth-child(2) {
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height:32px;
        line-height:32px;
      }
      >div:nth-child(3) {
        width: 140px;
      }
      >div:nth-child(4) {
        text-align: right;
        width: 100px;
      }
    }

  }

  .explore_button {
    font-family: "Roboto Condensed", sans-serif;
    display: flex;
    align-items: center;
    color: var(--text-links);
    height: 28px;
    border: 1px solid var(--stroke-light-gray1);
    border-radius: 15px;
    font-size: 11px;
    line-height: 13px;
    padding: 0 12px;
    cursor: pointer;

  }

`;


const actions = { getTcIndex };
export default connect(state => state.login, actions)(HireIdeaTooltip);
