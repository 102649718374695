import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from '../Common/ConfirmDialog';
import type { BrowserLocation } from 'history/createBrowserHistory';
import history from '../../core/history';

class TransitionLocker extends Component {
  static childContextTypes = {
    blockTransition: PropTypes.func,
    unblockTransition: PropTypes.func,
  };
  stack=[];
  state = {};
  getChildContext() {
    return {
      blockTransition: this.blockTransition,
      unblockTransition: this.unblockTransition,
    };
  }

  componentDidMount() {
      history.block((location) => {
        const candidates = this.stack.filter(el => el.predicate(location));
        if (candidates.length) {
          const message = candidates.map(el => el.message).join('\n');
          this.registerConfirmDialog(message, candidates);
          return message;
        }
        return;
      })
  }

  blockTransition = (message: string, predicate: (location: BrowserLocation) => boolean = () => true) => {
    const el = { message, predicate };
    this.stack.unshift(el);
    return this.unblockTransition.bind(this, el);
  }

  unblockTransition = (el) => {
    const index = this.stack.indexOf(el);
    if (index === -1) {
      return;
    }
    this.stack.splice(index,1);
  }

  registerConfirmDialog(message = 'Are you sure you want to leave this page?', candidates = []) {
    window.confirmTransition = (cb) => {
      return new Promise((res, rej) => {
        this.setState({
          confirmTransitionDialog: (
            <ConfirmDialog
              title="DATA IS NOT SAVED"
              open
              handleClose={(confirm) => {
                this.setState({ confirmTransitionDialog: undefined });
                cb(confirm);
                if (confirm) {
                  candidates.forEach(this.unblockTransition);
                  res(confirm);
                } else {
                  rej(confirm);
                }
              }}
            >
              <span>Are you sure you want to leave this page?</span>
            </ConfirmDialog>
          ),
        });
      })
    };
}

  render() {
    return (
      <div>
        {this.props.children}
        {this.state.confirmTransitionDialog}
      </div>
    );
  }
}

export default TransitionLocker;
