exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3wZWx {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: row;\n      flex-direction: row;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  font-size: 13px;\n  width: 100%;\n  color: #333333;\n  font-weight: normal;\n  margin-bottom: 40px; }\n\n.cT3tN {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center; }\n\n.mAnN8 {\n  margin: 0 1.25em; }\n\n.khsgd {\n  width: 75px !important;\n  font-family: 'Roboto';\n  font-weight: 500;\n  font-size: 13px !important;\n  line-height: 24px; }\n\n.khsgd button {\n    padding-right: 0px !important; }\n\n.khsgd > div:nth-child(2) > div > div:nth-child(2) {\n    padding-right: 12px !important;\n    padding-left: 6px !important; }\n\n.euaXK {\n  margin-right: .5em;\n  cursor: pointer; }\n\n._3_Gf4 {\n  margin-right: .5em;\n  color: rgba(0, 0, 0, 0.26); }\n\n._16Bnw {\n  margin: 0 .5em;\n  cursor: pointer; }\n\n._2rpgp {\n  margin: 0 .5em;\n  color: rgba(0, 0, 0, 0.26); }\n", ""]);

// Exports
exports.locals = {
	"paginationContainer": "_3wZWx",
	"paginationSection": "cT3tN",
	"paginationText": "mAnN8",
	"paginationSelect": "khsgd",
	"navigationLeft": "euaXK",
	"navigationLeftFirstPage": "_3_Gf4",
	"navigationRight": "_16Bnw",
	"navigationRightLastPage": "_2rpgp"
};