import React from 'react';
import NotificationSystem from 'react-notification-system';

export default class ShipnextNotificationSystem extends NotificationSystem {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener('storage', this.storageListener);
  }

  storageListener =(e) => {
    if(e.key === '__lastRemovedNotificationUid') {
      this.removeNotification({ uid: e.newValue});
    }
  };

  onNotificationRemoved(notification){
    window.localStorage.setItem('__lastRemovedNotificationUid', notification.uid);
  }

  addNotification = (notification) =>  {
    if(notification.uid) {
      notification.onRemove = this.onNotificationRemoved;
    }
    super.addNotification(notification);
  }

}
