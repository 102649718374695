import React from 'react';
import pure from 'recompose/pure';

const _ContactsIce = props => (
  <span {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37259 18.6274 0 12 0C5.37258 0 0 5.37259 0 12C0 18.6274 5.37258 24 12 24Z" fill="#285596"/>
      <g clipPath="url(#clip0_35788_27002)">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 5V18.825H7.72693V17.5618H6.27894V6.26295H17.7208V13.2381H19V5H5Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.07595 18.825H9.96203V14.625H9.07595V18.825Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.8795 18.2224C12.1173 18.2224 11.5717 17.6413 11.5717 16.8125C11.5717 15.9836 12.0654 15.3941 12.8622 15.3941C13.3299 15.3941 13.7197 15.6162 13.9796 15.9751L14.5686 15.4026C14.1095 14.8728 13.5291 14.625 12.8449 14.625C11.615 14.625 10.6709 15.5735 10.6709 16.8125C10.6709 18.0515 11.537 19 12.8795 19C13.6071 19 14.1874 18.6924 14.6032 18.154L13.9882 17.6413C13.7197 18.0088 13.3472 18.2224 12.8795 18.2224Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8542 17.1799C15.9907 17.808 16.48 18.2224 17.1236 18.2224C17.5913 18.2224 17.9638 18.0088 18.2323 17.6414L18.8473 18.154C18.4315 18.6923 17.8513 18.9999 17.1236 18.9999C15.7812 18.9999 14.915 18.0514 14.915 16.8125C14.915 15.5735 15.7743 14.625 17.0042 14.625C17.9257 14.625 19.1858 15.2582 18.9771 17.1799H15.8542ZM18.1418 16.5049C18.0476 15.6513 17.4878 15.394 17.1064 15.394C16.459 15.394 15.9903 15.795 15.84 16.5049H18.1418Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_35788_27002">
          <rect width="14" height="14" fill="white" transform="translate(5 5)"/>
        </clipPath>
      </defs>
    </svg>
  </span>
);

const ContactsIce = pure(_ContactsIce);
ContactsIce.displayName = 'ContactsIce';
ContactsIce.muiName = 'SvgIcon';

export default ContactsIce;
