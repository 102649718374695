import React, {Component} from 'react';
import styled from "styled-components";
import ContactBookApi from "../../core/api/ContactBookApi";
import s from "../Vessel/VesselFooter/styles.scss";
import HireIdeaTooltip from "../Vessel/VesselFooter/HireIdeaTooltip";
import WithTooltip from "../Common/WithTooltip";
import Loader from "../Common/Loader";
import {Avatar} from "./Avatar";
import Popover from "material-ui/Popover";
import RoundBorderedBtn from "../Common/RoundBorderedBtn";
import {extractEmail, extractNameFromEmail, muteEvent} from "../../core/utils";
import {CreateChatWrapper} from "../Chat";
import PubSub from "pubsub-js";


type State = {};
type Props = {
  email: string,
  userId: string
};

const ContactPopoverBody = styled(({ className, contact, createChatProps, onOpenDetailsClick, onMouseOver, onMouseOut, ...props }) => {
return (<div className={className} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
  <div>
    <Avatar contact={contact} size="mid" />
    <div>
      <div className={'name'}>{contact.name}</div>
      <div className={'email'}>{contact.emails[0]}</div>
      <div className={'company'}>{contact.companies?.[0]?.name || ''}</div>
    </div>
  </div>
  <div>
    { contact.id ? <RoundBorderedBtn onClick={onOpenDetailsClick}>CONTACT DETAILS</RoundBorderedBtn> : <RoundBorderedBtn onClick={onOpenDetailsClick}>ADD CONTACT</RoundBorderedBtn>}
    <div className={'action-icons'}>
        { contact.emails[0] ? (<a href={`mailto:${contact.emails[0]}`}><span className="material-icons">email</span></a>) : null }
        { createChatProps ? (<a><CreateChatWrapper
          type="28"
          hideForSelf
          fill
          {...createChatProps}
        >
          <span className="material-icons chat-icon">chat_bubble</span>
        </CreateChatWrapper></a>) : null }
    </div>
  </div>
</div>);
})`
  background: white;
  width: 280px;
  min-height: 132px;
  padding: 12px;
  font-size: 12px;
  border-radius: 4px;
  >div:first-child {
    display: flex;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--stroke-light-gray2);
    line-height: 20px;
    > div:first-child {
      margin-right: 12px;
    }
    .name, .email, .company {
      word-break: break-all;
    }
    .name {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .company {
      color: var(--text-light, #999);
    }

  }
  >div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .action-icons {
      display: flex;
      align-items: center;
      height: 20px;
      a + a {
        margin-left: 8px;
      }
      .chat-icon {
        margin-top: 2px;
      }
      .material-icons {
        height: 20px;
        width: 20px;
        font-size: 20px;
        color: #4380C7;
      }
    }
  }
`

const ContactLoader = styled(({ className, ...props}) => {
  return <div className={className} {...props}>
    <Loader />
  </div>
})`
  background: white;
  width: 280px;
  min-height: 132px;
  border-radius: 4px;
`

export class ContactDetailsTip extends Component<Props, State> {

  state = {
    loading: true,
  }

  componentDidMount() {
    this.tryLoadContact();
  }


  tryLoadContact = async () => {
    const email = extractEmail(this.props.email);
    const name = extractNameFromEmail(this.props.email);
    this.setState({ loading: true, contact: null });
    try {
      const res = await ContactBookApi.findContactByEmail(email);
      let contact = res.data;
      if (!contact) {
        contact = { emails: [email], name };
      }
      this.setState({ contact });
    } catch (e) {
      console.error(e);
      this.setState({ contact: { emails: [email] }, name });
    } finally {
      this.setState({ loading: false });
    }
  }

  openContact = async (e) => {
    const position = window.innerWidth / e.pageX > 2 ? 'right' : 'left';
    PubSub.publish('GlobalContactDetailsDialog', { contact: this.state.contact, createChatProps: this.props.createChatProps, position });
    this.props.onClose();
  }

  render() {
    if (this.state.loading) {
      return <ContactLoader
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
      />;
    }
    return (<ContactPopoverBody
        contact={this.state.contact}
        createChatProps={this.props.createChatProps}
        onOpenDetailsClick={this.openContact}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
      />
    );
  }
}

class ContactDetailsPopover extends Component<{ email: any, userId?: any, createChatProps?: any, popoverProps?: any }> {
  static defaultProps = {
    popoverProps: {},
  }
  state = {};
  onMouseOver = (e) => {
    if (!this.props.email){
      return;
    }
    clearTimeout(this.openTimer);
    clearTimeout(this.closeTimer);
    if (this.state.opened) {
      return;
    }
    const anchorEl = e.currentTarget;
    this.openTimer = setTimeout(() => this.setState({ anchorEl, opened: true }), 300);
  }
  onMouseOut = () => {
    clearTimeout(this.openTimer);
    clearTimeout(this.closeTimer);
    if (this.state.opened) {
      this.closeTimer = setTimeout(() => this.setState({ anchorEl: null, opened: false }), 500);
    }
  }

  close = () => {
    this.setState({ anchorEl: null, opened: false });
  }

  render() {
    let {email, userId, createChatProps} = this.props;
    return <span onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} >
      {this.props.children}
      {this.state.opened ? (
        <Popover anchorEl={this.state.anchorEl} open={this.state.opened} onRequestClose={this.close} useLayerForClickAway={false} {...this.props.popoverProps} >
          <ContactDetailsTip
            email={email}
            userId={userId}
            createChatProps={createChatProps}
            onClose={this.close}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
          />
        </Popover>
      ) : null}
    </span>;
  }
}

export default ContactDetailsPopover;

