import React, { Component } from 'react';
import NarrowPortAutocomplete from './../../NewInputs/NarrowPortAutocomplete';
import NarrowCargoAutocomplete from './../../NewInputs/NarrowCargoAutocomplete';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import v from './VesselEdit.scss';
import Chip from 'material-ui/Chip';
import CustomCheckbox from '../../Common/CustomCheckbox';
import Info from 'material-ui/svg-icons/action/info-outline';
class Exclusions extends Component {
  static defaultProps = {
      cargoExclusions: [],
      tradingRestrictions: [],
      isVesselOwner: false,
      attachAsRef: () => void(0),
      changesCallback: () => void(0),
      cargosFieldName: "cargoExclusions",
      cargosFieldLabel: "Cargo",
      areasFieldName: "tradingRestrictions",
      areasFieldLabel: "Trading Area",
      isChanged: false,
  }
  static greenExclRoles = ['user', 'admin']
  static ownerRole = "owner"
  static userRole = "user"
  static adminRole = "admin"
  constructor(props) {
      super(props);
      this.state = {
          cargoExclusions: this.filteredExclusions(this.props.cargoExclusions || []).map(excl => excl ? {...excl, makeOwners: excl.role === Exclusions.ownerRole ? true : false} : {}),
          tradingRestrictions: this.filteredExclusions(this.props.tradingRestrictions || []).map(excl => excl ? {...excl, makeOwners: excl.role === Exclusions.ownerRole ? true : false} : {}),
      }
  }

  componentWillReceiveProps(nextProps) {
      if ((nextProps.cargoExclusions !== this.props.cargoExclusions || nextProps.tradingRestrictions !== this.props.tradingRestrictions) && !nextProps.isChanged) {
          this.setState({
              cargoExclusions: this.filteredExclusions(nextProps.cargoExclusions || this.state.cargoExclusions).map(excl => excl ? {...excl, makeOwners: excl.role === Exclusions.ownerRole ? true : false} : {}),
              tradingRestrictions: this.filteredExclusions(nextProps.tradingRestrictions || this.state.tradingRestrictions).map(excl => excl ? {...excl, makeOwners: excl.role === Exclusions.ownerRole ? true : false} : {}),
          })
      }
  }
  sortExclusions = list => list.sort((a,b) => a.role === Exclusions.ownerRole ? -1 : 1)
  filteredExclusions = list => list.filter((item, index) => {
      if (!item._id && !item.name) {
          return false;
      }
      const _item = JSON.stringify(item);
      return index === list.findIndex(obj => JSON.stringify(obj) === _item)
  })
  transformExclToSend = list => list ? list.map(({ _id, makeOwners = false, role = 'user' }) => ({ id: _id, makeOwners, role })) : []
  getCargoExclusions = () => {
    return this.transformExclToSend(this.state.cargoExclusions);
  }
  getTradingRestrictions = () => {
    return this.transformExclToSend(this.state.tradingRestrictions);
  }
  getOnlyUserExclusions = type => this.state[type] ? this.transformExclToSend(this.state[type].filter(item => item.role === Exclusions.userRole)) : null;
  exclusionExists = (arr, excl) => {
      return arr.find(item => item._id === excl._id)
  }
  addCargoExclusion = (exclusion) => {
      if (!exclusion || !exclusion.name) return;
      const { cargoExclusions } = this.state;
      if (this.exclusionExists(cargoExclusions, exclusion)) return;
      this.props.changesCallback();
      this.setState(state => ({
          cargoExclusions: [...state.cargoExclusions, {
              ...exclusion,
              role: this.props.isVesselOwner ? Exclusions.ownerRole : Exclusions.userRole,
              mine: true,
              makeOwners: this.props.isVesselOwner ? true : false,
          }]
      }))
  }
  addTradingRestriction = (exclusion) => {
      if (!exclusion || !exclusion.name) return;
      const { tradingRestrictions } = this.state;
      if (this.exclusionExists(tradingRestrictions, exclusion)) return;
      this.props.changesCallback();
      this.setState(state => ({
          tradingRestrictions: [...state.tradingRestrictions, {
              ...exclusion,
              role: this.props.isVesselOwner ? Exclusions.ownerRole : Exclusions.userRole,
              mine: true,
              makeOwners: this.props.isVesselOwner ? true : false,
          }],
      }))
  }

  renderExclusions = (array, cb, type) => {
      const { isVesselOwner } = this.props;
      return array.map(item => {
          const green = Exclusions.greenExclRoles.includes(item.role.toLowerCase());
          const canDelete = isVesselOwner || item.mine || item.role === Exclusions.userRole;
          return (
              <Chip key={item._id} onRequestDelete={canDelete ? _ => cb(item) : null} backgroundColor={green ? '#D6EDBD' : '#E6E6E6'} style={{margin: '8px 8px 0px 0px'}} className={v.chip}>
                {isVesselOwner && green
                  ? (
                      <span style={{display: 'flex', marginRight: '4px'}}>
                        <CustomCheckbox isDark value={item.makeOwners} onCheck={this.makeExclusionOwners.bind(this, item._id, type)} />
                        {
                          item.role ===  Exclusions.adminRole
                          ? (
                            <span title="Recommended by admin">
                              <Info style={{ width: '24px', cursor: 'pointer', height: '24px' }} />
                            </span>

                          )
                          : null
                        }
                      </span>
                  )
                  : null
                }
                {item.name}
              </Chip>
          )
      })
  }

  makeExclusionOwners = (id, type = 'cargoExclusions', ev, val) => {
    const index = this.state[type].findIndex(excl => excl._id === id);
    if (index >= 0) {
      this.props.changesCallback();
      const exclusions = [...this.state[type]];
      exclusions[index] = {
        ...exclusions[index],
        makeOwners: val || false,
      };
      this.setState(state => ({
        [type]: exclusions,
      }));
    }
  }

  deleteExclusion = (type, excl) => {
      const newArr = this.state[type].filter(item => {
          if (item._id === excl._id) {
              if (item.role === excl.role) {
                  return false;
              }
              else {
                  return true;
              }
          }
          else {
              return true;
          }
      });
      this.props.changesCallback();
      this.setState({
          [type]: newArr
      })
  }
  componentDidMount() {
    this.props.attachAsRef(this);
  }
  render() {
      const { addCargoExclusion, addTradingRestriction, renderExclusions, deleteExclusion } = this;
      const { cargoExclusions, tradingRestrictions } = this.state;
      return (
          <div className={v.exclusions}>
              <div className={v.add}>
                  <NarrowCargoAutocomplete
                      floatingLabelText={this.props.cargosFieldLabel}
                      forceSelection
                      validationError="max 500 characters"
                      name={this.props.cargosFieldName}
                      fullWidth
                      shouldReset
                      onNewRequest={addCargoExclusion}
                      params={{groupBy: 'name'}}
                  />

              </div>
              <div className={v.list}>
                  {
                      renderExclusions(cargoExclusions, deleteExclusion.bind(this, 'cargoExclusions'), "cargoExclusions")
                  }
              </div>
              <div className={v.add}>
                  <NarrowPortAutocomplete
                      params={{
                        withAreas: true
                      }}
                      floatingLabelText={this.props.areasFieldLabel}
                      validationError="max 500 characters"
                      name={this.props.areasFieldName}
                      shouldReset
                      onNewRequest={addTradingRestriction}
                  />

              </div>
              <div className={v.list}>
                  {
                      renderExclusions(tradingRestrictions, deleteExclusion.bind(this, 'tradingRestrictions'), "tradingRestrictions")
                  }
              </div>
          </div>
      )
  }
}

export default withStyles(v)(Exclusions)
