/*
  Component is used to hide (display: none) if/else expressions
  Needs at least one IF statement (can be nested in children)
  Every Else statement is bound to last previois IF statement.
  E.g VesselTableNew headers (selected/unselected vessels)
*/

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
const Wrapper = styled.div`
  ${
    props => {
      let string = '';
      if (!props.withoutStyles) {
        string += `
          width: 100%;
          display: flex;
          align-items: center;
        `;
      }
      if (props.shouldHide) {
        string += `display: none;`;
      }
      return css`
        ${string}
      `
    }
  };
`;
const contextTypes = {
  isResolved: PropTypes.bool,
  resolve: PropTypes.func,
  attachExpression: PropTypes.func,
  detachExpresion: PropTypes.func,
  withoutStyles: PropTypes.bool,
};


class Condition extends Component {

  static childContextTypes = contextTypes;

  getChildContext() {
    return {
      withoutStyles: this.props.withoutStyles,
      isResolved: this.state.isResolved,
      resolve: val => {
        this.setState(state => ({
          ...state,
          isResolved: {
            ...state.isResolved,
            ...val,
          }
        }));
      },
      attachExpression: instance => {
        let index = null;
        React.Children.forEach(this.props.children, (child, i) => {
          let isAncestorOrChild = child === instance._reactInternalInstance._currentElement || this.compareChildren(child?.props?.children, instance?._reactInternalInstance?._currentElement);
          if (isAncestorOrChild) index = i;
        });
        if (this.constructor.isIf(instance)) {
          let id = instance.state?._conditionId;
          if (!id) {
            id = this.resolveIfClause(instance, index);
            if (index >= 0) {
              // TODO: change imperative loop of mixed thjis.props.children and this.expressions to comparing _conditionId of instance (IF statement) to _conditionIfId of bound (following) Else statement
              for (let i = index; i < this.props.children.length; i++) {
                if (this.constructor.isElse(this.expressions[i])) {
                  const elseStatement = this.expressions[i];
                  elseStatement.state = {
                    ...(elseStatement.state || {}),
                    _conditionIfId: id,
                  };
                  elseStatement.setState({
                    _conditionIfId: id,
                  });
                }
              }
            }
          }
          this.setState(state => ({
            isResolved: {
              ...state.isResolved,
              [id]: instance.props.display,
            },
          }));
        }
        const newExpressions = [...this.expressions];
        newExpressions[index] = instance;

        this.expressions = newExpressions;
      },
      detachExpresion: instance => {
        if (this.constructor.isIf(instance)) {
          const followedElseExpression = this.expressions.find(e => e?.state?._conditionIfId === instance.state?._conditionId);
          if (followedElseExpression) {
            const { _conditionIndex } = followedElseExpression.state || {};
            let newIfStatement = null;
            for (let i = _conditionIndex; i >= 0; i--) {
              if (this.constructor.isIf(this.expressions[i])) {
                newIfStatement = this.expressions[i];
              }
            }
            if (newIfStatement) {
              followedElseExpression.setState({
                _conditionIfId: newIfStatement.state._conditionId,
              });
            }
          }

          this.setState(state => {
            const newState = {
              ...state,
            };

            Reflect.deleteProperty(newState.isResolved, instance.state._conditionId);

            return newState;
          })
        }
        const i = this.expressions.findIndex((e, i) => e === instance);
        const newExpressions = [...this.expressions];
        delete newExpressions[i];
        this.expressions = newExpressions;
      }
    };
  }

  static IF = class _IF extends Component {
    static contextTypes = contextTypes;

    state = {}

    componentDidMount() {
      this.context.attachExpression(this);
    }

    componentDidUpdate() {
      this.updateContext();
    }

    componentWillUnmount() {
      this.context.detachExpresion(this);
    }

    updateContext = () => {
      const isTrue = this.props.display;
      const val = this.context.isResolved[this.state._conditionId];
      if (isTrue !== val) {
        this.context.resolve({
          [this.state._conditionId]: isTrue,
        });
      }
    }

    render() {
      const { display, children, withoutStyles, ...rest } = this.props;
      const isTrue = display;
      return (
        <Wrapper withoutStyles={withoutStyles ?? this.context.withoutStyles} shouldHide={!isTrue} {...rest}>
          {
            children
          }
        </Wrapper>
      )
    }
  }

  static Else = class Else extends Component {
    static contextTypes = contextTypes

    state = {}

    componentDidMount() {
      this.context.attachExpression(this);
    }

    componentWillUnmount() {
      this.context.detachExpresion(this);
    }

    render() {
      const val = this.context.isResolved[this.state._conditionIfId];
      return (
        <Wrapper withoutStyles={this.props.withoutStyles ?? this.context.withoutStyles} shouldHide={val}>
          {
            this.props.children
          }
        </Wrapper>
      )
    }
  }

  static IfType = (() => {
    const Tag = this.IF;
    return (<Tag />).type;
  })();
  static ElseType = (() => {
    const Tag = this.Else;
    return (<Tag />).type;
  })();

  static isIf = instance => instance?._reactInternalInstance?._currentElement?.type === this.IfType;
  static isElse = instance => instance?._reactInternalInstance?._currentElement?.type === this.ElseType

  state = {
    isResolved: {},
  }

  expressions = [];

  compareChildren = (children, element) => {
    if (!element || !children) return;
    let isFound = false;
    React.Children.forEach(children, child => {
      if (!child || isFound) return;
      isFound = child === element || this.compareChildren(child?.props?.children, element);
    });
    return isFound;
  }

  resolveIfClause = (instance, index) => {
    const id = uuidv4();
    instance.state = {
      ...(instance.state || {}),
      _conditionId: id,
    }
    instance.setState({
      _conditionIndex: index, _conditionId: id,
    });
    this.setState(state => ({
      isResolved: {
        ...state.isResolved,
        [id]: instance.props.display,
      }
    }));
    return id;
  }

  componentDidMount() {
    let latestIfId = null;
    this.expressions.forEach((child, i) => {
      if (this.constructor.isIf(child)) {
        let id = child.state?._conditionId;
        if (!id) {
          id = this.resolveIfClause(child, i);
        }
        latestIfId = id;
      }
      else if (this.constructor.isElse(child)) {
        if (latestIfId && !child.state?._conditionIfId) {
          child.setState({
            _conditionIndex: i, _conditionIfId: latestIfId,
          })
        }
      }
      return child;
    });
  }

  render() {
    const { children, withoutStyles, ...rest } = this.props;
    return (
      <Wrapper withoutStyles={withoutStyles} {...rest}>
        {
          children
        }
      </Wrapper>
    );
  }
}

export default Condition;
