import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import s from '../../Contracts/Timecharter/Request/Request.scss';
import cx from "classnames";
import RaisedButton from '../../Common/RaisedButton';
import NarrowFormsyDate from '../../NewInputs/NarrowFormsyDate';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import NarrowVesselAutocomplete from '../../NewInputs/NarrowVesselAutocomplete';
import Counteragents from './Counteragents';
import { BROKER, CARRIER } from './CounteragentTypes';

export class Preamble extends PureComponent {

  static contextTypes = {
    onChange: PropTypes.func,
    selector: PropTypes.func,
  }

  onChange = this.context.onChange('preamble');

  handleGenericChange = name => (ev, val) => {
    return this.onChange(name)(val);
  }

  componentDidMount() {
    const { isTcOffline } = this.props;
    const { counteragents } = this.props.preamble;

    let copy = [...(counteragents || [])];

    if (!copy?.length) {
      copy.push(this.createDefaultStateObject());
    }
    else {
      copy = copy.map(counteragent => counteragent?.type ? counteragent : ({ ...(counteragent || {}), type: isTcOffline ? BROKER.value : CARRIER.value }));
    }

    return this.handleGenericChange('counteragents')(null, copy);
  }

  createDefaultStateObject = (options) => ({
    key: 1,
    type: this.props.isTcOffline ? BROKER.value : CARRIER.value,
    company: '',
    details: '',
    ...options,
  });

  handleAddCounteragent = () => {
    const prev = this.props.preamble.counteragents || [];
    return this.handleGenericChange('counteragents')(null, [...prev, this.createDefaultStateObject({ key: prev.length + 1 })]);
  }

  render() {
    const { isTcOffline = false } = this.props;
    const { clientPlace, clientDate, clientContractRefNo, vessel, vesselDetails, counteragents } = this.props.preamble;

    return (
      <div data-id="preamble">
        <div className={cx("row", isTcOffline && s.step_row)}>
          <div className="col-6">
            <NarrowFormsyText
              name="preamble[clientPlace]"
              value={clientPlace}
              hintText="Place"
              floatingLabelText="Place"
              fullWidth
              onChange={this.handleGenericChange('clientPlace')}
            />
          </div>
          <div className="col-3">
            <NarrowFormsyDate
              name="preamble[clientDate]"
              value={clientDate}
              hintText="Date"
              floatingLabelText="Date"
              fullWidth
              onChange={this.handleGenericChange('clientDate')}
              container="inline"
              autoOk
              startDate={new Date()}
            />
          </div>
          <div className="col-3">
            <NarrowFormsyText
              name="preamble[clientContractRefNo]"
              value={clientContractRefNo}
              hintText="Ref/No"
              floatingLabelText="Ref/No"
              fullWidth
              onChange={this.handleGenericChange('clientContractRefNo')}
            />
          </div>
        </div>
        <div className={cx("row", isTcOffline && s.step_row)}>
          <div className="col-6">
            <NarrowVesselAutocomplete
              name="preamble[vessel]"
              value={vessel}
              hintText="Vessel"
              floatingLabelText="Vessel"
              fullWidth
              onNewRequest={(vessel) => this.handleGenericChange('vessel')(null, vessel)}
            />
          </div>
          <div className="col-6">
            <NarrowFormsyText
              name="preamble[vesselDetails]"
              value={vesselDetails}
              hintText="Details"
              floatingLabelText="Details"
              fullWidth
              onChange={this.handleGenericChange('vesselDetails')}
            />
          </div>
        </div>
        <div className={cx("row", isTcOffline && s.step_row)}>
          <Counteragents handleChange={this.handleGenericChange('counteragents').bind(this, null)} handleAdd={this.handleAddCounteragent} counteragents={counteragents || []} isTcOffline={isTcOffline} className={isTcOffline ? s.step_row : ''} />
        </div>
        <div style={{ margin: '12px 0' }}>
          <RaisedButton
            label="NEXT"
            disableTouchRipple
            disableFocusRipple
            primary
            type="submit"
            style={{ margin: '13px 0 6px 0' }}
          />
        </div>
      </div>
    )
  }
}

export default Preamble;
