import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../../../Pages/Pages.scss';

function formatDay(date) {
  return ('0' + date.getDate()).slice(-2);
}
const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
function formatMonth(date) {
  return MONTH_NAMES[date.getMonth()];
}
function formatDate(item) {
  const startDate = new Date(item.startDate);
  const endDate = new Date(item.endDate);
  if (startDate.getTime() === endDate.getTime()) {
    return (
      <span className={s.date}>
        {formatDay(startDate)}&nbsp;{formatMonth(startDate)}
      </span>
    );
  }
  if (startDate.getMonth() !== endDate.getMonth()) {
    return (
      <span className={s.date}>
        {formatDay(startDate)} {formatMonth(startDate)} - {formatDay(endDate)} {formatMonth(endDate)}
      </span>
    );
  }
  return (
    <span className={s.date}>
      {formatDay(startDate)} - {formatDay(endDate)} {formatMonth(endDate)}
    </span>
  );
}
function Conference({ item }) {
  return (
    <li>
      <span className={s.wrapper_description}>
        {item.link ?
          <a href={item.link} rel="noopener noreferrer" target="_blank">
            <span className={s.green_active}>{item.name}</span>
          </a>
          :
          <span>{item.name}</span>
        }
        <span>{item.html}</span>
      </span>
      <span className={s.date}>
        {formatDate(item)}
      </span>
    </li>
  );
}

class ConferencesMob extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open2020: false,
      open2019: false,
      open2018: false,
      open2017: false,
    };
  }
  _toggleConf2020 = () => {
    this.setState({ open2020: !this.state.open2020 });
  };
  _toggleConf2019 = () => {
    this.setState({ open2019: !this.state.open2019 });
  };
  _toggleConf2018 = () => {
    this.setState({ open2018: !this.state.open2018 });
  };
  _toggleConf2017 = () => {
    this.setState({ open2017: !this.state.open2017 });
  };
  render() {
    return (
      <div className={s.wrapper_accordion}>
        <div
          className={cx(s.title_year, this.state.open2020 ? s.active : null)}
          onClick={this._toggleConf2020}
        >
          <p>2020</p>
          <span className={s.arrow_show} />
        </div>
        { this.state.open2020 ?
          <div className={s.wrapper_conferences}>
            <ul className={s.column}>
              {this.props.conferences2020.map((item, i) => (
                  <Conference key={i} item={item} />
                ),
              )}
            </ul>
          </div>
          : null
        }
        <div
          className={cx(s.title_year, this.state.open2019 ? s.active : null)}
          onClick={this._toggleConf2019}
        >
          <p>2019</p>
          <span className={s.arrow_show} />
        </div>
        { this.state.open2019 ?
          <div className={s.wrapper_conferences}>
            <ul className={s.column}>
              {this.props.conferences2019.map((item, i) => (
                  <Conference key={i} item={item} />
                ),
              )}
            </ul>
          </div>
          : null
        }

        <div
          className={cx(s.title_year, this.state.open2018 ? s.active : null)}
          onClick={this._toggleConf2018}
        >
          <p>2018</p>
          <span className={s.arrow_show} />
        </div>
        { this.state.open2018 ?
          <div className={s.wrapper_conferences}>
            <ul className={s.column}>
              {this.props.conferences2018.map((item, i) => (
                  <Conference key={i} item={item} />
                ),
              )}
            </ul>
          </div>
          : null
        }
        <div
          className={cx(s.title_year, this.state.open2017 ? s.active : null)}
          onClick={this._toggleConf2017}
        >
          <p>2017</p>
          <span className={s.arrow_show} />
        </div>
        {this.state.open2017 ?
          <div className={s.wrapper_conferences}>
            <ul className={s.column}>
              {this.props.conferences2017.map((item, i) => (
                  <Conference key={i} item={item} />
                ),
              )}
            </ul>
          </div>
          : null
        }
      </div>
    );
  }
}

export default (withStyles(s)(ConferencesMob));
