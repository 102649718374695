import React from 'react';
import pure from 'recompose/pure';

const _ContactsLinkedin = props => (
  <span {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#285596"/>
      <path d="M12.9281 10.3646C13.1254 10.1424 13.2836 9.9166 13.4898 9.72638C13.7824 9.44119 14.1287 9.217 14.5087 9.06684C14.8887 8.91669 15.2947 8.84356 15.7032 8.85171C16.1739 8.8436 16.6435 8.90043 17.0987 9.0206C18.1405 9.32283 18.7449 10.0393 19.0347 11.0562C19.2268 11.8182 19.3135 12.603 19.2925 13.3886C19.2925 15.0473 19.2925 16.7042 19.2925 18.3664C19.2925 18.521 19.2498 18.5619 19.097 18.5602C18.2412 18.553 17.3873 18.553 16.5352 18.5602C16.3841 18.5602 16.3574 18.5157 16.3574 18.3824C16.3574 16.8049 16.3574 15.2274 16.3574 13.6499C16.3663 13.2537 16.3196 12.8581 16.2187 12.4748C16.0143 11.7637 15.5076 11.4082 14.7663 11.4455C13.7547 11.4988 13.2267 12.0019 13.0987 13.0313C13.0673 13.2765 13.0519 13.5236 13.0525 13.7708C13.0525 15.305 13.0525 16.8393 13.0525 18.3735C13.0525 18.5264 13.0134 18.5708 12.8569 18.569C11.9983 18.569 11.1378 18.569 10.2774 18.569C10.1387 18.569 10.0996 18.5335 10.0996 18.3913C10.0996 15.3548 10.0996 12.3178 10.0996 9.28016C10.0996 9.12905 10.1476 9.10238 10.2898 9.10238C11.1076 9.10949 11.9248 9.10949 12.7414 9.10238C12.8907 9.10238 12.9298 9.15038 12.9192 9.2926C12.9227 9.63749 12.9281 10.0002 12.9281 10.3646Z" fill="#FEFEFE"/>
      <path d="M8.24976 13.8348C8.24976 15.337 8.24976 16.8393 8.24976 18.3415C8.24976 18.5086 8.20887 18.5601 8.03643 18.5584C7.18131 18.5477 6.32799 18.5584 5.47465 18.5584C5.33776 18.5584 5.29688 18.5246 5.29688 18.3806C5.29688 15.337 5.29688 12.2947 5.29688 9.25348C5.29688 9.12726 5.32887 9.0757 5.46221 9.08459C6.32798 9.08459 7.19376 9.08459 8.05954 9.08459C8.22665 9.08459 8.24798 9.14682 8.24798 9.29082C8.2468 10.8055 8.24739 12.3201 8.24976 13.8348Z" fill="#FEFEFE"/>
      <path d="M8.48398 6.07124C8.48574 6.40896 8.38715 6.7396 8.20073 7.02121C8.01431 7.30282 7.74845 7.52272 7.43687 7.65302C7.1253 7.78332 6.78205 7.81814 6.45066 7.75308C6.11926 7.68801 5.81464 7.52599 5.57546 7.28756C5.33628 7.04913 5.1733 6.74503 5.1072 6.41384C5.04109 6.08265 5.07484 5.73929 5.20416 5.4273C5.33348 5.11532 5.55255 4.84877 5.83358 4.66147C6.1146 4.47416 6.44492 4.37455 6.78264 4.37525C7.23223 4.37757 7.66281 4.55683 7.98122 4.87424C8.29963 5.19165 8.48024 5.62167 8.48398 6.07124Z" fill="#FEFEFE"/>
    </svg>
  </span>
);

const ContactsLinkedin = pure(_ContactsLinkedin);
ContactsLinkedin.displayName = 'ContactsLinkedin';
ContactsLinkedin.muiName = 'SvgIcon';

export default ContactsLinkedin;
