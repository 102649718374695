/** @name EMAILS_CHECK_ALL_REDUCER */
export default function EMAILS_CHECK_ALL(state, { payload }) {
  const newState = {
    emailList: {
      ...state.emailList,
      data: state.emailList.data.map(e => ({ ...e, selected: payload.selected })),
    },
  };
  return { ...state, ...newState };
}
