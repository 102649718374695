import Api from './api';

export default class Miscellaneous extends Api {
  static getAddressBook(query) {
    if (!query) return;
    query = query.replace(/[\/|\\]/, "");
    return this.fetch(`/addressBook/${query}`, {
      method: "GET",
    }, "/api/v2");
  }
}
