import React from 'react';
import Sort from './Sort';

class SortContacts extends Sort {
  static defaultProps = {
    sort: {
      field: 'name',
      value: 1,
      label: 'Name',
    },
    values: {
      name: {
        field: 'name',
        value: 1,
        label: 'Name',
      },
    },
  };
}

export default SortContacts;
