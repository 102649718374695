import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import cx from 'classnames';
import s from './Cargo.scss';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from '../Common/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';
import SimpleFormsyAutoComplete from '../Common/SimpleFormsyAutocomplete';
import NarrowFormsyText from '../NewInputs/NarrowFormsyText';
import NarrowSelect from '../NewInputs/NarrowSelect';

const floatingLabelStyle = {fontSize: '13px'};
const floatingLabelFocusStyle = {fontSize: '15px'};
const inputStyle = {fontSize: '13px'};
const hintStyle = {fontSize: '13px'};

const EMPTY = {
  name: '',
  value: undefined,
  disablesFields: [
    "quantity","dimension","speed","abbreviation","term"
  ],
  requiredFields: {},
}

const LRATE = {
  name: 'Loading Rate',
  value: 'LoadingRate',
  disablesFields: [],
  requiredFields: { quantity: 1, dimension: 1 },
};
const LRATECQD = {
  name: 'Loading Rate - CQD',
  value: 'LoadingRateCQD',
  disablesFields: ['quantity', 'dimension'],
  previewLabel: 'CQD',
  requiredFields: {},
};
const LRATESCALE = {
  name: 'Loading Rate - Scale',
  value: 'LoadingRateScale',
  disablesFields: [...LRATECQD.disablesFields],
  previewLabel: 'Scale',
  requiredFields: {},
};
const LRAFAVC = {
  name: 'LR - As Fast As Vessel Can',
  value: 'LRAsFastAsVesselCan',
  disablesFields: [...LRATECQD.disablesFields],
  previewLabel: 'As Fast As Vessel Can',
  requiredFields: {},
};
export const LDRATE = {
  name: 'L/D Rate',
  value: 'LDRate',
  disablesFields: [],
  disablesOther: 'all',
  requiredFields: { quantity: 1, dimension: 1 },
};
export const LRATEDAYS = {
  name: 'L/D Rate - Total Days',
  value: 'LDRatesTotalDays',
  disablesFields: ['dimension'],
  forceValues: { dimension: 'days' },
  disablesOther: 'all',
  requiredFields: {},
  previewLabel: 'Total days',
};
export const LRATEHOURS = {
  name: 'L/D Rate - Total Hours',
  value: 'LDRatesTotalHours',
  disablesFields: ['dimension'],
  forceValues: { dimension: 'hours' },
  disablesOther: 'all',
  requiredFields: {},
  previewLabel: 'Total hours',
};

const DRATE = {
  name: 'Discharging Rate',
  value: 'DischargingRate',
  disablesFields: [],
  requiredFields: { quantity: 1, dimension: 1 },
};
const DRATECQD = {
  name: 'Discharging Rate - CQD',
  value: 'DischargingRateCQD',
  disablesFields: [...LRATECQD.disablesFields],
  previewLabel: 'CQD',
  requiredFields: {},
};
const DRATESCALE = {
  name: 'Discharging Rate - Scale',
  value: 'DischargingRateScale',
  disablesFields: [...LRATECQD.disablesFields],
  previewLabel: 'Scale',
  requiredFields: {},
};
const DRAFAVC = {
  name: 'DR - As Fast As Vessel Can',
  value: 'DRAsFastAsVesselCan',
  disablesFields: [...LRATECQD.disablesFields],
  previewLabel: 'As Fast As Vessel Can',
  requiredFields: {},
};

export const loadingRateTypes = [
  EMPTY,
  LRATE,
  LRATECQD,
  LRATESCALE,
  LRAFAVC,
  LDRATE,
  LRATEDAYS,
  LRATEHOURS,
];

export const dischargingRateTypes = [
  EMPTY,
  DRATE,
  DRATECQD,
  DRATESCALE,
  DRAFAVC,
];

const resetObjectFields = (obj = {}) => {
  const temp = {};

  if (!obj) return temp;

  const keys = Object.keys(obj);

  for (let key of keys) {
    temp[key] = "";
  }

  return temp;
}

export class LDRatesTypeConfig extends React.Component {

  disableOtherFields = (initialDisabledFields, otherFields) => {
    let copy = Array.isArray(initialDisabledFields)
      ? [...initialDisabledFields]
      : initialDisabledFields === 'all'
        ? initialDisabledFields
        : [initialDisabledFields];
    if (otherFields) {
      if (Array.isArray(otherFields)) {
        copy = [...initialDisabledFields, ...otherFields];
      }
      else if (typeof otherFields === 'string') {
        if (otherFields === 'all') {
          copy = 'all';
        }
        else {
          copy = [...copy, otherFields];
        }
      }
    }

    return copy;
  }

  render() {
    const { children, loading, discharging } = this.props;

    const loadingTypeConfig = loading.type ? loadingRateTypes.find(rateType => rateType.value === loading.type) : EMPTY;
    const dischargingTypeConfig = discharging.type ? dischargingRateTypes.find(rateType => rateType.value === discharging.type) : EMPTY;
    const loadingDisabledFields = this.disableOtherFields(loadingTypeConfig?.disablesFields ? [...loadingTypeConfig.disablesFields] : [], dischargingTypeConfig?.disablesOther);
    const dischargingDisabledFields = this.disableOtherFields(dischargingTypeConfig?.disablesFields ? [...dischargingTypeConfig.disablesFields] : [], loadingTypeConfig?.disablesOther);

    return children({
      loadingDisabledFields,
      dischargingDisabledFields,
      loadingRequiredFields: loadingTypeConfig.requiredFields,
      dischargingRequiredFields: dischargingTypeConfig.requiredFields,
    });
  }
}

// eslint-disable-next-line react/prefer-stateless-function
export default class LoadingDischargingRate extends PureComponent{

  static contextTypes = {
    selector: PropTypes.func,
  }

  static defaultProps = {
    showNextButton: true,
  }

  render() {
    // const loading = this.context.selector?.('ld')?.loading || {};
    // const discharging = this.context.selector?.('ld')?.discharging || {};
    const { loading, discharging } = this.props;

    return (
      <LDRatesTypeConfig loading={loading} discharging={discharging}>
        {
          ({
            loadingDisabledFields = [],
            dischargingDisabledFields = [],
            loadingRequiredFields = {},
            dischargingRequiredFields = {},
          }) => (
            <span>
              <LoadingRate loading={loading} disabledFields={loadingDisabledFields} requiredFields={loadingRequiredFields} />
              <DischargingRate discharging={discharging} disabledFields={dischargingDisabledFields} requiredFields={dischargingRequiredFields} />
              {this.props.showNextButton ? (
                <div style={{ margin: '24px 0 18px 0' }}>
                  <RaisedButton
                    label="NEXT"
                    disableTouchRipple
                    disableFocusRipple
                    primary
                    type="submit"
                    style={{ marginRight: 12 }}
                  />
                </div>
              ) : null}
            </span>
          )
        }
      </LDRatesTypeConfig>
    );
  }
}

class Rate extends Component {

  static contextTypes = {
    onChange: PropTypes.func,
    ldRates: PropTypes.object,
    selector: PropTypes.func,
  }

  extractFieldValues = name => {
    const { selector = () => ({}) } = this.context;

    const values = this.props[name] ?? selector('ld')[name] ?? {};

    return values;
  }

  setDefaultRate = (name, types, value) => {
    if (!this.props.setDefaultType) return;
    const values = this.extractFieldValues(name);

    const { type } = values;
    if (!type) {
      this.handleTypeChange(name, types)(null, value);
    }
  }

  onChange = this.context.onChange ? this.context.onChange("ld") : () => () => {};

  handleTypeChange = (name, types) => async (ev, val) => {
    const typeConfig = types.find(rateType => rateType.value === val);

    const onChange = this.onChange(name);

    const prevValues = this.extractFieldValues(name);

    const dataToUpdate = {
      ...prevValues,
      type: val,
    }

    if (typeConfig) {
      const forcedValues = typeConfig.forceValues || {};
      typeConfig.disablesFields.forEach(field => {
        if (field || forcedValues[field]) {
          dataToUpdate[field] = forcedValues[field] || '';
        }
      });

      if (typeConfig.disablesOther) {
        const otherFields = name === 'loading' ? 'discharging' : 'loading';
        const otherData = this.context.selector?.('ld')?.[otherFields] ?? {};

        let otherDataToUpdate = {
          ...otherData,
        };

        switch (typeof typeConfig.disablesOther) {
          case 'string': {
            if (typeConfig.disablesOther === 'all') {
              otherDataToUpdate = {
                ...resetObjectFields(otherData),
                type: otherData.type,
              }
            }
            else {
              otherDataToUpdate[typeConfig.disablesOther] = '';
            }
            break;
          }
          case 'object': {
            if (Array.isArray(typeConfig.disablesOther)) {
              typeConfig.disablesOther.forEach(field => otherDataToUpdate[field] = '');
            }
          }
        }

        this.onChange(otherFields)({
          ...otherDataToUpdate,
        });

      }

      await onChange(dataToUpdate);
    }
  }

  isTypeDisabled = (props) => props.disabledFields === 'all' || props.disabledFields?.includes?.('type')

  render() {
    return <div />;
  }
}


export class LoadingRate extends Rate {

  componentDidMount() {
    this.setDefaultRate('loading', loadingRateTypes, LRATE.value);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabledFields !== this.props.disabledFields && !this.isTypeDisabled(nextProps)) {
      this.setDefaultRate('loading', loadingRateTypes, LRATE.value);
    }
  }

  render() {

    const loading = this.extractFieldValues('loading');

    const { cqd = false, type = '' } = loading;

    const isTypeDisabled = this.isTypeDisabled(this.props);

    return (
        <SharedFields disabledFields={this.props.disabledFields} cqd={cqd} name="loading" requiredFields={this.props.requiredFields}>
          <div className="col-12 col-md-4">
            <NarrowSelect
              name="ldRates[loading][type]"
              value={isTypeDisabled ? '' : type}
              onChange={this.handleTypeChange('loading', loadingRateTypes)}
              autoWidth
              hintText={isTypeDisabled ? '---' : 'Select LR Type'}
              floatingLabelText={isTypeDisabled ? undefined : 'Select LR Type'}
              disabled={isTypeDisabled}
              style={{ width: "100%" }}
            >
              {
                loadingRateTypes.map(lRateType => <MenuItem key={lRateType.value} value={lRateType.value} primaryText={lRateType.name} />)
              }
            </NarrowSelect>
          </div>
        </SharedFields>
    );
  }

}

/*export function LoadingRate(props, { ldRates }) {
  const { dimensions, speed, abbreviation, term } = ldRates;
  const previousValues = props.previousValues;
  return
}*/

LoadingRate.propTypes = {
};
LoadingRate.defaultProps = {
  floatingLabelText: 'Loading rate',
};


export class DischargingRate extends Rate {

  componentDidMount() {
    this.setDefaultRate('discharging', dischargingRateTypes, DRATE.value);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabledFields !== this.props.disabledFields && !this.isTypeDisabled(nextProps)) {
      this.setDefaultRate('discharging', dischargingRateTypes, DRATE.value);
    }
  }

  render() {
    const discharging = this.extractFieldValues('discharging');

    const { cqd = false, type = '' } = discharging;

    const isTypeDisabled = this.isTypeDisabled(this.props);

    return (
        <SharedFields disabledFields={this.props.disabledFields} key={cqd} cqd={cqd} name="discharging" requiredFields={this.props.requiredFields}>
          <div className="col-12 col-md-4">
            <NarrowSelect
              name="ldRates[discharging][type]"
              onChange={this.handleTypeChange('discharging', dischargingRateTypes)}
              value={isTypeDisabled ? '' : type}
              hintText={isTypeDisabled ? '---' : 'Select DR Type'}
              floatingLabelText={isTypeDisabled ? undefined : 'Select DR Type'}
              disabled={isTypeDisabled}
              autoWidth
              style={{ width: "100%" }}
            >
             {
              dischargingRateTypes.map(dRateType => <MenuItem key={dRateType.value} value={dRateType.value} primaryText={dRateType.name} />)
              }
            </NarrowSelect>
          </div>
        </SharedFields>
    );
  }

}

DischargingRate.propTypes = {
};
DischargingRate.defaultProps = {
  floatingLabelText: 'Discharging rate',
};

class SharedFields extends React.Component {

  static contextTypes = {
    onChange: PropTypes.func,
    ldRates: PropTypes.object,
    selector: PropTypes.func,
  };

  renderField = inputName => InputEl => {
    const { disabledFields = [], name, requiredFields = {} } = this.props;
    const isDisabled = disabledFields === 'all' ? true : disabledFields.includes(inputName);
    const required = !!requiredFields[inputName] && !isDisabled;
    let label = InputEl.props.floatingLabelText;
    if (required) {
      label += '*';
    }
    return React.cloneElement(InputEl, {
      disabled: isDisabled,
      required,
      onChange: isDisabled ? () => { } : InputEl.props.onChange,
      name: `ldRates[${name}][${inputName}]`,
      value: InputEl.props.value,
      floatingLabelText: label,
    });
  }

  onChange = this.context.onChange && this.context.onChange("ld")(this.props.name);

  render() {
    const { name, cqd, children, ...rest } = this.props;
    const { dimensions, speed, abbreviation, term } = this.context.ldRates;

    const values = (this.context.selector && this.context.selector("ld"))?.[name] || {};

    return (
      <div style={{ alignItems: "center", minHeight: "62px" }} className={cx('row', s.ld_rate, cqd ? s.cqd : null)}>
          <NarrowFormsyText
            type="text"
            style={{ display: 'none' }}
            name={`ldRates[${name}][cqd]`}
            value={cqd}
          />
          {
            children
          }
          <div className="col-12 col-md-4">
            {
              this.renderField('quantity')(
                <NarrowFormsyText
                  floatingLabelText={"Rate"}
                  validations="isNumeric,gt0"
                  validationErrors={{
                    isNumeric: 'only numbers',
                    gt0: 'must be greater than 0',
                  }}
                  requiredError="required"
                  value={values.quantity || ""}
                  onChange={this.onChange && ((ev, val) => this.onChange({
                    ...values,
                    'quantity': val,
                  }))}
                  required={!cqd}
                  fullWidth
                />
              )
            }
          </div>
          <div className="col-12 col-md-4">
            {
              this.renderField('dimension')(
                <LdRateSelect
                  ds={dimensions}
                  hintText={"Measures"}
                  floatingLabelText={"Measures"}
                  required={!cqd}
                  requiredError="required"
                  value={values.dimension || ""}
                  onChange={this.onChange && ((ev, val) => this.onChange({
                    ...values,
                    'dimension': val,
                  }))}
                />
              )
            }
          </div>
          <div className="w-100" />
          <div className="col-12 col-md-4">
            {
              this.renderField('speed')(
                <LdRateSelect
                  ds={speed}
                  hintText="Speed"
                  floatingLabelText="Speed"
                  value={values.speed || ""}
                  onChange={this.onChange && ((ev, val) => this.onChange({
                    ...values,
                    'speed': val,
                  }))}
                />
              )
            }
          </div>
          <div className="col-12 col-md-4">
            {
              this.renderField('abbreviation')(
                <LdRateSelect
                  ds={abbreviation}
                  hintText="Abbreviation"
                  floatingLabelText="Abbreviation"
                  value={values.abbreviation || ""}
                  onChange={this.onChange && ((ev, val) => this.onChange({
                    ...values,
                    'abbreviation': val,
                  }))}
                />
              )
            }
          </div>
          <div className="col-12 col-md-4">
            {
              this.renderField('term')(
                <LdRateSelect
                  ds={term}
                  hintText="Term"
                  floatingLabelText="Term"
                  value={values.term || ""}
                  onChange={this.onChange && ((ev, val) => this.onChange({
                    ...values,
                    'term': val,
                  }))}
                />
              )
            }
          </div>
      </div>
    )
  }
}


function LdAutocomplete({ name, ds, required, previousValues, ...restProps }) {
  return (<SimpleFormsyAutoComplete
    hintText=" "
    floatingLabelStyle={floatingLabelStyle}
    floatingLabelFocusStyle={floatingLabelFocusStyle}
    hintStyle={hintStyle}
    inputStyle={inputStyle}
    style={{ fontSize: '13px' }}
    name={name}
    fullWidth
    autoWidth
    required={required}
    dataSource={ds}
    openOnFocus
    filter={AutoComplete.caseInsensitiveFilter}
    validations={{
      ldDimension: (values, value) => !value || ds.indexOf(value) > -1 ? true : `invalid`
      ,
    }}
    requiredError="required"
    {...restProps}
  />);
}


const LdRateSelect = ({ ds = [], style = {}, ...rest }) => {
  return (
    <NarrowSelect
      autoWidth
      style={{ width: "100%", ...style }}
      {...rest}
    >
      {
        ["", ...ds].map(rate => <MenuItem value={rate} primaryText={rate} />)
      }
    </NarrowSelect>
  );
}
