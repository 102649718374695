/**
 * Created by jenya on 14.11.16.
 */

/* eslint-disable import/prefer-default-export */
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCEEDED = 'GET_PROFILE_SUCCEEDED';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCEEDED = 'UPDATE_PROFILE_SUCCEEDED';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

/**@see  {@link PROFILE_CHANGE_PASSWORD_REDUCER}*/
export const PROFILE_CHANGE_PASSWORD = 'PROFILE_CHANGE_PASSWORD';
/**@see  {@link PROFILE_CHANGE_PASSWORD_REQUEST_REDUCER}*/
export const PROFILE_CHANGE_PASSWORD_REQUEST =
  'PROFILE_CHANGE_PASSWORD_REQUEST';
/**@see  {@link PROFILE_CHANGE_PASSWORD_FAILED_REDUCER}*/
export const PROFILE_CHANGE_PASSWORD_FAILED = 'PROFILE_CHANGE_PASSWORD_FAILED';
