import React from 'react';
import cx from 'classnames';
import CounteragentTypes, { BROKER, CARRIER } from './CounteragentTypes';
import NarrowCompanyAutocomplete from '../../NewInputs/NarrowCompanyAutocomplete';
import NarrowFormsyText from '../../NewInputs/NarrowFormsyText';
import RoundBorderedPlus from '../../Common/RoundBorderedPlus';
import styled from 'styled-components';
import RoundFilledCross from '../../Common/RoundFilledCross';

const styles = `margin-left: 12px;`;

const Add = styled(RoundBorderedPlus)`
  ${styles}
`;
const Remove = styled(RoundFilledCross)`
  ${styles}
`

function Counteragents(props) {
  const { counteragents, className = "", handleChange, handleAdd, isTcOffline, ...rest } = props;

  const change = index => name => (ev, val) => {
    const agent = counteragents.find((agent, i) => index === i);

    if (!agent) return;

    const copy = {
      ...agent,
      [name]: val
    };

    const counteragentsCopy = [...counteragents];

    counteragentsCopy[index] = copy;

    return handleChange(counteragentsCopy);
  };

  const addRow = () => {
    if (handleAdd) {
      return handleAdd();
    }
    if (isTcOffline) {
      return handleChange([...counteragents, { type: BROKER.value }]);
    }
    return handleChange([...counteragents, { type: CARRIER.value }]);
  }

  const removeRow = (i) => () => {
    return handleChange(counteragents.filter((_, index) => index !== i));
  }

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {counteragents.map((agent, i) => {
        return (
          <div key={agent?.key ?? i} className={cx("w-100", "align-items-center", className)}>
            <div className="col-3">
              <CounteragentTypes
                isTcOffline={isTcOffline}
                fullWidth
                name={`preamble[counteragents][${i}][type]`}
                value={agent?.type}
                onChange={change(i)("type")}
              />
            </div>
            <div className="col-3">
              <NarrowCompanyAutocomplete
                name={`preamble[counteragents][${i}][company]`}
                value={agent?.company || ''}
                hintText="Company"
                floatingLabelText="Company"
                fullWidth
                onNewRequest={change(i)('company').bind(null, null)}
                popoverProps={{ style: { width: "auto" } }}
                inputStyles={{ textOverflow: "ellipsis" }}
              />
            </div>
            <div className="col-6 align-items-center">
              <NarrowFormsyText
                name={`preamble[counteragents][${i}][details]`}
                value={agent?.details || ''}
                hintText="Details"
                floatingLabelText="Details"
                fullWidth
                onChange={change(i)('details')}
              />
              {
                i === 0
                  ? <Add onClick={addRow} />
                  : <Remove onClick={removeRow(i)} />
              }
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Counteragents;
