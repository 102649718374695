import produce from 'immer';
import { v4 } from 'uuid';

export function populateAutotagCategory(state, autotag) {
  autotag.tags.forEach(t => {
    t.category = state.tagCategoriesByName[t.categoryName?.toLowerCase()];
  });
  return autotag;
}

/** @name PROFILE_GET_AUTOTAGS_REDUCER */
export default function PROFILE_GET_AUTOTAGS(state, { payload, res }) {
  const { entity } = payload;
  return produce(state, draft => {
    draft.autoTags[entity] = res.data.map(pref => populateAutotagCategory(state, { ...pref.autoTags, _id: pref._id }));
    if (draft.autoTags[entity].length === 0) {
      draft.autoTags[entity].push({ _id: v4(), filter: {}, tags: [], isNew: true, enabled: false });
    }
  });
}
