import React, { Component } from 'react'
import cx from "classnames";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Comments.scss';
import {dateTime} from './../../../core/format'
import IconButton from 'material-ui/IconButton';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
export class Comment extends Component {

  static defaultProps = {
    persistLineBreaks: true
  }
  _textLines = [];

  state = {
    collapsible: false,
    collapsed: true
  }
  componentDidMount() {
    const {comment} = this.refs;
    if (!comment) return;
    console.log('width', comment.clientWidth, comment.scrollWidth, comment.scrollWidth > comment.clientWidth)
    if (comment.scrollWidth > comment.clientWidth || comment.scrollHeight > comment.clientHeight) {
      this.setState({
        collapsible: true
      })
    }
  }

  renderCommentText = comment => {
    try {
      if (this.props.persistLineBreaks) {
        //const textWithBreaks = String(comment.text).split("").map(char => char.match(regexp) ? <br /> : char);
        //const textWithBreaks = String(comment.text).split(/\n/g).reduce((acc, text, i, arr) => [...acc, String(text).trim().length === 0 && i === arr.length-1 ? null : <pre ref={instance => this._textLines.push(instance)} key={text}>{text}{i < arr.length-1 ? <br/> : null}</pre>], [])
        return <pre ref={instance => this._textLines.push(instance)} key={comment.text}>{comment.text}</pre>;
      }
      else {
        return comment.text;
      }
    } catch (error) {
      console.log(error);
    }
  }

  toggle = (ev, val) => {
    this.setState(state => ({collapsed: !state.collapsed}));

  }

  render() {
    const {comment} = this.props;
    const {collapsible, collapsed} = this.state;
    const fullName = comment?.addedBy?.name ? `${comment?.addedBy?.name} ${ comment?.addedBy?.surname}` : 'Unknown'
    return (
      <div className={s.comment}>
        <div className={s.image_wrapper}>
          {
            comment?.addedBy?.avatar
              ? <img className={s.avatar} src={comment?.addedBy?.avatar} alt=""/>
              : <div className={s.avatar_mock}>{comment?.avatarName}</div>
          }
        </div>
        <div className={s.comment_right}>
          <div className={s.comment_top}>
            <h6 className={s.comment_author}>{fullName} </h6>
            <span className={s.comment_date}>{comment.createdAt ? dateTime(comment.createdAt) : '---'}</span>
          </div>
          <p className={s.comment_company}>
            { comment?.addedBy?.company?.name || '---' }
          </p>
          <div className={s.comment_text}>
            <p ref="comment" className={cx(collapsible && s.collapsible_text, s.text, !collapsed && s.full_text)}>
              {this.renderCommentText(comment)}
            </p>
            {collapsible
              ? <IconButton className={s.collapse} style={{transform: !collapsed ? `rotate(0deg)` : 'rotate(180deg)'}} onClick={this.toggle}><ArrowUp color="#B2B2B2" style={{width: 24, height: 24}} /></IconButton>
              : null}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(s)(Comment);
