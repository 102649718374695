import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

const _ApproveIcon = (props) => (
  <span {...props}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_36572_14723" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_36572_14723)">
        <rect x="-2" y="-2" width="24" height="24" fill="#75C422"/>
        <path d="M9 15.0001L4 10.0001L5.41 8.59008L9 12.1701L16.59 4.58008L18 6.00008L9 15.0001Z" fill="white"/>
      </g>
    </svg>
  </span>
);

const ApproveIcon = pure(_ApproveIcon);
ApproveIcon.displayName = 'ApproveIcon';
ApproveIcon.muiName = 'SvgIcon';

export default ApproveIcon;
