import type {Cargo} from "../types";
import {or0} from "../utils";

export default function calculateCargoTotals(cargoList: Cargo[]): {
  totalAddress: number,
  totalBrokerage: number,
  totalFrt: number,
  totalLiner: number,
  totalRevenue: number,
  tw: number,
  tv: number
} {
  let totalAddress = 0;
  let totalBrokerage = 0;
  let totalFrt = 0;
  let totalLiner = 0;
  let totalRevenue = 0;
  let totalRevenueGross = 0;
  let totalFreightTons = 0;
  let tw = 0;
  let tv = 0;
  let averageFreightComm = 0
  for (let i = 0; i < cargoList.length; i++) {
    const cargo = cargoList[i];
    totalAddress += (or0(cargo.revenue) * or0(cargo.addComm) / 100);
    totalBrokerage += (or0(cargo.revenue) * or0(cargo.brokComm) / 100);
    totalFrt += (or0(cargo.revenue) * or0(cargo.frtTax) / 100);
    totalLiner += or0(cargo.linerTerms);
    totalRevenue += or0(cargo.revenue);
    totalRevenueGross += or0(cargo.frt) * or0(cargo.grossFreight);
    totalFreightTons += or0(cargo.frt);
    averageFreightComm += or0(cargo.frt) * (or0(cargo.addComm) + or0(cargo.brokComm) + or0(cargo.frtTax));
    if (cargo.unit === 'MT') {
      tw += or0(cargo.quantity);
    } else if (cargo.unit === 'CBM') {
      tv += or0(cargo.quantity);
    }
  }
  if (totalFreightTons) {
    averageFreightComm = averageFreightComm / totalFreightTons;
  }

  return {
    totalAddress,
    totalBrokerage,
    totalFrt,
    totalLiner,
    totalRevenue,
    tw,
    tv,
    totalRevenueGross,
    totalFreightTons,
    averageFreightComm
  };
}
