import { replaceInArray } from '../../core/utils';

/** @name MONITOR_SEND_OFFER_REDUCER */
export default function MONITOR_SEND_OFFER(state, { payload, res }) {
  const newState = { loading: false };
  if (res.status !== 200) {
    return state;
  }
  const freight = payload.changedRequest.freight;
  const index = state.cargoList.data.findIndex(c => c._id === payload.cargoRequest);
  if (index === -1) {
    return state;
  }
  const cargo = { ...state.cargoList.data[index] };
  if (!cargo.status.value || cargo.status.value > freight.value) {
    cargo.status = { ...cargo.status };
    cargo.status.value = freight.value;
    cargo.status.term = freight.term;
    cargo.status.end = Date.now() + (1000 * 60 * 60 * 24);
    const cargoList = { ...state.cargoList };
    cargoList.data = replaceInArray(cargoList.data, index, cargo);
    newState.cargoList = cargoList;
  }
  return { ...state, ...newState };
}
