import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import MenuItem from 'material-ui/MenuItem';
import s from './TcOffer.scss';
import FormsyText from '../../Common/FormsyText';
import FormsySelect from '../../Common/Select';
import RemoveButton from '../../Common/RemoveButton';
import AddButton from '../../Common/AddButton';
import { replaceInArray } from '../../../core/utils';
import Diff from '../../Common/Diff';
import CustomCheckbox from '../../Common/CustomCheckbox';
import CheckBox from '../../Common/CheckBox';
import { formatNumberOrEmpty } from '../../../core/format';

class Bunkers extends PureComponent {
  static propTypes = {
    bunkers: PropTypes.array,
    previousValues: PropTypes.array,
    disabled: PropTypes.bool,
    preview: PropTypes.bool,
    namePrefix: PropTypes.string,
  };
  static defaultProps = {
    bunkers: [],
    previousValues: [],
    disabled: false,
    namePrefix: 'bunkers',
  };

  constructor(props) {
    super(props);
    this.state = { bunkers: this.props.bunkers };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.bunkers !== this.props.bunkers && this.props.preview) {
      this.setState({ bunkers: nextProps.bunkers });
    }
  }


  handleAddBunker = () => {
    this.setState({ bunkers: [...this.state.bunkers, { checked: true }] });
  };

  handleRemoveBunker = (index, _id) => {
    if (_id) {
      this.setState({
        bunkers: replaceInArray(this.state.bunkers, index, {
          ...this.state.bunkers[index],
          checked: false,
        }),
      });
    } else {
      this.setState({
        bunkers: replaceInArray(this.state.bunkers, index, null),
      });
    }
  };

  handleRestoreBunker = (index) => {
    this.setState({
      bunkers: replaceInArray(this.state.bunkers, index, {
        ...this.state.bunkers[index],
        checked: true,
      }),
    });
  };

  render() {
    return (<span>
      <div className={this.props.preview ? s.preview_bunkers : ''}>
        {this.state.bunkers.map((bunker, index) => {
          if (!bunker) {
            return null;
          }
          return (<Bunker
            bunker={bunker}
            previousValues={this.props.previousValues}
            index={index}
            handleRemoveBunker={this.handleRemoveBunker}
            handleRestoreBunker={this.handleRestoreBunker}
            key={bunker._id || index}
            disabled={this.props.disabled}
            preview={this.props.preview}
            namePrefix={this.props.namePrefix}
          />);
        })}
      </div>
      <div className={s.bunkers_footer}>
        {this.props.disabled ? null : <AddButton onClick={this.handleAddBunker} label="Add bunker" />}
      </div>
    </span>);
  }
}


class Bunker extends PureComponent {
  static propTypes = {
    bunker: PropTypes.object,
    previousValues: PropTypes.array,
    index: PropTypes.number,
    handleRemoveBunker: PropTypes.func,
    handleRestoreBunker: PropTypes.func,
    disabled: PropTypes.bool,
    preview: PropTypes.bool,
    namePrefix: PropTypes.string,
  };

  static defaultProps= {
    namePrefix: 'bunkers',
  };

  state = { previousValue: {} };
  constructor(props) {
    super(props);
    if (props.bunker && props.previousValues) {
      this.state.previousValue = props.previousValues.find(b => b._id === props.bunker._id) || {};
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (((this.props.previousValues !== nextProps.previousValues)
        || this.props.bunker !== nextProps.bunker)
      && this.props.preview) {
      this.setState({
        bunker: nextProps.bunker,
        previousValue: nextProps.previousValues.find(b => b._id === nextProps.bunker._id) || {},
      });
    }
  }


  handleRemove = () => {
    this.props.handleRemoveBunker(this.props.index, this.props.bunker._id);
  };

  handleRestore = () => {
    this.props.handleRestoreBunker(this.props.index);
  };


  render() {
    const removed = !!(!this.props.bunker.checked && this.props.bunker._id);
    if (this.props.preview) {
      return (
        <PreviewBunker
          bunker={this.props.bunker}
          previousValue={this.state.previousValue}
          removed={removed}
        />
      );
    }
    return (<div className={cx(s.bunker, 'row', removed ? s.removed : '')}>
      <div className="col-3">
        <FormsySelect
          requiredError="required"
          floatingLabelText="Type of fuel"
          hintText=" "
          name={`${this.props.namePrefix}[${this.props.index}][typeOfFuel]`}
          autoWidth
          fullWidth
          required
          defaultValue={'IFO 120'}
          previousValue={this.state.previousValue.typeOfFuel}
          disabled={this.props.disabled || removed}
        >
          <MenuItem key="IFO 380" value="IFO 380" primaryText="IFO 380" />
          <MenuItem key="IFO 180" value="IFO 180" primaryText="IFO 180" />
          <MenuItem key="IFO 120" value="IFO 120" primaryText="IFO 120" />
          <MenuItem key="IFO 80" value="IFO 80" primaryText="IFO 80" />
          <MenuItem key="IFO 60" value="IFO 60" primaryText="IFO 60" />
          <MenuItem key="MDO" value="MDO" primaryText="MDO" />
          <MenuItem key="LSMDO" value="LSMDO" primaryText="LSMDO" />
          <MenuItem key="LSMGO" value="LSMGO" primaryText="LSMGO" />
        </FormsySelect>
      </div>
      <div className="col-4">
        <FormsyText
          floatingLabelText="Quantity, mt"
          validations="isNumeric,gt0,max:10000"
          validationErrors={{
            isNumeric: 'only numbers',
            gt0: 'must be greater than 0',
            max: 'max 10000',
          }}
          requiredError="required"
          name={`${this.props.namePrefix}[${this.props.index}][quantity]`}
          required
          fullWidth
          previousValue={this.state.previousValue.quantity}
          disabled={removed}
        />
      </div>
      <div className="col-4">
        <FormsyText
          floatingLabelText="Price per mt, USD"
          validations="isNumeric,gt0"
          validationErrors={{
            isNumeric: 'only numbers',
            gt0: 'must be greater than 0',
          }}
          requiredError="required"
          name={`${this.props.namePrefix}[${this.props.index}][price]`}
          required
          fullWidth
          previousValue={this.state.previousValue.price}
          disabled={removed}
        />
        <FormsyText
          name={`${this.props.namePrefix}[${this.props.index}][_id]`}
          style={{ display: 'none' }}
        />
        <CustomCheckbox
          as={CheckBox}
          name={`${this.props.namePrefix}[${this.props.index}][checked]`}
          style={{ display: 'none' }}
          value={this.props.bunker.checked}
        />
      </div>
      <div className={cx('col-1', 'align-items-center')}>
        {removed ? null
          : <RemoveButton className={s.remove_button} onClick={this.handleRemove} /> }
        {removed ?
          <AddButton style={{ marginLeft: '-2px' }} className={s.remove_button} onClick={this.handleRestore} />
          : null}
      </div>
    </div>);
  }
}

function PreviewBunker({ bunker, previousValue, removed }) {
  return (
    <div className={cx(s.bunker, removed ? s.removed : '', !previousValue.typeOfFuel ? s.new : '')}>
      <span className="w-60"><Diff newValue={bunker.typeOfFuel} oldValue={previousValue.typeOfFuel} /></span>
      <span className="w-60"><Diff newValue={formatNumberOrEmpty(bunker.quantity)} oldValue={formatNumberOrEmpty(previousValue.quantity)} /> mt</span>
      <span><Diff newValue={formatNumberOrEmpty(bunker.price)} oldValue={formatNumberOrEmpty(previousValue.price)} /> USD per mt</span>
    </div>
  );
}

export default withStyles(s)(Bunkers);
