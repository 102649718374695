import history from './history';

const defaultErrorMessages = {
  401: 'Unauthorized',
  404: 'Not found',
  500: 'Something went wrong',
  default: 'Something went wrong',
  undefined: 'Something went wrong',
};


const requestCounters = {};

export default function promiseMiddleware({ dispatch, getState }) {
  return next => (action) => {
    const {
      promise,
      type,
      redirect,
      afterQueue,
      successMessage,
      silent,
      messageType = 'MESSAGE',
      forceSuccessMessage,
      afterFail,
      takeLatest = false,
      ...rest
    } = action;
    let errorMessage = rest.errorMessage;
    if (!promise) return next(action);

    const SUCCESS = type;
    const REQUEST = type + '_REQUEST';
    const FAILURE = type + '_FAILED';
    requestCounters[REQUEST] = requestCounters[REQUEST] || 0;
    requestCounters[REQUEST]++;
    const currentRequestNumber = requestCounters[REQUEST];
    next({ ...rest, type: REQUEST });
    return promise
      .then((res) => {
        if(takeLatest && requestCounters[REQUEST] > currentRequestNumber) {
          return;
        }
        if (afterQueue && afterQueue.length) {
          afterQueue.forEach((fn) => {
            fn(res)(dispatch, getState);
          });
        }
        next({ ...rest, res, type: SUCCESS });
        if (redirect) {
          /* next({type:'NAVIGATE',payload:redirect})*/
          history.push(redirect.route);
        }
        if (successMessage) {
          next({ type: messageType, payload: successMessage });
        } else if (forceSuccessMessage && res.data && res.data.message) {
          next({ type: messageType, payload: { message: res.data.message } });
        }
        return res;
      })
      .catch((error) => {
        if(takeLatest && requestCounters[REQUEST] > currentRequestNumber) {
          return;
        }
        /*if(typeof window !=='undefined' && typeof window.Raven!=='undefined'){
          window.Raven.captureException(new Error(JSON.stringify(error.message)));
        }*/
        const message = {
          level: 'error',
          message: '',
        };
        console.error(error);
        next({ ...rest, error, type: FAILURE });
        if (afterFail && afterFail.length) {
          afterFail.forEach((fn) => {
            fn(error)(dispatch, getState);
          });
        }
        if (error.status === 401 && typeof window !== 'undefined') {
          return history.replace('/login');
        }
        if (error.status === 402) {
          return next({ type: 'LOGIN_TOGGLE_SUBSCRIBE_DIALOG', payload: true });
        }
        if (silent || error.status === 403) {
          return error;
        }
        if (
          !errorMessage &&
          error.status < 500 &&
          error.message &&
          error.message.errors &&
          error.message.errors[0] &&
          error.message.errors[0].messages &&
          error.message.errors[0].messages[0] &&
          typeof error.message.errors[0].messages[0] === 'string'
        ) {
          errorMessage = error.message.errors[0].messages[0];
        }
        if (
          !errorMessage &&
          error.status < 500 &&
          error.errors &&
          error.errors[0] &&
          error.errors[0].messages &&
          error.errors[0].messages[0] &&
          typeof error.errors[0].messages[0] === 'string'
        ) {
          errorMessage = error.errors[0].messages[0];
        }
        if (
          !errorMessage &&
          error.status < 500 &&
          error?.message?.errors?.[0]?.message
        ) {
          errorMessage = error.message.errors[0].message;
        }
        if (!errorMessage && typeof error?.message?.response?.message === "string") {
          errorMessage = error?.message?.response?.message;
        }
        if (!errorMessage && error?.message?.response?.message?.[0]?.constraints) {
          errorMessage = error.message.response.message.map(m => Object.values(m.constraints).join(', ')).join(', ');
        }
        if (errorMessage) {
          if (typeof errorMessage === 'string') {
            message.message = errorMessage;
            next({ type: messageType, payload: message });
          } else {
            message.message =
              errorMessage[error.status] ||
              errorMessage.default ||
              defaultErrorMessages[error.status] ||
              defaultErrorMessages.default;
            message.action = errorMessage.action;
            next({ type: messageType, payload: message });
          }
        } else {
          message.message =
            defaultErrorMessages[error.status] ||
            defaultErrorMessages.default;
          next({ type: messageType, payload: message });
        }
        // Another benefit is being able to log all failures here
        return error;
      });
  };
}
