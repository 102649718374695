import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

let Twitter = props => (
  <span {...props}>
    <svg width="45px" height="44px" viewBox="0 0 45 44" version="1.1">

        <title>Twitter</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Web---Start"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Shipnext-1440" transform="translate(-1094.000000, -1754.000000)" fill="#FFFFFF">
            <g id="Group-5" transform="translate(951.000000, 1754.000000)">
                <g id="Group-3" transform="translate(87.000000, 0.000000)">
                    <g id="Icon/Social/Insta" transform="translate(56.137931, 0.000000)">
                        <circle id="Oval-4-Copy" opacity="0.33350317" cx="22" cy="22" r="22"></circle>
                        <path d="M31.0738916,17.4568995 C30.4327849,17.7158324 29.7448867,17.8914378 29.0221225,17.9698363 C29.7601139,17.5670258 30.3250278,16.9281118 30.5927699,16.1691355 C29.900517,16.5421639 29.1363975,16.8130305 28.3222133,16.9598655 C27.6702342,16.3259066 26.7428566,15.9310345 25.7142397,15.9310345 C23.740855,15.9310345 22.1408064,17.3894488 22.1408064,19.1871661 C22.1408064,19.4421298 22.1723708,19.6911527 22.2333364,19.9292797 C19.2640092,19.7933405 16.6310175,18.4966523 14.8687923,16.5262871 C14.5607481,17.0064843 14.3855075,17.566014 14.3855075,18.1632901 C14.3855075,19.2933232 15.0168232,20.2904005 15.9746836,20.8737454 C15.3890778,20.8558969 14.8383095,20.7090619 14.3561347,20.4649941 L14.3561347,20.505672 C14.3561347,22.0831368 15.5882834,23.3996711 17.2220595,23.699308 C16.9227246,23.7727255 16.6070809,23.8134035 16.2805363,23.8134035 C16.0497948,23.8134035 15.8266527,23.7925716 15.6078653,23.7528794 C16.0628304,25.047596 17.3820729,25.9891043 18.9451208,26.0149171 C17.7227631,26.8879889 16.1814885,27.4068665 14.5074387,27.4068665 C14.2190048,27.4068665 13.934897,27.3909896 13.6551724,27.3622193 C15.2367207,28.2878767 17.1143308,28.8275862 19.1323439,28.8275862 C25.7055873,28.8275862 29.2986309,23.8650033 29.2986309,19.5612063 L29.2866484,19.1395615 C29.9886923,18.6831536 30.596043,18.1097187 31.0738916,17.4568995 Z" id="Symbol/Twitter" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
        </g>
      </svg>
  </span>
);

Twitter = pure(Twitter);
Twitter.displayName = 'Twitter';
Twitter.muiName = 'SvgIcon';

export default Twitter;
