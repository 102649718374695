import PropTypes from 'prop-types';
import React, { Component } from "react";
import Highlight from "./../../Monitor/Highlight.js";
import s from "./CustomAutocomplete.scss";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BasicCard from "./BasicCard";
import BasicAutocompleteList from "./BasicAutocompleteList";
import { withPopover } from "./../../../core/HOC";
import NarrowFormsyText from "./../../NewInputs/NarrowFormsyText";
import { CardTitle } from "material-ui/Card";
import Tags from './../../../core/api/Tags'

import { debounceWithoutFirstCall as debounce } from './../../../core/utils';
import BasicItem from './BasicItem.js';

export class TagsAutocomplete extends Component {
  static defaultProps = {
    placeholder: "Enter tag",
    errorText: "",
    invalid: false,
    forceReset: false,
    autocompleteProps: {
      apiMethodName: 'getAllTags'
    }
  };

  static contextTypes = {
    showMessage: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      isLoading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.forceReset !== this.props.forceReset) {
      this.reset();
    }
    if (nextProps.value !== this.props.value) {
      this.setState({ val: nextProps.value });
    }
  }


  cleanInput = val => String(val).trim().replace(/\s\s+/g, " ")

  updateSearch = (ev, val) => {

    const trimmed = this.cleanInput(val);
    const valueWithoutHashtag = trimmed[0] === "#" ? trimmed.slice(1) : trimmed;

    if (trimmed.length >= 2) {
      this.setState({
        isLoading: true,
        val: valueWithoutHashtag
      }, () => this.props.showSelect());
      this.loadTags();
    }
    else {
      this.props.closeSelect();
    }
    this.props.handleChange && this.props.handleChange(valueWithoutHashtag);
  };

  mapValue = (value) => this.cleanInput(typeof value === 'string' ? value : value?.value || "")

  loadTags = debounce(async () => {
    const val = this.mapValue(this.props.value ?? this.state.val);
    try {
      const res = await Tags[this.props.autocompleteProps.apiMethodName || this.constructor.defaultProps.autocompleteProps.apiMethodName]({
        entity: this.props.entity,
        q: encodeURIComponent(val)
      });
      if (res.data) {
        this.setState({
          tags: res.data
        });
      }
    } catch (error) {
      console.error(error);
      this.context.showMessage({
        level: "error",
        _id: new Date().getMilliseconds(),
        autoDismiss: 5,
        message: `Error fetching tags for query ${val}: ${error?.message || error}`
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  }, 400);

  reset = () => {
    this.props.closeSelect();
    this.setState({
      tags: [],
      isLoading: false
    })
  }

  showSuggestions = () => {
    const val = this.mapValue(this.props.value);
    if (val.length >= 2) {
      this.props.showSelect();
    }
  };

  handleClick = (ev, item) => {
    if (this.props.handleSelect) {
      this.props.handleSelect(item);
      this.props.closeSelect();
      if (this.props.value == null) {
        this.setState({ val: "" });
      }
    }
  }

  /* componentDidUpdate() {
    if (this.state.search.length >= 2 && !this.state.tags.length && !this.state.isLoading) {
      this.props.showSelect();
      this.setState({
        isLoading: true
      });
      this.loadTags();
    }
  } */

  focusCard = ev => {
    ev.preventDefault();

    if (this.select) {
      this.select.focus();
    }
  }

  render() {
    const { placeholder, open, setAnchorEl, className = "", value, autocompleteProps = {}, disabled } = this.props;
    const { tags, isLoading } = this.state;
    const val = this.mapValue(value ?? this.state.val);
    const { apiMethodName, ...restAutocompleteProps } = autocompleteProps;
    return (
      <div ref={setAnchorEl} className={className} style={{ zIndex: 2, position: "relative" }} {...restAutocompleteProps}>
        <NarrowFormsyText
          ref={node => (this.input = node)}
          floatingLabelText={placeholder}
          name={"tag"}
          value={val.length ? val.includes("#") ? val : `#${val}` : ""}
          onFocus={this.showSuggestions}
          onChange={this.updateSearch}
          fullWidth
          tabIndex="1"
          classes={s.tag_autocomplete_input}
          errorText={this.props.errorText}
          invalid={this.props.invalid}
          onBlur={this.focusCard}
          autoComplete="off"
          onKeyDown={ev => {
            if (ev.key === "ArrowDown") {
              this.focusCard(ev);
            }
          }}
          disabled={disabled}
        />
        <BasicCard
          open={open}
          adjustRef={node => (this.select = node)}
          handleClick={this.persistSelect}
          className={s.tags_card}
          showLoader={isLoading}
        >
          <div>
            <BasicAutocompleteList
              fallback={ isLoading ? null :
                <CardTitle
                  style={{ padding: "12px" }}
                  subtitle={"No tags found"}
                />
              }
              list={tags}
              renderItem={listItem => (
                <TagItem
                  handleClick={this.handleClick}
                  keyword={val}
                  item={listItem}
                  currentItem={this.props.value}
                ></TagItem>
              )}
            />
          </div>
        </BasicCard>
      </div>
    );
  }
}

export function TagItem({ item, handleClick, ...rest }) {
  const { keyword, currentItem } = rest;
  const regExp = new RegExp(
    keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
    "i"
  );
  const name = `#${item.value || item.label}`;
  return (
    <BasicItem
      item={item}
      handleClick={handleClick}
      {...rest}
      className={cx(item === currentItem && s.active)}
    >
        <div className={s.flex}>
            <span className={s.tag_name} style={{ marginLeft: "5px" }}>
              <Highlight search={regExp} children={name} />
            </span>
        </div>
        <div className={s.item_info}>
            <span className={s.category}>
              <Highlight search={regExp} children={item.category?.value || item.categoryName} />
            </span>
        </div>
    </BasicItem>
  )
}

// export class TagItem extends React.Component {

//   static defaultProps = {
//     adjustRef: () => { }
//   }

//   determineKey = ev => {
//     const key = ev.keyCode || ev.which;

//     if (key === 13) {
//       this.props.handleClick(this.props.item);
//     }
//   }


//   render() {
//     const { keyword, item, handleClick, currentItem } = this.props;
//     const regExp = new RegExp(
//       keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
//       "i"
//     );
//     const name = `#${item.value}`
//     return (
//       <li
//         key={item._id}
//         onKeyDown={({ keyCode, which }) =>
//           keyCode === 13 || which === 13 ? handleClick(item) : null
//         }
//         onClick={(ev) => {
//           handleClick(item);
//         }}
//         onKeyPress={this.determineKey}
//         tabIndex="2"
//         ref={this.props.adjustRef}
//         className={cx(s.item_wrapper, s.tag_item, item === currentItem && s.active)}
//       >
//         <div className={s.flex}>
//             <span className={s.tag_name} style={{ marginLeft: "5px" }}>
//               <Highlight search={regExp} children={name}></Highlight>
//             </span>
//         </div>
//         <div className={s.item_info}>
//           <span className={s.category}>
//             <Highlight search={regExp} children={item.category?.value}></Highlight>
//           </span>
//         </div>
//       </li>
//     );
//   }
// }

export default withStyles(s)(withPopover(TagsAutocomplete));
