import React, { Component } from 'react'

export class AbstractFormHandler extends Component {

  static getPropsValues = props => props.freight;

  change = name => (ev, val) => {
    const { onChange } = this.props;
    const { getPropsValues = props => ({}) } = this.constructor;

    const values = getPropsValues(this.props);
    const value = val ?? ev?.target?.value ?? ev;

    if (value === values[name]) return;

    const copy = {
      ...values,
      [name]: value,
    };
    return onChange(copy);
  }

  render() {
    return <div />;
  }
}

export default AbstractFormHandler;
