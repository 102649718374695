import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { convertDatesFromUTC, User } from '../core/api/api';
import { refreshCargoRequest, getRequestList, getMyCargoDetails } from '../actions/cargo';
import { getVesselRequestList,getVesselTcOfferList, getVesselsList, getSnpList } from '../actions/vessel';
import { getFixedContracts, getVoyageContractById, updateOnlyContractPDF } from '../actions/contracts';
import { getCounters, getProfile } from '../actions/login';
import { getCargoList } from '../actions/monitor';
import VesselApi from '../core/api/Vessel';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { isUserSubscribedToMarketplace } from './../core/utils';

import { handlers as chatHandlers, mappers as chatMappers } from '../components/Chat';
import CargoApi from '../core/api/Cargo';
import { addTopMessage, removeTopMessage } from '../actions/runtime';
import { EMAILS_LIST_CHANGES } from '../constants/emails';

const EVENTS = {
  'deck:tc-order': 'MONITOR_ORDER_NEW',
  'monitor:cargo': 'MONITOR_CARGO_NEW',
  'monitor:vessel': 'MONITOR_VESSEL_NEW',
  'monitor:cargoRemove': 'MONITOR_CARGO_REMOVE',
  //'monitor:updateCargo': 'MONITOR_CARGO_UPDATE',
  bid: 'MONITOR_BID_NEW',
  'monitor:updateTCRate': 'MONITOR_UPDATE_TC',
  connected: 'MONITOR_SOCKET_CONNECTED',
  TCOffer: 'VESSEL_TC_NEW',
  voyageSubject: 'CONTRACT_VOYAGE_SUBJECT_UPDATED',
  timeSubject: 'CONTRACT_TC_SUBJECT_UPDATED',
  freight: 'VESSEL_NEW_FREIGHT_REQUEST',
  'monitor:updateVessel': 'MONITOR_UPDATE_VESSEL',
  'monitor:updateVesselLocation': 'MONITOR_UPDATE_VESSEL_LOCATION',
  'bl:genericUpdate': 'BL_GENERIC_UPDATE',
  'myFleet:refreshList': 'myFleet:refreshList',
  'monitor:cargoPatch': 'MONITOR_CARGO_PATCH',
  'bid-update':'BID_UPDATE',
  'freight-update':'BID_UPDATE',
  'user:updateProfile':'user:updateProfile',
  'forSale:newItem': 'FOR_SALE_NEW_ITEM',
  'forSale:itemRemoved': 'FOR_SALE_ITEM_REMOVED',
  'forSale:itemPriceUpdated': 'FOR_SALE_ITEM_PRICE_UPDATED',
  "positionStatusChanged": "CHANGE_POSITION_STATUS",
  "vesselGroupsChanged": "CHANGE_VESSEL_STATUS",
  'offlineContracts:contractIsUpdated': "UPDATE_OFFLINE_CONTRACT_DETAILS",
  'mailer:list': EMAILS_LIST_CHANGES,
  'mailer:thread': 'mailer:thread',
  'invoice:change':'invoice:change',
  'invoice:item':'invoice:item',
  'department:kyc:status': 'KYC_STATUS_UPDATED',
  'invoice:invited':'INVOICE_INVITED',
  'invoice:freight-change':'INVOICE_UPDATED',
  'connected': 'connected',
  ...chatMappers,
};

const HANDLERS = {
  /**
   * @param {bidType} data
   * @param user
   * @param {function} dispatch
   */
  /* POSITION_CHANGED_CHECK_BLUR: async (data, user, dispatch, store) => {
    const isSubscribed = isUserSubscribedToMarketplace(user);
    if (!isSubscribed) return;
    dispatch({
      type: "CHANGE_POSITION_STATUS",
      payload: data,
      currentUser: user
    });
    PubSub.publish("CHANGE_POSITION_STATUS", data);
  },
  VESSEL_STATUS_CHANGED_CHECK_BLUR: async (data, user, dispatch, store) => {
    const isSubscribed = isUserSubscribedToMarketplace(user);
    if (!isSubscribed) return;
    dispatch({
      type: "CHANGE_VESSEL_STATUS",
      payload: data,
      currentUser: user
    });
    PubSub.publish("CHANGE_VESSEL_STATUS", data);
  }, */
  UPDATE_OFFLINE_CONTRACT_DETAILS: async (data, user, dispatch, store) => {
    const { _id } = user;
    const { contractId, sender: { _id: senderId } = {}, contractFile: contractLinkToPDF } = data;
    const { selectedContract } = store.getState().contracts;

    if (selectedContract?._id !== contractId) return;

    if (_id === senderId) {
      return dispatch(updateOnlyContractPDF(contractId, contractLinkToPDF));
    }
    dispatch({
      type: "AWAIT_PDF_UPDATE",
      payload: false,
    });
    return dispatch(getVoyageContractById(contractId, "voyage"));
  },
  MONITOR_BID_NEW: async (data, user, dispatch, store) => {
    if (data.status && data.status.name === 'fixed') {
      dispatch(getFixedContracts());
    }
    dispatch(getCounters());
  },
  // CHANGE OBJECT TO CONTAIN ID AND VESSEL FIELD
  MONITOR_UPDATE_VESSEL_LOCATION: async (data, user, dispatch, store) => {
    return {
      _id: data._id,
      vessel: {
        hasKnownLocationSince: data.hasKnownLocationSince
      }
    }
  },
  BID_UPDATE: async (data, user, dispatch, store) => {
    dispatch(getCounters());
    if(window.location.pathname.indexOf("main-deck/general") !== -1) {
      const state = store.getState();
      try {
        const newVesselData = await getNewMonitorVessel(state, data.vesselRequest);
        if (newVesselData) {
          store.dispatch({
            type: 'MONITOR_UPDATE_VESSEL',
            payload: newVesselData,
            currentUser: user,
          });
        }

      } catch (e) {
        console.error(e);
      }
      try {
        const newCargoData = await CargoApi.getNewMonitorCargo(data.cargoRequest);
        if (newCargoData) {
          store.dispatch({
            type: 'MONITOR_CARGO_UPDATE',
            payload: newCargoData,
            currentUser: user,
            options: { upsert: true },
          });
        }else {
          store.dispatch({
            type: 'MONITOR_CARGO_REMOVE',
            payload: data.cargoRequest,
            currentUser: user,
          });
        }

      } catch (e) {
        console.error(e);
      }

    }
  },
  VESSEL_TC_NEW: (data, user, dispatch) => {
    //dispatch(getVesselTcOfferList({}));
    dispatch(getCounters());
    if (data.status === 'fix') {
      dispatch(getFixedContracts());
    }
  },
  CONTRACT_VOYAGE_SUBJECT_UPDATED: (data, user, dispatch) => {
    dispatch(getFixedContracts());
    dispatch(getCounters());
  },
  CONTRACT_TC_SUBJECT_UPDATED: (data, user, dispatch) => {
    dispatch(getFixedContracts());
    dispatch(getCounters());
  },
  VESSEL_NEW_FREIGHT_REQUEST: (data, user, dispatch) => {
    dispatch(getCounters());
  },
  'myFleet:refreshList': (data, user, dispatch) => {
    dispatch(getVesselsList({}));
  },
  ...chatHandlers,
  MONITOR_UPDATE_VESSEL: async (data, user, dispatch, store) => {
    if(window.location.pathname.indexOf("main-deck/general") === -1) {
      return 'IGNORE';
    }
    const state = store.getState();
    if (data.addedByUser) {
      if (state.monitor.cargoList.activeRequests && state.monitor.cargoList.activeRequests.vessel <= 0) {
        dispatch(getCargoList({ ...state.monitor.cargoList, page: 1 }));
      }
    }
    const newVesselData = await getNewMonitorVessel(state,data);
    if(!newVesselData) {
      return 'IGNORE';
    }
    return newVesselData;
  },
  'user:updateProfile':async (data, user, dispatch, store) => {
    store.dispatch(getProfile());
  },
  'connected': (data, user, dispatch, store, socket) => {
    socket.emit('messages:get');
    Socket.rooms.forEach((room) => socket.emit('subscribe', room));
  }
};

let newCargoCache = {};
let patchCargoCache = {};

class Socket extends Component {
  state = { connected: undefined };

  static rooms = [];

  static joinRoom(topic, opts = {}){
    this.rooms.push({ topic, ...opts });
    if (window.socket && window.socket.connected) {
      window.socket.emit('subscribe', { topic, ...opts });
    }
  }

  static leaveRoom(topic, opts = {} ) {
    const newRooms = [];
    for (let i = 0; i < this.rooms.length; i++) {
      const room = this.rooms[i];
      if(room.topic === topic) {
        if (window.socket) {
          window.socket.emit('unsubscribe', room);
        }
      } else {
        newRooms.push(room);
      }

    }

    this.rooms = newRooms;

  }

  componentDidMount() {
    this.connect(this.props);
    setTimeout(() => {
      if (this.state.connected === undefined) {
        this.setState({ connected: false });
      }
    }, 500);
    window.addEventListener('storage', this.storageListener);
    if (this.props.user) {
      this.checkUnpaidInvoices();
    }
    this.flushInterval = setInterval(async () => {
      let _newCargoes = [...Object.values(newCargoCache)];
      newCargoCache = {};
      let _patchedCargoes = [...Object.values(patchCargoCache)];
      patchCargoCache = {};
      if (_newCargoes.length) {
        this.context.store.dispatch({
          type: 'MONITOR_CARGO_NEW',
          payload: _newCargoes,
          currentUser: this.props.user,
        });
      }
      _newCargoes = undefined;
      if (_patchedCargoes.length) {
        this.context.store.dispatch({
          type: 'MONITOR_CARGO_PATCH',
          payload: _patchedCargoes,
          currentUser: this.props.user,
        });
        for (let i = 0; i < _patchedCargoes.length; i++) {
          PubSub.publish('MONITOR_CARGO_PATCH', _patchedCargoes[i]);
        }
      }
      _patchedCargoes = undefined;

    }, 5000);
  }
  componentWillUnmount() {
    this.disconnect();
    window.clearInterval(this.flushInterval);
  }
  connect(props) {
    if (this.socket) {
      this.disconnect();
    }
    if (typeof window !== 'undefined' && props.user) {
      this.socket = window.io.connect(props.socketUrl, {
        reconnection: true,
        reconnectionDelay: 500,
        reconnectionAttempts: Number.MAX_VALUE,
        transports: ['websocket', 'polling'],
      });
      this.socket.on('connect', this.onConnect);
      Object.keys(EVENTS).forEach((key) => {
        this.socket.on(key, this.onEvent.bind(this, EVENTS[key]));
      });
      this.socket.on('disconnect', this.onDisconnect);
      this.socket.on('reconnect', this.onReconnect);
      this.socket.on('messages:receive', this.onUnseen);
      window.socket = this.socket;
    }
  }
  disconnect() {
    if (this.socket) {
      Object.keys(EVENTS).forEach((key) => {
        this.socket.off(key);
      });
      this.socket.off('messages:receive');
      this.socket.disconnect();
      this.socket = null;
      window.socket = null;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.user) {
      this.disconnect();
      return;
    }
    if (
      nextProps.user &&
      (!this.props.user || this.props.user._id !== nextProps.user._id)
    ) {
      this.disconnect();
      this.connect(nextProps);
      this.checkUnpaidInvoices(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.user !== this.props.user || nextState.connected !== this.state.connected;
  }

  onConnect = () => {
    console.debug('socket connected');
    this.setState({ connected: true });
    this.connected = true;
  };

  onDisconnect = () => {
    console.debug('socket disconnected');
    this.setState({ connected: false });
  };
  onReconnect = () => {
    this.connected = false;
    this.setState({ connected: false });
    console.debug('socket reconnect');
  };

  onEvent = async (event, data = {}) => {
    console.log(event);
    console.debug(event, data);
    if (data.messageId) {
      this.socket.emit('message:seen', data.messageId);
    }
    if (event) {
      data = convertDatesFromUTC(data);
      switch (event) {
        case 'MONITOR_CARGO_NEW' :
          newCargoCache[data._id] = data;
          return;
        case 'MONITOR_CARGO_PATCH':
          if (patchCargoCache[data._id]) {
            Object.assign(patchCargoCache[data._id], data);
          } else {
            patchCargoCache[data._id] = data;
          }
          return;
      }
      if (HANDLERS[event]) {
       const newData = await HANDLERS[event](data, this.props.user, this.context.store.dispatch,this.context.store, this.socket);
       if(newData === 'IGNORE') {
         return;
       }
       if(newData) {
         data = newData;
       }
      }
      this.context.store.dispatch({
        type: event,
        payload: data,
        currentUser: this.props.user,
      });

      PubSub.publish(event,data);
    }
  };

  onUnseen = (messageList) => {
    messageList.forEach(({ _id, message, event }) => {
      this.onEvent(EVENTS[event], { ...message, messageId: _id });
    });
  };

  storageListener= (e) => {
    switch (e.key) {
      case 'logout-event':
        if (this.props.user) {
          window.location.reload();
        }
        break;
      case 'login-event':
        window.location.reload();
        break;
      default:
    }
  };

  checkUnpaidInvoices = (props) => {
    return;
    props = props || this.props;
    if(!props.user || !props.user.emailVerified) {
      return;
    }
    setTimeout(() => {
      User.getPendingInvoices().then(({data}) => {
        if(data?.invoices?.length) {
          const invoices = data.invoices.filter(i => i.status === 'open');
          const invoice = invoices[0];
          if(!invoice) {
            return;
          }
          this.context.store.dispatch(
            addTopMessage({id: 'unpaid_invoice', content:<div style={{width:'100%'}}>You have unpaid invoice. <a href={invoice.invoicePage}>Pay now</a></div>})
          )
        } else {
          this.context.store.dispatch(removeTopMessage({id: 'unpaid_invoice'}));
        }
      })
    }, 1000)

  }

  render() {
    return (<div>
      <div className={cx('connecting', this.state.connected === false && this.props.user && typeof window !== 'undefined' ? 'show' : '')}>
        Connecting to SHIPNEXT Network...
      </div>
    </div>);
  }
}

// eslint-disable-next-line react/no-unused-prop-types
Socket.propTypes = { socketUrl: PropTypes.string };
Socket.defaultProps = { socketUrl: 'http://localhost:9000/v1' };
Socket.contextTypes = {
  store: PropTypes.object.isRequired,
};

export default connect(state => state.login, {})(Socket);


async function getNewMonitorVessel(state,vessel){
 return (await VesselApi.getVesselList({
    filters:{...state.monitor.vesselList.filters, vesselId:vessel.vessel},
    sort:state.monitor.vesselList.sort,
    page:1,
    limit:5
  }))
    ?.data[0];
}

async function getNewMonitorCargo(state,cargo){
  return (await CargoApi.getCargoList({
    filters:{ search: cargo.refNo },
    sort:state.monitor.cargoList.sort,
    page:1,
    limit:5
  }))
    ?.data[0];
}

/** @typedef {Object} bidType
 * @property {String} _id
 * @property {Object} cargoRequest
 * @property {Number} comm
 * @property {Object} freight
 * @property {String} messageId
 * @property {Object} status
 * @property {Object} vesselRequest
 */
