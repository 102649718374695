import React from 'react'
import AbstractFormHandler from './AbstractFormHandler'
import NarrowSelect from '../../NewInputs/NarrowSelect';
import CurrencySelect from '../../Common/CurrencySelect';
import MenuItem from 'material-ui/MenuItem';

export const DAY = {
  label: 'per day',
  value: 'perDay',
};
const RATA = {
  label: 'per day pro rata',
  value: 'perDayProRata',
};
const HOUR = {
  label: 'per hour',
  value: 'perHour',
};

export const units = [
  DAY,
  RATA,
  HOUR,
];

const HALF = {
  label: 'Dispatch at half demurrage',
  value: 'halfDispatch',
};
const FULL = {
  label: 'Full dispatch',
  value: 'fullDispatch',
};
const FREE = {
  label: 'Free of dispatch',
  value: 'freeDispatch',
};
const EMPTY = {
  label: '',
  value: '',
};

export const terms = [
  EMPTY,
  HALF,
  FULL,
  FREE,
];

export class Opts extends AbstractFormHandler {

  static getPropsValues = props => props.opts;

  static defaultProps = { termsEnabled: true };

  render() {
    const { change } = this;
    const { namePrefix = 'options', opts = {}, className = '', ...rest } = this.props;

    const { currency, timeUnit = DAY.value, terms: termsValue } = opts;

    return (
      <div className={className} style={{ display: 'flex', flex: 1, }} {...rest}>
        <div className="col-3">
          <CurrencySelect
            style={{ width: '100%' }}
            as={NarrowSelect}
            name={`${namePrefix}[currency]`}
            value={currency || ""}
            hintText="Currency"
            fullWidth
            onChange={change("currency")}
          />
        </div>
        <div className="col-4">
          <NarrowSelect
            name={`${namePrefix}[timeUnit]`}
            value={timeUnit}
            hintText="Unit"
            fullWidth
            autoWidth
            onChange={change("timeUnit")}
          >
            {
              units.map(unit => <MenuItem value={unit.value} key={unit.value} primaryText={unit.label} />)
            }
          </NarrowSelect>
        </div>
        {this.props.termsEnabled ? (
          <div className="col-5">
            <NarrowSelect
              name={`${namePrefix}[terms]`}
              value={termsValue}
              hintText="Term"
              fullWidth
              autoWidth
              onChange={change("terms")}
            >
              {
                terms.map(term => <MenuItem value={term.value} key={term.value} primaryText={term.label} />)
              }
            </NarrowSelect>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Opts;
