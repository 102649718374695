import React from 'react'
import SecondText from '../../Monitor/RowBlocks/SecondText';
import cx from 'classnames';
import s from './../../Monitor/TableNew.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { ProgressLine } from '../../Common/ProgressVesselPosition';
function From({ routeObj, loadingStatus }) {

  const comingFrom = routeObj?.route?.from?.name || "---";

  const speed = routeObj?.lastPos?.speed ? routeObj.lastPos.speed + " kn" : "---";

  return (
    <div className={s.text}>
      <span title={comingFrom ? comingFrom : undefined} className={cx(s.bold_text, s.uppercased, s.departure_port)}>
        {
          comingFrom
        }
      </span>
      <span className={cx(s.progress_wrapper)}>
        <ProgressLine showPercent={false} onRoute={routeObj} loadingStatus={loadingStatus} />
      </span>
      <span className={s.row_block}>
        <SecondText>{speed}</SecondText>
      </span>
    </div>

  )
}

export default withStyles(s)(From);
