import Api from './api';




async function sleep(ms = 300) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  })
}
/**
 * @class
 */
export default class BL extends Api {

  static endpoint = '/bl';

  static ROLES = [
    {
      key: 'shipper',
      label: 'SHIPPER',
    },
    {
      key: 'receiver',
      label: 'RECEIVER',
    },
    {
      key: 'notify',
      label: 'NOTIFY',
    },
    {
      key: 'sa',
      label: 'SHIPPER`S AGENT',
    },
    {
      key: 'ca',
      label: 'CARRIER`S AGENT',
    },
    {
      key: 'captain',
      label: 'CAPTAIN',
    },
  ];
  static async get(_id, token) {
    return BL.fetch('/bl/' + _id, {
      method: 'GET',
      token,
    });
  }

  static async create(bl, token) {
    return BL.fetch('/bl/', {
      method: 'POST',
      body: JSON.stringify(bl),
    });
  }

  static async inviteParticipant(data, token) {
    if (data.currentParticipant && data.currentParticipant.status !== 'rejected' && data.currentParticipant.status !== 'removed') {
      const cp = data.currentParticipant;
      data.currentParticipant = undefined;
      await BL.fetch(`/bl/${data.blId}/disbandParticipant`, {
        method: 'PUT',
        body: JSON.stringify({ userId: cp.user._id, role: cp.role }),
      });
    }
    return BL.fetch(`/bl/${data.blId}/addParticipant`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  static async confirmParticipation(bl, role, token) {
    return BL.fetch(`/bl/${bl._id}/processRoleDecision`, {
      method: 'PUT',
      body: JSON.stringify({ roles: [role], decision: true }),
    });
  }

  static async rejectParticipation(bl, role, token) {
    return BL.fetch(`/bl/${bl._id}/processRoleDecision`, {
      method: 'PUT',
      body: JSON.stringify({ roles: [role], decision: false }),
    });
  }

  static async remindParticipant(data, token) {
    return BL.fetch(`/bl/${data.blId}/resendInviteNotification`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static async sign({ field, amendment, role, version, blId }, token) {
    return BL.fetch(`/bl/${blId}/sign`, {
      method: 'PUT',
      body: JSON.stringify({ field, version, amendment }),
    });
  }

  static async claim(data, token) {
    await sleep(1000);
    return { status: 200, data: { ...JSON.parse(JSON.stringify(data)), _id: Math.random() } };
  }

  static async findUserByEmail(email) {
    return BL.fetch('/users/byEmail?email=' + encodeURIComponent(email), {
      method: 'GET',
    });
  }

  static async getBlList({
                              filters = {},
                              sort = { field: 'addedAt', value: -1 },
                              page = 1,
                            }) {
    let uri = `/bl?page=${page}&limit=50&sort=${encodeURIComponent(JSON.stringify({ [sort.field]: sort.value }))}`;
    if (filters.query) {
      uri += '&q=' + encodeURIComponent(filters.query);
    }
    const filtersDate = {};
      if (filters.dateFrom) {
        filtersDate.dateFrom = filters.dateFrom;
      }
      if (filters.dateTo) {
        filtersDate.dateTo = filters.dateTo;
      }
      if (filtersDate.dateFrom || filtersDate.dateTo) {
        uri += '&filters=' + JSON.stringify(filtersDate);
      }

    return BL.fetch(uri, {
      method: 'GET',
    });
  }

  static async confirmParticipants(bl) {
    return BL.fetch(`/bl/${bl._id}/approveParticipants`, {
      method: 'PUT',
      body: JSON.stringify({ version: bl.status.participantsVersion }),
    });
  }

  static async changeField(data) {
    return BL.fetch(`/bl/${data.blId}/changeField`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  static async issue(data) {
    return BL.fetch(`/bl/${data.blId}/issue`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }
  static async entrust(data) {
    return BL.fetch(`/bl/${data.blId}/setEntrust`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  static async getCargoRequest(_id) {
    return BL.fetch(`/bl/${_id}/cargoRequest`);
  }
  static async remindApproval(data) {
    return BL.fetch(`/bl/${data.blId}/resendApproveRequest`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }
}
