import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import Form from '../About/Form';
import Link from '../../Link';
import RoundThemableButton from '../../Common/RoundThemableButton';
import TradingDesk from '../../Icons/TradingDesk';
import Exchange from '../../Icons/Exchange';
import FleetMonitor from '../../Icons/FleetMonitor';
import CargoMonitor from '../../Icons/CargoMonitor';
import Documents from '../../Icons/Documents';
import PortsIcon from '../../Icons/PortsIcon';

class PageSolutionsCargoMonitor extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_cargo_monitor_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Digitize and automate<br /> your Supply Chain</h1>
                </div>

                <div className={s.wrapper_icon}>
                  <CargoMonitor />
                  <p>SHIPNEXT Cargo Monitor</p>
                </div>
                <div className={s.wrapper_links}>
                  <a href="#how-it-works" className={s.wrapper_links_link}>How it works</a>
                  <a href="#use-case" className={s.wrapper_links_link}>Use Case</a>
                  {/* {!this.props.user && <RoundThemableButton className={s.wrapper_links_link_color} component={Link} to={'/register'}>Register</RoundThemableButton>} */}
                </div>
              </div>
              <div className={s.right_part}>
                <Form
                  label={'Request demo'}
                  requestType={'shipnextDemo'}
                />
                <button
                  onClick={this.props.handleChangeForm.bind(this, 'Request demo', 'shipnextDemo')}
                >
                  Request demo
                </button>
              </div>
            </div>

          </div>
        </div>

        <span className={s.anchor} id="how-it-works" />
        <div className={cx(s.how_it_works, s.how_it_works_cm)}>
          <div className={s.container}>
            <h2 className={s.title_block}>How it works</h2>
          </div>
          <div className={s.first_step}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>1</div>
                  <div className={s.right_part}>
                    <div className={s.wrapper_info}>
                      <div className={s.info_left}>
                        <h3>What’s a Cargo Monitor</h3>
                        <p className={s.description}>
                        Cargo Monitor allows better management of the company’s Freight negotiations, Freight tends, and quotations.
                        </p>
                        <p className={s.description}>
                          Carriers and Brokerage firms can use this tool to build a stronger collaboration in the team, and build a great insight into CRM-related analytics, if you are a trader or shipper, you can track your ongoing negotiations, collect and manage freight offers and tenders. SHIPNEXT algorithms may help structure data according to cargo type, geography or trade, and KPIs.
                        </p>
                      </div>

                      <div className={s.info_right}>
                        <div className={s.wrapper_image}>
                          <img src="/images/solutions/cm_step_1.png" alt="What’s a Cargo Monitor" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div className={cx(s.second_step, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>2</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Cargo Tracking and Execution</h3>
                      <p className={s.description}>
                      Once the cargo is booked, SHIPNEXT provides solutions for cargo tracking and supply chain management. Our users can use an easy and yet advanced solution to track cargo delivery, store daily communication and updates, and compare planned -vs- actual delivery.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/solutions/cm_step_2.png" alt="Cargo Tracking and Execution" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.third_step, s.divide_figure)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>3</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Important of Maritime transportation</h3>
                      <p className={s.description}>
                      Maritime transportation, being responsible for over 83% of international trade, is not only the most cost-efficient mode of transport but is also a limiting factor in door-to-door transportation. Planning maritime transportation comes before planning delivery dates to port and, thus, transportation by land. Therefore the management of real-time data and information flows, and the ability to process it helps efficient planning of inventory management and delivery schedules based on a combination of factors affecting transit time.
                      </p>
                    </div>
                    <div className={s.info_right}>
                      <div className={s.wrapper_image}>
                        <img src="/images/pages/diagram-solution4.png" alt="Important of Maritime transportation" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(s.fourth_step, s.container_solutions, s.divide_figure, s.bg_color)}>
            <div className={s.container}>
              <div className={s.divide_figure}>
                <div className={s.figure}>4</div>
                <div className={s.right_part}>
                  <div className={s.wrapper_info}>
                    <div className={s.info_left}>
                      <h3>Real-time Data and Cargo-tracking</h3>
                      <p className={s.description}>
                        Ship’s speed, schedule, position, arrival time, berthing, loading/discharge rates, free storage time at port, weather, congestion, distances and more, all have an impact on supply chain management.<br />
                        We help build interconnectivity and interoperability across the whole supply chain.
                      </p>
                      <p className={s.description}>
                        Having all this data processed in real-time, as well as providing tools for cargo tracing, is a key aim of SHIPNEXT in building a sophisticated transportation management system.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span className={s.anchor} id="use-case" />
        <div className={s.use_case}>
          <div className={s.container}>
            <h2 className={s.title_block}>Use Case</h2>
            <div className={s.wrapper_use_case}>
              <div className={s.wrapper_svg}>
                <CargoMonitor />
              </div>
              <div className={s.description_use_case}>
                <ul>
                  <li><h3>Carriers or Shipping Companies</h3> use the Cargo Monitor to follow all the given Quotations, Firm freight negotiations, send reminders, and build analytics around freight. One can also track the total amount of RFQ received, sent and attended/quoted, which can be used to manage the company activity as well as manage workloads.<br />
                  Cargo Monitor can also be used by the Carrier to enable their customers to track their cargo delivery and manage their supply chain.</li>
                  <li><h3>Shipbrokers</h3> use the Cargo Monitor to store, manage and track the freight requests, indications and offers and send reminders. Once the cargo is booked, Cargo Monitor can be used to track cargo delivery and enter data for personal records or interact with the Carrier.</li>
                  <li><h3>Traders and Shippers</h3> use the Cargo Monitor to manage their RFQs, Quotations and freight tenders. This works both ways, as Shipping managers inside a trading firm could also use this tool to pass on the received offer onto their Commercial or Trading department in the form of a freight offer. Once the cargo is contracted, the Trading firm or Shipper can track the cargo delivery, key-in and store information, thereby using the Cargo Delivery as a supply-chain management tool.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <span className={s.anchor} id="other-solutions" />
        <div className={s.other_solutions}>
          <div className={s.container}>
            <h2 className={s.title_block}>Other solutions</h2>
            <div className={s.links_other_solutions}>
              <Link className={s.part_link} to="/solution-shipnext-marketplace">
                <div>
                  <TradingDesk />
                  <h3>SHIPNEXT<br /> Trading Desk</h3>
                </div>
                <p>Ship Next Your Cargo</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-fleet-monitor'}>
                <div>
                  <FleetMonitor />
                  <h3>SHIPNEXT<br /> Fleet Monitor</h3>
                </div>
                <p>Track Your Fleet</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-exchange'}>
                <div>
                  <Exchange />
                  <h3>SHIPNEXT <br /> Exchange</h3>
                </div>
                <p>Optimize E-mail management and stay Connected</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-documents'}>
                <div>
                  <Documents />
                  <h3>SHIPNEXT <br /> Documents</h3>
                </div>
                <p>Your Contract Management</p>
              </Link>
              <Link className={s.part_link} to={'/solution-shipnext-ports'}>
                <div>
                  <PortsIcon />
                  <h3>SHIPNEXT <br /> Ports</h3>
                </div>
                <p>Port Data-search Base</p>
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withStyles(s)(PageSolutionsCargoMonitor);
