import React from "react";
import pure from "recompose/pure";
import SvgIcon from "material-ui/SvgIcon";

let MailIcon = props => (
  <span {...props}>
    <span className="icon-wrapper">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.66683 2.66667H13.3335C14.0668 2.66667 14.6668 3.26667 14.6668 4.00001V12C14.6668 12.7333 14.0668 13.3333 13.3335 13.3333H2.66683C1.9335 13.3333 1.3335 12.7333 1.3335 12L1.34016 4.00001C1.34016 3.26667 1.9335 2.66667 2.66683 2.66667ZM8.00016 8.66667L13.3335 5.33334V4.00001L8.00016 7.33334L2.66683 4.00001V5.33334L8.00016 8.66667Z" fill="#B2B2B2"/>
      </svg>
    </span>
  </span>
);

MailIcon = pure(MailIcon);
MailIcon.displayName = "MailIcon";
MailIcon.muiName = "SvgIcon";

export default MailIcon;
