import React from 'react';
import _DatePicker from 'material-ui/DatePicker';

function isEqualDate(d1, d2) {
  return d1 && d2 &&
    (d1.getFullYear() === d2.getFullYear()) &&
    (d1.getMonth() === d2.getMonth()) &&
    (d1.getDate() === d2.getDate()) &&
    (d1.getHours() === d2.getHours()) &&
    (d1.getMinutes() === d2.getMinutes());
};

export default class DatePicker extends _DatePicker {

  componentWillReceiveProps(nextProps) {
    if (nextProps.strictCompare) {
      if (this.isControlled()) {
        const newDate = this.getControlledDate(nextProps);
        if (!isEqualDate(this.state.date, newDate)) {
          this.setState({
            date: newDate,
          });
        }
      }
    } else {
      super.componentWillReceiveProps(nextProps);
    }
  }

}
