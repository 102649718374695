import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import Dialog from '../Common/DialogGreyTitle';
import s from './ConfirmEmailDialog.scss';
import { requestConfirmEmail,logout } from '../../actions/login';
import { isAllowedPathForUnsubscribed, isNotAllowedForNotVerified } from '../../core/utils';
import cx from 'classnames';
import Link from '../Link';


class ConfirmEmailDialog extends PureComponent {
  static propTypes = {
    requestConfirmEmail: PropTypes.func,
    path: PropTypes.string,
    isPublicPath: PropTypes.bool,
    isUnLockedPathForUnverified: PropTypes.bool,
  };
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSendVerificationEmail = () => {
    this.props.requestConfirmEmail().then(((res) => {
      if (res.status === 200) {
        this.setState({ sent: true });
      }
    }));
  };
  handleSwitchAccount = () => {
    this.props.logout();
  };

  render() {
    if(!this.props.user) {
      return null;
    }
    if(this.props.user.emailVerified) {
      return null;
    }
    const show = (!this.props.isPublicPath && !this.props.isUnLockedPathForUnverified) || this.props.path === '/main-deck/general';
    return (
      <div >
        <Dialog
          open={show}
          contentClassName={cx(s.dialogContent)}
          bodyClassName={cx(s.dialogBody)}
          title={'Please, Confirm e-mail'}
        >
          <div>
            <div className={s.wrapper_access}>
              {this.state.sent ? (
                <span >
              Please check your mailbox to complete e-mail verification.&nbsp;{<a onClick={this.handleSendVerificationEmail}>Resend link.</a>}
              </span>
              ) : (
                <span >
              To continue using SHIPNEXT your e-mail must be verified.&nbsp;{<a onClick={this.handleSendVerificationEmail}>Resend link.</a>}
              </span>
              )
            }
              <br/>
              If you have any questions, send it to <a href="mailto:support@shipnext.com">support@shipnext.com</a>.
              <br/>
              <a onClick={this.handleSwitchAccount} >Switch account</a>
            </div>
            <div className={s.info}>
              <a href="/legal" target="_blank">
                Terms & conditions
              </a>
              <a href="/rules-regulations" target="_blank">
                Rules & Regulations
              </a>
              <Link to="/">
                Back to home
              </Link>
            </div>
          </div>
        </Dialog>

      </div>
    );
  }
}


export default connect(
  state => ({ ...state.login }),
  {requestConfirmEmail, logout },
)(withStyles(s)(ConfirmEmailDialog));
