import produce from "immer";

/** @name CONTACTS_EXPAND_GROUP_REDUCER */
export default function CONTACTS_EXPAND_GROUP(state, { payload }) {
  return produce(state, draft => {
    const company = draft.companyList.data.find(c => c.id === payload.companyId);
    if (company) {
      company.expanded = !company.expanded;
    }
  });
}
