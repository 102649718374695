import React, { Component } from 'react';

import Fleet from '../../../core/api/Fleet';
import VesselFilter from './VesselFilter';
import { prepareVesselFilterFromServer, prepareVesselFilterToServer, prepareVesselFiltersFromServer } from './common';


export class AllFleetFilterNew extends VesselFilter {

  static type = 'allFleet'

  static dataFieldName = 'allFleetFilter'

  static defaultProps = {
    openPortsAvailable: false,
  }

  fetchData = async () => {
    try {
      const res = await Fleet.getAllFleetFilters();
      const filters = res.data;
      return prepareVesselFiltersFromServer(filters, this.constructor.dataFieldName);
    } catch (error) {
      console.log(error);
    }
  }

  saveFilterToDB = async (filterName, filterData) => {
    const res = await Fleet
      .saveAllFleetFilter(filterName, prepareVesselFilterToServer(filterData))
      prepareVesselFilterFromServer(res.data);
      return res;
  }

  updateFilterInDB = async (tabId, formData) => {
    const res = await Fleet
      .updateAllFleetFilter(tabId, prepareVesselFilterToServer(formData));
      prepareVesselFilterFromServer(res.data);
      return res;
  }

  deleteFilter = (tabId) => {
    return Fleet
      .deleteAllFleetFilter(tabId)
      .catch(error => console.error(error));
  }

}

export default AllFleetFilterNew;
