/** @name LOGIN_REDUCER */
export default function LOGIN(state, { res }) {
  const newState = {
    user: res.data,
    error: null,
    message: null,
    loading: false,
    loginOpened: false,
    afterQueue: [],
  };
  return { ...state, ...newState };
}
