export const changeArrItem = (list, field, val) => list.map(item => ({...item, [field]: val}))

export default function FLEET_UPDATE_ALL_VESSELS(state, {payload: {field ='', val= ''}}) {

  const fleet = changeArrItem(state.vesselList.data, field, val);

  const filteredFleet = changeArrItem(state.vesselList.filtered, field, val);

  return { ...state, vesselList: { ...state.vesselList, filtered: filteredFleet, data: fleet } };
}
