import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from 'material-ui/SvgIcon';

const _TradingDesk = props => (
  <span {...props}>
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="29" fill="#F5F5F5"/>
      <g clip-path="url(#clip0_35050_25197)">
        <path d="M32.5 45C32.2239 45 32 44.7761 32 44.5L32 13.5C32 13.2239 32.2239 13 32.5 13H39V45H32.5Z" fill="#90CAFF"/>
        <path d="M39 13L45.5 13C45.7761 13 46 13.2239 46 13.5V44.5C46 44.7761 45.7761 45 45.5 45H39V13Z" fill="#67A1E5"/>
        <path d="M25.5 45C25.7761 45 26 44.7761 26 44.5L26 13.5C26 13.2239 25.7761 13 25.5 13H19L19 45H25.5Z" fill="#56AC13"/>
        <path d="M19 13L12.5 13C12.2239 13 12 13.2239 12 13.5L12 44.5C12 44.7761 12.2239 45 12.5 45H19L19 13Z" fill="#94D352"/>
        <path d="M28.6556 21.5094C29.0186 21.5059 29.3821 21.4937 29.7446 21.5024C30.0818 21.5105 30.3175 21.7509 30.3192 22.0974C30.3255 23.3744 30.3255 24.652 30.3192 25.929C30.3175 26.304 30.0531 26.5286 29.6224 26.5327C28.9148 26.5397 28.2077 26.5344 27.5 26.5344C26.5635 26.5344 25.6276 26.5344 24.6396 26.5344C24.6396 26.7596 24.6396 26.9674 24.6396 27.1747C24.6396 27.5909 24.6373 28.0071 24.6396 28.4227C24.6407 28.6885 24.5111 28.8772 24.276 28.9561C24.0581 29.0292 23.843 29.0258 23.6469 28.8307C22.1874 27.3807 20.7182 25.94 19.2547 24.4941C18.9215 24.165 18.9181 23.8997 19.2277 23.5741C19.468 23.7099 19.7066 23.8504 19.9492 23.9816C21.5732 24.8592 23.1979 24.7687 24.7812 23.9055C26.0526 23.2125 27.2884 22.4515 28.5357 21.7137C28.5948 21.6795 28.6166 21.579 28.6556 21.5094Z" fill="#90CAFF"/>
        <path d="M28.6578 21.5095C28.6188 21.5791 28.597 21.6795 28.5379 21.7144C27.2901 22.4521 26.0548 23.2125 24.7834 23.9062C23.1995 24.7699 21.5755 24.8599 19.9514 23.9822C19.7082 23.851 19.4702 23.7106 19.23 23.5747C19.3544 23.4441 19.476 23.3101 19.6039 23.1829C20.8913 21.9059 22.1799 20.6295 23.4696 19.3536C23.5511 19.273 23.6388 19.1981 23.7288 19.1273C23.9215 18.9752 24.1274 18.9595 24.3424 19.0797C24.5432 19.1923 24.6452 19.3595 24.6441 19.5969C24.6401 20.2133 24.6429 20.8303 24.6429 21.5095C25.9963 21.5095 27.3268 21.5095 28.6578 21.5095Z" fill="#90CAFF"/>
        <path d="M23.8085 21.3019H34V26.8574H23.8085V21.3019Z" fill="#90CAFF"/>
        <path d="M29.5313 32.5093C29.1753 32.5058 28.8188 32.4937 28.4633 32.5024C28.1327 32.5105 27.9015 32.7508 27.8998 33.0973C27.8937 34.3743 27.8937 35.6519 27.8998 36.9289C27.9015 37.3039 28.1608 37.5286 28.5831 37.5326C29.2771 37.5396 29.9705 37.5344 30.6645 37.5344C31.5828 37.5344 32.5006 37.5344 33.4696 37.5344C33.4696 37.7596 33.4696 37.9674 33.4696 38.1746C33.4696 38.5908 33.4718 39.007 33.4696 39.4226C33.4684 39.6885 33.5955 39.8771 33.8261 39.956C34.0398 40.0292 34.2507 40.0257 34.443 39.8307C35.8743 38.3807 37.3151 36.94 38.7502 35.4941C39.077 35.1649 39.0804 34.8997 38.7767 34.574C38.541 34.7099 38.3071 34.8503 38.0692 34.9815C36.4766 35.8592 34.8834 35.7686 33.3307 34.9055C32.0839 34.2124 30.872 33.4514 29.6488 32.7136C29.5909 32.6794 29.5695 32.579 29.5313 32.5093Z" fill="#56AC13"/>
        <path d="M29.5291 32.5094C29.5673 32.5791 29.5887 32.6795 29.6466 32.7143C30.8703 33.4521 32.0817 34.2125 33.3285 34.9061C34.8817 35.7699 36.4744 35.8598 38.067 34.9822C38.3055 34.851 38.5388 34.7105 38.7745 34.5747C38.6524 34.4441 38.5332 34.31 38.4078 34.1829C37.1453 32.9059 35.8816 31.6294 34.6169 30.3536C34.537 30.2729 34.451 30.198 34.3627 30.1272C34.1737 29.9751 33.9718 29.9595 33.7609 30.0796C33.5641 30.1922 33.464 30.3594 33.4651 30.5968C33.4691 31.2132 33.4663 31.8303 33.4663 32.5094C32.1391 32.5094 30.8344 32.5094 29.5291 32.5094Z" fill="#56AC13"/>
        <path d="M33.9943 32.2483H24V37.8039H33.9943V32.2483Z" fill="#56AC13"/>
      </g>
      <defs>
        <clipPath id="clip0_35050_25197">
          <rect width="34" height="32" fill="white" transform="translate(12 13)"/>
        </clipPath>
      </defs>
    </svg>
  </span>
);

const TradingDesk = pure(_TradingDesk);
TradingDesk.displayName = 'TradingDesk';
TradingDesk.muiName = 'SvgIcon';

export default TradingDesk;
