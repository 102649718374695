import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './IssueReport.scss';
import { connect } from 'react-redux';
import IssueReportFloatButton from './IssueReportFloatButton';
import IssueReportPanel from './IssueReportPanel';
import ScreenCapture from './ScreenCapture';
import { User } from "../../core/api/api";
import { setIssueSelectable, selectElement, removeSelectedElement, clearSelectedElements } from "./actions";
import { UUIDToObjectId } from '../../core/utils';

function transformSelectedBeforeSend(selectedElements) {
  const transformedObject = {
    mailThreadsRefNos: [],
  };

  for (const key in selectedElements) {
    if (selectedElements.hasOwnProperty(key)) {
      if (selectedElements[key].length) {
        if (key === "mails" || key === "mailThreads") {
          transformedObject.mailThreadsRefNos.push(...selectedElements[key].map(obj => obj.id));
        } else {
          transformedObject[key] = selectedElements[key].map(obj => obj.entityId);
        }
      }
    }
  }

  return transformedObject;
}

class IssueReport extends Component {
  static contextTypes = {
    showMessage: PropTypes.func,
  };

  state = {
    isOpen: false,
    isShown: true,
    screenCapture: null,
    topics: [],
    attachments: [],
    text: '',
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isChatShown) {
      this.setState({ isShown: false });
    }
    if (nextProps.path.split('/')[1] !== this.props.path.split('/')[1]) {
      this.resetForm();
      this.setState({ isShown: false });
    }
  }
  handleOpenIssueReport = () => {
    this.setState({ isOpen: true, isShown: true });
  }
  handleToggleIssueReport = () => {
    const { isShown } = this.state;
    this.setState({ isShown: !isShown });
  }
  handleIssueReportClose = () => {
    this.setState({ isOpen: false });
  }
  handleScreenCapture = (screenCapture) => {
    if (screenCapture && screenCapture.data && this.state.attachments.indexOf(screenCapture?.data) === -1) {
      this.attachmentsChanges([...this.state.attachments, screenCapture.data]);
    }
  }
  attachmentsChanges = (files) => {
    this.setState({ attachments: files });
  }
  textAreaChange = (text) => {
    this.setState({ text: text });
  }
  newRequest = (item) => {
    if (this.state.topics.indexOf(item) === -1) {
      const newTopics = [...this.state.topics, item];
      this.setState({ topics: newTopics });
    }
  }
  removeTopic = (id) => {
    const newTopics = this.state.topics.filter(topic => topic.id !== id);
    this.setState({ topics: newTopics });
  }
  resetForm = () => {
    this.props.clearSelectedElements();
    this.setState({ text: '', topics: [], attachments: [], screenCapture: null });
  }
  handleSubmit = async () => {
    const { text, topics, attachments } = this.state;
    const report = {
      text: text.length ? text : undefined,
      topics: topics.map(t => t.name),
      attachments: attachments.map(a => a._id),
      pageUrl: window.location.href,
    };
    if (Object.values(this.props.issueReport.selectedElements).flat().length) {
      report.userElements = transformSelectedBeforeSend(this.props.issueReport.selectedElements);
    }
    const res = await User.sendIssueReport(report);
    if (!res.errors.length) {
      this.context.showMessage({
        message: `Your report is well received.`,
      });
      this.resetForm();
      this.handleIssueReportClose();
    }
  }
  render() {
    const { isOpen, isShown, screenCapture, topics, attachments, text } = this.state;
    const selectedElementsList = Object.values(this.props.issueReport.selectedElements).flat();
    if (!this.props.user) {
      return null;
    }
    return (
      <ScreenCapture onEndCapture={this.handleScreenCapture} showForm={this.handleToggleIssueReport}>
        {({ onStartCapture }) => (
        <div className={s.wrapper_issue_report}>
          <IssueReportFloatButton
            selectedElementsList={selectedElementsList}
            showForm={this.handleOpenIssueReport}
            isOpen={isOpen}
            isShown={isShown}
            clearSelectedElements={this.props.clearSelectedElements}
          />
          {isOpen ?
            <IssueReportPanel
              isShown={isShown}
              screenCapture={screenCapture}
              onStartCapture={onStartCapture}
              hideForm={this.handleToggleIssueReport}
              closeForm={this.handleIssueReportClose}
              clearScreenCapture={this.clearScreenCapture}
              removeTopic={this.removeTopic}
              newRequest={this.newRequest}
              textAreaChange={this.textAreaChange}
              attachmentsChanges={this.attachmentsChanges}
              topics={topics}
              attachments={attachments}
              text={text}
              resetForm={this.resetForm}
              handleSubmit={this.handleSubmit}
              handleStartSelectElements={this.props.setIssueSelectable}
              handleRemoveSelectedElement={this.props.removeSelectedElement}
              selectedElements={this.props.issueReport.selectedElements}
              path={this.props.path}
            />
          : null
          }
          <div>
              <div className={cx(s.max_selected, selectedElementsList.length === 20 ? s.show : "")}>
                Maximum number of elements has been reached
              </div>
          </div>
        </div>
        )}
      </ScreenCapture>
    );
  }
}
const mapStateToProps = (state) => ({
    user: state.login.user,
    isChatShown: state.chat.isShown,
    issueReport: state.issueReport,
  }
);
const mapDispatchToProps = (dispatch) => (
  {
    setIssueSelectable: (selectable) => dispatch(setIssueSelectable(selectable)),
    selectElement: (el) => dispatch(selectElement(el)),
    removeSelectedElement: (el) => dispatch(removeSelectedElement(el)),
    clearSelectedElements: () => dispatch(clearSelectedElements()),
  }
);

export default connect(mapStateToProps, mapDispatchToProps
  )(withStyles(s)(IssueReport));

