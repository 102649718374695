import { removeFromArray } from '../../core/utils';

/** @name INVOICE_DELETE_REDUCER */
export default function INVOICE_DELETE(state, action) {
  const id = action.payload;
  const newState = { ...state };
  const index = newState.invoiceStore.findIndex(a => a._id === id);

  if (index === -1) {
    return state;
  } 
  newState.invoiceStore = removeFromArray(newState.invoiceStore, index);
  
  return { ...state, ...newState };
}
