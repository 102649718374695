import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from '../Pages.scss';

class BlockchainTechnology extends Component {
  render() {
    return (
      <div className={s.blockcahin_technology}>

        <div className={s.container}>
          <div className={s.title}>
            <h2>Blockchain Technology</h2>
          </div>
          <div className={s.body_technology}>
            <div>
              <h3>Building Ecosystem</h3>
              <p>SHIPNEXT is building an ecosystem to enable secure, seamless and reliable transactions between the Industry participants on basis of interconnectivity and with the help of Smart Contracts. SHIPNEXT solution can be integrated into Blockchain using Hyperledger Fabric, R3 or other technology.</p>

              <h3>Decentralized approach</h3>
              <p>Decentralized distributed ledger is the intangible digital platform that can be only addressed through a front-end application that structures the queries and results obtained from the ledger.</p>

              <h3>Blockchain-driven interconnectivity</h3>
              <p>This application will allow industry players and service providers to become integrated into the system. For example, if there is an insurance company that wants to provide coverage for either cargo or a ship, we will integrate our APIs, so that a player seeking insurance coverage would be able to use insurance services through our application.</p>

              <h3>API integration</h3>
              <p>Open API platforms have proven to be more competitive in the long term and lead to larger and more diverse ecosystems. SHIPNEXT application layer will provide a user friendly and easy to use front-end interface to shipping industry players.</p>

              <h3>A road to greater efficiency</h3>
              <p>SHIPNEXT, as algorithm-based blockchain driven solution aims at increasing efficiency and proclaim interoperability to ensure reliable, secure and real-time data management across the transportation industry in general and shipping sector in particular.</p>
            </div>
            <img src="/images/technology/blockchain-technology.png?v=1" alt="Blockchain technology" />
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(s)(BlockchainTechnology));
