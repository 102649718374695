/* eslint-disable indent */
import React, { Component } from 'react';
import s from './VesselPreviewNew.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class PastNames extends Component {
    static defaultProps = {
        pastNames: [],
    }
    constructor(props) {
        super(props);
        this.state = {
            showAll: false,
        }
        this.renderPastNames = this.renderPastNames.bind(this);
        this.defaultField = this.defaultField.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
    }
    defaultField(field) {
        return field || '---';
    }
    toggleAll() {
        this.setState(state => ({ showAll: !state.showAll }));
    }
    renderPastNames() {
        const { pastNames } = this.props;
        const { defaultField } = this;
        const names = this.state.showAll ? pastNames : pastNames.slice(0,3);
        return names.map(name => (
           <div className={s.body}>
                <div className={cx(s.row, s.ex_name)}>{defaultField(name?.name)}</div>
                <div className={cx(s.row, s.editor)}>{defaultField(name?.user?.company?.name)}</div>
                <div className={cx(s.row, s.update)}>{ name?.time ? defaultField(new Date(name.time)?.toLocaleDateString()) : '---'}</div>
           </div>
        ))
    }
    render() {
        const { pastNames } = this.props;
        if (pastNames.length < 1) return <div>---</div>
        return (
            <div className={s.collapsible_table}>
                <div className={s.headers}>
                    <div className={cx(s.header, s.ex_name)}>Ex Name</div>
                    <div className={cx(s.header, s.editor)}>Edited by</div>
                    <div className={cx(s.header, s.update)}>Date</div>
                </div>
                {this.renderPastNames()}
                {
                  pastNames.length > 3
                    ? <button onClick={this.toggleAll} className={s.show_more}>Show {this.state.showAll ? 'less' : 'more'}</button>
                    : null
                }
            </div>
        )
    }
}

export default withStyles(s)(PastNames);
