function prepareShips(cargo) {
  const newState = { ships: [] };

  if (cargo.offersRequests) {
    cargo.offersRequests.forEach((or) => {
      const vr = or.vesselRequest;
      vr._type = 'offer';
      newState.ships.push({
        ...vr,
        freight: or.freight,
        status: { name: or.status || 'offer', end: or.endAt.getTime() },
        bidId: or.bidId,
        key: or._id,
      });
    });
  }
  if (cargo.freightRequests) {
    cargo.freightRequests.forEach((fr) => {
      const vr = fr.vesselRequest;
      vr._type = 'freightRequest';
     // if (!newState.ships.find(ship => ship._id === vr._id)) {
      newState.ships.push({ ...vr, status: { name: (fr.status || '') + ' freight request' }, key: fr._id });
     // }
    });
  }
  if (cargo.recommended) {
    cargo.recommended.forEach((vr) => {
      if (!newState.ships.find(ship => ship._id === vr._id)) {
        newState.ships.push({ ...vr.vesselRequest, status: { name: 'proposal' }, estimatedDate: vr.estimatedDate, key: vr._id });
      }
    });
  }
  return { ...cargo, ...newState };
}

/** @name MY_CARGO_DETAILS_REDUCER */
export default function MY_CARGO_DETAILS(state, { payload: { _id, select = true }, res: { status, data } }) {
  const newState = {};
  const myCargoDetails = { ...state.myCargoDetails };
  newState.myCargoDetails = myCargoDetails;
  const cargo = prepareShips(data);
  if (select && myCargoDetails.selectedId === _id) {
    newState.myCargoDetails.selected = cargo;
    newState.myCargoDetails.loading = false;
  }
  return { ...state, ...newState };
}
