import PropTypes from 'prop-types';
import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Layout from '../../components/Layout';
import s from './NotFound.scss';
import Page from '../../components/Page/Page';
import RaisedButton from '../../components/Common/RaisedButton';
import Link from '../../components/Link/Link';
import NotFoundSvg from './assets/NotFoundSvg.svg'

function NotFound({ title }) {
  return (
    <Layout full={false}>
      <Page headerTitle={(title) || 'PAGE NOT FOUND'}>
       <div className={s.wrapper}>
        <div className={s.text}>
          <div className={s.title}>Ooops...</div>
          <div className={s.semiTitle}>Page Not Found</div>
          <div className={s.description}>
            Sorry. the content you’re looking for doesn’t exist. 
            Either it was removed, or you mistyped the link. 
          </div>
          <div className={s.button}>
            <Link to="/main-deck/general">
            <RaisedButton
                label="Go to main page"
                type="submit"
                primary
                buttonStyle={{
                  background: "#4380C7",
                  color: "#fff"
                }}
              />
            </Link>
          </div>
        </div>
        <div className={s.image}>
          <img src={NotFoundSvg} alt="Not found" width={622} height={372}/>
        </div>
       </div>
      </Page>
    </Layout>
  );
}

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withStyles(s)(NotFound);
