import React, { Component } from 'react';
import styled from "styled-components";
import {MinusButton, PlusButton} from "../Common/AddButton";
import NarrowFormsyDate from "../NewInputs/NarrowFormsyDate";
import NarrowFormsyText from "../NewInputs/NarrowFormsyText";


const StyleWrapper = styled.div`
  >div {
    display: flex;
    align-items: center;
    >div:nth-child(2) {
      flex: 1;
      margin: 0 12px;
    }
    >div:nth-child(1) {
      width: 102px;
    }
  }
`;



class ContactDatesEdit extends Component {

  handleChangeDate = (i, e, value) => {
    const dates = [...this.props.dates];
    dates[i].date = value;
    this.props.onChange(null, dates);
  }
  handleChangeNote = (i, e, value) => {
    const dates = [...this.props.dates];
    dates[i].note = value;
    this.props.onChange(null, dates);
  }
  handleRemoveDate = (i) => {
    const dates = this.props.dates.filter((date, j) => j !== i);
    this.props.onChange(null, dates);
  }
  handleAddDate = () => {
    const dates = [...this.props.dates, { date: undefined, note: "" }];
    this.props.onChange(null, dates);
  }

  render() {
    return (
      <StyleWrapper>
        {this.props.dates.map((date, i) => {
          return (<div>
            <div>
              <NarrowFormsyDate
                name={`dates[${i}][date]`}
                floatingLabelText="Important Date"
                value={date.date}
                fullWidth
                onChange={this.handleChangeDate.bind(this, i)}
              />
            </div>
            <div>
              <NarrowFormsyText
                name={`dates[${i}][note]`}
                floatingLabelText="Details"
                fullWidth
                validations="minLength:2,maxLength:128"
                validationError="2 - 128 characters"
                onChange={this.handleChangeNote.bind(this, i)}
                value={date.note}
              />
            </div>
            <div>{i === 0 ? <PlusButton onClick={this.handleAddDate} /> : <MinusButton onClick={this.handleRemoveDate.bind(this, i)} />}</div>
          </div>)
        })}
      </StyleWrapper>
    );
  }
}

export default ContactDatesEdit;
