import produce from "immer";
import {sortContacts} from "./CONTACTS_CREATE_GROUP";

/** @name CONTACTS_UPDATE_COMPANY_REDUCER */
export default function CONTACTS_UPDATE_COMPANY(state, {res}) {
  const newContact = res.data;
  newContact.type = 'company';
  return produce(state, draft => {
    if (newContact.groups?.length) {
      newContact.groups.forEach(g => {
        const groupIndex = draft.companyList.data.findIndex(c => c.id === g.id);
        if (groupIndex !== -1) {
          const inGroupIndex = draft.companyList.data[groupIndex].companies.findIndex(c => c.id === newContact.id);
          if (inGroupIndex !== -1) {
            draft.companyList.data[groupIndex].companies[inGroupIndex] = newContact;
          } else {
            draft.companyList.data[groupIndex].companies.push(newContact);
          }
        }
      });
    } else {
      const index = draft.companyList.data.findIndex(c => c.id === newContact.id);
      if (index !== -1) {
        draft.companyList.data[index] = newContact;
      } else {
        draft.companyList.data.push(newContact);
      }
    }
    draft.companyList = sortContacts(draft.companyList);
  });
}

