import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SignupConnect.scss';
import FormsyText from '../../components/Common/FormsyText';
import Header from "../Header";
import FormsyCheckbox from "../Common/CheckBox";
import RaisedButton from '../Common/RaisedButton';
import Formsy from 'formsy-react';

class SignupConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };
  handleScroll = (e) =>  {
    const scrolling = e.currentTarget.scrollTop !==0;
    if(this.state.scrolling !== scrolling){
      this.setState({scrolling})
    }
  };
  render() {
    return (
      <div className={s.home} onScroll={this.handleScroll}>
        <Header scroll={this.state.scrolling} loginConnect />
        <div className={s.root}>
          <div>
            <div className={s.list_info}>
              <div className={s.img_app_gmail}>
                <img src="/images/connect-register@2x.jpg" alt="Shipnext-app-gmail"/>
              </div>
              <div className={s.info_connect}>
                <div>
                  <div className={s.part_info_connect}>
                    <img src="/images/mashine-learning@2x.png" alt="mashine-learning"/>
                    <p>Email processing using natural language recognition and machine learning</p>
                  </div>
                  <div className={s.part_info_connect}>
                    <img src="/images/filters@2x.png" alt="mashine-learning"/>
                    <p>Individual data search among Cargo, Ships, Port and other related real-time data-bases.</p>
                  </div>
                  <div className={s.part_info_connect}>
                    <img src="/images/relevant@2x.png" alt="mashine-learning"/>
                    <p>Instant Cargo-to-Ship matching through big-data analysis and linear programming, using real-time data</p>
                  </div>
                  <div className={s.part_info_connect}>
                    <img src="/images/mail-template@2x.png" alt="mashine-learning"/>
                    <p>Freight trading, contract management and other individual commercial solutions</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.container}>
              <div>
                <h2>Register</h2>
                <Formsy.Form>
                  <div className={s.wrapper_input}>
                    <FormsyText
                      hintText="First Name*"
                      floatingLabelText="First Name*"
                      validations="minLength:2"
                      validationError="at least 2 characters"
                      requiredError="required"
                      name="firstName"
                      required
                      fullWidth
                    />
                  </div>
                  <div className={s.wrapper_input}>
                    <FormsyText
                      hintText="Last Name*"
                      floatingLabelText="Last Name*"
                      validations="minLength:2"
                      validationError="at least 2 characters"
                      requiredError="required"
                      name="lastName"
                      required
                      fullWidth
                    />
                  </div>
                  <div className={s.wrapper_input}>
                    <FormsyText
                      hintText="Company*"
                      floatingLabelText="Company*"
                      validations="minLength:2"
                      validationError="at least 2 characters"
                      requiredError="required"
                      name="company"
                      required
                      fullWidth
                    />
                  </div>
                  <div className={s.wrapper_input}>
                    <FormsyText
                      hintText="E-mail"
                      floatingLabelText="E-mail*"
                      validations="isEmail"
                      validationError="please enter valid email"
                      requiredError="required"
                      name="email"
                      required
                      fullWidth
                      ref="emailInput"
                    />
                  </div>
                  <div className={s.wrapper_input}>
                    <FormsyText
                      hintText="Password*"
                      floatingLabelText="Password*"
                      type="password"
                      name="password"
                      validations="minLength:6"
                      validationError="at least 6 characters"
                      requiredError="required"
                      required
                      fullWidth
                    />
                  </div>
                  <div className={s.wrapper_input}>
                    <FormsyText
                      hintText="Confirm password*"
                      floatingLabelText="Confirm password*"
                      name="confirmPassword"
                      type="password"
                      validations="equalsField:password"
                      validationError="password did not match"
                      requiredError="required"
                      required
                      fullWidth
                    />
                  </div>
                  <FormsyCheckbox
                    className={s.checkbox_terms}
                    label={
                      <span className={s.label_checkbox}>
                        I agree to the Shipnext
                        {' '}
                        <a href="/privacy" target="_blank">
                          Privacy Policy
                        </a>
                        {' '}and{' '}
                        <a href="/legal" target="_blank">
                          Terms of Service.
                        </a>
                      </span>
                    }
                    inputStyle={{ width: '100px' }}
                    style={{
                      marginTop: '18px',
                      marginBottom: '30px'
                    }}
                    iconStyle = {{
                      width: '22px',
                      height: '22px',
                      fill: 'rgba(0, 0, 0, 0.4)'
                    }}
                    name="terms"
                    validations="isTrue"
                    requiredError="required"
                    required
                  />
                  <RaisedButton
                    label={'Create account'}
                    type={'submit'}
                    primary
                    style={{
                      width: '150px',
                      height: '40px',
                    }}
                    buttonStyle={{
                      backgroundColor: '#71BC3D'
                    }}
                    labelStyle={{
                      textTransform: 'capitalize',
                      fontSize: '13px',
                      fontWeight: 'normal',
                      lineHeight: '40px'
                    }}
                  />
                </Formsy.Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SignupConnect);
