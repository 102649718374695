import CompanyApi from '../../core/api/Company';
import Autocomplete from './AutoComplete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class CompanyAutocomplete extends Autocomplete {
  static defaultProps = {
    forceSelection: false,
  };
  constructor(props) {
    super(props);
    this.Api = CompanyApi;
  }
}

CompanyAutocomplete.propTypes = {};

export default CompanyAutocomplete;
