import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Pages.scss';
import cx from 'classnames';
import Form from '../About/Form';

const partners = [
  {
    link: 'http://www.varamar.com',
    maskImage: '/images/partners/varamar-b.png',
    image: '/images/partners/varamar-logo.png',
    name: 'Varamar',
  },
  {
    link: 'https://ifchor.com/',
    maskImage: '/images/partners/ifchor-b.png',
    image: '/images/partners/ifchor.png',
    name: 'Ifchor',
  },
  {
    link: 'https://www.epnetwork.net/',
    maskImage: '/images/partners/epn-b.png',
    image: '/images/partners/epn.png',
    name: 'EPN',
  },
  {
    link: 'https://www.uni-tankers.com/',
    maskImage: '/images/partners/uni-tankers-b.png',
    image: '/images/partners/uni-tankers.png',
    name: 'Uni-tankers',
  },
  {
    link: 'https://www.leewaybrokers.com/',
    maskImage: '/images/partners/leeway-b.png',
    image: '/images/partners/leeway.png',
    name: 'Leeway',
  },
  {
    link: 'https://www.martrade-group.com/',
    maskImage: '/images/partners/martrade-b.png',
    image: '/images/partners/martrade-group.png',
    name: 'Martrade group',
  },
  {
    link: 'https://www.renkaede.com',
    maskImage: '/images/partners/renkaedeag-b.png',
    image: '/images/partners/renkaedeag.png',
    name: 'Renkaede',
  },
  {
    link: 'https://www.jindalshadeed.com/',
    maskImage: '/images/partners/jindal-b.png',
    image: '/images/partners/jindal.png',
    name: 'Jindal shadeed',
  },
  {
    maskImage: '/images/partners/antracholding-b.png',
    image: '/images/partners/antracholding.png',
    name: 'Antrac holding',
  },
  {
    link: 'http://www.gjunquera.com/',
    maskImage: '/images/partners/gjunquera-b.png',
    image: '/images/partners/gjunquera.png',
    name: 'Gjunquera',
  },
  {
    // link: 'http://www.lmc.cd/',
    maskImage: '/images/partners/lmc-b.png',
    image: '/images/partners/lmc.png',
    name: 'LMC',
  },
  {
    link: 'http://tlc-com.ch',
    maskImage: '/images/partners/tlc20-b.png',
    image: '/images/partners/tlc20.png',
    name: 'TLC',
  },
  {
    link: 'https://www.almadinalogistics.com/',
    maskImage: '/images/partners/madina-b.png',
    image: '/images/partners/madina.png',
    name: 'Almadina logistics',
  },
  {
    link: 'https://www.paretoship.com',
    maskImage: '/images/partners/paretoship-b.png',
    image: '/images/partners/paretoship.png',
    name: 'Paretoship',
  },
  {
    link: 'http://www.shipforsale.net',
    maskImage: '/images/partners/shipforsale-b.png',
    image: '/images/partners/shipforsale.png',
    name: 'Shipforsale',
  },
  {
    link: 'http://www.microsoft.com/',
    maskImage: '/images/partners/microsoft-logo-b.png',
    image: '/images/partners/microsoft-logo.png',
    name: 'Microsoft',
  },
  {
    link: 'http://www.safina-eg.com/',
    maskImage: '/images/partners/safina-b.png',
    image: '/images/partners/safina.png',
    name: 'Navideep',
  },
  {
    link: 'http://www.seatram.com.au',
    maskImage: '/images/partners/seatram-b.png',
    image: '/images/partners/seatram.png',
    name: 'Seatram',
  },
  {
    link: 'http://kabokyyachting.com',
    maskImage: '/images/partners/kabokyyachting-b.png',
    image: '/images/partners/kaboky.png',
    name: 'Kaboky yachting',
  },
  {
    link: 'http://www.interpipe.biz',
    maskImage: '/images/partners/interpipe-b.png',
    image: '/images/partners/interpipe.png',
    name: 'Interpipe',
  },
  {
    link: 'http://acirfa.es/',
    maskImage: '/images/partners/acirfa-b.png',
    image: '/images/partners/acirfa.png',
    name: 'Acirfa',
  },
  {
    link: 'https://www.orient-transport.com/',
    maskImage: '/images/partners/orient-b.png',
    image: '/images/partners/orient-logo.png',
    name: 'Orient',
  },
  {
    link: 'https://www.kogaship.com/',
    maskImage: '/images/partners/koga-b.png',
    image: '/images/partners/koga.png',
    name: 'Koga',
  },
  {
    link: 'https://www.thalassamaritime.com',
    maskImage: '/images/partners/thalassa-b.png',
    image: '/images/partners/thalassa.png',
    name: 'Thalassa maritime',
  },
  {
    link: 'https://sbbusa.com/',
    maskImage: '/images/partners/sbbusa-b.png',
    image: '/images/partners/sbbusa.png',
    name: 'SBB Shipping USA',
  },
  {
    link: 'https://www.al-cargo.com',
    maskImage: '/images/partners/al-cargo-b.png',
    image: '/images/partners/al-cargo.png',
    name: 'A&L cargo',
  },
  {
    link: 'https://www.balticline.no/',
    maskImage: '/images/partners/balticline-b.png',
    image: '/images/partners/balticline.png',
    name: 'Baltic line',
  },
  {
    link: 'http://www.anchor-up.de',
    maskImage: '/images/partners/anchor-up-b.png',
    image: '/images/partners/anchor-up.png',
    name: 'Anchor up',
  },
  {
    link: 'http://www.fairwind.co.uk/',
    maskImage: '/images/partners/fairwind-b.png',
    image: '/images/partners/fairwind.png',
    name: 'Fairwind',
  },
  {
    link: 'http://www.afagegroup.com/',
    maskImage: '/images/partners/afagegroup-b.png',
    image: '/images/partners/afagegroup.png',
    name: 'Afage group',
  },
  {
    link: 'http://www.green.maritime.tilda.ws/',
    maskImage: '/images/partners/green-maritime-b.png',
    image: '/images/partners/green-maritime.png',
    name: 'Green maritime',
  },
  {
    link: 'http://www.doric.gr',
    maskImage: '/images/partners/doric-b.png',
    image: '/images/partners/doric_logo.png',
    name: 'DORIC',
  },
  {
    link: 'http://www.eastship-projects.com',
    maskImage: '/images/partners/eastship-b.png',
    image: '/images/partners/eastship.png',
    name: 'Eastship',
  },
  {
    link: 'http://www.blueshark-marine.com/',
    maskImage: '/images/partners/blueshark-b.png',
    image: '/images/partners/blueshark.png',
    name: 'Blue shark marine',
  },
  {
    link: 'https://www.agrovisp.com/',
    maskImage: '/images/partners/agrovisp-b.png',
    image: '/images/partners/agrovisp.png',
    name: 'Agrovisp',
  },
  {
    link: 'http://www.irishshipbrokers.ie/',
    maskImage: '/images/partners/irish_shipbrokers-b.png',
    image: '/images/partners/irish_shipbrokers.png',
    name: 'Irish shipbrokers',
  },
  {
    link: 'https://www.isctaranto.com/',
    maskImage: '/images/partners/ionian-b.png',
    image: '/images/partners/ionian.png',
    name: 'Isctaranto',
  },
  {
    link: 'https://www.efspl.in/',
    maskImage: '/images/partners/euphoria-b.png',
    image: '/images/partners/euphoria.png',
    name: 'Euphoria',
  },
  {
    link: 'https://www.eleenmarine.com/',
    maskImage: '/images/partners/eleenmarine-b.png',
    image: '/images/partners/eleenmarine.png',
    name: 'Eleen marine',
  },
  {
    link: 'https://www.bulkmarine.com/',
    maskImage: '/images/partners/bulkmarine-b.png',
    image: '/images/partners/bulkmarine.png',
    name: 'Bulk marine',
  },
  {
    link: 'https://www.easterncargos.com/',
    maskImage: '/images/partners/eastern-b.png',
    image: '/images/partners/eastern.png',
    name: 'Eastern cargos',
  },
  {
    link: 'http://www.barite.in/',
    maskImage: '/images/partners/pvs-b.png',
    image: '/images/partners/pvs.png',
    name: 'Barite',
  },
  {
    link: 'https://www.topmarineinc.com/',
    maskImage: '/images/partners/top-marine-b.png',
    image: '/images/partners/top-marine.png',
    name: 'Top marine inc',
  },
  {
    link: 'https://www.akijshipping.com/',
    maskImage: '/images/partners/akij-b.png',
    image: '/images/partners/akij.png',
    name: 'AKIJ shipping',
  },
  {
    link: 'http://www.strgco.com/',
    maskImage: '/images/partners/strgco-b.png',
    image: '/images/partners/strgco.png',
    name: 'Strgco',
  },
  {
    link: 'https://www.rhnetwork.net/',
    maskImage: '/images/partners/rh-b.png',
    image: '/images/partners/rh.png',
    name: 'RH network',
  },
  {
    link: 'https://www.seawavemaritime.it/',
    maskImage: '/images/partners/seawavemaritime-b.png',
    image: '/images/partners/seawavemaritime.png',
    name: 'Seawave',
  },
  {
    link: 'https://www.deltagf.com/',
    maskImage: '/images/partners/delta-b.png',
    image: '/images/partners/delta.png',
    name: 'Delta',
  },
  {
    link: 'https://www.tmsalt.com/',
    maskImage: '/images/partners/tmsalt-b.png',
    image: '/images/partners/tmsalt.png',
    name: 'TMSalt',
  },
  {
    link: 'https://www.s3sworldwide.com.sg/',
    maskImage: '/images/partners/s3s-b.png',
    image: '/images/partners/s3s.png',
    name: 'S3S',
  },
  {
    link: 'https://www.skargo.com.ua/',
    maskImage: '/images/partners/skargo-b.png',
    image: '/images/partners/skargo.png',
    name: 'Skargo',
  },
  {
    link: 'https://www.zimar.ca/',
    maskImage: '/images/partners/zimar-b.png',
    image: '/images/partners/zimar.png',
    name: 'Zimar',
  },
  {
    // link: 'https://www.charter-link.com.hk/',
    maskImage: '/images/partners/charter-link-b.png',
    image: '/images/partners/charter-link.png',
    name: 'Charter link',
  },
  {
    link: 'https://www.europemarine.me/',
    maskImage: '/images/partners/ems-b.png',
    image: '/images/partners/ems.png',
    name: 'Europe marine',
  },
  {
    link: 'https://www.rodson.com/',
    maskImage: '/images/partners/rodson-b.png',
    image: '/images/partners/rodson.png',
    name: 'Rodson',
  },
  {
    link: 'https://www.caxtonfreight.com/',
    maskImage: '/images/partners/caxton-b.png',
    image: '/images/partners/caxton.png',
    name: 'Caxton',
  },
  {
    link: 'http://www.navistarmaritime.com/',
    maskImage: '/images/partners/navisrat-b.png',
    image: '/images/partners/navisrat.png',
    name: 'Navisrat',
  },
  {
    link: 'http://www.dumarchart.com/',
    maskImage: '/images/partners/dumar-b.png',
    image: '/images/partners/dumar.png',
    name: 'Dumar',
  },
  {
    link: 'https://www.normidshipping.com/',
    maskImage: '/images/partners/normid-b.png',
    image: '/images/partners/normid.png',
    name: 'Normid shipping',
  },
  {
    link: 'https://www.navigo.si/',
    maskImage: '/images/partners/navigo-b.png',
    image: '/images/partners/navigo.png',
    name: 'Navigo',
  },
  {
    link: 'https://www.evaluation.echinasea.com/',
    maskImage: '/images/partners/china-sea-b.png',
    image: '/images/partners/china-sea.png',
    name: 'China sea',
  },
  {
    link: 'https://www.chtboe.com/',
    maskImage: '/images/partners/chtboe-b.png',
    image: '/images/partners/chtboe.png',
    name: 'Chtboe',
  },
  {
    link: 'https://www.lwgroup.eu/',
    maskImage: '/images/partners/lw-b.png',
    image: '/images/partners/lw.png',
    name: 'LW Group',
  },
  {
    link: 'https://www.minersa.com/',
    maskImage: '/images/partners/minersa-b.png',
    image: '/images/partners/minersa_logo.png',
    name: 'Minersa',
  },
  {
    link: 'https://www.african-tt.com/',
    maskImage: '/images/partners/african-b.png',
    image: '/images/partners/africian.png',
    name: 'African',
  },
  {
    link: 'http://www.kingcrown.in/',
    maskImage: '/images/partners/king_logo-b.png',
    image: '/images/partners/king-logo.png',
    name: 'King crown',
  },
  {
    link: 'https://www.nostosmarine.com/',
    maskImage: '/images/partners/nostos-marine-b.png',
    image: '/images/partners/nostos-marine.png',
    name: 'Nostos marine',
  },
  {
    link: 'https://www.novamarinecarriers.com/',
    maskImage: '/images/partners/nova-marine-b.png',
    image: '/images/partners/nova-marine.png',
    name: 'Nova Marine',
  },
  {
    link: 'https://www.genesisgroupslp.com/',
    maskImage: '/images/partners/genesis-b.png',
    image: '/images/partners/genesis.png',
    name: 'Genesis',
  },
  {
    link: 'https://www.kdrshipping.com/',
    maskImage: '/images/partners/kdr-b.png',
    image: '/images/partners/kdr.png',
    name: 'KDR',
  },
  {
    link: 'https://www.bangkokship.com/',
    maskImage: '/images/partners/bangkok-b.png',
    image: '/images/partners/bangkok.png',
    name: 'Bangkok',
  },
  {
    link: 'https://www.metalhouse.us/',
    maskImage: '/images/partners/mealhouse-b.png',
    image: '/images/partners/mealhouse.png',
    name: 'Metalhouse',
  },
  {
    link: 'https://www.willemmaritime.com/',
    maskImage: '/images/partners/willemmaritime-b.png',
    image: '/images/partners/willemmaritime.png',
    name: 'Willem Maritime',
  },
  {
    link: 'http://www.ecshipping.com.tr/',
    maskImage: '/images/partners/ec-shipping-b.png',
    image: '/images/partners/ec-shipping.png',
    name: 'EC Shipping',
  },
  {
    link: 'https://www.alyamar.com.tr/',
    maskImage: '/images/partners/alymar-b.png',
    image: '/images/partners/alymar.png',
    name: 'Alyamar',
  },
  {
    link: 'https://www.gatransportllc.com/',
    maskImage: '/images/partners/gat-b.png',
    image: '/images/partners/gat.png',
    name: 'GAT',
  },
  {
    link: 'https://www.pintobasto.com/',
    maskImage: '/images/partners/pinto-b.png',
    image: '/images/partners/pinto.png',
    name: 'PintoBasto',
  },
  {
    link: 'https://www.qualityfreight.com/',
    maskImage: '/images/partners/quality-b.png',
    image: '/images/partners/quality.png',
    name: 'Quality freight',
  },
  {
    link: 'http://www.dmsksa.com/',
    maskImage: '/images/partners/dms-b.png',
    image: '/images/partners/dms.png',
    name: 'DMS',
  },
  {
    link: 'http://www.joinocean.com/',
    maskImage: '/images/partners/join-ocean-b.png',
    image: '/images/partners/join-ocean.png',
    name: 'Join ocean',
  },
  {
    link: 'https://www.ge.team/',
    maskImage: '/images/partners/get-b.png',
    image: '/images/partners/get.png',
    name: 'GET',
  },
  {
    link: 'https://www.utrs.com/',
    maskImage: '/images/partners/utrs-b.png',
    image: '/images/partners/utrs.png',
    name: 'UTRS',
  },
  {
    link: 'https://www.colfletar.com.co/',
    maskImage: '/images/partners/ctr-b.png',
    image: '/images/partners/ctr.png',
    name: 'CTR',
  },
  {
    link: 'https://www.falcongrp.com/',
    maskImage: '/images/partners/falcongrp-b.png',
    image: '/images/partners/falcongrp.png',
    name: 'Falcon',
  },
  {
    link: 'https://www.albertmortensen.com/',
    maskImage: '/images/partners/albmor-b.png',
    image: '/images/partners/albmor.png',
    name: 'Albert Mortensen',
  },
];

class PagePartners extends Component {
  render() {
    return (
      <div>
        <span className={s.anchor} id="top" />
        <div className={cx(s.firstscreen, s.page_partners_firstscreen)}>
          <div className={s.container}>
            <div className={s.wrapper_firstscreen}>
              <div className={s.wrapper_title}>
                <div className={s.bg_title_mob}>
                  <h1>Clients & Partners</h1>
                </div>
                <p className={s.text_partners}>
                  SHIPNEXT always welcomes new clients and<br /> partners, creative ideas and cooperation. Interconnectivity and Interoperability is one of our<br /> key aims.
                </p>
              </div>
              <div className={s.right_part}>
                <Form
                  label={'Contact SHIPNEXT'}
                  requestType={'invest'}
                />
                <button
                  onClick={this.props.handleChangeForm.bind(this, 'Contact SHIPNEXT', 'invest')}
                >
                  Contact SHIPNEXT
                </button>
              </div>
            </div>

          </div>
        </div>

        <span className={s.anchor} id="partners" />
        <div className={s.partner}>
          <div className={s.container}>
            <div className={s.wrapper_partners}>
              {partners.map((item, i) => (
                item.link ?
                <a className={s.wrapper_image} key={i} href={item.link} target={'_blank'} rel="nofollow">
                  <img className={s.logo} src={item.image} alt={item.name} />
                  <img className={s.logo_mask} src={item.maskImage} alt={item.name} />
                </a>
                : <span className={s.wrapper_image} key={i}>
                    <img className={s.logo} src={item.image} alt={item.name} />
                    <img className={s.logo_mask} src={item.maskImage} alt={item.name} />
                  </span>
              ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(PagePartners);
