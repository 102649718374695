import React from 'react';
import pure from 'recompose/pure';

const _ContactsYahoo = props => (
  <span {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 24.0001C18.6274 24.0001 24 18.6275 24 12.0001C24 5.37271 18.6274 0.00012207 12 0.00012207C5.37258 0.00012207 0 5.37271 0 12.0001C0 18.6275 5.37258 24.0001 12 24.0001Z" fill="#285596"/>
      <g clipPath="url(#clip0_35788_27009)">
        <path d="M4 8.65162H7.05151L8.82839 13.4453L10.6284 8.65162H13.5992L9.12567 20H6.13554L7.36018 16.9929L4.00009 8.65162H4ZM17.0588 12.482H13.7312L16.6846 5L20 5.00015L17.0588 12.482ZM14.6029 13.1981C15.6239 13.1981 16.4515 14.0709 16.4515 15.1473C16.4515 16.2239 15.6239 17.0967 14.6029 17.0967C13.582 17.0967 12.7546 16.2239 12.7546 15.1473C12.7546 14.0709 13.5822 13.1981 14.6029 13.1981Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_35788_27009">
          <rect width="16" height="15" fill="white" transform="translate(4 5)"/>
        </clipPath>
      </defs>
    </svg>
  </span>
);

const ContactsYahoo = pure(_ContactsYahoo);
ContactsYahoo.displayName = 'ContactsYahoo';
ContactsYahoo.muiName = 'SvgIcon';

export default ContactsYahoo;
