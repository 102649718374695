import React from 'react'
import s from './VesselPreviewNew.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PortLink from '../../Common/PortLink';
import { date } from './../../../core/format'

const countDays = minutes => {
  let rawDays = minutes / 60 / 24;
  const rounded = Math.floor(rawDays);
  const hours = Math.round(24 * Math.abs(rawDays - rounded));
  return `${rounded > 0 ? `${rounded} ${rounded === 1 ? "day" : "days"}` : ""} ${rawDays % 1 === 0 ? "" : `${hours} hours`}`
}

export function PreviousPort({ port, defaultField = item => item || "---", ...rest }) {
  return (
    <div className={s.body} {...rest}>
        <div className={cx(s.row, s.port)}>
            <PortLink
                  port={{
                    _id:
                    port.port._id,
                    name: port.port.name
                  }}
            />
        </div>
        <div className={cx(s.row, s.speed)}>{`${port.averageSpeed ? port.averageSpeed + " kn" : defaultField(port.averageSpeed)}`}</div>
        <div className={cx(s.row, s.arrival_date)}>{date(port.firstRegisteredTimestamp)}</div>
        <div className={cx(s.row, s.duration)}>{defaultField(countDays(port.minutesOfStay))}</div>
        {/* <div className={cx(s.row, s.cargo)}>{defaultField(port.cargo)}</div> */}
    </div>
  )
}

export default withStyles(s)(PreviousPort)
