import Api from './api';

function convertNewsToOldFormat(res = []) {
  return res.map((item) => {
    return {
      link: item.slug,
      imageData: { path: item._embedded?.["wp:featuredmedia"]?.[0]?.source_url },
      shortDescription: (item.excerpt?.rendered || "").replace("<p>","").replace("</p>",""),
      publishDate: new Date(item.date_gmt),
      title: item.title.rendered,
      description: item.content?.rendered,
    }

  })

}

export default class IO extends Api {
  static endpoint = '/io/api';

  static async getNewsList({ limit = 6, offset = 0 }) {
    const uri = `/wp-json/wp/v2/posts?categories=2&_fields=id,excerpt,title,link,_links.wp:featuredmedia,content,slug,date_gmt&_embed&per_page=${limit}`;
    let res;
    try {
       res = await IO.fetch(uri, {
        method: 'GET',
      });
      return convertNewsToOldFormat(res);
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  static async getNews({ id }) {
    const uri = `/wp-json/wp/v2/posts?categories=2&slug=${encodeURIComponent(id)}&_fields=id,excerpt,title,link,_links.wp:featuredmedia,content,slug,date_gmt&_embed`;
    let res = await IO.fetch( uri, {
      method: 'GET',
    });
    return convertNewsToOldFormat(res)[0];
  }

  static async getConferences() {
    return IO.fetch(IO.endpoint + '/landing/conferences/landing?limit=100&order=' + encodeURIComponent('[["startDate","ASC"]]'), {
      method: 'GET',
    });
  }

  static async getRoadMap() {
    return IO.fetch(IO.endpoint + '/landing/road-map/landing?limit=50', {
      method: 'GET',
    });
  }

  static async getStaticText() {
    return IO.fetch(IO.endpoint + '/landing/static-text/landing?limit=50', {
      method: 'GET',
    });
  }

  static async getInPress() {
    return IO.fetch(IO.endpoint + '/landing/in-press/landing?limit=50', {
      method: 'GET',
    });
  }
  static async getPartners() {
    return IO.fetch(IO.endpoint + '/landing/partners/landing?limit=50', {
      method: 'GET',
    });
  }


  static async getStatistics() {
    return IO.fetch('/statistics/actual', {
      method: 'GET',
    });
  }

  static async getLanding() {
    let res = await Promise.all([this.getNewsList({ limit: 3 }), this.getStatistics()]);
    /*res = JSON.stringify(res);
    res = JSON.parse(res.replace(/https:\/\/shipnext\.io\//g, 'https://shipnext.com/api/v1/io/'));*/
    return {
      news: res[0],
      statistics: res[1].data,
      actual: Date.now(),
    };
  }
}
