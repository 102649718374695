import produce from "immer";

/** @name CONTACTS_SELECT_CONTACT_REDUCER */
 export default function CONTACTS_SELECT_CONTACT(state, { payload }) {
   const { id, selected } = payload;
  return produce(state, draft => {
    if (!id) {
      draft.contactList.data.forEach(c => {
        c.selected = selected;
      });
    } else {
      const contact = draft.contactList.data.find(c => c.id === id);
      if (contact) {
        contact.selected = selected;
      }
    }
  });
}
