import React from 'react'

export default function LockIcon({opened,fill="#4380C7",...props}) {
    if(opened){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill} {...props}>
                <path d="M10.0002 14.1667C10.4585 14.1667 10.8509 14.0035 11.1772 13.6771C11.5036 13.3507 11.6668 12.9584 11.6668 12.5C11.6668 12.0417 11.5036 11.6493 11.1772 11.323C10.8509 10.9966 10.4585 10.8334 10.0002 10.8334C9.54183 10.8334 9.14947 10.9966 8.82308 11.323C8.49669 11.6493 8.3335 12.0417 8.3335 12.5C8.3335 12.9584 8.49669 13.3507 8.82308 13.6771C9.14947 14.0035 9.54183 14.1667 10.0002 14.1667ZM5.00016 18.3334C4.54183 18.3334 4.14947 18.1702 3.82308 17.8438C3.49669 17.5174 3.3335 17.125 3.3335 16.6667V8.33337C3.3335 7.87504 3.49669 7.48268 3.82308 7.15629C4.14947 6.8299 4.54183 6.66671 5.00016 6.66671H10.8335V5.00004C10.8335 3.84726 11.2397 2.86462 12.0522 2.05212C12.8647 1.23962 13.8474 0.833374 15.0002 0.833374C16.1529 0.833374 17.1356 1.23962 17.9481 2.05212C18.7606 2.86462 19.1668 3.84726 19.1668 5.00004H17.5002C17.5002 4.3056 17.2571 3.71532 16.771 3.22921C16.2849 2.7431 15.6946 2.50004 15.0002 2.50004C14.3057 2.50004 13.7154 2.7431 13.2293 3.22921C12.7432 3.71532 12.5002 4.3056 12.5002 5.00004V6.66671H15.0002C15.4585 6.66671 15.8509 6.8299 16.1772 7.15629C16.5036 7.48268 16.6668 7.87504 16.6668 8.33337V16.6667C16.6668 17.125 16.5036 17.5174 16.1772 17.8438C15.8509 18.1702 15.4585 18.3334 15.0002 18.3334H5.00016Z"/>
            </svg>
        )
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill} {...props}>
                <path d="M5.00016 18.3334C4.54183 18.3334 4.14947 18.1702 3.82308 17.8438C3.49669 17.5174 3.3335 17.125 3.3335 16.6667V8.33337C3.3335 7.87504 3.49669 7.48268 3.82308 7.15629C4.14947 6.8299 4.54183 6.66671 5.00016 6.66671H5.8335V5.00004C5.8335 3.84726 6.23975 2.86462 7.05225 2.05212C7.86475 1.23962 8.84738 0.833374 10.0002 0.833374C11.1529 0.833374 12.1356 1.23962 12.9481 2.05212C13.7606 2.86462 14.1668 3.84726 14.1668 5.00004V6.66671H15.0002C15.4585 6.66671 15.8509 6.8299 16.1772 7.15629C16.5036 7.48268 16.6668 7.87504 16.6668 8.33337V16.6667C16.6668 17.125 16.5036 17.5174 16.1772 17.8438C15.8509 18.1702 15.4585 18.3334 15.0002 18.3334H5.00016ZM10.0002 14.1667C10.4585 14.1667 10.8509 14.0035 11.1772 13.6771C11.5036 13.3507 11.6668 12.9584 11.6668 12.5C11.6668 12.0417 11.5036 11.6493 11.1772 11.323C10.8509 10.9966 10.4585 10.8334 10.0002 10.8334C9.54183 10.8334 9.14947 10.9966 8.82308 11.323C8.49669 11.6493 8.3335 12.0417 8.3335 12.5C8.3335 12.9584 8.49669 13.3507 8.82308 13.6771C9.14947 14.0035 9.54183 14.1667 10.0002 14.1667ZM7.50016 6.66671H12.5002V5.00004C12.5002 4.3056 12.2571 3.71532 11.771 3.22921C11.2849 2.7431 10.6946 2.50004 10.0002 2.50004C9.30572 2.50004 8.71544 2.7431 8.22933 3.22921C7.74322 3.71532 7.50016 4.3056 7.50016 5.00004V6.66671Z"/>
            </svg>
        )
        
    }
}
