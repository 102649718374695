export default function CHANGE_VESSEL_STATUS(state, action) {
  const newState = { ...state, data: [...state.data] };
  const index = newState.data.findIndex(vessel => vessel._id === action.payload.vesselId);

  const newVessel = {
    ...(newState.data[index]),
    vesselUserGroups: {
      ...(newState.data[index]?.vesselGroups || {}),
      groups: action.payload.vesselGroups.groups,
    },
  };

  newState.data[index] = newVessel;

  return { ...newState };
}
