import MenuItem from 'material-ui/MenuItem/MenuItem';
import Link from '../Link/Link';
import React from 'react';
import { isTrading, sortByName } from '../../core/utils';


export const fleetLinks = sortByName([
  {
    name: "MY FLEET",
    id: "my",
    url: '/fleetmonitor/myfleet/',
  },
  {
    name: "S&P FLEET",
    id: "sp",
    url: '/fleetmonitor/spfleet/',
  },
  {
    name: "FLEET",
    id: "all",
    url: '/fleetmonitor/allfleet',
  }
], "asc")

export const tcLinks = sortByName([
  {
    name: "MAP",
    id: "map",
    url: '/fleetmonitor/allfleet/',
  },
  {
    name: "TIME CHARTER",
    id: "tc",
    url: '/fleetmonitor/allfleet/tc',
  },

], "asc");
export const myFleetTcLinks = sortByName([
  {
    name: "MAP",
    id: "map",
    url: '/fleetmonitor/myfleet/',
  },
  {
    name: "TIME CHARTER",
    id: "tc",
    url: '/fleetmonitor/myfleet/tc',
  },

], "asc");

export const findLink = (id, links = fleetLinks) => links.find(link => link.id.toLowerCase().trim() === String(id).toLowerCase().trim());

export const renderFleetMenu = list => {
  return list.map(item => (
    <Link to={item.url}>
      <MenuItem primaryText={item.name} value={item.name} key={item.url}>
      </MenuItem>
    </Link>
  ))
}

export const computeStatus = vessel => {
  if (!vessel) return {};
  const isMy = vessel.computedChannel === 'company';
  const isClient = vessel.computedChannel === 'broker';
  const isPublic = vessel.computedChannel === 'market';
  const isBidding = isTrading(vessel.status && vessel.status.status);
  const isRouteAIS = (vessel.computedChannel === 'onRoute' || vessel.computedChannel === 'ais') ? true : false;

  return {
    isMy,
    isClient,
    isPublic,
    isBidding,
    isRouteAIS
  }
}
