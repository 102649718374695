import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 1px
  }
`;

export const ForSaleHeader = styled.div`
  padding: 10px 10px 10px 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  > * {
    &:nth-child(1) {
      width: 31%;
    };
    &:nth-child(2) {
      width: 28%;
    };
    &:nth-child(3) {
      width: 36%;
    };
    &:nth-child(4) {
      width: 5%;
    };
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0 0;
  cursor: pointer;

  > * {
    transition: all 1s;
    transform: ${props => props.rotate ? "rotate(180deg)" : "rotate(0deg)"}
  }
`;

export const EmailRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px #D2D2D2 dashed;
  background-color: #F5F5F5;
  padding: 12px 20px 12px 19px;

  > * {
    line-height: 20px;
  }
`;

export const EmailSubHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ShowMessageToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  > span {
    margin-left: 4px;
    color: rgba(67, 128, 199, 1);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-decoration: underline;
  }
`;

export const TableRow = styled.div`
  padding: 0 10px 0 12px;
  width: 100%;
  display: flex;
  height: 48px;
  align-items: center;
  background-color: #F5F5F5;

  > * {
    &:nth-child(1) {
      width: 31%;
    };
    &:nth-child(2) {
      width: 28%;
    };
    &:nth-child(3) {
      width: 36%;
    };
    &:nth-child(4) {
      width: 5%;
    };
  }
`;

export const FirstCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
  ${props => props.right && css`align-items: flex-end;`}
`;

export const EmailText = styled.div`
  white-space: pre-wrap;
  color: #333333;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;

  &:first-of-type {
    padding: 0 0 4px 0;
  }

  ${props =>
    props.medium &&
    css`
      color: #787878;
    `
  };
  ${props =>
    props.dark &&
    css`
      color: rgba(0, 0, 0, 0.8);
    `
  };
`;

export const Text = styled.span`
  color: #333333;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  &:nth-child(1) {
    padding-bottom: 4px;
  };
  ${props =>
    props.link &&
    css`
      text-transform: none;
      color: #4380C7;
      text-decoration: underline;
      cursor: pointer;
    `
  };
  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `
  };
  ${props =>
    props.medium &&
    css`
      color: #787878;
    `
  };
  ${props =>
    props.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    `
  }
`;

export const OwnerChatWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  svg {
    width: 85.75%;
    height: 85.75%;
  }
`;
