import PropTypes from 'prop-types';
import React, { Component } from "react";
import styled, { css } from "styled-components";
export const Icon = styled.a`
  color: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
  }
`;
