/** @name SET_SELECTED_INDEXES */
export default function SET_SELECTED_INDEXES(state, action) {
  const newState = {};

  newState.balticIndex = {
    ...state.balticIndex,
    selectedIndexes: action.payload,
  };

  return { ...state, ...newState };
}
