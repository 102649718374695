exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".cHu_g {\n  padding: 48px 48px 0px 48px;\n  padding: 3rem 3rem 0rem 3rem;\n  width: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  max-width: 1200px;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  margin: 0 auto; }\n\n._2kx5d {\n  width: 100%;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: end;\n      align-items: flex-end; }\n\n._1DLN9 {\n  width: auto;\n  max-width: 100%;\n  margin: 0 auto;\n  border-collapse: collapse;\n  border-spacing: 0px;\n  table-layout: fixed; }\n\n._3y_O9 {\n  background-color: #F5F5F5 !important;\n  color: #787878 !important;\n  max-width: 500px;\n  width: 30%;\n  border: 1px solid #E6E6E6;\n  border-bottom: none;\n  font-size: 10px !important;\n  white-space: normal !important;\n  word-wrap: break-word;\n  text-transform: uppercase !important; }\n\n._3tkfX {\n  background-color: #F5F5F5 !important;\n  color: #787878 !important;\n  width: 80px;\n  max-width: 80px;\n  border: 1px solid #E6E6E6;\n  white-space: normal !important;\n  word-wrap: break-word;\n  text-align: center !important;\n  text-transform: uppercase !important;\n  font-size: 10px !important;\n  border-bottom: none; }\n\n._3tkfX.TQtWp {\n    font-weight: bold !important; }\n\n._2IJyG {\n  max-width: 500px;\n  width: 30%;\n  border: 1px solid #E6E6E6;\n  white-space: normal !important;\n  word-wrap: break-word;\n  color: #4380C7;\n  font-size: 12px !important;\n  padding-right: 0px !important; }\n\n._2tjsA {\n  max-width: 80px;\n  border: 1px solid #E6E6E6;\n  white-space: normal !important;\n  word-wrap: break-word;\n  width: 80px;\n  color: #333333;\n  font-size: 12px !important;\n  text-align: center !important; }\n", ""]);

// Exports
exports.locals = {
	"TableWrapper": "cHu_g",
	"BetweenContainer": "_2kx5d",
	"BorderedTable": "_1DLN9",
	"BigHeader": "_3y_O9",
	"SmallHeader": "_3tkfX",
	"Highlighted": "TQtWp",
	"BigRow": "_2IJyG",
	"SmallRow": "_2tjsA"
};